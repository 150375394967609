export function createFundingRequestV3(send) {
  return (sourceAccountId, body, ...args) =>
    send(
      {
        path: '/v3/sourceAccounts/{sourceAccountId}/fundingRequest',
        pathParameters: { sourceAccountId },
        type: 'post',
        query: undefined,
        body: body,
        headers: undefined,
        auth: 'oauth2',
        contentType: 'application/json',
      },
      ...args
    );
}
