import observe from 'callbag-observe';
import { createFileDownloadHandler } from './FileDownloaderHandler';
import { createTokenStore } from './TokenStore';

const preAuthLinks = [
  'passwordUpdateVerificationLink',
  'unlockAccountLink',
  'id',
  'unsubscribeLink',
];

const hasDeepLink = (searchParams) =>
  preAuthLinks.some((link) => searchParams.has(link));

const storeKey = 'refreshToken';

createVeloLibraryAdapter.deepLinks = preAuthLinks;
createVeloLibraryAdapter.tokenStoreKey = storeKey;

/**
 * @deprecated
 * This adapter exists to bridge a gap between an older iteration of the front-end architecture
 * and the current version. It does not represent the strategic direction of the codebase,
 * and its functionality should generally be migrated into other contexts with time,
 * rather than new functionality being added here, or expensive maintenance being done on this lib.
 * */
export function createVeloLibraryAdapter(api, window) {
  const goToLogin = () => (window.location.hash = '#/login');
  const downloadContentAsFile = createFileDownloadHandler(window);
  const tokenStore = createTokenStore(window.sessionStorage, storeKey);

  function removeDeepLink() {
    const base = window.location.toString().split('?')[0];
    const url = base + window.location.hash;
    window.history.replaceState(null, null, url);
  }

  function _logout() {
    // go to the login screen first
    goToLogin();
    tokenStore.clear();
  }

  const storeTokenPair = (response) => api.onSessionState(response, true);

  const lib = {
    api,
    parent: { _logout, removeDeepLink },
    storeTokenPair,
    downloadContentAsFile,
  };

  // observe changes to the token
  observe((response) => {
    if (response.refresh_token) {
      tokenStore.save(response.refresh_token);
    }
  })(api.sessionStateObservable);

  var searchParams = new URLSearchParams(window.location.search);

  const setUnauthenticatedAuthState = () => api.onSessionState({}, false);

  if (hasDeepLink(searchParams) || searchParams.has('inviteLink')) {
    setUnauthenticatedAuthState();
  }

  // have we come from a deep link, remove it and exit without loading a refreshToken
  if (hasDeepLink(searchParams)) {
    const eventType = 'hashchange';
    // the handler is added once and then removed
    var handler = function () {
      window.removeEventListener(eventType, handler);
      removeDeepLink();
    };
    window.addEventListener(eventType, handler);
    return lib;
  }

  if (searchParams.has('inviteLink')) {
    return lib;
  }

  if (api.enableOIDC) {
    return lib;
  }

  // store the refreshToken on the parent object from storage
  const refreshToken = tokenStore.load();
  // if we don't have a refresh token, forward to the login screen
  if (!refreshToken) {
    setUnauthenticatedAuthState();
    goToLogin();
    return lib;
  }

  // otherwise, attempt to refresh the token (login happens automatically)
  api.refresh(refreshToken).then(storeTokenPair, () => {
    // don't repeat the same mistake
    tokenStore.clear();
    goToLogin();
  });

  return lib;
}
