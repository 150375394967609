import React from 'react';
import { arrayOf, string, shape, bool, func, oneOf, node } from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import styled from 'astroturf/react';
import { VeloIconButton } from '../VeloIconButton';
import { VeloCard } from '../VeloCard';
import { ConfirmationDialog } from '../ConfirmationDialog';
import { VeloOverlay } from '../VeloOverlay';
import { PaymentChannelFormBody } from './PaymentChannelFormBody';
import { PaymentChannelsFormMode } from './PaymentChannelsFormMode';
import { PaymentChannelsBankLookupMode } from './PaymentChannelsBankLookupMode';
import { PaymentChannelView } from '../PaymentChannelView';
import { VeloPropTypes } from '../VeloPropTypes';
import { BankLookupDialog } from '../BankLookupDialog';
import { objectHasProperty } from 'velo-data';
import { DisclosureMessage } from '../DisclosureMessage';
import { PaymentChannelEnableLink } from '../PaymentChannelEnableLink';

const Card = styled(VeloCard)`
  @import 'velo-variables';

  padding: 0rem 1.5rem 1rem;

  @media (min-width: velo-breakpoint(XS)) {
    background-color: velo-color('token-color-system-page-background');
  }
`;

const Header = styled(VeloCard.Header)`
  margin: 0.5rem 0;
`;

const Content = styled(VeloCard.Content)`
  margin: 1rem 0;
`;

const Error = styled(VeloCard.Error)`
  margin: 0 0 1rem;
`;

const ErrorMessage = styled(VeloCard.ErrorMessage)`
  text-align: center;
`;

const Action = styled(VeloCard.Action)`
  @import 'velo-variables';

  margin-left: 0;
  margin-right: 0;

  @media (max-width: velo-breakpoint(XS)) {
    flex-direction: column;
    flex-direction: column-reverse;
  }
`;

const root = 'velo-payment-channel';
const TestIds = {
  CLOSE: `${root}-close`,
  CREATE_CHANNEL_FORM: 'create-channel-form',
  CREATE_CHANNEL_BUTTON: 'create-channel-button',
  CONFIRM_DIALOG: `${root}-confirm-dialog`,
  PAYEE_DISCLOSURE: `${root}-disclosure-message`,
  SAVE_DESKTOP: `${root}-save-desktop`,
  SAVE_MOBILE: `${root}-save-mobile`,
  ENABLE_INSTRUCTION: PaymentChannelView.Disabled.testIds.ENABLE_INSTRUCTION,
};

// Used to manipulate channel values when syncing with
// initial props and submitting data.
const convertValues = (channel, cb) =>
  Object.keys(channel).reduce(
    (previous, key) => ({
      ...previous,
      [key]: cb(channel[key]),
    }),
    {}
  );

//Check to test whether the banklookup result is empty
const isEmptyBankLookup = (obj) => !obj || Object.keys(obj).length === 0;

const typePropsMap = {
  [PaymentChannelsFormMode.CREATE]: {
    title: <FormattedMessage defaultMessage="Create Payment Method" />,
    actionButtonText: (submitting) =>
      submitting ? (
        <FormattedMessage defaultMessage="Creating..." />
      ) : (
        <FormattedMessage defaultMessage="Create" />
      ),
  },
  [PaymentChannelsFormMode.EDIT]: {
    title: <FormattedMessage defaultMessage="Edit Payment Method" />,
    actionButtonText: (submitting) =>
      submitting ? (
        <FormattedMessage defaultMessage="Saving..." />
      ) : (
        <FormattedMessage defaultMessage="Save" />
      ),
  },
};

/**
 * Create new payment channel form.
 */
class PaymentChannelForm extends React.Component {
  static testIds = TestIds;
  static propTypes = {
    /** The type of form to display. */
    type: oneOf(Object.values(PaymentChannelsFormMode)),
    /** Called when the close button is clicked. */
    onClose: func.isRequired,
    /** Called when the form is submitted. Passed an object of field values. */
    onSubmit: func.isRequired,
    /** Override the initial submitting status. */
    submitting: bool,
    /** The error message to display. */
    error: node,
    /** The payment channel details used to initialise the form when editing. */
    channel: shape({
      /**Payment Channel Name. */
      paymentChannelName: string,
      /** The channel country code. */
      countryCode: string,
      /** The channel currency code. */
      currency: string,
      /** The account name. */
      accountName: string,
      /** The routing number (e.g. 123-456-789) */
      routingNumber: string,
      /** The account number. */
      accountNumber: string,
      /** The IBAN. */
      iban: string,
    }),
    /** Supported country codes. */
    countries: arrayOf(string),
    /** Current country selected */
    selectedCountry: string,
    /** Currencies for the selected country */
    currencies: arrayOf(string),
    /** The list of payment channel rules for bank fields to display. */
    paymentChannelRules: VeloPropTypes.paymentChannelRulesType(),
    /** function to handle on country change */
    onCountryChange: func.isRequired,
    /** optional classname for form */
    className: string,
    /** The bank lookup results */
    banklookup: VeloPropTypes.bankLookupType(),
    /** function to handle on enable payment channel */
    onEnablePaymentChannel: func.isRequired,
  };

  static defaultProps = {
    type: PaymentChannelsFormMode.CREATE,
    channel: {},
  };

  static Body = PaymentChannelFormBody;
  static bankLookupMode = PaymentChannelsBankLookupMode;
  static mode = PaymentChannelsFormMode;
  static formatBankLookupError = (label) =>
    typeof label === 'string' ? (
      `The ${label} provided is incorrect. Please correct and resubmit.`
    ) : (
      <FormattedMessage
        defaultMessage="The {label} provided is incorrect. Please correct and resubmit."
        values={{ label }}
      />
    );

  constructor(props) {
    super(props);
    // If passed a channel for editing we need to ensure
    // any null/undefined values are replaced with empty strings
    const initialChannel = convertValues(props.channel, (value) =>
      value === null || value === undefined ? '' : value
    );
    // Initialise the local state
    this.state = {
      channel: {
        paymentChannelName: '',
        countryCode: 'US',
        currency: 'USD',
        accountName: '',
        routingNumber: '',
        accountNumber: '',
        iban: '',
        // Merge in channel props in case we are editing
        ...initialChannel,
      },
      submitting: false,
      error: props.error,
      showCloseConfirmation: false,
      selectedCountry: 'US',
      currency: 'USD',
      contentHasChanged: false,
      banklookup: {},
    };
    // Create a ref to the error message
    this.errorRef = React.createRef();
  }

  // Shared change handler for all elements
  handleChange = ({ target: { value, name } }) => {
    const isCountry = name === 'countryCode';
    this.setState(
      (state) => ({
        channel: { ...state.channel, [name]: value },
        contentHasChanged: true,
      }),
      () => {
        isCountry && this.props.onCountryChange(value);
      }
    );
  };

  handleSubmit = (e) => {
    this.setState(
      () => ({
        validating: true,
        error: '',
      }),
      () => this.props.onBankLookup(this.state.channel)
    );

    e.preventDefault();
  };

  verifyContentHasChanged = () => {
    const { channel: original } = this.props;
    const { channel: current } = this.state;
    // Count the number of changes made by the user
    const changeCount = Object.keys(current).reduce(
      (changes, key) =>
        original[key] !== undefined &&
        original[key] !== null &&
        current[key] !== original[key]
          ? changes + 1
          : changes,
      0
    );

    return changeCount > 0;
  };

  handleClose = () => {
    const { type, onClose } = this.props;

    if (
      type === PaymentChannelsFormMode.EDIT &&
      this.verifyContentHasChanged()
    ) {
      // The user made some changes
      // Display a confirmation popup
      this.setState({ showCloseConfirmation: true });
    } else {
      // Creating new channel or editing with no changes made
      onClose();
    }
  };

  handleEnablePaymentChannel = (evt) => {
    this.setState({
      submitting: true,
      error: '',
    });

    evt.preventDefault();
    this.props.onEnablePaymentChannel();
  };

  handleCloseNotification = (evt) => {
    const action = evt.detail.action;
    this.setState({ showCloseConfirmation: false }, () => {
      if (action === 'accept') {
        // Hiding the entire component while the confirmation
        // dialog is closing could feasibly cause an exception.
        requestAnimationFrame(() => this.props.onClose());
      }
    });
  };

  handleCloseBankLookup = (evt) => {
    const action = evt.detail.action;
    this.setState(
      {
        validating: false,
        submitting: action === 'accept',
        banklookup: {},
        error: '',
      },
      () => {
        if (action === 'accept') {
          this.submitForm();
        }
      }
    );
  };

  submitForm = () => {
    // Any empty strings need to be passed back as null
    const data = convertValues(this.state.channel, (value) =>
      value === '' ? null : value
    );

    this.props.onSubmit(data);
  };

  componentDidUpdate(prevProps, prevState) {
    // If we are rendered with an error while submitting
    // then we want to re-enable the form elements and display
    // the error to the user.
    if (
      (this.props.error && prevState.submitting) ||
      (this.props.error && !prevState.error)
    ) {
      this.setState(
        {
          error: this.props.error,
          submitting: false,
          validating: false,
        },
        () => {
          // Ensure the error is visible
          this.errorRef.current.scrollIntoView();
        }
      );
    }

    //If the country has changed, we need to set the selected currency
    //to be the first in the currencies array
    //There will always be one item in the array, the default has been set to ['USD]
    if (
      prevProps.selectedCountry !== this.props.selectedCountry &&
      prevProps.selectedCountry
    ) {
      this.setState({
        channel: {
          ...this.state.channel,
          routingNumber: '',
          accountNumber: '',
          iban: '',
          currency: this.props.currencies[0],
        },
      });
    }

    if (prevProps.channel !== this.props.channel) {
      this.setState({
        channel: {
          ...this.props.channel,
        },
      });
    }

    if (prevProps.banklookup !== this.props.banklookup) {
      const { result } = this.props.banklookup;
      if (result && isEmptyBankLookup(result)) {
        //if the banklookup is returned as an empty object, we will just submit the form
        this.submitForm();
      } else {
        this.setState(() => ({
          banklookup: this.props.banklookup,
        }));
      }
    }
  }

  render() {
    const {
      countries,
      currencies,
      paymentChannelRules,
      className,
      mode,
      showAdvancedPaymentMethods,
      intl,
    } = this.props;

    const {
      submitting,
      error,
      channel,
      validating,
      banklookup,
      contentHasChanged,
    } = this.state;

    const { title, actionButtonText } = typePropsMap[this.props.type];
    const enabled = objectHasProperty(channel, 'enabled', true);
    return (
      <Card>
        <Header divider>
          <VeloCard.HeaderTitle>{title}</VeloCard.HeaderTitle>
          <VeloIconButton
            icon="close"
            title={intl.formatMessage({
              defaultMessage: 'Close Payment Method form',
            })}
            onClick={this.handleClose}
            data-testid={TestIds.CLOSE}
          />
        </Header>

        <VeloCard.Body>
          <form
            onSubmit={this.handleSubmit}
            data-testid={TestIds.CREATE_CHANNEL_FORM}
            className={className}
          >
            {!enabled && (
              <PaymentChannelView.Disabled
                editMode
                disabledReason={channel.disabledReason}
              />
            )}

            <Content>
              <PaymentChannelFormBody
                paymentChannelName={channel.paymentChannelName}
                countryCode={channel.countryCode}
                currency={channel.currency}
                accountName={channel.accountName}
                routingNumber={channel.routingNumber}
                accountNumber={channel.accountNumber}
                iban={channel.iban}
                countries={countries}
                currencies={currencies}
                onChange={this.handleChange}
                disabled={submitting}
                paymentChannelRules={paymentChannelRules}
                banklookupMode={
                  PaymentChannelsBankLookupMode.paymentChannelForm
                }
                showAdvancedPaymentMethods={showAdvancedPaymentMethods}
              />
            </Content>
            {error && (
              <Error ref={this.errorRef}>
                <ErrorMessage>{error}</ErrorMessage>
              </Error>
            )}
            <Action>
              {!enabled && (
                <PaymentChannelEnableLink
                  handleEnablePaymentChannel={this.handleEnablePaymentChannel}
                />
              )}

              <VeloCard.Button
                disabled={submitting || validating || !contentHasChanged}
                data-testid={TestIds.CREATE_CHANNEL_BUTTON}
              >
                {validating ? (
                  <FormattedMessage defaultMessage="Validating..." />
                ) : (
                  actionButtonText(submitting)
                )}
              </VeloCard.Button>
            </Action>
            {mode === PaymentChannelView.mode.PAYEE && (
              <Content data-testid={TestIds.PAYEE_DISCLOSURE}>
                <DisclosureMessage divider />
              </Content>
            )}
          </form>
        </VeloCard.Body>

        <ConfirmationDialog
          open={this.state.showCloseConfirmation}
          onClose={this.handleCloseNotification}
          dialogType={ConfirmationDialog.dialogTypes.DiscardChangesType}
          data-testid={TestIds.CONFIRM_DIALOG}
        />

        <BankLookupDialog
          title={
            <FormattedMessage defaultMessage="Are these details correct?" />
          }
          open={banklookup.result !== undefined}
          onClose={this.handleCloseBankLookup}
        >
          <BankLookupDialog.Body
            paymentChannelRules={paymentChannelRules}
            banklookup={banklookup.result}
            channel={channel}
          />
        </BankLookupDialog>

        <VeloOverlay show={submitting} />
      </Card>
    );
  }
}

const PaymentChannelFormIntl = injectIntl(PaymentChannelForm);

export { PaymentChannelFormIntl as PaymentChannelForm };
