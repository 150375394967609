import React from 'react';
import { string } from 'prop-types';
import { IconButton } from '@rmwc/icon-button';
import { VeloIcon } from '../VeloIcon';
import styled from 'astroturf/react';

import '@material/icon-button/dist/mdc.icon-button.css';

VeloIconButton.propTypes = {
  /** The title attribute text  */
  title: string.isRequired,
  /** Test ID for the Velo Icon Button. */
  'data-testid': string.isRequired,
};

const StyledIconButton = styled(IconButton)`
  @import 'velo-variables';
  color: velo-color('token-color-text-default');
`;

/**
 * Icon Button component.
 * Requires all Icon buttons have a title attribute and a data-testid
 */
function VeloIconButton({ ...props }) {
  return (
    <StyledIconButton
      {...props}
      icon={{
        strategy: 'component',
        icon: <VeloIcon icon={props.icon} />,
      }}
    />
  );
}

export { VeloIconButton };
