export function SettingsAdvancedPresenter(
  wireframe,
  _,
  { showAdvancedPaymentMethods, setAdvancedPaymentMethods, locale, setLocale },
  { successNotification }
) {
  const onClose = () => {
    wireframe.goBack();
  };

  const onSubmit = ({ showAdvancedPaymentMethods, locale }) => {
    setAdvancedPaymentMethods(showAdvancedPaymentMethods);
    setLocale(locale);
    wireframe.sendNote(successNotification);
    wireframe.goBack();
  };

  return {
    onClose,
    onSubmit,
    advancedPaymentMethodsEnabled: showAdvancedPaymentMethods,
    locale,
  };
}
