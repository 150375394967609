import React from 'react';
import { any, string } from 'prop-types';
import styled from 'astroturf/react';
import { VeloDate } from '../VeloDate';
import { VeloTime } from '../VeloTime';

const Container = styled('div')`
  @import '@design-system/tokens/typography/scss/mixins';

  @include ds-typography-ellipsis;
`;

VeloDateTime.propTypes = {
  /** Custom CSS class(es). */
  className: string,
  /** The date/time to use. */
  value: any.isRequired,
  /** The HTML tag to use. */
  tag: string,
};

function VeloDateTime({ value, tag = 'div', ...other }) {
  return (
    <Container as={tag} {...other}>
      <VeloDate.Short value={value} /> <VeloTime.Short value={value} />
    </Container>
  );
}

export { VeloDateTime };
