import React from 'react';
import { func } from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import { PageHeader } from '../PageHeader';
import { VeloFab } from '../VeloFab';

export function FundingAccountsPageHeader(props) {
  return (
    <PageHeader
      title={<FormattedMessage defaultMessage="Funding Accounts" />}
      {...props}
    />
  );
}

WithCreate.propTypes = {
  onCreate: func.isRequired,
};

function WithCreate({ onCreate }) {
  const intl = useIntl();
  return (
    <FundingAccountsPageHeader
      fab={
        <VeloFab
          title={intl.formatMessage({ defaultMessage: 'Funding Accounts' })}
          label={<FormattedMessage defaultMessage="Create Account" />}
          icon="add"
          onClick={onCreate}
          data-testid={VeloFab.testIdBuilder('Create Account')}
        />
      }
    />
  );
}

FundingAccountsPageHeader.WithCreate = WithCreate;
