import React from 'react';
import { oneOf } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { OnboardedStatus } from 'velo-data';
import { VeloStatusIndicator } from '../VeloStatusIndicator';

const OnboardStatusMap = {
  [OnboardedStatus.CREATED]: {
    type: VeloStatusIndicator.types.UNKNOWN,
    label: <FormattedMessage defaultMessage="Created" />,
  },
  [OnboardedStatus.INVITED]: {
    type: VeloStatusIndicator.types.COMM,
    label: <FormattedMessage defaultMessage="Invited" />,
  },
  [OnboardedStatus.REGISTERED]: {
    type: VeloStatusIndicator.types.INFO,
    label: <FormattedMessage defaultMessage="Registered" />,
  },
  [OnboardedStatus.ONBOARDED]: {
    type: VeloStatusIndicator.types.SUCCESS,
    label: <FormattedMessage defaultMessage="Onboarded" />,
  },
};

OnboardedStatusIndicator.propTypes = {
  /**
   * The onboarded status as a string, or empty for unavailable.
   */
  onboardedStatus: oneOf(Object.keys(OnboardStatusMap)),
};

function OnboardedStatusIndicator({ onboardedStatus, ...props }) {
  return (
    <VeloStatusIndicator
      {...props}
      {...(OnboardStatusMap[onboardedStatus] || {
        type: VeloStatusIndicator.types.UNKNOWN,
        label: 'Unavailable',
      })}
    />
  );
}

export { OnboardedStatusIndicator };
