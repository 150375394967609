import React from 'react';
import { Filter } from '../Filter';
import { Pagination } from '../Pagination';

export function createRenderForksFromTable(TableSpec) {
  const { Table, Loading, Empty, Data, Error, Headings } = TableSpec;

  function renderTable({ filterProps, headerProps, pageProps }, children) {
    return (
      <Table>
        {filterProps?.fields.length ? <Filter {...filterProps} /> : null}
        <Headings {...headerProps} />
        {children}
        {pageProps.totalPages ? <Pagination {...pageProps} /> : null}
      </Table>
    );
  }

  return {
    none: (props) => renderTable(props, <Loading />),
    error: (error, props) => renderTable(props, <Error error={error} />),
    empty: (props) => renderTable(props, <Empty />),
    value: (data, tableProps, props) =>
      renderTable(tableProps, <Data data={data} {...props} />),
  };
}
