import React, { useState, useEffect } from 'react';
import { bool, string, func, shape, arrayOf } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import { VeloDialog } from '../../VeloDialog';
import { VeloCardForm } from '../../VeloCardForm';
import { VeloSelect } from '../../VeloSelect';
import { VeloCallout } from '../../VeloCallout';

import styles from './NavigationDrawerOperatingAsDialog.module.scss';

const root = `velo-navigation-drawer-operating-as-dialog`;

const TestIds = {
  ...VeloCardForm.testIds,
  SELECT: `${root}-select`,
  CLOSE: `${root}-close`,
  ACTION_BUTTON: `${root}-action-button`,
};

NavigationDrawerOperatingAsDialog.root = root;
NavigationDrawerOperatingAsDialog.testIds = TestIds;

NavigationDrawerOperatingAsDialog.propTypes = {
  /** Render if open. */
  open: bool,
  /** the currently selected operating payor */
  selectedOperatingAsPayor: shape({
    /** whether the current operate as payor is the logged in payor */
    isLoggedInPayor: bool,
    /** the operating as payor id */
    payorId: string,
    /** the operating payor name */
    payorName: string,
  }),
  /** the list of available operating as payors */
  operateAsPayors: arrayOf(
    shape({
      /** the operating as payor id */
      payorId: string,
      /** the operating payor name */
      payorName: string,
    })
  ),
  /** the function to handle when the operating user is changed */
  handleApplyOperatingAs: func.isRequired,
  /** the function to handle when the operating user dialog is closed */
  handleCloseOperatingAs: func.isRequired,
};

function Form({
  handleApplyOperatingAs,
  handleCloseOperatingAs,
  operateAsPayors,
  selectedOperatingAsPayor,
}) {
  const options = operateAsPayors
    .map((payor) => {
      return { label: payor.payorName, value: payor.payorId };
    })
    .sort((a, b) => a.label.localeCompare(b.label));

  const [operatingAs, setOperatingAs] = useState();

  /**
   * RMWC Select work around.
   * This forces the floating label to float.
   *
   * Refs are not supported by RMWC Select so using the foundation lib is not
   * possible.
   */
  useEffect(() => {
    setOperatingAs(selectedOperatingAsPayor.payorId);
  }, [selectedOperatingAsPayor.payorId]);

  return (
    <VeloCardForm
      className={styles.card}
      onSubmit={(e) => {
        e.preventDefault();
        handleApplyOperatingAs(operatingAs);
      }}
      buttonProps={{
        children: <FormattedMessage defaultMessage="Apply" />,
        className: 'applyButton',
        'data-testid': TestIds.ACTION_BUTTON,
      }}
      onClose={() => {
        handleCloseOperatingAs();
      }}
      title={<FormattedMessage defaultMessage="Act as" />}
      fullScreen={false}
      overlay={false}
    >
      <div className={classnames('ds-typography-root', styles.container)}>
        <VeloCallout type={VeloCallout.types.WARNING}>
          <FormattedMessage
            defaultMessage="This lets you see and behave as another Payor. Be aware that any
          changes made in this mode will affect the Payor you are acting as."
          />
        </VeloCallout>
        <div className={styles.currentlyOperatingMessage}>
          <FormattedMessage
            defaultMessage="Currently acting as {payor}."
            values={{
              payor: <strong>{selectedOperatingAsPayor.payorName}</strong>,
            }}
          />
        </div>
        <VeloSelect
          label={<FormattedMessage defaultMessage="Act as" />}
          placeholder=""
          key={selectedOperatingAsPayor.payorId}
          options={options}
          value={operatingAs}
          data-testid={TestIds.SELECT}
          onChange={(e) => setOperatingAs(e.target.value)}
        />
      </div>
    </VeloCardForm>
  );
}

function NavigationDrawerOperatingAsDialog({
  open,
  handleCloseOperatingAs,
  ...props
}) {
  return (
    <VeloDialog
      disableTrapFocus={true}
      open={open}
      onClose={() => {
        handleCloseOperatingAs();
      }}
      role="dialog"
    >
      <Form {...props} handleCloseOperatingAs={handleCloseOperatingAs} />
    </VeloDialog>
  );
}
export { NavigationDrawerOperatingAsDialog };
