import React from 'react';
import { FormattedMessage } from 'react-intl';
import { PaymentViewResult } from '../../PaymentViewResult';

/**
 * Render a Payor Name.
 */
function PayorName({ result }) {
  return (
    <PaymentViewResult
      {...result}
      errorMessage={<FormattedMessage defaultMessage="Error loading Payor" />}
    />
  );
}

export { PayorName };
