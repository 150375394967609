import React from 'react';
import { bool, func, node } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styled from 'astroturf/react';
import { VeloForm } from '../VeloForm';
import { PasswordFieldSet } from '../PasswordFieldSet';
import { VeloStrengthMeter } from '../VeloStrengthMeter';
import { VeloLogoPage } from '../VeloLogoPage';

const Form = styled(VeloForm)`
  &.offsetTop {
    margin-top: 1.5rem;
  }
`;

Form.propTypes = {
  offsetTop: bool,
};

const FieldSet = styled(PasswordFieldSet)`
  margin-bottom: 1.5rem;
`;

const FieldLabels = {
  password: <FormattedMessage defaultMessage="New Password" />,
  confirm: <FormattedMessage defaultMessage="Confirm New Password" />,
};

class ResetPasswordForm extends React.Component {
  static propTypes = {
    /**
     * An optional error when the password cannot be set.
     */
    error: node,
    /**
     * A result of validation detail to pass to the password fieldset.
     */
    validationDetail: VeloStrengthMeter.ValidationDetailPropType,
    /**
     * A function that is called when when the password changes.
     */
    validatePassword: func.isRequired,
    /**
     * A function that is called when the form is submitted.
     */
    onSubmit: func.isRequired,
    /**
     * A boolean used to apply a top margin in cases where the form is dropped
     * into a legacy placeholder div
     *
     * FIXME: Remove this when the onboarding journey is refactored
     */
    offsetTop: bool,
  };

  static fieldLabels = FieldLabels;

  state = {
    password: '',
    submitting: false,
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState(
      (state) => ({ ...state, submitting: true }),
      () => this.props.onSubmit({ password: this.state.password })
    );
  };

  handlePasswordChange = ({ password }) =>
    this.setState((state) => ({ ...state, password }), this.onPasswordChange);

  componentDidUpdate(prevProps) {
    if (prevProps.error !== this.props.error) {
      this.setState((state) => ({ ...state, submitting: false }));
    }
  }

  onPasswordChange = () =>
    this.props.validatePassword({ password: this.state.password });

  render() {
    const { error, validationDetail, offsetTop } = this.props;
    const { submitting } = this.state;
    const actionText = submitting ? (
      <FormattedMessage defaultMessage="Saving..." />
    ) : (
      <FormattedMessage defaultMessage="Save" />
    );

    const formProps = {
      actionText,
      error,
      overlay: submitting,
      onSubmit: this.handleSubmit,
      defaultErrorMessage: (
        <FormattedMessage defaultMessage="Error saving your password. Please try again." />
      ),
      offsetTop,
    };

    return (
      <Form {...formProps}>
        <VeloLogoPage.Text>
          <FormattedMessage defaultMessage="Set a secure password for signing in." />
        </VeloLogoPage.Text>

        <FieldSet
          passwordLabel={FieldLabels.password}
          confirmLabel={FieldLabels.confirm}
          validationDetail={validationDetail}
          onChange={this.handlePasswordChange}
        />
      </Form>
    );
  }
}

export { ResetPasswordForm };
