import React from 'react';
import { bool, string } from 'prop-types';
import styled from 'astroturf/react';
import { VeloSkeleton } from '../VeloSkeleton';

const Icon = styled('div')`
  @import 'velo-variables';

  width: 1.5rem;
  min-width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  font-size: 0.75rem;
  line-height: 1.5rem;
  text-align: center;
  margin-right: 0.75rem;
  color: velo-color('token-color-text-inverse');
  background-color: velo-color('token-color-brand-primary');

  &.disabled {
    background-color: velo-color('token-color-system-disabled');
  }

  &.success {
    background-color: velo-color('token-color-system-success-default');
  }

  /* Ensure the icons are the correct size. */
  :global(.ds-icon) {
    line-height: inherit;
    font-size: 1rem;
  }
`;

Icon.propTypes = {
  disabled: bool,
  success: bool,
};

const Skeleton = styled(VeloSkeleton)`
  width: 1.5rem;
  min-width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.75rem;
`;

VeloStepperIcon.propTypes = {
  /** Custom CSS class(es). */
  className: string,
  /** Disabled the icon. */
  disabled: bool,
  /** Icon indicates a successful state */
  success: bool,
  /** Render as a loading skeleton. */
  skeleton: bool,
};

/**
 * Vertical stepper icon. Can be used to display the step number or
 * a Material icon (by rendering an rmwc/Icon component.)
 */
function VeloStepperIcon({ disabled, skeleton, ...other }) {
  return skeleton ? <Skeleton /> : <Icon disabled={disabled} {...other} />;
}

export { VeloStepperIcon };
