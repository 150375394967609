import styled from 'astroturf/react';
import { VeloTypography } from '../VeloTypography';

const ErrorMessage = styled(VeloTypography).attrs({
  use: 'bodyTextEmphasised',
  tag: 'div',
})`
  @import 'velo-variables';

  background-color: velo-color('token-color-system-error-lighter');
  border-radius: 4px;
  flex-basis: 100%;
  padding: 1rem;
  text-align: center;
`;

export { ErrorMessage };
