import map from 'just-map';
import { formatErrorCode, formatCallbackErrorArg } from '../../selectors';

const OTP_REQUIRED_ERROR = 'otpRequired';

/**
 * The OTPRequiredPresenter takes full control of the dialog state.
 * Therefore the initial state is defined in this file.
 */
OTPRequiredPresenter.initialState = {
  open: false,
  onSubmit: () => {},
  title: '',
  mobile: '',
};

export function OTPRequiredPresenter(setState, entity, { handlers, user }) {
  const closeDialog = () =>
    setState({ ...OTPRequiredPresenter.initialState, mobile: user.smsNumber });

  const wrappedHandlers = map(handlers, (_, handler) => (body, callback) => {
    const cb = (error, data) => {
      const errorMessage = formatErrorCode(error, OTP_REQUIRED_ERROR);

      if (errorMessage === OTP_REQUIRED_ERROR) {
        /**
         * When there is a OTP required error,
         * Then we show a dialog to get the OTP.
         * The dialog will call the onSubmit function, which will retry
         * the failed API call, merging in the OTP code.
         */
        const failedAPICall = handler;
        const title = handler.title;

        setState({
          open: true,
          title,
          mobile: error.smsNumber || user.smsNumber,
          onSubmit: (otpBody) => {
            failedAPICall(
              {
                ...otpBody,
                ...body,
              },
              () => closeDialog()
            );
          },
        });
        callback();
      } else {
        callback(errorMessage, data);
      }
    };

    cb.shouldIgnoreError = (error) =>
      formatErrorCode(error, OTP_REQUIRED_ERROR) === OTP_REQUIRED_ERROR;

    handler(body, cb);
  });

  return [
    wrappedHandlers,
    {
      onClose: closeDialog,
      user: `${user.firstName} ${user.lastName}`,
      onSendCode: (body, cb) =>
        entity.registerSMS(body, formatCallbackErrorArg(cb)),
    },
  ];
}
