import React from 'react';
import { oneOf } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { WatchlistStatus } from 'velo-data';
import { VeloStatusIndicator } from '../VeloStatusIndicator';

const WatchlistStatusMap = {
  [WatchlistStatus.NONE]: {
    type: VeloStatusIndicator.types.UNKNOWN,
    label: <FormattedMessage defaultMessage="None" />,
  },
  [WatchlistStatus.REVIEW]: {
    type: VeloStatusIndicator.types.WARNING,
    label: <FormattedMessage defaultMessage="Review" />,
  },
  [WatchlistStatus.PENDING]: {
    type: VeloStatusIndicator.types.PENDING,
    label: <FormattedMessage defaultMessage="Pending" />,
  },
  [WatchlistStatus.FAILED]: {
    type: VeloStatusIndicator.types.FAILED,
    label: <FormattedMessage defaultMessage="Failed" />,
  },
  [WatchlistStatus.PASSED]: {
    type: VeloStatusIndicator.types.SUCCESS,
    label: <FormattedMessage defaultMessage="Passed" />,
  },
};

WatchlistStatusIndicator.propTypes = {
  /**
   * The watchlist status as a string, or empty for unavailable.
   */
  watchlistStatus: oneOf(Object.keys(WatchlistStatusMap)),
};

function WatchlistStatusIndicator({ watchlistStatus, ...props }) {
  return (
    <VeloStatusIndicator
      {...props}
      {...(WatchlistStatusMap[watchlistStatus] || {
        type: VeloStatusIndicator.types.UNKNOWN,
        label: <FormattedMessage defaultMessage="Unavailable" />,
      })}
    />
  );
}

export { WatchlistStatusIndicator };
