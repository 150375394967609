import React from 'react';
import { func, node, bool } from 'prop-types';
import styled from 'astroturf/react';
import { VeloIconButton } from '../VeloIconButton';
import { VeloCard } from '../VeloCard';

const root = 'velo-card-page';

const TestIds = {
  CLOSE_BUTTON: `${root}-close`,
};

VeloCardPage.propTypes = {
  /** Page title */
  title: node.isRequired,
  /** Function to close page */
  onClose: func.isRequired,
  /** Whether to offset the card for a fixed bottom pagination bar */
  fixedBottomPaginationOffset: bool,
};

const Card = styled(VeloCard)`
  @import 'velo-variables';
  @import 'velo-mixins';

  &:global(.mdc-card) {
    padding: 0;
    margin-top: 0;

    @include from-breakpoint(XS) {
      &:not(.slimline) {
        max-width: 74rem;
      }

      /** The divider needs to fullBleed on the card so remove the padding */
      &:not(.hasDivider) {
        padding: 0rem 1rem;
      }
    }
  }

  &.fixedBottomPaginationOffset {
    height: calc(100% - #{$mobile-fixed-bottom-pagination-height});
  }
`;

/** The Card Body needs to add padding that has been prevented by the divider alignment */
const CardPageBody = styled(VeloCard.Body)`
  @import 'velo-mixins';
  @include from-breakpoint(XS) {
    &.followsDivider {
      padding: 0rem 1rem;
    }
  }
`;

Card.propTypes = {
  ...VeloCard.propTypes,
  /** Whether to offset the card for a fixed bottom pagination bar */
  fixedBottomPaginationOffset: bool,
  /** Whether the page is constrained by a broader max width or not */
  slimline: bool,
  /** Whether the page is using a divider or not */
  hasDivider: bool,
};

CardPageBody.propTypes = {
  followsDivider: bool,
};

VeloCardPage.testIds = TestIds;

export function VeloCardPage({
  className,
  children,
  onClose,
  title,
  fixedBottomPaginationOffset = false,
  divider = false,
  slimline = false,
}) {
  return (
    <Card
      fullScreen={true}
      fixedBottomPaginationOffset={fixedBottomPaginationOffset}
      className={className}
      slimline={slimline}
      hasDivider={divider}
    >
      {/* Header */}
      <VeloCard.Header divider={divider}>
        <VeloCard.HeaderTitle>{title}</VeloCard.HeaderTitle>
        <VeloIconButton
          icon="close"
          title="Close"
          onClick={onClose}
          data-testid={TestIds.CLOSE_BUTTON}
        />
      </VeloCard.Header>
      <CardPageBody followsDivider={divider}>
        <VeloCard.Content>{children}</VeloCard.Content>
      </CardPageBody>
    </Card>
  );
}
