import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import {
  usePayoutStatusPoller,
  usePresenter,
  useSerializableMemo,
} from '../../hooks';
import { getPayoutSummaryV3 as getPayoutSummary } from 'velo-api/src/entities/payouts';
import { PayoutProcessing, VeloNotification } from 'velo-react-components';
import { PayoutProcessingPresenter } from './PayoutProcessingPresenter';

const entitySpec = {
  getPayoutSummary: getPayoutSummary,
};
const fixedProps = {
  failureProcessing: VeloNotification.types.PAYOUT_PROCESSING_FAILURE,
};

function PayoutProcessingRoute() {
  const { payoutId } = useParams();
  const {
    getPayoutSummary,
    goToPayoutReview,
    goToPayoutDetail,
    exitProcessing,
  } = usePresenter(
    PayoutProcessingPresenter,
    entitySpec,
    fixedProps,
    useSerializableMemo({ payoutId })
  );

  // use a hook to poll the state
  const { payout, isComplete, isAborted, error } = usePayoutStatusPoller(
    payoutId,
    getPayoutSummary
  );

  const progressValue = Number(
    payout.paymentsSubmitted
      ? (
          (payout.paymentsAccepted + payout.paymentsRejected) /
          payout.paymentsSubmitted
        ).toPrecision(1)
      : 0
  );

  const getProgressHeaderProps = (payout) => {
    if (payout && payout.status) {
      return {
        showProgressBar: true,
        isSubmitted: payout.paymentsSubmitted,
        title: payout.paymentsSubmitted
          ? PayoutProcessing.titles.COMPLETE
          : PayoutProcessing.titles.IN_PROGRESS,
      };
    }
    // loading no bar required/desired
    return {
      showProgressBar: false,
    };
  };
  //calculate progress from PROPS
  const props = {
    progressProps: {
      progress: progressValue,
      label: payout.paymentsSubmitted
        ? `${payout.paymentsAccepted + payout.paymentsRejected} of ${
            payout.paymentsSubmitted
          }`
        : '',
      ...getProgressHeaderProps(payout),
    },
    onClose: () => exitProcessing(),
  };

  useEffect(() => {
    if (isAborted) {
      goToPayoutDetail();
    } else if (isComplete) {
      goToPayoutReview();
    } else if (error) {
      exitProcessing(error);
    }
  }, [
    isComplete,
    isAborted,
    error,
    goToPayoutDetail,
    goToPayoutReview,
    exitProcessing,
    payoutId,
  ]);

  return <PayoutProcessing {...props} />;
}

export { PayoutProcessingRoute };
