import { useReducer, useEffect, useCallback, useRef, useState } from 'react';

const ActionTypes = {
  IN_ERROR: 'IN_ERROR',
  PROCESSING: 'PROCESSING',
};

const reducer = (state, action) => {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case ActionTypes.IN_ERROR:
      /** reset state and add error */
      return { ...state, error: action.payload, isComplete: true };
    case ActionTypes.PROCESSING:
      return {
        ...state,
        getStatusRetryInc: state.getStatusRetryInc + 1,
        batch: action.payload,
      };
    // There is no default case on the switch above as it cannot be tested
    // and falling out of this function is harmless.
  }
};
const initialState = {
  //Increment to poll
  getStatusRetryInc: 0,
  //ID of the submitted batch
  batchId: null,
  // Any error during submission/progress
  error: null,
  // API response
  batch: {
    failedCount: 0,
    failures: [],
    pendingCount: 0,
  },
  // Finished
  isComplete: false,
};

function usePayeeUploadStatusPoller(
  batchId,
  batchCount,
  getBatchStatus,
  onComplete,
  debounceTimeinMs = 200
) {
  const timeout = useRef(null);
  const [total, setTotal] = useState(0);

  /** Initialize state */
  const [state, dispatch] = useReducer(reducer, initialState, () => {
    return { ...initialState, batchId: batchId };
  });

  const fetchBatchStatus = useCallback(
    (batchId) => {
      getBatchStatus(batchId, (error, response) => {
        if (error) {
          dispatch({ type: ActionTypes.IN_ERROR, payload: error });
        } else {
          if (total === 0) {
            setTotal(response.pendingCount);
          }

          if (response.pendingCount === 0 && response.status === 'ACCEPTED') {
            onComplete(response, batchCount);
            clearTimeout(timeout.current);
          } else {
            //update state
            dispatch({
              type: ActionTypes.PROCESSING,
              payload: response,
            });
          }
        }
      });
    },
    [getBatchStatus, total, onComplete, batchCount]
  );

  // Trigger a poll on new batchId or getStatusRetryInc
  useEffect(() => {
    if (state.batchId && !state.isComplete) {
      clearTimeout(timeout);
      timeout.current = setTimeout(() => {
        fetchBatchStatus(state.batchId);
      }, debounceTimeinMs);
    } else {
      clearTimeout(timeout.current);
    }

    return () => {
      clearTimeout(timeout.current);
    };
  }, [
    state.getStatusRetryInc,
    debounceTimeinMs,
    state.isComplete,
    state.batchId,
    fetchBatchStatus,
  ]);
  const { error, batch } = state;
  return { batch, error };
}

export { usePayeeUploadStatusPoller };
