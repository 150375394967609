export const paymentChannelFields = [
  'paymentCountry',
  'currency',
  'accountName',
  'accountNumber',
  'routingNumber',
  'iban',
];

export function selectPayeePaymentChannel(data) {
  return {
    ...paymentChannelFields.reduce(
      (acc, cur) => ({ ...acc, [cur]: data[cur] }),
      {}
    ),
    paymentCountry: data.countryCode,
  };
}
