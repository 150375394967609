import React from 'react';
import { bool, string, shape } from 'prop-types';
import styled from 'astroturf/react';
import { VeloTypography } from '../../VeloTypography';
import { NavigationDrawerItem } from '../NavigationDrawerItem';
import navigationDrawerPropTypes from '../navigationDrawerPropTypes';

const OperatingPayor = styled(VeloTypography)`
  @import 'velo-variables';
  @import '../navigationDrawer.variables';

  /* static width to allow for animation effect on collapse / expand */
  $width: $navigation-list-width;

  margin: 0.5rem 1rem;
  display: flex;
  flex-direction: row;
  opacity: 1;
  min-width: $width;
  transition: $opening-transition-fadein;
  transition-delay: $animation-duration;
  color: velo-color('token-color-text-default');

  &.highlight {
    background-color: velo-color('token-color-system-warning-lighter');
    font-weight: bold;
    flex: 1;
    align-self: center;
  }

  &.hidden {
    opacity: 0;
    transition: $opening-transition-fadein;
    transition-delay: 0ms;
  }
`;

OperatingPayor.propTypes = {
  highlight: bool,
  hidden: bool,
};

const Name = styled('div')`
  flex: 1;
  align-self: center;
  padding: 0.75rem 1rem;
  user-select: none;
`;

NavigationDrawerOperatingAsItem.propTypes = {
  /** Render if open. */
  isNavigationDrawerOpen: bool,
  /** the item Id which is currently selected */
  selectedItemId: string,
  /** the operating as user details */
  operatingAs: shape({
    /** whether the current operate as payor is the logged in payor */
    isLoggedInPayor: bool,
    /** the operating as payor id */
    payorId: string,
    /** the operating payor name */
    payorName: string,
  }),
  /** the item to be rendered */
  item: navigationDrawerPropTypes.navigationItem,
};

function NavigationDrawerOperatingAsItem({
  isNavigationDrawerOpen,
  selectedItemId,
  operatingAs,
  item,
  hideTooltip,
  onClick,
}) {
  const showIndicator = !operatingAs.isLoggedInPayor && !isNavigationDrawerOpen;
  return (
    <>
      <NavigationDrawerItem
        item={item}
        isNavigationDrawerOpen={isNavigationDrawerOpen}
        isSelectedItem={item.id === selectedItemId}
        notificationIcon={showIndicator ? 'warning' : ''}
        rightAlignedAction={{
          icon: 'more_vert',
        }}
        hideTooltip={hideTooltip}
        onClick={onClick}
      />
      {operatingAs && (
        <OperatingPayor
          tag="div"
          highlight={!operatingAs.isLoggedInPayor}
          hidden={!isNavigationDrawerOpen}
        >
          {operatingAs.payorName && <Name>{operatingAs.payorName}</Name>}
        </OperatingPayor>
      )}
    </>
  );
}
export { NavigationDrawerOperatingAsItem };
