import { TRANSMISSION_TYPES } from 'velo-data';

// Helper mapped transmissionTypes with intl Formatted Message
const transmissionTypes = (intl) => ({
  [TRANSMISSION_TYPES.ACH]: {
    label: intl.formatMessage({ defaultMessage: 'ACH' }),
    value: TRANSMISSION_TYPES.ACH,
  },
  [TRANSMISSION_TYPES.WIRE]: {
    label: intl.formatMessage({ defaultMessage: 'Wire' }),
    value: TRANSMISSION_TYPES.WIRE,
  },
  [TRANSMISSION_TYPES.SAME_DAY_ACH]: {
    label: intl.formatMessage({ defaultMessage: 'Same-day ACH' }),
    value: TRANSMISSION_TYPES.SAME_DAY_ACH,
  },
  [TRANSMISSION_TYPES.LOCAL]: {
    label: intl.formatMessage({ defaultMessage: 'Local' }),
    value: TRANSMISSION_TYPES.LOCAL,
  },
  [TRANSMISSION_TYPES.DEFAULT]: {
    label: intl.formatMessage({ defaultMessage: 'Use default' }),
    value: TRANSMISSION_TYPES.DEFAULT,
  },
  [TRANSMISSION_TYPES.GACHO]: {
    label: intl.formatMessage({ defaultMessage: 'GACHO' }),
    value: TRANSMISSION_TYPES.GACHO,
  },
  [TRANSMISSION_TYPES.PRIORITY]: {
    label: intl.formatMessage({ defaultMessage: 'Priority' }),
    value: TRANSMISSION_TYPES.PRIORITY,
  },
  [TRANSMISSION_TYPES.STANDARD]: {
    label: intl.formatMessage({ defaultMessage: 'Standard' }),
    value: TRANSMISSION_TYPES.STANDARD,
  },
});

// Get transmission types for use in Filters - omits the default Type
const transmissionTypeFilterOptions = (intl) => {
  const types = transmissionTypes(intl);
  return Object.keys(types)
    .filter((key) => key !== TRANSMISSION_TYPES.DEFAULT)
    .map((key) => types[key]);
};

// Types always available to a Payor
const defaultTransmissionTypes = {
  [TRANSMISSION_TYPES.DEFAULT]: true,
};
// Get Payout options for those opted in by the Payor
//- includes default available Types and Default Value
// default param for error/empty scenarios
const transmissionPayoutOptions = (
  availableTransmissionTypes = defaultTransmissionTypes,
  intl
) => {
  const types = transmissionTypes(intl);

  const transTypes = {
    ...defaultTransmissionTypes,
    ...availableTransmissionTypes,
  };

  return Object.keys(transTypes)
    .filter((key) => transTypes[key])
    .map((key) => types[key] || { label: key, value: key });
};

// Get a single formatted string for label use only
const getTransmissionTypeLabel = (key, intl) => {
  const types = transmissionTypes(intl);

  return types[key] ? types[key].label : key;
};

const getDefaultTransmissionFilterOption = (intl) => {
  return transmissionTypes(intl)[TRANSMISSION_TYPES.DEFAULT];
};

export {
  transmissionTypeFilterOptions,
  getDefaultTransmissionFilterOption,
  transmissionPayoutOptions,
  getTransmissionTypeLabel,
};
