import React, { useState, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { VeloTypography } from '../VeloTypography';
import { VeloFieldGrid } from '../VeloFieldGrid';
import { VeloTextField } from '../VeloTextField/VeloTextField';
import { Currency } from '../FormFields/FormFieldTypes';
import { VeloCardForm } from '../VeloCardForm';
import { ConfirmAutoTopUp } from '../ConfirmAutoTopUp';
import { majorToMinorUnitMoney, minorToMajorUnitMoney } from 'velo-data';
import { SourceAccountFormFields } from '../SourceAccountFormFields';
import { useFieldGridForm } from '../useFieldGridForm';
import { VeloGridLoading } from '../VeloGridLoading';
import { func } from 'prop-types';
import { LookupTextField } from '../LookupTextField';

const root = 'source-account-auto-topup';

const TestIds = {
  ...VeloCardForm.testIds,
  TOGGLE: `${root}-toggle`,
  TOGGLE_VALUE: `${root}-toggle-value`,
  MIN_BALANCE_FIELD: `${root}-min-balance-field`,
  TARGET_BALANCE_FIELD: `${root}-target-balance-field`,
  CONFIRM_DIALOG: ConfirmAutoTopUp.testIds.DIALOG,
  CONFIRM_DIALOG_CANCEL: ConfirmAutoTopUp.testIds.CANCEL,
  CONFIRM_DIALOG_ACCEPT: ConfirmAutoTopUp.testIds.CONFIRM_AUTO_TOPUP,
  ACTION_BUTTON: `${root}-action-button`,
};

function BalanceField(props) {
  return (
    <>
      <VeloTextField {...props} />
      <VeloTypography use="footnote">
        <p>
          <FormattedMessage
            defaultMessage="When enabled, if the source account balance falls below the minimum
          balance it will automatically trigger an Auto Topup pull from the
          connected funding account."
          />
        </p>
        <p>
          <FormattedMessage
            defaultMessage="ACH requests typically will take 3-5 business days but may vary by
          bank and processing windows."
          />
        </p>
      </VeloTypography>
    </>
  );
}
const Labels = {
  TITLE: <FormattedMessage defaultMessage="Auto Topup" />,
  FORM_HEADING: <FormattedMessage defaultMessage="Auto Topup From" />,
  ENABLED_TOGGLE: <FormattedMessage defaultMessage="Auto topup" />,
  MIN_BALANCE: <FormattedMessage defaultMessage="Minimum balance" />,
  TARGET_BALANCE: <FormattedMessage defaultMessage="Target balance" />,
  FUNDING_ACCOUNT_SELECT: <FormattedMessage defaultMessage="Funding account" />,
  SUBMITTING_BUTTON: <FormattedMessage defaultMessage="Saving..." />,
  SUBMIT_BUTTON: <FormattedMessage defaultMessage="Save" />,
};

const headingProps = { compact: true };

function Loading({ onClose }) {
  return (
    <VeloCardForm.Loading slimline onClose={onClose} title={Labels.TITLE}>
      <VeloGridLoading
        headingProps={headingProps}
        sections={[
          {
            heading: true,
            fields: [
              {
                type: VeloGridLoading.fieldTypes.LabelledItem,
              },
              {
                type: VeloGridLoading.fieldTypes.LabelledItem,
              },
              {
                type: VeloGridLoading.fieldTypes.LabelledItem,
              },
            ],
          },
          {
            heading: true,
            fields: [
              {
                type: VeloGridLoading.fieldTypes.LabelledItem,
              },
              {
                type: VeloGridLoading.fieldTypes.TextField,
              },
              {
                type: VeloGridLoading.fieldTypes.TextField,
              },
            ],
          },
        ]}
      />
    </VeloCardForm.Loading>
  );
}

const config = {
  createSections({ currency, enabled }, { fetchResults, data }) {
    const normalisedData = { ...data, sourceAccountName: data.name };
    return [
      SourceAccountFormFields.createSourceAccountGridSection(
        false,
        normalisedData
      ),
      {
        heading: Labels.FORM_HEADING,
        fields: [
          SourceAccountFormFields.createLabelledSwitchGridField(false, {
            name: 'enabled',
            label: Labels.ENABLED_TOGGLE,
            'data-testid': TestIds.TOGGLE,
          }),
          {
            Component: LookupTextField,
            disabled: !enabled,
            entityId: '',
            fetchOnEmpty: true,
            fetchResults,
            label: Labels.FUNDING_ACCOUNT_SELECT,
            mode: LookupTextField.modes.FUNDING_ACCOUNT,
            name: 'fundingAccount',
            required: enabled,
          },
          {
            ...Currency(currency, Labels.MIN_BALANCE),
            disabled: !enabled,
            autoComplete: 'off',
            required: enabled,
            name: 'minBalance',
            'data-testid': TestIds.MIN_BALANCE_FIELD,
          },
          {
            name: 'targetBalance',
            ...Currency(currency, Labels.TARGET_BALANCE, true),
            Component: BalanceField,
            disabled: !enabled,
            required: enabled,
            autoComplete: 'off',
            'data-testid': TestIds.TARGET_BALANCE_FIELD,
          },
        ],
      },
    ];
  },
  getInitialValues({ autoTopUpFundingAccount, data }) {
    const { currency, autoTopUpConfig } = data;
    const {
      enabled,
      minBalance = 0,
      targetBalance = 0,
      fundingAccountId,
    } = autoTopUpConfig;

    const balances = {
      minBalance: minorToMajorUnitMoney(minBalance, currency),
      targetBalance: minorToMajorUnitMoney(targetBalance, currency),
    };

    return {
      enabled,
      currency,
      fundingAccount: autoTopUpFundingAccount.name,
      fundingAccountId,
      minBalance: balances.minBalance ? balances.minBalance.toString(10) : '',
      targetBalance: balances.targetBalance
        ? balances.targetBalance.toString(10)
        : '',
    };
  },
  formatBody({
    fundingAccountId,
    enabled,
    minBalance,
    currency,
    targetBalance,
  }) {
    return {
      fundingAccountId,
      enabled,
      minBalance: majorToMinorUnitMoney(minBalance, currency),
      targetBalance: majorToMinorUnitMoney(targetBalance, currency),
    };
  },
  getButtonProps(submitting) {
    return {
      children: submitting ? Labels.SUBMITTING_BUTTON : Labels.SUBMIT_BUTTON,
      'data-testid': TestIds.ACTION_BUTTON,
    };
  },
};

const defaultDialogProps = {
  open: false,
  currency: 'USD',
  minBalance: 0,
  currentBalance: 0,
};

function addConfirmationDialog(
  { formatBody, ...config },
  { onSubmit, ...props },
  setDialogProps
) {
  const { balance: currentBalance } = props.data;
  return [
    {
      ...config,
      formatBody: (x) => x,
    },
    {
      ...props,
      onSubmit(body, cb) {
        const { enabled, currency } = body;
        const minBalance = majorToMinorUnitMoney(body.minBalance, currency);
        if (enabled && minBalance > currentBalance) {
          setDialogProps({
            open: true,
            onClose({ detail }) {
              setDialogProps(defaultDialogProps);
              if (detail.action === 'accept') {
                onSubmit(formatBody(body), cb);
              } else {
                cb(undefined, undefined);
              }
            },
            minBalance,
            currentBalance,
            currency,
          });
        } else {
          onSubmit(formatBody(body), cb);
        }
      },
    },
  ];
}

SourceAccountAutoTopupForm.Loading = Loading;
SourceAccountAutoTopupForm.labels = Labels;

SourceAccountAutoTopupForm.propTypes = {
  fetchResults: func.isRequired,
  onClose: func.isRequired,
  onSubmit: func.isRequired,
};

function SourceAccountAutoTopupForm(props) {
  const [dialogProps, setDialogProps] = useState(defaultDialogProps);
  const [gridProps, formProps] = useFieldGridForm(
    ...useMemo(
      () => addConfirmationDialog(config, props, setDialogProps),
      [setDialogProps, props]
    )
  );

  return (
    <VeloCardForm slimline {...formProps} title={Labels.TITLE}>
      <VeloFieldGrid headingProps={headingProps} {...gridProps} />
      <ConfirmAutoTopUp {...dialogProps} />
    </VeloCardForm>
  );
}

SourceAccountAutoTopupForm.testIds = TestIds;

export { SourceAccountAutoTopupForm };
