import React, { useState, useContext, useLayoutEffect, useMemo } from 'react';
import { bool, node, number, string } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import { VeloTypography } from '../VeloTypography';
import { VeloLinearProgress } from '../VeloLinearProgress';
import { ViewHeight } from '../ViewHeight';
import { VeloButton } from '../VeloButton';
import { VeloSkeleton } from '../VeloSkeleton';
import { useScrollToTop } from '../hooks';
import { VeloThemeContext } from '../VeloThemeContext';

import styles from './OnboardingPage.module.scss';

const root = 'onboarding';

const TestIds = {
  TITLE: `${root}-title`,
  SUBTITLE: `${root}-subtitle`,
  CONTINUE: `${root}-continue-button`,
  PROGRESS: `${root}-progress`,
  PROGRESS_LABEL: `${root}-progress-label`,
  HEADER: `${root}-header`,
};

const titleProps = {
  /** Render as a skeleton */
  loading: bool,
  /** The title to render. */
  children: node,
};

/**
 * The main title on an onboarding page.
 */
const OnboardingPageTitle = React.forwardRef(
  ({ children, loading, ...other }, ref) => (
    <VeloTypography
      use="secondaryHeader"
      tag="h1"
      className={styles.title}
      ref={ref}
      data-testid={TestIds.TITLE}
      {...other}
    >
      {loading ? <VeloSkeleton className={styles.titleLoading} /> : children}
    </VeloTypography>
  )
);

OnboardingPageTitle.propTypes = titleProps;

/**
 * The sub-title on an onboarding page.
 */
function OnboardingPageSubtitle({ className, children, loading, ...other }) {
  return (
    <VeloTypography
      className={classnames(styles.subtitle, className)}
      tag="div"
      use="bodyText"
      data-testid={TestIds.SUBTITLE}
      {...other}
    >
      {loading ? <VeloSkeleton className={styles.subtitleLoading} /> : children}
    </VeloTypography>
  );
}

OnboardingPageSubtitle.propTypes = titleProps;

const footerProps = {
  /** Custom CSS classes. */
  className: string,
};

/**
 * A container used to wrap a footer component such as
 * the `OnboardingPageButton` to ensure consistent
 * padding.
 */
const OnboardingFooter = ({ className, ...other }) => (
  <div className={classnames(styles.footer, className)} {...other} />
);

OnboardingFooter.propTypes = footerProps;

/**
 * The "Continue" button usually found in the footer of
 * an onboarding page.
 */
const OnboardingPageButton = ({ className, children, ...other }) => (
  <VeloButton.Primary
    className={classnames(styles.button, className)}
    data-testid={TestIds.CONTINUE}
    {...other}
  >
    {children || <FormattedMessage defaultMessage="Continue" />}
  </VeloButton.Primary>
);

/**
 * Used to surface a submit error to the user.
 */
const OnboardingPageSubmitError = React.forwardRef(
  ({ className, ...other }, ref) => (
    <div className={classnames(styles.submitError, className)} ref={ref}>
      <VeloTypography
        use="bodyText"
        tag="div"
        className={styles.submitErrorMessage}
        {...other}
      />
    </div>
  )
);

/**
 * Progress bar with label.
 */
const ProgressBar = ({ steps, activeStep, showAllProgressSteps }) => {
  const showProgress = steps && steps > 0;
  const progress = showProgress ? (1 / steps) * (activeStep + 1) : 0;
  return (
    <div className={styles.progress}>
      {(showAllProgressSteps || showProgress) && (
        <div className={styles.progressBar}>
          <VeloLinearProgress
            data-testid={TestIds.PROGRESS}
            progress={progress}
          />
          <VeloTypography
            className={styles.progressBarLabel}
            tag="span"
            use="bodyTextSmaller"
            data-testid={TestIds.PROGRESS_LABEL}
          >
            <FormattedMessage
              defaultMessage="Step {active} of {steps}"
              values={{ active: activeStep + 1, steps }}
            />
          </VeloTypography>
        </div>
      )}
    </div>
  );
};

const OnboardingPageContext = React.createContext({});
const OnboardingPageProvider = OnboardingPageContext.Provider;

/**
 * Hook used to hide the main header on mount and show it
 * again on unmount.
 *
 * On desktop we only hide the progress bar and leave the
 * Velo logo intact.
 *
 * On mobile we hide the entire header.
 */
const useHiddenOnboardingHeader = () => {
  const { setShowHeader } = useContext(OnboardingPageContext);
  // useLayoutEffect avoids a brief flash of the header
  // before the CSS class is applied on iOS.
  useLayoutEffect(() => {
    setShowHeader(false);
    return () => {
      setShowHeader(true);
    };
  }, [setShowHeader]);
};

const useOnboardingContext = () => useContext(OnboardingPageContext);

OnboardingPage.propTypes = {
  /** The number of steps. */
  steps: number,
  /** The currently active step. */
  activeStep: number,
};

OnboardingPage.testIds = TestIds;

/**
 * Onboarding Page container component.
 * Use this to wrap content to be displayed during the onboarding process.
 */
function OnboardingPage({
  steps,
  activeStep,
  children,
  showAllProgressSteps = false,
  ...other
}) {
  const body = useScrollToTop(activeStep);
  const [showHeader, setShowHeader] = useState(true);
  // Memoise the context value to avoid unnecessary rerenders
  const value = useMemo(
    () => ({ setShowHeader, showHeader }),
    [setShowHeader, showHeader]
  );

  const { logoUrl } = VeloThemeContext.useBrandingTokens();

  return (
    <ViewHeight className={styles.container} {...other}>
      {/* Header */}
      <div
        className={classnames(styles.header, {
          [styles.headerHidden]: !showHeader,
        })}
        data-testid={TestIds.HEADER}
      >
        <img
          alt="Logo"
          className={styles.logo}
          aria-hidden="true"
          src={logoUrl}
        />
        <ProgressBar
          steps={steps}
          activeStep={activeStep}
          showAllProgressSteps={showAllProgressSteps}
        />
        <div className={styles.spacer} />
      </div>

      {/* Body */}
      <div className={styles.body} ref={body}>
        {/* Wrap with a context provider allowing a custom header class to be applied. */}
        <OnboardingPageContext.Provider value={value}>
          <div className={styles.content}>{children}</div>
        </OnboardingPageContext.Provider>
      </div>
    </ViewHeight>
  );
}

OnboardingPage.Title = OnboardingPageTitle;
OnboardingPage.Subtitle = OnboardingPageSubtitle;
OnboardingPage.Footer = OnboardingFooter;
OnboardingPage.Button = OnboardingPageButton;
OnboardingPage.SubmitError = OnboardingPageSubmitError;
OnboardingPage.Provider = OnboardingPageProvider;
OnboardingPage.useHiddenOnboardingHeader = useHiddenOnboardingHeader;
OnboardingPage.useContext = useOnboardingContext;

export { OnboardingPage };
