//convert UPPER NAME to Upper Name, or UPPER-NAME to Upper-Name
export const toProperCase = (str) => {
  if (!str) {
    return '';
  }
  return str.toLowerCase().replace(/\b\w/g, (m) => m.toUpperCase());
};

// Strip everything out of a string apart from numbers and decimal points.
export const stripNonNumeric = (value) => value.replace(/[^0-9.]/g, '');

// Convert a string (potentially containing non-numeric characters) to a number
export const toNumber = (value) => parseFloat(stripNonNumeric(value));

export function maskStart(str, trailLength, maskChar) {
  var len = str.length;
  if (len <= trailLength) {
    return maskChar.repeat(len);
  }
  const sliceOffset = len > trailLength * 2 ? -trailLength : trailLength - len;
  return str.slice(sliceOffset).padStart(len, maskChar);
}
