import { createTagsRoute } from '../../containers';
import { getUserTags, unlinkUserTag } from 'velo-api/src/entities/users';

export const PayorUsersTagsRoute = createTagsRoute(
  {
    getTags: getUserTags,
    removeTag: unlinkUserTag,
  },
  (wireframe, { entityId, payorId }) =>
    wireframe.navigateToUserAddTags({
      entityId,
      payorId,
    })
);
