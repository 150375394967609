import React, {
  useEffect,
  useState,
  useMemo,
  useContext,
  createContext,
} from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { arrayOf, func, node, number, object } from 'prop-types';
import styled from 'astroturf/react';
import compare from 'just-compare';
import { VeloCardForm } from '../VeloCardForm';
import { VeloTab } from '../VeloTab';
import { VeloDivider } from '../VeloDivider';
import { populateSectionsFrom, onChange } from '../useFieldGridForm';
import { VeloCard } from '../VeloCard';
import { VeloOverlay } from '../VeloOverlay';
import { VeloErrorCard } from '../VeloErrorCard';
import { VeloIconButton } from '../VeloIconButton';
import { ConfirmationDialog } from '../ConfirmationDialog';

const root = 'velo-tabbed-view';

const TestIds = {
  ACTION_BUTTON: `${root}-action-button`,
  OVERLAY: `${root}-overlay`,
  DISCARD_CHANGES_DIALOG: `${root}-discard-changes`,
  TAB: `${root}-tab`,
  CLOSE_BUTTON: `${root}-close-button`,
  ...ConfirmationDialog.testIds,
};

const Tab = styled(VeloTab)`
  text-transform: none;
  flex: 0 1 auto;
`;

const Error = styled(VeloCardForm.SubmitError)`
  margin: 0rem 1.5rem 1rem 1.5rem;
`;

const Context = createContext({});
const Provider = Context.Provider;

/**
 * Hook used to manage form state and `VeloFieldGrid` props.
 * Passed a config object that matches `useFieldGridForm` and
 * props.
 */
function useForm(
  { getInitialValues, createSections, formatBody, getButtonProps },
  { onSubmit, ...props }
) {
  const intl = useIntl();
  const values = getInitialValues(props);
  const [body, setBody] = useState(values);
  const [error, setError] = useState(undefined);
  const { submitting, setSubmitting, dirty, setDirty } = useContext(Context);

  // Create the sections
  const setFormField = (values) => setBody({ ...body, ...values });
  const sections = createSections(body, setFormField, props, intl);

  // Keep the dirty flag up-to-date when the body changes
  useEffect(
    () =>
      void setDirty(
        Object.entries(values).some(([k, v]) => !compare(v, body[k]))
      ),
    [body, values, setDirty]
  );

  return [
    // Form props
    {
      onSubmit: (event) => {
        event.preventDefault();
        setError(undefined);
        setSubmitting(true);
        onSubmit(formatBody(body), (error) => {
          setError(error);
          setSubmitting(false);
        });
      },
      buttonProps: {
        disabled: submitting || !dirty,
        ...getButtonProps(submitting, body, dirty, values),
      },
      error,
    },
    // Grid props
    {
      sections: sections.map(populateSectionsFrom(intl, body)),
      onChange: (event) => {
        // When a component changes we merge the new value
        // into the existing body.
        onChange(event, setFormField);
      },
    },
    body,
  ];
}

VeloTabbedViewForm.propTypes = {
  // Called when the form is submitted. Passed the form body and an error-first callback.
  onSubmit: func.isRequired,
  // Props to spread to the Submit button.
  buttonProps: object.isRequired,
  // A submission error.
  error: node,
};

/**
 * Used to render a form as a tab page. Includes a Submit button
 * and error handling.
 */
function VeloTabbedViewForm({ error, children, buttonProps, onSubmit }) {
  return (
    <form onSubmit={onSubmit}>
      {children}
      {error && <Error error={error} />}
      <VeloCard.Action>
        <VeloCard.Button {...buttonProps} data-testid={TestIds.ACTION_BUTTON} />
      </VeloCard.Action>
    </form>
  );
}

/**
 * Used in conjunction with `VeloGridLoading` to render
 * a `VeloTabbedViewForm` in the loading state.
 */
function VeloTabbedViewFormLoading({ children }) {
  return (
    <>
      {children}
      <VeloCard.Action>
        <VeloCard.Button skeleton data-testid={TestIds.ACTION_BUTTON} />
      </VeloCard.Action>
    </>
  );
}

VeloTabbedViewForm.Loading = VeloTabbedViewFormLoading;

VeloTabbedViewError.propTypes = {
  // The error to display.
  error: node.isRequired,
};

/**
 * Used to display loading errors, etc.
 */
function VeloTabbedViewError({ error }) {
  return (
    <VeloErrorCard.Content>
      <VeloErrorCard.Title>
        <FormattedMessage defaultMessage="An error occurred" />
      </VeloErrorCard.Title>
      <VeloErrorCard.Message>{error}</VeloErrorCard.Message>
    </VeloErrorCard.Content>
  );
}

VeloTabbedView.propTypes = {
  // The card title.
  title: node.isRequired,
  // The tabs labels
  tabs: arrayOf(node).isRequired,
  // The currently active tab
  activeTabIndex: number.isRequired,
  // Called when the tab is changed. Passed the new active tab index.
  onActivate: func.isRequired,
  // Called when the close button is clicked
  onClose: func.isRequired,
};

const DiscardType = {
  SWITCH_TAB: 'switch-tab',
  CLOSE: 'close',
};

/**
 * Render a `VeloCard` containing tabs.
 */
function VeloTabbedView({
  title,
  tabs,
  activeTabIndex,
  onClose,
  onActivate,
  children,
}) {
  useEffect(() => {
    document.documentElement.scrollIntoView();
  }, []);
  const [submitting, setSubmitting] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [discardType, setDiscardType] = useState(undefined);
  const [nextActiveTabIndex, setNextActiveTabIndex] = useState();
  const [tabKey, setTabKey] = useState(Date.now().toString(10));
  const context = useMemo(
    () => ({
      submitting,
      setSubmitting,
      dirty,
      setDirty,
    }),
    [submitting, dirty]
  );
  const discardHandlers = useMemo(
    () => ({
      [DiscardType.SWITCH_TAB]: () => {
        // Switch tabs
        setDirty(false);
        onActivate(nextActiveTabIndex);
      },
      [DiscardType.CLOSE]: onClose,
    }),
    [nextActiveTabIndex, onClose, onActivate]
  );

  return (
    <VeloCard>
      {/* The card header. */}
      <VeloCard.Header divider={false}>
        <VeloCard.HeaderTitle>{title}</VeloCard.HeaderTitle>
        <VeloIconButton
          icon="close"
          title="Close"
          onClick={() => {
            // Show the Discard changes dialog?
            if (dirty) {
              setDiscardType(DiscardType.CLOSE);
            } else {
              onClose();
            }
          }}
          data-testid={TestIds.CLOSE_BUTTON}
        />
      </VeloCard.Header>

      {/* The tabs. */}
      <VeloTab.Bar
        key={tabKey}
        activeTabIndex={activeTabIndex}
        onActivate={(event) => {
          // Is the content dirty?
          if (dirty) {
            setDiscardType(DiscardType.SWITCH_TAB);
            setNextActiveTabIndex(event.detail.index);
          } else {
            onActivate(event.detail.index);
          }
        }}
      >
        {tabs.map((label, index) => {
          // An ID is required for testing
          const id = `${TestIds.TAB}-${index + 1}`;
          return (
            <Tab key={index} id={id} data-testid={id}>
              {label}
            </Tab>
          );
        })}
      </VeloTab.Bar>
      <VeloDivider />

      {/* The currently active tab. */}
      <VeloCard.Body>
        <Provider value={context}>
          <React.Fragment key={activeTabIndex}>{children}</React.Fragment>
        </Provider>
      </VeloCard.Body>

      {/* Overlay used when submitting a form. */}
      <VeloOverlay show={submitting} data-testid={TestIds.OVERLAY} />

      {/* Discard changes? dialog */}
      <ConfirmationDialog
        open={discardType !== undefined}
        dialogType={ConfirmationDialog.dialogTypes.DiscardChangesType}
        data-testid={TestIds.DISCARD_CHANGES_DIALOG}
        onClose={(event) => {
          if (event.detail.action === 'accept') {
            discardHandlers[discardType]();
          } else {
            // By forcing a new key we ensure that
            // the tab renders with the correct
            // focus state.
            setTabKey(Date.now().toString(10));
          }
          setDiscardType(undefined);
        }}
      />
    </VeloCard>
  );
}

VeloTabbedView.Error = VeloTabbedViewError;
VeloTabbedView.Form = VeloTabbedViewForm;
VeloTabbedView.Context = Context;
VeloTabbedView.useForm = useForm;
VeloTabbedView.testIds = TestIds;

export { VeloTabbedView };
