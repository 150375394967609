import React from 'react';
import { func, object, string } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { RailsId } from 'velo-data';
import { Content } from '../Content';
import { PayoutHeader } from '../PayoutHeader';
import { PayoutInstructCard } from '../PayoutInstructCard';
import { PayoutReviewPayments } from '../PayoutReviewPayments';
import { PayoutDisclaimer } from './PayoutDisclaimer';
import { PayoutPageLoading } from '../PayoutPageLoading/PayoutPageLoading';
import { Filter } from '../Filter';
import { Pagination } from '../Pagination';
import { VeloTypography } from '../VeloTypography';
import { useIsBelowBreakpoint } from '../hooks/useIsBelowBreakpoint';

/** PayoutPageError:
 * Page with Data List in Error (All Payments Rejected), and Details of Submission state of Payout
 * */
PayoutPageError.propTypes = {
  /** Function to close page */
  onClose: func.isRequired,
  /** Object containing rejected payment details */
  rejectedPaymentErrors: object.isRequired,
  /** Error to display in place of the table */
  error: string.isRequired,
};

function Table({ children }) {
  return (
    <PayoutReviewPayments.Table>
      <PayoutReviewPayments.Headings />
      {children}
    </PayoutReviewPayments.Table>
  );
}
function PayoutPageError({
  onClose,
  rejectedPaymentErrors,
  error,
  actions,
  ...data
}) {
  return (
    <PayoutInstructCard onClose={onClose}>
      <PayoutHeader.InstructError
        actions={actions}
        rejectedPaymentErrors={rejectedPaymentErrors}
        {...data}
      />
      <Table>
        <PayoutReviewPayments.Error error={error} />
      </Table>
    </PayoutInstructCard>
  );
}

/**
 * PayoutPageData:
 * Page with Data List and Details of Submission state of Payout
 * */
PayoutPageData.propTypes = {
  /** Function to close page */
  onClose: func.isRequired,
  /** Object containing rejected payment details */
  rejectedPaymentErrors: object.isRequired,
  /** Object containing actions for payout */
  actions: object.isRequired,
};

function PayoutPageData({
  onClose,
  onClick,
  actions,
  rejectedPaymentErrors,
  content,
  pageProps,
  filterProps,
  children,
  ...props
}) {
  const wubsAustraliaPayout = !!content.find(({ railsId, currency }) => {
    return (
      (railsId === RailsId.WU || railsId === RailsId.WUBS_DECOUPLED) &&
      currency === 'AUD'
    );
  });
  const isMobile = useIsBelowBreakpoint(useIsBelowBreakpoint.breakpoints.XS);

  return (
    <PayoutInstructCard
      onClose={onClose}
      fixedBottomPaginationOffset={Boolean(pageProps) && isMobile}
    >
      <PayoutHeader.Instruct
        actions={actions}
        rejectedPaymentErrors={rejectedPaymentErrors}
        {...props}
      />
      <Filter {...filterProps} compact={true} />
      <Table>
        {children || (
          <PayoutReviewPayments.Data data={content} onClick={onClick} />
        )}
      </Table>
      {pageProps && <Pagination {...pageProps} />}
      <PayoutDisclaimer />
      {wubsAustraliaPayout && (
        <VeloTypography use="footnote" tag="p">
          <FormattedMessage defaultMessage="In Australia, remittance services are offered to you by Western Union Business Solutions (Australia) Pty Ltd ABN 24 150 129 749. Velo does not give effect to remittance arrangements by accepting instructions for the remittance of money. In addition, Velo does not give effect to remittance arrangements by making money available or arranging for it to be made available to your Payees." />
        </VeloTypography>
      )}
    </PayoutInstructCard>
  );
}

PayoutsInstructPage.testIds = PayoutHeader.TestIds;

function PayoutsInstructPage({ children }) {
  return <Content>{children}</Content>;
}

PayoutsInstructPage.Error = PayoutPageError;
PayoutsInstructPage.Data = PayoutPageData;
PayoutsInstructPage.Loading = PayoutPageLoading;

export { PayoutsInstructPage };
