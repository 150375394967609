import React from 'react';
import { VeloModalSheet, FundingAccountsView } from 'velo-react-components';
import { getFundingAccountV2 as getFundingAccount } from 'velo-api/src/entities/fundingAccounts';
import { getPayorByIdV2 as getPayor } from 'velo-api/src/entities/payors';
import {
  usePresenter,
  useSerializableMemo,
  useCallbackFnAsResultState,
} from '../../hooks';
import { forkResult } from '../../selectors';

import { createRenderForksFromComponent } from '../../containers';
import { FundingAccountViewPresenter } from './FundingAccountViewPresenter';
import { getFundingAccountPrivileges } from './FundingAccountHelpers';

const entitySpec = { getFundingAccount, getPayor };

const forks = createRenderForksFromComponent(FundingAccountsView);

export function FundingAccountViewRoute({ fundingAccountId, role, query }) {
  const [loader, sheetProps, ...rest] = usePresenter(
    FundingAccountViewPresenter,
    entitySpec,
    undefined,
    useSerializableMemo({
      fundingAccountId,
      query,
      privileges: getFundingAccountPrivileges(role),
    })
  );

  const [result] = useCallbackFnAsResultState(loader, true);

  return (
    <VeloModalSheet {...sheetProps}>
      {forkResult(forks, result, ...rest)}
    </VeloModalSheet>
  );
}
