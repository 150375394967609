import React from 'react';
import { any, elementType, oneOfType, string } from 'prop-types';
import { FormattedDate } from 'react-intl';

VeloDate.propTypes = {
  /** The date to use. */
  value: any.isRequired,
  /** The tag to use when rendering. */
  tag: oneOfType([string, elementType]),
  /** FormattedDate component props. */
  ...FormattedDate.propTypes,
};

/**
 * Renders a date using the current react-intl locale settings.
 *
 * Pass the date using the `value` prop. Additional props are
 * passed through to the underlying FormattedDate component which
 * can accept Intl options:
 *
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/DateTimeFormat
 */
function VeloDate({ value, tag: Tag = 'span', ...other }) {
  return (
    <Tag>
      <FormattedDate value={value} {...other} />
    </Tag>
  );
}

const Short = ({ value, tag }) => <VeloDate value={value} tag={tag} />;

const Long = ({ value, tag }) => (
  <VeloDate
    value={value}
    tag={tag}
    weekday="long"
    day="numeric"
    month="long"
    year="numeric"
  />
);

VeloDate.Short = Short;
VeloDate.Long = Long;

export { VeloDate };
