import React from 'react';
import { bool } from 'prop-types';
import styled from 'astroturf/react';
import { PaymentStatus } from '../../PaymentStatus';

const Status = styled(PaymentStatus)`
  &.margins {
    margin-bottom: 1rem;
  }
`;

Status.propTypes = {
  margins: bool,
};

/**
 * Render a Payment status in a Payment View.
 */
function PaymentViewStatus(props) {
  return <Status use="itemContent" {...props} />;
}

export { PaymentViewStatus };
