import React from 'react';
import { bool, func, string } from 'prop-types';
import styled from 'astroturf/react';
import { Transitions } from '../Transitions';

const Scrim = styled('div')`
  @import 'velo-variables';

  z-index: $velo-over-all-default;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: velo-color('token-color-system-overlay');

  &.transparent {
    opacity: 0;
  }
`;

Scrim.propTypes = {
  transparent: bool,
};

VeloScrim.propTypes = {
  /** Custom CSS class(es). */
  className: string,
  /** Show or hide the scrim. */
  open: bool,
  /** Enable hiding the scrim visually. */
  transparent: bool,
  /** Called when clicking on the scrim. */
  onClick: func,
};

/**
 * Animated scrim (backdrop) component.
 * Suitable for use with modal components.
 */
function VeloScrim({ open, ...other }) {
  return (
    <Transitions.Fade in={open} unmountOnExit>
      <Scrim
        role="presentation"
        aria-hidden={open ? 'false' : 'true'}
        {...other}
      />
    </Transitions.Fade>
  );
}

export { VeloScrim };
