import React from 'react';
import styled from 'astroturf/react';
import { VeloTypography } from '../VeloTypography';

const Title = styled(VeloTypography)`
  @import 'velo-variables';
  @import '@design-system/tokens/typography/scss/mixins';

  @include ds-typography-ellipsis;

  flex-grow: 2;
  margin: 0;
`;

function VeloCardHeaderTitle({ use = 'secondaryHeader', ...props }) {
  return <Title use={use} tag="h1" {...props} />;
}

export { VeloCardHeaderTitle };
