import React from 'react';
import { func, string } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styled from 'astroturf/react';
import { WUBSPaymentReceipt } from '../../../WUBSPaymentReceipt';
import { VeloTextButton } from '../../../VeloTextButton';
import { PaymentViewDivider } from '../../PaymentViewDivider';

const ReceiptTitle = styled(WUBSPaymentReceipt.Title)`
  margin-top: 1rem;
`;

const root = 'payment-view-footer';

const TestIds = {
  PAYMENT_RECEIPT_LINK: `${root}-payment-receipt-link`,
};

PaymentViewFooter.testIds = TestIds;

PaymentViewFooter.propTypes = {
  onClick: func.isRequired,
  paymentCurrency: string.isRequired,
};

/**
 * Payment view footer is used to render a link to the payment receipt page.
 * Currently there is only one type of receipt the WUBS receipt, hence the fixed title text
 */
function PaymentViewFooter({ onClick, paymentCurrency }) {
  return (
    <>
      <PaymentViewDivider />
      <ReceiptTitle paymentCurrency={paymentCurrency} />
      <VeloTextButton
        use="linkText"
        onClick={onClick}
        data-testid={TestIds.PAYMENT_RECEIPT_LINK}
      >
        <FormattedMessage defaultMessage="Payment Receipt" />
      </VeloTextButton>
    </>
  );
}

export { PaymentViewFooter };
