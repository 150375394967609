import React, { useCallback, useState, useEffect } from 'react';
import { array, func, object, string } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import TestIds from './testIds';
import {
  BasicCurrency,
  FreeTextEntryWithinRange,
} from '../FormFields/FormFieldTypes';
import { VeloSelect } from '../VeloSelect';
import { SelectCurrency } from '../FormFields/SelectCurrency';
import { LookupTextField } from '../LookupTextField';
import { VeloStepper } from '../VeloStepper';
import { formatPayment, SinglePayoutForm } from './SinglePayoutForm';
import { StepContent } from './StepContent';

SingleStandardCreateStep.propTypes = {
  /** callback triggered on form submission. */
  onSubmit: func.isRequired,
  /** preselected currency option. */
  defaultCurrency: string,
  /** available currencies to pay in. */
  currencyLabelList: array,
  /** payor source accounts. */
  sourceAccountNameList: array,
  /** handler to call on selecting a sourceAccount*/
  onSourceAccountSelected: func.isRequired,
  /** available transmission types accounts. */
  transmissionTypesMap: object.isRequired,
  /** function to retrieve payee results. */
  fetchPayeeResults: func.isRequired,
};

function SingleStandardCreateStep({
  defaultCurrency,
  defaultTransmissionType,
  currencyLabelList,
  sourceAccountNameList,
  onSourceAccountSelected,
  transmissionTypesMap,
  fetchPayeeResults,
  onSubmit,
  ...props
}) {
  const [state, setState] = useState({
    payoutMemo: '',
    remoteId: '',
    payeeName: '',
    currency: defaultCurrency,
    amount: '',
    sourceAccountName: sourceAccountNameList[0],
    payorPaymentId: '',
    transmissionType: defaultTransmissionType.value,
  });

  // When switching sourceAccounts Selected Currency may no longer be available, so reset to defaultCurrency provided
  useEffect(() => {
    const selectedCurrentIsAvailable = currencyLabelList.find(
      (value) => value === state.currency
    );
    if (!selectedCurrentIsAvailable) {
      setState((s) => ({ ...s, currency: defaultCurrency }));
    }
  }, [defaultCurrency, currencyLabelList, state.currency]);

  const onChange = useCallback(
    (evt) => {
      const { name, value, remoteId } = evt.target;
      if (name === 'sourceAccountName') {
        onSourceAccountSelected(value);
      }

      const isLookup = name === 'payeeName';
      const lookupState = isLookup ? { remoteId: remoteId } : {};
      setState((s) => ({ ...s, ...lookupState, [name]: value }));
    },
    [setState, onSourceAccountSelected]
  );

  const onSubmitHandler = (evt) => {
    evt.preventDefault();
    const { payoutMemo, ...payment } = state;
    onSubmit({
      payoutMemo,
      payments: [formatPayment(payment)],
    });
  };
  const sections = [
    {
      fields: [
        {
          name: 'payoutMemo',
          'data-testid': TestIds.SINGLE_PAYOUT_PAYEE_MEMO,
          ...FreeTextEntryWithinRange(
            <FormattedMessage defaultMessage="Payout memo (recommended)" />,
            1,
            40
          ),
          value: state.payoutMemo,
        },
        {
          Component: LookupTextField,
          helpText: {
            children: ' ',
          },
          label: <FormattedMessage defaultMessage="Payee" />,
          value: state.payeeName,
          entityId: state.remoteId,
          name: 'payeeName',
          'data-testid': TestIds.SINGLE_PAYOUT_PAYEE_NAME,
          fetchResults: fetchPayeeResults,
          required: true,
        },
        {
          Component: VeloSelect,
          label: <FormattedMessage defaultMessage="Source account" />,
          helpText: (
            <FormattedMessage defaultMessage="Please select a source account" />
          ),
          name: 'sourceAccountName',
          'data-testid': TestIds.SINGLE_PAYOUT_BANK_ACCOUNT,
          value: state.sourceAccountName,
          options: sourceAccountNameList,
          required: true,
        },
        {
          Component: SelectCurrency,
          name: 'currency',
          'data-testid': TestIds.SINGLE_PAYOUT_CURRENCIES,
          helpText: (
            <FormattedMessage defaultMessage="Select available currency" />
          ),
          currencies: currencyLabelList,
          value: state.currency,
          desktop: 4,
          tablet: 4,
          phone: 4,
          required: true,
          // MDC bug fix when re-setting list contents index remains and can be out of bounds
          key: currencyLabelList.length,
        },
        {
          name: 'amount',
          'data-testid': TestIds.SINGLE_PAYOUT_AMOUNT,
          value: state.amount,
          ...BasicCurrency(
            <FormattedMessage defaultMessage="Payment amount" />,
            <FormattedMessage defaultMessage="Please enter a valid payment amount" />
          ),
          desktop: 8,
          tablet: 8,
          phone: 8,
          required: true,
        },
        {
          Component: VeloSelect,
          label: <FormattedMessage defaultMessage="Transmission type" />,
          helpText: (
            <FormattedMessage defaultMessage="Additional charges may be incurred for some transmission types" />
          ),
          name: 'transmissionType',
          'data-testid': TestIds.SINGLE_PAYOUT_TRANSMISSION_TYPE,
          value: state.transmissionType,
          options: transmissionTypesMap[state.sourceAccountName],
          required: false,
        },
        {
          name: 'payorPaymentId',
          'data-testid': TestIds.SINGLE_PAYOUT_PAYMENT_ID,
          value: state.payorPaymentId,
          ...FreeTextEntryWithinRange(
            <FormattedMessage defaultMessage="Unique payment ID" />,
            1,
            40
          ),
        },
      ],
    },
  ];

  return (
    <VeloStepper.Step>
      <VeloStepper.Label
        icon="2"
        text={
          <FormattedMessage defaultMessage="Add an optional descriptive memo and complete the details below to make an individual payment" />
        }
      />
      <StepContent>
        <SinglePayoutForm
          onSubmit={onSubmitHandler}
          onChange={onChange}
          sections={sections}
          breakpoint={600}
          error={props.error}
        />
      </StepContent>
    </VeloStepper.Step>
  );
}

export { SingleStandardCreateStep };
