import { useEffect, useRef } from 'react';
const activityEvents = ['mousemove', 'keypress'];

const ONE_MINUTE_MS = 60000;
const ONE_SECOND_MS = 1000;

function useInactivityTimer({ inactivityThresholdMinutes, onInactivity }) {
  const inactivityIntervalId = useRef();
  const lastActiveTimestamp = useRef(Date.now());

  useEffect(() => {
    /**
     * These overrides are for testing purposes only.
     */
    const inactivityPollInterval = Number(
      localStorage.getItem('velo.ui.inactivitypollinterval')
    );
    const inactivityPollSteps = Number(
      localStorage.getItem('velo.ui.inactivitypollsteps')
    );

    const inactivityThresholdMs = inactivityPollSteps
      ? inactivityPollSteps * inactivityPollInterval
      : inactivityThresholdMinutes * ONE_MINUTE_MS;

    const handleActivity = () => {
      lastActiveTimestamp.current = Date.now();
    };
    inactivityIntervalId.current = setInterval(() => {
      if (Date.now() - lastActiveTimestamp.current >= inactivityThresholdMs) {
        clearInterval(inactivityIntervalId.current);
        onInactivity();
      }
    }, ONE_SECOND_MS);

    activityEvents.forEach((eventName) =>
      document.addEventListener(eventName, handleActivity)
    );

    return () => {
      clearInterval(inactivityIntervalId.current);
      activityEvents.forEach((eventName) =>
        document.removeEventListener(eventName, handleActivity)
      );
    };
  }, [inactivityThresholdMinutes, onInactivity]);
}

export { useInactivityTimer };
