import { bool, oneOf } from 'prop-types';
import styled from 'astroturf/react';
import { Grid, GridInner, GridCell } from '@rmwc/grid';
import './VeloGrid.scss';

/**
 * Wrapper for the RMWC `Grid` component.
 */
const VeloGrid = styled(Grid)`
  &.compact {
    padding: 0;

    :global(.mdc-layout-grid__inner) {
      margin: calc(16px / 2 * -1);

      @supports (display: grid) {
        margin: 0;
        grid-gap: 16px;
      }
    }
  }
`;

VeloGrid.propTypes = {
  /** Remove outer padding and use 16px between cells. */
  compact: bool,
};

const Cell = styled(GridCell)`
  @import 'velo-variables';

  &.mobileAlign-right {
    @media (max-width: velo-breakpoint(XS)) {
      text-align: right;
    }
  }
`;

Cell.propTypes = {
  mobileAlign: oneOf(['right']),
};

VeloGrid.Inner = GridInner;
VeloGrid.Cell = Cell;

export { VeloGrid };
