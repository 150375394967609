import React, { useState } from 'react';
import styled from 'astroturf/react';
import { func, bool } from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { VeloButton } from '../VeloButton';
import { VeloSplitButton } from '../VeloSplitFab';
import { VeloIcon } from '../VeloIcon';
import { ConfirmationDialog } from '../ConfirmationDialog';
import { PayoutScheduleDialog } from '../PayoutScheduleDialog';
import { PayoutScheduleInfo } from '../PayoutScheduleInfo';
import globalIds from './testIds';

const root = globalIds.ACTIONS;
const TestIds = {
  DIALOG: `${root}-dialog`,
  SCHEDULE_DIALOG: `${root}-schedule-dialog`,
  WITHDRAW: `${root}-withdraw`,
  WITHDRAW_LOADING: `${root}-withdraw-skeleton`,
  INSTRUCT: `${root}-instruct`,
  INSTRUCT_LOADING: `${root}-instruct-skeleton`,
};

const ButtonBox = styled('div')`
  @import 'velo-mixins';

  margin-top: 1rem;
  display: flex;
  justify-content: space-between;

  @include from-breakpoint(XS) {
    justify-content: flex-end;
  }

  @include from-breakpoint(S) {
    margin-top: unset;
  }
`;

const marginRight = 0.5;

const InstructButtonWrapper = styled('div')`
  margin-right: ${marginRight}rem;
`;
const WithdrawButton = styled(VeloButton.Secondary)`
  margin-right: ${marginRight}rem;
`;

const Icon = styled(VeloIcon)`
  margin-right: 10px;
`;

const ScheduleInfo = styled(PayoutScheduleInfo)`
  margin-bottom: 1rem;
`;

function Loading() {
  return (
    <ButtonBox>
      <WithdrawButton skeleton data-testid={TestIds.WITHDRAW_LOADING} />
      <InstructButtonWrapper>
        <VeloButton skeleton data-testid={TestIds.INSTRUCT_LOADING} />
      </InstructButtonWrapper>
    </ButtonBox>
  );
}

PayoutActions.Loading = Loading;

const {
  ConfirmWithdrawPayoutType,
  ConfirmInstructPayoutType,
  ConfirmDeschedulePayoutType,
} = ConfirmationDialog.dialogTypes;

const defaultDialogState = {
  dialogType: ConfirmInstructPayoutType,
  open: false,
};

function addScheduleInfoToDialog(schedule, dialogConfig) {
  return {
    ...dialogConfig,
    ...(schedule
      ? {
          body: (
            <div>
              <ScheduleInfo {...schedule} />
              {dialogConfig.body}
            </div>
          ),
        }
      : {}),
  };
}

function usePayoutActions({ onWithdraw, onInstruct, onDeschedule, schedule }) {
  const [dialogState, setDialogState] = useState(defaultDialogState);
  const [schedulingDialogOpen, setSchedulingDialogOpen] = useState(false);
  const openDialog = (dialogType) => setDialogState({ open: true, dialogType });

  // handlers to allow ConfirmationDialog before  executing
  return {
    ...dialogState,
    onClose(evt) {
      if (evt.detail.action === 'accept') {
        if (dialogState.dialogType.id === ConfirmWithdrawPayoutType.id) {
          onWithdraw();
        } else if (
          dialogState.dialogType.id === ConfirmDeschedulePayoutType.id
        ) {
          onDeschedule();
        } else {
          onInstruct();
        }
      }
      setDialogState(defaultDialogState);
    },
    schedulingDialogOpen,
    setSchedulingDialogOpen,
    onWithdraw: () => openDialog(ConfirmWithdrawPayoutType),
    onInstruct: (index) => {
      openDialog(addScheduleInfoToDialog(schedule, ConfirmInstructPayoutType));
    },
    onSelectScheduleOption: (option) => {
      if (option.id === 'schedule') {
        setSchedulingDialogOpen(true);
      } else {
        // Deschedule
        openDialog(
          addScheduleInfoToDialog(schedule, ConfirmDeschedulePayoutType)
        );
      }
    },
  };
}

PayoutActions.propTypes = {
  /** Action for the Instruct Request*/
  onInstruct: func,
  /** Action for the Withdraw Request*/
  onWithdraw: func,
  /** Determines if the Instruct Button is enabled for Action (Based on whether the payout is Quoted)*/
  instructEnabled: bool,
};

PayoutActions.testIds = TestIds;

export function PayoutActions(props) {
  const intl = useIntl();
  const {
    onInstruct,
    onWithdraw,
    onSelectScheduleOption,
    schedulingDialogOpen,
    setSchedulingDialogOpen,
    ...dialogProps
  } = usePayoutActions(props);

  const scheduleDialogProps = {
    onClose: () => setSchedulingDialogOpen(false),
    open: schedulingDialogOpen,
    onSubmit: (payload, cb) => {
      props.onSchedule(payload, (error) => {
        if (error) {
          cb(error);
        } else {
          cb();
          setSchedulingDialogOpen(false);
        }
      });
    },
  };

  return (
    <>
      <ButtonBox>
        {props.onWithdraw && (
          <WithdrawButton data-testid={TestIds.WITHDRAW} onClick={onWithdraw}>
            <FormattedMessage defaultMessage="Withdraw" />
          </WithdrawButton>
        )}
        {props.onInstruct && (
          <InstructButtonWrapper>
            <VeloSplitButton
              title="Instruct Payout"
              data-testid={TestIds.INSTRUCT}
              onPrimary={onInstruct}
              onSelectOption={onSelectScheduleOption}
              disabled={!props.instructEnabled}
              options={[
                {
                  id: 'schedule',
                  icon: (
                    <Icon
                      icon="calendar"
                      title={intl.formatMessage({
                        defaultMessage: 'Schedule Payout Icon',
                      })}
                    />
                  ),
                  label: props.schedule ? (
                    <FormattedMessage defaultMessage="Reschedule" />
                  ) : (
                    <FormattedMessage defaultMessage="Schedule" />
                  ),
                },
                {
                  id: 'deschedule',
                  icon: (
                    <Icon
                      icon="delete"
                      title={intl.formatMessage({
                        defaultMessage: 'Clear Schedule Icon',
                      })}
                    />
                  ),
                  disabled: !props.schedule,
                  label: <FormattedMessage defaultMessage="Clear Schedule" />,
                },
              ]}
            >
              <FormattedMessage defaultMessage="Instruct" />
            </VeloSplitButton>
          </InstructButtonWrapper>
        )}
      </ButtonBox>
      <ConfirmationDialog
        largeWidth
        {...dialogProps}
        data-testid={TestIds.DIALOG}
      />
      {props.schedule ? (
        <PayoutScheduleDialog.Reschedule
          {...props.schedule}
          {...scheduleDialogProps}
        />
      ) : (
        <PayoutScheduleDialog {...scheduleDialogProps} />
      )}
    </>
  );
}
