import compose from 'just-compose';
import { VeloTableBuilder } from '../VeloTableBuilder';
import { PayoutsListColumns } from '../PayoutsListColumns';

const { columnsByName, filtersByName, fieldNames, mapHeaderProps, ...config } =
  PayoutsListColumns;

export const PayoutsListForPayor = VeloTableBuilder({
  ...config,
  columns: fieldNames,
  columnOrder: [
    columnsByName[fieldNames.SUBMITTED_DATE],
    columnsByName[fieldNames.SCHEDULED_ICON],
    columnsByName[fieldNames.PAYMENT_MEMO],
    columnsByName[fieldNames.TOTAL_PAYMENTS],
    columnsByName[fieldNames.RETURNED_PAYMENTS],
    columnsByName[fieldNames.STATUS],
    columnsByName[fieldNames.SCHEDULED_STATUS_ICON],
  ],
  filters: [],
  sortableColumns: [fieldNames.SUBMITTED_DATE],
});

const { Headings } = PayoutsListForPayor;

PayoutsListForPayor.Headings = compose(mapHeaderProps, Headings);

PayoutsListForPayor.getFilters = () => {
  return [
    filtersByName[fieldNames.SUBMITTED_DATE],
    filtersByName[fieldNames.PAYMENT_MEMO],
    filtersByName[fieldNames.STATUS],
    filtersByName[fieldNames.SCHEDULE_STATUS],
  ];
};

PayoutsListForPayor.TabConfig = {
  getFilters: PayoutsListForPayor.getFilters,
  primaryFilterField: PayoutsListForPayor.columns.SUBMITTED_DATE,
  defaultSort: PayoutsListForPayor.sortOrder.DESCENDING,
  table: PayoutsListForPayor,
};
