import React from 'react';
import { PayoutHeader } from '../PayoutHeader';
import { Content } from '../Content';
import { VeloCardPage } from '../VeloCardPage';
import { func, string } from 'prop-types';
import { FormattedMessage } from 'react-intl';

PayoutWithdrawnPage.propTypes = {
  /** Function to close page */
  onClose: func.isRequired,
  /** A string to represent the withdrawnDateTime */
  withdrawnDateTime: string,
};

export function PayoutWithdrawnPage(props) {
  return (
    <Content>
      <VeloCardPage
        title={<FormattedMessage defaultMessage="Payout Withdrawn" />}
        {...props}
      >
        <PayoutHeader.Withdrawn {...props} />
      </VeloCardPage>
    </Content>
  );
}
