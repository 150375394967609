/* eslint-disable default-case */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { VeloSkeleton } from '../../VeloSkeleton';
import { PaymentsList } from '../PaymentsList';
import { PaymentResult } from '../PaymentResult';
import { PaymentStatus } from '../../PaymentStatus';
import { VeloListCurrencyAmount } from '../../VeloListCurrencyAmount';
import PaymentsListPropTypes from '../propTypes';
import {
  dataTestIdBuilder,
  PaymentStatesDoesNotRequireScheduledIcon,
} from 'velo-data';
import { VeloIcon } from '../../VeloIcon';
import { VeloTable } from '../../VeloTable';

const columns = {
  SCHEDULED_ICON: 0,
  PAYOR_NAME: 1,
  PAYEE_NAME: 2,
  SOURCE_AMOUNT: 3,
  PAYMENT_AMOUNT: 4,
  PAYMENT_STATUS: 5,
  SCHEDULED_STATUS_ICON: 6,
};
const TestIds = PaymentsList.testIds;
// Render a cell at a particular row/col
const renderCell = (skeleton, data, row, col) => {
  const testId = dataTestIdBuilder(TestIds.LIST, 'row', row);
  switch (col) {
    case columns.SCHEDULED_ICON:
      return data.schedule?.scheduledFor ? (
        <VeloIcon
          icon="schedule"
          data-testid={dataTestIdBuilder(testId, 'schedule-icon')}
        />
      ) : null;
    case columns.PAYOR_NAME:
      return skeleton ? (
        <VeloSkeleton />
      ) : (
        <span data-testid={dataTestIdBuilder(testId, 'payor-name')}>
          {data.payorName}
        </span>
      );
    case columns.PAYEE_NAME:
      return (
        <span data-testid={dataTestIdBuilder(testId, 'payee-name')}>
          <PaymentResult
            skeleton={skeleton}
            errorMessage="Error loading Payee"
            {...data.payeeName}
          />
        </span>
      );
    case columns.SOURCE_AMOUNT:
      return (
        <VeloListCurrencyAmount
          skeleton={skeleton}
          amount={data.sourceAmount}
          currency={data.sourceCurrency}
          data-testid={dataTestIdBuilder(testId, 'amount')}
        />
      );
    case columns.PAYMENT_AMOUNT:
      return (
        <VeloListCurrencyAmount
          skeleton={skeleton}
          amount={data.paymentAmount}
          currency={data.paymentCurrency}
          data-testid={dataTestIdBuilder(testId, 'payment-amount')}
        />
      );
    case columns.PAYMENT_STATUS:
      return (
        <PaymentStatus
          use="tableContent"
          skeleton={skeleton}
          status={data.status}
          data-testid={dataTestIdBuilder(testId, 'status')}
        />
      );
    case columns.SCHEDULED_STATUS_ICON:
      return data.schedule?.scheduledFor &&
        PaymentStatesDoesNotRequireScheduledIcon.indexOf(data.status) === -1 ? (
        <VeloIcon
          icon="schedule"
          data-testid={dataTestIdBuilder(testId, 'schedule-status-icon')}
        />
      ) : null;
  }
  // There is no default case on the switch above as it cannot be tested
  // and falling out of this function is harmless.
};

BackOfficePaymentsList.propTypes = {
  ...PaymentsListPropTypes,
};

BackOfficePaymentsList.testIds = TestIds;

/**
 * Displays a list of BackOffice Payments.
 *
 * Five columns of information are displayed:
 *
 * - Date/Time
 * - Payor
 * - Payee
 * - Source payment amount
 * - Payment status
 */
function BackOfficePaymentsList(props) {
  return (
    <PaymentsList
      headings={[
        {
          label: '',
          'data-testid': dataTestIdBuilder(TestIds.LIST, 'scheduled-icon'),
          size: VeloTable.size.XSMALL,
        },
        {
          label: <FormattedMessage defaultMessage="Payor" />,
          'data-testid': dataTestIdBuilder(TestIds.LIST, 'heading-payor-name'),
        },
        {
          label: <FormattedMessage defaultMessage="Payee" />,
          'data-testid': dataTestIdBuilder(TestIds.LIST, 'heading-payee-name'),
        },
        {
          label: <FormattedMessage defaultMessage="Source amount" />,
          alignEnd: true,
          'data-testid': dataTestIdBuilder(TestIds.LIST, 'heading-amount'),
        },
        {
          label: <FormattedMessage defaultMessage="Payment amount" />,
          alignEnd: true,
          'data-testid': dataTestIdBuilder(
            TestIds.LIST,
            'heading-payment-amount'
          ),
        },
        {
          label: <FormattedMessage defaultMessage="Status" />,
          'data-testid': dataTestIdBuilder(TestIds.LIST, 'heading-status'),
        },
        {
          label: '',
          'data-testid': dataTestIdBuilder(
            TestIds.LIST,
            'scheduled-status-icon'
          ),
          size: VeloTable.size.XSMALL,
        },
      ]}
      renderCell={renderCell}
      {...props}
    />
  );
}

export { BackOfficePaymentsList };
