import React from 'react';
import {
  arrayOf,
  bool,
  func,
  node,
  object,
  oneOfType,
  string,
} from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { VeloTextField } from '../VeloTextField';
import { DecimalNumeric } from '../FormFields/FormFieldTypes';
import { InputRangeContainer } from '../InputRangeContainer';
import { stripNonNumeric, toNumber } from 'velo-data';

const createRenderFunction =
  ({ name, label, testId, className, isMobile }) =>
  ({ value, onChange, error }) =>
    (
      <VeloTextField
        {...(isMobile ? DecimalNumeric(label) : { type: 'text' })}
        label={label}
        className={className}
        data-testid={testId}
        name={name}
        value={isMobile ? stripNonNumeric(value) : value}
        onChange={onChange}
        invalid={error !== undefined}
      />
    );

const root = 'velo-currency-range';

const TestIds = {
  LEFT_FIELD: `${root}-left-input`,
  RIGHT_FIELD: `${root}-right-input`,
};

const errorLeftMsg = 'The minimum cannot be greater than the maximum';
const errorRightMsg = 'The maximum cannot be less than the minimum';
const errorInvalidMsg = 'Please enter a valid amount';

const validateNumbers = ([from, to], valueIndex) => {
  // check invalid numbers
  const [left, right] = [toNumber(from), toNumber(to)];

  const errorLeft =
    valueIndex === 0
      ? from && isNaN(left)
        ? errorInvalidMsg
        : left > right
        ? errorLeftMsg
        : undefined
      : undefined;

  const errorRight =
    valueIndex === 1
      ? to && isNaN(right)
        ? errorInvalidMsg
        : right < left
        ? errorRightMsg
        : undefined
      : undefined;

  return { errorLeft, errorRight, value: [from, to] };
};

const normaliseValueProp = (value) => (!value ? ['', ''] : value);

VeloCurrencyRange.propTypes = {
  /**
   * The name as it will appear in the onChange "target.name" object.
   */
  name: string.isRequired,
  /**
   * The label to be rendered above the range component.
   */
  label: node,
  /**
   * Either an array of string values, or an empty string.
   */
  value: oneOfType([arrayOf(string), string]),
  /**
   * An onChange handler which will receive an event with the same structure
   * as a standard HTML input event.
   */
  onChange: func.isRequired,
  /**
   * CSS class(es) to pass to the underlying VeloTextField component rendered on
   * the left and right.
   */
  className: string,
  /**
   * GridCell props to use for the left/right components.
   */
  cellProps: object,
  /**
   * Additional cells to render to the right of the components.
   */
  cells: node,
  /**
   * Flag indicating if mobile-friendly text field props
   * should be applied.
   */
  isMobile: bool,
  /**
   * Flag to avoid collisions in filters that have n   range options
   */
  isUnique: bool,
};

VeloCurrencyRange.testIds = TestIds;
VeloCurrencyRange.errorLeftMsg = errorLeftMsg;
VeloCurrencyRange.errorRightMsg = errorRightMsg;
VeloCurrencyRange.errorInvalidMsg = errorInvalidMsg;

function VeloCurrencyRange({ className, value, isMobile, isUnique, ...other }) {
  const testIdLeft = isUnique
    ? `${TestIds.LEFT_FIELD}-${other.name}`
    : `${TestIds.LEFT_FIELD}`;
  const testIdRight = isUnique
    ? `${TestIds.RIGHT_FIELD}-${other.name}`
    : `${TestIds.RIGHT_FIELD}`;

  const renderLeft = createRenderFunction({
    name: 'currencyFrom',
    label: <FormattedMessage defaultMessage="Minimum" />,
    testId: testIdLeft,
    className,
    isMobile,
  });

  const renderRight = createRenderFunction({
    name: 'currencyTo',
    label: <FormattedMessage defaultMessage="Maximum" />,
    testId: testIdRight,
    className,
    isMobile,
  });

  return (
    <InputRangeContainer
      value={normaliseValueProp(value)}
      validator={validateNumbers}
      renderLeft={renderLeft}
      renderRight={renderRight}
      {...other}
    />
  );
}

export { VeloCurrencyRange };
