import React from 'react';
import styled from 'astroturf/react';
import {
  List,
  ListItem,
  ListItemText,
  ListItemPrimaryText,
  ListItemSecondaryText,
  ListDivider,
  ListItemGraphic,
  ListItemMeta,
  CollapsibleList,
  SimpleListItem,
} from '@rmwc/list';
import '@rmwc/list/collapsible-list.css';
import './mdc-list.css';

export const VeloList = React.forwardRef((props, ref) => {
  return <List {...props} ref={ref} />;
});

VeloList.Item = styled(ListItem)`
  @import 'velo-variables';

  color: velo-color('token-color-text-default') !important;

  &:before,
  &:after {
    background-color: velo-color('token-color-brand-primary') !important;
  }
`;

VeloList.SimpleItem = SimpleListItem;

VeloList.ItemText = styled(ListItemText)`
  font-family: var(--ds-typography-font-family);
`;

VeloList.ItemPrimaryText = ListItemPrimaryText;

VeloList.ItemSecondaryText = ListItemSecondaryText;

VeloList.Divider = ListDivider;

VeloList.ItemGraphic = ListItemGraphic;

VeloList.ItemMeta = styled(ListItemMeta)`
  &:global(.ds-icon) {
    font-size: 24px;
    line-height: 24px;
  }

  &:global(.mdc-list-item__meta:not(.ds-icon)) {
    font-family: var(--ds-typography-font-family);
  }
`;

VeloList.Collapsible = CollapsibleList;
