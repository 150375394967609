import React from 'react';
import { bool, func, number, node, shape } from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import styled from 'astroturf/react';
import debounce from 'just-debounce-it';
import { VeloTypography } from '../VeloTypography';
import { VeloPasswordField } from '../VeloPasswordField';
import { VeloStrengthMeter } from '../VeloStrengthMeter';
import styles from './PasswordFieldSet.module.scss';

const Fieldset = styled('fieldset')`
  margin: 0;
  padding: 0;
  border: none;
  text-align: left;
`;

const Password = styled(VeloPasswordField)`
  margin-top: 0.25rem;
`;

const ConfirmPassword = styled(VeloPasswordField)`
  margin-top: 1.25rem;
`;

const List = styled(VeloTypography).attrs({ tag: 'ul', use: 'bodyText' })`
  padding: 0;
  margin: 0 0 0 0.875rem;
`;

const TestIds = {
  ...VeloStrengthMeter.testIds,
  PRIMARY_PASSWORD_FIELD: 'password-fieldset-primary-password-field',
  SECONDARY_PASSWORD_FIELD: 'password-fieldset-secondary-password-field',
};

const FieldNames = {
  password: 'password',
  confirmPassword: 'confirmedPassword',
};

const defaultTimer = {
  timerId: undefined,
  restart: function (callback) {
    this.timerId && clearTimeout(this.timerId);
    // The default delay is 3 seconds.
    this.timerId = setTimeout(callback, 3000);
  },
};

const OutOfRangeValidation = {
  result: {
    score: 0,
    warning: (
      <FormattedMessage defaultMessage="Your password must be between 10 and 64 characters" />
    ),
    suggestions: [],
  },
};

const PasswordStateEnum = {
  Unset: 0,
  OutOfRange: 1,
  WeakPassword: 2,
  NoConfirmedPassword: 3,
  NotEqual: 4,
  Valid: 5,
};

/**
 * This is similar to using pattern matching or cons, the logic is run in order,
 * stopping at the first matching guard.
 */
const logicGuards = [
  {
    passwordState: PasswordStateEnum.Unset,
    guard: ({ password }) => password.length === 0,
  },
  {
    passwordState: PasswordStateEnum.OutOfRange,
    guard: ({ password }) => password.length < 10,
  },
  {
    passwordState: PasswordStateEnum.WeakPassword,
    guard: (_, { validationDetail }) => {
      const { result } = validationDetail || { result: undefined };
      return result ? result.score < 3 : true;
    },
  },
  {
    passwordState: PasswordStateEnum.NoConfirmedPassword,
    guard: ({ confirmedPassword }) => confirmedPassword.length === 0,
  },
  {
    passwordState: PasswordStateEnum.NotEqual,
    guard: ({ confirmedPassword, password }) => password !== confirmedPassword,
  },
  {
    passwordState: PasswordStateEnum.Valid,
    guard: () => true,
  },
];

class PasswordFieldSet extends React.Component {
  static propTypes = {
    /**
     * A function that is called with the password when this field is changed.
     */
    onChange: func.isRequired,
    /**
     * A label for the first password field.
     */
    passwordLabel: node.isRequired,
    /**
     * A label for the second password field.
     */
    confirmLabel: node.isRequired,
    /**
     * An optional validation detail object to render the strength meter
     */
    validationDetail: VeloStrengthMeter.ValidationDetailPropType,
    /**
     * A number representing ms to debounce the onChange requests by.
     */
    debounceTime: number,
    /**
     * A timer implementation implementing 'restart' that takes a callback to
     * be invoked when the time elapses.
     */
    timer: shape({
      restart: func,
    }),
    /**
     * Optional node to present above the form
     */
    introduction: node,
    /**
     * Whether the fieldset is currently disabled
     */
    disabled: bool,
  };

  static defaultProps = {
    debounceTime: 300,
    timer: defaultTimer,
    disabled: false,
  };

  static testIds = TestIds;
  state = {
    password: '',
    confirmedPassword: '',
    invalid: {
      [FieldNames.password]: false,
      [FieldNames.confirmPassword]: false,
    },
    showHints: false,
  };

  triggerChange = debounce(
    () => this.props.onChange({ password: this.state.password }),
    this.props.debounceTime,
    // invokes immediately for the first result (useful for tests)
    true
  );

  getPasswordState = () =>
    logicGuards.find(({ guard }) => guard(this.state, this.props))
      .passwordState;

  setFieldValidation = (fieldName, message) => {
    // Because of jsDom, we have to use this selector instead of named fields.
    const selector = `[name=${fieldName}]`;
    const fieldSet = this.fieldSetRef.current;
    try {
      const field = fieldSet.querySelector(selector);
      // Use the HTML validation API
      field.setCustomValidity(message);
    } catch (ex) {}
  };

  getValidations = (passwordState) => {
    const { intl } = this.props;
    const passwordVal = passwordState === PasswordStateEnum.WeakPassword;
    const confirmedVal = passwordState === PasswordStateEnum.NotEqual;

    return {
      [FieldNames.password]: passwordVal
        ? intl.formatMessage({
            defaultMessage:
              'Your password does not meet the strength requirements',
          })
        : '',
      [FieldNames.confirmPassword]: confirmedVal
        ? intl.formatMessage({ defaultMessage: 'Your passwords do not match' })
        : '',
    };
  };

  onTimer = () => this.setState({ showHints: true });

  componentDidUpdate(_, prevState) {
    const { password } = this.state;
    const passwordState = this.getPasswordState();
    const passwordUpdated = password !== prevState.password;
    if (passwordUpdated && passwordState > PasswordStateEnum.OutOfRange) {
      this.triggerChange();
    }
    if (passwordUpdated) {
      this.props.timer.restart(this.onTimer);
      this.setState({ showHints: false });
    }

    Object.entries(this.getValidations(passwordState)).forEach(
      ([fieldName, value]) => {
        this.setFieldValidation(fieldName, value);
      }
    );
  }

  mergeInvalidState = ({ invalid }, { name }, value) => ({
    invalid: { ...invalid, [name]: value },
  });

  passwordFieldProps = {
    maxLength: 64,
    onChange: (e) => {
      e.preventDefault();
      const target = { name: e.target.name, value: e.target.value };
      this.setState((s) => ({
        ...s,
        [target.name]: target.value,
        ...this.mergeInvalidState(s, target, false),
      }));
    },
    onBlur: (e) => {
      const target = { name: e.target.name, value: e.target.value };
      const { valid } =
        this.passwordFieldRefs[
          e.target.name
        ].current.foundation.adapter_.getNativeInput().validity;

      this.setState((s) => ({
        ...s,
        ...this.mergeInvalidState(s, target, !valid),
      }));
    },
  };

  getValidationDetail = (passwordState) => {
    const { invalid, showHints } = this.state;
    let detail = { result: null };
    if (passwordState === PasswordStateEnum.Unset) {
      return detail;
    } else if (passwordState === PasswordStateEnum.OutOfRange) {
      detail = OutOfRangeValidation;
    } else if (this.props.validationDetail) {
      detail = this.props.validationDetail;
    }
    if (!showHints && !invalid[FieldNames.password] && detail.result) {
      const { score } = detail.result;
      return { result: { score, waring: '', suggestions: [] } };
    }
    return detail;
  };

  fieldSetRef = React.createRef();

  passwordFieldRefs = {
    [FieldNames.password]: React.createRef(),
    [FieldNames.confirmPassword]: React.createRef(),
  };

  componentDidMount() {
    // We will manually validate due to custom validation logic
    this.passwordFieldRefs[
      FieldNames.password
    ].current.foundation.setUseNativeValidation(false);
    this.passwordFieldRefs[
      FieldNames.confirmPassword
    ].current.foundation.setUseNativeValidation(false);
  }

  render() {
    const { passwordLabel, confirmLabel, introduction, disabled, className } =
      this.props;
    const { password, confirmedPassword } = this.state;
    const passwordState = this.getPasswordState();
    const validationDetail = this.getValidationDetail(passwordState);
    const confirmDisabled = passwordState <= PasswordStateEnum.WeakPassword;
    const validations = this.getValidations(passwordState);
    const helpText = validations[FieldNames.confirmPassword];

    return (
      <Fieldset className={className} ref={this.fieldSetRef}>
        {introduction}
        <Password
          invalid={this.state.invalid[FieldNames.password]}
          disabled={disabled}
          data-testid={TestIds.PRIMARY_PASSWORD_FIELD}
          label={passwordLabel}
          value={password}
          name={FieldNames.password}
          id={FieldNames.password}
          minLength={10}
          {...this.passwordFieldProps}
          ref={this.passwordFieldRefs[FieldNames.password]}
        />
        <VeloStrengthMeter data={validationDetail} />
        <ConfirmPassword
          invalid={this.state.invalid[FieldNames.confirmPassword]}
          disabled={disabled || confirmDisabled}
          data-testid={TestIds.SECONDARY_PASSWORD_FIELD}
          label={confirmLabel}
          value={confirmedPassword}
          name={FieldNames.confirmPassword}
          id={FieldNames.confirmPassword}
          {...this.passwordFieldProps}
          helpText={{
            className: styles.helper,
            validationMsg: true,
            children: helpText,
          }}
          ref={this.passwordFieldRefs[FieldNames.confirmPassword]}
        />
        <List>
          <li>
            <FormattedMessage defaultMessage="Needs to be at least 10 characters." />
          </li>
          <li>
            <FormattedMessage defaultMessage="Avoid including any personal information." />
          </li>
          <li>
            <FormattedMessage defaultMessage="Avoid re-using a previous password." />
          </li>
        </List>
      </Fieldset>
    );
  }
}

PasswordFieldSet.testIds = TestIds;
PasswordFieldSet.FieldNames = FieldNames;

const PasswordFieldSetIntl = injectIntl(PasswordFieldSet);

export { PasswordFieldSetIntl as PasswordFieldSet };
