import React from 'react';
import {
  bool,
  shape,
  string,
  oneOf,
  func,
  number,
  node,
  oneOfType,
} from 'prop-types';
import { VeloSnackbar } from '../VeloSnackbar';
import { VeloNotification } from '../VeloNotification';

/**
 * Container to externalise the show/hide logic, to simplify the child component VeloNotification
 * New notifications simply replace current notifications - no QUEUE implemented Yet..
 */

const NotificationPropTypes = {
  /** The notification message to display. */
  message: oneOfType([node, string]),
  /** The action text displayed. */
  actionText: string,
  /** Whether the notification is dismissed on action click. */
  dismissesOnAction: bool,
  /** The function triggered on click */
  onClick: func,
  /** The function triggered on close  */
  onClose: func,
  /** The timeout length before the notification disappears */
  timeout: number,
  /** The type of notification  */
  type: oneOf(Object.values(VeloNotification.notificationType)),
  /** The context of the notification. */
  context: string,
};

const defaultSnackbar = { open: false };

class VeloNotificationContainer extends React.Component {
  static propTypes = {
    notification: shape(NotificationPropTypes),
    snackbar: shape(NotificationPropTypes),
  };

  state = {
    displayState: VeloNotification.displayStates.ANIMATE_IN,
  };

  setTimer = (action, time) => {
    this.clearTimer();
    this.timerId = setTimeout(() => {
      action();
      this.clearTimer();
    }, time);
  };

  clearTimer = () => {
    clearTimeout(this.timerId);
    this.timerId = null;
  };

  hideCurrent = () => {
    this.setState({
      displayState: VeloNotification.displayStates.ANIMATE_OUT,
    });
  };

  componentDidUpdate(prevprops) {
    /**
     * Triggers the render out or between notifications
     */
    if (prevprops.notification !== this.props.notification) {
      this.animateNotification();
    }
  }

  componentDidMount() {
    this.animateNotification();
  }
  componentWillUnmount() {
    this.clearTimer();
  }
  animateNotification = () => {
    if (!this.props.notification) return;

    this.setState({
      displayState: VeloNotification.displayStates.ANIMATE_IN,
    });
    if (this.props.notification.timeout) {
      this.setTimer(this.hideCurrent, this.props.notification.timeout);
    }
  };

  render() {
    let displayState;
    const { notification = {}, snackbar = defaultSnackbar } = this.props;
    const { message } = notification;

    if (!message) {
      displayState = VeloNotification.displayStates.HIDDEN;
    } else {
      displayState = this.state.displayState;
    }

    return (
      <>
        <VeloNotification
          {...notification}
          animateCss={displayState}
          dismiss={this.hideCurrent}
        />
        <VeloSnackbar {...snackbar} />
      </>
    );
  }
}

VeloNotificationContainer.root = VeloNotification.root;
VeloNotificationContainer.testIds = VeloNotification.testIds;
VeloNotificationContainer.notificationType = VeloNotification.notificationType;
VeloNotificationContainer.types = VeloNotification.types;

export { VeloNotificationContainer };
