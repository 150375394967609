import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { oneOf, string } from 'prop-types';
import { PayeeType, payeeSsnLabel, payeeTaxIdLabel } from 'velo-data';
import { VeloPropTypes } from '../VeloPropTypes';
import { MultiLineOnOffSwitch } from './MultiLineOnOffSwitch';

const description = (payeeType, countryCode, payeeCountryRules) =>
  ({
    [PayeeType.INDIVIDUAL]: payeeSsnLabel(countryCode, payeeCountryRules),
    [PayeeType.COMPANY]: payeeTaxIdLabel(countryCode, payeeCountryRules),
  }[payeeType]);

ChallengeCodeSwitch.propTypes = {
  countryCode: string.isRequired,
  payeeType: oneOf(Object.values(PayeeType)),
  payeeCountryRules: VeloPropTypes.payeeCountryRulesType(),
};

/**
 * Component used to toggle challenge code fields
 * when adding a single payee. Contains some helper text indicating
 * the effect of adding a challenge code to the user.
 */
export function ChallengeCodeSwitch({
  payeeType,
  countryCode,
  payeeCountryRules,
  ...other
}) {
  const intl = useIntl();
  return (
    <MultiLineOnOffSwitch
      label={<FormattedMessage defaultMessage="Provide a challenge code?" />}
      line1={
        <FormattedMessage defaultMessage="Select this if you want to provide a custom challenge code to securely identify the user during registration." />
      }
      line2={
        <FormattedMessage
          defaultMessage="If you do not wish to use a custom challenge code, the application will use the user's {ssn}."
          values={{
            ssn: description(payeeType, countryCode, payeeCountryRules),
          }}
        />
      }
      aria-label={intl.formatMessage({
        defaultMessage: 'Toggle challenge code',
      })}
      {...other}
    />
  );
}
