import React from 'react';
import { func, node, bool } from 'prop-types';
import { injectIntl } from 'react-intl';
import styled from 'astroturf/react';
import { VeloIconButton } from '../VeloIconButton';
import { VeloFab } from '../VeloFab';
import { VeloModalSheet } from '../VeloModalSheet';
import { VeloDivider } from '../VeloDivider';
import { PaymentChannelViewDeleteConfirmation } from './PaymentChannelViewDeleteConfirmation';
import { PaymentChannelViewCannotDeleteConfirmation } from './PaymentChannelViewCannotDeleteConfirmation';
import { PaymentChannelViewMode } from './PaymentChannelViewMode';
import { PaymentChannelDisabled } from './PaymentChannelDisabled';
import { PaymentChannelViewDetails } from './PaymentChannelViewDetails';
import { PaymentChannelViewItem } from './PaymentChannelViewItem';
import { PaymentChannelViewPayor } from './PaymentChannelViewPayor';
import { PaymentChannelViewPayors } from './PaymentChannelViewPayors';
import { PaymentChannelViewLoading } from './PaymentChannelViewLoading';
import { PaymentChannelViewError } from './PaymentChannelViewError';
import { PaymentChannelTypes } from './propTypes';

const Container = styled('div')`
  height: 100%;
`;

const Header = styled(VeloModalSheet.Header)`
  margin: 1rem;
`;

const HeaderTitle = styled(VeloModalSheet.HeaderTitle)`
  &.disabled {
    opacity: 0.4;
  }
`;

HeaderTitle.propTypes = {
  disabled: bool,
};

const EditButton = styled(VeloFab)`
  @import 'velo-variables';

  top: 60px;
  position: absolute;
  z-index: $velo-mobile-in-front-index-default;
`;

const Body = styled(VeloModalSheet.Body)`
  padding-top: 2rem;
  height: calc(100% - 7rem);

  &.disabled {
    opacity: 0.4;
  }

  &.enabling {
    opacity: 0.6;
  }
`;

Body.propTypes = {
  disabled: bool,
  enabling: bool,
};

const root = 'view-payment-channel';

const TestIds = {
  DELETE: `${root}-delete`,
  EDIT: `${root}-edit`,
  CLOSE: `payment-channel-view-close-button`,
  CONTAINER: `${root}-container`,
};
/**
 * Render a Payment Channel view.
 */
class PaymentChannelView extends React.Component {
  static propTypes = {
    /** Called when the close button is clicked. */
    onClose: func,
    /** Called when the delete button/icon is clicked.
     * If not present, delete icon not rendered */
    onDelete: func,
    /** Called when the edit button/icon is clicked.
     * If not present, edit icon not rendered */
    onEdit: func,
    /** The Payment Channel Sheet Modal body containing the Payment Channel details. */
    children: node.isRequired,
    /** function to handle on enable payment channel */
    onEnablePaymentChannel: func.isRequired,
  };

  state = {
    // Used to show/hide the delete confirmation dialog
    showDeleteConfirmation: false,
    // Used to render the entire component in a disabled state while
    // the payment channel is deleted.
    disabled: false,
    enabling: false,
  };

  static root = root;
  static testIds = TestIds;
  static mode = PaymentChannelViewMode;
  static types = PaymentChannelTypes;

  static Loading = PaymentChannelViewLoading;
  static Error = PaymentChannelViewError;
  static Details = PaymentChannelViewDetails;
  static Item = PaymentChannelViewItem;
  static Payor = PaymentChannelViewPayor;
  static Payors = PaymentChannelViewPayors;
  static Disabled = PaymentChannelDisabled;

  handleDelete = () => {
    this.setState({ showDeleteConfirmation: true });
  };

  handleCloseConfirmation = () => {
    this.setState({ showDeleteConfirmation: false });
  };

  handleDeleteChannel = () => {
    // Disable the component and delete the channel
    this.setState({ disabled: true, showDeleteConfirmation: false }, () => {
      this.props.onDelete();
    });
  };

  handleEnablePaymentChannel = (evt) => {
    this.setState({ enabling: true });
    evt.preventDefault();
    this.props.onEnablePaymentChannel();
  };

  handleDeleteDialogClick = (e) => {
    e.stopPropagation();
  };

  render() {
    const {
      onClose,
      children,
      onDelete,
      onEdit,
      result,
      mode,
      onEnablePaymentChannel,
      intl,
      ...other
    } = this.props;

    const { disabled, enabling } = this.state;
    const { paymentChannelName, disabledReason } = result;
    const enabled = result.hasOwnProperty('enabled') ? result.enabled : true;
    const canEdit = mode !== PaymentChannelViewMode.BACKOFFICE && onEdit;
    const canDelete = mode !== PaymentChannelViewMode.BACKOFFICE && onDelete;

    const isDeletable = !(
      result.payors.result && result.payors.result.length > 0
    );

    return (
      <Container>
        <Header divider {...other}>
          <HeaderTitle disabled={disabled}>{paymentChannelName}</HeaderTitle>
          {/* Display the delete button if we have a delete handler. */}
          {canDelete && (
            <VeloIconButton
              icon="delete"
              title={intl.formatMessage({
                defaultMessage: 'Delete Payment Method',
              })}
              onClick={this.handleDelete}
              disabled={disabled}
              data-testid={TestIds.DELETE}
            />
          )}
          <VeloIconButton
            icon="close"
            onClick={onClose}
            title={intl.formatMessage({
              defaultMessage: 'Close Payment Method view',
            })}
            disabled={disabled}
            data-testid={TestIds.CLOSE}
          />

          {canEdit && (
            <EditButton
              icon="edit"
              mini
              title={intl.formatMessage({
                defaultMessage: 'Edit Payment Method',
              })}
              onClick={onEdit}
              disabled={disabled}
              data-testid={TestIds.EDIT}
            />
          )}
        </Header>

        <Body
          disabled={disabled}
          enabling={enabling}
          data-testid={TestIds.CONTAINER}
        >
          {!enabled && (
            <>
              <PaymentChannelDisabled
                disabledReason={disabledReason}
                onEnable={
                  mode === PaymentChannelViewMode.BACKOFFICE
                    ? this.handleEnablePaymentChannel
                    : undefined
                }
              />
              <VeloDivider padded />
            </>
          )}
          {children}
        </Body>

        {/* Delete confirmation dialog */}
        {isDeletable && (
          <PaymentChannelViewDeleteConfirmation
            paymentChannelName={paymentChannelName}
            open={this.state.showDeleteConfirmation}
            onDelete={this.handleDeleteChannel}
            onClose={this.handleCloseConfirmation}
            onClick={this.handleDeleteDialogClick}
          />
        )}

        {/* Unable to Delete confirmation dialog */}
        {!isDeletable && (
          <PaymentChannelViewCannotDeleteConfirmation
            open={this.state.showDeleteConfirmation}
            onClose={this.handleCloseConfirmation}
            onClick={this.handleDeleteDialogClick}
          />
        )}
      </Container>
    );
  }
}

const PaymentChannelViewIntl = injectIntl(PaymentChannelView);

export { PaymentChannelViewIntl as PaymentChannelView };
