export function unlinkSourceAccountTagV2(send) {
  return (sourceAccountId, tagId, ...args) =>
    send(
      {
        path: '/v2/sourceAccounts/{sourceAccountId}/tags/{tagId}',
        pathParameters: { sourceAccountId, tagId },
        type: 'delete',
        query: undefined,
        body: undefined,
        headers: undefined,
        auth: 'oauth2',
        contentType: undefined,
      },
      ...args
    );
}
