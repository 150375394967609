import React, { useCallback, useMemo } from 'react';
import {
  Content,
  createRenderForksFromTable,
  FundingAccountsList,
  FundingAccountsPageHeader,
  UserRoles,
} from 'velo-react-components';
import { Privileges } from 'velo-data';

import {
  listPayorsV2 as getPayors,
  getPayorByIdV2 as getPayor,
} from 'velo-api/src/entities/payors';
import { getFundingAccountsV2 as getFundingAccounts } from 'velo-api/src/entities/fundingAccounts';

import {
  usePresenter,
  useAllQueries,
  useCallbackFnAsResultState,
  useSerializableMemo,
  useCountries,
  useCurrencies,
} from '../../hooks';
import { forkResult } from '../../selectors';

import { FundingAccountsListPresenter } from './FundingAccountsListPresenter';

const entitySpec = {
  getFundingAccounts,
  getPayors,
  getPayor,
};

const getConfigByRole = (role) => {
  if (UserRoles.isBackOffice(role)) {
    return {
      keys: ['payorId'],
      privileges: [Privileges.FUNDING_ACCOUNT_VIEW_PAYOR],
      Header: FundingAccountsPageHeader.WithCreate,
      forks: createRenderForksFromTable(FundingAccountsList.BackOffice),
      filters: FundingAccountsList.BackOffice.filters,
      queryArgs: [
        FundingAccountsList.BackOffice.columns.NAME,
        FundingAccountsList.BackOffice.sortOrder.ASCENDING,
        20,
        null,
      ],
    };
  }

  return {
    keys: [],
    privileges: [],
    Header: () => <FundingAccountsPageHeader />,
    forks: createRenderForksFromTable(FundingAccountsList.Payor),
    filters: FundingAccountsList.Payor.filters,
    queryArgs: [
      FundingAccountsList.Payor.columns.NAME,
      FundingAccountsList.Payor.sortOrder.ASCENDING,
      20,
      null,
    ],
  };
};

export function FundingAccountsListRoute({ query, role }) {
  const { countries } = useCountries();
  const { currencies } = useCurrencies();
  const { Header, forks, filters, keys, queryArgs, privileges } = useMemo(
    () => getConfigByRole(role),
    [role]
  );

  const [loader, populatedFilters, getProps, onCreate] = usePresenter(
    FundingAccountsListPresenter,
    entitySpec,
    useMemo(
      () => ({
        keys,
        filters,
        countries,
        currencies,
      }),
      [keys, filters, countries, currencies]
    ),
    useSerializableMemo({ query, privileges })
  );

  const [listQuery, tableProps, displayValues] = useAllQueries(
    populatedFilters,
    ...queryArgs
  );

  const [result] = useCallbackFnAsResultState(
    useCallback(
      (cb) => loader(listQuery, displayValues, cb),
      [loader, displayValues, listQuery]
    )
  );

  return (
    <Content header={<Header onCreate={onCreate} />}>
      {forkResult(forks, result, ...getProps(tableProps, result))}
    </Content>
  );
}
