import React, { useCallback, useMemo } from 'react';
import { Route, Switch, useLocation } from 'react-router';
import querystring from 'query-string';
import {
  createRenderForksFromTable,
  PayeesList,
  PayeesListPage,
  VeloCacheHitContext,
  VeloSummary,
} from 'velo-react-components';
import { listPayorsV2 as getPayors } from 'velo-api/src/entities/payors';
import { listPayeesV4 as getPayees } from 'velo-api/src/entities/payees';
import { useCountries } from '../../hooks';
import { forkResult } from '../../selectors';
import {
  usePresenter,
  useAllQueries,
  useCallbackFnAsResultState,
} from '../../hooks';
import { PayeesListPresenter } from './PayeesListPresenter';

const entitySpec = {
  getPayees,
  getPayors,
};

const PayeesListMode = PayeesList.mode;

const modeConfigMap = {
  [PayeesListMode.BACKOFFICE]: {
    forks: createRenderForksFromTable(PayeesList.BackOffice),
    fixedProps: {
      keys: ['payorId'],
    },
    filters: PayeesList.BackOffice.filters,
    columns: PayeesList.BackOffice.columns,
    sortOrder: PayeesList.BackOffice.sortOrder,
  },
  [PayeesListMode.PAYOR]: {
    forks: createRenderForksFromTable(PayeesList.Payor),
    fixedProps: {
      keys: [],
    },
    filters: PayeesList.Payor.filters,
    columns: PayeesList.Payor.columns,
    sortOrder: PayeesList.Payor.sortOrder,
  },
};

const payeesByPayorForks = createRenderForksFromTable(PayeesList.ByPayor);

function summaryAsResult(result) {
  return result.summary ? { result: result.summary } : result;
}

function PayeesByPayorDetails({ PayeeDetailView }) {
  const qs = querystring.parse(useLocation().search);
  return (
    <PayeeDetailView
      open={qs?.payeeId !== undefined}
      payorId={qs?.payorId}
      payeeId={qs?.payeeId}
    />
  );
}

export function PayeesListRoute({ query, mode, role, PayeeDetailView }) {
  const { countries } = useCountries();

  const { forks, filters, columns, sortOrder, fixedProps } =
    modeConfigMap[mode];

  const { payorId, payorIdName } = query;

  const [
    loader,
    { populatedFilters, getProps, onAdd, payeesByPayorPath, detailsRoutes },
  ] = usePresenter(
    PayeesListPresenter,
    entitySpec,
    fixedProps,
    useMemo(
      () => ({
        query: { payorId, payorIdName },
        countries,
        filters,
        role,
      }),
      [countries, filters, role, payorId, payorIdName]
    )
  );

  const [listQuery, tableProps, displayValues] = useAllQueries(
    populatedFilters,
    columns.DISPLAY_NAME,
    sortOrder.ASCENDING,
    20,
    null
  );

  const [cacheHit] = VeloCacheHitContext.useVeloCacheHitContext(
    VeloSummary.SummaryType.PAYEES
  );
  const [result] = useCallbackFnAsResultState(
    useCallback(
      (cb) => loader(listQuery, displayValues, cb, cacheHit),
      [loader, listQuery, displayValues, cacheHit]
    )
  );

  return (
    <PayeesListPage
      onAdd={onAdd}
      list={
        <>
          <VeloSummary
            type={VeloSummary.SummaryType.PAYEES}
            summary={summaryAsResult(result)}
          />
          <Switch>
            <Route path={payeesByPayorPath}>
              {forkResult(
                payeesByPayorForks,
                result,
                ...getProps(tableProps, result)
              )}
              <PayeesByPayorDetails PayeeDetailView={PayeeDetailView} />
            </Route>
            <Route>
              {forkResult(forks, result, ...getProps(tableProps, result))}
              <Route path={detailsRoutes} exact>
                {({ match }) => (
                  <PayeeDetailView
                    open={match !== null}
                    payeeId={match?.params.payeeId}
                  />
                )}
              </Route>
            </Route>
          </Switch>
        </>
      }
    />
  );
}
