import 'velo-scripts/polyfills';
import 'velo-react-components/src/styles/charset.css';
import 'velo-assets/fonts';
import 'velo-react-components/src/styles/velo.scss';
import observe from 'callbag-observe';
import callbagToPromise from 'callbag-to-promise';
import React from 'react';
import ReactDOM from 'react-dom';
import { createAPI } from 'velo-api/src/createAPI';
import PayorApp from './App';
import { refresh } from 'velo-api/src/entities/authenticate';
import { validateAccessToken as verifyMFA } from 'velo-api/src/entities/validate';
import { listSupportedCountriesV3 as getSupportedCountries } from 'velo-api/src/entities/supportedCountries';
import { listPaymentChannelRulesV1 as getPaymentChannelRules } from 'velo-api/src/entities/paymentChannelRules';
import { listSupportedCurrenciesV2 as getCurrencies } from 'velo-api/src/entities/currencies';
import {
  getSelf,
  listUsers as getUsersv2,
  getUserByIdV2 as getUser,
  inviteUser,
  deleteUserByIdV2 as deleteUser,
  enableUserV2 as enableUser,
  disableUserV2 as disableUser,
  resendToken as resendInvite,
  userDetailsUpdate as updateUserDetails,
  unregisterMFA as unregisterMfaDevice,
  unlockUserV2 as unlockUser,
} from 'velo-api/src/entities/users';
import {
  getPayeeByIdV4 as getPayee,
  getPayeeRulesV4 as getPayeeRules,
  listPayeesV4 as getPayees,
  resendPayeeInviteV4 as resendPayeeInvite,
  deletePayeeByIdV4 as deletePayee,
  getPaymentChannelsV4BO as getPaymentChannels,
  getPaymentChannelV3 as getPaymentChannel,
  createPaymentChannelV3 as createPaymentChannel,
  deletePaymentChannelV3 as deletePaymentChannel,
  enablePaymentChannelV4 as enablePaymentChannel,
  updatePaymentChannelV3 as updatePaymentChannel,
} from 'velo-api/src/entities/payees';
import { lookupBankV2 as banklookup } from 'velo-api/src/entities/banklookup';
import {
  listPaymentsAuditV4 as getPayments,
  getPaymentDetailsV4 as getPaymentDetails,
  getPayoutsForPayorV4 as getPayouts,
  getPaymentsForPayoutV4 as getPaymentsByPayout,
  getFundingsV4 as getPayorFundings,
  exportTransactionsCSVV4 as getTransactions,
} from 'velo-api/src/entities/paymentaudit';
import { withdrawPayment } from 'velo-api/src/entities/payments';
import { payorGetBranding as getPayorBranding } from 'velo-api/src/entities/payors';
import { resetPassword } from 'velo-api/src/entities/password';
import {
  unlockAccountWithToken as passwordUnlock,
  getVerificationTokenById as checkTokenIsValid,
  submitPassword as updatePassword,
  generateOTPForSMS as generateOTP,
  validatePassword as validatePasswordWithToken,
} from 'velo-api/src/entities/tokens';
import { payorLinksV1 as getPayorLinks } from 'velo-api/src/entities/payorLinks';
import { getPayoutSummaryV3 as getPayoutStatus } from 'velo-api/src/entities/payouts';
import { getSourceAccountV3 as getSourceAccount } from 'velo-api/src/entities/sourceAccounts';
import { getFundingAccountsV2 as getFundingAccounts } from 'velo-api/src/entities/fundingAccounts';
import { getPayorByIdV2 as getPayor } from 'velo-api/src/entities/payors';
import {
  createVeloLibraryAdapter,
  ApplicationVariantEnum,
} from 'velo-portal-common';

const appVersion = process.env.REACT_APP_BUILD_VERSION || process.env.NODE_ENV;
const gitSha = process.env.REACT_APP_GIT_SHA || process.env.NODE_ENV;
const apiKey = process.env.REACT_APP_API_KEY;
const secretKey = process.env.REACT_APP_API_SECRET;
const host = process.env.REACT_APP_API_HOST;
const iamHost = process.env.REACT_APP_API_IAM_HOST;

const api = createAPI({
  host,
  iamHost,
  apiKey,
  secretKey,
  sendWithPromise(spec) {
    return callbagToPromise(api.send(spec));
  },
  mergeSpec(override) {
    return (spec) => api.sendWithPromise({ ...spec, ...override });
  },
  // FIXME: remove this when proper contentType support lands
  sendFormWithPromise(spec) {
    return api.sendWithPromise({
      ...spec,
      contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
    });
  },
  logout() {
    return callbagToPromise(api.logoutUser);
  },
  login({ email, password }) {
    return refresh(api.sendFormWithPromise)(
      {
        grant_type: 'password',
      },
      {
        password,
        username: email && email.trim(),
      }
    );
  },
  refresh(refresh_token) {
    return refresh(api.sendFormWithPromise)(
      {},
      {
        grant_type: 'refresh_token',
        refresh_token,
      }
    );
  },
  verifyMFA(otpValue, token) {
    return verifyMFA(api.sendWithPromise)(
      { otpValue },
      { Authorization: `Bearer ${token}` }
    );
  },
  sendResetPasswordLink(email) {
    return resetPassword(api.sendWithPromise)({ email });
  },
  updatePassword(token, body, otp) {
    const headers = otp ? { 'Velo-OTP': otp } : {};
    return updatePassword(api.sendWithPromise)(token, body, headers);
  },
  getSupportedCountries() {
    // TODO: update OpenAPI to indicate no auth on this endpoint
    return getSupportedCountries(api.mergeSpec({ auth: undefined }))();
  },
  getPayorFundings(payorId, query) {
    return getPayorFundings(api.sendWithPromise)({ payorId, ...query });
  },
  resendPayeeInvite(payeeId, payorId) {
    return resendPayeeInvite(api.sendWithPromise)(payeeId, { payorId });
  },
  // spread the named functions onto the API object
  ...((fns) =>
    Object.entries(fns).reduce(
      (acc, [k, v]) => ({
        ...acc,
        [k]: (...args) => v(api.sendWithPromise)(...args),
      }),
      {}
    ))({
    banklookup,
    getPaymentChannelRules,
    getCurrencies,
    getPayee,
    getSelf,
    getPayments,
    getPaymentDetails,
    passwordUnlock,
    checkTokenIsValid,
    generateOTP,
    validatePasswordWithToken,
    getPayeeRules,
    getPayorLinks,
    getPayouts,
    getPayoutStatus,
    getPaymentsByPayout,
    getPaymentChannels,
    getPaymentChannel,
    deletePaymentChannel,
    enablePaymentChannel,
    updatePaymentChannel,
    createPaymentChannel,
    getSourceAccount,
    getPayees,
    getPayor,
    getFundingAccounts,
    getUsersv2,
    getUser,
    getPayorBranding,
    inviteUser,
    deleteUser,
    enableUser,
    disableUser,
    resendInvite,
    updateUserDetails,
    unregisterMfaDevice,
    unlockUser,
    getTransactions,
    deletePayee,
    withdrawPayment,
  }),
});

if (process.env.NODE_ENV !== 'production') {
  window.api = api;
  observe((state) => {
    window.sessionState = state;
  })(api.sessionStateObservable);
}

const veloLib = createVeloLibraryAdapter(api, window);

ReactDOM.render(
  <PayorApp
    appVariant={ApplicationVariantEnum.PayorPortal}
    appVersion={appVersion}
    gitSha={gitSha}
    veloLib={veloLib}
    initialSearch={window.location.search}
    messages={JSON.parse(process.env.REACT_APP_INTL || null)}
  />,
  document.querySelector('#root')
);
