import fetch from 'isomorphic-fetch';

/**
 * This is a drop-in replacement api that conforms to the standard fetch
 * specification.
 * The code traps an safari specific edge case whereby redirected responses that
 * are cross-origin do not automatically inherit http headers. For redirected
 * requests that require authorization headers this results in a 401 return
 * code. The solution to this is then to copy the original headers (which
 * includes the authorization headers) and re-request.
 *
 * This is only an issue when dealing with CORS specific requests, however to
 * prove out the nature of the platform which must assume that the application
 * operates in a CORS context.
 *
 * @see https://stackoverflow.com/questions/39354408/safari-rejects-redirected-cors-request-due-to-browser-set-headers
 * @type {fetch}
 */
export default function trapRedirectErrorFetch(url, init) {
  return fetch(url, init).then(function onFetchResponse(response) {
    // safari related issue that removes auth headers
    if (response.redirected && response.status === 401) {
      return fetch(response.url, { headers: init.headers });
    }
    return response;
  });
}
