import { useIntl } from 'react-intl';
import { PayorAccountType } from 'velo-data';
import { oneOf } from 'prop-types';

PayorAccountTypeText.propTypes = {
  type: oneOf(Object.values(PayorAccountType)).isRequired,
};

function fromIntl(intl, type) {
  switch (type) {
    case PayorAccountType.WUBS_DECOUPLED:
      return intl.formatMessage({
        defaultMessage: 'WUBS decoupled',
      });
    case PayorAccountType.PRIVATE_COUPLED:
      return intl.formatMessage({
        defaultMessage: 'WUBS private coupled',
      });
    case PayorAccountType.PRIVATE:
      return intl.formatMessage({
        defaultMessage: 'Private',
      });
    default:
      return intl.formatMessage({
        defaultMessage: 'FBO',
      });
  }
}

PayorAccountTypeText.fromIntl = fromIntl;

export function PayorAccountTypeText({ type }) {
  return fromIntl(useIntl(), type);
}
