import { useState } from 'react';
import { getLocalisedPaymentChannelRules } from 'velo-react-components/src/Localisation';
import {
  useAppContext,
  contextCountriesSelector,
  contextPaymentChannelRulesSelector,
} from '../../context';
import { supportedPaymentCountries } from '../../selectors';

export const defaultCountry = 'US';
export const defaultCurrencies = ['USD'];

export function usePaymentChannelCountries(initialCountry = defaultCountry) {
  const [selectedCountry, onCountryChange] = useState(initialCountry);
  const context = useAppContext();
  const countries = contextCountriesSelector(context);
  const paymentChannelRules = getLocalisedPaymentChannelRules(
    contextPaymentChannelRulesSelector(context).bank
  );

  const { currencies } = countries.find(
    ({ isoCountryCode }) => isoCountryCode === selectedCountry
  ) || {
    currencies: defaultCurrencies,
  };

  return {
    countries: supportedPaymentCountries(
      countries.map(({ isoCountryCode }) => isoCountryCode),
      paymentChannelRules.bank
    ),
    currencies,
    onCountryChange,
    paymentChannelRules,
    selectedCountry,
  };
}
