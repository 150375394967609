import { ResultContainer } from '../ResultContainer';
import { VeloAppLoading } from '../VeloAppLoading';

const VeloAppLoadingContainer = ResultContainer({
  LoadingComponent: VeloAppLoading,
  ErrorComponent: VeloAppLoading.Error,
});

VeloAppLoadingContainer.testIds = VeloAppLoading.testIds;
VeloAppLoadingContainer.LoadingComponent = VeloAppLoading;
VeloAppLoadingContainer.ErrorComponent = VeloAppLoading.Error;

export { VeloAppLoadingContainer };
