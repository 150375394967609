import React from 'react';
import { oneOf, bool, node } from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import right from './slideRight.module.scss';
import left from './slideLeft.module.scss';
import down from './slideDown.module.scss';
import top from './slideTop.module.scss';

const ANIMATION_TIME_MS = 150;

const directionMap = { right, left, down, top };

Slide.propTypes = {
  direction: oneOf(Object.keys(directionMap)).isRequired,
  animate: bool,
  children: node,
};

function Slide({ direction, animate, children, ...other }) {
  return (
    <CSSTransition
      timeout={ANIMATION_TIME_MS}
      classNames={directionMap[direction]}
      exit={animate}
      enter={animate}
      {...other}
    >
      {children}
    </CSSTransition>
  );
}

function SlideLeft({ children, ...props }) {
  return (
    <Slide direction="left" {...props}>
      {children}
    </Slide>
  );
}

function SlideRight({ children, ...props }) {
  return (
    <Slide direction="right" {...props}>
      {children}
    </Slide>
  );
}

function SlideDown({ children, ...props }) {
  return (
    <Slide direction="down" {...props}>
      {children}
    </Slide>
  );
}
function SlideTop({ children, ...props }) {
  return (
    <Slide direction="top" {...props}>
      {children}
    </Slide>
  );
}

export { SlideLeft, SlideRight, SlideTop, SlideDown };
