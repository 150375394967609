import React from 'react';
import { bool, node } from 'prop-types';
import styled from 'astroturf/react';
import { VeloTypography } from '../../VeloTypography';
import { VeloSkeleton } from '../../VeloSkeleton';
import { VeloGrid } from '../../VeloGrid';

const Container = styled('div')`
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

const Value = styled(VeloTypography)`
  word-break: break-all;
`;

const Skeleton = styled(VeloSkeleton)`
  width: 25%;
`;

const Action = styled(VeloGrid.Cell)`
  text-align: right;
`;

PaymentViewItem.propTypes = {
  /** The label to use. */
  label: node,
  /** The value to display, */
  value: node,
  /** Render as a loading skeleton. */
  skeleton: bool,
  /** Render an action button. */
  action: node,
};

/**
 * Render a Payment label/value pair.
 */
function PaymentViewItem({
  label,
  value,
  skeleton,
  className,
  action,
  ...other
}) {
  const actionCellSpan = { desktop: 4, tablet: 2, phone: 2 };
  const labelCellSpan = action
    ? { desktop: 8, tablet: 6, phone: 2 }
    : { desktop: 12, tablet: 12, phone: 12 };
  return value || skeleton ? (
    <Container className={className}>
      <VeloGrid compact>
        <VeloGrid.Cell {...labelCellSpan}>
          <VeloTypography use="itemLabel" tag="div">
            {skeleton ? <Skeleton /> : label}
          </VeloTypography>

          <Value use="itemContent" tag="div" {...other}>
            {skeleton ? <VeloSkeleton /> : value}
          </Value>
        </VeloGrid.Cell>
        {action && <Action {...actionCellSpan}>{action}</Action>}
      </VeloGrid>
    </Container>
  ) : null;
}

export { PaymentViewItem };
