/* eslint-disable no-undef */

import { fetchRemote } from './fetchRemote';

export const loadModule = async (
  remoteName,
  remoteUrl,
  moduleName,
  scope = 'default'
) => {
  if (!(remoteName in window)) {
    await __webpack_init_sharing__(scope);

    const fetchedContainer = await fetchRemote(
      `${remoteUrl}/remoteEntry.js`,
      remoteName
    );

    await fetchedContainer.init(__webpack_share_scopes__[scope]);
  }

  const container = window[remoteName];

  const factory = await container.get(`./${moduleName}`);

  const Module = factory();

  return Module;
};
