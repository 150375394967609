import React from 'react';
import { node } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Content } from '../Content';
import { PageHeader } from '../PageHeader';

PayorFundingListPage.propTypes = {
  /** The list of fundings to render. */
  list: node,
};

/**
 * Used to display a list of Fundings in the Payor Portal,
 * adding a suitable page title.
 */
function PayorFundingListPage({ children }) {
  return (
    <Content
      header={
        <PageHeader title={<FormattedMessage defaultMessage="Funding" />} />
      }
    >
      {children}
    </Content>
  );
}

export { PayorFundingListPage };
