import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'astroturf/react';
import { PayeeCreateType } from '../../PayeeCreateType';
import { PayeeAccountType } from '../../PayeeAccountType';
import { VeloStepper } from '../../VeloStepper';
import { VeloIcon } from '../../VeloIcon';
import { TestIds } from '../testIds';
import { AddPayeeMode } from '../propTypes';

const StepContent = styled(VeloStepper.Content)`
  padding-right: 0rem;
`;

function TypeSelectionStep({ mode, type, loading, onChange }) {
  const creating = mode === AddPayeeMode.PAYOR_CREATE;
  const Selection = {
    Component: creating ? PayeeCreateType : PayeeAccountType,
    span: creating ? 4 : 6,
    title: creating ? (
      <FormattedMessage defaultMessage="Upload a file to create one or more Payees, or add a single Payee manually." />
    ) : (
      <FormattedMessage defaultMessage="Choose account type" />
    ),
  };

  return (
    <VeloStepper.Step>
      <VeloStepper.Label
        icon={type === undefined ? '1' : <VeloIcon icon="check" />}
        text={Selection.title}
        skeleton={loading}
      />
      <StepContent>
        <Selection.Component
          value={type}
          onChange={onChange}
          desktopSpan={Selection.span}
          skeleton={loading}
          individualTestId={TestIds.INDIVIDUAL}
          companyTestId={TestIds.COMPANY}
          csvUploadId={TestIds.CSV}
        />
      </StepContent>
    </VeloStepper.Step>
  );
}

export { TypeSelectionStep };
