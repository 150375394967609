import React from 'react';
import { FormattedMessage } from 'react-intl';
import { postcodeValue } from 'velo-data';
import { ProfileField, PostalCode } from './FormFieldTypes';
import { SelectRegion } from './SelectRegion';
import { findCountryEntity } from '../utils';

export const AddressFieldsAttributesMap = {
  ADDRESS_1: {
    name: 'line1',
    label: <FormattedMessage defaultMessage="Address 1" />,
  },
  ADDRESS_2: {
    name: 'line2',
    label: <FormattedMessage defaultMessage="Address 2" />,
  },
  ADDRESS_3: {
    name: 'line3',
    label: <FormattedMessage defaultMessage="Address 3" />,
  },
  ADDRESS_4: {
    name: 'line4',
    label: <FormattedMessage defaultMessage="Address 4" />,
  },
  CITY: { name: 'city', label: <FormattedMessage defaultMessage="City" /> },
  COUNTY: { name: 'countyOrProvince' },
  ZIP: { name: 'zipOrPostcode' },
};

/**
 * Return an array of fields representing an address.
 * Localised based on the supplied `country` param.
 */
export const AddressFields = (
  country,
  regions,
  numberOfOptionalAdressLines = 3,
  // Used when the region field is overriden as a text field
  regionFieldOverride = false,
  countries
) => {
  const { hidden: postcodeHidden = false, ...postcodeProps } =
    PostalCode(country);

  const countryConfiguration = findCountryEntity(countries, country);

  return [
    {
      ...ProfileField(`_`, 'Please enter an address', 1, 100),
      ...AddressFieldsAttributesMap.ADDRESS_1,
      required: true,
    },
    ...[
      {
        ...ProfileField(`_`, null, 1, 100),
        ...AddressFieldsAttributesMap.ADDRESS_2,
      },
      {
        ...ProfileField(`_`, null, 1, 100),
        ...AddressFieldsAttributesMap.ADDRESS_3,
      },
      {
        ...ProfileField(`_`, null, 1, 100),
        ...AddressFieldsAttributesMap.ADDRESS_4,
      },
    ].slice(0, numberOfOptionalAdressLines),
    {
      ...ProfileField('_', 'Please enter a city', 1, 50),
      ...AddressFieldsAttributesMap.CITY,
      required: true,
      desktop: 4,
    },
    ...(regions.length || regionFieldOverride
      ? [
          {
            Component: SelectRegion,
            country,
            ...AddressFieldsAttributesMap.COUNTY,
            options: regions,
            required: true,
            desktop: 4,
          },
        ]
      : []),
    ...(postcodeHidden
      ? []
      : [
          {
            ...postcodeProps,
            ...AddressFieldsAttributesMap.ZIP,
            required:
              countryConfiguration?.addressRules?.postalCode?.mandatory ?? true,
            desktop: 4,
          },
        ]),
  ];
};

/**
 * Ensure an object of address fields is using valid data.
 */
export const sanitizeAddressFields = (fields, country) => ({
  ...fields,
  zipOrPostcode: fields.zipOrPostcode || postcodeValue(country),
  ...(fields.countyOrProvince === '' ? { countyOrProvince: null } : {}),
});
