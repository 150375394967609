import styled from 'astroturf/react';
import { bool } from 'prop-types';

const VeloDivider = styled('hr').attrs({
  role: 'separator',
})`
  @import 'velo-variables';

  height: 0;
  margin: 0;
  border: none;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: velo-color('token-color-system-divider');

  &.padded {
    margin: 1rem 0;
  }

  &.vertical {
    margin: 0;
    height: 100%;
    max-height: 100vh;
    border-bottom: none;
    display: inline-block;
    border-left-width: 1px;
    border-left-style: solid;
    border-left-color: velo-color('token-color-system-divider');
  }
`;

VeloDivider.propTypes = {
  /* Add space before and after the divicer. */
  padded: bool,
  /* Vertical divider when using flexbox. */
  vertical: bool,
};

export { VeloDivider };
