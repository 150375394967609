import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'astroturf/react';
import { PayeeCreationTypes } from 'velo-data';
import { VeloStepper } from '../../VeloStepper';
import { Loading } from '../Loading';
import { PersonForm } from '../PersonForm';
import { BusinessForm } from '../BusinessForm';
import { RootId, TestIds } from '../testIds';
import { FormButtons } from './FormButtons';
import { AddPayeeMode } from '../propTypes';

const StepContent = styled(VeloStepper.Content)`
  padding-right: 0rem;
`;

const StyledBusinessForm = styled(BusinessForm)`
  padding: 0;
`;

const StyledPersonForm = styled(PersonForm)`
  padding: 0;
`;

function Form({ type, props }) {
  switch (type) {
    case PayeeCreationTypes.INDIVIDUAL:
      return <StyledPersonForm {...props} />;
    case PayeeCreationTypes.COMPANY:
      return <StyledBusinessForm {...props} />;
    default:
      return <Loading />;
  }
}

function PayeeCreateForms({
  mode,
  type,
  loading,
  formProps,
  children,
  errorComponent,
}) {
  const { submitting, dirty, ...props } = formProps;
  return (
    <form onSubmit={props.onSubmit} id={RootId} data-testid={TestIds.FORM}>
      <VeloStepper.Step last>
        <VeloStepper.Label
          icon="2"
          text={<FormattedMessage defaultMessage="Enter details" />}
          disabled={type === undefined}
          skeleton={loading}
        />
        {/*Render the Main Form Component*/}
        <StepContent>
          <Form type={type} props={props} />
          {/* Render the Optional Sub Sections */}
          {children}
        </StepContent>
      </VeloStepper.Step>
      {/* Error */}
      {errorComponent}
      {/* Buttons */}
      <FormButtons
        mode={props.mode}
        onCancel={props.onCancel}
        submitDisabled={
          type === undefined || (mode === AddPayeeMode.PAYOR_EDIT && !dirty)
        }
        submitting={submitting}
        loading={loading}
      />
    </form>
  );
}

export { PayeeCreateForms };
