import React from 'react';
import { arrayOf, func, shape } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { PaymentViewPropTypes } from '../propTypes';
import { ResultContainer } from '../../ResultContainer';
import { PaymentViewContainer } from '../PaymentViewContainer';
import { PaymentViewLoading } from '../PaymentViewLoading';
import { PaymentViewError } from '../PaymentViewError';
import { PaymentView } from '../PaymentView';
import { PaymentViewFooter } from './PaymentViewFooter';
import { BusinessPaymentViewDetails } from './BusinessPaymentViewDetails';
import { BusinessPaymentViewHistory } from './BusinessPaymentViewHistory';
import { VeloPropTypes } from '../../VeloPropTypes';

const RailsIdsWithWubsReceipt = {
  WUBS_DECOUPLED: 'WUBS_DECOUPLED',
  WU: 'WU',
};

/* Only show the footer for WUBS payments - and those of a post Instructed on Rails State*/
function showPaymentWubsReceipt(payment) {
  return (
    RailsIdsWithWubsReceipt[payment.railsId] &&
    (payment.status === 'ACCEPTED_BY_RAILS' || payment.status === 'CONFIRMED')
  );
}

const LoadingComponent = ({ result, onClose }) => {
  return (
    <PaymentViewLoading onClose={onClose} tabs>
      <BusinessPaymentViewDetails result={result} />
      <BusinessPaymentViewHistory result={result} />
    </PaymentViewLoading>
  );
};

const BaseComponent = ({
  result,
  onClose,
  renderTitle,
  onViewPaymentReceipt,
  paymentChannelRules,
  ...other
}) => {
  const tabMap = [
    {
      label: <FormattedMessage defaultMessage="Information" />,
      renderContent: () => (
        <>
          <BusinessPaymentViewDetails
            result={result}
            paymentChannelRules={paymentChannelRules}
            {...other}
          />
          {showPaymentWubsReceipt(result) && (
            <PaymentViewFooter
              onClick={onViewPaymentReceipt}
              paymentCurrency={result.paymentCurrency}
            />
          )}
        </>
      ),
    },
    {
      label: <FormattedMessage defaultMessage="Tracker" />,
      renderContent: () => (
        <BusinessPaymentViewHistory
          result={result}
          paymentChannelRules={paymentChannelRules}
        />
      ),
    },
  ];

  return (
    <PaymentView onClose={onClose} title={renderTitle(result)} tabs={tabMap} />
  );
};

const Component = ResultContainer({
  ErrorComponent: PaymentViewError,
  LoadingComponent,
})(BaseComponent);

BusinessPaymentView.propTypes = {
  ...PaymentViewPropTypes,
  renderTitle: func.isRequired,
  /** The payment channel rules to render. */
  paymentChannelRules: arrayOf(shape(VeloPropTypes.PaymentChannelRuleType)),
};

function BusinessPaymentView(props) {
  return (
    <PaymentViewContainer
      {...props}
      render={(props) => <Component {...props} />}
    />
  );
}

BusinessPaymentView.BusinessPaymentViewHistory = BusinessPaymentViewHistory;

BusinessPaymentView.testIds = PaymentViewFooter.testIds;

export { BusinessPaymentView };
