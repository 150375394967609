export function createTokenStore(storage, keyName) {
  return {
    save(token) {
      /**
       * For mobile safari private browsing
       * @see https://developer.mozilla.org/en-US/docs/Web/API/Storage/setItem#Exceptions
       */
      try {
        storage.setItem(keyName, token);
      } catch (ex) {
        console.warn('Unable to store session token');
      }
      return token;
    },
    load() {
      let token = undefined;
      try {
        token = storage.getItem(keyName);
      } catch (ex) {
        console.warn('Failed to getItem from storage');
      }
      return token;
    },
    clear() {
      try {
        storage.clear();
      } catch (ex) {
        console.warn('Failed to clear storage');
      }
    },
  };
}
