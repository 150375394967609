/* eslint-disable default-case */
import React, { useRef } from 'react';
import { func, node, string } from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import styled from 'astroturf/react';
import { VeloTypography } from '../VeloTypography';
import { VeloIcon } from '../VeloIcon';
import { VeloButton } from '../VeloButton';

const root = 'velo-file-upload';

const TestIds = {
  ICON: `${root}-icon`,
  INPUT: `${root}-input`,
  BUTTON: `${root}-button`,
  DRAG_DROP: `${root}-drag-drop`,
};

const Container = styled('div')`
  @import 'velo-variables';

  background-color: velo-color('token-color-system-page-surface');
  border: 0.04rem dashed velo-color('token-color-text-muted');
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: velo-breakpoint(S)) {
    padding: 2rem;
  }
`;

const Input = styled('input')`
  display: none;
`;

const Icon = styled(VeloIcon)`
  @import 'velo-variables';

  color: velo-color('token-color-brand-primary');
  margin: 0.4rem 0;
  padding: 0.8rem;
`;

const Background = styled('h3')`
  @import 'velo-variables';
  @import 'velo-mixins';

  @include velo-font-weight(medium);
  width: 5rem;
  margin: 1rem 0;
  text-align: center;
  background-image: linear-gradient(
    velo-color('token-color-system-divider'),
    velo-color('token-color-system-divider')
  );
  background-size: 100% 0.1rem;
  background-repeat: no-repeat;
  background-position: center;
`;

const Divider = styled(VeloTypography).attrs({ use: 'summaryData' })`
  @import 'velo-variables';

  text-transform: uppercase;
  background: velo-color('token-color-system-page-surface');
  padding: 0 0.3rem;
`;

VeloFileUpload.propTypes = {
  /** A custom drag + drop file upload label  */
  dragDropLabel: node,
  /** A custom file upload button label  */
  buttonLabel: node,
  /** A custom accept files of type label  */
  acceptTypeLabel: node,
  /** A custom file upload icon  */
  icon: string,
  /** The callback when a file has been added */
  onAdded: func.isRequired,
  /** The accepted file formats */
  acceptedFileFormats: string,
};

/**
 * File Upload component.
 * Allows click + drag/drop file upload then returns file contents to
 * onAdded callback
 */
function VeloFileUpload({
  buttonLabel,
  dragDropLabel,
  acceptTypeLabel,
  icon = 'file_upload',
  acceptedFileFormats,
  onAdded,
}) {
  const fileInputRef = useRef(null);
  const eventTypes = {
    FILE_DROP: 'drop',
    FILE_CHANGE: 'change',
  };
  const intl = useIntl();
  const formatDragDropLabel =
    dragDropLabel ||
    intl.formatMessage({ defaultMessage: 'Drag files to upload' });
  const formatButtonLabel =
    buttonLabel || intl.formatMessage({ defaultMessage: 'Browse' });
  const getEvent = (event) => {
    event.preventDefault();

    switch (event.type) {
      case eventTypes.FILE_DROP:
      case eventTypes.FILE_CHANGE:
        const { dataTransfer, target } = event;
        const files = dataTransfer ? dataTransfer.files : target.files;
        if (files.length) {
          onAdded(files);
        }
        break;
    }
    // Clear to allow reselect of the same file name
    // i.e. Fail with error, user corrects in file, re-selects file
    event.target.value = '';
  };

  const openFileDialog = () => {
    fileInputRef.current.click();
  };

  return (
    <Container
      role="presentation"
      onDragOver={getEvent}
      onDrop={getEvent}
      data-testid={TestIds.DRAG_DROP}
    >
      <Input
        ref={fileInputRef}
        type="file"
        data-testid={TestIds.INPUT}
        onChange={getEvent}
        accept={acceptedFileFormats || '*'}
      />

      <Icon icon={{ icon, size: 'xlarge' }} data-testid={TestIds.ICON} />

      <VeloTypography>{formatDragDropLabel}</VeloTypography>

      <Background>
        <Divider>
          <FormattedMessage defaultMessage="or" />
        </Divider>
      </Background>

      <VeloButton.Secondary
        type="button"
        data-testid={TestIds.BUTTON}
        onClick={openFileDialog}
      >
        {formatButtonLabel}
      </VeloButton.Secondary>
      {acceptTypeLabel && (
        <VeloTypography use="bodyTextSmaller" tag="p">
          {acceptTypeLabel}
        </VeloTypography>
      )}
    </Container>
  );
}

VeloFileUpload.testIds = TestIds;

export { VeloFileUpload };
