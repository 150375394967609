import styled from 'astroturf/react';

export const VeloCardError = styled('div')`
  @import 'velo-variables';

  display: flex;
  align-items: center;
  background-color: velo-color('token-color-system-error-lighter');
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;

  min-height: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
`;
