import React from 'react';
import { bool, object, string } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styled from 'astroturf/react';
import TestIds from './testIds';
import { VeloTimeline } from '../VeloTimeline';
import { PayoutHeaderFields } from './PayoutHeaderFields';
import { PayoutActions } from './PayoutActions';
import { PayoutPaymentsSummary } from './PayoutPaymentsSummary';
import { VeloDate } from '../VeloDate';
import { VeloTime } from '../VeloTime';
import { SubmittedErrorDetails } from './SubmittedErrorDetails';
import { WithdrawnNotice } from './WithdrawnNotice';
import { VeloLabelledItem } from '../VeloLabelledItem/VeloLabelledItem';
import { VeloGrid } from '../VeloGrid';
import { VeloCurrency } from '../VeloCurrency';
import { VeloTypography } from '../VeloTypography';
import { VeloIcon } from '../VeloIcon';
import { PayoutScheduleInfo } from '../PayoutScheduleInfo';
import { PayoutTypes } from '../PayoutTypes';

const PayoutType = PayoutTypes.PAYOUT_TYPE;

const VeloEmptyInfoIcon = styled(VeloIcon)`
  @import 'velo-variables';
  color: velo-color('token-color-brand-primary');
`;

const EmptyResultContainer = styled('div')`
  @import 'velo-variables';
  background-color: velo-color('token-color-system-info-lighter');
  padding-top: 0.2rem;
  height: 3.2rem;
  width: inherit;
`;

const EmptyIconLabel = styled('div')`
  margin: 0.8rem;
  display: flex;
  align-items: center;
`;

const EmptyResultTypography = styled(VeloTypography).attrs({
  use: 'bodyText',
  tag: 'div',
})`
  margin-left: 0.5rem;
`;

const HeaderGrid = styled(VeloGrid)`
  margin: 0;
  padding: 0;
  &.pad {
    padding-bottom: 1rem;
  }
`;

const SourceAccountInnerGrid = styled(VeloGrid.Inner)`
  grid-gap: 0.5rem;
  padding-top: 0.5rem;
`;

HeaderGrid.propTypes = {
  /**
   * Applies bottom padding to the grid if required
   */
  pad: bool,
};

const JustifiedContainer = styled('div')`
  @import 'velo-mixins';

  @include from-breakpoint(S) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;

const HeaderRow = (props) => <VeloGrid.Cell span="12" {...props} />;

function DateLabel({ label, value }) {
  if (value) {
    return (
      <VeloLabelledItem label={label} data-testid={TestIds.DATE}>
        <VeloDate
          weekday="short"
          day="numeric"
          month="short"
          year="numeric"
          value={value}
        />{' '}
        <VeloTime.Short value={value} />
      </VeloLabelledItem>
    );
  } else {
    return <VeloLabelledItem.Skeleton />;
  }
}

function PaymentsSummary(props) {
  const columns = PayoutHeaderFields.summaryToColumns(props);
  return <PayoutPaymentsSummary columns={columns} />;
}

function Timeline({ payoutType, ...data }) {
  if (
    payoutType !== PayoutType.STANDARD &&
    payoutType !== PayoutType.OPEN_BANKING
  ) {
    const items = PayoutHeaderFields.summaryToTimeline(data);
    const len = items.length;

    if (len) {
      const span = Math.min(12, len * 2 + 2);
      return (
        <HeaderRow span={span}>
          <VeloTimeline items={items} />
        </HeaderRow>
      );
    }
  }
  return null;
}

function Memo({ payoutMemo }) {
  if (payoutMemo) {
    return (
      <VeloLabelledItem
        label={<FormattedMessage defaultMessage="Payout memo" />}
      >
        <span data-testid={TestIds.PAYOUT_MEMO}>{payoutMemo}</span>
      </VeloLabelledItem>
    );
  }
  return null;
}

function DateAndMemo({ dateLabel, date, ...other }) {
  return (
    <>
      <VeloGrid.Cell span={4}>
        <DateLabel label={dateLabel} value={date} />
      </VeloGrid.Cell>
      <VeloGrid.Cell span={8}>
        <Memo {...other} />
      </VeloGrid.Cell>
    </>
  );
}

function ProcessingSourceAccount({ isQuotable }) {
  const copy = isQuotable ? (
    <FormattedMessage defaultMessage="Recalculating source account total." />
  ) : (
    <FormattedMessage defaultMessage="Payout quote unavailable, Payor must re-quote." />
  );
  return (
    <VeloGrid.Cell span={8}>
      <EmptyResultContainer>
        <EmptyIconLabel>
          <VeloEmptyInfoIcon icon="feedback" />
          <EmptyResultTypography>
            <VeloTypography use="bodyTextEmphasised">{copy}</VeloTypography>
          </EmptyResultTypography>
        </EmptyIconLabel>
      </EmptyResultContainer>
    </VeloGrid.Cell>
  );
}
function SourceAccountTotals({ accounts }) {
  return (
    <>
      <VeloGrid.Cell span={4}>
        <VeloLabelledItem
          label={<FormattedMessage defaultMessage="Source Account" />}
        >
          <SourceAccountInnerGrid>
            {accounts.map((account, index) => (
              <VeloGrid.Cell span={12} key={'accountName' + index}>
                <span data-testid={`${TestIds.SOURCE_ACCOUNT}-${index}`}>
                  {account.sourceAccountName}
                </span>
              </VeloGrid.Cell>
            ))}
          </SourceAccountInnerGrid>
        </VeloLabelledItem>
      </VeloGrid.Cell>
      <VeloGrid.Cell span={8}>
        <VeloLabelledItem
          label={<FormattedMessage defaultMessage="Source Total" />}
        >
          <SourceAccountInnerGrid>
            {accounts.map((account, index) => (
              <VeloGrid.Cell
                span={12}
                key={'amount_' + index}
                data-testid={`${TestIds.SOURCE_TOTAL}-${index}`}
              >
                <VeloCurrency
                  value={account.totalPayoutCost}
                  currency={account.currency}
                />
              </VeloGrid.Cell>
            ))}
          </SourceAccountInnerGrid>
        </VeloLabelledItem>
      </VeloGrid.Cell>
    </>
  );
}

Loading.propTypes = {
  /** Indicates if a skeleton for actions should be rendered */
  actions: bool,
};

function Loading({ actions }) {
  return (
    <HeaderGrid pad>
      <HeaderRow>
        <JustifiedContainer>
          <PayoutPaymentsSummary.Loading />
          {actions && <PayoutActions.Loading />}
        </JustifiedContainer>
      </HeaderRow>
      <HeaderRow span="6">
        <VeloLabelledItem.Skeleton />
      </HeaderRow>
    </HeaderGrid>
  );
}

Instruct.propTypes = {
  /** Action handlers for the Instruct Page*/
  actions: object,
  /** Object containing rejected payment details */
  rejectedPaymentErrors: object.isRequired,
  /** A string to represent the submittedDate */
  submittedDateTime: string.isRequired,
};

function Instruct({
  actions,
  rejectedPaymentErrors,
  submittedDateTime,
  accounts,
  schedule,
  isQuotable,
  ...data
}) {
  return (
    <HeaderGrid>
      <HeaderRow>
        <JustifiedContainer>
          <PaymentsSummary {...data} />
          <PayoutActions {...actions} schedule={schedule} />
        </JustifiedContainer>
      </HeaderRow>
      <DateAndMemo
        dateLabel={<FormattedMessage defaultMessage="Submitted Date/Time" />}
        date={submittedDateTime}
        data-testid={TestIds.SUBMITTED_DATE}
        {...data}
      />
      {accounts.length > 0 ? (
        <SourceAccountTotals accounts={accounts} />
      ) : (
        <ProcessingSourceAccount isQuotable={isQuotable} />
      )}

      {schedule && (
        <VeloGrid.Cell span={12}>
          <PayoutScheduleInfo scheduledFor={schedule.scheduledFor} />
        </VeloGrid.Cell>
      )}

      <HeaderRow>
        <SubmittedErrorDetails rejectedPaymentErrors={rejectedPaymentErrors} />
      </HeaderRow>
      <Timeline {...data} />
    </HeaderGrid>
  );
}

InstructError.propTypes = {
  /** Action handlers for the Instruct Page*/
  actions: object,
  /** A string to represent the submittedDate */
  submittedDateTime: string,
  /** Object containing rejected payment details */
  rejectedPaymentErrors: object,
};

function InstructError({
  rejectedPaymentErrors,
  actions,
  submittedDateTime,
  ...data
}) {
  return (
    <HeaderGrid>
      <HeaderRow>
        <JustifiedContainer>
          <PaymentsSummary {...data} />
          <PayoutActions {...actions} />
        </JustifiedContainer>
      </HeaderRow>
      <DateAndMemo
        dateLabel={<FormattedMessage defaultMessage="Submitted Date/Time" />}
        date={submittedDateTime}
        {...data}
      />
      <HeaderRow>
        <SubmittedErrorDetails rejectedPaymentErrors={rejectedPaymentErrors} />
      </HeaderRow>
      <Timeline {...data} />
      <HeaderRow />
    </HeaderGrid>
  );
}

Details.propTypes = {
  /** the date the payout was instructed */
  instructedDateTime: string,
  submittedDateTime: string,
};
function Details({ instructedDateTime, submittedDateTime, ...data }) {
  // The submitted date can be displayed as the instructed date when instructed date is not supplied.
  const date = instructedDateTime || submittedDateTime;

  return (
    <HeaderGrid pad>
      <HeaderRow>
        <JustifiedContainer>
          <PaymentsSummary {...data} />
        </JustifiedContainer>
      </HeaderRow>
      <DateAndMemo
        dateLabel={<FormattedMessage defaultMessage="Instructed Date/Time" />}
        date={date}
        {...data}
      />
      <Timeline {...data} />
    </HeaderGrid>
  );
}

function Withdrawn({ withdrawnDateTime }) {
  return (
    <HeaderGrid pad>
      <HeaderRow span="8">
        <WithdrawnNotice />
      </HeaderRow>
      <HeaderRow>
        <DateLabel
          label={<FormattedMessage defaultMessage="Withdrawn Date/Time" />}
          value={withdrawnDateTime}
        />
      </HeaderRow>
    </HeaderGrid>
  );
}

export const PayoutHeader = {
  Instruct,
  InstructError,
  Details,
  Loading,
  Withdrawn,
  TestIds,
  Actions: PayoutActions,
};
