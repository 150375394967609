/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import { bool, func, node, object, string } from 'prop-types';
import styled from 'astroturf/react';
import { FormField } from '@rmwc/formfield';
import { Checkbox } from '@rmwc/checkbox';
import { VeloTypography } from '../VeloTypography';
import '@material/checkbox/dist/mdc.checkbox.css';
import '@material/form-field/dist/mdc.form-field.css';

const StyledFormField = styled(FormField)`
  @import 'velo-variables';
  @import '@material/theme/mixins';

  font-family: var(--ds-typography-font-family);

  :global(.mdc-checkbox--disabled + label) {
    /**
     * When the checkbox is disabled ensure that the label 
     * element is displayed using the theme disabled colour.
     */
    color: velo-color('token-color-text-disabled');
  }

  /**
   * Ensure checkboxes are accessible
   */
  :global(.mdc-checkbox__background) {
    border-color: velo-color('token-color-text-muted') !important;
  }

  :global(.mdc-checkbox__native-control:disabled ~ .mdc-checkbox__background) {
    border-color: velo-color('token-color-border-disabled') !important;
  }

  :global(.mdc-checkbox__native-control:enabled:checked
      ~ .mdc-checkbox__background) {
    border-color: velo-color('token-color-brand-primary') !important;
  }

  /**
   * When displaying the checkbox after the label we
   * need to ensure that the label does not end up
   * centered.
   */
  &:global(.mdc-form-field--align-end) {
    display: flex;

    label {
      margin-left: 0;
    }
  }
`;

const Text = styled(VeloTypography)`
  cursor: pointer;
`;

VeloCheckbox.propTypes = {
  /** Custom CSS class(es) applied to the root element. */
  className: string,
  /** The checkbox ID. */
  id: string.isRequired,
  /** The checked state. */
  checked: bool,
  /** Called when the checked state changes. */
  onChange: func,
  /** Disables the checkbox and label. */
  disabled: bool,
  /** Position the checkbox after the label. */
  alignEnd: bool,
  /** The checkbox test ID. */
  'data-testid': string.isRequired,
  /** The label text. */
  label: node,
  /** The label text. */
  children: node,
  /** Additional props to pass to the root element. */
  rootProps: object,
  /** Additional props to pass to the label element. */
  labelProps: object,
};

/**
 * Velo Checkbox component.
 *
 * Ensures a checkbox renders using the primary theme colour
 * with a suitable label, with support for a full disabled
 * state.
 */
function VeloCheckbox({
  className,
  rootProps,
  labelProps,
  id,
  alignEnd,
  label,
  children,
  ...other
}) {
  return (
    <StyledFormField className={className} alignEnd={alignEnd} {...rootProps}>
      <Checkbox id={id} {...other} />
      <Text use="fieldText" tag="label" htmlFor={id} {...labelProps}>
        {label || children}
      </Text>
    </StyledFormField>
  );
}

export { VeloCheckbox };
