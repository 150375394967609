import map from 'callbag-map';
import pipe from 'callbag-pipe';
import makeSubject from 'callbag-subject';
import startWith from 'callbag-start-with';
import remember from 'callbag-remember';
import take from 'callbag-take';

export function createSessionContext(baseSessionState) {
  const stateSubject = makeSubject();
  const onSessionState = (payload, mfa) =>
    stateSubject(1, { ...baseSessionState, ...payload, mfa });

  const sessionStateObservable = pipe(
    stateSubject,
    // start with the base state, so there is always some config
    startWith(baseSessionState),
    remember
  );

  function mapLatestState(mapper) {
    return pipe(sessionStateObservable, take(1), map(mapper));
  }

  return {
    onSessionState,
    sessionStateObservable,
    mapLatestState,
  };
}
