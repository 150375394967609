import React from 'react';
import { func, string } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { VeloTypography } from '../VeloTypography';
import { PaymentReceiptContainer } from './PaymentReceiptContainer';

PaymentReceiptError.propTypes = {
  error: string.isRequired,
  onClose: func.isRequired,
};

function PaymentReceiptError({ error, onClose }) {
  return (
    <PaymentReceiptContainer onClose={onClose}>
      <VeloTypography use="secondaryHeader" tag="h1">
        <FormattedMessage defaultMessage="An error occurred" />
      </VeloTypography>
      <VeloTypography use="bodyText" tag="div">
        {error}
      </VeloTypography>
    </PaymentReceiptContainer>
  );
}

export { PaymentReceiptError };
