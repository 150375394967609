import styled from 'astroturf/react';
import { bool } from 'prop-types';

const FieldContainer = styled('div')`
  margin-bottom: 0.5rem;

  &.offsetTop {
    margin-top: 1.5rem;
  }
`;

FieldContainer.propTypes = {
  /** Add additional top margin. */
  offsetTop: bool,
};

export { FieldContainer };
