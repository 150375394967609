import React from 'react';
import { arrayOf, oneOf, func, node } from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import styled from 'astroturf/react';
import { Content } from '../Content';
import { VeloStepper } from '../VeloStepper';
import { VeloCard } from '../VeloCard';
import { VeloIconButton } from '../VeloIconButton';
import { VeloRadioTileGroup } from '../VeloRadioTileGroup';

const ReportTypes = {
  TRANSACTION: 'transaction',
  WUBS_PAYMENTS: 'wubs-payments',
  DUPLICATE_PAYEES: 'duplicate-payees',
  PAYOR_SETTLEMENT: 'payor-settlement',
  PAYEES_STATUS_REPORT: 'payees-status-report',
};

const TestIds = {
  TRANSACTION_REPORT_TILE: 'transaction-report-tile',
  WUBS_PAYMENTS_REPORT_TILE: 'wubs-payments-report-tile',
  DUPLICATE_PAYEES_REPORT_TILE: 'duplicate-payees-report-tile',
  PAYOR_SETTLEMENT_REPORT_TILE: 'payor-settlement-report-tile',
  PAYEES_STATUS_REPORT_TILE: 'payees-status-report-tile',
  CLOSE_BUTTON: 'reports-page-close-button',
};

const IconButton = styled(VeloIconButton)`
  @import 'velo-variables';
  display: none;

  @media (max-width: velo-breakpoint(XS)) {
    display: block;
  }
`;

const ReportTiles = {
  [ReportTypes.TRANSACTION]: (props) => (
    <VeloRadioTileGroup.Tile
      {...props}
      title={<FormattedMessage defaultMessage="Transaction Report" />}
      label={
        <FormattedMessage defaultMessage="Contains the history of all transactions." />
      }
      value={ReportTypes.TRANSACTION}
      data-testid={TestIds.TRANSACTION_REPORT_TILE}
    />
  ),
  [ReportTypes.WUBS_PAYMENTS]: (props) => (
    <VeloRadioTileGroup.Tile
      {...props}
      title={<FormattedMessage defaultMessage="WUBS Payments Report" />}
      label={
        <FormattedMessage defaultMessage="Contains the history of WUBS payments for Payors." />
      }
      value={ReportTypes.WUBS_PAYMENTS}
      data-testid={TestIds.WUBS_PAYMENTS_REPORT_TILE}
    />
  ),
  [ReportTypes.DUPLICATE_PAYEES]: (props) => (
    <VeloRadioTileGroup.Tile
      {...props}
      title={<FormattedMessage defaultMessage="Duplicates Report" />}
      label={
        <FormattedMessage defaultMessage="Contains all duplicated payee names or payee addresses." />
      }
      value={ReportTypes.DUPLICATE_PAYEES}
      data-testid={TestIds.DUPLICATE_PAYEES_REPORT_TILE}
    />
  ),
  [ReportTypes.PAYOR_SETTLEMENT]: (props) => (
    <VeloRadioTileGroup.Tile
      {...props}
      title={<FormattedMessage defaultMessage="Payor Settlement Report" />}
      label={
        <FormattedMessage defaultMessage="Contains the settlement history for Payors." />
      }
      value={ReportTypes.PAYOR_SETTLEMENT}
      data-testid={TestIds.PAYOR_SETTLEMENT_REPORT_TILE}
    />
  ),
  [ReportTypes.PAYEES_STATUS_REPORT]: (props) => (
    <VeloRadioTileGroup.Tile
      {...props}
      title={<FormattedMessage defaultMessage="Payees Status Report" />}
      label={
        <FormattedMessage defaultMessage="Contains all payee statuses in the selected range." />
      }
      value={ReportTypes.PAYEES_STATUS_REPORT}
      data-testid={TestIds.PAYEES_STATUS_REPORT_TILE}
    />
  ),
};

ReportsPage.propTypes = {
  /** On mobile the ReportsPage will be full screen. This function is used
   * to close the ReportsPage on mobile.
   */
  onClose: func.isRequired,
  /** The currently selected report. */
  selectedType: oneOf(Object.values(ReportTypes)),
  /** A function used to navigate to the current selected report */
  onSelectReport: func.isRequired,
  /** A list of the visible report types */
  reportTypes: arrayOf(oneOf(Object.values(ReportTypes))).isRequired,
  /** The current form that is rendered in the reports page */
  children: node.isRequired,
};
ReportsPage.testIds = TestIds;
ReportsPage.types = ReportTypes;

function ReportsPage({
  children,
  onSelectReport,
  selectedType,
  reportTypes,
  onClose,
}) {
  const intl = useIntl();
  return (
    <Content>
      <VeloCard>
        <VeloCard.Header>
          <VeloCard.HeaderTitle>
            <FormattedMessage defaultMessage="Export a Report" />
          </VeloCard.HeaderTitle>
          <IconButton
            icon="close"
            title={intl.formatMessage({ defaultMessage: 'Close' })}
            onClick={onClose}
            data-testid={TestIds.CLOSE_BUTTON}
          />
        </VeloCard.Header>
        <VeloCard.Body>
          <VeloStepper>
            {/* Step 1 */}
            <VeloStepper.Step>
              <VeloStepper.Label
                icon="1"
                text={<FormattedMessage defaultMessage="Choose a report" />}
              />
              <VeloStepper.Content>
                <VeloRadioTileGroup
                  value={selectedType}
                  onChange={onSelectReport}
                  desktopSpan={6}
                >
                  {reportTypes.map((reportType, index) =>
                    ReportTiles[reportType]({ key: `report-tile-${index}` })
                  )}
                </VeloRadioTileGroup>
              </VeloStepper.Content>
            </VeloStepper.Step>

            {/* Step 2 */}
            <VeloStepper.Step>
              <VeloStepper.Label
                icon="2"
                text={
                  <FormattedMessage defaultMessage="Configure the report" />
                }
              />
              <VeloStepper.Content>{children}</VeloStepper.Content>
            </VeloStepper.Step>
          </VeloStepper>
        </VeloCard.Body>
      </VeloCard>
    </Content>
  );
}

export { ReportsPage };
