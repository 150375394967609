import React, { useMemo } from 'react';
import {
  Content,
  UserForm,
  VeloCardForm,
  VeloNotification,
} from 'velo-react-components';
import {
  unregisterMFA,
  roleUpdate,
  userDetailsUpdate as updateUserDetails,
  enableUserV2,
  disableUserV2,
  getUserByIdV2,
} from 'velo-api/src/entities/users';
import { getPayorByIdV2 } from 'velo-api/src/entities/payors';
import { forkResult } from '../../selectors';
import { usePresenter, useCallbackFnAsResultState } from '../../hooks';
import { OTPRequiredInterceptor } from '../../containers';
import { UserEditPresenter } from './UserEditPresenter';

const entitySpec = {
  unregisterMFA,
  roleUpdate,
  updateUserDetails,
  enableUser: enableUserV2,
  disableUser: disableUserV2,
  getUserById: getUserByIdV2,
  getPayor: getPayorByIdV2,
};

const forks = {
  none: ({ onClose }) => <UserForm.Loading onClose={onClose} />,
  error: (error, { onClose }) => (
    <VeloCardForm.Error onClose={onClose} error={error} />
  ),
};

const payorForks = {
  ...forks,
  value: ({ user, ...viewModes }, { handlers, ...props }) => {
    return (
      <OTPRequiredInterceptor
        user={user}
        handlers={handlers}
        render={(componentProps) => (
          <UserForm.EditPayor
            {...props}
            {...componentProps}
            {...viewModes}
            data={user}
            defaultCountry="US"
          />
        )}
      />
    );
  },
};

const backOfficeAdminForks = {
  ...forks,
  value: ({ user }, { handlers, ...props }) => (
    <UserForm.EditBackOffice
      {...props}
      {...handlers}
      data={user}
      defaultCountry="US"
    />
  ),
};

const payeeForks = {
  ...forks,
  value: ({ user }, { handlers, ...props }) => (
    <UserForm.EditPayee
      {...props}
      {...handlers}
      data={user}
      defaultCountry="US"
    />
  ),
};

const secureDetailsOTPDialogTitles =
  UserForm.EditPayor.secureDetailsOTPDialogTitles;

const notifications = {
  unregisterMfaNotification: {
    error: VeloNotification.types.USER_UNREGISTER_MFA_FAILURE,
    success: VeloNotification.types.USER_UNREGISTER_MFA_SUCCESS,
  },
  userDetailsNotification: {
    error: VeloNotification.types.USER_DETAILS_UPDATE_FAILURE,
    success: VeloNotification.types.USER_DETAILS_UPDATE_SUCCESS,
  },
};

function useUserEditPresenter({ userId, role }, editPayorUser, editPayeeUser) {
  const presentProps = useMemo(
    () => ({
      userId,
      editPayorUser,
      editPayeeUser,
      role,
    }),
    [editPayorUser, editPayeeUser, userId, role]
  );

  const [loader, props] = usePresenter(
    UserEditPresenter,
    entitySpec,
    notifications,
    presentProps
  );

  const [result] = useCallbackFnAsResultState(loader);

  return [result, props];
}

function PayorUsersEditRoute(props) {
  const [result, { handlers, ...componentProps }] = useUserEditPresenter(
    props,
    true
  );

  Object.entries(secureDetailsOTPDialogTitles).forEach(
    ([methodName, title]) => {
      handlers[methodName].title = title;
    }
  );

  return (
    <Content>
      {forkResult(payorForks, result, {
        handlers,
        ...componentProps,
      })}
    </Content>
  );
}

function BackOfficeUsersEditRoute(props) {
  const [result, { handlers, ...componentProps }] = useUserEditPresenter(props);

  return (
    <Content>
      {forkResult(backOfficeAdminForks, result, {
        handlers,
        ...componentProps,
      })}
    </Content>
  );
}

function PayeeUsersEditRoute(props) {
  const [result, { handlers, ...componentProps }] = useUserEditPresenter(
    props,
    false,
    true
  );

  return (
    <Content>
      {forkResult(payeeForks, result, {
        handlers,
        ...componentProps,
      })}
    </Content>
  );
}

export { PayorUsersEditRoute, BackOfficeUsersEditRoute, PayeeUsersEditRoute };
