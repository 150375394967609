import React from 'react';
import { arrayOf, bool, func, node, object } from 'prop-types';
import { useIntl } from 'react-intl';
import styled from 'astroturf/react';
import { VeloIconButton } from '../VeloIconButton';
import { VeloFab } from '../VeloFab';
import { VeloModalSheet } from '../VeloModalSheet';
import { VeloSkeleton } from '../VeloSkeleton';

const testIdPrefix = 'velo-modal-card-content';

const TestIds = {
  CLOSE: `${testIdPrefix}-close-button`,
  SKELETON: `${testIdPrefix}-skeleton`,
  EDIT: `${testIdPrefix}-edit-button`,
  TITLE: `${testIdPrefix}-title`,
};

const Header = styled(VeloModalSheet.Header)`
  margin: 1rem !important;
`;

const HeaderTitle = styled(VeloModalSheet.HeaderTitle)`
  &.disabled {
    opacity: 0.4;
  }
`;

HeaderTitle.propTypes = {
  disabled: bool,
};

const EditFab = styled(VeloFab)`
  @import 'velo-variables';

  top: 60px;
  position: absolute;
  z-index: $velo-mobile-in-front-index-default;
`;

const Body = styled(VeloModalSheet.Body)`
  padding-top: 2rem;

  &.disabled {
    opacity: 0.4;
  }
`;

Body.propTypes = {
  disabled: bool,
};

const TitleLoading = styled(VeloSkeleton)`
  width: 50%;
`;

const propTypes = {
  /**
   * Handler for when the close button is clicked.
   */
  onClose: func.isRequired,
  /**
   * Optional node to display as the title.
   */
  title: node,
  /**
   * Whether to render the content in a disabled mode (for delete etc).
   */
  disabled: bool,
  /**
   * Optional list of VeloIconButton props to render as buttons before the close
   * button.
   */
  buttons: arrayOf(object),
  /**
   * Handler for when the edit FAB is clicked.
   * Omit this prop to hide the FAB.
   */
  onEdit: func,
  /**
   * Use a compact body with no padding.
   */
  compact: bool,
};

function VeloModalSheetCardContent({
  disabled,
  children,
  onClose,
  buttons = [],
  title,
  onEdit,
  editProps = undefined,
  compact,
  ...other
}) {
  const intl = useIntl();
  const buttonElements = buttons.map((p) =>
    React.createElement(VeloIconButton, { disabled, ...p, key: p.icon })
  );
  return (
    <>
      <Header divider={!!title} {...other}>
        <HeaderTitle data-testid={TestIds.TITLE} disabled={disabled}>
          {title}
        </HeaderTitle>
        {buttonElements}
        <VeloIconButton
          icon="close"
          onClick={onClose}
          title={intl.formatMessage({ defaultMessage: 'Close' })}
          disabled={disabled}
          data-testid={TestIds.CLOSE}
        />
        {onEdit && (
          <EditFab
            icon="edit"
            title={intl.formatMessage({ defaultMessage: 'Edit' })}
            mini
            disabled={disabled}
            onClick={onEdit}
            data-testid={TestIds.EDIT}
          />
        )}
      </Header>

      <Body disabled={disabled} compact={compact}>
        {children}
      </Body>
    </>
  );
}

const VeloModalSheetCardContentSkeleton = ({ children, ...props }) => (
  <VeloModalSheetCardContent
    title={<TitleLoading data-testid={TestIds.SKELETON} />}
    {...props}
  >
    {children}
  </VeloModalSheetCardContent>
);

const VeloModalSheetCardContentError = ({ error, children, ...props }) => (
  <VeloModalSheetCardContent {...props}>
    <VeloModalSheet.Error>{error || children}</VeloModalSheet.Error>
  </VeloModalSheetCardContent>
);

VeloModalSheetCardContent.propTypes = propTypes;
VeloModalSheetCardContentSkeleton.propTypes = propTypes;
VeloModalSheetCardContentError.propTypes = propTypes;

VeloModalSheetCardContent.testIds = TestIds;
VeloModalSheetCardContentSkeleton.testIds = TestIds;
VeloModalSheetCardContentError.testIds = TestIds;

VeloModalSheetCardContent.Error = VeloModalSheetCardContentError;
VeloModalSheetCardContent.Skeleton = VeloModalSheetCardContentSkeleton;

export { VeloModalSheetCardContent };
