import { PayoutStates, payorBrandingSelectors } from 'velo-data';

const selectPayoutSubmitting = (payoutBlock) =>
  payoutBlock && payoutBlock.submitting;
const selectPayoutFrom = (payoutBlock) => payoutBlock && payoutBlock.payoutFrom;
const selectPayoutTo = (payoutBlock) => payoutBlock && payoutBlock.payoutTo;

const selectPayorId = (infoBlock) => infoBlock && infoBlock.payorId;
const selectPayorName = (infoBlock, preferDbaName) =>
  infoBlock &&
  payorBrandingSelectors.selectDisplayName(infoBlock, preferDbaName);

const selectSourceAccountName = ({ accounts }) => {
  return accounts && accounts.length ? accounts[0].sourceAccountName : null;
};

const selectSourceAccountNameFromPayment = (payment) => {
  return payment && payment.sourceAccountName;
};

const selectPayorSubmittingId = (payout) =>
  selectPayorId(selectPayoutSubmitting(payout));
const selectPayorFromId = (payout) => selectPayorId(selectPayoutFrom(payout));
const selectPayorFromName = (payout, preferDbaName) =>
  selectPayorName(selectPayoutFrom(payout), preferDbaName);
const selectPayorToId = (payout) => selectPayorId(selectPayoutTo(payout));
// This selector will need to be updated with an API change to support Multi sourceAccount Payout Batches
const selectSourceAccount = (payout) => selectSourceAccountName(payout);
const selectSourceAccountFromAcceptedPayment = (payment) =>
  selectSourceAccountNameFromPayment(payment);

const reviewStates = [
  PayoutStates.QUOTED,
  PayoutStates.REJECTED,
  PayoutStates.INCOMPLETE,
];

const processingStates = [PayoutStates.SUBMITTED, PayoutStates.ACCEPTED];
const withdrawnStates = [PayoutStates.WITHDRAWN];

const selectPayoutNeedsReview = (status) => reviewStates.includes(status);
const selectPayoutNeedsProcessing = (status) =>
  processingStates.includes(status);
const selectPayoutIsWithdrawn = (status) => withdrawnStates.includes(status);

export const payoutSelectors = {
  selectPayoutNeedsReview,
  selectPayoutIsWithdrawn,
  selectPayoutNeedsProcessing,
  selectPayorToId,
  selectPayorFromName,
  selectPayorFromId,
  selectPayorSubmittingId,
  selectSourceAccount,
  selectSourceAccountFromAcceptedPayment,
};
