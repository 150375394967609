import React from 'react';
import { bool, string, func, object, number, node } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styled from 'astroturf/react';
import DayPicker from 'react-day-picker';
import { injectIntl } from 'react-intl';
import { VeloCard } from '../VeloCard';
import { VeloGrid } from '../VeloGrid';
import { VeloTypography } from '../VeloTypography';
import { VeloIconButton } from '../VeloIconButton';
import { EscapeKey } from '../EscapeKey';
import { ScrollLock } from '../ScrollLock';
import { VeloDivider } from '../VeloDivider';
import { DateTextField } from './DateTextField';
import { VeloPopupContentContext } from '../VeloPopupContentContext';
import { toISODateString, removeTimezoneOffset } from 'velo-data';

import './DayPicker.scss';

const DatePicker = styled('div')`
  position: relative;
`;

const Underlay = styled('div')`
  @import 'velo-variables';

  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: $velo-desktop-in-front-index-default - 1;
`;

const Card = styled(VeloCard)`
  @import 'velo-variables';
  @import 'velo-mixins';

  z-index: $velo-desktop-in-front-index-default;
  @include smooth-scrolling;

  /* Full-screen on mobile */
  @media (max-width: velo-breakpoint(XS)) {
    position: fixed;
    top: 0 !important;
    left: 0 !important;
    height: 100%;
    width: 100%;
    max-width: 100%;
    z-index: $velo-over-all-default;
    border-radius: 0;
    max-height: inherit;
  }
`;

const HeaderContainer = styled('div')`
  @import 'velo-variables';

  @media (min-width: velo-breakpoint(XS)) {
    display: none;
  }
`;

const Header = styled(VeloGrid.Cell)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const root = 'date-picker';

const TestIds = {
  DAY_PICKER: `${root}__day-picker-container`,
  SCRIM: `${root}__day-picker-underlay`,
  TEXT_FIELD: `${root}__input`,
  CANCEL_BUTTON: `${root}__day-picker-cancel`,
};

const datePickerHeight = 260;
const minLength = 10;

const EUPattern = {
  datePattern: ['d', 'm', 'Y'],
  placeholder: 'dd/mm/yyyy',
  delimiter: '/',
};

const USPattern = {
  datePattern: ['m', 'd', 'Y'],
  placeholder: 'mm/dd/yyyy',
  delimiter: '/',
};

const ISOPattern = {
  datePattern: ['Y', 'm', 'd'],
  placeholder: 'yyyy-mm-dd',
  delimiter: '-',
};

const JPPattern = {
  datePattern: ['Y', 'm', 'd'],
  placeholder: 'yyyy/mm/dd',
  delimiter: '/',
};

const localeDatePatternMap = {
  'en-gb': EUPattern,
  'fr-fr': EUPattern,
  'en-us': USPattern,
  'en-ca': ISOPattern,
  'fr-ca': ISOPattern,
  'ja-jp': JPPattern,
};

class VeloDatePickerComponent extends React.Component {
  textFieldRef = React.createRef();
  containerRef = React.createRef();

  static propTypes = {
    /**
     * The label rendered above the text input component
     */
    label: node,
    /**
     * CSS class(es) to pass to the root container
     */
    className: string,
    /**
     * The ISO date value rendered in the date picker and in the
     * text input. The text input is formatted as a localised string
     */
    value: string,
    /*
     * An onChange handler that will recieve ISO date strings
     */
    onChange: func.isRequired,
    /**
     * An ISO date value used to disable all dates after this date in the date picker
     */
    disableAfter: string,
    /**
     * An ISO date value used to disable all dates before this date in the date picker
     */
    disableBefore: string,
    /**
     * Ensure dates after the current day will be disabled. If the disableAfter
     * prop is an earlier date then disable after the earlier date.
     * This is used for date ranges. The end date cannot be after the start
     * date.
     */
    disableFutureDates: bool,
    /**
     * Shows a validation error state in the text input
     */
    invalid: bool,
    /**
     * Provided by the React Intl library context provider
     */
    intl: object.isRequired,
    /**
     * This sets the first day of the week in the date picker
     */
    firstDayOfWeek: number,
    /**
     * A test id for the date pickers text input
     */
    textInputTestId: string,
    /**
     * Whether or not the date input is required.
     * If the input is required then the date picker calendar icon is not
     * rendered
     */
    required: bool,
    /**
     * VeloTextField helpText prop
     */
    helpText: object,
    /**
     * VeloTextField name prop.
     * Commonly used with forms.
     */
    name: string,
    /**
     * Called when the day picker is opened or closed.
     */
    onToggleDayPicker: func,
  };

  static testIds = TestIds;
  static defaultProps = {
    firstDayOfWeek: 1,
    textInputTestId: TestIds.TEXT_FIELD,
    onToggleDayPicker: () => {},
  };

  state = {
    showDayPicker: false,
  };

  /**
   * Returns React Day Picker modifier object.
   * This modifier object is used to disable dates after and or before given
   * dates.
   * http://react-day-picker.js.org/docs/matching-days
   */
  getDisabledModifiers = () => {
    let after = this.props.disableAfter && new Date(this.props.disableAfter);
    const before =
      this.props.disableBefore && new Date(this.props.disableBefore);

    if (this.props.disableFutureDates && after) {
      after = after.getTime() > Date.now() ? new Date() : after;
    } else if (this.props.disableFutureDates) {
      after = new Date();
    }

    if (after || before) {
      return {
        disabled: {
          after: removeTimezoneOffset(after),
          before: removeTimezoneOffset(before),
        },
      };
    }
  };

  hideDayPicker = () =>
    this.setState({ showDayPicker: false }, () =>
      this.props.onToggleDayPicker(false)
    );

  // Disabled dates changes will not be passed to the parent
  handleDayClicked = (date, { disabled }) => {
    if (!disabled) {
      this.setState(
        {
          showDayPicker: false,
        },
        () => {
          this.props.onToggleDayPicker(false);
          this.props.onChange({
            target: { value: toISODateString(date), name: this.props.name },
          });
        }
      );
    }
  };

  textFieldHandlers = {
    onClickIcon: () => {
      if (!this.state.showDayPicker) {
        this.setState(
          {
            showDayPicker: true,
          },
          () => this.props.onToggleDayPicker(true)
        );
      } else {
        this.hideDayPicker();
      }
    },
  };

  componentDidMount() {
    window.addEventListener('resize', this.hideDayPicker);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.hideDayPicker);
  }

  /**
   * Fix the date picker position relative to the current position of the text
   * field
   */
  getFixedPositionRelativeToTextField = () => {
    const { top, bottom, left } = this.textFieldRef.getBoundingClientRect();
    const viewportHeight = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight
    );

    const modalStyles = {
      position: 'fixed',
      left,
    };

    // Prevent the date picker being cutoff at the bottom of the window
    if (bottom + datePickerHeight > viewportHeight) {
      return {
        ...modalStyles,
        top: Math.max(0, top - datePickerHeight),
      };
    }

    return {
      ...modalStyles,
      top: bottom,
    };
  };

  /**
   * Used to infer the date pattern and placeholder using the locale
   * If locale is not supported then default to US format date
   */
  getLocaleDatePatternProps = () => {
    return (
      localeDatePatternMap[this.props.intl.locale.toLowerCase()] || USPattern
    );
  };

  render() {
    let date;

    if (this.props.value) {
      date = removeTimezoneOffset(new Date(this.props.value));
    }

    const { placeholder, datePattern, delimiter } =
      this.getLocaleDatePatternProps();
    return (
      <DatePicker
        className={this.props.className}
        data-testid={`${this.props.textInputTestId}-wrapper`}
      >
        <DateTextField
          inputRef={(ref) => (this.textFieldRef = ref)}
          testId={this.props.textInputTestId}
          label={
            <FormattedMessage
              defaultMessage="{label} ({format})"
              values={{
                label: this.props.label,
                format: placeholder.toLowerCase(),
              }}
            />
          }
          delimiter={delimiter}
          placeholder={placeholder}
          value={this.props.value}
          invalid={this.props.invalid}
          required={this.props.required}
          helpText={this.props.helpText}
          name={this.props.name}
          minLength={this.props.required && minLength}
          iconSelected={this.state.showDayPicker}
          datePattern={datePattern}
          {...this.textFieldHandlers}
          onChange={this.props.onChange}
        />
        {this.state.showDayPicker && (
          <>
            <ScrollLock
              getScrollableElement={() => this.containerRef.current}
            />
            <EscapeKey onPressed={this.hideDayPicker} />
            <Underlay
              data-testid={TestIds.SCRIM}
              role="presentation"
              onClick={this.hideDayPicker}
            />
            <VeloPopupContentContext.Consumer>
              <Card
                flat={false}
                fullScreen={false}
                data-testid={TestIds.DAY_PICKER}
                ref={this.containerRef}
                style={this.getFixedPositionRelativeToTextField()}
              >
                <HeaderContainer>
                  <VeloGrid>
                    <Header span={12}>
                      <VeloTypography use="secondaryHeader" tag="div">
                        {this.props.label}
                      </VeloTypography>
                      <VeloIconButton
                        icon="close"
                        type="button"
                        title={this.props.intl.formatMessage({
                          defaultMessage: 'Close date picker',
                        })}
                        onClick={this.hideDayPicker}
                        data-testid={TestIds.CANCEL_BUTTON}
                      />
                    </Header>
                  </VeloGrid>
                  <VeloDivider />
                </HeaderContainer>
                <DayPicker
                  firstDayOfWeek={this.props.firstDayOfWeek}
                  selectedDays={date}
                  month={date}
                  onDayClick={this.handleDayClicked}
                  modifiers={this.getDisabledModifiers()}
                />
              </Card>
            </VeloPopupContentContext.Consumer>
          </>
        )}
      </DatePicker>
    );
  }
}

const VeloDatePicker = injectIntl(VeloDatePickerComponent);

export { VeloDatePicker };
