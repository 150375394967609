import React from 'react';
import cookie from 'cookie';

const Context = React.createContext();
const AdvancedSettingsConsumer = Context.Consumer;

const ONE_WEEK_SECONDS = 60 * 60 * 24 * 7;
const COOKIE_NAME = 'cookieadvancedpaymentmethods_show';

function getAdvancedPaymentMethodsSetting() {
  const cookies = cookie.parse(document.cookie);

  return (
    (cookies && cookies[COOKIE_NAME] && cookies[COOKIE_NAME] === 'true') ||
    false
  );
}

function setAdvancedPaymentMethodsSetting(value) {
  document.cookie = cookie.serialize(COOKIE_NAME, value, {
    maxAge: ONE_WEEK_SECONDS,
    path: window.location.pathname,
  });
}

function AdvancedSettingsProvider({ children }) {
  const state = {
    showAdvancedPaymentMethods: getAdvancedPaymentMethodsSetting(),
    setAdvancedPaymentMethods: setAdvancedPaymentMethodsSetting,
  };

  return <Context.Provider value={state}>{children}</Context.Provider>;
}

const SettingsContext = {
  Consumer: AdvancedSettingsConsumer,
  Provider: AdvancedSettingsProvider,
};

export { SettingsContext };
