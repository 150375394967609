import {
  createDataJoinCallbackFunction,
  createAwaitAllDataJoinCallback,
} from '../selectors';
import { SendError } from 'velo-api/src/send/SendError';

/**
 * A HOF encapsulating the logic needed to join a list data source containing
 * entries that have a `payorId` attribute with corresponding de-duplicated
 * calls to `entity.getPayor`, populating each entry with the additional
 * attribute `payorName`
 */
export function PayorNameDataJoin(
  entity,
  enabled,
  selectFromPayorResult = (payor) => ({ payorName: payor?.payorName })
) {
  /**
   * Create a function to perform the data-join, by default this would call the
   * passed callback every-time new data became available, but we use the await
   * helper to ensure that the callback is only issued after all the data has
   * been loaded or errored.
   */
  return createAwaitAllDataJoinCallback(
    createDataJoinCallbackFunction(
      /** a function that extracts parameters to be passed to the loader */
      (data) => data.map(({ payorId: key }) => ({ key, params: [key, {}] })),
      /** the function to call with the params: `[key, {}]` */
      entity.getPayor
    ),
    /** This function is called for each entry in the list `value`  */
    (resultsByKey, content, value) => {
      const { error, result: payor } = resultsByKey[value.payorId];
      /** early exit if a result is in error */
      if (error && !SendError.isNotFoundError(error)) {
        return [error];
      }
      /** expose `payorName` on the object */
      return [
        /** No error */
        undefined,
        /** The current content, plus the new populated value */
        content.concat({
          ...value,
          ...selectFromPayorResult(payor),
        }),
      ];
    },
    enabled
  );
}
