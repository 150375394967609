import React from 'react';
import { bool } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styled from 'astroturf/react';
import { VeloTypography } from '../VeloTypography';
import { PaymentChannelViewPayor } from './PaymentChannelViewPayor';
import { ResultContainer } from '../ResultContainer';
import { VeloSkeleton } from '../VeloSkeleton';
import { VeloPropTypes } from '../VeloPropTypes';

const LoadingHeader = styled(VeloSkeleton)`
  width: 33%;
  display: block;
  margin: 2rem 0 1.5rem;
`;

const LoadingItem = styled(VeloSkeleton)`
  width: 66%;
  margin-bottom: 10px;
`;

/** Render the payors list in the loading state. */
const LoadingComponent = () => (
  <>
    <LoadingHeader data-testid="velo-payments-channel-payor-skeleton" />
    <LoadingItem />
    <LoadingItem />
    <LoadingItem />
  </>
);

/** Render the payors list. */
const BaseComponent = ({ result: payors }) => (
  <>
    <div>
      <VeloTypography use="sectionHeader" tag="h2">
        <FormattedMessage defaultMessage="Payors" />
      </VeloTypography>
    </div>
    {payors && (
      <div data-testid="velo-payment-channel-view-payors">
        {/* TODO: use ResultContainer */}
        {payors.error ? (
          <PaymentChannelViewPayor type="error" disabled={payors.disabled}>
            <FormattedMessage defaultMessage="Error loading payors" />
          </PaymentChannelViewPayor>
        ) : payors.result === undefined ? (
          <PaymentChannelViewPayor type="loading" />
        ) : payors.result.length === 0 ? (
          <PaymentChannelViewPayor disabled={payors.disabled}>
            <FormattedMessage defaultMessage="Payor not assigned" />
          </PaymentChannelViewPayor>
        ) : (
          payors.result.map((payor, index) => (
            <PaymentChannelViewPayor key={index} disabled={payors.disabled}>
              {payor}
            </PaymentChannelViewPayor>
          ))
        )}
      </div>
    )}
  </>
);

PaymentChannelViewPayors.propTypes = {
  // The payors
  result: VeloPropTypes.result(),
  /** Render in the disabled state. */
  disabled: bool,
};

const Component = ResultContainer({ LoadingComponent })(BaseComponent);

/**
 * Used to display Payment Channel Payors
 */
function PaymentChannelViewPayors({ result }) {
  return <Component result={result} />;
}

export { PaymentChannelViewPayors };
