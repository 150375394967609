import { useMemo } from 'react';
import { useAppContext, contextCountriesSelector } from '../../context';

function useCountries() {
  const context = useAppContext();
  const countries = contextCountriesSelector(context);

  return useMemo(() => {
    return {
      countries,
    };
  }, [countries]);
}

export { useCountries };
