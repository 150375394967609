import { useMemo } from 'react';
import { NavigationDrawer, VeloThemeContext } from 'velo-react-components';
import { ApplicationVariantEnum } from '../AppConfigs';

const toLink = ([id, href], order) => {
  return {
    id,
    href,
    label: NavigationDrawer.config.NavigationItemLabels[id],
    order,
    navigateToLink: () => window.open(href),
  };
};

export function useLegalLinks(appVariant) {
  const { legalLinks } = VeloThemeContext.useBrandingTokens();
  return legalLinks[appVariant || ApplicationVariantEnum.PayeePortal];
}

/** Build a list of legal links based on the application variant and currently configured links with order */
export function useLegalLinksList(appVariant) {
  const legalLinks = useLegalLinks(appVariant);
  return useMemo(() => Object.entries(legalLinks).map(toLink), [legalLinks]);
}
