import React from 'react';
import {
  Numeric,
  FreeTextEntryWithinRange,
} from '../FormFields/FormFieldTypes';
import { FormattedMessage } from 'react-intl';
import { SelectCurrency } from '../FormFields/SelectCurrency';
import { SelectCountry } from '../FormFields/SelectCountry';
import { VeloSelect } from '../VeloSelect';
import { LookupTextField } from '../LookupTextField';

export const fieldNames = {
  NAME: 'name',
  PAYOR: 'payor',
  PAYOR_NAME: 'payorName',
  COUNTRY: 'countryCode',
  CURRENCY: 'currency',
  TYPE: 'type',
  ACCOUNT_NAME: 'accountName',
  ROUTING_NUMBER: 'routingNumber',
  ACCOUNT_NUMBER: 'accountNumber',
};

export const labelsByFieldName = {
  [fieldNames.NAME]: <FormattedMessage defaultMessage="Funding account name" />,
  [fieldNames.PAYOR_NAME]: <FormattedMessage defaultMessage="Payor" />,
  [fieldNames.PAYOR]: <FormattedMessage defaultMessage="Payor" />,
  [fieldNames.COUNTRY]: <FormattedMessage defaultMessage="Country" />,
  [fieldNames.CURRENCY]: <FormattedMessage defaultMessage="Currency" />,
  [fieldNames.TYPE]: <FormattedMessage defaultMessage="Type" />,
  [fieldNames.ACCOUNT_NAME]: (
    <FormattedMessage defaultMessage="Account holder name" />
  ),
  [fieldNames.ACCOUNT_NUMBER]: (
    <FormattedMessage defaultMessage="Account number" />
  ),
  [fieldNames.ROUTING_NUMBER]: (
    <FormattedMessage defaultMessage="Routing number" />
  ),
};

const attributeMap = {
  NAME: {
    ...FreeTextEntryWithinRange(labelsByFieldName[fieldNames.NAME], 1, 30),
    name: 'name',
    required: true,
  },
  ACCOUNT_HOLDER_NAME: {
    ...FreeTextEntryWithinRange(
      labelsByFieldName[fieldNames.ACCOUNT_NAME],
      1,
      22
    ),
    name: 'accountName',
    required: true,
  },
  ACCOUNT_NUMBER: {
    ...Numeric(
      labelsByFieldName[fieldNames.ACCOUNT_NUMBER],
      'Please enter between 4 and 17 numbers',
      '{4,17}'
    ),
    minLength: 4,
    maxLength: 17,
    name: 'accountNumber',
    required: true,
  },
  ROUTING_NUMBER: {
    ...Numeric(
      labelsByFieldName[fieldNames.ROUTING_NUMBER],
      'Please enter between 6 and 9 numbers',
      '{6,9}'
    ),
    minLength: 6,
    maxLength: 9,
    name: 'routingNumber',
    required: true,
  },
  COUNTRY: {
    Component: SelectCountry,
    name: fieldNames.COUNTRY,
    helpText: 'Please select a country',
    required: true,
  },
  CURRENCY: {
    Component: SelectCurrency,
    name: fieldNames.CURRENCY,
    helpText: 'Please select a currency',
    required: true,
  },
  TYPE: {
    name: fieldNames.TYPE,
    Component: VeloSelect,
    label: labelsByFieldName[fieldNames.TYPE],
    'aria-label': 'Type',
    helpText: 'Please select a funding account type',
    required: true,
  },
  PAYOR: {
    label: labelsByFieldName[fieldNames.PAYOR_NAME],
    name: fieldNames.PAYOR,
    Component: LookupTextField,
    mode: LookupTextField.modes.PAYOR,
    helpText: {
      children: ' ',
    },
    entityId: '',
    required: true,
  },
};

export const FundingAccountFormFields = {
  attributeMap,
};
