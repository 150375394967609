// Used to find the year, month and day in a ISO date array
const ISODateIndexMap = {
  Y: 0,
  m: 1,
  d: 2,
};

// Year is 4 chars long, Day and month are 2 chars long
const datePatternLengthMap = {
  Y: 4,
  m: 2,
  d: 2,
};

// The max number of characters in a date digit sequence like 30012000
const maxCharLength = 8;

// Used to check for leap years - https://stackoverflow.com/a/16353241
const isLeapYear = (year) =>
  (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;

// Used to pad a '0' before a '1' when the date is a single digit
export const addLeadingZero = (number) => (number < 10 ? '0' : '') + number;

// Used to convert 28/02/2000 to 2000-02-28
export const valueFromISODate = (datePattern, delimiter, ISODate) => {
  if (ISODate !== '') {
    const parts = ISODate.split('-');
    return datePattern
      .map((monthDayYear) => parts[ISODateIndexMap[monthDayYear]])
      .join(delimiter);
  }

  return ISODate;
};

// Used to get an array of normalised date values
const getFixedDate = (day, month, year) => {
  day = Math.min(day, 31);
  month = Math.min(month, 12);
  year = parseInt(year, 10);

  if ((month < 7 && month % 2 === 0) || (month > 8 && month % 2 === 1)) {
    day = Math.min(day, month === 2 ? (isLeapYear(year) ? 29 : 28) : 30);
  }

  return [day, month, year];
};

// Convert from [day, month, year] array to ISO date string
export const getISOFormatDate = (date) => {
  if (date[2]) {
    return (
      date[2] + '-' + addLeadingZero(date[1]) + '-' + addLeadingZero(date[0])
    );
  }
};

// Used to produce a complete [day, month, year] array and normalised date string
const getFixedDateString = (datePattern, value) => {
  let date = [],
    dayIndex = 0,
    monthIndex = 0,
    yearIndex = 0,
    dayStartIndex = 0,
    monthStartIndex = 0,
    yearStartIndex = 0;

  // yyyy-mm-dd || yyyy-dd-mm || mm-dd-yyyy || dd-mm-yyyy || dd-yyyy-mm || mm-yyyy-dd
  if (value.length === maxCharLength) {
    datePattern.forEach(function (type, index) {
      switch (type) {
        case 'd':
          dayIndex = index;
          break;
        case 'm':
          monthIndex = index;
          break;
        default:
          yearIndex = index;
          break;
      }
    });

    yearStartIndex = yearIndex * 2;
    dayStartIndex = dayIndex <= yearIndex ? dayIndex * 2 : dayIndex * 2 + 2;
    monthStartIndex =
      monthIndex <= yearIndex ? monthIndex * 2 : monthIndex * 2 + 2;

    const day = parseInt(value.slice(dayStartIndex, dayStartIndex + 2), 10);
    const month = parseInt(
      value.slice(monthStartIndex, monthStartIndex + 2),
      10
    );
    const year = parseInt(value.slice(yearStartIndex, yearStartIndex + 4), 10);

    date = getFixedDate(day, month, year);
  }

  // When the [day, month, year] array is not complete return the input value
  const normalisedDateString =
    date.length !== 3
      ? value
      : datePattern.reduce(function (previous, current) {
          switch (current) {
            case 'd':
              return previous + addLeadingZero(date[0]);
            case 'm':
              return previous + addLeadingZero(date[1]);
            default:
              return previous + date[2];
          }
        }, '');

  return { date, normalisedDateString };
};

/**
 *  Remove non digit chars from the user input
 *  Autocomplete for digits over 3 for day as 40 is not a valid day value
 *  Autocomplete for digits over 12 for month as 13 is not a valid month value
 *  Check for user inputted delimiters.
 *  If a user inputs 1/ for then return 01/
 *  Ignore delimiter half way through typing a year like 200/
 * */
const parseInputValue = (value, datePattern, delimiter) => {
  const userInputDelimiters = value
    .split(/\d{1,4}/)
    .filter((sub) => sub.charAt(0) === delimiter)
    .slice(0, 2);

  let result = '';

  let cleanedValue = value.replace(/[^\d]/g, '');

  datePattern.forEach(function (patternItem, index) {
    const length = datePatternLengthMap[patternItem];
    const hasUserInputDelimiter = userInputDelimiters[index];

    if (cleanedValue.length > 0) {
      var sub = cleanedValue.slice(0, length),
        sub0 = sub.slice(0, 1),
        rest = cleanedValue.slice(length);

      switch (datePattern[index]) {
        case 'd':
          if (sub === '00') {
            sub = '01';
          } else if (
            parseInt(sub0, 10) > 3 ||
            (hasUserInputDelimiter && sub.length === 1)
          ) {
            sub = '0' + sub0;
          } else if (parseInt(sub, 10) > 31) {
            sub = '31';
          }

          break;

        case 'm':
          if (sub === '00') {
            sub = '01';
          } else if (
            parseInt(sub0, 10) > 1 ||
            (hasUserInputDelimiter && sub.length === 1)
          ) {
            sub = '0' + sub0;
          } else if (parseInt(sub, 10) > 12) {
            sub = '12';
          }

          break;
        // Must be a 'Y' date pattern item
        default:
          if (hasUserInputDelimiter && sub.length !== 4) {
            userInputDelimiters[index] = false;
          }
          break;
      }

      result += sub;
      cleanedValue = rest;
    }
  });

  return { parsedInputValue: result, userInputDelimiters };
};

// Add delimiters into value for display in the text field
const formatOutputValue = (
  value,
  datePattern,
  delimiter,
  userInputDelimiters
) => {
  let cleanedResult = value.slice(0, maxCharLength);
  let output = '';

  datePattern.forEach((yearMonthDay, index) => {
    const length = datePatternLengthMap[yearMonthDay];

    if (cleanedResult.length > 0) {
      let sub = cleanedResult.slice(0, length),
        rest = cleanedResult.slice(length);

      if (index > 0 && !userInputDelimiters[index - 1]) {
        output += delimiter;
      } else if (userInputDelimiters[index]) {
        sub += delimiter;
      }

      output += sub;

      cleanedResult = rest;
    }
  });

  return output;
};

/**
 * @param {String} value A user input date text input value
 * @param {Array} datePattern An array of date pattern strings like [Y, m, d]
 * @param {String} delimiter The separator for the day, month, year values
 * @return {Object} A formatted value for the text input and an ISO date
 * The ISO date is only available when the day, month and year are fully inputted
 */
export const getValidatedDate = (value, datePattern, delimiter) => {
  const { parsedInputValue, userInputDelimiters } = parseInputValue(
    value,
    datePattern,
    delimiter
  );

  const { normalisedDateString, date } = getFixedDateString(
    datePattern,
    parsedInputValue
  );

  const result = formatOutputValue(
    normalisedDateString,
    datePattern,
    delimiter,
    userInputDelimiters
  );

  const ISODate =
    result === '' && value !== delimiter ? result : getISOFormatDate(date);

  return { value: result, ISODate };
};
