import React from 'react';
import { FormattedMessage } from 'react-intl';
import { PaymentViewPropTypes } from '../propTypes';
import { VeloCurrency } from '../../VeloCurrency';
import { ResultContainer } from '../../ResultContainer';
import { PaymentViewContainer } from '../PaymentViewContainer';
import { PaymentViewLoading } from '../PaymentViewLoading';
import { PaymentViewError } from '../PaymentViewError';
import { PaymentView } from '../PaymentView';
import { PaymentViewChannel } from '../PaymentViewChannel';
import { PayeePaymentViewHistory } from './PayeePaymentViewHistory';
import { PayeePaymentViewDetails } from './PayeePaymentViewDetails';

const LoadingComponent = ({ result, onClose }) => (
  <PaymentViewLoading onClose={onClose} tabs>
    <PayeePaymentViewDetails result={result} />
    <PaymentViewChannel result={result} />
    <PayeePaymentViewHistory result={result} />
  </PaymentViewLoading>
);

const BaseComponent = ({ result, onClose, onConfirm, paymentChannelRules }) => {
  const tabMap = [
    {
      label: <FormattedMessage defaultMessage="Information" />,
      renderContent: () => (
        <>
          <PayeePaymentViewDetails result={result} onConfirm={onConfirm} />
          <PaymentViewChannel
            result={result}
            paymentChannelRules={paymentChannelRules}
          />
        </>
      ),
    },
    {
      label: <FormattedMessage defaultMessage="Tracker" />,
      renderContent: () => (
        <PayeePaymentViewHistory
          result={result}
          paymentChannelRules={paymentChannelRules}
        />
      ),
    },
  ];

  return (
    <PaymentView
      title={
        <VeloCurrency
          value={result.paymentAmount}
          currency={result.paymentCurrency}
        />
      }
      onClose={onClose}
      tabs={tabMap}
    />
  );
};

const Component = ResultContainer({
  ErrorComponent: PaymentViewError,
  LoadingComponent,
})(BaseComponent);

PayeePaymentView.propTypes = {
  ...PaymentViewPropTypes,
};

function PayeePaymentView(props) {
  return (
    <PaymentViewContainer
      {...props}
      render={(props) => <Component {...props} />}
    />
  );
}

export { PayeePaymentView };
