import { SearchPresenterCore } from './SearchPresenterCore';

export function PayorSearchPresenter(entity) {
  return SearchPresenterCore(
    entity.getPayors,
    (payorName) => ({ payorName, pageSize: 5 }),
    ({ payorName, payorId, primaryContactEmail }) => ({
      value: payorName,
      entityId: payorId,
      secondaryValues: [primaryContactEmail],
    })
  );
}
