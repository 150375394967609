import React from 'react';
import styled from 'astroturf/react';
import { bool, node, object, oneOfType, string } from 'prop-types';
import { Fab } from '@rmwc/fab';
import { dataTestIdBuilder } from 'velo-data';
import { VeloSkeleton } from '../VeloSkeleton';
import '@material/fab/dist/mdc.fab.css';

const StyledFab = styled(Fab)`
  @import 'velo-variables';
  @import '@material/fab/mixins';

  font-family: var(--ds-typography-font-family);

  /* Apply the theme colour directly to fix an Edge issue. */
  /* https://github.com/material-components/material-components-web/issues/5044 */
  background-color: velo-color('token-color-brand-primary');

  &:global(.mdc-fab--extended) {
    box-shadow: none;
    /**
     * Required for Safari as the built-in user-agent style
     * sheet does not reset all margins.
     *
     * Without this the gap inside a VeloSplitFab is too wide.
     */
    margin: 0;
  }

  &.disabled {
    background-color: velo-color('token-color-text-disabled');
    pointer-events: none;
  }
`;

/**
 * Existing pattern used in payee portal is to have a separate component which is visible
 * on mobile. Meaning two fabs are rendered 1 for desktop and 1 for mobile.
 *
 * NOTE: This maybe better refactored into one fully responsive component
 */
const MobileFab = styled(VeloFab)`
  @import 'velo-variables';
  display: block;
  position: fixed;
  z-index: $velo-base-index-default + 1;
  bottom: 1rem;
  right: 1rem;

  @media (min-width: velo-breakpoint(XS)) {
    display: none;
  }
`;

const root = 'velo-fab';

VeloFab.propTypes = {
  /** Custom CSS class(es). */
  className: string,
  /** Disable the FAB */
  disabled: bool,
  /** The title attribute text  */
  title: node.isRequired,
  /** Test ID for the velo fab. */
  'data-testid': string.isRequired,
  /**
   * Icon for the button/fab.
   */
  icon: oneOfType([node, object]),
};

VeloFab.testIdBuilder = (label, desc) => dataTestIdBuilder(root, label, desc);

const Loading = styled(({ className }) => (
  <VeloSkeleton className={className} />
))`
  height: 56px;
  width: 56px;
  border-radius: 28px;

  &.extended {
    width: 220px;
  }
`;

Loading.propTypes = {
  extended: bool,
};

VeloFab.Loading = Loading;
VeloFab.Mobile = MobileFab;

/**
 * Velo Floating Action Button (FAB).
 *
 * Note that all rmwc/Fab props are supported.
 */
export function VeloFab(props) {
  return <StyledFab {...props} />;
}
