/**
 * Return a suitable tooltip for an nav drawer item.
 * The item can be a string or a FormattedMessage component.
 */
export function getDataTip(intl, label) {
  if (typeof label === 'string') {
    return label;
  }
  return intl.formatMessage(label.props);
}
