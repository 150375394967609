import {
  PayorSearchPresenter,
  createListRedirectionLoader,
} from '../../presenters';
import {
  mapTablePropsWithResult,
  createCancelAllClosure,
  filterSelectors,
  createEarlyExitCallback,
} from '../../selectors';
import { PayorNameDataJoin } from '../../presenters';
import { canShowFundingAccountPayorField } from './FundingAccountHelpers';

export function FundingAccountsListPresenter(
  wireFrame,
  entity,
  { query, privileges },
  { filters, countries, currencies, keys },
  intl
) {
  const redirect = (qs) =>
    wireFrame.navigateToFundingAccountsList.redirect({}, qs);

  const dataJoin = PayorNameDataJoin(
    entity,
    canShowFundingAccountPayorField(privileges)
  );

  /** Create a loader that will redirect based on keys that would mismatch */
  const loader = createListRedirectionLoader(redirect, keys, query, (q, cb) => {
    /** cancellations goes outside as there is an inner data-join to cancel  */
    const cancellations = [];
    cancellations.push(
      /** Get the list of accounts with the normalized query from the helper */
      entity.getFundingAccounts(
        q,
        /** Use the helper, if error exit early */
        createEarlyExitCallback(
          ({ content, ...extra }) =>
            /**
             * When the data comes back, call the data-join with content and any
             * extra properties.
             * The callback will then be called once all the data has finished
             * loading.
             */
            cancellations.push(dataJoin(cb, content, extra)),
          cb
        )
      )
    );
    return createCancelAllClosure(cancellations);
  });

  const onCreate = () => wireFrame.navigateToFundingAccountsCreate({}, query);
  const onClick = ({ id: fundingAccountId }) =>
    wireFrame.navigateToFundingAccountDetail({ fundingAccountId }, query);

  function getProps(...args) {
    return [
      mapTablePropsWithResult(...args),
      {
        onClick,
      },
    ];
  }

  const fetchPayorResults = PayorSearchPresenter(entity);

  return [
    loader,
    /** Populate the filters from the query and fixed data */
    filterSelectors.populateFilters(filters, query, {
      countries,
      currencies,
      fetchPayorResults,
      intl,
    }),
    getProps,
    onCreate,
  ];
}
