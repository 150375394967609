import { useCallback } from 'react';
import curry from 'just-curry-it';
import {
  VeloNotification,
  UserRoles,
  VeloCacheHitContext,
} from 'velo-react-components';
import {
  usePresenter,
  useCallbackFnAsResultState,
  useSerializableMemo,
} from '../../hooks';
import { createFromHook } from '../../containers';

import { WebhookUpsertPresenter } from './WebhookUpsertPresenter';

const successNote = VeloNotification.types.WEBHOOK_SUCCESS;

const isReadyOnly = (role) => [UserRoles.PayorSupport].includes(role);

export const createWebhookUpsertRoute = curry(
  createFromHook,
  2
)(({ payorId, role, autoClose }) => {
  const readOnly = isReadyOnly(role);
  const [cacheHit] = VeloCacheHitContext.useVeloCacheHitContext(
    VeloNotification.types.WEBHOOK_SUCCESS.context
  );
  const [loader, getProps] = usePresenter(
    WebhookUpsertPresenter,
    undefined,
    successNote,
    useSerializableMemo({ payorId, readOnly, autoClose })
  );

  const [result] = useCallbackFnAsResultState(
    useCallback((cb) => loader(cb, cacheHit), [loader, cacheHit])
  );

  return [result, getProps];
});
