/* eslint-disable default-case */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  PaymentStatesDoesNotRequireScheduledIcon,
  dataTestIdBuilder,
} from 'velo-data';
import { VeloSkeleton } from '../../VeloSkeleton';
import { PaymentsList } from '../PaymentsList';
import { PaymentStatus } from '../../PaymentStatus';
import { PaymentResult } from '../PaymentResult';
import { VeloListCurrencyAmount } from '../../VeloListCurrencyAmount';
import PaymentsListPropTypes from '../propTypes';
import { VeloIcon } from '../../VeloIcon';
import { VeloTable } from '../../VeloTable';
import styles from './PayorPaymentsList.module.scss';

const testIds = PaymentsList.testIds;

const columns = {
  SCHEDULED_ICON: 0,
  PAYEE_NAME: 1,
  REMOTE_ID: 2,
  SOURCE_AMOUNT: 3,
  PAYMENT_AMOUNT: 4,
  PAYMENT_STATUS: 5,
  SCHEDULED_STATUS_ICON: 6,
};

const renderCell = (skeleton, data, row, col) => {
  const testId = `${testIds.LIST}-row-${row}`;
  switch (col) {
    case columns.SCHEDULED_ICON:
      return data.schedule?.scheduledFor ? (
        <VeloIcon
          icon="schedule"
          data-testid={dataTestIdBuilder(testId, 'schedule-icon')}
        />
      ) : null;
    case columns.PAYEE_NAME:
      return (
        <span data-testid={`${testId}-payee-name`}>
          <PaymentResult
            skeleton={skeleton}
            errorMessage={
              <FormattedMessage defaultMessage="Error loading Payee" />
            }
            {...data.payeeName}
          />
        </span>
      );
    case columns.REMOTE_ID:
      return skeleton ? (
        <VeloSkeleton />
      ) : (
        <span data-testid={`${testId}-remote-id`}>{data.remoteId}</span>
      );
    case columns.SOURCE_AMOUNT:
      return (
        <VeloListCurrencyAmount
          skeleton={skeleton}
          amount={data.sourceAmount}
          currency={data.sourceCurrency}
          data-testid={`${testId}-amount`}
        />
      );
    case columns.PAYMENT_AMOUNT:
      return (
        <VeloListCurrencyAmount
          skeleton={skeleton}
          amount={data.paymentAmount}
          currency={data.paymentCurrency}
          data-testid={`${testId}-payment-amount`}
        />
      );
    case columns.PAYMENT_STATUS:
      return (
        <PaymentStatus
          use="tableContent"
          skeleton={skeleton}
          status={data.status}
          data-testid={`${testId}-status`}
        />
      );
    case columns.SCHEDULED_STATUS_ICON:
      return data.schedule?.scheduledFor &&
        PaymentStatesDoesNotRequireScheduledIcon.indexOf(data.status) === -1 ? (
        <VeloIcon
          icon="schedule"
          data-testid={dataTestIdBuilder(testId, 'schedule-status-icon')}
        />
      ) : null;
  }
  // There is no default case on the switch above as it cannot be tested
  // and falling out of this function is harmless.
};

PayorPaymentsList.propTypes = {
  ...PaymentsListPropTypes,
};

/**
 * Displays a list of Payor Payments.
 *
 * Five columns of information are displayed:
 *
 * - Date/Time
 * - Payee name
 * - Remote ID
 * - Source payment amount
 * - Payment status
 */
function PayorPaymentsList(props) {
  return (
    <PaymentsList
      headings={[
        {
          label: '',
          'data-testid': `${testIds.LIST}-scheduled-icon`,
          size: VeloTable.size.XSMALL,
        },
        {
          label: <FormattedMessage defaultMessage="Payee Name" />,
          className: styles.payeeName,
          'data-testid': `${testIds.LIST}-heading-payee-name`,
        },
        {
          label: <FormattedMessage defaultMessage="Remote ID" />,
          className: styles.remoteId,
          'data-testid': `${testIds.LIST}-heading-remote-id`,
        },
        {
          label: <FormattedMessage defaultMessage="Source Amount" />,
          alignEnd: true,
          'data-testid': `${testIds.LIST}-heading-source-amount`,
        },
        {
          label: <FormattedMessage defaultMessage="Payment Amount" />,
          alignEnd: true,
          'data-testid': `${testIds.LIST}-heading-source-amount`,
        },
        {
          label: <FormattedMessage defaultMessage="Status" />,
          'data-testid': `${testIds.LIST}-heading-status`,
        },
        {
          label: '',
          'data-testid': `${testIds.LIST}-scheduled-status-icon`,
          size: VeloTable.size.XSMALL,
        },
      ]}
      renderCell={renderCell}
      {...props}
    />
  );
}

PayorPaymentsList.testIds = testIds;
export { PayorPaymentsList };
