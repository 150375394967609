/* eslint-disable default-case */
import React from 'react';
import { func } from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import classnames from 'classnames';
import styled from 'astroturf/react';
import { useValidateOTPForm } from '../hooks';

import { VeloSteps } from '../VeloSteps';
import { VeloButton } from '../VeloButton';
import { VeloTextField } from '../VeloTextField';
import { VeloOverlay } from '../VeloOverlay';
import { VeloDivider } from '../VeloDivider';
import { useSMSCode } from '../FormFields/FormFieldTypes';
import { MFATypes } from '../MFATypes';
import { VeloThemeContext } from '../VeloThemeContext';
import { OnboardingPage } from '../OnboardingPage';
import styles from './InviteOTP.module.scss';

const root = 'onboarding-otp-check';

const MFATypeLabels = MFATypes.Labels;

const TestIds = {
  SECURE_STEP: `${root}-secure-step`,
  SMS_STEP_ONLY: `${root}-sms-step-only`,
  REGISTER_MFA: `${root}-register-mfa`,
  OTP: `${root}-otp`,
  SEND_OTP: `${root}-send-otp`,
  CONTINUE: `${root}-continue`,
  MFA_ERROR: `${root}-mfa-error`,
  OTP_ERROR: `${root}-otp-error`,
};

const resendTitles = {
  RESEND_INVITE: <FormattedMessage defaultMessage="Resend Invite" />,
  RESEND_MFA_REGISTRATION: (
    <FormattedMessage defaultMessage="Resend Registration" />
  ),
};
const Container = styled('div')`
  max-width: 30rem;
  margin: auto;
`;

const Strong = styled('span')`
  font-weight: 600;
`;

const formPropTypes = {
  /** Called to send an OTP to the users mobile. */
  updateMfa: func.isRequired,
  /** Called when submitting the OTP (6-digit code). */
  validateOTP: func.isRequired,
};

InviteOTPForm.propTypes = formPropTypes;
InviteOTP.propTypes = formPropTypes;
TwoFactor.propTypes = formPropTypes;
InviteOTP.testIds = TestIds;

function InviteOTPForm(props) {
  const [setCodeInputRef, { onValidateOTP, onSendOTP, onChangeOTP }, state] =
    useValidateOTPForm(props, {
      formatSendOTPBody: ({ mfaType, smsNumber }) => ({ type: mfaType }),
    });

  // The Send Code button is initially primary until
  // a code has been sent.
  const SendCodeButton = state.mfaSuccess
    ? VeloButton.Secondary
    : VeloButton.Primary;

  const smsCodeField = useSMSCode();

  return (
    <>
      <form onSubmit={onSendOTP}>
        <section className={styles.section}>
          {state.mfaError && (
            <OnboardingPage.SubmitError
              className={styles.error}
              data-testid={TestIds.MFA_ERROR}
            >
              {state.mfaError}
            </OnboardingPage.SubmitError>
          )}

          <SendCodeButton
            className={styles.sendCodeButton}
            type="submit"
            data-testid={TestIds.SEND_OTP}
          >
            {state.sendCodeLabel}
          </SendCodeButton>
        </section>
      </form>

      <VeloDivider className={styles.divider} />

      <form onSubmit={onValidateOTP}>
        <section className={styles.section}>
          <OnboardingPage.Subtitle
            className={classnames(styles.subtitle, {
              [styles.subtitleDisabled]: state.otpDisabled,
            })}
          >
            <FormattedMessage
              defaultMessage="Please enter the <strong>6-digit code</strong> we sent to your mobile phone."
              values={{ strong: (chunks) => <strong>{chunks}</strong> }}
            />
          </OnboardingPage.Subtitle>

          <div className={styles.textField}>
            <VeloTextField
              dynamicHelpText
              inputRef={setCodeInputRef}
              key={state.otpInputKey}
              id="otp"
              value={state.otp}
              onChange={onChangeOTP}
              disabled={state.otpDisabled}
              required
              data-testid={TestIds.OTP}
              {...smsCodeField}
            />
          </div>

          {state.otpError && (
            <OnboardingPage.SubmitError
              className={styles.error}
              data-testid={TestIds.OTP_ERROR}
            >
              {state.otpError}
            </OnboardingPage.SubmitError>
          )}
        </section>

        <OnboardingPage.Footer>
          <OnboardingPage.Button
            disabled={state.otpDisabled}
            type="submit"
            data-testid={TestIds.CONTINUE}
          >
            {state.continueLabel}
          </OnboardingPage.Button>
        </OnboardingPage.Footer>
      </form>

      {/* Submitting */}
      <VeloOverlay show={state.submitting} />
    </>
  );
}

function Title(props) {
  const { platformName } = VeloThemeContext.useBrandingTokens();
  return (
    <OnboardingPage.Title {...props}>
      <FormattedMessage
        defaultMessage="You've been invited to create your account by {platformName}."
        values={{ platformName }}
      />
    </OnboardingPage.Title>
  );
}

function PayeeCompanyTitle({ companyName, ...props }) {
  return (
    <OnboardingPage.Title {...props}>
      <FormattedMessage
        defaultMessage="You've been invited by <strong>{companyName}</strong> to start getting paid using Velo Payments."
        values={{ companyName, strong: (chunks) => <Strong>{chunks}</Strong> }}
      />
    </OnboardingPage.Title>
  );
}

const requiredSteps = [
  <FormattedMessage defaultMessage="Validate your mobile phone." />,
];
const inviteSteps = [
  <FormattedMessage defaultMessage="Create your password." />,
];
const secureSteps = [
  <FormattedMessage defaultMessage="Register your secure two-factor authentication method." />,
];

function InviteOTP(props) {
  const intl = useIntl();
  return (
    <Container data-testid={TestIds.SECURE_STEP}>
      <section>
        <Title />
        <OnboardingPage.Subtitle className={styles.subtitle}>
          <FormattedMessage
            defaultMessage="Easy to create in just 3 simple steps. You will need your mobile phone and your {mfaLabel}."
            values={{ mfaLabel: MFATypeLabels(intl)[props.mfaType] }}
          />
        </OnboardingPage.Subtitle>

        <VeloSteps steps={[...requiredSteps, ...inviteSteps, ...secureSteps]} />
      </section>
      <InviteOTPForm {...props} />
    </Container>
  );
}

function TwoFactor({ companyName, ...props }) {
  return (
    <Container data-testid={TestIds.SMS_STEP_ONLY}>
      <section>
        {companyName ? (
          <PayeeCompanyTitle companyName={companyName} />
        ) : (
          <Title />
        )}
        <OnboardingPage.Subtitle className={styles.subtitle}>
          <FormattedMessage defaultMessage="Easy to create in just 2 simple steps. You will need your mobile phone." />
        </OnboardingPage.Subtitle>
        <VeloSteps steps={[...requiredSteps, ...inviteSteps]} />
      </section>
      <InviteOTPForm {...props} />
    </Container>
  );
}

function MFARegistration(props) {
  const intl = useIntl();
  return (
    <Container data-testid={TestIds.REGISTER_MFA}>
      <section>
        <OnboardingPage.Title>
          <FormattedMessage defaultMessage="Verify your mobile phone" />
        </OnboardingPage.Title>

        <OnboardingPage.Subtitle className={styles.subtitle}>
          <FormattedMessage
            defaultMessage="You will need your mobile phone and your {mfaLabel}."
            values={{ mfaLabel: MFATypeLabels(intl)[props.mfaType] }}
          />
        </OnboardingPage.Subtitle>

        <VeloSteps steps={[...requiredSteps, ...secureSteps]} />
      </section>
      <InviteOTPForm {...props} />
    </Container>
  );
}

InviteOTP.TwoFactor = TwoFactor;
InviteOTP.MFARegistration = MFARegistration;
InviteOTP.titles = resendTitles;

export { InviteOTP };
