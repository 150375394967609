import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ErrorCode } from 'velo-data';

const ErrorMessages = {
  [ErrorCode.LESS_THAN_OR_EQUAL_TO]: (values) => (
    <FormattedMessage
      defaultMessage="{label} must be less than or equal to {value}"
      values={values}
    />
  ),
  [ErrorCode.PAYEE_CHALLENGE_CODE]: () => (
    <FormattedMessage defaultMessage="Individuals must have a national identification or challenge code. Companies must have a tax id or a challenge code." />
  ),
  [ErrorCode.REGEX]: (values) => (
    <FormattedMessage
      defaultMessage="{label} does not match the required format"
      values={values}
    />
  ),
  [ErrorCode.MUST_BE_BETWEEN_MIN_MAX]: (values) => (
    <FormattedMessage
      defaultMessage="{label} size must be between {min} and {max}"
      values={values}
    />
  ),
  [ErrorCode.REDEMPTION_CODE_NOT_FOUND]: () => (
    <FormattedMessage defaultMessage="Credit top-up code invalid. Free credits have not been applied." />
  ),
};

const isStringError = (error) => typeof error === 'string';
const isAPIError = (error) => error.errors && error.errors.length;
const isAPIErrorWithLocalisationDetail = (error) => {
  return isAPIError(error) && !!ErrorMessages[error.errors[0].errorCode];
};

export const defaultErrorMessage = (
  <FormattedMessage defaultMessage="Sorry, something went wrong. Please try again." />
);

const ErrorTypes = {
  STRING: 'STRING',
  API_ERROR: 'API_ERROR',
  API_ERROR_WITH_LOCALISATION_SUPPORT: 'API_ERROR_WITH_LOCALISATION_SUPPORT',
};

const getErrorType = (error) => {
  if (isStringError(error)) return ErrorTypes.STRING;
  if (isAPIErrorWithLocalisationDetail(error))
    return ErrorTypes.API_ERROR_WITH_LOCALISATION_SUPPORT;
  if (isAPIError(error)) return ErrorTypes.API_ERROR;
};

const formatCallbackErrorArg =
  (fieldList, cb) =>
  (error, ...rest) => {
    const getErrorText = (error) => {
      const errorType = getErrorType(error);

      switch (errorType) {
        case ErrorTypes.STRING:
          return error;

        case ErrorTypes.API_ERROR_WITH_LOCALISATION_SUPPORT: {
          const localisationFn = ErrorMessages[error.errors[0].errorCode];
          const errorLocationItem = fieldList.find(({ name }) => {
            // Also handle path based field locations i.e path.to.userName
            const parts = error.errors[0].location.split('.');
            const location = parts[parts.length - 1];

            return location === name;
          });

          return localisationFn({
            ...errorLocationItem,
            ...error.errors[0].localisationDetails?.parameters,
          });
        }

        case ErrorTypes.API_ERROR:
          return error.errors[0].errorMessage;
        default:
          return error.message || defaultErrorMessage;
      }
    };

    cb(error ? getErrorText(error) : undefined, ...rest);
  };

export { formatCallbackErrorArg };
