import share from 'callbag-share';
import {
  createSessionContext,
  createSendWithAjax,
  rescueWithRefresh,
  logoutOnError,
} from './send';
import { getAPIEnvironment } from './utils';

import { SendError } from './send/SendError';

export function coreCreateAPI(
  { createRefreshFn, createLogoutFn, init },
  config
) {
  // get the API environment variables
  const { env, host } = getAPIEnvironment(
    window.location.hostname,
    config.host
  );

  // setup a session context with the streams and helper functions
  const sessionStateContext = createSessionContext({
    host,
    // provide the basic auth token for refreshToken requests
    basic: btoa(`${config.apiKey}:${config.secretKey}`),
  });

  // create the base send (that calls fetch/ajax)
  const rawSend = createSendWithAjax(
    sessionStateContext.sessionStateObservable
  );
  // logoutUser also clears the state
  const logoutUser = createLogoutFn(sessionStateContext, rawSend);
  // refresh token is shared to limit in flight refreshes to 1
  const refreshToken = share(
    logoutOnError(logoutUser)(createRefreshFn(sessionStateContext, rawSend))
  );
  // with* helpers wrap a stream performing actions based on the error
  const withLogout = logoutOnError(logoutUser, (error) =>
    // only if it's a FORBIDDEN error
    SendError.isType(403, error)
  );
  const withRetry = rescueWithRefresh(refreshToken);

  return {
    env,
    ...config,
    host,
    ...sessionStateContext,
    // named logoutUser so as not to conflict with existing logout function
    logoutUser,
    refreshToken,
    init,
    send(sendSpec) {
      const stream = withLogout(rawSend(sendSpec));
      if (sendSpec.auth === 'oauth2') {
        return withRetry(stream);
      }
      return stream;
    },
  };
}
