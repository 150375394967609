import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'astroturf/react';
import { VeloIcon } from '../VeloIcon';
import { VeloSectionGrid } from '../VeloSectionGrid';
import { VeloTypography } from '../VeloTypography';

const TitleRow = styled('div')`
  display: flex;
  cursor: pointer;
`;

const ContentRow = styled('div')`
  margin-top: 0.5rem;
  margin-left: 1.5rem;
`;

function ExpandableContent({ title, children }) {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <TitleRow onClick={() => setOpen((open) => !open)}>
        <VeloIcon icon={open ? 'chevron_down' : 'chevron_right'} />
        <VeloTypography use="bodyTextEmphasised">{title}</VeloTypography>
      </TitleRow>
      {open && (
        <ContentRow>
          <VeloTypography use="bodyTextSmaller">{children}</VeloTypography>
        </ContentRow>
      )}
    </div>
  );
}

const YapilyConnectFields = () => (
  <VeloSectionGrid
    compact
    sections={[
      {
        fields: [
          <ExpandableContent
            title={<FormattedMessage defaultMessage="Data sharing" />}
          >
            <FormattedMessage
              defaultMessage="Yapily Connect Ltd will retrieve your bank data based on your request
    and provide this information to Velo Payments."
            />
          </ExpandableContent>,
          <ExpandableContent
            title={<FormattedMessage defaultMessage="Secure connection" />}
          >
            <FormattedMessage defaultMessage="Data is securely retrieved in read-only format and only for the duration agreed with you. You have the right to withdraw consent at any time." />
          </ExpandableContent>,
          <ExpandableContent
            title={<FormattedMessage defaultMessage="FCA authorisation" />}
          >
            <FormattedMessage defaultMessage="Yapily Connect Ltd Ltd is authorised and regulated by the Financial Conduct Authority under the Payment Service Regulations 2017 (827001) for the provision of Account Information and Payment Initiation services." />
          </ExpandableContent>,
          <VeloTypography use="bodyTextSmaller">
            <FormattedMessage
              defaultMessage="For payment to be made from your bank by Velo Payments, we are
  about to securely redirect you to your bank where you will be
  asked to confirm confirm your consent for Yapily Connect Ltd, an FCA
  regulated initiation provider for Velo Payments. Yapily Connect Ltd will share these details with your bank, where you
  will be asked to confirm the following payment."
            />
          </VeloTypography>,
        ],
      },
    ]}
    render={(component) => component}
  />
);

export { YapilyConnectFields };
