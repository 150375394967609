import React from 'react';
import { bool, number } from 'prop-types';
import { LinearProgress } from '@rmwc/linear-progress';
import '@material/linear-progress/dist/mdc.linear-progress.css';

VeloLinearProgress.propTypes = {
  /** A Progress buffer float percentage between 0 and 1. */
  buffer: number,
  /**
   * Hides the progress bar.
   * Adding / removing this prop will trigger an animation in or out.
   */
  closed: bool,
  /** Progress float percentage between 0 and 1. */
  progress: number,
  /** Progress goes from right to left. */
  reversed: bool,
};

export function VeloLinearProgress(props) {
  return <LinearProgress {...props} />;
}
