import {
  PayeeSearchPresenter,
  formatCallbackErrorArg,
  createEarlyExitCallback,
} from 'velo-portal-common';

const CONFIRM_PAYOUT_DELAY = 5000;

export function OpenBankingCreatePayoutPresenter(
  wireframe,
  entity,
  { payorId },
  notifications
) {
  const loader = (cb) => entity.getOpenBankingInstitutions(cb);

  const paymentChannelLoader = (payeeId, cb) => {
    if (payeeId) {
      return entity.getOpenBankingPayee(payeeId, (error, data) => {
        if (error) {
          cb(error);
        } else {
          const paymentChannel = data.paymentChannel;
          cb(undefined, paymentChannel);
        }
      });
    }
  };

  const tokenLoader = (oneTimeToken, cb) => {
    if (oneTimeToken) {
      return entity.confirmOpenBankingPayout(
        {
          oneTimeToken,
        },
        (error, data) => {
          if (error) {
            wireframe.navigateToPayoutCreateOpenBanking.redirect();
            cb(error);
          } else {
            const { payoutId } = data;

            /**
             * @note The API is eventually consistent. Meaning the payment isn't released for 3-4 seconds
             * after using the `oneTimeToken`
             *
             * For the MVP, a delay is added so that the user should see the payout is released on the
             * Payout details screen.
             *
             * @todo Revisit UX and how to handle the API delay
             */
            setTimeout(() => {
              wireframe.sendNote(notifications.PAYOUT_SUBMITTED);
              wireframe.navigateToPayoutDetail.redirect({ payoutId });
            }, CONFIRM_PAYOUT_DELAY);
          }
        }
      );
    }
  };

  const fetchPayeeResults = PayeeSearchPresenter(entity, {
    pageSize: 5,
    payorId,
  });
  const onClose = () => wireframe.goBack();
  const onSubmit = (
    { paymentMemo, selectedBank, payeeId, amount, currency },
    cb
  ) =>
    entity.submitOpenBankingPayout(
      {
        payorId,
        financialInstitutionId: selectedBank.id,
        payments: [
          {
            payeeId,
            amount,
            currency,
            paymentMemo,
          },
        ],
      },
      formatCallbackErrorArg(
        createEarlyExitCallback((data) => {
          window.location.replace(data.authorizationUrl);
        }, cb)
      )
    );

  return [
    loader,
    paymentChannelLoader,
    tokenLoader,
    { fetchPayeeResults, onClose, onSubmit },
  ];
}
