import React from 'react';
import { FormattedMessage, defineMessage } from 'react-intl';
import { paymentChannelsConfig } from 'velo-data';

const Type = {
  SUCCESS: 'success',
  FAILURE: 'failure',
  WARNING: 'warning',
  INFO: 'info',
  PAYEE: 'payee',
};

const DisplayStates = {
  HIDDEN: 'hide',
  ANIMATE_IN: 'animIn',
  ANIMATE_OUT: 'animOut',
};

const VeloNotificationTypes = {
  LOGIN_UNLOCK_SUCCESS: {
    message: (
      <FormattedMessage
        defaultMessage={
          'Successfully unlocked your account. Please try logging in again'
        }
      />
    ),
    actionText: '',
    timeout: 7000,
    type: Type.SUCCESS,
    context: 'login', // Not yet implemented but use to contextually determine styling/position
  },
  LOGIN_UNLOCK_FAILURE: {
    message: (
      <FormattedMessage
        defaultMessage={
          'Sorry Password unlock link has expired. Please try logging in again'
        }
      />
    ),
    actionText: '',
    timeout: 7000,
    type: Type.WARNING,
    context: 'login',
  },
  CONFIRM_PAYMENT_SUCCESS: {
    message: (
      <FormattedMessage defaultMessage="Thank you for confirming your payment." />
    ),
    actionText: '',
    timeout: 7000,
    type: Type.SUCCESS,
    context: 'login',
  },
  CONFIRM_PAYMENT_FAILURE: {
    message: (
      <FormattedMessage defaultMessage="There was a problem confirming your payment" />
    ),
    actionText: '',
    timeout: 7000,
    type: Type.WARNING,
    context: 'login',
  },
  UNSUBSCRIBE_SUCCESS: {
    message: (
      <FormattedMessage
        defaultMessage={
          'You have successfully unsubscribed from email reminders.'
        }
      />
    ),
    actionText: '',
    timeout: 7000,
    type: Type.SUCCESS,
    context: 'login',
  },
  UNSUBSCRIBE_FAILURE: {
    message: (
      <FormattedMessage defaultMessage="There was a problem unsubscribing" />
    ),
    actionText: '',
    timeout: 7000,
    type: Type.WARNING,
    context: 'login',
  },
  PAYMENT_WITHDRAW_SUCCESS: {
    message: (
      <FormattedMessage defaultMessage="Payment successfully withdrawn" />
    ),
    timeout: 5000,
    type: Type.SUCCESS,
    context: 'payments',
    open: true,
    component: 'snackbar',
  },
  PAYMENT_WITHDRAW_FAILURE: {
    message: (
      <FormattedMessage defaultMessage="Payment withdraw failed. Please try again" />
    ),
    timeout: 5000,
    type: Type.FAILURE,
    context: 'payments',
    open: true,
    component: 'snackbar',
  },
  PAYOUT_SUBMITTED: {
    message: (
      <FormattedMessage defaultMessage="Payout submitted successfully." />
    ),
    timeout: 5000,
    type: Type.SUCCESS,
    context: 'payouts',
    open: true,
    component: 'snackbar',
  },
  PAYOUT_WITHDRAW_SUCCESS: {
    message: (
      <FormattedMessage defaultMessage="Payout successfully withdrawn" />
    ),
    timeout: 5000,
    type: Type.SUCCESS,
    context: 'payouts',
    open: true,
    component: 'snackbar',
  },
  PAYOUT_WITHDRAW_FAILURE: {
    message: (
      <FormattedMessage defaultMessage="Payout withdraw failed. Please try again" />
    ),
    timeout: 5000,
    type: Type.FAILURE,
    context: 'payouts',
    open: true,
    component: 'snackbar',
  },
  PAYOUT_SCHEDULE_SUCCESS: {
    message: (
      <FormattedMessage defaultMessage="Payout successfully scheduled" />
    ),
    timeout: 5000,
    type: Type.SUCCESS,
    context: 'payouts',
    open: true,
    component: 'snackbar',
  },
  PAYOUT_DESCHEDULE_SUCCESS: {
    message: (
      <FormattedMessage defaultMessage="Payout schedule successfully cleared" />
    ),
    timeout: 5000,
    type: Type.SUCCESS,
    context: 'payouts',
    open: true,
    component: 'snackbar',
  },
  PAYOUT_DESCHEDULE_FAILURE: {
    message: (
      <FormattedMessage defaultMessage="Failed to clear payout schedule" />
    ),
    timeout: 5000,
    type: Type.FAILURE,
    context: 'payouts',
    open: true,
    component: 'snackbar',
  },
  PAYOUT_INSTRUCT_SUCCESS: {
    message: (
      <FormattedMessage defaultMessage="Payout successfully instructed" />
    ),
    timeout: 5000,
    type: Type.SUCCESS,
    context: 'payouts',
    open: true,
    component: 'snackbar',
  },
  PAYOUT_INSTRUCT_FAILURE: {
    open: true,
    message: (
      <FormattedMessage defaultMessage="Payout instruct failed. Please try again" />
    ),
    timeout: 5000,
    type: Type.FAILURE,
    component: 'snackbar',
    context: 'payouts',
  },
  PAYOUT_PROCESSING_FAILURE: {
    open: true,
    message: (
      <FormattedMessage defaultMessage="Something went wrong, please try again." />
    ),
    timeout: 5000,
    type: Type.FAILURE,
    component: 'snackbar',
    context: 'payouts',
  },
  RESEND_INVITE_SUCCESS: {
    open: true,
    type: Type.SUCCESS,
    message: defineMessage({
      defaultMessage: 'Successfully sent an invitation to "{email}"',
    }),
    timeout: 3000,
    component: 'snackbar',
    context: 'payees',
  },
  RESEND_INVITE_FAILURE: {
    open: true,
    type: Type.FAILURE,
    message: defineMessage({
      defaultMessage: 'Failed to resend an invitation to "{email}"',
    }),
    actionText: 'Resend',
    stacked: true,
    timeout: 5000,
    component: 'snackbar',
    context: 'payees',
  },
  DELETE_PAYEE_SUCCESS: {
    open: true,
    type: Type.SUCCESS,
    message: (
      <FormattedMessage defaultMessage="The Payee was deleted successfully" />
    ),
    timeout: 3000,
    component: 'snackbar',
    context: 'payees',
  },
  CREATE_PAYEE_SUCCESS: {
    open: true,
    type: Type.SUCCESS,
    message: defineMessage({
      defaultMessage:
        '{itemCount, plural, one {Successfully created 1 Payee} other {Successfully created {itemCount} Payees}}',
    }),
    timeout: 5000,
    component: 'snackbar',
    context: 'payees',
  },
  DELETE_PAYEE_FAILURE: {
    open: true,
    message: <FormattedMessage defaultMessage="Error deleting the Payee" />,
    type: Type.FAILURE,
    timeout: 5000,
    component: 'snackbar',
    context: 'payees',
  },
  PROFILE_UPDATE_SUCCESS: {
    open: true,
    type: Type.SUCCESS,
    message: (
      <FormattedMessage defaultMessage="Successfully updated profile details" />
    ),
    stacked: true,
    timeout: 5000,
    component: 'snackbar',
    context: 'payees',
  },
  PAYEE_STATUS_UPDATE: {
    open: true,
    type: Type.SUCCESS,
    message: (
      <FormattedMessage defaultMessage="Successfully updated payee status." />
    ),
    stacked: true,
    timeout: 5000,
    component: 'snackbar',
    context: 'payees',
  },
  PAUSE_PAYMENT_UPDATE_SUCCESS: {
    open: true,
    type: Type.SUCCESS,
    message: (
      <FormattedMessage defaultMessage="Successfully updated Payments status." />
    ),
    stacked: true,
    timeout: 5000,
    component: 'snackbar',
    context: 'payees',
  },
  COMPLIANCE_UPDATE_SUCCESS: {
    open: true,
    type: Type.SUCCESS,
    message: (
      <FormattedMessage defaultMessage="Successfully updated Know Your Customer status." />
    ),
    stacked: true,
    timeout: 5000,
    component: 'snackbar',
    context: 'payees',
  },
  RESEND_SMS_SUCCESS: {
    open: true,
    type: Type.SUCCESS,
    message: (
      <FormattedMessage defaultMessage="Code successfully sent to your mobile phone." />
    ),
    timeout: 3000,
    component: 'snackbar',
    context: 'login-mfa',
  },
  RESEND_SMS_FAILURE: {
    open: true,
    message: (
      <FormattedMessage defaultMessage="Something went wrong, please try again." />
    ),
    type: Type.FAILURE,
    timeout: 5000,
    component: 'snackbar',
    context: 'login-mfa',
  },
  UNREGISTER_MFA_SUCCESS: {
    open: true,
    message: (
      <FormattedMessage
        defaultMessage={
          'Successfully unregistered your two-factor authentication device.'
        }
      />
    ),
    type: Type.SUCCESS,
    timeout: 5000,
    component: 'snackbar',
    context: 'login-mfa',
  },
  UNREGISTER_MFA_FAILURE: {
    open: true,
    message: (
      <FormattedMessage defaultMessage="Something went wrong, please try again." />
    ),
    type: Type.FAILURE,
    timeout: 5000,
    component: 'snackbar',
    context: 'login-mfa',
  },
  FUNDING_ACCOUNT_CREATE_SUCCESS: {
    open: true,
    type: Type.SUCCESS,
    message: (
      <FormattedMessage defaultMessage="Successfully created a funding account." />
    ),
    stacked: true,
    timeout: 5000,
    component: 'snackbar',
    context: 'funding-accounts',
  },
  SOURCE_ACCOUNT_UPDATE_SUCCESS: {
    open: true,
    type: Type.SUCCESS,
    message: (
      <FormattedMessage defaultMessage="Successfully updated the account details." />
    ),
    stacked: true,
    timeout: 5000,
    component: 'snackbar',
    context: 'source-accounts',
  },
  SOURCE_ACCOUNT_OBO_UPDATE_SUCCESS: {
    open: true,
    type: Type.SUCCESS,
    message: (
      <FormattedMessage defaultMessage="Successfully updated the On Behalf Of details." />
    ),
    stacked: true,
    timeout: 5000,
    component: 'snackbar',
    context: 'source-accounts',
  },
  SOURCE_ACCOUNT_OBO_UPDATE_FAILURE: {
    open: true,
    type: Type.FAILURE,
    message: (
      <FormattedMessage defaultMessage="There was an issue updating the On Behalf Of details." />
    ),
    stacked: true,
    timeout: 5000,
    component: 'snackbar',
    context: 'source-accounts',
  },
  SOURCE_ACCOUNT_ADD_FUNDING_SUCCESS: {
    open: true,
    type: Type.SUCCESS,
    message: (
      <FormattedMessage defaultMessage="The funding request was made successfully." />
    ),
    stacked: true,
    timeout: 5000,
    component: 'snackbar',
    context: 'source-accounts',
  },
  SOURCE_ACCOUNT_MIN_BALANCE_SUCCESS: {
    open: true,
    type: Type.SUCCESS,
    message: (
      <FormattedMessage
        defaultMessage={
          'Successfully updated the minimum balance for notification.'
        }
      />
    ),
    stacked: true,
    timeout: 5000,
    component: 'snackbar',
    context: 'source-accounts',
  },
  SOURCE_ACCOUNT_AUTO_TOPUP_CONFIG_SUCCESS: {
    open: true,
    type: Type.SUCCESS,
    message: (
      <FormattedMessage defaultMessage="Successfully updated the auto topup configuration." />
    ),
    stacked: true,
    timeout: 5000,
    component: 'snackbar',
    context: 'source-accounts',
  },
  SOURCE_ACCOUNT_CREATE_SUCCESS: {
    open: true,
    message: (
      <FormattedMessage defaultMessage="Successfully created source account." />
    ),
    type: Type.SUCCESS,
    timeout: 5000,
    component: 'snackbar',
    context: 'source-accounts',
  },
  SOURCE_ACCOUNT_TRANSFER_SUCCESS: {
    open: true,
    type: Type.SUCCESS,
    message: <FormattedMessage defaultMessage="Successfully transferred." />,
    stacked: true,
    timeout: 5000,
    component: 'snackbar',
    context: 'source-accounts',
  },
  SOURCE_ACCOUNT_DELETE_SUCCESS: {
    open: true,
    message: (
      <FormattedMessage defaultMessage="Successfully deleted the source account." />
    ),
    type: Type.SUCCESS,
    timeout: 5000,
    component: 'snackbar',
    context: 'source-accounts',
  },
  SOURCE_ACCOUNT_DELETE_FAILURE: {
    open: true,
    message: (
      <FormattedMessage defaultMessage="Failed to delete the source account." />
    ),
    type: Type.FAILURE,
    timeout: 5000,
    component: 'snackbar',
    context: 'source-accounts',
  },
  SOURCE_ACCOUNT_DELETE_FBO_FAILURE: {
    open: true,
    message: (
      <FormattedMessage defaultMessage="Failed to delete the source account. To delete an FBO source account, the balance must be zero." />
    ),
    type: Type.FAILURE,
    timeout: 5000,
    component: 'snackbar',
    context: 'source-accounts',
  },
  TAG_DELETE_SUCCESS: {
    open: true,
    type: Type.SUCCESS,
    message: (
      <FormattedMessage defaultMessage="Successfully deleted the tag." />
    ),
    stacked: true,
    timeout: 5000,
    component: 'snackbar',
    context: 'tags',
  },
  TAG_CREATE_SUCCESS: {
    open: true,
    type: Type.SUCCESS,
    message: <FormattedMessage defaultMessage="Successfully created a tag." />,
    stacked: true,
    timeout: 5000,
    component: 'snackbar',
    context: 'tags',
  },
  TAG_EDIT_SUCCESS: {
    open: true,
    type: Type.SUCCESS,
    message: (
      <FormattedMessage defaultMessage="Successfully updated the tag." />
    ),
    stacked: true,
    timeout: 5000,
    component: 'snackbar',
    context: 'tags',
  },
  TAG_LINK_SUCCESS: {
    open: true,
    type: Type.SUCCESS,
    message: <FormattedMessage defaultMessage="Successfully linked tags." />,
    stacked: true,
    timeout: 5000,
    component: 'snackbar',
    context: 'tags',
  },
  TAG_ADD_SUCCESS: {
    open: true,
    type: Type.SUCCESS,
    message: defineMessage({
      defaultMessage: 'Successfully added the tag "{name}"',
    }),
    stacked: true,
    timeout: 5000,
    component: 'snackbar',
    context: 'tags',
  },
  TAG_REMOVE_SUCCESS: {
    open: true,
    type: Type.SUCCESS,
    message: defineMessage({
      defaultMessage: 'Successfully removed the tag "{name}"',
    }),
    stacked: true,
    timeout: 5000,
    component: 'snackbar',
    context: 'tags',
  },
  TAG_REMOVE_FAILURE: {
    open: true,
    type: Type.FAILURE,
    message: defineMessage({
      defaultMessage: 'Failed to remove the tag "{name}"',
    }),
    stacked: true,
    timeout: 5000,
    component: 'snackbar',
    context: 'tags',
  },
  TAG_UNLINK_SUCCESS: {
    open: true,
    type: Type.SUCCESS,
    message: <FormattedMessage defaultMessage="Successfully unlinked tags." />,
    stacked: true,
    timeout: 5000,
    component: 'snackbar',
    context: 'tags',
  },
  TAG_UNLINK_FAILURE: {
    open: true,
    type: Type.FAILURE,
    message: (
      <FormattedMessage defaultMessage="Failed to unlink tags, please try again." />
    ),
    stacked: true,
    timeout: 5000,
    component: 'snackbar',
    context: 'tags',
  },
  PAYMENT_CHANNEL_REORDER_SUCCESS: {
    message: (
      <FormattedMessage
        defaultMessage={`Successfully reordered {title} list.`}
        values={{ title: paymentChannelsConfig.title.toLowerCase() }}
      />
    ),
    open: true,
    type: Type.SUCCESS,
    stacked: true,
    timeout: 5000,
    component: 'snackbar',
    context: 'payment-channels',
  },
  PAYMENT_CHANNEL_REORDER_FAILURE: {
    message: (
      <FormattedMessage
        defaultMessage={`Failed to reorder {title} list.`}
        values={{ title: paymentChannelsConfig.title.toLowerCase() }}
      />
    ),
    open: true,
    type: Type.FAILURE,
    stacked: true,
    timeout: 5000,
    component: 'snackbar',
    context: 'payment-channels',
  },
  PAYMENT_CHANNEL_ENABLE_SUCCESS: {
    message: (
      <FormattedMessage
        defaultMessage={`Successfully re-enabled {title}.`}
        values={{ title: paymentChannelsConfig.title.toLowerCase() }}
      />
    ),
    open: true,
    type: Type.SUCCESS,
    stacked: true,
    timeout: 5000,
    component: 'snackbar',
    context: 'payment-channels',
  },
  PAYMENT_CHANNEL_ENABLE_FAILURE: {
    message: (
      <FormattedMessage
        defaultMessage={`Failed to re-enable {title}.`}
        values={{ title: paymentChannelsConfig.title.toLowerCase() }}
      />
    ),
    open: true,
    type: Type.FAILURE,
    stacked: true,
    timeout: 5000,
    component: 'snackbar',
    context: 'payment-channels',
  },
  PAYMENT_CHANNEL_DELETE_SUCCESS: {
    message: (
      <FormattedMessage
        defaultMessage={`Successfully deleted {title}.`}
        values={{ title: paymentChannelsConfig.title.toLowerCase() }}
      />
    ),
    open: true,
    type: Type.SUCCESS,
    stacked: true,
    timeout: 5000,
    component: 'snackbar',
    context: 'payment-channels',
  },
  PAYMENT_CHANNEL_DELETE_FAILURE: {
    message: (
      <FormattedMessage
        defaultMessage={`Failed to delete {title}.`}
        values={{ title: paymentChannelsConfig.title.toLowerCase() }}
      />
    ),
    open: true,
    type: Type.FAILURE,
    stacked: true,
    timeout: 5000,
    component: 'snackbar',
    context: 'payment-channels',
  },
  PAYMENT_CHANNEL_CREATE_SUCCESS: {
    message: (
      <FormattedMessage
        defaultMessage={`Your payment method was created.`}
        values={{ title: paymentChannelsConfig.title.toLowerCase() }}
      />
    ),
    open: true,
    type: Type.SUCCESS,
    stacked: true,
    timeout: 5000,
    component: 'snackbar',
    context: 'payment-channels',
  },
  PAYMENT_CHANNEL_CREATE_FAILURE: {
    message: (
      <FormattedMessage
        defaultMessage={`There was an error creating your payment method.`}
        values={{ title: paymentChannelsConfig.title.toLowerCase() }}
      />
    ),
    open: true,
    type: Type.FAILURE,
    stacked: true,
    timeout: 5000,
    component: 'snackbar',
    context: 'payment-channels',
  },
  PAYMENT_CHANNEL_UPDATE_SUCCESS: {
    message: (
      <FormattedMessage
        defaultMessage={`Your payment method was updated.`}
        values={{ title: paymentChannelsConfig.title.toLowerCase() }}
      />
    ),
    open: true,
    type: Type.SUCCESS,
    stacked: true,
    timeout: 5000,
    component: 'snackbar',
    context: 'payment-channels',
  },
  PAYMENT_CHANNEL_UPDATE_FAILURE: {
    message: (
      <FormattedMessage
        defaultMessage={`There was an error updating your payment method.`}
        values={{ title: paymentChannelsConfig.title.toLowerCase() }}
      />
    ),
    open: true,
    type: Type.FAILURE,
    stacked: true,
    timeout: 5000,
    component: 'snackbar',
    context: 'payment-channels',
  },
  PAYMENT_CONFIRMED_SUCCESS: {
    message: (
      <FormattedMessage defaultMessage="Successfully confirmed payment." />
    ),
    open: true,
    type: Type.SUCCESS,
    stacked: true,
    timeout: 5000,
    component: 'snackbar',
    context: 'payments',
  },
  PAYMENT_CONFIRMED_FAILURE: {
    message: <FormattedMessage defaultMessage="Failed to confirm payment." />,
    open: true,
    type: Type.FAILURE,
    stacked: true,
    timeout: 5000,
    component: 'snackbar',
    context: 'payments',
  },
  CREATE_PAYOR_USER_SUCCESS: {
    message: (
      <FormattedMessage defaultMessage="Successfully created a Payor user." />
    ),
    open: true,
    type: Type.SUCCESS,
    stacked: true,
    timeout: 5000,
    component: 'snackbar',
    context: 'payor-users',
  },
  CREATE_PAYEE_USER_SUCCESS: {
    message: (
      <FormattedMessage defaultMessage="Successfully created a Payee user." />
    ),
    open: true,
    type: Type.SUCCESS,
    stacked: true,
    timeout: 5000,
    component: 'snackbar',
    context: 'payee-users',
  },
  CREATE_BACKOFFICE_USER_SUCCESS: {
    message: (
      <FormattedMessage defaultMessage="Successfully created an Admin user." />
    ),
    open: true,
    type: Type.SUCCESS,
    stacked: true,
    timeout: 5000,
    component: 'snackbar',
    context: 'backoffice-users',
  },
  PAYOR_LINK_CREATED: {
    open: true,
    type: Type.SUCCESS,
    message: <FormattedMessage defaultMessage="Successfully linked Payors." />,
    timeout: 5000,
    component: 'snackbar',
    context: 'payor-links',
  },
  PAYOR_UNLINK_SUCCESS: {
    open: true,
    type: Type.SUCCESS,
    message: (
      <FormattedMessage defaultMessage="Successfully unlinked Payors." />
    ),
    timeout: 5000,
    component: 'snackbar',
    context: 'payor-links',
  },
  PAYOR_UNLINK_FAILURE: {
    open: true,
    type: Type.FAILURE,
    message: <FormattedMessage defaultMessage="Failed to unlink Payors." />,
    timeout: 5000,
    component: 'snackbar',
    context: 'payor-links',
  },
  WEBHOOK_SUCCESS: {
    message: (
      <FormattedMessage defaultMessage="Successfully updated the Webhook." />
    ),
    open: true,
    type: Type.SUCCESS,
    stacked: true,
    timeout: 5000,
    component: 'snackbar',
    context: 'webhooks',
  },
  DELETE_PAYOR_USER_SUCCESS: {
    message: (
      <FormattedMessage defaultMessage="Successfully deleted the Payor user." />
    ),
    open: true,
    type: Type.SUCCESS,
    stacked: true,
    timeout: 5000,
    component: 'snackbar',
    context: 'payor-users',
  },
  DELETE_PAYOR_USER_FAILURE: {
    open: true,
    message: (
      <FormattedMessage defaultMessage="Error deleting the Payor user." />
    ),
    type: Type.FAILURE,
    timeout: 5000,
    component: 'snackbar',
    context: 'payor-users',
  },
  DELETE_BACKOFFICE_USER_SUCCESS: {
    message: (
      <FormattedMessage defaultMessage="Successfully deleted the Admin user." />
    ),
    open: true,
    type: Type.SUCCESS,
    stacked: true,
    timeout: 5000,
    component: 'snackbar',
    context: 'backoffice-users',
  },
  DELETE_BACKOFFICE_USER_FAILURE: {
    open: true,
    message: (
      <FormattedMessage defaultMessage="Error deleting the Admin user." />
    ),
    type: Type.FAILURE,
    timeout: 5000,
    component: 'snackbar',
    context: 'backoffice-users',
  },
  DELETE_PAYEE_USER_SUCCESS: {
    message: (
      <FormattedMessage defaultMessage="Successfully deleted the Payee user." />
    ),
    open: true,
    type: Type.SUCCESS,
    stacked: true,
    timeout: 5000,
    component: 'snackbar',
    context: 'payee-users',
  },
  DELETE_PAYEE_USER_FAILURE: {
    open: true,
    message: (
      <FormattedMessage defaultMessage="Error deleting the Payee user." />
    ),
    type: Type.FAILURE,
    timeout: 5000,
    component: 'snackbar',
    context: 'payee-users',
  },
  RESEND_MFA_REGISTRATION_SUCCESS: {
    open: true,
    type: Type.SUCCESS,
    message: (
      <FormattedMessage defaultMessage="Successfully resent registration email." />
    ),
    timeout: 3000,
    component: 'snackbar',
    context: 'payor-users',
  },
  RESEND_MFA_REGISTRATION_FAILURE: {
    open: true,
    message: (
      <FormattedMessage defaultMessage="Error resending the registration email." />
    ),
    type: Type.FAILURE,
    timeout: 5000,
    component: 'snackbar',
    context: 'payor-users',
  },
  RESEND_PAYOR_USER_INVITE_SUCCESS: {
    open: true,
    type: Type.SUCCESS,
    message: (
      <FormattedMessage defaultMessage="Successfully resent Payor user invitation" />
    ),
    timeout: 3000,
    component: 'snackbar',
    context: 'payor-users',
  },
  RESEND_PAYOR_USER_INVITE_FAILURE: {
    open: true,
    message: (
      <FormattedMessage defaultMessage="Error resending the Payor user invite." />
    ),
    type: Type.FAILURE,
    timeout: 5000,
    component: 'snackbar',
    context: 'payor-users',
  },
  RESEND_BACKOFFICE_USER_INVITE_SUCCESS: {
    open: true,
    type: Type.SUCCESS,
    message: (
      <FormattedMessage defaultMessage="Successfully resent the Admin user invitation" />
    ),
    timeout: 3000,
    component: 'snackbar',
    context: 'backoffice-users',
  },
  RESEND_BACKOFFICE_USER_INVITE_FAILURE: {
    open: true,
    message: (
      <FormattedMessage defaultMessage="Error resending the Admin user invite." />
    ),
    type: Type.FAILURE,
    timeout: 5000,
    component: 'snackbar',
    context: 'backoffice-users',
  },
  RESEND_PAYEE_USER_INVITE_SUCCESS: {
    open: true,
    type: Type.SUCCESS,
    message: (
      <FormattedMessage defaultMessage="Successfully resent the Payee user invitation" />
    ),
    timeout: 3000,
    component: 'snackbar',
    context: 'payee-users',
  },
  RESEND_PAYEE_USER_INVITE_FAILURE: {
    open: true,
    message: (
      <FormattedMessage defaultMessage="Error resending the Payee user invite." />
    ),
    type: Type.FAILURE,
    timeout: 5000,
    component: 'snackbar',
    context: 'payee-users',
  },
  PAYOR_USER_EDIT_SUCCESS: {
    open: true,
    type: Type.SUCCESS,
    message: (
      <FormattedMessage defaultMessage="Successfully updated the Payor user." />
    ),
    stacked: true,
    timeout: 5000,
    component: 'snackbar',
    context: 'payor-users',
  },
  BACKOFFICE_USER_EDIT_SUCCESS: {
    open: true,
    type: Type.SUCCESS,
    message: (
      <FormattedMessage defaultMessage="Successfully updated the Admin user." />
    ),
    stacked: true,
    timeout: 5000,
    component: 'snackbar',
    context: 'payor-users',
  },
  USER_UNREGISTER_MFA_SUCCESS: {
    open: true,
    message: (
      <FormattedMessage
        defaultMessage={
          'Successfully unregistered the two-factor authentication device.'
        }
      />
    ),
    type: Type.SUCCESS,
    timeout: 5000,
    component: 'snackbar',
    context: 'payor-users',
  },
  USER_REGISTER_MFA_SUCCESS: {
    message: (
      <FormattedMessage
        defaultMessage={
          'Successfully registered your two-factor authentication device. Please log in'
        }
      />
    ),
    actionText: '',
    timeout: 7000,
    type: Type.SUCCESS,
    context: 'payor-users',
  },
  USER_UNREGISTER_MFA_FAILURE: {
    open: true,
    message: (
      <FormattedMessage
        defaultMessage={
          'Failed to unregistered the two-factor authentication device.'
        }
      />
    ),
    type: Type.FAILURE,
    timeout: 5000,
    component: 'snackbar',
    context: 'payor-users',
  },
  USER_DETAILS_UPDATE_SUCCESS: {
    open: true,
    component: 'snackbar',
    message: (
      <FormattedMessage defaultMessage="Successfully updated the user details." />
    ),
    timeout: 7000,
    type: Type.SUCCESS,
    context: 'payor-users',
  },
  USER_DETAILS_UPDATE_FAILURE: {
    open: true,
    component: 'snackbar',
    message: (
      <FormattedMessage defaultMessage="Failed to update the user details." />
    ),
    type: Type.FAILURE,
    timeout: 5000,
    context: 'payor-users',
  },
  UNLOCK_PAYOR_USER_SUCCESS: {
    open: true,
    type: Type.SUCCESS,
    message: (
      <FormattedMessage defaultMessage="Successfully unlocked Payor user." />
    ),
    timeout: 3000,
    component: 'snackbar',
    context: 'payor-users',
  },
  UNLOCK_PAYOR_USER_FAILURE: {
    open: true,
    message: (
      <FormattedMessage defaultMessage="Error unlocking the Payor user." />
    ),
    type: Type.FAILURE,
    timeout: 5000,
    component: 'snackbar',
    context: 'payor-users',
  },
  UNLOCK_BACKOFFICE_USER_SUCCESS: {
    open: true,
    type: Type.SUCCESS,
    message: (
      <FormattedMessage defaultMessage="Successfully unlocked the Admin user." />
    ),
    timeout: 3000,
    component: 'snackbar',
    context: 'backoffice-users',
  },
  UNLOCK_BACKOFFICE_USER_FAILURE: {
    open: true,
    message: (
      <FormattedMessage defaultMessage="Error unlocking the Admin user." />
    ),
    type: Type.FAILURE,
    timeout: 5000,
    component: 'snackbar',
    context: 'backoffice-users',
  },
  UNLOCK_PAYEE_USER_SUCCESS: {
    open: true,
    type: Type.SUCCESS,
    message: (
      <FormattedMessage defaultMessage="Successfully unlocked the Payee user." />
    ),
    timeout: 3000,
    component: 'snackbar',
    context: 'payee-users',
  },
  UNLOCK_PAYEE_USER_FAILURE: {
    open: true,
    message: (
      <FormattedMessage defaultMessage="Error unlocking the Payee user." />
    ),
    type: Type.FAILURE,
    timeout: 5000,
    component: 'snackbar',
    context: 'payee-users',
  },
  FX_FEES_REPORT_DOWNLOAD_SUCCESS: {
    open: true,
    message: (
      <FormattedMessage defaultMessage="Successfully downloaded the report." />
    ),
    type: Type.SUCCESS,
    timeout: 5000,
    component: 'snackbar',
    context: 'fx-fees',
  },
  USER_TOKEN_INVALID: {
    message: (
      <FormattedMessage
        defaultMessage={
          'Something went wrong. Please contact your administrator.'
        }
      />
    ),
    actionText: '',
    timeout: 7000,
    type: Type.WARNING,
    context: 'token',
  },
  SESSION_EXPIRED_AUTO_LOGOUT: {
    message: (
      <FormattedMessage
        defaultMessage={'To continue using your account, please log in again.'}
      />
    ),
    actionText: '',
    type: Type.WARNING,
    context: 'session-expired-auto-logout',
    dismissesOnAction: true,
  },
  FUNDING_ALLOCATE_SUCCESS: {
    open: true,
    message: (
      <FormattedMessage defaultMessage="Successfully allocated funding." />
    ),
    type: Type.SUCCESS,
    timeout: 5000,
    component: 'snackbar',
    context: 'fundings',
  },
  BACKOFFICE_DEALLOCATE_FUNDING_SUCCESS: {
    open: true,
    type: Type.SUCCESS,
    message: (
      <FormattedMessage defaultMessage="Successfully deallocated funding." />
    ),
    stacked: true,
    timeout: 5000,
    component: 'snackbar',
    context: 'deallocation',
  },
  EDIT_PAYOR_DETAILS_SUCCESS: {
    open: true,
    type: Type.SUCCESS,
    message: (
      <FormattedMessage defaultMessage="Successfully updated Payor details." />
    ),
    stacked: true,
    timeout: 5000,
    component: 'snackbar',
    context: 'edit-payor',
  },
  EDIT_PAYOR_BRANDING_SUCCESS: {
    open: true,
    type: Type.SUCCESS,
    message: (
      <FormattedMessage defaultMessage="Successfully updated Payor branding details." />
    ),
    stacked: true,
    timeout: 5000,
    component: 'snackbar',
    context: 'edit-payor',
  },
  EDIT_PAYOR_COMPLIANCE_SUCCESS: {
    open: true,
    type: Type.SUCCESS,
    message: (
      <FormattedMessage defaultMessage="Successfully updated Payor compliance details." />
    ),
    stacked: true,
    timeout: 5000,
    component: 'snackbar',
    context: 'edit-payor',
  },
  EDIT_APPEARANCE_MODE: {
    open: true,
    type: Type.SUCCESS,
    message: (
      <FormattedMessage defaultMessage="Successfully updated appearance." />
    ),
    stacked: true,
    timeout: 5000,
    component: 'snackbar',
    context: 'settings',
  },
  EDIT_ADVANCED_SETTING: {
    open: true,
    type: Type.SUCCESS,
    message: (
      <FormattedMessage defaultMessage="Successfully updated setting." />
    ),
    stacked: true,
    timeout: 5000,
    component: 'snackbar',
    context: 'settings',
  },
  EDIT_PAYEE_SUCCESS: {
    open: true,
    type: Type.SUCCESS,
    message: (
      <FormattedMessage defaultMessage="Successfully updated Payee details." />
    ),
    stacked: true,
    timeout: 5000,
    component: 'snackbar',
    context: 'edit-payee',
  },
  RESEND_PAYEE_INVITE_SUCCESS: {
    open: true,
    type: Type.SUCCESS,
    message: (
      <FormattedMessage defaultMessage="Successfully resent Payee invitation" />
    ),
    timeout: 3000,
    component: 'snackbar',
    context: 'payees',
  },
  RESEND_PAYEE_INVITE_FAILURE: {
    open: true,
    type: Type.FAILURE,
    message: (
      <FormattedMessage defaultMessage="Error resending the Payee invite." />
    ),
    timeout: 5000,
    component: 'snackbar',
    context: 'payees',
  },
  UNLOCK_PAYEE_SUCCESS: {
    open: true,
    type: Type.SUCCESS,
    message: <FormattedMessage defaultMessage="Successfully unlocked Payee." />,
    timeout: 3000,
    component: 'snackbar',
    context: 'payees',
  },
  UNLOCK_PAYEE_FAILURE: {
    open: true,
    message: <FormattedMessage defaultMessage="Error unlocking the Payee." />,
    type: Type.FAILURE,
    timeout: 5000,
    component: 'snackbar',
    context: 'payees',
  },
  EDIT_PAYEE_REMOTE_ID_SUCCESS: {
    open: true,
    type: Type.SUCCESS,
    message: (
      <FormattedMessage defaultMessage="Successfully updated the Payee remote ID." />
    ),
    stacked: true,
    timeout: 5000,
    component: 'snackbar',
    context: 'edit-payee',
  },
  EXPORT_PAYIN_PAYMENTS_SUCCESS: {
    open: true,
    type: Type.SUCCESS,
    message: (
      <FormattedMessage defaultMessage="Successfully downloaded payment history." />
    ),
    timeout: 5000,
    component: 'snackbar',
    context: 'payin-payments',
  },
  EXPORT_PAYIN_PAYMENTS_FAILURE: {
    open: true,
    message: (
      <FormattedMessage defaultMessage="Error downloading payment history." />
    ),
    type: Type.FAILURE,
    timeout: 5000,
    component: 'snackbar',
    context: 'payin-payments',
  },
  UPLOAD_BRANDING_SUCCESS: {
    open: true,
    type: Type.SUCCESS,
    message: <FormattedMessage defaultMessage="Successfully uploaded image." />,
    timeout: 5000,
    component: 'snackbar',
    context: 'payin-branding',
  },
  UPLOAD_BRANDING_FAILURE: {
    open: true,
    message: <FormattedMessage defaultMessage="Error uploading image." />,
    type: Type.FAILURE,
    timeout: 5000,
    component: 'snackbar',
    context: 'payin-branding',
  },
  LOGIN_MAGIC_LINK_SUCCESS: {
    open: true,
    type: Type.SUCCESS,
    message: (
      <FormattedMessage defaultMessage="An email has been sent to your inbox containing your magic link to log in." />
    ),
    timeout: 5000,
    component: 'snackbar',
    context: 'payin-login',
  },
  LOGIN_MAGIC_LINK_FAILURE: {
    open: true,
    type: Type.FAILURE,
    message: (
      <FormattedMessage defaultMessage="There was an error sending a magic link to your inbox." />
    ),
    timeout: 5000,
    component: 'snackbar',
    context: 'payin-login',
  },
  EDIT_CREDITOR_DETAILS_SUCCESS: {
    open: true,
    type: Type.SUCCESS,
    message: (
      <FormattedMessage defaultMessage="Your details have been updated." />
    ),
    timeout: 5000,
    component: 'snackbar',
    context: 'payin-creditor-details',
  },
  EDIT_CREDITOR_DETAILS_FAILURE: {
    open: true,
    type: Type.FAILURE,
    message: (
      <FormattedMessage defaultMessage="There was an error updating your details." />
    ),
    timeout: 5000,
    component: 'snackbar',
    context: 'payin-creditor-details',
  },
  PAYIN_CODE_REDEMPTION_SUCCESS: {
    open: true,
    type: Type.SUCCESS,
    message: (
      <FormattedMessage defaultMessage="Top-up code credits successfully applied." />
    ),
    timeout: 5000,
    component: 'snackbar',
    context: 'payin-code-redemption',
  },
  PAYIN_CODE_REDEMPTION_FAILURE: {
    open: true,
    type: Type.FAILURE,
    message: (
      <FormattedMessage defaultMessage="Credit top-up code invalid. Free credits have not been applied." />
    ),
    timeout: 5000,
    component: 'snackbar',
    context: 'payin-code-redemption',
  },
  PAYIN_REQUEST_DELETE_SUCCESS: {
    open: true,
    type: Type.SUCCESS,
    message: (
      <FormattedMessage defaultMessage="The request has been deleted." />
    ),
    timeout: 5000,
    component: 'snackbar',
    context: 'payin-request-delete',
  },
  PAYIN_REQUEST_DELETE_FAILURE: {
    open: true,
    type: Type.FAILURE,
    message: (
      <FormattedMessage defaultMessage="We were unable to delete this request. You may already have payments against this request." />
    ),
    timeout: 5000,
    component: 'snackbar',
    context: 'payin-request-delete',
  },
  DEBTOR_SEND_EMAIL_RECEIPT_SUCCESS: {
    open: true,
    type: Type.SUCCESS,
    message: (
      <FormattedMessage defaultMessage="Successfully sent email receipt." />
    ),
    timeout: 5000,
    component: 'snackbar',
    context: 'debtor-send-email-receipt',
  },
  PAYIN_ADMIN_CREDITOR_DELETE_SUCCESS: {
    open: true,
    type: Type.SUCCESS,
    message: (
      <FormattedMessage defaultMessage="The creditor has been deleted." />
    ),
    timeout: 5000,
    component: 'snackbar',
    context: 'payin-admin-creditor-details',
  },
  PAYIN_ADMIN_CREDITOR_DELETE_FAILURE: {
    open: true,
    type: Type.FAILURE,
    message: (
      <FormattedMessage defaultMessage="We were unable to delete this creditor." />
    ),
    timeout: 5000,
    component: 'snackbar',
    context: 'payin-admin-creditor-details',
  },
  PAYIN_KIOSK_UPDATE_SUCCESS: {
    open: true,
    type: Type.SUCCESS,
    message: <FormattedMessage defaultMessage="Kiosk updated successfully." />,
    timeout: 5000,
    component: 'snackbar',
    context: 'payin-kiosk-update',
  },
  PAYIN_KIOSK_UPDATE_FAILURE: {
    open: true,
    type: Type.FAILURE,
    message: <FormattedMessage defaultMessage="Kiosk update failed." />,
    timeout: 5000,
    component: 'snackbar',
    context: 'payin-kiosk-update',
  },
  EMAIL_TEMPLATE_UPDATE_SUCCESS: {
    open: true,
    type: Type.SUCCESS,
    message: (
      <FormattedMessage defaultMessage="Your changes have been saved." />
    ),
    timeout: 5000,
    component: 'snackbar',
    context: 'email-template-update',
  },
  EMAIL_TEMPLATE_UPDATE_FAILURE: {
    open: true,
    type: Type.FAILURE,
    message: (
      <FormattedMessage defaultMessage="We were unable to save changes made to this draft." />
    ),
    timeout: 5000,
    component: 'snackbar',
    context: 'email-template-update',
  },
  EMAIL_TEMPLATE_SEND_TEST_SUCCESS: {
    open: true,
    type: Type.SUCCESS,
    message: (
      <FormattedMessage defaultMessage="A test email has been sent to your email address." />
    ),
    timeout: 5000,
    component: 'snackbar',
    context: 'email-template-send-test',
  },
  EMAIL_TEMPLATE_SEND_TEST_FAILURE: {
    open: true,
    type: Type.FAILURE,
    message: (
      <FormattedMessage defaultMessage="We were unable to send a test email of this template." />
    ),
    timeout: 5000,
    component: 'snackbar',
    context: 'email-template-send-test',
  },
  EMAIL_TEMPLATE_CREATE_DRAFT_SUCCESS: {
    open: true,
    type: Type.SUCCESS,
    message: (
      <FormattedMessage defaultMessage="A draft of this template has been saved." />
    ),
    timeout: 5000,
    component: 'snackbar',
    context: 'email-template-draft',
  },
  EMAIL_TEMPLATE_CREATE_DRAFT_FAILURE: {
    open: true,
    type: Type.FAILURE,
    message: (
      <FormattedMessage defaultMessage="We were unable to create a draft for this template." />
    ),
    timeout: 5000,
    component: 'snackbar',
    context: 'email-template-draft',
  },
  EMAIL_TEMPLATE_DISCARD_DRAFT_SUCCESS: {
    open: true,
    type: Type.SUCCESS,
    message: (
      <FormattedMessage defaultMessage="The draft of this template has been discarded." />
    ),
    timeout: 5000,
    component: 'snackbar',
    context: 'email-template-draft',
  },
  EMAIL_TEMPLATE_DISCARD_DRAFT_FAILURE: {
    open: true,
    type: Type.FAILURE,
    message: (
      <FormattedMessage defaultMessage="We were unable to discard this draft for this template." />
    ),
    timeout: 5000,
    component: 'snackbar',
    context: 'email-template-draft',
  },
  EMAIL_TEMPLATE_PUBLISH_DRAFT_SUCCESS: {
    open: true,
    type: Type.SUCCESS,
    message: (
      <FormattedMessage defaultMessage="The draft has been successfully published." />
    ),
    timeout: 5000,
    component: 'snackbar',
    context: 'email-template-draft',
  },
  EMAIL_TEMPLATE_PUBLISH_DRAFT_FAILURE: {
    open: true,
    type: Type.FAILURE,
    message: (
      <FormattedMessage defaultMessage="We were unable to publish this draft for the template." />
    ),
    timeout: 5000,
    component: 'snackbar',
    context: 'email-template-draft',
  },
};

export { Type, DisplayStates, VeloNotificationTypes };
