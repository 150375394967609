import { PayorAccountType } from 'velo-data';

function getAvailableCurrencies(
  sourceAccountName,
  sourceAccountsDetails,
  currencies
) {
  const account = sourceAccountsDetails.find(
    ({ name }) => name === sourceAccountName
  );

  if (account && account.type === PayorAccountType.WUBS_DECOUPLED) {
    return {
      defaultCurrency: account.currency,
      currencyLabelList: [account.currency],
    };
  }

  const list = currencies.map(({ currency }) => currency);

  return {
    currencyLabelList: list,
    defaultCurrency: 'USD',
  };
}

function getPayorLabels(fromPayors, toPayors, selectedPayorFrom) {
  const filterByPayorId =
    (id, match = true) =>
    ({ payorId }) =>
      id === payorId ? match : !match;

  const pickName = ({ payorName }) => payorName;

  const filteredFromPayors = fromPayors.filter(
    filterByPayorId(selectedPayorFrom)
  );

  const filteredToPayors = toPayors.filter(
    filterByPayorId(selectedPayorFrom, false)
  );

  const fromOptions = fromPayors.map(pickName);
  const toOptions = [...filteredFromPayors, ...filteredToPayors].map(pickName);

  return { fromOptions, toOptions };
}

function getPayorId(options, name) {
  const payor = options.find((option) => option.payorName === name);
  return payor.payorId;
}

export { getPayorId, getPayorLabels, getAvailableCurrencies };
