import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  dataTestIdBuilder,
  PayoutStatesDoNotRequireScheduledIcon,
} from 'velo-data';
import { PaymentStatus } from '../PaymentStatus';
import { VeloIcon } from '../VeloIcon';
import { VeloListCurrencyAmount } from '../VeloListCurrencyAmount';
import { Alignment } from '../VeloTable/types';
import { VeloTypography } from '../VeloTypography';
import { LookupTextField } from '../LookupTextField';
import styled from 'astroturf/react';
import { transmissionTypeFilterOptions } from '../utils';
import { VeloTable } from '../VeloTable';

const root = 'velo-payouts-payment-review-list';

const TestIds = {
  LIST: root,
  HEADING: `${root}-heading`,
  SORT_HEADING: `${root}-sort-heading`,
  ROW_PREFIX: `${root}-row`,
  COLUMN_PREFIX: `${root}-column`,
  SKELETON_ROW: `${root}-icon-skeleton`,
};

const fieldNames = {
  PAYEE_NAME: 'payeeName',
  REMOTE_ID: 'remoteId',
  PAYMENT_MEMO: 'paymentMemo',
  PAYMENT_CURRENCY: 'paymentCurrency',
  PAYMENT_AMOUNT: 'paymentAmount',
  TRANSMISSION_TYPE: 'transmissionType',
  PAYMENT_STATUS: 'status',
  SOURCE_ACCOUNT: 'sourceAccountName',
  SCHEDULED_STATUS_ICON: 'scheduledStatus',
};

const columnsByName = {
  [fieldNames.PAYEE_NAME]: {
    name: fieldNames.PAYEE_NAME,
    label: <FormattedMessage defaultMessage="Payee name" />,
    'data-testid': dataTestIdBuilder(
      TestIds.COLUMN_PREFIX,
      'heading-payee-name'
    ),
  },
  [fieldNames.REMOTE_ID]: {
    name: fieldNames.REMOTE_ID,
    label: <FormattedMessage defaultMessage="Remote ID" />,
    'data-testid': dataTestIdBuilder(
      TestIds.COLUMN_PREFIX,
      'heading-remote-id'
    ),
  },
  [fieldNames.PAYMENT_MEMO]: {
    name: fieldNames.PAYMENT_MEMO,
    label: <FormattedMessage defaultMessage="Payment memo" />,
    'data-testid': dataTestIdBuilder(
      TestIds.COLUMN_PREFIX,
      'heading-payment-memo'
    ),
  },
  [fieldNames.PAYMENT_AMOUNT]: {
    name: fieldNames.PAYMENT_AMOUNT,
    label: <FormattedMessage defaultMessage="Payment amount" />,
    align: Alignment.END,
    'data-testid': dataTestIdBuilder(
      TestIds.COLUMN_PREFIX,
      'heading-source-amount'
    ),
  },
  [fieldNames.PAYMENT_STATUS]: {
    name: fieldNames.PAYMENT_STATUS,
    label: <FormattedMessage defaultMessage="Status" />,
    'data-testid': dataTestIdBuilder(TestIds.COLUMN_PREFIX, 'heading-status'),
  },
  [fieldNames.SCHEDULED_STATUS_ICON]: {
    name: fieldNames.SCHEDULED_STATUS_ICON,
    size: VeloTable.size.XSMALL,
    'data-testid': dataTestIdBuilder(
      TestIds.COLUMN_PREFIX,
      'scheduled-status-icon'
    ),
  },
};

const filtersByName = {
  [fieldNames.PAYEE_NAME]: {
    name: 'entityId',
    label: <FormattedMessage defaultMessage="Payee" />,
    type: 'entityIdLookup',
    mode: LookupTextField.modes.PAYEE,
  },
  [fieldNames.REMOTE_ID]: {
    name: fieldNames.REMOTE_ID,
    label: <FormattedMessage defaultMessage="Remote ID" />,
    type: 'string',
  },
  [fieldNames.PAYMENT_STATUS]: {
    name: fieldNames.PAYMENT_STATUS,
    label: <FormattedMessage defaultMessage="Status" />,
    type: 'list',
    options: (intl) => [
      {
        label: intl.formatMessage({ defaultMessage: 'Accepted' }),
        value: 'ACCEPTED',
      },
      {
        label: intl.formatMessage({ defaultMessage: 'Rejected' }),
        value: 'REJECTED',
      },
      {
        label: intl.formatMessage({ defaultMessage: 'Withdrawn' }),
        value: 'WITHDRAWN',
      },
    ],
  },
  [fieldNames.TRANSMISSION_TYPE]: {
    name: fieldNames.TRANSMISSION_TYPE,
    label: <FormattedMessage defaultMessage="Transmission Type" />,
    type: 'list',
    options: transmissionTypeFilterOptions,
  },
  [fieldNames.PAYMENT_MEMO]: {
    name: fieldNames.PAYMENT_MEMO,
    label: <FormattedMessage defaultMessage="Payment Memo" />,
    type: 'string',
  },
  [fieldNames.SOURCE_ACCOUNT]: {
    name: fieldNames.SOURCE_ACCOUNT,
    label: <FormattedMessage defaultMessage="Source Account" />,
    type: 'string',
  },
};

const cellFactoryByFieldName = {
  [fieldNames.PAYEE_NAME]: ({ displayName }, index) =>
    displayName ? (
      <span
        data-testid={dataTestIdBuilder(TestIds.ROW_PREFIX, index, 'payee-name')}
      >
        {displayName}
      </span>
    ) : (
      <FormattedMessage defaultMessage="Unknown" />
    ),
  [fieldNames.REMOTE_ID]: ({ remoteId }, index) => (
    <span
      data-testid={dataTestIdBuilder(TestIds.ROW_PREFIX, index, 'remote-id')}
    >
      {remoteId}
    </span>
  ),
  [fieldNames.PAYMENT_MEMO]: ({ paymentMemo }, index) => (
    <span
      data-testid={dataTestIdBuilder(TestIds.ROW_PREFIX, index, 'payment-memo')}
    >
      {paymentMemo}
    </span>
  ),
  [fieldNames.PAYMENT_AMOUNT]: ({ amount, currency }, index) => (
    <VeloListCurrencyAmount
      amount={amount}
      currency={currency}
      data-testid={dataTestIdBuilder(TestIds.ROW_PREFIX, index, 'amount')}
    />
  ),
  [fieldNames.PAYMENT_STATUS]: ({ status }, index) => (
    <PaymentStatus
      use="tableContent"
      status={status}
      data-testid={dataTestIdBuilder(TestIds.ROW_PREFIX, index, 'status')}
    />
  ),
  [fieldNames.SCHEDULED_STATUS_ICON]: (props) => {
    const { schedule, status } = props;
    return schedule?.scheduledFor &&
      PayoutStatesDoNotRequireScheduledIcon.indexOf(status) === -1 ? (
      <VeloIcon icon="schedule" />
    ) : null;
  },
};

const EmptyResultContainer = styled('div')`
  @import 'velo-variables';
  background-color: velo-color('token-color-system-info-lighter');
  height: 50px;
  width: inherit;
`;

const EmptyIconLabel = styled('div')`
  margin: 0.8rem;
  display: flex;
  align-items: center;
`;

const EmptyResultTypography = styled(VeloTypography).attrs({
  use: 'body',
  tag: 'div',
})`
  @import 'velo-variables';
  @import 'velo-mixins';

  margin-left: 0.5rem;
`;

const VeloEmptyInfoIcon = styled(VeloIcon)`
  @import 'velo-variables';
  color: velo-color('token-color-brand-primary');
`;

export const PayoutReviewPaymentsColumns = {
  fieldNames,
  filtersByName,
  columnsByName,
  getEmptyRowProps: ({ isProcessing }) => ({
    children: isProcessing ? (
      <EmptyResultContainer>
        <EmptyIconLabel>
          <VeloEmptyInfoIcon icon="feedback" />
          <EmptyResultTypography>
            <VeloTypography use="bodyTextEmphasised">
              <FormattedMessage defaultMessage="Processing payments: " />
            </VeloTypography>
            <FormattedMessage defaultMessage="Payments processing is in progress. Please try again later." />
          </EmptyResultTypography>
        </EmptyIconLabel>
      </EmptyResultContainer>
    ) : null,
  }),
  getSkeletonRowProps: () => ({ 'data-testid': TestIds.SKELETON_ROW }),
  getRowProps(payment, index, _, { onClick }) {
    return {
      key: payment.paymentId,
      'data-testid': dataTestIdBuilder(TestIds.ROW_PREFIX, index),
      icon: 'chevron_right',
      onClick: () => onClick(payment),
    };
  },
  getColumnProps({ name, align, size }, ...args) {
    const key = name;
    return {
      key,
      size,
      align,
      children: cellFactoryByFieldName[key](...args),
    };
  },
  dataProps: {
    data: PropTypes.arrayOf(
      PropTypes.shape({
        /** The payment ID. */
        paymentId: PropTypes.string,
        payorPaymentId: PropTypes.string,
      })
    ),
    onClick: PropTypes.func,
  },
};
