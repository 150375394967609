import React from 'react';
import { func, object, node, shape } from 'prop-types';
import { VeloButton } from '../VeloButton';

const Stages = {
  DEFAULT: 0,
  PENDING: 1,
  ERROR: 2,
  RESULT: 3,
};

class VeloStatefulButton extends React.Component {
  state = {
    stage: Stages.DEFAULT,
  };

  static propTyes = {
    /**
     * An handler that takes an "error first" callback, invoked on click.
     */
    onClick: func.isRequired,
    /**
     * An map object keyed on valid stage names, whose values are props that are
     * spread onto the the underlying VeloButton instance.
     */
    stages: shape({
      [Stages.DEFAULT]: object,
      [Stages.PENDING]: object,
      [Stages.ERROR]: object,
      [Stages.RESULT]: object,
    }).isRequired,
    /**
     * The button component to render.
     */
    component: node,
  };

  static defaultProps = {
    component: VeloButton,
  };

  static stages = Stages;

  onClickCallback = (error) =>
    this.setState({
      stage: error ? Stages.ERROR : Stages.RESULT,
    });

  onClick = () =>
    this.setState({ stage: Stages.PENDING }, () =>
      this.props.onClick(this.onClickCallback)
    );

  render() {
    const { onClick, stages, component: Component, ...other } = this.props;
    const props = {
      ...other,
      onClick: this.onClick,
      // stage props come last to all overrides
      ...(stages[this.state.stage] || {}),
    };
    return <Component {...props} />;
  }
}

export { VeloStatefulButton };
