import React from 'react';
import { defineMessage, FormattedMessage } from 'react-intl';

const PaymentChannelRulesLabels = {
  ACCOUNT_NUMBER: (
    <FormattedMessage
      {...defineMessage({ defaultMessage: 'Account number' })}
    />
  ),
  CURRENCY: (
    <FormattedMessage {...defineMessage({ defaultMessage: 'Currency' })} />
  ),
  TRANSIT_NUMBER: (
    <FormattedMessage
      {...defineMessage({ defaultMessage: 'Transit number' })}
    />
  ),
  ROUTING_NUMBER: (
    <FormattedMessage
      {...defineMessage({ defaultMessage: 'Routing number' })}
    />
  ),
  IBAN: <FormattedMessage {...defineMessage({ defaultMessage: 'IBAN' })} />,
  BANK_ROUTING_CODE: (
    <FormattedMessage
      {...defineMessage({ defaultMessage: 'Bank routing code' })}
    />
  ),
  ZENGIN_CODE: (
    <FormattedMessage {...defineMessage({ defaultMessage: 'Zengin code' })} />
  ),
  SORT_CODE: (
    <FormattedMessage {...defineMessage({ defaultMessage: 'Sort code' })} />
  ),
  BSB_CODE: (
    <FormattedMessage {...defineMessage({ defaultMessage: 'BSB code' })} />
  ),
  PAYMENT_CHANNEL_NAME: (
    <FormattedMessage
      {...defineMessage({ defaultMessage: 'Payment method name' })}
    />
  ),
  ACCOUNT_HOLDER_NAME: (
    <FormattedMessage
      {...defineMessage({ defaultMessage: 'Account holder name' })}
    />
  ),
};

export { PaymentChannelRulesLabels };
