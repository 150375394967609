import React from 'react';
import { FormattedMessage } from 'react-intl';
import { AddPayeeMode } from '../propTypes';
import { RootId, TestIds } from '../testIds';
import { VeloButton } from '../../VeloButton';
import styled from 'astroturf/react';
import { OnboardingPage } from '../../OnboardingPage';

/**
 * Form buttons.
 * The form mode will determine the type of buttons to render.
 */

const CreateButtonBlock = styled('div')`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 1rem;
`;

const CancelButton = styled(VeloButton)`
  margin-right: 1rem;
`;

const OnBoardingButtonBlock = styled('div')`
  display: flex;
  justify-content: center;
`;

const OnboardingContinueButton = styled(OnboardingPage.Button)`
  margin-right: 1rem;
`;

const buttonLabels = {
  [AddPayeeMode.PAYOR_CREATE]: (submitting) =>
    submitting ? (
      <FormattedMessage defaultMessage="Creating..." />
    ) : (
      <FormattedMessage defaultMessage="Create" />
    ),
  [AddPayeeMode.PAYOR_EDIT]: (submitting) =>
    submitting ? (
      <FormattedMessage defaultMessage="Saving..." />
    ) : (
      <FormattedMessage defaultMessage="Save" />
    ),
};

function PayorCreateButtons(props) {
  return (
    <CreateButtonBlock>
      <CancelButton
        type="button"
        onClick={props.onCancel}
        data-testid={TestIds.CANCEL}
        skeleton={props.loading}
      >
        <FormattedMessage defaultMessage="Cancel" />
      </CancelButton>

      <VeloButton.Primary
        type="submit"
        disabled={props.submitDisabled}
        data-testid={TestIds.CREATE}
        skeleton={props.loading}
      >
        {buttonLabels[props.mode](props.submitting)}
      </VeloButton.Primary>
    </CreateButtonBlock>
  );
}
function PayorOnBoardingButtons(props) {
  return (
    <OnBoardingButtonBlock>
      <OnboardingContinueButton
        type="submit"
        form={RootId}
        disabled={props.submitDisabled}
        data-testid={TestIds.CREATE}
        skeleton={props.loading}
      />
    </OnBoardingButtonBlock>
  );
}

function FormButtons({ mode, dirty, ...other }) {
  return mode === AddPayeeMode.PAYOR_CREATE ||
    mode === AddPayeeMode.PAYOR_EDIT ? (
    <PayorCreateButtons mode={mode} {...other} />
  ) : (
    <PayorOnBoardingButtons {...other} />
  );
}

export { FormButtons };
