import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'astroturf/react';
import { VeloTypography } from '../VeloTypography';
import { PaymentReceipt } from './PaymentReceipt';
import { WUBSPaymentReceiptTitle } from './WUBSPaymentReceiptTitle';
import { paymentReceiptPropTypes } from './paymentReceiptPropTypes';

const Paragraph = styled(VeloTypography).attrs({ tag: 'div', use: 'bodyText' })`
  margin-bottom: 0.5rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

function DefaultWubsRecieptText() {
  return (
    <>
      <Paragraph>
        <FormattedMessage
          defaultMessage="Additional terms for REMITTERS in New York. Convera USA,
    LLC is licensed and regulated as a money transmitter by the New York State Department of
    Financial Services, One State Street, New York, New York 10004. Any unresolved complaints
    may be submitted online at www.dfs.ny.gov or mailed to the New York State Department of
    Financial Services, One State Street, New York, New York 10004: Attention: Consumer
    Assistance Unit. Convera’s  liability to you for non-delivery or delayed delivery is
    limited to the amount of the money transfer. Convera will refund a money transfer if
    it is unable to process the payment, unless prohibited from doing so by applicable law."
        />
      </Paragraph>
      <Paragraph>
        <FormattedMessage
          defaultMessage="Additional terms for REMITTERS in Texas.  If you have a complaint,
    first contact Client Services at 1-866-381-6551. You may also email us at:
    customerserviceus@convera.com. If you still have an unresolved complaint regarding the company's
    money transmission or currency exchange activity, please direct your complaint to: Texas
    Department of Banking, 2601 North Lamar Boulevard, Suite 300, Austin, Texas 78705-4294,
    1-877-276-5554 (toll free), Fax 512-475-1313, email consumer.complaints@dob.texas.gov,
    or www.dob.texas.gov."
        />
      </Paragraph>
      <Paragraph>
        <FormattedMessage
          defaultMessage="Additional terms for REMITTERS in California. Convera USA, LLC is licensed
    and regulated as a money transmitter by the California Department of Financial Protection and
    Innovation. If you have complaints with respect to any aspect of the money transmission activities
    conducted through Convera, you may contact the California Department of Financial Protection and
    Innovation at its toll-free telephone number, 1-866-275-2677, by email at
    consumer.services@dfpi.ca.gov, or by mail at the California Department of Financial Protection and
    Innovation Attn: Consumer Services, 2101 Arena Boulevard, Sacramento, CA 95834."
        />
      </Paragraph>
      <Paragraph>
        <FormattedMessage
          defaultMessage="RIGHT TO REFUND (California Customers) You, the customer,
    are entitled to a refund of the money to be transmitted as a result of this agreement if Convera
    USA, LLC does not forward the money received from you within 10 days of the date of its receipt,
    or does not give instructions committing an equivalent amount of money to the person designated by
    you within 10 days of the receipt of funds from you unless otherwise instructed by you."
        />
      </Paragraph>
      <Paragraph>
        <FormattedMessage
          defaultMessage="If your instructions as to when the money shall be forwarded or transmitted
    are not complied with and the money has not yet been forwarded or transmitted, you have the right
    to a refund of the amount of your money."
        />
      </Paragraph>
      <Paragraph>
        <FormattedMessage
          defaultMessage="If you want a refund, you must  mail or deliver your written request to
    Convera USA, LLC at 7979 E Tufts Ave, Denver CO 80237, Attention: Client Services. If you do not
    receive your refund, you may be entitled to your money back plus a penalty of up to $1,000 and
    attorney's fees pursuant to Section 2102 of the California Financial Code."
        />
      </Paragraph>
    </>
  );
}

function AustralianWubsRecieptText() {
  return (
    <>
      <Paragraph>
        <FormattedMessage defaultMessage="Western Union Business Solutions (Australia) Pty Limited, Level 12, 1 Margaret Street Sydney, NSW 2000, Australia" />
        <br />
        <FormattedMessage defaultMessage="Phone: +61285857000" />
        <br />
        <FormattedMessage defaultMessage="Fax: 1800 065 937" />
        <br />
        <FormattedMessage defaultMessage="ABN : 24 150 129 749" />
      </Paragraph>
      <Paragraph>
        <FormattedMessage defaultMessage='Remittance services are provided in Australia by Western Union Business Solutions (Australia) Pty Limited, a company within the Western Union Business Solutions division of The Western Union Company. Western Union Business Solutions (Australia) Pty Limited is a wholly-owned subsidiary of The Western Union Company, a world-wide leader in consumer and business payments and financial services. Western Union Business Solutions (Australia) Pty Limited ("WUBS") hereby confirms the details of this Transaction entered into between You and WUBS (the "Transaction") on the Order Date specified above. Each Transaction constitutes an independent separate agreement and incorporates the Terms and Conditions between You and WUBS (the " Master Agreement"), a copy of which You have previously received and agreed to, govern all aspects of this Transaction.' />
      </Paragraph>
    </>
  );
}

function renderContent({ paymentCurrency }) {
  return (
    <>
      <WUBSPaymentReceiptTitle paymentCurrency={paymentCurrency} />
      <VeloTypography use="bodyTextEmphasised" tag="div">
        <FormattedMessage defaultMessage="Disclosures:" />
      </VeloTypography>
      {paymentCurrency === 'AUD' ? (
        <AustralianWubsRecieptText />
      ) : (
        <DefaultWubsRecieptText />
      )}
    </>
  );
}

WUBSPaymentReceipt.propTypes = paymentReceiptPropTypes;

function WUBSPaymentReceipt(props) {
  return <PaymentReceipt {...props} renderContent={renderContent} />;
}

WUBSPaymentReceipt.testIds = PaymentReceipt.testIds;
WUBSPaymentReceipt.Title = WUBSPaymentReceiptTitle;

export { WUBSPaymentReceipt };
