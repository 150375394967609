import { useContext } from 'react';
import { Context } from '../../context';
import { useLoadingEffectFunction } from '../helpers';

function usePayorDetailsAPI(payorId, guard) {
  const { api } = useContext(Context);

  const [data, getPayor] = useLoadingEffectFunction(
    { fn: api.getPayor, guard },
    payorId
  );

  return [data, { getPayor }];
}

export { usePayorDetailsAPI };
