import { getOrderedPaymentChannelRules } from 'velo-data';

const DEFAULT_COUNTRY_CODE = 'US';

const paymentChannelRulesSelector = (countryCode, allPaymentChannelRules) => {
  const channelRules = allPaymentChannelRules.find(
    (rule) => rule.isoCountryCode === (countryCode || DEFAULT_COUNTRY_CODE)
  );

  return getOrderedPaymentChannelRules(channelRules.rules);
};

/**
 * Given a list of ISO country codes and payment method rules, return
 * an array of countries that have a corresponding payment method
 * ruleset.
 */
const supportedPaymentCountries = (countries, allPaymentChannelRules) =>
  allPaymentChannelRules
    ? countries.filter((isoCountryCode) =>
        allPaymentChannelRules.find(
          (rule) => rule.isoCountryCode === isoCountryCode
        )
      )
    : [];

export { paymentChannelRulesSelector, supportedPaymentCountries };
