import cookie from 'cookie';

const COOKIE_NAME = 'app_preference_stopconfirmpaymentsdialog';

function useUpdatePaymentConfirmationPreference(value = false) {
  document.cookie = cookie.serialize(COOKIE_NAME, value, {
    path: window.location.pathname,
  });
}

export { useUpdatePaymentConfirmationPreference };
