import React from 'react';
import {
  arrayOf,
  bool,
  func,
  object,
  oneOfType,
  node,
  string,
} from 'prop-types';
import { InputRangeContainer } from '../InputRangeContainer';
import { VeloDatePicker } from '../VeloDatePicker';
import { compareDateStrings } from 'velo-data';

const createRenderFunction =
  ({ name, label, testId, className, disableFutureDates, onToggleDayPicker }) =>
  ({ value, onChange, error, right, left }) => {
    return (
      <VeloDatePicker
        className={className}
        textInputTestId={testId}
        name={name}
        label={label}
        value={value}
        disableAfter={right}
        disableBefore={left}
        disableFutureDates={disableFutureDates}
        disableValidationMessage
        invalid={!!error}
        onChange={onChange}
        onToggleDayPicker={onToggleDayPicker}
      />
    );
  };

const leftFieldTestId = 'velo-date-range-left-input';
const rightFieldTestId = 'velo-date-range-right-input';

const errorLeftMsg = 'The start date cannot be greater than the end';
const errorRightMsg = 'The end date cannot be before the start';

class VeloDateRange extends React.Component {
  static testIds = {
    LEFT_FIELD: leftFieldTestId,
    RIGHT_FIELD: rightFieldTestId,
  };

  static propTypes = {
    /**
     * The name as it will appear in the onChange "target.name" object.
     */
    name: string.isRequired,
    /**
     * The label to be rendered above the range component.
     */
    label: node,
    /**
     * Either an array of string values, or an empty string.
     */
    value: oneOfType([arrayOf(string), string]),
    /**
     * An onChange handler which will receive an event with the same structure
     * as a standard HTML input event.
     */
    onChange: func.isRequired,
    /**
     * CSS class(es) to pass to the underlying VeloTextField component rendered on
     * the left and right.
     */
    className: string,
    /**
     * GridCell props to use for the left/right components.
     */
    cellProps: object,
    /**
     * Additional cells to render to the right of the components.
     */
    cells: node,
    /**
     * Disables all dates after the current day.
     */
    disableFutureDates: bool,
    /**
     * Called when either the left or right day pickers are opened
     * or closed.
     */
    onToggleDayPicker: func,
  };

  static defaultProps = {
    onToggleDayPicker: () => {},
  };

  static leftFieldTestId = leftFieldTestId;
  static rightFieldTestId = rightFieldTestId;

  validateDates = ([from, to], valueIndex) => {
    // check overlapping dates
    const invalidDateRange = compareDateStrings(from, to) === 1;
    const errorLeft =
      valueIndex === 0 && invalidDateRange ? errorLeftMsg : undefined;
    const errorRight =
      valueIndex === 1 && invalidDateRange ? errorRightMsg : undefined;
    return { errorLeft, errorRight, value: [from, to] };
  };

  renderLeft = createRenderFunction({
    name: 'dateFrom',
    label: 'Start Date',
    testId: leftFieldTestId,
    className: this.props.className,
    disableFutureDates: this.props.disableFutureDates,
    onToggleDayPicker: this.props.onToggleDayPicker,
  });
  renderRight = createRenderFunction({
    name: 'dateTo',
    label: 'End Date',
    testId: rightFieldTestId,
    className: this.props.className,
    disableFutureDates: this.props.disableFutureDates,
    onToggleDayPicker: this.props.onToggleDayPicker,
  });

  normaliseValueProp = (value) => {
    if (!value) {
      return ['', ''];
    }
    return value;
  };

  render() {
    const value = this.normaliseValueProp(this.props.value);
    return (
      <React.Fragment>
        <InputRangeContainer
          value={value}
          label={this.props.label}
          name={this.props.name}
          onChange={this.props.onChange}
          validator={this.validateDates}
          renderLeft={this.renderLeft}
          renderRight={this.renderRight}
          cellProps={this.props.cellProps}
          cells={this.props.cells}
        />
      </React.Fragment>
    );
  }
}

VeloDateRange.errorLeftMsg = errorLeftMsg;
VeloDateRange.errorRightMsg = errorRightMsg;
VeloDateRange.leftFieldTestId = leftFieldTestId;
VeloDateRange.rightFieldTestId = rightFieldTestId;

export { VeloDateRange };
