import { useState } from 'react';
import { useDebouncedResize } from './useDebouncedResize';

const pointerCoarse = () => window.matchMedia('(pointer: coarse)').matches;

/**
 * Hook that returns true for mobile/tablet devices.
 * Check for a coarse pointer via a media query.
 */
const useMobile = () => {
  const [isMobile, setMobile] = useState(pointerCoarse());
  useDebouncedResize(() => setMobile(pointerCoarse()));
  return isMobile;
};

export { useMobile };
