import React from 'react';
import { func, node } from 'prop-types';
import { useIntl } from 'react-intl';
import styled from 'astroturf/react';
import { VeloIconButton } from '../../VeloIconButton';
import { VeloModalSheet } from '../../VeloModalSheet';

const HeaderTitle = styled(VeloModalSheet.HeaderTitle)`
  margin: 1rem 0;
`;

const root = 'velo-payments-channel';

const TestIds = {
  CLOSE_BUTTON: 'payment-channel-view-loading-close-button',
  HEADER_SKELETON: `${root}-header-skeleton`,
  BODY_SKELETON: `${root}-body-skeleton`,
  SKELETON: `${root}-skeleton`,
};

PaymentChannelViewLoading.propTypes = {
  /** Called when the close button is clicked. */
  onClose: func,
  /** The components to render in the flyout body. */
  children: node.isRequired,
};

PaymentChannelViewLoading.testIds = TestIds;
/**
 * Render a Payment View in the loading state.
 */
function PaymentChannelViewLoading({ children, onClose }) {
  const intl = useIntl();
  return (
    <>
      <VeloModalSheet.Header divider data-testid={TestIds.SKELETON}>
        <HeaderTitle skeleton data-testid={TestIds.HEADER_SKELETON} />
        <VeloIconButton
          icon="close"
          onClick={onClose}
          title={intl.formatMessage({
            defaultMessage: 'Close Payment Method view',
          })}
          data-testid={TestIds.CLOSE_BUTTON}
        />
      </VeloModalSheet.Header>

      <VeloModalSheet.Body data-testid={TestIds.BODY_SKELETON}>
        {children}
      </VeloModalSheet.Body>
    </>
  );
}

export { PaymentChannelViewLoading };
