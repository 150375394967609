import { SelectRegion } from '../FormFields/SelectRegion';
import { AddressFields as address } from '../FormFields/AddressFields';

/**
 * Map a value to each field in the supplied sections array.
 */
const mapValues = (sections, values) =>
  sections.map((section) => ({
    ...section,
    fields: section.fields.map((field) => ({
      value: values[field.name],
      ...field,
      ...(field.Component === SelectRegion
        ? { initialValue: values[field.name] }
        : {}),
    })),
  }));

export { address, mapValues };
