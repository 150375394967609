import React from 'react';
import { bool, func, node } from 'prop-types';
import styled from 'astroturf/react';
import { VeloDialog } from '../VeloDialog';
import { BankLookupBody } from './BankLookupBody';

const Dialog = styled(VeloDialog)`
  @import 'velo-variables';

  z-index: $velo-over-all-default;

  :global(.mdc-dialog__surface) {
    @media (min-width: velo-breakpoint(XS)) {
      min-width: 700px;
    }
  }

  :global(.mdc-dialog__actions) {
    padding: 0 1rem 1rem;
  }
`;

const root = 'velo-banklookup';
const TestIds = {
  DIALOG: `${root}-dialog`,
  ACCEPT_BUTTON: `${root}-accept-button`,
  CANCEL_BUTTON: `${root}-cancel-button`,
};
BankLookupDialog.testIds = TestIds;

BankLookupDialog.propTypes = {
  /* The open state of the dialog */
  open: bool,
  /* The function called on close */
  onClose: func.isRequired,
  /* The title of the dialog */
  title: node.isRequired,
};

function BankLookupDialog({ open, onClose, title, children }) {
  return (
    <Dialog open={open} onClose={onClose} data-testid={TestIds.DIALOG}>
      <VeloDialog.Title>{title}</VeloDialog.Title>

      <VeloDialog.Content>{children}</VeloDialog.Content>

      <VeloDialog.Actions>
        <VeloDialog.Button
          action="accept"
          type="button"
          data-testid={TestIds.ACCEPT_BUTTON}
          isDefaultAction
        >
          Yes
        </VeloDialog.Button>

        <VeloDialog.Button
          action="close"
          type="button"
          data-testid={TestIds.CANCEL_BUTTON}
        >
          No
        </VeloDialog.Button>
      </VeloDialog.Actions>
    </Dialog>
  );
}

BankLookupDialog.Body = BankLookupBody;

export { BankLookupDialog };
