import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { VeloStepper } from '../../VeloStepper';
import { VeloGrid } from '../../VeloGrid';
import { VeloFileUpload } from '../../VeloFileUpload';
import { VeloTextButton } from '../../VeloTextButton';
import { VeloIcon } from '../../VeloIcon';
import { TestIds } from '../testIds';
import styled from 'astroturf/react';

const Instructions = styled('div')`
  max-height: 0rem;
  overflow: hidden;
  transition: max-height 200ms ease-in-out;
  &.visible-show {
    max-height: 100rem; // Edge has issues with 100% here...
  }
  &.visible-hide {
    max-height: 0rem;
  }
`;

const ListItem = styled('li')`
  @import 'velo-variables';

  margin: 0 0 1.3rem;
  padding-bottom: 0.25rem;
  color: velo-color('token-color-text-default');
`;

const StepContent = styled(VeloStepper.Content)`
  padding-right: 0rem;
`;

const CSVDragAndDrop = styled(VeloGrid)`
  padding: 0;
  margin: 0;
`;

const FooterLinks = styled('footer')`
  display: flex;
  justify-content: space-between;
`;

const FooterButton = styled(VeloTextButton)`
  align-self: flex-end;
`;

function PayeeCsvInstruction({ visible }) {
  return (
    <Instructions
      data-testid={TestIds.GROUP_CSV_INSTRUCTIONS}
      visible={visible ? 'show' : 'hide'}
    >
      <ol>
        <ListItem>
          <FormattedMessage defaultMessage="The file format must be CSV and have a .csv file extension to be accepted." />
        </ListItem>
        <ListItem>
          <FormattedMessage
            defaultMessage="The CSV file must contain a header row with all mandatory fields
          completed. Multiple fields require data to be submitted in a case
          sensitive or specific format. Please refer to the sample file for data
          field requirements."
          />
        </ListItem>
        <ListItem>
          <FormattedMessage defaultMessage="If payee type is an Individual, please submit all Individual mandatory fields." />
        </ListItem>
        <ListItem>
          <FormattedMessage defaultMessage="If payee type is a Company, please submit all Company mandatory fields." />
        </ListItem>
        <ListItem>
          <FormattedMessage
            defaultMessage="The CSV file may contain any or all of the optional fields as detailed
          in the sample file. Providing optional information will assist and
          accelerate your payees in their onboarding process."
          />
        </ListItem>
        <ListItem>
          <FormattedMessage defaultMessage="The file may not contain a footer row." />
        </ListItem>
        <ListItem>
          <FormattedMessage
            defaultMessage="You may be presented with an error message if there is a formatting or
          whole file issue."
          />
        </ListItem>
        <ListItem>
          <FormattedMessage
            defaultMessage="For individual row errors you will be provided with a CSV file
          containing the payees needing correction. Please address those
          specific errors and resubmit those records for processing."
          />
        </ListItem>
      </ol>
    </Instructions>
  );
}

function PayeeUploadStep({ type, loading, onSubmit, downloadSampleCsv }) {
  const [showInstructions, setShowInstructions] = useState(false);

  const onFileAdded = (file) => {
    onSubmit(file);
  };
  const onShowInstructions = () => {
    setShowInstructions(!showInstructions);
  };
  return (
    <VeloStepper.Step>
      <VeloStepper.Label
        icon="2"
        text={<FormattedMessage defaultMessage="Drag & drop" />}
        disabled={type === undefined}
        skeleton={loading}
      />
      <StepContent>
        <section>
          <CSVDragAndDrop desktop={6} tablet={8} phone={4}>
            <VeloGrid.Cell span={12}>
              <VeloFileUpload
                buttonLabel={
                  <FormattedMessage defaultMessage="Browse for file" />
                }
                dragDropLabel={
                  <FormattedMessage defaultMessage="Drag & drop CSV file to upload" />
                }
                onAdded={(file) => onFileAdded(file)}
                acceptedFileFormats={'.csv'}
              />
            </VeloGrid.Cell>
          </CSVDragAndDrop>
          <FooterLinks>
            <FooterButton
              onClick={onShowInstructions}
              icon={
                <VeloIcon
                  icon={showInstructions ? 'chevron_up' : 'chevron_down'}
                />
              }
              padded
            >
              <FormattedMessage defaultMessage="CSV instructions" />
            </FooterButton>
            <FooterButton
              padded
              onClick={downloadSampleCsv}
              icon={<VeloIcon icon="arrow_downward" />}
            >
              <FormattedMessage defaultMessage="Download sample CSV file" />
            </FooterButton>
          </FooterLinks>
          <PayeeCsvInstruction visible={showInstructions} />
        </section>
      </StepContent>
    </VeloStepper.Step>
  );
}

export { PayeeUploadStep };
