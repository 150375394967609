import { VeloTextField } from 'velo-react-components';
import { getPayeeByIdV4 } from 'velo-api/src/entities/payees';
import { usePresenter, useSerializableMemo } from '../../hooks';
import { forkResult } from '../../selectors';
import { useQuery } from '@tanstack/react-query';

function Presenter(_, entity, { payeeId }) {
  const loader = async () => {
    const response = await entity.getPayeeAsync(payeeId);
    return response;
  };

  loader.queryKey = 'get-payee';

  return [loader];
}

const entitySpec = {
  getPayee: getPayeeByIdV4,
};

const forks = {
  value: ({ displayName }) => displayName,
  error: (error) => error,
  loading: VeloTextField.Skeleton,
  none: VeloTextField.Skeleton,
};

export const PayeeNameFragment = (props) => {
  const [loader] = usePresenter(
    Presenter,
    entitySpec,
    null,
    useSerializableMemo({ payeeId: props.payeeId })
  );

  const result = useQuery([loader.queryKey], loader);

  return forkResult(forks, result);
};

PayeeNameFragment.Presenter = Presenter;
