import React from 'react';
import { node, oneOf, string } from 'prop-types';
import styled from 'astroturf/react';
import { VeloIconLabel } from '../VeloIconLabel';
import { VeloFlagIcon } from '../VeloFlagIcon';
import { VeloTypography } from '../VeloTypography';

const FlagIcon = styled(VeloFlagIcon)`
  height: 16px;
  width: 24px;
`;

VeloFlagLabel.propTypes = {
  /** The two-character ISO country code. */
  country: string.isRequired,
  /** The label to render. */
  label: node.isRequired,
  /** The typography token to use. */
  use: oneOf(VeloTypography.veloTokens),
};

/**
 * Render a flag icon and a label.
 *
 * Ideal for use in tables and flyouts to render
 * content such as country names, phone numbers, etc.
 */
function VeloFlagLabel({ country, label, use = 'itemContent' }) {
  return (
    <VeloIconLabel
      icon={{ icon: <FlagIcon flag={country} />, strategy: 'component' }}
      label={label}
      use={use}
    />
  );
}

export { VeloFlagLabel };
