import React from 'react';
import { func, shape, string, oneOf } from 'prop-types';
import styled from 'astroturf/react';
import { FormattedMessage, useIntl } from 'react-intl';
import { PayorAccountType } from 'velo-data';
import { countryName } from '../utils';
import { VeloGridLoading } from '../VeloGridLoading';
import { VeloLabelledItem } from '../VeloLabelledItem';
import { VeloModalSheetCardContent } from '../VeloModalSheetCardContent';
import { VeloSectionGrid } from '../VeloSectionGrid';
import { VeloSkeleton } from '../VeloSkeleton';
import { fieldNames, labelsByFieldName } from '../FundingAccountFormFields';
import { PayorAccountTypeText } from '../PayorAccountTypeText';

const TitleSkeleton = styled(VeloSkeleton)`
  width: 40%;
`;

const cardProps = ({ data, onClose, onEdit }) => ({
  title: data.name,
  onClose,
  onEdit,
});

function createRenderFn(fieldName, transform = (v) => v) {
  return (data, intl) => ({
    label: labelsByFieldName[fieldName],
    children: transform(data[fieldName], intl),
    Component: VeloLabelledItem,
  });
}

const fieldRenderByName = {
  [fieldNames.PAYOR_NAME]: createRenderFn(fieldNames.PAYOR_NAME),
  [fieldNames.COUNTRY]: createRenderFn(fieldNames.COUNTRY, countryName),
  [fieldNames.CURRENCY]: createRenderFn(fieldNames.CURRENCY),
  [fieldNames.TYPE]: createRenderFn(fieldNames.TYPE, (type) => (
    <PayorAccountTypeText type={type} />
  )),
  [fieldNames.ACCOUNT_NAME]: createRenderFn(fieldNames.ACCOUNT_NAME),
  [fieldNames.ROUTING_NUMBER]: createRenderFn(fieldNames.ROUTING_NUMBER),
  [fieldNames.ACCOUNT_NUMBER]: createRenderFn(fieldNames.ACCOUNT_NUMBER),
};

const fieldOrderByType = {
  [PayorAccountType.FBO]: [
    [fieldNames.TYPE],
    [
      fieldNames.COUNTRY,
      fieldNames.CURRENCY,
      fieldNames.ACCOUNT_NAME,
      fieldNames.ROUTING_NUMBER,
      fieldNames.ACCOUNT_NUMBER,
    ],
  ],
  [PayorAccountType.WUBS_DECOUPLED]: [[fieldNames.TYPE], [fieldNames.CURRENCY]],
  [PayorAccountType.PRIVATE]: [
    [fieldNames.TYPE],
    [
      fieldNames.CURRENCY,
      fieldNames.ACCOUNT_NAME,
      fieldNames.ROUTING_NUMBER,
      fieldNames.ACCOUNT_NUMBER,
    ],
  ],
};

function getSections(data, intl) {
  const mapper = (name) => fieldRenderByName[name](data, intl);
  const payorFields = data.payorName ? [fieldNames.PAYOR_NAME] : [];
  const [sectionOne, sectionTwo] = fieldOrderByType[data.type];

  return [
    {
      fields: payorFields.concat(sectionOne).map(mapper),
    },
    {
      heading: <FormattedMessage defaultMessage="Funding account details" />,
      fields: sectionTwo.map(mapper),
    },
  ];
}

const gridProps = ({ data }, intl) => ({
  compact: true,
  headingProps: { overline: true, tail: true },
  render: ({ Component, ...props }) => <Component {...props} />,
  sections: getSections(data, intl),
});

function LoadingView({ onClose }) {
  const fields = (length) =>
    Array.from({ length }, () => ({
      type: VeloGridLoading.fieldTypes.LabelledItem,
    }));

  return (
    <VeloModalSheetCardContent onClose={onClose} title={<TitleSkeleton />}>
      <VeloGridLoading
        compact
        sections={[{ fields: fields(4) }, { heading: true, fields: fields(2) }]}
      />
    </VeloModalSheetCardContent>
  );
}

FundingAccountsView.Loading = LoadingView;
FundingAccountsView.Error = VeloModalSheetCardContent.Error;

FundingAccountsView.propTypes = {
  data: shape({
    /** Funding account name */
    name: string.isRequired,
    /** The payor name */
    payorName: string,
    /** The ISO country code */
    countryCode: string,
    /** The currency */
    currency: string.isRequired,
    /** Funding account type */
    type: oneOf(Object.values(PayorAccountType)).isRequired,
    /** Account holder name name */
    accountName: string,
    /** Account number */
    accountNumber: string,
    /** Routing number */
    routingNumber: string,
  }).isRequired,
  /** Handler called when close is actioned */
  onClose: func.isRequired,
  /** Optional handler for the edit button. If omitted, no FAB shown */
  onEdit: func,
};

export function FundingAccountsView(props) {
  const intl = useIntl();
  return (
    <VeloModalSheetCardContent {...cardProps(props)}>
      <VeloSectionGrid {...gridProps(props, intl)} />
    </VeloModalSheetCardContent>
  );
}
