import { useMemo } from 'react';
import { useAPIContext } from '../../context';
import { mapPromiseResponseWithCallback } from '../helpers';

function useInviteUserAPI() {
  const api = useAPIContext();

  const inviteUser = useMemo(
    () => mapPromiseResponseWithCallback(api.inviteUser),
    [api.inviteUser]
  );

  return inviteUser;
}

export { useInviteUserAPI };
