import pascalCase from 'just-pascal-case';
import { useState, useEffect, useRef } from 'react';
import { VeloDialogStates } from '../VeloDialog/VeloDialogStates';

/**
 * This function enables calling setters on the underlying foundation component.
 */
function applyFoundationConfig(foundation, config) {
  Object.entries(config).forEach(([propName, value]) => {
    const setterName = `set${pascalCase(propName)}`;
    const setter = foundation[setterName];
    if (setter) {
      foundation[setterName](value);
    }
  });
}

/**
 * Hook that can be used to only render an RMWC `Dialog`
 * or `SimpleDialog` component when it is visible.
 *
 * See `ConfirmationDialog.js` for usage.
 */
const useDialogManager = (
  open,
  onDialogStateChanged = () => {},
  foundationConfig = {}
) => {
  const ref = useRef();
  const [isMounted, setMounted] = useState(false);

  const onStateChange = (state) => {
    if (state === VeloDialogStates.CLOSED) {
      setMounted(false);
    }

    if (state === VeloDialogStates.OPENED) {
      applyFoundationConfig(ref.current.foundation, foundationConfig);
    }

    onDialogStateChanged(state);
  };

  useEffect(() => {
    if (open) {
      setMounted(true);
    }
  }, [open]);

  return [isMounted, onStateChange, ref];
};

export { useDialogManager };
