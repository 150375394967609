import React from 'react';
import { Context } from '../../context';
import { formatError } from '../../selectors';

class PasswordUnlock extends React.PureComponent {
  static contextType = Context;

  componentDidMount() {
    const { token, exit } = this.props;
    // This causes a VeloNotification.types.LOGIN_UNLOCK_FAILURE error to be
    // surfaced to the user in the route handler.
    if (!token) return exit('Invalid token.');
    const { api } = this.context;
    api
      .passwordUnlock(token)
      .then(() => {
        exit();
      })
      .catch((err) => {
        exit(formatError(err));
      });
  }

  render() {
    return null;
  }
}

export { PasswordUnlock };
