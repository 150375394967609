import React from 'react';
import { PaymentViewPropTypes } from '../propTypes';
import { BusinessPaymentView, PayeeName } from '../BusinessPaymentView';

PayorPaymentView.propTypes = {
  ...PaymentViewPropTypes,
};

const renderTitle = ({ payeeName }) => <PayeeName result={payeeName} />;

function PayorPaymentView(props) {
  return <BusinessPaymentView {...props} renderTitle={renderTitle} />;
}

PayorPaymentView.testIds = BusinessPaymentView.testIds;

export { PayorPaymentView };
