import React from 'react';
import { oneOf } from 'prop-types';
import styled from 'astroturf/react';
import { useIntl } from 'react-intl';
import { OnboardingPage } from '../OnboardingPage';
import { VeloButton } from '../VeloButton';
import { VeloBackground } from '../VeloBackground';
import { TestIds } from './testIds';
import { AddPayeeMode } from './propTypes';

const ErrorBar = styled(VeloBackground)`
  display: flex;
  padding: 0.8rem;
  justify-content: space-between;
  margin-bottom: 1.5rem;
`;

const Button = styled(VeloButton.Secondary)`
  @import 'velo-variables';
  background-color: velo-color('token-color-system-page-background');
  &:global(.mdc-button:not(:disabled)) {
    background-color: velo-color('token-color-system-page-background');
  }
`;

const OnBoardError = styled(OnboardingPage.SubmitError)`
  margin-bottom: 1rem;
`;

const ErrorText = styled('div')`
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
`;

function OnboardingError({ error, errorRef }) {
  return (
    <OnBoardError ref={errorRef} data-testid={TestIds.ERROR}>
      {error}
    </OnBoardError>
  );
}

function FailuresErrorMessage({ failuresList, headerText }) {
  return (
    <ErrorText>
      {headerText ? (
        <>
          {headerText}
          <ul>
            {failuresList.map((value, index) => (
              <li key={index}>{value}</li>
            ))}
          </ul>
        </>
      ) : (
        <>{failuresList[0]}</>
      )}
    </ErrorText>
  );
}

/** MAX VALUE: potentially 100's of unique errors so limit display */
const MaxDisplayErrors = 3;
/** Must handle string and array errors due to the multi use case of the parent component
 * used across a number of places/routes not yet refactored
 * API Error (Form or CSV)  - 1 line no Export
 * Batch Form - 1 Error no export
 * Batch CSV - N Errors that require Export and limiting  display if >MaxDisplayErrors**/
function extractFailureErrors(error, showDownloadErrors, intl) {
  if (!Array.isArray(error)) {
    return { failuresList: [error], headerText: null };
  }
  const errorHeaderText = intl.formatMessage({
    defaultMessage:
      'Payees could not be created. Export the Errors for more information.',
  });
  const showMultipleErrorDetails = error.length <= MaxDisplayErrors;
  const failuresList = showDownloadErrors
    ? showMultipleErrorDetails
      ? [
          ...new Set(
            error
              .slice(0, MaxDisplayErrors)
              .map((fail) =>
                fail.failureMessage ? fail.failureMessage : fail.message
              )
          ),
        ]
      : [error.length + ' ' + errorHeaderText]
    : [error[0].failureMessage];

  return {
    failuresList,
    headerText:
      showDownloadErrors && showMultipleErrorDetails ? errorHeaderText : null,
  };
}
function CreateError({ error, errorRef, downloadErrors, showDownloadErrors }) {
  const intl = useIntl();
  const errorDisplayProps = extractFailureErrors(
    error,
    showDownloadErrors,
    intl
  );

  return (
    <ErrorBar
      data-testid={TestIds.ERRORS}
      rounded
      status={VeloBackground.status.ERROR}
      ref={errorRef}
    >
      <FailuresErrorMessage {...errorDisplayProps} />
      {showDownloadErrors && (
        <Button
          onClick={() => downloadErrors(error)}
          data-testid={`${TestIds.ERRORS}-download`}
        >
          EXPORT ERRORS
        </Button>
      )}
    </ErrorBar>
  );
}

AddPayeeError.propTypes = {
  mode: oneOf(Object.values(AddPayeeMode)).isRequired,
};

function AddPayeeError({ mode, ...props }) {
  return props.error ? (
    mode === AddPayeeMode.PAYOR_CREATE || mode === AddPayeeMode.PAYOR_EDIT ? (
      <CreateError {...props} />
    ) : (
      <OnboardingError {...props} />
    )
  ) : null;
}

export { AddPayeeError };
