import React from 'react';
import { bool, string } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styled from 'astroturf/react';
import payPal from 'velo-assets/icons/payment-paypal.svg';
import cashPickup from 'velo-assets/icons/payment-cash-pickup.svg';
import pushToCard from 'velo-assets/icons/payment-push-to-card.svg';
import bankAcc from 'velo-assets/icons/payment-bank-account.svg';
import { VeloTypography } from '../../VeloTypography';
import { VeloRadioButton } from '../../VeloRadioButton';

const Container = styled('div')`
  @import 'velo-variables';

  /* 85px = same height as text field with helper text */
  height: 4.6875rem;

  @media (max-width: velo-breakpoint(XS)) {
    height: 3rem;
  }
`;

const AdvancedContainer = styled('div')`
  @import 'velo-variables';

  /* expand for extra options */
  height: 12.9rem;

  @media (max-width: velo-breakpoint(XS)) {
    height: 23rem;
    width: 100%;
    flex-grow: 1;
    border-bottom: 1px solid velo-color('token-color-system-divider');
    margin: 0 0 2rem;
  }
`;

const Label = styled(VeloTypography)`
  @import 'velo-variables';

  &.disabled {
    color: velo-color('token-color-system-disabled');
  }
`;

const AdvancedVeloRadioGroup = styled('div')`
  margin: 0.6rem 0;
  display: flex;
  flex-wrap: wrap;
`;

const RadioButtonContainer = styled('div')`
  @import 'velo-variables';

  width: 48%;
  margin: 1%;

  @media (max-width: velo-breakpoint(XS)) {
    margin: 0.5rem 0;
    width: 100%;
  }
`;

Label.propTypes = {
  disabled: bool,
};

PaymentChannelType.propTypes = {
  /** Custom CSS class(es). */
  className: string,
  /** Render as disabled. Used when the form is being submitted. */
  disabled: bool,
};

/**
 * Advanced payment method selection, Non functional prototype.
 *
 * Displays advanced payment methods only when `cookieadvancedpaymentmethods_show`
 * cookie has been set in the users browser.
 */
function AdvancedPaymentMethods() {
  const paymentMethodProps = [
    {
      icon: bankAcc,
      title: <FormattedMessage defaultMessage="Bank account" />,
      disabled: false,
      checked: true,
    },
    {
      icon: pushToCard,
      title: <FormattedMessage defaultMessage="Card" />,
      disabled: true,
    },
    {
      icon: cashPickup,
      title: <FormattedMessage defaultMessage="Cash" />,
      disabled: true,
    },
    {
      icon: payPal,
      title: <FormattedMessage defaultMessage="PayPal" />,
      disabled: true,
    },
  ];

  return (
    <AdvancedContainer>
      <Label use="itemLabel">
        <FormattedMessage defaultMessage="Payment Method Type" />
      </Label>
      <AdvancedVeloRadioGroup>
        {paymentMethodProps.map((props, index) => (
          <RadioButtonContainer key={index}>
            <VeloRadioButton checked={props.checked} value={index} {...props} />
          </RadioButtonContainer>
        ))}
      </AdvancedVeloRadioGroup>
    </AdvancedContainer>
  );
}

function StandardPaymentMethods({ className, disabled }) {
  return (
    <Container className={className}>
      <div>
        <Label use="itemLabel" disabled={disabled}>
          <FormattedMessage defaultMessage="Payment Method Type" />
        </Label>
      </div>
      <div>
        <Label use="itemContent" disabled={disabled}>
          <FormattedMessage defaultMessage="Bank Account" />
        </Label>
      </div>
    </Container>
  );
}

/**
 * Placeholder for "Payment Channel Type". Supports basic and advanced
 * payment methods, where advanced is demonstrable but not functional.
 */
export function PaymentChannelType({ className, disabled, advanced }) {
  return (
    <>
      {advanced ? (
        <AdvancedPaymentMethods />
      ) : (
        <StandardPaymentMethods className={className} disabled={disabled} />
      )}
    </>
  );
}
