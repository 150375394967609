import tap from 'callbag-tap';
import pipe from 'callbag-pipe';
import flatten from 'callbag-flatten';
import defer from 'callbag-defer';
import rescue from 'callbag-rescue';
import callbagOf from 'callbag-of';
import fromPromise from 'callbag-from-promise';
import callbagToPromise from 'callbag-to-promise';

import { refresh } from './entities/authenticate';
import { logout } from './entities/logout';
import { coreCreateAPI } from './coreCreateAPI';

function createLogoutFn({ onSessionState, mapLatestState }, send) {
  const logoutFn = logout(send);
  const clearState = () => onSessionState({}, false);
  return pipe(
    defer(() => logoutFn()),
    // regardless of error or data, always clear the state
    tap(clearState, clearState),
    // Fail silently
    rescue((error) => callbagOf(undefined))
  );
}

function createRefreshFn({ onSessionState, mapLatestState }, send) {
  const refreshFn = refresh(send);
  const updateState = (payload) => onSessionState(payload, true);
  return pipe(
    mapLatestState(({ refresh_token }) =>
      /**
       * Wrap the refresh and updateState stream in a Promise to prevent the refresh
       * request being cancelled.
       *
       * This will be replaced when `createAPIWithOIDC` is switched on in production.
       */
      fromPromise(
        callbagToPromise(
          pipe(
            refreshFn({}, { grant_type: 'refresh_token', refresh_token }),
            // only update the state if successful
            tap(updateState)
          )
        )
      )
    ),
    flatten
  );
}

export function createAPI(config) {
  return coreCreateAPI(
    {
      createLogoutFn,
      createRefreshFn,
      init: () => Promise.resolve(),
    },
    config
  );
}
