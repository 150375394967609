import React, { useRef, useReducer } from 'react';
import { FormattedMessage } from 'react-intl';
import { MultiFactorChallenge } from '../MultiFactorChallenge';

/**
 * Hook used to handle a RMWC `inputRef`.
 * This works slightly differently from a normal `ref`
 * and requires manual assignment.
 */
const useInputRef = () => {
  const ref = useRef(null);
  return [
    ref,
    // Function used to apply the ref.
    (input) => {
      ref.current = input;
    },
  ];
};

/* eslint-disable default-case */
const labels = {
  SEND_CODE: <FormattedMessage defaultMessage="Send Code" />,
  RESEND_CODE: <FormattedMessage defaultMessage="Resend Code" />,
  SENDING: <FormattedMessage defaultMessage="Sending..." />,
  VERIFYING: <FormattedMessage defaultMessage="Verifying..." />,
  // Using `null` will use the default onboarding
  // button label ('Continue')
  CONTINUE: null,
};

// The initial form state
const initialState = {
  phoneNumber: '',
  otp: '',
  submitting: false,
  mfaError: undefined,
  otpError: undefined,
  mfaSuccess: false,
  sendCodeLabel: labels.SEND_CODE,
  continueLabel: labels.CONTINUE,
  otpDisabled: true,
  // The OTP input is cleared when sending a new code
  // but this can cause the HTML5 validation to mark
  // the field as required. To work around this we pass
  // a `key` to the component and update it when we
  // clear it.
  otpInputKey: Date.now().toString(10),
};

// Action types
const types = {
  SET_PHONE_NUMBER: 'SET_PHONE_NUMBER',
  SET_OTP: 'SET_OTP',
  UPDATE_MFA: 'UPDATE_MFA',
  MFA_RESPONSE: 'MFA_RESPONSE',
  VALIDATE_OTP: 'VALIDATE_OTP',
  OTP_RESPONSE: 'OTP_RESPONSE',
};

// Reducer used to manage the form state
const reducer = (state, action) => {
  switch (action.type) {
    case types.SET_PHONE_NUMBER:
      return { ...state, phoneNumber: action.payload };
    case types.SET_OTP:
      return { ...state, otp: action.payload };
    case types.UPDATE_MFA:
      return {
        ...state,
        submitting: true,
        mfaError: undefined,
        otpError: undefined,
        otp: '',
        sendCodeLabel: labels.SENDING,
        otpInputKey: Date.now().toString(10),
      };
    case types.MFA_RESPONSE:
      return {
        ...state,
        submitting: false,
        mfaError: action.error,
        mfaSuccess: action.error === undefined,
        sendCodeLabel: labels.RESEND_CODE,
        otpDisabled: action.error !== undefined,
      };
    case types.VALIDATE_OTP:
      return {
        ...state,
        submitting: true,
        otpError: undefined,
        continueLabel: labels.VERIFYING,
      };
    case types.OTP_RESPONSE:
      return {
        ...state,
        submitting: false,
        otpError: action.error,
        continueLabel: labels.CONTINUE,
      };
  }
  // There is no default case on the switch above as it cannot be tested.
};

export function useValidateOTPForm(props, { formatSendOTPBody }) {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
  });
  const [codeInput, setCodeInputRef] = useInputRef();

  // Handlers
  const onChangePhoneNumber = (evt) => {
    dispatch({ type: types.SET_PHONE_NUMBER, payload: evt.target.value });
  };

  const onSendOTP = (evt) => {
    evt.preventDefault();
    dispatch({ type: types.UPDATE_MFA });
    props.updateMfa(
      formatSendOTPBody({
        mfaType: MultiFactorChallenge.mfaTypes.SMS,
        smsNumber: state.phoneNumber,
      }),
      (error) => {
        dispatch({ type: types.MFA_RESPONSE, error });
        // Ensure the code input is visible and has focus
        if (error === undefined) {
          codeInput.current.scrollIntoView();
          codeInput.current.focus();
        }
      }
    );
  };

  const onChangeOTP = (evt) => {
    dispatch({ type: types.SET_OTP, payload: evt.target.value });
  };

  const onValidateOTP = (evt) => {
    evt.preventDefault();
    dispatch({ type: types.VALIDATE_OTP });
    props.validateOTP({ otp: state.otp }, (error) => {
      dispatch({ type: types.OTP_RESPONSE, error });
    });
  };

  return [
    setCodeInputRef,
    {
      onValidateOTP,
      onChangeOTP,
      onSendOTP,
      onChangePhoneNumber,
    },
    state,
    dispatch,
  ];
}
