import React from 'react';
import { Switch, Route, useHistory } from 'react-router';
import { PayorUsersEditRoute } from '../UserEditRoute';
import { PayorUserViewRoute } from '../UserViewRoute';
import { useWireframe } from '../../hooks';

function PayorUsersRoute({ role, children }) {
  const wireframe = useWireframe(useHistory());
  return (
    <Switch>
      <Route
        path={wireframe.navigateToUserEdit.path}
        exact
        render={({ match }) => (
          <PayorUsersEditRoute {...match.params} role={role} />
        )}
      />
      <>
        {children}
        {/* Payor User view */}
        <Route path={wireframe.navigateToUserDetails.path} exact>
          {({ match, history }) => (
            <PayorUserViewRoute role={role} match={match} />
          )}
        </Route>
      </>
    </Switch>
  );
}

export { PayorUsersRoute };
