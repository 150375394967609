import React from 'react';
import { FormattedMessage } from 'react-intl';
import { VeloCurrency } from '../../../VeloCurrency';
import { PaymentViewHistory } from '../../PaymentViewHistory';
import { PaymentViewDateTime } from '../../PaymentViewDateTime';
import { PaymentViewItem } from '../../PaymentViewItem';
import { PaymentViewChannelDetails } from '../../PaymentViewChannel';
import { VeloStatusIndicator } from '../../../VeloStatusIndicator';

// Supported Payor Payment events
const eventTypes = {
  BANK_PAYMENT_REQUESTED: {
    type: VeloStatusIndicator.types.INFO,
    label: <FormattedMessage defaultMessage="Bank Payment Requested" />,
  },
  SOURCE_AMOUNT_CONFIRMED: {
    type: VeloStatusIndicator.types.SUCCESS,
    label: <FormattedMessage defaultMessage="Source Amount Confirmed" />,
  },
  PAYMENT_SUBMITTED: {
    type: VeloStatusIndicator.types.INFO,
    label: <FormattedMessage defaultMessage="Payment Submitted" />,
  },
  PAYMENT_SUBMITTED_ACCEPTED: {
    type: VeloStatusIndicator.types.SUCCESS,
    label: <FormattedMessage defaultMessage="Payment Submission Accepted" />,
  },
  PAYMENT_SUBMITTED_REJECTED: {
    type: VeloStatusIndicator.types.ERROR,
    label: <FormattedMessage defaultMessage="Payment Submission Rejected" />,
  },
  PAYMENT_REJECTED: {
    type: VeloStatusIndicator.types.ERROR,
    label: <FormattedMessage defaultMessage="Payment Rejected" />,
  },
  PAYMENT_AWAITING_FUNDS: {
    type: VeloStatusIndicator.types.INFO,
    label: <FormattedMessage defaultMessage="Payment Awaiting Funds" />,
  },
  PAYMENT_FUNDED: {
    type: VeloStatusIndicator.types.SUCCESS,
    label: <FormattedMessage defaultMessage="Payment Funded" />,
  },
  PAYMENT_UNFUNDED: {
    type: VeloStatusIndicator.types.ERROR,
    label: <FormattedMessage defaultMessage="Payment Unfunded" />,
  },
  PAYMENT_WITHDRAWN: {
    type: VeloStatusIndicator.types.INFO,
    label: <FormattedMessage defaultMessage="Payment Withdrawn" />,
  },
  PAYOUT_BATCH_WITHDRAWN: {
    type: VeloStatusIndicator.types.SUCCESS,
    label: <FormattedMessage defaultMessage="Payout Batch Withdrawn" />,
  },
  ACH_SUBMITTED_TO_ODFI: {
    type: VeloStatusIndicator.types.INFO,
    label: <FormattedMessage defaultMessage="ACH File Submitted" />,
  },
  RETURN_PAYMENT_FUNDING_REQUESTED: {
    type: VeloStatusIndicator.types.INFO,
    label: (
      <FormattedMessage defaultMessage="Return Payment Funding Requested" />
    ),
  },
  RETURN_PAYMENT_CANCELLATION_REQUESTED: {
    type: VeloStatusIndicator.types.INFO,
    label: (
      <FormattedMessage defaultMessage="Return Payment Cancellation Requested" />
    ),
  },
  PAYMENT_ACCEPTED_BY_RAILS: {
    type: VeloStatusIndicator.types.SUCCESS,
    label: <FormattedMessage defaultMessage="Payment Accepted By Rails" />,
  },
  PAYMENT_TRACKING_DETAILS_UPDATED: {
    type: VeloStatusIndicator.types.SUCCESS,
    label: (
      <FormattedMessage defaultMessage="Payment Tracking Details Updated" />
    ),
  },
  PAYMENT_CONFIRMED: {
    type: VeloStatusIndicator.types.SUCCESS,
    label: <FormattedMessage defaultMessage="Payment Confirmed" />,
  },
  PAYMENT_FAILED: {
    type: VeloStatusIndicator.types.ERROR,
    label: <FormattedMessage defaultMessage="Payment Failed" />,
  },
  PAYMENT_CANCELLED: {
    type: VeloStatusIndicator.types.WARNING,
    label: <FormattedMessage defaultMessage="Payment Cancelled" />,
  },
  ACH_RETURN_RECEIVED: {
    type: VeloStatusIndicator.types.ERROR,
    label: <FormattedMessage defaultMessage="ACH Return Received" />,
  },
  ACH_PAYMENT_RETURN_CANCELLED: {
    type: VeloStatusIndicator.types.ERROR,
    label: <FormattedMessage defaultMessage="ACH Payment Return Cancelled" />,
  },
  PAYOUT_WITHDRAWN: {
    type: VeloStatusIndicator.types.SUCCESS,
    label: <FormattedMessage defaultMessage="Payout Withdrawn" />,
  },
  PAYMENT_RETURNED_BY_RAILS: {
    type: VeloStatusIndicator.types.INFO,
    label: <FormattedMessage defaultMessage="Payment Returned By Rails" />,
  },
  RETURN_CANCELLED: {
    type: VeloStatusIndicator.types.INFO,
    label: <FormattedMessage defaultMessage="Return Cancelled" />,
  },
  ORCHESTRATED_PAYMENT_REQUESTED: {
    type: VeloStatusIndicator.types.INFO,
    label: <FormattedMessage defaultMessage="Orchestrated Payment Requested" />,
  },
  ORCHESTRATED_PAYMENT_BATCH_CONFIRMED: {
    type: VeloStatusIndicator.types.SUCCESS,
    label: (
      <FormattedMessage defaultMessage="Orchestrated Payment Batch Confirmed" />
    ),
  },
  ORCHESTRATED_PAYMENT_BATCH_REQUESTED: {
    type: VeloStatusIndicator.types.INFO,
    label: (
      <FormattedMessage defaultMessage="Orchestrated Payment Batch Requested" />
    ),
  },
  PAYOUT_SCHEDULED: {
    type: VeloStatusIndicator.types.INFO,
    label: <FormattedMessage defaultMessage="Payment Scheduled At" />,
  },
  PAYOUT_DESCHEDULED: {
    type: VeloStatusIndicator.types.INFO,
    label: <FormattedMessage defaultMessage="Payment Schedule Cleared" />,
  },
  INSTRUCT_SCHEDULED_PAYOUT: {
    type: VeloStatusIndicator.types.INFO,
    label: <FormattedMessage defaultMessage="Scheduled Payment Submitted" />,
  },
  INSTRUCT_SCHEDULED_PAYOUT_FAILED: {
    type: VeloStatusIndicator.types.ERROR,
    label: <FormattedMessage defaultMessage="Scheduled Payment Failed" />,
  },
  POST_INSTRUCT_PAYMENT_FX_INITIATED: {
    type: VeloStatusIndicator.types.INFO,
    label: (
      <FormattedMessage defaultMessage="Post Instruct Payment FX Initiated" />
    ),
  },
  POST_INSTRUCT_PAYMENT_FX_EXECUTED: {
    type: VeloStatusIndicator.types.INFO,
    label: (
      <FormattedMessage defaultMessage="Post Instruct Payment FX Executed" />
    ),
  },
  POST_INSTRUCT_PAYMENT_FX_COMPLETED: {
    type: VeloStatusIndicator.types.SUCCESS,
    label: (
      <FormattedMessage defaultMessage="Post Instruct Payment FX Completed" />
    ),
  },
  POST_INSTRUCT_PAYMENT_FX_CANCELLED: {
    type: VeloStatusIndicator.types.ERROR,
    label: (
      <FormattedMessage defaultMessage="Post Instruct Payment FX Cancelled" />
    ),
  },
  POST_INSTRUCT_PAYMENT_FX_RETURNED: {
    type: VeloStatusIndicator.types.INFO,
    label: (
      <FormattedMessage defaultMessage="Post Instruct Payment FX Returned" />
    ),
  },
  POST_INSTRUCT_PAYMENT_FX_RESUBMITTED: {
    type: VeloStatusIndicator.types.INFO,
    label: (
      <FormattedMessage defaultMessage="Post Instruct Payment FX Resubmitted" />
    ),
  },
  POST_INSTRUCT_PAYMENT_FX_REFUNDED: {
    type: VeloStatusIndicator.types.INFO,
    label: (
      <FormattedMessage defaultMessage="Post Instruct Payment FX Refunded" />
    ),
  },
};

function BusinessPaymentViewHistory({ result, paymentChannelRules }) {
  return (
    <PaymentViewHistory
      result={result}
      eventTypes={eventTypes}
      renderEventDetails={(event) => {
        return (
          <>
            {/* Submitted Date/Time */}
            {event.eventDateTime && !event.scheduledAt && (
              <PaymentViewItem
                label={<FormattedMessage defaultMessage="Date/Time" />}
                value={<PaymentViewDateTime value={event.eventDateTime} />}
              />
            )}

            {/* Scheduled at */}
            {event.scheduledAt && (
              <PaymentViewItem
                label={
                  <FormattedMessage defaultMessage="Scheduled at date/time" />
                }
                value={<PaymentViewDateTime value={event.scheduledAt} />}
              />
            )}

            {/* Scheduled for */}
            {event.scheduledFor && (
              <PaymentViewItem
                label={
                  <FormattedMessage defaultMessage="Scheduled for date/time" />
                }
                value={<PaymentViewDateTime value={event.scheduledFor} />}
              />
            )}

            {/* Scheduled by */}
            {event.scheduledBy && (
              <PaymentViewItem
                label={<FormattedMessage defaultMessage="Scheduled by" />}
                value={event.scheduledBy}
              />
            )}

            {/* Source Amount */}
            {event.sourceAmount && event.sourceCurrency && (
              <PaymentViewItem
                label={<FormattedMessage defaultMessage="Source Amount" />}
                value={
                  <VeloCurrency
                    value={event.sourceAmount}
                    currency={event.sourceCurrency}
                  />
                }
              />
            )}

            {/* Source Currency */}
            {event.sourceCurrency && !event.sourceAmount && (
              <PaymentViewItem
                label={<FormattedMessage defaultMessage="Source Currency" />}
                value={event.sourceCurrency}
              />
            )}

            {/* Payment Amount */}
            {event.paymentAmount && event.paymentCurrency && (
              <PaymentViewItem
                label={<FormattedMessage defaultMessage="Payment Amount" />}
                value={
                  <VeloCurrency
                    value={event.paymentAmount}
                    currency={event.paymentCurrency}
                  />
                }
              />
            )}

            {/* Payment Channel Details */}
            <PaymentViewChannelDetails
              result={event}
              paymentChannelRules={paymentChannelRules}
            />
          </>
        );
      }}
    />
  );
}

export { BusinessPaymentViewHistory };
