import { arrayOf, bool, func, number, shape, string } from 'prop-types';
import { VeloPropTypes } from '../VeloPropTypes';

const PaymentAmountTypes = {
  paymentAmount: number,
  paymentCurrency: string,
  sourceAmount: number,
  sourceCurrency: string,
};

const PaymentChannelTypes = {
  paymentChannelName: string,
  accountName: string,
  accountNumber: string,
  routingNumber: string,
  iban: string,
};

const PaymentEventTypes = {
  events: arrayOf(
    shape({
      eventId: string,
      eventDateTime: string,
      eventType: string,
      ...PaymentAmountTypes,
      ...PaymentChannelTypes,
    })
  ),
};

const { result } = VeloPropTypes;

const PaymentViewPropTypes = {
  /** Used to show/hide the component. */
  open: bool.isRequired,
  /** The data to render. */
  data: result(
    shape({
      paymentId: string,
      payeeId: string,
      payorId: string,
      submittedDateTime: string,
      status: string,
      rate: number,
      fundingStatus: string,
      remoteId: string,
      payorName: result(string),
      payeeName: result(string),
      sourceAccount: result(string),
      paymentMemo: string,
      ...PaymentAmountTypes,
      ...PaymentChannelTypes,
      ...PaymentEventTypes,
    })
  ),
  /** Called when the view is closed */
  onClose: func.isRequired,
  /** Called to render a payment receipt page */
  onViewPaymentReceipt: func,
};

export { PaymentAmountTypes, PaymentChannelTypes, PaymentViewPropTypes };
