import React from 'react';
import { array, node, string } from 'prop-types';
import { VeloSelect } from '../VeloSelect';
import { useMobile } from '../hooks';

VeloEnhancedSelect.propTypes = {
  /**
   * ID to ensure a correct label.
   * If omitted the `data-testid` will be used.
   */
  id: string,
  /***
   * label is always required to ensure that the
   * select is accessible
   */
  label: node.isRequired,
  /**
   * Options to use for the mobile variant.
   */
  options: array.isRequired,
  /**
   * ID for use in automation tests.
   */
  'data-testid': string.isRequired,
};

export function VeloEnhancedSelect({ options, children, ...props }) {
  const isMobile = useMobile();
  return isMobile ? (
    <VeloSelect {...props} options={options} />
  ) : (
    <VeloSelect {...props} enhanced children={children} options={undefined} />
  );
}
