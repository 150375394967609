import React from 'react';
import { any, bool, string, func, node } from 'prop-types';
import styled from 'astroturf/react';
import { VeloIcon } from '../VeloIcon';
import { VeloTypography } from '../VeloTypography';
import { VeloRipple } from '../VeloRipple';
import { VeloSkeleton } from '../VeloSkeleton';

VeloRadioButton.propTypes = {
  /** The title. */
  title: node.isRequired,
  /** The icon or image path */
  icon: string.isRequired,
  /** The button value. */
  value: any.isRequired,
  /** Called when the button is clicked. */
  onChange: func,
  /** The selected state. */
  checked: bool,
  /** The Loading state. */
  skeleton: bool,
  /** The disabled state */
  disabled: bool,
};

const Button = styled('button')`
  @import 'velo-variables';

  color: velo-color('token-color-brand-primary');
  background-color: velo-color('token-color-brand-primary-background');
  border: 1px solid velo-color('token-color-border-disabled');
  border-radius: 0.25rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  outline: none;
  width: 100%;
  margin: 0;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  height: 4rem;

  @media (max-width: velo-breakpoint(XS)) {
    justify-content: center;
  }

  &.checked {
    border-color: velo-color('token-color-brand-primary');
  }

  &.disabled {
    border: 1px solid velo-color('token-color-border-disabled');
    opacity: 0.4;
    cursor: default;
  }

  /* https://github.com/material-components/material-components-web/issues/5044 */
  &:global(.mdc-ripple-surface--primary::before),
  &:global(.mdc-ripple-surface--primary::after) {
    background-color: velo-color('token-color-brand-primary');
  }
`;

const Skeleton = styled(VeloSkeleton)`
  width: 100%;
  height: 3.5rem;
`;

const Icon = styled(VeloIcon)`
  margin-right: 0.3rem;
`;

/**
 * A compact label and `Icon` radio button, useful for forms.
 *
 * Wrapping multiple buttons gives full control for positioning.
 *
 * ```js
 * <Wrapper>
 *    <VeloRadioButton {...props}></<VeloRadioButton>
 *    <VeloRadioButton {...props}></<VeloRadioButton>
 *     ...
 * <Wrapper>
 * ```
 *
 * Mobile breakpoint will center button icon and title.
 */
function VeloRadioButton({
  onChange,
  checked,
  disabled,
  icon,
  title,
  value,
  other,
}) {
  const titleStyle = disabled ? 'bodyText' : 'bodyTextEmphasised';
  return (
    <VeloRipple primary disabled={disabled}>
      <Button
        checked={checked}
        disabled={disabled}
        type="button"
        role="switch"
        aria-checked={checked}
        onClick={onChange}
        value={value}
        {...other}
      >
        <ButtonIcon icon={icon} title={title} />
        <VeloTypography use={titleStyle} tag="div">
          {title}
        </VeloTypography>
      </Button>
    </VeloRipple>
  );
}

function ButtonIcon({ icon }) {
  return (
    <Icon
      icon={{
        icon,
        strategy: icon && icon.split('.').length > 1 ? 'url' : undefined,
      }}
    />
  );
}

/**
 * Loading variant.
 */
function VeloRadioButtonLoading() {
  return <Skeleton />;
}

VeloRadioButton.Skeleton = VeloRadioButtonLoading;

export { VeloRadioButton };
