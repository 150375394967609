import React, { useState } from 'react';
import { string, arrayOf, shape, func, number } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { majorToMinorUnitMoney } from 'velo-data';

import { useFieldGridForm } from '../useFieldGridForm';
import { Currency } from '../FormFields/FormFieldTypes';
import { SelectCurrency } from '../FormFields/SelectCurrency';
import { VeloFieldGrid } from '../VeloFieldGrid';
import { VeloLabelledItem } from '../VeloLabelledItem';
import { VeloSelect } from '../VeloSelect';
import { VeloCardForm } from '../VeloCardForm';
import { VeloGridLoading } from '../VeloGridLoading';
import { VeloCurrency } from '../VeloCurrency';

import { SourceAccountTransferFundsConfirmationDialog } from './SourceAccountTransferFundsConfirmationDialog';

const Label = ({ value, label }) => (
  <VeloLabelledItem label={label}>{value}</VeloLabelledItem>
);

const CurrencyLabel = ({ value, label, currency }) => (
  <VeloLabelledItem label={label}>
    <VeloCurrency value={value} currency={currency} />
  </VeloLabelledItem>
);

const Labels = {
  DIALOG: SourceAccountTransferFundsConfirmationDialog.Labels,
  TITLE: <FormattedMessage defaultMessage="Transfer Funds" />,
  SOURCE_ACCOUNT_NAME: (
    <FormattedMessage defaultMessage="Source account name" />
  ),
  EFFECTIVE_BALANCE: <FormattedMessage defaultMessage="Effective balance" />,
  TRANSFER_FROM_HEADING: <FormattedMessage defaultMessage="Transfer From" />,
  TRANSFER_TO_HEADING: <FormattedMessage defaultMessage="Transfer To" />,
  ACCOUNT_SELECT: <FormattedMessage defaultMessage="Source account" />,
  AMOUNT_FIELD: <FormattedMessage defaultMessage="Amount" />,
  SUBMIT_BUTTON: <FormattedMessage defaultMessage="Transfer" />,
  SUBMITTING_BUTTON: <FormattedMessage defaultMessage="Transferring..." />,
};

const config = {
  createSections(_, { options, currency }) {
    return [
      {
        heading: Labels.TRANSFER_FROM_HEADING,
        fields: [
          {
            label: Labels.SOURCE_ACCOUNT_NAME,
            name: 'sourceAccountName',
            Component: Label,
          },
          {
            label: Labels.EFFECTIVE_BALANCE,
            name: 'balance',
            currency,
            Component: CurrencyLabel,
          },
        ],
      },
      {
        heading: Labels.TRANSFER_TO_HEADING,
        fields: [
          {
            label: Labels.ACCOUNT_SELECT,
            name: 'toSourceAccountId',
            required: true,
            Component: VeloSelect,
            options,
          },
          {
            Component: SelectCurrency,
            name: 'currency',
            currencies: [currency],
            desktop: 4,
            tablet: 4,
            phone: 4,
            disabled: true,
          },
          {
            name: 'amount',
            ...Currency(currency, Labels.AMOUNT_FIELD, true),
            desktop: 8,
            tablet: 8,
            phone: 8,
            required: true,
          },
        ],
      },
    ];
  },
  getInitialValues({ currency, sourceAccountName, balance }) {
    return {
      balance,
      sourceAccountName,
      toSourceAccountId: '',
      amount: '',
      currency,
    };
  },
  getButtonProps(submitting) {
    return {
      children: submitting ? Labels.SUBMITTING_BUTTON : Labels.SUBMIT_BUTTON,
    };
  },
  formatBody({ amount, ...body }) {
    return {
      ...body,
      amount: majorToMinorUnitMoney(amount, body.currency),
    };
  },
};

SourceAccountTransferFundsForm.propTypes = {
  sourceAccountName: string.isRequired,
  options: arrayOf(
    shape({
      label: string.isRequired,
      value: string.isRequired,
    })
  ).isRequired,
  balance: number.isRequired,
  currency: string.isRequired,
  onSubmit: func.isRequired,
  onClose: func.isRequired,
};

const defaultDialogProps = {
  open: false,
  amount: 0,
  currency: 'USD',
  sourceAccountFromName: '',
  sourceAccountToName: '',
};

const matchOptionValue = (value) => (option) => option.value === value;

export function SourceAccountTransferFundsForm(props) {
  const [dialogProps, setDialogProps] = useState(defaultDialogProps);

  function onSubmit({ sourceAccountName, balance, ...body }, cb) {
    const onClose = ({ detail }) => {
      setDialogProps(defaultDialogProps);
      if (detail.action === 'accept') {
        props.onSubmit(body, cb);
      } else {
        cb(undefined, undefined);
      }
    };

    const { label: sourceAccountToName } = props.options.find(
      matchOptionValue(body.toSourceAccountId)
    );

    setDialogProps({
      open: true,
      onClose,
      amount: body.amount,
      sourceAccountFromName: sourceAccountName,
      sourceAccountToName,
      currency: body.currency,
    });
  }

  const [gridProps, formProps] = useFieldGridForm(config, {
    ...props,
    onSubmit,
  });

  return (
    <VeloCardForm slimline={true} {...formProps} title={Labels.TITLE}>
      <VeloFieldGrid {...gridProps} breakpoint={600} />
      <SourceAccountTransferFundsConfirmationDialog {...dialogProps} />
    </VeloCardForm>
  );
}

function Loading(props) {
  return (
    <VeloCardForm.Loading slimline={true} title={Labels.TITLE} {...props}>
      <VeloGridLoading
        sections={[
          {
            heading: true,
            fields: [
              {
                type: VeloGridLoading.fieldTypes.LabelledItem,
              },
              {
                type: VeloGridLoading.fieldTypes.LabelledItem,
              },
            ],
          },
          {
            heading: true,
            fields: [
              {
                type: VeloGridLoading.fieldTypes.TextField,
              },
              {
                type: VeloGridLoading.fieldTypes.TextField,
              },
            ],
          },
        ]}
      />
    </VeloCardForm.Loading>
  );
}

SourceAccountTransferFundsForm.Labels = Labels;
SourceAccountTransferFundsForm.Loading = Loading;
SourceAccountTransferFundsForm.Error = VeloCardForm.Error;
