import React from 'react';
import { useWireframe } from '../../hooks';
import {
  Switch,
  Route,
  useHistory,
  useRouteMatch,
  useParams,
} from 'react-router';
import { PaymentMethodsListRoute } from './PaymentMethodsListRoute';
import { PaymentMethodViewRoute } from './PaymentMethodViewRoute';
import { PaymentMethodUpsertRoute } from './PaymentMethodUpsertRoute';

function usePaymentMethodRoute() {
  const wireframe = useWireframe(useHistory());
  const { url } = useRouteMatch();
  const detailsPath = `${url}/:paymentMethodId`;

  return { detailsPath, wireframe };
}

function Payee({ query, payeeId }) {
  const { detailsPath, wireframe } = usePaymentMethodRoute();

  return (
    <Switch>
      {/* Payment method create */}
      <Route
        path={wireframe.navigateToPaymentMethodCreate.path}
        payeeId={payeeId}
        exact
      >
        <PaymentMethodUpsertRoute query={query} payeeId={payeeId} />
      </Route>

      {/* Payment method edit */}
      <Route
        path={wireframe.navigateToPaymentMethodEdit.path}
        payeeId={payeeId}
        exact
      >
        <PaymentMethodUpsertRoute query={query} payeeId={payeeId} />
      </Route>

      <Route>
        {/* Payment method list */}
        <PaymentMethodsListRoute.Payee query={query} payeeId={payeeId} />

        {/* Payment method details */}
        <Route path={detailsPath} exact>
          <PaymentMethodViewRoute.Payee query={query} payeeId={payeeId} />
        </Route>
      </Route>
    </Switch>
  );
}

const Payor = ({ query }) => {
  const { payeeId } = useParams();
  const { detailsPath, wireframe } = usePaymentMethodRoute();

  return (
    <>
      {/* Payment method create */}
      <Route
        path={wireframe.navigateToPaymentMethodCreate.path}
        payeeId={payeeId}
        exact
      >
        <PaymentMethodUpsertRoute.Payor query={query} payeeId={payeeId} />
      </Route>

      {/* Payment method edit */}
      <Route
        path={wireframe.navigateToPaymentMethodEdit.path}
        payeeId={payeeId}
        exact
      >
        <PaymentMethodUpsertRoute.Payor query={query} payeeId={payeeId} />
      </Route>

      <Route>
        {/* Payment method list */}
        <PaymentMethodsListRoute.Payor query={query} payeeId={payeeId} />

        {/* Payment method details */}
        <Route path={detailsPath} exact>
          <PaymentMethodViewRoute.Payor query={query} payeeId={payeeId} />
        </Route>
      </Route>
    </>
  );
};

function BackOffice({ query, payeeId }) {
  const { detailsPath, wireframe } = usePaymentMethodRoute();

  return (
    <Switch>
      {/* Payment method create */}
      {payeeId && (
        <Route
          path={wireframe.navigateToPaymentMethodCreate.path}
          payeeId={payeeId}
          exact
        >
          <PaymentMethodUpsertRoute query={query} payeeId={payeeId} />
        </Route>
      )}

      {/* Payment method edit */}
      {payeeId && (
        <Route
          path={wireframe.navigateToPaymentMethodEdit.path}
          payeeId={payeeId}
          exact
        >
          <PaymentMethodUpsertRoute query={query} payeeId={payeeId} />
        </Route>
      )}

      <Route>
        {/* Payment method list */}
        <PaymentMethodsListRoute.BackOffice query={query} payeeId={payeeId} />

        {/* Payment method details */}
        <Route path={detailsPath} exact>
          <PaymentMethodViewRoute.BackOffice query={query} payeeId={payeeId} />
        </Route>
      </Route>
    </Switch>
  );
}

export const PaymentMethodsRoute = {
  Payee,
  Payor,
  BackOffice,
};
