import React from 'react';
import { func, bool } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { VeloCardPage } from '../VeloCardPage';

PayoutInstructCard.propTypes = {
  /** Function to close page */
  onClose: func.isRequired,
  /** Whether to offset the card for a fixed bottom pagination bar */
  fixedBottomPaginationOffset: bool,
};
function PayoutInstructCard({
  children,
  onClose,
  fixedBottomPaginationOffset,
}) {
  return (
    <VeloCardPage
      title={<FormattedMessage defaultMessage="Review Payout" />}
      onClose={onClose}
      fixedBottomPaginationOffset={fixedBottomPaginationOffset}
    >
      {children}
    </VeloCardPage>
  );
}

export { PayoutInstructCard };
