import { VeloTableBuilder } from '../VeloTableBuilder';
import { SourceAccountsListColumns } from '../SourceAccountsListColumns';

const { columnsByName, filtersByName, fieldNames, ...config } =
  SourceAccountsListColumns;

const common = {
  ...config,
  columns: fieldNames,
  sortableColumns: [fieldNames.NAME, fieldNames.BALANCE],
};

export const SourceAccountsList = {
  BackOffice: VeloTableBuilder({
    ...common,
    columnOrder: [
      columnsByName[fieldNames.NAME],
      columnsByName[fieldNames.PAYOR],
      columnsByName[fieldNames.TYPE],
      columnsByName[fieldNames.BALANCE],
    ],
    filters: [
      filtersByName[fieldNames.PAYOR_ID],
      filtersByName[fieldNames.TYPE],
      filtersByName[fieldNames.PHYSICAL_ACCOUNT_ID],
      filtersByName[fieldNames.DELETED],
    ],
  }),
  Payor: VeloTableBuilder({
    ...common,
    columnOrder: [
      columnsByName[fieldNames.NAME],
      columnsByName[fieldNames.TYPE],
      columnsByName[fieldNames.BALANCE],
    ],
    filters: [
      filtersByName[fieldNames.TYPE],
      filtersByName[fieldNames.DELETED],
    ],
  }),
};
