import { PayeePaymentView } from './PayeePaymentView';
import { PayorPaymentView } from './PayorPaymentView';
import { BackOfficePaymentView } from './BackOfficePaymentView';
import { BusinessPaymentView } from './BusinessPaymentView';

const PaymentView = {
  Payee: PayeePaymentView,
  Payor: PayorPaymentView,
  BackOffice: BackOfficePaymentView,
  Business: BusinessPaymentView,
};

export { PaymentView };
