import { SecondaryValueType, PayoutTypes, TRANSMISSION_TYPES } from 'velo-data';

import {
  combineCallbacks,
  PayeeSearchPresenter,
  selectIdFromLocation,
  formatCallbackErrorArg,
  createEarlyExitCallback,
} from 'velo-portal-common';

function CreatePayoutPresenter(wireframe, entity) {
  function onClose() {
    wireframe.navigateToPayoutsList();
  }

  // Submission is complete - proceed to the polling processing route
  function onComplete(payoutId) {
    wireframe.navigateToPayoutProcessing({ payoutId });
  }

  const getSinglePayoutParams = (args) => {
    return {
      requestBody: {
        ...args,
        payments: args.payments.map((p) => ({
          ...p,
          transmissionType:
            p.transmissionType === TRANSMISSION_TYPES.DEFAULT
              ? null
              : p.transmissionType,
        })),
      },
      contentType: 'application/json',
    };
  };

  const getGroupPayoutParams = (args) => {
    return {
      requestBody: { ...args },
      contentType: 'multipart/form-data',
    };
  };
  function submitPayout(props, cb) {
    const { type, ...params } = props;
    const { requestBody, contentType } =
      type === PayoutTypes.CREATE.SINGLE
        ? getSinglePayoutParams(params)
        : getGroupPayoutParams(params);

    entity.submitPayout(
      requestBody,
      contentType,
      formatCallbackErrorArg(
        createEarlyExitCallback(
          ({ location }) => onComplete(selectIdFromLocation(location)),
          cb
        ),
        cb
      )
    );
  }

  function searchPayeesByName(displayName, payorId, callback) {
    // Must create each time as the PayorId is assumed not to vary in the
    // PayeeSearchPresenter passed param into constructor - usually via a route change param
    // In this instance it could be any selected payorID i.e. unknown except within this call
    // and is always filtered on PayorId (unlike BOP use cases)
    const fetchPayeeResults = PayeeSearchPresenter(entity, {
      pageSize: 5,
      page: 1,
      payorId,
    });

    fetchPayeeResults(
      displayName,
      createEarlyExitCallback((data) => {
        const { result, totalResults } = data;
        // we map remoteID to a top level object prop for ease of access and prevent API/Data struct leakage
        const mapped = result.map((payee) => ({
          ...payee,
          remoteId: payee.secondaryValues.find(
            (item) => item.type === SecondaryValueType.REMOTE_ID
          ).value,
        }));

        callback(undefined, { result: mapped, totalResults });
      }, callback),
      callback
    );
  }

  function downloadSampleCsv() {
    wireframe.downloadContentAsFile(
      `remoteId,currency,amount,paymentMemo,sourceAccountName,transmissionType,payorPaymentId`,
      'Group_Payout_Sample_CSV.csv',
      'text/csv;encoding:utf-8'
    );
  }

  function load(payorId, fromPayorId, payoutType, cb) {
    const requiresHierarchy = payoutType !== PayoutTypes.TYPE.STANDARD;
    const [
      onPayor,
      onSourceAccounts,
      onFromPayorHierarchy,
      onToPayorHierarchy,
      onFromPayor,
    ] = combineCallbacks(
      requiresHierarchy ? 5 : 2,
      (
        error,
        payorData,
        sourceAccountsData,
        fromPayorHierarchyData,
        toPayorHierarchyData
      ) => {
        if (error) {
          return cb(error);
        }

        const extractHierarchy = ([content]) => content.payors;
        const [payor] = payorData;
        const [sourceAccounts] = sourceAccountsData;

        const fromPayorHierarchy = requiresHierarchy
          ? extractHierarchy(fromPayorHierarchyData)
          : [];

        const toPayorHierarchy = requiresHierarchy
          ? extractHierarchy(toPayorHierarchyData)
          : [];

        return cb(
          payor,
          sourceAccounts.content,
          fromPayorHierarchy,
          toPayorHierarchy
        );
      }
    );

    entity.getPayor(payorId, onPayor);
    entity.getSourceAccounts({ payorId: fromPayorId }, onSourceAccounts);
    if (requiresHierarchy) {
      entity.getPayorLinks(
        { descendantsOfPayor: payorId },
        onFromPayorHierarchy
      );
      entity.getPayorLinks(
        { descendantsOfPayor: fromPayorId },
        onToPayorHierarchy
      );

      // get the data for TransmissionTypes available in the From Payor
      // in a Parent To Child this is overkill as the the From is the Parent
      // but the complexity negates the condition, just extract this for all cases
      // Additionally, cannot use PayorId as this relates to the acting Payor User
      // and would limit the selection for Payors
      entity.getPayor(fromPayorId, onFromPayor);
    }
  }

  return {
    submitPayout,
    searchPayeesByName,
    load,
    onClose,
    onComplete,
    downloadSampleCsv,
  };
}

export { CreatePayoutPresenter };
