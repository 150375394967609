import React from 'react';
import styled from 'astroturf/react';
import { bool, node, func, string } from 'prop-types';
import { VeloRadio } from '../VeloRadio';
import { VeloTypography } from '../VeloTypography';

const RadioImageContainer = styled('div')`
  cursor: pointer;
`;

const RadioButtonContainer = styled('div')`
  align-self: flex-start;
`;

const VeloRadioLabel = styled(VeloTypography)`
  align-self: center;
  text-transform: capitalize;
`;

function VeloRadioImage({ onChange, checked, label, image, name, ...props }) {
  return (
    <>
      <RadioButtonContainer>
        <VeloRadio
          checked={checked}
          name={name}
          id={name}
          value={name}
          onChange={onChange}
          {...props}
        >
          <VeloRadioLabel>{label}</VeloRadioLabel>
        </VeloRadio>
        <RadioImageContainer
          role={'img'}
          onClick={() => onChange({ target: { value: name } })}
        >
          {image}
        </RadioImageContainer>
      </RadioButtonContainer>
    </>
  );
}

VeloRadioImage.propTypes = {
  /** callback on radio selection. */
  onChange: func.isRequired,
  /** Radio label */
  label: string.isRequired,
  /** Radio name */
  name: string.isRequired,
  /** Radio image */
  image: node.isRequired,
  /** is radio checked */
  checked: bool,
  /** Unique testId */
  'data-testid': string.isRequired,
};

export { VeloRadioImage };
