import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'astroturf/react';
import TestIds from './testIds';
import { VeloTypography } from '../VeloTypography';

const Header = styled(VeloTypography).attrs({
  use: 'bodyTextEmphasised',
  tag: 'div',
})`
  @import 'velo-variables';
  color: velo-color('token-color-text-default');
  border-top: 1px solid velo-color('token-color-system-divider');
  border-bottom: 1px solid velo-color('token-color-system-divider');
  padding: 0.8rem 0 0.8rem 0;
`;

const Cell = styled('div')`
  @import 'velo-variables';
  color: velo-color('token-color-text-default');
  border-bottom: 1px solid velo-color('token-color-system-divider');
  padding: 0.5rem 0 0.5rem 0;
  white-space: initial;
  overflow-wrap: break-word;
`;

const Instructions = styled('div')`
  @import 'velo-variables';
  max-height: 0rem;
  overflow: hidden;
  transition: max-height 200ms ease-in-out;
  &.visible-show {
    max-height: 100rem; // Edge has issues with 100% here...
  }
  &.visible-hide {
    max-height: 0rem;
  }
  li {
    margin: 0 0 1.3rem;
    padding-bottom: 0.25rem;
    color: velo-color('token-color-text-default');
  }
`;

const Grid = styled('div')`
  @import 'velo-variables';
  margin-top: 3rem;
  margin-left: 1.5rem;
  display: grid;
  grid-template-columns: 2fr 1fr 3fr;
  @media (max-width: velo-breakpoint(XS)) {
    display: none;
  }
`;

function GridHeader({ value }) {
  return <Header>{value}</Header>;
}
function GridCell({ value, ...props }) {
  return <Cell {...props}>{value}</Cell>;
}

function GroupCsvInstructions({ visible }) {
  const data = [
    {
      fieldName: 'remoteId',
      type: 'String',
      optional: <FormattedMessage defaultMessage="false" />,
      description: (
        <FormattedMessage defaultMessage="Size must be between 1 and 100 inclusive. This is the unique identifier defined by the payor during payee onboarding." />
      ),
    },
    {
      fieldName: 'currency',
      type: 'String',
      optional: <FormattedMessage defaultMessage="false" />,
      description: (
        <FormattedMessage defaultMessage="Must be a 3-character currency code e.g. USD, GBP, EUR." />
      ),
    },
    {
      fieldName: 'amount',
      type: 'Long',
      optional: <FormattedMessage defaultMessage="false" />,
      description: (
        <FormattedMessage defaultMessage="Specified in minor units. Must be at least 1. Example: $11.00 needs to be submitted as 1100." />
      ),
    },
    {
      fieldName: 'paymentMemo',
      type: 'String',
      optional: <FormattedMessage defaultMessage="true" />,
      description: (
        <FormattedMessage defaultMessage="Size must be between 0 and 40 inclusive. This message will be displayed to the payee in the Payee Portal." />
      ),
    },
    {
      fieldName: 'sourceAccountName',
      type: 'String',
      optional: <FormattedMessage defaultMessage="false" />,
      description: (
        <FormattedMessage defaultMessage="Size must be between 1 and 64 inclusive. This needs to be the exact name as the source account setup to fund payments." />
      ),
    },
    {
      fieldName: 'payorPaymentId',
      type: 'String',
      optional: <FormattedMessage defaultMessage="true" />,
      description: (
        <FormattedMessage defaultMessage="Size must be between 0 and 40 inclusive. Unique identifier that defines the payment for the payor.  This will not be displayed to the payee in the Payee Portal." />
      ),
    },
    {
      fieldName: 'transmissionType',
      type: 'String',
      optional: <FormattedMessage defaultMessage="true" />,
      description: (
        <FormattedMessage defaultMessage="Specify a transmission type for the payment. Must be a valid and enabled type for the Payor and Rails. Leave blank to use the default Rails transmission type for the payment" />
      ),
    },
  ];

  return (
    <Instructions
      data-testid={TestIds.GROUP_CSV_INSTRUCTIONS}
      visible={visible ? 'show' : 'hide'}
    >
      <ol>
        <li>
          <FormattedMessage defaultMessage="The file format must be CSV and have a .csv file extension to be accepted." />
        </li>
        <li>
          <FormattedMessage
            defaultMessage="The file must contain a header row including all of the mandatory
          fields: remoteId, currency, amount, sourceAccountName."
          />
        </li>
        <li>
          <FormattedMessage
            defaultMessage="The file may contain any or all of the optional fields as presented in
          the sample CSV file. Providing the optional information will assist
          and accelerate your payment processing and will likely reduce support
          inquiries."
          />
        </li>
        <li>
          <FormattedMessage defaultMessage="The file must not contain a footer row." />
        </li>
        <li>
          <FormattedMessage
            defaultMessage="You may be presented with an error message if there is a formatting or
          whole file issue."
          />
        </li>
        <li>
          <FormattedMessage
            defaultMessage="For individual row errors, you will be provided with a CSV file
          containing the payments that require correction. Please address those
          specific errors and resubmit those records for processing."
          />
        </li>
      </ol>

      <Grid>
        <GridHeader value={<FormattedMessage defaultMessage="Field name" />} />
        <GridHeader value={<FormattedMessage defaultMessage="Optional" />} />
        <GridHeader
          value={<FormattedMessage defaultMessage="Field details" />}
        />
        {data.map((item, i) => (
          <React.Fragment key={i}>
            <GridCell value={item.fieldName} />
            <GridCell value={item.optional} />
            <GridCell value={item.description} />
          </React.Fragment>
        ))}
      </Grid>
    </Instructions>
  );
}

export { GroupCsvInstructions };
