import React, { useState } from 'react';
import { func, string } from 'prop-types';
import styled from 'astroturf/react';
import { VeloIconButton } from '../VeloIconButton';

const Container = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

SensitiveToggle.propTypes = {
  /** The test ID for the toggle button. */
  'data-testid': string.isRequired,
  /** The title for the toggle button. */
  title: string.isRequired,
  /** Children function. */
  children: func,
  /** Render function. */
  render: func,
};

/**
 * Used to toggle between limited and full visibility of a
 * sensitive value using an icon button.
 *
 * This component uses the `render props` pattern allowing the
 * current toggle state to be passed to your own content component.
 *
 * Supply a suitable `children` or `render` prop, whichever you
 * prefer. You will need to perform your own masking based on
 * the value of the current toggle state.
 *
 * Additional props are spread to the `VeloIconButton`.
 */
function SensitiveToggle({ children, render = children, ...other }) {
  const [sensitive, setSensitive] = useState(true);

  return (
    <Container>
      <div>{render(sensitive)}</div>
      <VeloIconButton
        icon={sensitive ? 'visibility_off' : 'visibility'}
        onClick={() => setSensitive((s) => !s)}
        {...other}
      />
    </Container>
  );
}

export { SensitiveToggle };
