import React from 'react';
import styled from 'astroturf/react';
import { string, node } from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { maskStart } from 'velo-data';
import { SensitiveToggle } from '../SensitiveToggle';
import { Description } from '../Description';

MaskedDescription.propTypes = {
  id: string.isRequired,
  label: node.isRequired,
  value: string,
  // required for SensitiveToggle
  'data-testid': string.isRequired,
};

const isDisplayableValue = (v) => v?.length > 0;

const UnsetValue = () => <FormattedMessage defaultMessage="Not set" />;

function SensitiveValue({ value, sensitive, ...props }) {
  if (!isDisplayableValue(value)) {
    return <UnsetValue />;
  }

  return (
    <span {...props}>
      {sensitive ? maskStart(value.slice(-30), 4, 'X') : value}
    </span>
  );
}

const MaskedValue = styled(SensitiveValue)`
  word-break: break-all;
`;

MaskedDescription.UnsetValue = UnsetValue;

export function MaskedDescription({ id, label, value, ...other }) {
  const intl = useIntl();
  return (
    <SensitiveToggle
      title={intl.formatMessage({ defaultMessage: 'Toggle visibility' })}
      disabled={!isDisplayableValue(value)}
      {...other}
    >
      {(sensitive) => (
        <Description
          id={id}
          label={label}
          value={<MaskedValue value={value} sensitive={sensitive} />}
        />
      )}
    </SensitiveToggle>
  );
}
