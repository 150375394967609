import React from 'react';
import { bool, func, node, string } from 'prop-types';
import styled from 'astroturf/react';
import { CSSTransition } from 'react-transition-group';
import { FormattedMessage } from 'react-intl';
import { keyCodes, dataTestIdBuilder } from 'velo-data';
import { VeloTypography } from '../VeloTypography';
import { VeloButton } from '../VeloButton';
import { VeloDivider } from '../VeloDivider';
import { VeloActionBar } from '../VeloActionBar';
import { VeloSkeleton } from '../VeloSkeleton';
import styles from './VeloCollapsibleSection.module.scss';

const root = 'collapsible-section';
const animationTimeMs = 300;

const Checkbox = styled('input')`
  display: none;
`;

const Label = styled(VeloTypography)`
  @import 'velo-variables';

  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  height: 3rem;
  padding-left: 1rem;
  padding-right: 1rem;

  &:hover,
  &:focus {
    background-color: velo-color('token-color-brand-primary-background');
    outline: none;
  }

  &.compact {
    padding-left: 0;
    padding-right: 0;
  }
`;

Label.propTypes = {
  compact: bool,
};

const LabelInner = styled('div')`
  display: flex;
  align-items: center;
`;

const Icon = styled(VeloActionBar.Icon)`
  @import 'velo-variables';

  margin-right: 0.5rem;
  transform: rotate(0deg);
  transition: transform ${animationTimeMs}ms ease-in-out;
  transform-origin: center center;

  &.open {
    transform: rotate(90deg);
  }
`;

Icon.propTypes = {
  open: bool,
};

const Content = styled('div')`
  overflow: hidden;
`;

const Inner = styled('div')`
  padding: 1rem 1rem 1rem 2.75rem;

  &.compact {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
`;

Inner.propTypes = {
  compact: bool,
};

VeloCollapsibleSection.propTypes = {
  /** The component ID. */
  id: string.isRequired,
  /** The label to display. */
  label: node.isRequired,
  /** Determines whether the section is open (expanded) or closed (collapsed). */
  open: bool,
  /** Called when the section is expanded. */
  onOpen: func.isRequired,
  /** Called when the section is collapsed. */
  onClose: func.isRequired,
  /**
   * Called when the Edit button is clicked.
   * Omit this prop to hide the button.
   */
  onEdit: func,
  /** Render a divider below the component. */
  divider: bool,
};

/**
 * Collapsible section component.
 *
 * Renders an indicator icon, label and an optional Edit button.
 * Use this component to toggle the display of `children` on/off using
 * a combination of the `open`, `onOpen` and `onClose` props.
 *
 * When the component has focus SPACE and ENTER will toggle the
 * section open/closed.
 *
 * This is a controlled component so you are responsible for managing
 * the expanded/collapsed state via the `open` prop. For example:
 *
 * ```js
 * function Controlled() {
 *   const [open, setOpen] = useState(false);
 *
 *   return (
 *     <VeloCollapsibleSection
 *       id="my-section"
 *       label="Section"
 *       open={open}
 *       onOpen={() => setOpen(true)}
 *       onClose={() => setOpen(false)}
 *     >
 *       <div>My content</div>
 *     </VeloCollapsibleSection>
 *   )
 * }
 * ```
 */
function VeloCollapsibleSection({
  id,
  label,
  open,
  onOpen,
  onClose,
  onEdit,
  divider,
  children,
  innerProps,
  labelProps,
  'data-testid': dataTestId,
}) {
  return (
    <>
      <div>
        <Checkbox
          id={id}
          type="checkbox"
          checked={open}
          onChange={(event) => {
            event.target.checked ? onOpen() : onClose();
          }}
          data-testid={dataTestIdBuilder(root, id, 'checkbox')}
        />

        <Label
          tag="label"
          use="sectionHeader"
          className={root}
          htmlFor={id}
          tabIndex={0}
          data-testid={dataTestId}
          onKeyDown={(event) => {
            /* istanbul ignore else */
            if (
              event.which === keyCodes.SPACE ||
              event.which === keyCodes.ENTER
            ) {
              event.preventDefault();
              open ? onClose() : onOpen();
            }
          }}
          {...labelProps}
        >
          <LabelInner>
            <Icon icon="chevron_right" open={open} />
            <span>{label}</span>
          </LabelInner>

          {onEdit && (
            <VeloButton
              onClick={onEdit}
              data-testid={dataTestIdBuilder(root, id, 'edit')}
              onKeyDown={(event) => {
                event.stopPropagation();
              }}
            >
              <FormattedMessage defaultMessage="Edit" />
            </VeloButton>
          )}
        </Label>

        <CSSTransition
          classNames={styles}
          timeout={animationTimeMs}
          in={open}
          unmountOnExit
        >
          <Content>
            <Inner {...innerProps}>{children}</Inner>
          </Content>
        </CSSTransition>
      </div>

      {divider && <VeloDivider />}
    </>
  );
}

VeloCollapsibleSection.root = root;

const Loading = styled(VeloSkeleton)`
  height: 3rem;
`;

VeloCollapsibleSection.Loading = Loading;

export { VeloCollapsibleSection };
