import React from 'react';
import classnames from 'classnames';
import { useIntl } from 'react-intl';
import { bool } from 'prop-types';
import { dataTestIdBuilder } from 'velo-data';
import { VeloTopAppBar } from '../../VeloTopAppBar';
import { VeloIcon } from '../../VeloIcon';
import { VeloThemeContext } from '../../VeloThemeContext';
import { navigationDrawerConfig } from '../navigationDrawerConfig';

import styles from './NavigationDrawerTopAppBar.module.scss';

const root = navigationDrawerConfig.NavigationDrawerRoot;

const TestIds = {
  TOGGLE_BUTTON: `${root}-toggle-button`,
  TOP_APP_BAR: `${root}-top-app-bar`,
};

NavigationDrawerTopAppBar.testIds = TestIds;

NavigationDrawerTopAppBar.propTypes = {
  /** Whether the navigation drawer is open. */
  isNavigationDrawerOpen: bool,
};

function NavigationDrawerTopAppBarImage({ isNavigationDrawerOpen, ...props }) {
  const { navDrawerLogoUrl, logoUrl } = VeloThemeContext.useBrandingTokens();
  return (
    <img
      aria-label="Logo"
      src={navDrawerLogoUrl || logoUrl}
      alt="Logo"
      height="25"
      width="auto"
      className={classnames(styles.logo, {
        [styles.hidden]: !isNavigationDrawerOpen,
      })}
      data-testid={props['data-testid']}
    />
  );
}

function NavigationDrawerTopAppBarTitle({ children, ...props }) {
  return (
    <VeloTopAppBar.Title
      data-testid={props['data-testid']}
      className={styles.title}
    >
      {children}
    </VeloTopAppBar.Title>
  );
}

function NavigationDrawerTopAppBarFixedAdjust({ isMobile }) {
  return (
    isMobile && <VeloTopAppBar.FixedAdjust className={styles.fixedAdjust} />
  );
}

/**
 * NavigationDrawerTopAppBar
 */
function NavigationDrawerTopAppBar({
  isNavigationDrawerOpen,
  title,
  isMobile,
  mode,
  scrollTarget,
  showAppBarContent = true,
  className,
  children,
  handleToggle,
}) {
  const intl = useIntl();
  const toggleButtonTitle = isNavigationDrawerOpen
    ? intl.formatMessage({ defaultMessage: 'Collapse navigation' })
    : intl.formatMessage({ defaultMessage: 'Open navigation' });
  const uniqueId = mode;

  return (
    <div
      className={classnames(styles.navigationTopAppBar, styles[className], {
        [styles.open]: isNavigationDrawerOpen,
      })}
      data-testid={dataTestIdBuilder(TestIds.TOP_APP_BAR, uniqueId)}
    >
      <VeloTopAppBar
        fixed
        scrollTarget={scrollTarget}
        className={classnames({
          [styles.scrollEffectOnly]: !showAppBarContent,
        })}
      >
        {showAppBarContent && (
          <VeloTopAppBar.Row>
            <VeloTopAppBar.Section>
              <VeloTopAppBar.NavigationIcon
                icon={{ strategy: 'component', icon: <VeloIcon icon="menu" /> }}
                ripple={!isMobile}
                data-testid={dataTestIdBuilder(TestIds.TOGGLE_BUTTON, uniqueId)}
                tabIndex={isNavigationDrawerOpen || !isMobile ? '0' : '-1'}
                title={toggleButtonTitle}
                onClick={(evt) => handleToggle(evt)}
                onKeyDown={(evt) => handleToggle(evt)}
              />
              {children}
            </VeloTopAppBar.Section>
          </VeloTopAppBar.Row>
        )}
      </VeloTopAppBar>
    </div>
  );
}

export {
  NavigationDrawerTopAppBar,
  NavigationDrawerTopAppBarImage,
  NavigationDrawerTopAppBarTitle,
  NavigationDrawerTopAppBarFixedAdjust,
};
