import { useReducer, useEffect, useCallback, useRef } from 'react';
import { PayoutStates } from 'velo-data';

// States are polling completion to a State where the Payout exists in the System and/or can be (re) quoted
// i.e. PayoutStates.INCOMPLETE = > payout that has been processed but incomplete due to lack of funding (not rejected) - needs to be re-quoted
const PollingCompletionStates = [
  PayoutStates.ACCEPTED,
  PayoutStates.REJECTED,
  PayoutStates.INCOMPLETE,
];

const AbortCompletionStates = [
  PayoutStates.COMPLETED,
  PayoutStates.INSTRUCTED,
  PayoutStates.WITHDRAWN,
  PayoutStates.QUOTED,
];

const ActionTypes = {
  IN_ERROR: 'IN_ERROR',
  PROCESSING: 'PROCESSING', // Submitted - Payment Process in BackEnd
  COMPLETE: 'COMPLETE',
  ABORT: 'ABORT',
};

const reducer = (state, action) => {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case ActionTypes.IN_ERROR:
      /** reset state and add error */
      return { ...state, error: action.payload, isComplete: true };
    case ActionTypes.PROCESSING:
      return {
        ...state,
        getStatusRetryInc: state.getStatusRetryInc + 1,
        payout: action.payload,
      };
    case ActionTypes.COMPLETE:
      return {
        ...state,
        payout: action.payload,
        isComplete: true,
        getStatusRetryInc: 0,
      };

    case ActionTypes.ABORT:
      return {
        ...state,
        payout: action.payload,
        isAborted: true,
        isComplete: true,
        getStatusRetryInc: 0,
      };
    // There is no default case on the switch above as it cannot be tested
    // and falling out of this function is harmless.
  }
};
const initialState = {
  //Increment to poll PayoutStatus
  getStatusRetryInc: 0,
  //ID of the submitted payout
  payoutId: null,
  // Any error during submission/progress
  error: null,
  // payout API response (subset)
  payout: {
    status: undefined,
    acceptedPayments: [],
  },
  // Finished
  isComplete: false,
  // abort if the Payout has already been processed
  // this can happen with a direct link, or incorrect href to route for a
  // Payout in an invalid processing state
  isAborted: false,
};

function usePayoutStatusPoller(
  payoutId,
  getPayoutSummary,
  createDebounceInMs = 500,
  reReviewDebounceMs = 300
) {
  const timeout = useRef(null);

  const fetchPayoutStatus = useCallback(
    (payoutId) => {
      getPayoutSummary(payoutId, (error, response) => {
        if (error) {
          dispatch({ type: ActionTypes.IN_ERROR, payload: error });
        } else {
          const abort = AbortCompletionStates.indexOf(response.status) !== -1;

          const action = abort
            ? ActionTypes.ABORT
            : PollingCompletionStates.indexOf(response.status) !== -1
            ? ActionTypes.COMPLETE
            : ActionTypes.PROCESSING;

          //update state
          dispatch({
            type: action,
            payload: response,
          });
        }
      });
    },
    [getPayoutSummary]
  );

  /** Initialize state and start polling */
  const [state, dispatch] = useReducer(reducer, initialState, () => {
    return { ...initialState, payoutId: payoutId };
  });

  const debounceTimeinMs =
    state.payout.status === PayoutStates.QUOTED
      ? reReviewDebounceMs
      : createDebounceInMs;

  // Trigger a poll on new payoutId or getStatusRetryInc
  useEffect(() => {
    if (state.payoutId && !state.isComplete) {
      if (debounceTimeinMs > 0) {
        clearTimeout(timeout);
        timeout.current = setTimeout(() => {
          fetchPayoutStatus(state.payoutId);
        }, debounceTimeinMs);
      } else {
        fetchPayoutStatus(state.payoutId);
      }
    } else {
      clearTimeout(timeout.current);
    }

    return () => {
      clearTimeout(timeout.current);
    };
  }, [
    state.getStatusRetryInc,
    debounceTimeinMs,
    state.isComplete,
    state.payoutId,
    fetchPayoutStatus,
  ]);
  const { payout, isComplete, isAborted, error } = state;
  return { payout, isComplete, isAborted, error };
}

export { usePayoutStatusPoller };
