import { PayorNameFragment as PayorNameFragmentComponent } from 'velo-react-components';
import { getPayorByIdV2 } from 'velo-api/src/entities/payors';

import {
  usePresenter,
  useCallbackFnAsResultState,
  useSerializableMemo,
} from '../../hooks';
import { forkResult, identitySelector } from '../../selectors';
import {
  createFromHook,
  createRenderForksFromComponent,
} from '../../containers';

function Presenter(_, entity, { payorId, ...props }) {
  const loader = (cb) => entity.getPayor(payorId, cb);
  const noLoader = () => undefined;
  return [payorId ? loader : noLoader, props];
}

const entitySpec = {
  getPayor: getPayorByIdV2,
};

const forks = createRenderForksFromComponent(PayorNameFragmentComponent);

export const PayorNameFragment = createFromHook(
  (props) => {
    const [loader, ...rest] = usePresenter(
      Presenter,
      entitySpec,
      null,
      useSerializableMemo(props)
    );
    const [result] = useCallbackFnAsResultState(loader);
    return forkResult(forks, result, ...rest);
  },
  {
    render: identitySelector,
  }
);
