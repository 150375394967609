import React from 'react';
import { bool, func, oneOf, string } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styled from 'astroturf/react';
import { VeloGrid } from '../VeloGrid';
import { InvitationStatusIndicator } from '../InvitationStatusIndicator';
import { VeloStatefulButton } from '../VeloStatefulButton';

const Grid = styled(VeloGrid.Inner)`
  display: flex;
  justify-content: space-between;
`;

InvitationStatusField.propTypes = {
  /** should show resend invite button. */
  showResendInvite: bool,
  /** The invitation status. */
  invitationStatus: oneOf(['ACCEPTED', 'DECLINED', 'PENDING', 'UNKNOWN'])
    .isRequired,
  /** Called when the Resend Invite button is clicked. */
  onResendInvite: func.isRequired,
  /** The Resend Invite button test ID. */
  'data-testid': string.isRequired,
  /**
   * Called to render the field.
   * Passed an object containing the `label` and `value` allowing
   * you to render a component of your choice.
   */
  render: func.isRequired,
};

/**
 * Render an invitation status indicator and, if the status is
 * `PENDING` a **Resend Invite** button.
 *
 * Uses the render-prop pattern allowing you to render the status
 * using a component of your choice.
 */
function InvitationStatusField({
  showResendInvite,
  invitationStatus,
  onResendInvite,
  'data-testid': testId,
  render,
}) {
  return (
    <Grid>
      <VeloGrid.Cell desktop={6} tablet={4}>
        {render({
          label: <FormattedMessage defaultMessage="Invitation status" />,
          value: (
            <InvitationStatusIndicator
              invitationStatus={invitationStatus}
              use="itemContent"
            />
          ),
        })}
      </VeloGrid.Cell>

      {showResendInvite && (
        <VeloGrid.Cell desktop={6} tablet={4}>
          <VeloStatefulButton
            label={<FormattedMessage defaultMessage="Resend invite" />}
            stages={{
              [VeloStatefulButton.stages.PENDING]: {
                disabled: true,
                label: <FormattedMessage defaultMessage="Resending invite" />,
              },
            }}
            onClick={onResendInvite}
            data-testid={testId}
          />
        </VeloGrid.Cell>
      )}
    </Grid>
  );
}

export { InvitationStatusField };
