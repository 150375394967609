export function PayoutWithdrawnPresenter(
  wireframe,
  entity,
  { payoutId, action }
) {
  function load(cb) {
    entity.getPaymentsByPayout(payoutId, (error, result) => {
      if (!error) {
        // In Withdrawn payment => withdrawn payout flow the PaymentAudit
        // has not yet updated (..and PayoutService 404s on the payoutId)
        // - meaning Withdraw has just occurred, so use now().
        cb(
          undefined,
          result.summary.withdrawnDateTime
            ? result.summary.withdrawnDateTime
            : new Date().toISOString()
        );
      } else {
        cb(error);
      }
    });
  }

  const exit = () => {
    // This prevents  back when a payout is withdrawn returning to the review route when Close clicked
    // NB it is still an issue with the Browser Back button To be resolved.
    if (action === 'PUSH') {
      wireframe.goBack();
    } else {
      wireframe.navigateToPayoutsList.redirect();
    }
  };

  return {
    load,
    exit,
  };
}
