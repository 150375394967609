import { createContext, useContext } from 'react';
export const Context = createContext({
  api: {
    send: () => () => undefined,
  },
});

export function useAPIContext() {
  return useContext(Context).api;
}

export const { Provider, Consumer } = Context;
