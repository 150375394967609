import React from 'react';
import { array, func, node } from 'prop-types';
import styled from 'astroturf/react';
import { bool } from 'prop-types';
import { VeloTypography } from '../VeloTypography';
import { VeloCard } from '../VeloCard';
import { VeloIcon } from '../VeloIcon';
import { ViewHeight } from '../ViewHeight';
import { VeloTextButton } from '../VeloTextButton';
import { VeloThemeContext } from '../VeloThemeContext';
import { dataTestIdBuilder } from 'velo-data';
import { VeloLogoPageText } from './VeloLogoPageText';
import { FormattedMessage } from 'react-intl';

const Card = styled(VeloCard)`
  @import 'velo-variables';
  @import '../ViewHeight/mixins';

  border-radius: 2px 2px 2px 2px;
  box-shadow: 0 2px 4px 0 velo-color('token-color-system-shadow');
  width: 29rem;
  display: flex;

  @media (max-width: velo-breakpoint(XS)) {
    min-width: 100%;
    @include vh(min-height, 100);
  }
`;

const Body = styled('div')`
  @import '../ViewHeight/mixins';
  @import 'velo-variables';
  @import 'velo-mixins';

  @include vh(min-height, 100);
  @include smooth-scrolling;

  background: velo-color('token-color-system-page-surface');
  display: flex;
  align-items: center;
  justify-content: center;

  @include smooth-scrolling;
`;

const Content = styled('div')`
  @import 'velo-variables';
  @import 'velo-mixins';

  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 3.5rem 2rem 3.5rem 2rem;
  flex-grow: 1;

  @media (max-width: velo-breakpoint(XS)) {
    margin: 2rem 1rem 1rem 1rem;
  }

  &.leftAlign {
    text-align: left;
  }

  &.compact {
    margin: 3.5rem 0.5rem 3.5rem 0.5rem;
  }
`;

Content.propTypes = {
  leftAlign: bool,
  compact: bool,
};

const Title = styled(VeloTypography).attrs({
  tag: 'h1',
  use: 'secondaryHeader',
})`
  @import 'velo-variables';
  @import 'velo-mixins';

  margin-top: 1rem;
  margin-bottom: 1rem;

  @include from-breakpoint(XS) {
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
`;

const Children = styled('div')`
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const BackLink = styled(VeloTextButton)`
  position: absolute;
  top: -7px;
  left: 0;
  min-width: 80px;
`;

const Footer = styled('div')`
  @import 'velo-variables';

  padding: 0.5rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: velo-color('token-color-system-page-surface');

  @media (max-width: velo-breakpoint(XS)) {
    padding: 0.5rem 1rem;
  }
`;

const Links = styled('div')`
  margin: 0 0 0.5rem;
  display: flex;
  justify-content: center;
`;

const Link = styled(VeloTextButton)`
  @import 'velo-variables';

  margin-right: 2rem;

  @media (max-width: 320px) {
    margin-right: 1.5rem;
  }

  &:last-child {
    margin-right: 0;
  }

  @media (max-width: velo-breakpoint(XS)) {
    text-align: center;
  }
`;

const Copyright = styled(VeloTypography).attrs({ tag: 'p', use: 'footnote' })`
  margin: 0;
  text-align: center;
`;

const root = 'velo-logo-page';
const TestIds = {
  BACK_BUTTON: `${root}-back-button`,
  TITLE: `${root}-title`,
  FOOTER_LINKS: `${root}-footer-links`,
};

VeloLogoPage.propTypes = {
  /**
   * An optional title to display underneath the logo.
   */
  title: node,
  /**
   * An optional list of Velo Legal Links.
   */
  legalLinks: array,
  /**
   * An event handler for the back link is clicked
   */
  onClickBackLink: func,
  /**
   * Text to display next to the back icon
   */
  backLinkText: node,
  /**
   * Left align content mode
   */
  leftAlign: bool,
};

VeloLogoPage.testIds = TestIds;
VeloLogoPage.root = root;
VeloLogoPage.Text = VeloLogoPageText;

function VeloLogoPage({
  title,
  children,
  onClickBackLink,
  backLinkText,
  legalLinks,
  logoSubtext,
  leftAlign,
  compact,
}) {
  const { copyrightName, logoUrl } = VeloThemeContext.useBrandingTokens();
  return (
    <ViewHeight>
      <Body>
        <Card flat={false} fullScreen={false}>
          <Content leftAlign={leftAlign} compact={compact}>
            {onClickBackLink ? (
              <BackLink
                padded
                onClick={onClickBackLink}
                data-testid={TestIds.BACK_BUTTON}
                icon={<VeloIcon icon="arrow_back" />}
              >
                {backLinkText}
              </BackLink>
            ) : null}

            <img
              alt="Logo"
              aria-label="Logo"
              height="40"
              width="auto"
              src={logoUrl}
            />

            {logoSubtext}

            {title && <Title data-testid={TestIds.TITLE}>{title}</Title>}

            <Children>{children}</Children>
          </Content>

          <Footer>
            {legalLinks && (
              <Links data-testid={TestIds.FOOTER_LINKS}>
                {legalLinks
                  .sort((a, b) => a.order - b.order)
                  .map((link, index) => (
                    <Link
                      key={index}
                      data-testid={dataTestIdBuilder(root, link.id, 'link')}
                      onClick={link.navigateToLink}
                    >
                      {link.label}
                    </Link>
                  ))}
              </Links>
            )}
            <Copyright>
              <FormattedMessage
                defaultMessage="© Copyright {year} {copyrightName}. All rights reserved."
                values={{ year: new Date().getFullYear(), copyrightName }}
              />
            </Copyright>
          </Footer>
        </Card>
      </Body>
    </ViewHeight>
  );
}

export { VeloLogoPage };
