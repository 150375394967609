import React from 'react';
import { arrayOf, bool, string } from 'prop-types';
import { FormattedDisplayName } from 'react-intl';
import { useIntl, FormattedMessage } from 'react-intl';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import styled from '@mui/system/styled';
import { VeloEnhancedSelect } from '../../VeloEnhancedSelect';
import { VeloFlagIcon } from '../../VeloFlagIcon';
import { CurrencyCountryMap, getCurrencySymbol } from 'velo-data';

// Note: using emotion `styled` as an experiment

const Currency = styled('span')`
  margin-right: 1rem;
`;

const FlagIcon = styled(VeloFlagIcon)`
  margin-right: 1rem;
`;

const CurrencyName = styled('span')`
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 1rem;
`;

CurrencyDetails.propTypes = {
  value: string,
  extended: bool,
  disabled: bool,
};

function CurrencyDetails(props) {
  const intl = useIntl();

  return (
    <>
      <ListItemIcon>
        <FlagIcon
          flag={CurrencyCountryMap[props.value]}
          disabled={props.disabled}
        />
      </ListItemIcon>
      <ListItemText
        disableTypography
        sx={{ display: 'flex', minWidth: 0, my: 0 }}
      >
        <Currency>{props.value}</Currency>
        {props.extended && (
          <>
            <CurrencyName>
              <FormattedDisplayName type="currency" value={props.value} />
            </CurrencyName>
            <span>{getCurrencySymbol(intl, props.value)}</span>
          </>
        )}
      </ListItemText>
    </>
  );
}

SelectCurrency.propTypes = {
  currencies: arrayOf(string),
};

/**
 * The SelectCurrency component uses an enhanced MDC `Select` component
 * to allow a supported currency to be picked from a list.
 * Use the `currencies` prop to pass in the list of currencies that you want to display.
 *
 * > Note that `SelectCurrency` uses `VeloFlagIcon` under the hood.
 * @param {currencies} param0
 */
export function SelectCurrency({
  currencies = ['USD'],
  value,
  disabled,
  ...other
}) {
  return (
    <VeloEnhancedSelect
      label={other.label || <FormattedMessage defaultMessage="Currency" />}
      value={value}
      options={[...currencies].sort()}
      // When a value is selected we only want to display the icon
      // and the currency code.
      renderValue={(value) => {
        return (
          <CurrencyDetails value={value} extended={false} disabled={disabled} />
        );
      }}
      disabled={disabled}
      {...other}
    >
      {[...currencies].sort().map((currency) => {
        return (
          <MenuItem key={currency} value={currency}>
            <CurrencyDetails value={currency} extended />
          </MenuItem>
        );
      })}
    </VeloEnhancedSelect>
  );
}
