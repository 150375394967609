import React from 'react';
import { useParams } from 'react-router';
import {
  Content,
  RemoteIdEditForm,
  VeloNotification,
} from 'velo-react-components';
import { getPayeeByIdV4, remoteIdUpdateV4 } from 'velo-api/src/entities/payees';
import { getPayorByIdV2 } from 'velo-api/src/entities/payors';
import {
  usePresenter,
  useCallbackFnAsResultState,
  useSerializableMemo,
} from '../../hooks';
import {
  createFromHook,
  createRenderForksFromComponent,
} from '../../containers';
import { forkResult } from '../../selectors';
import { EditRemoteIdPresenter } from './EditRemoteIdPresenter';

const entitySpec = {
  getPayeeByIdV4,
  getPayorByIdV2,
  remoteIdUpdateV4,
};

const forks = createRenderForksFromComponent(RemoteIdEditForm);

const modes = {
  BACKOFFICE: 'BACKOFFICE',
  PAYOR: 'PAYOR',
};

const BaseEditRemoteIdRoute = createFromHook(
  ({ payeeId, payorId }) => {
    const [loader, props] = usePresenter(
      EditRemoteIdPresenter,
      entitySpec,
      VeloNotification.types.EDIT_PAYEE_REMOTE_ID_SUCCESS,
      useSerializableMemo({ payeeId, payorId })
    );
    const [result] = useCallbackFnAsResultState(loader);
    return [result, props];
  },
  {
    render: ([result, props]) => (
      <Content>{forkResult(forks, result, props)}</Content>
    ),
  }
);

const PayorPortalEditRemoteIdRoute = ({ payorId, ...props }) => {
  const { payeeId } = useParams();
  return (
    <BaseEditRemoteIdRoute payorId={payorId} payeeId={payeeId} {...props} />
  );
};

const BackofficePortalEditRemoteIdRoute = (props) => {
  const { payeeId, payorId } = useParams();
  return (
    <BaseEditRemoteIdRoute payorId={payorId} payeeId={payeeId} {...props} />
  );
};

const EditRemoteIdRoute = {
  [modes.PAYOR]: PayorPortalEditRemoteIdRoute,
  [modes.BACKOFFICE]: BackofficePortalEditRemoteIdRoute,
};

export { EditRemoteIdRoute };
