import React, { createContext, useContext } from 'react';
import {
  NavigationDrawer,
  CookieConsent,
  VeloThemeContext,
} from 'velo-react-components';
import cookie from 'cookie';
import { ApplicationVariantEnum } from '../AppConfigs/ApplicationVariantEnum';

const Context = createContext({
  closeCookieBanner: null,
});

const ONE_YEAR_SECONDS = 60 * 60 * 24 * 365;
const COOKIE_NAME = 'cookieconsent_status';

function getCookieConsent() {
  const cookies = cookie.parse(document.cookie);
  return cookies[COOKIE_NAME];
}

const toLinksProps = (links) => ({
  termsLink: links[NavigationDrawer.Items.TERMS],
  privacyLink: links[NavigationDrawer.Items.PRIVACY],
});

const VariantsWithCookieBanner = {
  [ApplicationVariantEnum.PayeePortal]: 'Payee',
  [ApplicationVariantEnum.PayorPortal]: 'Payor',
};

function CookieConsentContainer({
  appVariant = ApplicationVariantEnum.PayeePortal,
  ...props
}) {
  // Have to use this here to avoid a circular dependency
  const { legalLinks } = VeloThemeContext.useBrandingTokens();

  return VariantsWithCookieBanner[appVariant] ? (
    <CookieConsent {...props} {...toLinksProps(legalLinks[appVariant])} />
  ) : null;
}

class Provider extends React.Component {
  state = {
    cookieBannerOpen: !getCookieConsent(),
  };

  handlers = {
    closeCookieBanner: () => {
      if (this.state.cookieBannerOpen) {
        document.cookie = cookie.serialize(COOKIE_NAME, 'dismissed', {
          maxAge: ONE_YEAR_SECONDS,
          path: window.location.pathname,
        });

        this.setState((state) => ({
          ...state,
          cookieBannerOpen: false,
        }));
      }
    },
  };

  render() {
    const valueProp = {
      ...this.handlers,
      ...this.state,
    };

    return (
      <Context.Provider value={valueProp}>
        <CookieConsentContainer
          appVariant={this.props.appVariant}
          onClose={this.handlers.closeCookieBanner}
          open={this.state.cookieBannerOpen}
        />
        {this.props.children}
      </Context.Provider>
    );
  }
}

const { Consumer } = Context;
/**
 * This function helps when using jest.mock.
 */
const useCookieConsentContext = () => useContext(Context);

const CookieConsentContext = {
  Consumer,
  Provider,
};

export { CookieConsentContext, useCookieConsentContext };
