import React from 'react';
import { func, string } from 'prop-types';
import { ResetPasswordPage, VeloNotification } from 'velo-react-components';
import { Context } from '../../context';
import { formatError } from '../../selectors';

class PasswordUpdateContainer extends React.PureComponent {
  static propTypes = {
    /**
     * The token to call the API with.
     */
    token: string.isRequired,
    /**
     * A render prop on which to apply the behavior.
     */
    render: func.isRequired,
    /**
     * A function to call when the password has been set successfully, or the
     * token is invalid.
     */
    exit: func.isRequired,
    /**
     * Preferably this would be Bool - but invite has a url Resource endpint that differs on submit
     * */
    mode: string,
  };

  /**
   * Requires access to the VeloAppContext
   */
  static contextType = Context;

  state = {
    validationDetail: undefined,
    mfaType: undefined,
    showMfa: false,
    formData: undefined,
  };

  generateOTP = () => {
    const { api } = this.context;
    // Fire and forget
    return api.generateOTP(this.props.token);
  };

  componentDidMount() {
    const { api } = this.context;
    const { token, mode } = this.props;

    /**
     * Token check is done in Legacy code higher up the chain for Invites
     * To migrate this requires a re-working /invite flow
     * OR an addition ternary to validate on /tokens or /invite endpoints
     * Consolidation to /tokens/:token would be preferable
     */

    if (mode === ResetPasswordPage.PasswordUpdateMode.Reset) {
      api
        .checkTokenIsValid(token)
        .then((response) => {
          if (response && response.mfaType) {
            this.setState({ mfaType: response.mfaType });
          }
        })
        .catch((error) => {
          this.props.exit(formatError(error));
        });
    }
  }

  updatePassword = (otp, callback) => {
    const { api } = this.context;

    return api.updatePassword(this.props.token, this.state.formData, otp).then(
      () => {
        this.props.exit();
        callback();
      },
      (error) => {
        callback(formatError(error));
      }
    );
  };

  createPassword = () => {
    const { api } = this.context;
    return api
      .updatePassword(this.props.token, this.state.formData, this.state.otp)
      .then(
        () => this.props.exit(),
        (error) => {
          this.setState({ error: formatError(error) });
        }
      );
  };

  handlers = {
    onSubmit: (formData) => {
      const { mode } = this.props;
      this.setState(
        {
          error: undefined,
          formData,
          showMfa: mode === ResetPasswordPage.PasswordUpdateMode.Reset,
        },
        () => {
          if (!this.state.showMfa) {
            this.createPassword();
          } else {
            this.generateOTP();
          }
        }
      );
    },
    validatePassword: (formData) => {
      this.setState({ validationDetail: {}, formData }, () => {
        const { api } = this.context;
        api
          .validatePasswordWithToken(this.props.token, formData)
          .then(
            (result) => ({ result }),
            (error) => ({ error: formatError(error) })
          )
          .then((validationDetail) => {
            // ensures that older results are not shown
            if (this.state.formData === formData) {
              this.setState({ validationDetail });
            }
          });
      });
    },
    onSubmitOtp: (otp, callback) => {
      this.setState(
        {
          error: undefined,
        },
        () => {
          this.updatePassword(otp, callback);
        }
      );
    },
    secondaryAction: (callback) => {
      const { requestNotification } = this.context;

      /**
       * This is essentially a fire and forget operation.
       * However success and failure notifications are displayed for user feedback.
       */
      this.generateOTP()
        .then(
          () => {
            requestNotification({
              ...VeloNotification.types.RESEND_SMS_SUCCESS,
              key: Date.now().toString(10),
            });
          },
          () => {
            requestNotification({
              ...VeloNotification.types.RESEND_SMS_FAILURE,
              key: Date.now().toString(10),
            });
          }
        )
        /**
         * This callback is used to disable the resend code link when the request
         * is loading.
         */
        .then(() => callback());
    },
  };

  render() {
    return this.props.render({ ...this.state, ...this.handlers });
  }
}

export { PasswordUpdateContainer };
