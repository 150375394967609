import React, { useEffect, useState } from 'react';
import { bool } from 'prop-types';
import { VeloCardForm } from '../VeloCardForm';
import { VeloErrorCard } from '../VeloErrorCard';
import { useFieldGridForm } from '../useFieldGridForm';
import { VeloConfirmDiscardChangesContext } from '../VeloConfirmDiscardChangesContext';
import {
  config,
  withDefaultData,
  LoadingGrid,
  ReadOnlyGrid,
  buildPropTypes,
  FormTitle,
  useWebhookForm,
} from '../WebhookFormFields';

const { withConfirmDiscardChangesContext } = VeloConfirmDiscardChangesContext;

WebhookUpsertPageForm.title = FormTitle;
WebhookUpsertPageForm.propTypes = buildPropTypes({
  /**
   * Indicates that this is a read-only form
   */
  readOnly: bool,
});

const Form = withConfirmDiscardChangesContext()(
  ({ contentUpdated, ...props }) => {
    const [{ onSubmit: submitHandler, ...hookProps }, render] =
      useWebhookForm(props);
    const [success, setSuccess] = useState(false);

    const onSubmit = (body, cb) =>
      submitHandler(body, (error, ...rest) => {
        if (error === undefined) {
          setSuccess(true);
        }
        cb(error, ...rest);
      });

    const [gridProps, { dirty, ...formProps }] = useFieldGridForm(config, {
      ...hookProps,
      onSubmit,
    });

    // keep the dirty state in sync with the context
    useEffect(() => {
      if (success) {
        contentUpdated(false);
      } else {
        contentUpdated(dirty);
      }
    }, [dirty, success, contentUpdated]);

    return (
      <VeloCardForm.Form slimline {...formProps}>
        {render(gridProps)}
      </VeloCardForm.Form>
    );
  }
);

export function WebhookUpsertPageForm({ readOnly, ...props }) {
  return readOnly ? <ReadOnlyGrid {...props} /> : <Form {...props} />;
}

WebhookUpsertPageForm.Empty = withDefaultData(WebhookUpsertPageForm);
WebhookUpsertPageForm.Loading = LoadingGrid;
WebhookUpsertPageForm.Error = VeloErrorCard.Inner;
