import {
  formatCallbackErrorArg,
  createEarlyExitCallback,
  combineCallbacks,
  createCancelAllClosure,
} from '../../selectors';
import {
  PayorNameDataJoin,
  SupportedRailsPresenter,
  AsyncRequestReplyHelper,
} from '../../presenters';
import { canShowFundingAccountPayorField } from './FundingAccountHelpers';

export function FundingAccountsEditPresenter(
  wireframe,
  entity,
  { fundingAccountId, privileges },
  note
) {
  const join = PayorNameDataJoin(
    entity,
    canShowFundingAccountPayorField(privileges)
  );

  function getFundingAccount(cb) {
    return entity.getFundingAccount(
      fundingAccountId,
      {
        // to ensure we can edit unmasked data
        sensitive: true,
      },
      createEarlyExitCallback(
        (data) =>
          join(
            createEarlyExitCallback(([data]) => cb(undefined, data)),
            [data]
          ),
        cb
      )
    );
  }

  const getSupportedRailsData = SupportedRailsPresenter(entity);

  function mergeData(cb) {
    return createEarlyExitCallback(
      ([fundingAccount], [extra]) => cb(undefined, fundingAccount, extra),
      cb
    );
  }

  function onSubmit(body, cb) {
    return AsyncRequestReplyHelper(
      (cb) => entity.updateAccountDetails(fundingAccountId, body, cb),
      entity.getFundingAccountStatus,
      formatCallbackErrorArg(
        createEarlyExitCallback(({ id }) => {
          wireframe.sendNote(note);
          // registers a redirect from old id to new id
          wireframe.addRedirect(fundingAccountId, (query) =>
            wireframe.navigateToFundingAccountDetail.redirect(
              { fundingAccountId: id },
              query
            )
          );
          wireframe.goBack();
        }, cb)
      )
    );
  }

  return [
    (cb) => {
      const [onFundingAccount, onRails] = combineCallbacks(2, mergeData(cb));
      return createCancelAllClosure([
        getFundingAccount(onFundingAccount),
        getSupportedRailsData(onRails),
      ]);
    },
    function getProps(result) {
      const { currencies } = result;
      return {
        currencies,
        onSubmit,
        onClose: wireframe.goBack,
      };
    },
  ];
}
