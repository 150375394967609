import { useMemo } from 'react';
import toIndex from 'just-index';
import {
  usePayorLinksQueryAPI,
  usePayorBrandingAPI,
} from 'velo-api-react-hooks';
import { combine } from '../../selectors';

function combiner({ payorName }, { links, payors }) {
  // key on payorId
  const payorMap = toIndex(payors, 'payorId');
  return {
    payorName,
    links: links
      // join with other info
      .map(({ linkId, fromPayorId }) => ({ linkId, ...payorMap[fromPayorId] })),
  };
}

// if the first argument is undefined, then do not load (payorId/query)
const loadingGuard = (a) => a !== undefined;

/**
 * Combines data from the payor (payorName) and the associated links from the
 * query.
 */
export function usePayorHierarchyData(payorId, query) {
  const [links, payorLinksAPI] = usePayorLinksQueryAPI(query, loadingGuard);
  const [payor, payorAPI] = usePayorBrandingAPI(payorId, loadingGuard);
  return useMemo(
    () => [combine(combiner, payor, links), { ...payorLinksAPI, ...payorAPI }],
    [links, payor, payorLinksAPI, payorAPI]
  );
}
