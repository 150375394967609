import React from 'react';
import { useHistory, useParams } from 'react-router';
import { WUBSPaymentReceipt } from 'velo-react-components';
import { PaymentDetailContainer, PaymentDetailMode } from '../../containers';
import {
  contextPaymentChannelRulesSelector,
  useAppContext,
} from '../../context';

function PaymentReceiptRoute() {
  const { paymentId } = useParams();
  const history = useHistory();
  const context = useAppContext();

  return (
    <PaymentDetailContainer
      paymentId={paymentId}
      mode={PaymentDetailMode.VIEW_RECEIPT}
      render={({ data }) => (
        <WUBSPaymentReceipt onClose={history.goBack} {...data} />
      )}
      allPaymentChannelRules={contextPaymentChannelRulesSelector(context).bank}
    />
  );
}

PaymentReceiptRoute.testIds = { ...WUBSPaymentReceipt.testIds };

export { PaymentReceiptRoute };
