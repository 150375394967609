import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { func } from 'prop-types';
import { VeloTextField } from '../VeloTextField';
import { VeloPasswordField } from '../VeloPasswordField';
import { Email, Password } from '../FormFields/FormFieldTypes';
import { VeloForm } from '../VeloForm';
import { VeloTextButton } from '../VeloTextButton';

const root = 'LoginForm';
const TestIds = {
  EMAIL: `${root}-email`,
  PASSWORD: `${root}-password`,
  RESET_PASSWORD: `${root}-reset-password`,
  ...VeloForm.testIds,
};

LoginForm.testIds = TestIds;

LoginForm.propTypes = {
  /**
   * On submit handler for the username/password login form
   */
  onLogin: func.isRequired,
  /**
   * on click handler to navigate to the forgot password page
   */
  onClickForgotPassword: func.isRequired,
};

function LoginForm({ onLogin, onClickForgotPassword, ...props }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState();
  const actionText = submitting ? (
    <FormattedMessage defaultMessage="Signing in..." />
  ) : (
    <FormattedMessage defaultMessage="Sign In" />
  );

  const formProps = {
    actionText,
    overlay: submitting,
    onSubmit: (e) => {
      e.preventDefault();
      setSubmitting(true);
      setError(undefined);
      onLogin({ email, password }, (error) => {
        if (error) {
          setSubmitting(false);
          setError(error);
        }
      });
    },
    error,
  };

  return (
    <VeloForm {...formProps}>
      <VeloForm.FieldContainer offsetTop>
        <VeloTextField
          {...Email()}
          data-testid={TestIds.EMAIL}
          required
          autoComplete="email"
          value={email}
          onChange={({ target }) => setEmail(target.value)}
        />
      </VeloForm.FieldContainer>

      <VeloPasswordField
        {...Password()}
        data-testid={TestIds.PASSWORD}
        required
        autoComplete="password"
        value={password}
        onChange={({ target }) => setPassword(target.value)}
      />

      <VeloTextButton
        padded
        offsetTop
        data-testid={TestIds.RESET_PASSWORD}
        onClick={onClickForgotPassword}
      >
        <FormattedMessage defaultMessage="Forgot your password? Click here to reset it." />
      </VeloTextButton>
    </VeloForm>
  );
}

LoginForm.getBadCredentialsErrorMessage = (intl) =>
  intl.formatMessage({
    id: 'LoginCredentialsError',
    defaultMessage: 'Incorrect email or password. Please try again.',
  });
LoginForm.getDefaultErrorMessage = (intl) =>
  intl.formatMessage({
    id: 'LoginError',
    defaultMessage: 'Sorry, something went wrong. Please try again later.',
  });

export { LoginForm };
