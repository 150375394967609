import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { VeloRadioTileGroup } from '../VeloRadioTileGroup';
import { PayeeCreationTypes } from 'velo-data';

PayeeCreateType.propTypes = {
  /** The test ID for the Personal Account button. */
  individualTestId: string.isRequired,
  /** The test ID for the Company Account button. */
  companyTestId: string.isRequired,
  /** The test ID for the CSV Upload button. */
  csvUploadId: string.isRequired,
};

/**
 * Render a trio of VeloRadioTiles used to switch between a
 * Personal, Company and CSV Payee creation
 *
 * Note that other props are passed to the underlying `VeloRadioTileGroup`.
 */
function PayeeCreateType({
  individualTestId,
  companyTestId,
  csvUploadId,
  ...props
}) {
  return (
    <VeloRadioTileGroup {...props}>
      <VeloRadioTileGroup.Tile
        icon="account_group"
        title={<FormattedMessage defaultMessage="Multiple accounts" />}
        label={<FormattedMessage defaultMessage="For one or more Payees" />}
        value={PayeeCreationTypes.CSV_UPLOAD}
        data-testid={csvUploadId}
      />

      <VeloRadioTileGroup.Tile
        icon="account_circle"
        title={<FormattedMessage defaultMessage="Personal account" />}
        label={<FormattedMessage defaultMessage="For individuals" />}
        value={PayeeCreationTypes.INDIVIDUAL}
        data-testid={individualTestId}
      />

      <VeloRadioTileGroup.Tile
        icon="payors"
        title={<FormattedMessage defaultMessage="Company account" />}
        label={<FormattedMessage defaultMessage="For registered businesses" />}
        value={PayeeCreationTypes.COMPANY}
        data-testid={companyTestId}
      />
    </VeloRadioTileGroup>
  );
}

export { PayeeCreateType };
