import React from 'react';
import { FormattedMessage } from 'react-intl';
import { VeloLogoPage } from '../VeloLogoPage';
import { LoginForm } from '../LoginForm';
import { MultiFactorChallengeGuard } from '../MultiFactorChallengeGuard';

const modes = {
  PAYEE: 'PAYEE',
  BACKOFFICE: 'BACKOFFICE',
  PAYOR: 'PAYOR',
};

const ModeConfigMap = {
  [modes.PAYEE]: {
    title: <FormattedMessage defaultMessage="Payee Portal Sign In" />,
  },
  [modes.BACKOFFICE]: {
    title: <FormattedMessage defaultMessage="Back Office Portal Sign In" />,
  },
  [modes.PAYOR]: {
    title: <FormattedMessage defaultMessage="Payor Portal Sign In" />,
  },
};

LoginPage.modes = modes;
LoginPage.mfaTypes = MultiFactorChallengeGuard.mfaTypes;
LoginPage.getBadCredentialsErrorMessage =
  LoginForm.getBadCredentialsErrorMessage;
LoginPage.getDefaultErrorMessage = LoginForm.getDefaultErrorMessage;

function LoginPage({
  showMfa,
  onSubmitOtp,
  secondaryAction,
  mfaType,
  error,
  mode,
  ...props
}) {
  const { title: loginPageTitle } = ModeConfigMap[mode];
  const pageTitle = showMfa ? (
    <FormattedMessage defaultMessage="Verification Code" />
  ) : (
    loginPageTitle
  );

  return (
    <VeloLogoPage title={pageTitle} {...props}>
      <MultiFactorChallengeGuard
        showMfa={showMfa}
        isLogin={true}
        secondaryAction={secondaryAction}
        mfaType={mfaType}
        onSubmit={onSubmitOtp}
        error={error}
      >
        <LoginForm {...props} />
      </MultiFactorChallengeGuard>
    </VeloLogoPage>
  );
}

export { LoginPage };
