import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'astroturf/react';
import { VeloDivider } from '../VeloDivider';
import { VeloTypography } from '../VeloTypography';

const Container = styled('div')`
  display: inline-flex;
  margin-top: 0.25rem;
`;

const root = 'DisclosureMessage';

const TestIds = {
  TOP_DIVIDER: `${root}-top-divider`,
  MESSAGE: `${root}-message`,
};

DisclosureMessage.testIds = TestIds;

function DisclosureMessage({ divider, ...other }) {
  return (
    <>
      {divider && <VeloDivider data-testid={TestIds.TOP_DIVIDER} />}
      <Container {...other}>
        <VeloTypography tag="p" use="footnote" data-testid={TestIds.MESSAGE}>
          <FormattedMessage defaultMessage="Your receiving financial institution may charge you a fee or an exchange rate for conversion of currency if your Payor sends payment in a different currency. Fees and currency conversion rates are at the sole discretion of the receiving financial institution." />
        </VeloTypography>
      </Container>
    </>
  );
}

export { DisclosureMessage };
