import React from 'react';
import { arrayOf, shape, string } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { VeloLabelledItem } from '../VeloLabelledItem';
import { Country } from '../Country';

const name = 'payment-channels';

const TestIds = {
  CURRENCY: 'currency',
  LAST_FOUR_DIGITS: 'last-four-digits',
  COUNTRY: 'country',
};

const propTypes = {
  payee: {
    paymentChannels: arrayOf(
      shape({
        paymentChannelId: string.isRequired,
        name: string.isRequired,
        currency: string.isRequired,
        last4Digits: string.isRequired,
        countryCode: string.isRequired,
      }).isRequired
    ).isRequired,
  },
};

function PayeeViewPaymentChannelsSection({
  payee: { paymentChannels },
  onEdit,
}) {
  return {
    name,
    heading: <FormattedMessage defaultMessage="Payment methods" />,
    onEdit,
    hideEdit: !onEdit,
    fields: [
      ...paymentChannels.map(
        ({ paymentChannelId, name, currency, countryCode, last4Digits }, i) => {
          return {
            id: `payment-channel-${i}`,
            label: name,
            value: (
              <>
                <span data-testid={TestIds.CURRENCY}>
                  <FormattedMessage
                    defaultMessage="Currency {currency}"
                    values={{
                      currency,
                    }}
                  />
                </span>
                <br />
                <span data-testid={TestIds.LAST_FOUR_DIGITS}>
                  <FormattedMessage
                    defaultMessage="Account ending in {last4Digits}"
                    values={{
                      last4Digits,
                    }}
                  />
                </span>

                <VeloLabelledItem
                  testId={TestIds.COUNTRY}
                  label={<FormattedMessage defaultMessage="Country" />}
                >
                  <Country country={countryCode} />
                </VeloLabelledItem>
              </>
            ),
          };
        }
      ),
    ],
  };
}

PayeeViewPaymentChannelsSection.sectionName = name;
PayeeViewPaymentChannelsSection.propTypes = propTypes;

export { PayeeViewPaymentChannelsSection };
