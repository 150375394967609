import React, { useMemo, useEffect } from 'react';
import { VeloAppLoading, VeloNotification } from 'velo-react-components';
import { getVerificationTokenById as checkTokenIsValid } from 'velo-api/src/entities/tokens';
import { useAPIMethods } from 'velo-api-react-hooks';
import { useAppContext } from '../../context';
import { useWireframe, useCallbackFnAsResultState } from '../../hooks';
import { forkResult } from '../../selectors';

import { TokenTypes } from './TokenTypes';
import { CheckTokenPresenter } from './UserTokenPresenters';
import { UserInviteRoutes } from './UserInviteRoutes';
import { MFARegistrationRoutes } from './MFARegistrationRoutes';

const entitySpec = {
  checkTokenIsValid,
};

const userTokenInvalidNotification = VeloNotification.types.USER_TOKEN_INVALID;

const TokenTypeRoutesMap = {
  [TokenTypes.INVITE_MFA_USER]: UserInviteRoutes,
  [TokenTypes.MFA_REGISTRATION]: MFARegistrationRoutes,
};

const forks = {
  none: () => <VeloAppLoading />,
  value: (tokenPayload, props) => {
    const Routes = TokenTypeRoutesMap[tokenPayload.tokenType];
    return <Routes {...tokenPayload} {...props} />;
  },
};

function UserTokenRoutes({ token, history }) {
  const wireframe = useWireframe(history);
  const entity = useAPIMethods(entitySpec);
  const { removeBusinessUserInviteLink } = useAppContext();
  const [loader] = useMemo(
    () =>
      CheckTokenPresenter({ token }, wireframe, entity, {
        userTokenInvalidNotification,
      }),
    [token, wireframe, entity]
  );

  useEffect(() => {
    removeBusinessUserInviteLink();
  }, [removeBusinessUserInviteLink]);

  const [result] = useCallbackFnAsResultState(loader);

  return forkResult(forks, result, {
    token,
    history,
  });
}

const routes = {
  base: '/token',
  invite: '/token/invite',
  inviteCreatePassword: '/token/invite/create-password/:otpToken',
  inviteRegisterYubikey: '/token/invite/yubikey/:otpToken',
  inviteRegisterTOTP: '/token/invite/totp/:otpToken',
  MFARegistrationOTPCheck: '/token/register-mfa',
  MFARegistrationRegisterDevice: '/token/register-mfa/:otpToken',
};

const wireframeConfig = {
  navigateToUserTokenBase: routes.base,
  navigateToInvite: routes.invite,
  navigateToInviteCreatePassword: routes.inviteCreatePassword,
  navigateToInviteRegisterYubikey: routes.inviteRegisterYubikey,
  navigateToInviteRegisterTOTP: routes.inviteRegisterTOTP,
  navigateToMFARegistrationOTPCheck: routes.MFARegistrationOTPCheck,
  navigateToMFARegistrationRegisterDevice: routes.MFARegistrationRegisterDevice,
};

UserTokenRoutes.wireframeConfig = wireframeConfig;
UserTokenRoutes.routes = routes;

export { UserTokenRoutes };
