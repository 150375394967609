import React from 'react';
import { bool, string } from 'prop-types';
import classnames from 'classnames';
import styled from 'astroturf/react';
import { VeloDialog } from '../VeloDialog';
import { VeloPropTypes } from '../VeloPropTypes';
import {
  ConfirmationDialogTypes,
  DialogTypeOf,
} from './ConfirmationDialogTypes';

const root = 'confirmation-dialog';

const TestIds = {
  CONFIRMATION_BUTTON: `${root}-accept-button`,
  CANCELLATION_BUTTON: `${root}-cancel-button`,
};

const Dialog = styled(VeloDialog.Simple)`
  &.largeWidth {
    :global {
      @media (min-width: 592px) {
        .mdc-dialog__surface {
          max-width: 620px;
        }
      }
    }
  }
`;

Dialog.propTypes = {
  largeWidth: bool,
};

ConfirmationDialog.propTypes = {
  /** Custom CSS class(es). */
  className: string,
  /** Used to open/close the dialog. */
  open: bool,
  /** Type provides the relevant button labels and body copy - could extends to css to compose. */
  dialogType: VeloPropTypes.dialogType().isRequired,
  largeWidth: bool,
};

ConfirmationDialog.testIds = TestIds;
ConfirmationDialog.dialogTypes = ConfirmationDialogTypes;
ConfirmationDialog.typeOf = DialogTypeOf;

/**
 * Confirmation dialog used to prompt the user
 * a contextual binary option
 */
function ConfirmationDialog({
  className,
  open,
  dialogType,
  acceptProps,
  cancelProps,
  ...other
}) {
  return (
    <Dialog
      open={open}
      className={classnames('velo-confirmation-dialog', className)}
      {...other}
      {...{
        ...dialogType,
        acceptProps: {
          'data-testid': TestIds.CONFIRMATION_BUTTON,
          ...dialogType.acceptProps,
        },
        cancelProps: {
          'data-testid': TestIds.CANCELLATION_BUTTON,
          ...dialogType.cancelProps,
        },
      }}
    />
  );
}

export { ConfirmationDialog };
