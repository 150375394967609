import { getPaymentChannelState, getPaymentsPausedFromIssues } from 'velo-data';

const defaultRef = {
  remoteId: undefined,
  invitationStatus: undefined,
  invitationStatusTimestamp: undefined,
  payableStatus: false,
  payableIssues: [],
};

function formatPayeeResponse(payorId, payee) {
  const {
    remoteId,
    invitationStatus,
    invitationStatusTimestamp,
    payableStatus,
    payableIssues,
    paymentChannelId,
  } = payee.payorRefs.find((ref) => ref.payorId === payorId) || defaultRef;

  const paymentChannelStatus = getPaymentChannelState(
    payableStatus,
    payableIssues,
    paymentChannelId
  );

  const payeePaymentsPaused = getPaymentsPausedFromIssues(payableIssues);

  return {
    ...payee,
    payableStatus,
    paymentChannelStatus,
    payeePaymentsPaused,
    remoteId,
    invitationStatus,
    created: invitationStatusTimestamp || payee.created,
    icon: payee.company ? 'payors' : 'person',
  };
}

export { formatPayeeResponse };
