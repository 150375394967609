import React from 'react';
import { bool, func, string, node, number, oneOf } from 'prop-types';
import styled from 'astroturf/react';
import { VeloTypography } from '../VeloTypography';
import { VeloIcon } from '../VeloIcon';
import { VeloIconButton } from '../VeloIconButton';
import {
  Type,
  DisplayStates,
  VeloNotificationTypes,
} from './VeloNotificationTypes';

const CloseContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-top: 0.25rem;
  font-size: 1.4rem;
`;

const NotificationText = styled(VeloTypography)`
  @import 'velo-variables';

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  color: inherit;
  flex: 2;
  padding: 1rem;

  span + button {
    padding-left: 0.25rem;
    @media (max-width: velo-breakpoint(XS)) {
      text-align: left;
      padding-left: 0;
      display: block;
    }
  }
`;

const NotificationInfo = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 1rem 0 1rem;

  i {
    font-size: 1.4rem;
  }
`;

const ActionText = styled(VeloTypography)`
  @import 'velo-mixins';
  @include velo-font-weight(bold);

  color: velo-color('token-color-text-inverse');
  text-decoration: none;
  background: transparent;
  padding: 0;
  border: 0;
  font-size: 1rem;

  &:hover,
  &:focus {
    text-decoration: none;
    cursor: pointer;
  }
`;

const Notification = styled('div')`
  @import '@material/animation/functions';
  @import 'velo-variables';
  @import 'velo-mixins';

  $default-background-color: velo-color('token-color-system-warning-lighter');
  $default-color: velo-color('token-color-text-default');
  $notification-height: 50px;

  background-color: $default-background-color;
  color: $default-color;
  width: auto;
  min-height: $notification-height;
  padding: 0 0 0 1rem;
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  left: 0;
  right: 0;
  top: 0;
  word-break: break-word;
  z-index: $velo-desktop-in-front-index-default;

  @media (max-width: velo-breakpoint(XS)) {
    max-height: inherit;
    min-height: $notification-height;
  }

  &.type-failure {
    background-color: velo-color('token-color-system-error-default');
    color: velo-color('token-color-text-inverse');
    :global(.token-typography__body-text) {
      font-weight: 500;
    }
    :global(.velo-notification-status-icon) {
      color: velo-color('token-color-text-inverse');
    }
  }

  &.type-warning {
    background-color: velo-color('token-color-system-warning-lighter');
    color: $default-color;
    :global(.velo-notification-status-icon) {
      color: velo-color('token-color-system-warning-default');
    }
  }

  &.type-success {
    background-color: velo-color('token-color-system-success-lighter');
    color: $default-color;

    :global(.velo-notification-status-icon) {
      color: velo-color('token-color-system-success-default');
    }
  }

  &.type-info {
    background-color: velo-color('token-color-system-info-lighter');
    color: $default-color;
    :global(.velo-notification-status-icon) {
      color: velo-color('token-color-system-info-default');
    }
  }

  &.type-payee {
    opacity: 0.95;
    background-color: velo-color('token-color-system-warning-default');
    color: velo-color('token-color-text-inverse');
    animation: slideUp 1s ease;
    transform: translateY(-100%);

    @media (max-width: velo-breakpoint(XS)) {
      /* Take the fixed mobile header into account. */
      top: -65px;
      transform: translateY(-130%);
    }
  }

  &.display-animIn {
    display: flex;
    animation: slideDown 1s ease;
    transform: translateY(0%);
  }

  &.display-animOut {
    display: flex;
    animation: slideUp 1s ease;
    transform: translateY(-100%);
  }

  &.display-hide {
    display: none;
  }
`;

Notification.propTypes = {
  payee: bool,
  display: oneOf(Object.values(DisplayStates)),
  type: oneOf(Object.values(Type)),
};

//export the className to allow it to be shared and reused in tests
const root = 'velo-notification';

const IconsByType = {
  [Type.SUCCESS]: 'check_circle',
  [Type.FAILURE]: 'cancel',
  [Type.WARNING]: 'warning',
  [Type.INFO]: 'info',
  [Type.PAYEE]: 'info',
};

const TestIds = {
  CONTAINER: `${root}-container`,
  CLOSE: `${root}-close`,
  SHOW: `${root}-show`,
};

VeloNotification.propTypes = {
  /** Custom CSS class(es). */
  className: string,
  /** Custom message text to be displayed */
  message: node.isRequired,
  /**  Custom action text to be displayed */
  actionText: node,
  /**  Display duration before auto animate out */
  timeout: number,
  /** onClose function to action closing the notification */
  onClose: func,
  /** onClick function to action the click through */
  onClick: func,
  /** bool value to close on any action */
  dismissesOnAction: bool,
  /** function to close base on dismissesOnAction */
  dismiss: func,
  /** apply css style to display */
  animateCss: oneOf(Object.values(DisplayStates)),
  /** The notification type. */
  type: oneOf(Object.values(Type)),
  /** Display a Payee notification. */
  payee: bool,
  /** The test ID prefix to use. */
  testId: string,
};

VeloNotification.defaultProps = {
  message: '',
  actionText: '',
  testId: root,
};

VeloNotification.testIds = TestIds;
VeloNotification.root = root;
VeloNotification.notificationType = Type;
VeloNotification.types = VeloNotificationTypes;
VeloNotification.displayStates = DisplayStates;

function VeloNotification({
  className,
  message,
  actionText,
  onClick,
  onClose,
  animateCss,
  type,
  dismissesOnAction,
  dismiss,
  payee,
  testId,
}) {
  const handleAction = (action) => {
    action();
    if (dismissesOnAction) {
      dismiss();
    }
  };

  return (
    <Notification
      data-testid={`${testId}-container`}
      className={className}
      display={animateCss}
      type={type && type.toLowerCase()}
      payee={payee}
    >
      <NotificationInfo>
        <VeloIcon
          className="velo-notification-status-icon"
          icon={IconsByType[type]}
        />
      </NotificationInfo>

      <NotificationText use="bodyTextSmaller" tag="div">
        <span>{message}</span>
        {actionText && (
          <ActionText
            use="bodyTextSmaller"
            tag="button"
            tabIndex="0"
            onClick={() => handleAction(onClick)}
            data-testid={`${testId}-show`}
          >
            {actionText}
          </ActionText>
        )}
      </NotificationText>

      {onClose && (
        <CloseContainer>
          <VeloIconButton
            data-testid={`${testId}-close`}
            icon="close"
            title="Close notification"
            label="close"
            onClick={() => handleAction(onClose)}
          />
        </CloseContainer>
      )}
    </Notification>
  );
}

export { VeloNotification };
