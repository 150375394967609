import React from 'react';
import { arrayOf, string } from 'prop-types';
import { useIntl } from 'react-intl';
import styled from 'astroturf/react';
import { VeloTextField } from '../../VeloTextField';
import { useRegionLabel } from '../../utils';
import { VeloIcon } from '../../VeloIcon';
import { VeloSelect } from '../../VeloSelect';

const WarningIcon = styled(VeloIcon).attrs({ icon: 'warning' })`
  @import 'velo-variables';

  color: velo-color('token-color-system-warning-default');
`;

SelectRegion.propTypes = {
  /** ISO country code. */
  country: string.isRequired,
  /**
   * Initial value.
   *
   * If this prop is specified and it does not appear
   * in the list of regions for a country then it will
   * be inserted at the top of the list. Use this to
   * ensure an original value is still selectable from
   * the list. For example, when loading a Payee Profile
   * where the state could have originally been entered
   * by hand or via a CSV upload.
   */
  initialValue: string,
  /**
   * The options to display (i.e. the list of regions.)
   */
  options: arrayOf(string),
};

/**
 * Display a drop-down list of regions based on the supplied
 * ISO country prop.
 *
 * The `label` and `options` will be added for you, and additional
 * props will be spread to the `VeloSelect` component.
 *
 * If the region is not supported then a `VeloTextField` will be
 * used as a fallback.
 *
 * If `initialValue` is specified and it does not appear in the
 * list of options then it will be added to the top of the list.
 *
 * If the `value` does not appear in the list of options then a
 * warning icon is rendered next to the text.
 */
function SelectRegion({
  country,
  initialValue,
  value,
  options = [],
  ...other
}) {
  const intl = useIntl();

  // All available regions as <option> elements
  const regions = options.map((option) => (
    <option key={option} value={option}>
      {option}
    </option>
  ));
  // Fetch the label to use
  const label = useRegionLabel(country);
  // If we have no options then we will use a text field
  if (options.length > 0) {
    return (
      <VeloSelect
        label={label}
        icon={
          // If the value does not exist render a warning icon
          value && options.indexOf(value) === -1 ? (
            <WarningIcon data-testid="select-region-warning-icon" />
          ) : undefined
        }
        value={value}
        helpText={{
          validationMsg: true,
          children: `Please select a ${label.toLowerCase()}`,
        }}
        {...other}
      >
        {initialValue && options.indexOf(initialValue) === -1 ? (
          <>
            <optgroup
              label={intl.formatMessage({ defaultMessage: 'Current Value' })}
            >
              <option value={initialValue}>{initialValue}</option>
            </optgroup>
            <optgroup
              label={intl.formatMessage({
                defaultMessage: 'Available Options',
              })}
            >
              {regions}
            </optgroup>
          </>
        ) : (
          regions
        )}
      </VeloSelect>
    );
  }
  return (
    <VeloTextField
      label={label}
      value={value}
      helpText={{
        validationMsg: true,
        children: `Please enter a ${label.toLowerCase()}`,
      }}
      {...other}
    />
  );
}

export { SelectRegion };
