import {
  combineCallbacks,
  createCancelAllClosure,
  createEarlyExitCallback,
} from '../../selectors';
import { PayorNameDataJoin } from '../../presenters';
import {
  PayorAccountType,
  Privileges,
  privilegeSelectors,
  sourceAccountSelectors,
} from 'velo-data';
import { FundingAccountSearchPresenter } from '../../presenters/FundingAccountSearchPresenter';

function createUtility(wireframe) {
  return (cb, notification) => (error) => {
    if (error) {
      return cb(error);
    }
    wireframe.sendNote(notification);
    wireframe.goBack();
  };
}

function createMergeCallback(entity, cb) {
  return createEarlyExitCallback(
    ([[sourceAccount]], [autoTopUpFundingAccount]) => {
      const normalisedSourceAccount =
        sourceAccountSelectors.normaliseSourceAccount(sourceAccount);
      return cb(undefined, normalisedSourceAccount, {
        autoTopUpFundingAccount,
        fetchResults: (query, callback) =>
          FundingAccountSearchPresenter(entity, {
            payorId: sourceAccount.payorId,
            type: normalisedSourceAccount.type,
            ...(normalisedSourceAccount.type === PayorAccountType.FBO && {
              currency: sourceAccount.currency,
            }),
          })(query, callback),
      });
    },
    cb
  );
}

function canViewPayor(privileges) {
  return privilegeSelectors.hasPrivileges(
    [Privileges.SOURCE_ACCOUNT_VIEW_PAYOR],
    privileges
  );
}

function CorePresenter(
  wireframe,
  entity,
  { sourceAccountId, privileges },
  getFundingAccount
) {
  const sendContent = PayorNameDataJoin(entity, canViewPayor(privileges));

  const loader = (cb) =>
    entity.getSourceAccount(
      sourceAccountId,
      createEarlyExitCallback((sourceAccount) => {
        const [onData, onFundingAccount] = combineCallbacks(
          2,
          createMergeCallback(entity, cb)
        );

        return createCancelAllClosure([
          sendContent(onData, [sourceAccount]),
          sourceAccount.autoTopUpConfig.fundingAccountId
            ? getFundingAccount(
                sourceAccount.autoTopUpConfig.fundingAccountId,
                onFundingAccount
              )
            : onFundingAccount(undefined, {}),
        ]);
      }, cb)
    );

  return [
    loader,
    {
      onClose: wireframe.goBack,
    },
    createUtility(wireframe),
  ];
}

export function SourceAccountWithFundingAccountSearchPresenter(
  wireframe,
  entity,
  props
) {
  return CorePresenter(wireframe, entity, props, entity.getFundingAccount);
}

export function SourceAccountsViewDataPresenter(wireframe, entity, props) {
  const [loader, ...rest] = CorePresenter(wireframe, entity, props, (_, cb) =>
    cb(undefined, {})
  );
  return [
    (cb) =>
      loader(
        createEarlyExitCallback(
          (sourceAccount) =>
            cb(undefined, {
              ...sourceAccount,
              sourceAccountName: sourceAccount.name,
            }),
          cb
        )
      ),
    ...rest,
  ];
}
