import React from 'react';
import { oneOf } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { InvitationStatus } from 'velo-data';

import { VeloStatusIndicator } from '../VeloStatusIndicator';

const InvitationStatusIndicatorMap = {
  [InvitationStatus.ACCEPTED]: {
    label: <FormattedMessage defaultMessage="Accepted" />,
    type: VeloStatusIndicator.types.SUCCESS,
  },
  [InvitationStatus.DECLINED]: {
    label: <FormattedMessage defaultMessage="Declined" />,
    type: VeloStatusIndicator.types.FAILED,
  },
  [InvitationStatus.PENDING]: {
    label: <FormattedMessage defaultMessage="Pending" />,
    type: VeloStatusIndicator.types.PENDING,
  },
  [InvitationStatus.UNKNOWN]: {
    label: <FormattedMessage defaultMessage="Unknown" />,
    type: VeloStatusIndicator.types.UNKNOWN,
  },
};

InvitationStatusIndicator.propTypes = {
  /**
   * The invitation status as a string, or empty for unavailable.
   */
  invitationStatus: oneOf(Object.keys(InvitationStatusIndicatorMap)),
};

function InvitationStatusIndicator({ invitationStatus, ...props }) {
  const statusIndicatorProps =
    InvitationStatusIndicatorMap[invitationStatus] ||
    InvitationStatusIndicatorMap[InvitationStatus.UNKNOWN];
  return <VeloStatusIndicator {...props} {...statusIndicatorProps} />;
}

export { InvitationStatusIndicator };
