import {
  NavigationDrawerPushMenu,
  NavigationDrawerPushContent,
} from './NavigationDrawerPush';
import { Fade } from './Fade';
import { SlideRight, SlideLeft, SlideTop, SlideDown } from './Slide';

const Transitions = {
  Fade,
  SlideRight,
  SlideLeft,
  SlideTop,
  SlideDown,
  NavigationDrawerPushContent,
  NavigationDrawerPushMenu,
};

export { Transitions };
