import React from 'react';
import styled from 'astroturf/react';
import { FormattedMessage } from 'react-intl';
import { func, object } from 'prop-types';
import { VeloProgressIndicator } from '../VeloProgressIndicator';
import { PayoutPageLoading } from '../PayoutPageLoading';
import { Content } from '../Content';

const ProgressContainer = styled('div')`
  margin-bottom: 2.5rem;
`;

const TestIds = {
  PROGRESS_INDICATOR: `velo-payouts-processing-progress-indicator`,
};

const titles = {
  IN_PROGRESS: <FormattedMessage defaultMessage="Upload in progress" />,
  COMPLETE: <FormattedMessage defaultMessage="Upload complete. Processing.." />,
};

const ProgressIndicator = (props) => (
  <ProgressContainer>
    <VeloProgressIndicator
      data-testid={TestIds.PROGRESS_INDICATOR}
      {...props}
    />
  </ProgressContainer>
);

/**
 * PayoutPageProcessing:
 * Skeleton Page ,with Processing Header - Loading CSV, Processing Payments etc..
 * */
PayoutProcessing.propTypes = {
  /** Function to close page */
  onClose: func.isRequired,
  /** Object containing progress details */
  progressProps: object.isRequired,
};
function PayoutProcessing({ progressProps, onClose }) {
  return (
    <Content>
      {progressProps.showProgressBar && (
        <ProgressIndicator {...progressProps} />
      )}
      {/** Loading  */}
      <PayoutPageLoading onClose={onClose} />
    </Content>
  );
}
PayoutProcessing.testIds = TestIds;
PayoutProcessing.titles = titles;

export { PayoutProcessing };
