import subscribe from 'callbag-subscribe';
import { useMemo } from 'react';
import pipe from 'callbag-pipe';
import callbagToPromise from 'callbag-to-promise';
import { useAPIContext } from '../context';

function convertCallbagFunctionToCallbackFunction(callbagFn) {
  return (...args) => {
    const cb = args[args.length - 1];
    const sansCallback = args.slice(0, -1);
    return subscribe({
      error: cb,
      next: (result) => cb(undefined, result),
    })(callbagFn(...sansCallback));
  };
}

/**
 * A hook that takes a plain object of key-values that are exports from the api
 * library and wraps them in the send implementation, ready for calling by other
 * objects. Each returned function will have the last argument augmented to
 * expect a callback.
 */
export function useAPIMethods(entitySpec) {
  const context = useAPIContext();
  return useMemo(
    () =>
      Object.entries(entitySpec || {}).reduce(
        (acc, [k, v]) => ({
          ...acc,
          [k]: convertCallbagFunctionToCallbackFunction(v(context.send)),
          [`${k}Async`]: (...args) =>
            pipe(v(context.send)(...args), callbagToPromise),
        }),
        {
          send: convertCallbagFunctionToCallbackFunction(context.send),
        }
      ),
    [context, entitySpec]
  );
}
