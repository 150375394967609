import { UserRoles } from 'velo-react-components';
import {
  PayorSearchPresenter,
  createListRedirectionLoader,
} from '../../presenters';
import {
  mapTablePropsWithResult,
  filterSelectors,
  createEarlyExitCallback,
} from '../../selectors';
import { formatPayeeResponse } from '../../containers/payees/helpers';

export function PayeesListPresenter(
  wireframe,
  entity,
  { query, countries, filters, role },
  { keys },
  intl
) {
  const fetchPayorResults = PayorSearchPresenter(entity);

  const redirect = (qs) => {
    if (qs.payorId) {
      wireframe.navigateToPayeesByPayorList.redirect({}, qs);
    } else {
      wireframe.navigateToPayeesList.redirect();
    }
  };

  const loader = createListRedirectionLoader(redirect, keys, query, (q, cb) => {
    const { payorId } = query;
    return entity.getPayees(
      { ...(payorId ? { payorId } : {}), ...q },
      createEarlyExitCallback(({ content, ...extra }) => {
        cb(
          undefined,
          content.map((payee) => formatPayeeResponse(query.payorId, payee)),
          extra
        );
      }, cb)
    );
  });

  function getProps(...args) {
    return [
      mapTablePropsWithResult(...args),
      {
        onClick: (payee) => {
          if (query.payorId && role === undefined) {
            wireframe.navigateToPayeesByPayorList(
              {},
              { ...query, payeeId: payee.payeeId }
            );
          } else {
            wireframe.navigateToPayeeDetails({ payeeId: payee.payeeId });
          }
        },
      },
    ];
  }

  // NOTE: UserRoles pattern is deprecated.
  // TODO: Refactor to remove deprecated UserRoles pattern
  const onAdd = UserRoles.isPayorAdmin(role)
    ? () => wireframe.navigateToPayeeCreate()
    : undefined;

  return [
    loader,
    {
      populatedFilters: filterSelectors.populateFilters(filters, query, {
        fetchPayorResults,
        countries,
        intl,
      }),
      getProps,
      onAdd,
      payeesByPayorPath: wireframe.navigateToPayeesByPayorList.path,
      detailsRoutes: wireframe.navigateToPayeeDetails.path,
    },
  ];
}
