import React, { useRef, useCallback } from 'react';
import {
  arrayOf,
  bool,
  node,
  shape,
  number,
  object,
  string,
  func,
} from 'prop-types';
import styled from 'astroturf/react';
import { VeloGrid } from '../VeloGrid';
import { VeloTypography } from '../VeloTypography';
import { useElementBreakpoint } from '../hooks';

const Heading = styled(VeloTypography).attrs({
  use: 'sectionHeader',
  tag: 'h2',
})`
  margin: 0;
`;

const HeadingCell = styled(VeloGrid.Cell)`
  @import 'velo-variables';
  border-bottom: 0;
  margin-bottom: 1rem;
  &.underline {
    border-bottom: 1px solid velo-color('token-color-system-divider');
  }
  &.overline {
    border-bottom: none;
    margin-bottom: 0;
    padding-top: 1.5rem;
    padding-bottom: 0.5rem;
    border-top: 1px solid velo-color('token-color-system-divider');
  }
  &.tail {
    &:first-child {
      padding-top: 0;
      border-top: none;
    }
  }
  &.compact {
    margin-bottom: 0;
  }
`;

HeadingCell.propTypes = {
  underline: bool,
  overline: bool,
  tail: bool,
  compact: bool,
};

HeadingCell.defaultProps = {
  underline: true,
};

// Default span values for a single-column view
const singleColumnSpan = { desktop: 12, tablet: 8, phone: 4 };

// Return the props to use for a heading cell
const getHeadingProps = ({
  className,
  underline = true,
  overline,
  tail,
  ...other
}) => ({
  className,
  underline,
  overline,
  tail,
  ...other,
  ...singleColumnSpan,
});

const VeloSectionGridType = (fieldProps = {}, sectionProps = {}) => ({
  /** The sections to render. */
  sections: arrayOf(
    shape({
      /** The section heading. */
      heading: node,
      /** Used to override default heading prop type */
      ...sectionProps,
      /** The field to render. */
      fields: arrayOf(
        shape({
          ...fieldProps,
          /** The desktop span to use. Defautls to 12. */
          desktop: number,
          /** The tablet span to use. Defaults to 8, */
          tablet: number,
          /** The phone span to use. Defaults to 4. */
          phone: number,
        })
      ),
    })
  ),
  /**
   * Single-column breakpoint
   */
  breakpoint: number,
  /**
   * Section heading props.
   * Use this to apply a custom className, style, etc.
   * to each header grid cell.
   */
  headingProps: object,
});

VeloSectionGrid.propTypes = {
  ...VeloSectionGridType(),
  /** Renders a more compact grid with less padding. */
  compact: bool,
  /** Custom CSS class(es) */
  className: string,
  /**
   * A function that is passed a fields object, and is expected to return a
   * React element.
   */
  render: func.isRequired,
};

VeloSectionGrid.defaultProps = {
  breakpoint: 800,
  headingProps: {},
};

/**
 * Render a grid of components. Requires an array of sections that
 * contain fields, each of which is passed to the `render` function to return
 * a React element.
 *
 * Sections can have an optional heading in order to group related content
 * together.
 *
 * Each field will be rendered as a `VeloGrid.Cell` using a custom `span`. You
 * can pass the span to use for `desktop`, `tablet` and `mobile` to
 * achieve a custom responsive layout.
 *
 * This component uses MDC Grid which relies on CSS media queries to use
 * the correct span based on the browser screen width. To handle instances
 * where a grid is used inside a narrow viewport the component will force
 * a mobile-style layout based on a default breakpoint of 800px. You can
 * override this using the `breakpoint` prop.
 */

const Cell = styled(VeloGrid.Cell)`
  &.verticleAlignCenter {
    display: flex;
    align-items: center;
  }
`;

Cell.propTypes = {
  verticleAlignCenter: bool,
};
function VeloSectionGrid({
  compact,
  sections,
  breakpoint,
  headingProps,
  render,
  ...other
}) {
  const grid = useRef(null);
  const singleColumn = useElementBreakpoint(grid, breakpoint);

  const createField = useCallback(
    (
      { desktop = 12, tablet = 8, phone = 4, verticleAlignCenter, ...field },
      key
    ) => (
      <Cell
        key={key}
        verticleAlignCenter={verticleAlignCenter}
        {...(singleColumn ? singleColumnSpan : { desktop, tablet, phone })}
      >
        {render(field)}
      </Cell>
    ),
    [singleColumn, render]
  );

  const createSection = useCallback(
    ({ heading, fields }, index) => {
      const sectionKey = `section-${index}`;
      return (
        <React.Fragment key={sectionKey}>
          {heading && (
            <HeadingCell compact={compact} {...getHeadingProps(headingProps)}>
              <Heading>{heading}</Heading>
            </HeadingCell>
          )}
          {fields.reduce(
            (children, field, index) => [
              ...children,
              createField(field, `${sectionKey}-field-${index}`),
            ],
            []
          )}
        </React.Fragment>
      );
    },
    [headingProps, createField, compact]
  );

  return (
    <div ref={grid}>
      <VeloGrid compact={compact} {...other}>
        {sections.map(createSection)}
      </VeloGrid>
    </div>
  );
}

VeloSectionGrid.type = VeloSectionGridType;

export { VeloSectionGrid };
