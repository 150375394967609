export function withdrawPayment(send) {
  return (paymentId, body, ...args) =>
    send(
      {
        path: '/v1/payments/{paymentId}/withdraw',
        pathParameters: { paymentId },
        type: 'post',
        query: undefined,
        body: body,
        headers: undefined,
        auth: 'oauth2',
        contentType: 'application/json',
      },
      ...args
    );
}
