import React from 'react';
import { oneOf, node } from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import styled from 'astroturf/react';
import { VeloStatusIndicator } from '../VeloStatusIndicator';
import { VeloIcon } from '../VeloIcon';

const AllocationTypeIcon = styled(VeloIcon)`
  @import 'velo-variables';

  color: velo-color('token-color-text-default');
  font-size: 20px;
  height: 20px;
  width: 20px;
`;

AllocationType.propTypes = {
  /** The allocation type. */
  type: oneOf(['AUTOMATIC', 'MANUAL']),
  /** Label prefix. */
  labelPrefix: node,
};

const typeIcons = {
  AUTOMATIC: 'automatic',
  MANUAL: 'manual',
  BANK_TRANSFER: 'bank',
};

const typeLabels = {
  AUTOMATIC: <FormattedMessage defaultMessage="Automatic" />,
  MANUAL: <FormattedMessage defaultMessage="Manual" />,
  BANK_TRANSFER: <FormattedMessage defaultMessage="Bank transfer" />,
  DIRECT_DEBIT: <FormattedMessage defaultMessage="Direct debit" />,
};

const typeTitles = {
  AUTOMATIC: (intl) => intl.formatMessage({ defaultMessage: 'Automatic' }),
  MANUAL: (intl) => intl.formatMessage({ defaultMessage: 'Manual' }),
  BANK_TRANSFER: (intl) =>
    intl.formatMessage({ defaultMessage: 'Bank transfer' }),
};

const prefixLabels = {
  BANK_TRANSFER: <FormattedMessage defaultMessage="Bank transfer" />,
  DIRECT_DEBIT: <FormattedMessage defaultMessage="Direct debit" />,
};

/**
 * Allocation type indicataor.
 *
 * Used by Funding tables and views.
 */
function AllocationType({ type, labelPrefix }) {
  const intl = useIntl();
  const allocationTypeIcon = typeIcons[type] || typeIcons[labelPrefix];
  const allocationTypeTitle =
    typeTitles[type]?.(intl) || typeTitles[labelPrefix]?.(intl);

  const allocationTypePrefix = prefixLabels[labelPrefix] || labelPrefix;
  const allocationTypeLabel = typeLabels[type] || '';

  return (
    <VeloStatusIndicator
      type="info"
      icon={{
        icon: (
          <AllocationTypeIcon
            icon={allocationTypeIcon}
            title={allocationTypeTitle}
          />
        ),
        strategy: 'component',
      }}
      label={
        <>
          {labelPrefix ? (
            <span>
              {allocationTypePrefix}
              {type && ' - '}
            </span>
          ) : null}
          {allocationTypeLabel}
        </>
      }
    />
  );
}

AllocationStatus.propTypes = {
  /** The allocation type. */
  type: oneOf(['ALLOCATED', 'UNALLOCATED']).isRequired,
};

const allocationStatusProps = {
  ALLOCATED: {
    type: VeloStatusIndicator.types.SUCCESS,
    label: <FormattedMessage defaultMessage="Allocated" />,
  },
  UNALLOCATED: {
    type: VeloStatusIndicator.types.WARNING,
    label: <FormattedMessage defaultMessage="Unallocated" />,
  },
};

/**
 * Allocation status indicator.
 */
function AllocationStatus({ type }) {
  return <VeloStatusIndicator {...allocationStatusProps[type]} />;
}

export { AllocationType, AllocationStatus };
