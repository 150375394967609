import { VeloNotification, SourceAccountEditForm } from 'velo-react-components';
import { getSupportedRails } from 'velo-api/src/entities/rails';
import { getSourceAccountStatusV3 as getSourceAccountStatus } from 'velo-api/src/entities/sourceAccounts';
import {
  createEarlyExitCallback,
  combineCallbacks,
  createCancelAllClosure,
} from '../../selectors';
import { SupportedRailsPresenter } from '../../presenters';
import { SourceAccountsViewDataPresenter } from './SourceAccountsViewDataPresenter';
import { createRoute } from './helpers';

function mergeData(cb) {
  return createEarlyExitCallback(
    ([data], [extra]) => cb(undefined, data, extra),
    cb
  );
}

function Presenter(wireframe, entity, props) {
  const [loader, componentProps] = SourceAccountsViewDataPresenter(
    wireframe,
    entity,
    props
  );

  const getSupportedRailsData = SupportedRailsPresenter(entity);

  return [
    (cb) => {
      const [onLoader, onRails] = combineCallbacks(2, mergeData(cb));
      return createCancelAllClosure([
        loader(onLoader),
        getSupportedRailsData(onRails),
      ]);
    },
    componentProps,
  ];
}

export const SourceAccountsEditRoute = createRoute(
  Presenter,
  SourceAccountEditForm,
  {
    getSourceAccountStatus,
    getSupportedRails,
  },
  VeloNotification.types.SOURCE_ACCOUNT_UPDATE_SUCCESS
);

SourceAccountsEditRoute.displayName = 'SourceAccountsEditRoute';
