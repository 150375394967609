import React, { useCallback, useState, useMemo } from 'react';
import {
  PayoutsPage,
  createRenderForksFromTable,
  UserRoles,
  PayoutsListForBackOffice,
  PayoutsScheduledListForBackOffice,
  PayoutsListForPayor,
  PayoutsScheduledListForPayor,
} from 'velo-react-components';
import { ScheduledStatus } from 'velo-data';
import { getPayoutSummaryV3 as getPayout } from 'velo-api/src/entities/payouts';
import { getPayoutsForPayorV4 as getPayouts } from 'velo-api/src/entities/paymentaudit';
import {
  listPayorsV2 as getPayors,
  getPayorByIdV2 as getPayorById,
} from 'velo-api/src/entities/payors';

import { PayoutsListRoutePresenter } from './PayoutsListRoutePresenter';
import {
  useAllQueries,
  useCallbackFnAsResultState,
  usePayorHierarchySelector,
  usePresenter,
} from '../../hooks';
import { forkResult } from '../../selectors';

const entitySpec = {
  getPayouts,
  getPayors,
  getPayout,
  getPayorById,
};

const PayoutsListMode = {
  PAYOR: 'PAYOR',
  BACKOFFICE: 'BACKOFFICE',
};

const TabConfigMap = {
  [PayoutsListMode.BACKOFFICE]: {
    0: {
      ...PayoutsListForBackOffice.TabConfig,
    },
    1: {
      ...PayoutsScheduledListForBackOffice.TabConfig,
      scheduleStatus: ScheduledStatus.ANY,
    },
  },
  [PayoutsListMode.PAYOR]: {
    0: {
      ...PayoutsListForPayor.TabConfig,
    },
    1: {
      ...PayoutsScheduledListForPayor.TabConfig,
      scheduleStatus: ScheduledStatus.ANY,
    },
  },
};

const filtersPersistedAcrossTabs = ['payorId'];

function PayoutsListRoute({
  query,
  payorId,
  isPayorAdmin = false,
  advanced = false,
  mode = PayoutsListMode.BACKOFFICE,
}) {
  const [selectedTab, setSelectedTab] = useState(0);

  const { getFilters, primaryFilterField, defaultSort, table, scheduleStatus } =
    TabConfigMap[mode][selectedTab];

  const [openBankingEnabledLoader, loader, filters, mapProps] = usePresenter(
    PayoutsListRoutePresenter,
    entitySpec,
    getFilters,
    useMemo(
      () => ({
        query,
        payorId,
        scheduleStatus,
      }),
      [query, payorId, scheduleStatus]
    )
  );

  const [listQuery, props, displayValues, clearFilters] = useAllQueries(
    filters,
    primaryFilterField,
    defaultSort,
    20,
    // This must be null, in order for filters to be computed
    null
  );

  const [openBankingEnabledResult] = useCallbackFnAsResultState(
    useCallback(
      (cb) => openBankingEnabledLoader(payorId, cb),
      [openBankingEnabledLoader, payorId]
    )
  );

  const [result, , setResult] = useCallbackFnAsResultState(
    useCallback(
      (cb) => loader(listQuery, displayValues, cb),
      [loader, listQuery, displayValues]
    ),
    true
  );

  const forks = createRenderForksFromTable(table);
  const forkProps = mapProps(props, result, isPayorAdmin);

  const pageHeaderProps = {
    ...forkProps[2], // splice in additional props
    advanced,
  };

  return (
    <PayoutsPage
      {...pageHeaderProps}
      openBankingEnabled={!!openBankingEnabledResult.result}
    >
      <PayoutsPage.TabbedPageView
        activeTabIndex={selectedTab}
        onActivate={(tabIndex) => {
          clearFilters(filtersPersistedAcrossTabs);
          setResult(useCallbackFnAsResultState.defaultState);
          setSelectedTab(tabIndex);
        }}
      >
        {forkResult(forks, result, ...forkProps)}
      </PayoutsPage.TabbedPageView>
    </PayoutsPage>
  );
}

function PayorPayoutListPayor({ payorId, role, ...props }) {
  const payorDescendants = usePayorHierarchySelector(payorId);
  const isPayorAdmin = UserRoles.isPayorAdmin(role);
  const advanced = payorDescendants && payorDescendants.length > 0;

  return (
    <PayoutsListRoute
      {...props}
      payorId={payorId}
      isPayorAdmin={isPayorAdmin}
      advanced={advanced}
      mode={PayoutsListMode.PAYOR}
    />
  );
}

PayoutsListRoute.Payor = PayorPayoutListPayor;

export { PayoutsListRoute };
