import { useMemo } from 'react';
import {
  useLoadingEffectFunction,
  mapPromiseResponseWithCallback,
} from '../helpers';
import { useAPIContext } from '../../context';

export function usePayorLinksQueryAPI(query, guard) {
  const api = useAPIContext();
  const [data, getPayorLinks] = useLoadingEffectFunction(
    {
      fn: api.getPayorLinks,
      guard,
    },
    query
  );

  const methods = useMemo(
    () => ({
      createPayorLink: mapPromiseResponseWithCallback(api.createPayorLink),
      deletePayorLink: mapPromiseResponseWithCallback(api.deletePayorLink),
    }),
    [api.createPayorLink, api.deletePayorLink]
  );

  return [data, { ...methods, getPayorLinks }];
}
