import React, { useState, useRef, useEffect } from 'react';
import { bool, func, string } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styled from 'astroturf/react';
import { useSMSCode, useYubikeyKeyCode } from '../FormFields/FormFieldTypes';
import { VeloLogoPage } from '../VeloLogoPage';
import { VeloForm } from '../VeloForm';
import { VeloTextField } from '../VeloTextField';
import { VeloTextButton } from '../VeloTextButton';
import { ConfirmationDialog } from '../ConfirmationDialog';
import { MFATypes } from '../MFATypes';

const Dialog = styled(ConfirmationDialog)`
  @import 'velo-variables';

  :global(.mdc-dialog__title) {
    text-align: left;
  }

  :global(.mdc-dialog__surface) {
    @media (min-width: velo-breakpoint(XS)) {
      /* On desktop fix the width of the dialogs to 400px */
      width: 25rem;
      max-width: 25rem;
      min-width: 25rem;
    }
  }
`;

const FormField = styled('div')`
  &.offsetBottom {
    margin-bottom: 0.3rem;
  }
`;

FormField.propTypes = {
  offsetBottom: bool,
};

const root = 'velo-mfa-challenge';
const TestIds = {
  ...VeloForm.testIds,
  CARD_FORM: `${root}-card-form`,
  HEADER: `${root}-header`,
  BODY: `${root}-body`,
  OTP_ENTRY: `${root}-otp-entry`,
  RESEND_CODE: `${root}-resend-code`,
  UNREGISTER_MFA: `${root}-unregister-mfa`,
  CONFIRM_DIALOG: `${root}-confirmation-dialog`,
};

MultiFactorChallenge.testIds = TestIds;

const getVerifyActionText = (submitting) =>
  submitting ? (
    <FormattedMessage defaultMessage="Verifying..." />
  ) : (
    <FormattedMessage defaultMessage="Verify" />
  );

/**
 * MVP-4625 - will cover copy changes and amend these
 */
const config = {
  [MFATypes.YUBIKEY]: {
    bodyCopy: (
      <FormattedMessage defaultMessage="Please insert and activate your YubiKey." />
    ),
    errorCopy: (
      <FormattedMessage defaultMessage="Failed to verify your YubiKey. Please try again." />
    ),
    getActionText: getVerifyActionText,
    renderSecondaryAction: (props) =>
      props.isLogin ? (
        <VeloTextButton
          padded
          offsetTop
          data-testid={TestIds.UNREGISTER_MFA}
          onClick={props.showConfirmationDialog}
        >
          <FormattedMessage defaultMessage="Lost your YubiKey? Click to unregister." />
        </VeloTextButton>
      ) : null,
    useFieldType: useYubikeyKeyCode,
  },
  [MFATypes.SMS]: {
    bodyCopy: (
      <FormattedMessage defaultMessage="Please enter the 6 digit code we sent to your phone." />
    ),
    errorCopy: (
      <FormattedMessage defaultMessage="The code you entered is invalid. Check you have entered the code correctly or click Resend and try again." />
    ),
    getActionText: getVerifyActionText,
    renderSecondaryAction: (props) => (
      <VeloTextButton
        padded
        offsetTop
        data-testid={TestIds.RESEND_CODE}
        disabled={props.disabled}
        onClick={props.resendCode}
      >
        <FormattedMessage defaultMessage="Not received a code? Click here to resend" />
      </VeloTextButton>
    ),
    useFieldType: useSMSCode,
  },
  [MFATypes.TOTP]: {
    bodyCopy: (
      <FormattedMessage defaultMessage="Please enter the 6 digit code shown in your authenticator app." />
    ),
    errorCopy: (
      <FormattedMessage defaultMessage="The code you entered is invalid. Check you have entered the code correctly and try again." />
    ),
    getActionText: getVerifyActionText,
    renderSecondaryAction: (props) =>
      props.isLogin ? (
        <VeloTextButton
          padded
          offsetTop
          data-testid={TestIds.UNREGISTER_MFA}
          onClick={props.showConfirmationDialog}
        >
          <FormattedMessage defaultMessage="Lost your device? Click to unregister." />
        </VeloTextButton>
      ) : null,
    useFieldType: useSMSCode,
  },
};

MultiFactorChallenge.propTypes = {
  /**
   * A function that is called when the form is submitted.
   */
  onSubmit: func.isRequired,
  /**
   * MFATypes string indicating the type of MFA challenge to present
   */
  mfaType: string,
  /**
   * Bool to determine loginMode or other
   */
  isLogin: bool,
  /**
   * Function to either resend/generate a code for MFA or unregister the
   * current registered MFA
   */
  secondaryAction: func,
};

MultiFactorChallenge.defaultProps = {
  isLogin: false,
};

MultiFactorChallenge.testIds = TestIds;

MultiFactorChallenge.mfaTypes = MFATypes;

function MultiFactorChallenge(props) {
  const { mfaType, isLogin, secondaryAction } = props;
  const [submitting, setSubmitting] = useState(false);
  const [loadingSecondaryAction, setLoadingSecondaryAction] = useState(false);
  const [showUnregisterMFAConfirmation, setShowUnregisterMFAConfirmation] =
    useState(false);
  const [error, setError] = useState(undefined);
  const [otpValue, setOtp] = useState('');
  const textFieldRef = useRef();

  /**
   * As YubiKey insertion is automated via YubiKey USB keyboard, auto focus the
   * input on mount.
   */
  useEffect(() => {
    if (mfaType === MFATypes.YUBIKEY) {
      textFieldRef.current.foundation.activateFocus();
      textFieldRef.current.foundation.adapter_.getNativeInput().focus();
    }
  }, [mfaType, textFieldRef]);

  /**
   * MVP-4625 - will cover copy changes and amend these
   */
  const {
    errorCopy,
    bodyCopy,
    renderSecondaryAction,
    getActionText,
    useFieldType,
  } = config[mfaType];
  const headerLabel = isLogin ? null : (
    <FormattedMessage defaultMessage="Prior to saving your new details, we need to confirm your identity." />
  );

  const formProps = {
    actionText: getActionText(submitting),
    overlay: submitting,
    onSubmit: (e) => {
      e.preventDefault();
      setSubmitting(true);
      setError(undefined);

      props.onSubmit(otpValue, (error) => {
        setError(error);
        setSubmitting(false);

        /**
         * As YubiKey insertion is automated via YubiKey USB keyboard, the
         * user will not be editing the input value manually
         */
        if (error && mfaType === MFATypes.YUBIKEY) {
          textFieldRef.current.foundation.setValue('');
          textFieldRef.current.foundation.adapter_.getNativeInput().focus();
          textFieldRef.current.foundation.activateFocus();
          setOtp('');
        }
      });
    },
    error,
    defaultErrorMessage: errorCopy,
  };

  const secondaryActionButton = renderSecondaryAction({
    isLogin,
    disabled: loadingSecondaryAction,
    resendCode: () => {
      setLoadingSecondaryAction(true);
      secondaryAction(() => setLoadingSecondaryAction(false));
    },
    showConfirmationDialog: () => {
      setLoadingSecondaryAction(true);
      setShowUnregisterMFAConfirmation(true);
    },
  });

  const formFieldType = useFieldType();
  const formField = (
    <FormField
      offsetBottom={secondaryActionButton === null && error !== undefined}
    >
      <VeloTextField
        {...formFieldType}
        data-testid={TestIds.OTP_ENTRY}
        required
        ref={textFieldRef}
        onChange={({ target: { value } }) => {
          setOtp(value);
        }}
      />
    </FormField>
  );

  return (
    <VeloForm data-testid={TestIds.CARD_FORM} {...formProps}>
      {headerLabel && (
        <VeloLogoPage.Text data-testid={TestIds.HEADER}>
          {headerLabel}
        </VeloLogoPage.Text>
      )}
      <VeloLogoPage.Text data-testid={TestIds.BODY}>
        {bodyCopy}
      </VeloLogoPage.Text>

      {formField}
      {secondaryActionButton}
      <Dialog
        open={showUnregisterMFAConfirmation}
        onClose={(evt) => {
          setShowUnregisterMFAConfirmation(false);

          evt.detail.action === 'accept'
            ? secondaryAction(() => setLoadingSecondaryAction(false))
            : setShowUnregisterMFAConfirmation(false);
        }}
        dialogType={ConfirmationDialog.dialogTypes.UnregisterMFAType}
        data-testid={TestIds.CONFIRM_DIALOG}
      />
    </VeloForm>
  );
}

export { MultiFactorChallenge };
