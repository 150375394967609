import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { bool, func, number, string } from 'prop-types';
import styled from 'astroturf/react';
import { VeloIconButton } from '../VeloIconButton';
import { VeloSkeleton } from '../VeloSkeleton';
import { PaginationMenu } from './PaginationMenu';
import { PageSizeMenu } from './PageSizeMenu';

const Container = styled('div')`
  @import '@material/elevation/mixins';
  @import 'velo-variables';

  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 3.75rem;

  @media (max-width: velo-breakpoint(XS)) {
    @include mdc-elevation(4);
    height: $mobile-fixed-bottom-pagination-height;
    justify-content: space-evenly;
    background-color: velo-color('token-color-system-page-background');
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
  }
`;

const IconSkeleton = styled(VeloSkeleton)`
  height: 24px;
  width: 24px;
  margin: 0.75rem;
`;

const PaginationSkeleton = styled(VeloSkeleton)`
  width: 8rem;
`;

Pagination.propTypes = {
  /** Custom CSS class(es). */
  className: string,
  /** The current page (indexed from 1.) */
  page: number,
  /** The total number of available pages. */
  totalPages: number,
  /** Called when the page is changed. */
  onPage: func,
  /** Render as a skeleton. */
  skeleton: bool,
  /** Disable the entire component. */
  disabled: bool,
};

/**
 * Pagination component.
 *
 * Renders controls allowing a user to navigate to the first, previous,
 * next or last pages. The current/total page is also displayed.
 */
function Pagination({
  className,
  page = 1,
  totalPages = 1,
  skeleton,
  disabled,
  onPage = () => {},
  onPageSize,
  pageSize,
  'data-testid': testId = 'velo-pagination',
}) {
  const intl = useIntl();
  const goto = useCallback(
    (newPage) => {
      if (newPage !== page) {
        onPage(newPage);
      }
    },
    [onPage, page]
  );

  return (
    <Container className={className} data-testid={testId}>
      {skeleton ? (
        <>
          <IconSkeleton data-testid="pagination-first-skeleton" />
          <IconSkeleton data-testid="pagination-prev-skeleton" />
          <PaginationSkeleton data-testid="pagination-page-skeleton" />
          <IconSkeleton data-testid="pagination-next-skeleton" />
          <IconSkeleton data-testid="pagination-last-skeleton" />
        </>
      ) : (
        <>
          {onPageSize && (
            <PageSizeMenu
              onPageSize={onPageSize}
              pageSize={pageSize}
              disabled={disabled}
            />
          )}

          <VeloIconButton
            icon="first_page"
            title={intl.formatMessage({
              defaultMessage: 'First page',
            })}
            disabled={disabled || page === 1}
            onClick={() => goto(1)}
            data-testid="pagination-first"
            ripple={false}
          />
          <VeloIconButton
            icon="chevron_left"
            title={intl.formatMessage({
              defaultMessage: 'Previous page',
            })}
            disabled={disabled || page === 1}
            onClick={() => goto(page - 1)}
            data-testid="pagination-prev"
            ripple={false}
          />
          <PaginationMenu
            disabled={disabled}
            page={page}
            totalPages={totalPages}
            onPage={goto}
          />
          <VeloIconButton
            icon="chevron_right"
            title={intl.formatMessage({
              defaultMessage: 'Next page',
            })}
            disabled={disabled || page === totalPages}
            onClick={() => goto(page + 1)}
            data-testid="pagination-next"
            ripple={false}
          />
          <VeloIconButton
            icon="last_page"
            title={intl.formatMessage({
              defaultMessage: 'Last page',
            })}
            disabled={disabled || page === totalPages}
            onClick={() => goto(totalPages)}
            data-testid="pagination-last"
            ripple={false}
          />
        </>
      )}
    </Container>
  );
}

export { Pagination };
