import { useCallback } from 'react';
import { useAppContext } from '../../context/VeloAppContext';
import { useInactivityTimer } from './useInactivityTimer';

const defaultSessionInactivityThresholdMinutes = 30;

function useSessionInactivityTimer(
  sessionInactivityThresholdMinutes = defaultSessionInactivityThresholdMinutes
) {
  const { logout } = useAppContext();

  const onInactivity = useCallback(() => {
    logout();
  }, [logout]);

  return useInactivityTimer({
    inactivityThresholdMinutes: sessionInactivityThresholdMinutes,
    onInactivity,
  });
}

export { useSessionInactivityTimer };
