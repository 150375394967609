import React from 'react';
import { FormattedMessage } from 'react-intl';

const Roles = {
  Payee: 'payee',
  PayorMaster: 'payor.master_admin',
  PayorSupport: 'payor.support',
  PayorAdmin: 'payor.admin',
  BOPAdmin: 'backoffice.admin',
  PayeeAdmin: 'velo.payee.admin',
  PayeeSupport: 'velo.payee.support',
};

const Labels = (intl) => ({
  [Roles.PayorMaster]: intl.formatMessage({ defaultMessage: 'Master Admin' }),
  [Roles.PayorAdmin]: intl.formatMessage({ defaultMessage: 'Admin' }),
  [Roles.PayorSupport]: intl.formatMessage({ defaultMessage: 'Support' }),
  [Roles.BOPAdmin]: intl.formatMessage({ defaultMessage: 'Admin' }),
  [Roles.PayeeAdmin]: intl.formatMessage({ defaultMessage: 'Admin' }),
  [Roles.PayeeSupport]: intl.formatMessage({ defaultMessage: 'Support' }),
});

const IntlLabels = {
  [Roles.PayorMaster]: <FormattedMessage defaultMessage="Master Admin" />,
  [Roles.PayorAdmin]: <FormattedMessage defaultMessage="Admin" />,
  [Roles.PayorSupport]: <FormattedMessage defaultMessage="Support" />,
  [Roles.BOPAdmin]: <FormattedMessage defaultMessage="Admin" />,
  [Roles.PayeeAdmin]: <FormattedMessage defaultMessage="Admin" />,
  [Roles.PayeeSupport]: <FormattedMessage defaultMessage="Support" />,
};

const Hierarchy = [
  {
    role: Roles.BOPAdmin,
    canEditSameRole: true,
  },
  {
    role: Roles.PayorMaster,
    canEditSameRole: false,
  },
  {
    role: Roles.PayorAdmin,
    canEditSameRole: false,
  },
  {
    role: Roles.PayorSupport,
    canEditSameRole: false,
  },
  {
    role: Roles.PayeeAdmin,
    canEditSameRole: true,
  },
  {
    role: Roles.PayeeSupport,
    canEditSameRole: false,
  },
];

const userRoleOption = (role, intl) => ({
  value: role,
  label: Labels(intl)[role],
});

const subordinates = (role) => {
  const rolesHierarchy = Hierarchy.map(({ role }) => role);
  const index = rolesHierarchy.indexOf(role);
  return role ? rolesHierarchy.slice(index + 1) : [];
};

const canEditOtherRole = (loggedInRole, role) => {
  const hierarchyItem =
    Hierarchy.find((hierarchyItem) => hierarchyItem.role === loggedInRole) ||
    {};
  const isSameRole = loggedInRole === role;
  const subordinateRoles = subordinates(loggedInRole);

  return (
    (hierarchyItem.canEditSameRole && isSameRole) ||
    subordinateRoles.includes(role)
  );
};

export const UserRoles = {
  // Roles
  ...Roles,
  // Helpers
  isPayee: (role) =>
    role === Roles.Payee ||
    role === Roles.PayeeSupport ||
    role === Roles.PayeeAdmin,
  isSupport: (role) => role === Roles.PayorSupport,
  isPayeeSupport: (role) => role === Roles.PayeeSupport,
  isPayorAdmin: (role) =>
    role === Roles.PayorAdmin || role === Roles.PayorMaster,
  isBackOffice: (role) => role === Roles.BOPAdmin,
  // Labels
  Labels,
  // Intl labels
  IntlLabels,
  // All options
  allRoleOptions: (intl) => [
    userRoleOption(Roles.PayorMaster, intl),
    userRoleOption(Roles.PayorAdmin, intl),
    userRoleOption(Roles.PayorSupport, intl),
  ],
  option: userRoleOption,
  Hierarchy,
  subordinates,
  canEditOtherRole,
};
