import { useState } from 'react';

export function useSubmitConfirmation(onSubmit, predicate, mapProps) {
  // map once to get the default state
  const defaultDialogProps = mapProps({ open: false });
  const [dialogProps, setDialogProps] = useState(defaultDialogProps);
  function trappedOnSubmit(body, cb) {
    const submit = () => onSubmit(body, cb);
    if (predicate(body)) {
      setDialogProps(
        mapProps({
          open: true,
          onClose({ detail }) {
            setDialogProps(defaultDialogProps);
            if (detail.action === 'accept') {
              submit();
            } else {
              cb(undefined, undefined);
            }
          },
        })
      );
    } else {
      submit();
    }
  }
  return [dialogProps, trappedOnSubmit];
}
