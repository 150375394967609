import { useState, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { getPayorId, getPayorLabels, getAvailableCurrencies } from './helpers';
import { PayoutTypes } from 'velo-react-components';
import {
  getDefaultTransmissionFilterOption,
  transmissionPayoutOptions,
} from 'velo-react-components/src/utils';
import { isCSVFileValid } from '../csv';

const defaultProps = {
  fromPayors: [],
  toPayors: [],
  sourceAccountNameList: [],
  defaultCurrency: 'USD',
  defaultTransmissionType: { label: '', value: '' },
  currencyLabelList: ['USD'],
  selectedCreationType: PayoutTypes.CREATE.GROUP,
  remoteSystemIds: [],
};

const getPropsFromData = (
  {
    payor,
    sourceAccounts,
    fromHierarchy,
    toHierarchy,
    currencies,
    selectedSourceAccountName,
    selectedCreationType,
    error,
  },
  intl
) => {
  if (!payor) {
    return defaultProps;
  }
  const sourceAccountsDetails = sourceAccounts.map(
    ({ name, type, currency, transmissionTypes }) => ({
      name,
      type,
      currency,
      transmissionTypes,
    })
  );
  const defaultSourceAccountName = sourceAccountsDetails[0]
    ? sourceAccountsDetails[0].name
    : '';
  const sourceAccountName = selectedSourceAccountName
    ? selectedSourceAccountName
    : defaultSourceAccountName;

  const currencyProps = getAvailableCurrencies(
    sourceAccountName,
    sourceAccountsDetails,
    currencies
  );

  // provide a good default so filters can have a happy fallback - this data is sadly optional
  defaultProps.defaultTransmissionType =
    getDefaultTransmissionFilterOption(intl);

  // transmission types map - sourceAccountNameList and this data could be combined in the future to be more efficient
  let transmissionTypesMap = sourceAccountsDetails.reduce((acc, current) => {
    const { name, transmissionTypes } = current;

    // convert transmission types into key value pair due to API sending this differently now
    const tTypes = {};
    transmissionTypes?.forEach((key) => {
      tTypes[key] = true;
    });

    acc[name] = transmissionPayoutOptions(tTypes, intl);

    return acc;
  }, {});

  return {
    ...defaultProps,
    transmissionTypesMap,
    sourceAccountNameList: sourceAccountsDetails.map(({ name }) => name),
    ...currencyProps,
    ...{ fromPayors: fromHierarchy, toPayors: toHierarchy },
    error,
    selectedCreationType,
    remoteSystemIds: payor?.remoteSystemIds,
  };
};

/**
 * Quite a complicated Hook - but it is basically a state management hook for
 * components with the Create Payout route.
 * Handles state specifc handlers and calls to the Prensenter API's
 * Removing the leakage of implementation to the Components
 *
 *  Notes on HIERARCHY props use :
 *  fromHierachy : this is the hierachy of the operating Payor - the available FROM selections
 *  toPayorHierarchy:  the hierarchy of selected FROM Payor determines which Payors it is possible TO pay.
 *
 * @param {*} MemoizedProps Prop provided by Route
 * @param {*} load  load and reload (on selective option selection) api data
 * @param {*} submitPayout wrapper for both CSV and Form API submission
 * @param {*} searchPayeesByName getter for payor specific payee searches
 */
function useCreatePayoutHook(
  { payorId, payoutType, currencies },
  load,
  submitPayout,
  searchPayeesByName
) {
  const intl = useIntl();
  const [payoutFromPayorId, setFromPayorId] = useState(payorId);
  const [payoutToPayorId, setToPayorId] = useState(payorId);

  // Store the API presenter data - to be mapped to usable props
  const [data, setData] = useState({
    payorId,
    currencies,
  });

  /**
   * Track the selected Payor Ids
   * Note: not component props so externally track from main props object
   */
  const { fromPayors, toPayors, ...props } = getPropsFromData(data, intl);

  const onPayorFromChange = (name) =>
    setFromPayorId(getPayorId(fromPayors, name));

  const onPayorToChange = (name) => {
    // To lookup the payorId based on the name, use either list of results
    setToPayorId(getPayorId([...toPayors, ...fromPayors], name));
  };

  /**
   * Switch SourceAccounts - persisting props
   */
  const onSourceAccountSelected = (name) => {
    setData({ ...data, selectedSourceAccountName: name });
  };

  /**
   * Switch Types - persisting props, clearing error
   */
  const onChange = (type) => {
    setData({ ...data, selectedCreationType: type, error: undefined });
  };

  /**
   * Map Payor Hierachy data to usubale LabelList props
   */
  const { fromOptions, toOptions } = getPayorLabels(
    fromPayors,
    toPayors,
    payoutFromPayorId
  );

  /**
   * Submit either Type of Payout (CSV/Form) - Based on selected Create Type and Provided Params
   */
  const onSubmit = (args) => {
    const submissionData = {
      ...args,
      payoutFromPayorId,
      payoutToPayorId,
      type: props.selectedCreationType,
    };
    if (props.selectedCreationType === PayoutTypes.CREATE.GROUP) {
      // Extract the paymentsFiles for reading - we dont submit all files
      const { paymentsFiles, ...groupSubmissionData } = submissionData;

      // Extra Step in CSV is 'pre-flight' read and validate of the file
      if (paymentsFiles.length === 1) {
        const file = paymentsFiles[0];

        // TODO: Localisation of these UI error strings
        if (isCSVFileValid(file)) {
          processSubmission({
            ...groupSubmissionData,
            file,
          });
        } else {
          setData({
            ...data,
            error:
              'The file you are trying to upload is invalid. Only a valid .csv can be accepted.',
          });
        }
      } else {
        // Catch MultiFile submission
        setData({
          ...data,
          error:
            'Multiple files detected. Please upload 1 payout .CSV file at a time.',
        });
      }
    } else {
      // Submit the Single Payout
      processSubmission(submissionData);
    }
  };

  /**
   * Single Submission call with Error handling
   */
  const processSubmission = (submissionData) => {
    submitPayout(submissionData, (error) => {
      setData({
        ...data,
        error: error,
      });
    });
  };

  /**
   * Map function call to the relevant selected Payor ID
   */
  const fetchPayeeResults = (name, cb) =>
    searchPayeesByName(name, payoutToPayorId, cb);

  useMemo(
    () => {
      load(
        payorId,
        payoutFromPayorId,
        payoutType,
        (
          payor,
          sourceAccounts,
          fromHierarchy,
          toHierarchy,
          transmissionTypes
        ) => {
          setData({
            payor,
            sourceAccounts,
            toHierarchy,
            fromHierarchy,
            currencies,
            payorId,
            transmissionTypes,
            ...props,
          });
        }
      );
    },
    // We re-use this to load the data but we want to persist the selected props
    // so we only trigger on a subset of dependencies  - only omitting the 'props' value
    // payoutType, payorId, payoutFromPayorId are all props that require a refresh of API data
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [payoutType, payorId, payoutFromPayorId, load, setData, currencies]
  );

  return {
    ...props,
    payorId,
    payoutType,
    payoutFromPayorId,
    payoutToPayorId,
    fromOptions,
    toOptions,
    fetchPayeeResults,
    onPayorFromChange,
    onPayorToChange,
    onSourceAccountSelected,
    onSubmit,
    onChange,
  };
}

export { useCreatePayoutHook };
