import { useMemo } from 'react';
import { useAPIContext } from '../../context';
import { mapPromiseResponseWithCallback } from '../helpers';

export function useRegisterUserSmsAPI() {
  const api = useAPIContext();

  const registerUserSms = useMemo(
    () => mapPromiseResponseWithCallback(api.registerUserSms),
    [api.registerUserSms]
  );

  return registerUserSms;
}
