import React from 'react';
import { node } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styled from 'astroturf/react';
import { VeloIcon } from '../VeloIcon';
import { VeloTypography } from '../VeloTypography';

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 100%;
  align-items: center;
`;

const Icon = styled(VeloIcon)`
  @import 'velo-variables';
  @import 'velo-mixins';

  @include icon-size(4rem);

  color: velo-color('token-color-system-error-default');
`;

VeloModalSheetError.propTypes = {
  /** The error message to render. */
  children: node,
};

/**
 * Used to render errors in a VeloModalSheetBody.
 */
function VeloModalSheetError({ children }) {
  return (
    <Container data-testid="velo-modal-sheet-error">
      <Icon icon="error" />

      <VeloTypography use="secondaryHeader" tag="h1">
        <FormattedMessage defaultMessage="An error occurred" />
      </VeloTypography>

      <VeloTypography use="bodyText" tag="div">
        {children}
      </VeloTypography>
    </Container>
  );
}

export { VeloModalSheetError };
