import { MFATypes } from 'velo-react-components';
import querystring from 'query-string';
import { formatError, selectIdFromLocation } from '../../selectors';
import { SendError } from 'velo-api/src/send';
import { TokenTypes } from './TokenTypes';

const mapTokenError = (tokenErrorText, error) => {
  if (error && SendError.isNotFoundError(error)) {
    return {
      errors: [{ errorMessage: tokenErrorText }],
    };
  }

  return error;
};

const mapTokenErrorCallbackErrorArg =
  (tokenErrorText, cb) =>
  (error, ...rest) =>
    cb(formatError(mapTokenError(tokenErrorText, error)), ...rest);

const selectIsTokenTypeValid = (tokenPayload = {}) =>
  !!TokenTypes[tokenPayload.tokenType];

export function CheckTokenPresenter(
  { token },
  wireframe,
  entity,
  { userTokenInvalidNotification }
) {
  return [
    //loader
    (cb) =>
      entity.checkTokenIsValid(token, (error, data) => {
        const tokenTypeIsValid = selectIsTokenTypeValid(data);
        if (error || !tokenTypeIsValid) {
          wireframe.sendNote(userTokenInvalidNotification);
          wireframe.navigateToLogin.redirect();
        }

        cb(error, data);
      }),
  ];
}

const selectOTPNextStepFromWireframe = (wireframe) => {
  return wireframe.navigateToInvite.matchPath()
    ? wireframe.navigateToInviteCreatePassword
    : wireframe.navigateToMFARegistrationRegisterDevice;
};

export function OTPValidationPresenter(
  { token },
  wireframe,
  entity,
  { userTokenInvalidNotification, resendSmsNotification }
) {
  const { message: tokenErrorText } = userTokenInvalidNotification;

  return [
    // props
    {
      updateMfa: (body, cb) => {
        return entity.sendOTP(
          token,
          body,
          mapTokenErrorCallbackErrorArg(tokenErrorText, (error, result) => {
            if (error) {
              cb(error);
            } else {
              wireframe.sendNote(resendSmsNotification);
              cb(error, result);
            }
          })
        );
      },
      validateOTP: (body, cb) => {
        return entity.validateOTP(
          token,
          body,
          mapTokenErrorCallbackErrorArg(tokenErrorText, (error, result) => {
            if (error) {
              cb(error);
            } else {
              const navigateToNextStep =
                selectOTPNextStepFromWireframe(wireframe);
              const otpToken = selectIdFromLocation(result.location);
              navigateToNextStep.redirect({ otpToken });
              wireframe.closeCookieBanner();
              cb();
            }
          })
        );
      },
    },
  ];
}

const selectPasswordNavigateToNextStepFromMFAType = (mfaType, wireframe) =>
  mfaType === MFATypes.YUBIKEY
    ? wireframe.navigateToInviteRegisterYubikey
    : wireframe.navigateToInviteRegisterTOTP;

export function PasswordValidationPresenter(
  { otpToken },
  wireframe,
  entity,
  { userTokenInvalidNotification, mfaType }
) {
  const { message: tokenErrorText } = userTokenInvalidNotification;

  return [
    {
      validatePassword: (body, cb) => {
        return entity.validatePasswordWithToken(
          otpToken,
          body,
          mapTokenErrorCallbackErrorArg(tokenErrorText, cb)
        );
      },
      onSubmit: (body, cb) => {
        return entity.updatePassword(
          otpToken,
          body,
          mapTokenErrorCallbackErrorArg(tokenErrorText, (error) => {
            if (error) {
              cb(error);
            } else {
              const navigateToNextStep =
                selectPasswordNavigateToNextStepFromMFAType(mfaType, wireframe);
              navigateToNextStep.redirect({ otpToken });
              cb();
            }
          })
        );
      },
    },
  ];
}

export function TwoFactorPasswordValidationPresenter(
  { otpToken },
  wireframe,
  entity,
  { userTokenInvalidNotification, storeTokenPair }
) {
  const { message: tokenErrorText } = userTokenInvalidNotification;

  return [
    {
      validatePassword: (body, cb) => {
        return entity.validatePasswordWithToken(
          otpToken,
          body,
          mapTokenErrorCallbackErrorArg(tokenErrorText, cb)
        );
      },
      /**
       * In the 2 step Payor Support user SMS flow the user is logged after
       * step 2.
       */
      onSubmit: (body, cb) => {
        return entity.updatePassword(
          otpToken,
          body,
          mapTokenErrorCallbackErrorArg(tokenErrorText, (error, payload) => {
            if (error) {
              cb(error);
            } else {
              storeTokenPair(payload);
              if (wireframe.navigateToPayoutsList) {
                wireframe.navigateToPayoutsList.redirect();
              } else {
                wireframe.navigateToPaymentsList.redirect();
              }

              cb();
            }
          })
        );
      },
    },
  ];
}

const selectMFANextStepFromWireframe = (wireframe) => {
  return wireframe.navigateToInviteRegisterYubikey.matchPath() ||
    wireframe.navigateToInviteRegisterTOTP.matchPath()
    ? wireframe.navigateToPayoutsList
    : wireframe.navigateToLogin;
};

export function MFAValidationPresenter(
  { otpToken },
  wireframe,
  entity,
  {
    storeTokenPair = () => {},
    userTokenInvalidNotification,
    completeNotification,
  }
) {
  const { message: tokenErrorText } = userTokenInvalidNotification;

  return [
    {
      onSubmit: (otp, cb) => {
        return entity.validateMFA(
          otpToken,
          { otp },
          mapTokenErrorCallbackErrorArg(tokenErrorText, (error, payload) => {
            if (error) {
              cb(error);
            } else {
              const navigateToNextStep =
                selectMFANextStepFromWireframe(wireframe);
              if (completeNotification) {
                wireframe.sendNote(completeNotification);
              }
              storeTokenPair(payload);
              navigateToNextStep.redirect();
              cb();
            }
          })
        );
      },
    },
  ];
}

export function MFARegistrationPresenter(
  { otpToken },
  wireframe,
  entity,
  { userTokenInvalidNotification, host, QRCodeSize }
) {
  return [
    //loader
    (cb) =>
      entity.registerMFA(
        otpToken,
        { mfaType: MFATypes.TOTP },
        (error, data) => {
          if (error) {
            wireframe.sendNote(userTokenInvalidNotification);
            wireframe.navigateToLogin.redirect();
          }

          cb(error, data);
        }
      ),
    {
      qrCodeUrl: `${host}/v1/tokens/${otpToken}/mfa/qrcode?${querystring.stringify(
        {
          width: QRCodeSize,
          height: QRCodeSize,
        }
      )}`,
    },
  ];
}
