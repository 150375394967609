export const fetchRemote = (url, remoteName) =>
  new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = url;

    script.onerror = (err) => {
      reject(new Error(`Failed to fetch remote: ${remoteName}`));
    };

    script.onload = () => {
      const proxy = {
        get: (request) => window[remoteName].get(request),
        init: (arg) => {
          try {
            return window[remoteName].init(arg);
          } catch (e) {
            console.error(`Failed to initialize remote: ${remoteName}`);
            reject(e);
          }
        },
      };
      resolve(proxy);
    };

    document.head.appendChild(script);
  });
