import React, { createContext } from 'react';
import { createPortal } from 'react-dom';
import styles from './VeloPopupContent.module.scss';

const rootClass = 'velo-popup-content-context';

const Context = createContext({
  ref: null,
});

class VeloPopupContentProvider extends React.Component {
  ref = React.createRef();

  render() {
    const { children } = this.props;
    return (
      <Context.Provider value={{ ref: this.ref }}>
        {children}
        <div
          data-testid={rootClass}
          ref={this.ref}
          className="ds-typography-root"
        />
      </Context.Provider>
    );
  }
}

class Consumer extends React.Component {
  static contextType = Context;

  render() {
    const { children } = this.props;

    // switch for popup content rendering
    if (this.context.ref && this.context.ref.current) {
      return createPortal(
        React.cloneElement(children, {
          style: {
            zIndex: styles.zIndex,
            ...children.props.style,
          },
        }),
        this.context.ref.current
      );
    }
    return children;
  }
}

const VeloPopupContentContext = {
  Consumer,
  Provider: VeloPopupContentProvider,
  rootClass,
};

export { VeloPopupContentContext };
