import { formatCallbackErrorArg } from '../../selectors';

export function PayoutPaymentPresenter(
  wireFrame,
  entity,
  { payoutId, paymentId },
  { withdrawFailure, withdrawSuccess }
) {
  function navigateToReceipt() {
    wireFrame.navigateToPayoutPaymentReceipt({ payoutId, paymentId });
  }
  function onClose() {
    wireFrame.goBack();
  }
  // Custom Withdraw action - overrides PaymentDetailContainer default handling
  function onWithdraw(paymentId, reason) {
    entity.withdrawPayment(
      paymentId,
      {
        reason,
      },
      formatCallbackErrorArg((error, result) => {
        if (result) {
          // Redirect processing route to reload the payout with updated data
          // This also facilitates the redirect if the payout is WITHDRAWN
          // caused by the last valid payment being withdrawn and the Payout is empty
          wireFrame.navigateToPayoutProcessing.redirect({ payoutId });
          wireFrame.sendNote(withdrawSuccess);
        } else {
          wireFrame.sendNote({
            ...withdrawFailure,
            message: error,
          });
        }
      })
    );
  }

  return { onClose, onWithdraw, navigateToReceipt };
}
