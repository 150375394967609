import { formatCallbackErrorArg, forkResult } from '../../selectors';
import { payorRefsSelectors } from 'velo-data';

const getIsPayeeDeletable = ({ onboardedStatus }) =>
  onboardedStatus === 'INVITED' || onboardedStatus === 'REGISTERED';

const getIsPayeeEditable = ({ onboardedStatus }) =>
  onboardedStatus === 'INVITED';

function PayeeViewRoutePresenter(
  wireframe,
  entity,
  { payorId, payeeId },
  { payeeCountryRules, notifications, isPayorAdmin, intl }
) {
  const {
    deletePayeeSuccess,
    deletePayeeFailure,
    resendInviteSuccess,
    resendInviteFailure,
  } = notifications;

  const onClose = () => wireframe.navigateToPayeesList();
  const onRefresh = () => wireframe.navigateToPayeeRefresh();
  const onEditPayee = () => wireframe.navigateToPayeeEdit({ payeeId });
  const onViewPayments = () =>
    wireframe.navigateToPaymentsList({}, { payeeId });

  const load = (cb) => {
    if (!payeeId) {
      return;
    }
    entity.loadPayee(
      payeeId,
      formatCallbackErrorArg((error, result) => {
        if (error) {
          cb(error);
        } else {
          const payorRef = payorRefsSelectors.getPayorRef(
            payorId,
            result.payorRefs
          );
          cb(undefined, { payee: { ...result, ...payorRef } });
        }
      })
    );
  };

  const onDeletePayee = () => {
    entity.deletePayee(
      payeeId,
      formatCallbackErrorArg((error) => {
        if (error) {
          wireframe.sendNote({
            ...deletePayeeFailure,
            message: error,
          });
        } else {
          onRefresh();
          wireframe.sendNote(deletePayeeSuccess);
        }
      })
    );
  };

  const onResendInvite = ({ email }) => {
    entity.resendPayeeInvite(
      payeeId,
      { payorId },
      formatCallbackErrorArg((error) => {
        if (error) {
          wireframe.sendNote({
            ...resendInviteFailure,
            message: intl.formatMessage(resendInviteFailure.message, {
              email: email,
            }),
          });
        } else {
          onRefresh();
          wireframe.sendNote({
            ...resendInviteSuccess,
            message: intl.formatMessage(resendInviteSuccess.message, {
              email: email,
            }),
          });
        }
      })
    );
  };

  const onEditRemoteId = () => {
    wireframe.navigateToPayeeEditRemoteId({ payeeId });
  };

  const onEditPaymentChannels = () => {
    wireframe.navigateToPayeePaymentMethods({ payeeId });
  };

  const getProps = (result) => {
    return forkResult(
      {
        value: (data, props) => {
          const isManagedPayee = data.payee.managedByPayorId === payorId;
          const isEditable = getIsPayeeEditable(data.payee) || isManagedPayee;
          const isDeletable = getIsPayeeDeletable(data.payee) || isManagedPayee;
          return {
            onView: onViewPayments,
            onDelete: isPayorAdmin && isDeletable ? onDeletePayee : undefined,
            onEdit: isPayorAdmin && isEditable ? onEditPayee : undefined,
            onResendInvite:
              isEditable && !isManagedPayee
                ? () => onResendInvite(data.payee)
                : undefined,
            onEditRemoteId: isPayorAdmin ? onEditRemoteId : undefined,
            onEditPaymentChannels:
              isPayorAdmin && isManagedPayee
                ? onEditPaymentChannels
                : undefined,
            payeeCountryRules,
            ...props,
          };
        },
        error: (error, props) => {
          return {
            error,
            ...props,
          };
        },
      },
      result,
      {
        onClose,
      }
    );
  };

  return [load, getProps];
}

export { PayeeViewRoutePresenter };
