import { useState, useCallback, useEffect } from 'react';
import { formatCallbackErrorArg } from '../selectors';

const defaultState = { error: undefined, result: undefined };

function useCallbackAsResultState(initialState = defaultState) {
  const [result, setState] = useState(initialState);
  const cb = useCallback(
    (...args) => {
      return formatCallbackErrorArg((error, result, extra = {}) => {
        setState({ ...extra, result, error });
      })(...args);
    },
    [setState]
  );
  return [result, cb, setState];
}

useCallbackAsResultState.defaultState = defaultState;

function useCallbackFnAsResultState(
  callbackFn,
  clearOnEffect = true,
  initialState
) {
  const [result, callback, setState] = useCallbackAsResultState(initialState);
  useEffect(() => {
    clearOnEffect && callback();
    return callbackFn(callback);
  }, [callbackFn, callback, clearOnEffect]);
  return [result, callback, setState];
}

useCallbackFnAsResultState.defaultState = defaultState;

export { useCallbackAsResultState, useCallbackFnAsResultState };
