/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import styled from 'astroturf/react';
import { bool, func, node, string } from 'prop-types';
import { VeloTypography } from '../VeloTypography';

const Container = styled('div')`
  display: flex;

  &.inline {
    display: inline-flex;
  }
`;

Container.propTypes = {
  inline: bool,
};

const TouchableArea = styled('div')`
  @import 'velo-variables';

  cursor: pointer;

  :global(.ds-icon) {
    color: velo-color('token-color-text-link-default');
  }

  &:focus {
    outline: none;
  }

  &.padded {
    margin: 0.2rem 0;
    padding: 0.8rem 0;
    display: flex;
    align-items: center;
  }

  &.offsetTop {
    margin-top: -0.8rem;
    padding: 0.8rem 0;
    margin-bottom: 0.2rem;
  }
`;

TouchableArea.propTypes = {
  padded: bool,
  offsetTop: bool,
};

VeloTextButton.propTypes = {
  /**
   * The text to render inside the button
   */
  children: node.isRequired,
  /**
   * A class name can be used to override styles
   */
  className: string,
  /**
   * An optional icon to render inside the button
   */
  icon: node,
  /**
   * A flag to apply button padding
   */
  padded: bool,
  /**
   * A flag to apply an margin/offset to the top of the button
   */
  offsetTop: bool,
  /**
   * A flag to disable interactivity
   */
  disabled: bool,
  /**
   * An function prop for handling click events
   */
  onClick: func,
  /**
   * A flag to make the button an inline element
   */
  inline: bool,
  /**
   * A typography setting which is passed to the `VeloTypograpy` component
   */
  use: string,
  /**
   * Used to override the default tag for the text button.
   */
  tag: string,
};

function VeloTextButton({
  children,
  className,
  icon,
  padded,
  offsetTop,
  disabled,
  onClick,
  inline,
  use = 'linkText',
  tag = 'div',
  ...props
}) {
  return (
    <Container inline={inline} className={className}>
      <TouchableArea
        as={tag}
        role="button"
        tabIndex={0}
        padded={padded}
        offsetTop={offsetTop}
        className={className}
        {...props}
        onClick={!disabled ? onClick : undefined}
      >
        {icon}
        <VeloTypography use={use}>{children}</VeloTypography>
      </TouchableArea>
    </Container>
  );
}

export { VeloTextButton };
