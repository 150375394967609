import React, { useState } from 'react';
import { bool, func, object } from 'prop-types';
import { VeloPropTypes } from '../../VeloPropTypes';
import { VeloModalSheet } from '../../VeloModalSheet';
import { PaymentWithdrawDialog } from '../../PaymentWithdrawDialog';
import { ConfirmationDialog } from '../../ConfirmationDialog';

PaymentViewContainer.propTypes = {
  /** Used to show/hide the component. */
  open: bool.isRequired,
  /** The data to render. */
  data: VeloPropTypes.result(object),
  /** Called when the view is closed/ */
  onClose: func.isRequired,
  /** Called when relaying a preference change */
  onRecordPreference: func,
  /** Called when confirming a payment */
  onConfirm: func,
  /** Flag to disable the payment confirmation dialog. */
  stopPaymentConfirmationDialog: bool,
  /** Called when rendering the contents. */
  render: func.isRequired,
};

/**
 * Generic Payment View container.
 * Used for Payee and Payor Payments.
 */
function PaymentViewContainer({
  open,
  onClose,
  onWithdraw,
  onConfirm,
  onRecordPreference,
  stopPaymentConfirmationDialog,
  render,
  data,
  ...other
}) {
  const [openDialog, setOpenDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const includeWithdraw = data && data.result && onWithdraw;
  const dialogProps = {
    dialogType: ConfirmationDialog.dialogTypes.ConfirmPaymentArrived,
    onClose: ({ detail }) => {
      onRecordPreference(detail, data);
      setOpenConfirmDialog(false);
    },
    recordPreference: true,
  };

  return (
    <>
      <VeloModalSheet open={open} onClose={onClose}>
        {render({
          onClose,
          onWithdraw: includeWithdraw ? () => setOpenDialog(true) : undefined,
          onConfirm: () =>
            stopPaymentConfirmationDialog
              ? onConfirm(data)
              : setOpenConfirmDialog(true),
          ...data,
          ...other,
        })}
      </VeloModalSheet>
      <ConfirmationDialog open={openConfirmDialog} {...dialogProps} />
      {includeWithdraw && (
        <PaymentWithdrawDialog
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          onWithdraw={onWithdraw}
          {...data.result}
        />
      )}
    </>
  );
}

export { PaymentViewContainer };
