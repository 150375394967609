import React from 'react';
import { node } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Content } from '../Content';
import { PageHeader } from '../PageHeader';

PaymentsPage.propTypes = {
  /** The list of Payments. */
  list: node.isRequired,
};

/**
 * The main Payee Payments page.
 * Renders a heading and a list of Payments.
 */
function PaymentsPage({ list }) {
  return (
    <Content
      header={
        <PageHeader title={<FormattedMessage defaultMessage="Payments" />} />
      }
    >
      {list}
    </Content>
  );
}

export { PaymentsPage };
