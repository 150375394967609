import { string, arrayOf, number, func, node, shape } from 'prop-types';
import { VeloPropTypes } from '../VeloPropTypes';

/**
 * Prop types for Payments List variants.
 */
const propTypes = {
  /** Custom CSS class(es). */
  className: string,
  /** The number of items per-page. Use when rendering loading state. */
  itemsPerPage: number,
  /** The data to render. */
  data: VeloPropTypes.result(
    arrayOf(
      shape({
        /** The payment ID. */
        paymentId: string.isRequired,
        /** The date/time the payment was submitted. */
        submittedDateTime: string.isRequired,
      })
    )
  ),
  /** Called when a payment is clicked. */
  onClick: func,
  /** Used to render the pagination component. */
  footer: node,
  /**
   * Called when the sort options for a column are required.
   * Passed the column index. The function needs to return
   *  1: sort ascending
   * -1: sort descending
   *  0: sortable but not selected
   */
  sortOptions: func,
  /** Called when clicking a sortable column header. */
  onSort: func,
  /** Used to render a filter component above the list. */
  filter: node,
};

export default propTypes;
