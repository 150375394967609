import React, { useState, forwardRef } from 'react';
import { bool } from 'prop-types';
import styled from 'astroturf/react';
import { FormattedMessage } from 'react-intl';
import {
  Dialog,
  DialogActions,
  DialogButton,
  DialogContent,
  DialogTitle,
} from '@rmwc/dialog';
import { VeloPopupContentContext } from '../VeloPopupContentContext';
import { useDialogManager } from '../hooks/useDialogManager';
import { VeloDialogStates } from './VeloDialogStates';
import { VeloCheckbox } from '../VeloCheckbox';
import '@material/dialog/dist/mdc.dialog.css';
import '@material/button/dist/mdc.button.css';

function createChangeHandler(disableFocus, onStateChange) {
  return (state) => {
    if (state === VeloDialogStates.OPENED) {
      /**
       * indirectly triggers 'layout' as recommended
       * @see https://material.io/develop/web/components/dialogs/#javascript-instantiation
       */
      window.dispatchEvent(new Event('resize'));
      if (disableFocus) {
        /**
         * Un-sets the default focus set by the Dialog, which is delayed
         * @see https://github.com/davidtheclark/focus-trap/blob/master/index.js#L145
         */
        setTimeout(() => {
          document.activeElement.blur();
        }, 0);
      }
    }
    onStateChange(state);
  };
}

VeloDialog.propTypes = {
  /**
   * Determines if the default behaviour of selecting the first tab-index is
   * disabled or not.
   */
  disableTrapFocus: bool,
};

VeloDialog.defaultProps = {
  disableTrapFocus: false,
};

VeloDialog.Actions = DialogActions;

VeloDialog.Button = styled(DialogButton)`
  font-family: var(--ds-typography-font-family);
`;

VeloDialog.Content = styled(DialogContent)`
  @import 'velo-variables';

  font-family: var(--ds-typography-font-family);
  color: velo-color('token-color-text-default') !important;
`;

VeloDialog.Title = styled(DialogTitle)`
  @import 'velo-variables';

  font-family: var(--ds-typography-font-family);
  color: velo-color('token-color-text-heading') !important;
`;

const MDCDialog = styled(Dialog)`
  @import 'velo-variables';

  z-index: $velo-over-all-default;
`;

const DialogWithRef = forwardRef((props, ref) => (
  <MDCDialog ref={ref} {...props} />
));

/**
 * Wrapper for the RMWC `Dialog` component with support for
 * disabling focus trapping and to only render if mounted.
 */
function VeloDialog({
  children,
  disableTrapFocus,
  onStateChange,
  foundationConfig,
  ...props
}) {
  const [mounted, onStateChangeHandler, ref] = useDialogManager(
    props.open,
    onStateChange,
    foundationConfig
  );
  return mounted ? (
    <VeloPopupContentContext.Consumer>
      <DialogWithRef
        ref={ref}
        onStateChange={createChangeHandler(
          disableTrapFocus,
          onStateChangeHandler
        )}
        {...props}
      >
        {children}
      </DialogWithRef>
    </VeloPopupContentContext.Consumer>
  ) : null;
}

/**
 * Custom version of the RMWC `SimpleDialog` to ensure the buttons have
 * type="button" applied for a11y and for use within a `<form>`.
 *
 * This code is copied from the `@rmwc/dialog` source with some minor
 * tweaks based on our usage.
 */
const Simple = ({
  open,
  title,
  body,
  recordPreference,
  acceptLabel,
  acceptProps,
  cancelLabel,
  cancelProps,
  ...other
}) => {
  const [checked, setChecked] = useState(false);

  return (
    <VeloDialog open={open} {...other}>
      <VeloDialog.Title>{title}</VeloDialog.Title>

      {/* Body is optional */}
      {body && <VeloDialog.Content>{body}</VeloDialog.Content>}

      {/* recording user preference is optional */}
      {recordPreference && (
        <VeloDialog.Content>
          <VeloCheckbox
            id="checkbox"
            checked={checked}
            onChange={() => setChecked(!checked)}
            data-testid="dialog-record-checkbox"
            label={
              <FormattedMessage defaultMessage="Don't show me this prompt again." />
            }
          />
        </VeloDialog.Content>
      )}
      {/* Return the recorded preference */}
      <VeloDialog.Actions>
        <VeloDialog.Button
          action={checked ? 'accept-record-disable' : 'accept'}
          type="button"
          {...acceptProps}
        >
          {acceptLabel}
        </VeloDialog.Button>

        {cancelLabel && (
          <VeloDialog.Button action="close" type="button" {...cancelProps}>
            {cancelLabel}
          </VeloDialog.Button>
        )}
      </VeloDialog.Actions>
    </VeloDialog>
  );
};

VeloDialog.Simple = Simple;

export { VeloDialog };
