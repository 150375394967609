import React, { useCallback } from 'react';
import {
  TagSection,
  VeloCacheHitContext,
  VeloNotification,
  VeloBookended,
} from 'velo-react-components';
import {
  usePresenter,
  useCallbackFnAsResultState,
  useSerializableMemo,
} from '../../hooks';
import { createFromHook, createRenderForksFromComponent } from '../helpers';
import { forkResult } from '../../selectors';
import { TagSectionPresenter } from './TagSectionPresenter';

const notes = {
  removeFailure: VeloNotification.types.TAG_REMOVE_FAILURE,
  removeSuccess: VeloNotification.types.TAG_REMOVE_SUCCESS,
};

const forks = createRenderForksFromComponent(TagSection);

export const createTagsRoute = (entitySpec, onAdd) => {
  const config = { ...notes, onAdd };
  return createFromHook(
    (props) => {
      const [loader, ...rest] = usePresenter(
        TagSectionPresenter,
        entitySpec,
        config,
        useSerializableMemo(props)
      );

      const [cacheHit] = VeloCacheHitContext.useVeloCacheHitContext('tags');

      const [result] = useCallbackFnAsResultState(
        useCallback((cb) => loader(cb, cacheHit), [loader, cacheHit]),
        false
      );
      return forkResult(forks, result, ...rest);
    },
    {
      render: (children) => (
        <VeloBookended.Bookend gridGutter>{children}</VeloBookended.Bookend>
      ),
    }
  );
};
