import {
  formatCallbackErrorArg,
  pipeCallbackFunctions,
} from 'velo-portal-common';

const createReducer = (id, physicalAccountName) => (options, account) => {
  if (
    account.id !== id &&
    account.physicalAccountName === physicalAccountName
  ) {
    return [...options, { label: account.name, value: account.id }];
  }
  return options;
};

const matchAccountId = (id) => (account) => account.id === id;

export function SourceAccountsTransferFundsPresenter(
  wireframe,
  entity,
  notification,
  { sourceAccountId }
) {
  function createSourceAccountCallback(sourceAccountId, cb) {
    return (error, data) => {
      if (error) {
        return cb(error);
      }

      const { content } = data;
      const {
        name: sourceAccountName,
        physicalAccountName,
        balance,
        currency,
      } = content.find(matchAccountId(sourceAccountId));

      const options = content.reduce(
        createReducer(sourceAccountId, physicalAccountName),
        []
      );

      return cb(undefined, {
        currency,
        balance,
        sourceAccountName,
        options,
      });
    };
  }

  function getSourceAccounts({ payorId }, cb) {
    return entity.getSourceAccounts(
      { payorId },
      createSourceAccountCallback(sourceAccountId, cb)
    );
  }

  function getSourceAccount(cb) {
    return entity.getSourceAccount(sourceAccountId, cb);
  }

  return [
    //loader
    pipeCallbackFunctions(getSourceAccount, getSourceAccounts),
    // props
    {
      onSubmit: (body, cb) => {
        return entity.transferFunds(
          sourceAccountId,
          body,
          formatCallbackErrorArg((error) => {
            if (!error) {
              wireframe.sendNote({
                ...notification,
                key: Date.now().toString(10),
              });
              wireframe.goBack();
            } else {
              cb(error);
            }
          })
        );
      },
      onClose: () => wireframe.goBack(),
    },
  ];
}
