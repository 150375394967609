import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { SelectCountry } from '../FormFields/SelectCountry';
import { SelectCurrency } from '../FormFields/SelectCurrency';
import { Custom, UserDefinedEntityName } from '../FormFields/FormFieldTypes';
import { VeloPropTypes } from '../VeloPropTypes';
import { FormGrid } from './FormGrid';
import { mapValues } from './Fields';
import { FormPropTypes } from './propTypes';
import { getOrderedPaymentChannelRules } from 'velo-data';
import { getCountryCodes } from '../utils';

PaymentChannelForm.propTypes = {
  ...FormPropTypes,
  /** An array of payment channel rules fetched from the API */
  paymentChannelRules: VeloPropTypes.paymentChannelRulesType().isRequired,
};

/**
 * Used to capture Payment Channel details.
 *
 * This form makes use of Payment Channel rules to render
 * a list of custom banking fields depending on a selected
 * country.
 */
function PaymentChannelForm({
  countries,
  values: { paymentCountry },
  values,
  paymentChannelRules,
  payeeCountryRules,
  breakpoint,
  readOnlyValues,
  ...other
}) {
  const intl = useIntl();
  // Find the rules for this country
  const match = paymentChannelRules.find(
    ({ isoCountryCode }) => isoCountryCode === paymentCountry
  );

  const sections = match
    ? [
        {
          fields: [
            {
              ...UserDefinedEntityName(
                <FormattedMessage defaultMessage="Payment Method Name" />,
                <FormattedMessage defaultMessage="Please enter a Payment Method Name" />,
                30
              ),
              name: 'paymentChannelName',
              required: true,
            },
            // Fixed field types required for all countries
            {
              name: 'paymentCountry',
              Component: SelectCountry,
              label: <FormattedMessage defaultMessage="Payment Country" />,
              helpText: {
                validationMsg: true,
                children: (
                  <FormattedMessage defaultMessage="Please select a payment country" />
                ),
              },
              countries: getCountryCodes(countries),
              required: true,
            },
            {
              name: 'currency',
              Component: SelectCurrency,
              helpText: {
                validationMsg: true,
                children: (
                  <FormattedMessage defaultMessage="Please select a payment currency" />
                ),
              },
              currencies: countries.find(
                ({ isoCountryCode }) => isoCountryCode === paymentCountry
              ).currencies,
              required: true,
            },
          ],
        },
        {
          heading: <FormattedMessage defaultMessage="Account details" />,
          fields: [
            ...getOrderedPaymentChannelRules(match.rules).map(
              ({
                element,
                displayName,
                validation,
                minLength,
                maxLength,
                required,
              }) => {
                // Show constraints in the placeholder
                // For this to be accurate we need to know if alpha chars
                // are valid.
                const type =
                  'x'.repeat(minLength).match(validation) === null
                    ? intl.formatMessage({ defaultMessage: 'digits' })
                    : intl.formatMessage({ defaultMessage: 'characters' });

                const placeholder =
                  minLength === maxLength
                    ? intl.formatMessage(
                        { defaultMessage: '{minLength} {type}' },
                        { minLength, type }
                      )
                    : intl.formatMessage(
                        { defaultMessage: '{minLength}-{maxLength} {type}' },
                        { minLength, maxLength, type }
                      );

                return {
                  ...Custom(
                    displayName,
                    <FormattedMessage
                      defaultMessage="Please enter the {displayName}"
                      values={{ displayName }}
                    />,
                    validation,
                    minLength,
                    maxLength
                  ),
                  ...{
                    name: element,
                    required,
                    placeholder,
                  },
                };
              }
            ),
          ],
        },
      ]
    : [];

  return (
    <FormGrid
      sections={mapValues(sections, values)}
      breakpoint={breakpoint}
      {...other}
    />
  );
}

export { PaymentChannelForm };
