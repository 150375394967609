import React from 'react';
import { func, oneOf, node, number } from 'prop-types';
import { VeloTable } from '../VeloTable';

export function VeloTableBuilder({
  columnOrder,
  columns,
  filters = [],
  sortableColumns = [],
  getRowProps,
  getColumnProps,
  dataProps,
  testIds = {},
  iconSpacer = true,
}) {
  const Headings = ({ onSort, sortColumn, sortOrder, children, ...props }) => {
    return (
      <VeloTable.HeaderRow iconSpacer={iconSpacer} {...props}>
        {columnOrder.map(({ name, label, ...other }) => {
          const sortable = sortableColumns.includes(name);
          return (
            <VeloTable.HeaderCell
              key={name}
              name={name}
              onSort={sortable ? onSort : undefined}
              sort={
                sortable
                  ? sortColumn === name
                    ? sortOrder
                    : VeloTable.sortOrder.NONE
                  : undefined
              }
              {...other}
            >
              {label}
            </VeloTable.HeaderCell>
          );
        })}
        {children}
      </VeloTable.HeaderRow>
    );
  };

  Headings.propTypes = {
    /** The name of the currently sorted column. */
    sortColumn: oneOf(sortableColumns),
    /** The sort order. */
    sortOrder: oneOf(Object.values(VeloTable.sortOrder)),
    /** Called when changing the sort column/order. */
    onSort: func,
  };

  const getCellProps = (col) => {
    const { size } = columnOrder[col];
    return { size };
  };

  const Loading = ({ rows = 1, ...props }) => (
    <VeloTable.Loading
      rows={rows}
      cols={columnOrder.length}
      getCellProps={getCellProps}
      icon
      data-testid={testIds.LOADING}
      {...props}
    />
  );

  Loading.propTypes = {
    /** The number of rows to render. */
    rows: number,
  };

  const Error = ({ error, ...props }) => (
    <VeloTable.Error data-testid={testIds.ERROR} {...props}>
      {error}
    </VeloTable.Error>
  );

  Error.propTypes = {
    /** The error message to display. */
    error: node.isRequired,
  };

  const Data = ({ data, ...props }) => (
    <>
      {data.map((...args) => {
        return React.createElement(
          VeloTable.Row,
          getRowProps(...args, props),
          columnOrder.map((col) => {
            const { Component, ...columnProps } = getColumnProps(
              col,
              ...args,
              props
            );

            const [cellData] = args;

            return React.createElement(Component || VeloTable.Cell, {
              ...columnProps,
              ...(columnProps.children
                ? {}
                : {
                    children: cellData[col.name],
                  }),
            });
          })
        );
      })}
    </>
  );

  Data.propTypes = dataProps;

  return {
    filters,
    columns,
    sortableColumns,
    sortOrder: VeloTable.sortOrder,
    Empty: VeloTable.Empty,
    Table: VeloTable,
    Headings,
    Error,
    Data,
    Loading,
  };
}
