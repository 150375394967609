import React from 'react';
import { func } from 'prop-types';
import { PaymentReceiptContainer } from './PaymentReceiptContainer';
import { PaymentViewItem } from '../PaymentView/PaymentViewItem';
import { VeloSkeleton } from '../VeloSkeleton';

const root = 'payment-receipt-skeleton';

PaymentReceiptSkeleton.propTypes = {
  onClose: func.isRequired,
};

function PaymentReceiptSkeleton({ onClose }) {
  return (
    <PaymentReceiptContainer onClose={onClose}>
      {[...Array(8).keys()].map((key) => (
        <PaymentViewItem key={`${root}-payment-item-${key}`} skeleton />
      ))}
      {[...Array(30).keys()].map((key) => (
        <VeloSkeleton key={`${root}-content-skeleton-${key}`} />
      ))}
    </PaymentReceiptContainer>
  );
}

export { PaymentReceiptSkeleton };
