import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'astroturf/react';
import { VeloTypography } from '../VeloTypography';
import { VeloResponsiveText } from '../VeloResponsiveText';

const Message = styled(VeloTypography).attrs({ use: 'bodyText' })`
  @import 'velo-variables';

  flex: 1;

  @media (max-width: velo-breakpoint(XS)) {
    padding-top: 1rem;
  }
`;

const Link = styled('a')`
  @import 'velo-variables';

  color: velo-color('token-color-brand-primary');
`;

const root = 'velo-payment-channel-enable-link';
const TestIds = {
  ENABLE_LINK: `${root}`,
};

PaymentChannelEnableLink.testIds = TestIds;

/**
 * Render a Payment Channel Enable Link Content.
 */
function PaymentChannelEnableLink({ handleEnablePaymentChannel }) {
  return (
    <Message>
      <FormattedMessage
        defaultMessage="If the information is correct please {click} to enable."
        values={{
          click: (
            <Link
              href="#enable"
              data-testid={TestIds.ENABLE_LINK}
              onClick={handleEnablePaymentChannel}
            >
              <VeloResponsiveText
                mobile={<FormattedMessage defaultMessage="press" />}
                desktop={<FormattedMessage defaultMessage="click" />}
                data-testid={TestIds.ENABLE_LINK}
              />
            </Link>
          ),
        }}
      />
    </Message>
  );
}

export { PaymentChannelEnableLink };
