export function remoteIdUpdateV4(send) {
  return (payeeId, body, ...args) =>
    send(
      {
        path: '/v4/payees/{payeeId}/remoteIdUpdate',
        pathParameters: { payeeId },
        type: 'post',
        query: undefined,
        body: body,
        headers: undefined,
        auth: 'oauth2',
        contentType: 'application/json',
      },
      ...args
    );
}
