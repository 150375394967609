import React from 'react';
import { node, string } from 'prop-types';
import styled from 'astroturf/react';
import { VeloIcon } from '../VeloIcon';
import { VeloTypography } from '../VeloTypography';
import { VeloCard } from '../VeloCard';

const Error = styled('div')`
  @import 'velo-variables';

  min-height: 20rem;
  padding: 4rem 4rem 1rem 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  @media (max-width: velo-breakpoint(XS)) {
    padding: 1rem;
    padding-top: 3rem;
    min-height: 10rem;
  }
`;

const Icon = styled(VeloIcon)`
  @import 'velo-mixins';

  @include icon-size(4rem);

  color: velo-color('token-color-system-error-default');
`;

VeloErrorCard.propTypes = {
  /** Custom CSS class(es) applied to the card. */
  className: string,
  /** The close icon to render in the top-right corner. */
  close: node,
  /** The error title. */
  title: node,
  /** The error message. */
  message: node,
};

function Content({ children }) {
  return (
    <Error>
      <Icon icon="error" />
      {children}
    </Error>
  );
}

const Title = styled(VeloTypography).attrs({
  use: 'secondaryHeader',
  tag: 'h1',
})`
  margin: 1.5rem 1rem 0rem 1rem;
`;

const Message = styled(VeloTypography).attrs({
  use: 'bodyText',
  tag: 'div',
})`
  margin: 1.5rem 1rem 1rem 1rem;
`;

function ErrorCardInner({ error, ...props }) {
  return (
    <Content>
      <Title>{error}</Title>
    </Content>
  );
}

VeloErrorCard.Error = Error;
VeloErrorCard.Content = Content;
VeloErrorCard.Title = Title;
VeloErrorCard.Message = Message;
VeloErrorCard.Inner = ErrorCardInner;

const Close = styled('div')`
  position: absolute;
  top: 0.5rem;
  right: 1rem;
`;

/**
 * Render a Card-style error message.
 * Useful for loading errors.
 */
function VeloErrorCard({ className, close, title, message, ...other }) {
  return (
    <VeloCard className={className} {...other}>
      <Close>{close}</Close>

      <Content>
        <Title>{title}</Title>
        <Message>{message}</Message>
      </Content>
    </VeloCard>
  );
}

export { VeloErrorCard };
