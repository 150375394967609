import React from 'react';
import { MultiFactorChallenge } from '../MultiFactorChallenge';

MultiFactorChallengeGuard.mfaTypes = MultiFactorChallenge.mfaTypes;

function MultiFactorChallengeGuard({ showMfa, children, ...props }) {
  return showMfa ? <MultiFactorChallenge {...props} /> : children;
}

export { MultiFactorChallengeGuard };
