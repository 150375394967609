import React from 'react';
import { FormattedMessage, defineMessage } from 'react-intl';

const DialogTypeOf = (
  id,
  title,
  acceptLabel,
  cancelLabel,
  body,
  recordPreference = false
) => ({
  id,
  title,
  acceptLabel,
  cancelLabel,
  body,
  recordPreference,
});

const DiscardChangesType = DialogTypeOf(
  'DiscardChangesType',
  <FormattedMessage defaultMessage="Discard unsaved changes?" />,
  <FormattedMessage defaultMessage="Discard" />,
  <FormattedMessage defaultMessage="Cancel" />
);

const SaveChangesType = DialogTypeOf(
  'SaveChangesType',
  <FormattedMessage defaultMessage="Confirm changes?" />,
  <FormattedMessage defaultMessage="Save" />,
  <FormattedMessage defaultMessage="Cancel" />
);

const SaveYourProfileChangesType = DialogTypeOf(
  'SaveProfileChangesType',
  <FormattedMessage defaultMessage="Confirm changes to your Profile?" />,
  <FormattedMessage defaultMessage="Save" />,
  <FormattedMessage defaultMessage="Cancel" />
);

const SaveOthersProfileChangesType = DialogTypeOf(
  'SaveProfileChangesType',
  <FormattedMessage defaultMessage="Confirm changes to this Profile?" />,
  <FormattedMessage defaultMessage="Save" />,
  <FormattedMessage defaultMessage="Cancel" />
);

const ConfirmWithdrawPayoutType = DialogTypeOf(
  'ConfirmWithdrawPayoutType',
  <FormattedMessage defaultMessage="Do you want to withdraw this payout?" />,
  <FormattedMessage defaultMessage="Withdraw Payout" />,
  <FormattedMessage defaultMessage="Cancel" />,
  <FormattedMessage defaultMessage="This will keep a record in the payouts list." />
);

const ConfirmDeschedulePayoutType = DialogTypeOf(
  'ConfirmDeschedulePayoutType',
  <FormattedMessage defaultMessage="Do you want to clear the schedule for this payout?" />,
  <FormattedMessage defaultMessage="Clear Schedule" />,
  <FormattedMessage defaultMessage="Cancel" />,
  <FormattedMessage
    defaultMessage="Clearing the schedule will remove the scheduled for date/time of this payout, no payment or series of payments will be made and the payout status will be reset to <b>‘Quoted’</b>."
    values={{ b: (...content) => <b>{content}</b> }}
  />
);

const ConfirmInstructPayoutType = DialogTypeOf(
  'ConfirmInstructPayoutType',
  <FormattedMessage defaultMessage="Do you want to instruct this payout?" />,
  <FormattedMessage defaultMessage="Instruct Payout" />,
  <FormattedMessage defaultMessage="Cancel" />,
  <FormattedMessage defaultMessage="Instructing this payout will make a payment or a series of payments." />
);

const ConfirmDeletePayeeType = DialogTypeOf(
  'ConfirmDeletePayeeType',
  <FormattedMessage defaultMessage="Do you want to permanently delete this payee?" />,
  <FormattedMessage defaultMessage="Delete" />,
  <FormattedMessage defaultMessage="Cancel" />
);

const ConfirmDeleteCreditorType = DialogTypeOf(
  'ConfirmDeleteCreditorType',
  <FormattedMessage defaultMessage="Do you want to permanently delete this creditor?" />,
  <FormattedMessage defaultMessage="Delete" />,
  <FormattedMessage defaultMessage="Cancel" />
);

const UnregisterMFAType = DialogTypeOf(
  'UnregisterMFAType',
  <FormattedMessage defaultMessage="Do you want to unregister your two-factor authentication device?" />,
  <FormattedMessage defaultMessage="Unregister" />,
  <FormattedMessage defaultMessage="Cancel" />
);

const RemovePayorLinkType = DialogTypeOf(
  'RemovePayorLinkType',
  'Are you sure you want to unlink "{{payorName}}" as the Parent Payor?',
  <FormattedMessage defaultMessage="Unlink" />,
  <FormattedMessage defaultMessage="Cancel" />
);

const DeallocateFunding = DialogTypeOf(
  'DeallocateFunding',
  <FormattedMessage defaultMessage="Are you sure you want to deallocate this funding?" />,
  <FormattedMessage defaultMessage="Deallocate" />,
  <FormattedMessage defaultMessage="Cancel" />
);

const DeleteTag = DialogTypeOf(
  'DeleteTag',
  <FormattedMessage defaultMessage="Are you sure you want to delete this tag?" />,
  <FormattedMessage defaultMessage="Delete" />,
  <FormattedMessage defaultMessage="Cancel" />
);

const DeletePaymentChannel = DialogTypeOf(
  'DeletePaymentChannelType',
  <FormattedMessage defaultMessage="Delete Payment Method" />,
  <FormattedMessage defaultMessage="Delete" />,
  <FormattedMessage defaultMessage="Cancel" />,
  defineMessage({
    defaultMessage:
      'Are you sure you want to delete the payment method "{name}"?',
  })
);

const UnableToDeletePaymentChannelType = DialogTypeOf(
  'UnableToDeletePaymentChannelType',
  <FormattedMessage defaultMessage="Delete Payment Method" />,
  <FormattedMessage defaultMessage="Okay" />,
  null,
  <>
    <div>
      <FormattedMessage defaultMessage="You cannot delete this payment method as there are no others." />
    </div>
    <br />
    <div>
      <FormattedMessage defaultMessage="If you want to delete this payment method please create another first." />
    </div>
  </>
);

const DisableWebhook = DialogTypeOf(
  'DisableWebhookType',
  <FormattedMessage defaultMessage="Disable Webhook" />,
  <FormattedMessage defaultMessage="Disable" />,
  <FormattedMessage defaultMessage="Cancel" />,
  <FormattedMessage defaultMessage="Disabling this webhook or specified category will immediately stop the delivery of outstanding events to the webhook URL. Please be advised that new events are discarded while the webhook or specified category remains disabled." />
);

const ConfirmDeleteSourceAccount = DialogTypeOf(
  'ConfirmDeleteSourceAccount',
  <FormattedMessage defaultMessage="Do you want to permanently delete this source account?" />,
  <FormattedMessage defaultMessage="Delete" />,
  <FormattedMessage defaultMessage="Cancel" />
);

const ConfirmPaymentArrived = DialogTypeOf(
  'ConfirmPaymentArrivedType',
  <FormattedMessage defaultMessage="Has your payment arrived successfully?" />,
  <FormattedMessage defaultMessage="Confirm" />,
  <FormattedMessage defaultMessage="Cancel" />,
  <FormattedMessage defaultMessage="Click Confirm if you have successfully received this payment. Click Cancel if you are not sure that you have received this payment." />,
  true
);

const EmailTemplateDiscardChanges = DialogTypeOf(
  'EmailTemplateDiscardChanges',
  <FormattedMessage defaultMessage="Discard changes" />,
  <FormattedMessage defaultMessage="Discard" />,
  <FormattedMessage defaultMessage="Cancel" />,
  <FormattedMessage defaultMessage="Are you sure you want to discard all changes made to this template?" />
);

const EmailTemplateSaveChanges = DialogTypeOf(
  'EmailTemplateSaveChanges',
  <FormattedMessage defaultMessage="Unsaved changes" />,
  <FormattedMessage defaultMessage="Continue without saving" />,
  <FormattedMessage defaultMessage="Go Back" />,
  <FormattedMessage defaultMessage="Any unsaved changes will be lost. Use the SAVE & SEND TEST button to keep your changes before leaving." />
);

const EmailTemplatePublish = DialogTypeOf(
  'PublishEmailTemplate',
  <FormattedMessage defaultMessage="Publish template" />,
  <FormattedMessage defaultMessage="Publish" />,
  <FormattedMessage defaultMessage="Cancel" />,
  <FormattedMessage defaultMessage="Are you sure you want to publish these changes for this template?" />
);

const ConfirmationDialogTypes = {
  DiscardChangesType,
  DeleteTag,
  SaveChangesType,
  SaveYourProfileChangesType,
  SaveOthersProfileChangesType,
  ConfirmWithdrawPayoutType,
  ConfirmDeschedulePayoutType,
  ConfirmInstructPayoutType,
  ConfirmDeletePayeeType,
  ConfirmDeleteCreditorType,
  UnregisterMFAType,
  RemovePayorLinkType,
  DeallocateFunding,
  DeletePaymentChannel,
  UnableToDeletePaymentChannelType,
  DisableWebhook,
  ConfirmDeleteSourceAccount,
  ConfirmPaymentArrived,
  EmailTemplateDiscardChanges,
  EmailTemplateSaveChanges,
  EmailTemplatePublish,
};

export { ConfirmationDialogTypes, DialogTypeOf };
