import React from 'react';
import { bool, string } from 'prop-types';
import styled from 'astroturf/react';
import { useScrollLock } from '../hooks';
import { useVeloModalSideSheetContext } from './VeloModalSheetContext';

const Body = styled('div')`
  @import 'velo-mixins';

  @include smooth-scrolling;

  overflow: auto;
  padding: 1rem 1rem 0 1rem;
  height: 100%;

  &.compact {
    padding: 0;
  }
`;

Body.propTypes = {
  compact: bool,
};

const BodyPadding = styled('div')`
  padding-bottom: 1rem;
`;

VeloModalSheetBody.propTypes = {
  /** Custom CSS class(es). */
  className: string,
  /** Remove padding. */
  compact: bool,
};

/**
 * Represents the body of a VeloModalSheet.
 */
function VeloModalSheetBody({ children, ...other }) {
  const { open } = useVeloModalSideSheetContext();
  const bodyRef = useScrollLock(open);

  return (
    <Body ref={bodyRef} {...other}>
      <BodyPadding>{children}</BodyPadding>
    </Body>
  );
}

export { VeloModalSheetBody };
