import React from 'react';
import { arrayOf, bool, shape, string, number, node } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styled from 'astroturf/react';
import { VeloIcon } from '../VeloIcon';
import { VeloTypography } from '../VeloTypography';
import { VeloPropTypes } from '../VeloPropTypes';
import { ResultContainer } from '../ResultContainer';

const RADIUS = '1.25rem';
const BAR_HEIGHT = '0.3125rem'; // 5px

const Meter = styled('div')`
  margin: 0.25rem auto 0;
`;

const Warning = styled(VeloTypography).attrs({
  tag: 'p',
  use: 'bodyTextSmaller',
})`
  @import 'velo-variables';

  color: velo-color('token-color-system-error-default');
  margin: 0.75rem 0 0;
  line-height: 1.375rem;
`;

const Error = styled('div')`
  @import 'velo-variables';

  display: flex;
  align-items: center;
  margin-top: 0.25rem;
  color: velo-color('token-color-system-error-default');
`;

const ErrorIcon = styled(VeloIcon).attrs({ icon: 'error' })`
  margin-right: 0.5rem;
`;

const Suggestions = styled('p')`
  margin: 0.75rem 0 0;
  span::after {
    content: ' ';
  }
`;

const StrengthBar = styled('div')`
  @import 'velo-variables';

  display: flex;
  height: ${BAR_HEIGHT};
  border-radius: ${RADIUS};
  background-color: velo-color('token-color-system-disabled');

  &.loading {
    background: unset;
  }
`;

StrengthBar.propTypes = {
  loading: bool,
};

const BarItem = styled('div')`
  @import 'velo-variables';

  @mixin bar-status($width, $color) {
    background-color: $color;
    width: $width;
    @if ($width == 100%) {
      border-radius: ${RADIUS};
    } @else {
      border-radius: ${RADIUS} 0 0 ${RADIUS};
    }
  }

  min-height: ${BAR_HEIGHT};
  margin: 0;
  line-height: ${BAR_HEIGHT};

  &.score-0,
  &.score-1 {
    /* Very weak, weak */
    @include bar-status(20%, velo-color('token-color-system-error-default'));
  }

  &.score-2 {
    /* Medium */
    @include bar-status(60%, velo-color('token-color-system-warning-default'));
  }

  &.score-3 {
    /* Strong */
    @include bar-status(80%, velo-color('token-color-system-success-default'));
  }

  &.score-4 {
    /* Very strong */
    @include bar-status(100%, velo-color('token-color-system-success-default'));
  }

  &.loading {
    border-radius: ${RADIUS};
  }
`;

BarItem.propTypes = {
  score: number,
  loading: bool,
};

const VeryStrongPasswordMessage = (
  <FormattedMessage defaultMessage="Congratulations! Your password is hard to break" />
);

const root = 'velo-strength-meter';
const TestIds = {
  STRENGTH_BAR: `${root}-bar`,
  STRENGTH_ERROR: `${root}-error`,
  STRENGTH_RESULT: `${root}-result`,
  LOADING_SKELETON: `${root}-skeleton`,
  EMPTY: `${root}-empty`,
};

/**
 * @see https://ci.velointra.net/docs/2.8/private-api.html#resources-auth-service-password-validation-controller-test-validate-password
 */
const ValidationDetailType = {
  /**
   * A number between 0-4 determining how strong the password is.
   */
  score: number.isRequired,
  /**
   * An optional warning message if the password is particularly unsuitable.
   */
  warning: node,
  /**
   * A list of zero or more suggestions on how to improve the password strength.
   */
  suggestions: arrayOf(string).isRequired,
};

const ValidationDetailPropType = VeloPropTypes.result(
  shape(ValidationDetailType)
);

function ResultComponent({ result }) {
  const { score, warning, suggestions } = result;
  const successThreshold = score === 4;
  const messages = successThreshold ? [VeryStrongPasswordMessage] : suggestions;
  const hasMessages = messages.length > 0;
  const showSuggestionsLabel = !successThreshold && suggestions.length > 0;
  const hasWarning = !successThreshold && !!warning;
  return (
    <Meter data-testid={TestIds.STRENGTH_RESULT}>
      <StrengthBar data-testid={TestIds.STRENGTH_BAR}>
        <BarItem score={score} />
      </StrengthBar>
      {hasMessages && (
        <Suggestions>
          {showSuggestionsLabel && (
            <VeloTypography use="bodyTextSmaller">Suggestions: </VeloTypography>
          )}
          {messages.map((str, i) => (
            <VeloTypography use="bodyTextSmaller" key={`suggestion-${i}`}>
              {str}
            </VeloTypography>
          ))}
        </Suggestions>
      )}
      {hasWarning && <Warning>{warning}</Warning>}
    </Meter>
  );
}

const LoadingComponent = () => (
  <Meter data-testid={TestIds.LOADING_SKELETON}>
    <StrengthBar loading data-testid={TestIds.STRENGTH_BAR}>
      <BarItem loading />
    </StrengthBar>
  </Meter>
);

const ErrorComponent = ({ error }) => (
  <Error data-testid={TestIds.STRENGTH_ERROR}>
    <ErrorIcon />
    <VeloTypography use="bodyTextSmaller" tag="div">
      {error}
    </VeloTypography>
  </Error>
);

const EmptyComponent = () => <div data-testid={TestIds.EMPTY} />;

const Container = ResultContainer({
  ErrorComponent,
  LoadingComponent,
  EmptyComponent,
})(ResultComponent);

VeloStrengthMeter.propTypes = {
  /**
   * A result type containing the validation result
   */
  data: ValidationDetailPropType.isRequired,
};

function VeloStrengthMeter({ data }) {
  return <Container {...data} />;
}

VeloStrengthMeter.testIds = TestIds;
VeloStrengthMeter.ValidationDetailPropType = ValidationDetailPropType;
VeloStrengthMeter.VeryStrongPasswordMessage = VeryStrongPasswordMessage;

export { VeloStrengthMeter };
