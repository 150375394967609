export function getAllPooledAccounts(send) {
  return (...args) =>
    send(
      {
        path: '/v1/pooledAccounts',
        pathParameters: {},
        type: 'get',
        query: undefined,
        body: undefined,
        headers: undefined,
        auth: 'oauth2',
        contentType: undefined,
      },
      ...args
    );
}
