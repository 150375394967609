import {
  arrayOf,
  func,
  node,
  oneOf,
  oneOfType,
  shape,
  string,
} from 'prop-types';

const types = {
  string: 'string',
  list: 'list',
  listCountry: 'listCountry',
  listCurrency: 'listCurrency',
  date: 'date',
  dateRange: 'dateRange',
  number: 'number',
  currencyRange: 'currencyRange',
  entityIdLookup: 'entityIdLookup',
};

const readOnlyTypes = {
  string: 'string',
};

const fieldPropTypesBase = {
  /** The field name. e.g. 'status' */
  name: string,
  /** The field label. */
  label: node,
};

const field = shape({
  ...fieldPropTypesBase,
  /** The field type. */
  type: oneOf(Object.values(types)),
  /** The column values for type=list. */
  options: oneOfType([
    arrayOf(
      shape({
        /** The list item value. */
        value: string,
        /** The list item label. */
        label: node,
      })
    ),
    func,
  ]),
});

const readOnlyField = shape({
  ...fieldPropTypesBase,
  /** The field type */
  type: oneOf(Object.values(readOnlyTypes)),
  /** The read only display value */
  value: string,
  /** The id value of the field */
  id: string,
});

export default { field, types, readOnlyField };
