import {
  SourceAccountAddFundingForm,
  VeloNotification,
} from 'velo-react-components';
import { createFundingRequestV3 as createAchFundingRequest } from 'velo-api/src/entities/sourceAccounts';
import { SourceAccountWithFundingAccountSearchPresenter } from './SourceAccountsViewDataPresenter';
import { createRoute } from './helpers';
import { SourceAccountsAddFundingConfirmRoute } from './SourceAccountsAddFundingConfirmRoute';

const dialog = SourceAccountsAddFundingConfirmRoute.asDialogRender;

function Presenter(wireframe, entity, props, notification) {
  const [loader, componentProps, createCallback] =
    SourceAccountWithFundingAccountSearchPresenter(wireframe, entity, props);

  const createAchFundingRequest = (
    { amount, fundingAccount: { id: fundingAccountId } },
    cb
  ) =>
    entity.createAchFundingRequest(
      props.sourceAccountId,
      { amount, fundingAccountId },
      cb
    );

  return [
    loader,
    {
      ...componentProps,
      dialog,
      onSubmit: ({ type, ...body }, cb) => {
        const finalCallback = createCallback(cb, notification);

        return createAchFundingRequest(body, finalCallback);
      },
    },
  ];
}

export const SourceAccountsAddFundingRoute = createRoute(
  Presenter,
  SourceAccountAddFundingForm,
  {
    createAchFundingRequest,
  },
  VeloNotification.types.SOURCE_ACCOUNT_ADD_FUNDING_SUCCESS
);

SourceAccountsAddFundingRoute.displayName = 'SourceAccountsAddFundingRoute';
