import { useEffect } from 'react';
import debounce from 'just-debounce-it';

// Custom hook to monitor an element for a breakpoint trigger
export function useDebouncedResize(effect, debounceTime = 100) {
  useEffect(() => {
    // on mount, trigger the effect immediately
    effect();
    // Handle window resize events
    const handleResize = debounce(effect, debounceTime);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [effect, debounceTime]);
}
