import React from 'react';
import { FormattedMessage } from 'react-intl';
import { func, string } from 'prop-types';
import { VeloTextField } from '../VeloTextField';
import { VeloForm } from '../VeloForm';
import { VeloLogoPage } from '../VeloLogoPage';
import { Email } from '../FormFields/FormFieldTypes';

const root = 'ForgotPasswordForm';
const TestIds = {
  EMAIL_FIELD: `${root}-email-field`,
  HEADER: `${root}-header`,
  ERROR: `${root}-error`,
  BACK_BUTTON: `${root}-back`,
};

class ForgotPasswordForm extends React.Component {
  static propTypes = {
    exit: func.isRequired,
    error: string,
  };

  static testIds = TestIds;

  state = {
    email: '',
    submitting: false,
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ submitting: true }, () =>
      this.props.exit(this.state.email)
    );
  };

  onChange = ({ target: { value } }) => {
    this.setState({ email: value });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.error !== this.props.error) {
      this.setState((s) => ({ ...s, submitting: false }));
    }
  }

  render() {
    const { submitting } = this.state;
    const { error } = this.props;
    const actionText = submitting ? (
      <FormattedMessage defaultMessage="Sending..." />
    ) : (
      <FormattedMessage defaultMessage="Send Instructions" />
    );
    const formProps = {
      actionText,
      overlay: submitting,
      onSubmit: this.handleSubmit,
    };

    return (
      <VeloForm
        {...formProps}
        error={
          error ? (
            <FormattedMessage defaultMessage="Error sending the reset link. Please try again." />
          ) : undefined
        }
      >
        <VeloLogoPage.Text data-testid={TestIds.header}>
          <FormattedMessage defaultMessage="Please enter your registered email address." />
        </VeloLogoPage.Text>

        <VeloForm.FieldContainer>
          <VeloTextField
            {...Email()}
            data-testid={TestIds.EMAIL_FIELD}
            required
            onChange={this.onChange}
          />
        </VeloForm.FieldContainer>
      </VeloForm>
    );
  }
}

export { ForgotPasswordForm };
