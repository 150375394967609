import React from 'react';
import { Switch, Route, Redirect, useHistory } from 'react-router';
import { ReportsPage, TransactionReportForm } from 'velo-react-components';
import {
  useWireframe,
  forkResult,
  usePayorTransactionReport,
} from 'velo-portal-common';

const ReportTypes = ReportsPage.types;

const forks = {
  value: (data, props) => <TransactionReportForm {...data} {...props} />,
  none: () => <TransactionReportForm.Loading />,
  error: (error) => <TransactionReportForm.Error error={error} />,
};

function TransactionReportRoute({ query, payorId }) {
  const { data, ...props } = usePayorTransactionReport({
    query: { ...query, payorId },
  });
  return forkResult(forks, data, props);
}

function PayorReportsRoute(props) {
  const history = useHistory();
  const wireFrame = useWireframe(history);

  return (
    <ReportsPage
      // onSelectReport is a noop due to ReportsPage PropTypes validation.
      // TODO: Replace with navigation function when multiple reports are
      // supported.
      onSelectReport={() => {}}
      onClose={wireFrame.goBack}
      selectedType={ReportTypes.TRANSACTION}
      reportTypes={[ReportTypes.TRANSACTION]}
    >
      {/*
       * React Router Switch is used as future proofing for when there are
       * multiple reports routes.
       *
       * `/reports` is the base route.
       * `/reports/transaction-report` is the default report sub route.
       */}
      <Switch>
        <Route path={wireFrame.navigateToTransactionReport.path} exact>
          <TransactionReportRoute {...props} />
        </Route>

        <Redirect to={wireFrame.navigateToTransactionReport.path} />
      </Switch>
    </ReportsPage>
  );
}

export { PayorReportsRoute };
