export function getOpenBankingPayeeV1(send) {
  return (payeeId, ...args) =>
    send(
      {
        path: '/v1/openbanking/payees/{payeeId}',
        pathParameters: { payeeId },
        type: 'get',
        query: undefined,
        body: undefined,
        headers: undefined,
        auth: 'oauth2',
        contentType: undefined,
      },
      ...args
    );
}
