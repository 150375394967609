import React from 'react';
import { arrayOf, func, oneOf, shape, string, object } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { dataTestIdBuilder, UserStatus, defaultWithEmptyStr } from 'velo-data';
import { VeloTableBuilder } from '../VeloTableBuilder';
import { PaymentResult } from '../PaymentsList/PaymentResult';
import { UserRoles } from '../UserRoles';
import { UserStatusIndicator } from '../UserStatusIndicator';
import { LookupTextField } from '../LookupTextField';

const root = 'velo-users-table';

const TestIds = {
  TABLE: root,
  HEADING: `${root}-heading`,
  LOADING: `${root}-loading`,
  ERROR: `${root}-error`,
  EMPTY: `${root}-empty`,
  ROW: `${root}-row`,
};

const variants = {
  BOP_ADMINS: 'bopadmins',
  PAYOR_USERS: 'payorusers',
  BOP_PAYOR_USERS: 'payorusersbop',
  BOP_PAYEE_USERS: 'payeeusersbop',
  PAYEE_USERS: 'payeeusers',
};

const fieldNames = {
  NAME: 'name',
  ROLE: 'role',
  EMAIL: 'email',
  ENTITY: 'entityId',
  STATUS: 'status',
  COMPANY_NAME: 'companyName',
};

const columnsByName = {
  [fieldNames.NAME]: {
    name: fieldNames.NAME,
    label: <FormattedMessage defaultMessage="Name" />,
    'data-testid': dataTestIdBuilder(TestIds.HEADING, fieldNames.NAME),
  },
  [fieldNames.ROLE]: {
    name: fieldNames.ROLE,
    label: <FormattedMessage defaultMessage="Role" />,
    'data-testid': dataTestIdBuilder(TestIds.HEADING, fieldNames.ROLE),
  },
  [fieldNames.ENTITY]: {
    name: fieldNames.ENTITY,
    label: <FormattedMessage defaultMessage="Payor" />,
    'data-testid': dataTestIdBuilder(TestIds.HEADING, fieldNames.ENTITY),
  },
  [fieldNames.STATUS]: {
    name: fieldNames.STATUS,
    label: <FormattedMessage defaultMessage="Status" />,
    'data-testid': dataTestIdBuilder(TestIds.HEADING, fieldNames.STATUS),
  },
  [fieldNames.EMAIL]: {
    name: fieldNames.EMAIL,
    label: <FormattedMessage defaultMessage="Email" />,
    'data-testid': dataTestIdBuilder(TestIds.HEADING, fieldNames.EMAIL),
  },
  [fieldNames.COMPANY_NAME]: {
    name: fieldNames.COMPANY_NAME,
    label: <FormattedMessage defaultMessage="Company" />,
    'data-testid': dataTestIdBuilder(TestIds.HEADING, fieldNames.COMPANY_NAME),
  },
};

const cellFactoryByFieldName = {
  [fieldNames.NAME]: ({ firstName, lastName }) =>
    `${defaultWithEmptyStr(firstName)} ${defaultWithEmptyStr(lastName)}`,
  [fieldNames.ROLE]: ({ roles }) => UserRoles.IntlLabels[roles[0].name],
  [fieldNames.ENTITY]: ({ entity }, index) => (
    <PaymentResult
      errorMessage={<FormattedMessage defaultMessage="Error loading Payor" />}
      {...entity}
    />
  ),
  [fieldNames.EMAIL]: ({ email }) => email,
  [fieldNames.STATUS]: ({ status }) => (
    <UserStatusIndicator status={status} use="tableContent" />
  ),
  [fieldNames.COMPANY_NAME]: ({ companyName }) => companyName,
};

const filtersByName = {
  [fieldNames.ENTITY]: {
    name: fieldNames.ENTITY,
    label: <FormattedMessage defaultMessage="Payor" />,
    type: 'entityIdLookup',
    mode: LookupTextField.modes.PAYOR,
  },
  [fieldNames.STATUS]: {
    name: fieldNames.STATUS,
    label: <FormattedMessage defaultMessage="Status" />,
    type: 'list',
    options: ({ intl }) => UserStatusIndicator.options(intl),
  },
};

const ColumnOrderMap = {
  [variants.BOP_ADMINS]: [
    columnsByName[fieldNames.NAME],
    columnsByName[fieldNames.EMAIL],
    columnsByName[fieldNames.STATUS],
  ],
  [variants.PAYOR_USERS]: [
    columnsByName[fieldNames.NAME],
    columnsByName[fieldNames.ROLE],
    columnsByName[fieldNames.EMAIL],
    columnsByName[fieldNames.STATUS],
  ],
  [variants.BOP_PAYOR_USERS]: [
    columnsByName[fieldNames.NAME],
    columnsByName[fieldNames.ENTITY],
    columnsByName[fieldNames.ROLE],
    columnsByName[fieldNames.EMAIL],
    columnsByName[fieldNames.STATUS],
  ],
  [variants.BOP_PAYEE_USERS]: [
    columnsByName[fieldNames.NAME],
    columnsByName[fieldNames.COMPANY_NAME],
    columnsByName[fieldNames.ROLE],
    columnsByName[fieldNames.EMAIL],
    columnsByName[fieldNames.STATUS],
  ],
  [variants.PAYEE_USERS]: [
    columnsByName[fieldNames.NAME],
    columnsByName[fieldNames.ROLE],
    columnsByName[fieldNames.EMAIL],
    columnsByName[fieldNames.STATUS],
  ],
};

/** The user's role. */
const rolePropType = arrayOf(
  shape({
    name: oneOf([
      UserRoles.PayorMaster,
      UserRoles.PayorAdmin,
      UserRoles.PayorSupport,
    ]),
  })
);

const AdditionalDataPropsMap = {
  [variants.BOP_ADMINS]: {},
  [variants.PAYOR_USERS]: {
    roles: rolePropType,
  },
  [variants.BOP_PAYOR_USERS]: {
    roles: rolePropType,
    entity: object.isRequired,
  },
  [variants.BOP_PAYEE_USERS]: {
    companyName: string.isRequired,
  },
};

const Config = {
  columns: fieldNames,
  filtersByName,
  getRowProps: (item, index, _, { onClick }) => ({
    key: item.id,
    icon: 'chevron_right',
    onClick: () => onClick(item),
    'data-testid': dataTestIdBuilder(TestIds.ROW, index),
  }),
  getColumnProps: (col, data, row) => ({
    key: col.name,
    children: cellFactoryByFieldName[col.name](data),
    'data-testid': dataTestIdBuilder(TestIds.ROW, row, col.name),
  }),
  filters: [],
  sortableColumns: [fieldNames.EMAIL],
};

const getDataProps = (additional) => ({
  /** An array of user details. */
  data: arrayOf(
    shape({
      /** The unique user ID. */
      id: string.isRequired,
      /** The user's first name. */
      firstName: string,
      /** The user's last name. */
      lastName: string,
      /** The user's email adddress. */
      email: string.isRequired,
      /** The status of the user */
      status: oneOf(Object.values(UserStatus)),
      /** Additional user properties. */
      ...additional,
    })
  ).isRequired,
  /** Called when a user is selected. */
  onClick: func.isRequired,
});

const buildTable = (variant) => {
  const { Table, Headings, Loading, Empty, Error, ...other } = VeloTableBuilder(
    {
      columnOrder: ColumnOrderMap[variant],
      dataProps: getDataProps(AdditionalDataPropsMap[variant]),
      ...Config,
    }
  );

  return {
    ...other,
    Table: (props) => <Table {...props} data-testid={TestIds.TABLE} />,
    Headings: (props) => <Headings {...props} data-testid={TestIds.HEADING} />,
    Loading: (props) => (
      <Loading
        {...props}
        getRowProps={() => ({ 'data-testid': TestIds.LOADING })}
      />
    ),
    Empty: (props) => (
      <Empty {...props} data-testid={TestIds.EMPTY}>
        No users found
      </Empty>
    ),
    Error: (props) => <Error {...props} data-testid={TestIds.ERROR} />,
    testIds: TestIds,
  };
};

export const PayorUsersList = buildTable(variants.PAYOR_USERS);
export const BOPPayorUsersList = {
  ...buildTable(variants.BOP_PAYOR_USERS),
  filters: [filtersByName[fieldNames.ENTITY], filtersByName[fieldNames.STATUS]],
};
export const BOPAdminsList = buildTable(variants.BOP_ADMINS);
export const BOPPayeeUsersList = {
  ...buildTable(variants.BOP_PAYEE_USERS),
  filters: [
    {
      ...filtersByName[fieldNames.ENTITY],
      label: <FormattedMessage defaultMessage="Company Payee" />,
      mode: LookupTextField.modes.PAYEE,
    },
    filtersByName[fieldNames.STATUS],
  ],
};
export const PayeeUsersList = {
  ...buildTable(variants.PAYEE_USERS),
  filters: [filtersByName[fieldNames.STATUS]],
};
