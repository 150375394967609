import React, { useEffect, useState } from 'react';
import { array, func, object, string } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styled from 'astroturf/react';
import TestIds from './testIds';
import {
  BasicCurrency,
  FreeTextEntryWithinRange,
} from '../FormFields/FormFieldTypes';
import { VeloSelect } from '../VeloSelect';
import { SelectCurrency } from '../FormFields/SelectCurrency';
import { LookupTextField } from '../LookupTextField';
import { VeloStepper } from '../VeloStepper';
import { formatPayment, SinglePayoutForm } from './SinglePayoutForm';
import { VeloFieldGrid } from '../VeloFieldGrid';
import { StepContent } from './StepContent';

const FieldGrid = styled(VeloFieldGrid)`
  padding: 0;
`;

SingleAdvancedCreateSteps.propTypes = {
  /** callback triggered on form submission. */
  onSubmit: func.isRequired,
  /** preselected currency option. */
  defaultCurrency: string.isRequired,
  /** available currencies to pay in. */
  currencyLabelList: array.isRequired,
  /** payor source accounts. */
  sourceAccountNameList: array.isRequired,
  /** handler to call on selecting a sourceAccount*/
  onSourceAccountSelected: func.isRequired,
  /** payor source transmission Types map of labels. */
  transmissionTypesMap: object.isRequired,
  /** function to retrieve payee results. */
  fetchPayeeResults: func.isRequired,
};

function getSourceAccountField(options, { sourceAccountName, fromPayor }) {
  const baseProps = {
    name: 'sourceAccountName',
    value: sourceAccountName,
  };
  if (options.length === 0) {
    return {
      ...baseProps,
      Component: VeloSelect.Loading,
    };
  }

  return {
    ...baseProps,
    Component: VeloSelect,
    label: <FormattedMessage defaultMessage="Source account" />,
    options,
    disabled: !fromPayor,
    required: true,
  };
}

function SingleAdvancedCreateSteps(props) {
  const {
    defaultCurrency,
    defaultTransmissionType,
    currencyLabelList,
    onSourceAccountSelected,
    sourceAccountNameList,
    transmissionTypesMap,
    fetchPayeeResults,
    onSubmit,
    fromOptions,
    toOptions,
    onPayorFromChange,
    onPayorToChange,
    error,
  } = props;

  const [paymentState, setPaymentState] = useState({
    payoutMemo: '',
    currency: defaultCurrency,
    transmissionType: defaultTransmissionType.value,
    amount: '',
    payorPaymentId: '',
  });

  const [payoutFromState, setPayoutFromState] = useState({
    sourceAccountName: '',
    fromPayor: '',
  });

  const [payoutToState, setPayoutToState] = useState({
    remoteId: '',
    payeeName: '',
    toPayor: '',
  });

  // get values from States
  const { fromPayor, sourceAccountName } = payoutFromState;
  const { toPayor, ...payeeDetails } = payoutToState;

  //Enable steps on multi input state values
  const step3Enabled = Boolean(
    fromPayor && sourceAccountName && toOptions.length
  );
  const step4Enabled = Boolean(
    step3Enabled && toPayor && payeeDetails.payeeName
  );

  // When switching sourceAccounts Selected Currency may no longer be available, so reset to defaultCurrency provided
  useEffect(() => {
    const selectedCurrentIsAvailable = currencyLabelList.find(
      (value) => value === paymentState.currency
    );
    if (!selectedCurrentIsAvailable) {
      setPaymentState((s) => ({ ...s, currency: defaultCurrency }));
    }
  }, [defaultCurrency, currencyLabelList, paymentState.currency]);

  useEffect(
    () => {
      if (fromPayor) {
        onPayorFromChange(fromPayor);
        setPayoutFromState((s) => ({ ...s, sourceAccountName: '' }));
        // ensure the clearing is updated - for filtering currencies
        onSourceAccountSelected(null);

        // Payor from may not have the transmission type pre selected reset to default to prevent accidental setting failure
        setPaymentState((s) => ({
          ...s,
          transmissionType: defaultTransmissionType.value,
        }));
        if (toPayor && toPayor !== fromPayor) {
          setPayoutToState({
            remoteId: '',
            payeeName: '',
            toPayor: '',
          });
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [payoutFromState.fromPayor]
  );

  useEffect(
    () => {
      // Clear To Details for Payee
      setPayoutToState({
        ...payoutToState,
        remoteId: '',
        payeeName: '',
      });

      if (toPayor) {
        onPayorToChange(toPayor);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [payoutToState.toPayor]
  );

  const onSubmitHandler = (evt) => {
    evt.preventDefault();
    const payment = {
      ...paymentState,
      ...payeeDetails,
      sourceAccountName: payoutFromState.sourceAccountName,
    };
    onSubmit({
      payoutMemo: paymentState.payoutMemo,
      payments: [formatPayment(payment)],
    });
  };

  // Utility onChange handler for all react states
  const onChange = (evt, setStateFn) => {
    const { name, value, remoteId } = evt.target;
    if (name === 'sourceAccountName') {
      onSourceAccountSelected(value);
    }
    const isLookup = name === 'payeeName';
    const lookupState = isLookup ? { remoteId: remoteId } : {};
    setStateFn((s) => ({ ...s, ...lookupState, [name]: value }));
  };

  //Step 2 - From Payor
  const fromSections = [
    {
      fields: [
        {
          Component: VeloSelect,
          label: <FormattedMessage defaultMessage="Payor (from)" />,
          name: 'fromPayor',
          value: fromPayor,
          options: fromOptions,
          required: true,
        },
        getSourceAccountField(sourceAccountNameList, payoutFromState),
      ],
    },
  ];

  //Step 3 - To Payor
  const toSections = [
    {
      fields: [
        {
          Component: VeloSelect,
          label: <FormattedMessage defaultMessage="Payor (to)" />,
          name: 'toPayor',
          value: toPayor,
          options: toOptions,
          required: true,
        },
        {
          Component: LookupTextField,
          helpText: {
            children: ' ',
          },
          label: <FormattedMessage defaultMessage="Payee" />,
          value: payeeDetails.payeeName,
          entityId: payeeDetails.remoteId,
          name: 'payeeName',
          fetchResults: fetchPayeeResults,
          disabled: !toPayor,
          required: true,
        },
      ],
    },
  ];

  //Step 4 - Payment Form
  const paymentSections = [
    {
      fields: [
        {
          name: 'payoutMemo',
          'data-testid': TestIds.SINGLE_PAYOUT_PAYEE_MEMO,
          ...FreeTextEntryWithinRange(
            <FormattedMessage defaultMessage="Payout memo (recommended)" />,
            1,
            40
          ),
          value: paymentState.payoutMemo,
        },
        {
          Component: SelectCurrency,
          name: 'currency',
          'data-testid': TestIds.SINGLE_PAYOUT_CURRENCIES,
          helpText: (
            <FormattedMessage defaultMessage="Select available currency" />
          ),
          currencies: currencyLabelList,
          value: paymentState.currency,
          desktop: 4,
          tablet: 4,
          phone: 4,
          required: true,
          // MDC bug fix when re-setting list contents index remains and can be out of bounds
          key: currencyLabelList.length,
        },
        {
          name: 'amount',
          'data-testid': TestIds.SINGLE_PAYOUT_AMOUNT,
          value: paymentState.amount,
          ...BasicCurrency(
            <FormattedMessage defaultMessage="Payment amount" />,
            <FormattedMessage defaultMessage="Please enter a valid payment amount" />
          ),
          desktop: 8,
          tablet: 8,
          phone: 8,
          required: true,
        },
        {
          Component: VeloSelect,
          label: <FormattedMessage defaultMessage="Transmission type" />,
          helpText: (
            <FormattedMessage defaultMessage="Additional charges may be incurred for some transmission types" />
          ),
          name: 'transmissionType',
          'data-testid': TestIds.SINGLE_PAYOUT_TRANSMISSION_TYPE,
          value: paymentState.transmissionType,
          options: transmissionTypesMap[payoutFromState.sourceAccountName],
          required: false,
        },
        {
          name: 'payorPaymentId',
          'data-testid': TestIds.SINGLE_PAYOUT_PAYMENT_ID,
          value: paymentState.payorPaymentId,
          ...FreeTextEntryWithinRange(
            <FormattedMessage defaultMessage="Unique payment ID" />,
            1,
            40
          ),
        },
      ],
    },
  ];

  return (
    <>
      <VeloStepper.Step>
        <VeloStepper.Label
          icon="2"
          text={
            <FormattedMessage defaultMessage="From which source account is this payout being made?" />
          }
        />
        <StepContent>
          <FieldGrid
            sections={fromSections}
            onChange={(evt) => onChange(evt, setPayoutFromState)}
          />
        </StepContent>
      </VeloStepper.Step>
      <VeloStepper.Step>
        <VeloStepper.Label
          disabled={!step3Enabled}
          icon="3"
          text={
            <FormattedMessage defaultMessage="To which payee is this payout being made?" />
          }
        />
        <StepContent disabled>
          {step3Enabled && (
            <FieldGrid
              sections={toSections}
              onChange={(evt) => onChange(evt, setPayoutToState)}
            />
          )}
        </StepContent>
      </VeloStepper.Step>
      <VeloStepper.Step>
        <VeloStepper.Label
          disabled={!step4Enabled}
          icon="4"
          text={
            <FormattedMessage defaultMessage="Add an optional descriptive memo and complete the details below to make an individual payment" />
          }
        />
        <StepContent>
          {step4Enabled && (
            <SinglePayoutForm
              onSubmit={onSubmitHandler}
              onChange={(evt) => onChange(evt, setPaymentState)}
              sections={paymentSections}
              breakpoint={600}
              error={error}
            />
          )}
        </StepContent>
      </VeloStepper.Step>
    </>
  );
}

export { SingleAdvancedCreateSteps };
