import React from 'react';
import { bool } from 'prop-types';
import { VeloStatusIndicator } from '../VeloStatusIndicator';
import { FormattedMessage } from 'react-intl';

PayableStatusIndicator.propTypes = {
  /** The payable status of the payee */
  payableStatus: bool.isRequired,
};

const statuses = {
  [true]: {
    type: VeloStatusIndicator.types.SUCCESS,
    label: <FormattedMessage defaultMessage="Payable" />,
  },
  [false]: {
    type: VeloStatusIndicator.types.WARNING,
    label: <FormattedMessage defaultMessage="Not payable" />,
  },
};

function PayableStatusIndicator({ payableStatus, ...props }) {
  return <VeloStatusIndicator {...statuses[payableStatus]} {...props} />;
}

export { PayableStatusIndicator };
