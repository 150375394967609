import React from 'react';
import styled from 'astroturf/react';
import { FormattedMessage } from 'react-intl';
import { VeloCallout } from '../VeloCallout';
import { VeloSectionGrid } from '../VeloSectionGrid';
import { VeloTypography } from '../VeloTypography';

const Callout = styled(VeloCallout)`
  margin-bottom: 1rem;
`;

const ErrorTitle = styled(VeloTypography)`
  @import 'velo-variables';
  color: velo-color('token-color-system-error-default');
`;

function ConfirmPayoutError({ error }) {
  return (
    <Callout icon={false} type={VeloCallout.types.ERROR}>
      <VeloSectionGrid
        compact
        sections={[
          {
            fields: [
              <ErrorTitle use="bodyTextEmphasised">
                <FormattedMessage defaultMessage="Payout failed" />
              </ErrorTitle>,
              <VeloTypography use="bodyTextEmphasised">{error}</VeloTypography>,
              <VeloTypography use="bodyTextEmphasised">
                <FormattedMessage defaultMessage="You have been securely logged out from your bank." />
              </VeloTypography>,
            ],
          },
        ]}
        render={(component) => component}
      />
    </Callout>
  );
}

export { ConfirmPayoutError };
