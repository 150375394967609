import React from 'react';
import { AdvancedForm, VeloNotification } from 'velo-react-components';
import { usePresenter } from '../../hooks';
import { SettingsAdvancedPresenter } from './SettingsAdvancedPresenter';
import { SettingsContext, LocaleContext } from '../../context';

function AdvancedFormComponent(context) {
  const fixedProps = {
    successNotification: VeloNotification.types.EDIT_ADVANCED_SETTING,
  };
  const props = usePresenter(SettingsAdvancedPresenter, {}, fixedProps, {
    ...context,
  });

  return <AdvancedForm {...props} />;
}

export function SettingsAdvancedRoute() {
  return (
    <LocaleContext.Consumer>
      {(localeContext) => (
        <SettingsContext.Consumer>
          {(context) => (
            <AdvancedFormComponent {...context} {...localeContext} />
          )}
        </SettingsContext.Consumer>
      )}
    </LocaleContext.Consumer>
  );
}
