import React from 'react';
import { func, node, bool, object } from 'prop-types';
import { VeloIconButton } from '../../VeloIconButton';
import { VeloModalSheet } from '../../VeloModalSheet';
import { PaymentViewTabs } from '../PaymentViewTabs';

const TestIds = {
  CLOSE_BUTTON: 'payment-view-loading-close-button',
};
PaymentViewLoading.testIds = TestIds;

PaymentViewLoading.propTypes = {
  /** Called when the close button is clicked. */
  onClose: func,
  /** The components to render in the flyout body. */
  children: node.isRequired,
  /** The tabs to render beneath the header. */
  tabs: bool,
  /** Optional props to be spread into the close button */
  closeButtonProps: object,
};

PaymentViewLoading.defaultProps = {
  closeButtonProps: {},
};

const getCloseButtonProps = (closeButtonProps) => ({
  'data-testid': TestIds.CLOSE_BUTTON,
  title: 'Close payment view',
  ...closeButtonProps,
});

/**
 * Render a Payment View in the loading state.
 */
function PaymentViewLoading({ children, onClose, tabs, closeButtonProps }) {
  return (
    <>
      <VeloModalSheet.Header divider={!tabs}>
        <VeloModalSheet.HeaderTitle skeleton />
        <VeloIconButton
          {...getCloseButtonProps(closeButtonProps)}
          icon="close"
          onClick={onClose}
        />
      </VeloModalSheet.Header>
      {tabs && <PaymentViewTabs skeleton />}
      <VeloModalSheet.Body>{children}</VeloModalSheet.Body>
    </>
  );
}

export { PaymentViewLoading };
