import React from 'react';
import { object, string } from 'prop-types';
import styled from 'astroturf/react';
import { Radio } from '@rmwc/radio';
import '@material/radio/dist/mdc.radio.css';
import '@material/form-field/dist/mdc.form-field.css';

const StyledRadio = styled('div')`
  @import 'velo-variables';
  display: inline-block;

  :global(.mdc-form-field) {
    color: velo-color('token-color-text-default');
    font-family: var(--ds-typography-font-family);
  }
`;

VeloRadio.propTypes = {
  /** Props to apply to the root element. */
  rootProps: object,
  /** The component test ID. */
  'data-testid': string.isRequired,
};

VeloRadio.defaultProps = {
  rootProps: {},
};

/**
 * Velo-themed Radio button.
 *
 * RMWC does not allow a custom class to be applied to the root
 * element, so we wrap the <Radio> in a div and apply the class
 * here instead. All other props are passed down. To add props to
 * the root element use `rootProps`.
 */
function VeloRadio({ rootProps, ...other }) {
  return (
    <StyledRadio {...rootProps}>
      <Radio {...other} />
    </StyledRadio>
  );
}

export { VeloRadio };
