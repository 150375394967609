import { useState, useEffect, useCallback } from 'react';

/**
 * Hook that can be used to only render an RMWC `Menu`
 * component when it is visible.
 *
 * See `VeloPhoneNumberField.js` for usage.
 */
const useMenuManager = (open, onCloseMenu) => {
  const [mounted, setMounted] = useState(open);

  useEffect(() => {
    if (open) {
      setMounted(true);
    }
  }, [open]);

  const onClose = useCallback(() => {
    setMounted(false);
    onCloseMenu();
  }, [onCloseMenu]);

  return [mounted, onClose];
};

export { useMenuManager };
