import React from 'react';
import { func, bool } from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import styled from 'astroturf/react';
import omit from 'just-omit';
import { PageHeader } from '../PageHeader';
import { VeloFab } from '../VeloFab';
import { VeloSplitFab } from '../VeloSplitFab';
import { VeloIcon } from '../VeloIcon';
import { Content } from '../Content';
import { PayoutTypes } from '../PayoutTypes';
import { VeloTabbedPageView } from '../VeloTabbedPageView';

const Icon = styled(VeloIcon)`
  margin-right: 10px;
`;

PayoutsPage.propTypes = {
  onCreatePayout: func,
  advanced: bool,
  openBankingEnabled: bool,
};

function useNavOptions(advanced, openBankingEnabled) {
  const intl = useIntl();
  const createPayoutLabel = intl.formatMessage({
    defaultMessage: 'Create Payout',
  });

  const createPayoutOnbehalfOfLabel = intl.formatMessage({
    defaultMessage: 'Create payout for another Payor',
  });

  const createPayoutOpenBankingLabel = intl.formatMessage({
    defaultMessage: 'Create a payout via Open Banking',
  });

  return [
    {
      id: PayoutTypes.TYPE.STANDARD,
      icon: {
        icon: 'payouts',
        title: createPayoutLabel,
      },
      label: createPayoutLabel,
    },
    ...(advanced
      ? [
          {
            id: PayoutTypes.TYPE.ADVANCED,
            icon: {
              icon: 'payout_on_behalf_of',
              title: createPayoutOnbehalfOfLabel,
            },
            label: createPayoutOnbehalfOfLabel,
          },
        ]
      : []),
    ...(openBankingEnabled
      ? [
          {
            id: PayoutTypes.TYPE.OPEN_BANKING,
            icon: {
              icon: 'accounts',
              title: createPayoutOnbehalfOfLabel,
            },
            label: createPayoutOpenBankingLabel,
          },
        ]
      : []),
  ];
}

export function PayoutsPage({
  children,
  onCreatePayout,
  advanced,
  openBankingEnabled,
  ...props
}) {
  const intl = useIntl();
  const fabProps = FabConstants(intl);
  const navOptions = useNavOptions(advanced, openBankingEnabled);
  const enableSplitFab = advanced || openBankingEnabled;

  const closeMenuTitle = intl.formatMessage({
    defaultMessage: 'Payouts Menu Close',
  });

  const openMenuTitle = intl.formatMessage({ defaultMessage: 'Payouts Menu' });

  const fab = enableSplitFab ? (
    <VeloSplitFab
      {...fabProps}
      onPrimary={onCreatePayout}
      onSelectOption={onCreatePayout}
      options={navOptions.map(({ icon, ...rest }) => ({
        ...rest,
        icon: <Icon {...icon} />,
      }))}
      data-testid={VeloFab.testIdBuilder(fabProps.label)}
    />
  ) : (
    <VeloFab
      {...fabProps}
      onClick={onCreatePayout}
      data-testid={VeloFab.testIdBuilder(fabProps.label)}
    />
  );

  const mobileFab = enableSplitFab ? (
    <VeloSplitFab.Mobile
      options={navOptions}
      onClick={onCreatePayout}
      closeMenuTitle={closeMenuTitle}
      openMenuTitle={openMenuTitle}
    />
  ) : (
    <VeloFab.Mobile
      id={fabProps.id}
      title={fabProps.label}
      onClick={onCreatePayout}
      data-testid={VeloFab.testIdBuilder(fabProps.label)}
      icon={{
        ...fabProps.icon,
        icon: 'add',
      }}
    />
  );

  return (
    <Content
      header={
        <PageHeader
          title={<FormattedMessage defaultMessage="Payouts" />}
          fab={onCreatePayout && fab}
        />
      }
      footer={onCreatePayout && mobileFab}
    >
      {children}
    </Content>
  );
}

function PayoutsTabbedPageView({ children, ...props }) {
  return (
    <VeloTabbedPageView
      {...props}
      tabs={[
        <FormattedMessage defaultMessage="All Payouts" />,
        <FormattedMessage defaultMessage="Scheduled Payouts" />,
      ]}
    >
      {children}
    </VeloTabbedPageView>
  );
}

PayoutsTabbedPageView.propTypes = omit(VeloTabbedPageView.propTypes, ['tabs']);

PayoutsPage.TabbedPageView = PayoutsTabbedPageView;

const FabConstants = (intl) => {
  const label = intl.formatMessage({ defaultMessage: 'Create Payout' });
  return {
    title: intl.formatMessage({ defaultMessage: 'Payouts' }),
    label,
    icon: {
      icon: 'payouts',
      title: label,
    },
  };
};
