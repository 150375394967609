import React, { useEffect } from 'react';
import { arrayOf, func, node, number } from 'prop-types';
import styled from 'astroturf/react';
import { VeloTab } from '../VeloTab';
import { VeloDivider } from '../VeloDivider';
import { VeloCard } from '../VeloCard';

const root = 'velo-tabbed-page-view';

const TestIds = {
  TAB: `${root}-tab`,
};

const Tab = styled(VeloTab)`
  text-transform: none;
  flex: 0 1 auto;
`;

// Provide a padded border for all content
const PaddedVeloCard = styled(VeloCard)`
  padding: 1rem;
`;

const ContentBody = styled('div')`
  padding-top: 1rem;
`;

VeloTabbedPageView.propTypes = {
  // The card header - optional
  header: node,
  // The tabs labels
  tabs: arrayOf(node).isRequired,
  // The currently active tab
  activeTabIndex: number.isRequired,
  // Called when the tab is changed. Passed the new active tab index.
  onActivate: func.isRequired,
};

/**
 * Render a `VeloCard` containing tabs.
 * Simplfied and not tied to a Form as per VeloTabbedView
 * No Loading/Error states as this is defered to the Tabs content
 * Component to render.
 */
function VeloTabbedPageView({
  header,
  tabs,
  activeTabIndex,
  onActivate,
  children,
}) {
  useEffect(() => {
    document.documentElement.scrollIntoView();
  }, []);

  return (
    <PaddedVeloCard fullWidth={true} fullScreen={false}>
      {/* The card header. */}
      {header && (
        <VeloCard.Header divider={false}>
          <VeloCard.HeaderTitle>{header}</VeloCard.HeaderTitle>
        </VeloCard.Header>
      )}
      {/* The tabs. */}
      <VeloTab.Bar
        activeTabIndex={activeTabIndex}
        onActivate={(event) => onActivate(event.detail.index)}
      >
        {tabs.map((label, index) => {
          // An ID is required for testing
          const id = `${TestIds.TAB}-${index + 1}`;
          return (
            <Tab key={index} id={id} data-testid={id}>
              {label}
            </Tab>
          );
        })}
      </VeloTab.Bar>
      <VeloDivider />
      {/* The currently active tab. */}
      <VeloCard.Body>
        <ContentBody key={activeTabIndex}>{children}</ContentBody>
      </VeloCard.Body>
    </PaddedVeloCard>
  );
}

VeloTabbedPageView.testIds = TestIds;

export { VeloTabbedPageView };
