import { useState, useCallback } from 'react';
import { useDebouncedResize } from './useDebouncedResize';

// Custom hook to monitor an element for a breakpoint trigger
const useElementBreakpoint = (element, breakpoint) => {
  const [triggered, setTriggered] = useState(false);

  // This will be called when the component mounts or the element/
  // breakpoint are changed.
  const effect = useCallback(() => {
    // Note that element.current.offsetWidth will be 0 when
    // running tests. By avoiding updating the state we can
    // avoid mocking this hook out to avoid nasty `act` warnings.
    if (element.current && element.current.offsetWidth > 0) {
      setTriggered(element.current.offsetWidth < breakpoint);
    }
  }, [element, breakpoint]);

  useDebouncedResize(effect);
  return triggered;
};

export { useElementBreakpoint };
