export function resendToken(send) {
  return (userId, body, ...args) =>
    send(
      {
        path: '/v2/users/{userId}/tokens',
        pathParameters: { userId },
        type: 'post',
        query: undefined,
        body: body,
        headers: undefined,
        auth: 'oauth2',
        contentType: 'application/json',
      },
      ...args
    );
}
