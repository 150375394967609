import { bool, string, shape, arrayOf, number, oneOf, node } from 'prop-types';
import { navigationDrawerConfig } from './navigationDrawerConfig';

const subMenuItem = shape({
  /** The icon to be displayed with the sub menu item */
  icon: string,
  /** The id of the sub menu item */
  id: string,
  /** The label of the sub menu item */
  label: node.isRequired,
  /** The order position of the menu item */
  order: number,
  /** The parent of the sub menu item */
  parent: string,
  /** The route for the sub menu item action */
  route: string,
});

const subMenuItems = arrayOf(subMenuItem);

const navigationItem = shape({
  /* id of the action item */
  id: string,
  /* the label to be displayed */
  label: node.isRequired,
  /* the icon to be displayed */
  icon: string,
  /* the group in which to render the item */
  group: oneOf(
    Object.values(navigationDrawerConfig.NavigationItemGroupMapping)
  ),
  /* if the item should appear in a sub menu in collapsed mode */
  submenu: bool,
  /* the primary item to trigger the sub menu */
  legalLinksPrimaryItem: bool,
  /* configure whether the group has top or bottom dividers rendered */
  hasDividers: shape({
    /** Whether there is a bottom border */
    bottom: bool,
    /** Whether there is a top border */
    top: bool,
  }),
  /** The array of sub menu items */
  subMenuItems: arrayOf(subMenuItem),
  /** Whether the menu item should be opened in a new browser tab */
  openInNewTab: bool,
});

const subMenuItemParent = shape({
  /** The id of the sub menu item parent */
  id: string,
  /** The label of the sub menu item parent */
  label: node,
  /** The sub menu items of the sub menu item parent */
  subItems: arrayOf(subMenuItem),
});
export default { navigationItem, subMenuItem, subMenuItems, subMenuItemParent };
