import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'astroturf/react';
import { VeloIcon } from '../VeloIcon';
import { VeloGrid } from '../VeloGrid';
import { VeloTextField } from '../VeloTextField';
import { FreeTextEntryWithinRange } from '../FormFields/FormFieldTypes';
import { VeloFileUpload } from '../VeloFileUpload';
import { VeloTextButton } from '../VeloTextButton';
import { VeloTypography } from '../VeloTypography';
import { GroupCsvInstructions } from './GroupCsvInstructions';
import TestIds from './testIds';

const StyledError = styled('div')`
  @import 'velo-variables';
  display: flex;
  background-color: velo-color('token-color-system-error-lighter');
  padding: 1rem;
  margin: 0.5rem 0;
  border-radius: 4px;
  justify-content: center;
`;

const DragAndDropGrid = styled('div')`
  padding: 0;
  margin: 0;
`;

const PayoutInstructions = styled(VeloTypography)`
  margin: 0 0 1.3rem;
`;

const FooterLinks = styled('footer')`
  display: flex;
  justify-content: space-between;
`;
const FooterButton = styled(VeloTextButton)`
  align-self: flex-end;
`;

const Error = (props) => (
  <StyledError>
    <VeloTypography use="bodyText" {...props} />
  </StyledError>
);

function GroupCsvUpload({ onSubmit, downloadSampleCsv, error }) {
  const [payoutMemo, setPayoutMemo] = useState('');
  const [showInstructions, setShowInstructions] = useState(false);

  const onFileAdded = (paymentsFiles) => {
    onSubmit({ paymentsFiles, payoutMemo });
  };
  const onShowInstructions = () => {
    setShowInstructions(!showInstructions);
  };

  return (
    <section>
      <DragAndDropGrid desktop={6} tablet={8} phone={4}>
        <VeloGrid.Cell span={12}>
          <VeloTextField
            {...FreeTextEntryWithinRange(
              <FormattedMessage defaultMessage="Payout memo (recommended)" />,
              1,
              40
            )}
            data-testid={TestIds.GROUP_PAYOUT_PAYEE_MEMO}
            value={payoutMemo}
            onChange={(field) => setPayoutMemo(field.target.value)}
          />
        </VeloGrid.Cell>
        <VeloGrid.Cell span={12}>
          <PayoutInstructions use="bodyTextEmphasised" tag="h4">
            <FormattedMessage defaultMessage="Upload your group payout CSV" />
          </PayoutInstructions>
          <VeloFileUpload
            buttonLabel={<FormattedMessage defaultMessage="Browse for file" />}
            dragDropLabel={
              <FormattedMessage defaultMessage="Drag & drop CSV file to upload" />
            }
            onAdded={(files) => onFileAdded(files)}
            acceptedFileFormats={'.csv'}
          />
        </VeloGrid.Cell>
      </DragAndDropGrid>
      <FooterLinks>
        <FooterButton
          onClick={onShowInstructions}
          icon={
            <VeloIcon icon={showInstructions ? 'chevron_up' : 'chevron_down'} />
          }
          padded
          data-testid={TestIds.GROUP_SHOW_INSTRUCTIONS_CSV}
        >
          <FormattedMessage defaultMessage="CSV instructions" />
        </FooterButton>
        <FooterButton
          padded
          onClick={() => downloadSampleCsv()}
          icon={<VeloIcon icon="arrow_downward" />}
          data-testid={TestIds.GROUP_DOWNLOAD_SAMPLE_CSV}
        >
          <FormattedMessage defaultMessage="Download sample CSV file" />
        </FooterButton>
      </FooterLinks>
      {error && <Error data-testid={TestIds.GROUP_PAYOUT_ERROR}>{error}</Error>}
      <GroupCsvInstructions visible={showInstructions} />
    </section>
  );
}

export { GroupCsvUpload };
