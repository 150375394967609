const findPayeeRule = (
  isoCountryCode,
  payeeCountryRules,
  propName,
  defaults
) => {
  const rule = payeeCountryRules.find(
    (rule) => rule.isoCountryCode === isoCountryCode
  );
  return rule ? rule[propName][0] : defaults;
};

const payeeSsn = (isoCountryCode, payeeCountryRules) =>
  findPayeeRule(isoCountryCode, payeeCountryRules, 'individualRules', {
    displayName: 'National Identification Number',
    validation: '^.{6,30}$',
    minLength: 6,
    maxLength: 30,
    description: '6-30 characters',
  });

const payeeSsnLabel = (isoCountryCode, payeeCountryRules) =>
  payeeSsn(isoCountryCode, payeeCountryRules).displayName;

const payeeTaxId = (isoCountryCode, payeeCountryRules) =>
  findPayeeRule(isoCountryCode, payeeCountryRules, 'companyRules', {
    displayName: 'Company Tax ID',
    validation: '.*\\S+.*',
    minLength: 1,
    maxLength: 30,
    description: '1-30 characters',
  });

const payeeTaxIdLabel = (isoCountryCode, payeeCountryRules) =>
  payeeTaxId(isoCountryCode, payeeCountryRules).displayName;

export { payeeSsn, payeeSsnLabel, payeeTaxId, payeeTaxIdLabel };
