import React, { useCallback, useState } from 'react';
import {
  arrayOf,
  bool,
  node,
  oneOf,
  shape,
  number,
  func,
  string,
} from 'prop-types';
import styled from 'astroturf/react';
import { VeloGrid } from '../VeloGrid';
import { VeloCollapsibleSection } from '../VeloCollapsibleSection';
import { useKeyboardNavigation } from '../hooks';

function Section({ heading, initiallyOpen = false, tag, children, ...other }) {
  const [open, setOpen] = useState(initiallyOpen);
  return (
    <VeloCollapsibleSection
      label={heading}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      {...other}
    >
      <VeloGrid compact>
        <VeloGrid.Inner tag={tag}>{children}</VeloGrid.Inner>
      </VeloGrid>
    </VeloCollapsibleSection>
  );
}

const Callout = styled('div')`
  @import 'velo-variables';

  &.type-warning,
  &.type-failed {
    margin: 0rem 0rem 0rem -0.625rem;
    padding: 0.625rem 0rem 0.625rem 0.625rem;
  }

  &.type-warning {
    background-color: velo-color('token-color-system-warning-lighter');
  }

  &.type-failed {
    background-color: velo-color('token-color-system-error-lighter');
  }
`;

Callout.types = {
  SUCCESS: 'success',
  WARNING: 'warning',
  FAILED: 'failed',
};

Callout.propTypes = {
  type: oneOf(Object.values(Callout.types)),
};

VeloAccordianGrid.propTypes = {
  /** The sections to render. */
  sections: arrayOf(
    shape({
      /** The section heading. */
      heading: node,
      /** A data test id to target the expandable section. */
      'data-testid': string,
      /** The initial expanded state. */
      initiallyOpen: bool,
      /** Called when the Edit button is clicked. */
      onEdit: func,
      /** The HTML tag to use for the section wrapper. Default is `div` */
      tag: string,
      /** The field to render. */
      fields: arrayOf(
        shape({
          /** The desktop span to use. Defaults to 12. */
          desktop: number,
          /** The tablet span to use. Defaults to 8, */
          tablet: number,
          /** The phone span to use. Defaults to 4. */
          phone: number,
        })
      ),
    })
  ),
  /**
   * A function that is passed a fields object, and is expected to return a
   * React element.
   */
  render: func.isRequired,
};

/**
 * Render a grid of components. Requires an array of sections that
 * contain fields, each of which is passed to the `render` function to return
 * a React element.
 *
 * Content can be group in collapsible sections.
 *
 * Each field will be rendered as a `VeloGrid.Cell` using a custom `span`. You
 * can pass the span to use for `desktop`, `tablet` and `mobile` to
 * achieve a custom responsive layout.
 */
function VeloAccordianGrid({ sections, render }) {
  const keyboardProps = useKeyboardNavigation(
    `.${VeloCollapsibleSection.root}`
  );

  const createField = useCallback(
    ({ desktop = 12, tablet = 8, phone = 4, ...field }, key) => (
      <VeloGrid.Cell key={key} desktop={desktop} tablet={tablet} phone={phone}>
        {render(field)}
      </VeloGrid.Cell>
    ),
    [render]
  );

  const createSection = useCallback(
    ({ fields, ...sectionProps }, index) => {
      const key = `section-${index}`;
      return (
        <Section key={key} id={key} {...sectionProps}>
          {fields.reduce(
            (children, field, index) => [
              ...children,
              createField(field, `${key}-field-${index}`),
            ],
            []
          )}
        </Section>
      );
    },
    [createField]
  );

  return <div {...keyboardProps}>{sections.map(createSection)}</div>;
}

VeloAccordianGrid.Callout = Callout;

export { VeloAccordianGrid };
