export function getSourceAccountStatusV3(send) {
  return (sourceAccountStatusId, ...args) =>
    send(
      {
        path: '/v3/sourceAccounts/status/{sourceAccountStatusId}',
        pathParameters: { sourceAccountStatusId },
        type: 'get',
        query: undefined,
        body: undefined,
        headers: undefined,
        auth: 'oauth2',
        contentType: undefined,
      },
      ...args
    );
}
