import React from 'react';
import styled from 'astroturf/react';
import { ChipSet, Chip } from '@rmwc/chip';
import '@material/chips/dist/mdc.chips.css';
import { bool } from 'prop-types';

const StyledChip = styled(Chip)`
  font-family: var(--ds-typography-font-family);
`;

export function VeloChip(props) {
  return <StyledChip {...props} />;
}

const StyledChipSet = styled(ChipSet)`
  &.slimline {
    padding: 0;
  }
`;

StyledChipSet.propTypes = {
  /**
   * Removes padding around the ChipSet
   */
  slimline: bool,
};

VeloChip.Set = StyledChipSet;
