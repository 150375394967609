import React from 'react';
import Money from 'js-money';
import { func, string } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { VeloFieldGrid } from '../VeloFieldGrid';
import { VeloForm } from '../VeloForm';

SinglePayoutForm.propTypes = {
  /** callback triggered on form submission. */
  onSubmit: func.isRequired,
  /** call back triggered on any field change */
  onChange: func.isRequired,
  /** optional error when present. */
  error: string,
};

function formatPayment({ payeeName, amount, currency, ...payment }) {
  return {
    ...payment,
    currency,
    //TODO convert this over to common util when available
    amount: Money.fromDecimal(amount, currency, 'round').amount,
  };
}

function SinglePayoutForm({ onSubmit, error, ...fieldGridProps }) {
  return (
    <VeloForm
      onSubmit={onSubmit}
      actionText={<FormattedMessage defaultMessage="Continue" />}
      disableHelperTextPadding={true}
      error={error}
      buttonProps={{ alignEnd: true }}
      autoComplete="off"
    >
      <VeloFieldGrid compact {...fieldGridProps} />
    </VeloForm>
  );
}

export { SinglePayoutForm, formatPayment };
