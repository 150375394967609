import React from 'react';
import { VeloRadioTileGroup } from '../VeloRadioTileGroup';

const gridConfig = {
  desktopSpan: 3,
  compact: true,
};

function BankRadioGroup({ selectedBank, onChange, institutions }) {
  return (
    <VeloRadioTileGroup
      {...gridConfig}
      value={selectedBank}
      onChange={onChange}
    >
      {institutions.map((bank) => (
        <VeloRadioTileGroup.ImageTile
          src={bank.iconUrl}
          title={bank.name}
          value={bank.id}
          key={bank.id}
        />
      ))}
    </VeloRadioTileGroup>
  );
}

function BankRadioGroupLoading() {
  const loadingItems = Array(8).fill(null);

  return (
    <VeloRadioTileGroup {...gridConfig} skeleton>
      {loadingItems.map((_, index) => (
        <VeloRadioTileGroup.ImageTile key={`bank-tile-${index}`} />
      ))}
    </VeloRadioTileGroup>
  );
}

BankRadioGroup.Loading = BankRadioGroupLoading;

export { BankRadioGroup };
