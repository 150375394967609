import { bool, oneOf } from 'prop-types';
import styled from 'astroturf/react';

const Status = {
  INFO: 'info',
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
  UNKNOWN: 'unknown',
};

const VeloBackground = styled('div')`
  @import 'velo-variables';
  background-color: velo-color('token-color-system-page-background');

  &.rounded {
    border-radius: 0.25rem;
  }

  &.status-info {
    background-color: velo-color('token-color-system-info-lighter');
  }

  &.status-warning {
    background-color: velo-color('token-color-system-warning-lighter');
  }

  &.status-success {
    background-color: velo-color('token-color-system-success-lighter');
  }

  &.status-unknown {
    background-color: velo-color('token-color-system-unknown');
  }

  &.status-error {
    background-color: velo-color('token-color-system-error-lighter');
  }
`;

VeloBackground.propTypes = {
  /**
   * Whether to show a rounded background
   */
  rounded: bool,
  /**
   * Optional status color to display
   */
  status: oneOf(Object.values(Status)),
};

VeloBackground.status = Status;

export { VeloBackground };
