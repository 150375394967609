import React from 'react';
import { FormattedMessage } from 'react-intl';
import { any, shape } from 'prop-types';
import { PaymentChannelTypes } from '../propTypes';
import { PaymentViewItem } from '../PaymentViewItem';
import { dataTestIdBuilder } from 'velo-data';
import { PaymentView } from '../PaymentView';

const root = PaymentView.root;

const TestIds = {
  NAME: `${root}-payment-channel-name`,
};

PaymentViewChannelDetails.root = root;

PaymentViewChannelDetails.propTypes = {
  /** The data to render. */
  result: shape(PaymentChannelTypes),

  paymentChannelRules: any,
};

/**
 * Render Payment Channel details.
 */
function PaymentViewChannelDetails({ result, paymentChannelRules }) {
  return (
    <>
      {/* Payment Channel name */}
      <PaymentViewItem
        label={<FormattedMessage defaultMessage="Payment Method Name" />}
        value={result.paymentChannelName}
        data-testid={TestIds.NAME}
      />

      {paymentChannelRules &&
        paymentChannelRules.map((rule, index) => (
          <PaymentViewItem
            key={index}
            label={rule.displayName}
            value={result[rule.element]}
            data-testid={dataTestIdBuilder(root, rule.element)}
          />
        ))}
    </>
  );
}

export { PaymentViewChannelDetails };
