import React from 'react';
import { bool, string } from 'prop-types';
import styled from 'astroturf/react';
import { VeloSkeleton } from '../VeloSkeleton';
import { VeloTypography } from '../VeloTypography';

const Text = styled(VeloTypography)`
  &.disabled {
    @import 'velo-variables';

    color: velo-color('token-color-text-disabled');
  }
`;

Text.propTypes = {
  disabled: bool,
};

VeloStepperText.propTypes = {
  /** Custom CSS class(es). */
  className: string,
  /** Disable the text. */
  disabled: bool,
  /** Render as a loading skeleton */
  skeleton: bool,
};

/**
 * The text that appears above each step in a vertical stepper.
 */
function VeloStepperText({ children, skeleton, ...other }) {
  return skeleton ? (
    <VeloSkeleton />
  ) : (
    <Text use="sectionHeader" tag="div" {...other}>
      {children}
    </Text>
  );
}

export { VeloStepperText };
