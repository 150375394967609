import React, {
  useRef,
  useCallback,
  useMemo,
  useState,
  useEffect,
} from 'react';
import { arrayOf, string, func, number, node, shape, object } from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { dataTestIdBuilder, isBelowBreakpoint, keyCodes } from 'velo-data';
import classnames from 'classnames';
import { VeloConfirmDiscardChangesContext } from '../VeloConfirmDiscardChangesContext';
import { ViewHeight } from '../ViewHeight';
import {
  NavigationDrawerTopAppBar,
  NavigationDrawerTopAppBarTitle,
  NavigationDrawerTopAppBarImage,
  NavigationDrawerTopAppBarFixedAdjust,
} from './NavigationDrawerTopAppBar';
import { NavigationDrawerOperatingAsDialog } from './NavigationDrawerOperatingAsDialog';
import { NavigationDrawerOperatingAsItem } from './NavigationDrawerOperatingAsItem';
import { Transitions } from '../Transitions';
import { navigationDrawerConfig } from './navigationDrawerConfig';
import navigationDrawerPropTypes from './navigationDrawerPropTypes';
import { VeloDrawer } from './VeloDrawer';
import { NavigationDrawerItem } from './NavigationDrawerItem';
import { NavigationDrawerFlyoutMenu } from './NavigationDrawerFlyoutMenu';
import { NavigationDrawerList } from './NavigationDrawerList';
import { NavigationItems } from './NavigationItems';
import { TopBarMode } from './NavigationDrawerTopBarMode';

import styles from './NavigationDrawer.module.scss';

const root = navigationDrawerConfig.NavigationDrawerRoot;
const TestIds = {
  ...VeloDrawer.testIds,
  ...NavigationDrawerOperatingAsItem.TestIds,
  DEFAULT_NAVIGATION_GROUP: `${root}-primary-menu-group`,
  FOOTER_NAVIGATION_GROUP: `${root}-footer-menu-group`,
  SIGNOUT_NAVIGATION_GROUP: `${root}-signout-menu-group`,
  PAGE: `${root}-page`,
  PAGE_TITLE: `${root}-page-title`,
};

const initialSubMenuProps = {
  isSubMenuOpen: false,
  selectedSubMenuParentId: undefined,
  selectedSubMenuParent: {
    id: undefined,
    subMenuItems: [],
  },
};

/**
 * @param {Array} navItems - The list of navigation items from config
 * @param {Object} selectedItem - The navItem which is selected
 * @param {String} subNavOpenId - The parent id of the sub nav which is open
 */
const getSubMenuProps = (navItems, selectedItem, subNavOpenId) => {
  // A sub menu item is the currently selected item
  if (selectedItem.parent && subNavOpenId === selectedItem.parent) {
    const parentItem = navItems.find((item) => item.id === selectedItem.parent);
    return {
      subNavOpenId,
      selectedSubMenuItemId: selectedItem.id,
      selectedSubMenuParentId: selectedItem.parent,
      selectedSubMenuLabel: parentItem.label,
      selectedSubMenuParent: {
        id: parentItem.id,
        subMenuItems: parentItem.subMenuItems,
      },
    };
    // The sub menu has been opened but no sub menu item is selected yet
    // The subNavOpenId is the parent id of the sub menu which is open
  } else if (!!subNavOpenId) {
    const parentId = subNavOpenId;
    const parentItem = navItems.find((item) => item.id === parentId);
    return {
      subNavOpenId,
      selectedSubMenuItemId: undefined,
      selectedSubMenuParentId: undefined,
      selectedSubMenuLabel: parentItem.label,
      selectedSubMenuParent: {
        id: parentItem.id,
        subMenuItems: parentItem.subMenuItems,
      },
    };
  }

  // Return empty initial sub menu props when no sub menu is active
  return initialSubMenuProps;
};

// TODO: Group the items on start up in the config file
const getGroupedItems = (navItems) =>
  navItems
    .sort((a, b) => a.order - b.order)
    .reduce((acc, item) => {
      const group = acc[item.group] || [];
      return { ...acc, [item.group]: [...group, item] };
    }, {});

/**
 * Selects the name of the current operating as payor
 *
 * @param {String} payorId - The payor id of the current operating as payor
 * @param {Array} operateAsPayors - The list of payors which the user can operate as
 */
const getPayorName = (payorId, operateAsPayors) => {
  const payor = operateAsPayors.find((payor) => payor.payorId === payorId);
  return payor.payorName;
};

/**
 * Selects props for the operate as another payor dialog
 *
 * @param {String} primaryPayorId - The payor id of the payor user
 * @param {String} operateAsPayorId - The payor id that the user is operating as
 * @param {Array} operateAsPayors - The list of payors that the user can operate as
 */
const getSelectedOperateAsPayors = (
  primaryPayorId,
  operateAsPayorId,
  operateAsPayors
) => {
  if (operateAsPayors && operateAsPayors.length > 0) {
    const activePayorId = operateAsPayorId || primaryPayorId;

    return {
      payorId: activePayorId,
      payorName: getPayorName(activePayorId, operateAsPayors),
      isLoggedInPayor: primaryPayorId === operateAsPayorId,
    };
  }
};

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

NavigationDrawer.defaultProps = {
  indicators: {},
};

NavigationDrawer.propTypes = {
  /** the action items to be added to the navigation drawer */
  navItems: arrayOf(navigationDrawerPropTypes.navigationItem).isRequired,
  /** The breakpoint below which the navigation drawer to be displayed in mobile view */
  breakpoint: number.isRequired,
  /** function to handle the onClick action of a navigation item */
  onNavigation: func.isRequired,
  /** the children elements to be rendered as the page content */
  children: node,
  /** the currently selected item */
  selectedItem: navigationDrawerPropTypes.navigationItem,
  /** list of items requiring an indicator to display in the navigation */
  indicators: object,
  /** function to handle the change of operating user */
  handleChangeOperatingAs: func,
  /** the list of available payors who can be acted on behalf of */
  operateAsPayors: arrayOf(
    shape({
      /** the operating as payor id */
      payorId: string,
      /** the operating payor name */
      payorName: string,
    })
  ),
  /** The payor id of the user logged in  */
  primaryPayorId: string,
};

function NavigationDrawer(props) {
  const { confirmDiscardChanges, getIsConfirmingDiscardChanges } =
    VeloConfirmDiscardChangesContext.useConfirmDiscardChangesContext();

  const [subNavOpenId, setSubNavOpenId] = useState();
  const [isMobile, setIsMobile] = useState(
    isBelowBreakpoint(`${props.breakpoint}px`)
  );
  const [openSelectOperatingAs, setOpenSelectOperatingAs] = useState(false);
  const [open, setOpen] = useState(false);
  const [hideToolTip, setHideTooltip] = useState(false);
  const [forceClose, setForceClose] = useState(false);
  const [changeOnResize, setChangeOnResize] = useState(true);
  const [windowWidth, setWindowWidth] = useState();

  const hasOperateAsPayors =
    props.operateAsPayors && props.operateAsPayors.length > 0;

  const selectedItem = props.selectedItem
    ? props.selectedItem
    : props.navItems[0];

  const selectedOperatingAsPayor = useMemo(
    () =>
      getSelectedOperateAsPayors(
        props.primaryPayorId,
        props.operateAsPayorId,
        props.operateAsPayors
      ),
    [props.primaryPayorId, props.operateAsPayorId, props.operateAsPayors]
  );

  const groupedItems = useMemo(
    () => getGroupedItems(props.navItems),
    [props.navItems]
  );

  const subMenuProps = useMemo(
    () => getSubMenuProps(props.navItems, selectedItem, subNavOpenId),
    [props.navItems, selectedItem, subNavOpenId]
  );

  const title = selectedItem && selectedItem.label;

  const baseProps = {
    isNavigationDrawerOpen: open,
    isMobile,
    title,
  };

  const previousSelectedItem = usePrevious(props.selectedItem);

  useEffect(() => {
    // Open the corresponding sub menu if the current selected item has changed
    // to become a sub menu item.
    if (
      props.selectedItem &&
      props.selectedItem.parent &&
      (!previousSelectedItem ||
        props.selectedItem.parent !== previousSelectedItem.parent)
    ) {
      setSubNavOpenId(props.selectedItem.parent);
    } else if (
      previousSelectedItem &&
      !props.selectedItem.parent &&
      props.selectedItem.id !== previousSelectedItem.id
    ) {
      setSubNavOpenId(null);
    }
  }, [props.selectedItem, previousSelectedItem, setSubNavOpenId]);

  const handleToggleNavigation = useCallback(
    (evt) => {
      const keyCode = evt.keyCode;

      let toggle = keyCode !== null;

      if (keyCode) {
        toggle = keyCode === keyCodes.ENTER || keyCode === keyCodes.SPACE;
      }

      if (keyCode === keyCodes.DOWN_ARROW) {
        evt.preventDefault();
        //FIXME: this is so UGLY!! and not really allowed...
        // set the first item in the navigation to have the focus
        // known issue with RMWC list component which does not expose a ref prop
        document.getElementById('velo-navigation-item-0').focus();
      }

      if (toggle) {
        setOpen(!open);
        setForceClose(false);
      }
    },
    [open]
  );

  /**
   * When the navigation drawer is toggled and the open state has been updated
   * then we need to rebuild the tool tip after the state update is resolved.
   */
  useEffect(() => {
    ReactTooltip.rebuild();
  }, [open]);

  const handleCloseOperatingAs = useCallback(() => {
    setOpenSelectOperatingAs(false);
  }, []);

  const handleApplyOperatingAs = useCallback(
    (operateAsPayorId) => {
      setOpenSelectOperatingAs(false);
      props.handleChangeOperatingAs(operateAsPayorId);
    },
    [props]
  );

  const handleChooseOperatingAs = useCallback(() => {
    setOpenSelectOperatingAs(true);
    setOpen(isMobile ? false : open);
  }, [isMobile, open]);

  const handleSubMenuClose = useCallback((keepOpen) => {
    setOpen(keepOpen);
    setForceClose(false);

    setSubNavOpenId(null);
  }, []);

  const handleListAction = useCallback(
    (id, forceTriggerNav) => {
      confirmDiscardChanges(() => {
        if (id) {
          // Select a flat list of all navigation items including sub menu items
          const flatListOfNavItems = props.navItems.reduce(
            (acc, { subMenuItems, ...other }) => [
              ...acc,
              { ...other, subMenuItems },
              ...(subMenuItems ? subMenuItems : []),
            ],
            []
          );

          // Find the item which is being actioned by id
          const item = flatListOfNavItems.find((item) => item.id === id);

          // The operating as item is handled by opening the dialog
          // Early return as no navigation action is required
          if (item.id === NavigationItems.OPERATING_AS) {
            handleChooseOperatingAs();
            return;
          }

          // If the sub menu parent item is click then the corresponding sub menu
          // should be opened apart from when the sub menu is collapsed
          // Early return as no navigation action is required
          if (
            !item.legalLinksPrimaryItem &&
            item.subMenuItems &&
            item.subMenuItems.length
          ) {
            // If the drawer is not collapsed
            // Open the sub menu corresponding to the sub menu parent id
            setSubNavOpenId(subNavOpenId !== item.id ? item.id : null);
            return;
          }

          // If a top level primary navigation is actioned then any open sub menu
          // needs to be closed
          if (!item.parent) {
            setSubNavOpenId(null);
          }

          // In mobile mode, a navigation action must close the drawer
          isMobile && setOpen(false);

          // If its collapsed and a primary item, then we don't want to trigger the onNavigation, as this will open the flyout
          const triggerOnNavigation = !(item.legalLinksPrimaryItem && !open);

          // Perform the click action
          (forceTriggerNav || triggerOnNavigation) &&
            props.onNavigation(item.id, !!item.openInNewTab);
        }
      });
    },
    [
      confirmDiscardChanges,
      handleChooseOperatingAs,
      isMobile,
      open,
      props,
      subNavOpenId,
    ]
  );

  const checkViewPort = useCallback(() => {
    const isMobile = isBelowBreakpoint(`${props.breakpoint}px`);
    const isTablet = isBelowBreakpoint(styles.tabletBreakpoint);
    const windowBeingMadeSmaller = window.innerWidth < windowWidth;

    // If the resize is allowed:
    // it is the first load and therefore allowResize is true - on large screens, the nav drawer should be open, on smaller screens, collapsed on load.
    // window is being made smaller by the user - the nav drawer is closed to make more space
    // the window is in mobile state so the nav drawer must be hidden
    const allowNavDrawerChangeOnResize =
      changeOnResize || isMobile || (open && windowBeingMadeSmaller);

    // we only want to change the open state of the navigation if is allowed.
    if (allowNavDrawerChangeOnResize) {
      setOpen(getIsConfirmingDiscardChanges() || !(isTablet || isMobile));

      setChangeOnResize(isMobile);
    }

    // Set the mobile state and the windowWidth every time there is a resize.
    setWindowWidth(window.innerWidth);
    setIsMobile(isMobile);

    // If window size changes and the drawer is collapsed, we want to rebuild the tooltips.
    if (!windowBeingMadeSmaller && !open) ReactTooltip.rebuild();
  }, [
    changeOnResize,
    getIsConfirmingDiscardChanges,
    open,
    props.breakpoint,
    windowWidth,
  ]);

  const handleContentScroll = useCallback(() => {
    setForceClose(!open && !!subNavOpenId);
  }, [open, subNavOpenId]);

  useEffect(
    () => {
      checkViewPort();
    },
    // eslint-disable-next-line
    []
  );

  useEffect(() => {
    window.addEventListener('scroll', handleContentScroll);
    window.addEventListener('resize', checkViewPort);

    return () => {
      window.removeEventListener('scroll', handleContentScroll);
      window.removeEventListener('resize', checkViewPort);
    };
  }, [checkViewPort, handleContentScroll]);

  return (
    <ViewHeight className={styles.appRoot}>
      <NavigationDrawerTopAppBar
        {...baseProps}
        handleToggle={handleToggleNavigation}
        mode={TopBarMode.CONTENT}
        showAppBarContent={isMobile}
        className="content"
      >
        <NavigationDrawerTopAppBarTitle
          data-testid={dataTestIdBuilder(
            TestIds.PAGE_TITLE,
            TopBarMode.CONTENT
          )}
        >
          {title}
        </NavigationDrawerTopAppBarTitle>
      </NavigationDrawerTopAppBar>
      <NavigationDrawerTopAppBarFixedAdjust isMobile={isMobile} />

      <VeloDrawer
        {...baseProps}
        {...subMenuProps}
        onDrawerClose={() => setOpen(false)}
        onToggleNavigation={handleToggleNavigation}
        onListAction={handleListAction}
        onSubMenuClose={handleSubMenuClose}
      >
        <NavigationDrawerList
          {...baseProps}
          groupedItems={groupedItems}
          items={props.navItems}
          selectedItem={selectedItem}
          onListAction={handleListAction}
          indicators={props.indicators}
          operatingAs={selectedOperatingAsPayor}
          hasOperatorAsOptions={hasOperateAsPayors}
          forceClose={forceClose}
          hideTooltip={setHideTooltip}
          {...subMenuProps}
        />
      </VeloDrawer>

      <Transitions.NavigationDrawerPushContent in={open && !isMobile}>
        <div
          className={classnames(styles.content, {
            [styles.contentMobile]: isMobile,
            [styles.contentCollapsed]: !open,
            [styles.overrideTooltip]: hideToolTip,
          })}
        >
          <div className={styles.pagebody}>{props.children}</div>
        </div>
      </Transitions.NavigationDrawerPushContent>

      <ReactTooltip
        effect="solid"
        place="right"
        type="dark"
        globalEventOff="onkeydown"
      />

      {/* Operating as dialog to select the payor to act as. */}
      <NavigationDrawerOperatingAsDialog
        handleCloseOperatingAs={handleCloseOperatingAs}
        open={openSelectOperatingAs}
        selectedOperatingAsPayor={selectedOperatingAsPayor}
        operateAsPayors={props.operateAsPayors}
        handleApplyOperatingAs={handleApplyOperatingAs}
      />
    </ViewHeight>
  );
}

NavigationDrawer.topBarMode = TopBarMode;
NavigationDrawer.List = NavigationDrawerList;
NavigationDrawer.Item = NavigationDrawerItem;
NavigationDrawer.Items = NavigationItems;
NavigationDrawer.FlyoutMenu = NavigationDrawerFlyoutMenu;
NavigationDrawer.OperatingAsDialog = NavigationDrawerOperatingAsDialog;
NavigationDrawer.ItemGroupMapping =
  navigationDrawerConfig.NavigationItemGroupMapping;
NavigationDrawer.OperatingAsItem = NavigationDrawerOperatingAsItem;
NavigationDrawer.TopAppBar = NavigationDrawerTopAppBar;
NavigationDrawer.TopAppBarImage = NavigationDrawerTopAppBarImage;
NavigationDrawer.TopAppBarFixedAdjust = NavigationDrawerTopAppBarFixedAdjust;
NavigationDrawer.TopAppBarTitle = NavigationDrawerTopAppBarTitle;

NavigationDrawer.testIds = TestIds;
NavigationDrawer.root = root;

NavigationDrawer.config = navigationDrawerConfig;

export { NavigationDrawer };
