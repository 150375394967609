const label = 'Postal Code';
const helpText = 'Please enter a postal code';

const postcodes = {
  US: {
    label: 'Zip Code',
    helpText: 'Please enter a zip code',
    pattern: '^.{5,10}$',
    minLength: 5,
    maxLength: 10,
    placeholder: '5-10 characters',
  },
  GB: {
    label: 'Postcode',
    helpText: 'Please enter a postcode',
    pattern: '^.{5,8}$',
    minLength: 5,
    maxLength: 8,
    placeholder: '5-8 characters',
  },
  CA: {
    label,
    helpText,
    pattern: '^.{6,7}$',
    minLength: 6,
    maxLength: 7,
    placeholder: '6-7 characters',
  },
  FR: {
    label,
    helpText,
    pattern: '^.{5}$',
    minLength: 5,
    maxLength: 5,
    placeholder: '5 characters',
  },
  JP: {
    label,
    helpText,
    pattern: '^.{7,8}$',
    minLength: 7,
    maxLength: 8,
    placeholder: '7-8 characters',
  },
  DE: {
    label,
    helpText,
    pattern: '^[0-9]{5}$',
    minLength: 5,
    maxLength: 5,
    placeholder: '5 digits',
  },
  NL: {
    label,
    helpText,
    pattern: '^.{6,7}$',
    minLength: 6,
    maxLength: 7,
    placeholder: '6-7 characters',
  },
  IE: {
    label: 'Eircode',
    helpText: 'Please enter a valid Eircode',
    pattern: '^.{1,8}$',
    minLength: 1,
    maxLength: 8,
    placeholder: '1-8 characters',
  },
  AU: {
    label: 'Postcode',
    helpText: 'Please enter a postcode',
    pattern: '^[0-9]{3,4}$',
    minLength: 3,
    maxLength: 4,
    placeholder: '3-4 digits',
  },
  CH: {
    label,
    helpText,
    pattern: '^[0-9]{4}$',
    minLength: 4,
    maxLength: 4,
    placeholder: '4 digits',
  },
  MX: {
    label,
    helpText,
    pattern: '^[0-9]{5}$',
    minLength: 5,
    maxLength: 5,
    placeholder: '5 digits',
  },
  // Hong Kong is a special case as postal codes
  // are not used. However, China does assign a
  // postal code we can use to keep the field
  // mandatory for now.
  HK: {
    label,
    helpText,
    pattern: '^999077$',
    minLength: 6,
    maxLength: 6,
    placeholder: '6 digits',
    value: '999077',
    hidden: true,
  },
  KE: {
    label,
    helpText,
    pattern: '^[0-9]{5}$',
    minLength: 5,
    maxLength: 5,
    placeholder: '5 digits',
  },
};

const postcodeProps = (country) =>
  postcodes[country] || {
    label,
    helpText,
    pattern: '^.{3,15}$',
    minLength: 3,
    maxLength: 15,
    placeholder: '3-15 characters',
  };

const postcodeValue = (country) => {
  const { value } = postcodes[country] || {};
  return value || '';
};

export { postcodeProps, postcodeValue };
