import React from 'react';
import { FormattedMessage } from 'react-intl';
import { VeloCardPage } from '../VeloCardPage';
import { Content } from '../Content';
import { PayoutHeader } from '../PayoutHeader';
import { VeloErrorCard } from '../VeloErrorCard/VeloErrorCard';
import { useIsBelowBreakpoint } from '../hooks/useIsBelowBreakpoint';

const title = <FormattedMessage defaultMessage="Payout Details" />;

function Page({ children, pageProps, ...props }) {
  const isMobile = useIsBelowBreakpoint(useIsBelowBreakpoint.breakpoints.XS);
  return (
    <Content>
      <VeloCardPage
        title={title}
        fixedBottomPaginationOffset={Boolean(pageProps) && isMobile}
        {...props}
      >
        {children}
      </VeloCardPage>
    </Content>
  );
}

function Loading({ children, ...props }) {
  return (
    <Page {...props}>
      <PayoutHeader.Loading />
      {children}
    </Page>
  );
}

function ErrorPage({ error, ...props }) {
  return (
    <Content>
      <VeloCardPage title={title} {...props}>
        <VeloErrorCard.Content>
          <VeloErrorCard.Title>{error}</VeloErrorCard.Title>
        </VeloErrorCard.Content>
      </VeloCardPage>
    </Content>
  );
}

PayoutDetailsPage.Error = ErrorPage;
PayoutDetailsPage.Loading = Loading;

export function PayoutDetailsPage({ children, ...props }) {
  return (
    <Page {...props}>
      <PayoutHeader.Details {...props} />
      {children}
    </Page>
  );
}
