import React, { useState } from 'react';
import { array, bool, string, func } from 'prop-types';
import styled from 'astroturf/react';
import { VeloMenu } from '../VeloMenu';
import { VeloFab } from '../VeloFab';
import { SplitMobileFab } from './SplitMobileFab';
import { VeloButton } from '../VeloButton';
import { useRightEdgeMenuAlignment } from './useRightEdgeMenuAlignment';

const PrimaryFab = styled(VeloFab)`
  @import 'velo-variables';
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 1px solid velo-color('token-color-system-page-surface');
`;

const PrimaryButton = styled(VeloButton.Primary)`
  @import 'velo-variables';
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 1px solid velo-color('token-color-system-page-surface');
`;

const MenuFab = styled(VeloFab)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding-left: 8px;

  :global(.mdc-fab__icon) {
    margin-right: -12px;
    margin-left: -6px;
  }
`;

const MenuButton = styled(VeloButton.Primary)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  min-width: 30px;
  padding: 0px;

  :global(.mdc-button__icon) {
    margin-left: 0;
    margin-right: 0;
  }
`;

const Layout = styled(VeloMenu.SurfaceAnchor)`
  display: flex;
`;

const MenuItem = styled(VeloMenu.Item)`
  height: 2rem;
  white-space: nowrap;
`;

const Menu = styled(VeloMenu)`
  @import '@material/ripple/mixins';
  @import 'velo-variables';

  /**
   * To understand why this is necessary:
   * @see https://github.com/material-components/material-components-web/blob/v1.0.1/packages/mdc-list/_mixins.scss#L297
   */
  :global(:not(.mdc-list--non-interactive).mdc-list) {
    :global(:not(.mdc-list-item--disabled).mdc-list-item) {
      @include mdc-states(primary);
    }
  }
`;

const propTypes = {
  /** Custom CSS class(es). */
  className: string,
  /** Disable the FAB */
  disabled: bool,
  /** The title attribute text  */
  title: string.isRequired,
  /** Test ID for the velo fab. */
  'data-testid': string.isRequired,
  /** options */
  options: array.isRequired,
  /** primary onClick */
  onPrimary: func.isRequired,
  /** handler for when an option is selected */
  onSelectOption: func.isRequired,
};

VeloSplitFab.propTypes = propTypes;

function useSplitButton({ options, onSelectOption, onPrimary, ...props }) {
  const [open, setOpen] = useState(false);

  const menuButtonProps = {
    onClick: () => setOpen(!open),
    label: ' ', // used to activate "extended fab" mode
    icon: 'chevron_down',
    disabled: props.disabled,
    title: `${props.title}-menu`,
    'data-testid': testIdBuilder(props['data-testid']),
  };

  const menu = (
    <Menu
      anchorCorner="bottomStart"
      open={open}
      {...useRightEdgeMenuAlignment(null)}
      onSelect={(evt) => {
        onSelectOption(options[evt.detail.index]);
      }}
      onClose={() => setOpen(false)}
      data-testid={`${props['data-testid']}-options`}
    >
      {options.map(({ label, icon, disabled }, index) => (
        <MenuItem
          key={index}
          disabled={disabled}
          data-testid={`${props['data-testid']}-option-${index}`}
        >
          {icon}
          {label}
        </MenuItem>
      ))}
    </Menu>
  );

  return [
    {
      ...props,
      onClick: onPrimary,
    },
    menuButtonProps,
    menu,
  ];
}

const testIdBuilder = (prefix) => `${prefix}-menu`;
VeloSplitFab.testIdBuilder = testIdBuilder;
VeloSplitFab.Mobile = SplitMobileFab;

export function VeloSplitFab(props) {
  const [mainButtonProps, menuButtonProps, menu] = useSplitButton(props);

  return (
    <Layout>
      <PrimaryFab {...mainButtonProps} />
      {menu}
      <MenuFab {...menuButtonProps} />
    </Layout>
  );
}

VeloSplitButton.propTypes = propTypes;

export function VeloSplitButton(props) {
  const [mainButtonProps, menuButtonProps, menu] = useSplitButton(props);

  return (
    <Layout>
      <PrimaryButton {...mainButtonProps} />
      {menu}
      <MenuButton {...menuButtonProps} />
    </Layout>
  );
}
