import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import styled from 'astroturf/react';
import { bool, func, string } from 'prop-types';
import { VeloTypography } from '../VeloTypography';
import { VeloIconButton } from '../VeloIconButton';

const Banner = styled('div')`
  @import 'velo-variables';
  background-color: velo-color('token-color-brand-primary');
  display: flex;
  justify-content: center;
  width: 100%;
`;

const Text = styled(VeloTypography)`
  @import 'velo-variables';
  color: velo-color('token-color-text-inverse');
  padding: 1rem;
  width: 100%;
  text-align: center;

  @media (max-width: velo-breakpoint(XS)) {
    text-align: left;
    padding-right: 0;
  }
`;

const AnchorLink = styled('a')`
  color: inherit;
`;

const CloseIcon = styled(VeloIconButton)`
  @import 'velo-variables';
  color: velo-color('token-color-text-inverse');
  margin-top: 0.15rem;
`;

const root = 'cookie-consent';

const TestIds = {
  ROOT: root,
  CLOSE: `${root}-close`,
};

CookieConsent.testIds = TestIds;

CookieConsent.propTypes = {
  open: bool.isRequired,
  onClose: func.isRequired,
  privacyLink: string.isRequired,
  termsLink: string.isRequired,
};

export function CookieConsent({
  open,
  onClose,
  privacyLink,
  termsLink,
  ...props
}) {
  const intl = useIntl();
  return open ? (
    <Banner data-testid={TestIds.ROOT}>
      <Text use="bodyTextSmaller">
        <FormattedMessage
          defaultMessage="To help personalize content and provide a safer experience, we use cookies. By using this site, you agree to our <privacy>Privacy Policy</privacy> and our <terms>Terms of Use</terms>."
          values={{
            privacy: (chunks) => (
              <AnchorLink target="blank" href={privacyLink}>
                {chunks}
              </AnchorLink>
            ),
            terms: (chunks) => (
              <AnchorLink target="blank" href={termsLink}>
                {chunks}
              </AnchorLink>
            ),
          }}
        />
      </Text>
      <CloseIcon
        icon="close"
        label="close"
        title={intl.formatMessage({
          defaultMessage: 'Close cookie consent information',
        })}
        onClick={() => {
          // 250ms timeout is used to allow the ripple animation to happen
          // before hiding the banner
          setTimeout(() => {
            onClose();
          }, 200);
        }}
        data-testid={TestIds.CLOSE}
      />
    </Banner>
  ) : null;
}
