import React, { useState, useMemo } from 'react';
import { func, shape, string, number, oneOf } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { VeloTypography } from '../VeloTypography';
import { VeloCardForm } from '../VeloCardForm';
import { VeloFieldGrid } from '../VeloFieldGrid';
import { Currency } from '../FormFields/FormFieldTypes';
import { majorToMinorUnitMoney, PayorAccountType } from 'velo-data';
import { SourceAccountFormFields } from '../SourceAccountFormFields';
import { useFieldGridForm } from '../useFieldGridForm';
import { VeloGridLoading } from '../VeloGridLoading';
import { LookupTextField } from '../LookupTextField';

const root = 'source-account-add-funding';

const TestIds = {
  ...VeloCardForm.testIds,
  AMOUNT_FIELD: `${root}-amount-field`,
  ACTION_BUTTON: `${root}-action-button`,
};

function Caption() {
  return (
    <VeloTypography use="footnote">
      <FormattedMessage
        defaultMessage="Executing a request initiates an ACH pull from the connected funding
      account. ACH requests typically will take 3-5 business days but may vary
      by bank and processing windows."
      />
    </VeloTypography>
  );
}

const Labels = {
  TITLE: <FormattedMessage defaultMessage="Add Funds" />,
  FUNDING_ACCOUNT_HEADING: <FormattedMessage defaultMessage="Add from" />,
  FUNDING_ACCOUNT_SELECT: <FormattedMessage defaultMessage="Funding account" />,
  FUNDING_AMOUNT: <FormattedMessage defaultMessage="Funding amount" />,
};

const config = {
  getButtonProps(submitting) {
    return {
      children: submitting ? (
        <FormattedMessage defaultMessage="Adding..." />
      ) : (
        <FormattedMessage defaultMessage="Add" />
      ),
      'data-testid': TestIds.ACTION_BUTTON,
    };
  },
  getInitialValues() {
    return {
      fundingAccount: '',
      caption: '',
      amount: '',
    };
  },
  formatBody({ fundingAccountSecondaryValue, ...body }) {
    const [{ value: fundingAccountCurrency }] = fundingAccountSecondaryValue;
    return {
      ...body,
      fundingAccountCurrency,
    };
  },
  createSections(_, { fetchResults, data }) {
    return [
      SourceAccountFormFields.createSourceAccountGridSection(false, {
        ...data,
        sourceAccountName: data.name,
      }),
      {
        heading: Labels.FUNDING_ACCOUNT_HEADING,
        fields: [
          {
            Component: LookupTextField,
            entityId: '',
            fetchOnEmpty: true,
            fetchResults,
            label: Labels.FUNDING_ACCOUNT_SELECT,
            mode: LookupTextField.modes.FUNDING_ACCOUNT,
            name: 'fundingAccount',
            required: true,
          },
          {
            name: 'amount',
            ...Currency(data.currency, Labels.FUNDING_AMOUNT, true),
            'data-testid': TestIds.AMOUNT_FIELD,
            required: true,
          },
          {
            name: 'caption',
            Component: Caption,
          },
        ],
      },
    ];
  },
};

const headingProps = { compact: true };

function Loading({ onClose }) {
  return (
    <VeloCardForm.Loading slimline onClose={onClose} title={Labels.TITLE}>
      <VeloGridLoading
        headingProps={headingProps}
        sections={[
          {
            heading: true,
            fields: [
              {
                type: VeloGridLoading.fieldTypes.LabelledItem,
              },
              {
                type: VeloGridLoading.fieldTypes.LabelledItem,
              },
              {
                type: VeloGridLoading.fieldTypes.LabelledItem,
              },
              {
                type: VeloGridLoading.fieldTypes.LabelledItem,
              },
            ],
          },
          {
            heading: true,
            fields: [
              {
                type: VeloGridLoading.fieldTypes.TextField,
              },
              {
                type: VeloGridLoading.fieldTypes.TextField,
              },
            ],
          },
        ]}
      />
    </VeloCardForm.Loading>
  );
}

const defaultDialogProps = {
  open: false,
  amount: 0,
  sourceAccount: {
    id: '',
    type: PayorAccountType.FBO,
    balance: 0,
    currency: 'USD',
  },
  fundingAccount: {
    id: '',
    currency: 'USD',
  },
};

function createSubmitHandler(sourceAccount, onSubmit, setDialogProps) {
  return (body, cb) => {
    const amount = majorToMinorUnitMoney(body.amount, sourceAccount.currency);

    const fundingAccount = {
      id: body.fundingAccountId,
      name: body.fundingAccount,
      currency: body.fundingAccountCurrency,
    };

    const baseProps = {
      sourceAccount,
      fundingAccount,
      amount,
    };

    const onClose = ({ detail }) => {
      // We extract the summary id based on the encoding of the action
      const [action, fxSummaryId] = detail.action.split(':');
      setDialogProps(defaultDialogProps);
      if (detail.error) {
        // do not call submit, error immediately
        cb(detail.error);
      } else if (action === 'accept') {
        onSubmit(
          {
            type: sourceAccount.type,
            fundingAccount,
            fxSummaryId,
            amount,
          },
          cb
        );
      } else {
        cb(undefined, undefined);
      }
    };

    setDialogProps({
      ...baseProps,
      open: true,
      onClose,
    });
  };
}

SourceAccountAddFundingForm.Loading = Loading;

SourceAccountAddFundingForm.propTypes = {
  fetchResults: func.isRequired,
  onClose: func.isRequired,
  onSubmit: func.isRequired,
  dialog: func.isRequired,
  data: shape({
    id: string.isRequired,
    payorName: string,
    type: oneOf(Object.values(PayorAccountType)).isRequired,
    name: string.isRequired,
    balance: number,
    currency: string.isRequired,
  }).isRequired,
};

function SourceAccountAddFundingForm({ dialog, ...props }) {
  const [dialogProps, setDialogProps] = useState(defaultDialogProps);
  const { onSubmit, data } = props;

  const [gridProps, formProps] = useFieldGridForm(config, {
    ...props,
    onSubmit: useMemo(
      () => createSubmitHandler(data, onSubmit, setDialogProps),
      [data, onSubmit, setDialogProps]
    ),
  });

  const children = useMemo(() => dialog(dialogProps), [dialog, dialogProps]);

  return (
    <VeloCardForm slimline {...formProps} title={Labels.TITLE}>
      <VeloFieldGrid headingProps={headingProps} {...gridProps} />
      {children}
    </VeloCardForm>
  );
}

SourceAccountAddFundingForm.testIds = TestIds;
SourceAccountAddFundingForm.labels = Labels;

export { SourceAccountAddFundingForm };
