import React from 'react';
import { bool } from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { countryName } from '../../utils';
import { VeloPropTypes } from '../../VeloPropTypes';
import { ResultContainer } from '../../ResultContainer';
import { PaymentChannelViewItem } from '../PaymentChannelViewItem';
import { PaymentChannelViewType } from '../PaymentChannelViewType';
import { PaymentChannelViewDetailsSkeleton } from './PaymentChannelViewDetailsSkeleton';

const testId = 'velo-payment-channel-details';

/** Render the payment channel details in the loading state. */
const LoadingComponent = () => <PaymentChannelViewDetailsSkeleton length={2} />;

/** Render the payment details. */
const BaseComponent = ({ result: channel, paymentChannelRules }) => {
  const intl = useIntl();
  return (
    <>
      <PaymentChannelViewType channelType={channel.channelType} />
      {channel.countryCode && (
        <PaymentChannelViewItem
          label={<FormattedMessage defaultMessage="Country" />}
          value={countryName(channel.countryCode, intl)}
          valueTestId={`${testId}-country`}
        />
      )}
      {channel.currency && (
        <PaymentChannelViewItem
          label={<FormattedMessage defaultMessage="Currency" />}
          value={channel.currency}
          valueTestId={`${testId}-currency`}
        />
      )}

      {paymentChannelRules &&
        paymentChannelRules.map((rule, index) => {
          return (
            rule && (
              <PaymentChannelViewItem
                key={`${index}`}
                label={rule.displayName}
                value={channel[rule.element]}
                valueTestId={`${testId}-${rule.element}`}
              />
            )
          );
        })}
    </>
  );
};

const Component = ResultContainer({ LoadingComponent })(BaseComponent);

PaymentChannelViewDetails.propTypes = {
  /** The data to render. */
  result: VeloPropTypes.result(),
  /** Flag setting the state of disabled on the component */
  disabled: bool,
};

/**
 * Used to display core Payment Channel details.
 */
function PaymentChannelViewDetails({ result, disabled, paymentChannelRules }) {
  return (
    <Component
      result={result}
      paymentChannelRules={paymentChannelRules}
      disabled={disabled}
    />
  );
}

export { PaymentChannelViewDetails };
