import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'astroturf/react';
import { VeloTypography } from '../VeloTypography';
import { VeloDate } from '../VeloDate';
import { VeloTime } from '../VeloTime';
import { VeloStatefulButton } from '../VeloStatefulButton';
import { VeloButton } from '../VeloButton';
import { VeloCallout } from '../VeloCallout';

const Section = styled(VeloCallout)`
  padding: 1rem;
`;

const Header = styled(VeloTypography)`
  margin-bottom: 1rem;
`;

const Content = styled('div')`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const ButtonContainer = styled('div')`
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
`;

export function LockedOut(props) {
  return (
    <Section type={VeloCallout.types.WARNING}>
      <Content>
        <Header use="sectionHeader">
          <FormattedMessage defaultMessage="User locked out" />
        </Header>

        <VeloTypography use="bodyText" tag="div">
          <FormattedMessage
            defaultMessage="The user has been locked out since {date} {time}"
            values={{
              date: <VeloDate.Long value={props.lockedOutTimestamp} />,
              time: <VeloTime.Long value={props.lockedOutTimestamp} />,
            }}
          />
        </VeloTypography>

        {props.onClick && (
          <ButtonContainer>
            <VeloStatefulButton
              component={VeloButton.Secondary}
              stages={{
                [VeloStatefulButton.stages.PENDING]: {
                  children: <FormattedMessage defaultMessage="Unlocking..." />,
                  disabled: true,
                },
              }}
              data-testid={props['data-testid']}
              onClick={props.onClick}
              ripple={false}
            >
              <FormattedMessage defaultMessage="Unlock" />
            </VeloStatefulButton>
          </ButtonContainer>
        )}
      </Content>
    </Section>
  );
}
