import {
  any,
  bool,
  arrayOf,
  func,
  node,
  number,
  shape,
  string,
  oneOfType,
  object,
} from 'prop-types';
// Generic result shape
const result = (result = any) =>
  shape({
    result,
    error: node,
  });

const dialogType = () =>
  shape({
    title: node.isRequired,
    acceptLabel: node.isRequired,
    cancelLabel: node,
  });

const formTabType = () =>
  shape({
    tabProps: shape({
      children: node,
    }).isRequired,
    renderContent: func.isRequired,
  });

const formTabsType = () => arrayOf(formTabType());

const countryType = () =>
  shape({
    /** Two-character ISO country code. */
    isoCountryCode: string.isRequired,
    /** Supported currencies. */
    currencies: arrayOf(string).isRequired,
  });

const countriesType = () => arrayOf(countryType());

/* list of all country payment channel rules */
const paymentChannelRulesType = () => arrayOf(paymentChannelRuleType());

/* A single country payment channel rules list */
const paymentChannelRuleType = () =>
  shape({
    /** The country for the rule */
    isoCountryCode: string,
    /** The list of rules to apply */
    rules: paymentChannelRulesListType(),
  });

const paymentChannelRuleItemType = () =>
  shape({
    /** the name of the field element */
    element: string,
    /** the required status of the field element */
    required: bool,
    /** ths display name of the field element */
    displayName: oneOfType([string, object]),
    /** the min length of the field element input */
    minLength: number,
    /** the max length of the field element input */
    maxLength: number,
    /** the validation string of the field element input */
    validation: string,
  });

const paymentChannelRulesListType = () => arrayOf(paymentChannelRuleItemType());

const bankLookupType = () =>
  shape({
    /** Bank account routing number */
    routingNumber: string,
    /** Bank account account number */
    accountNumber: string,
    /** The bank name */
    bankName: string,
    /** The swift or BIC reference */
    swiftBic: string,
    /** The bank address */
    bankAddress: shape({
      /** Line one of address */
      line1: string,
      /** Line two of address */
      line2: string,
      /** The bank address city */
      city: string,
      /** The bank address region */
      countyOrProvince: string,
      /**The bank address zip/post code */
      zipOrPostcode: string,
      /** The bank address country */
      country: string,
    }),
  });

const sourceAccountType = (additions = {}) =>
  shape({
    ...additions,
    name: string,
    currency: string,
    fundingRef: string,
    balance: number,
    notifications: shape({
      minimumBalance: number.isRequired,
    }),
    autoTopUpConfig: shape({
      enabled: bool.isRequired,
      minBalance: number,
      targetBalance: number,
    }),
    /**
     * Not always visible, returned.
     */
    physicalAccountName: string,
  });

const payeeCountryRuleType = shape({
  element: string.isRequired,
  displayName: string.isRequired,
  description: string.isRequired,
  minLength: number.isRequired,
  maxLength: number.isRequired,
  validation: string.isRequired,
});

const railProviderType = shape({
  railsId: string.isRequired,
  description: string.isRequired,
  accountType: string.isRequired,
  balanceVisible: bool.isRequired,
  platformFundingSupported: bool.isRequired,
  supportedCurrencies: arrayOf(string).isRequired,
  requiredCredentials: arrayOf(
    shape({
      propertyName: string.isRequired,
      label: string.isRequired,
    })
  ),
});

const payeeCountryRulesType = () =>
  arrayOf(
    shape({
      isoCountryCode: string.isRequired,
      individualRules: arrayOf(payeeCountryRuleType),
      companyRules: arrayOf(payeeCountryRuleType),
    })
  );

const VeloPropTypes = {
  result,
  dialogType,
  bankLookupType,
  sourceAccountType,
  paymentChannelRuleType,
  paymentChannelRulesType,
  paymentChannelRulesListType,
  paymentChannelRuleItemType,
  countryType,
  countriesType,
  formTabsType,
  payeeCountryRulesType,
  railProviderType,
};

export { VeloPropTypes };
