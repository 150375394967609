import { PaymentChannelForm } from 'velo-react-components';
import { formatError } from './helpers';

export const formatBankLookupError = (error, paymentChannelRules) => {
  // A generic error will be missing the `location` tag.
  if (error.errors[0].location === undefined) {
    return formatError(error);
  }
  // Lookup error. Find the "routingNumber" or "iban" element
  const element = paymentChannelRules.find(
    (rule) => rule.element === 'routingNumber' || rule.element === 'iban'
  );
  return PaymentChannelForm.formatBankLookupError(element.displayName);
};
