import React from 'react';
import { useIntl } from 'react-intl';
import { VeloIconButton } from '../../VeloIconButton';
import { VeloModalSheet } from '../../VeloModalSheet';

const TestIds = {
  CLOSE_BUTTON: 'payment-channel-view-error-close-button',
};

PaymentChannelViewError.testIds = TestIds;
/**
 * Render a Payment Channel View error.
 */
function PaymentChannelViewError({ error, onClose }) {
  const intl = useIntl();
  return (
    <>
      <VeloModalSheet.Header>
        <VeloModalSheet.HeaderTitle />
        <VeloIconButton
          icon="close"
          title={intl.formatMessage({ defaultMessage: 'Close error message' })}
          onClick={onClose}
          data-testid={TestIds.CLOSE_BUTTON}
        />
      </VeloModalSheet.Header>
      <VeloModalSheet.Body>
        <VeloModalSheet.Error>{error}</VeloModalSheet.Error>
      </VeloModalSheet.Body>
    </>
  );
}

export { PaymentChannelViewError };
