import { arrayOf, bool, func, shape, string } from 'prop-types';
import { VeloPropTypes } from '../VeloPropTypes';

const PaymentChannelTypes = {
  paymentChannelName: string,
  channelType: string,
  accountName: string,
  accountNumber: string,
  routingNumber: string,
  iban: string,
  id: string,
};

const PaymentChannelViewPropTypes = {
  /** Used to show/hide the component. */
  open: bool.isRequired,
  /** The data to render. */
  data: VeloPropTypes.result(
    shape({
      payors: shape({ result: arrayOf(string) }),
      ...PaymentChannelTypes,
    })
  ),
  /** Called when the view is closed */
  onClose: func.isRequired,
  /** Called when the item is to be edited */
  onEdit: func,
  /** Called when the item is to be deleted */
  onDelete: func,
};

export { PaymentChannelTypes, PaymentChannelViewPropTypes };
