import React from 'react';
import pick from 'just-pick';
import { PayorAccountType } from 'velo-data';
import { FormattedMessage } from 'react-intl';
import { countryName } from '../utils';
import { VeloCardForm } from '../VeloCardForm';
import { VeloFieldGrid } from '../VeloFieldGrid';
import { VeloLabelledItem } from '../VeloLabelledItem';
import { useFieldGridForm } from '../useFieldGridForm';
import { VeloGridLoading } from '../VeloGridLoading';
import {
  FundingAccountFormFields,
  labelsByFieldName,
  fieldNames,
} from '../FundingAccountFormFields';
import { func, shape, string, oneOf, arrayOf } from 'prop-types';
import { PayorAccountTypeText } from '../PayorAccountTypeText';

const { attributeMap } = FundingAccountFormFields;

const Labels = {
  TITLE: <FormattedMessage defaultMessage="Edit Funding Account" />,
  ACCOUNT_HEADING: (
    <FormattedMessage defaultMessage="Funding account details" />
  ),
  SUBMIT_BUTTON: <FormattedMessage defaultMessage="Save" />,
  SUBMITTING_BUTTON: <FormattedMessage defaultMessage="Saving..." />,
};

const createField = (name, value) => ({
  name,
  label: labelsByFieldName[name],
  value,
  Component: VeloLabelledItem.UseValue,
});

const readFieldRenderByName = {
  [fieldNames.PAYOR_NAME]: ({ data }) =>
    createField(fieldNames.PAYOR_NAME, data.payorName),
  [fieldNames.COUNTRY]: ({ data, intl }) =>
    createField(fieldNames.COUNTRY, countryName(data.countryCode, intl)),
  [fieldNames.CURRENCY]: ({ data }) =>
    createField(fieldNames.CURRENCY, data.currency),
  [fieldNames.TYPE]: ({ data }) =>
    createField(fieldNames.TYPE, <PayorAccountTypeText type={data.type} />),
};

const writeFieldRenderByName = {
  [fieldNames.NAME]: () => attributeMap.NAME,
  [fieldNames.ACCOUNT_NAME]: () => attributeMap.ACCOUNT_HOLDER_NAME,
  [fieldNames.ROUTING_NUMBER]: () => attributeMap.ROUTING_NUMBER,
  [fieldNames.ACCOUNT_NUMBER]: () => attributeMap.ACCOUNT_NUMBER,
  [fieldNames.COUNTRY]: () => attributeMap.COUNTRY,
  [fieldNames.CURRENCY]: ({ currencies }) => ({
    ...attributeMap.CURRENCY,
    currencies,
  }),
};

const fieldsByType = {
  [PayorAccountType.FBO]: {
    read: [
      fieldNames.PAYOR_NAME,
      fieldNames.COUNTRY,
      fieldNames.CURRENCY,
      fieldNames.TYPE,
    ],
    write: [
      fieldNames.NAME,
      fieldNames.COUNTRY,
      fieldNames.ACCOUNT_NAME,
      fieldNames.ROUTING_NUMBER,
      fieldNames.ACCOUNT_NUMBER,
    ],
  },
  [PayorAccountType.PRIVATE]: {
    read: [
      fieldNames.PAYOR_NAME,
      fieldNames.COUNTRY,
      fieldNames.CURRENCY,
      fieldNames.TYPE,
    ],
    write: [
      fieldNames.NAME,
      fieldNames.COUNTRY,
      fieldNames.ACCOUNT_NAME,
      fieldNames.ROUTING_NUMBER,
      fieldNames.ACCOUNT_NUMBER,
    ],
  },
  [PayorAccountType.WUBS_DECOUPLED]: {
    read: [fieldNames.PAYOR_NAME, fieldNames.TYPE],
    write: [fieldNames.NAME, fieldNames.CURRENCY],
  },
};

const config = {
  createSections(_, props, intl) {
    const { read, write } = fieldsByType[props.data.type];
    return [
      {
        fields: read.map((name) =>
          readFieldRenderByName[name]({
            ...props,
            intl,
          })
        ),
      },
      {
        heading: Labels.ACCOUNT_HEADING,
        fields: write.map((name) => writeFieldRenderByName[name](props)),
      },
    ];
  },
  getButtonProps(submitting) {
    return {
      children: submitting ? Labels.SUBMITTING_BUTTON : Labels.SUBMIT_BUTTON,
    };
  },
  formatBody(body) {
    return body;
  },
  getInitialValues({ data }) {
    const { write } = fieldsByType[data.type];
    return pick(data, write);
  },
};

function Loading({ onClose }) {
  return (
    <VeloCardForm.Loading slimline title={Labels.TITLE} onClose={onClose}>
      <VeloGridLoading
        sections={[
          {
            heading: false,
            fields: new Array(4).fill({
              type: VeloGridLoading.fieldTypes.LabelledItem,
            }),
          },
          {
            heading: true,
            fields: new Array(4).fill({
              type: VeloGridLoading.fieldTypes.TextField,
            }),
          },
        ]}
      />
    </VeloCardForm.Loading>
  );
}

const ErrorView = (props) => (
  <VeloCardForm.Error title={Labels.TITLE} {...props} />
);

FundingAccountEditForm.Loading = Loading;
FundingAccountEditForm.Error = ErrorView;

FundingAccountEditForm.propTypes = {
  /**
   * Handler for when the form is submitted
   */
  onSubmit: func.isRequired,
  /**
   * Handler for when the form is closed
   */
  onClose: func.isRequired,
  /**
   * For the WUBS type, an array of supported currencies
   */
  currencies: arrayOf(string).isRequired,
  /**
   * Data for the form
   */
  data: shape({
    /**
     * The funding account name
     */
    name: string.isRequired,
    /**
     * The account holder name
     */
    accountName: string.isRequired,
    /**
     * The routing number as a string
     */
    routingNumber: string.isRequired,
    /**
     * The account number as a string
     */
    accountNumber: string.isRequired,
    /**
     * The type of funding account
     */
    type: oneOf(Object.values(PayorAccountType)).isRequired,
    /**
     * An iso country code
     */
    countryCode: string.isRequired,
    /**
     * An iso currency code
     */
    currency: string.isRequired,
  }),
};

FundingAccountEditForm.testIds = VeloCardForm.testIds;
FundingAccountEditForm.labels = Labels;

export function FundingAccountEditForm(props) {
  const [gridProps, formProps] = useFieldGridForm(config, props);
  return (
    <VeloCardForm slimline {...formProps} title={Labels.TITLE}>
      <VeloFieldGrid {...gridProps} />
    </VeloCardForm>
  );
}
