import { useMemo } from 'react';
import { useUserQueryAPI } from 'velo-api-react-hooks';
import { getPagedAPIResponse } from '../../selectors';
import { usePagination } from '../queries/usePagination';
import { useSort } from '../queries/useSort';
import { VeloCacheHitContext } from 'velo-react-components';

const itemsPerPage = 20;

// Hook used to fetch a list of Payor Users.
const useUsers = ({ initialSortColumn, initialSortOrder, query }, cacheKey) => {
  const [setPage, pageQuery] = usePagination(itemsPerPage);

  const [sortColumn, sortOrder, setSort, sortQuery] = useSort(
    initialSortColumn,
    initialSortOrder
  );
  const [cacheCounter] = VeloCacheHitContext.useVeloCacheHitContext(cacheKey);
  const _query = useMemo(
    () => ({ ...query, ...pageQuery(cacheCounter), ...sortQuery() }),
    [query, pageQuery, cacheCounter, sortQuery]
  );
  const [result] = useUserQueryAPI(_query);

  // Called when the sort column/direction changes
  const onSort = (column, direction) => {
    setPage(1);
    setSort(column, direction);
  };

  const { content, page } = getPagedAPIResponse(result);
  return {
    content,
    page: { onPage: (page) => setPage(page), ...page },
    sortProps: {
      onSort,
      sortColumn,
      sortOrder,
    },
  };
};

export { useUsers };
