import { PageSize, SecondaryValueType } from 'velo-data';

import { SearchPresenterCore } from './SearchPresenterCore';

export function FundingAccountSearchPresenter(entity, query) {
  return SearchPresenterCore(
    entity.getFundingAccounts,
    (name) => ({
      ...query,
      name,
      // More results returned when no name query is present
      pageSize: name ? PageSize.SMALL : PageSize.X_LARGE,
      sort: 'name:asc',
    }),
    ({ currency, id, name }) => ({
      value: name,
      entityId: id,
      secondaryValues: [{ type: SecondaryValueType.CURRENCY, value: currency }],
    })
  );
}
