import React from 'react';
import { string } from 'prop-types';
import { useIntl } from 'react-intl';
import { VeloFlagLabel } from '../VeloFlagLabel';
import { countryName } from '../utils/countries';

function getCountryName(intl, countryCode) {
  return countryName(countryCode, intl);
}

Country.propTypes = {
  /** The 2-character ISO country code. */
  country: string.isRequired,
};

Country.getCountryName = getCountryName;

/**
 * Render a flag icon and country name.
 *
 * Uses `VeloIconLabel` and a custom icon.
 */
function Country({ country, ...props }) {
  const intl = useIntl();
  const name = getCountryName(intl, country);
  return (
    <div {...props}>
      {name ? <VeloFlagLabel country={country} label={name} /> : country}
    </div>
  );
}

export { Country };
