import { formatCallbackErrorArg } from '../../selectors';

export function LoginPresenter(
  wireframe,
  entity,
  { notification, showResetSentPage }
) {
  return [
    {
      unregisterMFA: (accessToken, body, cb) => {
        return entity.unregisterMFA(
          body,
          { Authorization: `Bearer ${accessToken}` },
          formatCallbackErrorArg((error, data) => {
            if (error) {
              wireframe.sendNote({
                ...notification.error,
                key: Date.now().toString(10),
              });
            } else {
              wireframe.sendNote({
                ...notification.success,
                key: Date.now().toString(10),
              });

              showResetSentPage();
            }

            cb(error, data);
          })
        );
      },
    },
  ];
}
