import { useState, useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import compare from 'just-compare';
import { formatCallbackErrorArg } from '../utils';

function getEntityKey(field) {
  if (field.hasOwnProperty('entityId')) {
    // by convention, it's the name + 'Id'
    return `${field.name}Id`;
  }
}

function getSecondaryKey(field) {
  if (field.hasOwnProperty('secondaryValues') && field.secondaryValues.length) {
    // by convention, it's the name + 'SecondaryValue'
    return `${field.name}SecondaryValue`;
  }
}

function getErrorKey(field) {
  if (field.hasOwnProperty('validationError')) {
    // by convention, it's the name + 'Error'
    return `${field.name}Error`;
  }
}

function populateSectionsFrom(intl, values) {
  const mapper = (field) => {
    const entityKey = getEntityKey(field);
    const hasStaticValue = field.hasOwnProperty('value');

    return {
      ...field,
      value: hasStaticValue ? field.value : values[field.name],
      ...(entityKey && { entityId: values[entityKey] }),
      ...(field.options && {
        options:
          typeof field.options === 'function'
            ? field.options(intl)
            : field.options,
      }),
    };
  };
  return ({ fields, ...section }) => ({
    ...section,
    fields: fields.map(mapper),
  });
}

function handleChange({ target: { name, value, ...props } }, setBody) {
  const entityKey = getEntityKey({ ...props, name });
  const errorKey = getErrorKey({ ...props, name });
  const secondaryKey = getSecondaryKey({ ...props, name });
  setBody({
    [name]: props.hasOwnProperty('checked') ? props.checked : value,
    ...(entityKey && { [entityKey]: props.entityId }),
    ...(errorKey && { [errorKey]: props.validationError }),
    ...(secondaryKey && { [secondaryKey]: props.secondaryValues }),
  });
}

function useFieldGridForm(
  { createSections, getInitialValues, formatBody, getButtonProps },
  { onClose, onSubmit, ...props }
) {
  const intl = useIntl();
  const values = getInitialValues(props);
  const [{ submitting, error, body, cancellation }, setState] = useState({
    submitting: false,
    error: undefined,
    body: values,
  });

  // there isn't really an effect here, just a cancellation function if given
  useEffect(
    () => () => {
      if (cancellation && typeof cancellation === 'function') {
        cancellation();
      }
    },
    [cancellation]
  );

  // Checks if initial values have been changed
  const dirty = Object.entries(values).some(([k, v]) => !compare(v, body[k]));

  const setBody = useCallback(
    (values) =>
      setState(({ body, ...state }) => ({
        ...state,
        body: {
          ...body,
          ...values,
        },
      })),
    [setState]
  );

  const sections = createSections(body, props, intl, setBody);

  const onChange = useCallback(
    (event) => handleChange(event, setBody),
    [setBody]
  );

  const allFields = sections.reduce(
    (acc, { fields }) => [...acc, ...fields],
    []
  );

  return [
    {
      sections: sections.map(populateSectionsFrom(intl, body)),
      onChange,
    },
    {
      onClose,
      overlay: submitting,
      error,
      dirty,
      onSubmit: (event) => {
        event.preventDefault();
        setState((state) => ({ ...state, submitting: true, error: undefined }));
        const cancellation = onSubmit(
          formatBody(body, props),
          formatCallbackErrorArg(allFields, (error) => {
            setState((state) => ({
              ...state,
              submitting: false,
              error,
              cancellation: undefined,
            }));
          })
        );
        // set the cancellation function
        setState((state) => ({ ...state, cancellation }));
      },
      buttonProps: {
        disabled: submitting,
        ...getButtonProps(submitting, body, dirty, values, props),
      },
    },
    body,
    setBody,
  ];
}

export { useFieldGridForm, populateSectionsFrom, handleChange as onChange };
