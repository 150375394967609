import React from 'react';
import { useParams, useHistory } from 'react-router';
import {
  PaymentsByPayoutList,
  PayoutDetailsPage,
  Pagination,
  Filter,
} from 'velo-react-components';
import { useWireframe } from '../../hooks';
import { forkResult, payoutSelectors } from '../../selectors';
import { usePaymentsByPayout } from '../../hooks';

const tableForks = {
  none(pageProps) {
    return (
      <>
        <PaymentsByPayoutList.Loading />
        <Pagination {...pageProps} disabled />
      </>
    );
  },
  empty(pageProps) {
    return <PaymentsByPayoutList.Empty isProcessing={pageProps.isProcessing} />;
  },
  value(data, pageProps, onClick) {
    return (
      <>
        <PaymentsByPayoutList.Data data={data} onClick={onClick} />
        <Pagination {...pageProps} />
      </>
    );
  },
};

const forkHandles = {
  none({ headerProps, pageProps, filterProps }, { onClose }) {
    return (
      <PayoutDetailsPage.Loading onClose={onClose}>
        <PaymentsByPayoutList.Table>
          <Filter {...filterProps} />
          <PaymentsByPayoutList.Headings {...headerProps} />
          <PaymentsByPayoutList.Loading />
          <Pagination {...pageProps} skeleton />
        </PaymentsByPayoutList.Table>
      </PayoutDetailsPage.Loading>
    );
  },
  error(error, _, { onClose }) {
    return <PayoutDetailsPage.Error onClose={onClose} error={error} />;
  },
  value(
    data,
    { pageProps, filterProps, headerProps },
    { onClose, onClick, redirectToReviewInstruct, redirectToWithdrawn }
  ) {
    const { summary, content: result } = data;

    if (payoutSelectors.selectPayoutNeedsReview(summary.payoutStatus)) {
      redirectToReviewInstruct();
      return null;
    }

    if (payoutSelectors.selectPayoutIsWithdrawn(summary.payoutStatus)) {
      redirectToWithdrawn();
      return null;
    }

    return (
      <PayoutDetailsPage {...summary} pageProps={pageProps} onClose={onClose}>
        <PaymentsByPayoutList.Table>
          <Filter {...filterProps} compact={true} />
          <PaymentsByPayoutList.Headings {...headerProps} />
          {forkResult(tableForks, { result }, pageProps, onClick)}
        </PaymentsByPayoutList.Table>
      </PayoutDetailsPage>
    );
  },
};

export function PaymentsByPayoutRoute({ query }) {
  const { payoutId } = useParams();
  const wireFrame = useWireframe(useHistory());

  const [result, props] = usePaymentsByPayout({
    payoutId,
    query,
    filters: PaymentsByPayoutList.filters,
    defaultColumn: PaymentsByPayoutList.columns.DATE_TIME,
  });

  const navProps = {
    redirectToWithdrawn: () =>
      wireFrame.navigateToPayoutWithdrawn.redirect({ payoutId }),
    redirectToReviewInstruct: () =>
      wireFrame.navigateToPayoutReview.redirect({ payoutId }),
    onClose: () => wireFrame.goBack(),
    onClick: ({ paymentId }) =>
      wireFrame.navigateToPayoutPayment({ payoutId, paymentId }),
  };

  return forkResult(forkHandles, result, props, navProps);
}
