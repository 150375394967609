import styled from 'astroturf/react';
import { VeloDivider } from '../../VeloDivider';

/**
 * Used to separate content in a Payment View.
 */
const PaymentViewDivider = styled(VeloDivider)`
  margin-top: 1rem;
`;

export { PaymentViewDivider };
