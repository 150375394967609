/**
 * Unique ids used as a lookup in routes. This is local to this file, not the
 * velo-react-components
 */
const NavigationItems = {
  PAYMENTS: 'payments',
  CHANNELS: 'payment-channels',
  PAYORS: 'payors',
  PAYOR_SECTION: 'payor-section',
  PROFILE: 'profile',
  SIGNOUT: 'signout',
  CONTACT: 'contact',
  TERMS: 'terms',
  PRIVACY: 'privacy',
  PAYEES: 'payees',
  PAYEE_USERS: 'payee-users',
  PAYEE_SECTION: 'payee-section',
  FUNDING: 'funding',
  FUNDINGS: 'fundings',
  PAYOUTS: 'payouts',
  USERS: 'users',
  OPERATING_AS: 'operating_as',
  POOLED_ACCOUNTS: 'pooled_accounts',
  ADMINS: 'admins',
  SOURCE_ACCOUNTS: 'source_accounts',
  FUNDING_ACCOUNTS: 'funding_accounts',
  PROFILE_DEFAULT: 'profile_default',
  PROFILE_COMPANY_DETAILS: 'profile_company_details',
  PROFILE_SECURITY: 'profile_security',
  PROFILE_MY_DETAILS: 'profile_my_details',
  PROFILE_COMMUNICATIONS: 'profile_communications',
  PROFILE_COMPANY_USERS: 'profile_company_users',
  REPORTS: 'reports',
  SETTINGS: 'settings',
  WEBHOOK: 'webhook',
  PLATFORM_SECTION: 'platform-section',
  DASHBOARD: 'dashboard',
  PAYMENT_REQUESTS: 'payment-requests',
  PAYIN_HISTORY: 'payin-history',
  CREDIT_PURCHASE_HISTORY: 'credit-purchase-history',
  CREDITOR_USERS: 'creditor-users',
  PROFILE_SETTINGS: 'profile-settings',
  GLOBAL_SETTINGS: 'global-settings',
  MY_KIOSK: 'my-kiosk',
  CREDITOR_ACCOUNTS: 'creditor-accounts',
  EMAIL_TEMPLATES: 'email-templates',
};

export { NavigationItems };
