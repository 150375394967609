import React from 'react';
import styled from 'astroturf/react';
import { VeloSkeleton } from '../../VeloSkeleton';

const Header = styled(VeloSkeleton)`
  width: 33%;
  display: block;
  margin: 0.5rem 0 1.5rem;
`;

const Label = styled(VeloSkeleton)`
  width: 30%;
  display: block;
  height: 12px;
`;

const Value = styled(VeloSkeleton)`
  width: 50%;
  margin-bottom: 15px;
`;

/* the payment channel details skeleton layout */
export const PaymentChannelViewDetailsSkeleton = ({ length }) => (
  <>
    <Header data-testid="velo-payments-channel-item-skeleton" />
    {[...Array(length)].map((x, i) => (
      <span key={`details-skeleton-${i}`}>
        <Label data-testid="velo-payments-channel-item-skeleton" />
        <Value data-testid="velo-payments-channel-item-skeleton" />
      </span>
    ))}
  </>
);
