import React from 'react';
import { func } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { ResultContainer } from '../ResultContainer';
import { PaymentViewItem } from '../PaymentView/PaymentViewItem';
import { PaymentViewStatus } from '../PaymentView/PaymentViewStatus';
import { PaymentViewDateTime } from '../PaymentView/PaymentViewDateTime';
import { PaymentViewAmount } from '../PaymentView/PaymentViewAmount';
import { FXRate } from '../PaymentView/BusinessPaymentView/FXRate';
import { PayorName } from '../PaymentView/BusinessPaymentView/PayorName';
import { PayeeName } from '../PaymentView/BusinessPaymentView/PayeeName';
import { PaymentReceiptContainer } from './PaymentReceiptContainer';
import { PaymentReceiptSkeleton } from './PaymentReceiptSkeleton';
import { PaymentReceiptError } from './PaymentReceiptError';
import { paymentReceiptPropTypes } from './paymentReceiptPropTypes';

BaseComponent.propTypes = {
  ...paymentReceiptPropTypes,
  renderContent: func.isRequired,
};

/**
 * Payment receipt
 */
function BaseComponent({
  renderContent,
  result: {
    payorName,
    payeeName,
    submittedDateTime,
    status,
    sourceAmount,
    sourceCurrency,
    paymentCurrency,
    rate,
    isPaymentCcyBaseCcy,
    paymentAmount,
    paymentMemo,
  },
  onClose,
  ...props
}) {
  return (
    <PaymentReceiptContainer {...props} onClose={onClose}>
      <PaymentViewItem
        label={<FormattedMessage defaultMessage="Payor/Sender" />}
        value={<PayorName result={payorName} />}
      />
      <PaymentViewItem
        label={<FormattedMessage defaultMessage="Payee/Recipient" />}
        value={<PayeeName result={payeeName} />}
      />
      <PaymentViewItem
        label={<FormattedMessage defaultMessage="Payment Instructed" />}
        value={<PaymentViewDateTime value={submittedDateTime} />}
      />
      <PaymentViewItem
        label={<FormattedMessage defaultMessage="Payment Status" />}
        value={<PaymentViewStatus status={status} />}
      />
      <PaymentViewAmount
        label={<FormattedMessage defaultMessage="Source Amount" />}
        value={sourceAmount}
        currency={sourceCurrency}
      />
      <PaymentViewItem
        label={<FormattedMessage defaultMessage="FX Rate" />}
        value={
          <FXRate
            sourceCurrency={sourceCurrency}
            paymentCurrency={paymentCurrency}
            rate={rate}
            swap={isPaymentCcyBaseCcy}
          />
        }
      />
      <PaymentViewAmount
        label={<FormattedMessage defaultMessage="Payment Amount" />}
        value={paymentAmount}
        currency={paymentCurrency}
      />
      <PaymentViewItem
        label={<FormattedMessage defaultMessage="Payment Memo" />}
        value={paymentMemo}
      />
      {renderContent({ paymentCurrency })}
    </PaymentReceiptContainer>
  );
}

const PaymentReceipt = ResultContainer({
  ErrorComponent: PaymentReceiptError,
  LoadingComponent: PaymentReceiptSkeleton,
})(BaseComponent);

PaymentReceipt.testIds = PaymentReceiptContainer.testIds;

export { PaymentReceipt };
