import React from 'react';
import { bool, func, node, string } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { VeloLabelledItem } from '../VeloLabelledItem';
import { VeloSwitch } from '../VeloSwitch';

const Switch = VeloLabelledItem.withAlignedLabel(
  ({ label, value, onText, offText, disabled, labelProps }) => (
    <VeloLabelledItem label={label} disabled={disabled} {...labelProps}>
      {value
        ? onText || <FormattedMessage defaultMessage="ON" />
        : offText || <FormattedMessage defaultMessage="OFF" />}
    </VeloLabelledItem>
  ),
  { phone: 3, desktop: 5 },
  { phone: 1, desktop: 7 }
)(({ value, label, onText, offText, ...props }) => (
  <VeloSwitch checked={value} {...props} />
));

VeloOnOffSwitch.propTypes = {
  /** The label. */
  label: node.isRequired,
  /** The current switch toggle state. */
  value: bool.isRequired,
  /** Called when the switch is toggled. */
  onChange: func.isRequired,
  /** The test ID for the switch. */
  'data-testid': string,
};

/**
 * A labelled on/off switch, useful for forms.
 *
 * This component wraps `VeloLabelledItem` to render a custom
 * label, the text **ON** or **OFF** and a `VeloSwich` that is
 * used to toggle the on/off state.
 *
 * Note that the component uses `value` instead of `checked` as
 * the toggle state for compatibility with `VeloSectionGrid` and
 * various form hooks.
 *
 * For a loading variant simply render `<VeloOnOffSwitch.Loading />`.
 */
function VeloOnOffSwitch(props) {
  return <Switch {...props} />;
}

function YesNoSwitch(props) {
  return (
    <Switch
      {...props}
      onText={<FormattedMessage defaultMessage="YES" />}
      offText={<FormattedMessage defaultMessage="NO" />}
    />
  );
}

/**
 * Loading variant.
 */
VeloOnOffSwitch.Loading = VeloLabelledItem.withAlignedLabel(() => (
  <VeloLabelledItem.Skeleton />
))(() => null);

VeloOnOffSwitch.YesNoSwitch = YesNoSwitch;

export { VeloOnOffSwitch };
