import { bool } from 'prop-types';
import styled from 'astroturf/react';

/**
 * Represents the body of a Velo Card.
 * By default the body is scrollable on mobile.
 */
export const VeloCardBody = styled('div')`
  @import 'velo-mixins';

  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;

  &.scrollable {
    /* Make the body scrollable on mobile. */
    @media (max-width: velo-breakpoint(XS)) {
      @include smooth-scrolling;

      height: auto;
    }
  }
`;

VeloCardBody.propTypes = {
  /** Make the body scrollable on mobile. */
  scrollable: bool,
};

VeloCardBody.defaultProps = {
  scrollable: true,
};
