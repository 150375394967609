import { payoutSelectors } from './payoutSelectors';

function selectPaidFromName({ payout }, preferDbaName = false) {
  return payoutSelectors.selectPayorFromName(payout, preferDbaName);
}

function selectPaidFromId({ payorId, payout }) {
  return payoutSelectors.selectPayorFromId(payout) || payorId;
}

function selectSourceAccountId(payment, { authentication }) {
  const entities = authentication.entityIds || [];
  const { sourceAccountId } = payment;
  if (sourceAccountId) {
    const payoutFromId = selectPaidFromId(payment);
    const allowed = entities.includes(payoutFromId);
    if (allowed) {
      return sourceAccountId;
    }
  }

  return undefined;
}

function selectPayeeBrandingPayorId({ payorId, payout }) {
  return payoutSelectors.selectPayorToId(payout) || payorId;
}

export const paymentSelectors = {
  selectPayeeBrandingPayorId,
  selectSourceAccountId,
  selectPaidFromName,
};
