import React from 'react';
import { ConfirmFunds } from 'velo-react-components';

SourceAccountsAddFundingConfirmRoute.asDialogRender = (props) => (
  <SourceAccountsAddFundingConfirmRoute {...props} />
);

export function SourceAccountsAddFundingConfirmRoute({
  open,
  onClose,
  ...props
}) {
  return (
    <ConfirmFunds.Dialog open={open} onClose={onClose}>
      <ConfirmFunds {...props} />
    </ConfirmFunds.Dialog>
  );
}
