import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

export function useSelectedNavItem(navItems) {
  const [selectedItem, setSelectedItem] = useState();
  const location = useLocation();

  //set the nav item for the given route
  useEffect(() => {
    const selectedNavItem =
      navItems &&
      navItems.find((item) => item.match && item.match(location.pathname));

    const selectedSubNavItem = selectedNavItem?.subMenuItems?.find(
      (item) => item.match && item.match(location.pathname)
    );

    if (selectedNavItem) setSelectedItem(selectedSubNavItem || selectedNavItem);
  }, [location, location.pathname, navItems]);

  return [selectedItem];
}
