import React from 'react';
import { bool, func } from 'prop-types';

/**
 * Component that adds support for the ESC key.
 * Useful in forms, etc.
 *
 * Simply render the component and provide a suitable
 * handler for the `onPressed` prop.
 *
 * You can disable ESC key processing via the `disabled` prop.
 */
class EscapeKey extends React.Component {
  static propTypes = {
    /** Called when the ESC key is pressed. */
    onPressed: func,
    /** Used to disable the onPressed event. */
    disabled: bool,
  };

  static defaultProps = {
    onPressed: () => {},
  };

  handleKeyDown = (event) => {
    if (event.key === 'Escape' || event.keyCode === 27) {
      const { disabled, onPressed } = this.props;
      if (!disabled) onPressed(event);
    }
  };

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  render() {
    return null;
  }
}

export { EscapeKey };
