import React from 'react';
import { func } from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import { PageHeader } from '../PageHeader';
import { VeloFab } from '../VeloFab';

export function SourceAccountsPageHeader(props) {
  return (
    <PageHeader
      title={<FormattedMessage defaultMessage="Source Accounts" />}
      {...props}
    />
  );
}

WithCreate.propTypes = {
  onCreate: func.isRequired,
};

function WithCreate({ onCreate }) {
  const intl = useIntl();

  const fab = (
    <VeloFab
      title={intl.formatMessage({ defaultMessage: 'Source Accounts' })}
      label={<FormattedMessage defaultMessage="Create Account" />}
      icon="add"
      onClick={onCreate}
      data-testid={VeloFab.testIdBuilder('create-account')}
    />
  );
  return <SourceAccountsPageHeader fab={fab} />;
}

SourceAccountsPageHeader.WithCreate = WithCreate;
