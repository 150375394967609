import React, { useMemo } from 'react';
import { useParams } from 'react-router';
import {
  SourceAccountView,
  VeloModalSheet,
  UserRoles,
  VeloNotification,
} from 'velo-react-components';
import {
  getSourceAccountsV3,
  getSourceAccountV3,
  deleteSourceAccountV3,
  getOboOverrideDetails,
} from 'velo-api/src/entities/sourceAccounts';
import {
  getFundingAccountV2,
  getFundingAccountsV2,
} from 'velo-api/src/entities/fundingAccounts';
import { getSupportedRails } from 'velo-api/src/entities/rails';
import { getPayorByIdV2 as getPayor } from 'velo-api/src/entities/payors';

import { forkResult } from '../../selectors';
import {
  useCallbackFnAsResultState,
  usePresenter,
  useSerializableMemo,
} from '../../hooks';

import { SourceAccountsViewPresenter } from './SourceAccountsViewPresenter';
import { SourceAccountsTagsRoute } from './SourceAccountsTagsRoute';
import { createRenderForksFromComponent } from '../../containers';
import { Privileges } from 'velo-data';

const entitySpec = {
  getPayor,
  getSupportedRails,
  getSourceAccount: getSourceAccountV3,
  getSourceAccounts: getSourceAccountsV3,
  getFundingAccount: getFundingAccountV2,
  getFundingAccounts: getFundingAccountsV2,
  deleteSourceAccount: deleteSourceAccountV3,
  getOboOverrideDetails,
};

const notifications = {
  success: VeloNotification.types.SOURCE_ACCOUNT_DELETE_SUCCESS,
  failure: VeloNotification.types.SOURCE_ACCOUNT_DELETE_FAILURE,
  fboFailure: VeloNotification.types.SOURCE_ACCOUNT_DELETE_FBO_FAILURE,
};

const forks = createRenderForksFromComponent(SourceAccountView);

function createPrivileges(role) {
  const privileges = [];

  const commonPrivileges = [
    Privileges.SOURCE_ACCOUNT_ADD_FUNDS,
    Privileges.SOURCE_ACCOUNT_SET_TOP_UP,
    Privileges.SOURCE_ACCOUNT_SET_LOW_BALANCE,
  ];

  if (UserRoles.isBackOffice(role)) {
    privileges.push(
      ...commonPrivileges,
      Privileges.SOURCE_ACCOUNT_VIEW_PAYOR,
      Privileges.SOURCE_ACCOUNT_EDIT,
      Privileges.SOURCE_ACCOUNT_VIEW_PHYSICAL_ACCOUNT,
      Privileges.SOURCE_ACCOUNT_DELETE
    );
  } else if (!UserRoles.isSupport(role)) {
    privileges.push(
      ...commonPrivileges,
      Privileges.SOURCE_ACCOUNT_TRANSFER_FUNDS
    );
  }

  return privileges;
}

function SourceAccountsViewRoute({ role, query }) {
  const { sourceAccountId, onTop } = useParams();
  const privileges = useMemo(() => createPrivileges(role), [role]);

  const [loader, getProps] = usePresenter(
    SourceAccountsViewPresenter,
    entitySpec,
    notifications,
    useSerializableMemo({ sourceAccountId, privileges, query })
  );

  const [result] = useCallbackFnAsResultState(loader, true);

  const createChildren = useMemo(
    () => (data) => {
      const { userDeleted } = data;
      return role === UserRoles.BOPAdmin && !userDeleted ? (
        <SourceAccountsTagsRoute
          key="tags"
          entityId={data.id}
          payorId={data.payorId}
          query={query}
        />
      ) : null;
    },
    [role, query]
  );

  const props = getProps(result, createChildren);

  return (
    <VeloModalSheet
      open={!!sourceAccountId}
      onClose={props.onClose}
      disableEscapeKey={!!onTop}
    >
      {forkResult(forks, result, props)}
    </VeloModalSheet>
  );
}

export { SourceAccountsViewRoute };
