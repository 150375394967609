import React from 'react';
import { any, elementType, oneOfType, string } from 'prop-types';
import { FormattedTime } from 'react-intl';

VeloTime.propTypes = {
  /** The time to use. */
  value: any.isRequired,
  /** The tag to use when rendering. */
  tag: oneOfType([string, elementType]),
  /** FormattedTime component props. */
  ...FormattedTime.propTypes,
};

/**
 * Renders a time using the current react-intl locale settings.
 *
 * Pass the time using the `value` prop. Additional props are
 * passed through to the underlying FormattedTime component which
 * can accept Intl options:
 *
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/DateTimeFormat
 */
function VeloTime({ value, tag: Tag = 'span', ...other }) {
  return (
    <Tag>
      <FormattedTime value={value} {...other} />
    </Tag>
  );
}

const Short = (props) => <VeloTime {...props} />;

const Long = (props) => (
  <VeloTime
    hour="numeric"
    minute="numeric"
    second="numeric"
    timeZoneName="short"
    {...props}
  />
);

VeloTime.Short = Short;
VeloTime.Long = Long;

export { VeloTime };
