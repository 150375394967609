import React from 'react';
import { func, object, shape, arrayOf, string } from 'prop-types';
import styled from 'astroturf/react';
import { VeloIcon } from '../../VeloIcon';
import { ResultContainer } from '../../ResultContainer';
import { VeloStepper } from '../../VeloStepper';
import { PaymentViewHeading } from '../PaymentViewHeading';
import { VeloStatusIndicator } from '../../VeloStatusIndicator';

const Stepper = styled(VeloStepper)`
  padding: 0;
`;

/** Component in the loading state. */
const LoadingComponent = () => (
  <>
    <PaymentViewHeading skeleton />

    <Stepper>
      <VeloStepper.Step>
        <VeloStepper.Label>
          <VeloStepper.Icon skeleton />
          <VeloStepper.Text skeleton />
        </VeloStepper.Label>
      </VeloStepper.Step>

      <VeloStepper.Step>
        <VeloStepper.Label>
          <VeloStepper.Icon skeleton />
          <VeloStepper.Text skeleton />
        </VeloStepper.Label>
      </VeloStepper.Step>
    </Stepper>
  </>
);

const InfoIcon = styled(VeloStepper.Icon).attrs({ children: 'i' })`
  @import 'velo-variables';
  background-color: velo-color('token-color-system-info-default');
  font-weight: bold;
`;

const SuccessIcon = styled(VeloStepper.Icon).attrs({
  children: <VeloIcon icon="check" />,
})`
  @import 'velo-variables';
  background-color: velo-color('token-color-system-success-default');
`;

const ErrorIcon = styled(VeloStepper.Icon).attrs({
  children: <VeloIcon icon="close" />,
})`
  @import 'velo-variables';
  background-color: velo-color('token-color-system-error-default');
`;

const WarningIcon = styled(VeloStepper.Icon).attrs({ children: '!' })`
  @import 'velo-variables';
  background-color: velo-color('token-color-system-warning-default');
  font-weight: bold;
`;

const icons = {
  [VeloStatusIndicator.types.INFO]: InfoIcon,
  [VeloStatusIndicator.types.SUCCESS]: SuccessIcon,
  [VeloStatusIndicator.types.ERROR]: ErrorIcon,
  [VeloStatusIndicator.types.WARNING]: WarningIcon,
};

/** Render the payment history. */
const BaseComponent = ({
  result,
  eventTypes,
  getEvents,
  renderEventDetails,
  heading,
}) => (
  <>
    {heading && <PaymentViewHeading>Payment History</PaymentViewHeading>}

    <Stepper>
      {getEvents(result.events).map((event, index) => {
        // Lookup the event type and label
        const info = eventTypes[event.eventType] || {
          type: VeloStatusIndicator.types.INFO,
          label: event.eventType,
        };
        // Lookup the icon to use
        const Icon = icons[info.type];

        return (
          <VeloStepper.Step key={index}>
            <VeloStepper.Label>
              <Icon />
              <VeloStepper.Text>{info.label}</VeloStepper.Text>
            </VeloStepper.Label>
            <VeloStepper.Content>
              {renderEventDetails(event)}
            </VeloStepper.Content>
          </VeloStepper.Step>
        );
      })}
    </Stepper>
  </>
);

const Component = ResultContainer({ LoadingComponent })(BaseComponent);

PaymentViewHistory.propTypes = {
  /** The data to render. */
  result: shape({
    events: arrayOf(
      shape({
        eventId: string,
        eventType: string,
      })
    ),
  }),
  /** The supported event types. */
  eventTypes: object.isRequired,
  /** Called when rendering event details. */
  renderEventDetails: func,
  /**
   * Returns the events to render, allowing them to
   * be modified first.
   */
  getEvents: func.isRequired,
};

PaymentViewHistory.defaultProps = {
  renderEventDetails: () => {},
  getEvents: (events) => events,
};

/**
 * Render Payment history.
 */
function PaymentViewHistory(props) {
  return <Component {...props} />;
}

export { PaymentViewHistory };
