import React from 'react';
import { bool } from 'prop-types';
import classnames from 'classnames';
import styled from 'astroturf/react';

/**
 * Vertical stepper content container. Render your step content using
 * this component.
 */
const Content = styled('div')`
  @import 'velo-variables';

  border-left: 1px solid velo-color('token-color-system-divider');
  margin-left: 0.75rem;
  padding: 0.75rem 1.5rem;

  &.form {
    padding-right: 1rem;
  }
`;

Content.propTypes = {
  /**
   * Styles the content with reduced margins for use in a form.
   */
  form: bool,
};

function VeloStepperContent({ className, collapsed, children, ...props }) {
  return (
    <Content
      className={classnames('velo-stepper-content', className)}
      {...props}
    >
      {collapsed ? null : children}
    </Content>
  );
}

export { VeloStepperContent };
