import React from 'react';
import { func, number, arrayOf, shape, bool, node } from 'prop-types';
import styled from 'astroturf/react';
import { VeloTab } from '../../VeloTab';
import { dataTestIdBuilder } from 'velo-data';
import { VeloSkeleton } from '../../VeloSkeleton';
import { VeloDivider } from '../../VeloDivider';
import { TestIds } from '../PaymentViewTestIds';

const TabsSkeleton = styled('div')`
  display: flex;
  padding-left: 1rem;
  height: 2.7rem;
`;

const TabSkeleton = styled(VeloSkeleton)`
  width: 7.5rem;
  min-width: 7.5rem;
  margin: 0 0.75rem 0 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
`;

const Tab = styled(VeloTab)`
  text-transform: none;
  flex: 0 1 auto;
  padding: 0 1rem;
  height: 35px;
  font-size: 1rem;
`;

PaymentViewTabs.propTypes = {
  /** Active tab state. */
  activeTab: number,
  /** Handler for managing tab state in parent */
  handleTabChange: func,
  /** Tabs data containing each label and corresponding component */
  tabs: arrayOf(
    shape({
      label: node.isRequired,
      renderContent: func.isRequired,
    })
  ),
  /** Displays a skeleton when set to true */
  skeleton: bool,
};

/**
 * Render payment view tabs.
 */
function PaymentViewTabs({
  activeTab,
  handleTabChange,
  tabs,
  skeleton,
  ...other
}) {
  return skeleton ? (
    <>
      <TabsSkeleton>
        <TabSkeleton />
        <TabSkeleton />
      </TabsSkeleton>
      <VeloDivider />
    </>
  ) : (
    <>
      <VeloTab.Bar
        activeTabIndex={activeTab}
        onActivate={handleTabChange}
        {...other}
      >
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            id={`tab-${index}`}
            data-testid={dataTestIdBuilder(TestIds.TAB_ROOT, index)}
          >
            {tab.label}
          </Tab>
        ))}
      </VeloTab.Bar>
      <VeloDivider />
    </>
  );
}

export { PaymentViewTabs };
