import React from 'react';
import { string, object, oneOf, func } from 'prop-types';
import {
  VeloNotification,
  VeloPropTypes,
  UserRoles,
} from 'velo-react-components';
import { Context } from '../../context';
import {
  useUpdatePaymentConfirmationPreference,
  useGetPaymentConfirmationPreference,
} from '../../hooks';
import {
  paymentChannelRulesSelector,
  formatError,
  formatAPIError,
  paymentSelectors,
} from '../../selectors';

const PaymentDetailMode = {
  VIEW_RECEIPT: 'VIEW_RECEIPT',
  PAYEE: 'PAYEE',
  PAYOR: 'PAYOR',
  BACKOFFICE: 'BACKOFFICE',
};

const PaymentConfirmPreference = {
  ACCEPT_AND_DISABLE: 'accept-record-disable',
  CANCEL: 'close',
};

class PaymentDetailContainer extends React.Component {
  static propTypes = {
    /** The current Payment ID. */
    paymentId: string,
    /** The default API query params. Passed by the <Route>. */
    query: object,
    /** Used to request additional data depending on mode **/
    mode: oneOf(Object.values(PaymentDetailMode)),
    /** An array of payment channel rules fetched from the API */
    allPaymentChannelRules: VeloPropTypes.paymentChannelRulesType().isRequired,
    /** Default success action */
    onSuccess: func,
    /* Function to send a notification - see useWireFrame */
    sendNote: func,
  };

  static contextType = Context;

  static defaultProps = {
    mode: PaymentDetailMode.PAYOR,
  };

  state = {
    data: {},
    countryCode: 'US',
  };

  getSourceAccountId = (payment) =>
    paymentSelectors.selectSourceAccountId(payment, this.context);

  getPaidFrom = (payment) => paymentSelectors.selectPaidFromName(payment);

  loadImplMap = {
    [PaymentDetailMode.VIEW_RECEIPT]: (payload) => {
      this.loadPayorName(payload.payorId);
      this.loadPayeeName(payload.payeeId);
      this.loadSourceAccountName(this.getSourceAccountId(payload));
      this.loadSourceAccountIsDeleted(payload.sourceAccountId);
      this.loadPaymentChannelRules(payload.countryCode);
    },
    [PaymentDetailMode.PAYEE]: (payload) => {
      this.loadPayorBranding(
        paymentSelectors.selectPayeeBrandingPayorId(payload)
      );
      this.loadPaymentChannelRules(payload.countryCode);
    },
    [PaymentDetailMode.PAYOR]: (payload) => {
      this.loadPayeeName(payload.payeeId);
      this.loadSourceAccountName(this.getSourceAccountId(payload));
      this.loadSourceAccountIsDeleted(payload.sourceAccountId);
      this.loadPaymentChannelRules(payload.countryCode);
    },
    [PaymentDetailMode.BACKOFFICE]: (payload) => {
      this.loadPayorName(payload.payorId);
      this.loadPayeeName(payload.payeeId);
      this.loadSourceAccountName(payload.sourceAccountId);
      this.loadSourceAccountIsDeleted(payload.sourceAccountId);
      this.loadPaymentChannelRules(payload.countryCode);
    },
  };

  // Process an API response and update the related
  // state property.
  loadResult = (response, name, stateName) =>
    response
      .catch((e) => e)
      .then(({ errors, [name]: result }) => ({
        result: errors ? undefined : result,
        error: formatAPIError(errors),
      }))
      .then((result) => {
        this.setState(({ data }) => ({
          data: {
            result: data.result && {
              ...data.result,
              [stateName]: result,
            },
          },
        }));
      });

  // Fetch the Payee name
  loadPayeeName = (payeeId) => {
    this.loadResult(
      payeeId
        ? this.context.api.getPayee(payeeId)
        : Promise.resolve({ displayName: '' }),
      'displayName',
      'payeeName'
    );
  };

  // Fetch the Payor name
  loadPayorName = (payorId) => {
    this.loadResult(
      this.context.api.getPayor(payorId),
      'payorName',
      'payorName'
    );
  };

  loadPayorBranding = (payorId) => {
    this.context.api
      .getPayorBranding(payorId)
      .catch((e) => e)
      .then(({ errors, dbaName, payorName }) => ({
        // Format the payorName to either be dbaName (branding) or payorName (legal name)
        result: errors ? undefined : dbaName || payorName,
        error: formatAPIError(errors),
      }))
      .then((result) => {
        this.setState(({ data }) => ({
          data: {
            result: data.result && {
              ...data.result,
              payorName: result,
            },
          },
        }));
      });
  };

  // Fetch the source account name
  loadSourceAccountName(sourceAccountId) {
    if (sourceAccountId) {
      this.loadResult(
        this.context.api.getSourceAccount(sourceAccountId),
        'name',
        'sourceAccount'
      );
    }
  }

  // Fetch the source account status
  loadSourceAccountIsDeleted(sourceAccountId) {
    if (sourceAccountId) {
      this.loadResult(
        this.context.api.getSourceAccount(sourceAccountId),
        'userDeleted',
        'sourceAccountDeleted'
      );
    }
  }

  loadPaymentChannelRules = (countryCode) => {
    this.setState(() => ({
      paymentChannelRules: paymentChannelRulesSelector(
        countryCode,
        this.props.allPaymentChannelRules
      ),
    }));
  };

  /* Default handling - can be overridden for route specific UX (see PayoutPaymentPresenter)*/
  onWithdraw = (paymentId, reason) => {
    this.context.api
      .withdrawPayment(paymentId, {
        reason,
      })
      .then((result) => {
        if (result || result === null) {
          // request cache update increment with timer - allows paymentAudit to catch up
          this.props.sendNote(
            VeloNotification.types.PAYMENT_WITHDRAW_SUCCESS,
            true,
            1000
          );
          this.props.onSuccess(paymentId);
        }
      })
      .catch((error) => {
        this.props.sendNote({
          ...VeloNotification.types.PAYMENT_WITHDRAW_FAILURE,
          message: formatError(error),
        });
      });
  };

  onConfirm = (data) => {
    const { result } = data;
    this.context.api
      .confirmPayment(result.payeeId, result.paymentId, { channel: 'PORTAL' })
      .then(() => {
        this.props.onClose();
        this.props.sendNote(
          VeloNotification.types.PAYMENT_CONFIRMED_SUCCESS,
          true,
          500
        );
      })
      .catch((error) => {
        this.props.sendNote({
          ...VeloNotification.types.PAYMENT_CONFIRMED_FAILURE,
          message: formatError(error),
        });
      });
  };

  onRecordPreference = ({ action }, data) => {
    const acceptAndStopConfirmationDialogs =
      action === PaymentConfirmPreference.ACCEPT_AND_DISABLE;

    useUpdatePaymentConfirmationPreference(acceptAndStopConfirmationDialogs);

    if (action !== PaymentConfirmPreference.CANCEL) {
      this.onConfirm(data);
    }
  };

  loadPaymentDetails = () => {
    const { paymentId, query, mode } = this.props;
    if (paymentId) {
      this.setState({ data: {} }, () => {
        this.context.api
          .getPaymentDetails(paymentId, {
            ...query,
            sensitive: !UserRoles.isPayeeSupport(this.context.user.role),
          })
          .then(
            (payment) => {
              return [
                {
                  data: {
                    result: {
                      ...payment,
                      payeeName: {},
                      paidFrom: this.getPaidFrom(payment),
                      /**
                       * Set the sourceAccount result to null when the source account
                       * data join is not possible via the API
                       */
                      sourceAccount: this.getSourceAccountId(payment)
                        ? {}
                        : { result: null },
                      /**
                       * BOP will need the FX fee details.
                       */
                      fx:
                        mode === PaymentDetailMode.BACKOFFICE ? {} : undefined,
                    },
                    error: undefined,
                  },
                },
                () => {
                  this.loadImplMap[mode](payment);
                },
              ];
            },
            // Error
            (ex) => [
              {
                data: {
                  result: undefined,
                  error: formatError(ex),
                },
              },
            ]
          )
          // Update the state
          .then(([state, updater]) => this.setState(state, updater));
      });
    }
  };

  componentDidMount() {
    this.loadPaymentDetails();
  }

  componentWillUnmount() {
    /* istanbul ignore next */
    this.setState = () => undefined;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.paymentId !== this.props.paymentId) {
      // ID changed
      this.loadPaymentDetails();
    }
  }

  render() {
    return this.props.render({
      ...this.props,
      ...this.state,
      onWithdraw: this.onWithdraw,
      onConfirm: this.onConfirm,
      onRecordPreference: this.onRecordPreference,
      stopPaymentConfirmationDialog:
        useGetPaymentConfirmationPreference.getCookiePrefs(),
    });
  }
}

export { PaymentDetailContainer, PaymentDetailMode };
