import React from 'react';
import { bool, node } from 'prop-types';
import { Ripple } from '@rmwc/ripple';
import '@material/ripple/dist/mdc.ripple.css';

function VeloRipple({ primary, accent, disabled, unbounded, children }) {
  return (
    <Ripple
      primary={primary}
      accent={accent}
      unbounded={unbounded}
      disabled={disabled}
    >
      {children}
    </Ripple>
  );
}

VeloRipple.propTypes = {
  /** Makes the ripple primary */
  primary: bool,
  /** Makes the ripple disabled */
  disabled: bool,
  /** Makes the ripple an accent color */
  accent: bool,
  /** Makes the ripple unbounded */
  unbounded: bool,
  /** Child component to wrap in a ripple */
  children: node.isRequired,
};

export { VeloRipple };
