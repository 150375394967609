import { PaymentChannelRulesLabels } from './PaymentChannelRulesLabels';

function localisationLabelFallback({ displayName }) {
  console.warn(`paymentChannelRule::'${displayName}' not mapped`);
  return displayName;
}

function mapDisplayName({ displayName }) {
  return displayName?.split(' ').join('_').toUpperCase();
}

function getLocalisedPaymentChannelRules(paymentChannelRules) {
  return {
    bank: paymentChannelRules?.map(({ isoCountryCode, rules }) => ({
      isoCountryCode,
      rules: rules?.map((rule) => ({
        ...rule,
        displayName:
          PaymentChannelRulesLabels[mapDisplayName(rule)] ||
          localisationLabelFallback(rule),
      })),
    })),
  };
}

export { getLocalisedPaymentChannelRules };
