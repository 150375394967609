import React, { useState } from 'react';
import { bool, func, string, shape } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styled from 'astroturf/react';
import Money from 'js-money';
import { TokenSizeLayoutXsBreakpoint } from '@design-system/tokens/layout/js/layout-tokens.module';
import { VeloDialog } from '../VeloDialog';
import { VeloTypography } from '../VeloTypography';
import { VeloSectionGrid } from '../VeloSectionGrid';
import { VeloDivider } from '../VeloDivider';
import { ErrorMessage } from '../ErrorMessage';
import { PayoutDetailsFields } from './PayoutDetailsFields';
import { YapilyConnectFields } from '../YapilyConnectFields';
import { VeloPropTypes } from '../VeloPropTypes';
import { VeloFullScreenLoadingOverlay } from '../VeloFullScreenLoadingOverlay';

const root = 'confirm-payout-dialog';

const TestIds = {
  CONFIRM: `${root}-confirm-button`,
  CANCEL: `${root}-cancel-button`,
  SUBMITTING_OVERLAY: `${root}-submit-overlay`,
};

const Error = styled(ErrorMessage)`
  margin-top: 1rem;
`;

const BankIconImage = styled('img')`
  height: 36px;
  width: auto;
  margin-bottom: 0.5rem;
`;

const BankCard = styled('div')`
  width: 130px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
`;

const DIALOG_WIDTH = '711px';

const Dialog = styled(VeloDialog)`
  :global {
    .mdc-dialog__surface {
      position: relative;
    }

    .mdc-dialog__actions {
      border: none;
    }

    @media (min-width: ${DIALOG_WIDTH}) {
      .mdc-dialog__surface {
        min-width: ${DIALOG_WIDTH};
      }
    }

    @media (max-width: ${DIALOG_WIDTH}) {
      .mdc-dialog__surface {
        border-radius: 0;
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
`;

ConfirmPayoutDialog.propTypes = {
  /** Open/close the dialog. */
  open: bool.isRequired,
  /** Called when the form is submitted. Passed the code. */
  onSubmit: func.isRequired,
  /** Called when the dialog is closed/ESC is pressed. */
  onClose: func.isRequired,
  /** The currency for the payout. */
  currency: string.isRequired,
  /** The paymentChannel details for the payor/payee relationship. */
  paymentChannelData: VeloPropTypes.result(),
  /** The form data which is submitted when the dialog is confirmed. */
  formData: shape({
    payeeName: string.isRequired,
    payeeId: string,
    /** The paymentMemo for the payout. */
    paymentMemo: string.isRequired,
    /** The amount of the payout. */
    amount: string,
  }),
  selectedBank: shape({
    id: string.isRequired,
    name: string.isRequired,
    iconUrl: string.isRequired,
  }),
};

ConfirmPayoutDialog.testIds = TestIds;

export function ConfirmPayoutDialog({
  open,
  onSubmit,
  currency,
  onClose,
  paymentChannelData,
  formData,
  selectedBank,
}) {
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(undefined);

  /**
   * The dialog can only be opened when the form data is valid.
   * This works in conjunction with native form validation used in OpenBankingCreatePayoutForm
   */
  const amount = open
    ? Money.fromDecimal(formData.amount, currency, 'round').amount
    : 0;

  // This will handle using ESC to close the dialog
  const onCloseDialog = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    setSubmitError(undefined);

    if (evt.detail.action === 'close' && !submitting) {
      setSubmitting(false);
      onClose();
    }

    if (evt.detail.action === 'accept') {
      setSubmitting(true);
      onSubmit(
        {
          ...formData,
          amount,
          selectedBank,
          currency,
        },
        (error) => {
          setSubmitting(false);

          if (error) {
            setSubmitError(error);
          }
        }
      );
    }
  };

  return (
    <Dialog open={open} disableTrapFocus onClose={onCloseDialog}>
      <VeloDialog.Title>
        <FormattedMessage defaultMessage="Confirm payout" />
      </VeloDialog.Title>
      <VeloDialog.Content>
        <VeloSectionGrid
          compact
          breakpoint={TokenSizeLayoutXsBreakpoint}
          sections={[
            {
              fields: [
                {
                  desktop: 4,
                  tablet: 4,
                  phone: 4,
                  section: (
                    <VeloSectionGrid
                      compact
                      sections={[
                        {
                          fields: [
                            <VeloTypography use="sectionHeader">
                              <FormattedMessage defaultMessage="Payout from" />
                            </VeloTypography>,
                            ...(selectedBank
                              ? [
                                  <BankCard>
                                    <BankIconImage
                                      src={selectedBank.iconUrl}
                                      alt={selectedBank.name}
                                    />
                                    <VeloTypography use="bodyText">
                                      {selectedBank.name}
                                    </VeloTypography>
                                  </BankCard>,
                                ]
                              : []),
                          ],
                        },
                      ]}
                      render={(component) => component}
                    />
                  ),
                },
                {
                  desktop: 8,
                  tablet: 4,
                  phone: 4,
                  section: (
                    <PayoutDetailsFields
                      {...paymentChannelData}
                      {...formData}
                      amount={amount}
                      currency={currency}
                    />
                  ),
                },
                <VeloDivider />,
                {
                  desktop: 9,
                  tablet: 6,
                  phone: 4,
                  section: <YapilyConnectFields />,
                },
                <VeloDivider />,
                <VeloTypography use="bodyTextEmphasised">
                  <FormattedMessage defaultMessage="Do you want to connect to your bank to make this payment?" />
                </VeloTypography>,
              ],
            },
          ]}
          render={(component) => component.section || component}
        />

        {submitError && <Error>{submitError}</Error>}
        {submitting && (
          <VeloFullScreenLoadingOverlay
            data-testid={TestIds.SUBMITTING_OVERLAY}
          >
            <FormattedMessage defaultMessage="You are being securely transferred to your bank for payment authentication." />
          </VeloFullScreenLoadingOverlay>
        )}
      </VeloDialog.Content>
      <VeloDialog.Actions>
        <VeloDialog.Button
          data-testid={TestIds.CONFIRM}
          action="accept"
          disabled={submitting}
        >
          <FormattedMessage defaultMessage="Confirm" />
        </VeloDialog.Button>
        <VeloDialog.Button
          data-testid={TestIds.CANCEL}
          action="close"
          disabled={submitting}
        >
          <FormattedMessage defaultMessage="Cancel" />
        </VeloDialog.Button>
      </VeloDialog.Actions>
    </Dialog>
  );
}
