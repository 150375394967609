import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import {
  PayorPayeeView,
  VeloModalSheet,
  UserRoles,
  VeloNotification,
} from 'velo-react-components';
import { PayeeViewRoutePresenter } from './PayeeViewRoutePresenter';
import {
  useCallbackFnAsResultState,
  usePayeeCountryRules,
  usePresenter,
  useSerializableMemo,
} from '../../hooks';
import {
  deletePayeeByIdV4 as deletePayee,
  getPayeeByIdV4 as loadPayee,
  resendPayeeInviteV4 as resendPayeeInvite,
} from 'velo-api/src/entities/payees';
import { createRenderForksFromComponent } from '../../containers';
import { forkResult } from '../../selectors';

const entities = {
  deletePayee,
  loadPayee,
  resendPayeeInvite,
};

const notifications = {
  deletePayeeSuccess: VeloNotification.types.DELETE_PAYEE_SUCCESS,
  deletePayeeFailure: VeloNotification.types.DELETE_PAYEE_FAILURE,
  resendInviteSuccess: VeloNotification.types.RESEND_INVITE_SUCCESS,
  resendInviteFailure: VeloNotification.types.RESEND_INVITE_FAILURE,
};

const forkHandles = createRenderForksFromComponent(PayorPayeeView);

function PayeeViewRoute({ open, payeeId, payorId, payorUserRole }) {
  const { payeeCountryRules } = usePayeeCountryRules();
  const intl = useIntl();
  const isPayorAdmin = UserRoles.isPayorAdmin(payorUserRole);

  const [loader, getProps] = usePresenter(
    PayeeViewRoutePresenter,
    entities,
    useMemo(
      () => ({ payeeCountryRules, notifications, isPayorAdmin, intl }),
      [payeeCountryRules, isPayorAdmin, intl]
    ),
    useSerializableMemo({ payeeId, payorId })
  );

  const [result] = useCallbackFnAsResultState(loader);

  const props = {
    ...getProps(result),
    payeeCountryRules,
  };

  return (
    <VeloModalSheet open={open} onClose={props.onClose}>
      {forkResult(forkHandles, result, props)}
    </VeloModalSheet>
  );
}

export { PayeeViewRoute };
