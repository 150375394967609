import { useCallback } from 'react';
import { useRegisterUserSmsAPI } from 'velo-api-react-hooks';
import { formatError } from '../../selectors';

export const useRegisterUserSms = () => {
  const registerUserSms = useRegisterUserSmsAPI();

  const onSendOtp = useCallback(
    (body, cb) => {
      registerUserSms(body, (error) => {
        cb(formatError(error));
      });
    },
    [registerUserSms]
  );

  return onSendOtp;
};
