import React from 'react';
import { useIntl } from 'react-intl';
import {
  Content,
  UserForm,
  VeloCardForm,
  VeloNotification,
  UserRoles,
} from 'velo-react-components';
import { forkResult } from '../../selectors';
import {
  useWireframe,
  useInvitePayorUser,
  useRegisterUserSms,
} from '../../hooks';

const UserRoleComponentMap = {
  [UserRoles.BOPAdmin]: ({ onClose, onSubmit, onSendOtp, data, intl }) => {
    return forkResult(
      {
        none: () => <UserForm.Loading onClose={onClose} />,
        error: (error) => (
          <VeloCardForm.Error onClose={onClose} error={error} />
        ),
        value: (data) => (
          <UserForm.CreatePayor
            data={data}
            defaultCountry="US"
            // BOP Admin can only create Master Payor Admins
            roles={[UserRoles.option(UserRoles.PayorMaster, intl)]}
            onClose={onClose}
            onSubmit={onSubmit}
            onSendOtp={onSendOtp}
          />
        ),
      },
      data
    );
  },
  [UserRoles.PayorMaster]: ({ data, intl, ...props }) => (
    <UserForm.CreatePayor
      defaultCountry="US"
      // Master Payor Admin can create Admin or Support users
      roles={[
        UserRoles.option(UserRoles.PayorAdmin, intl),
        UserRoles.option(UserRoles.PayorSupport, intl),
      ]}
      {...props}
    />
  ),
  [UserRoles.PayorAdmin]: ({ data, intl, ...props }) => (
    <UserForm.CreatePayor
      defaultCountry="US"
      // Admin can only create Support users
      roles={[UserRoles.option(UserRoles.PayorSupport, intl)]}
      {...props}
    />
  ),
};

function PayorUsersCreateRoute({ history, role, payorId }) {
  const { goBack: onClose, sendNote } = useWireframe(history);
  const intl = useIntl();
  const Component = UserRoleComponentMap[role];

  const onSuccess = () => {
    onClose();
    sendNote({
      ...VeloNotification.types.CREATE_PAYOR_USER_SUCCESS,
      key: Date.now().toString(10),
    });
  };

  const { onSubmit, data } = useInvitePayorUser({
    payorId,
    onSuccess,
    role,
  });

  const onSendOtp = useRegisterUserSms();

  return (
    <Content>
      <Component
        intl={intl}
        onSubmit={onSubmit}
        onClose={onClose}
        onSendOtp={onSendOtp}
        data={data}
      />
    </Content>
  );
}

export { PayorUsersCreateRoute };
