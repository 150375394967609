import { useLayoutEffect } from 'react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

ScrollLock.defaultProps = {
  getScrollableElement: () => {},
  isEnabled: true,
};
/**
 * Render this component to lock scrolling on the
 * document body. Useful when displaying popups.
 */
function ScrollLock({ getScrollableElement, isEnabled }) {
  useLayoutEffect(() => {
    if (isEnabled) {
      const scrollableElement = getScrollableElement();
      disableBodyScroll(scrollableElement);

      return () => {
        enableBodyScroll(scrollableElement);
      };
    }
  }, [getScrollableElement, isEnabled]);
  return null;
}

export { ScrollLock };
