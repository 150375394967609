import { VeloNotification } from 'velo-react-components';
import { themes } from '@design-system/tokens/colors/js/theme-defs.js';

export function SettingsAppearancePresenter(
  wireframe,
  _,
  { theme, onThemeChange }
) {
  const successNotification = VeloNotification.types.EDIT_APPEARANCE_MODE;
  const defaultTheme =
    (theme && { name: theme }) || themes.find((theme) => theme.default);

  const onSubmit = ({ themeSelection }) => {
    onThemeChange(themeSelection);
    wireframe.sendNote(successNotification);
    wireframe.goBack();
  };

  const onChange = (themeObj) => {
    onThemeChange(themeObj.theme);
  };

  return { onSubmit, onChange, defaultTheme, themes };
}
