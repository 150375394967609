import React from 'react';
import { bool, node, string } from 'prop-types';
import styled from 'astroturf/react';
import { VeloTypography } from '../../VeloTypography';

const Container = styled('div')`
  margin-bottom: 1rem;
`;

const Value = styled(VeloTypography)`
  word-break: break-all;
`;

PaymentChannelViewItem.propTypes = {
  /** The label to use. */
  label: node.isRequired,
  /** The value to display, */
  value: node,
  /** Render in the disabled state. */
  disabled: bool,
  /** A suitable test ID for the value. */
  valueTestId: string,
};

/**
 * Render a channel label/value pair.
 */
export function PaymentChannelViewItem({
  label,
  value,
  disabled,
  valueTestId,
}) {
  return value ? (
    <Container>
      <VeloTypography use="itemLabel" tag="div">
        {label}
      </VeloTypography>

      <Value use="itemContent" tag="div" data-testid={valueTestId}>
        {value}
      </Value>
    </Container>
  ) : null;
}
