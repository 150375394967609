const RootId = 'add-single-payee';

const TestIds = {
  FORM: `${RootId}-form`,
  INDIVIDUAL: `${RootId}-individual`,
  COMPANY: `${RootId}-company`,
  CSV: `${RootId}-csv`,
  GROUP_CSV_INSTRUCTIONS: `${RootId}-csv-instructions`,
  TOGGLE_PAYMENT_CHANNEL: `${RootId}-toggle-payment-channel`,
  CREATE: `${RootId}-create`,
  CANCEL: `${RootId}-cancel`,
  ERROR: `${RootId}-error`,
  SKRIM: `${RootId}-skrim`,
  PROGRESS: `${RootId}-progress`,
  CONFIRMATION_DIALOG: `${RootId}-confirmation-dialog`,
  TOGGLE_CHALLENGE_CODE: `${RootId}-toggle-challenge-code`,
};

export { RootId, TestIds };
