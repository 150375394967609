import { PayorAccountType } from 'velo-data';
import { createEarlyExitCallback } from '../selectors';

export function SupportedRailsPresenter(entity) {
  const payorAccountTypes = [
    PayorAccountType.FBO,
    PayorAccountType.PRIVATE,
    PayorAccountType.PRIVATE_COUPLED,
  ];

  return (cb) => {
    return entity.getSupportedRails(
      createEarlyExitCallback((rails) => {
        cb(undefined, { payorAccountTypes, ...rails });
      }, cb)
    );
  };
}
