import React, { useRef } from 'react';
import { arrayOf, bool, func, string, oneOf } from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import styled from 'astroturf/react';
import { PaymentChannelType } from '../FormFields/PaymentChannelType';
import { ChannelName } from '../FormFields/ChannelName';
import { SelectCountry } from '../FormFields/SelectCountry';
import { SelectCurrency } from '../FormFields/SelectCurrency';
import { VeloSkeleton } from '../VeloSkeleton';
import { VeloTypography } from '../VeloTypography';
import { VeloTextField } from '../VeloTextField';
import { Custom } from '../FormFields/FormFieldTypes';
import { VeloPropTypes } from '../VeloPropTypes';
import { PaymentChannelsBankLookupMode } from './PaymentChannelsBankLookupMode';
import { useElementBreakpoint } from '../hooks';

const Form = styled('div')`
  @import 'velo-variables';

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &.narrow {
    flex-direction: column;
  }

  @media (max-width: velo-breakpoint(XS)) {
    flex-direction: column;
  }

  :global(.mdc-text-field-helper-text) {
    margin-bottom: 1.5rem;
  }
`;

Form.propTypes = {
  narrow: bool,
};

const Start = styled('div')`
  @import 'velo-variables';

  width: 100%;
  margin-right: 1rem;
  display: flex;

  @media (max-width: velo-breakpoint(XS)) {
    padding-right: 0;
    margin-right: 0;
  }

  @media (min-width: velo-breakpoint(XS)) {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
`;

const End = styled('div')`
  @import 'velo-variables';

  width: 100%;
  margin-left: 1rem;
  display: flex;

  @media (max-width: velo-breakpoint(XS)) {
    padding-left: 0;
    margin-left: 0;
  }

  @media (min-width: velo-breakpoint(XS)) {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  &.narrow {
    margin-left: 0;
  }
`;

End.propTypes = {
  narrow: bool,
};

const InternalCard = styled('div')`
  @import 'velo-variables';

  @media (min-width: velo-breakpoint(XS)) {
    padding: 1.5rem 1.5rem 0;
    display: flex;
  }
  flex-direction: column;
  width: 100%;

  &.compact {
    padding: 0;
  }
`;

InternalCard.propTypes = {
  compact: bool,
};

const SectionTitle = styled(VeloTypography).attrs({ use: 'sectionHeader' })`
  @import 'velo-variables';

  border-bottom: 1px solid velo-color('token-color-system-divider');
  width: 100%;
  display: inline-block;
  margin-bottom: 2rem;
  padding-bottom: 0.5rem;
`;

const ChannelType = styled(PaymentChannelType)`
  margin-bottom: 1.5rem;
`;

const FieldSkeleton = styled(VeloSkeleton)`
  height: 56px;
  margin-bottom: 30px;
  margin-top: 6px;

  &:last-child {
    margin-top: 13px;
  }
`;

const root = 'velo-payment-channel';
const TestIds = {
  CHANNEL_NAME: `${root}-channel-name`,
  CURRENCY: `${root}-currency`,
  COUNTRY: `${root}-country`,
  SKELETON: `${root}-form-body-field-skeleton`,
};
PaymentChannelFormBody.testIds = TestIds;
PaymentChannelFormBody.root = root;
PaymentChannelFormBody.propTypes = {
  /** Custom CSS class(es). */
  className: string,
  /** Disable all fields. */
  disabled: bool,
  /**Payment Method Name. */
  paymentChannelName: string,
  /** The channel country code. */
  countryCode: string,
  /** The channel currency code. */
  currency: string,
  /** The account name. */
  accountName: string,
  /** The routing number (e.g. 123-456-789) */
  routingNumber: string,
  /** The account number. */
  accountNumber: string,
  /** The IBAN. */
  iban: string,
  /** The list of country codes to display. */
  countries: arrayOf(string),
  /** The list of currency codes to display. */
  currencies: arrayOf(string),
  /** The list of payment channel rules for bank fields to display. */
  paymentChannelRules: arrayOf(VeloPropTypes.paymentChannelRuleItemType()),
  /** Called when a field is changed. Use the HTML "name" attribute for the type. */
  onChange: func,
  /** the parent component (affects the layout styless to determine padding) */
  mode: oneOf(Object.values(PaymentChannelsBankLookupMode)),
};

//below this form width breakpoint, the fields will be displayed in a single column
const singleColumnBreakpoint = 680;

/**
 * Form fields used to capture/edit Payment Channel details.
 */
function PaymentChannelFormBody({
  className,
  disabled,
  paymentChannelName,
  countryCode,
  currency,
  accountName,
  routingNumber,
  accountNumber,
  iban,
  countries,
  currencies,
  onChange,
  paymentChannelRules,
  banklookupMode,
  showAdvancedPaymentMethods,
  ...other
}) {
  //mapping for the channel payment rules fields
  const channelPaymentRulesFieldMapping = {
    accountNumber: accountNumber,
    routingNumber: routingNumber,
    accountName: accountName,
    iban: iban,
  };
  const grid = useRef(null);
  const singleColumn = useElementBreakpoint(grid, singleColumnBreakpoint);
  const intl = useIntl();

  return (
    <Form className={className} narrow={singleColumn} {...other} ref={grid}>
      <Start>
        <InternalCard
          compact={
            banklookupMode !== PaymentChannelsBankLookupMode.PaymentChannelForm
          }
        >
          <SectionTitle>
            <FormattedMessage defaultMessage="Payment Method Details" />
          </SectionTitle>

          <ChannelType
            disabled={disabled}
            advanced={showAdvancedPaymentMethods}
          />

          <ChannelName
            name="paymentChannelName"
            id="paymentChannelName"
            value={paymentChannelName}
            onChange={onChange}
            required
            disabled={disabled}
            data-testid={TestIds.CHANNEL_NAME}
          />

          <SelectCountry
            name="countryCode"
            id="countryCode"
            countries={countries}
            value={countries && countries.length > 0 ? countryCode : ''}
            onChange={onChange}
            required
            disabled={disabled}
            data-testid={TestIds.COUNTRY}
            style={{ marginBottom: '2.6875rem' }}
          />

          <SelectCurrency
            name="currency"
            id="currency"
            currencies={currencies}
            value={currencies && currencies.length > 0 ? currency : ''}
            onChange={onChange}
            required
            disabled={disabled}
            data-testid={TestIds.CURRENCY}
            style={{ marginBottom: '2.6875rem' }}
          />
        </InternalCard>
      </Start>

      <End narrow={singleColumn}>
        <InternalCard
          compact={
            banklookupMode !== PaymentChannelsBankLookupMode.PaymentChannelForm
          }
        >
          <SectionTitle>
            <FormattedMessage defaultMessage="Account Details" />
          </SectionTitle>

          {(!paymentChannelRules || paymentChannelRules.length === 0) && (
            <>
              <FieldSkeleton data-testid={TestIds.SKELETON} />
              <FieldSkeleton data-testid={TestIds.SKELETON} />
              <FieldSkeleton data-testid={TestIds.SKELETON} />
            </>
          )}
          {
            // Dynamic fields based on the rules for this country
            paymentChannelRules &&
              paymentChannelRules.map(
                (
                  {
                    element,
                    displayName,
                    validation,
                    minLength,
                    maxLength,
                    required,
                  },
                  index
                ) => {
                  // Show constraints in the placeholder
                  // For this to be accurate we need to know if alpha chars
                  // are valid.
                  const type =
                    'x'.repeat(minLength).match(validation) === null
                      ? intl.formatMessage({ defaultMessage: 'digits' })
                      : intl.formatMessage({ defaultMessage: 'characters' });

                  const placeholder =
                    minLength === maxLength
                      ? intl.formatMessage(
                          { defaultMessage: '{minLength} {type}' },
                          { minLength, type }
                        )
                      : intl.formatMessage(
                          { defaultMessage: '{minLength}-{maxLength} {type}' },
                          { minLength, maxLength, type }
                        );

                  return (
                    <VeloTextField
                      {...Custom(
                        displayName,
                        <FormattedMessage
                          defaultMessage="Please enter the {displayName}"
                          values={{ displayName }}
                        />,
                        validation,
                        minLength,
                        maxLength
                      )}
                      key={index}
                      id={element}
                      name={element}
                      onChange={onChange}
                      required={required}
                      value={channelPaymentRulesFieldMapping[element]}
                      disabled={disabled}
                      data-testid={`${root}-${element}`}
                      placeholder={placeholder}
                    />
                  );
                }
              )
          }
        </InternalCard>
      </End>
    </Form>
  );
}

export { PaymentChannelFormBody };
