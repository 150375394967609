import React, { useCallback, useMemo } from 'react';
import { Route, Redirect, Switch, useHistory } from 'react-router';
import { PayeesList } from 'velo-react-components';
import {
  useAppContext,
  useWireframe,
  PaymentsListMode,
  BusinessPaymentsRoute,
  PaymentReceiptRoute,
  useOperatingUser,
  PayeesListRoute,
  PayorUsersCreateRoute,
  PayorUsersRoute,
  PayorUsersListRoute,
  PayoutPaymentRoute,
  PaymentsByPayoutRoute,
  SourceAccountsRoute,
  PayoutReviewRoute,
  PayoutProcessingRoute,
  FundingAccountsRoute,
  SettingsRoute,
  PayoutWithdrawnRoute,
  PayeeViewRoute,
  PayoutsListRoute,
  EditRemoteIdRoute,
  PaymentMethodsRoute,
} from 'velo-portal-common';
import { AppNavigation } from '../../Navigation';
import { PayorCreatePayeeRoute } from '../PayorCreatePayeeRoute';
import { PayorFundingRoute } from '../PayorFundingRoute';
import { PayorReportsRoute } from '../PayorReportsRoute';
import { CreatePayoutRoute } from '../CreatePayoutRoute';
import { OpenBankingCreatePayoutRoute } from '../OpenBankingCreatePayoutRoute';
import { SourceAccountsTransferFundsRoute } from '../SourceAccounts';
import { EditPayeeRoute } from '../EditPayeeRoute';
import { PayorWebhookUpsertRoute } from '../PayorWebhookUpsertRoute';
import { useRemoteRoutes } from 'velo-portal-common/src/hooks/useRemoteRoutes';

const query = { sensitive: true };

export function useResetToBaseRoute({ wireframe, history }) {
  return useCallback(() => {
    const baseRoutes = [
      wireframe.navigateToUsersList.path,
      wireframe.navigateToPayeesList.path,
      wireframe.navigateToPayoutsList.path,
      wireframe.navigateToPaymentsList.path,
      wireframe.navigateToSourceAccountsList.path,
      wireframe.navigateToFundingAccountsList.path,
      wireframe.navigateToReports.path,
    ];

    const { pathname, search } = history.location;
    const paths = pathname.split('/').filter((path) => !!path);
    const baseRoute = () => paths.length && `/${paths[0]}`;
    const resetRoute = (pathname) => history.replace({ pathname, search: '' });

    if (paths.length > 1 || search.length) {
      const safeRoute = baseRoutes.includes(baseRoute())
        ? baseRoute()
        : wireframe.navigateToPayoutsList.path;
      resetRoute(safeRoute);
    }
  }, [history, wireframe]);
}

export function PrivateRoutes({ routes }) {
  const history = useHistory();
  const { remoteRoutes } = useRemoteRoutes();
  const wireframe = useWireframe(history);
  const role = useAppContext().user.role;
  const payorId = useOperatingUser();
  const resetToBaseRoute = useResetToBaseRoute({ wireframe, history });
  const queryWithPayorId = useMemo(() => ({ payorId }), [payorId]);
  const PayeeDetailView = useCallback(
    (props) => (
      <PayeeViewRoute
        {...props}
        payorId={payorId}
        payorUserRole={role}
        baseRoute={wireframe.navigateToPayeesList.path}
        history={history}
      />
    ),
    [history, payorId, role, wireframe]
  );

  return (
    <AppNavigation
      resetToBaseRoute={resetToBaseRoute}
      history={history}
      query={query}
      payorId={payorId}
      routes={routes}
    >
      <Switch>
        {/* /payouts */}
        <Route path={wireframe.navigateToPayoutCreate.path}>
          <CreatePayoutRoute payorId={payorId} />
        </Route>

        <Route path={wireframe.navigateToPayoutCreateOpenBanking.path}>
          <OpenBankingCreatePayoutRoute payorId={payorId} />
        </Route>

        <Route path={wireframe.navigateToPayoutPaymentReceipt.path} exact>
          <PaymentReceiptRoute />
        </Route>

        <Route path={wireframe.navigateToPayoutProcessing.path} exact>
          <PayoutProcessingRoute />
        </Route>

        <Route path={wireframe.navigateToPayoutReview.path}>
          <PayoutReviewRoute role={role} payorId={payorId} />
          <Route path={wireframe.navigateToPayoutPaymentReview.path} exact>
            {() => <PayoutPaymentRoute query={query} role={role} />}
          </Route>
        </Route>

        <Route path={wireframe.navigateToPayoutsList.path} exact>
          <PayoutsListRoute.Payor payorId={payorId} role={role} />
        </Route>

        <Route path={wireframe.navigateToPayoutWithdrawn.path} exact>
          <PayoutWithdrawnRoute query={query} />
        </Route>

        <Route path={wireframe.navigateToPayoutDetail.path}>
          <>
            <PaymentsByPayoutRoute query={query} />
            <Route path={wireframe.navigateToPayoutPayment.path}>
              <PayoutPaymentRoute query={query} />
            </Route>
          </>
        </Route>

        {/* /payees */}
        <Route path={wireframe.navigateToPayeeCreate.path}>
          <PayorCreatePayeeRoute
            payorId={payorId}
            rootPath={wireframe.navigateToPayeesList.path}
          />
        </Route>

        <Route path={wireframe.navigateToPayeeEditRemoteId.path}>
          <EditRemoteIdRoute.PAYOR payorId={payorId} />
        </Route>

        <Route path={wireframe.navigateToPayeePaymentMethods.path}>
          <PaymentMethodsRoute.Payor payorId={payorId} query={query} />
        </Route>

        <Route path={wireframe.navigateToPayeeEdit.path}>
          <EditPayeeRoute payorId={payorId} />
        </Route>

        {/* redirect and reload on refresh route */}
        <Route path={wireframe.navigateToPayeeRefresh.path} exact>
          <Redirect to={wireframe.navigateToPayeesList.path} />
        </Route>

        <Route path={wireframe.navigateToPayeesList.path}>
          <PayeesListRoute
            query={queryWithPayorId}
            mode={PayeesList.mode.PAYOR}
            role={role}
            PayeeDetailView={PayeeDetailView}
          />
        </Route>

        {/* /payments */}
        <Route path={wireframe.navigateToPaymentsList.path}>
          <BusinessPaymentsRoute
            mode={PaymentsListMode.PAYOR}
            query={{ ...query, payorId }}
            role={role}
          />
        </Route>

        {/* /source-accounts */}
        <Route path={wireframe.navigateToSourceAccountsList.path}>
          <Switch>
            <Route
              path={wireframe.navigateToSourceAccountTransferFunds.path}
              exact
            >
              <SourceAccountsTransferFundsRoute />
            </Route>
            <SourceAccountsRoute role={role} query={queryWithPayorId} />
          </Switch>
        </Route>

        <Route path={wireframe.navigateToFundingAccountsList.path}>
          <FundingAccountsRoute query={queryWithPayorId} role={role} />
        </Route>

        {/* /funding */}
        <Route path={wireframe.navigateToFundingList.path}>
          <PayorFundingRoute payorId={payorId} />
        </Route>

        {/* /users */}
        <Route path={wireframe.navigateToUsersList.path}>
          <Switch>
            <Route
              path={wireframe.navigateToUserCreate.path}
              exact
              render={(routeProps) => (
                <PayorUsersCreateRoute
                  {...routeProps}
                  role={role}
                  payorId={payorId}
                />
              )}
            />
            <PayorUsersRoute role={role} payorId={payorId}>
              <PayorUsersListRoute role={role} payorId={payorId} />
            </PayorUsersRoute>
          </Switch>
        </Route>

        {/* /reports */}
        <Route path={wireframe.navigateToReports.path}>
          <PayorReportsRoute query={query} payorId={payorId} />
        </Route>

        {/* /settings */}
        <Route path={wireframe.navigateToSettingsWebhook.path}>
          <PayorWebhookUpsertRoute payorId={payorId} role={role} />
        </Route>

        <Route path={wireframe.navigateToSettings.path}>
          <SettingsRoute rootPath={wireframe.navigateToPaymentsList.path} />
        </Route>

        {/* istanbul ignore next */}
        {remoteRoutes}

        <Redirect to={wireframe.navigateToPayoutsList.path} />
      </Switch>
    </AppNavigation>
  );
}
