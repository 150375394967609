import React from 'react';
import { shape, string } from 'prop-types';

import { listPayorsV2 as getPayors } from 'velo-api/src/entities/payors';
import {
  createFundingAccountV2 as createFundingAccount,
  getFundingAccountStatusV2 as getFundingAccountStatus,
} from 'velo-api/src/entities/fundingAccounts';
import { useCurrencies, usePresenter } from '../../hooks';
import {
  VeloNotification,
  FundingAccountCreateForm,
  Content,
} from 'velo-react-components';
import { FundingAccountsCreatePresenter } from './FundingAccountsCreatePresenter';

const entitySpec = {
  getPayors,
  createFundingAccount,
  getFundingAccountStatus,
};

const notification = VeloNotification.types.FUNDING_ACCOUNT_CREATE_SUCCESS;

FundingAccountsCreateRoute.propTypes = {
  /**
   * The current query object is required, but the internal fields are optional
   */
  query: shape({
    /**
     * The referenced `payorId` (optional)
     */
    payorId: string,
    /**
     * The presentational name of the payor. If `payorId` is set, then this
     * field must also be set
     */
    payorIdName: string,
  }).isRequired,
};

export function FundingAccountsCreateRoute({ query }) {
  const currencies = useCurrencies();
  const [props] = usePresenter(
    FundingAccountsCreatePresenter,
    entitySpec,
    notification,
    query
  );

  return (
    <Content>
      <FundingAccountCreateForm
        currencies={currencies.currencyLabelList}
        {...props}
      />
    </Content>
  );
}
