import React, { useRef } from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { VeloList } from '../../VeloList';
import { VeloTypography } from '../../VeloTypography';
import { VeloThemeContext } from '../../VeloThemeContext';
import { NavigationDrawerOperatingAsItem } from '../NavigationDrawerOperatingAsItem';
import { NavigationDrawerFlyoutMenu } from '../NavigationDrawerFlyoutMenu';

import { dataTestIdBuilder } from 'velo-data';
import { navigationDrawerConfig } from '../navigationDrawerConfig';
import { NavigationItems } from '../NavigationItems';

import styles from './NavigationDrawerList.module.scss';

const root = 'navigation-drawer-list';

const TestIds = {
  LIST: `${root}-list`,
  COPYRIGHT: `${root}-copyright`,
};
NavigationDrawerList.root = root;
NavigationDrawerList.testIds = TestIds;
/* The grouped sections to be rendered in the Navigation Drawer */
const NavigationDrawerLists = [
  {
    dataTestId: TestIds.DEFAULT_NAVIGATION_GROUP,
    itemsRef: navigationDrawerConfig.NavigationItemGroupMapping.DEFAULT,
    class: styles.defaultgroup,
  },
  {
    dataTestId: TestIds.OPERATINGAS_NAVIGATION_GROUP,
    itemsRef: navigationDrawerConfig.NavigationItemGroupMapping.OPERATING_AS,
  },
  {
    dataTestId: TestIds.SIGNOUT_NAVIGATION_GROUP,
    itemsRef: navigationDrawerConfig.NavigationItemGroupMapping.SIGNOUT,
    class: styles.signoutgroup,
  },
  {
    dataTestId: TestIds.FOOTER_NAVIGATION_GROUP,
    itemsRef: navigationDrawerConfig.NavigationItemGroupMapping.FOOTER,
    class: styles.footergroup,
  },
];

function CopyrightListItem({ isNavigationDrawerOpen, isMobile }) {
  const { copyrightName } = VeloThemeContext.useBrandingTokens();

  return (
    <VeloTypography
      use="footnote"
      tag="div"
      className={classnames(styles.copyright, {
        [styles.collapsedCopyright]: !isNavigationDrawerOpen && !isMobile,
      })}
      data-testid={TestIds.COPYRIGHT}
    >
      <FormattedMessage
        defaultMessage="© Copyright {year} {copyrightName}."
        values={{ year: new Date().getFullYear(), copyrightName }}
      />
      <br />
      <FormattedMessage defaultMessage="All rights reserved." />
    </VeloTypography>
  );
}

function NavigationDrawerList({
  items,
  groupedItems,
  isNavigationDrawerOpen,
  isMobile,
  selectedItem,
  onListAction,
  indicators,
  operatingAs,
  hasOperatorAsOptions,
  selectedSubMenuItemId,
  selectedSubMenuParentId,
  selectedSubMenuParent,
  subNavOpenId,
  forceClose,
  hideTooltip,
}) {
  const lastIndex = NavigationDrawerLists.length - 1;
  const listRef = useRef(null);
  const isSubMenuOpen = !!subNavOpenId;

  return (
    <>
      <VeloList
        ref={listRef}
        className={styles.list}
        nonInteractive={!isNavigationDrawerOpen}
        data-testid={TestIds.LIST}
        onAction={(evt) => {
          /**
           * MDC work around.
           *
           * The `onAction` event handler gets passed the index of the list item only.
           *
           * The index is not suitable to identify the list item because we don't
           * exactly know how many list items there are at a given point in time.
           *
           * Instead use the foundation API to set an identifier on the list item.
           *
           * Then query the DOM element by the list item index identifier and
           * get the `data-nav-id` attribute from the list element.
           *
           * The value of `data-nav-id` is the navigationItem.id from config
           *
           * The navigation item id is then passed in the callback to reliably
           * identify which navigation item has been selected.
           */
          listRef.current.foundation.adapter_.setAttributeForElementIndex(
            evt.detail,
            'data-listid',
            evt.detail
          );

          const item = document.querySelector(`[data-listid*="${evt.detail}"]`);

          onListAction(item.getAttribute('data-nav-id'));
          evt.preventDefault();
        }}
      >
        {NavigationDrawerLists.map((section, index) => (
          <div
            key={dataTestIdBuilder(root, 'group', index)}
            data-testid={dataTestIdBuilder(
              root,
              'group' + section.itemsRef + '_',
              index
            )}
            className={section.class}
          >
            {groupedItems[section.itemsRef] &&
              groupedItems[section.itemsRef].map((item, index) => {
                const subMenuProps = item.subMenuItems &&
                  !item.legalLinksPrimaryItem && {
                    selectedSubMenuParent,
                    selectedSubMenuParentId,
                    selectedSubMenuItemId,
                    subNavOpenId,
                  };

                return item.id === NavigationItems.OPERATING_AS ? (
                  hasOperatorAsOptions ? (
                    <NavigationDrawerOperatingAsItem
                      key={item.id}
                      selectedItemId={selectedItem.id}
                      operatingAs={operatingAs}
                      item={item}
                      isNavigationDrawerOpen={isNavigationDrawerOpen}
                      hideTooltip={hideTooltip}
                    />
                  ) : (
                    /* Required to ensure indexing of listItems corresponds to 
                    the list of navigation items. If nothing is rendered when no
                    operate as is present, the onAction events for subsequent menu items 
                    is broken.  */
                    <span key={item.id} />
                  )
                ) : (
                  <NavigationDrawerFlyoutMenu
                    index={index}
                    key={'menu-item-' + item.id}
                    items={
                      items.find((curr) => curr.id === item.id).subMenuItems
                    }
                    item={item}
                    isNavigationDrawerOpen={isNavigationDrawerOpen}
                    selectedItem={selectedItem}
                    notificationIcon={indicators[item.id]}
                    isMobile={isMobile}
                    onListAction={onListAction}
                    className={classnames({
                      [styles.footergroupBottomGutter]:
                        section.itemsRef ===
                          navigationDrawerConfig.NavigationItemGroupMapping
                            .FOOTER && lastIndex === index,
                    })}
                    isSubMenuOpen={isSubMenuOpen}
                    forceClose={forceClose}
                    {...subMenuProps}
                    hideTooltip={hideTooltip}
                  />
                );
              })}
            {lastIndex === index && (
              <CopyrightListItem
                isMobile={isMobile}
                isNavigationDrawerOpen={isNavigationDrawerOpen}
              />
            )}
          </div>
        ))}
      </VeloList>
    </>
  );
}

export { NavigationDrawerList };
