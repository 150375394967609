export function validateMFA(send) {
  return (tokenId, body, ...args) =>
    send(
      {
        path: '/v1/tokens/{tokenId}/mfa/validate',
        pathParameters: { tokenId },
        type: 'post',
        query: undefined,
        body: body,
        headers: undefined,
        auth: undefined,
        contentType: 'application/json',
      },
      ...args
    );
}
