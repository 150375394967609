import React from 'react';
import { arrayOf, func, number, object, oneOf, string } from 'prop-types';
import { VeloPropTypes, paymentsListFields } from 'velo-react-components';
import { formatSortQuery, createFilterFormatterOnChange } from '../../helpers';
import { Context } from '../../../context';
import {
  backOfficePaymentFieldEnum,
  payorPaymentFieldEnum,
  paymentListFieldEnum,
  formatAPIError,
  formatError,
} from '../../../selectors';
import { PaymentsListMode } from './paymentsListMode';

const payorColumns = [
  { name: payorPaymentFieldEnum.submittedDateTime, sortable: true },
  { name: payorPaymentFieldEnum.scheduled, sortable: false },
  { name: payorPaymentFieldEnum.payeeName, sortable: false },
  { name: payorPaymentFieldEnum.remoteId, sortable: true },
  { name: payorPaymentFieldEnum.sourceAmount, sortable: true },
  { name: payorPaymentFieldEnum.paymentAmount, sortable: false },
  { name: payorPaymentFieldEnum.status, sortable: false },
  { name: payorPaymentFieldEnum.scheduledStatus, sortable: false },
];

const paymentsListModeColumnMap = {
  [PaymentsListMode.BACKOFFICE]: [
    { name: backOfficePaymentFieldEnum.submittedDateTime, sortable: true },
    { name: backOfficePaymentFieldEnum.scheduled, sortable: false },
    { name: backOfficePaymentFieldEnum.payorName, sortable: true },
    { name: backOfficePaymentFieldEnum.payeeName, sortable: false },
    { name: backOfficePaymentFieldEnum.sourceAmount, sortable: true },
    { name: payorPaymentFieldEnum.paymentAmount, sortable: false },
    { name: backOfficePaymentFieldEnum.status, sortable: false },
    { name: backOfficePaymentFieldEnum.scheduledStatus, sortable: false },
  ],
  [PaymentsListMode.PAYOR]: payorColumns,
};

const paymentsListModeFieldMap = {
  [PaymentsListMode.BACKOFFICE]: paymentsListFields.backOfficeFields,
  [PaymentsListMode.PAYOR]: paymentsListFields.payorFields,
};

const queryHasChanged = (property, prev, curr) => {
  if (curr.hasOwnProperty(property) && prev.hasOwnProperty(property)) {
    return curr[property] !== prev[property];
  }
  return false;
};

/**
 * Container used to manage a list of Payments.
 * This container will manage:
 *  - Loading the payments
 *  - Pagination
 *  - Sorting
 *  - Filtering
 * The component requires on a render prop and makes no
 * assumptions about the presentational components used.
 */
class PaymentsListContainer extends React.Component {
  static propTypes = {
    /**
     * Called when rendering child components. The current
     * state will be injected as props.
     */
    render: func.isRequired,
    /** The default API query params. */
    query: object.isRequired,
    /** The number of payments per-page. */
    pageSize: number,
    /** Used to select column configuration based on mode */
    mode: oneOf(Object.values(PaymentsListMode)).isRequired,
    /** A payeeId that is used to filter the list */
    payeeId: string,
    /** A callback to notify the parent that external filters have been cleared
     * by the user. Currently there is only 1 external filter, the payeeId
     */
    onClearExternalFilters: func,
    /** An array of countries fetched from the API */
    countries: VeloPropTypes.countriesType(),
    /** An array of rails providers fetched from the API */
    railsProviders: arrayOf(VeloPropTypes.railProviderType),
    /** Trigger to refresh the table content */
    refresh: number,
  };

  static defaultProps = {
    pageSize: 20,
  };

  // Access to the main app context
  static contextType = Context;

  state = {
    data: {},
    summary: {},
    page: 0,
    totalPages: 0,
    sortCol: 0,
    sortDirection: -1,
    filters: {},
    payeeData: {},
  };

  handlers = {
    // Called when switching pages
    onPage: (page) => {
      this.setState({ data: {} }, () => this.getPayments(page));
    },
    // Return the sort option for a particular column
    sortOptions: (column) => {
      const columns = paymentsListModeColumnMap[this.props.mode];
      return columns[column].sortable
        ? column === this.state.sortCol
          ? this.state.sortDirection
          : 0
        : undefined;
    },
    // Called when a sortable column is clicked
    onSort: (sortCol, sortDirection) => {
      // Update sort UI, reset to show skeleton and reload the current page
      this.setState({ data: {}, sortCol, sortDirection, totalPages: 0 }, () =>
        this.getPayments(this.state.page)
      );
    },
    // Called when filters are updated
    onChange: createFilterFormatterOnChange((filters) => {
      let nextState = { data: {}, filters, totalPages: 0 };
      const hasPayeeIdFilterBeenReset = this.props.payeeId && !filters.payeeId;

      if (hasPayeeIdFilterBeenReset) {
        nextState = {
          ...nextState,
          payeeData: {},
        };
      }

      // Update filters, reset to show skeleton and reload the first page
      this.setState(nextState, () => {
        hasPayeeIdFilterBeenReset && this.props.onClearExternalFilters();
        this.getPayments(1);
      });
    }, paymentsListModeFieldMap[this.props.mode](this.props)),
  };

  getPayeeNames = (payload) => {
    // Build a list of unique valid Payee IDs
    const payees = [
      ...new Set(
        this.state.data.result
          .map(({ payeeId }) => payeeId)
          .filter((payeeId) => payeeId)
      ),
    ];
    // Make an API call for each
    payees.forEach((payeeId) => {
      this.context.api
        .getPayee(payeeId)
        .catch((e) => e)
        .then(({ errors, displayName }) => ({
          result: errors ? undefined : displayName,
          error: formatAPIError(errors),
        }))
        .then((result) => {
          this.setState(({ data }) => ({
            data: {
              result:
                data.result &&
                data.result.map((payment) => ({
                  ...payment,
                  payeeName:
                    payment.payeeId === payeeId ? result : payment.payeeName,
                })),
            },
          }));
        });
    });
  };

  apiImplMap = {
    [PaymentsListMode.BACKOFFICE]: this.context.api.getPayments,
    [PaymentsListMode.PAYOR]: this.context.api.getPayments,
  };

  successResultFormatter = (payload) => ({
    data: {
      result: payload.content.map((payment) => ({
        ...payment,
        // We don't know the payee name yet but assume an
        // empty name if no payeeId is available.
        payeeName: { result: payment.payeeId === undefined ? '' : undefined },
      })),
      error: undefined,
    },
    ...payload.page,
  });

  errorResultFormatter = (error) => ({
    data: {
      result: undefined,
      error,
    },
  });

  resultFormatterImplMap = {
    [PaymentsListMode.BACKOFFICE]: {
      successResultFormatter: this.successResultFormatter,
      errorResultFormatter: this.errorResultFormatter,
    },
    [PaymentsListMode.PAYOR]: {
      successResultFormatter: this.successResultFormatter,
      errorResultFormatter: this.errorResultFormatter,
    },
  };

  // Fetch payments for a specific page
  getPayments = (page) => {
    // Fetch payments from the API
    const columns = paymentsListModeColumnMap[this.props.mode];
    const { query, pageSize } = this.props;
    const { sortCol, sortDirection, filters } = this.state;
    const getPaymentsApi = this.apiImplMap[this.props.mode];

    getPaymentsApi({
      ...query,
      page,
      pageSize,
      sort: formatSortQuery(columns[sortCol].name, sortDirection),
      ...filters,
    })
      .then(
        // Success
        (payload) => {
          const { successResultFormatter } =
            this.resultFormatterImplMap[this.props.mode];
          return [successResultFormatter(payload), this.getPayeeNames];
        },
        // Error
        (ex) => {
          const { errorResultFormatter } =
            this.resultFormatterImplMap[this.props.mode];
          return [errorResultFormatter(formatError(ex))];
        }
      )
      // Update the state
      .then(([state, updater]) => this.setState(state, updater));
  };

  componentDidMount() {
    const { payeeId } = this.props;

    // Set the payeeId read only filter then fetch the first page of payments
    // filtered by payeeId
    if (payeeId) {
      this.context.api
        .getPayee(payeeId)
        .then(
          ({ displayName }) => {
            return [
              {
                payeeData: {
                  value: displayName,
                  entityId: payeeId,
                },
                filters: { payeeId },
              },
              () => this.getPayments(1),
            ];
          },
          (ex) => [this.errorResultFormatter(formatError(ex))]
        )
        .then(([state, updater]) => this.setState(state, updater));

      // Else fetch the first page of payments
    } else {
      this.getPayments(1);
    }
  }

  componentWillUnmount() {
    this.setState = () => undefined;
  }

  componentDidUpdate(prevProps) {
    const { query } = this.props;
    if (queryHasChanged('payorId', prevProps.query, query)) {
      //reset state to ensure loading skeleton displays.
      this.setState({
        data: {
          result: undefined,
          error: undefined,
        },
      });
      //we only want to do this for payments if the payorId has changed in .
      //always load the first page.
      this.getPayments(1);
    }

    // Request to refresh - load the current page with current filters
    if (this.props.refresh !== prevProps.refresh && this.state.page !== 0) {
      this.getPayments(this.state.page);
    }
  }

  searchPayees = (payeeName) => {
    const { payorId } = this.props.query;

    let payeeQuery = {
      displayName: payeeName,
      pageSize: 5,
      page: 1,
      sort: formatSortQuery('displayName', 1),
    };

    if (this.props.mode === PaymentsListMode.PAYOR) {
      payeeQuery = {
        ...payeeQuery,
        payorId,
      };
    }

    return this.context.api.getPayees(payeeQuery);
  };

  getFields = () => {
    const currencies = this.props.countries.map(({ currencies }) => currencies);

    // De-duplicate the currencies array and convert them to label/value objects
    const currencyOptions = [...new Set([].concat(...currencies))].map(
      (currency) => ({
        label: currency,
        value: currency,
      })
    );

    const fields = paymentsListModeFieldMap[this.props.mode](this.props);
    return fields.map((field) =>
      field.name === paymentListFieldEnum.sourceCurrency ||
      field.name === paymentListFieldEnum.paymentCurrency
        ? { ...field, options: currencyOptions }
        : field
    );
  };

  render() {
    const fields = this.getFields();

    return this.props.render({
      ...this.props,
      ...this.state,
      ...this.handlers,
      searchPayees: this.searchPayees,
      fields,
    });
  }
}

export { PaymentsListContainer };
