import React, { useMemo, useState } from 'react';
import { bool, func } from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import styled from 'astroturf/react';
import { toISODateString } from 'velo-data';
import { VeloDialog } from '../VeloDialog';
import { VeloCardForm } from '../VeloCardForm';
import { VeloTypography } from '../VeloTypography';
import { VeloLabelledItem } from '../VeloLabelledItem';
import { VeloCallout } from '../VeloCallout';
import { VeloCheckbox } from '../VeloCheckbox';
import { VeloSectionGrid } from '../VeloSectionGrid';
import { VeloDatePicker } from '../VeloDatePicker';
import { VeloTimePicker } from '../VeloTimePicker';
import { ErrorMessage } from '../ErrorMessage';
import { PayoutScheduleInfo } from '../PayoutScheduleInfo';
import { useSelectWorkAround } from './useSelectWorkAround';

const root = 'schedule-payout-dialog';

const TestIds = {
  CONFIRM: `${root}-confirm`,
  EMAIL_CHECKBOX: `${root}-email-box`,
};

const Form = styled(VeloCardForm)`
  margin: 0;
`;

const Dialog = styled(VeloDialog)`
  :global {
    .mdc-dialog__surface {
      min-width: 585px;
    }
  }
`;

const FormRow = styled('div')`
  display: grid;
  grid-template-columns: 1fr auto;
`;

const DatePicker = styled(VeloDatePicker)`
  margin-right: 19px;
`;

PayoutScheduleDialog.propTypes = {
  /** Open/close the dialog. */
  open: bool.isRequired,
  /** Called when the form is submitted. Passed the schedule data. */
  onSubmit: func.isRequired,
  /** Called when the dialog is closed/ESC is pressed. */
  onClose: func.isRequired,
};

function RescheduleDialog({ scheduledFor, ...props }) {
  const intl = useIntl();

  const { dateAndTimeString, ...dateTimeProps } = useMemo(() => {
    const scheduledForDate = new Date(scheduledFor);
    const month = `${scheduledForDate.getMonth() + 1}`.padStart(2, '0');
    const day = `${scheduledForDate.getDate()}`.padStart(2, '0');

    return {
      date: `${scheduledForDate.getFullYear()}-${month}-${day}`,
      hour: `${scheduledForDate.getHours()}`.padStart(2, '0'),
      minute: `${scheduledForDate.getMinutes()}`.padStart(2, '0'),
      dateAndTimeString: scheduledForDate.toString(),
    };
  }, [scheduledFor]);

  return (
    <PayoutScheduleDialog
      {...props}
      {...dateTimeProps}
      title={<FormattedMessage defaultMessage="Reschedule payout" />}
      submitButtonText={intl.formatMessage({
        defaultMessage: 'Reschedule Payout',
      })}
      introFields={[
        <PayoutScheduleInfo scheduledFor={scheduledFor} />,
        <VeloTypography use="bodyText">
          <FormattedMessage defaultMessage="When would you like this payout to be sent?" />
        </VeloTypography>,
      ]}
    />
  );
}

function PayoutScheduleDialog({
  open,
  onSubmit,
  onClose,
  hour = '',
  minute = '00',
  date = '',
  notificationsEnabled: sendPayoutCompleteEmailProp = true,
  title = <FormattedMessage defaultMessage="Schedule payout" />,
  introFields = [
    <VeloCallout type={VeloCallout.types.WARNING}>
      <FormattedMessage defaultMessage="When set, this payment or series of payments will automatically be triggered when the scheduled date and time is reached." />
    </VeloCallout>,
    <VeloTypography use="bodyText">
      <FormattedMessage defaultMessage="When would you like this payout to be sent?" />
    </VeloTypography>,
  ],
  submitButtonText,
}) {
  const intl = useIntl();
  const [overlay, setOverlay] = useState(false);
  const [error, setError] = useState(undefined);
  const [payoutDate, setPayoutDate] = useState(date);
  const [payoutTime, setPayoutTime] = useState({
    hour,
    minute,
  });
  const [notificationsEnabled, setNotificationsEnabled] = useState(
    sendPayoutCompleteEmailProp
  );

  const hasChangedFromInitialValues =
    payoutDate !== date ||
    payoutTime.hour !== hour ||
    payoutTime.minute !== minute;

  const disableDatesBefore = useMemo(() => {
    const date = new Date();
    date.setDate(date.getDate() + 1);
    return toISODateString(date);
  }, []);

  const currentTimeZone = useMemo(() => {
    const now = new Date();
    const [, timezoneString] = now.toString().split('GMT');
    return `GMT${timezoneString}`;
  }, []);

  // This will handle using ESC to close the dialog
  const onCloseDialog = (evt) => {
    if (evt.detail.action === 'close') {
      onClose();
    }
  };

  const [timePickerKey] = useSelectWorkAround(open);

  return (
    <Dialog open={open} disableTrapFocus onClose={onCloseDialog}>
      <Form
        title={title}
        onSubmit={(event) => {
          event.preventDefault();
          setError(undefined);
          setOverlay(true);

          onSubmit(
            {
              scheduledFor: new Date(
                `${payoutDate}T${payoutTime.hour}:${payoutTime.minute}:00`
              ).toISOString(),
              notificationsEnabled,
            },
            (error) => {
              if (error) {
                setError(error);
                setOverlay(false);
              }
            }
          );
        }}
        onClose={onClose}
        buttonProps={{
          children:
            submitButtonText ||
            intl.formatMessage({
              defaultMessage: 'Schedule Payout',
            }),
          disabled:
            !payoutDate ||
            !payoutTime.hour ||
            !payoutTime.minute ||
            !hasChangedFromInitialValues,
          'data-testid': TestIds.CONFIRM,
        }}
        fullScreen={false}
        overlay={overlay}
      >
        <VeloSectionGrid
          compact
          sections={[
            {
              fields: [
                ...introFields,
                <VeloTypography use="bodyTextEmphasised">
                  <FormattedMessage defaultMessage="Send payout on:" />
                </VeloTypography>,
                <FormRow>
                  <DatePicker
                    disableBefore={disableDatesBefore}
                    label={<FormattedMessage defaultMessage="Payout date" />}
                    value={payoutDate}
                    onChange={(e) => setPayoutDate(e.target.value)}
                  />

                  <VeloTimePicker
                    key={timePickerKey}
                    name="payoutTime"
                    value={payoutTime}
                    onChange={(e) => setPayoutTime(e.target.value)}
                  />
                </FormRow>,
                <VeloLabelledItem
                  label={<FormattedMessage defaultMessage="Timezone" />}
                >
                  {currentTimeZone}
                </VeloLabelledItem>,

                <VeloLabelledItem
                  label={
                    <FormattedMessage defaultMessage="Check to send email notification when:" />
                  }
                >
                  <VeloCheckbox
                    data-testid={TestIds.EMAIL_CHECKBOX}
                    id="notificationsEnabled"
                    checked={notificationsEnabled}
                    onChange={(e) => setNotificationsEnabled(e.target.checked)}
                  >
                    <FormattedMessage defaultMessage="Schedule payout is successful" />
                  </VeloCheckbox>
                </VeloLabelledItem>,

                <VeloTypography use="bodyTextSmaller">
                  <FormattedMessage defaultMessage="For Payouts containing FX transactions, when the scheduled time is reached if any of the quoted FX rates have degraded, the payout will not be instructed and you will be notified.  The payout will be marked as Incomplete,  and can be re-quoted and manually instructed or re-scheduled." />
                </VeloTypography>,
                ...(error ? [<ErrorMessage>{error}</ErrorMessage>] : []),
              ],
            },
          ]}
          render={(component) => component}
        />
      </Form>
    </Dialog>
  );
}

PayoutScheduleDialog.Reschedule = RescheduleDialog;
PayoutScheduleDialog.testIds = TestIds;

export { PayoutScheduleDialog };
