import React, { useContext, useMemo } from 'react';
import {
  PaymentChannelsPage,
  PaymentChannelForm,
  PaymentChannelView,
  VeloNotification,
} from 'velo-react-components';
import {
  Context,
  SettingsContext,
  contextPaymentChannelRulesSelector,
  contextCountriesSelector,
} from '../../context';
import { usePresenter, useSerializableMemo } from '../../hooks';
import { PaymentChannelsUpsertContainer } from '../../containers';
import { PaymentMethodsCacheHitConsumer } from './PaymentMethodsCacheHitConsumer';
import {
  updatePaymentChannelV4,
  createPaymentChannelV4,
  getPaymentChannelByIdV4,
  enablePaymentChannelV4,
} from 'velo-api/src/entities/payees';
import {
  PaymentMethodUpsertPresenter,
  PayorPaymentMethodUpsertPresenter,
} from './PaymentMethodUpsertPresenter';
import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router';

const entitySpec = {
  getPaymentChannelById: getPaymentChannelByIdV4,
  updatePaymentChannel: updatePaymentChannelV4,
  createPaymentChannel: createPaymentChannelV4,
  enablePaymentChannel: enablePaymentChannelV4,
};

const notifications = {
  update: {
    success: VeloNotification.types.PAYMENT_CHANNEL_UPDATE_SUCCESS,
    failure: VeloNotification.types.PAYMENT_CHANNEL_UPDATE_FAILURE,
  },
  create: {
    success: VeloNotification.types.PAYMENT_CHANNEL_CREATE_SUCCESS,
    failure: VeloNotification.types.PAYMENT_CHANNEL_CREATE_FAILURE,
  },
};

const renderPaymentChannelForm = (props) => (
  <PaymentChannelForm mode={PaymentChannelView.mode.PAYEE} {...props} />
);

function usePaymentMethodUpsertPresenter(Presenter, { payeeId }) {
  const queryClient = useQueryClient();
  const routeParams = useParams();

  return usePresenter(
    Presenter,
    entitySpec,
    useSerializableMemo({
      payeeId,
      ...routeParams,
    }),
    useMemo(
      () => ({
        queryClient,
        notifications,
      }),
      [queryClient]
    )
  );
}

const UpsertPaymentChannelForm = ({
  handlers,
  payeeId,
  paymentChannel,
  upsertType,
  query,
  loader,
}) => {
  const context = useContext(Context);

  return (
    <SettingsContext.Consumer>
      {({ showAdvancedPaymentMethods }) => (
        <PaymentMethodsCacheHitConsumer>
          {(increment) => (
            <PaymentChannelsUpsertContainer
              onComplete={handlers.onComplete}
              onClose={handlers.onClose}
              payeeId={payeeId}
              channelId={paymentChannel}
              type={upsertType}
              query={query}
              render={renderPaymentChannelForm}
              allPaymentChannelRules={
                contextPaymentChannelRulesSelector(context).bank
              }
              allCountries={contextCountriesSelector(context)}
              refreshData={increment}
              showAdvancedPaymentMethods={showAdvancedPaymentMethods}
              onLoad={loader}
              handlers={handlers}
            />
          )}
        </PaymentMethodsCacheHitConsumer>
      )}
    </SettingsContext.Consumer>
  );
};

export function PayorPaymentMethodUpsertRoute({ payeeId }) {
  const [loader, handlers, props] = usePaymentMethodUpsertPresenter(
    PayorPaymentMethodUpsertPresenter,
    {
      payeeId,
    }
  );

  return (
    <PaymentChannelsPage.Managed.Form
      form={
        <UpsertPaymentChannelForm
          loader={loader}
          handlers={handlers}
          payeeId={payeeId}
          upsertType={
            props.isEdit
              ? PaymentChannelForm.mode.EDIT
              : PaymentChannelForm.mode.CREATE
          }
          {...props}
        />
      }
    />
  );
}

export function PaymentMethodUpsertRoute({ payeeId }) {
  const [loader, handlers, props] = usePaymentMethodUpsertPresenter(
    PaymentMethodUpsertPresenter,
    {
      payeeId,
    }
  );

  return (
    <PaymentChannelsPage.Standard.Form
      form={
        <UpsertPaymentChannelForm
          loader={loader}
          handlers={handlers}
          payeeId={payeeId}
          upsertType={
            props.isEdit
              ? PaymentChannelForm.mode.EDIT
              : PaymentChannelForm.mode.CREATE
          }
          {...props}
        />
      }
    />
  );
}

PaymentMethodUpsertRoute.Payor = PayorPaymentMethodUpsertRoute;
