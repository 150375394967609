import styled from 'astroturf/react';
import { bool } from 'prop-types';

const VeloBookended = styled('div')`
  display: flex;
  &.column {
    flex-direction: column;
  }

  > :first-child {
    order: 1;
  }

  &.justify {
    justify-content: space-between;
  }
`;

VeloBookended.propTypes = {
  /**
   * Indicates that the layout is flex column (instead of row)
   */
  column: bool,
  /**
   * Pushes the content to the edges (space-between)
   */
  justify: bool,
};

const Bookend = styled('div')`
  order: 2;
  &.start {
    order: 0;
    &.gridGutter {
      margin-bottom: 16px;
    }
  }

  &:not(.start) {
    &.gridGutter {
      margin-top: 16px;
    }
  }
`;

Bookend.propTypes = {
  /**
   * Indicates that this bookend will be at the start of the container.
   */
  start: bool,
  /**
   * Will add the equivalent of grid-gutter margin to either the top or bottom
   * depending on the start property
   */
  gridGutter: bool,
};

VeloBookended.Bookend = Bookend;

export { VeloBookended };
