import React from 'react';
import { object } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styled from 'astroturf/react';
import { VeloButton } from '../VeloButton';
import TestIds from './testIds';
import { VeloBackground } from '../VeloBackground';
import { VeloTypography } from '../VeloTypography';

const ErrorBar = styled(VeloBackground)`
  @import 'velo-variables';
  display: flex;
  padding: 0.8rem;
  justify-content: space-between;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  margin-bottom: 1rem;

  @media (min-width: velo-breakpoint(XS)) {
    flex-direction: row;
    gap: 0;
  }
`;

const Button = styled(VeloButton.Secondary)`
  @import 'velo-variables';
  background-color: velo-color('token-color-system-page-background');
  &:global(.mdc-button:not(:disabled)) {
    background-color: velo-color('token-color-system-page-background');
  }
`;

const ReadableMessage = styled(VeloTypography)`
  @import 'velo-variables';

  @media (min-width: velo-breakpoint(XS)) {
    max-width: 66%;
  }
`;

/**
 * SubmittedErrorDetails Component
 * */
SubmittedErrorDetails.propTypes = {
  /** Rejected Payment details,
   * {  errors: [], //unique errors
   *    count: 0 // total rejected payments
   * } */
  rejectedPaymentErrors: object.isRequired,
};

SubmittedErrorDetails.testIds = TestIds;

function SubmittedErrorDetails({ rejectedPaymentErrors }) {
  return rejectedPaymentErrors.errors ? (
    <ErrorBar
      data-testid={TestIds.ERRORS}
      rounded
      status={VeloBackground.status.ERROR}
    >
      <ReadableMessage use="bodyText">
        <FormattedMessage
          defaultMessage="{count} {count, plural, one {payment} other {payments}} could not be included. Reasons:"
          values={{ count: rejectedPaymentErrors.count }}
        />
        <ul>
          {rejectedPaymentErrors.errors.map((error, i) => {
            return <li key={`error-${i}`}>{error}</li>;
          })}
        </ul>
      </ReadableMessage>
      <Button
        onClick={rejectedPaymentErrors.onClick}
        data-testid={`${TestIds.ERRORS}-download`}
      >
        <FormattedMessage defaultMessage="EXPORT ERRORS" />
      </Button>
    </ErrorBar>
  ) : null;
}

export { SubmittedErrorDetails };
