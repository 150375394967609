import React from 'react';
import { bool, func, node, number, object, oneOf, string } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import debounce from 'just-debounce-it';
import styled from 'astroturf/react';
import { EscapeKey } from '../EscapeKey';
import { VeloTextField } from '../VeloTextField';
import { ResultsList } from '../ResultsList';
import { VeloMenu } from '../VeloMenu';

const TextField = styled(VeloTextField)`
  @import 'velo-variables';

  :global .mdc-text-field__icon {
    outline: none;
  }

  &.selected {
    :global .mdc-text-field__icon {
      color: velo-color('token-color-brand-primary');
    }
  }
`;

TextField.propTypes = {
  selected: bool,
};

const Surface = styled(VeloMenu.Surface)`
  @import 'velo-variables';
  @import '@material/elevation/mixins';

  @include mdc-elevation(1);

  max-width: inherit;
  z-index: $velo-over-all-default;
  width: 100%;

  &.adjust {
    /* Adjust to take text filed helper text into account */
    margin-top: -1.1875rem;
  }
`;

Surface.propTypes = {
  adjust: bool,
};

const Scrim = styled('div')`
  @import 'velo-variables';

  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: $velo-over-all-default;
`;

const root = 'lookup';

const TestIds = {
  SEARCH_ICON: `${root}-search-icon`,
  CLEAR_ICON: `${root}-clear-icon`,
  TEXT_FIELD: `${root}-text-field`,
  VALIDATION_ERROR: `${root}-validation-error`,
  SCRIM: `${root}-scrim`,
  HELPER_TEXT: `${root}-helper-text`,
};

const LookupTextFieldMode = {
  PAYIN_ACCOUNT_DISPLAY_NAME: 'PAYIN_ACCOUNT_DISPLAY_NAME',
  FUNDING_ACCOUNT: 'FUNDING_ACCOUNT',
  PAYEE: 'PAYEE',
  PAYOR: 'PAYOR',
  TAG: 'TAG',
  WUBS: 'WUBS',
};

const ModeConfigMap = {
  [LookupTextFieldMode.PAYEE]: {
    errorMessages: {
      noResults: (
        <FormattedMessage defaultMessage="No payee was found, please clear and try an alternative." />
      ),
      noResultSelected: (
        <FormattedMessage defaultMessage="No payee was selected, please clear or select a payee." />
      ),
    },
  },
  [LookupTextFieldMode.PAYOR]: {
    errorMessages: {
      noResults: (
        <FormattedMessage defaultMessage="No payor was found, please clear and try an alternative." />
      ),
      noResultSelected: (
        <FormattedMessage defaultMessage="No payor was selected, please clear or select a payor." />
      ),
    },
    defaultProps: {
      /**
       * It is not currently possible to limit the payors results list size
       * via the API
       * */
      disableMaxResults: true,
    },
  },
  [LookupTextFieldMode.TAG]: {
    errorMessages: {
      noResults: (
        <FormattedMessage defaultMessage="No tag was found, please clear and try an alternative." />
      ),
      noResultSelected: (
        <FormattedMessage defaultMessage="No tag was selected, please clear or select a tag." />
      ),
    },
  },
  [LookupTextFieldMode.WUBS]: {
    errorMessages: {
      noResults: (
        <FormattedMessage defaultMessage="No WUBS decoupled customer was found, please clear and try an alternative." />
      ),
      noResultSelected: (
        <FormattedMessage defaultMessage="No WUBS decoupled customer was selected, please clear or select a result." />
      ),
    },
    defaultProps: {
      disableMaxResults: true,
    },
  },
  [LookupTextFieldMode.FUNDING_ACCOUNT]: {
    errorMessages: {
      noResults: (
        <FormattedMessage defaultMessage="No funding account was found, please clear and try an alternative." />
      ),
      noResultSelected: (
        <FormattedMessage defaultMessage="No funding account was selected, please clear or select a tag." />
      ),
    },
  },
  [LookupTextFieldMode.PAYIN_ACCOUNT_DISPLAY_NAME]: {
    errorMessages: {
      noResults: (
        <FormattedMessage defaultMessage="No payin account was found, please clear and try an alternative." />
      ),
      noResultSelected: (
        <FormattedMessage defaultMessage="No payin account was selected, please clear or select a tag." />
      ),
    },
  },
};

class LookupTextField extends React.Component {
  static propTypes = {
    /**
     * A mode type used to configure this component. This is defaulted to PAYEE
     */
    mode: oneOf(Object.values(LookupTextFieldMode)),
    /**
     * A function that is called when this field is changed.
     */
    onChange: func.isRequired,
    /**
     * Whether a fetch is triggered when there is no input, either on
     * initial focus or when input is removed.
     * When `true`, fetchResults will be called with no value to
     * populate the results.
     */
    fetchOnEmpty: bool,
    /**
     * An async function used to fetch a list of entities. This takes a value
     * and a node style (err, result) => {} callback as arguments
     */
    fetchResults: func.isRequired,
    /**
     * A number representing ms to debounce the result fetching requests by.
     */
    debounceTime: number,
    /**
     * Require a label for accessibility.
     * Can use empty string if placeholder is preferred.
     */
    label: node.isRequired,
    /**
     * A placeholder to display
     */
    placeholder: string,
    /** Whether or not the input is required */
    required: bool,
    /** The value used to control this component */
    value: string.isRequired,
    /** The entityId of the selected result item */
    entityId: string,
    /** The params to show help text under the field */
    helpText: object,
  };

  static defaultProps = {
    debounceTime: 200,
    maxResultsListSize: 5,
    mode: LookupTextFieldMode.PAYEE,
  };

  static testIds = TestIds;
  static modes = LookupTextFieldMode;

  closedState = () => ({
    error: undefined,
    showResultsList: false,
  });

  initialState = () => ({
    ...this.closedState(),
    result: undefined,
    totalResults: 0,
  });

  state = this.initialState();

  textFieldRef = React.createRef();

  hideResults = () => this.setState(this.closedState());

  // The input is valid if there is no value or if there is a value and an entityId
  isValueInvalid = ({ value, entityId }) => !!value && !entityId;

  // Format event object
  getEvent = (data = {}) => ({
    target: {
      // Defaults
      value: '',
      entityId: undefined,
      // Updated value and entityId
      ...data,
      name: this.props.name,
      validationError: this.isValueInvalid(data),
    },
  });

  listHandlers = {
    onClick: (result) => {
      this.setState(this.closedState(), () =>
        this.props.onChange(this.getEvent(result))
      );
    },
  };

  fetchResults = debounce(
    (value) =>
      this.props.fetchResults(value, (error, data) => {
        // Ensure only the latest search results are rendered
        if (this.state.lastValue === value) {
          this.setState({ error, ...data });
        }
      }),
    this.props.debounceTime
  );

  textFieldHandlers = {
    onChange: (event) => {
      const { value } = event.target;
      const showResultsList = !!value.length || this.props.fetchOnEmpty;

      /**
       * The value you must be passed to the container before the state is
       * updated, to ensure that when this.isValueInvalid() is called it
       * has access to the latest this.props.value and this.state.showResultsList
       **/
      this.props.onChange(this.getEvent({ value }));

      this.setState(
        {
          ...this.initialState(),
          lastValue: value,
          showResultsList,
        },
        () => {
          if (showResultsList) {
            this.fetchResults(value);
          }
        }
      );
    },
    onFocus: (event) => {
      const { value } = event.target;
      const showResultsList = !!value.length || this.props.fetchOnEmpty;

      this.setState(
        {
          ...this.initialState(),
          lastValue: value,
          showResultsList,
          active: true,
        },
        () => {
          if (showResultsList) {
            this.fetchResults(value);
          }
        }
      );
    },
    onBlur: (event) => {
      this.setState({
        active: false,
      });
    },
  };

  searchIconHandlers = {
    onClick: () => {
      this.textFieldRef.current.focus();
    },
  };

  clearIconHandlers = {
    onClick: () => {
      this.props.onChange(this.getEvent());
    },
  };

  /**
   * Render a search icon either when typing a search or if there is no value
   * Otherwise render a clear icon to remove the search value
   */
  getIcon = () => {
    const showClearIcon = this.props.value && !this.state.showResultsList;

    return {
      icon: showClearIcon
        ? {
            ...this.clearIconHandlers,
            tabIndex: 0,
            icon: 'close',
            'data-testid': TestIds.CLEAR_ICON,
          }
        : {
            ...this.searchIconHandlers,
            // Search Icon is presentational only, clicking it only focussed the
            // text field
            tabIndex: -1,
            icon: 'search',
            'data-testid': TestIds.SEARCH_ICON,
          },
      selected: !showClearIcon && this.state.active,
    };
  };

  /**
   * Provide user feedback if there is no search results or no valid result
   * selected
   */
  getHelperTextProps = () => {
    const invalid =
      this.isValueInvalid(this.props) && !this.state.showResultsList;

    const { errorMessages } = ModeConfigMap[this.props.mode];
    const { noResultSelected, noResults } = errorMessages;

    const errorMessage =
      this.state.result && this.state.result.length
        ? noResultSelected
        : noResults;

    if (this.textFieldRef.current) {
      this.textFieldRef.current.setCustomValidity(invalid ? errorMessage : '');
    }

    return {
      invalid,
      helpText: invalid
        ? {
            validationMsg: true,
            children: errorMessage,
            'data-testid': TestIds.HELPER_TEXT,
          }
        : this.props.helpText,
    };
  };

  render() {
    const { selected, icon } = this.getIcon();
    const { error, result, totalResults, showResultsList } = this.state;
    const { errorMessages, defaultProps } = ModeConfigMap[this.props.mode];

    return (
      <VeloMenu.SurfaceAnchor>
        {showResultsList && (
          <>
            {/* Handle ESC key */}
            <EscapeKey onPressed={this.hideResults} />
            {/* Disable scrolling on content underneath */}
            <Scrim onClick={this.hideResults} data-testid={TestIds.SCRIM} />
          </>
        )}
        <Surface
          style={{
            // Specify an exact width to ensure the correct size on dialogs
            width:
              this.textFieldRef.current &&
              this.textFieldRef.current.offsetWidth,
          }}
          adjust={this.props.helpText !== undefined}
          open={showResultsList}
          anchorCorner="bottomLeft"
          onClose={this.hideResults}
        >
          {showResultsList && (
            <ResultsList
              result={result}
              error={error}
              totalResults={totalResults}
              noResultsErrorMessage={errorMessages.noResults}
              maxResultsListSize={this.props.maxResultsListSize}
              {...defaultProps}
              {...this.listHandlers}
            />
          )}
        </Surface>

        <TextField
          inputRef={this.textFieldRef}
          type="search"
          label={this.props.label}
          placeholder={this.props.placeholder}
          value={this.props.value}
          disabled={this.props.disabled}
          autoComplete="off"
          required={this.props.required}
          trailingIcon={icon}
          data-testid={TestIds.TEXT_FIELD}
          selected={selected}
          {...this.getHelperTextProps()}
          {...this.textFieldHandlers}
        />
      </VeloMenu.SurfaceAnchor>
    );
  }
}

export { LookupTextField };
