import React, { useCallback } from 'react';
import { oneOf, func, string, shape } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styled from 'astroturf/react';
import { VeloSelect } from '../VeloSelect';

const paddedIndexMapper = (_, index) => `${index.toString().padStart(2, '0')}`;
const optionsMapper = (value) => ({ label: value, value });
const hours = Array(24).fill().map(paddedIndexMapper);
const minutes = Array(60).fill().map(paddedIndexMapper);
const hourOptions = hours.map(optionsMapper);
const minuteOptions = minutes.map(optionsMapper);

VeloTimePicker.propTypes = {
  value: shape({
    minute: oneOf([...minutes, '']).isRequired,
    hour: oneOf([...hours, '']).isRequired,
  }).isRequired,
  onChange: func.isRequired,
  name: string.isRequired,
};

const root = 'velo-time-picker';

const TestIds = {
  HOURS: `${root}-hours`,
  MINUTES: `${root}-minutes`,
};

const Container = styled('div')`
  display: grid;
  grid-template-columns: 90px 19px 90px;
`;

const Separator = styled('span')`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

function VeloTimePicker({ value, onChange, name }) {
  const { hour, minute } = value;
  const handleChange = useCallback(
    (event) =>
      onChange({
        target: {
          value: {
            hour,
            minute,
            [event.target.name]: event.target.value,
          },
          name,
        },
      }),
    [hour, minute, onChange, name]
  );

  return (
    <Container>
      <VeloSelect
        data-testid={TestIds.HOURS}
        label={<FormattedMessage defaultMessage="Hours" />}
        options={hourOptions}
        name="hour"
        value={hour}
        onChange={handleChange}
      />
      <Separator>:</Separator>
      <VeloSelect
        data-testid={TestIds.MINUTES}
        options={minuteOptions}
        label=""
        name="minute"
        aria-label="Minutes"
        value={minute}
        onChange={handleChange}
      />
    </Container>
  );
}

export { VeloTimePicker };
