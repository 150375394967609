import curry from 'just-curry-it';

import {
  updateWebhookV1,
  createWebhookV1,
  listWebhooksV1,
} from 'velo-api/src/entities/webhooks';

import {
  forkResult,
  createEarlyExitCallback,
  formatCallbackErrorArg,
  replaceEmptyStringsWithNull,
} from '../../selectors';

const none = () => ({});

const forks = {
  none,
  empty: ({ onCreate: onSubmit, readOnly }) => ({ readOnly, onSubmit }),
  value: ({ id }, { onEdit, readOnly }) => ({
    readOnly,
    onSubmit: curry(onEdit, 3)(id),
  }),
  error: none,
};

export function WebhookUpsertPresenter(
  wireframe,
  { send },
  { payorId, readOnly, autoClose },
  successNote
) {
  const api = {
    createWebhook: createWebhookV1(send),
    updateWebhook: updateWebhookV1(send),
    getWebhooks: listWebhooksV1(send),
  };

  function trapSuccess(cb) {
    return formatCallbackErrorArg(
      createEarlyExitCallback(() => {
        wireframe.sendNote(successNote, true);
        autoClose && wireframe.goBack();
      }, cb)
    );
  }

  const onCreate = (body, cb) =>
    api.createWebhook(
      { payorId, ...replaceEmptyStringsWithNull(body) },
      trapSuccess(cb)
    );
  const onEdit = (id, body, cb) =>
    api.updateWebhook(id, replaceEmptyStringsWithNull(body), trapSuccess(cb));

  const getProps = (result) =>
    forkResult(forks, result, { onCreate, onEdit, readOnly });

  function loader(cb) {
    return api.getWebhooks(
      { payorId },
      createEarlyExitCallback(
        ({ content }) => cb(undefined, content[0] || null),
        cb
      )
    );
  }

  return [loader, getProps];
}
