import {
  createEarlyExitCallback,
  combineCallbacks,
  formatCallbackErrorArg,
  replaceEmptyStringsWithNull,
  replaceEmptyValuesWithEmptyStrings,
  forkResult,
  selectFormValuesFromEditPayee,
  selectEditPayeeDataFromFormValues,
} from 'velo-portal-common';
import { payorRefsSelectors, paymentMethodSelectors } from 'velo-data';

const query = { sensitive: true };

export function EditPayeePresenter(
  wireframe,
  entity,
  { payorId, payeeId },
  { notification, ...fixedProps }
) {
  return [
    // Loader
    (cb) =>
      entity.getPayee(
        payeeId,
        query,
        createEarlyExitCallback(({ payorRefs, ...data }) => {
          const payee = {
            ...selectFormValuesFromEditPayee(data),
            remoteId: payorRefsSelectors.getRemoteId(payorId, payorRefs),
          };
          // Do we have a Payment Channel?
          const paymentChannelId = payorRefsSelectors.getPaymentChannelId(
            payorId,
            payorRefs
          );
          if (paymentChannelId) {
            // Load the channel
            entity.getPaymentChannel(
              paymentChannelId,
              query,
              createEarlyExitCallback((data) => {
                cb(
                  undefined,
                  replaceEmptyValuesWithEmptyStrings({
                    ...payee,
                    ...paymentMethodSelectors.selectPayeePaymentChannel(data),
                    // We need the channel ID when the form is submitted
                    paymentChannelId,
                  })
                );
              }, cb)
            );
          } else {
            cb(undefined, replaceEmptyValuesWithEmptyStrings(payee));
          }
        }, cb)
      ),
    // getProps from the loader result
    (result) => {
      return forkResult(
        {
          value: ({ paymentChannelId }) => ({
            onSubmit: (
              {
                remoteId,
                accountName,
                accountNumber,
                routingNumber,
                iban,
                currency,
                paymentCountry,
                ...body
              },
              cb
            ) => {
              const [onPayeeDetailsUpdate, onRemoteIdUpdate, onPaymentChannel] =
                combineCallbacks(
                  3,
                  formatCallbackErrorArg(
                    createEarlyExitCallback((data) => {
                      // Success
                      wireframe.sendNote(notification);
                      wireframe.goBack();
                    }, cb)
                  )
                );

              // Update Payee details
              entity.payeeDetailsUpdate(
                payeeId,
                selectEditPayeeDataFromFormValues(body),
                onPayeeDetailsUpdate
              );

              // Update remote ID
              entity.remoteIdUpdate(
                payeeId,
                { payorId, remoteId },
                onRemoteIdUpdate
              );

              // Update Payment Channel
              if (accountName) {
                const payload = replaceEmptyStringsWithNull({
                  accountName,
                  accountNumber,
                  routingNumber,
                  iban,
                  currency,
                  countryCode: paymentCountry,
                });
                // We have a payment channel
                // Create a new one or update
                if (paymentChannelId) {
                  // Update the existing channel
                  entity.updatePaymentChannel(
                    payeeId,
                    paymentChannelId,
                    payload,
                    onPaymentChannel
                  );
                } else {
                  // Create a new Payment Channel
                  entity.createPaymentChannel(
                    payeeId,
                    payload,
                    onPaymentChannel
                  );
                }
              } else {
                // Delete the Payment Channel if we have one
                if (paymentChannelId) {
                  entity.deletePaymentChannel(
                    payeeId,
                    paymentChannelId,
                    onPaymentChannel
                  );
                } else {
                  onPaymentChannel();
                }
              }
            },
            onClose: () => {
              wireframe.goBack();
            },
            ...fixedProps,
          }),
        },
        result
      );
    },
  ];
}
