import index from 'just-index';

export function getPayorMap(payorRefs) {
  return index(payorRefs, 'payorId');
}

export function getPayorRef(payorId, payorRefs) {
  return getPayorMap(payorRefs)[payorId];
}

export function getRemoteId(payorId, payorRefs) {
  return getPayorMap(payorRefs)[payorId].remoteId;
}

export function getPaymentChannelId(payorId, payorRefs) {
  return getPayorMap(payorRefs)[payorId].paymentChannelId;
}

export function getInvitationStatus(payorId, payorRefs) {
  return getPayorMap(payorRefs)[payorId].invitationStatus;
}
