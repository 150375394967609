import React from 'react';
import { bool } from 'prop-types';
import styled from 'astroturf/react';
import { VeloDivider } from '../VeloDivider';

const StyledHeader = styled('div')`
  position: relative;
  margin: 0.5rem 1rem 0.5rem 2rem;
  min-height: 3rem;

  /* Space out content */
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

VeloCardHeader.propTypes = {
  /** Show a divider below the component. */
  divider: bool,
};

/**
 * Used to render a header on a VeloCard.
 */
export function VeloCardHeader({ divider, children, ...other }) {
  return (
    <>
      <StyledHeader {...other}>{children}</StyledHeader>
      {divider && <VeloDivider />}
    </>
  );
}
