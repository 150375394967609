import React from 'react';
import { func, shape, bool, string, arrayOf, oneOf } from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import curry from 'just-curry-it';
import omit from 'just-omit';
import pick from 'just-pick';
import compare from 'just-compare';
import { VeloGridLoading } from '../VeloGridLoading';
import { VeloSectionGrid } from '../VeloSectionGrid';
import { VeloFieldGrid } from '../VeloFieldGrid';
import { VeloOnOffSwitch } from '../VeloOnOffSwitch';
import { ConfirmationDialog } from '../ConfirmationDialog';
import { FreeTextEntryWithinRange } from '../FormFields/FormFieldTypes';
import { Description } from '../Description';
import { useSubmitConfirmation } from '../hooks';
import { MaskedDescription } from '../MaskedDescription';

const fieldNames = {
  URL: 'webhookUrl',
  AUTH_HEADER: 'authorizationHeader',
  ENABLED: 'enabled',
  CATEGORIES: { PAYMENT: 'payment', PAYEE: 'payee', FUNDING: 'funding' },
};

const WebhookCategories = {
  PAYEE: 'payee',
  PAYMENT: 'payment',
  FUNDING: 'funding',
};

const WebhookCategoryLabels = (intl) => ({
  [WebhookCategories.PAYEE]: intl.formatMessage({ defaultMessage: 'Payee' }),
  [WebhookCategories.PAYMENT]: intl.formatMessage({
    defaultMessage: 'Payment',
  }),
  [WebhookCategories.FUNDING]: intl.formatMessage({
    defaultMessage: 'Funding',
  }),
});

export const FormTitle = <FormattedMessage defaultMessage="Webhook" />;

const labelsByFieldName = {
  [fieldNames.URL]: <FormattedMessage defaultMessage="Webhook URL" />,
  [fieldNames.AUTH_HEADER]: (
    <FormattedMessage defaultMessage="Authorization header" />
  ),
  [fieldNames.ENABLED]: <FormattedMessage defaultMessage="Enabled" />,
  [fieldNames.CATEGORIES.PAYMENT]: (
    <FormattedMessage defaultMessage="Payment" />
  ),
  [fieldNames.CATEGORIES.PAYEE]: <FormattedMessage defaultMessage="Payee" />,
  [fieldNames.CATEGORIES.FUNDING]: (
    <FormattedMessage defaultMessage="Funding" />
  ),
};

const Labels = {
  CATEGORIES: <FormattedMessage defaultMessage="Categories" />,
  CATEGORIES_NONE: <FormattedMessage defaultMessage="Not set" />,
  SUBMIT_BUTTON: <FormattedMessage defaultMessage="Save" />,
  SUBMITTING_BUTTON: <FormattedMessage defaultMessage="Saving..." />,
};

export function useWebhookForm(props) {
  const [dialogProps, onSubmit] = useSubmitConfirmation(
    props.onSubmit,
    ({ enabled, categories }) => {
      const isWebhookEnabled = !enabled && props.data.enabled === true;
      const existingCategories = props.data.categories || [];
      const isCategoryDisabled =
        !compare(categories.sort(), existingCategories.sort()) &&
        existingCategories.length >= categories.length;

      return isWebhookEnabled || isCategoryDisabled;
    },
    (x) => x
  );

  const intl = useIntl();
  const dialogType = ConfirmationDialog.dialogTypes.DisableWebhook;

  return [
    { ...props, onSubmit, intl },
    (props) => (
      <>
        <VeloFieldGrid {...props} />
        <ConfirmationDialog dialogType={dialogType} {...dialogProps} />
      </>
    ),
  ];
}

const emptyData = {
  enabled: false,
  webhookUrl: '',
  authorizationHeader: '',
  payee: false,
  payment: false,
  funding: false,
};

export const config = {
  createSections() {
    return [
      {
        fields: [
          {
            name: fieldNames.ENABLED,
            Component: VeloOnOffSwitch,
            label: labelsByFieldName[fieldNames.ENABLED],
          },
          {
            ...FreeTextEntryWithinRange(
              labelsByFieldName[fieldNames.URL],
              6,
              2000
            ),
            name: fieldNames.URL,
            required: true,
          },
          {
            ...FreeTextEntryWithinRange(
              labelsByFieldName[fieldNames.AUTH_HEADER],
              4,
              1000
            ),
            name: fieldNames.AUTH_HEADER,
          },
        ],
      },
      {
        // category ordering reflects nav
        heading: Labels.CATEGORIES,
        fields: [
          {
            name: fieldNames.CATEGORIES.PAYMENT,
            Component: VeloOnOffSwitch,
            label: labelsByFieldName[fieldNames.CATEGORIES.PAYMENT],
          },
          {
            name: fieldNames.CATEGORIES.PAYEE,
            Component: VeloOnOffSwitch,
            label: labelsByFieldName[fieldNames.CATEGORIES.PAYEE],
          },
          {
            name: fieldNames.CATEGORIES.FUNDING,
            Component: VeloOnOffSwitch,
            label: labelsByFieldName[fieldNames.CATEGORIES.FUNDING],
          },
        ],
      },
    ];
  },
  getInitialValues({ data }) {
    const categoryData =
      (data.categories &&
        data.categories.reduce((acc, cat) => ({ ...acc, [cat]: true }), {})) ||
      {};

    // provision for missing values
    return {
      ...emptyData,
      ...data,
      ...categoryData,
    };
  },
  getButtonProps(submitting, _, dirty, { webhookUrl }) {
    return {
      // button is disabled additionally if you are editing an existing item when not dirty
      disabled: submitting || (!dirty && !!webhookUrl),
      children: submitting ? Labels.SUBMITTING_BUTTON : Labels.SUBMIT_BUTTON,
    };
  },
  formatBody(body) {
    // transform keys to array of enabled categories
    const categories = Object.entries(
      pick(body, [
        WebhookCategories.PAYMENT,
        WebhookCategories.PAYEE,
        WebhookCategories.FUNDING,
      ])
    )
      .filter(([_, value]) => value)
      .map(([key]) => key);

    // remove categories from the top level
    const shapeBody = omit(
      body,
      WebhookCategories.FUNDING,
      WebhookCategories.PAYEE,
      WebhookCategories.PAYMENT
    );

    // return expected category shape
    return {
      ...shapeBody,
      categories,
    };
  },
};

const readOnlyRender = (props) => <Description {...props} />;

function createMaskedField(props) {
  return {
    render: () => <MaskedDescription {...props} />,
  };
}

function createVisibleReadOnlyField({ value, id, label }) {
  return {
    id,
    label,
    value: value || <MaskedDescription.UnsetValue />,
  };
}

const createReadOnlyCategoriesLabel = ({ categories }, intl) =>
  (categories &&
    categories.length > 0 &&
    categories.map((cat) => WebhookCategoryLabels(intl)[cat]).join(', ')) ||
  Labels.CATEGORIES_NONE;

const createReadOnlyFields = (fieldFactory, data, intl) => [
  {
    value: data.enabled ? (
      <FormattedMessage defaultMessage="On" />
    ) : (
      <FormattedMessage defaultMessage="Off" />
    ),
    label: labelsByFieldName[fieldNames.ENABLED],
    id: fieldNames.ENABLED,
  },
  fieldFactory({
    label: labelsByFieldName[fieldNames.URL],
    value: data.webhookUrl,
    id: fieldNames.URL,
    'data-testid': 'webhook-url-visibility-toggle',
  }),
  fieldFactory({
    label: labelsByFieldName[fieldNames.AUTH_HEADER],
    value: data.authorizationHeader,
    id: fieldNames.AUTH_HEADER,
    'data-testid': 'auth-header-visibility-toggle',
  }),
  {
    value: createReadOnlyCategoriesLabel(data, intl),
    label: Labels.CATEGORIES,
    id: fieldNames.CATEGORIES.PAYEE,
  },
];

export const createMaskedFields = curry(
  createReadOnlyFields,
  3
)(createMaskedField);

export const ReadOnlyGrid = ({ data }) => {
  const intl = useIntl();
  return (
    <VeloSectionGrid
      render={readOnlyRender}
      sections={[
        {
          fields: createReadOnlyFields(createVisibleReadOnlyField, data, intl),
        },
      ]}
    />
  );
};

export const LoadingGrid = () => (
  <VeloGridLoading
    sections={[
      {
        fields: [
          { type: VeloGridLoading.fieldTypes.OnOffSwitch },
          { type: VeloGridLoading.fieldTypes.TextField },
          { type: VeloGridLoading.fieldTypes.TextField },
        ],
      },
      {
        heading: true,
        fields: [
          { type: VeloGridLoading.fieldTypes.OnOffSwitch },
          { type: VeloGridLoading.fieldTypes.OnOffSwitch },
        ],
      },
    ]}
  />
);

export const withDefaultData = (Component) => (props) =>
  <Component {...props} data={emptyData} />;

export function buildDataShape() {
  return shape({
    enabled: bool.isRequired,
    webhookUrl: string.isRequired,
    authorizationHeader: string,
    categories: arrayOf(oneOf(Object.values(WebhookCategories)).isRequired),
  });
}

export function buildPropTypes(types) {
  return {
    onSubmit: func.isRequired,
    data: buildDataShape().isRequired,
    ...types,
  };
}
