import React, { useState } from 'react';
import { node, func, shape, arrayOf } from 'prop-types';
import { useIntl } from 'react-intl';
import { VeloModalSheet } from '../../VeloModalSheet';
import { VeloIconButton } from '../../VeloIconButton';
import { PaymentViewTabs } from '../PaymentViewTabs';
import { root, TestIds } from '../PaymentViewTestIds';

PaymentView.TestIds = TestIds;
PaymentView.root = root;

PaymentView.propTypes = {
  /** The flyout title. */
  title: node.isRequired,
  /** Called when the close button is clicked. */
  onClose: func,
  /** The flyout body containing the Payment details. */
  children: node,
  /** Tabs data containing each label and corresponding component */
  tabs: arrayOf(
    shape({
      label: node.isRequired,
      renderContent: func.isRequired,
    })
  ),
};

/**
 * Render a Payment view.
 */
function PaymentView({ title, onClose, children, tabs }) {
  const intl = useIntl();
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = ({ detail: { index } }) => setActiveTab(index);

  const renderTabs = () => {
    const props = {
      tabs,
      activeTab,
      handleTabChange,
    };
    return <PaymentViewTabs {...props} />;
  };

  const renderTabChildren = () => {
    return tabs[activeTab].renderContent();
  };

  return (
    <>
      <VeloModalSheet.Header divider={!tabs}>
        <VeloModalSheet.HeaderTitle data-testid={TestIds.TITLE}>
          {title}
        </VeloModalSheet.HeaderTitle>
        <VeloIconButton
          icon="close"
          title={intl.formatMessage({
            defaultMessage: 'Close payment view',
          })}
          onClick={onClose}
          data-testid={TestIds.CLOSE_BUTTON}
        />
      </VeloModalSheet.Header>
      {tabs && renderTabs()}
      <VeloModalSheet.Body>
        {tabs ? renderTabChildren() : children}
      </VeloModalSheet.Body>
    </>
  );
}

export { PaymentView };
