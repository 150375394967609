import React from 'react';
import { bool, func, number, string } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styled from 'astroturf/react';
import Money from 'js-money';
import { VeloDialog } from '../VeloDialog';
import { VeloTypography } from '../VeloTypography';
import { ConfirmFunds } from '../ConfirmFunds';
import { VeloCurrency } from '../VeloCurrency';

const Dialog = styled(VeloDialog)`
  @import 'velo-variables';

  :global(.mdc-dialog__surface) {
    /* This overrides the width of the dialog on desktop to match the UX design */
    @media (min-width: 592px) {
      max-width: velo-breakpoint(XS);
    }
  }
`;

/**
 * Render an amount using suitable locale currency formatting.
 * Note that the `money` prop is a `js-money` object.
 *
 * The children-as-a-function pattern allows us to
 * render a custom tag allowing a `data-testid` prop to
 * be passed.
 */
const Amount = ({ money, ...other }) => (
  <VeloCurrency value={money.getAmount()} currency={money.getCurrency()}>
    {(value) => (
      <VeloTypography use="bodyTextEmphasised" {...other}>
        {value}
      </VeloTypography>
    )}
  </VeloCurrency>
);

const root = 'confirm-auto-topup';

const TestIds = {
  DIALOG: `${root}-dialog`,
  TITLE: `${root}-title`,
  MIN_BALANCE: `${root}-min-balance`,
  CURRENT_BALANCE: `${root}-current-balance`,
  CONFIRM_AUTO_TOPUP: `${root}-confirm-auto-topup`,
  CANCEL: `${root}-cancel`,
};

ConfirmAutoTopUp.propTypes = {
  /** Open/close the dialog. */
  open: bool.isRequired,
  /** Called when the dialog is closed. */
  onClose: func,
  /** The currency code ("USD", "GBP", etc.). */
  currency: string.isRequired,
  /** The minimum balance to trigger auto top up (in minor units). */
  minBalance: number.isRequired,
  /** The current source account balance (in minor units). */
  currentBalance: number.isRequired,
};

ConfirmAutoTopUp.testIds = TestIds;

/**
 * Confirm Auto Top Up Dialog.
 *
 * Used the minimum balance is greater that the current balance, which will
 * cause funds to be immediatly requested for a Source Account.
 */
function ConfirmAutoTopUp({
  open,
  onClose,
  currency,
  minBalance,
  currentBalance,
}) {
  const currentBalanceMoney = new Money(currentBalance, currency);
  const minBalanceMoney = new Money(minBalance, currency);

  return (
    <Dialog open={open} onClose={onClose} data-testid={TestIds.DIALOG}>
      {/* Title */}
      <VeloDialog.Title data-testid={TestIds.TITLE}>
        <VeloTypography use="secondaryHeader">
          <FormattedMessage defaultMessage="Confirm Topup Amount" />
        </VeloTypography>
      </VeloDialog.Title>

      {/* Content */}
      <VeloDialog.Content>
        <ConfirmFunds.Text>
          <FormattedMessage
            defaultMessage="You have specified an Auto Topup minimum balance of {amount},{br} which is more than the actual current balance of {current}."
            values={{
              amount: (
                <Amount
                  money={minBalanceMoney}
                  data-testid={TestIds.MIN_BALANCE}
                />
              ),
              br: <br />,
              current: (
                <Amount
                  money={currentBalanceMoney}
                  data-testid={TestIds.CURRENT_BALANCE}
                />
              ),
            }}
          />
        </ConfirmFunds.Text>

        <ConfirmFunds.Text>
          <FormattedMessage
            defaultMessage="This <strong>will automatically trigger an Auto Topup pull</strong> from the connected funding account."
            values={{
              strong: (chunks) => <strong>{chunks}</strong>,
            }}
          />
        </ConfirmFunds.Text>

        <ConfirmFunds.Text>
          <FormattedMessage defaultMessage="Do you want to use this value?" />
        </ConfirmFunds.Text>
      </VeloDialog.Content>

      {/* Action buttons */}
      <VeloDialog.Actions>
        <VeloDialog.Button
          type="button"
          action="accept"
          isDefaultAction
          data-testid={TestIds.CONFIRM_AUTO_TOPUP}
        >
          <FormattedMessage defaultMessage="Confirm" />
        </VeloDialog.Button>
        <VeloDialog.Button
          type="button"
          action="close"
          data-testid={TestIds.CANCEL}
        >
          <FormattedMessage defaultMessage="Cancel" />
        </VeloDialog.Button>
      </VeloDialog.Actions>
    </Dialog>
  );
}

export { ConfirmAutoTopUp };
