import { useMemo } from 'react';
import { useHistory } from 'react-router';
import { useIntl } from 'react-intl';
import { useAPIMethods } from 'velo-api-react-hooks';
import { useWireframe } from './useWireframe';

/**
 * usePresenter encapsulates the common operations associated with using a
 * Presenter function, namely:
 * - The first argument of the Presenter is a wireframe.
 * - It assumes you are using a provided entitySpec, and that this is the second
 * argument to the Presenter.
 * - It assumes that there is some fixed, static config to be passed to the
 * Presenter, and that this is the last argument of the Presenter.
 * - It assumes there will be some props (React props) and this is passed as the
 * third argument to the Presenter (including history).
 */
export function usePresenter(Presenter, entitySpec, fixedProps, props) {
  const wireframe = useWireframe(useHistory());
  const entity = useAPIMethods(entitySpec);
  const intl = useIntl();
  return useMemo(
    () => Presenter(wireframe, entity, props, fixedProps, intl),
    [Presenter, wireframe, entity, props, fixedProps, intl]
  );
}
