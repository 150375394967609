import React from 'react';
import { bool } from 'prop-types';
import styled from 'astroturf/react';
import { FormattedMessage } from 'react-intl';
import { SecondaryValueType, getCurrencyName } from 'velo-data';
import { VeloTypography } from '../VeloTypography';
import { VeloSkeleton } from '../VeloSkeleton';

const SecondaryResultText = styled(VeloTypography)`
  @import 'velo-variables';
  @import '@design-system/tokens/typography/scss/mixins';

  @include ds-typography-ellipsis;
  color: velo-color('token-color-text-muted');

  &:nth-child(1) {
    width: 55%;
  }

  &:nth-child(2) {
    width: 45%;
  }
`;

const Skeleton = styled(VeloSkeleton)`
  width: 25%;
  height: 0.875rem;

  &.secondary {
    height: 0.75rem;
  }
`;

Skeleton.propTypes = {
  secondary: bool,
};

export const primary = (value) => (
  <VeloTypography use="bodyText" tag="div">
    {value}
  </VeloTypography>
);

export const primaryLoading = () => <Skeleton />;

const secondaryValueTransformByType = {
  [SecondaryValueType.REMOTE_ID]: (value) => (
    <FormattedMessage defaultMessage="Remote ID: {value}" values={{ value }} />
  ),
  [SecondaryValueType.CURRENCY]: (currency) => (
    <FormattedMessage
      defaultMessage="{currency} ({currencyName})"
      values={{ currency, currencyName: getCurrencyName(currency) }}
    />
  ),
};

function mapSecondaryValue(value) {
  if (value.hasOwnProperty('type')) {
    return secondaryValueTransformByType[value.type](value.value);
  }
  return value;
}

export const secondary = (secondaryValues) =>
  secondaryValues.map((value, i) => (
    <SecondaryResultText
      key={`secondary-value-${i}`}
      use="bodyTextSmaller"
      tag="div"
    >
      {mapSecondaryValue(value)}
    </SecondaryResultText>
  ));

export const secondaryLoading = () => (
  <>
    <SecondaryResultText tag="div">
      <Skeleton secondary style={{ width: '66%' }} />
    </SecondaryResultText>

    <SecondaryResultText tag="div">
      <Skeleton secondary style={{ width: '80%' }} />
    </SecondaryResultText>
  </>
);
