import React from 'react';
import {
  Experimental_CssVarsProvider as CssVarsProvider,
  experimental_extendTheme as extendTheme,
} from '@mui/material/styles';
import themes from '@design-system/tokens/colors/js/theme-defs';
import { TokenTypographyXxsBaseFontFamily } from '@design-system/tokens/typography/js/tokens';

const veloTheme = themes.themes.find((theme) => theme.default);

const theme = extendTheme({
  typography: {
    fontFamily: TokenTypographyXxsBaseFontFamily,
  },
  colorSchemes: {
    light: {
      palette: {
        primary: {
          main: veloTheme.color,
        },
        secondary: {
          main: veloTheme.color,
        },
      },
    },
  },
  components: {
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
      },
      styleOverrides: {
        root: {
          '& .MuiInputBase-root': {
            height: 56,
          },
          '& .MuiFormHelperText-root': {
            lineHeight: 'normal',
          },
          '& .MuiSelect-select': {
            display: 'flex',
            alignItems: 'center',
            py: '1rem',
          },
          '& .MuiListItemIcon-root': {
            minWidth: 40,
          },
        },
      },
    },
  },
});

export function MUIThemeProvider(props) {
  return <CssVarsProvider theme={theme}>{props.children}</CssVarsProvider>;
}
