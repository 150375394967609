import React from 'react';
import { bool, node, string } from 'prop-types';
import styled from 'astroturf/react';
import { VeloGrid } from '../VeloGrid';
import { VeloTypography } from '../VeloTypography';
import { VeloSkeleton } from '../VeloSkeleton';

const LabelledItemContainer = styled('div')`
  margin-bottom: 0.5rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

const Value = styled(VeloTypography).attrs({ tag: 'div' })`
  &.splitWords {
    word-break: break-all;
  }
`;

Value.propTypes = {
  splitWords: bool,
};

const Label = styled(VeloTypography).attrs({ tag: 'div' })`
  &.disabled {
    color: var(--token-color-text-disabled);
  }
`;

VeloLabelledItem.propTypes = {
  /** The label to use. */
  label: node.isRequired,
  /** The value to display, */
  value: node,
  /** Render in the disabled state. */
  disabled: bool,
  /** A suitablke test ID for the value. */
  testId: string,
  /** Custom CSS classes. */
  className: string,
  /** Weather or not to split words in the value content on line break. */
  splitWords: bool,
};

Label.propTypes = {
  /** Render label in the disabled state. */
  disabled: bool,
};

function VeloLabelledItem({
  label,
  disabled,
  testId,
  splitWords = true,
  className,
  children,
}) {
  return (
    <LabelledItemContainer className={className}>
      <Label use="itemLabel" disabled={disabled}>
        {label}
      </Label>
      <Value
        splitWords={splitWords}
        use={disabled ? 'itemContentDisabled' : 'itemContent'}
        data-testid={testId}
      >
        {children}
      </Value>
    </LabelledItemContainer>
  );
}

const SkeletonContainer = styled('div')`
  width: 100%;
`;

const SkeletonLabel = styled(VeloSkeleton)`
  width: 25%;
  display: block;
  height: 12px;
`;

const SkeletonValue = styled(VeloSkeleton)`
  width: 50%;
`;

function VeloLabelledItemSkeleton({ testId, children }) {
  return (
    <SkeletonContainer>
      <SkeletonLabel />
      {children ? children : <SkeletonValue data-testid={testId} />}
    </SkeletonContainer>
  );
}

function withAlignedLabel(labelFn, leftCellProps = {}, rightCellProps = {}) {
  return (Component) => (props) =>
    (
      <VeloGrid.Inner>
        <VeloGrid.Cell desktop="4" tablet="2" phone="2" {...leftCellProps}>
          {labelFn(props)}
        </VeloGrid.Cell>
        <VeloGrid.Cell
          mobileAlign="right"
          desktop="8"
          tablet="2"
          phone="2"
          {...rightCellProps}
        >
          <Component {...props} />
        </VeloGrid.Cell>
      </VeloGrid.Inner>
    );
}

function UseValue({ label, value, options }) {
  const children = options
    ? options.find((option) => option.value === value).label
    : value;
  return <VeloLabelledItem label={label}>{children}</VeloLabelledItem>;
}

VeloLabelledItem.Skeleton = VeloLabelledItemSkeleton;
VeloLabelledItem.withAlignedLabel = withAlignedLabel;
VeloLabelledItem.UseValue = UseValue;

export { VeloLabelledItem };
