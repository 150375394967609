import React from 'react';
import { FormattedMessage } from 'react-intl';
import { array, func, string } from 'prop-types';
import styled from 'astroturf/react';
import { VeloIconButton } from '../VeloIconButton';
import { VeloTypography } from '../VeloTypography';

const Header = styled('div')`
  @import 'velo-variables';

  display: none;

  @media (max-width: velo-breakpoint(S)) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 0.5rem 0 1rem;
  }
`;

FilterHeader.propTypes = {
  /** Custom CSS class(es). */
  className: string,
  /** used to open the Filter form. */
  onTogglePopup: func.isRequired,
  /** The array of current filters. Used for the label. */
  filters: array.isRequired,
};

/**
 * Mobile Filter header. Renders the number of applied
 * filters and a button to open the filter form.
 */
function FilterHeader({ className, onTogglePopup, filters }) {
  return (
    <Header className={className}>
      <VeloTypography use="bodyTextEmphasised">
        <FormattedMessage
          defaultMessage="{length, plural, =0 {No filters applied} one {1 filter applied} other {{length} filters applied}}"
          values={{ length: filters.length }}
        />
      </VeloTypography>

      <VeloIconButton
        icon="filter_list"
        title="Filter list"
        onClick={onTogglePopup}
        data-testid="velo-filter-header-button"
      />
    </Header>
  );
}

export { FilterHeader };
