import { useState, useCallback, useMemo } from 'react';
import pick from 'just-pick';
import { createFilterFormatterOnChange } from '../../containers/helpers';

function computeInitialState(filters) {
  return filters.reduce((acc, { name, entityId, value }) => {
    const v = entityId || value;
    return { ...acc, ...(v !== undefined && { [name]: v }) };
  }, {});
}

const useFilter = (filters, defaultFilter) => {
  const [currentFilters, setFilters] = useState(
    () => defaultFilter || computeInitialState(filters)
  );

  const [displayValues, setDisplayValues] = useState({});

  const onChange = useMemo(() => {
    const changeValues = createFilterFormatterOnChange((selectedFilters) => {
      setFilters(selectedFilters);
    }, filters);

    return (fieldValues, displayValues = {}) => {
      changeValues(fieldValues);
      setDisplayValues(displayValues);
    };
  }, [filters, setDisplayValues]);

  const query = useCallback(
    () => ({
      ...currentFilters,
    }),
    [currentFilters]
  );

  const clearFilters = (persistedKeys = []) => {
    const persistedFilters = pick(currentFilters, persistedKeys);
    const persistedDisplayValues = pick(displayValues, persistedKeys);

    setFilters(persistedFilters);
    setDisplayValues(persistedDisplayValues);
  };

  return [onChange, query, displayValues, clearFilters];
};

export { useFilter };
