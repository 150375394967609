import React from 'react';
import { any } from 'prop-types';
import styled from 'astroturf/react';
import { VeloDate } from '../VeloDate';
import { VeloDateTime } from '../VeloDateTime';

VeloDateFormat.propTypes = {
  /** The date/time to format */
  value: any.isRequired,
};

/**
 * Renders a long or short date, dependent on the
 * viewport size e.g.
 *
 * Long: 27/07/2012 16:00 PM
 * Short: 27 Jul
 *
 * Pass the date using the `value` prop.
 */

const Container = styled('div')`
  @import 'velo-variables';
  @import 'velo-mixins';

  @media (max-width: velo-breakpoint(XS)) {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0;
  }
`;

const ShortDate = styled('div')`
  @import 'velo-variables';
  @import 'velo-mixins';
  @include velo-font-weight(medium);

  word-wrap: break-word;
  white-space: normal;
  text-align: center;
  display: flex;
  flex-direction: row;

  @media (min-width: velo-breakpoint(XS)) {
    display: none;
  }

  @media (max-width: velo-breakpoint(XS)) {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 4rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 0;
  }
`;

const LongDate = styled('div')`
  @import 'velo-variables';
  @import 'velo-mixins';

  @media (max-width: velo-breakpoint(XS)) {
    display: none;
  }
`;

function VeloDateFormat({ value }) {
  return (
    <Container>
      <ShortDate>
        <VeloDate value={value} day="numeric" />
        <VeloDate value={value} month="short" />
      </ShortDate>

      <LongDate>
        <VeloDateTime value={value} />
      </LongDate>
    </Container>
  );
}

export { VeloDateFormat };
