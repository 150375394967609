import React, { useCallback, useMemo } from 'react';
import { Route, Switch, useParams, useHistory } from 'react-router';
import { SourceAccountsAddFundingRoute } from './SourceAccountsAddFundingRoute';
import { SourceAccountsAutoTopupRoute } from './SourceAccountsAutoTopupRoute';
import { SourceAccountsBalanceThresholdRoute } from './SourceAccountsBalanceThresholdRoute';
import { SourceAccountsViewRoute } from './SourceAccountsViewRoute';
import { SourceAccountsEditRoute } from './SourceAccountsEditRoute';
import { createFromHook } from '../../containers';
import { useWireframe } from '../../hooks';
import { SourceAccountsListRoute } from './SourceAccountsListRoute';

function useActionView({ role }) {
  const params = useParams();
  return useMemo(() => ({ ...params, role }), [role, params]);
}

const AddFunds = createFromHook(useActionView, {
  render: (props) => <SourceAccountsAddFundingRoute {...props} />,
});
const AutoTopup = createFromHook(useActionView, {
  render: (props) => <SourceAccountsAutoTopupRoute {...props} />,
});
const BalanceThreshold = createFromHook(useActionView, {
  render: (props) => <SourceAccountsBalanceThresholdRoute {...props} />,
});
const Edit = createFromHook(useActionView, {
  render: (props) => <SourceAccountsEditRoute {...props} />,
});

function SourceAccountsRoute({ role, query }) {
  const renderView = useCallback(
    () => <SourceAccountsViewRoute role={role} query={query} />,
    [role, query]
  );

  const wireframe = useWireframe(useHistory());

  return (
    <Switch>
      <Route path={wireframe.navigateToSourceAccountAddFunding.path} exact>
        <AddFunds role={role} />
      </Route>
      <Route path={wireframe.navigateToSourceAccountAutoTopup.path} exact>
        <AutoTopup role={role} />
      </Route>
      <Route path={wireframe.navigateToSourceAccountEdit.path} exact>
        <Edit role={role} />
      </Route>
      <Route
        path={wireframe.navigateToSourceAccountBalanceThreshold.path}
        exact
      >
        <BalanceThreshold role={role} />
      </Route>
      <>
        <Route path={wireframe.navigateToSourceAccountDetail.path} exact>
          {renderView}
        </Route>
        <Route>
          <SourceAccountsListRoute query={query} role={role} />
        </Route>
      </>
    </Switch>
  );
}

export { SourceAccountsRoute };
