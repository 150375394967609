import React, { useState } from 'react';
import { string, node, number } from 'prop-types';
import { useDebouncedResize } from '../hooks';

const getHeight = () => window.innerHeight * 0.01;

ViewHeight.propTypes = {
  /** The child components to render. */
  children: node.isRequired,
  /** The window resize debounce delay. */
  debounceTime: number,
  /** The tag to wrap the children with. */
  tag: string,
};

/**
 * Use this to wrap components that need to control their height using "vh" values.
 * The actual size of the view will be used, taking any browser chrome into account.
 * For example, on Mobile Safari, the following CSS will not include the height of
 * the bottom navigation toolbar:
 *
 *  height: 100vh;
 *
 * This means that components positioned at the bottom of the view will be clipped
 * and the user may not be able to see them.
 *
 * Instead, use this to wrap your component and use the `vh` mixin to specify your
 * view height. For example:
 *
 *   @import '../ViewHeight/mixins';
 *
 *   .my-class {
 *     @include vh(height, 100);
 *   }
 *
 * The mixin accepts two paramneters: the name of the CSS style property and the size
 * as a percentage of the available space.
 *
 * The mixin will ensure compatibility with IE11 which does not support CSS variables.
 *
 * You can also reference CSS vars via inline styles but this is not supported in IE11
 * and there is no simple way to add a fallback, so this is not recommended:
 *
 * const style = {
 *   height: `calc(var(--vh) * 100)`,
 *   display: 'flex',
 *   justifyContent: 'center',
 *   alignItems: 'center'
 * };
 */
export function ViewHeight({
  tag: Tag = 'div',
  debounceTime = 100,
  children,
  ...other
}) {
  const [height, setHeight] = useState(getHeight());
  useDebouncedResize(() => setHeight(getHeight()), debounceTime);

  return (
    <Tag style={{ '--vh': `${height}px` }} {...other}>
      {children}
    </Tag>
  );
}
