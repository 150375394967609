import React from 'react';
import { Icon } from '@rmwc/icon';
import '@rmwc/icon/icon.css';

/**
 * Wrapper for the RMWC `Icon` component.
 */
export function VeloIcon(props) {
  return <Icon {...props} />;
}
