import React, { createContext, useContext, useState, useMemo } from 'react';
import { ConfirmationDialog } from '../ConfirmationDialog';

const root = 'confirm-discard-changes-context';

const TestIds = {
  DIALOG: `${root}-dialog`,
  CONFIRMATION_BUTTON: `${root}-confirmation-button`,
  CANCELLATION_BUTTON: `${root}-cancellation-button`,
};

const VeloConfirmDiscardChangesContext = createContext({
  confirmDiscardChanges: null,
  contentUpdated: null,
  getIsConfirmingDiscardChanges: null,
});

const confirmDiscardChangesDialogType = {
  ...ConfirmationDialog.dialogTypes.DiscardChangesType,
  acceptProps: {
    ...ConfirmationDialog.dialogTypes.DiscardChangesType.acceptProps,
    'data-testid': TestIds.CONFIRMATION_BUTTON,
  },
  cancelProps: {
    ...ConfirmationDialog.dialogTypes.DiscardChangesType.cancelProps,
    'data-testid': TestIds.CANCELLATION_BUTTON,
  },
};

function Provider(props) {
  const [contentUpdated, setContentUpdated] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  /**
   * The continue function is called after unsaved saved changes have been
   * discarded.
   *
   * The state is stored in an object due to using a function as state.
   */
  const [{ continueFn }, setContinueFn] = useState({});

  const contextValue = useMemo(
    () => ({
      contentUpdated: setContentUpdated,
      getIsConfirmingDiscardChanges: () => showDialog,
      confirmDiscardChanges: (continueFn) => {
        if (contentUpdated) {
          setShowDialog(true);
          setContinueFn({ continueFn });
        } else {
          continueFn();
        }
      },
    }),
    [contentUpdated, showDialog]
  );

  return (
    <VeloConfirmDiscardChangesContext.Provider value={contextValue}>
      {props.children}
      <ConfirmationDialog
        data-testid={TestIds.DIALOG}
        open={showDialog}
        onClose={(evt) => {
          if (evt.detail.action === 'accept') {
            continueFn();
            setContentUpdated(false);
          }

          setShowDialog(false);
        }}
        dialogType={confirmDiscardChangesDialogType}
      />
    </VeloConfirmDiscardChangesContext.Provider>
  );
}

const { Consumer } = VeloConfirmDiscardChangesContext;

const useConfirmDiscardChangesContext = () =>
  useContext(VeloConfirmDiscardChangesContext);

const defaultMapper = (context) => context;

const withConfirmDiscardChangesContext = (
  mapContextToProps = defaultMapper
) => {
  return (WrappedComponent) => (props) => {
    return (
      <Consumer>
        {(context) => (
          <WrappedComponent {...mapContextToProps(context, props)} {...props} />
        )}
      </Consumer>
    );
  };
};

const Context = {
  Provider,
  Consumer,
  Context: VeloConfirmDiscardChangesContext,
  useConfirmDiscardChangesContext,
  withConfirmDiscardChangesContext,
  testIds: TestIds,
};

export { Context as VeloConfirmDiscardChangesContext };
