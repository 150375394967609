import {
  createDataJoinCallbackFunction,
  createAwaitAllDataJoinCallback,
} from '../../selectors';

export function PaymentRailDataJoin(entity) {
  return createAwaitAllDataJoinCallback(
    createDataJoinCallbackFunction(
      ([{ id: key }]) => [{ key, params: [] }],
      entity.getSupportedRails
    ),
    (resultsByKey, content, sourceAccount) => {
      const { error, result } = resultsByKey[sourceAccount.id];

      if (error) {
        return [error];
      }

      const matchingRailsProvider = result.supportedRails.find(
        ({ railsId }) => railsId === sourceAccount.railsId
      );

      // Defaults when no matching rails provider
      if (!matchingRailsProvider) {
        return [
          undefined,
          [
            ...content,
            {
              ...sourceAccount,
              platformFundingSupported: false,
              balanceVisible: true,
            },
          ],
        ];
      }

      const {
        balanceVisible,
        platformFundingSupported,
        onBehalfOfOverrideSupported,
        sourceAccountTransfersSupported,
      } = matchingRailsProvider;

      return [
        undefined,
        [
          ...content,
          {
            ...sourceAccount,
            balanceVisible,
            platformFundingSupported,
            onBehalfOfOverrideSupported,
            sourceAccountTransfersSupported,
          },
        ],
      ];
    },
    true
  );
}
