import React from 'react';
import {
  arrayOf,
  array,
  oneOf,
  oneOfType,
  func,
  number,
  bool,
  object,
  shape,
  string,
  element,
} from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styled from 'astroturf/react';
import { PayeeCreationTypes, PayeeType } from 'velo-data';
import { propTypesCountries } from '../utils';
import { VeloStepper } from '../VeloStepper';
import { VeloOverlay } from '../VeloOverlay';
import { ConfirmationDialog } from '../ConfirmationDialog';
// Note we are importing directly from OnboardingPage and
// to avoid a circular dependency.
import { VeloPropTypes } from '../VeloPropTypes';
import { sanitizeAddressFields } from '../FormFields/AddressFields';
import { Content } from '../Content';
import { VeloCardPage } from '../VeloCardPage';
import { VeloProgressIndicator } from '../VeloProgressIndicator';
import {
  PayeeUploadStep,
  TypeSelectionStep,
  ChallengeCodeStep,
  PaymentChannelStep,
} from './Steps';
import { PayeeCreateForms } from './Steps/PayeeCreateForms';
import { AddPayeeError } from './AddPayeeError';
import { TestIds } from './testIds';
import { AddPayeeMode } from './propTypes';

const AppPayeeStepper = styled(VeloStepper)`
  padding-bottom: 0rem;
`;

const OnboardingPayeeStepper = styled(VeloStepper)`
  padding: 0rem;
`;
/**
 * Add Single Payee form.
 *
 * The form is arranged using a vertical stepper with three distinct
 * steps:
 *
 * 1. Account type
 * 2. Details
 * 3. Optional payment channel details
 */
class AddPayee extends React.Component {
  static propTypes = {
    /**
     * The mode of operation for the form.
     */
    mode: oneOf(Object.values(AddPayeeMode)),
    /** List of supported countries, associated currencies and regions. */
    countries: propTypesCountries,
    /** Initially selected country. */
    country: string.isRequired,
    /** Payment Channel rules and currency. */
    paymentChannel: shape({
      /** Payment channel field rules. */
      rules: VeloPropTypes.paymentChannelRulesType().isRequired,
      /** Initially selected Payment Channel currency. */
      currency: string.isRequired,
    }),
    /**
     * Called when the form is submitted. Passed the form
     * data, including a `type` of `Individual` or `Company`.
     */
    onSubmit: func.isRequired,
    /** Called when the Cancel button is clicked. */
    onClose: func,
    /**
     * Disable the entire form and changes the "Create" button to "Creating...".
     * Use this whilst API calls are in progress.
     */
    submitting: bool,
    /**
     * Error message to display.
     * Use this to surface errors - API or Status
     */
    error: oneOfType([string, array, element]),
    /**
     * Values map: used to initialise form fields.
     */
    values: object,
    /**
     * Read only values map: used to populate read only info in the form
     */
    readOnlyValues: object,
    /**
     * Render the form in the loading state.
     */
    loading: bool,
    /**
     * Breakpoint to enable single-column field layout.
     */
    breakpoint: number,
    /**
     * An array of masked field names.
     * Fields in this list are not validated unless they change.
     * If the field is not changed then it will not be included
     * when the form is submitted.
     */
    masked: arrayOf(string),
    /**
     * Boolean for determining in with fileSubmitted, if the error requires the Export error button
     */
    isBatchError: bool,
    /**
     * Payee rules. Used for the nationalIdentification and taxId fields.
     */
    payeeCountryRules: VeloPropTypes.payeeCountryRulesType().isRequired,
    /**
     * Initially expand the Challenge section.
     */
    showChallengeCode: bool,
    /**
     * Initially expand the Payment Method.
     */
    showPaymentChannel: bool,
  };

  static defaultProps = {
    mode: AddPayeeMode.PAYOR_CREATE,
    values: {},
    breakpoint: 600,
    masked: [],
    showChallengeCode: false,
    showPaymentChannel: false,
  };

  static mode = AddPayeeMode;

  // The fields used on the form. These are used when
  // initialising state and building the data object to
  // return when the form is submitted.
  fieldNames = {
    individual: [
      'title',
      'firstName',
      'otherNames',
      'lastName',
      'dateOfBirth',
      'nationalIdentification',
    ],
    company: [
      'name',
      'operatingName',
      'taxId',
      'adminUserFirstName',
      'adminUserLastName',
    ],
    shared: ['email', 'contactEmail', 'contactSmsNumber', 'remoteId'],
    address: [
      'line1',
      'line2',
      'line3',
      'line4',
      'city',
      'countyOrProvince',
      'zipOrPostcode',
      'countryCode',
    ],
    challenge: ['challengeDescription', 'challengeValue'],
    paymentChannel: [
      'paymentChannelName',
      'paymentCountry',
      'currency',
      'accountName',
      'accountNumber',
      'routingNumber',
      'iban',
    ],
  };

  state = Object.entries({
    // Fields
    ...[
      ...this.fieldNames.individual,
      ...this.fieldNames.company,
      ...this.fieldNames.shared,
      ...this.fieldNames.address,
      ...this.fieldNames.challenge,
      ...this.fieldNames.paymentChannel,
    ].reduce((acc, name) => ({ ...acc, [name]: '' }), {}),
    // Default the contactEmail to the readOnly loginEmail field if contactEmail is not supplied
    contactEmail:
      this.props.values.contactEmail ||
      this.props.readOnlyValues?.loginEmail ||
      '',
    // Local UI state
    type:
      this.props.mode === AddPayeeMode.PAYOR_CREATE
        ? PayeeCreationTypes.CSV_UPLOAD
        : undefined,
    showChallengeCode: this.props.showChallengeCode,
    showPaymentChannel: this.props.showPaymentChannel,

    // Confirmation dialog
    showConfirmationDialog: false,
    confirmationDialogMessage: '',
    onAcceptConfirmationDialog: undefined,
    // State initialised from props
    countryCode: this.props.country,
    currency: this.props.paymentChannel
      ? this.props.paymentChannel.currency
      : '',
    paymentCountry: this.props.country,
    masked: [...this.props.masked],
    fileSubmitted: false,
  })
    // Apply initial values
    .reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: this.props.values[key] || value,
      }),
      {}
    );

  initialValues = [
    'type',
    ...this.fieldNames.individual,
    ...this.fieldNames.company,
    ...this.fieldNames.shared,
    ...this.fieldNames.address,
    ...this.fieldNames.challenge,
    ...this.fieldNames.paymentChannel,
  ].reduce(
    (acc, key) => ({
      ...acc,
      [key]: this.state[key],
    }),
    {}
  );

  errorRef = React.createRef();

  // Returns true if changes have been made
  hasChanged() {
    const { mode, showChallengeCode, showPaymentChannel } = this.props;
    const initialValues = {
      ...this.initialValues,
      /**
       * When editing a Payee we need the challenge/payment method
       * toggles to determine if Save is enabled.
       */
      ...(mode === AddPayeeMode.PAYOR_EDIT
        ? {
            showChallengeCode,
            showPaymentChannel,
          }
        : {}),
    };
    return (
      Object.entries(initialValues).reduce(
        (acc, [key, value]) => (this.state[key] === value ? acc : acc + 1),
        0
      ) !== 0
    );
  }

  getCurrency = (isoCountryCode) =>
    this.props.countries.find(
      (country) => country.isoCountryCode === isoCountryCode
    ).currencies[0];

  handlers = {
    onChangeType: (type) => {
      this.setState({ type });
    },
    onToggleShowChallengeCode: (evt) => {
      this.setState({ showChallengeCode: evt.target.checked });
    },
    onToggleShowPaymentChannel: (evt) => {
      this.setState({ showPaymentChannel: evt.target.checked });
    },
    onChange: (evt) => {
      const { name, value } = evt.target;
      this.setState({ [name]: value }, () => {
        if (name === 'paymentCountry') {
          // If a new payment country was selected we have to
          // update the list of currencies and reset the bank
          // fields.
          this.setState({
            currency: this.getCurrency(value),
            accountNumber: '',
            routingNumber: '',
            iban: '',
          });
        } else if (name === 'countryCode') {
          // If the country changes then reset the region,
          // zip code, SSN/Tax ID and Payment Method
          // details.
          this.setState((prevState) => ({
            countyOrProvince: '',
            zipOrPostcode: '',
            nationalIdentification: '',
            taxId: '',
            // Remove SSN/Tax ID from the masked values so we
            // include them when we POST
            masked: prevState.masked.filter(
              (name) => name !== 'nationalIdentification' && name !== 'taxId'
            ),
            // Sync the Payment Method details if the countries differ
            ...(prevState.paymentCountry !== value
              ? {
                  paymentCountry: value,
                  currency: this.getCurrency(value),
                  accountNumber: '',
                  routingNumber: '',
                  iban: '',
                }
              : {}),
          }));
        }
      });
      // Is this a masked field?
      if (this.props.masked.indexOf(name) !== -1) {
        // Update our copy of the masked fields array
        // by adding or removing this field based on
        // the current value matching the initial
        // value.
        this.setState((state) => ({
          masked:
            value === this.initialValues[name]
              ? [...new Set([...state.masked, name])]
              : state.masked.filter((value) => value !== name),
        }));
      }
    },
    onSubmit: (file) => {
      if (file) {
        this.setState({ fileSubmitted: true });
        this.props.onSubmit(file);
      } else {
        this.setState({ fileSubmitted: false });
        const {
          type,
          showChallengeCode,
          showPaymentChannel,
          masked,
          countryCode,
        } = this.state;
        const {
          shared,
          address,
          individual,
          company,
          challenge,
          paymentChannel,
        } = this.fieldNames;

        this.props.onSubmit(
          sanitizeAddressFields(
            {
              type,
              // Build an array of field names to return, filter out
              // any masked values that have not changed and reduce
              // the array to an object containing state values.
              ...[
                ...shared,
                ...address,
                ...(type === PayeeCreationTypes.INDIVIDUAL ? individual : []),
                ...(type === PayeeCreationTypes.COMPANY ? company : []),
                ...(showChallengeCode ? challenge : []),
                ...(showPaymentChannel ? paymentChannel : []),
              ]
                .filter((name) => masked.indexOf(name) === -1)
                .reduce(
                  (acc, name) => ({ ...acc, [name]: this.state[name] }),
                  {}
                ),
            },
            countryCode
          )
        );
      }
    },
    onSubmitForm: (evt) => {
      evt.preventDefault();
      // When on-boarding we display a confirmation dialog if
      // the user has made any changes
      if (
        this.props.mode === AddPayeeMode.PAYEE_ONBOARDING &&
        this.state.type === PayeeType.INDIVIDUAL &&
        this.hasChanged()
      ) {
        this.setState({
          showConfirmationDialog: true,
          confirmationDialogMessage: (
            <FormattedMessage defaultMessage="Are you sure you want to save your changes?" />
          ),
          onAcceptConfirmationDialog: () => {
            this.handlers.onSubmit();
          },
        });
      } else {
        this.handlers.onSubmit();
      }
    },
    onCancel: () => {
      if (this.hasChanged()) {
        this.setState({
          showConfirmationDialog: true,
          confirmationDialogMessage: (
            <FormattedMessage defaultMessage="Are you sure you want to cancel?" />
          ),
          onAcceptConfirmationDialog: () => {
            this.props.onClose();
          },
        });
      } else {
        this.props.onClose();
      }
    },
    onCloseConfirmationDialog: ({ detail: { action } }) => {
      this.setState(
        {
          showConfirmationDialog: false,
        },
        () => {
          if (action === 'close') {
            requestAnimationFrame(() =>
              this.state.onAcceptConfirmationDialog()
            );
          }
        }
      );
    },
  };

  componentDidUpdate(prevProps) {
    const { error } = this.props;

    if (error && error !== prevProps.error) {
      // Ensure the error is visible
      this.errorRef.current.scrollIntoView();
    }
  }

  render() {
    const {
      mode,
      paymentChannel,
      submitting,
      error,
      loading,
      countries,
      breakpoint,
      downloadSampleCsv,
      downloadErrors,
      isBatchError,
      payeeCountryRules,
    } = this.props;

    const {
      type,
      showChallengeCode,
      showPaymentChannel,
      showConfirmationDialog,
      confirmationDialogMessage,
      masked,
      countryCode,
      fileSubmitted,
    } = this.state;

    const isCSVUpload = type === PayeeCreationTypes.CSV_UPLOAD;

    const stepProps = {
      mode,
      type,
      loading,
      paymentChannel,
      showChallengeCode,
      showPaymentChannel,
      countryCode,
      downloadSampleCsv,
    };

    const formProps = {
      onSubmit: this.handlers.onSubmitForm,
      onCancel: this.handlers.onCancel,
      onChange: this.handlers.onChange,
      submitting,
      countries,
      values: this.state,
      readOnlyValues: this.props.readOnlyValues,
      mode,
      breakpoint,
      masked,
      payeeCountryRules,
      dirty: this.hasChanged(),
    };

    const Stepper =
      mode === AddPayeeMode.PAYOR_CREATE || mode === AddPayeeMode.PAYOR_EDIT
        ? AppPayeeStepper
        : OnboardingPayeeStepper;

    const errorComponent = (
      <AddPayeeError
        errorRef={this.errorRef}
        error={error}
        mode={mode}
        showDownloadErrors={fileSubmitted && isBatchError}
        downloadErrors={downloadErrors}
      />
    );

    return (
      <>
        <Stepper>
          {/* Choose Payee Account or Creation type */}
          <TypeSelectionStep
            {...stepProps}
            onChange={this.handlers.onChangeType}
          />

          {/* Payee Forms Entry */}
          {!isCSVUpload && (
            <PayeeCreateForms
              mode={mode}
              {...stepProps}
              formProps={formProps}
              errorComponent={errorComponent}
            >
              {/* Optionally configure a custom challenge code. */}
              <ChallengeCodeStep
                {...stepProps}
                formProps={formProps}
                payeeCountryRules={payeeCountryRules}
                onChange={this.handlers.onToggleShowChallengeCode}
              />

              {/* Optionally confirm payment channel details */}
              <PaymentChannelStep
                {...stepProps}
                formProps={formProps}
                onChange={this.handlers.onToggleShowPaymentChannel}
              />
            </PayeeCreateForms>
          )}

          {/* Payee CVS Upload */}
          {isCSVUpload && (
            <>
              <PayeeUploadStep
                {...stepProps}
                onSubmit={this.handlers.onSubmit}
              />
              {errorComponent}
            </>
          )}
        </Stepper>

        {/* Submitting */}
        <VeloOverlay show={submitting} data-testid={TestIds.SKRIM} />

        {/* Confirmation dialog */}
        <ConfirmationDialog
          open={showConfirmationDialog}
          onClose={this.handlers.onCloseConfirmationDialog}
          dialogType={ConfirmationDialog.typeOf(
            'AddPayee',
            confirmationDialogMessage,
            <FormattedMessage defaultMessage="No" />,
            <FormattedMessage defaultMessage="Yes" />
          )}
          data-testid={TestIds.CONFIRMATION_DIALOG}
        />
      </>
    );
  }
}

/** Wrapper for Add Payee as a Card - used in Payor AddPayee **/
function CreatePayee({ onClose, ...props }) {
  return (
    <Content>
      <VeloCardPage
        onClose={onClose}
        title={<FormattedMessage defaultMessage="Create Payee" />}
      >
        <AddPayee onClose={onClose} {...props} />
      </VeloCardPage>
    </Content>
  );
}

function Processing(props) {
  return (
    <VeloProgressIndicator
      data-testid={TestIds.PROGRESS}
      {...props}
      title={
        <FormattedMessage defaultMessage="Processing Payees. Please wait..." />
      }
    />
  );
}

AddPayee.CreatePayee = CreatePayee;
AddPayee.Processing = Processing;

export { AddPayee };
