import React, { useEffect, useRef, useState } from 'react';
import { string, bool, node } from 'prop-types';
import { usePrevious } from 'react-use';
import styled from 'astroturf/react';
import { TextField } from '@rmwc/textfield';
import { VeloSkeleton } from '../VeloSkeleton';
import { mergeRefs } from '../utils';
import '@material/textfield/dist/mdc.textfield.css';
import '@material/floating-label/dist/mdc.floating-label.css';
import '@material/notched-outline/dist/mdc.notched-outline.css';

const StyledTextField = styled(TextField)`
  @import 'velo-variables';

  width: 100%;

  /* Hide the clear button on Chrome/Safari */
  :global(input[type='search']::-webkit-search-cancel-button) {
    display: none;
  }

  /* Apply DS token border */
  &:global(.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):not(.mdc-text-field--invalid)
      .mdc-notched-outline__leading),
  &:global(.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):not(.mdc-text-field--invalid)
      .mdc-notched-outline__notch),
  &:global(.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):not(.mdc-text-field--invalid)
      .mdc-notched-outline__trailing) {
    border-color: rgba(0, 0, 0, 0.23);
  }
  &:global(.mdc-text-field--outlined.mdc-text-field--disabled
      .mdc-notched-outline__leading),
  &:global(.mdc-text-field--outlined.mdc-text-field--disabled
      .mdc-notched-outline__notch),
  &:global(.mdc-text-field--outlined.mdc-text-field--disabled
      .mdc-notched-outline__trailing) {
    border-color: velo-color('token-color-border-disabled');
  }

  /* Fix floating label color */
  &:global(.mdc-text-field:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid)
      .mdc-floating-label) {
    color: velo-color('token-color-text-default');
  }
  /* Fix field text color */
  &:global(.mdc-text-field:not(.mdc-text-field--disabled)
      .mdc-text-field__input) {
    color: velo-color('token-color-text-default');
  }
  /* Fix disabled field label color */
  &:global(.mdc-text-field--disabled .mdc-floating-label) {
    color: velo-color('token-color-text-disabled');
  }
  /* Fix placeholder color */
  &:global(.mdc-text-field:not(.mdc-text-field--disabled)
      .mdc-text-field__input::placeholder) {
    color: velo-color('token-color-text-muted');
  }

  /* Fix disabled color */
  &:global(.mdc-text-field--outlined.mdc-text-field--disabled
      .mdc-text-field__input) {
    color: velo-color('token-color-text-disabled');
  }

  /* Ensure the correct font is used for the help text, label and input. */
  + :global(.mdc-text-field-helper-line > .mdc-text-field-helper-text),
  :global(.mdc-floating-label),
  :global(.mdc-text-field__input) {
    font-family: var(--ds-typography-font-family);
  }

  &:global(.mdc-text-field:not(.mdc-text-field--disabled)
      .mdc-text-field__icon) {
    color: velo-color('token-color-text-default') !important;
  }

  /* Fix the floating label colour. */
  /* https://github.com/material-components/material-components-web/issues/2718 */
  &:global(.mdc-text-field--focused:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid)
      .mdc-floating-label) {
    color: velo-color('token-color-brand-primary');
  }

  /* Fix the required asterisk colour. */
  /* https://github.com/material-components/material-components-web/issues/2718 */
  :global {
    .mdc-text-field__input:required ~ {
      .mdc-floating-label::after,
      .mdc-notched-outline .mdc-floating-label::after {
        color: velo-color('token-color-brand-primary');
      }
    }
  }

  /** Only show help text when invalid */
  &.dynamicHelpText {
    + :global(.mdc-text-field-helper-line) {
      display: none;
    }

    &:global(.mdc-text-field--invalid + .mdc-text-field-helper-line) {
      display: block;
    }
  }
`;

StyledTextField.propTypes = {
  dynamicHelpText: bool,
};

/**
 * This allows use to get a ref to the underlying MDC TextField
 * component. This can be used to disable native validation, etc.
 */
export const VeloTextField = React.forwardRef(
  (
    {
      label,
      placeholder,
      id,
      dynamicHelpText,
      'data-testid': testId,
      rootProps = {},
      onFocus = () => {},
      onBlur = () => {},
      value,
      ...other
    },
    ref
  ) => {
    const [hasFocus, setHasFocus] = useState(false);
    const previousValue = usePrevious(value);
    const textFieldRef = useRef(null);

    useEffect(() => {
      if (value && previousValue !== value && textFieldRef.current) {
        textFieldRef.current.foundation.setValue(value);
      }
    }, [value, previousValue]);

    return (
      <StyledTextField
        outlined
        label={label}
        placeholder={hasFocus || !label ? placeholder : ''}
        ref={mergeRefs([ref, textFieldRef])}
        id={id || testId}
        dynamicHelpText={dynamicHelpText}
        rootProps={{ ...rootProps, ripple: false }}
        data-testid={testId}
        value={value}
        {...other}
        onFocus={(event) => {
          onFocus(event);
          setHasFocus(true);
        }}
        onBlur={(event) => {
          onBlur(event);
          setHasFocus(false);
        }}
      />
    );
  }
);

VeloTextField.propTypes = {
  /** Custom CSS class(es). */
  className: string,
  /**
   * Require a label for accessibility.
   * Can use empty string if placeholder is preferred.
   */
  label: node.isRequired,
  /** ID for use in automation tests. */
  'data-testid': string.isRequired,
  /**
   * ID to ensure `aria-label` compatibility.
   * If this prop is not specified then `data-testid`
   * will be used.
   */
  id: string,
  /**
   * Enable dynamic helper text container height.
   *
   * When enabled the helper text will only take up height when there is
   * an helper text validation message.
   */
  dynamicHelpText: bool,
  /** Help text is always visible */
  staticHelpText: bool,
};

const VeloTextFieldSkeleton = styled(VeloSkeleton)`
  @import '@material/theme/variables';
  @import '@material/textfield/variables';
  height: $mdc-text-field-height;
`;

VeloTextField.Skeleton = () => <VeloTextFieldSkeleton />;
