const nullLoader = () => undefined;

const query = { sort: 'name:asc' };

export function TagSectionPresenter(wireframe, entity, props, config, intl) {
  const { entityId } = props;
  function loader(cb) {
    return entity.getTags(entityId, query, (error, data) => {
      if (error) return cb(error);
      cb(undefined, data.content);
    });
  }

  const sendNote = (note, data) =>
    wireframe.sendNote(
      { ...note, message: intl.formatMessage(note.message, data) },
      true
    );

  return [
    !!entityId ? loader : nullLoader,
    {
      onAdd: () => config.onAdd(wireframe, props),
      onRemove(tag) {
        return entity.removeTag(entityId, tag.tagId, (error) => {
          if (error) {
            return sendNote(config.removeFailure, tag);
          }
          sendNote(config.removeSuccess, tag);
        });
      },
    },
  ];
}
