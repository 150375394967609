import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { useIntl } from 'react-intl';
import { string, func, bool, arrayOf, number } from 'prop-types';
import styled from 'astroturf/react';
import { VeloMenu } from '../../VeloMenu';
import { VeloList } from '../../VeloList';
import { keyCodes, dataTestIdBuilder } from 'velo-data';
import classnames from 'classnames';
import styles from '../NavigationDrawerItem/NavigationDrawerItem.module.scss';

import { NavigationDrawerItem } from '../NavigationDrawerItem';
import { navigationDrawerConfig } from '../navigationDrawerConfig';
import navigationDrawerPropTypes from '../navigationDrawerPropTypes';
import { getDataTip } from '../helpers';

const FlyoutMenu = styled(VeloMenu)`
  @import 'velo-variables';
  @import '../navigationDrawer.variables.scss';

  width: $navigation-list-width;
  z-index: $velo-full-screen-index-default;

  :global(.mdc-list) {
    padding: 0;
  }
`;

const Anchor = styled(VeloMenu.SurfaceAnchor)`
  @import 'velo-variables';
  > div:first-child {
    &:focus {
      background-color: velo-color(
        'token-color-brand-primary-background'
      ) !important;
    }
    &:before,
    &:after {
      opacity: 0;
    }
  }
`;

const FlyoutMenuItem = styled(VeloMenu.Item)`
  @import 'velo-variables';
  font-size: 0.9rem;
  height: 38px;
  padding: 0.3rem 3rem;
  overflow: hidden;
  &.state-active {
    font-weight: bold;
    color: velo-color('token-color-text-default');
  }
`;

const FlyoutIndicator = styled(VeloList.ItemGraphic)`
  /* Required to ensure that the right arrow (which appears on hover
 for navigation items in collapsed mode which have sub menu items) does not
 jump around on hover */
  left: -20px;
`;

const root = `velo-navigation-drawer-flyout-menu`;
const TestIds = {
  FLYOUT: `${root}`,
  FLYOUT_ITEM: `${root}-item`,
  FLYOUT_PARENT_ITEM: `${root}-parent`,
};

NavigationDrawerFlyoutMenu.root = root;
NavigationDrawerFlyoutMenu.testIds = TestIds;

NavigationDrawerFlyoutMenu.propTypes = {
  /** The items to be rendered in the flyout menu */
  items: arrayOf(navigationDrawerPropTypes.navigationItem),
  /* The index of the item */
  index: number,
  /** Whether the navigation drawer is in mobile mode */
  isMobile: bool,
  /** The current item */
  item: navigationDrawerPropTypes.navigationItem,
  /** Whether the Navigation drawer is in an open state */
  isNavigationDrawerOpen: bool,
  /** Function to handle how the sub menu was opened */
  handleSubMenuOpenedBy: func,
  /** the notification for the menu item */
  notificationIcon: string,
  /** Custom className if required */
  className: string,
  /** The selected sub menu item Id */
  selectedSubMenuItemId: string,
  /** The selected sub menu item parent Id */
  selectedSubMenuParentId: string,
  /** The selected sub menu parent */
  selectedSubMenuParent: navigationDrawerPropTypes.subMenuItemParent,
  /** Whether a sub menu is open */
  isSubMenuOpen: bool,
  /** Function to handle when the flyout is clicked */
  onListAction: func,
};

function NavigationDrawerFlyoutMenu({
  items,
  isMobile,
  item,
  index,
  isNavigationDrawerOpen,
  notificationIcon,
  className,
  selectedSubMenuItemId,
  selectedSubMenuParentId,
  selectedSubMenuParent,
  selectedItem,
  subNavOpenId,
  onListAction,
  forceClose,
  hideTooltip,
  isSubMenuOpen,
}) {
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const icon = navigationDrawerConfig.NavigationItemIcons[item.id];

  // Ensure that when the state of the navigation drawer changes,
  // the flyout is closed.
  useLayoutEffect(() => {
    setOpen(false);
    return () => {
      setOpen(true);
    };
  }, [isNavigationDrawerOpen]);

  // Ensure that the sub menu is closed if the status of the sub menu changes.
  // i.e. when the window is scrolled.
  useLayoutEffect(() => {
    if (forceClose) {
      setOpen(false);
    }
  }, [forceClose]);

  // Whether the item is the current active item - can be either main menu or a submenu item
  const isActivated =
    selectedItem.parent === item.id && (!isNavigationDrawerOpen || isMobile);

  const label = getDataTip(intl, item.label);

  const ref = useRef(null);

  const [hoistToBody, setHoistToBody] = useState(false);

  useEffect(() => {
    const menuEl = ref.current.querySelector('.mdc-menu-surface');

    /* istanbul ignore next */
    if (menuEl) {
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (
            mutation.type === 'attributes' &&
            mutation.attributeName === 'class'
          ) {
            if (mutation.target.classList.contains('mdc-menu-surface--open')) {
              setHoistToBody(
                mutation.target.classList.contains('mdc-menu-surface--open')
              );

              Array.from(menuEl.querySelectorAll('.mdc-list-item')).forEach(
                (el, index) => {
                  el.onkeydown = (e) => {
                    if (e.keyCode === keyCodes.ENTER) {
                      onListAction(item.subMenuItems[index].id, true);
                      setOpen(false);
                    }
                  };

                  el.onclick = () => {
                    onListAction(item.subMenuItems[index].id, true);
                    setOpen(false);
                  };
                }
              );
            }
          }
        });
      });

      observer.observe(menuEl, {
        attributes: true,
      });

      return () => {
        observer.disconnect();
      };
    }
  });

  return (
    <div ref={ref}>
      {items && !isNavigationDrawerOpen ? (
        <Anchor>
          <FlyoutMenu
            open={open}
            onSelect={(evt) => {
              onListAction(item.subMenuItems[evt.detail.index].id, true);
            }}
            onClose={(evt) => setOpen(false)}
            anchorCorner="topRight"
            hoistToBody={hoistToBody}
            data-testid={dataTestIdBuilder(TestIds.FLYOUT, label)}
          >
            {items.map((subitem) => {
              const subItemSelected = selectedSubMenuItemId
                ? selectedSubMenuItemId === subitem.id
                : false;

              return (
                <FlyoutMenuItem
                  ripple={false}
                  onClick={(evt) => {}}
                  key={'flyout-item-' + subitem.id}
                  state={subItemSelected ? 'active' : undefined}
                  data-testid={dataTestIdBuilder(
                    TestIds.FLYOUT_ITEM,
                    subitem.id
                  )}
                  className={classnames(styles.flyoutMenu)}
                >
                  {subitem.label}
                </FlyoutMenuItem>
              );
            })}
          </FlyoutMenu>

          <VeloList.Item
            index={index}
            onClick={(evt) => {
              setOpen(!open);
              hideTooltip(true);
            }}
            onKeyDown={(evt) => {
              if (evt.keyCode === keyCodes.ENTER) setOpen(!open);
            }}
            activated={isActivated}
            className={classnames(
              styles.item,
              styles.collapsed,
              styles.parent,

              {
                [styles.noicon]: !icon,
                [styles.legalLinksPrimaryItem]: item.legalLinksPrimaryItem,
              }
            )}
            data-nav-id={item.id}
            data-tip={!open ? label : undefined}
            ripple={false}
            data-testid={dataTestIdBuilder(
              TestIds.FLYOUT_PARENT_ITEM,
              label,
              'parent'
            )}
          >
            {icon && (
              <VeloList.ItemGraphic
                data-testid={dataTestIdBuilder(root, label, 'icon')}
                className={styles[item.id]}
                icon={{
                  ...icon,
                  tabIndex: -1,
                  focusable: false,
                }}
              />
            )}
            {notificationIcon && (
              <span
                data-testid={dataTestIdBuilder(
                  root,
                  label,
                  `${notificationIcon}-indicator`
                )}
                className={classnames(
                  styles.indicator,
                  styles[notificationIcon]
                )}
              />
            )}
            <FlyoutIndicator
              data-testid={dataTestIdBuilder(root, label, 'more')}
              className={classnames(styles.rightSideIcon, styles.more, {
                [styles.collapsed]: !isNavigationDrawerOpen,
              })}
              icon={{
                icon: 'keyboard_arrow_right',
                tabIndex: -1,
                focusable: false,
              }}
            />
            <span className={styles.fadeoutOnCollapse}>{item.label}</span>
          </VeloList.Item>
        </Anchor>
      ) : (
        <NavigationDrawerItem
          index={index}
          item={item}
          isNavigationDrawerOpen={isNavigationDrawerOpen}
          notificationIcon={notificationIcon}
          className={className}
          isMobile={isMobile}
          selectedSubMenuItemId={selectedSubMenuItemId}
          selectedSubMenuParentId={selectedSubMenuParentId}
          isSelectedItem={
            !item.legalLinksPrimaryItem &&
            (item.id === selectedItem.id ||
              (!isSubMenuOpen && selectedItem.parent === item.id))
          }
          selectedSubMenuParent={selectedSubMenuParent}
          subNavOpenId={subNavOpenId}
          hideTooltip={hideTooltip}
        />
      )}
    </div>
  );
}

export { NavigationDrawerFlyoutMenu };
