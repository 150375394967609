import React from 'react';
import styled from 'astroturf/react';

/**
 * Loading IO CSS Spinner
 *
 * https://github.com/loadingio/css-spinner/blob/master/dist/spinner.html
 *
 * NOTE: This spinner component has a fixed size.
 * TODO: Programmatically render the spinner and support resizing all steps in the progress indicator.
 */
const SpinnerContainer = styled('div')`
  @import 'velo-variables';

  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;

  @keyframes lds-spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  div {
    transform-origin: 40px 40px;
    animation: lds-spinner 1.2s linear infinite;
  }

  div:after {
    content: ' ';
    display: block;
    position: absolute;
    top: 3.2px;
    left: 36.8px;
    width: 6.4px;
    height: 17.6px;
    border-radius: 20%;
    background: velo-color('token-color-brand-primary');
  }

  div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
  }

  div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
  }

  div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
  }

  div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
  }

  div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
  }

  div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
  }

  div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
  }

  div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
  }

  div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
  }
  div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
  }
  div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
  }
  div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
  }
`;

function VeloSpinner() {
  return (
    <SpinnerContainer>
      {[
        ...new Array(12)
          .fill()
          .map((_, i) => <div key={`velo-spinner-step-${i}`} />),
      ]}
    </SpinnerContainer>
  );
}

export { VeloSpinner };
