import React from 'react';
import styled from 'astroturf/react';
import { bool, string, func, node } from 'prop-types';
import { VeloTopAppBar } from '../../VeloTopAppBar';
import { VeloIcon } from '../../VeloIcon';
import { navigationDrawerConfig } from '../navigationDrawerConfig';
import { NavigationDrawerNestedMenuItem } from './NavigationDrawerNestedMenuItem';
import navigationDrawerPropTypes from '../navigationDrawerPropTypes';

const TitleIcon = styled(VeloTopAppBar.NavigationIcon)`
  padding-left: 0;
  padding-right: 0;
  width: 35px;

  &:hover {
    cursor: pointer;
    &:before,
    &:after {
      background-color: transparent;
    }
  }
`;

const MobileMenuWrapper = styled('div')`
  @import 'velo-variables';
  position: absolute;
  background-color: velo-color('token-color-system-page-background') !important;
  height: 100%;
  width: 100%;
  z-index: $velo-over-all-default;

  transform: translateX(100%);
  &.open {
    transform: translateX(0%);
  }
  transition: transform 250ms ease-in-out;
`;

const root = 'velo-mobile-nested-submenu';
const TestIds = {
  BACK_ICON: `${root}-back-icon`,
  TITLE_ICON: `${root}-title-icon`,
};

NavigationDrawerMobileNestedMenu.testIds = TestIds;

MobileMenuWrapper.propTypes = {
  open: bool,
};

NavigationDrawerMobileNestedMenu.propTypes = {
  /** Is the Navigation Drawer open. */
  isSubMenuOpen: bool,
  /** the function to handle a list action. */
  onListAction: func,
  /** The Id of the selected sub menu item */
  selectedSubMenuItemId: string,
  /** The selected sub menu parent object */
  selectedSubMenuParent: navigationDrawerPropTypes.subMenuItemParent,
  /** The function to handle when the sub menu is closed. */
  onSubMenuClose: func,
  /** The title of the selected page. */
  title: node,
};

function NavigationDrawerMobileNestedMenu({
  isSubMenuOpen,
  onListAction,
  selectedSubMenuItemId,
  selectedSubMenuParent,
  onSubMenuClose,
  title,
}) {
  const parentIcon =
    selectedSubMenuParent &&
    navigationDrawerConfig.NavigationItemIcons[selectedSubMenuParent.id];

  return (
    <MobileMenuWrapper open={isSubMenuOpen} data-testid={root}>
      {isSubMenuOpen && (
        <>
          {selectedSubMenuParent && (
            <VeloTopAppBar.Row>
              <VeloTopAppBar.Section>
                <VeloTopAppBar.NavigationIcon
                  icon={{
                    strategy: 'component',
                    icon: (
                      <VeloIcon
                        icon="chevron_left"
                        title={title}
                        onClick={() => onSubMenuClose(true)}
                        data-testid={TestIds.BACK_ICON}
                      />
                    ),
                  }}
                />

                {parentIcon && (
                  <TitleIcon
                    icon={{
                      tabIndex: -1,
                      strategy: 'component',
                      icon: (
                        <VeloIcon
                          {...parentIcon}
                          data-testid={TestIds.TITLE_ICON}
                          title={`${title} icon`}
                        />
                      ),
                    }}
                  />
                )}
                {title}
              </VeloTopAppBar.Section>
            </VeloTopAppBar.Row>
          )}
          {selectedSubMenuParent.subMenuItems.map((subItem) => (
            <NavigationDrawerNestedMenuItem
              key={subItem.id}
              isMobile={true}
              subItem={subItem}
              onSubItemSelect={onListAction}
              isSelectedItem={subItem.id === selectedSubMenuItemId}
            />
          ))}
        </>
      )}
    </MobileMenuWrapper>
  );
}

export { NavigationDrawerMobileNestedMenu };
