export function v4CreatePayee(send) {
  return (body, contentType = 'application/json', ...args) =>
    send(
      {
        path: '/v4/payees',
        pathParameters: {},
        type: 'post',
        query: undefined,
        body: body,
        headers: undefined,
        auth: 'oauth2',
        contentType: contentType,
      },
      ...args
    );
}
