import React from 'react';
import { VeloGrid } from '../VeloGrid';
import { VeloSkeleton } from '../VeloSkeleton';
import styled from 'astroturf/react';

const cellProps = { desktop: 12, tablet: 8, phone: 4 };

const LoadingGrid = styled(VeloGrid)`
  padding: 0;
`;

const HeaderSkeleton = styled(VeloSkeleton)`
  width: 10rem;
  height: 2rem;
`;

const FieldSkeleton = styled(VeloSkeleton)`
  @import '@material/theme/mixins';
  @import '@material/textfield/variables';

  $mdc-text-field-helper-text-height: 19px;
  height: $mdc-text-field-height;
  margin-bottom: $mdc-text-field-helper-text-height;
`;

/**
 * Loading skeleton used to represent a field grid.
 * Used by the Add Payee form.
 */
function Loading() {
  return (
    <LoadingGrid>
      {/* Heading */}
      <VeloGrid.Cell {...cellProps}>
        <HeaderSkeleton />
      </VeloGrid.Cell>

      {/* Fields */}
      {[...Array(4).keys()].map((index) => (
        <VeloGrid.Cell key={index} {...cellProps} desktop={6}>
          <FieldSkeleton />
        </VeloGrid.Cell>
      ))}
    </LoadingGrid>
  );
}

export { Loading };
