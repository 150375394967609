import React from 'react';
import { func, node, string, bool } from 'prop-types';
import styled from 'astroturf/react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

const Loading = styled('div')`
  @import 'velo-mixins';
  @include skeleton-background();
  height: 56px;
  width: 100%;
`;

VeloSelect.propTypes = {
  /***
   * label is always required to ensure that the
   * select is accessible
   */
  label: node.isRequired,
  /** ID for use in automation tests. */
  'data-testid': string.isRequired,
  /** Use a non-native menu instead of a native select. */
  enhanced: bool,
  /** The start icon to render */
  icon: node,
  /** Used to override the rendered value when an option is selected. */
  renderValue: func,
};

VeloSelect.Loading = Loading;

function getHelpText(helpText, focus) {
  if (!helpText) {
    return undefined;
  }
  if (typeof helpText === 'object' && !React.isValidElement(helpText)) {
    if (helpText.validationMsg) {
      return ' ';
    }
    return focus ? helpText.children : ' ';
  }
  return focus ? helpText : ' ';
}

/**
 * Select (drop-down) component. Ensures correct style
 * is applied and adds visual support for the required
 * attribute.
 */
export function VeloSelect({
  id,
  'data-testid': testId,
  'aria-label': ariaLabel,
  options,
  helpText,
  value,
  enhanced = false,
  icon,
  renderValue,
  children,
  onFocus,
  onBlur,
  ...other
}) {
  const [hasFocus, setHasFocus] = React.useState(false);

  const helperText = getHelpText(helpText, hasFocus);

  return (
    <TextField
      id={id || testId}
      select
      SelectProps={{
        native: enhanced ? false : true,
        renderValue,
      }}
      inputProps={{ 'data-testid': testId, 'aria-label': ariaLabel }}
      fullWidth
      helperText={helperText}
      {...(icon && {
        InputProps: {
          startAdornment: (
            <InputAdornment position="start">{icon}</InputAdornment>
          ),
        },
      })}
      onFocus={(event) => {
        setHasFocus(true);
        if (onFocus) {
          onFocus(event);
        }
      }}
      onBlur={(event) => {
        setHasFocus(false);
        if (onBlur) {
          onBlur(event);
        }
      }}
      value={value || ''}
      {...other}
    >
      {enhanced === false && !value && <option value="" disabled />}

      {options &&
        options.map((option, index) => {
          // RMWC supports an array of string or objects
          if (typeof option === 'string') {
            return (
              <option key={index} value={option}>
                {option}
              </option>
            );
          }
          return (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          );
        })}

      {children}
    </TextField>
  );
}
