import React, { useState } from 'react';
import { VeloSectionGrid } from '../VeloSectionGrid';
import { VeloChip } from '../VeloChip';
import { VeloButton } from '../VeloButton';
import { VeloGridLoading } from '../VeloGridLoading';
import { VeloBookended } from '../VeloBookended';
import { func, arrayOf, shape, string, node } from 'prop-types';
import { VeloCardForm } from '../VeloCardForm/VeloCardForm';
import { VeloBackground } from '../VeloBackground';
import { FormattedMessage } from 'react-intl';

const root = 'velo-tags-section-view';

const TestIds = {
  ADD_BUTTON: `${root}-add`,
  CHIP_CLOSE: (id) => `${root}-tag-close-${id}`,
};

function tagsToChips(tags, onRemoveTag) {
  return tags.map(({ name, tagId }) => (
    <VeloChip
      id={tagId}
      label={name}
      onRemove={onRemoveTag}
      trailingIcon={{
        icon: 'close',
        'data-testid': TestIds.CHIP_CLOSE(tagId),
      }}
      key={tagId}
    />
  ));
}

const headingProps = { overline: true };

const render = ({ children }) => children;

function Heading({ onClick }) {
  return (
    <VeloBookended justify>
      <VeloButton onClick={onClick} data-testid={TestIds.ADD_BUTTON}>
        <FormattedMessage defaultMessage="Add tag" />
      </VeloButton>
      <span>
        <FormattedMessage defaultMessage="Tags" />
      </span>
    </VeloBookended>
  );
}

function selectTagById(data, id) {
  return data.find((tag) => id === tag.tagId);
}

TagSection.propTypes = {
  /**
   * When a request to add a tag is triggered
   */
  onAdd: func.isRequired,
  /**
   * When a request to remove a tag is triggered
   */
  onRemove: func.isRequired,
  /**
   * An array of tags
   */
  data: arrayOf(
    shape({
      tagId: string.isRequired,
      name: string.isRequired,
    })
  ).isRequired,
};

function TagSection({ data, onAdd, onRemove }) {
  const [renderKey, setRenderKey] = useState(0);
  const onRemoveHandler = (event) =>
    onRemove(
      selectTagById(data, event.detail.chipId),
      /**
       * if there is an error, we need to force the chips to re-render so that
       * chips that have been hidden (by animation) are now restored
       */
      (error) => error && setRenderKey((n) => n + 1)
    );
  return (
    <VeloSectionGrid
      compact
      render={render}
      headingProps={headingProps}
      sections={[
        {
          heading: <Heading onClick={onAdd} />,
          fields: [
            {
              children: (
                <VeloChip.Set slimline key={renderKey.toString(10)}>
                  {tagsToChips(data, onRemoveHandler)}
                </VeloChip.Set>
              ),
            },
          ],
        },
      ]}
    />
  );
}

const loadingSections = [
  {
    heading: true,
    fields: [
      {
        type: VeloGridLoading.fieldTypes.TextField,
      },
    ],
  },
];

function Loading() {
  return (
    <VeloGridLoading
      headingProps={headingProps}
      sections={loadingSections}
      compact
    />
  );
}

ErrorView.propTypes = {
  /**
   * When a request to add a tag is triggered
   */
  onAdd: func.isRequired,
  /**
   * An error
   */
  error: node.isRequired,
};

function ErrorView({ error, onAdd }) {
  return (
    <VeloSectionGrid
      compact
      render={render}
      headingProps={headingProps}
      sections={[
        {
          heading: <Heading onClick={onAdd} />,
          fields: [
            {
              children: (
                <VeloBackground rounded status={VeloBackground.status.ERROR}>
                  <VeloCardForm.ErrorMessage>{error}</VeloCardForm.ErrorMessage>
                </VeloBackground>
              ),
            },
          ],
        },
      ]}
    />
  );
}

ErrorView.propTypes = {
  /**
   * When a request to add a tag is triggered
   */
  onAdd: func.isRequired,
};

function Empty({ onAdd }) {
  return (
    <VeloSectionGrid
      compact
      render={render}
      headingProps={headingProps}
      sections={[
        {
          heading: <Heading onClick={onAdd} />,
          fields: [],
        },
      ]}
    />
  );
}

TagSection.testIds = TestIds;
TagSection.Loading = Loading;
TagSection.Error = ErrorView;
TagSection.Empty = Empty;

export { TagSection };
