import { useContext } from 'react';
import { Context } from '../../context';
import { useLoadingEffectFunction } from '../helpers';

function usePayorBrandingAPI(payorId, guard) {
  const { api } = useContext(Context);

  const [data, getPayorBranding] = useLoadingEffectFunction(
    { fn: api.getPayorBranding, guard },
    payorId
  );

  return [data, { getPayorBranding }];
}

export { usePayorBrandingAPI };
