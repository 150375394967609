import React, { useState, useMemo } from 'react';
import styled from 'astroturf/react';
import { useIntl, FormattedMessage } from 'react-intl';
import { VeloGridLoading } from '../VeloGridLoading';
import { VeloModalSheetCardContent } from '../VeloModalSheetCardContent';
import { VeloSkeleton } from '../VeloSkeleton';
import { VeloSectionGrid } from '../VeloSectionGrid';
import { VeloLabelledItem } from '../VeloLabelledItem';
import { ConfirmationDialog } from '../ConfirmationDialog';
import { DisclosureMessage } from '../DisclosureMessage';
import { UserRoles } from '../UserRoles';
import { PaymentChannelDisabled } from '../PaymentChannelView/PaymentChannelDisabled';
import { func, shape, string } from 'prop-types';
import { VeloPropTypes } from '../VeloPropTypes';
import { Country } from '../Country';
import { VeloButton } from '../VeloButton';

const root = 'view-payment-channel';

const detailsRoot = 'velo-payment-channel-details';

const TestIds = {
  DELETE: `${root}-delete`,
  EDIT: VeloModalSheetCardContent.testIds.EDIT,
  CLOSE: `payment-channel-view-close-button`,
  CONTAINER: `${root}-container`,
  COUNTRY: `${detailsRoot}-country`,
  CURRENCY: `${detailsRoot}-currency`,
  DELETE_DIALOG: `${root}-delete-dialog`,
  CANNOT_DELETE_DIALOG: `${root}-cannot-delete-dialog`,
  PAYOR: `${root}-payor`,
};

const Skeleton = styled(VeloSkeleton)`
  width: 40%;
`;

const Label = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

function LoadingView({ onClose }) {
  const fields = (count) =>
    [...Array(count).keys()].map(() => ({
      type: VeloGridLoading.fieldTypes.LabelledItem,
    }));

  return (
    <VeloModalSheetCardContent title={<Skeleton />} onClose={onClose}>
      <VeloGridLoading
        compact
        sections={[
          {
            heading: true,
            fields: fields(4),
          },
          {
            heading: true,
            fields: fields(3),
          },
        ]}
      />
    </VeloModalSheetCardContent>
  );
}

const getGridProps = (
  { data, paymentChannelRules, onEnable, role, onEdit },
  intl
) => ({
  compact: true,
  sections: [
    ...(data.enabled
      ? []
      : [
          {
            fields: [
              {
                Component: PaymentChannelDisabled,
                disabledReason: data.disabledReason,
                onEnable,
              },
            ],
          },
        ]),
    {
      heading: (
        <Label>
          <FormattedMessage defaultMessage="Bank account details" />
          {onEdit && (
            <VeloButton onClick={onEdit} data-testid={TestIds.EDIT}>
              <FormattedMessage defaultMessage="Edit" />
            </VeloButton>
          )}
        </Label>
      ),
      onEdit,
      fields: [
        {
          label: <FormattedMessage defaultMessage="Country" />,
          children: <Country country={data.countryCode} />,
          testId: TestIds.COUNTRY,
          Component: VeloLabelledItem,
        },
        {
          label: <FormattedMessage defaultMessage="Currency" />,
          children: data.currency,
          testId: TestIds.CURRENCY,
          Component: VeloLabelledItem,
        },
        ...paymentChannelRules.reduce((xs, rule) => {
          return xs.concat({
            label: rule.displayName,
            children: data[rule.element],
            testId: `${detailsRoot}-${rule.element}`,
            Component: VeloLabelledItem,
          });
        }, []),
        {
          Component: () =>
            UserRoles.isPayee(role) && <DisclosureMessage divider />,
        },
      ],
    },
    ...(data.payee && UserRoles.isBackOffice(role)
      ? [
          {
            heading: <FormattedMessage defaultMessage="Payee" />,
            fields: [
              {
                label: <FormattedMessage defaultMessage="Name" />,
                children: data.payee.displayName,
                Component: VeloLabelledItem,
              },
            ],
          },
        ]
      : []),
  ],
  render: ({ Component, ...props }) => <Component {...props} />,
  headingProps: { overline: true, tail: true },
});

function getCardProps({ data, onClose, onDelete }, { disabled }, intl) {
  const buttons = [];

  if (onDelete) {
    buttons.push({
      icon: 'delete',
      onClick: () => onDelete(data),
      title: intl.formatMessage({ defaultMessage: 'Delete payment method' }),
      'data-testid': TestIds.DELETE,
    });
  }

  return {
    title: data.paymentChannelName,
    onClose,
    buttons,
    disabled,
  };
}

function getDialogType(data, intl) {
  if (!data.isOnlyPaymentMethodForPayee) {
    const { body, ...rest } =
      ConfirmationDialog.dialogTypes.DeletePaymentChannel;

    return [
      TestIds.DELETE_DIALOG,
      {
        ...rest,
        body: intl.formatMessage(body, { name: data.paymentChannelName }),
      },
    ];
  }

  return [
    TestIds.CANNOT_DELETE_DIALOG,
    ConfirmationDialog.dialogTypes.UnableToDeletePaymentChannelType,
  ];
}

function usePaymentMethodView({
  onDelete,
  onEdit,
  onEnable,
  data,
  role,
  ...props
}) {
  const [state, setState] = useState({ disabled: false, dialogOpen: false });
  const intl = useIntl();

  const handlers = useMemo(() => {
    return {
      onEdit: !onEdit ? undefined : () => onEdit(data),
      onDelete: !onDelete
        ? undefined
        : () => setState({ dialogOpen: true, disabled: false }),
      onEnable: !onEnable
        ? undefined
        : (event) => {
            event.preventDefault();
            setState({ disabled: true, dialogOpen: false });
            onEnable(data, () => {
              setState({ disabled: false, dialogOpen: false });
            });
          },
      role,
    };
  }, [onDelete, onEdit, onEnable, setState, data, role]);

  const dialogProps = useMemo(() => {
    const [testId, dialogType] = getDialogType(data, intl);
    return {
      'data-testid': testId,
      dialogType,
      onClose: (evt) => {
        const disabled =
          evt.detail.action === 'accept' && testId === TestIds.DELETE_DIALOG;
        setState({ dialogOpen: false, disabled });
        if (disabled) {
          onDelete(data, () =>
            setState({ disabled: false, dialogOpen: false })
          );
        }
      },
    };
  }, [setState, onDelete, data, intl]);

  const finalProps = { data, ...props, ...handlers };
  return [
    getCardProps(finalProps, state, intl),
    getGridProps(finalProps, intl),
    {
      open: state.dialogOpen,
      ...dialogProps,
    },
  ];
}

PaymentMethodView.propTypes = {
  data: shape({
    /** The name of the payment method */
    paymentChannelName: string.isRequired,
    /** The ISO code for the country */
    countryCode: string.isRequired,
    /** The currency of the method */
    currency: string.isRequired,
  }).isRequired,
  /** The list of appropriate payment channel rules */
  paymentChannelRules: VeloPropTypes.paymentChannelRulesListType(),
  /** Handler called when close is actioned */
  onClose: func.isRequired,
  /** Optional handler for the delete button. If omitted, no button shown */
  onDelete: func,
  /** Optional handler for the edit button. If omitted, no FAB shown */
  onEdit: func,
  /** Optional handler for the enable action link. If omitted, no link shown */
  onEnable: func,
};

PaymentMethodView.testIds = TestIds;

PaymentMethodView.Loading = LoadingView;
PaymentMethodView.Error = VeloModalSheetCardContent.Error;

export function PaymentMethodView(props) {
  const [cardProps, gridProps, dialogProps] = usePaymentMethodView(props);

  return (
    <VeloModalSheetCardContent {...cardProps}>
      <VeloSectionGrid {...gridProps} />
      <ConfirmationDialog {...dialogProps} />
    </VeloModalSheetCardContent>
  );
}
