import { useCallback, useEffect, useState, useRef } from 'react';

export const emptyResult = { error: undefined, result: undefined };

/**
 * Maps a function that returns a Promise to a function taking the same
 * arguments with the additional last parameter as an error first callback.
 * This callback is called with `(rejectedValue, resolvedValue)` from the
 * promise returned from the passed function.
 * @param {function(...any):Promise<T>} fn A function that returns a promise
 * that resolves with error / result response values.
 * @return {function(...any, function(any, T):void):Promise<T>} A new function
 * where the last parameter is an error first callback `(error, result)`.
 *
 * @example
 *
 * const onSubmitHandler = mapPromiseResponseWithCallback(api.enhancedKycUpdate)
 *
 * onSubmitHandler("payeeId", true, (error, data) => {})
 * @template T
 */
export function mapPromiseResponseWithCallback(fn) {
  return (...args) => {
    const sansCallback = args.slice(0, -1);
    const callback = args[args.length - 1];
    return fn(...sansCallback).then((x) => callback(undefined, x), callback);
  };
}

const noop = () => undefined;
const True = () => true;

/**
 * @typedef {object} LoadSpec
 * @property {function(...any):Promise<T>} fn A function returning a promise.
 * @property {function(...any):boolean} guard A function returning a boolean
 * that indicates if the loading function should be called.
 * @template T
 * A hook that triggers the passed function when the given arguments change,
 * making use of the `useEffect` hook.
 * The hook returns a tuple of a result object (`{result, error}`) and the
 * wrapped loading function, which can be used to trigger a manual reload of
 * data (given if the arguments didn't change).
 * The given loading function must return a promise, the resolve will be set as
 * the result, and the reject will be set as the error.
 * @param {LoadSpec<T>} loadSpec A specification for the load operation.
 * @param {...any} fnArgs Variable number of arguments to pass to the function.
 * @return {[{result:T}, function(function(any, T):void=, Boolean=true)]} The return
 * function can take two optional arguments, the first is an error first
 * callback with the result/error of the operation. The second is a boolean flag
 * to indicate if the loading state should be cleared prior to refreshing the
 * data.
 * @template T
 */
export function useLoadingEffectFunction(loadSpec, ...fnArgs) {
  const args = [...fnArgs];
  const mounted = useRef(false);
  const [loadingResult, setLoadingResult] = useState(emptyResult);
  const load = useCallback((cb = noop, clearState = true) => {
    const { fn, guard = True } = loadSpec;
    const setResult = (res) => {
      if (mounted.current) {
        setLoadingResult(res);
      }
    };
    if (!guard(...args)) return;
    clearState && setResult(emptyResult);
    fn(...args)
      .then(
        (result) => setResult({ result, error: undefined }),
        (error) => setResult({ error, result: undefined })
      )
      .then((x) => cb(undefined, x), cb);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, args);

  useEffect(() => {
    mounted.current = true;
    load();
    return () => {
      mounted.current = false;
    };
  }, [load]);

  return [loadingResult, load];
}
