const PaymentStates = {
  AWAITING_FUNDS: 'AWAITING_FUNDS',
  AWAITING_AUTHORIZATION: 'AWAITING_AUTHORIZATION',
  ACCEPTED: 'ACCEPTED',
  FUNDED: 'FUNDED',
  UNFUNDED: 'UNFUNDED',
  BANK_PAYMENT_REQUESTED: 'BANK_PAYMENT_REQUESTED',
  REJECTED: 'REJECTED',
  WITHDRAWING: 'WITHDRAWING',
  WITHDRAWN: 'WITHDRAWN',
  ACCEPTED_BY_RAILS: 'ACCEPTED_BY_RAILS',
  CONFIRMED: 'CONFIRMED',
  RETURNED: 'RETURNED',
  CANCELLED: 'CANCELLED',
};

//its easier to say which don't than do require the icon
const PaymentStatesDoesNotRequireScheduledIcon = [
  PaymentStates.CANCELLED,
  PaymentStates.RETURNED,
  PaymentStates.WITHDRAWN,
  PaymentStates.WITHDRAWING,
  PaymentStates.REJECTED,
];

export { PaymentStates, PaymentStatesDoesNotRequireScheduledIcon };
