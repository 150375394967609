import React from 'react';
import { bool, func } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { ConfirmationDialog } from '../ConfirmationDialog';

const root = 'velo-payment-channel';

const TestIds = {
  CANNOT_DELETE_DIALOG: `${root}-cannot-delete-dialog`,
};

/**
 * Payment channel cannot delete confirmation popup.
 */
class PaymentChannelViewCannotDeleteConfirmation extends React.Component {
  static propTypes = {
    /** Used to open or close the dialog. */
    open: bool,
    /** Called when the dialog is closed. */
    onClose: func.isRequired,
    /** Called when the dialog is clicked  */
    onClick: func.isRequired,
  };

  static testIds = TestIds;

  render() {
    const { open, onClose, onClick } = this.props;

    const UnableToDeletePaymentChannelType = ConfirmationDialog.typeOf(
      'UnableToDeletePaymentChannelType',
      <FormattedMessage defaultMessage="Delete Payment Method" />,
      <FormattedMessage defaultMessage="Okay" />,
      null,
      <>
        <FormattedMessage
          tagName="div"
          defaultMessage="You cannot delete this payment method as there are Payors assigned."
        />
        <br />
        <FormattedMessage
          tagName="div"
          defaultMessage="If you want to delete this payment method please assign these Payors to a different payment method."
        />
      </>
    );

    return (
      <ConfirmationDialog
        open={open}
        onClose={onClose}
        data-testid={TestIds.CANNOT_DELETE_DIALOG}
        ref={this.ref}
        onClick={onClick}
        dialogType={UnableToDeletePaymentChannelType}
      />
    );
  }
}

export { PaymentChannelViewCannotDeleteConfirmation };
