import React, { useMemo, useState, useEffect, useRef } from 'react';
import { shape, number, string, oneOf } from 'prop-types';
import styled from 'astroturf/react';
import { FormattedMessage } from 'react-intl';
import { VeloDialog } from '../VeloDialog';
import { VeloTypography } from '../VeloTypography';
import { VeloCurrency } from '../VeloCurrency';
import { VeloGridLoading } from '../VeloGridLoading';
import { PayorAccountType } from 'velo-data';
import { VeloLabelledItem } from '../VeloLabelledItem';
import { FXRate } from '../PaymentView/BusinessPaymentView/FXRate/FXRate';
import { VeloSectionGrid } from '../VeloSectionGrid';
import { ConfirmQuoteCallout } from './ConfirmQuoteCallout';

/**
 * Render an amount using suitable locale currency formatting.
 * Note that the `money` prop is a `js-money` object.
 *
 * The children-as-a-function pattern allows us to
 * render a custom tag allowing a `data-testid` prop to
 * be passed.
 */
const Amount = ({ money, ...other }) => (
  <VeloCurrency value={money.getAmount()} currency={money.getCurrency()}>
    {(value) => (
      <VeloTypography use="bodyTextEmphasised" {...other}>
        {value}
      </VeloTypography>
    )}
  </VeloCurrency>
);

/**
 * Render a block of text using the correct theme props.
 */
const Text = (props) => <VeloTypography use="bodyText" tag="p" {...props} />;

const root = 'confirm-funds';

const TestIds = {
  DIALOG: `${root}-dialog`,
  TITLE: `${root}-title`,
  ADD_FUNDS: `${root}-add-funds`,
  CANCEL: `${root}-cancel`,
};

const Dialog = styled(VeloDialog).attrs({
  'data-testid': TestIds.DIALOG,
})`
  @import 'velo-variables';

  :global(.mdc-dialog__surface) {
    @media (min-width: velo-breakpoint(XS)) {
      min-width: 600px;
    }
  }
`;

const Error = styled('span')`
  @import 'velo-variables';

  color: velo-color('token-color-system-error-default');
`;

const ReQuoteRequired = () => (
  <Error>
    <FormattedMessage defaultMessage="Re-quote required" />
  </Error>
);

ConfirmFunds.Dialog = Dialog;

ConfirmFunds.propTypes = {
  sourceAccount: shape({
    /**
     * The type of the source account
     */
    type: oneOf(Object.values(PayorAccountType)).isRequired,
    /** The currency code ("USD", "GBP", etc.). */
    currency: string.isRequired,
    /** The current source account balance (in minor units). */
    balance: number,
  }),
  fundingAccount: shape({
    /** The currency code ("USD", "GBP", etc.). */
    currency: string.isRequired,
  }),
  /** The amount of funds being requested (in minor units). */
  amount: number.isRequired,
  quote: shape({
    /** The currency code ("USD", "GBP", etc.). */
    sourceCurrency: string.isRequired,
    /** computed total source amount */
    totalSourceAmount: number.isRequired,
    /** The currency code ("USD", "GBP", etc.). */
    paymentCurrency: string.isRequired,
    /**
     * The FX rate for this quote
     */
    rate: number.isRequired,
    /**
     * The reference identifier for this quote
     */
    fxSummaryId: string.isRequired,
    /**
     * The expiry time as an ISO date string
     */
    expiryTime: string.isRequired,
  }),
};

ConfirmFunds.testIds = TestIds;

function DialogLayout({ children, button }) {
  return (
    <>
      {/* Title */}
      <VeloDialog.Title data-testid={TestIds.TITLE}>
        <VeloTypography use="secondaryHeader">
          <FormattedMessage defaultMessage="Confirm addition of funds" />
        </VeloTypography>
      </VeloDialog.Title>

      {/* Content */}
      <VeloDialog.Content>{children}</VeloDialog.Content>
      {/* Action buttons */}
      <VeloDialog.Actions>
        {button}
        <VeloDialog.Button
          type="button"
          action="close"
          data-testid={TestIds.CANCEL}
        >
          <FormattedMessage defaultMessage="Cancel" />
        </VeloDialog.Button>
      </VeloDialog.Actions>
    </>
  );
}

const ButtonType = {
  CONFIRM: 'confirm',
  QUOTING: 'quoting',
  REQUOTE: 'requote',
};

const buttonsByType = {
  [ButtonType.CONFIRM]: ({ quote }) => (
    <VeloDialog.Button
      type="button"
      action={`accept${quote ? ':' + quote.fxSummaryId : ''}`}
      isDefaultAction
      data-testid={TestIds.ADD_FUNDS}
    >
      <FormattedMessage defaultMessage="Confirm" />
    </VeloDialog.Button>
  ),
  [ButtonType.REQUOTE]: () => (
    <VeloDialog.Button
      type="button"
      action="requote"
      isDefaultAction
      data-testid={TestIds.ADD_FUNDS}
    >
      <FormattedMessage defaultMessage="Re-quote" />
    </VeloDialog.Button>
  ),
  [ButtonType.QUOTING]: React.forwardRef(({ error }, ref) => (
    <VeloDialog.Button
      type="button"
      action="accept"
      disabled={false}
      data-testid={TestIds.ADD_FUNDS}
    >
      <span ref={ref}>
        <FormattedMessage defaultMessage="Quoting..." />
      </span>
    </VeloDialog.Button>
  )),
};

/* istanbul ignore next: tired of writing tests */
const defaultClicker = (element) => element.click();

/**
 * In order to avoid visual glitches, the loading view achieves the error state
 * by immediately clicking on the primary button (and therefore closing the
 * dialog with transition) and keeping the same layout as the dialog is closing.
 */
function LoadingView({ error, clicker = defaultClicker }) {
  const ref = useRef();
  const { current: button } = ref;
  useEffect(() => {
    error && clicker(button);
  }, [button, error, clicker]);

  const PrimaryButton = buttonsByType[ButtonType.QUOTING];
  return (
    <DialogLayout button={<PrimaryButton ref={ref} error={error} />}>
      <VeloGridLoading
        compact
        sections={[
          {
            fields: [
              {
                type: VeloGridLoading.fieldTypes.Custom,
                Component: ConfirmQuoteCallout.Loading,
              },
            ].concat(
              Array.from(new Array(5), () => ({
                type: VeloGridLoading.fieldTypes.LabelledItem,
              }))
            ),
          },
        ]}
      />
    </DialogLayout>
  );
}

ConfirmFunds.Loading = LoadingView;
ConfirmFunds.Error = LoadingView;
ConfirmFunds.Empty = ConfirmFunds;

const fieldNames = {
  QUOTE: 'quote',
  FUNDING_ACCOUNT_NAME: 'fundingAccountName',
  AMOUNT: 'amount',
  PROJECTED_BALANCE: 'projectedBalance',
  FX_RATE: 'fxRate',
  COST: 'cost',
};

function computeProjected({ sourceAccount: { balance, currency }, amount }) {
  return balance !== undefined
    ? {
        // because these will always be the same currencies, so minor+minor unit
        value: balance + amount,
        currency,
      }
    : { currency };
}

function getFxRateProps({
  rate,
  isPaymentCcyBaseCcy: swap,
  sourceCurrency,
  paymentCurrency,
}) {
  return {
    sourceCurrency,
    rate,
    swap,
    paymentCurrency,
  };
}

function computeCost({ totalSourceAmount, sourceCurrency }) {
  return {
    value: totalSourceAmount,
    currency: sourceCurrency,
  };
}

const fieldRenderByName = {
  [fieldNames.QUOTE]: ({ quote }, _, onQuoteExpired) => ({
    quote,
    onQuoteExpired,
    now: Date.now(),
    Component: ConfirmQuoteCallout,
  }),
  [fieldNames.FUNDING_ACCOUNT_NAME]: ({ fundingAccount: { name: value } }) => ({
    value,
    Component: VeloLabelledItem.UseValue,
    label: <FormattedMessage defaultMessage="Funding account" />,
  }),
  [fieldNames.FX_RATE]: ({ quote }, type) => ({
    children:
      type === ButtonType.REQUOTE ? (
        <ReQuoteRequired />
      ) : (
        <FXRate {...getFxRateProps(quote)} />
      ),
    Component: VeloLabelledItem,
    label: <FormattedMessage defaultMessage="FX rate" />,
  }),
  [fieldNames.AMOUNT]: ({ amount, sourceAccount: { currency } }) => ({
    children: <VeloCurrency value={amount} currency={currency} />,
    Component: VeloLabelledItem,
    label: <FormattedMessage defaultMessage="Funding amount" />,
  }),
  [fieldNames.PROJECTED_BALANCE]: (props) => {
    const currencyProps = computeProjected(props);
    return {
      children: <VeloCurrency {...currencyProps} />,
      Component: VeloLabelledItem,
      label: (
        <FormattedMessage defaultMessage="Projected source account balance" />
      ),
    };
  },
  [fieldNames.COST]: ({ quote }, type) => ({
    children:
      type === ButtonType.REQUOTE ? (
        <ReQuoteRequired />
      ) : (
        <VeloCurrency {...computeCost(quote)} />
      ),
    Component: VeloLabelledItem,
    label: <FormattedMessage defaultMessage="Cost" />,
  }),
};

const fieldsByType = {
  [PayorAccountType.FBO]: () => [
    fieldNames.FUNDING_ACCOUNT_NAME,
    fieldNames.AMOUNT,
    fieldNames.PROJECTED_BALANCE,
  ],
  [PayorAccountType.PRIVATE]: () => [
    fieldNames.FUNDING_ACCOUNT_NAME,
    fieldNames.AMOUNT,
    fieldNames.PROJECTED_BALANCE,
  ],
  [PayorAccountType.WUBS_DECOUPLED]: ({ sourceAccount, fundingAccount }) => {
    const needsQuote = sourceAccount.currency !== fundingAccount.currency;
    return [
      ...(needsQuote ? [fieldNames.QUOTE] : []),
      fieldNames.FUNDING_ACCOUNT_NAME,
      fieldNames.AMOUNT,
      ...(needsQuote ? [fieldNames.FX_RATE, fieldNames.COST] : []),
      fieldNames.PROJECTED_BALANCE,
    ];
  },
};
function getSections(props, ...rest) {
  const { type } = props.sourceAccount;
  const fieldOrder = fieldsByType[type](props);
  const mapper = (name) => fieldRenderByName[name](props, ...rest);
  return [
    {
      fields: fieldOrder.map(mapper).filter(Boolean),
    },
  ];
}

const render = ({ Component, ...props }) => <Component {...props} />;

/**
 * Add Funds Confirmation Dialog.
 *
 * Used when adding funds to a Source Account.
 */
function ConfirmFunds(props) {
  const [type, setType] = useState(ButtonType.CONFIRM);
  const onQuoteExpired = useMemo(
    () => () => setType(ButtonType.REQUOTE),
    [setType]
  );
  const sections = useMemo(
    () => getSections(props, type, onQuoteExpired),
    [props, type, onQuoteExpired]
  );
  const Button = buttonsByType[type];
  return (
    <DialogLayout button={<Button {...props} />}>
      <VeloSectionGrid compact sections={sections} render={render} />
      <VeloTypography use="bodyTextEmphasised" tag="p">
        <FormattedMessage defaultMessage="Do you want to add funds?" />
      </VeloTypography>
    </DialogLayout>
  );
}

ConfirmFunds.Amount = Amount;
ConfirmFunds.Text = Text;

export { ConfirmFunds };
