import { useEffect, useState, useMemo } from 'react';
import { createDataJoinCallbackFunction } from '../selectors/helpers';
import { useSerializableMemo } from './useSerializableMemo';

export function useDataJoin(
  inputData,
  extractRequests,
  makeRequest,
  mergeData
) {
  const data = useSerializableMemo(inputData);
  const [state, setState] = useState({});

  const effect = useMemo(
    () => createDataJoinCallbackFunction(extractRequests, makeRequest),
    [extractRequests, makeRequest]
  );

  useEffect(() => effect(data, setState), [data, effect]);

  return useMemo(() => mergeData(data, state), [mergeData, data, state]);
}
