import React from 'react';
import { bool, node } from 'prop-types';
import styled from 'astroturf/react';
import { VeloTypography } from '../../VeloTypography';
import { VeloSkeleton } from '../../VeloSkeleton';

const Heading = styled(VeloTypography)`
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
`;

const Skeleton = styled(VeloSkeleton)`
  width: 40%;
`;

const TestIds = {
  SKELETON: 'payment-view-heading-skeleton',
};

PaymentViewHeading.propTypes = {
  /** Render the heading as a loading skeleton. */
  skeleton: bool,
  /** The heading to render. */
  children: node,
};

PaymentViewHeading.testIds = TestIds;
/**
 * Represents a Payment View heading.
 */
function PaymentViewHeading({ skeleton, children }) {
  return (
    <Heading use="sectionHeader" tag="div">
      {skeleton ? <Skeleton data-testid={TestIds.SKELETON} /> : children}
    </Heading>
  );
}

export { PaymentViewHeading };
