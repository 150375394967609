import React, { useMemo } from 'react';
import { useHistory, useParams } from 'react-router';
import {
  useWireframe,
  useCallbackFnAsResultState,
  forkResult,
} from 'velo-portal-common';
import { useAPIMethods } from 'velo-api-react-hooks';
import {
  getSourceAccountV3 as getSourceAccount,
  getSourceAccountsV3 as getSourceAccounts,
  transferFundsV3 as transferFunds,
} from 'velo-api/src/entities/sourceAccounts';
import {
  Content,
  SourceAccountTransferFundsForm,
  VeloNotification,
} from 'velo-react-components';

import { SourceAccountsTransferFundsPresenter } from './SourceAccountsTransferFundsPresenter';

const entitySpec = {
  getSourceAccount,
  getSourceAccounts,
  transferFunds,
};

const forks = {
  none: ({ onClose }) => (
    <SourceAccountTransferFundsForm.Loading onClose={onClose} />
  ),
  error: (error, { onClose }) => (
    <SourceAccountTransferFundsForm.Error error={error} onClose={onClose} />
  ),
  value: (data, props) => (
    <SourceAccountTransferFundsForm {...data} {...props} />
  ),
};

export function SourceAccountsTransferFundsRoute() {
  const { sourceAccountId } = useParams();
  const history = useHistory();
  const wireframe = useWireframe(history);
  const entity = useAPIMethods(entitySpec);
  const [loader, props] = useMemo(
    () =>
      SourceAccountsTransferFundsPresenter(
        wireframe,
        entity,
        VeloNotification.types.SOURCE_ACCOUNT_TRANSFER_SUCCESS,
        { sourceAccountId }
      ),
    [wireframe, entity, sourceAccountId]
  );

  const [result] = useCallbackFnAsResultState(loader);

  return <Content>{forkResult(forks, result, props)}</Content>;
}
