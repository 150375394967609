export function getPaymentChannelByIdV4(send) {
  return (paymentChannelId, query, ...args) =>
    send(
      {
        path: '/v4/payees/paymentChannels/{paymentChannelId}',
        pathParameters: { paymentChannelId },
        type: 'get',
        query: query,
        body: undefined,
        headers: undefined,
        auth: 'oauth2',
        contentType: undefined,
      },
      ...args
    );
}
