import React, { useMemo, useState } from 'react';
import { func } from 'prop-types';
import { FormattedDisplayName, FormattedMessage, useIntl } from 'react-intl';
import safeGet from 'just-safe-get';
import styled from 'astroturf/react';
import { PayorAccountType, CurrencyCountryMap } from 'velo-data';
import { ConfirmationDialog } from '../ConfirmationDialog';
import { VeloPropTypes } from '../VeloPropTypes';
import { VeloLabelledItem } from '../VeloLabelledItem';
import { VeloModalSheetCardContent } from '../VeloModalSheetCardContent';
import { VeloButton } from '../VeloButton';
import { VeloSectionGrid } from '../VeloSectionGrid';
import { fieldNames, labelsByFieldName } from '../SourceAccountFormFields';
import { VeloBookended } from '../VeloBookended';
import { PayorAccountTypeText } from '../PayorAccountTypeText';
import { VeloGridLoading } from '../VeloGridLoading';
import { VeloDate } from '../VeloDate';
import { VeloTime } from '../VeloTime';
import { VeloCurrency } from '../VeloCurrency';
import { SourceAccountStatus } from '../SourceAccountStatus';
import { VeloFlagIcon } from '../VeloFlagIcon';
import { Country } from '../Country';
import { Address } from '../Address';
import styles from './SourceAccountView.module.scss';

const CurrencyWrapper = styled('div')`
  display: flex;
  align-items: center;
  margin: 0.5rem 0;
`;

const root = 'source-account-view';

const headingProps = {
  overline: true,
  tail: true,
};

const TestIds = {
  /**
   * Top section
   */
  ACCOUNT_DELETED_STATUS: `${root}-deleted-status`,
  ACCOUNT_DELETED_DATETIME: `${root}-deleted-datetime`,
  ADD_FUNDS: `${root}-add-funds-btn`,
  TRANSFER_FUNDS: `${root}-transfer-funds-btn`,
  EFFECTIVE_BALANCE_VALUE: `${root}-effective-balance-value`,
  FUNDING_REF_VALUE: `${root}-funding-ref-value`,
  RAILS_PROVIDER: `${root}-rails-provider`,
  PHYSICAL_ACCOUNT_NAME_VALUE: `${root}-physical-account-name-value`,
  DELETE: `${root}-delete`,
  /**
   * Topup section
   */
  EDIT_TOPUP: `${root}-edit-topup-btn`,
  CONFIG_ENABLED_VALUE: `${root}-ach-config-enabled-value`,
  CONFIG_MIN_BALANCE_VALUE: `${root}-ach-config-min-balance-value`,
  CONFIG_TARGET_BALANCE_VALUE: `${root}-ach-config-target-balance-value`,
  /**
   * On Behalf Of section
   */
  EDIT_OBO_DETAILS: `${root}-edit-obo-details`,
  OBO_REFERENCE: `${root}-reference`,
  OBO_COMPANY_NAME: `${root}-company-name`,
  OBO_ADDRESS: `${root}-address`,
  OBO_COUNTRY: `${root}-country`,
  /**
   * Notification section
   */
  EDIT_NOTIFICATION: `${root}-edit-notification-btn`,
  NOTIFICATION_ENABLED_VALUE: `${root}-ach-notification-enabled-value`,
  NOTIFICATION_MIN_BALANCE_VALUE: `${root}-ach-notification-min-balance-value`,
};

const headings = {
  oboOverride: <FormattedMessage defaultMessage="On Behalf Of" />,
  balanceThresholdHeading: (
    <FormattedMessage defaultMessage="Low Balance Notification" />
  ),
  autoTopupHeading: <FormattedMessage defaultMessage="Auto Topup" />,
};

/**
 * A dictionary of field sections by account type, where the fields on the
 * sections are ordered field names for transformation.
 */
const sectionsByType = {
  [PayorAccountType.FBO]: [
    {
      fields: [fieldNames.DELETION_STATUS, fieldNames.DELETION_DATE],
    },
    {
      fields: [
        fieldNames.PAYOR_NAME,
        fieldNames.TYPE,
        fieldNames.PHYSICAL_ACCOUNT_NAME,
        fieldNames.FUNDING_REF,
        fieldNames.BALANCE,
      ],
    },
    {
      heading: headings.oboOverride,
      fields: [
        fieldNames.OBO_NOT_FOUND_DETAILS,
        fieldNames.OBO_REF,
        fieldNames.OBO_NAME,
        fieldNames.OBO_ADDRESS,
        fieldNames.OBO_ADDRESS_COUNTRY,
      ],
    },
    {
      heading: headings.autoTopupHeading,
      fields: [
        fieldNames.TOP_UP_ENABLED,
        fieldNames.TOP_UP_ACCOUNT_NAME,
        fieldNames.TOP_UP_MIN_BALANCE,
        fieldNames.TOP_UP_TARGET_BALANCE,
      ],
    },
    {
      heading: headings.balanceThresholdHeading,
      fields: [
        fieldNames.LOW_BALANCE_ENABLED,
        fieldNames.LOW_BALANCE_THRESHOLD,
      ],
    },
  ],
  [PayorAccountType.WUBS_DECOUPLED]: [
    {
      fields: [fieldNames.DELETION_STATUS, fieldNames.DELETION_DATE],
    },
    {
      fields: [fieldNames.PAYOR_NAME, fieldNames.TYPE, fieldNames.BALANCE],
    },
  ],
  [PayorAccountType.PRIVATE_COUPLED]: [
    {
      fields: [fieldNames.DELETION_STATUS, fieldNames.DELETION_DATE],
    },
    {
      fields: [fieldNames.PAYOR_NAME, fieldNames.TYPE, fieldNames.BALANCE],
    },
  ],
  [PayorAccountType.PRIVATE]: [
    {
      fields: [fieldNames.DELETION_STATUS, fieldNames.DELETION_DATE],
    },
    {
      fields: [
        fieldNames.PAYOR_NAME,
        fieldNames.TYPE,
        fieldNames.CURRENCY,
        fieldNames.RAILS_PROVIDER,
        fieldNames.BALANCE,
        fieldNames.RAIL_SPECIFIC_ACCOUNT_CREDENTIALS,
      ],
    },
    {
      heading: headings.autoTopupHeading,
      fields: [
        fieldNames.TOP_UP_ENABLED,
        fieldNames.TOP_UP_ACCOUNT_NAME,
        fieldNames.TOP_UP_MIN_BALANCE,
        fieldNames.TOP_UP_TARGET_BALANCE,
      ],
    },
  ],
};

const TestIdsByName = {
  [fieldNames.OBO_REF]: TestIds.OBO_REFERENCE,
  [fieldNames.OBO_NAME]: TestIds.OBO_COMPANY_NAME,
  [fieldNames.OBO_ADDRESS]: TestIds.OBO_ADDRESS,
  [fieldNames.OBO_ADDRESS_COUNTRY]: TestIds.OBO_COUNTRY,
};

const deletedSectionsByType = {
  [PayorAccountType.FBO]: [
    {
      fields: [fieldNames.DELETION_STATUS, fieldNames.DELETION_DATE],
    },
    {
      fields: [
        fieldNames.PAYOR_NAME,
        fieldNames.TYPE,
        fieldNames.PHYSICAL_ACCOUNT_NAME,
        fieldNames.FUNDING_REF,
        fieldNames.BALANCE,
      ],
    },
  ],
};

function DateTimeView({ value }) {
  return (
    <div data-testid={TestIds.ACCOUNT_DELETED_DATETIME}>
      <VeloDate.Long value={value} tag="div" />
      <VeloTime.Long value={value} tag="div" />
    </div>
  );
}

function DeletedStatus() {
  return (
    <>
      <SourceAccountStatus
        label={<FormattedMessage defaultMessage="Deleted" />}
        use="itemContent"
        testId={TestIds.ACCOUNT_DELETED_STATUS}
      />
    </>
  );
}

const fixedValueFields = {
  [fieldNames.OBO_NOT_FOUND_DETAILS]: (
    <FormattedMessage defaultMessage="Not currently set" />
  ),
};

function createRenderFn(fieldName, transform = (children) => ({ children })) {
  return (props) => {
    const value = safeGet(props.data, fieldName);

    // an undefined balance is applicable for WUBS
    if (value === undefined && fieldName !== fieldNames.BALANCE) {
      return undefined;
    }

    const transformedValue = transform(value, props, fieldName);

    const fixedValue = fixedValueFields[fieldName];

    return Array.isArray(transformedValue)
      ? transformedValue
      : {
          label: labelsByFieldName[fieldName],
          ...(fixedValue
            ? { ...transformedValue, children: fixedValue }
            : transformedValue),
        };
  };
}

function currencyTransform(value, { data }) {
  return {
    children: <VeloCurrency value={value} currency={data.currency} />,
  };
}

const addTestIdTransform = (transform) => (value, data, name) => {
  const transformed = transform(value, data, name);
  return {
    ...transformed,
    children: (
      <div data-testid={TestIdsByName[name]}>{transformed.children}</div>
    ),
  };
};

function addressTransform(value) {
  return {
    children: <Address {...value} />,
  };
}

function countryTransform(value) {
  return {
    children: <Country country={value} />,
  };
}

function currencyNameTransform(value) {
  return {
    children: (
      <CurrencyWrapper>
        <VeloFlagIcon.Graphic flag={CurrencyCountryMap[value]} />
        <span>
          <FormattedDisplayName type="currency" value={value} />
        </span>
      </CurrencyWrapper>
    ),
  };
}

const oboEditButtonRenderer = (children, { onEditOboDetails, data }) => {
  return (
    onEditOboDetails && {
      children,
      title: <FormattedMessage defaultMessage="Edit" />,
      testId: TestIds.EDIT_OBO_DETAILS,
      labelId: TestIds.CONFIG_ENABLED_VALUE,
      onClick: onEditOboDetails,
    }
  );
};

const buttonRenderByName = {
  [fieldNames.BALANCE]: (value, { onAddFunding, onTransferFunds, data }) => {
    const { userDeleted, platformFundingSupported } = data;
    const props = currencyTransform(value, {
      data,
    });

    if (!platformFundingSupported) {
      return props;
    }

    if (onAddFunding) {
      return userDeleted
        ? { ...props }
        : {
            ...props,
            title: <FormattedMessage defaultMessage="Add Funds" />,
            testId: TestIds.ADD_FUNDS,
            onClick: onAddFunding,
          };
    } else if (onTransferFunds) {
      return userDeleted
        ? {}
        : {
            ...props,
            title: <FormattedMessage defaultMessage="Transfer Funds" />,
            testId: TestIds.TRANSFER_FUNDS,
            onClick: onTransferFunds,
          };
    }
    return props;
  },
  [fieldNames.FUNDING_REF]: (
    children,
    { onTransferFunds, onAddFunding, data }
  ) => {
    const { userDeleted } = data;
    return (
      onTransferFunds &&
      onAddFunding &&
      !userDeleted && {
        children,
        title: <FormattedMessage defaultMessage="Transfer Funds" />,
        testId: TestIds.TRANSFER_FUNDS,
        onClick: onTransferFunds,
      }
    );
  },
  [fieldNames.TOP_UP_ENABLED]: (children, { onAutoTopup, data }) => {
    const { userDeleted } = data;
    return (
      onAutoTopup &&
      !userDeleted && {
        children,
        title: <FormattedMessage defaultMessage="Edit" />,
        testId: TestIds.EDIT_TOPUP,
        labelId: TestIds.CONFIG_ENABLED_VALUE,
        onClick: onAutoTopup,
      }
    );
  },
  [fieldNames.LOW_BALANCE_ENABLED]: (
    children,
    { onBalanceThreshold, data }
  ) => {
    const { userDeleted } = data;
    return (
      onBalanceThreshold &&
      !userDeleted && {
        children,
        title: <FormattedMessage defaultMessage="Edit" />,
        testId: TestIds.EDIT_NOTIFICATION,
        labelId: TestIds.NOTIFICATION_ENABLED_VALUE,
        onClick: onBalanceThreshold,
      }
    );
  },
  [fieldNames.OBO_NOT_FOUND_DETAILS]: oboEditButtonRenderer,
  [fieldNames.OBO_REF]: oboEditButtonRenderer,
};

function buttonTransform(children, props, name) {
  return buttonRenderByName[name](children, props) || { children };
}

function accountCredentialsTransform(values) {
  return values.map(({ propertyName, label, ...value }) => ({
    label,
    children: value[propertyName],
  }));
}
/**
 * A dictionary of render functions taking the props object and returning a
 * field object or undefined
 * @type {Object<string,function(Object):Object>}
 */
const fieldRenderByName = {
  [fieldNames.BALANCE]: createRenderFn(fieldNames.BALANCE, buttonTransform),
  [fieldNames.FUNDING_REF]: createRenderFn(
    fieldNames.FUNDING_REF,
    buttonTransform
  ),
  [fieldNames.PHYSICAL_ACCOUNT_NAME]: createRenderFn(
    fieldNames.PHYSICAL_ACCOUNT_NAME
  ),
  [fieldNames.TOP_UP_ENABLED]: createRenderFn(
    fieldNames.TOP_UP_ENABLED,
    buttonTransform
  ),
  [fieldNames.RAILS_PROVIDER]: createRenderFn(fieldNames.RAILS_PROVIDER),
  [fieldNames.TOP_UP_ACCOUNT_NAME]: createRenderFn(
    fieldNames.TOP_UP_ACCOUNT_NAME
  ),
  [fieldNames.TOP_UP_MIN_BALANCE]: createRenderFn(
    fieldNames.TOP_UP_MIN_BALANCE,
    currencyTransform
  ),
  [fieldNames.TOP_UP_TARGET_BALANCE]: createRenderFn(
    fieldNames.TOP_UP_TARGET_BALANCE,
    currencyTransform
  ),
  [fieldNames.LOW_BALANCE_ENABLED]: createRenderFn(
    fieldNames.LOW_BALANCE_ENABLED,
    buttonTransform
  ),
  [fieldNames.LOW_BALANCE_THRESHOLD]: createRenderFn(
    fieldNames.LOW_BALANCE_THRESHOLD,
    currencyTransform
  ),
  [fieldNames.CURRENCY]: createRenderFn(
    fieldNames.CURRENCY,
    currencyNameTransform
  ),

  [fieldNames.PAYOR_NAME]: createRenderFn(fieldNames.PAYOR_NAME),
  [fieldNames.TYPE]: createRenderFn(fieldNames.TYPE, (type) => ({
    children: <PayorAccountTypeText type={type} />,
  })),
  [fieldNames.DELETION_STATUS]: createRenderFn(
    fieldNames.DELETION_STATUS,
    () => ({
      children: <DeletedStatus />,
    })
  ),
  [fieldNames.DELETION_DATE]: createRenderFn(
    fieldNames.DELETION_DATE,
    (deletedAt) => ({
      children: <DateTimeView value={deletedAt} />,
    })
  ),
  [fieldNames.RAIL_SPECIFIC_ACCOUNT_CREDENTIALS]: createRenderFn(
    fieldNames.RAIL_SPECIFIC_ACCOUNT_CREDENTIALS,
    accountCredentialsTransform
  ),
  [fieldNames.OBO_NOT_FOUND_DETAILS]: createRenderFn(
    fieldNames.OBO_NOT_FOUND_DETAILS,
    buttonTransform
  ),
  [fieldNames.OBO_REF]: createRenderFn(
    fieldNames.OBO_REF,
    addTestIdTransform(buttonTransform)
  ),
  [fieldNames.OBO_NAME]: createRenderFn(
    fieldNames.OBO_NAME,
    addTestIdTransform((children) => ({ children }))
  ),
  [fieldNames.OBO_ADDRESS]: createRenderFn(
    fieldNames.OBO_ADDRESS,
    addTestIdTransform(addressTransform)
  ),
  [fieldNames.OBO_ADDRESS_COUNTRY]: createRenderFn(
    fieldNames.OBO_ADDRESS_COUNTRY,
    addTestIdTransform(countryTransform)
  ),
};

function formatData({ notifications, autoTopUpConfig = {}, ...rest }) {
  return {
    ...rest,
    notifications: {
      // if it's a positive integer
      ...(notifications?.minimumBalance > 0 && {
        ...notifications,
        minimumBalanceEnabled: <FormattedMessage defaultMessage="ON" />,
      }),
      // if explicitly zero
      ...(notifications?.minimumBalance === 0 && {
        minimumBalanceEnabled: <FormattedMessage defaultMessage="OFF" />,
      }),
    },
    autoTopUpConfig: {
      // if it's truthy, add the enabled label
      ...(autoTopUpConfig?.enabled && {
        ...autoTopUpConfig,
        enabled: <FormattedMessage defaultMessage="ON" />,
      }),
      // if it's explicit false, only return the off label
      ...(autoTopUpConfig?.enabled === false && {
        enabled: <FormattedMessage defaultMessage="OFF" />,
      }),
    },
  };
}

const LabelledButton = VeloLabelledItem.withAlignedLabel(
  ({ labeltext, labelId, value }) => (
    <VeloLabelledItem testId={labelId} label={labeltext}>
      {value}
    </VeloLabelledItem>
  ),
  { desktop: 6, tablet: 4 },
  { desktop: 6, tablet: 4, className: styles.alignButton }
)(({ labelId, ...props }) => React.createElement(VeloButton, props));

const render = ({ label, children, onClick, title, testId, labelId }) => {
  if (onClick) {
    return (
      <LabelledButton
        data-testid={testId}
        onClick={onClick}
        labeltext={label}
        labelId={labelId}
        value={children}
      >
        {title}
      </LabelledButton>
    );
  }

  return (
    <VeloLabelledItem testId={testId} label={label}>
      {children}
    </VeloLabelledItem>
  );
};

SourceAccountView.propTypes = {
  data: VeloPropTypes.sourceAccountType(),
  onTransferFunds: func,
  onClose: func.isRequired,
  onDelete: func,
  onAddFunding: func,
  onAutoTopup: func,
  onBalanceThreshold: func,
};

function createFieldReducer(props) {
  return (fields, name) => {
    const field = fieldRenderByName[name](props);

    // only push if the renderer returns a value
    if (field) {
      return fields.concat(field);
    }
    return fields;
  };
}

function createSectionReducer(props) {
  const reducer = createFieldReducer(props);
  return (sections, { fields: fieldOrder, ...section }) => {
    const fields = fieldOrder.reduce(reducer, []);
    // only push if there are fields to show
    if (fields.length > 0) {
      return sections.concat({
        ...section,
        fields,
      });
    }
    return sections;
  };
}

function LoadingView({ onClose, children }) {
  const fields = (count) =>
    [...Array(count).keys()].map(() => ({
      type: VeloGridLoading.fieldTypes.LabelledItem,
    }));
  return (
    <VeloModalSheetCardContent.Skeleton onClose={onClose}>
      <VeloBookended column>
        <VeloGridLoading
          compact
          headingProps={headingProps}
          sections={[
            {
              fields: fields(3),
            },
            {
              heading: true,
              fields: fields(4),
            },
            {
              heading: true,
              fields: fields(2),
            },
          ]}
        />
        {children}
      </VeloBookended>
    </VeloModalSheetCardContent.Skeleton>
  );
}

SourceAccountView.Loading = LoadingView;
SourceAccountView.Error = VeloModalSheetCardContent.Error;

function SourceAccountView({
  onClose,
  onDelete,
  onEdit,
  data,
  children,
  ...props
}) {
  const [state, setState] = useState({ disabled: false, dialogOpen: false });
  const intl = useIntl();
  const { userDeleted } = data;
  const onEditAction = !userDeleted ? onEdit : undefined;
  const sourceAccountTypeSections =
    userDeleted && data.type === PayorAccountType.FBO
      ? deletedSectionsByType[PayorAccountType.FBO]
      : sectionsByType[data.type || PayorAccountType.FBO];

  const sections = sourceAccountTypeSections.reduce(
    createSectionReducer({
      data: formatData(data),
      ...props,
    }),
    []
  );

  const buttons = [
    ...(!userDeleted && onDelete
      ? [
          {
            icon: 'delete',
            title: intl.formatMessage({ defaultMessage: 'Delete' }),
            'data-testid': TestIds.DELETE,
            onClick: () => setState({ disabled: false, dialogOpen: true }),
          },
        ]
      : []),
  ];

  const dialogProps = useMemo(() => {
    return {
      open: state.dialogOpen,
      dialogType: ConfirmationDialog.dialogTypes.ConfirmDeleteSourceAccount,
      onClose: (evt) => {
        const disabled = evt.detail.action === 'accept';
        setState({ dialogOpen: false, disabled });
        if (disabled) {
          onDelete(data, () =>
            setState({ disabled: false, dialogOpen: false })
          );
        }
      },
    };
  }, [setState, onDelete, data, state]);

  return (
    <VeloModalSheetCardContent
      onEdit={onEditAction}
      title={data.name}
      onClose={onClose}
      buttons={buttons}
    >
      <VeloBookended column>
        <VeloSectionGrid
          compact
          render={render}
          sections={sections}
          headingProps={headingProps}
        />
        {children}
      </VeloBookended>
      <ConfirmationDialog {...dialogProps} />
    </VeloModalSheetCardContent>
  );
}

SourceAccountView.testIds = {
  ...VeloModalSheetCardContent.testIds,
  ...TestIds,
};

export { SourceAccountView };
