import React from 'react';
import { bool } from 'prop-types';
import styled from 'astroturf/react';
import { VeloTypography } from '../VeloTypography';
import { VeloSkeleton } from '../VeloSkeleton';

const Title = styled(VeloTypography)`
  @import '@design-system/tokens/typography/scss/mixins';

  @include ds-typography-ellipsis;

  flex-grow: 2;
  margin: 0;
`;

const Skeleton = styled(VeloSkeleton)`
  width: 50%;
`;

VeloModalSheetHeaderTitle.propTypes = {
  /** Render a loading skeleton. */
  skeleton: bool,
};

/**
 * Used to render a title in a VeloModalSheetHeader.
 *
 * You can override the Typography class or HTML tag
 * using the `use` and `tag` props respectively (they are
 * passed down to the underlying Typography` component.)
 */
function VeloModalSheetHeaderTitle({ children, skeleton, ...other }) {
  return (
    <Title use="secondaryHeader" tag="h1" {...other}>
      {skeleton ? <Skeleton /> : children}
    </Title>
  );
}

export { VeloModalSheetHeaderTitle };
