import React from 'react';
import { bool, string, func } from 'prop-types';
import styled from 'astroturf/react';
import { EscapeKey } from '../EscapeKey';
import { VeloModalSheetCard } from './VeloModalSheetCard';
import { VeloModalSideSheetProvider } from './VeloModalSheetContext';
import { VeloScrim } from '../VeloScrim';
import { VeloModalSheetHeader } from './VeloModalSheetHeader';
import { VeloModalSheetHeaderTitle } from './VeloModalSheetHeaderTitle';
import { VeloModalSheetFooter } from './VeloModalSheetFooter';
import { VeloModalSheetBody } from './VeloModalSheetBody';
import { VeloModalSheetError } from './VeloModalSheetError';

const Scrim = styled(VeloScrim)`
  @import 'velo-variables';

  @media (max-width: velo-breakpoint(XS)) {
    display: none;
  }
`;

const TestIds = {
  SCRIM: 'velo-modal-sheet-scrim',
};

VeloModalSheet.propTypes = {
  /** Custom CSS class(es) passed to the card component. */
  className: string,
  /** Disables the effect of escape calling the onClose handler. */
  disableEscapeKey: bool,
  /** Controls whether to open or close the fly-out. */
  open: bool.isRequired,
  /** Called when the user closes the fly-out. */
  onClose: func.isRequired,
};

VeloModalSheet.testIds = TestIds;

/**
 * Generic "fly-out" component that will animate from the right-hand
 * side and occupy the full height of the page.
 *
 * This component works in the same way as core Material/RMWC components
 * in that the visibility is controlled using an `open` prop. If you
 * conditionally render this component then the CSS transitions will not
 * work correctly.
 */
function VeloModalSheet({
  className,
  open,
  onClose,
  children,
  disableEscapeKey,
  ...other
}) {
  const handleClick = () => {
    if (open) onClose();
  };

  return (
    <VeloModalSideSheetProvider open={open}>
      <Scrim open={open} onClick={handleClick} data-testid={TestIds.SCRIM} />

      <VeloModalSheetCard open={open} className={className} {...other}>
        {children}
      </VeloModalSheetCard>

      {open && (
        <>
          <EscapeKey disabled={disableEscapeKey} onPressed={onClose} />
        </>
      )}
    </VeloModalSideSheetProvider>
  );
}

VeloModalSheet.Header = VeloModalSheetHeader;
VeloModalSheet.HeaderTitle = VeloModalSheetHeaderTitle;
VeloModalSheet.Footer = VeloModalSheetFooter;
VeloModalSheet.Body = VeloModalSheetBody;
VeloModalSheet.Error = VeloModalSheetError;

export { VeloModalSheet };
