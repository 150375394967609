import React from 'react';
import styled from 'astroturf/react';
import { bool, number, node, oneOf } from 'prop-types';

import { PayoutStatus } from '../PayoutStatus';
import { VeloIcon } from '../VeloIcon';
import { PayoutTypes } from '../PayoutTypes';

const TotalLabel = styled('span')`
  @import 'velo-variables';

  @media (min-width: velo-breakpoint(XS)) {
    display: none;
    text-align: left;
  }
`;

const ConditionalFormat = styled('div')`
  @import 'velo-mixins';
  &.error {
    color: velo-color('token-color-system-error-default');
  }
`;

ConditionalFormat.propTypes = {
  error: bool,
};

Total.propTypes = {
  error: bool,
  total: number,
  label: node,
};

function Total({ total, label, error = false, ...props }) {
  const nonZero = total > 0;
  return (
    <ConditionalFormat error={error && nonZero}>
      {nonZero && <TotalLabel>{label} </TotalLabel>}
      <span {...props}>{total || '--'}</span>
    </ConditionalFormat>
  );
}

/**
 * This is to allow for the potential "On Behalf Of" indicator
 */
const IconsContainer = styled('div')`
  min-width: 58px;
  text-align: right;
`;

const OnBehalfOfIcon = styled(VeloIcon)`
  margin-right: 10px;
`;

PayoutIcon.propTypes = {
  payoutType: oneOf(Object.values(PayoutTypes.PAYOUT_TYPE)).isRequired,
};

function PayoutIcon({ payoutType }) {
  const isHierachyPayout = PayoutTypes.isHierachyPayout(payoutType);
  return (
    <IconsContainer>
      {isHierachyPayout && <OnBehalfOfIcon icon="payout_on_behalf_of" />}
      <VeloIcon icon="more_horiz" />
    </IconsContainer>
  );
}

export const PayoutsTableComponents = {
  IconsContainer,
  PayoutIcon,
  Total,
  Status: PayoutStatus,
};
