import { arrayOf, func, node, string } from 'prop-types';
import { VeloPropTypes } from '../VeloPropTypes';

const sourceAccountFormType = VeloPropTypes.sourceAccountType({
  /**
   * The payor name, not required in case it is omitted (for the payor view).
   */
  payorName: string,
});

const sourceAccountFormProps = {
  /**
   * See the sourceAccountFormType.
   */
  data: sourceAccountFormType,
  /**
   * Generic node for presenting an error.
   */
  error: node,
  /**
   * Function to call when the form is closed.
   */
  onClose: func.isRequired,
  /**
   * Function to call when form is submitted, takes a body and an error first
   * callback.
   */
  onSubmit: func.isRequired,
  /**
   * A list of fields in `data` to omit from display.
   */
  omitDisplayFields: arrayOf(string),
};

const sourceAccountSkeletonData = {
  payorName: '_',
  name: '_',
  currency: 'USD',
  balance: 0,
  fundingRef: '_',
  notifications: { minimumBalance: 1 },
  autoTopUpConfig: { enabled: true, minBalance: 0, targetBalance: 0 },
};

export {
  sourceAccountFormType,
  sourceAccountFormProps,
  sourceAccountSkeletonData,
};
