import React, { useState, useEffect } from 'react';
import { bool, func, number, object, string } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Money from 'js-money';
import styled from 'astroturf/react';
import { VeloDialog } from '../VeloDialog';
import { VeloTypography } from '../VeloTypography';
import { VeloCurrency } from '../VeloCurrency';
import { VeloForm } from '../VeloForm';
import { VeloTextField } from '../VeloTextField';
import { VeloCard } from '../VeloCard';
import { VeloButton } from '../VeloButton';
import { FreeTextEntryWithinRange } from '../FormFields/FormFieldTypes';

const ErrorContainer = styled(VeloCard.Error)`
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  border-radius: 4px;
  padding: 0;
`;

const ErrorMessage = styled(VeloCard.ErrorMessage)`
  @import 'velo-mixins';

  @include velo-font-weight(regular);
  padding: 1rem;
  margin: 0;
`;

const ButtonContainer = styled('div')`
  display: flex;
  justify-content: flex-end;
  margin: 0rem 1rem 1rem 1rem;
`;

const Amount = ({ money, ...other }) => (
  <VeloCurrency value={money.getAmount()} currency={money.getCurrency()}>
    {(value) => (
      <VeloTypography use="bodyTextEmphasised" {...other}>
        {value}
      </VeloTypography>
    )}
  </VeloCurrency>
);

const BodyText = (props) => (
  <VeloTypography use="bodyText" tag="p" {...props} />
);

const PayeeName = (props) => (
  <VeloTypography use="bodyTextEmphasised" {...props} />
);

const SubmitError = ({ error, ...props }) => (
  <ErrorContainer {...props}>
    <ErrorMessage>{error}</ErrorMessage>
  </ErrorContainer>
);

const root = 'confirm-withdraw';

const TestIds = {
  DIALOG: `${root}-dialog`,
  TITLE: `${root}-title`,
  AMOUNT: `${root}-amount`,
  SOURCE_AMOUNT: `${root}-source-amount`,
  CANCEL: `${root}-cancel`,
  CONFIRM: `${root}-confirm`,
  FORM: `${root}-form`,
  REASON: `${root}-reason`,
};

PaymentWithdrawDialog.propTypes = {
  open: bool,
  paymentCurrency: string.isRequired,
  paymentAmount: number.isRequired,
  paymentId: string.isRequired,
  payeeName: object,
  error: string,
  onClose: func.isRequired,
  onWithdraw: func.isRequired,
};

function PaymentWithdrawDialog({
  open,
  paymentCurrency,
  onClose,
  onWithdraw,
  paymentAmount,
  paymentId,
  payeeName,
  error,
}) {
  const [amount, setAmount] = useState(undefined);
  const [reason, setReason] = useState('');

  useEffect(() => {
    try {
      const amountMoney = new Money(paymentAmount, paymentCurrency);
      setAmount(amountMoney);
    } catch (e) {
      setAmount(undefined);
    }
  }, [paymentAmount, paymentCurrency]);

  const onSubmit = (e) => {
    e.preventDefault();
    onClose();
    onWithdraw(paymentId, reason);
  };

  return (
    <VeloDialog open={open} onClose={onClose} data-testid={TestIds.DIALOG}>
      <form data-testid={TestIds.FORM} onSubmit={onSubmit}>
        {/* Title */}
        <VeloDialog.Title data-testid={TestIds.TITLE}>
          <VeloTypography use="secondaryHeader">
            <FormattedMessage defaultMessage="Confirm withdraw payment" />
          </VeloTypography>
        </VeloDialog.Title>
        {/* Content */}
        <VeloDialog.Content>
          <BodyText>
            <FormattedMessage
              defaultMessage="Withdraw payment to {payeeName} of {amount}"
              values={{
                payeeName: (
                  <PayeeName>
                    {payeeName ? payeeName.result : payeeName}
                  </PayeeName>
                ),
                amount: amount ? (
                  <Amount money={amount} />
                ) : (
                  <VeloTypography use="bodyTextEmphasised">
                    {paymentAmount}
                  </VeloTypography>
                ),
              }}
            />
          </BodyText>

          {/* Form */}
          <VeloForm.FieldContainer>
            <VeloTextField
              required
              {...FreeTextEntryWithinRange(
                <FormattedMessage defaultMessage="Withdrawal reason" />,
                2,
                255
              )}
              name="withdraw reason"
              data-testid={TestIds.REASON}
              onChange={({ target }) => setReason(target.value)}
            />
          </VeloForm.FieldContainer>
          {error && <SubmitError role="alert" error={error} />}
        </VeloDialog.Content>
        <ButtonContainer>
          <VeloButton data-testid={TestIds.CONFIRM} type="submit">
            <FormattedMessage defaultMessage="Confirm" />
          </VeloButton>
          <VeloButton
            data-testid={TestIds.CANCEL}
            onClick={onClose}
            type="button"
          >
            <FormattedMessage defaultMessage="Cancel" />
          </VeloButton>
        </ButtonContainer>
      </form>
    </VeloDialog>
  );
}

PaymentWithdrawDialog.testIds = TestIds;

export { PaymentWithdrawDialog };
