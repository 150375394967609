import { useMemo } from 'react';
import { useParams } from 'react-router';
import {
  useCountries,
  usePayeeCountryRules,
  usePaymentChannelRules,
  usePresenter,
  useCallbackFnAsResultState,
  createFromHook,
  forkResult,
  createRenderForksFromComponent,
  useSerializableMemo,
} from 'velo-portal-common';
import { EditPayeeForm, VeloNotification } from 'velo-react-components';
import {
  getPayeeByIdV4 as getPayee,
  getPaymentChannelByIdV4 as getPaymentChannel,
  payeeDetailsUpdateV4 as payeeDetailsUpdate,
  remoteIdUpdateV4 as remoteIdUpdate,
  createPaymentChannelV4 as createPaymentChannel,
  deletePaymentChannelV4 as deletePaymentChannel,
  updatePaymentChannelV4 as updatePaymentChannel,
} from 'velo-api/src/entities/payees';
import { EditPayeePresenter } from './EditPayeePresenter';

const entitySpec = {
  getPayee,
  getPaymentChannel,
  payeeDetailsUpdate,
  remoteIdUpdate,
  createPaymentChannel,
  deletePaymentChannel,
  updatePaymentChannel,
};

const forks = createRenderForksFromComponent(EditPayeeForm, (data) => ({
  // EditPayeeForm uses a `values` prop instead of `data`
  values: data,
}));

const EditPayeeRoute = createFromHook(
  ({ payorId }) => {
    const { payeeId } = useParams();
    const { countries } = useCountries();
    const { payeeCountryRules } = usePayeeCountryRules();
    const { rules } = usePaymentChannelRules();

    const [loader, getProps] = usePresenter(
      EditPayeePresenter,
      entitySpec,
      useMemo(
        () => ({
          // Fixed props
          notification: VeloNotification.types.EDIT_PAYEE_SUCCESS,
          countries,
          paymentChannel: { rules: rules.bank, currency: 'USD' },
          country: 'US',
          payeeCountryRules,
        }),
        [countries, payeeCountryRules, rules]
      ),
      useSerializableMemo({ payorId, payeeId })
    );
    const [result] = useCallbackFnAsResultState(loader);
    return [result, getProps(result)];
  },
  {
    render: ([result, props]) => forkResult(forks, result, props),
  }
);

export { EditPayeeRoute };
