import { createEarlyExitCallback, combineCallbacks } from '../../selectors';
import { payorRefsSelectors } from 'velo-data';

function EditRemoteIdPresenter(
  wireframe,
  entities,
  { payorId, payeeId },
  notification
) {
  const loader = (cb) => {
    const [onPayee, onPayor] = combineCallbacks(
      2,
      createEarlyExitCallback(
        ([{ displayName, payorRefs }], [{ payorName }]) => {
          cb(undefined, {
            remoteId: payorRefsSelectors.getRemoteId(payorId, payorRefs),
            payeeName: displayName,
            payorName: payorName,
          });
        },
        cb
      )
    );
    entities.getPayeeByIdV4(payeeId, { sensitive: false }, onPayee);
    entities.getPayorByIdV2(payorId, onPayor);
  };

  const onClose = wireframe.goBack;

  const onSubmit = ({ remoteId }, cb) => {
    entities.remoteIdUpdateV4(
      payeeId,
      { payorId, remoteId },
      createEarlyExitCallback(() => {
        cb();
        wireframe.goBack();
        wireframe.sendNote(notification);
      }, cb)
    );
  };

  return [loader, { onClose, onSubmit }];
}

export { EditRemoteIdPresenter };
