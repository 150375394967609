import React from 'react';
import PropTypes, { string, bool } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  paymentChannelsConfig,
  dataTestIdBuilder,
  CurrencyCountryMap,
} from 'velo-data';
import { getCountries, countryName } from '../utils';
import { LookupTextField } from '../LookupTextField';
import { VeloStatusIndicator } from '../VeloStatusIndicator';
import { VeloTableBuilder } from '../VeloTableBuilder';
import { VeloDragNDropTableBuilder } from '../VeloTableBuilder/VeloDragNDropTableBuilder';
import { VeloTable } from '../VeloTable';

const root = 'velo-payment-methods-list';

const TestIds = {
  TABLE: root,
  NAME: 'name',
  PAYEE: 'payee',
  PAYORS: 'payors',
  ENABLED: 'enabled',
  IDENTIFIER: 'identifier',
  CURRENCY: 'currency',
  HEADING: `${root}-heading`,
  LOADING: `${root}-loading`,
  ROW: `${root}-row`,
  DISABLED_ICON: `${root}-channel-disabled-icon`,
};

const fieldNames = {
  NAME: 'paymentChannelName',
  // for sorting purposes, use `payeeName` here
  PAYEE: 'payeeName',
  PAYOR: 'payorId',
  IDENTIFIER: 'identifier',
  ENABLED: 'enabled',
  CURRENCY: 'currency',
  COUNTRY: 'country',
};

const columnsByName = {
  [fieldNames.NAME]: {
    name: fieldNames.NAME,
    label: <FormattedMessage defaultMessage="Name" />,
    'data-testid': dataTestIdBuilder(TestIds.HEADING, TestIds.NAME),
  },
  [fieldNames.PAYEE]: {
    name: fieldNames.PAYEE,
    label: <FormattedMessage defaultMessage="Payee" />,
    'data-testid': dataTestIdBuilder(TestIds.HEADING, TestIds.PAYEE),
  },
  [fieldNames.CURRENCY]: {
    name: fieldNames.CURRENCY,
    label: <FormattedMessage defaultMessage="Currency" />,
    'data-testid': dataTestIdBuilder(TestIds.HEADING, TestIds.CURRENCY),
  },
  [fieldNames.PAYOR]: {
    name: fieldNames.PAYOR,
    label: <FormattedMessage defaultMessage="Payors" />,
    'data-testid': dataTestIdBuilder(TestIds.HEADING, TestIds.PAYORS),
  },
  [fieldNames.IDENTIFIER]: {
    name: fieldNames.IDENTIFIER,
    label: <FormattedMessage defaultMessage="Identifier" />,
    'data-testid': dataTestIdBuilder(TestIds.HEADING, TestIds.ENABLED),
  },
  [fieldNames.ENABLED]: {
    name: fieldNames.ENABLED,
    size: VeloTable.size.SMALL,
    'data-testid': dataTestIdBuilder(TestIds.HEADING, TestIds.ENABLED),
  },
};

function formatIdentifier({ iban, accountNumber }) {
  if (iban) {
    return (
      <FormattedMessage
        defaultMessage="IBAN Account ending in ...{iban}"
        values={{ iban: iban.slice(-4) }}
      />
    );
  }
  if (accountNumber) {
    return (
      <FormattedMessage
        defaultMessage="Account ending in ...{accountNumber}"
        values={{ accountNumber: accountNumber.slice(-4) }}
      />
    );
  }
  return null;
}

const cellFactoryByFieldName = {
  [fieldNames.NAME]: ({ paymentChannelName }) => paymentChannelName,
  [fieldNames.PAYEE]: ({ payee }) => payee.displayName,
  [fieldNames.CURRENCY]: ({ currency }) => currency,
  [fieldNames.IDENTIFIER]: formatIdentifier,
  [fieldNames.ENABLED]: ({ enabled }) =>
    enabled ? null : (
      <VeloStatusIndicator
        type={VeloStatusIndicator.types.WARNING}
        label="Disabled"
        data-testid={TestIds.DISABLED_ICON}
      />
    ),
};

const filtersByName = {
  [fieldNames.NAME]: {
    name: fieldNames.NAME,
    label: <FormattedMessage defaultMessage="Name" />,
    type: 'string',
  },
  [fieldNames.PAYEE]: {
    name: 'payeeId',
    label: <FormattedMessage defaultMessage="Payee" />,
    type: 'entityIdLookup',
    mode: LookupTextField.modes.PAYEE,
  },
  [fieldNames.CURRENCY]: {
    name: fieldNames.CURRENCY,
    label: <FormattedMessage defaultMessage="Currency" />,
    type: 'listCurrency',
    options: Object.keys(CurrencyCountryMap).map((label) => ({
      label,
      value: label,
    })),
  },
  [fieldNames.COUNTRY]: {
    name: fieldNames.COUNTRY,
    label: <FormattedMessage defaultMessage="Country" />,
    type: 'listCountry',
    options: (intl) =>
      getCountries().map((value) => ({
        value,
        label: countryName(value, intl),
      })),
  },
  [fieldNames.ENABLED]: {
    name: fieldNames.ENABLED,
    label: <FormattedMessage defaultMessage="Status" />,
    type: 'list',
    options: ({ intl }) => [
      {
        label: intl.formatMessage({ defaultMessage: 'Enabled' }),
        value: 'true',
      },
      {
        label: intl.formatMessage({ defaultMessage: 'Disabled' }),
        value: 'false',
      },
    ],
  },
};

const config = {
  columns: fieldNames,
  sortableColumns: [fieldNames.NAME, fieldNames.PAYEE],
  getRowProps(datum, index, _, { onClick }) {
    return {
      key: datum.id,
      'aria-label': datum.paymentChannelName,
      icon: 'chevron_right',
      onClick: () => onClick(datum),
      'data-testid': dataTestIdBuilder(TestIds.ROW, index),
    };
  },
  getColumnProps({ name: key, align, size }, ...args) {
    return {
      key,
      align,
      size,
      children: cellFactoryByFieldName[key](...args),
    };
  },
};

const buildTable = (
  columnOrder,
  datumPropTypes,
  filters,
  dnd,
  headingProps
) => {
  const builder = dnd ? VeloDragNDropTableBuilder : VeloTableBuilder;
  const { Table, Headings, Loading, Empty, Error, ...other } = builder({
    ...config,
    filters,
    columnOrder,
    dataProps: {
      data: PropTypes.arrayOf(
        PropTypes.shape({
          /** The id (must be unique). */
          id: string.isRequired,
          /** The name or the payment method. */
          paymentChannelName: string.isRequired,
          /** The currency. */
          currency: string.isRequired,
          /** The enabled state of the payment method. */
          enabled: bool.isRequired,
          ...datumPropTypes,
        })
      ),
      /** A handler triggered when a row is clicked */
      onClick: PropTypes.func.isRequired,
    },
  });

  return {
    ...other,
    Table: (props) => <Table {...props} data-testid={TestIds.TABLE} />,
    Headings: (props) => (
      <Headings {...props} data-testid={TestIds.HEADING} {...headingProps} />
    ),
    Loading: (props) => (
      <Loading
        {...props}
        getRowProps={() => ({ 'data-testid': TestIds.LOADING })}
      />
    ),
    Empty: (props) => (
      <Empty {...props} data-testid={TestIds.EMPTY}>
        {`No ${paymentChannelsConfig.title}s available`}
      </Empty>
    ),
    Error: (props) => <Error {...props} data-testid={TestIds.ERROR} />,
    testIds: TestIds,
  };
};

export const PaymentMethodsList = {
  BackOffice: buildTable(
    [
      columnsByName[fieldNames.NAME],
      columnsByName[fieldNames.PAYEE],
      columnsByName[fieldNames.IDENTIFIER],
      columnsByName[fieldNames.ENABLED],
    ],
    {
      /** The name of the associated payee */
      payee: PropTypes.shape({ displayName: PropTypes.string.isRequired }),
    },
    [
      filtersByName[fieldNames.NAME],
      filtersByName[fieldNames.PAYEE],
      filtersByName[fieldNames.CURRENCY],
      filtersByName[fieldNames.COUNTRY],
      filtersByName[fieldNames.ENABLED],
    ]
  ),
  Payor: buildTable(
    [
      columnsByName[fieldNames.NAME],
      columnsByName[fieldNames.CURRENCY],
      columnsByName[fieldNames.ENABLED],
    ],
    {},
    [
      filtersByName[fieldNames.CURRENCY],
      filtersByName[fieldNames.COUNTRY],
      filtersByName[fieldNames.ENABLED],
    ],
    true,
    { borderBottom: true }
  ),
  Payee: buildTable(
    [
      columnsByName[fieldNames.NAME],
      columnsByName[fieldNames.IDENTIFIER],
      columnsByName[fieldNames.ENABLED],
    ],
    {},
    [
      filtersByName[fieldNames.CURRENCY],
      filtersByName[fieldNames.COUNTRY],
      filtersByName[fieldNames.ENABLED],
    ],
    true
  ),
};

PaymentMethodsList.filtersByName = filtersByName;
PaymentMethodsList.fieldNames = fieldNames;
