import React, { useMemo, useState, useCallback } from 'react';
import { useParams } from 'react-router';
import {
  PayoutPageLoading,
  PayoutReviewPayments,
  PayoutsInstructPage,
  UserRoles,
  VeloNotification,
  VeloCacheHitContext,
} from 'velo-react-components';
import {
  getPayoutSummaryV3 as getPayoutSummary,
  instructPayoutV3 as instructPayout,
  withdrawPayoutV3 as withdrawPayout,
  createQuoteForPayoutV3 as payouts,
  getPaymentsForPayoutV3,
  scheduleForPayout,
  deschedulePayout,
} from 'velo-api/src/entities/payouts';
import { getPaymentsForPayoutV4 } from 'velo-api/src/entities/paymentaudit';
import { listPayeesV4 as listPayees } from 'velo-api/src/entities/payees';
import {
  useAllQueries,
  useCallbackFnAsResultState,
  usePresenter,
  useSerializableMemo,
} from '../../hooks';
import { forkResult } from '../../selectors';
import { usePayoutAutoReQuote } from '../../hooks/payouts';
import { PayoutReviewPresenter } from './PayoutReviewPresenter';

const entitySpec = {
  withdrawPayout: withdrawPayout,
  instructPayout: instructPayout,
  getPayoutSummary: getPayoutSummary,
  getPaymentsByPayout: getPaymentsForPayoutV4,
  getPayoutPayments: getPaymentsForPayoutV3,
  quotePayout: payouts,
  getPayees: listPayees,
  schedulePayout: scheduleForPayout,
  deschedulePayout,
};

const forkHandles = {
  error: (error, props, redirectToWithdrawn) => {
    if (error === 'Payout was not found') {
      redirectToWithdrawn();
    }
    return <PayoutsInstructPage.Error error={error} {...props} />;
  },
  none: (props) => {
    return <PayoutPageLoading {...props} />;
  },
  empty: (props) => {
    return (
      <PayoutsInstructPage.Error
        {...props}
        error={'Something has gone wrong. Please try again.'}
      />
    );
  },
  value: (result, props) => {
    return props.error ? (
      <PayoutsInstructPage.Error {...props} />
    ) : (
      <PayoutsInstructPage.Data
        {...props}
        pageProps={props.content.length === 0 ? undefined : props.pageProps}
      >
        {forkResult(
          {
            empty: () => <PayoutReviewPayments.Empty />,
            value: (data) => (
              <PayoutReviewPayments.Data data={data} onClick={props.onClick} />
            ),
          },
          { result: props.content }
        )}
      </PayoutsInstructPage.Data>
    );
  },
};

const fixedProps = {
  instructFailure: VeloNotification.types.PAYOUT_INSTRUCT_FAILURE,
  instructSuccess: VeloNotification.types.PAYOUT_INSTRUCT_SUCCESS,
  withdrawFailure: VeloNotification.types.PAYOUT_WITHDRAW_FAILURE,
  withdrawSuccess: VeloNotification.types.PAYOUT_WITHDRAW_SUCCESS,
  scheduleSuccess: VeloNotification.types.PAYOUT_SCHEDULE_SUCCESS,
  descheduleFailure: VeloNotification.types.PAYOUT_DESCHEDULE_FAILURE,
  descheduleSuccess: VeloNotification.types.PAYOUT_DESCHEDULE_SUCCESS,
  submissionFailed: 'Payout Submission Failed.',
};

const cacheId = VeloNotification.types.PAYOUT_SCHEDULE_SUCCESS.context;

export function PayoutReviewRoute({ role, payorId }) {
  const { payoutId } = useParams();
  const isAdmin = UserRoles.isPayorAdmin(role);
  const isBop = UserRoles.isBackOffice(role);

  const {
    load,
    quotePayout,
    getPayoutTableData,
    getProps,
    redirectToWithdrawn,
  } = usePresenter(
    PayoutReviewPresenter,
    entitySpec,
    fixedProps,
    // because this is calculated as useCallbackFnAsResultState(load) below
    // useSerializableMemo to create a deep equal to props
    useSerializableMemo({
      payoutId,
      payorId,
      isAdmin,
      isBop,
    })
  );

  const [query, queryProps] = useAllQueries(
    PayoutReviewPayments.filters,
    PayoutReviewPayments.columns.PAYEE_NAME // No sort so default to 1st column
  );

  const [cacheHit] = VeloCacheHitContext.useVeloCacheHitContext(cacheId);

  // loader API composition function - must load all data from multiple APIs
  const [loadData] = useCallbackFnAsResultState(
    useCallback((cb) => load(cb, cacheHit), [load, cacheHit]),
    true
  );

  // Updates on User input table data (1 API)
  const [tableData, setTableData] = useState({});
  useMemo(
    () => getPayoutTableData(query, setTableData),
    [query, getPayoutTableData]
  );

  // Provides the Quote FXSummaries - re-quoted and refreshed
  const { quoteData, autoReQuote } = usePayoutAutoReQuote({
    quotePayout,
    allowQuote: isAdmin,
    payoutId,
  });

  // Begin Auto Re-quoting AFTER load result only
  useMemo(
    () => {
      if (loadData.result) {
        autoReQuote(loadData);
      }
    },
    // only execute on loadData
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loadData]
  );

  const componentProps = getProps(loadData, quoteData, tableData, queryProps);

  // fork on the outcome of loadData or subsequent tableData error
  // componentProps carry the props this iis just determining the render type
  const forkOnData = tableData.error
    ? { ...loadData, error: tableData.error }
    : loadData;

  return (
    <PayoutsInstructPage>
      {forkResult(forkHandles, forkOnData, componentProps, redirectToWithdrawn)}
    </PayoutsInstructPage>
  );
}
