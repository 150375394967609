import React, { useCallback } from 'react';
import {
  usePresenter,
  useSerializableMemo,
  useCallbackFnAsResultState,
  usePaymentChannelCountries,
  useLoggedInUserRole,
} from '../../hooks';
import { createRenderForksFromComponent } from '../../containers';
import { forkResult } from '../../selectors';

import {
  VeloCacheHitContext,
  PaymentMethodView,
  VeloModalSheet,
  VeloNotification,
  UserRoles,
} from 'velo-react-components';

import {
  getPayeeByIdV4,
  getPaymentChannelByIdV4,
  enablePaymentChannelV4,
  deletePaymentChannelV3,
} from 'velo-api/src/entities/payees';
import { getPayorByIdV2 } from 'velo-api/src/entities/payors';

import {
  PayeePaymentMethodViewPresenter,
  PayorPaymentMethodViewPresenter,
  BackofficePaymentMethodViewPresenter,
} from './PaymentMethodViewPresenter';
import { Privileges } from 'velo-data';
import { useParams } from 'react-router';

const entitySpec = {
  enablePaymentMethod: enablePaymentChannelV4,
  deletePaymentMethod: deletePaymentChannelV3,
  getPaymentMethod: getPaymentChannelByIdV4,
  getPayee: getPayeeByIdV4,
  getPayor: getPayorByIdV2,
};

const staticProps = {
  deleteNotes: {
    success: VeloNotification.types.PAYMENT_CHANNEL_DELETE_SUCCESS,
    failure: VeloNotification.types.PAYMENT_CHANNEL_DELETE_FAILURE,
  },
  enableNotes: {
    success: VeloNotification.types.PAYMENT_CHANNEL_ENABLE_SUCCESS,
    failure: VeloNotification.types.PAYMENT_CHANNEL_ENABLE_FAILURE,
  },
};

const forks = createRenderForksFromComponent(PaymentMethodView);

function usePaymentMethodViewPresenter(
  Presenter,
  { paymentMethodId, query, payeeId }
) {
  const role = useLoggedInUserRole();

  const [loader, getProps] = usePresenter(
    Presenter,
    entitySpec,
    staticProps,
    useSerializableMemo({
      paymentMethodId,
      query,
      role,
      payeeId,
      privileges: UserRoles.isBackOffice(role)
        ? // back office can't edit or delete a payment channel
          []
        : [Privileges.PAYMENT_METHOD_EDIT, Privileges.PAYMENT_METHOD_DELETE],
    })
  );

  const [cacheHit] =
    VeloCacheHitContext.useVeloCacheHitContext('payment-channels');

  return [loader, getProps, cacheHit];
}

function Payee({ query, payeeId }) {
  const routeParams = useParams();
  const [loader, getProps, cacheHit] = usePaymentMethodViewPresenter(
    PayeePaymentMethodViewPresenter,
    {
      query,
      payeeId,
      ...routeParams,
    }
  );

  const [result] = useCallbackFnAsResultState(
    useCallback((cb) => loader(cb, cacheHit), [loader, cacheHit])
  );

  const forkProps = getProps(
    result,
    usePaymentChannelCountries().paymentChannelRules.bank
  );

  return (
    <VeloModalSheet
      open={!!routeParams.paymentMethodId}
      onClose={forkProps.onClose}
    >
      {forkResult(forks, result, forkProps)}
    </VeloModalSheet>
  );
}

function Payor({ query, payeeId }) {
  const routeParams = useParams();
  const [loader, getProps, cacheHit] = usePaymentMethodViewPresenter(
    PayorPaymentMethodViewPresenter,
    {
      query,
      payeeId,
      ...routeParams,
    }
  );

  const [result] = useCallbackFnAsResultState(
    useCallback((cb) => loader(cb, cacheHit), [loader, cacheHit])
  );

  const forkProps = getProps(
    result,
    usePaymentChannelCountries().paymentChannelRules.bank
  );

  return (
    <VeloModalSheet
      open={!!routeParams.paymentMethodId}
      onClose={forkProps.onClose}
    >
      {forkResult(forks, result, forkProps)}
    </VeloModalSheet>
  );
}

function BackOffice({ query, payeeId }) {
  const routeParams = useParams();
  const [loader, getProps, cacheHit] = usePaymentMethodViewPresenter(
    BackofficePaymentMethodViewPresenter,
    {
      query,
      payeeId,
      ...routeParams,
    }
  );

  const [result] = useCallbackFnAsResultState(
    useCallback((cb) => loader(cb, cacheHit), [loader, cacheHit])
  );

  const forkProps = getProps(
    result,
    usePaymentChannelCountries().paymentChannelRules.bank
  );

  return (
    <VeloModalSheet
      open={!!routeParams.paymentMethodId}
      onClose={forkProps.onClose}
    >
      {forkResult(forks, result, forkProps)}
    </VeloModalSheet>
  );
}

export const PaymentMethodViewRoute = {
  Payee,
  Payor,
  BackOffice,
};
