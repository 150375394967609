import React from 'react';
import styled from 'astroturf/react';
import { VeloSkeleton } from '../VeloSkeleton';
import { VeloCurrency } from '../VeloCurrency';
import { useIsBelowBreakpoint } from '../hooks/useIsBelowBreakpoint';

const Skeleton = styled(VeloSkeleton)`
  width: 50%;
`;

const TestIds = {
  SKELETON: 'velo-payments-list-amount-skeleton',
};

VeloListCurrencyAmount.testIds = TestIds;

/**
 * NOTE: In future we may want to remove this and add skeleton to VeloCurrency
 */
function VeloListCurrencyAmount({
  skeleton,
  amount,
  currency,
  mobileLabel,
  ...other
}) {
  const isMobile = useIsBelowBreakpoint(useIsBelowBreakpoint.breakpoints.XS);

  return skeleton ? (
    <Skeleton data-testid={TestIds.SKELETON} />
  ) : amount !== undefined && currency ? (
    <span {...other}>
      {!!mobileLabel && isMobile && mobileLabel}
      {!!mobileLabel && isMobile && ' '}
      <VeloCurrency value={amount} currency={currency} />
    </span>
  ) : null;
}

export { VeloListCurrencyAmount };
