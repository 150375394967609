import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'astroturf/react';
import { PayeeCreationTypes } from 'velo-data';
import { VeloSectionGrid } from '../../VeloSectionGrid';
import { ChallengeCodeSwitch } from '../ChallengeCodeSwitch';
import { ChallengeCodeForm } from '../ChallengeCodeForm';
import { TestIds } from '../testIds';
import { AddPayeeMode } from '../propTypes';

const SectionGrid = styled(VeloSectionGrid)`
  padding-top: 1rem;
  padding-left: 0rem;
  padding-right: 0rem;
`;

function ChallengeCodeStep({
  mode,
  type,
  showChallengeCode,
  onChange,
  countryCode,
  payeeCountryRules,
  formProps,
}) {
  const show =
    (mode === AddPayeeMode.PAYOR_CREATE || mode === AddPayeeMode.PAYOR_EDIT) &&
    type &&
    type !== PayeeCreationTypes.CSV_UPLOAD;
  return (
    <>
      {show && (
        <SectionGrid
          sections={[
            {
              heading: <FormattedMessage defaultMessage="Challenge code" />,
              fields: [
                {
                  Component: ChallengeCodeSwitch,
                  value: showChallengeCode,
                  onChange,
                  payeeType: type,
                  countryCode,
                  payeeCountryRules,
                  'data-testid': TestIds.TOGGLE_CHALLENGE_CODE,
                },
                ...(showChallengeCode
                  ? [{ Component: ChallengeCodeForm, ...formProps }]
                  : []),
              ],
            },
          ]}
          render={({ Component, ...props }) => <Component {...props} />}
        />
      )}
    </>
  );
}

export { ChallengeCodeStep };
