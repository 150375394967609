import React from 'react';
import { bool, func, string } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styled from 'astroturf/react';
import { WatchlistStatus } from 'velo-data';
import { VeloStatusIndicator } from '../VeloStatusIndicator';
import { VeloAccordianGrid } from '../VeloAccordianGrid';
import { VeloDate } from '../VeloDate';
import { VeloTime } from '../VeloTime';
import { WatchlistStatusIndicator } from '../WatchlistStatusIndicator';
import { Description } from '../Description';
import { VeloButton } from '../VeloButton';
import { VeloIcon } from '../VeloIcon';
import { VeloStatefulButton } from '../VeloStatefulButton';
import { PayableStatusIndicator } from '../PayableStatusIndicator';

const name = 'compliance';

const propTypes = {
  lockedOut: bool,
  lockedOutTimestamp: string,
  onUnlock: func,
  disabled: bool,
  disabledComment: string,
  disabledUpdatedTimestamp: string,
  pausePayment: bool,
  enhancedKycCompleted: bool,
  kycCompletedTimestamp: string,
  watchlistStatus: string,
  watchlistStatusUpdatedTimestamp: string,
};

const LockedOutLabel = styled('div')`
  display: flex;
  align-items: center;
`;

const LockedOutIcon = styled(VeloIcon).attrs({ icon: 'warning' })`
  @import 'velo-variables';
  color: velo-color('token-color-system-warning-default');
  margin-right: 0.5rem;
`;

const LockedOutMessage = styled('div')`
  margin: 1rem 1rem 1rem 0rem;
`;

const LockedOutButton = styled('div')`
  display: flex;
  justify-content: flex-end;
  margin-right: 1rem;
`;

function LockedOut({ lockedOutTimestamp, onUnlock }) {
  return (
    <VeloAccordianGrid.Callout
      type={VeloAccordianGrid.Callout.types.WARNING}
      role="alert"
    >
      <Description
        id="lockedOut"
        label={
          <LockedOutLabel>
            <LockedOutIcon />
            <FormattedMessage tagName="span" defaultMessage="Locked out" />
          </LockedOutLabel>
        }
        value={
          <>
            <LockedOutMessage>
              <FormattedMessage
                defaultMessage="Locked out since {date} {time}"
                values={{
                  date: (
                    <VeloDate.Long
                      value={lockedOutTimestamp}
                      tag={React.Fragment}
                    />
                  ),
                  time: (
                    <VeloTime.Long
                      value={lockedOutTimestamp}
                      tag={React.Fragment}
                    />
                  ),
                }}
              />
            </LockedOutMessage>
            <LockedOutButton>
              <VeloStatefulButton
                component={VeloButton.Secondary}
                stages={{
                  [VeloStatefulButton.stages.PENDING]: {
                    children: (
                      <FormattedMessage defaultMessage="Unlocking..." />
                    ),
                    disabled: true,
                  },
                }}
                data-testid="unlock"
                onClick={onUnlock}
                ripple={false}
              >
                <FormattedMessage defaultMessage="Unlock" />
              </VeloStatefulButton>
            </LockedOutButton>
          </>
        }
      />
    </VeloAccordianGrid.Callout>
  );
}

function PayeeStatus({ disabled, disabledUpdatedTimestamp, disabledComment }) {
  return (
    <VeloAccordianGrid.Callout
      type={disabled ? VeloAccordianGrid.Callout.types.WARNING : undefined}
    >
      <Description
        id="disabled"
        label={<FormattedMessage defaultMessage="Payee status" />}
        value={
          disabled ? (
            <>
              <VeloStatusIndicator
                type={VeloStatusIndicator.types.WARNING}
                label={<FormattedMessage defaultMessage="Disabled" />}
              />
              <VeloDate.Long value={disabledUpdatedTimestamp} tag="div" />
              <VeloTime.Long value={disabledUpdatedTimestamp} tag="div" />
              {disabledComment}
            </>
          ) : (
            <VeloStatusIndicator
              type={VeloStatusIndicator.types.SUCCESS}
              label={<FormattedMessage defaultMessage="Enabled" />}
            />
          )
        }
      />
    </VeloAccordianGrid.Callout>
  );
}

function Paused({ pausePayment, pausePaymentTimestamp }) {
  return (
    <VeloAccordianGrid.Callout type={VeloAccordianGrid.Callout.types.FAILED}>
      <Description
        id="pausePayment"
        label={<FormattedMessage defaultMessage="Payments" />}
        value={
          <>
            <VeloStatusIndicator
              type={VeloStatusIndicator.types.LOCKED}
              label={<FormattedMessage defaultMessage="On hold" />}
            />
            <VeloDate.Long value={pausePaymentTimestamp} tag="div" />
            <VeloTime.Long value={pausePaymentTimestamp} tag="div" />
          </>
        }
      />
    </VeloAccordianGrid.Callout>
  );
}

const watchlistCalloutType = {
  [WatchlistStatus.NONE]: VeloAccordianGrid.Callout.types.SUCCESS,
  [WatchlistStatus.PENDING]: VeloAccordianGrid.Callout.types.SUCCESS,
  [WatchlistStatus.REVIEW]: VeloAccordianGrid.Callout.types.WARNING,
  [WatchlistStatus.PASSED]: VeloAccordianGrid.Callout.types.SUCCESS,
  [WatchlistStatus.FAILED]: VeloAccordianGrid.Callout.types.FAILED,
};

function Watchlist({ watchlistStatus, watchlistStatusUpdatedTimestamp }) {
  return (
    <VeloAccordianGrid.Callout type={watchlistCalloutType[watchlistStatus]}>
      <Description
        id="watchlistStatus"
        label={<FormattedMessage defaultMessage="Watchlist status" />}
        value={
          <>
            <WatchlistStatusIndicator watchlistStatus={watchlistStatus} />
            <VeloDate.Long value={watchlistStatusUpdatedTimestamp} tag="div" />
            <VeloTime.Long value={watchlistStatusUpdatedTimestamp} tag="div" />
          </>
        }
      />
    </VeloAccordianGrid.Callout>
  );
}

function PayeeViewComplianceSection({
  payee: {
    lockedOut,
    lockedOutTimestamp,
    onUnlock,
    disabled,
    disabledComment,
    disabledUpdatedTimestamp,
    pausePayment,
    pausePaymentTimestamp,
    enhancedKycCompleted,
    kycCompletedTimestamp,
    watchlistStatus,
    watchlistStatusUpdatedTimestamp,
    payableStatus,
  },
}) {
  return {
    name,
    heading: <FormattedMessage defaultMessage="Compliance" />,
    fields: [
      ...(lockedOut
        ? [
            {
              render: () => (
                <LockedOut
                  lockedOutTimestamp={lockedOutTimestamp}
                  onUnlock={onUnlock}
                />
              ),
            },
          ]
        : []),
      // only show if provided not just true
      ...(payableStatus !== undefined
        ? [
            {
              id: 'payableStatus',
              label: <FormattedMessage defaultMessage="Payable status" />,
              value: <PayableStatusIndicator payableStatus={payableStatus} />,
            },
          ]
        : []),
      ...(disabled !== undefined
        ? [
            {
              render: () => (
                <PayeeStatus
                  disabled={disabled}
                  disabledComment={disabledComment}
                  disabledUpdatedTimestamp={disabledUpdatedTimestamp}
                />
              ),
            },
          ]
        : []),
      ...(pausePayment
        ? [
            {
              render: () => (
                <Paused
                  pausePayment={pausePayment}
                  pausePaymentTimestamp={pausePaymentTimestamp}
                />
              ),
            },
          ]
        : []),
      ...(enhancedKycCompleted
        ? [
            {
              id: 'enhancedKycCompleted',
              label: <FormattedMessage defaultMessage="Enhanced KYC" />,
              value: (
                <>
                  <FormattedMessage defaultMessage="On" />
                  <VeloDate.Long value={kycCompletedTimestamp} tag="div" />
                  <VeloTime.Long value={kycCompletedTimestamp} tag="div" />
                </>
              ),
            },
          ]
        : []),
      {
        render: () => (
          <Watchlist
            watchlistStatus={watchlistStatus}
            watchlistStatusUpdatedTimestamp={watchlistStatusUpdatedTimestamp}
          />
        ),
      },
    ],
  };
}

PayeeViewComplianceSection.sectionName = name;
PayeeViewComplianceSection.propTypes = propTypes;
export { PayeeViewComplianceSection };
