/* eslint-disable react/style-prop-object */
import React from 'react';
import { number, string, oneOf } from 'prop-types';
import {
  FormattedNumber,
  FormattedNumberParts,
  FormattedMessage,
} from 'react-intl';
import styled from 'astroturf/react';
import Money from 'js-money';

const Error = styled('span')`
  @import 'velo-variables';

  color: velo-color('token-color-system-error-default');
`;

VeloCurrency.propTypes = {
  /** The value to display (in minor units) */
  value: number,
  /** The currency code to use (e.g. "USD", "GBP", etc.) */
  currency: string.isRequired,
  /** The currency display */
  currencyDisplay: oneOf(['symbol', 'code', 'name']),
};

VeloCurrency.defaultProps = {
  currencyDisplay: 'symbol',
};

function extractCurrencyFromParts(message, parts) {
  return parts.reduce((xs, { type, value }) => {
    if (type === 'currency') {
      if (xs.length === 0) {
        return xs.concat(<span key="currency">{value} </span>);
      }
      return xs.concat(<span key="currency"> {value}</span>);
    }

    if (type === 'integer') {
      return xs.concat(<span key="message">{message}</span>);
    }

    return xs;
  }, []);
}

/**
 * Currency component. Accepts a value in minor units and
 * displays using the current react-intl locale.
 */
function VeloCurrency({
  value,
  currency,
  currencyDisplay,
  children,
  ...other
}) {
  const baseProps = {
    style: 'currency',
    currency,
    currencyDisplay,
    ...other,
  };
  if (value === undefined) {
    return (
      <FormattedNumberParts value={0} {...baseProps}>
        {(parts) =>
          extractCurrencyFromParts(
            children || <FormattedMessage defaultMessage="Unknown" />,
            parts
          )
        }
      </FormattedNumberParts>
    );
  }

  try {
    return (
      <FormattedNumber
        value={Money.fromInteger(value, currency)}
        {...baseProps}
      >
        {children}
      </FormattedNumber>
    );
  } catch (_) {
    return (
      <Error>
        <FormattedMessage defaultMessage="Invalid currency" />
      </Error>
    );
  }
}

export { VeloCurrency };
