const root = 'velo-payouts-create';
const TestIds = {
  SINGLE_PAYOUT_PAYEE_MEMO: `${root}-single-payout-memo`,
  SINGLE_PAYOUT_BANK_ACCOUNT: `${root}-single-payout-bank-account`,
  SINGLE_PAYOUT_TRANSMISSION_TYPE: `${root}-single-payout-transmission-types`,
  SINGLE_PAYOUT_CURRENCIES: `${root}-single-payout-currencies`,
  SINGLE_PAYOUT_AMOUNT: `${root}-single-payout-amount`,
  SINGLE_PAYOUT_PAYMENT_ID: `${root}-single-payout-payment-id`,
  SINGLE_PAYOUT_PAYEE_NAME: `${root}-single-payout-payee-name`,
  SINGLE_PAYOUT_REMOTE_SYSTEM_ID: `${root}-single-payout-remote-system-id`,
  SINGLE_PAYOUT_REMOTE_SUPPLIER_ID: `${root}-single-payout-remote-supplier-id`,
  SINGLE_PAYOUT_PAYMENT_MEMO: `${root}-single-payment-memo`,
  SINGLE_PAYOUT_PAYMENT_METADATA: `${root}-single-payment-metadata`,
  SINGLE_PAYOUT_INVOICEE_ID: `${root}-single-payment-invoicee-id`,
  GROUP_PAYOUT_PAYEE_MEMO: `${root}-group-payout-memo`,
  GROUP_DOWNLOAD_SAMPLE_CSV: `${root}-group-download-sample-csv`,
  GROUP_SHOW_INSTRUCTIONS_CSV: `${root}-show-instructions-csv`,
  GROUP_CSV_INSTRUCTIONS: `${root}-instructions-csv`,
  GROUP_PAYOUT_ERROR: `${root}-group-payout-error`,
  PAYOUT_FROM: `${root}-payor-from-select`,
  PAYOUT_TO: `${root}-payor-to-select`,
};

export default TestIds;
