import React from 'react';
import { oneOf, shape, string, number } from 'prop-types';
import styled from 'astroturf/react';
import { VeloTypography } from '../VeloTypography';
import { ResultContainer } from '../ResultContainer';
import { VeloSkeleton } from '../VeloSkeleton';
import { VeloDateTime } from '../VeloDateTime';
import { VeloPropTypes } from '../VeloPropTypes';
import { FormattedMessage } from 'react-intl';

const SummaryContainer = styled('div')`
  display: flex;
  padding: 0 1rem;
  flex-wrap: wrap;
`;

const Column = styled('div')`
  margin-right: 2.5rem;
  display: flex;
  flex-direction: column;
  &:last-child {
    margin-right: 0;
  }
  span:first-child {
    flex-grow: 1;
  }
`;

const SummaryType = {
  PAYEES: 'payees',
  PAYMENTS_BY_PAYOUT: 'payments-by-payout',
};

const root = {
  PAYEES: 'payee-summary-total',
  PAYMENTS_BY_PAYOUT: 'payments-by-payout',
};
const TestIds = {
  [SummaryType.PAYEES]: {
    TOTAL_COUNT: `${root.PAYEES}-count`,
    TOTAL_INVITED: `${root.PAYEES}-invited`,
    TOTAL_REGISTERED: `${root.PAYEES}-registered`,
    TOTAL_ONBOARDED: `${root.PAYEES}-onboarded`,
    TOTAL_WATCHLIST: `${root.PAYEES}-watchlist-failed`,
  },
  [SummaryType.PAYMENTS_BY_PAYOUT]: {
    INSTRUCTED_DATE_TIME: `${root.PAYMENTS_BY_PAYOUT}-instructed-date`,
    TOTAL_PAYMENTS: `${root[SummaryType.PAYMENTS_BY_PAYOUT]}-total-payments`,
    CONFIRMED_PAYMENTS: `${root.PAYMENTS_BY_PAYOUT}-confirmed-payments`,
    RELEASED_PAYMENTS: `${root.PAYMENTS_BY_PAYOUT}-released-payments`,
    FAILED_PAYMENTS: `${root[SummaryType.PAYMENTS_BY_PAYOUT]}-failed-payments`,
    INCOMPLETE_PAYMENTS: `${root.PAYMENTS_BY_PAYOUT}-incomplete-payments`,
  },
};

const implMap = {
  [SummaryType.PAYEES]: {
    types: {
      totalPayeesCount: number.isRequired,
      totalInvitedCount: number.isRequired,
      totalRegisteredCount: number.isRequired,
      totalOnboardedCount: number.isRequired,
      totalWatchlistFailedCount: number.isRequired,
    },
    format: ({
      totalPayeesCount,
      totalInvitedCount,
      totalRegisteredCount,
      totalOnboardedCount,
      totalWatchlistFailedCount,
    }) => [
      {
        heading: <FormattedMessage defaultMessage="Total number of Payees" />,
        value: totalPayeesCount,
        testId: TestIds[SummaryType.PAYEES].TOTAL_COUNT,
      },
      {
        heading: <FormattedMessage defaultMessage="Invited" />,
        value: totalInvitedCount,
        testId: TestIds[SummaryType.PAYEES].TOTAL_INVITED,
      },
      {
        heading: <FormattedMessage defaultMessage="Registered" />,
        value: totalRegisteredCount,
        testId: TestIds[SummaryType.PAYEES].TOTAL_REGISTERED,
      },
      {
        heading: <FormattedMessage defaultMessage="Onboarded" />,
        value: totalOnboardedCount,
        testId: TestIds[SummaryType.PAYEES].TOTAL_ONBOARDED,
      },
      {
        heading: <FormattedMessage defaultMessage="Watchlist Failed" />,
        value: totalWatchlistFailedCount,
        testId: TestIds[SummaryType.PAYEES].TOTAL_WATCHLIST,
      },
    ],
  },
  [SummaryType.PAYMENTS_BY_PAYOUT]: {
    propTypes: {
      confirmedPayments: number.isRequired,
      returnedPayments: number.isRequired,
      incompletePayments: number.isRequired,
      instructedDateTime: string.isRequired,
      releasedPayments: number.isRequired,
      totalPayments: number.isRequired,
    },
    format: ({
      confirmedPayments,
      returnedPayments,
      incompletePayments,
      instructedDateTime,
      releasedPayments,
      totalPayments,
    }) => [
      {
        heading: 'Payout Instructed',
        value: instructedDateTime ? (
          <VeloDateTime value={instructedDateTime} />
        ) : undefined,
        testId: TestIds[SummaryType.PAYMENTS_BY_PAYOUT].INSTRUCTED_DATE_TIME,
      },
      {
        heading: 'Payments',
        value: totalPayments,
        testId: TestIds[SummaryType.PAYMENTS_BY_PAYOUT].TOTAL_PAYMENTS,
      },
      {
        heading: 'Confirmed',
        value: confirmedPayments,
        testId: TestIds[SummaryType.PAYMENTS_BY_PAYOUT].CONFIRMED_PAYMENTS,
      },
      {
        heading: 'Released',
        value: releasedPayments,
        testId: TestIds[SummaryType.PAYMENTS_BY_PAYOUT].RELEASED_PAYMENTS,
      },
      {
        heading: 'Returned',
        value: returnedPayments,
        testId: TestIds[SummaryType.PAYMENTS_BY_PAYOUT].FAILED_PAYMENTS,
      },
      {
        heading: 'Incomplete',
        value: incompletePayments,
        testId: TestIds[SummaryType.PAYMENTS_BY_PAYOUT].INCOMPLETE_PAYMENTS,
      },
    ],
  },
};

const SummaryColumn = ({ heading, value, testId }) => (
  <Column>
    <VeloTypography use="summaryLabel">{heading}</VeloTypography>
    <VeloTypography use="summaryData" data-testid={testId}>
      {value !== undefined ? value : <VeloSkeleton />}
    </VeloTypography>
  </Column>
);

const Summary = ({ headings }) => (
  <SummaryContainer>
    {headings.map((props) => (
      <SummaryColumn key={props.testId} {...props} />
    ))}
  </SummaryContainer>
);

function makeComponent({ types, format }) {
  const LoadingComponent = () => <Summary headings={format({})} />;
  const Component = ({ result }) => <Summary headings={format(result)} />;
  // Create some strong prop checking on the component
  Component.propTypes = {
    result: shape(types),
  };
  return ResultContainer({ LoadingComponent })(Component);
}

class VeloSummary extends React.PureComponent {
  static testIds = TestIds;

  static propTypes = {
    /**
     * The type of summary to be shown.
     */
    type: oneOf(Object.values(SummaryType)).isRequired,
    /**
     * A result type with the summary data.
     */
    summary: VeloPropTypes.result().isRequired,
  };

  Component = makeComponent(implMap[this.props.type]);

  render() {
    const { summary } = this.props;
    const Component = this.Component;
    return <Component {...summary} />;
  }
}

VeloSummary.SummaryType = SummaryType;

export { VeloSummary };
