import React from 'react';
import { bool, string } from 'prop-types';
import styled from 'astroturf/react';

const Overlay = styled('div')`
  @import 'velo-variables';

  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: velo-color('token-color-system-page-background');
  opacity: 0.6;
  z-index: $velo-base-index-default;

  &.transparent {
    opacity: 0;
  }
`;

Overlay.propTypes = {
  /** Show or hide the overlay. */
  transparent: bool,
};

VeloOverlay.propTypes = {
  /** Show or hide the overlay. */
  show: bool,
  /** Custom CSS class(es). */
  className: string,
};

/**
 * Overlay suitable for disabling content.
 * Render the component and it will cover the entire parent.
 *
 * Note that position: absolute is required on the parent
 * element for the overlay to position correctly.
 */
function VeloOverlay({ show, transparent, ...other }) {
  return show ? <Overlay transparent={transparent} {...other} /> : null;
}

export { VeloOverlay };
