import { VeloTypography } from '../VeloTypography';
import styled from 'astroturf/react';

const VeloLogoPageText = styled(VeloTypography).attrs({
  tag: 'p',
  use: 'bodyText',
})`
  margin: 0 0 1.2rem 0;
  border: none;
  text-align: center;
`;

export { VeloLogoPageText };
