import React, { useMemo } from 'react';
import { Switch, Route, Redirect } from 'react-router';
import {
  VeloNotification,
  InviteOTP,
  InvitePassword,
  InviteRegisterYubikey,
  InviteRegisterTOTP,
  MFATypes,
  OnboardingPage,
} from 'velo-react-components';
import {
  generateOTP as sendOTP,
  validateOTP,
  validatePassword as validatePasswordWithToken,
  submitPassword as updatePassword,
  validateMFA,
  registerMFA,
} from 'velo-api/src/entities/tokens';
import { useAPIMethods, useAPIContext } from 'velo-api-react-hooks';
import { useWireframe, useCallbackFnAsResultState } from '../../hooks';
import { useAppContext } from '../../context';

import {
  PasswordValidationPresenter,
  TwoFactorPasswordValidationPresenter,
  MFAValidationPresenter,
  MFARegistrationPresenter,
  OTPValidationPresenter,
} from './UserTokenPresenters';
import { forkResult } from '../../selectors';

const entitySpec = {
  sendOTP,
  validateOTP,
  validatePasswordWithToken,
  updatePassword,
  validateMFA,
  registerMFA,
};

const secureSteps = 3;
const twoFactorSteps = 2;
const userTokenInvalidNotification = VeloNotification.types.USER_TOKEN_INVALID;
const resendSmsNotification = VeloNotification.types.RESEND_SMS_SUCCESS;
const QR_CODE_SIZE = InviteRegisterTOTP.QR_CODE_SIZE;

function PasswordRoute({ history, otpToken, mfaType }) {
  const wireframe = useWireframe(history);
  const entity = useAPIMethods(entitySpec);
  const [componentProps] = useMemo(
    () =>
      PasswordValidationPresenter({ otpToken }, wireframe, entity, {
        userTokenInvalidNotification,
        mfaType,
      }),
    [otpToken, wireframe, entity, mfaType]
  );

  return (
    <OnboardingPage activeStep={1} steps={secureSteps}>
      <InvitePassword {...componentProps} />
    </OnboardingPage>
  );
}

function TwoFactorPasswordRoute({ history, otpToken }) {
  // Use api.onSessionState to login the user upon MFA validated
  const { storeTokenPair } = useAppContext();
  const wireframe = useWireframe(history);
  const entity = useAPIMethods(entitySpec);
  const [componentProps] = useMemo(
    () =>
      TwoFactorPasswordValidationPresenter({ otpToken }, wireframe, entity, {
        userTokenInvalidNotification,
        storeTokenPair,
      }),
    [otpToken, wireframe, entity, storeTokenPair]
  );

  return (
    <OnboardingPage activeStep={1} steps={twoFactorSteps}>
      <InvitePassword {...componentProps} />
    </OnboardingPage>
  );
}

function RegisterYubikeyRoute({ history, otpToken }) {
  // Use api.onSessionState to login the user upon MFA validated
  const { storeTokenPair } = useAppContext();
  const wireframe = useWireframe(history);
  const entity = useAPIMethods(entitySpec);

  const [componentProps] = useMemo(
    () =>
      MFAValidationPresenter({ otpToken }, wireframe, entity, {
        userTokenInvalidNotification,
        storeTokenPair,
      }),
    [otpToken, wireframe, entity, storeTokenPair]
  );

  return (
    <OnboardingPage activeStep={2} steps={secureSteps}>
      <InviteRegisterYubikey {...componentProps} />
    </OnboardingPage>
  );
}

/**
 * Error and empty forks are not handled as the presenter will redirect
 * the user to login.
 */
const forks = {
  none: () => <InviteRegisterTOTP.Loading />,
  value: (data, props) => <InviteRegisterTOTP {...data} {...props} />,
};

function RegisterTOTPRoute({ history, otpToken }) {
  // Use api.onSessionState to login the user upon MFA validated
  const { storeTokenPair } = useAppContext();
  const wireframe = useWireframe(history);
  const entity = useAPIMethods(entitySpec);
  const api = useAPIContext();

  const [componentProps] = useMemo(
    () =>
      MFAValidationPresenter({ otpToken }, wireframe, entity, {
        userTokenInvalidNotification,
        storeTokenPair,
      }),
    [otpToken, wireframe, entity, storeTokenPair]
  );

  const [loader, registrationProps] = useMemo(
    () =>
      MFARegistrationPresenter({ otpToken }, wireframe, entity, {
        userTokenInvalidNotification,
        host: api.host,
        QRCodeSize: QR_CODE_SIZE,
      }),
    [otpToken, wireframe, entity, api.host]
  );
  const [result] = useCallbackFnAsResultState(loader);

  return (
    <OnboardingPage activeStep={2} steps={secureSteps}>
      {forkResult(forks, result, {
        ...componentProps,
        ...registrationProps,
      })}
    </OnboardingPage>
  );
}

function OTPRoute(props) {
  return (
    <OnboardingPage activeStep={0} steps={secureSteps}>
      <InviteOTP {...props} />
    </OnboardingPage>
  );
}

function TwoFactorOTPRoute(props) {
  return (
    <OnboardingPage activeStep={0} steps={twoFactorSteps}>
      <InviteOTP.TwoFactor {...props} />
    </OnboardingPage>
  );
}

const RoutesMFATypeMap = {
  [MFATypes.SMS]: [TwoFactorOTPRoute, TwoFactorPasswordRoute],
  [MFATypes.YUBIKEY]: [OTPRoute, PasswordRoute],
  [MFATypes.TOTP]: [OTPRoute, PasswordRoute],
};

function UserInviteRoutes({ token, history, ...props }) {
  const [OTP, Password] = RoutesMFATypeMap[props.mfaType];
  const wireframe = useWireframe(history);
  const entity = useAPIMethods(entitySpec);

  const [otpProps] = useMemo(
    () =>
      OTPValidationPresenter({ token }, wireframe, entity, {
        userTokenInvalidNotification,
        resendSmsNotification,
      }),
    [entity, token, wireframe]
  );

  return (
    <Switch>
      <Route
        path={wireframe.navigateToInviteCreatePassword.path}
        render={({ history, match }) => (
          <Password
            {...props}
            history={history}
            otpToken={match.params.otpToken}
          />
        )}
      />
      <Route
        path={wireframe.navigateToInviteRegisterYubikey.path}
        render={({ history, match }) => (
          <RegisterYubikeyRoute
            {...props}
            history={history}
            otpToken={match.params.otpToken}
          />
        )}
      />
      <Route
        path={wireframe.navigateToInviteRegisterTOTP.path}
        render={({ history, match }) => (
          <RegisterTOTPRoute
            {...props}
            history={history}
            otpToken={match.params.otpToken}
          />
        )}
      />
      <Route
        path={wireframe.navigateToInvite.path}
        render={(routeProps) => (
          <OTP {...routeProps} {...props} {...otpProps} />
        )}
      />
      <Redirect to={wireframe.navigateToInvite.path} />
    </Switch>
  );
}

export { UserInviteRoutes };
