export function unregisterMFAForSelf(send) {
  return (body, headers, ...args) =>
    send(
      {
        path: '/v2/users/self/mfa/unregister',
        pathParameters: {},
        type: 'post',
        query: undefined,
        body: body,
        headers: headers,
        auth: 'oauth2',
        contentType: 'application/json',
      },
      ...args
    );
}
