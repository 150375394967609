import React from 'react';
import { FormattedMessage } from 'react-intl';
import { VeloTextField } from '../../VeloTextField';

// Validation messages
const messages = {
  valueMissing: (
    <FormattedMessage defaultMessage="Please enter a Payment Method Name" />
  ),
  patternMismatch: (
    <FormattedMessage defaultMessage="Letters, numbers, spaces and dashes only" />
  ),
  noLeadingSpaces: <FormattedMessage defaultMessage="No leading spaces" />,
  noTrailingSpaces: <FormattedMessage defaultMessage="No trailing spaces" />,
};

const validChars = 'A-Za-z0-9-_';

/**
 * Payment Channel Name text field.
 *
 * Accepts alphanumerics, dashes, spaces and underscores
 * up to 30 characters long.
 */
export class ChannelName extends React.Component {
  state = { validationMessage: messages.valueMissing };

  handleBlur = (e) => {
    const value = e.target.value;
    const firstChar = value.charAt(0);
    const lastChar = value.charAt(value.length - 1);
    // Use the appropriate message depending on the
    // validation error.
    this.setState({
      validationMessage:
        firstChar === ' '
          ? messages.noLeadingSpaces
          : lastChar === ' '
          ? messages.noTrailingSpaces
          : e.target.validity.patternMismatch
          ? messages.patternMismatch
          : messages.valueMissing,
    });
  };

  render() {
    return (
      <VeloTextField
        label={<FormattedMessage defaultMessage="Payment Method Name" />}
        maxLength={30}
        pattern={`[${validChars}]+([${validChars} ]+[${validChars}])*`}
        helpText={{
          validationMsg: true,
          children: this.state.validationMessage,
        }}
        onBlur={this.handleBlur}
        {...this.props}
      />
    );
  }
}
