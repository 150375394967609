import React from 'react';
import { bool } from 'prop-types';
import styled from 'astroturf/react';

const Step = styled('div')`
  @mixin no-content-border {
    :global(.velo-stepper-content) {
      border-left: none;
    }
  }

  margin-bottom: 0.5rem;

  &:last-child {
    @include no-content-border;
  }

  &.last {
    @include no-content-border;
  }
`;

Step.propTypes = {
  last: bool,
};

VeloStepperStep.propTypes = {
  /**
   * Explicitly define the last child.
   * There is NO "last of class" support in CSS.
   * last-of-type is for elements.
   * last-of-child for any children with the same parent.
   * So if content is not of the same parent (wrapped in a form)
   * then the border-left is incorrectly rendered
   */
  last: bool,
};

/**
 * Represents a single step in a vertical stepper.
 *
 * Use in combination with <VeloStepperLabel>. <VeloStepperIcon>,
 * <VeloStepperPrimaryText> and <VeloStepperConent>. For example:
 *
 * <VeloStepperStep>
 *   <VeloStepperLabel>
 *     <VeloStepperIcon>1</VeloStepperIcon>
 *     <VeloStepperText>Step title</VeloStepperText>
 *   </VeloStepperLabel>
 *   <VeloStepperContent>
 *     Your step content here
 *   </VeloStepperContent>
 * </VeloStepperStep>
 */
function VeloStepperStep(props) {
  return <Step {...props} />;
}

export { VeloStepperStep };
