import React from 'react';
import { bool, oneOf } from 'prop-types';
import styled from 'astroturf/react';
import { VeloTypography } from '../VeloTypography';
import { VeloSkeleton } from '../VeloSkeleton';

const Content = styled(VeloTypography)`
  @import 'velo-variables';

  &.error {
    color: velo-color('token-color-system-error-default');
  }

  &.disabled {
    opacity: 0.4;
  }
`;

Content.propTypes = {
  error: bool,
  disabled: bool,
};

const Loading = styled(VeloSkeleton)`
  width: 66%;
  margin-bottom: 10px;
`;

PaymentChannelViewPayor.propTypes = {
  /** Used to indicate loading or errors. */
  type: oneOf(['loading', 'error']),
  /** Render in the disabled state. */
  disabled: bool,
};

/**
 * Used to display an individual payor, optionally
 * in a loading or error state.
 */
export function PaymentChannelViewPayor({
  type,
  disabled,
  children,
  ...other
}) {
  return (
    <Content
      use="itemContent"
      tag="div"
      error={type === 'error'}
      disabled={disabled}
      {...other}
      data-testid="velo-payments-channel-view-payor"
    >
      {type === 'loading' ? (
        <>
          {[...Array(3)].map((_, index) => (
            <Loading
              key={`payor-skeleton-idx-${parseInt(index.toString())}`}
              data-testid="velo-payments-channel-payors-skeleton"
            />
          ))}
        </>
      ) : (
        children
      )}
    </Content>
  );
}
