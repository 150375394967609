import { useMemo } from 'react';
import { useFieldGridForm } from '../useFieldGridForm';

function createFieldGridConfig({ steps, ...rest }) {
  return {
    ...rest,
    createSections(...args) {
      return steps.reduce(
        (sections, step) => sections.concat(step.createSections(...args)),
        []
      );
    },
    getInitialValues(...args) {
      return steps.reduce(
        (values, step) => ({ ...values, ...step.getInitialValues(...args) }),
        {}
      );
    },
  };
}

export function useSteppedGridForm(config, props) {
  const hookProps = useFieldGridForm(
    useMemo(() => createFieldGridConfig(config), [config]),
    props
  );

  return [
    config.steps.reduce(
      (steps, step) => steps.concat(step.mapToSteps(hookProps, props)),
      []
    ),
    ...hookProps,
  ];
}
