import React from 'react';
import { ResultContainer } from '../../ResultContainer';
import { VeloSkeleton } from '../../VeloSkeleton';
import { VeloTypography } from '../../VeloTypography';

/**
 * Create the base component.
 */
const Component = ResultContainer({
  ErrorComponent: ({ error }) => (
    <VeloTypography use="itemContentError">{error}</VeloTypography>
  ),
  LoadingComponent: ({ loadingTestId }) => (
    <VeloSkeleton data-testid={loadingTestId} />
  ),
})(({ result, loadingTestId, ...other }) => <span {...other}>{result}</span>);

/**
 * Used to render a generic result within a Payments View.
 * Used for Payee Name, Payor Name, etc.
 * Supports loading, error and success states.
 */
function PaymentViewResult({
  result,
  error,
  errorMessage,
  skeleton,
  ...other
}) {
  const props = skeleton
    ? {}
    : { result, error: error ? errorMessage : undefined };
  return <Component {...props} {...other} />;
}

export { PaymentViewResult };
