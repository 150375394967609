import React, { useCallback } from 'react';
import { arrayOf, func, string, oneOfType, any, object } from 'prop-types';
import { VeloTextField } from '../VeloTextField';
import { VeloSectionGrid } from '../VeloSectionGrid';

// Props to apply if validation should be disabled because a
// field is masked.
const maskedProps = {
  required: false,
  pattern: undefined,
  minLength: undefined,
  maxLength: undefined,
};

VeloFieldGrid.propTypes = {
  ...VeloSectionGrid.type({
    /** The field name. */
    name: string,
    /** The current field value. */
    value: any,
    /**
     * By default a `VeloTextField` is used but
     * you can use this prop to render something
     * else.
     *
     * Either a function or an object for styled components using Astroturf.
     */
    Component: oneOfType([func, object]),
  }),
  /**
   * Called when a field is changed.
   * `event.target.name` will contain the field name.
   */
  onChange: func,
  /**
   * An array of masked field names.
   * Fields in this list are not validated.
   */
  masked: arrayOf(string),
};

VeloFieldGrid.defaultProps = {
  masked: [],
};

/**
 * Render a grid of field components. Requires an array of sections that
 * contain fields which are generally components based on `VeloTextField`
 * or `VeloSelect`.
 *
 * Each field requires a `name` and `value` prop. The `name` is used
 * for the `onChange` event and to generate the `data-testid`.
 *
 * Under the hood, this uses `<VeloGrid/>` to render the fields, and requires
 * the same prop types to make this work.
 */
function VeloFieldGrid({ onChange, masked, ...other }) {
  const render = useCallback(
    ({ Component = VeloTextField, ...field }) => (
      <Component
        id={field.name}
        value={field.value}
        onChange={onChange}
        data-testid={field.name}
        {...field}
        {...(masked.indexOf(field.name) !== -1 &&
          field.value && {
            ...maskedProps,
          })}
      />
    ),
    [onChange, masked]
  );

  return <VeloSectionGrid render={render} {...other} />;
}

export { VeloFieldGrid };
