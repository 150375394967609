import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import {
  LoginPage,
  VeloNotification,
  ResetInstructionsSentPage,
  MFADisabledInstructionsPage,
} from 'velo-react-components';
import { useLoginAPI, useAPIMethods } from 'velo-api-react-hooks';
import { SendError } from 'velo-api/src/send';
import { unregisterMFAForSelf as selfUnregisterMFA } from 'velo-api/src/entities/users';
import { useWireframe, useLegalLinksList } from '../../hooks';
import { postLoginSelector, formatError } from '../../selectors';
import { useAppContext } from '../../context';
import { mapCallbackArg } from '../../containers';
import { ApplicationVariantEnum } from '../../AppConfigs';
import { LoginPresenter } from './LoginPresenter';

const AppVariantConfigMap = {
  [ApplicationVariantEnum.BackOffice]: {
    loginPageMode: LoginPage.modes.BACKOFFICE,
    defaultRoute: '/',
  },
  [ApplicationVariantEnum.PayorPortal]: {
    loginPageMode: LoginPage.modes.PAYOR,
    defaultRoute: '/',
  },
  [ApplicationVariantEnum.PayeePortal]: {
    loginPageMode: LoginPage.modes.PAYEE,
    defaultRoute: '/payments',
  },
};

const MFATypes = LoginPage.mfaTypes;

const entitySpec = { unregisterMFA: selfUnregisterMFA };

const MFATypesActionMap = {
  [MFATypes.YUBIKEY]: ({ unregisterMFA }) => unregisterMFA,
  [MFATypes.TOTP]: ({ unregisterMFA }) => unregisterMFA,
  [MFATypes.SMS]: ({ resendCode }) => resendCode,
};

const SecureMFATypes = [MFATypes.YUBIKEY, MFATypes.TOTP];

function LoginRoute(props) {
  const wireframe = useWireframe(props.history);
  const entity = useAPIMethods(entitySpec);

  const {
    appVariant,
    requestNotification,
    storeTokenPair,
    setContextState,
    logout,
  } = useAppContext();

  useEffect(() => {
    // clear the application state on first mount
    setContextState({ data: undefined, user: undefined });
  }, [setContextState]);

  const { loginPageMode, defaultRoute } = AppVariantConfigMap[appVariant];
  const legalLinks = useLegalLinksList(appVariant);

  const handlers = useLoginAPI();
  const [cachedCredentials, setCredentials] = useState();
  const [loginResult, setLoginResult] = useState();
  const [shouldShowResetSentPage, setShowResetSentPage] = useState(false);

  const showLoginScreen = useCallback(() => {
    setLoginResult(undefined);
    setShowResetSentPage(false);
    logout();
  }, [logout]);

  const showResetSentPage = useCallback(() => {
    setShowResetSentPage(true);
  }, []);

  const [{ unregisterMFA }] = useMemo(
    () =>
      LoginPresenter(wireframe, entity, {
        showResetSentPage,
        notification: {
          error: VeloNotification.types.UNREGISTER_MFA_FAILURE,
          success: VeloNotification.types.UNREGISTER_MFA_SUCCESS,
        },
      }),
    [entity, wireframe, showResetSentPage]
  );

  const intl = useIntl();

  const onSubmitOtp = mapCallbackArg(
    (otp, token, callback) => (error, result) => {
      callback(formatError(error));

      if (!error) {
        storeTokenPair(result);
        props.history.replace(defaultRoute);
      }
    }
  )(handlers.onSubmitOtp);

  const resendCode = mapCallbackArg((_, callback) => (error, result) => {
    callback(formatError(error));

    if (error) {
      requestNotification({
        ...VeloNotification.types.RESEND_SMS_FAILURE,
        key: Date.now().toString(10),
      });
    } else {
      setLoginResult(result);
      requestNotification({
        ...VeloNotification.types.RESEND_SMS_SUCCESS,
        key: Date.now().toString(10),
      });
    }
  })(handlers.onLogin);

  const onLogin = mapCallbackArg((credentials, callback) => (error, result) => {
    wireframe.clearNote();
    if (!error) {
      setCredentials(credentials);
      setLoginResult(result);
      wireframe.closeCookieBanner();
      callback();
    } else if (
      error instanceof SendError &&
      SendError.isClientError(error) &&
      error.type === 400 &&
      error.legacyAuthError === 'Bad credentials'
    ) {
      // we have to be specific to 400, as API returns 401 for server errors "for sEcUriTy"
      // meanwhile, it returns what arguably should be 401s as 400s, and doesn't use the standard errors[] format
      // we also parse the non-standard error into legacyAuthError, so can make sure it specifically says 'Bad credentials'
      callback(LoginPage.getBadCredentialsErrorMessage(intl));
    } else {
      callback(LoginPage.getDefaultErrorMessage(intl));
      // can't use formatError, as without the standard API error format it just says "An error occurred with the send request"
    }
  })(handlers.onLogin);

  const mfaProps = postLoginSelector(loginResult);
  const { mfaType } = mfaProps;

  const secondaryAction = MFATypesActionMap[mfaType]
    ? MFATypesActionMap[mfaType]({
        unregisterMFA: (callback) =>
          unregisterMFA(loginResult.access_token, { mfaType }, callback),
        resendCode: (callback) => resendCode(cachedCredentials, callback),
      })
    : undefined;

  return shouldShowResetSentPage ? (
    <ResetInstructionsSentPage
      legalLinks={legalLinks}
      onClose={showLoginScreen}
    />
  ) : SecureMFATypes.includes(mfaType) && !mfaProps.verified ? (
    <MFADisabledInstructionsPage
      legalLinks={legalLinks}
      onClose={showLoginScreen}
    />
  ) : (
    <LoginPage
      legalLinks={legalLinks}
      {...handlers}
      {...mfaProps}
      mfaType={mfaType}
      mode={loginPageMode}
      onLogin={onLogin}
      secondaryAction={secondaryAction}
      onSubmitOtp={(otp, callback) =>
        onSubmitOtp(otp, loginResult.access_token, callback)
      }
      onClickForgotPassword={() => {
        props.history.push('/forgot-password');
      }}
    />
  );
}

export { LoginRoute };
