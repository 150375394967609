import React, { useState } from 'react';
import { array, func, oneOf, bool, shape, string } from 'prop-types';
import styled from 'astroturf/react';
import { FormattedMessage } from 'react-intl';
import { VeloCardForm } from '../VeloCardForm';
import { VeloRadioTileGroup } from '../VeloRadioTileGroup';
import { VeloRadioImage } from '../VeloRadioImage';
import { VeloGrid } from '../VeloGrid';
import { VeloCheckbox } from '../VeloCheckbox';
import { VeloTabbedView } from '../VeloTabbedView';
import { VeloFieldGrid } from '../VeloFieldGrid';
import { PreviewThemeThumbnail } from './PreviewThemeThumbnail';

const SyncCheckBox = styled(VeloCheckbox)`
  margin: 0 0 1rem 0;
  color: var(--token-color-text-default);
`;

const ThemeDescription = styled('span')`
  color: var(--token-color-text-default);
`;

const Grid = styled(VeloGrid)`
  padding: 1rem 0;
`;

const Labels = {
  SAVE: <FormattedMessage defaultMessage="Save" />,
  SAVING: <FormattedMessage defaultMessage="Saving..." />,
  MODE: <FormattedMessage defaultMessage="Light and dark theme" />,
  SYNC: (
    <FormattedMessage defaultMessage="Sync with OS setting. Automatically apply your system appearance preference" />
  ),
  THEMES: <FormattedMessage defaultMessage="More themes" />,
  THEME_OPTIONS: (
    <FormattedMessage defaultMessage="Select a theme to preview its appearance." />
  ),
  THEME_ALTERNATIVES: (
    <FormattedMessage defaultMessage="Preview alternative theme options. Only you will see this." />
  ),
  DARK_MODE_TITLE: <FormattedMessage defaultMessage="Dark theme" />,
  LIGHT_MODE_TITLE: <FormattedMessage defaultMessage="Light theme" />,
};

const root = 'appearance-form';
const TestIds = {
  ...VeloCardForm.testIds,
  THEME_SYNC: `${root}-theme-mode-sync`,
  THEME_MODE_DARK: `${root}-theme-dark-mode`,
  THEME_MODE_LIGHT: `${root}-theme-light-mode`,
  THEME_OPTION: `${root}-theme-option-`,
};

const accessibilityModesEnum = {
  LIGHT: 'light',
  DARK: 'dark',
  SYSTEM: 'system',
};

function ModeSelector({ mode, availableModes, name, sync, onChange }) {
  const isDarkModeDisabled =
    sync || !availableModes[accessibilityModesEnum.DARK];

  return (
    <VeloRadioTileGroup
      desktopSpan={6}
      value={mode}
      name={name}
      onChange={onChange}
    >
      <VeloRadioTileGroup.Tile
        icon="sun"
        title={Labels.LIGHT_MODE_TITLE}
        label=""
        value={accessibilityModesEnum.LIGHT}
        disabled={sync}
        data-testid={TestIds.THEME_MODE_LIGHT}
      />
      <VeloRadioTileGroup.Tile
        icon="moon"
        title={Labels.DARK_MODE_TITLE}
        label=""
        value={accessibilityModesEnum.DARK}
        disabled={isDarkModeDisabled}
        data-testid={TestIds.THEME_MODE_DARK}
      />
    </VeloRadioTileGroup>
  );
}

function ThemeSelector({ onChange, themes, selectedTheme, mode }) {
  const children = themes.map((theme, index) => {
    const { color, name } = theme;
    const themeName = name.split('_')[1];
    return (
      <VeloGrid.Cell phone={2} tablet={2} desktop={3} key={name}>
        <VeloRadioImage
          checked={selectedTheme.name === name}
          label={themeName}
          id={name}
          value={name}
          name={name}
          onChange={onChange}
          data-testid={`${TestIds.THEME_OPTION}${index + 1}`}
          image={
            <PreviewThemeThumbnail
              colour={color}
              darkMode={mode === accessibilityModesEnum.DARK}
            />
          }
        />
      </VeloGrid.Cell>
    );
  });

  return (
    <>
      <ThemeDescription>{Labels.THEME_ALTERNATIVES}</ThemeDescription>
      <Grid>{children}</Grid>
    </>
  );
}

function AppearanceForm({
  onSubmit,
  onChange,
  themes,
  syncSystemMode,
  enableModeSetting = false,
  enableSyncSetting = false,
  isSynced = false,
  defaultTheme,
  defaultMode = accessibilityModesEnum.LIGHT,
  ...props
}) {
  const [mode, setMode] = useState(defaultMode);
  const [sync, setSync] = useState(isSynced);
  const [selectedTheme, setTheme] = useState(
    defaultTheme || themes.find((theme) => theme.default && theme.name)
  );
  const [availableModes, setAvailableModes] = useState(
    themes.find((theme) => theme.default).modes
  );

  const config = {
    getInitialValues() {
      return {
        sync: sync,
        mode: mode,
        themeSelection: selectedTheme.name,
      };
    },
    formatBody: () => ({
      sync,
      mode,
      themeSelection: selectedTheme.name,
    }),
    getButtonProps: (submitting) =>
      submitting ? { children: Labels.SAVING } : { children: Labels.SAVE },
    createSections() {
      const formFields = [
        {
          heading: Labels.THEMES,
          fields: [
            {
              Component: ThemeSelector,
              onChange: handleThemeChange,
              themes: themes,
              selectedTheme: selectedTheme,
              mode: mode,
              name: 'themeSelection',
            },
          ],
        },
      ];

      const appearanceModeFields = [];

      const syncField = {
        Component: SyncCheckBox,
        value: sync,
        label: Labels.SYNC,
        name: 'sync',
        'data-testid': TestIds.THEME_SYNC,
        checked: sync,
        onChange: handleSyncChange,
      };

      const modeSelection = {
        Component: ModeSelector,
        name: 'mode',
        mode: mode,
        sync: sync,
        onChange: handleModeChange,
        availableModes,
      };

      if (enableSyncSetting || enableModeSetting) {
        if (enableSyncSetting) {
          appearanceModeFields.push(syncField);
        }

        if (enableModeSetting) {
          appearanceModeFields.push(modeSelection);
        }

        formFields.unshift({
          heading: Labels.MODE,
          fields: appearanceModeFields,
        });
      }

      return formFields;
    },
  };

  const handleSyncChange = (evt) => {
    setMode(syncSystemMode);
    setSync(!sync);
    onChange({
      sync: evt.target.checked,
      theme: selectedTheme.name,
      mode: syncSystemMode,
    });
  };

  const handleModeChange = (evt) => {
    setMode(evt);
    onChange({
      theme: selectedTheme.name,
      mode: evt,
    });
  };

  const handleThemeChange = (evt) => {
    const themeModes = themes.find(
      (theme) => theme.name === evt.target.value
    ).modes;
    const selectedMode = !themeModes.dark ? accessibilityModesEnum.LIGHT : mode;

    setTheme({ name: evt.target.value });
    setMode(selectedMode);
    setAvailableModes(themeModes);
    onChange({
      theme: evt.target.value,
      mode: selectedMode,
    });
  };

  const [gridProps, formProps] = VeloTabbedView.useForm(config, {
    onSubmit,
    onChange,
    ...props,
  });
  return (
    <VeloTabbedView.Form
      buttonProps={gridProps.buttonProps}
      onSubmit={gridProps.onSubmit}
    >
      <VeloFieldGrid
        onChange={formProps.onChange}
        sections={formProps.sections}
      />
    </VeloTabbedView.Form>
  );
}

AppearanceForm.propTypes = {
  /** Callback on theme change. */
  onChange: func.isRequired,
  /** Callback on form save. */
  onSubmit: func.isRequired,
  /** Available themes */
  themes: array.isRequired,
  /** Default theme override */
  defaultTheme: shape({ name: string }),
  /** Default mode override */
  defaultMode: oneOf(Object.values(accessibilityModesEnum)),
  /** Is system preference mode synced */
  isSynced: bool,
  /** Toggle mode option visibility */
  enableModeSetting: bool,
  /** Toggle system sync option visibility */
  enableSyncSetting: bool,
  /** Detect system mode preference */
  syncSystemMode: oneOf(Object.values(accessibilityModesEnum)),
};

AppearanceForm.Error = VeloCardForm.Error;
AppearanceForm.accessibilityModes = accessibilityModesEnum;
AppearanceForm.TestIds = TestIds;

export { AppearanceForm };
