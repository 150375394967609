import React from 'react';
import { CSSTransition } from 'react-transition-group';
import contentStyles from './contentStyles.module.scss';
import menuStyles from './menuStyles.module.scss';

const ANIMATION_TIME_MS = 250;

function NavigationDrawerPush({ type, children, ...other }) {
  return (
    <CSSTransition
      timeout={ANIMATION_TIME_MS}
      classNames={type === 'content' ? contentStyles : menuStyles}
      {...other}
    >
      {children}
    </CSSTransition>
  );
}

function NavigationDrawerPushMenu({ children, ...other }) {
  return (
    <NavigationDrawerPush
      type="menu"
      timeout={ANIMATION_TIME_MS * 2}
      {...other}
    >
      {children}
    </NavigationDrawerPush>
  );
}

function NavigationDrawerPushContent({ children, ...other }) {
  return (
    <NavigationDrawerPush type="content" {...other}>
      {children}
    </NavigationDrawerPush>
  );
}

export { NavigationDrawerPushContent, NavigationDrawerPushMenu };
