import compose from 'just-compose';
import { VeloTableBuilder } from '../VeloTableBuilder';
import { PayoutReviewPaymentsColumns } from './PayoutReviewPaymentsColumns';

const {
  columnsByName,
  filtersByName,
  fieldNames,
  getRowProps,
  getSkeletonRowProps,
  getEmptyRowProps,
  ...config
} = PayoutReviewPaymentsColumns;

const addBorderBottomProp = (props) => ({ ...props, borderBottom: true });

const PayoutReviewPayments = VeloTableBuilder({
  ...config,
  columns: fieldNames,
  getRowProps: compose(getRowProps, addBorderBottomProp),
  columnOrder: [
    columnsByName[fieldNames.PAYEE_NAME],
    columnsByName[fieldNames.REMOTE_ID],
    columnsByName[fieldNames.PAYMENT_MEMO],
    columnsByName[fieldNames.PAYMENT_AMOUNT],
    columnsByName[fieldNames.PAYMENT_STATUS],
    columnsByName[fieldNames.SCHEDULED_STATUS_ICON],
  ],
  filters: [
    filtersByName[fieldNames.PAYEE_NAME],
    filtersByName[fieldNames.REMOTE_ID],
    filtersByName[fieldNames.PAYMENT_STATUS],
    filtersByName[fieldNames.TRANSMISSION_TYPE],
    filtersByName[fieldNames.PAYMENT_MEMO],
    filtersByName[fieldNames.SOURCE_ACCOUNT],
  ],
  sortableColumns: [],
});

PayoutReviewPayments.Headings = compose(
  addBorderBottomProp,
  PayoutReviewPayments.Headings
);

PayoutReviewPayments.Loading = compose(
  (props) => ({ ...props, getRowProps: getSkeletonRowProps }),
  PayoutReviewPayments.Loading
);

PayoutReviewPayments.Empty = compose((props) => {
  return { ...getEmptyRowProps(props) };
}, PayoutReviewPayments.Empty);

export { PayoutReviewPayments };
