import React from 'react';
import { arrayOf, shape, string, number, func } from 'prop-types';
import {
  dataTestIdBuilder,
  PayoutStatesDoNotRequireScheduledIcon,
} from 'velo-data';
import { FormattedMessage } from 'react-intl';
import { VeloTable } from '../VeloTable';
import { LookupTextField } from '../LookupTextField';
import { PayoutsTableComponents } from './PayoutsTableComponents';
import { VeloIcon } from '../VeloIcon';
import { scheduleStatusFieldOptions } from '../paymentsListFields';

const root = 'velo-payouts-list';

const TestIds = {
  LIST: root,
  HEADING: `${root}-heading`,
  SORT_HEADING: `${root}-sort-heading`,
  ROW_PREFIX: `${root}-row`,
  COLUMN_PREFIX: `${root}-column`,
  SHORT: `${root}-short-date`,
  LONG: `${root}-long-date`,
  SKELETON_ROW: `${root}-icon-skeleton`,
};

const fieldNames = {
  // unique for filter only - also used as key
  PAYOR_ID: 'payorId',
  PAYOR_NAME: 'payorName',
  SUBMITTED_DATE: 'submittedDateTime',
  SCHEDULED_DATE: 'scheduledFor',
  PAYMENT_MEMO: 'payoutMemo',
  TOTAL_PAYMENTS: 'totalPayments',
  RETURNED_PAYMENTS: 'totalReturnedPayments',
  STATUS: 'status',
  SCHEDULED_ICON: 'scheduled-icon',
  SCHEDULED_STATUS_ICON: 'scheduled-status-icon',
  SCHEDULE_STATUS: 'scheduleStatus',
};

const columnsByName = {
  [fieldNames.SUBMITTED_DATE]: {
    name: fieldNames.SUBMITTED_DATE,
    label: <FormattedMessage defaultMessage="Submitted Date" />,
    'data-testid': dataTestIdBuilder(TestIds.HEADING, 'submitted-date'),
  },
  [fieldNames.SCHEDULED_DATE]: {
    name: fieldNames.SCHEDULED_DATE,
    label: <FormattedMessage defaultMessage="Scheduled For Date" />,
    'data-testid': dataTestIdBuilder(TestIds.HEADING, 'scheduled-date'),
  },
  [fieldNames.SCHEDULED_ICON]: {
    name: fieldNames.SCHEDULED_ICON,
    size: VeloTable.size.XSMALL,
    'data-testid': dataTestIdBuilder(TestIds.HEADING, 'scheduled-icon'),
  },
  // used to indicate scheduled based on state - supplementary to Status column
  [fieldNames.SCHEDULED_STATUS_ICON]: {
    name: fieldNames.SCHEDULED_STATUS_ICON,
    size: VeloTable.size.XSMALL,
    'data-testid': dataTestIdBuilder(TestIds.HEADING, 'scheduled-status-icon'),
  },
  [fieldNames.PAYMENT_MEMO]: {
    name: fieldNames.PAYMENT_MEMO,
    hiddenOnMobile: true,
    label: <FormattedMessage defaultMessage="Payout Memo" />,
    'data-testid': dataTestIdBuilder(TestIds.HEADING, 'payout-memo'),
  },
  [fieldNames.TOTAL_PAYMENTS]: {
    name: fieldNames.TOTAL_PAYMENTS,
    dateTimePadding: true,
    label: <FormattedMessage defaultMessage="Total Payments" />,
    align: VeloTable.alignment.MIDDLE,
    'data-testid': dataTestIdBuilder(TestIds.HEADING, 'total-payments'),
  },
  [fieldNames.RETURNED_PAYMENTS]: {
    name: fieldNames.RETURNED_PAYMENTS,
    dateTimePadding: true,
    label: <FormattedMessage defaultMessage="Returned Payments" />,
    align: VeloTable.alignment.MIDDLE,
    'data-testid': dataTestIdBuilder(TestIds.HEADING, 'failed-payments'),
  },
  [fieldNames.STATUS]: {
    name: fieldNames.STATUS,
    dateTimePadding: true,
    label: <FormattedMessage defaultMessage="Status" />,
    'data-testid': dataTestIdBuilder(TestIds.HEADING, 'status'),
  },
  [fieldNames.PAYOR_NAME]: {
    name: fieldNames.PAYOR_NAME,
    hiddenOnMobile: true,
    label: <FormattedMessage defaultMessage="Payor Name" />,
    align: VeloTable.alignment.START,
    'data-testid': dataTestIdBuilder(TestIds.HEADING, 'payor-name'),
  },
};

const cellComponentByFieldName = {
  [fieldNames.SUBMITTED_DATE]: VeloTable.DateTimeCell,
  [fieldNames.SCHEDULED_DATE]: VeloTable.DateTimeCell,
};

const cellComponentTestIdByFieldName = {
  [fieldNames.SUBMITTED_DATE]: `${TestIds.COLUMN_PREFIX}-submitted-date`,
  [fieldNames.SCHEDULED_DATE]: `${TestIds.COLUMN_PREFIX}-scheduled-date`,
};

const cellFactoryByFieldName = {
  [fieldNames.SUBMITTED_DATE]: ({ submittedDateTime }) => submittedDateTime,
  [fieldNames.SCHEDULED_DATE]: ({ schedule }) => schedule.scheduledFor,
  [fieldNames.SCHEDULED_ICON]: ({ schedule }, index) =>
    schedule?.scheduledFor ? (
      <VeloIcon
        icon="schedule"
        data-testid={dataTestIdBuilder(
          TestIds.ROW_PREFIX,
          index,
          'schedule-icon'
        )}
      />
    ) : null,
  [fieldNames.SCHEDULED_STATUS_ICON]: ({ schedule, status }, index) =>
    schedule?.scheduledFor &&
    PayoutStatesDoNotRequireScheduledIcon.indexOf(status) === -1 ? (
      <VeloIcon
        icon="schedule"
        data-testid={dataTestIdBuilder(
          TestIds.ROW_PREFIX,
          index,
          'schedule-status-icon'
        )}
      />
    ) : null,
  [fieldNames.PAYMENT_MEMO]: ({ payoutMemo }, index) => (
    <span
      data-testid={dataTestIdBuilder(TestIds.ROW_PREFIX, index, 'payout-memo')}
    >
      {payoutMemo}
    </span>
  ),
  [fieldNames.TOTAL_PAYMENTS]: ({ totalPayments }, index) => (
    <PayoutsTableComponents.Total
      label="Total payments"
      total={totalPayments}
      data-testid={dataTestIdBuilder(
        TestIds.ROW_PREFIX,
        index,
        'total-payments-icon'
      )}
    />
  ),
  [fieldNames.RETURNED_PAYMENTS]: ({ totalReturnedPayments }, index) => (
    <PayoutsTableComponents.Total
      label="Returned payments"
      error={true}
      total={totalReturnedPayments}
      data-testid={dataTestIdBuilder(
        TestIds.ROW_PREFIX,
        index,
        'returned-payments'
      )}
    />
  ),
  [fieldNames.STATUS]: ({ status }, index) => (
    <PayoutsTableComponents.Status
      status={status}
      data-testid={dataTestIdBuilder(TestIds.ROW_PREFIX, index, 'status')}
    />
  ),
  [fieldNames.PAYOR_NAME]: ({ payorName }, index) => (
    <span
      data-testid={dataTestIdBuilder(TestIds.ROW_PREFIX, index, 'payor-name')}
    >
      {payorName}
    </span>
  ),
};

const filtersByName = {
  [fieldNames.SUBMITTED_DATE]: {
    name: fieldNames.SUBMITTED_DATE,
    label: <FormattedMessage defaultMessage="Submitted Date" />,
    type: 'dateRange',
    nameFrom: 'submittedDateFrom',
    nameTo: 'submittedDateTo',
  },
  [fieldNames.SCHEDULED_DATE]: {
    name: fieldNames.SCHEDULED_DATE,
    label: <FormattedMessage defaultMessage="Scheduled For Date" />,
    type: 'dateRange',
    nameFrom: 'scheduledForDateFrom',
    nameTo: 'scheduledForDateTo',
  },
  [fieldNames.PAYMENT_MEMO]: {
    name: fieldNames.PAYMENT_MEMO,
    label: <FormattedMessage defaultMessage="Payout Memo" />,
    type: 'string',
  },
  [fieldNames.STATUS]: {
    name: fieldNames.STATUS,
    label: <FormattedMessage defaultMessage="Status" />,
    type: 'list',
    options: (intl) =>
      Object.values(PayoutsTableComponents.Status.getStates(intl)),
  },
  [fieldNames.SCHEDULE_STATUS]: {
    name: fieldNames.SCHEDULE_STATUS,
    label: <FormattedMessage defaultMessage="Schedule Status" />,
    type: 'list',
    options: scheduleStatusFieldOptions,
  },
  [fieldNames.PAYOR_ID]: {
    name: fieldNames.PAYOR_ID,
    label: <FormattedMessage defaultMessage="Payor" />,
    type: 'entityIdLookup',
    mode: LookupTextField.modes.PAYOR,
  },
};

export const PayoutsListColumns = {
  mapHeaderProps(props) {
    return {
      iconSpacer: false,
      children: <PayoutsTableComponents.IconsContainer />,
      ...props,
    };
  },
  filtersByName,
  fieldNames,
  columnsByName,
  getRowProps(payout, index, _, { onClick }) {
    return {
      key: payout.payoutId,
      icon: {
        strategy: 'component',
        icon: <PayoutsTableComponents.PayoutIcon {...payout} />,
      },
      onClick: () => onClick(payout),
      'data-testid': dataTestIdBuilder(TestIds.ROW_PREFIX, index),
    };
  },
  getColumnProps(
    { name: key, align, dateTimePadding, className, hiddenOnMobile, size },
    ...args
  ) {
    return {
      key,
      align,
      dateTimePadding,
      className,
      hiddenOnMobile,
      size,
      'data-testid': cellComponentTestIdByFieldName[key],
      Component: cellComponentByFieldName[key],
      children: cellFactoryByFieldName[key](...args),
    };
  },
  dataProps: {
    data: arrayOf(
      shape({
        payoutId: string.isRequired,
        submittedDateTime: string.isRequired,
        scheduledFor: string,
        payoutMemo: string,
        totalPayments: number,
        totalReturnedPayments: number,
        status: string,
      })
    ),
    onClick: func.isRequired,
  },
};
