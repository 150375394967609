import React from 'react';
import { VeloNotification } from 'velo-react-components';
import { PasswordUnlock } from '../../containers';
import { Context } from '../../context';

class PasswordUnlockRoute extends React.Component {
  static contextType = Context;

  showNotification = (errorMessage) => {
    this.props.history.push('/login');
    // errorMessage is intentionally not used directly for presentation
    this.context.requestNotification(
      errorMessage
        ? VeloNotification.types.LOGIN_UNLOCK_FAILURE
        : VeloNotification.types.LOGIN_UNLOCK_SUCCESS
    );
  };

  render() {
    return <PasswordUnlock {...this.props} exit={this.showNotification} />;
  }
}

export { PasswordUnlockRoute };
