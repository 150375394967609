import React from 'react';
import styled from 'astroturf/react';
import { VeloSkeleton } from '../../VeloSkeleton';
import { ResultContainer } from '../../ResultContainer';

const Error = styled('span')`
  @import 'velo-variables';

  color: velo-color('token-color-system-error-default');
`;

/**
 * Create the base component.
 */
const Component = ResultContainer({
  ErrorComponent: ({ error, ...other }) => <Error {...other}>{error}</Error>,
  LoadingComponent: (props) => <VeloSkeleton {...props} />,
})(({ result, ...props }) => (
  <span data-testid={props['data-testid']}>{result}</span>
));

/**
 * Used to render a generic result within a Payments List cell.
 * Used for Payee Name, Payor Name, etc.
 * Supports loading, error and success states.
 */
function PaymentResult({ result, error, errorMessage, skeleton, ...other }) {
  const props = skeleton
    ? {}
    : { result, error: error ? errorMessage : undefined };
  return <Component {...props} {...other} />;
}

export { PaymentResult };
