export function getPaymentDetailsV4(send) {
  return (paymentId, query, ...args) =>
    send(
      {
        path: '/v4/paymentaudit/payments/{paymentId}',
        pathParameters: { paymentId },
        type: 'get',
        query: query,
        body: undefined,
        headers: undefined,
        auth: 'oauth2',
        contentType: undefined,
      },
      ...args
    );
}
