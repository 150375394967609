import React, { useMemo } from 'react';
import { fromDecimal } from 'js-money';

const originalValueFormatter = (field) => (value) => ({ [field.name]: value });

const rangeFormatter = (field) => (value) => {
  if (!value) return {};
  const [from, to] = value;
  const f = from ? { [field.nameFrom]: from } : {};
  const t = to ? { [field.nameTo]: to } : {};
  return { ...t, ...f };
};

// TODO: when we change currency, we must allow injection of the type
const toMoney = (value) =>
  fromDecimal(parseFloat(value), 'USD', Math.ceil).amount.toString(10);

const currencyFormatter = (field) => {
  const fn = rangeFormatter(field);
  return (value) =>
    Object.entries(fn(value)).reduce(
      (map, [key, value]) => ({ ...map, [key]: toMoney(value) }),
      {}
    );
};

const fieldFormatters = {
  string: originalValueFormatter,
  list: originalValueFormatter,
  listCountry: originalValueFormatter,
  listCurrency: originalValueFormatter,
  number: originalValueFormatter,
  currencyRange: currencyFormatter,
  date: originalValueFormatter,
  dateRange: rangeFormatter,
  entityIdLookup: originalValueFormatter,
};

function createFilterFormatterOnChange(onChange, fields) {
  const formatters = fields.reduce(
    (ftm, field) => ({
      ...ftm,
      [field.name]: fieldFormatters[field.type](field),
    }),
    {}
  );

  return (fieldValues) =>
    onChange(
      Object.entries(fieldValues).reduce((fv, [k, v]) => {
        // When there isn't a formatter, do nothing
        const result = formatters[k] && formatters[k](v);
        return { ...fv, ...result };
      }, {})
    );
}

// Format the API sort query
const formatSortQuery = (name, direction) =>
  `${name}:${direction === 1 ? 'asc' : 'desc'}`;

/**
 * Allows the user to intercept a callback argument.
 * Create a function that accepts a function where the last argument is a
 * callback. When this returned function is invoked with the function containing
 * a callback parameter then a new function is returned with the same signature
 * as the passed function, but the callback parameter is passed as a result of
 * invoking the mapper function with the original function arguments (including
 * the original callback).
 */
function mapCallbackArg(mapper) {
  return (funcWithCallback) => {
    return (...args) => {
      const noCallback = args.length === 1;
      const argsMinusCallback = noCallback ? args : args.slice(0, -1);
      return funcWithCallback(...argsMinusCallback, mapper(...args));
    };
  };
}

const emptyObject = {};

/**
 * Given a hook, return a new component that has a render prop that is passed
 * the results of the hook. The hook receives the component props.
 *
 * const useMyHook = componentProps => {
 *   ...
 *   return hookProps;
 * }
 *
 * const MyComponent = createFromHook(useMyHook);
 *
 * <MyComponent componentProps={...}>
 *   {
 *     hookProps => {...}
 *   }
 * </MyComponent>
 *
 * or
 *
 * <MyComponent componentProps={...} render={hookProps => {...}} />
 */
const createFromHook =
  (hook, defaultProps = emptyObject) =>
  (inputProps) => {
    // avoid re-computing props if input props do not change
    const [render, props] = useMemo(() => {
      const {
        children,
        render = children,
        ...props
      } = {
        ...defaultProps,
        ...inputProps,
      };
      return [render, props];
    }, [inputProps]);
    return render(hook(props));
  };

const defaultMapDataToProps = (data) => ({ data });

/**
 * Takes a React component function and converts it into an object of forks to
 * be used with `forkResult`.
 * @param {function} Component A component constructor. Must have `.Error`
 * `.Loading` and optionally `.Empty` extra component constructors on the passed
 * object.
 * @param {function!} mapDataToProps An optional function for converting the
 * data passed to the value fork to props. By default this will pass a prop
 * named 'data' to the component.
 */
function createRenderForksFromComponent(
  Component,
  mapDataToProps = defaultMapDataToProps
) {
  return {
    value: (data, props) => <Component {...mapDataToProps(data)} {...props} />,
    error: (error, props) => <Component.Error {...props} error={error} />,
    none: (props) => <Component.Loading {...props} />,
    empty: (props) => <Component.Empty {...props} />,
  };
}

export {
  createRenderForksFromComponent,
  mapCallbackArg,
  formatSortQuery,
  createFilterFormatterOnChange,
  createFromHook,
};
