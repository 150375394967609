import React from 'react';
import { func, node } from 'prop-types';
import { useIntl } from 'react-intl';
import { Content } from '../Content';
import { PageHeader } from '../PageHeader';
import { VeloFab } from '../VeloFab';

const sharedPropTypes = {
  /**
   * Called when the "Create" extended FAB is clicked.
   * If this prop is missing then the button will not appear.
   */
  onCreate: func,
  /** The page content to render. */
  children: node.isRequired,
};

UsersPage.propTypes = {
  ...sharedPropTypes,
};

function UsersPage({ title, onCreate, children }) {
  const intl = useIntl();
  return (
    <Content
      header={
        <PageHeader
          title={title}
          fab={
            onCreate && (
              <VeloFab
                label={intl.formatMessage({ defaultMessage: 'Create' })}
                icon="add"
                title={title}
                onClick={onCreate}
                data-testid={VeloFab.testIdBuilder('Create')}
              />
            )
          }
        />
      }
    >
      {children}
    </Content>
  );
}

function BackOfficeUsersPage(props) {
  const intl = useIntl();
  return (
    <UsersPage
      {...props}
      title={intl.formatMessage({ defaultMessage: 'Admins' })}
    />
  );
}

function PayorUsersPage(props) {
  const intl = useIntl();
  return (
    <UsersPage
      {...props}
      title={intl.formatMessage({ defaultMessage: 'Payor Users' })}
    />
  );
}

function BOPPayeeUsersPage(props) {
  const intl = useIntl();
  return (
    <UsersPage
      {...props}
      title={intl.formatMessage({ defaultMessage: 'Company Payee Users' })}
    />
  );
}

function PayeeUsersPage(props) {
  const intl = useIntl();
  return (
    <UsersPage
      {...props}
      title={intl.formatMessage({ defaultMessage: 'Users' })}
    />
  );
}

UsersPage.BackOffice = BackOfficeUsersPage;
UsersPage.Payor = PayorUsersPage;
UsersPage.BOPPayee = BOPPayeeUsersPage;
UsersPage.Payee = PayeeUsersPage;

UsersPage.BackOffice.propTypes = sharedPropTypes;
UsersPage.Payor.propTypes = sharedPropTypes;
UsersPage.Payee.propTypes = sharedPropTypes;
UsersPage.BOPPayee.propTypes = sharedPropTypes;

export { UsersPage };
