import React from 'react';
import { FormattedMessage } from 'react-intl';
import { arrayOf, bool, func, node, shape, string } from 'prop-types';
import styled from 'astroturf/react';
import PaymentsListPropTypes from './propTypes';
import { dataTestIdBuilder } from 'velo-data';
import { VeloTable } from '../VeloTable';
import { ResultContainer } from '../ResultContainer';
import { VeloSkeleton } from '../VeloSkeleton';

const Table = styled(VeloTable)`
  @import 'velo-variables';
  @media (max-width: velo-breakpoint(XS)) {
    margin-bottom: 3.75rem;
  }
`;

const LoadingIcon = styled(VeloSkeleton)`
  height: 24px;
  width: 24px;
`;

const root = 'velo-payments-list';

const TestIds = {
  LIST: root,
  ICON_SKELETON: `${root}-icon-skeleton`,
  DATE_TIME_HEADING: `${root}-heading-datetime`,
  SHORT: `${root}-short-date`,
  LONG: `${root}-long-date`,
  MOBILE_SORT: `${root}-mobile-sort`,
};

const PaymentsCell = ({
  alignEnd,
  loading,
  data,
  row,
  col,
  size,
  renderCell,
  ...other
}) => (
  <VeloTable.Cell
    align={alignEnd ? VeloTable.alignment.END : VeloTable.alignment.START}
    size={size}
    dateTimePadding
    {...other}
  >
    {renderCell(loading, data, row, col)}
  </VeloTable.Cell>
);

const TableLoading = ({ headings, renderCell }) => (
  <VeloTable.Row
    icon={{
      strategy: 'component',
      icon: <LoadingIcon data-testid={TestIds.ICON_SKELETON} />,
    }}
  >
    <VeloTable.DateTimeCell.Loading data-testid={`${TestIds.LONG}-skeleton`} />
    {headings.map(({ className, alignEnd }, col) => (
      <PaymentsCell
        key={col}
        className={className}
        loading
        row={0}
        col={col}
        alignEnd={alignEnd}
        data={{}}
        renderCell={renderCell}
      />
    ))}
  </VeloTable.Row>
);

const TableResult = ({ result, headings, onClick, renderCell }) =>
  result.map((item, row) => {
    const testId = dataTestIdBuilder(TestIds.LIST, 'row', row);
    return (
      <VeloTable.Row
        key={row}
        icon="chevron_right"
        onClick={() => onClick(item)}
        data-testid={testId}
      >
        {/* Date/time */}
        <VeloTable.DateTimeCell
          data-testid={dataTestIdBuilder(testId, 'datetime')}
        >
          {item.submittedDateTime}
        </VeloTable.DateTimeCell>

        {/* Custom cells */}
        {headings.map(({ className, alignEnd, size }, col) => (
          <PaymentsCell
            key={col}
            className={className}
            loading={false}
            row={row}
            col={col}
            alignEnd={alignEnd}
            data={item}
            size={size}
            renderCell={renderCell}
          />
        ))}
      </VeloTable.Row>
    );
  });

// The table content in error, loading, empty and result variants
const TableContent = ResultContainer({
  ErrorComponent: ({ error }) => <VeloTable.Error>{error}</VeloTable.Error>,
  LoadingComponent: TableLoading,
  EmptyComponent: () => (
    <VeloTable.Empty>
      <FormattedMessage defaultMessage="No results" />
    </VeloTable.Empty>
  ),
})(TableResult);

// The table footer. Only rendered when loading or we have a valid result
const TableFooter = ResultContainer({
  ErrorComponent: () => null,
  EmptyComponent: () => null,
  LoadingComponent: ({ footer }) => footer,
})(({ footer }) => footer);

PaymentsList.propTypes = {
  /** Core list props. */
  ...PaymentsListPropTypes,
  /** The list headings. */
  headings: arrayOf(
    shape({
      /** The heading label. */
      label: node.isRequired,
      /** The heading alignment. */
      alignEnd: bool,
      /** A CSS class name to apply to the list cell. */
      className: string,
    })
  ).isRequired,
  /**
   * Called when rendering a cell in the list.
   */
  renderCell: func.isRequired,
};

PaymentsList.defaultProps = {
  sortOptions: () => undefined,
  data: { error: undefined, result: undefined },
  footer: undefined,
};

PaymentsList.testIds = TestIds;

const getHeadings = ({ headings, sortOptions, onSort }) =>
  [
    {
      label: <FormattedMessage defaultMessage="Date/Time" />,
      'data-testid': TestIds.DATE_TIME_HEADING,
    },
    ...headings,
  ].map(({ label, alignEnd, ...other }, index) => ({
    ...other,
    sort: sortOptions(index),
    onSort: (name, direction) => onSort(Number(name), direction),
    align: alignEnd ? VeloTable.alignment.END : VeloTable.alignment.START,
    name: String(index),
    children: label,
  }));

export function PaymentsList(props) {
  const headings = getHeadings(props);
  // Find the sorted column
  const sortedColumn = headings.find(
    ({ sort }) =>
      sort === VeloTable.sortOrder.ASCENDING ||
      sort === VeloTable.sortOrder.DESCENDING
  );

  return (
    <Table className={props.className} data-testid={TestIds.LIST}>
      {props.filter}

      {/* Mobile sort dialog */}
      {sortedColumn && (
        <VeloTable.MobileSortHeader
          direction={sortedColumn.sort}
          column={sortedColumn.name}
          headings={headings.map(({ children, name }) => ({
            label: children,
            name,
          }))}
          onSort={(name, direction) => props.onSort(Number(name), direction)}
          data-testid={TestIds.MOBILE_SORT}
        />
      )}

      {/* Headings */}
      <VeloTable.HeaderRow iconSpacer>
        {getHeadings(props).map((heading, index) => (
          <VeloTable.HeaderCell key={index} {...heading} />
        ))}
      </VeloTable.HeaderRow>

      {/* Content */}
      <TableContent
        {...props.data}
        headings={props.headings}
        onClick={props.onClick}
        renderCell={props.renderCell}
      />

      {/* Footer */}
      {props.footer && <TableFooter {...props.data} footer={props.footer} />}
    </Table>
  );
}
