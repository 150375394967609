import React from 'react';
import pick from 'just-pick';
import { PayorAccountType } from 'velo-data';
import { FormattedMessage } from 'react-intl';
import { VeloCardForm } from '../VeloCardForm';
import { VeloFieldGrid } from '../VeloFieldGrid';
import { useFieldGridForm } from '../useFieldGridForm';
import { VeloGridLoading } from '../VeloGridLoading';
import {
  SourceAccountFormFields,
  fieldNames,
} from '../SourceAccountFormFields';
import { func, shape, string, oneOf, number } from 'prop-types';

const { attributeMap } = SourceAccountFormFields;

const Labels = {
  TITLE: <FormattedMessage defaultMessage="Edit Source Account" />,
  ACCOUNT_HEADING: <FormattedMessage defaultMessage="Source account details" />,
  SUBMIT_BUTTON: <FormattedMessage defaultMessage="Save" />,
  SUBMITTING_BUTTON: <FormattedMessage defaultMessage="Saving..." />,
};

const fieldRenderByName = {
  [fieldNames.NAME]: () => attributeMap.NAME,
};

const fieldsByType = {
  [PayorAccountType.FBO]: [fieldNames.NAME],
  [PayorAccountType.WUBS_DECOUPLED]: [fieldNames.NAME],
};

const config = {
  createSections(_, props) {
    const fieldNames = fieldsByType[props.data.type];
    return [
      SourceAccountFormFields.createSourceAccountGridSection(
        false,
        // sourceAccountName is undefined to remove the labelledItem
        { ...props.data, sourceAccountName: undefined },
        null
      ),
      {
        heading: Labels.ACCOUNT_HEADING,
        fields: fieldNames.map((name) => fieldRenderByName[name](props)),
      },
    ];
  },
  getButtonProps(submitting) {
    return {
      children: submitting ? Labels.SUBMITTING_BUTTON : Labels.SUBMIT_BUTTON,
    };
  },
  formatBody(body) {
    return body;
  },
  getInitialValues({ data }) {
    const write = fieldsByType[data.type];
    return pick(data, write);
  },
};

function Loading({ onClose }) {
  return (
    <VeloCardForm.Loading slimline title={Labels.TITLE} onClose={onClose}>
      <VeloGridLoading
        sections={[
          {
            heading: false,
            fields: new Array(3).fill({
              type: VeloGridLoading.fieldTypes.LabelledItem,
            }),
          },
          {
            heading: true,
            fields: new Array(2).fill({
              type: VeloGridLoading.fieldTypes.TextField,
            }),
          },
        ]}
      />
    </VeloCardForm.Loading>
  );
}

const ErrorView = (props) => (
  <VeloCardForm.Error title={Labels.TITLE} {...props} />
);

SourceAccountEditForm.Loading = Loading;
SourceAccountEditForm.Error = ErrorView;

SourceAccountEditForm.propTypes = {
  /**
   * Handler for when the form is submitted
   */
  onSubmit: func.isRequired,
  /**
   * Handler for when the form is closed
   */
  onClose: func.isRequired,
  /**
   * Data for the form
   */
  data: shape({
    /**
     * The source account name
     */
    name: string.isRequired,
    /**
     * Effective balance of the account if given
     */
    balance: number,
    /**
     * The type of source account
     */
    type: oneOf(Object.values(PayorAccountType)).isRequired,
  }),
};

SourceAccountEditForm.testIds = VeloCardForm.testIds;
SourceAccountEditForm.labels = Labels;

export function SourceAccountEditForm(props) {
  const [gridProps, formProps] = useFieldGridForm(config, props);
  return (
    <VeloCardForm slimline {...formProps} title={Labels.TITLE}>
      <VeloFieldGrid {...gridProps} />
    </VeloCardForm>
  );
}
