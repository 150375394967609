import React, { useRef } from 'react';
import { any, bool, number, string, func, node, oneOf } from 'prop-types';
import styled from 'astroturf/react';
import { VeloRipple } from '../VeloRipple';
import { VeloIcon } from '../VeloIcon';
import { VeloGrid } from '../VeloGrid';
import { VeloTypography } from '../VeloTypography';
import { VeloSkeleton } from '../VeloSkeleton';
import { useElementBreakpoint } from '../hooks';

const ButtonBackground = {
  WHITE: 'white',
  THEMED: 'themed',
};

const Button = styled('button')`
  @import 'velo-variables';

  background-color: velo-color('token-color-brand-primary-background');
  border: 1px solid velo-color('token-color-border-disabled');
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  cursor: pointer;
  outline: none;
  width: 100%;
  flex: 1;

  &.checked {
    border-color: velo-color('token-color-brand-primary');
  }

  &.disabled {
    opacity: 0.4;
    cursor: default;
  }

  &.compact {
    padding: 1.5rem 0.2rem;
  }

  &.background-themed {
    background-color: velo-color('token-color-brand-primary-background');

    /* https://github.com/material-components/material-components-web/issues/5044 */
    &:global(.mdc-ripple-surface--primary::before),
    &:global(.mdc-ripple-surface--primary::after) {
      background-color: velo-color('token-color-brand-primary');
    }
  }

  &.background-white {
    background-color: white;

    /* https://github.com/material-components/material-components-web/issues/5044 */
    &:global(.mdc-ripple-surface--primary::before),
    &:global(.mdc-ripple-surface--primary::after) {
      background-color: white;
    }
  }
`;

Button.propTypes = {
  checked: bool,
  disabled: bool,
  compact: bool,
  background: oneOf(Object.values(ButtonBackground)),
};

Button.defaultProps = {
  background: ButtonBackground.THEMED,
};

const Icon = styled(VeloIcon)`
  @import 'velo-variables';
  @import 'velo-mixins';

  color: velo-color('token-color-text-default');
  @include icon-size(2.5rem);

  margin-bottom: 0.25rem;

  &.checked {
    color: velo-color('token-color-brand-primary');
  }
`;

Icon.propTypes = {
  checked: bool,
};

const Label = styled(VeloTypography).attrs({ use: 'bodyText', tag: 'div' })`
  @import 'velo-variables';

  flex-grow: 1;

  @media (max-width: velo-breakpoint(XS)) {
    display: none;
  }
`;

const Grid = styled(VeloGrid)`
  padding: 0;

  :global {
    .mdc-layout-grid__inner {
      grid-auto-rows: 1fr;
    }
  }
`;

const Cell = styled(VeloGrid.Cell)`
  display: flex;
`;

const Skeleton = styled(VeloSkeleton)`
  width: 100%;
  height: 10rem;
`;

VeloRadioTile.propTypes = {
  /** Custom CSS class(es). */
  className: string,
  /** Called when the button is clicked. */
  onClick: func,
  /** Renders in the checked state. */
  checked: bool,
  /** The icon to use. This can be a string for a font icon, a url, or whatever the selected strategy needs. RMWC */
  icon: node,
  /** The title. */
  title: node.isRequired,
  /** The label (hidden on mobile.) */
  label: node.isRequired,
  /** Render as a skeleton. */
  skeleton: bool,
  /** Disable this tile */
  disabled: bool,
  /** The button value. Used with `VeloRadioTileGroup` */
  value: any,
  /** The button test ID. */
  'data-testid': string.isRequired,
};

/**
 * Radio Button Tile Base.
 *
 * Renders children inside a tile button.
 *
 * Use in combination with `VeloRadioTileGroup` to create
 * a group of buttons.
 */
function VeloRadioTileBase({
  children,
  checked,
  disabled,
  name,
  skeleton,
  compact,
  background,
  ...other
}) {
  return skeleton ? (
    <Skeleton />
  ) : (
    <VeloRipple primary disabled={disabled}>
      <Button
        checked={checked}
        disabled={disabled}
        type="button"
        role="switch"
        aria-checked={checked}
        compact={compact}
        background={background}
        {...other}
      >
        {children}
      </Button>
    </VeloRipple>
  );
}

/**
 * Radio Button Tile.
 *
 * Renders an icon, title and label.
 *
 * Use in combination with `VeloRadioTileGroup` to create
 * a group of buttons.
 */
function VeloRadioTile({ icon, title, label, checked, ...props }) {
  return (
    <VeloRadioTileBase checked={checked} {...props}>
      <div>
        {icon && <Icon icon={icon} checked={checked} />}
        <VeloTypography use="bodyTextEmphasised" tag="div">
          {title}
        </VeloTypography>
      </div>
      <Label>{label}</Label>
    </VeloRadioTileBase>
  );
}

const Image = styled('img')`
  height: 44px;
  width: auto;
`;

const ImageTileText = styled(VeloTypography)`
  margin-top: 0.5rem;
`;

/**
 * Radio Button Tile with image as content.
 *
 * Renders a title and image.
 *
 * Use in combination with `VeloRadioTileGroup` to create
 * a group of buttons.
 */
function VeloRadioImageTile({ src, title, label, checked, ...props }) {
  return (
    <VeloRadioTileBase
      checked={checked}
      background={ButtonBackground.WHITE}
      compact
      {...props}
    >
      <div>
        <Image src={src} alt={title} />

        <ImageTileText use="bodyTextSmaller" tag="div">
          {title}
        </ImageTileText>
      </div>
    </VeloRadioTileBase>
  );
}

// Button spans
const buttonSpans = {
  PHONE: 2,
  DESKTOP: 4,
  DESKTOP_NARROW: 6,
};

VeloRadioTileGroup.propTypes = {
  /** Custom CSS class(es). */
  className: string,
  /** The currently selected button. */
  value: any,
  /** Called when a button is clicked. */
  onChange: func,
  /** Render as a loading skeleton. */
  skeleton: bool,
  /** Breakpoint used to make buttons wider. */
  breakpoint: number,
  /** Desktop button span. */
  desktopSpan: number,
  /** The buttons to render. */
  children: node.isRequired,
  /** Enable compact grid. */
  compact: bool,
};

VeloRadioTileGroup.defaultProps = {
  breakpoint: 600,
};

/**
 * Radio Tile Group.
 *
 * Use this to render a group of buttons in an MDC `Grid`.
 *
 *  <VeloRadioTileGroup value="button1" onChange={newValue => {...}}>
 *    <VeloRadioTile value="button1" ... />
 *    <VeloRadioTile value="button2" ... />
 *  </VeloRadioTileGroup>
 */
function VeloRadioTileGroup({ compact, ...props }) {
  const ref = useRef(null);
  const breakpoint = useElementBreakpoint(ref, props.breakpoint);
  return (
    <div ref={ref}>
      <Grid compact={compact} className={props.className}>
        {React.Children.map(props.children, (child, index) => (
          <Cell
            key={index}
            desktop={
              props.desktopSpan ||
              (breakpoint ? buttonSpans.DESKTOP_NARROW : buttonSpans.DESKTOP)
            }
            phone={props.phoneSpan || buttonSpans.PHONE}
          >
            {React.cloneElement(child, {
              skeleton: props.skeleton,
              checked: props.value === child.props.value,
              onClick: child.props.disabled
                ? undefined
                : () => props.onChange(child.props.value),
            })}
          </Cell>
        ))}
      </Grid>
    </div>
  );
}

VeloRadioTileGroup.TileBase = VeloRadioTileBase;
VeloRadioTileGroup.Tile = VeloRadioTile;
VeloRadioTileGroup.ImageTile = VeloRadioImageTile;

export { VeloRadioTileGroup };
