import React from 'react';
import { FormattedMessage } from 'react-intl';
import { PaymentViewPropTypes } from '../propTypes';
import { BusinessPaymentView, PayorName } from '../BusinessPaymentView';
import { PaymentViewItem } from '../PaymentViewItem';
import { PaymentView } from '../PaymentView';

const testId = PaymentView.root;

BackOfficePaymentView.propTypes = {
  ...PaymentViewPropTypes,
};

const renderTitle = (result) => <PayorName result={result.payorName} />;

function BackOfficePaymentView(props) {
  return (
    <BusinessPaymentView
      {...props}
      renderAdditionalBasicPaymentDetails={({
        railsId,
        paymentTrackingReference,
      }) => (
        <>
          {railsId && props.railsProviders && (
            <PaymentViewItem
              label={<FormattedMessage defaultMessage="Rails provider" />}
              value={
                props.railsProviders.find(
                  ({ railsId: comparisonRailsId }) =>
                    railsId === comparisonRailsId
                )?.description || <FormattedMessage defaultMessage="Unknown" />
              }
              data-testid={`${testId}-rails-provider`}
            />
          )}
          {paymentTrackingReference && (
            <PaymentViewItem
              label={
                <FormattedMessage defaultMessage="Payment tracking reference" />
              }
              value={paymentTrackingReference}
              data-testid={`${testId}-payment-tracking-reference`}
            />
          )}
        </>
      )}
      renderTitle={renderTitle}
    />
  );
}

export { BackOfficePaymentView };
