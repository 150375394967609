import React from 'react';
import { Route } from 'react-router';
import { SecondaryValueType } from 'velo-data';
import {
  PaymentsPage,
  Pagination,
  Filter,
  PaymentView,
  PaymentsList,
  UserRoles,
} from 'velo-react-components';
import {
  contextCountriesSelector,
  contextSupportedRailsSelector,
  contextPaymentChannelRulesSelector,
  Context,
} from '../../context';
import {
  PaymentDetailContainer,
  PaymentDetailMode,
  PaymentsListContainer,
  PaymentsListMode,
} from '../../containers';
import { formatError } from '../../selectors';
import { extractURLSearchParams } from '../../utils';

const PaymentModeConfigMap = {
  [PaymentsListMode.PAYOR]: {
    Components: {
      PaymentView: PaymentView.Payor,
      PaymentsList: PaymentsList.Payor,
      PaymentsPage: PaymentsPage,
    },
  },
  [PaymentsListMode.BACKOFFICE]: {
    Components: {
      PaymentView: PaymentView.BackOffice,
      PaymentsList: PaymentsList.BackOffice,
      PaymentsPage: PaymentsPage,
    },
    PaymentDetailContainerMode: PaymentDetailMode.BACKOFFICE,
  },
};

class PaymentsListRoute extends React.Component {
  static contextType = Context;

  static testIds = PaymentView.Business.testIds;

  handlePaymentClick = ({ paymentId }) => {
    const { history, match } = this.props;
    history.push(`${match.url}/${paymentId}`);
  };

  resetURL = () => {
    const { history, match } = this.props;
    history.push(match.url);
  };

  proxyFilterOnChange = (onChangeHandler) => (filter) => {
    const { payeeId } = extractURLSearchParams(this.props.location.search);

    /**
     * If there was a deep link to the payee's payments then remove the URL
     * search params on payee lookup for consistency
     * */
    if (payeeId && filter && filter.payeeId !== payeeId) {
      this.props.history.replace(this.props.match.url);
    }

    onChangeHandler(filter);
  };

  proxySearchPayees = (searchPayeesHandler) => (searchValue, callback) => {
    searchPayeesHandler(searchValue).then(
      ({ content, page }) => {
        callback(undefined, {
          result: content.map(({ displayName, payeeId, email, payorRefs }) => {
            const secondaryValues = [email];

            if (this.props.mode === PaymentsListMode.PAYOR) {
              const result = payorRefs.find(
                (ref) => ref.payorId === this.props.query.payorId
              );

              if (result && result.remoteId) {
                secondaryValues.push({
                  type: SecondaryValueType.REMOTE_ID,
                  value: result.remoteId,
                });
              }
            }

            return {
              value: displayName,
              entityId: payeeId,
              secondaryValues,
            };
          }),
          totalResults: page.totalElements,
        });
      },
      (ex) => {
        callback(formatError(ex));
      }
    );
  };

  renderList = ({
    data,
    summary,
    pageSize,
    page,
    totalPages,
    onPage,
    sortOptions,
    onSort,
    fields,
    readOnlyFields,
    onChange,
    payeeData,
    searchPayees,
    withdrawingPaymentId,
  }) => {
    const footerProps = {
      page,
      totalPages,
      skeleton: totalPages === 0,
      disabled: data.result === undefined,
      onPage,
    };
    const footer = <Pagination {...footerProps} />;

    const updatedFields = fields.map((field) =>
      field.name === 'payeeId'
        ? {
            ...payeeData,
            ...field,
            fetchResults: this.proxySearchPayees(searchPayees),
          }
        : field
    );

    // Update the state of the withdrawing Payments
    const mergedData = {
      ...data,
      result: data.result
        ? data.result.map((payment) => {
            return {
              ...payment,
              status:
                withdrawingPaymentId === payment.paymentId
                  ? 'WITHDRAWING'
                  : payment.status,
            };
          })
        : undefined,
    };

    const filterProps = {
      fields: updatedFields,
      readOnlyFields,
      onChange: this.proxyFilterOnChange(onChange),
      isUnique: true,
    };

    const filter = <Filter {...filterProps} />;

    const listProps = {
      data: mergedData,
      itemsPerPage: pageSize,
      sortOptions,
      onSort,
      onClick: this.handlePaymentClick,
      footer,
      filter,
    };
    const { PaymentsList, PaymentsPage } =
      PaymentModeConfigMap[this.props.mode].Components;

    return (
      <PaymentsPage
        onClickBackLink={this.props.onClickBackLink}
        summary={summary}
        list={<PaymentsList {...listProps} />}
      />
    );
  };

  renderListContainer = () => {
    const { payeeId } = extractURLSearchParams(this.props.location.search);
    const countries = contextCountriesSelector(this.context);
    const railsProviders = contextSupportedRailsSelector(this.context);

    const containerProps = {
      query: this.props.query,
      mode: this.props.mode,
      refresh: this.props.refresh,
      render: this.renderList,
      withdrawingPaymentId: this.props.withdrawingPaymentId,
      onClearExternalFilters: this.resetURL,
      payeeId,
      payoutId: this.props.match.params.payoutId,
      countries,
      railsProviders,
    };
    return <PaymentsListContainer {...containerProps} />;
  };

  renderPaymentView = (props) => {
    const { PaymentView } = PaymentModeConfigMap[this.props.mode].Components;
    return <PaymentView onClose={this.props.history.goBack} {...props} />;
  };

  renderDetailContainer = ({ match }) => {
    const props = {
      mode: PaymentModeConfigMap[this.props.mode].PaymentDetailContainerMode,
      paymentId: match && match.params.paymentId,
      onSuccess: (paymentId) => {
        this.props.history.goBack();
        this.props.setWithdrawingPaymentId(paymentId);
      },
      sendNote: this.props.sendNote,
    };

    return (
      <PaymentDetailContainer
        {...props}
        render={(props) =>
          this.renderPaymentView({
            ...props,
            open: match !== null,
            onViewPaymentReceipt: () =>
              this.props.onViewPaymentReceipt(match.params.paymentId),
            onWithdraw: UserRoles.isSupport(this.props.role)
              ? undefined
              : props.onWithdraw,
            railsProviders: contextSupportedRailsSelector(this.context),
          })
        }
        allPaymentChannelRules={
          contextPaymentChannelRulesSelector(this.context).bank
        }
      />
    );
  };

  render() {
    return (
      <>
        {this.renderListContainer()}
        <Route path={`${this.props.match.url}/:paymentId`} exact>
          {this.renderDetailContainer}
        </Route>
      </>
    );
  }
}

export { PaymentsListRoute };
