import { UserStatus } from 'velo-data';
import { UserRoles, MFATypes } from 'velo-react-components';
import {
  formatError,
  replaceEmptyStringsWithNull,
  pipeCallbackFunctions,
} from '../../selectors';

const checkForErrorFormatting = (originalCb, localCb) => (error, data) => {
  originalCb.shouldIgnoreError && originalCb.shouldIgnoreError(error)
    ? originalCb(error)
    : localCb(formatError(error), data);
};

function combineUserAndPayorData({ user, ...props }, { payorName }) {
  return {
    user: {
      ...user,
      payorName,
    },
    ...props,
  };
}

const getRoles = (loggedInRole, intl) =>
  UserRoles.subordinates(loggedInRole).map((role) =>
    UserRoles.option(role, intl)
  );

const adminSupportUserRoles = [UserRoles.PayorAdmin, UserRoles.PayorSupport];

export function UserEditPresenter(
  wireframe,
  entity,
  { userId, role, editPayorUser, editPayeeUser },
  { unregisterMfaNotification, userDetailsNotification },
  intl
) {
  const payeeRoles = [
    UserRoles.option(UserRoles.PayeeAdmin, intl),
    UserRoles.option(UserRoles.PayeeSupport, intl),
  ];
  const backOfficeRoles = [UserRoles.option(UserRoles.BOPAdmin, intl)];
  const getUpdateHandler =
    (notification, goBack = true) =>
    (cb) =>
    (error, data) => {
      if (error) {
        wireframe.sendNote({
          ...notification.error,
          message: error,
        });
      } else {
        wireframe.sendNote({
          ...notification.success,
        });
        goBack && wireframe.goBack();
      }
      cb(error, data);
    };

  function buildViewData(data) {
    const userRole = data.roles[0].name;

    return {
      user: {
        ...data,
        role: userRole,
      },
      payorId: data.entityId,
      isSupportUserWithSMS:
        userRole === UserRoles.PayorSupport && data.mfaType === MFATypes.SMS,
      isBackOfficeEditingAdminOrSupport:
        role === UserRoles.BOPAdmin && adminSupportUserRoles.includes(userRole),
    };
  }

  function getUser(cb) {
    return entity.getUserById(userId, (error, data) => {
      if (error) {
        return cb(error);
      }

      cb(undefined, buildViewData(data));
    });
  }

  function getPayor(viewData, cb) {
    return entity.getPayor(viewData.payorId, (error, payor) => {
      cb(error, {
        ...(!error && combineUserAndPayorData(viewData, payor)),
      });
    });
  }

  const loadPayor = role === UserRoles.BOPAdmin && editPayorUser;

  return [
    // loader, conditionally loads the payor if required
    pipeCallbackFunctions(getUser, ...(loadPayor ? [getPayor] : [])),
    {
      onClose: wireframe.goBack,
      roles: editPayeeUser
        ? payeeRoles
        : role === UserRoles.BOPAdmin && !editPayorUser
        ? backOfficeRoles
        : getRoles(role, intl),
      handlers: {
        onUnregisterMfa: (body, cb) => {
          return entity.unregisterMFA(
            userId,
            body,
            checkForErrorFormatting(
              cb,
              getUpdateHandler(unregisterMfaNotification)(cb)
            )
          );
        },
        onSubmit: ({ role, verificationCode, ...profile }, cb) => {
          const submitFn = Object.keys(profile).length
            ? pipeCallbackFunctions(
                (cb) =>
                  entity.updateUserDetails(
                    userId,
                    replaceEmptyStringsWithNull({
                      ...profile,
                      // Merge in verification only if available
                      ...{ verificationCode },
                    }),
                    (error, data) => {
                      /**
                       * We need to use the updated SMS number from the UserForm
                       *
                       * The OTPAdminInterceptor reads this sms number from
                       * the error and uses it to send an OTP code
                       */
                      if (error) {
                        error.smsNumber = profile.smsNumber;
                      }

                      cb(error, data);
                    }
                  ),
                (_, cb) =>
                  entity.roleUpdate(
                    userId,
                    // Merge in verification only if available
                    { roles: [role], ...{ verificationCode } },
                    (error, data) => {
                      /**
                       * We need to use the updated SMS number from the UserForm
                       *
                       * The OTPAdminInterceptor reads this sms number from
                       * the error and uses it to send an OTP code
                       */
                      if (error) {
                        error.smsNumber = profile.smsNumber;
                      }

                      cb(error, data);
                    }
                  )
              )
            : (cb) =>
                entity.roleUpdate(
                  userId,
                  // Merge in verification only if available
                  { roles: [role], ...{ verificationCode } },
                  cb
                );

          submitFn(
            checkForErrorFormatting(cb, (error, data) => {
              if (error) {
                wireframe.sendNote({
                  ...userDetailsNotification.error,
                  message: error,
                });
              } else {
                wireframe.sendNote({
                  ...userDetailsNotification.success,
                });
                wireframe.goBack();
              }
              cb(error, data);
            })
          );
        },
        onSubmitStatus: (status, cb) => {
          const statusFn =
            status === UserStatus.ENABLED
              ? entity.enableUser
              : entity.disableUser;

          statusFn(
            userId,
            checkForErrorFormatting(
              cb,
              getUpdateHandler(userDetailsNotification, false)(cb)
            )
          );
        },
      },
    },
  ];
}
