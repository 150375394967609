import React, { useEffect, useState } from 'react';
import { string } from 'prop-types';
import { parsePhoneNumberFromString } from 'libphonenumber-js/core';
import metadata from '../VeloPhoneNumberField/metadata.custom.json';
import { VeloFlagLabel } from '../VeloFlagLabel';

PhoneNumber.propTypes = {
  /** The phone number in E.164 format. */
  value: string.isRequired,
};

/**
 * Render a phone number with an approprate flag icon.
 *
 * The phone number needs to be in E.164 format and will
 * be reformatted to make it more readable.
 *
 * If the country cannot be determined from the number then
 * the raw `value` will be rendered instead without a flag.
 */
function PhoneNumber({ value }) {
  const [country, setCountry] = useState();
  const [phoneNumber, setPhoneNumber] = useState(value);

  useEffect(() => {
    const details = parsePhoneNumberFromString(value, metadata);
    setCountry(details?.country);
    setPhoneNumber(details ? details.formatInternational() : value);
  }, [value]);

  return country ? (
    <VeloFlagLabel country={country} label={phoneNumber} />
  ) : (
    <div>{value}</div>
  );
}

export { PhoneNumber };
