export const TRANSMISSION_TYPES = {
  SAME_DAY_ACH: 'SAME_DAY_ACH',
  WIRE: 'WIRE',
  ACH: 'ACH',
  LOCAL: 'LOCAL',
  DEFAULT: 'DEFAULT',
  GACHO: 'GACHO',
  PRIORITY: 'PRIORITY',
  STANDARD: 'STANDARD',
};
