import { useLayoutEffect } from 'react';
import { VeloThemeContext } from 'velo-react-components';
import { themes } from '@design-system/tokens/colors/js/theme-defs.js';

//function to remove theme classes from a given element
const removePortalThemes = (el, theme) => {
  themes.forEach((portalTheme) => {
    portalTheme !== theme && el.classList.remove(portalTheme.name);
  });
};

const useTheme = () => {
  const [theme] = VeloThemeContext.useVeloThemeContext();

  useLayoutEffect(() => {
    //get the body Element
    const body = document.querySelector('body');

    //add selected theme
    body.classList.add(theme);

    return () => {
      //remove other themes that are not required
      removePortalThemes(body, theme);
    };
  }, [theme]);
};

export { useTheme };
