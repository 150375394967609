import React, { useState } from 'react';
import cookie from 'cookie';

const COOKIE_NAME = 'last_locale';
const SUPPORTED_LOCALES = [
  'en-GB',
  'en-US',
  'fr-FR',
  'en-CA',
  'fr-CA',
  'de-DE',
];

const Context = React.createContext();
const LocaleConsumer = Context.Consumer;

const getBrowserLocalePreference = () => {
  if (navigator.languages && navigator.languages.length) {
    return navigator.languages.find(
      (a) =>
        SUPPORTED_LOCALES.find((b) => a.toLowerCase() === b.toLowerCase()) ||
        false
    );
  } else {
    return (
      navigator.userLanguage || navigator.language || navigator.browserLanguage
    );
  }
};

function getCookieLocalePreference() {
  const cookies = cookie.parse(document.cookie);
  const supportedCookieLocale = SUPPORTED_LOCALES.find(
    (lang) => lang === cookies[COOKIE_NAME]
  );
  return supportedCookieLocale;
}

function LocaleProvider({ messages, children }) {
  const [localeTranslations, setLocaleTranslations] = useState(messages);
  const getTranslations = (languagePreference) => {
    // @TODO: To preserve real translations place outside of the `/build` dir
    switch (languagePreference) {
      case 'fr-FR':
        import('velo-react-components/build/translations/fr-FR.json').then(
          (data) => setLocaleTranslations(data.default)
        );
        break;
      case 'de-DE':
        import('velo-react-components/build/translations/de-DE.json').then(
          (data) => setLocaleTranslations(data.default)
        );
        break;
      default:
        setLocaleTranslations(true);
    }

    return localeTranslations;
  };

  const getUsersLocale = () => {
    return getCookieLocalePreference() || getBrowserLocalePreference();
  };

  const getInitialTranslation = () => {
    if (!localeTranslations) {
      getTranslations(getUsersLocale());
    } else {
      return localeTranslations;
    }
  };

  function setLocale(value) {
    document.cookie = cookie.serialize(COOKIE_NAME, value, {
      path: window.location.pathname,
    });

    getTranslations(value);
  }

  const state = {
    locale: getUsersLocale(),
    setLocale,
    localeTranslations: getInitialTranslation(),
  };

  return <Context.Provider value={state}>{children}</Context.Provider>;
}

const LocaleContext = {
  Consumer: LocaleConsumer,
  Provider: LocaleProvider,
};

export { LocaleContext };
