import {
  selectIdFromLocation,
  createEarlyExitCallback,
  createCancelAllClosure,
} from '../selectors';

function poll(statusFn, timeout, finalCallback, cancellationFns) {
  /**
   * The callback passed to the statusFn
   */
  const onStatus = createEarlyExitCallback(function onStatusData(response) {
    /**
     * if the response contains the retryAfter, then we must keep polling
     */
    if (response.retryAfter) {
      return poll(
        statusFn,
        response.retryAfter,
        finalCallback,
        cancellationFns
      );
    }
    /**
     * Otherwise, we assume we have completed the request
     */
    finalCallback(undefined, response);
  }, finalCallback);

  /**
   * Create a timeout with the given timeout
   */
  const timerId = setTimeout(statusFn, timeout, onStatus);
  /**
   * Push the cancellation function so that timers will be cleared
   */
  cancellationFns.push(function cancelPoll() {
    clearTimeout(timerId);
  });
}

/**
 * @typedef {import('../selectors').CancellationFunction} CancelFunction
 * @typedef {import('../selectors').ErrorFirstCallback<D>} NodeStyleCallback
 * @template D
 */

/**
 * Calls the given postFn and expects the response to contain location and retry
 * data. It will then poll the statusFn until it resolves appropriately, at
 * which point it calls the finalCallback with the response. For any errors in
 * the process the finalCallback will be called with the error and short-circuit
 * the polling.
 * The returned function can be used to cancel the requests/polling.
 *
 * @param {function(NodeStyleCallback<T>):CancelFunction} postFn A function that
 * makes the post request, taking a node style callback
 * @param {function(NodeStyleCallback<T>):CancelFunction} statusFn A function
 * used for polling the status taking a node style callback
 * @param {NodeStyleCallback<T>} finalCallback A node-style callback called when
 * the operation is complete
 * @returns {CancelFunction} A function that cancels the process
 * @template T
 */
export function AsyncRequestReplyHelper(postFn, statusFn, finalCallback) {
  /**
   * Define storage for functions to cancel
   */
  const cancellationFns = [];
  cancellationFns.push(
    /**
     * Push the first call to the post api into the cancellation store
     */
    postFn(
      createEarlyExitCallback(
        /**
         * When the post call is successful, it will have the location and the
         * retry timeout available
         */
        function onPostSuccess(payload) {
          const { location, retryAfter } = payload;
          const id = selectIdFromLocation(location);
          if (!retryAfter) {
            return finalCallback(undefined, payload);
          }

          /**
           * Create a function that will call the status function with the
           * captured id.
           */
          function checkStatus(cb) {
            cancellationFns.push(statusFn(id, cb));
          }
          /**
           * Commence polling using the given arguments, passing the final
           * callback and the cancellation store.
           */
          return poll(checkStatus, retryAfter, finalCallback, cancellationFns);
        },
        finalCallback
      )
    )
  );
  /**
   * Return the master cancellation function
   */
  return createCancelAllClosure(cancellationFns);
}
