import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'astroturf/react';
import { VeloTypography } from '../VeloTypography';
import { VeloBackground } from '../VeloBackground';

const Box = styled((props) => (
  <VeloBackground status={VeloBackground.status.WARNING} {...props} />
))`
  display: flex;
  align-items: center;
  padding: 1rem;
  min-height: 3rem;
`;

export function WithdrawnNotice() {
  return (
    <Box>
      <VeloTypography use="bodyText">
        <FormattedMessage defaultMessage="This payout has been withdrawn. The payout details are no longer available." />
      </VeloTypography>
    </Box>
  );
}
