import React from 'react';
import { node, string } from 'prop-types';
import styled from 'astroturf/react';

const Mobile = styled('span')`
  @media (pointer: fine) {
    display: none;
  }
`;

const Desktop = styled('span')`
  @media (pointer: coarse) {
    display: none;
  }
`;

const root = 'velo-responsive-action-text';
const TestIds = {
  MOBILE: `${root}-mobile`,
  DESKTOP: `${root}-desktop`,
};

VeloResponsiveText.testIds = TestIds;

const propTypes = {
  /**
   * Text string for the mobile display
   */
  mobile: node.isRequired,
  /**
   * Text string for the desktop display.
   */
  desktop: node.isRequired,
  /** ID for use in automation tests. */
  'data-testid': string.isRequired,
};

VeloResponsiveText.propTypes = propTypes;

/**
 * Render some responsively sensitive text
 */
function VeloResponsiveText({ mobile, desktop, ...other }) {
  return (
    <>
      <Mobile data-testid={`${other['data-testid']}-mobile`}>{mobile}</Mobile>
      <Desktop data-testid={`${other['data-testid']}-desktop`}>
        {desktop}
      </Desktop>
    </>
  );
}

export { VeloResponsiveText };
