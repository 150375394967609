export const PayoutTypes = {
  TYPE: {
    STANDARD: 'standard',
    ADVANCED: 'advanced',
    OPEN_BANKING: 'open-banking',
  },
  CREATE: {
    GROUP: 'group',
    SINGLE: 'single',
  },
  PAYOUT_TYPE: {
    STANDARD: 'STANDARD',
    AS: 'AS',
    ON_BEHALF_OF: 'ON_BEHALF_OF',
    OPEN_BANKING: 'OPEN_BANKING',
  },
};

PayoutTypes.isHierachyPayout = (payoutType) =>
  payoutType === PayoutTypes.PAYOUT_TYPE.AS ||
  payoutType === PayoutTypes.PAYOUT_TYPE.ON_BEHALF_OF;
