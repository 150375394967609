import React from 'react';
import { arrayOf, bool, func, object, string, shape, any } from 'prop-types';
import index from 'just-index';
import styled from 'astroturf/react';
import { ISOStringToDate, removeTimezoneOffset } from 'velo-data';
import { injectIntl, FormattedMessage } from 'react-intl';
import { VeloChip } from '../VeloChip';
import { FilterForm } from './FilterForm';
import { FilterScrim } from './FilterScrim';
import FilterPropTypes from './filterPropTypes';
import { useIsBelowBreakpoint } from '../hooks';

const Breakpoints = useIsBelowBreakpoint.breakpoints;

const Container = styled('div')`
  position: relative;
  display: flex;
  justify-content: space-between;
`;

const Chips = styled(VeloChip.Set)`
  @import 'velo-variables';

  width: 100%;
  justify-content: flex-end;

  @media (max-width: velo-breakpoint(S)) {
    display: none;
  }
`;

// Compare two filter arrays
const compare = (a, b) => {
  if (a.length !== b.length) return false;
  for (let i = 0; i < a.length; i++) {
    if (a[i].name !== b[i].name) return false;
    if (a[i].value !== b[i].value) return false;
  }
  return true;
};

/**
 * Container used to manage filtering.
 * The container is responsible for rendering a list of existing
 * filters in the form of chips. For managing filters supply a
 * suitable component via the `render` props.
 */
class FilterComponent extends React.Component {
  static propTypes = {
    /** The available columns to filter. */
    fields: arrayOf(FilterPropTypes.field).isRequired,
    /**
     * Called when the filters change.
     * Passed an object map keyed on name with the filter value.
     */
    onChange: func.isRequired,
    /**
     * Used to render a custom filter component (e.g. a button
     * or text field.)
     */
    render: func.isRequired,
    /**
     * Props to apply to the FilterForm. Can be used to ensure
     * the form has the correct styles to line up with the
     * render component.
     */
    formProps: object,
    /** show compact expanded filter */
    compact: bool,
    /** Used to determine if matches the breakpoint passed to HOC. */
    isMobile: bool,
    /** Prime the filter  */
    primedFilters: arrayOf(
      shape({
        /** name of filter */
        name: string,
        /** value of filter */
        value: any,
        /** optional entity id */
        entityId: string,
      })
    ),
  };

  static defaultProps = {
    'data-testid': 'velo-filter-container',
  };

  state = {
    filters: this.props.primedFilters ? this.props.primedFilters : [],
    showPopup: false,
  };

  handleTogglePopup = () => {
    this.setState((state) => ({ showPopup: !state.showPopup }));
  };

  getFieldsWithInitialValue = (fields) =>
    fields
      .filter((field) => field.value || field.entityId)
      .map(({ name, value, entityId }) => ({ name, value, entityId }));

  createFilterStateUpdate = (inputFilters) => {
    // ensures that overlapping filters are de-duped
    const filtersByName = {
      ...index(inputFilters, 'name'),
      ...index(this.state.filters, 'name'),
    };
    return { filters: Object.values(filtersByName) };
  };

  componentDidUpdate(prevProps) {
    const prevFiltersWithInitialValue = this.getFieldsWithInitialValue(
      prevProps.fields
    );
    const filtersWithInitialValue = this.getFieldsWithInitialValue(
      this.props.fields
    );

    if (
      !compare(this.state.filters, filtersWithInitialValue) &&
      !compare(prevFiltersWithInitialValue, filtersWithInitialValue)
    ) {
      this.setState(this.createFilterStateUpdate(filtersWithInitialValue));
    }
  }

  componentDidMount() {
    const filtersWithInitialValue = this.getFieldsWithInitialValue(
      this.props.fields
    );

    this.setState({
      filters: [...this.state.filters, ...filtersWithInitialValue],
    });
  }

  dispatchOnChange = () => {
    const values = this.values();
    const displayValues = this.displayValues();
    this.props.onChange(values, displayValues);
  };

  handleApplyFilters = (data, entityIds) => {
    const filters = Object.keys(data)
      .filter((key) => data[key])
      .map((key) => ({
        name: key,
        value: data[key],
        entityId: entityIds[key],
      }));

    const hasFilterChanged = !compare(filters, this.state.filters);

    this.setState(
      () => ({
        filters,
        showPopup: false,
      }),
      () => {
        if (hasFilterChanged) {
          this.dispatchOnChange();
        }
      }
    );
  };

  handleCancelForm = () => {
    this.setState({ showPopup: false });
  };

  handleDeleteChip = (event) => {
    this.setState(
      (state) => ({
        filters: state.filters.filter(
          (filter) => filter.name !== event.detail.chipId
        ),
      }),
      this.dispatchOnChange
    );
  };

  handleAddFilter = (data) => {
    this.setState(
      (state) => ({
        filters: state.filters.find((filter) => filter.name === data.name)
          ? state.filters.map((filter) =>
              filter.name === data.name
                ? { ...filter, value: data.value }
                : filter
            )
          : [...state.filters, data],
      }),
      this.dispatchOnChange
    );
  };

  handleBackgroundClick = () => {
    this.setState({ showPopup: false });
  };

  // Return the current filter values, keyed on field name
  values = () =>
    this.state.filters.reduce(
      (acc, { name, value, entityId }) => ({
        ...acc,
        [name]: entityId || value,
      }),
      {}
    );

  displayValues = () =>
    this.state.filters.reduce(
      (acc, { name, value, entityId }) => ({
        ...acc,
        [name]: value,
      }),
      {}
    );

  entityIds = () =>
    this.state.filters.reduce(
      (acc, { name, entityId }) => ({
        ...acc,
        [name]: entityId,
      }),
      {}
    );

  formatChipLabel = (name, value) => {
    const { intl } = this.props;
    const isArray = value instanceof Array;
    const field = this.props.fields.find((field) => field.name === name);

    let formattedArrayChipLabel;
    let formattedChipLabel = value;

    if (isArray) {
      formattedArrayChipLabel = this.formatChipArrayDescription(
        value,
        field.type
      );
    } else if (field.type === FilterPropTypes.types.date) {
      formattedChipLabel = this.props.intl.formatDate(value);
    }

    let displayChipLabel =
      formattedArrayChipLabel instanceof Array
        ? formattedArrayChipLabel
            .filter((value) => value !== '')
            .join(` ${intl.formatMessage({ defaultMessage: 'to' })} `)
        : formattedArrayChipLabel || formattedChipLabel;

    if (field.options) {
      const { intl } = this.props;
      const fieldOptions =
        typeof field.options === 'function'
          ? field.options(intl)
          : field.options;
      displayChipLabel = fieldOptions.find(
        ({ value }) => value === displayChipLabel
      ).label;
    }
    const label = field.label;
    return (
      <FormattedMessage
        defaultMessage="{label}: {displayChipLabel}"
        values={{ label, displayChipLabel }}
      />
    );
  };

  formatChipArrayDescription(value, fieldType) {
    const [valueFrom, valueTo] = value;
    let formattedDescription = '';

    if (valueFrom !== '' && valueTo === '') {
      // from value only populated
      formattedDescription =
        fieldType === FilterPropTypes.types.dateRange ? (
          <FormattedMessage
            defaultMessage="after {date}"
            values={{
              date: this.props.intl.formatDate(
                removeTimezoneOffset(ISOStringToDate(valueFrom))
              ),
            }}
          />
        ) : (
          <FormattedMessage
            defaultMessage="{from} or more"
            values={{ from: valueFrom }}
          />
        );
    } else if (valueFrom === '' && valueTo !== '') {
      // to value only populated
      formattedDescription =
        fieldType === FilterPropTypes.types.dateRange ? (
          <FormattedMessage
            defaultMessage="before {date}"
            values={{
              date: this.props.intl.formatDate(
                removeTimezoneOffset(ISOStringToDate(valueTo))
              ),
            }}
          />
        ) : (
          <FormattedMessage
            defaultMessage="{to} or less"
            values={{ to: valueTo }}
          />
        );
    } else {
      //return formatted array to display
      formattedDescription =
        fieldType === FilterPropTypes.types.dateRange
          ? [
              this.props.intl.formatDate(
                removeTimezoneOffset(ISOStringToDate(valueFrom))
              ),
              this.props.intl.formatDate(
                removeTimezoneOffset(ISOStringToDate(valueTo))
              ),
            ]
          : value;
    }

    return formattedDescription;
  }

  render() {
    return (
      <Container data-testid={this.props['data-testid']}>
        {/* Render current filters as chips. */}
        <Chips>
          {this.state.filters.map(({ name, value }) => (
            <VeloChip
              key={name}
              id={name}
              label={this.formatChipLabel(name, value)}
              onRemove={this.handleDeleteChip}
              trailingIcon={{
                icon: 'close',
                'data-testid': `filter-chip-icon-${name}`,
              }}
              data-testid={`filter-chip-${name}`}
            />
          ))}
        </Chips>

        {/* Render a custom filter component. */}
        {this.props.render({
          fields: this.props.fields,
          filters: this.state.filters,
          onTogglePopup: this.handleTogglePopup,
          onAddFilter: this.handleAddFilter,
        })}

        {/* Show the Filters popup. */}
        {this.state.showPopup && (
          <>
            <FilterScrim
              onClick={this.handleBackgroundClick}
              data-testid="velo-filter-scrim"
            />
            <FilterForm
              fields={this.props.fields}
              values={this.displayValues()}
              entityIds={this.entityIds()}
              onSubmit={this.handleApplyFilters}
              isMobile={this.props.isMobile}
              onCancel={this.handleCancelForm}
              isUnique={this.props.isUnique}
              compact={this.props.compact}
              compactWidth={this.props.compactWidth}
            />
          </>
        )}
      </Container>
    );
  }
}

function WrappedFilter(props) {
  const isMobile = useIsBelowBreakpoint(Breakpoints.S);
  return <FilterComponent {...props} isMobile={isMobile} />;
}

const FilterContainer = injectIntl(WrappedFilter);

export { FilterContainer };
