import { string } from 'prop-types';
import styled from 'astroturf/react';
import { VeloButton } from '../VeloButton';

export const VeloCardButton = styled(VeloButton.Primary)`
  @import 'velo-variables';

  @media (max-width: velo-breakpoint(XS)) {
    width: 100%;
  }
`;

VeloCardButton.propTypes = {
  /** The unique test id required for the button */
  'data-testid': string.isRequired,
};
