import React, { useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { func, shape, string } from 'prop-types';
import styled from 'astroturf/react';
import { Content } from '../Content';
import { VeloCard } from '../VeloCard';
import { VeloErrorCard } from '../VeloErrorCard';
import { AddPayee } from '../AddPayee';

const Card = styled(VeloCard)`
  max-width: none !important;

  &:global(.mdc-card) {
    padding: 0;
  }
`;

EditPayeeForm.propTypes = {
  /**
   * Flattened API response.
   */
  values: shape({
    // Individual
    title: string,
    firstName: string,
    otherNames: string,
    lastName: string,
    dateOfBirth: string,
    nationalIdentification: string,
    // Company
    name: string,
    operatingName: string,
    taxId: string,
    // Shared
    email: string,
    remoteId: string,
    // Address
    line1: string,
    line2: string,
    line3: string,
    line4: string,
    city: string,
    countyOrProvince: string,
    zipOrPostcode: string,
    countryCode: string,
    // Challenge code
    challengeDescription: string,
    challengeValue: string,
    // Payment method
    paymentCountry: string,
    currency: string,
    accountName: string,
    accountNumber: string,
    routingNumber: string,
    iban: string,
  }).isRequired,
  /**
   * Initially selected country.
   */
  country: string.isRequired,
  /**
   * Called when the form is submitted. Passed the form
   * data, including a `type` of `Individual` or `Company`.
   */
  onSubmit: func.isRequired,
  /**
   * Called when the Cancel button is clicked.
   */
  onClose: func.isRequired,
};

function EditPayeeForm({ values, onSubmit, ...props }) {
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState();

  return (
    <Content>
      <Card fullScreen>
        <VeloCard.Header divider>
          <VeloCard.HeaderTitle>
            <FormattedMessage defaultMessage="Edit Payee" />
          </VeloCard.HeaderTitle>
        </VeloCard.Header>
        <VeloCard.Body>
          <VeloCard.Content>
            <AddPayee
              mode={AddPayee.mode.PAYOR_EDIT}
              showChallengeCode={!!values.challengeDescription}
              showPaymentChannel={!!values.accountName}
              values={values}
              submitting={submitting}
              error={error}
              onSubmit={(body) => {
                setSubmitting(true);
                onSubmit(body, (error) => {
                  setSubmitting(false);
                  setError(error);
                });
              }}
              masked={['nationalIdentification', 'taxId']}
              {...props}
            />
          </VeloCard.Content>
        </VeloCard.Body>
      </Card>
    </Content>
  );
}

function Loading() {
  /* istanbul ignore next */
  const noop = () => {};

  return (
    <EditPayeeForm
      loading
      values={{}}
      country="US"
      payeeCountryRules={[]}
      onSubmit={noop}
      onClose={noop}
    />
  );
}

function Error({ error }) {
  const intl = useIntl();
  return (
    <Content>
      <VeloErrorCard
        title={intl.formatMessage({ defaultMessage: 'An error occurred' })}
        message={error}
      />
    </Content>
  );
}

EditPayeeForm.Loading = Loading;
EditPayeeForm.Error = Error;

export { EditPayeeForm };
