import React from 'react';
import { func, oneOf, object, array, string } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import TestIds from './testIds';
import { PayoutTypeRadioGroup } from './PayoutTypeRadioGroup';
import { PayoutTypes } from '../PayoutTypes';
import { Content } from '../Content';
import { VeloStepper } from '../VeloStepper';
import { VeloIconButton } from '../VeloIconButton';
import { VeloCard } from '../VeloCard';
import {
  GroupStandardCreateStep,
  SingleStandardCreateStep,
  GroupAdvancedCreateSteps,
  SingleAdvancedCreateSteps,
} from '../PayoutCreateSteps';

PayoutCreateForm.propTypes = {
  /** callback triggered on closing form. */
  onClose: func.isRequired,
  /** callback triggered on switching creation types  */
  onChange: func.isRequired,
  /** the type of payout being made - ADVANCED or STANDARD. */
  payoutType: oneOf(Object.values(PayoutTypes.TYPE)).isRequired,
  /** the type of payout being created - GROUP or SINGLE. */
  selectedCreationType: oneOf(Object.values(PayoutTypes.CREATE)).isRequired,
  /** default currency to use */
  defaultCurrency: string.isRequired,
  /** available currencies to pay in. */
  currencyLabelList: array.isRequired,
  /** payor source accounts. */
  sourceAccountNameList: array.isRequired,
  /** handler to call on selecting a sourceAccount*/
  onSourceAccountSelected: func.isRequired,
  /** payor source transmission Types list of labels. */
  transmissionTypesMap: object,
  /** remote payment systems. */
  remoteSystemIds: array.isRequired,
};

const titlesByPayoutType = {
  [PayoutTypes.TYPE.STANDARD]: (
    <FormattedMessage defaultMessage="Create Payout" />
  ),
  [PayoutTypes.TYPE.ADVANCED]: (
    <FormattedMessage defaultMessage="Create Payout For Another Payor" />
  ),
};

function renderSteps(payoutType, selectedCreationType, props) {
  let Component = SingleStandardCreateStep;
  switch (payoutType) {
    case PayoutTypes.TYPE.ADVANCED:
      Component =
        selectedCreationType === PayoutTypes.CREATE.SINGLE
          ? SingleAdvancedCreateSteps
          : GroupAdvancedCreateSteps;
      break;
    default:
      Component =
        selectedCreationType === PayoutTypes.CREATE.SINGLE
          ? SingleStandardCreateStep
          : GroupStandardCreateStep;
  }

  return <Component {...props} />;
}

function PayoutCreateForm({
  onClose,
  onChange,
  payoutType,
  selectedCreationType,
  ...props
}) {
  return (
    <Content>
      <VeloCard>
        <VeloCard.Header>
          <VeloCard.HeaderTitle>
            {titlesByPayoutType[payoutType]}
          </VeloCard.HeaderTitle>
          <VeloIconButton
            title="close"
            icon="close"
            data-testid={TestIds.CLOSE}
            onClick={onClose}
          />
        </VeloCard.Header>
        <VeloCard.Body>
          <VeloStepper>
            {/* Step 1 */}
            <VeloStepper.Step>
              <VeloStepper.Label
                icon="1"
                text={
                  <FormattedMessage defaultMessage="Pay multiple payees simultaneously using Group Payout or select Single Payout to make a one time payment to a payee" />
                }
              />
              <VeloStepper.Content>
                <PayoutTypeRadioGroup
                  selectedPayoutType={selectedCreationType}
                  onChange={onChange}
                />
              </VeloStepper.Content>
            </VeloStepper.Step>
            {renderSteps(payoutType, selectedCreationType, props)}
          </VeloStepper>
        </VeloCard.Body>
      </VeloCard>
    </Content>
  );
}

export { PayoutCreateForm };
