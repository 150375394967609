import defaultTheme from '@design-system/tokens/colors/js/default-theme.js';
import { getScriptJsonText } from '../getScriptJsonText';

function getJsonConfig() {
  let jsonConfig = {};
  try {
    jsonConfig = JSON.parse(getScriptJsonText('[data-name="config-json"]'));
  } catch (ex) {
    jsonConfig = {};
  }

  //get from env variable
  const envTheme = process.env.REACT_APP_THEME;

  return {
    ...{
      //if api theme set, this is used, otherwise, a theme set in env is used. otherwise, if neither set, the theme is defaulted to no theme.
      theme: envTheme || defaultTheme,
    },
    ...jsonConfig,
  };
}

export { getJsonConfig };
