import React from 'react';
import { node, oneOf, oneOfType, object, string } from 'prop-types';
import styled from 'astroturf/react';
import { VeloIcon } from '../VeloIcon';
import { VeloTypography } from '../VeloTypography';

const Container = styled('div')`
  display: flex;
  align-items: center;
`;

const Label = styled(VeloTypography)`
  @import '@design-system/tokens/typography/scss/mixins';

  @include ds-typography-ellipsis;

  margin-left: 0.5rem;
`;

const propTypes = {
  /**
   * The icon name.
   */
  icon: oneOfType([string, object]).isRequired,
  /**
   * Text string for the label.
   */
  label: node.isRequired,
  /**
   * The label typography style.
   */
  use: oneOf(VeloTypography.veloTokens).isRequired,
};

VeloIconLabel.propTypes = propTypes;

function VeloIconLabel({ label, use, ...other }) {
  return (
    <Container>
      <VeloIcon {...other} />
      <Label use={use}>{label}</Label>
    </Container>
  );
}

export { VeloIconLabel };
