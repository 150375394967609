import React, { useCallback } from 'react';
import { useParams } from 'react-router';
import {
  PaymentView,
  UserRoles,
  VeloNotification,
} from 'velo-react-components';
import {
  PaymentDetailContainer,
  PaymentDetailMode,
  PaymentsListMode,
} from '../../containers';
import {
  contextPaymentChannelRulesSelector,
  contextSupportedRailsSelector,
  useAppContext,
} from '../../context';
import { usePresenter, useSerializableMemo } from '../../hooks';
import { PayoutPaymentPresenter } from './PayoutPaymentPresenter';
import { withdrawPayment } from 'velo-api/src/entities/payments/withdrawPayment';

const entitySpec = {
  withdrawPayment: withdrawPayment,
};

const fixedProps = {
  withdrawFailure: VeloNotification.types.PAYMENT_WITHDRAW_FAILURE,
  withdrawSuccess: VeloNotification.types.PAYMENT_WITHDRAW_SUCCESS,
};

const payoutViewMap = {
  [PaymentsListMode.BACKOFFICE]: PaymentView.BackOffice,
  [PaymentsListMode.PAYOR]: PaymentView.Payor,
};

export function PayoutPaymentRoute({
  query,
  role,
  mode = PaymentDetailMode.PAYOR,
}) {
  const isSupport = UserRoles.isSupport(role);
  const { paymentId, payoutId } = useParams();
  const { onClose, onWithdraw, navigateToReceipt } = usePresenter(
    PayoutPaymentPresenter,
    entitySpec,
    fixedProps,
    useSerializableMemo({
      payoutId,
      paymentId,
    })
  );

  const context = useAppContext();
  const PaymentView = payoutViewMap[mode];
  const renderPaymentView = useCallback(
    (props) => (
      <PaymentView
        // spread before to ensure custom props below
        {...props}
        onClose={onClose}
        onWithdraw={isSupport ? undefined : onWithdraw}
        open={paymentId !== undefined}
        onViewPaymentReceipt={navigateToReceipt}
        railsProviders={contextSupportedRailsSelector(context)}
      />
    ),
    [paymentId, navigateToReceipt, onClose, onWithdraw, isSupport, context]
  );

  return (
    <PaymentDetailContainer
      mode={mode}
      query={query}
      paymentId={paymentId}
      render={renderPaymentView}
      allPaymentChannelRules={contextPaymentChannelRulesSelector(context).bank}
    />
  );
}
