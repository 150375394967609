import React, { useCallback, useState } from 'react';
import { func, node, bool, object } from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'astroturf/react';
import { VeloCard } from '../VeloCard';
import { VeloErrorCard } from '../VeloErrorCard';
import { VeloOverlay } from '../VeloOverlay';
import { VeloIconButton } from '../VeloIconButton';
import { ConfirmationDialog } from '../ConfirmationDialog';
import { VeloSkeleton } from '../VeloSkeleton';

const Card = styled(VeloCard)`
  @import 'velo-variables';

  padding: 0rem 0rem 1rem;

  @media (min-width: velo-breakpoint(XS)) {
    background-color: velo-color('token-color-system-page-background');
  }
`;

const PADDING = '1rem';

const Header = styled(VeloCard.Header)`
  @import 'velo-variables';
  @media (min-width: velo-breakpoint(XS)) {
    margin-left: ${PADDING};
    margin-right: 0.5rem;
  }
`;

const Action = styled(VeloCard.Action)`
  @import 'velo-variables';
  @media (min-width: velo-breakpoint(XS)) {
    margin-left: ${PADDING};
    margin-right: ${PADDING};
  }
`;

const Content = styled(VeloCard.Content)`
  @import 'velo-variables';
  @media (min-width: velo-breakpoint(XS)) {
    margin-left: ${PADDING};
    margin-right: ${PADDING};
  }
`;

const TitleSkeleton = styled(VeloSkeleton)`
  width: 40%;
`;

const ErrorContainer = styled(VeloCard.Error)`
  @import 'velo-variables';
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  border-radius: 4px;
  padding: 0;
  &.slimline {
    margin-left: 1rem;
    margin-right: 1rem;
    @media (min-width: velo-breakpoint(XS)) {
      margin-left: ${PADDING};
      margin-right: ${PADDING};
    }
  }
`;

ErrorContainer.propTypes = {
  slimline: bool,
};

const PreFormContent = styled(Content)`
  @import 'velo-variables';
  padding: 0;
`;

const ErrorMessage = styled(VeloCard.ErrorMessage)`
  @import 'velo-mixins';

  @include velo-font-weight(regular);
  padding: 1rem;
  margin: 0;
`;

const SubmitError = ({ error, ...props }) => (
  <ErrorContainer {...props}>
    <ErrorMessage>{error}</ErrorMessage>
  </ErrorContainer>
);

const root = 'velo-card-form';

const TestIds = {
  ACTION_BUTTON: `${root}-action-button`,
  CLOSE_BUTTON: `${root}-close-btn`,
  FORM: `${root}-form`,
  OVERLAY: `${root}-overlay`,
  DISCARD_CHANGES_DIALOG: `${root}-discard-changes`,
  ...ConfirmationDialog.testIds,
};

function Form({ onSubmit, slimline, error, buttonProps, overlay, children }) {
  return (
    <form onSubmit={onSubmit} data-testid={TestIds.FORM}>
      <Content slimline={slimline}>
        {children}
        {error && (
          <SubmitError slimline={slimline} role="alert" error={error} />
        )}
      </Content>
      <Action>
        <VeloCard.Button
          type="submit"
          disabled={overlay}
          data-testid={TestIds.ACTION_BUTTON}
          {...buttonProps}
        />
      </Action>
    </form>
  );
}

VeloCardForm.propTypes = {
  /**
   * A function that is called when the form is closed.
   */
  onClose: func,
  /**
   * A function that is called when the form is submitted.
   */
  onSubmit: func.isRequired,
  /**
   * Optional JSX to prelude the form content.
   */
  preFormBody: node,
  /**
   * An optional error if there is a issue when submitting.
   */
  error: node,
  /**
   * A boolean indicating whether to show the overlay.
   */
  overlay: bool,
  /**
   * An optional class to apply to the primary button.
   */
  buttonProps: object,
  /**
   * Indicates that the card content is slimline (without margins)
   */
  slimline: bool,
};

function VeloCardForm({
  title,
  onClose,
  onSubmit,
  preFormBody,
  children,
  error,
  slimline,
  overlay = false,
  dirty = false,
  divider = true,
  buttonProps = {},
  ...props
}) {
  const intl = useIntl();
  const [dialogState, setDialogState] = useState({ open: false });

  const onDialogClose = useCallback(
    (evt) => {
      if (evt.detail.action === 'accept') {
        onClose();
      }
      setDialogState({ open: false });
    },
    [onClose, setDialogState]
  );

  const onClick = useCallback(() => {
    if (dirty) {
      setDialogState({ open: true });
    } else {
      onClose();
    }
  }, [setDialogState, dirty, onClose]);
  return (
    <Card {...props}>
      <Header divider={divider}>
        <VeloCard.HeaderTitle>{title}</VeloCard.HeaderTitle>
        {onClose && (
          <VeloIconButton
            icon="close"
            title={intl.formatMessage({ defaultMessage: 'Close' })}
            onClick={onClick}
            data-testid={TestIds.CLOSE_BUTTON}
          />
        )}
      </Header>
      <VeloCard.Body>
        {preFormBody && (
          <PreFormContent slimline={slimline}>{preFormBody}</PreFormContent>
        )}
        <Form
          onSubmit={onSubmit}
          error={error}
          slimline={slimline}
          buttonProps={buttonProps}
          overlay={overlay}
        >
          {children}
        </Form>
      </VeloCard.Body>
      <VeloOverlay show={overlay} data-testid={TestIds.OVERLAY} />
      <ConfirmationDialog
        dialogType={ConfirmationDialog.dialogTypes.DiscardChangesType}
        data-testid={TestIds.DISCARD_CHANGES_DIALOG}
        onClose={onDialogClose}
        {...dialogState}
      />
    </Card>
  );
}

VeloCardForm.testIds = TestIds;

VeloCardFormInError.propTypes = {
  /**
   * A optional function that is called when the form is closed.
   */
  onClose: func,
  /**
   * An optional error if there is a issue when submitting.
   */
  error: node.isRequired,
  /**
   * An optional title for the card.
   */
  title: node,
};

function VeloCardFormInError({ error, onClose, title, className, fullWidth }) {
  const intl = useIntl();
  return (
    <VeloErrorCard
      fullWidth
      close={
        onClose && (
          <VeloIconButton
            icon="close"
            title={intl.formatMessage({ defaultMessage: 'Close' })}
            onClick={onClose}
            data-testid={TestIds.CLOSE_BUTTON}
          />
        )
      }
      title={title || <FormattedMessage defaultMessage="An error occurred" />}
      message={error}
      className={className}
    />
  );
}

VeloCardFormInError.testIds = {
  CLOSE_BUTTON: TestIds.CLOSE_BUTTON,
};

VeloCardFormLoading.propTypes = {
  /**
   * Called when the close button is clicked.
   */
  onClose: func.isRequired,
};

VeloCardFormLoading.testIds = {
  CLOSE_BUTTON: TestIds.CLOSE_BUTTON,
  ACTION_BUTTON: TestIds.ACTION_BUTTON,
};

function VeloCardFormLoading({ onClose, children, title, slimline, ...other }) {
  const intl = useIntl();
  return (
    <Card {...other}>
      <Header divider>
        <VeloCard.HeaderTitle>
          {title || <TitleSkeleton />}
        </VeloCard.HeaderTitle>
        <VeloIconButton
          icon="close"
          title={intl.formatMessage({ defaultMessage: 'Close' })}
          onClick={onClose}
          data-testid={TestIds.CLOSE_BUTTON}
        />
      </Header>
      <VeloCard.Body>
        <Content slimline={slimline}>{children}</Content>
        <Action>
          <VeloCard.Button skeleton data-testid={TestIds.ACTION_BUTTON} />
        </Action>
      </VeloCard.Body>
    </Card>
  );
}

VeloCardForm.Form = Form;
VeloCardForm.Error = VeloCardFormInError;
VeloCardForm.Loading = VeloCardFormLoading;
VeloCardForm.SubmitError = SubmitError;
VeloCardForm.ErrorMessage = ErrorMessage;

export { VeloCardForm };
