import React from 'react';
import { node, string } from 'prop-types';
import { Content } from '../Content';
import { VeloCard } from '../VeloCard';

ContentWithCard.propTypes = {
  /** Custom CSS classes. */
  className: string,
  /** The custom header to render. */
  header: node,
  /** The content to render. */
  children: node,
};

function ContentWithCard({ children, header, flat = true, ...props }) {
  return (
    <Content
      {...props}
      header={<VeloCard.Container flat={flat}>{header}</VeloCard.Container>}
    >
      <VeloCard fullScreen={false}>{children}</VeloCard>
    </Content>
  );
}

export { ContentWithCard };
