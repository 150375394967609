import React from 'react';
import { bool, func, string, number } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Money from 'js-money';
import { VeloDialog } from '../VeloDialog';
import { VeloTypography } from '../VeloTypography';
import { ConfirmFunds } from '../ConfirmFunds';

SourceAccountTransferFundsConfirmationDialog.propTypes = {
  /** Open/close the dialog. */
  open: bool.isRequired,
  /** Called when the dialog is closed. Optional, as underlying dialog is option */
  onClose: func,
  /** The currency code ("USD", "GBP", etc.). */
  currency: string.isRequired,
  /** The amount the transfer is for */
  amount: number.isRequired,
  /**The account where the funds are transferred from */
  sourceAccountFromName: string.isRequired,
  /**The account where the funds are transferred to */
  sourceAccountToName: string.isRequired,
};

const Labels = {
  TRANSFER_BUTTON: <FormattedMessage defaultMessage="Confirm" />,
  CANCEL_BUTTON: <FormattedMessage defaultMessage="Cancel" />,
};

SourceAccountTransferFundsConfirmationDialog.Labels = Labels;

export function SourceAccountTransferFundsConfirmationDialog({
  open,
  onClose,
  currency,
  amount,
  sourceAccountFromName,
  sourceAccountToName,
}) {
  const amountMoney = new Money(amount, currency);

  return (
    <VeloDialog open={open} onClose={onClose}>
      <VeloDialog.Title>
        <VeloTypography use="secondaryHeader">
          <FormattedMessage defaultMessage="Confirm Transfer Funds" />
        </VeloTypography>
      </VeloDialog.Title>

      <VeloDialog.Content>
        <ConfirmFunds.Text>
          <FormattedMessage
            defaultMessage="You are about to transfer {amount} from {from} to {to}."
            values={{
              amount: <ConfirmFunds.Amount money={amountMoney} />,
              from: <strong>{sourceAccountFromName}</strong>,
              to: <strong>{sourceAccountToName}</strong>,
            }}
          />
        </ConfirmFunds.Text>

        <ConfirmFunds.Text>
          <FormattedMessage defaultMessage="Are you sure you want to transfer these funds?" />
        </ConfirmFunds.Text>
      </VeloDialog.Content>

      <VeloDialog.Actions>
        <VeloDialog.Button type="button" action="accept" isDefaultAction>
          {Labels.TRANSFER_BUTTON}
        </VeloDialog.Button>
        <VeloDialog.Button type="button" action="close">
          {Labels.CANCEL_BUTTON}
        </VeloDialog.Button>
      </VeloDialog.Actions>
    </VeloDialog>
  );
}
