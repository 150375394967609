import { useState, useEffect, useRef } from 'react';
import { formatError } from '../../selectors';
import { PayoutStates } from 'velo-data';

function getRequoteTimeOut(result) {
  const now = new Date().getTime();
  // Filter to be only active fxSummaries (Batches that have not been paid - See Payout INCOMPLETE state)
  // - include WITHDRAWN but this should
  // never be returned in normal execution
  const filteredFxSummaries = result.fxSummaries.filter(
    (fx) =>
      fx.status !== 'EXECUTED' &&
      fx.status !== 'REJECTED' &&
      fx.status !== 'WITHDRAWN'
  );

  return filteredFxSummaries.reduce((lastTime, fx) => {
    const newTime = new Date(fx.expiryTime).getTime() - now;
    if (lastTime === 0) {
      return newTime;
    } else {
      return newTime < lastTime ? newTime : lastTime;
    }
  }, 0);
}

// Get only referenced quotes to avoid infinite anonymous re-quoting
function getQuoteResult(quoteRef, quoteInflight, autoReQuote) {
  if (quoteRef?.current) {
    quoteRef.current = null;
    quoteInflight.current = null;
    return autoReQuote;
  }
  return;
}

function usePayoutAutoReQuote({
  quotePayout,
  allowQuote,
  defaultRetryInterval = 2000,
}) {
  /** Timer for re-quoting when fx quote expires */
  const quoteRef = useRef(null);
  const quoteInflight = useRef(null);

  /** Quote data for the Batches (source>destination currency payment groups) in the Payout */
  const [quoteData, setQuoteData] = useState({
    result: undefined,
    error: undefined,
  });

  const getQuote = () => {
    quoteInflight.current = true;
    quotePayout((error, result) =>
      getQuoteResult(
        quoteRef,
        quoteInflight,
        autoReQuote({
          error: formatError(error),
          result: result ? result : undefined,
        })
      )
    );
  };

  // Cancel re-quotes when exiting
  useEffect(() => {
    return () => {
      clearTimeout(quoteRef.current);
      quoteRef.current = null;
      quoteInflight.current = null;
    };
  }, []);

  function retry(time = defaultRetryInterval) {
    if (!quoteRef.current) {
      quoteRef.current = setTimeout(getQuote, Math.max(time, 0));
    }
  }

  function autoReQuote({ result, error }) {
    setQuoteData({ result, error });

    if (error && allowQuote) {
      // if a payment is withdrawn, quote will error - and we delay and retry
      retry();
    } else {
      if (allowQuote) {
        if (result && result.fxSummaries && result.fxSummaries.length > 0) {
          const reQuoteTimeout = getRequoteTimeOut(result);
          //Re-quote immediately or with 10% buffer on the earliest FX expiry ensures the Payout is always quoted within session.
          const buffer = 0.1;
          const timeOutWithBuffer = Math.floor(
            reQuoteTimeout - reQuoteTimeout * buffer
          );
          retry(timeOutWithBuffer);
        } else {
          // retry only if the Payout is not rejected - prevents repeated quotes that will always fail
          if (result && result.status !== PayoutStates.REJECTED) {
            retry();
          }
        }
      }
    }
  }

  return { quoteData, autoReQuote };
}
export { usePayoutAutoReQuote, getRequoteTimeOut, getQuoteResult };
