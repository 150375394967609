export function updateAccountDetailsV2(send) {
  return (fundingAccountId, body, ...args) =>
    send(
      {
        path: '/v2/fundingAccounts/{fundingAccountId}/updateAccountDetails',
        pathParameters: { fundingAccountId },
        type: 'post',
        query: undefined,
        body: body,
        headers: undefined,
        auth: 'oauth2',
        contentType: 'application/json',
      },
      ...args
    );
}
