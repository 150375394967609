import React from 'react';
import { string, bool, func } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { ConfirmationDialog } from '../ConfirmationDialog';

const root = 'velo-payment-channel';

const TestIds = {
  DELETE_DIALOG: `${root}-delete-dialog`,
};

/**
 * Payment channel delete confirmation popup.
 */
class PaymentChannelViewDeleteConfirmation extends React.Component {
  static propTypes = {
    /** The name of the channel being deleted. */
    paymentChannelName: string.isRequired,
    /** Used to open or close the dialog. */
    open: bool,
    /** Called when user opts to delete the payment channel. */
    onDelete: func.isRequired,
    /** Called when the dialog is closed. */
    onClose: func.isRequired,
    onClick: func.isRequired,
  };

  static testIds = TestIds;

  handleClose = (event) => {
    const { onDelete, onClose } = this.props;
    if (event.detail.action === 'accept') {
      onDelete();
    } else {
      onClose();
    }
  };

  render() {
    const { paymentChannelName, open } = this.props;

    const DeletePaymentChannelType = ConfirmationDialog.typeOf(
      'DeletePaymentChannelType',
      <FormattedMessage defaultMessage="Delete Payment Method" />,
      <FormattedMessage defaultMessage="Delete" />,
      <FormattedMessage defaultMessage="Cancel" />,
      <FormattedMessage
        defaultMessage='Are you sure you want to delete the payment method "{paymentChannelName}"?'
        values={{ paymentChannelName }}
      />
    );

    return (
      <ConfirmationDialog
        open={open}
        onClose={this.handleClose}
        data-testid={TestIds.DELETE_DIALOG}
        ref={this.ref}
        onClick={this.handleClick}
        dialogType={DeletePaymentChannelType}
      />
    );
  }
}

export { PaymentChannelViewDeleteConfirmation };
