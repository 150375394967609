import { useEffect, useMemo } from 'react';
import omit from 'just-omit';
import { forkResult, withResult, combine } from '../../selectors';
import { useDataJoin } from '../useDataJoin';
import { useCallbackAsResultState } from '../useCallbackAsResultState';
import { useAllQueries } from '../queries';
import { useAPIMethods } from 'velo-api-react-hooks';
import { getPaymentsForPayoutV4 } from 'velo-api/src/entities/paymentaudit';
import { getPayeeByIdV4 as getPayeeById } from 'velo-api/src/entities/payees';

const entitySpec = {
  getPaymentsByPayout: getPaymentsForPayoutV4,
  getPayee: getPayeeById,
};

const pagePropForks = {
  none: (props) => props,
  error: (_, props) => props,
  value: (
    { summary: { totalPayments }, page: { page, totalPages } },
    props,
    query
  ) => ({
    // This is a work around for a PaymentAudit Delay where COMPLETED/INSTRUCTED
    // Payouts contain no Payment items yet have > 0 totalPayments - query check to
    // not show when a filter is applied
    isProcessing: totalPayments > 0 && Object.keys(query).length === 0,
    ...props,
    page,
    totalPages,
  }),
};

const emptyArray = () => [];

const extractContentForks = {
  none: emptyArray,
  empty: emptyArray,
  error: emptyArray,
  value: ({ content }) => content || [],
};

const combiner = (data, content) => ({
  ...data,
  ...(data.content && { content }),
});

const prePopulateSummary = withResult((data) => ({
  ...data,
  // set content to empty to show the loading table
  content: undefined,
}));

const extractDisplayName = withResult(({ displayName }) => displayName);

function createPaymentDataMapper(resultsById) {
  return (payment) => ({
    ...payment,
    // The payeeId could be missing, in which case
    // assume a blank name.
    payeeName: payment.payeeId
      ? extractDisplayName(resultsById[payment.payeeId] || {})
      : { result: '' },
  });
}

export function usePaymentsByPayout({
  payoutId,
  filters,
  defaultColumn,
  query,
}) {
  const [listQuery, { pageProps, ...props }] = useAllQueries(
    filters,
    defaultColumn
  );

  const entity = useAPIMethods(entitySpec);

  const [paymentsResult, onPaymentsResult, setPaymentsResult] =
    useCallbackAsResultState();

  useEffect(() => {
    // preserve the summary header between pagination/sort/filter
    setPaymentsResult((result) => prePopulateSummary(result));
    return entity.getPaymentsByPayout(
      payoutId,
      { ...query, ...listQuery },
      onPaymentsResult
    );
  }, [setPaymentsResult, onPaymentsResult, entity, query, listQuery, payoutId]);

  const result = useDataJoin(
    forkResult(extractContentForks, paymentsResult),
    // use memo is used to stop the functions re-compiling
    ...useMemo(
      () => [
        // a function that extracts parameters to be passed to the loader
        (data) => data.map(({ payeeId: key }) => ({ key, params: [key, {}] })),
        // the function to be called with the load parameters (the loader)
        entity.getPayee,
        // a function that joins the results with the original data
        (data, resultsById) => data.map(createPaymentDataMapper(resultsById)),
      ],
      [entity.getPayee]
    )
  );

  const joinedResult = combine(combiner, paymentsResult, { result });

  return [
    joinedResult,
    {
      ...props,
      pageProps: forkResult(
        pagePropForks,
        paymentsResult,
        pageProps,
        omit({ ...listQuery }, ['sort', 'page', 'pageSize'])
      ),
    },
  ];
}
