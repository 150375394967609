import React from 'react';
import { oneOf, string } from 'prop-types';
import styled from 'astroturf/react';
import { VeloTextField } from '../VeloTextField';
import { VeloIcon } from '../VeloIcon';

const TextField = styled(VeloTextField)`
  /* due to selector specificity in material, we need important here :-( */
  input {
    padding-right: 54px !important;
  }
`;

const Icon = styled(VeloIcon)`
  @import '@material/theme/mixins';
  @import '@material/textfield/variables';

  color: $mdc-text-field-disabled-icon !important;
  right: 30px !important;
`;

const FieldTypeEnum = {
  Password: 'password',
  PlainText: 'text',
};

const inversions = {
  [FieldTypeEnum.Password]: FieldTypeEnum.PlainText,
  [FieldTypeEnum.PlainText]: FieldTypeEnum.Password,
};

const IconMap = {
  [FieldTypeEnum.Password]: 'visibility_off',
  [FieldTypeEnum.PlainText]: 'visibility',
};

class VeloPasswordField extends React.Component {
  static propTypes = {
    /**
     * For the password field, the type can only be either 'text' or 'password'
     */
    type: oneOf(Object.values(FieldTypeEnum)),
    /**
     * Optional additional className to apply
     */
    className: string,
  };

  static defaultProps = {
    type: FieldTypeEnum.Password,
  };

  state = { type: this.props.type };

  toggleType = () => this.setState((s) => ({ type: inversions[s.type] }));

  /**
   * We use mouse down to prevent a focus change.
   */
  onMouseDown = (e) => {
    e.preventDefault();
    this.toggleType();
  };

  render() {
    const { forwardedRef, ...props } = this.props;
    const fieldProps = { ...props, ...this.state };
    const iconProps = {
      icon: IconMap[fieldProps.type],
      onMouseDown: this.onMouseDown,
    };

    return (
      <TextField
        {...fieldProps}
        ref={forwardedRef}
        required
        trailingIcon={{ strategy: 'component', icon: <Icon {...iconProps} /> }}
      />
    );
  }
}

/**
 * This allows use to get a ref to the underlying MDC TextField
 * component. This can be used to disablenative validation, etc.
 */
const VeloPasswordFieldRef = React.forwardRef((props, ref) => (
  <VeloPasswordField {...props} forwardedRef={ref} />
));

VeloPasswordFieldRef.IconMap = IconMap;
VeloPasswordFieldRef.FieldTypeEnum = FieldTypeEnum;

export { VeloPasswordFieldRef as VeloPasswordField };
