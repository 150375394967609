import { PayeeType } from 'velo-data';

const payeeTypeOptions = [
  {
    label: 'Individual',
    value: PayeeType.INDIVIDUAL,
  },
  {
    label: 'Company',
    value: PayeeType.COMPANY,
  },
];

const onboardedStatusOptions = [
  {
    label: 'Invited',
    value: 'INVITED',
  },
  {
    label: 'Registered',
    value: 'REGISTERED',
  },
  {
    label: 'Onboarded',
    value: 'ONBOARDED',
  },
];

const watchlistStatusOptions = [
  {
    label: 'None',
    value: 'NONE',
  },
  {
    label: 'Review',
    value: 'REVIEW',
  },
  {
    label: 'Pending',
    value: 'PENDING',
  },
  {
    label: 'Passed',
    value: 'PASSED',
  },
  {
    label: 'Failed',
    value: 'FAILED',
  },
];

const invitationStatusOptions = [
  {
    label: 'Accepted',
    value: 'ACCEPTED',
  },
  {
    label: 'Rejected',
    value: 'REJECTED',
  },
];

const payorPaymentStatusOptions = [
  {
    label: 'Accepted',
    value: 'ACCEPTED',
  },
  {
    label: 'Awaiting Funds',
    value: 'AWAITING_FUNDS',
  },
  {
    label: 'Funded',
    value: 'FUNDED',
  },
  {
    label: 'Unfunded',
    value: 'UNFUNDED',
  },
  {
    label: 'Payment Requested',
    value: 'BANK_PAYMENT_REQUESTED',
  },
  {
    label: 'Awaiting Confirmation',
    value: 'ACCEPTED_BY_RAILS',
  },
  {
    label: 'Confirmed',
    value: 'CONFIRMED',
  },
  {
    label: 'Returned',
    value: 'RETURNED',
  },
  {
    label: 'Rejected',
    value: 'REJECTED',
  },
  {
    label: 'Withdrawn',
    value: 'WITHDRAWN',
  },
  {
    label: 'Cancelled',
    value: 'CANCELLED',
  },
];

const payoutStatusOptions = [
  {
    label: 'Accepted',
    value: 'ACCEPTED',
  },
  {
    label: 'Rejected',
    value: 'REJECTED',
  },
  {
    label: 'Submitted',
    value: 'SUBMITTED',
  },
  {
    label: 'Quoted',
    value: 'QUOTED',
  },
  {
    label: 'Instructed',
    value: 'INSTRUCTED',
  },
  {
    label: 'Completed',
    value: 'COMPLETED',
  },
  {
    label: 'Incomplete',
    value: 'INCOMPLETE',
  },
  {
    label: 'Withdrawn',
    value: 'WITHDRAWN',
  },
];

const payeeDisabledStatusOptions = [
  {
    label: 'Enabled',
    value: 'false',
  },
  {
    label: 'Disabled',
    value: 'true',
  },
];

const payeeFieldEnum = {
  displayName: 'displayName',
  email: 'email',
  remoteId: 'remoteId',
  onboardedStatus: 'onboardedStatus',
  watchlistStatus: 'watchlistStatus',
  invitationStatus: 'invitationStatus',
  payeeCountry: 'payeeCountry',
  disabled: 'disabled',
  payeeType: 'payeeType',
};

const paymentFieldEnum = {
  remoteId: 'remoteId',
  sourceCurrency: 'sourceCurrency',
  paymentCurrency: 'paymentCurrency',
  routingNumber: 'routingNumber',
  accountNumber: 'accountNumber',
  status: 'status',
  sourceAmount: 'sourceAmount',
  paymentAmount: 'paymentAmount',
  submittedDateTime: 'submittedDateTime',
  payeeName: 'payeeName',
  payeeType: 'payeeType',
  sourceAccount: 'sourceAccount',
};

const paymentListFieldEnum = {
  remoteId: 'remoteId',
  status: 'status',
  sourceCurrency: 'sourceCurrency',
  sourceAmount: 'sourceAmount',
  paymentCurrency: 'paymentCurrency',
  paymentAmount: 'paymentAmount',
  submittedDateTime: 'submittedDateTime',
  paymentMemo: 'paymentMemo',
  sourceAccountName: 'sourceAccountName',
  transmissionType: 'transmissionType',
};

const payorPaymentFieldEnum = {
  scheduled: 'scheduled',
  scheduledStatus: 'scheduledStatus',
  status: 'status',
  sourceAmount: 'sourceAmount',
  paymentAmount: 'paymentAmount',
  submittedDateTime: 'submittedDateTime',
  payeeName: 'payeeName',
  remoteId: 'remoteId',
};

const backOfficePaymentFieldEnum = {
  scheduled: 'scheduled',
  scheduledStatus: 'scheduledStatus',
  status: 'status',
  sourceAmount: 'sourceAmount',
  paymentAmount: 'paymentAmount',
  submittedDateTime: 'submittedDateTime',
  payorName: 'payorName',
  payeeName: 'payeeName',
};

export {
  payeeTypeOptions,
  watchlistStatusOptions,
  onboardedStatusOptions,
  invitationStatusOptions,
  payoutStatusOptions,
  payeeFieldEnum,
  paymentFieldEnum,
  paymentListFieldEnum,
  payorPaymentFieldEnum,
  backOfficePaymentFieldEnum,
  payorPaymentStatusOptions,
  payeeDisabledStatusOptions,
};
