import unique from 'just-unique';
import {
  createDataJoinCallbackFunction,
  createAwaitAllDataJoinCallback,
} from '../../selectors';

function createPayorIdReducer(map) {
  return ([err, payors], id) => {
    if (err) return [err];
    const { error, result } = map[id];
    if (error) return [error];
    return [undefined, payors.concat(result)];
  };
}

function extractRequests(data) {
  // each generated string should be de-duped by array unique
  return unique(data.reduce((xs, { payorIds }) => xs.concat(payorIds), [])).map(
    // for each key, split back into reasonable params
    (key) => ({ key, params: [key, {}] })
  );
}

function mapContentValues(resultsById, content, value) {
  const [error, payors] = value.payorIds.reduce(
    createPayorIdReducer(resultsById),
    // initial state for the reduce
    [
      // no initial error,
      undefined,
      // initially empty payors array
      [],
    ]
  );
  if (error) return [error];

  return [
    undefined,
    content.concat({
      ...value,
      payors,
      payee: { displayName: value.payeeName },
    }),
  ];
}

export function PaymentMethodsDataJoin(entity) {
  return createAwaitAllDataJoinCallback(
    createDataJoinCallbackFunction(extractRequests, entity.getPayor),
    mapContentValues,
    true
  );
}
