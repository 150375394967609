import { useMedia } from 'react-use';
import {
  TokenSizeLayoutLBreakpoint,
  TokenSizeLayoutMBreakpoint,
  TokenSizeLayoutSBreakpoint,
  TokenSizeLayoutXsBreakpoint,
  TokenSizeLayoutXxsBreakpoint,
} from '@design-system/tokens/layout/js/layout-tokens.module';

const DESIGN_SYSTEM_BREAKPOINT_UNITS = 'px';

const Breakpoints = {
  L: `${TokenSizeLayoutLBreakpoint}${DESIGN_SYSTEM_BREAKPOINT_UNITS}`,
  M: `${TokenSizeLayoutMBreakpoint}${DESIGN_SYSTEM_BREAKPOINT_UNITS}`,
  S: `${TokenSizeLayoutSBreakpoint}${DESIGN_SYSTEM_BREAKPOINT_UNITS}`,
  XS: `${TokenSizeLayoutXsBreakpoint}${DESIGN_SYSTEM_BREAKPOINT_UNITS}`,
  XXS: `${TokenSizeLayoutXxsBreakpoint}${DESIGN_SYSTEM_BREAKPOINT_UNITS}`,
};

const useIsBelowBreakpoint = (breakpoint) => {
  return useMedia(`(max-width: ${breakpoint})`);
};

useIsBelowBreakpoint.breakpoints = Breakpoints;

export { useIsBelowBreakpoint };
