export function unlinkUserTag(send) {
  return (userId, tagId, ...args) =>
    send(
      {
        path: '/v2/users/{userId}/tags/{tagId}',
        pathParameters: { userId, tagId },
        type: 'delete',
        query: undefined,
        body: undefined,
        headers: undefined,
        auth: 'oauth2',
        contentType: undefined,
      },
      ...args
    );
}
