import React from 'react';
import { withRouter } from 'react-router';
import compose from 'just-compose';
import { VeloAppLoadingContainer } from 'velo-react-components';
import { createFromHook } from '../helpers';
import { useAppBootstrap } from '../../hooks/app';

const Bootstrap = createFromHook(useAppBootstrap);

function withAppContainer(entityPropName) {
  return compose(
    VeloAppLoadingContainer,
    (Component) => (props) =>
      (
        <Bootstrap
          {...props}
          entityPropName={entityPropName}
          render={(p) => <Component {...props} {...p} />}
        />
      ),
    withRouter
  );
}

export { withAppContainer };
