import React from 'react';
import { bool, string } from 'prop-types';
import { NavigationDrawerNestedMenuItem } from './NavigationDrawerNestedMenuItem';
import navigationDrawerPropTypes from '../navigationDrawerPropTypes';

NavigationDrawerNestedMenu.propTypes = {
  /** The sub menu items to be rendered. */
  subMenuItems: navigationDrawerPropTypes.subMenuItems,
  /** Whether the navigation drawer is in mobile mode */
  isMobile: bool,
  /** The selected sun menu item id */
  selectedSubMenuItemId: string,
};

function NavigationDrawerNestedMenu({
  subMenuItems,
  selectedSubMenuItemId,
  onClickItem,
}) {
  return (
    <>
      {subMenuItems
        .sort((a, b) => a.order - b.order)
        .map((subItem) => (
          <NavigationDrawerNestedMenuItem
            key={subItem.id}
            subItem={subItem}
            isSelectedItem={selectedSubMenuItemId === subItem.id}
            isMobile={false}
            onClick={onClickItem}
          />
        ))}
    </>
  );
}

export { NavigationDrawerNestedMenu };
