import React from 'react';
import { FormattedMessage } from 'react-intl';
import { VeloLogoPage } from '../VeloLogoPage';
import { ForgotPasswordForm } from '../ForgotPasswordForm';
import { EmailInstructionsSent } from './EmailInstructionsSent';

const getPageConfig = ({ showEmailInstructionsSent, onClickBackLink }) => {
  return showEmailInstructionsSent
    ? {
        Component: EmailInstructionsSent,
        pageProps: {
          title: (
            <FormattedMessage defaultMessage="Instructions successfully sent to your email" />
          ),
        },
      }
    : {
        Component: ForgotPasswordForm,
        pageProps: {
          title: <FormattedMessage defaultMessage="Forgot your password?" />,
          onClickBackLink,
          backLinkText: <FormattedMessage defaultMessage="Sign In" />,
        },
      };
};

function ForgotPasswordPage({
  onClickBackLink,
  showEmailInstructionsSent,
  ...props
}) {
  const { Component, pageProps } = getPageConfig({
    showEmailInstructionsSent,
    onClickBackLink,
  });

  return (
    <VeloLogoPage {...props} {...pageProps}>
      <Component {...props} onClickBackLink={onClickBackLink} />
    </VeloLogoPage>
  );
}

export { ForgotPasswordPage };
