import styled from 'astroturf/react';
import { VeloFieldGrid } from '../VeloFieldGrid';

const FormGrid = styled(VeloFieldGrid)`
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
`;

export { FormGrid };
