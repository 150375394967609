/**
 * Named "Payor" because this covers the enums for both funding account and
 * source account types
 */
export const PayorAccountType = {
  FBO: 'FBO',
  WUBS_DECOUPLED: 'WUBS_DECOUPLED',
  PRIVATE_COUPLED: 'PRIVATE_COUPLED',
  PRIVATE: 'PRIVATE',
};
