import React from 'react';
import { CSSTransition } from 'react-transition-group';
import styles from './Fade.module.scss';

const ANIMATION_TIME_MS = 250;

function Fade({ children, ...other }) {
  return (
    <CSSTransition timeout={ANIMATION_TIME_MS} classNames={styles} {...other}>
      {children}
    </CSSTransition>
  );
}

export { Fade };
