import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { VeloRadioTileGroup } from '../VeloRadioTileGroup';
import { PayeeType } from 'velo-data';

PayeeAccountType.propTypes = {
  /** The test ID for the Personal Account button. */
  individualTestId: string.isRequired,
  /** The test ID for the Company Account button. */
  companyTestId: string.isRequired,
};

/**
 * Render a pair of VeloRadioTiles used to switch between a
 * Personal and Company Payee account.
 *
 * Used by the Add Payee and Payee Profile forms.
 *
 * Note that other props are passed to the underlying `VeloRadioTileGroup`.
 */
function PayeeAccountType({ individualTestId, companyTestId, ...other }) {
  return (
    <VeloRadioTileGroup {...other}>
      <VeloRadioTileGroup.Tile
        icon="account_circle"
        title={<FormattedMessage defaultMessage="Personal account" />}
        label={<FormattedMessage defaultMessage="For individuals" />}
        value={PayeeType.INDIVIDUAL}
        data-testid={individualTestId}
      />

      <VeloRadioTileGroup.Tile
        icon="payors"
        title={<FormattedMessage defaultMessage="Company account" />}
        label={<FormattedMessage defaultMessage="For registered businesses" />}
        value={PayeeType.COMPANY}
        data-testid={companyTestId}
      />
    </VeloRadioTileGroup>
  );
}

export { PayeeAccountType };
