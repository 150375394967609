import React, { useEffect, useState } from 'react';
import { func, node, shape } from 'prop-types';
import { useIntl } from 'react-intl';
import { WatchlistStatus, PayeeType, dataTestIdBuilder } from 'velo-data';
import { VeloPropTypes } from '../VeloPropTypes';
import { VeloModalSheet } from '../VeloModalSheet';
import { VeloModalSheetCardContent } from '../VeloModalSheetCardContent';
import { VeloIconButton } from '../VeloIconButton';
import { VeloAccordianGrid } from '../VeloAccordianGrid';
import { VeloGridLoading } from '../VeloGridLoading';
import { ConfirmationDialog } from '../ConfirmationDialog';
import { Description } from '../Description';
import { ActionBar } from './ActionBar';
import { PayeeViewDetailSection } from '../PayeeViewDetailSection';
import { PayeeViewPaymentChannelsSection } from '../PayeeViewPaymentChannelsSection';
import { PayeeViewSecuritySection } from '../PayeeViewSecuritySection';
import { PayeeViewComplianceSection } from '../PayeeViewComplianceSection';
import { PayeeViewOtherDetailsSection } from '../PayeeViewOtherDetailsSection';
import { PayeeViewCommunicationsSection } from '../PayeeViewCommunicationsSection';

// Return the name of the section that should be
// initially expanded.
function initialExpandedSectionName({
  payee: { pausePayment, watchlistStatus },
}) {
  // Disabled, payments paused or watchlist failure
  if (
    pausePayment ||
    watchlistStatus === WatchlistStatus.FAILED ||
    watchlistStatus === WatchlistStatus.REVIEW
  ) {
    return PayeeViewComplianceSection.sectionName;
  }
  return PayeeViewDetailSection.sectionName;
}

const root = 'payee-view';

const TestIds = {
  CLOSE: `${root}-close`,
  VIEW: `${root}-view`,
  DELETE: `${root}-delete`,
  CONFIRMATION_DIALOG: `${root}-confirmation-dialog`,
};

PayorPayeeView.propTypes = {
  /** Called when the view is closed. */
  onClose: func.isRequired,
  /** Called when an item is selected from the View menu. */
  onView: func.isRequired,
  /** Called when an item is selected from the Delete menu. */
  onDelete: func,
  /** Called when an Edit button is clicked. */
  onEdit: func,
  /** Payee country rules used for some label formatting. */
  payeeCountryRules: VeloPropTypes.payeeCountryRulesType().isRequired,
  /** The API data. */
  data: shape({
    payee: shape({
      ...PayeeViewDetailSection.propTypes,
      ...PayeeViewSecuritySection.propTypes,
      ...PayeeViewComplianceSection.propTypes,
      ...PayeeViewOtherDetailsSection.propTypes,
      ...PayeeViewCommunicationsSection.propTypes,
    }).isRequired,
  }).isRequired,
};

PayorPayeeView.sectionNames = {
  DETAILS: PayeeViewDetailSection.sectionName,
  SECURITY: PayeeViewSecuritySection.sectionName,
  COMPLIANCE: PayeeViewComplianceSection.sectionName,
  COMMUNICATIONS: PayeeViewCommunicationsSection.sectionName,
  OTHER: PayeeViewOtherDetailsSection.sectionName,
};

PayorPayeeView.menuItems = {
  PAYMENTS: 'payments',
};

function stripPayeeToRenderOnlyValues(payee) {
  // Strip data not to show in Payor View flyout
  // Not sure why we cant / dont show these as they are available in
  // the API response...
  // Sections conditionally render values
  const {
    marketingOptInDecision,
    marketingOptInTimestamp,
    acceptTermsAndConditionsTimestamp,
    individual,
    company,
    disabled, // PayeeStatus
    ...strippedPayee
  } = payee;

  // Render a subset only of the Company / Individual
  return {
    ...strippedPayee,
    ...(company
      ? {
          company: {
            operatingName: company.operatingName,
            taxId: company.taxId,
          },
        }
      : {}),
    ...(individual ? { individual: { name: individual.name } } : {}),
  };
}
function PayorPayeeView(props) {
  const {
    data,
    onClose,
    onView,
    onDelete,
    onEdit,
    payeeCountryRules,
    onResendInvite,
    onEditRemoteId,
    onEditPaymentChannels,
  } = props;

  useEffect(() => void document.getElementById(TestIds.CLOSE).focus(), []);
  const intl = useIntl();
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const expanded = initialExpandedSectionName(data);

  // Strip data out of API for rendering only
  const componentData = {
    ...data,
    payee: stripPayeeToRenderOnlyValues(data.payee),
  };
  const { payeeType } = componentData.payee;

  return (
    <>
      <VeloModalSheet.Header
        divider
        actionBar={
          <ActionBar
            onView={onView}
            payorRelationship={componentData.payorRelationship}
            onDelete={onDelete ? () => setDeleteConfirmation(true) : undefined}
          />
        }
      >
        <VeloModalSheet.HeaderTitle>
          {componentData.payee.displayName}
        </VeloModalSheet.HeaderTitle>
        <VeloIconButton
          icon="close"
          onClick={onClose}
          title={intl.formatMessage({ defaultMessage: 'Close' })}
          data-testid={TestIds.CLOSE}
          // Required to give the button initial focus
          // as RMWC IconButton does not support refs.
          id={TestIds.CLOSE}
        />
      </VeloModalSheet.Header>

      <VeloModalSheet.Body compact>
        <VeloAccordianGrid
          sections={[
            PayeeViewDetailSection({
              ...componentData,
              payeeCountryRules,
              onResendInvite,
              onEditRemoteId,
              mode: PayeeViewDetailSection.mode.PAYOR,
              onEdit,
            }),
            ...(componentData.payee.paymentChannels &&
            componentData.payee.paymentChannels.length
              ? [
                  PayeeViewPaymentChannelsSection({
                    ...componentData,
                    onEdit: onEditPaymentChannels,
                  }),
                ]
              : []),
            payeeType === PayeeType.COMPANY
              ? PayeeViewCommunicationsSection(componentData)
              : PayeeViewSecuritySection(componentData),
            PayeeViewComplianceSection(componentData),
            PayeeViewOtherDetailsSection(componentData),
          ].map(({ name, onEdit, ...section }) => {
            return {
              ...section,
              tag: 'dl',
              divider: true,
              'data-testid': dataTestIdBuilder(root, name, 'heading'),
              ...(Boolean(onEdit) ? { onEdit } : {}),
              initiallyOpen: expanded === name,
            };
          })}
          render={({ render, ...props }) =>
            render ? render() : <Description {...props} />
          }
        />
      </VeloModalSheet.Body>

      <ConfirmationDialog
        open={deleteConfirmation}
        onClose={(event) => {
          setDeleteConfirmation(false);
          if (event.detail.action === 'accept') {
            onDelete('payee');
          }
        }}
        dialogType={ConfirmationDialog.dialogTypes.ConfirmDeletePayeeType}
        data-testid={TestIds.CONFIRMATION_DIALOG}
      />
    </>
  );
}

Loading.propTypes = {
  /** Called when the sheet is closed. */
  onClose: func.isRequired,
};

function Loading({ onClose }) {
  return (
    <VeloModalSheetCardContent.Skeleton compact onClose={onClose}>
      <VeloGridLoading
        sections={[
          {
            heading: false,
            fields: [...Array(4).keys()].map(() => ({
              type: VeloGridLoading.fieldTypes.CollapsibleSection,
            })),
          },
        ]}
      />
    </VeloModalSheetCardContent.Skeleton>
  );
}

Error.propTypes = {
  /** The error to display. */
  error: node.isRequired,
  /** Called when the sheet is closed. */
  onClose: func.isRequired,
};

function Error({ error, onClose }) {
  return (
    <VeloModalSheetCardContent.Error onClose={onClose}>
      {error}
    </VeloModalSheetCardContent.Error>
  );
}

PayorPayeeView.Loading = Loading;
PayorPayeeView.Error = Error;

export { PayorPayeeView };
