import React, { forwardRef } from 'react';
import styled from 'astroturf/react';
import {
  Menu as RMWCMenu,
  MenuItem,
  MenuItems,
  MenuSurfaceAnchor,
  MenuSurface,
} from '@rmwc/menu';
import { VeloDivider } from '../VeloDivider';
import '@material/menu/dist/mdc.menu.css';
import '@material/menu-surface/dist/mdc.menu-surface.css';
import '../VeloList/mdc-list.css';

const Divider = styled(VeloDivider).attrs({
  as: 'li',
})`
  margin: 8px 0;
`;

const Menu = styled(RMWCMenu)`
  @import 'velo-variables';

  :global(.mdc-list-item--disabled) {
    color: velo-color('token-color-text-disabled');
  }
`;

export const VeloMenu = forwardRef((props, ref) => (
  <Menu
    ref={ref}
    {...props}
    onSelect={(evt) => {
      !evt.detail.item.classList.contains('mdc-list-item--disabled') &&
        props.onSelect &&
        props.onSelect(evt);
    }}
  />
));

VeloMenu.Item = styled(MenuItem)`
  @import 'velo-variables';
  color: velo-color('token-color-text-default');
  font-family: var(--ds-typography-font-family);
`;
VeloMenu.Items = MenuItems;
VeloMenu.SurfaceAnchor = MenuSurfaceAnchor;
VeloMenu.Surface = MenuSurface;
VeloMenu.Divider = Divider;
