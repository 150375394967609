import React from 'react';
import { bool, func, node, string } from 'prop-types';
import styled from 'astroturf/react';
import { Button, ButtonIcon } from '@rmwc/button';
import { VeloSkeleton } from '../VeloSkeleton';
import '@material/button/dist/mdc.button.css';

const StyledButton = styled(Button)`
  font-family: var(--ds-typography-font-family);
  line-height: 1.5; /** fixes icon alignment (was 2.25rem) */

  &.fullWidth {
    width: 100%;
  }
`;

StyledButton.propTypes = { fullWidth: bool };

const SkeletonButton = styled(VeloSkeleton)`
  height: 2.25rem;
  width: 8rem;

  &.fullWidth {
    width: 100%;
  }
`;

SkeletonButton.propTypes = { fullWidth: bool };

/**
 * Base button component.
 */
const BaseButton = ({ skeleton, unelevated, outlined, children, ...other }) =>
  skeleton ? (
    <SkeletonButton {...other} />
  ) : (
    <StyledButton unelevated={unelevated} outlined={outlined} {...other}>
      {children}
    </StyledButton>
  );

const propTypes = {
  /** Custom CSS class(es) */
  className: string,
  /** Render as a skeleton. */
  skeleton: bool,
  /** Render full width. */
  fullWidth: bool,
  /** Disable the button. */
  disabled: bool,
  /** Called when the button is clicked. */
  onClick: func,
  /** The button label. */
  children: node,
  /** The button label */
  label: node,
  /** The unique test ID for the button */
  'data-testid': string.isRequired,
};

/**
 * Velo flat button component.
 */
function VeloButton(props) {
  return <BaseButton {...props} />;
}

VeloButton.propTypes = propTypes;

const StyledPrimaryButton = styled(BaseButton)`
  @import 'velo-variables';

  /* Apply the theme colour directly to fix an Edge issue. */
  /* https://github.com/material-components/material-components-web/issues/5044 */
  &:global(.mdc-button--unelevated:not(:disabled)) {
    background-color: velo-color('token-color-brand-primary');
  }
`;

/**
 * Velo primary button component.
 */
function VeloPrimaryButton(props) {
  return <StyledPrimaryButton unelevated {...props} />;
}

VeloPrimaryButton.propTypes = propTypes;

/**
 * Velo secondary button component.
 */
function VeloSecondaryButton(props) {
  return <BaseButton outlined {...props} />;
}

VeloSecondaryButton.propTypes = propTypes;

VeloButton.Primary = VeloPrimaryButton;
VeloButton.Secondary = VeloSecondaryButton;
VeloButton.Icon = ButtonIcon;

export { VeloButton };
