import React, { useCallback } from 'react';
import {
  PayorFundingListPage,
  FundingList,
  createRenderForksFromTable,
} from 'velo-react-components';
import {
  forkResult,
  usePresenter,
  useAllQueries,
  useSerializableMemo,
  useCallbackFnAsResultState,
} from 'velo-portal-common';
import { getFundingsV4 } from 'velo-api/src/entities/paymentaudit';
import { PayorFundingListPresenter } from './PayorFundingListPresenter';

const tableForks = createRenderForksFromTable(FundingList);

const fixedProps = {
  filters: FundingList.filters,
};

const entitySpec = {
  getPayorFundings: getFundingsV4,
};

function PayorFundingListRoute({ payorId }) {
  const [loader, populatedFilters, getProps] = usePresenter(
    PayorFundingListPresenter,
    entitySpec,
    fixedProps,
    useSerializableMemo({ payorId })
  );

  const [listQuery, tableProps] = useAllQueries(
    populatedFilters,
    FundingList.columns.DATE_TIME,
    FundingList.sortOrder.DESCENDING
  );

  const [result] = useCallbackFnAsResultState(
    useCallback((cb) => loader(listQuery, cb), [loader, listQuery])
  );

  const listProps = getProps(tableProps, result);

  return (
    <PayorFundingListPage>
      {forkResult(tableForks, result, listProps)}
    </PayorFundingListPage>
  );
}

export { PayorFundingListRoute };
