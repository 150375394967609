import compose from 'just-compose';
import { VeloTableBuilder } from '../VeloTableBuilder';
import { PayoutsListColumns } from '../PayoutsListColumns';

const { columnsByName, filtersByName, fieldNames, mapHeaderProps, ...config } =
  PayoutsListColumns;

export const PayoutsScheduledListForPayor = VeloTableBuilder({
  ...config,
  columns: fieldNames,
  columnOrder: [
    columnsByName[fieldNames.SCHEDULED_DATE],
    columnsByName[fieldNames.SCHEDULED_ICON],
    columnsByName[fieldNames.PAYMENT_MEMO],
    columnsByName[fieldNames.TOTAL_PAYMENTS],
    columnsByName[fieldNames.RETURNED_PAYMENTS],
    columnsByName[fieldNames.STATUS],
    columnsByName[fieldNames.SCHEDULED_STATUS_ICON],
  ],
  filters: [],
  sortableColumns: [fieldNames.SCHEDULED_DATE],
});

const { Headings } = PayoutsScheduledListForPayor;

PayoutsScheduledListForPayor.Headings = compose(mapHeaderProps, Headings);

PayoutsScheduledListForPayor.getFilters = (fetchResults) => {
  return [
    filtersByName[fieldNames.SCHEDULED_DATE],
    filtersByName[fieldNames.PAYMENT_MEMO],
    filtersByName[fieldNames.STATUS],
    filtersByName[fieldNames.SCHEDULE_STATUS],
  ];
};

PayoutsScheduledListForPayor.TabConfig = {
  getFilters: PayoutsScheduledListForPayor.getFilters,
  primaryFilterField: PayoutsScheduledListForPayor.columns.SCHEDULED_DATE,
  defaultSort: PayoutsScheduledListForPayor.sortOrder.DESCENDING,
  table: PayoutsScheduledListForPayor,
};
