import React from 'react';
import { useHistory, useParams } from 'react-router';
import {
  useCallbackFnAsResultState,
  usePresenter,
  useSerializableMemo,
} from '../../hooks';
import { PayoutWithdrawnPresenter } from './PayoutWithdrawnPresenter';
import { PayoutWithdrawnPage } from 'velo-react-components/src/PayoutWithdrawn';
import { getPaymentsForPayoutV4 } from 'velo-api/src/entities/paymentaudit';

const entitySpec = {
  getPaymentsByPayout: getPaymentsForPayoutV4,
};

function PayoutWithdrawnRoute() {
  const { payoutId } = useParams();

  const { action } = useHistory();

  const { load, exit } = usePresenter(
    PayoutWithdrawnPresenter,
    entitySpec,
    null,
    useSerializableMemo({ payoutId, action })
  );

  const [data] = useCallbackFnAsResultState(load);

  return <PayoutWithdrawnPage onClose={exit} withdrawnDateTime={data.result} />;
}

export { PayoutWithdrawnRoute };
