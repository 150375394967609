import React from 'react';
import {
  any,
  bool,
  func,
  node,
  number,
  object,
  oneOf,
  oneOfType,
  string,
} from 'prop-types';
import classnames from 'classnames';
import styled from 'astroturf/react';
import { VeloList } from '../VeloList';
import { VeloSkeleton } from '../VeloSkeleton';
import { VeloIcon } from '../VeloIcon';
import { VeloDateTime } from '../VeloDateTime';
import { VeloDate } from '../VeloDate';
import { VeloTypography } from '../VeloTypography';
import { VeloTableMobileSortHeader } from './VeloTableMobileSort';
import { Alignment, Size, SortOrder } from './types';
import { FormattedMessage } from 'react-intl';

const Row = styled('div')`
  @import 'velo-variables';
  @import 'velo-mixins';
  @import '@material/ripple/common';
  @import '@design-system/tokens/typography/scss/mixins';

  $bottom-border: 0.25rem;
  $margin-default: 0rem;

  @include ds-typography-ellipsis;

  cursor: pointer;
  height: auto;
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: velo-color('token-color-system-page-background');
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;

  &:hover {
    background-color: rgba(#0077ba, 0.2);
  }

  &.static {
    @include non-interactive;

    cursor: default !important;
  }

  &.border {
    margin-bottom: $margin-default;
    margin-top: $margin-default;
    border-bottom: $bottom-border solid
      velo-color('token-color-system-page-surface');
  }

  &.header {
    @include non-interactive;

    cursor: default;
    background-color: transparent;

    /* Hide the header on mobile */
    @media (max-width: velo-breakpoint(XS)) {
      display: none;
    }
  }
`;

Row.propTypes = {
  static: bool,
  border: bool,
  header: bool,
};

const Text = styled(VeloList.ItemText)`
  @import 'velo-variables';

  width: 100%;
  display: flex;
  justify-content: space-evenly;

  @media (max-width: velo-breakpoint(XS)) {
    flex-wrap: wrap;
  }

  &.wrap {
    white-space: normal;
  }
`;

Text.propTypes = {
  wrap: bool,
};

const Icon = styled(VeloIcon)`
  @import 'velo-variables';

  margin-left: auto;
  margin-right: 0;
  color: velo-color(token-color-text-muted);

  /* Hide icons on mobile */
  @media (max-width: velo-breakpoint(XS)) {
    display: none;
  }
`;

// Render a table row.
const VeloTableRow = React.forwardRef(
  (
    {
      children,
      icon,
      onClick,
      borderBottom,
      use = 'tableContent',
      wrap,
      ...other
    },
    ref
  ) => {
    return (
      <Row
        ref={ref}
        onClick={onClick}
        static={onClick === undefined}
        border={borderBottom}
        {...other}
        className={classnames(
          'mdc-list-item',
          // 'mdc-list--non-interactive',
          other.className
        )}
      >
        <VeloTypography use={use} wrap>
          <Text wrap={wrap}>{children}</Text>
        </VeloTypography>
        {icon && <Icon icon={icon} />}
      </Row>
    );
  }
);

VeloTableRow.propTypes = {
  // Custom CSS classes.
  className: string,
  // The icon to render.
  icon: oneOfType([node, object]),
  // Called when the row is clicked
  onClick: func,
  // The row content
  children: node,
  // Adds a strong bottom border if enabled
  borderBottom: bool,
  // The typography style to use
  use: string,
  // Allow text to wrap over multiple lines
  wrap: bool,
};

const IconSpacer = styled('span')`
  /* This matches the standard Material icon width */
  min-width: 24px;
`;

VeloTableHeaderRow.propTypes = {
  // Custom CSS classes.
  className: string,
  // Used to ensure correct spacing when using row icons.
  iconSpacer: bool,
  // The row content
  children: node,
};

// Render a header row
function VeloTableHeaderRow({ iconSpacer, children, ...other }) {
  return (
    <VeloTableRow header {...other}>
      {children}
      {iconSpacer && <IconSpacer />}
    </VeloTableRow>
  );
}

const VeloTableCell = styled(VeloTypography).attrs({ use: 'tableContent' })`
  @import 'velo-variables';
  @import '@design-system/tokens/typography/scss/mixins';

  @mixin left-align-mobile() {
    /* Left align columns on mobile */
    @media (max-width: velo-breakpoint(XS)) {
      text-align: left;
      justify-content: flex-start;
    }
  }

  @include ds-typography-ellipsis;

  flex-grow: 1;
  text-align: left;
  width: 100%;
  padding-right: 1rem;

  /* No need for extra padding on mobile as cells are vertically stacked. */
  @media (max-width: velo-breakpoint(XS)) {
    padding-right: 0rem;

    &.hiddenOnMobile {
      display: none;
    }
  }

  &.align-end {
    @include left-align-mobile;

    text-align: right;
    justify-content: flex-end;
  }

  &.align-middle {
    @include left-align-mobile;

    text-align: center;
    justify-content: center;
  }

  &.dateTimePadding {
    @media (max-width: velo-breakpoint(XS)) {
      padding-left: 4rem;
    }
  }

  @media (min-width: velo-breakpoint(XS)) {
    &.size-xsmall {
      flex-basis: 25%;
    }

    &.size-small {
      flex-basis: 50%;
    }

    &.size-large {
      flex-basis: 150%;
    }

    &.size-xlarge {
      flex-basis: 200%;
    }
  }
`;

VeloTableCell.propTypes = {
  // Cell alignment
  align: oneOf(Object.values(Alignment)),
  // Cell flex-basis size
  size: oneOf(Object.values(Size)),
  // Include date/time padding
  dateTimePadding: bool,
  /** hides the cell on mobile breakpoints */
  hiddenOnMobile: bool,
};

const VeloNullableTableCell = ({ children, ...props }) => {
  return <VeloTableCell {...props}>{children || '--'}</VeloTableCell>;
};

const ShortDate = styled('span')`
  @import 'velo-variables';
  @import 'velo-mixins';

  @include velo-font-weight(medium);

  word-wrap: break-word;
  white-space: normal;
  text-align: center;
  display: flex;
  flex-direction: column;

  @media (min-width: velo-breakpoint(XS)) {
    display: none;
  }
`;

const LongDate = styled(VeloDateTime)`
  @import 'velo-variables';

  @media (max-width: velo-breakpoint(XS)) {
    display: none;
  }
`;

const DateTimeCell = styled(VeloTableCell)`
  @import 'velo-variables';

  @media (max-width: velo-breakpoint(XS)) {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
  }
`;

/** Tentative solution for second dates in lists following the primary datetime which is displayed on mobile */
/** This can be refined in the future to prevent having to style sibling cells */
const AuxDateWrapper = styled('div')`
  @import 'velo-variables';

  display: none;

  @media (min-width: velo-breakpoint(XS)) {
    display: inline-block;
    width: 100%;
    overflow: hidden;
    padding-right: 1rem;
    /** Prevents size issue on list items creating "double rows" */
    & > [class*='VeloTable-VeloTableCell'] {
      display: block;
    }
  }
`;

VeloTableDateTimeCell.propTypes = {
  // The date/time to display
  children: any.isRequired,
};

// Special date/time table cell variant
function VeloTableDateTimeCell({ children: dateTime, ...other }) {
  return (
    <DateTimeCell {...other}>
      {/* Mobile */}
      <ShortDate>
        <VeloDate value={dateTime} day="numeric" />
        <VeloDate value={dateTime} month="short" />
      </ShortDate>
      {/* Desktop */}
      <LongDate value={dateTime} />
    </DateTimeCell>
  );
}

// Auxillary date/time table cell variant (hidden on mobile)
function VeloTableAuxillaryDateTimeCell({ children: dateTime, ...other }) {
  return (
    <AuxDateWrapper>
      <VeloTableDateTimeCell {...{ children: dateTime, ...other }} />
    </AuxDateWrapper>
  );
}

const DateTimeLoading = styled(VeloSkeleton)`
  @import 'velo-variables';

  @media (max-width: velo-breakpoint(XS)) {
    height: 3rem;
    margin-left: 1rem;
  }
`;

function VeloTableDateTimeCellLoading(props) {
  return (
    <DateTimeCell {...props}>
      <DateTimeLoading />
    </DateTimeCell>
  );
}

VeloTableDateTimeCell.Loading = VeloTableDateTimeCellLoading;

const SortArrow = styled(VeloIcon)`
  font-size: 1.25rem;
  transform: rotate(0deg);
  transition: transform 250ms ease-in-out;
  transform-origin: center center;

  &.descending {
    transform: rotate(180deg);
  }

  &.align-start,
  &.align-middle {
    margin-right: 0.5rem;
    order: -1;
  }

  &.align-end {
    margin-left: 0.5rem;
    order: 0;
  }

  &.hidden {
    opacity: 0;
  }
`;

SortArrow.propTypes = {
  descending: bool,
  align: oneOf(Object.values(Alignment)),
  hidden: bool,
};

VeloTableSortArrow.propTypes = {
  // The sort direction
  direction: oneOf(Object.values(SortOrder)),
  // Determines the icon position
  align: oneOf(Object.values(Alignment)),
};

// Render a sort arrow
function VeloTableSortArrow({ className, direction, align, ...other }) {
  return (
    <SortArrow
      icon="arrow_downward"
      className={classnames(
        {
          /**
           * We use a global class name for the hidden arrow as we
           * need to modify the opacity from another astroturf
           * component. Astroturf does support referencing one
           * component from another but it does not work
           * with our config.
           *
           * https://github.com/4Catalyzer/astroturf#referring-to-other-components
           */
          'velo-table-hidden-sort-arrow': direction === SortOrder.NONE,
        },
        className
      )}
      hidden={direction === SortOrder.NONE}
      align={align}
      descending={direction === SortOrder.DESCENDING}
      {...other}
    />
  );
}

const HeaderCell = styled(VeloTableCell)`
  &.sortable {
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      :global(.velo-table-hidden-sort-arrow) {
        opacity: 0.5;
      }
    }
  }
`;

HeaderCell.propTypes = {
  sortable: bool,
};

const HeaderCellLabel = styled(VeloTypography).attrs({ use: 'tableHeader' })`
  @import '@design-system/tokens/typography/scss/mixins';

  @include ds-typography-ellipsis;
`;

VeloTableHeaderCell.propTypes = {
  // Used to identify the column.
  name: string,
  // The cusrrent sort direction
  sort: oneOf(Object.values(SortOrder)),
  // Cell alignment.
  align: oneOf(Object.values(Alignment)),
  // Called when the cell is clicked. Passed the name and new sort direction.
  onSort: func,
  // The cell contents
  children: node,
};

// Render a header cell
function VeloTableHeaderCell({
  name,
  sort,
  align,
  children,
  onSort,
  ...other
}) {
  const onClick = (event) => {
    event.stopPropagation();
    const direction =
      sort === SortOrder.ASCENDING ? SortOrder.DESCENDING : SortOrder.ASCENDING;
    onSort(name, direction);
  };

  const sortable = sort !== undefined && !!children;

  return (
    <HeaderCell
      sortable={sortable}
      role="presentation"
      align={align}
      onClick={sortable ? onClick : undefined}
      {...other}
    >
      <HeaderCellLabel>{children}</HeaderCellLabel>
      {sortable ? (
        <VeloTableSortArrow
          direction={sort}
          align={align === Alignment.END ? Alignment.START : Alignment.END}
        />
      ) : null}
    </HeaderCell>
  );
}

// Render an error row
const VeloTableError = styled(VeloTableRow).attrs({
  use: 'tableContentError',
  wrap: true,
})`
  text-align: center;
`;

// Render an empty results row
function VeloTableEmpty({ children, ...other }) {
  return (
    <VeloTableRow {...other}>
      {children || <FormattedMessage defaultMessage="No results" />}
    </VeloTableRow>
  );
}

const SkeletonIcon = styled(VeloSkeleton)`
  width: 24px;
`;

VeloTableLoading.propTypes = {
  // True to render skeleton icons.
  icon: bool,
  // The number of rows to render.
  rows: number.isRequired,
  // The number of columns to render,
  cols: number.isRequired,
  // Return additional props for a row.
  getRowProps: func,
  // Retyrn additional props for a cell.
  getCellProps: func,
};

// Render rows and columns in a loading state
function VeloTableLoading({
  icon,
  rows,
  cols,
  getRowProps = () => {},
  getCellProps = () => {},
}) {
  return (
    <>
      {[...Array(rows).keys()].map((row) => (
        <VeloTableRow
          key={row}
          icon={icon && { strategy: 'component', icon: <SkeletonIcon /> }}
          {...getRowProps(row)}
        >
          {[...Array(cols).keys()].map((col) => (
            <VeloTableCell key={col} {...getCellProps(col)}>
              <VeloSkeleton />
            </VeloTableCell>
          ))}
        </VeloTableRow>
      ))}
    </>
  );
}

const VeloTable = styled(VeloList)`
  padding-top: 0;
`;

VeloTable.alignment = Alignment;
VeloTable.size = Size;
VeloTable.sortOrder = SortOrder;
VeloTable.Row = VeloTableRow;
VeloTable.Cell = VeloTableCell;
VeloTable.NullableCell = VeloNullableTableCell;
VeloTable.DateTimeCell = VeloTableDateTimeCell;
VeloTable.DateTimeCellWrapper = DateTimeCell;
VeloTable.AuxillaryDateTimeCell = VeloTableAuxillaryDateTimeCell;
VeloTable.HeaderRow = VeloTableHeaderRow;
VeloTable.HeaderCell = VeloTableHeaderCell;
VeloTable.Error = VeloTableError;
VeloTable.Empty = VeloTableEmpty;
VeloTable.Loading = VeloTableLoading;
VeloTable.MobileSortHeader = VeloTableMobileSortHeader;

export { VeloTable };
