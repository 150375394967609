import React from 'react';
import { PasswordForgottenContainer } from '../../containers/password/index';
import { useAppContext } from '../../context';
import { useLegalLinksList } from '../../hooks';

function ForgotPasswordRoute(props) {
  const { appVariant } = useAppContext();
  const legalLinks = useLegalLinksList(appVariant);
  return <PasswordForgottenContainer {...props} legalLinks={legalLinks} />;
}

export { ForgotPasswordRoute };
