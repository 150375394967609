import React, { useState } from 'react';
import styled from 'astroturf/react';
import { bool, string, func } from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import { OnboardingPage } from '../OnboardingPage';
import { VeloTextButton } from '../VeloTextButton';
import { VeloDialog } from '../VeloDialog';
import { VeloDivider } from '../VeloDivider';
import { useSMSCode } from '../FormFields/FormFieldTypes';
import { VeloOverlay } from '../VeloOverlay';
import { VeloTextField } from '../VeloTextField';

const root = 'invite-totp-registration';

const TestIds = {
  CONTAINER: root,
  CONTAINER_LOADING: `${root}-loading`,
  CONTINUE: `${root}-continue`,
  OTP_ERROR: `${root}-otp-error`,
  OTP: `${root}-otp`,
  OVERLAY: `${root}-overlay`,
  COPY_CODE_LINK: `${root}-copy-code-link`,
  COPY_CODE_DIALOG: `${root}-copy-code-dialog`,
};

const Container = styled('div')`
  max-width: 500px;
  margin: auto;

  display: flex;
  align-items: center;
  flex-direction: column;
`;

/**
 * QR Code size is specified in pixels to match the pixel units expected by
 * the API. The QR_CODE_SIZE value is sent to the API when we request the
 * QR Code.
 */
const QR_CODE_SIZE = 150;
const QR_CODE_MARGIN = 10;

const QRCodeImage = styled('img')`
  @import 'velo-mixins';

  width: ${QR_CODE_SIZE}px;
  height: ${QR_CODE_SIZE}px;
  @include skeleton-background();
`;

const QRCodeImageLoading = styled('div')`
  @import 'velo-mixins';

  width: ${QR_CODE_SIZE - QR_CODE_MARGIN * 2}px;
  height: ${QR_CODE_SIZE - QR_CODE_MARGIN * 2}px;
  margin: ${QR_CODE_MARGIN}px;
  @include skeleton-background();
`;

const Subtitle = styled(OnboardingPage.Subtitle)`
  @import 'velo-variables';

  @media (max-width: velo-breakpoint(XS)) {
    text-align: left;
  }

  &.compact {
    margin-bottom: 0;
  }

  &.disabled {
    color: velo-color('token-color-text-disabled');
  }
`;

Subtitle.propTypes = {
  compact: bool,
  disabled: bool,
};

const Divider = styled(VeloDivider)`
  @import 'velo-variables';

  width: 100%;
  margin: 1.5rem 0;

  @media (max-width: velo-breakpoint(XS)) {
    margin: 1rem 0;
  }
`;

const TextFieldContainer = styled('div')`
  @import 'velo-variables';

  margin: auto;

  @import '../OnboardingPage/variables';
  width: $button-width;

  @media (max-width: velo-breakpoint(XS)) {
    width: 100%;
  }
`;

function TextButton(props) {
  return <VeloTextButton inline use="linkText" {...props} />;
}

function Page({ children, ...props }) {
  return (
    <Container data-testid={TestIds.CONTAINER} {...props}>
      <section>
        <OnboardingPage.Title>
          <FormattedMessage defaultMessage="Register and verify your secure authentication method" />
        </OnboardingPage.Title>

        <Subtitle>
          <FormattedMessage
            defaultMessage="To connect your authenticator app, please scan the{br}QR code below."
            values={{ br: <br /> }}
          />
        </Subtitle>

        <Subtitle compact>
          <FormattedMessage defaultMessage="If you don't already have an authenticator app, you can download one, such as Authy or Google Authenticator." />
        </Subtitle>
      </section>
      {children}
    </Container>
  );
}

function Loading(props) {
  return (
    <Page data-testid={TestIds.CONTAINER_LOADING}>
      <QRCodeImageLoading />

      <Divider />

      <form>
        <Subtitle disabled>
          <FormattedMessage
            defaultMessage="Once registered, please enter the <strong>6-digit code</strong> shown in your authenticator app."
            values={{ strong: (chunks) => <strong>{chunks}</strong> }}
          />
        </Subtitle>

        <VeloTextField.Skeleton />

        <OnboardingPage.Footer>
          <OnboardingPage.Button type="submit" disabled={true}>
            <FormattedMessage defaultMessage="Verify Code" />
          </OnboardingPage.Button>
        </OnboardingPage.Footer>
      </form>
    </Page>
  );
}

InviteRegisterTOTP.Loading = Loading;
InviteRegisterTOTP.testIds = TestIds;
InviteRegisterTOTP.QR_CODE_SIZE = QR_CODE_SIZE;

InviteRegisterTOTP.propTypes = {
  qrCodeUrl: string.isRequired,
  totpSharedSecret: string.isRequired,
  onSubmit: func.isRequired,
};

export function InviteRegisterTOTP(props) {
  const intl = useIntl();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [{ value, submitting, error }, setFormState] = useState({
    value: '',
    submitting: false,
    error: undefined,
  });

  const smsCodeField = useSMSCode();

  const buttonText = submitting ? (
    <FormattedMessage defaultMessage="Verifying..." />
  ) : (
    <FormattedMessage defaultMessage="Verify Code" />
  );

  const onSubmitHandler = (e) => {
    e.preventDefault();
    setFormState((state) => ({ ...state, error: undefined, submitting: true }));

    props.onSubmit(value, (error) => {
      setFormState((state) => ({ ...state, error, submitting: false }));
    });
  };

  return (
    <Page>
      <QRCodeImage
        src={props.qrCodeUrl}
        alt={intl.formatMessage({
          defaultMessage: 'Authenticator App QR Code',
        })}
      />

      <Subtitle compact>
        <FormattedMessage
          defaultMessage="If you are unable to scan, manually enter the <link>special code</link>."
          values={{
            link: (chunks) => (
              <TextButton
                data-testid={TestIds.COPY_CODE_LINK}
                onClick={() => setDialogOpen(true)}
              >
                {chunks}
              </TextButton>
            ),
          }}
        />
      </Subtitle>

      <Divider />

      <form onSubmit={onSubmitHandler}>
        <Subtitle>
          <FormattedMessage
            defaultMessage="Once registered, please enter the <strong>6-digit code</strong> shown in your authenticator app."
            values={{ strong: (chunks) => <strong>{chunks}</strong> }}
          />
        </Subtitle>

        <TextFieldContainer>
          <VeloTextField
            dynamicHelpText
            id="otp"
            value={value}
            onChange={(event) => {
              const value = event.target.value;
              setFormState((state) => ({ ...state, value }));
            }}
            required
            data-testid={TestIds.OTP}
            {...smsCodeField}
          />
        </TextFieldContainer>

        {error && (
          <OnboardingPage.SubmitError data-testid={TestIds.OTP_ERROR}>
            {error}
          </OnboardingPage.SubmitError>
        )}

        <OnboardingPage.Footer>
          <OnboardingPage.Button
            type="submit"
            disabled={submitting}
            data-testid={TestIds.CONTINUE}
          >
            {buttonText}
          </OnboardingPage.Button>
        </OnboardingPage.Footer>
      </form>

      {/* Submitting */}
      <VeloOverlay show={submitting} data-testid={TestIds.OVERLAY} />

      <VeloDialog
        open={dialogOpen}
        data-testid={TestIds.COPY_CODE_DIALOG}
        onClose={(evt) => {
          setDialogOpen(false);
        }}
        role="dialog"
      >
        <VeloDialog.Title>
          <FormattedMessage defaultMessage="Your authenticator app registration special code" />
        </VeloDialog.Title>

        <VeloDialog.Content>{props.totpSharedSecret}</VeloDialog.Content>

        <VeloDialog.Actions>
          <VeloDialog.Button action="close">
            <FormattedMessage defaultMessage="Close" />
          </VeloDialog.Button>
        </VeloDialog.Actions>
      </VeloDialog>
    </Page>
  );
}
