import React, { useState } from 'react';
import { func } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { VeloOverlay } from '../VeloOverlay';
import { OnboardingPage } from '../OnboardingPage';
import { PasswordFieldSet } from '../PasswordFieldSet';

import styles from './InvitePassword.module.scss';

InvitePassword.propTypes = {
  /** Called when the form is submitted. */
  onSubmit: func.isRequired,
  /** Called when the password needs to be validated. */
  validatePassword: func.isRequired,
};

const ROOT = 'invite-password';

const TestIds = {
  ...PasswordFieldSet.testIds,
  ROOT,
  FORM: `${ROOT}-form`,
  SUBMIT: `${ROOT}-submit`,
  ERROR: `${ROOT}-error`,
};

const defaultValidationDetail = { result: undefined, error: undefined };

/**
 * The onboarding Finish page.
 */
function InvitePassword(props) {
  const [{ password, submitting, error }, setFormState] = useState({
    password: '',
    submitting: false,
    error: undefined,
  });
  const [validationDetail, setValidationDetail] = useState(
    defaultValidationDetail
  );
  const disabled = submitting || password.length < 10;
  const buttonText = submitting ? (
    <FormattedMessage defaultMessage="Saving..." />
  ) : (
    <FormattedMessage defaultMessage="Continue" />
  );
  const validateCb = (error, result) => setValidationDetail({ error, result });

  const passwordProps = {
    disabled: submitting,
    validationDetail,
    passwordLabel: <FormattedMessage defaultMessage="Enter password" />,
    confirmLabel: <FormattedMessage defaultMessage="Confirm password" />,
    onChange: ({ password }) => {
      setFormState({ password, submitting, error });
      props.validatePassword({ password }, validateCb);
    },
  };

  const submitCallback = (error) =>
    setFormState({ submitting: false, error, password });

  const onSubmit = (e) => {
    e.preventDefault();
    setFormState({ error: undefined, submitting: true, password });
    props.onSubmit({ password }, submitCallback);
  };

  return (
    <section className={styles.section}>
      <header>
        <OnboardingPage.Title>
          <FormattedMessage defaultMessage="Choose a password" />
        </OnboardingPage.Title>
        <OnboardingPage.Subtitle>
          <FormattedMessage defaultMessage="To secure your account choose a strong password for signing in." />
        </OnboardingPage.Subtitle>
      </header>
      <form
        className={styles.form}
        onSubmit={onSubmit}
        id={ROOT}
        data-testid={TestIds.FORM}
      >
        <PasswordFieldSet {...passwordProps} />
        {error && (
          <OnboardingPage.SubmitError data-testid={TestIds.ERROR}>
            {error}
          </OnboardingPage.SubmitError>
        )}

        <OnboardingPage.Footer>
          <OnboardingPage.Button
            type="submit"
            form={ROOT}
            disabled={disabled}
            data-testid={TestIds.SUBMIT}
          >
            {buttonText}
          </OnboardingPage.Button>
        </OnboardingPage.Footer>
      </form>

      <VeloOverlay show={submitting} />
    </section>
  );
}

InvitePassword.testIds = TestIds;

export { InvitePassword };
