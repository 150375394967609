import React, { useCallback, useRef, useState, useEffect } from 'react';
import styled from 'astroturf/react';
import { VeloTypography } from '../VeloTypography';
import { useDebouncedResize } from '../hooks';
import { dataTestIdBuilder } from 'velo-data';

const List = styled('ol')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  margin: 0;
  padding-bottom: 1rem;
`;

const iconSize = 20;

const Line = styled('div')`
  @import 'velo-variables';
  $iconSize: ${iconSize}px;
  position: absolute;
  height: 1px;
  background-color: velo-color('token-color-system-divider');
  top: calc(50%);
  left: calc(50% + #{$iconSize});
`;

const ListItem = styled(VeloTypography)`
  @import 'velo-variables';
  $iconSize: ${iconSize}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  white-space: nowrap;
  position: relative;
  &::before {
    font-family: 'DsIconFont';
    font-size: $iconSize;
    width: $iconSize;
    height: $iconSize;
    content: '\f10e';
    color: velo-color('token-color-brand-primary');
    margin: 8px 0;
    order: 1;
  }
`;

const Header = styled(VeloTypography)`
  order: 0;
`;

const Footer = styled(VeloTypography)`
  order: 2;
`;
const root = 'velo-timeline';
const TestIds = {
  ITEM: `${root}-item`,
  HEADER: `${root}-header`,
  FOOTER: `${root}-footer`,
};

function getCenterX(el) {
  const { right, width } = el.getBoundingClientRect();
  return right - width / 2;
}

function computeLineWidth(left, right) {
  const leftX = getCenterX(left);
  const rightX = getCenterX(right);
  const width = rightX - leftX;
  return width - iconSize * 2;
}

function getLinesFromList(list) {
  const items = list.querySelectorAll('li');
  const len = items.length - 1;
  const lines = [];
  for (let i = 0; i < len; i++) {
    const left = items[i];
    const line = left.querySelector('[role=separator]');
    lines.push({
      left: left,
      right: items[i + 1],
      line,
    });
  }
  return lines;
}

function useVeloTimeline() {
  const ref = useRef(null);
  const [lines, setLines] = useState([]);

  useEffect(() => {
    ref.current && setLines(getLinesFromList(ref.current));
  }, [ref, setLines]);

  const effect = useCallback(() => {
    if (lines.length) {
      lines.forEach(({ left, right, line }) => {
        const width = computeLineWidth(left, right);
        line.style.width = `${width}px`;
      });
    }
  }, [lines]);
  useDebouncedResize(effect, 20);
  return ref;
}

export function VeloTimeline({ items }) {
  const ref = useVeloTimeline();
  return (
    <List ref={ref}>
      {items.map(({ label, value }, index) => (
        <ListItem
          key={index}
          tag="li"
          data-testid={dataTestIdBuilder(TestIds.ITEM, index)}
        >
          <Header
            use="bodyTextSmaller"
            data-testid={dataTestIdBuilder(TestIds.HEADER, index)}
          >
            {label}
          </Header>
          <Line role="separator" />
          <Footer
            use="bodyTextSmallerEmphasised"
            data-testid={dataTestIdBuilder(TestIds.FOOTER, index)}
          >
            {value}
          </Footer>
        </ListItem>
      ))}
    </List>
  );
}
