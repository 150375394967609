import {
  PayorUsersList,
  BOPAdminsList,
  BOPPayorUsersList,
  BOPPayeeUsersList,
  PayeeUsersList,
} from './UsersList';

export const UsersList = {
  BOPAdmins: BOPAdminsList,
  PayorUsers: PayorUsersList,
  BOPPayorUsers: BOPPayorUsersList,
  BOPPayeeUsers: BOPPayeeUsersList,
  PayeeUsers: PayeeUsersList,
};
