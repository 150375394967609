import React from 'react';
import { array, string, func, bool } from 'prop-types';
import classnames from 'classnames';
import styled from 'astroturf/react';
import { VeloTextField } from '../VeloTextField';
import { valueFromISODate, getValidatedDate } from './utils/date-formatting';

const TextField = styled(VeloTextField)`
  @import 'velo-variables';

  &.selected {
    :global(.mdc-text-field__icon) {
      outline: none;
      color: velo-color('token-color-brand-primary') !important;
    }
  }
`;

TextField.propTypes = {
  selected: bool,
};

const root = 'date-picker__input';

const TestIds = {
  RIGHT_ICON: `${root}-right-icon`,
};

class DateTextField extends React.Component {
  static propTypes = {
    /*
     * An onChange handler that will receive ISO date strings
     */
    onChange: func.isRequired,
    /**
     * An array of date pattern items like ['Y', 'm', 'd']
     */
    datePattern: array.isRequired,
    /**
     * A placeholder text like 'YYYY/MM/DD'
     */
    placeholder: string,
    /**
     * A separator to insert between year-month-day in the text input
     */
    delimiter: string.isRequired,
    /**
     * A boolean that controls the invalid state of the text input
     */
    invalid: bool,
    /**
     * An automation data-testid
     */
    testId: string,
    /**
     * Event handler function for when the user clicks on the icon
     */
    onClickIcon: func.isRequired,
    /**
     * A boolean to control is the selected state of the date picker icon
     */
    iconSelected: bool.isRequired,
    /**
     * Field name.
     */
    name: string,
  };
  state = {
    value: '',
  };

  static testIds = TestIds;
  componentDidMount() {
    if (this.props.value) {
      const { value } = this.processInput(
        valueFromISODate(
          this.props.datePattern,
          this.props.delimiter,
          this.props.value
        )
      );

      this.setState({ value });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.value !== undefined &&
      this.props.value !== prevProps.value
    ) {
      const { value } = this.processInput(
        valueFromISODate(
          this.props.datePattern,
          this.props.delimiter,
          this.props.value
        )
      );

      this.setState({ value });
    }
  }

  /**
   * Always update internal value.
   * Also call the parent onChange handler when there is a valid ISODate
   **/
  onChange = (event) => {
    const { value, ISODate } = this.processInput(event.target.value);
    if (ISODate !== undefined) {
      this.setState({ value }, () => {
        this.props.onChange({
          target: { value: ISODate, name: this.props.name },
        });
      });
    } else {
      this.setState({ value });
    }
  };

  processInput = (value) => {
    return getValidatedDate(
      value,
      this.props.datePattern,
      this.props.delimiter
    );
  };

  render() {
    const {
      delimiter,
      datePattern,
      iconSelected,
      onClickIcon,
      testId,
      invalid,
      required,
      helpText,
      ...textFieldProps
    } = this.props;

    return (
      <TextField
        {...textFieldProps}
        type="text"
        data-testid={testId}
        value={this.state.value}
        onChange={this.onChange}
        invalid={this.state.value && invalid}
        required={required}
        trailingIcon={
          !required &&
          !helpText && {
            'data-testid': TestIds.RIGHT_ICON,
            tabIndex: 0,
            icon: 'calendar',
            onClick: onClickIcon,
          }
        }
        helpText={helpText}
        selected={iconSelected}
        className={classnames({
          'mdc-text-field--focused': iconSelected,
        })}
      />
    );
  }
}

export { DateTextField };
