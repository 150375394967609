import React from 'react';
import { array, func, node, object } from 'prop-types';
import { VeloGrid } from '../VeloGrid';
import { VeloTypography } from '../VeloTypography';

const defaultValidator = (value) => ({ value });

const TestIds = {
  ERROR: 'input-range-error',
};

class InputRangeContainer extends React.Component {
  static propTypes = {
    /**
     * A handler that intercepts updated values before the onChange handler is
     * triggered, enabling the implementor to short-circuit this side-effect.
     * It accepts two parameters:
     *   value: The current proposed value to be changed
     *   valueIndex: The index of which value to be changed i.e. 0 for left, and
     *   1 for right
     * The validator must return a value with the following properties:
     *   value: A sanitised value
     *   errorLeft: An optional error to present for the left-most filed
     *   errorRight: An optional error to present for the right-most filed
     */
    validator: func,
    /**
     * An onChange handler which will receive an event with the same structure
     * as a standard HTML input event.
     */
    onChange: func.isRequired,
    /**
     * A function to render the left or lower bound component. The function is
     * passed an object parameter with the properties:
     *   value: The current value to render
     *   onChange: A handler accepting a HTML input event
     *   error: An optional field indicating an error
     */
    renderLeft: func.isRequired,
    /**
     * A function to render the right or upper bound component. The function is
     * passed an object parameter with the properties:
     *   value: The current value to render
     *   onChange: A handler accepting a HTML input event
     *   error: An optional field indicating an error
     */
    renderRight: func.isRequired,
    /**
     * The label to be rendered above the range component.
     */
    label: node.isRequired,
    /**
     * An array containing exactly two elements that represent the left, right
     * or lower and upper bound values.
     */
    value: array,
    /**
     * The cell props used for the left/right components.
     */
    cellProps: object,
    /**
     * Additional cells to render to the right of the components.
     */
    cells: node,
  };

  static defaultProps = {
    validator: defaultValidator,
    cellProps: { span: 6 },
  };

  static testIds = TestIds;

  state = { value: this.props.value };

  onStateUpdate = () => {
    const { errorLeft = undefined, errorRight = undefined, value } = this.state;
    this.props.onChange({
      target: {
        name: this.props.name,
        value,
        validationError: !!errorLeft || !!errorRight,
      },
    });
  };

  notifyValueChange = (value, idx) =>
    this.setState(this.props.validator(value, idx), this.onStateUpdate);

  onLeftChange = (evt) =>
    this.notifyValueChange([evt.target.value, this.state.value[1]], 0);

  onRightChange = (evt) =>
    this.notifyValueChange([this.state.value[0], evt.target.value], 1);

  componentDidUpdate(prevProps) {
    const { value } = this.props;
    if (value.join('') !== prevProps.value.join('')) {
      this.setState({ value });
    }
  }

  render() {
    const { errorLeft, errorRight, value } = this.state;
    const error = errorLeft || errorRight;
    const [left, right] = value;
    return (
      <>
        <VeloGrid.Inner>
          <VeloGrid.Cell span="12">{this.props.label}</VeloGrid.Cell>
          <VeloGrid.Cell {...this.props.cellProps}>
            {this.props.renderLeft({
              value: left,
              right,
              onChange: this.onLeftChange,
              error: errorLeft,
            })}
          </VeloGrid.Cell>
          <VeloGrid.Cell {...this.props.cellProps}>
            {this.props.renderRight({
              value: right,
              left,
              onChange: this.onRightChange,
              error: errorRight,
            })}
          </VeloGrid.Cell>
          {this.props.cells}
          {error && (
            <VeloGrid.Cell span="12">
              <VeloTypography
                use="bodyTextSmallerError"
                data-testid={TestIds.ERROR}
              >
                {error}
              </VeloTypography>
            </VeloGrid.Cell>
          )}
        </VeloGrid.Inner>
        {this.props.children}
      </>
    );
  }
}

export { InputRangeContainer };
