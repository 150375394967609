import React from 'react';
import { string, object, oneOfType } from 'prop-types';
import classnames from 'classnames';
import statusClassNames from '@design-system/tokens/components/status-indicators/js/component-dictionary.enum';
import { VeloIcon } from '../VeloIcon';
import { VeloTypography } from '../VeloTypography';

SourceAccountStatus.propTypes = {
  /** The sourceAccount object. */
  label: oneOfType([string, object]).isRequired,
};

const AccountStatusTypes = {
  DELETED: {
    name: 'error',
    icon: 'error_outline',
    iconColor: 'token-color-system-error-default',
    iconFallback: '#db1c0e',
    textToken: 'bodyTextMuted',
  },
};

/**
 * Render a custom SourceAccount Status indicator.
 */
function SourceAccountStatus({ label, testId, ...props }) {
  return (
    <div className="ds-status-indicator" data-testid={testId}>
      <VeloIcon
        className={classnames(
          statusClassNames.ICON,
          `${statusClassNames.ICON}--${AccountStatusTypes.DELETED.name}`
        )}
        icon={AccountStatusTypes.DELETED.icon}
      />
      <VeloTypography
        use={AccountStatusTypes.DELETED.textToken}
        className={statusClassNames.LABEL}
      >
        {label}
      </VeloTypography>
    </div>
  );
}

export { SourceAccountStatus };
