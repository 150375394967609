import React, { useCallback, useMemo } from 'react';
import {
  SourceAccountsList,
  Content,
  SourceAccountsPageHeader,
  createRenderForksFromTable,
  UserRoles,
} from 'velo-react-components';
import { PageSize, Privileges } from 'velo-data';
import { useIntl } from 'react-intl';

import { getSourceAccountsV3 } from 'velo-api/src/entities/sourceAccounts';
import { listPayorsV2, payorGetBranding } from 'velo-api/src/entities/payors';
import { getAllPooledAccounts } from 'velo-api/src/entities/pooledAccounts';
import {
  usePresenter,
  useSerializableMemo,
  useCallbackFnAsResultState,
  useAllQueries,
  useCurrencies,
} from '../../hooks';
import { forkResult } from '../../selectors';

import { SourceAccountsListPresenter } from './SourceAccountsListPresenter';

const entitySpec = {
  getAllPooledAccounts,
  getSourceAccounts: getSourceAccountsV3,
  getPayors: listPayorsV2,
  getPayor: payorGetBranding,
};

function configFromTable(Table) {
  return {
    forks: createRenderForksFromTable(Table),
    filters: Table.filters,
    queryArgs: [
      Table.columns.NAME,
      Table.sortOrder.ASCENDING,
      PageSize.MEDIUM,
      null,
    ],
  };
}

const getConfigByRole = (role) => {
  if (UserRoles.isBackOffice(role)) {
    return {
      ...configFromTable(SourceAccountsList.BackOffice),
      privileges: [Privileges.SOURCE_ACCOUNT_VIEW_PAYOR],
      Header: SourceAccountsPageHeader.WithCreate,
    };
  }

  return {
    ...configFromTable(SourceAccountsList.Payor),
    privileges: [],
    Header: () => <SourceAccountsPageHeader />,
  };
};

export function SourceAccountsListRoute({ query, role }) {
  const currencies = useCurrencies();
  const intl = useIntl();
  const { Header, forks, filters, queryArgs, privileges } = useMemo(
    () => getConfigByRole(role),
    [role]
  );
  const [loader, filterLoaderArgs, getProps, onCreate] = usePresenter(
    SourceAccountsListPresenter,
    entitySpec,
    useMemo(
      () => ({
        filters,
        currencies,
        intl,
      }),
      [filters, currencies, intl]
    ),
    useSerializableMemo({ query, privileges })
  );

  const [filterResult] = useCallbackFnAsResultState(...filterLoaderArgs);

  const [listQuery, tableProps, displayValues] = useAllQueries(
    // always guaranteed to have a result
    filterResult.result,
    ...queryArgs
  );

  const [result] = useCallbackFnAsResultState(
    useCallback(
      (cb) => loader(listQuery, displayValues, cb),
      [loader, displayValues, listQuery]
    )
  );

  return (
    <Content header={<Header onCreate={onCreate} />}>
      {forkResult(forks, result, ...getProps(tableProps, result))}
    </Content>
  );
}
