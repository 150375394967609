/* istanbul ignore file */
import { useState, useEffect } from 'react';

export function useSelectWorkAround(dialogOpen) {
  const [timePickerKey, setTimePickerKey] = useState(false);

  /**
   * Work around for select input label not floating in side of dialog.
   *
   * This type of workaround should usually be done with MDC Foundation.
   *
   * However it doesn't look to be exposed in version 5.7 - https://github.com/jamesmfriedman/rmwc/blob/v5.7.2/src/select/index.tsx
   *
   * TODO: Update to RMWC version 6 and remove this code
   */
  useEffect(() => {
    if (process.env.NODE_ENV !== 'test')
      setTimeout(() => {
        setTimePickerKey(dialogOpen);
      }, 10);
  }, [dialogOpen]);

  return [timePickerKey, setTimePickerKey];
}
