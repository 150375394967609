import React from 'react';
import { node, number, string } from 'prop-types';
import { VeloCurrency } from '../../VeloCurrency';
import { PaymentViewItem } from '../PaymentViewItem';

PaymentViewAmount.propTypes = {
  /** The label. */
  label: node.isRequired,
  /** The value. */
  value: number,
  /** The currency code. */
  currency: string,
};

/**
 * Render a Payment amount.
 */
function PaymentViewAmount({ label, value, currency, ...other }) {
  return (value || value === 0) && currency ? (
    <PaymentViewItem
      label={label}
      value={<VeloCurrency value={value} currency={currency} />}
      {...other}
    />
  ) : null;
}

export { PaymentViewAmount };
