import React, { useMemo, useCallback } from 'react';
import {
  useAllQueries,
  useCallbackFnAsResultState,
  usePresenter,
  useLoggedInUserRole,
} from '../../hooks';
import { forkResult } from '../../selectors';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
  useAppContext,
  contextCountriesSelector,
  contextCurrenciesSelector,
} from '../../context';
import {
  PaymentMethodsList,
  createRenderForksFromTable,
  PaymentChannelsPage,
  VeloCacheHitContext,
  VeloNotification,
} from 'velo-react-components';
import {
  listPayeesV4 as getPayees,
  getPayeeByIdV4 as getPayee,
  getPaymentChannelsV4BO,
  updatePaymentChannelOrderV4,
  getPaymentChannelsV4,
} from 'velo-api/src/entities/payees';
import { listPayorsV2, getPayorByIdV2 } from 'velo-api/src/entities/payors';
import {
  PayeePaymentMethodsListPresenter,
  BackOfficePaymentMethodsListPresenter,
  PayorPaymentMethodsListPresenter,
} from './PaymentMethodsListPresenter';
import { PayeeNameFragment } from '../PayeeNameFragment';

const entitySpec = {
  getPaymentMethods: getPaymentChannelsV4BO,
  getPaymentChannelsPayee: getPaymentChannelsV4,
  getPayee,
  getPayor: getPayorByIdV2,
  getPayees,
  getPayors: listPayorsV2,
  updatePaymentChannelOrder: updatePaymentChannelOrderV4,
};

const PAGE_SIZE = 20;

const notifications = {
  reorder: {
    success: VeloNotification.types.PAYMENT_CHANNEL_REORDER_SUCCESS,
    failure: VeloNotification.types.PAYMENT_CHANNEL_REORDER_FAILURE,
  },
};

function Payee({ query, payeeId }) {
  const role = useLoggedInUserRole();
  const context = useAppContext();

  const queryClient = useQueryClient();

  const presenterProps = useMemo(
    () => ({
      filters: [],
      countries: contextCountriesSelector(context),
      currencies: contextCurrenciesSelector(context),
      payeeId: payeeId,
      role,
      queryClient,
      query,
    }),
    [context, payeeId, role, queryClient, query]
  );

  const [loader, filters, getProps, onCreate] = usePresenter(
    PayeePaymentMethodsListPresenter,
    entitySpec,
    presenterProps,
    notifications
  );

  const [listQuery, tableProps] = useAllQueries(
    filters,
    undefined,
    undefined,
    PAGE_SIZE,
    null
  );

  const [cacheHit] =
    VeloCacheHitContext.useVeloCacheHitContext('payment-channels');

  const result = useQuery([loader.queryKey, listQuery, cacheHit], () =>
    loader(listQuery, cacheHit)
  );

  return (
    <PaymentChannelsPage.Standard.List
      showHelpText
      list={forkResult(
        createRenderForksFromTable(PaymentMethodsList.Payee),
        result,
        ...getProps(tableProps, result)
      )}
      onCreate={onCreate}
    />
  );
}

function Payor({ query, payeeId }) {
  const role = useLoggedInUserRole();
  const context = useAppContext();

  const queryClient = useQueryClient();

  const presenterProps = useMemo(
    () => ({
      filters: [],
      countries: contextCountriesSelector(context),
      currencies: contextCurrenciesSelector(context),
      payeeId: payeeId,
      role,
      queryClient,
      query,
    }),
    [context, payeeId, role, queryClient, query]
  );

  const [loader, filters, getProps, onCreate, onClose] = usePresenter(
    PayorPaymentMethodsListPresenter,
    entitySpec,
    presenterProps,
    notifications
  );

  const [listQuery, tableProps] = useAllQueries(
    filters,
    undefined,
    undefined,
    PAGE_SIZE,
    null
  );

  const [cacheHit] =
    VeloCacheHitContext.useVeloCacheHitContext('payment-channels');

  const result = useQuery([loader.queryKey, listQuery, cacheHit], () =>
    loader(listQuery, cacheHit)
  );

  return (
    <PaymentChannelsPage.Managed.List
      showHelpText
      payeeName={<PayeeNameFragment payeeId={payeeId} />}
      onClose={onClose}
      list={forkResult(
        createRenderForksFromTable(PaymentMethodsList.Payor),
        result,
        ...getProps(tableProps, result)
      )}
      onCreate={onCreate}
    />
  );
}

function BackOffice({ query }) {
  const role = useLoggedInUserRole();
  const context = useAppContext();

  const presenterProps = useMemo(
    () => ({
      filters: PaymentMethodsList.BackOffice.filters,
      countries: contextCountriesSelector(context),
      currencies: contextCurrenciesSelector(context),
      role,
      query,
    }),
    [context, role, query]
  );

  const [loader, filters, getProps] = usePresenter(
    BackOfficePaymentMethodsListPresenter,
    entitySpec,
    presenterProps,
    query
  );

  const [listQuery, tableProps, displayValues] = useAllQueries(
    filters,
    PaymentMethodsList.BackOffice.columns.PAYEE,
    PaymentMethodsList.BackOffice.sortOrder.ASCENDING,
    PAGE_SIZE,
    null
  );

  const [cacheHit] =
    VeloCacheHitContext.useVeloCacheHitContext('payment-channels');

  const [result] = useCallbackFnAsResultState(
    useCallback(
      (cb) => loader(listQuery, displayValues, cb, cacheHit),
      [loader, displayValues, listQuery, cacheHit]
    )
  );

  return (
    <PaymentChannelsPage.Standard.List
      list={forkResult(
        createRenderForksFromTable(PaymentMethodsList.BackOffice),
        result,
        ...getProps(tableProps, result)
      )}
    />
  );
}

export const PaymentMethodsListRoute = {
  Payor,
  Payee,
  BackOffice,
};
