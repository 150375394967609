import { useRef, useLayoutEffect } from 'react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

/**
 * An optional function argument to be passed to the useScrollLock hook
 * @function
 * @name getScrollableElements
 * @returns {Array<HTMLElement>} An array of elements. Each element will have scrolling enabled when the scroll is active.
 */

/**
 * Hook used to lock body scrolling
 * @param  {boolean} enableScrollLock
 * @param  {getScrollableElements} getScrollableElements
 * @returns {object} React ref object
 *
 */
const useScrollLock = (
  enableScrollLock = true,
  getScrollableElements = () => []
) => {
  const ref = useRef(null);

  useLayoutEffect(() => {
    const scrollableElements = [ref.current, ...getScrollableElements()];

    if (enableScrollLock) {
      // Enables multiple scrollable elements
      scrollableElements.forEach((element) => {
        disableBodyScroll(element);
      });
    } else {
      scrollableElements.forEach((element) => {
        enableBodyScroll(element);
      });
    }

    return () => {
      scrollableElements.forEach((element) => {
        enableBodyScroll(element);
      });
    };
  }, [enableScrollLock, getScrollableElements]);
  return ref;
};

export { useScrollLock };
