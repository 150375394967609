import { useMemo } from 'react';
import { useAPIContext } from '../../context';
import { mapPromiseResponseWithCallback } from '../helpers';

export function useTransactionsAPI() {
  const api = useAPIContext();
  return useMemo(
    () => mapPromiseResponseWithCallback(api.getTransactions),
    [api.getTransactions]
  );
}
