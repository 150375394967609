import React from 'react';

import { VeloModalSheet } from '../../VeloModalSheet';
import { VeloIconButton } from '../../VeloIconButton';

const TestIds = {
  CLOSE_BUTTON: 'payment-view-error-close-button',
};

PaymentViewError.testIds = TestIds;

PaymentViewError.defaultProps = {
  closeButtonProps: {},
};

const getCloseButtonProps = (closeButtonProps) => ({
  'data-testid': TestIds.CLOSE_BUTTON,
  title: 'Close payment view',
  ...closeButtonProps,
});

/**
 * Render a Payment View error.
 */
function PaymentViewError({ onClose, closeButtonProps }) {
  // Payment Errors are predominantly caused by Payment Audit Delays = non terminal
  const displayError = 'An unexpected error occurred, please try again.';
  return (
    <>
      <VeloModalSheet.Header>
        <VeloModalSheet.HeaderTitle />
        <VeloIconButton
          {...getCloseButtonProps(closeButtonProps)}
          icon="close"
          onClick={onClose}
        />
      </VeloModalSheet.Header>
      <VeloModalSheet.Body>
        <VeloModalSheet.Error>{displayError}</VeloModalSheet.Error>
      </VeloModalSheet.Body>
    </>
  );
}

export { PaymentViewError };
