import { useAPIContext } from '../../context';
import { useLoadingEffectFunction } from '../helpers';

function useUserQueryAPI(query) {
  const api = useAPIContext();
  const [data, getUsers] = useLoadingEffectFunction(
    { fn: api.getUsersv2 },
    query
  );
  return [data, { getUsers }];
}

export { useUserQueryAPI };
