import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'astroturf/react';
import { VeloTypography } from '../VeloTypography';
import { ResultContainer } from '../ResultContainer';
import { VeloCurrency } from '../VeloCurrency';
import { VeloLabelledItem } from '../VeloLabelledItem';
import { VeloGridLoading } from '../VeloGridLoading';
import { VeloSectionGrid } from '../VeloSectionGrid';
import { ErrorMessage } from '../ErrorMessage';

const root = 'velo-ob-payouts-details-fields';

const TestIds = {
  LOADING: `${root}-loading`,
  ERROR: `${root}-error`,
};

const FieldTypes = VeloGridLoading.fieldTypes;

function PayoutDetailsFieldsLoading() {
  return (
    <VeloGridLoading
      data-testid={TestIds.LOADING}
      compact
      sections={[
        {
          fields: [
            { type: FieldTypes.TextField },
            { type: FieldTypes.TextField },
            { type: FieldTypes.TextField },
            { type: FieldTypes.TextField },
            { type: FieldTypes.TextField },
          ],
        },
      ]}
    />
  );
}

const Error = styled(ErrorMessage)`
  margin-top: 1rem;
`;

function PayoutDetailsFieldsError({ error }) {
  return (
    <VeloSectionGrid
      data-testid={TestIds.ERROR}
      compact
      sections={[
        {
          fields: [
            <VeloTypography use="secondaryHeader" tag="h1">
              <FormattedMessage defaultMessage="An error occurred" />
            </VeloTypography>,
            <Error>{error}</Error>,
          ],
        },
      ]}
      render={(component) => component}
    />
  );
}

function PayoutDetailsFieldsData({
  amount,
  currency,
  paymentMemo,
  payeeName,
  result: { routingNumber, accountNumber },
}) {
  return (
    <VeloSectionGrid
      breakpoint={400}
      compact
      sections={[
        {
          fields: [
            <VeloTypography use="sectionHeader">
              <FormattedMessage defaultMessage="Payout to" />
            </VeloTypography>,
            <VeloLabelledItem label="Payee Name">{payeeName}</VeloLabelledItem>,
            {
              desktop: 6,
              tablet: 4,
              phone: 4,
              section: (
                <VeloSectionGrid
                  compact
                  sections={[
                    {
                      fields: [
                        <VeloLabelledItem label="Sort Code">
                          {routingNumber}
                        </VeloLabelledItem>,
                        <VeloLabelledItem label="Account Number">
                          {accountNumber}
                        </VeloLabelledItem>,
                      ],
                    },
                  ]}
                  render={(component) => component}
                />
              ),
            },

            {
              desktop: 6,
              tablet: 4,
              phone: 4,
              section: (
                <VeloSectionGrid
                  compact
                  sections={[
                    {
                      fields: [
                        <VeloLabelledItem label="Payment amount">
                          <VeloCurrency value={amount} currency={currency} />
                        </VeloLabelledItem>,
                        ...(paymentMemo
                          ? [
                              <VeloLabelledItem label="Payment reference">
                                {paymentMemo}
                              </VeloLabelledItem>,
                            ]
                          : []),
                      ],
                    },
                  ]}
                  render={(component) => component}
                />
              ),
            },
          ],
        },
      ]}
      render={(component) =>
        component.section ? component.section : component
      }
    />
  );
}

const PayoutDetailsFields = ResultContainer({
  ErrorComponent: PayoutDetailsFieldsError,
  LoadingComponent: PayoutDetailsFieldsLoading,
})(PayoutDetailsFieldsData);

PayoutDetailsFields.testIds = TestIds;

export { PayoutDetailsFields };
