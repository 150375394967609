import { PayorNameDataJoin } from '../../presenters';
import { createEarlyExitCallback } from '../../selectors';
import {
  canShowFundingAccountPayorField,
  canEditFundingAccount,
  canShowFundingAccountSensitive,
} from './FundingAccountHelpers';

const nullLoader = () => undefined;

export function FundingAccountViewPresenter(
  wireframe,
  entity,
  { fundingAccountId, privileges, query }
) {
  wireframe.checkForRedirect(fundingAccountId, query);

  const dataJoin = PayorNameDataJoin(
    entity,
    canShowFundingAccountPayorField(privileges)
  );

  const loader = (cb) =>
    entity.getFundingAccount(
      fundingAccountId,
      {
        sensitive: canShowFundingAccountSensitive(privileges),
      },
      createEarlyExitCallback(
        (data) =>
          dataJoin(
            createEarlyExitCallback(
              ([fundingAccount]) => cb(undefined, fundingAccount),
              cb
            ),
            [data]
          ),
        cb
      )
    );

  const onClose = wireframe.goBack;
  const onEdit = () =>
    wireframe.navigateToFundingAccountEdit({ fundingAccountId });

  return [
    fundingAccountId ? loader : nullLoader,
    {
      open: !!fundingAccountId,
      onClose,
    },
    {
      onClose,
      onEdit: canEditFundingAccount(privileges) ? onEdit : undefined,
    },
  ];
}
