import React from 'react';
import { arrayOf, shape, string, func, oneOf } from 'prop-types';
import { dataTestIdBuilder, PayorAccountType } from 'velo-data';
import index from 'just-index';
import { FormattedMessage } from 'react-intl';
import { VeloTable } from '../VeloTable';
import { VeloTableBuilder } from '../VeloTableBuilder';
import { LookupTextField } from '../LookupTextField';
import { PayorAccountTypeText } from '../PayorAccountTypeText';

const root = 'velo-funding-accounts-list';

const TestIds = {
  LIST: root,
  HEADING: `${root}-heading`,
  SORT_HEADING: `${root}-sort-heading`,
  ROW_PREFIX: `${root}-row`,
  COLUMN_PREFIX: `${root}-column`,
  SKELETON_ROW: `${root}-icon-skeleton`,
};

const fieldNames = {
  NAME: 'name',
  TYPE: 'type',
  PAYOR: 'payorId',
  CURRENCY: 'currency',
  COUNTRY: 'countryCode',
};

const columnsByName = index(
  [
    {
      name: fieldNames.NAME,
      label: <FormattedMessage defaultMessage="Name" />,
      size: VeloTable.size.LARGE,
      'data-testid': dataTestIdBuilder(TestIds.HEADING, 'name'),
    },
    {
      name: fieldNames.TYPE,
      label: <FormattedMessage defaultMessage="Type" />,
      size: VeloTable.size.SMALL,
      'data-testid': dataTestIdBuilder(TestIds.HEADING, 'type'),
    },
    {
      name: fieldNames.PAYOR,
      label: <FormattedMessage defaultMessage="Payor" />,
      size: VeloTable.size.LARGE,
      'data-testid': dataTestIdBuilder(TestIds.HEADING, 'payor'),
    },
    {
      name: fieldNames.CURRENCY,
      label: <FormattedMessage defaultMessage="Currency" />,
      size: VeloTable.size.SMALL,
      align: VeloTable.alignment.END,
      'data-testid': dataTestIdBuilder(TestIds.HEADING, 'currency'),
    },
  ],
  'name'
);

const cellFactoryByFieldName = {
  [fieldNames.NAME]: ({ name }) => name,
  [fieldNames.TYPE]: ({ type }) => <PayorAccountTypeText type={type} />,
  [fieldNames.PAYOR]: ({ payorName }) => payorName,
  [fieldNames.CURRENCY]: ({ currency }) => currency,
};

const filtersByName = index(
  [
    {
      name: fieldNames.NAME,
      label: <FormattedMessage defaultMessage="Name" />,
      type: 'string',
    },
    {
      name: fieldNames.PAYOR,
      label: <FormattedMessage defaultMessage="Payor" />,
      type: 'entityIdLookup',
      mode: LookupTextField.modes.PAYOR,
    },
    {
      name: fieldNames.TYPE,
      label: <FormattedMessage defaultMessage="Type" />,
      type: 'list',
      options: ({ intl }) =>
        Object.values(PayorAccountType)
          .filter((type) => type !== PayorAccountType.PRIVATE_COUPLED)
          .map((value) => ({
            label: PayorAccountTypeText.fromIntl(intl, value),
            value,
          })),
    },
    {
      name: fieldNames.CURRENCY,
      label: <FormattedMessage defaultMessage="Currency" />,
      type: 'listCurrency',
      options: [],
    },
  ],
  'name'
);

const common = {
  getRowProps(data, index, _, { onClick }) {
    return {
      key: data.id,
      icon: 'chevron_right',
      onClick: () => onClick(data),
      'data-testid': dataTestIdBuilder(TestIds.ROW_PREFIX, index),
    };
  },
  getColumnProps({ name: key, align, size }, data, index) {
    return {
      key,
      align,
      size,
      'data-testid': dataTestIdBuilder(TestIds.ROW_PREFIX, index, key),
      children: cellFactoryByFieldName[key](data),
    };
  },
  dataProps: {
    data: arrayOf(
      shape({
        /** unique id */
        id: string.isRequired,
        /** The type of funding account */
        type: oneOf(Object.values(PayorAccountType)),
        /** account name */
        name: string.isRequired,
        /** payor name */
        payorName: string,
        /** The currency of the account */
        currency: string.isRequired,
      })
    ),
    /** row onClick handler */
    onClick: func.isRequired,
  },
  columns: fieldNames,
  sortableColumns: [fieldNames.NAME],
};

export const FundingAccountsList = {
  BackOffice: VeloTableBuilder({
    ...common,
    columnOrder: [
      columnsByName[fieldNames.NAME],
      columnsByName[fieldNames.PAYOR],
      columnsByName[fieldNames.TYPE],
      columnsByName[fieldNames.CURRENCY],
    ],
    filters: [
      filtersByName[fieldNames.NAME],
      filtersByName[fieldNames.PAYOR],
      filtersByName[fieldNames.TYPE],
      filtersByName[fieldNames.CURRENCY],
    ],
  }),
  Payor: VeloTableBuilder({
    ...common,
    columnOrder: [
      columnsByName[fieldNames.NAME],
      columnsByName[fieldNames.TYPE],
      columnsByName[fieldNames.CURRENCY],
    ],
    filters: [
      filtersByName[fieldNames.NAME],
      filtersByName[fieldNames.TYPE],
      filtersByName[fieldNames.CURRENCY],
    ],
  }),
};
