import { useMemo } from 'react';
import { useAppContext } from '../../context';

function selectAuthState(entityPropName, { entityIds, user_info, mfa }) {
  const userId = (user_info || { user_id: undefined }).user_id;
  return {
    [entityPropName]: (entityIds || [])[0],
    userId,
    loggedIn: userId !== undefined && mfa,
  };
}

export function useAuthState(entityPropName) {
  const { authentication } = useAppContext();
  return useMemo(
    () => selectAuthState(entityPropName, authentication),
    [authentication, entityPropName]
  );
}

export function useAuthStateAsResult(entityPropName) {
  const context = useAppContext();

  const { host, basic, ...authentication } = context.authentication;

  const formattedAuthState = useMemo(
    () => selectAuthState(entityPropName, authentication),
    [authentication, entityPropName]
  );

  const result = Object.keys(authentication).length
    ? formattedAuthState
    : undefined;

  return { result };
}
