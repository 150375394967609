import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { VeloStepper } from '../VeloStepper';
import { GroupCsvUpload } from './GroupCsvUpload';
import { VeloSelect } from '../VeloSelect';
import TestIds from './testIds';
import { StepContent } from './StepContent';

function GroupAdvancedCreateSteps({
  fromOptions,
  toOptions,
  onPayorFromChange,
  onPayorToChange,
  ...props
}) {
  const [payorFrom, setPayorFrom] = useState();
  const [payorTo, setPayorTo] = useState();

  const applyPayorFrom = (value) => {
    // Only reset if From set to other than To - so paying Standard/AS payout to self/As
    if (payorFrom && payorTo !== value) {
      setPayorTo(undefined);
    }
    setPayorFrom(value);
    onPayorFromChange(value);
  };

  const applyPayorTo = (value) => {
    setPayorTo(value);
    onPayorToChange(value);
  };

  return (
    <>
      <VeloStepper.Step>
        <VeloStepper.Label
          icon="2"
          text={
            <FormattedMessage defaultMessage="From which payor is this payout being made?" />
          }
        />
        <StepContent>
          <VeloSelect
            value={payorFrom}
            label={<FormattedMessage defaultMessage="Payor (from)" />}
            options={fromOptions}
            data-testid={TestIds.PAYOUT_FROM}
            onChange={({ target: { value } }) => applyPayorFrom(value)}
            required={true}
          />
        </StepContent>
      </VeloStepper.Step>
      <VeloStepper.Step>
        <VeloStepper.Label
          disabled={!payorFrom}
          icon="3"
          text={
            <FormattedMessage defaultMessage="To whose payees is this payout being made?" />
          }
        />
        <StepContent>
          {payorFrom && (
            <VeloSelect
              value={payorTo}
              label={<FormattedMessage defaultMessage="Payor (to)" />}
              options={toOptions}
              data-testid={TestIds.PAYOUT_TO}
              onChange={({ target: { value } }) => applyPayorTo(value)}
              required={true}
            />
          )}
        </StepContent>
      </VeloStepper.Step>
      <VeloStepper.Step>
        <VeloStepper.Label
          disabled={!payorTo}
          icon="4"
          text={
            <FormattedMessage defaultMessage="Add an optional descriptive memo and upload your CSV" />
          }
        />
        <StepContent>{payorTo && <GroupCsvUpload {...props} />}</StepContent>
      </VeloStepper.Step>
    </>
  );
}
export { GroupAdvancedCreateSteps };
