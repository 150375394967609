import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { FormGrid } from './FormGrid';
import { ProfileField } from '../FormFields/FormFieldTypes';

export function ChallengeCodeForm({
  values: { challengeDescription, challengeValue },
  breakpoint,
  onChange,
}) {
  const intl = useIntl();
  return (
    <FormGrid
      sections={[
        {
          fields: [
            {
              name: 'challengeDescription',
              required: true,
              value: challengeDescription,
              ...ProfileField(
                <FormattedMessage defaultMessage="Challenge code description" />,
                <FormattedMessage defaultMessage="Please enter a challenge code description" />,
                1,
                255
              ),
              placeholder: intl.formatMessage({
                defaultMessage: '1-255 characters',
              }),
            },
            {
              name: 'challengeValue',
              required: true,
              value: challengeValue,
              ...ProfileField(
                <FormattedMessage defaultMessage="Challenge code" />,
                <FormattedMessage defaultMessage="Please enter a challenge code value" />,
                3,
                20
              ),
              placeholder: intl.formatMessage({
                defaultMessage: '3-20 characters',
              }),
            },
          ],
        },
      ]}
      onChange={onChange}
      breakpoint={breakpoint}
    />
  );
}
