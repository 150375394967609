import React, { useMemo, useRef, useState } from 'react';
import { bool, func, number } from 'prop-types';
import styled from 'astroturf/react';
import { PageSize } from 'velo-data';
import { VeloButton } from '../VeloButton';
import { VeloMenu } from '../VeloMenu';
import { FormattedMessage } from 'react-intl';
import { useMenuManager } from '../hooks';

const Button = styled(VeloButton)`
  @import 'velo-variables';

  line-height: normal;
  text-transform: none;
  letter-spacing: normal;
  font-weight: normal;

  :global(.mdc-button__label) {
    color: velo-color('token-color-text-default');
  }

  &:disabled {
    :global(.mdc-button__label) {
      color: velo-color('token-color-text-disabled');
    }
  }
`;

const Wrapper = styled('div')`
  @import 'velo-mixins';
  display: none;

  @include from-breakpoint(XS) {
    display: flex;
    align-items: center;
  }
`;

const root = 'page-size';

const TestIds = {
  BUTTON: `${root}-button`,
  MENU: `${root}-menu`,
};

/**
 * Render an MDC button with a drop-down icon.
 * The user clicks this button to display a list of pages.
 */
const PageButton = ({ disabled, ...other }) => (
  <Button
    trailingIcon="arrow_drop_down"
    disabled={disabled}
    data-testid={TestIds.BUTTON}
    {...other}
  />
);

const pageSizes = Object.values(PageSize);

/**
 * The menu that appears when the button is clicked.
 * Uses `useMenuManager` to esnure it only appears in the DOM
 * when open.
 */
const PageMenu = ({ ...menuProps }) => {
  const [mounted, onClose] = useMenuManager(menuProps.open, menuProps.onClose);

  return mounted ? (
    <VeloMenu {...menuProps} onClose={onClose} data-testid={TestIds.MENU}>
      {pageSizes.map((pageSize) => (
        <VeloMenu.Item key={pageSize} ripple={false}>
          {pageSize}
        </VeloMenu.Item>
      ))}
    </VeloMenu>
  ) : null;
};

PageSizeMenu.propTypes = {
  /** The current page. */
  pageSize: number,
  /** Disable the component. */
  disabled: bool,
  /** Called when a new page is selected. */
  onPageSize: func,
};

PageSizeMenu.testIds = TestIds;

/**
 * Pagination menu.
 * When clicked a menu is displayed allowing the user to
 * jump directly to page.
 */
function PageSizeMenu({ pageSize, disabled, onPageSize }) {
  const [open, setOpen] = useState(false);
  const selected = useRef(-1);

  const menuHandlers = useMemo(
    () => ({
      onOpen: () => {
        selected.current = -1;
      },
      onSelect: (event) => {
        selected.current = event.detail.index;
      },
      onClose: () => {
        setOpen(false);
        if (selected.current !== -1) {
          onPageSize(pageSizes[selected.current]);
        }
      },
    }),
    [onPageSize]
  );

  return (
    <Wrapper>
      <VeloMenu.SurfaceAnchor>
        <PageMenu open={open} {...menuHandlers} />
        <PageButton disabled={disabled} onClick={() => setOpen(true)}>
          <FormattedMessage
            defaultMessage="Items per page: {pageSize}"
            values={{ pageSize }}
          />
        </PageButton>
      </VeloMenu.SurfaceAnchor>
    </Wrapper>
  );
}

export { PageSizeMenu };
