const sanitizeDataByPaymentChannelRules = (data, paymentChannelRules) => {
  // Formats the data based on the given payment Channel rules
  const { countryCode, currency, paymentChannelName } = data;

  let paymentChannelRulesFields = {};

  paymentChannelRules.map((rule) => {
    paymentChannelRulesFields = {
      [rule.element]: data[rule.element],
      ...paymentChannelRulesFields,
    };

    return paymentChannelRulesFields;
  });

  return {
    ...paymentChannelRulesFields,
    paymentChannelName,
    currency,
    countryCode,
  };
};

export { sanitizeDataByPaymentChannelRules };
