import { useMemo } from 'react';
import { usePayorLinksQueryAPI } from 'velo-api-react-hooks';

export const PayorHierarchySelectType = {
  DESCENDANTS: 'descendantsOfPayor',
  PARENTS: 'parentsOfPayor',
};
export function usePayorHierarchySelector(
  payorId,
  type = PayorHierarchySelectType.DESCENDANTS
) {
  const query = useMemo(() => ({ [type]: payorId }), [type, payorId]);
  const [result] = usePayorLinksQueryAPI(query);
  return result.result && result.result.payors;
}
