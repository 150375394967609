import {
  getSourceAccountTagsV2 as getSourceAccountTags,
  unlinkSourceAccountTagV2 as unlinkSourceAccountTag,
} from 'velo-api/src/entities/sourceAccounts';
import { createTagsRoute } from '../../containers';

export const SourceAccountsTagsRoute = createTagsRoute(
  {
    getTags: getSourceAccountTags,
    removeTag: unlinkSourceAccountTag,
  },
  (wireframe, { entityId, payorId, query }) =>
    wireframe.navigateToSourceAccountAddTags(
      {
        entityId,
        payorId,
      },
      query
    )
);
