import { formatCallbackErrorArg, formatError } from '../../selectors';
import { UserRoles } from 'velo-react-components';

const ResendTokenTypes = {
  INVITE_MFA_USER: 'INVITE_MFA_USER',
  MFA_REGISTRATION: 'MFA_REGISTRATION',
};

function buildViewData(data) {
  const userRole = data.roles[0].name;

  return {
    ...data,
    role: userRole,
    secondaryContactNumber: data.secondaryContactNumber || '',
  };
}

const checkForErrorFormatting = (originalCb, localCb) => (error, data) => {
  originalCb.shouldIgnoreError && originalCb.shouldIgnoreError(error)
    ? originalCb(error)
    : localCb(formatError(error), data);
};

const selectNavigateToEdit = (wireframe, availableRoles) => {
  if (availableRoles[UserRoles.BOPAdmin]) {
    return wireframe.navigateToAdminEdit;
  } else if (availableRoles[UserRoles.PayeeAdmin]) {
    return wireframe.navigateToPayeeUserEdit;
  } else {
    return wireframe.navigateToUserEdit;
  }
};

export function UserViewPresenter(
  wireframe,
  entity,
  { userId, availableRoles },
  {
    deleteUserNotification,
    unlockUserNotification,
    resendInviteNotification,
    resendRegistrationNotification,
  }
) {
  const getUpdateHandler =
    (notification, goBack = true, refresh = true) =>
    (cb) =>
    (error, data) => {
      if (error) {
        wireframe.sendNote({
          ...notification.error,
          message: error,
        });
      } else {
        wireframe.sendNote(notification.success, refresh);

        if (goBack) {
          wireframe.goBack();
        }
      }
      cb(error, data);
    };

  const navigateToEdit = selectNavigateToEdit(wireframe, availableRoles);

  return [
    // loader
    (cb) => {
      if (!userId) return;

      return entity.getUserById(userId, (error, data) => {
        if (error) {
          return cb(error);
        }

        cb(undefined, buildViewData(data));
      });
    },
    {
      onClose: wireframe.goBack,
      onEdit: ({ id: userId }) => navigateToEdit({ userId }),
      handlers: {
        onDelete: (cb) => {
          return entity.deleteUserById(
            userId,
            formatCallbackErrorArg(getUpdateHandler(deleteUserNotification)(cb))
          );
        },
        onUnlock: (cb) => {
          return entity.unlockUser(
            userId,
            formatCallbackErrorArg(getUpdateHandler(unlockUserNotification)(cb))
          );
        },
        onResendInvite: (body, cb) => {
          return entity.resendToken(
            userId,
            { tokenType: ResendTokenTypes.INVITE_MFA_USER, ...body },
            checkForErrorFormatting(
              cb,
              getUpdateHandler(resendInviteNotification, false)(cb)
            )
          );
        },
        onResendMfaRegistration: (body, cb) => {
          return entity.resendToken(
            userId,
            { tokenType: ResendTokenTypes.MFA_REGISTRATION, ...body },
            checkForErrorFormatting(
              cb,
              getUpdateHandler(resendRegistrationNotification, false)(cb)
            )
          );
        },
      },
    },
  ];
}
