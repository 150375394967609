import React, { useEffect, useState } from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router';
import { PaymentsListRoute } from '../PaymentsListRoute';
import { PaymentReceiptRoute } from '../PaymentReceiptRoute';
import { useWireframe } from '../../hooks';
import { VeloCacheHitContext } from 'velo-react-components';

function BusinessPaymentsRoute(props) {
  const history = useHistory();
  const location = useLocation();
  const { navigateToPaymentReceipt, sendNote } = useWireframe(history);

  // allows the masking of a withdrawnPayment that is reported incorrectly from
  // v4/paymentaudit/payments?sensitive=true&payorId= temporarily - hence the reset when refreshed
  // this is not desired or pretty but an API work around - please, please, remove when PaymentAudit is fixed/replaced
  // Placed here a functional component - children are class based...
  const [refresh, setRefresh] = useState(0);
  const [withdrawingPaymentId, setWithdrawingPaymentId] = useState(undefined);

  const [cacheHit] = VeloCacheHitContext.useVeloCacheHitContext('payments');

  useEffect(() => {
    // Update the state to trigger a Component Update
    // delay the unmasking by a bit...
    const timer = setTimeout(() => setWithdrawingPaymentId(undefined), 500);
    setRefresh(cacheHit);
    return () => clearTimeout(timer);
  }, [cacheHit]);

  return (
    <Switch>
      <Route path={navigateToPaymentReceipt.path} exact>
        <PaymentReceiptRoute />
      </Route>

      <Route
        key={location.search}
        render={(routeProps) => (
          <PaymentsListRoute
            {...routeProps}
            {...props}
            refresh={refresh}
            setWithdrawingPaymentId={setWithdrawingPaymentId}
            withdrawingPaymentId={withdrawingPaymentId}
            sendNote={sendNote}
            onViewPaymentReceipt={(paymentId) =>
              navigateToPaymentReceipt({ paymentId })
            }
          />
        )}
      />
    </Switch>
  );
}

BusinessPaymentsRoute.testIds = { ...PaymentsListRoute.testIds };

export { BusinessPaymentsRoute };
