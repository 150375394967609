import React from 'react';
import styled from 'astroturf/react';
import { func } from 'prop-types';

const FilterOverlay = styled('div')`
  @import 'velo-variables';

  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: $velo-full-screen-index-default;
`;

FilterScrim.propTypes = {
  /** Called when clicking on the scrim. */
  onClick: func.isRequired,
};

/**
 * Used to capture mouse clicks when rendering a popup
 * within Filter components.
 */
function FilterScrim({ onClick, ...other }) {
  return <FilterOverlay onClick={onClick} role="presentation" {...other} />;
}

export { FilterScrim };
