import omit from 'just-omit';
import {
  privilegeSelectors,
  sourceAccountSelectors,
  Privileges,
  PayorAccountType,
} from 'velo-data';
import { SendError } from 'velo-api/src/send';
import {
  combineCallbacks,
  pipeCallbackFunctions,
  forkResult,
  createEarlyExitCallback,
  paginatedSelectors,
  fundingAccountSelectors,
  sourceAccountSelectors as sourceAccountSelector,
} from '../../selectors';
import { PayorNameDataJoin } from '../../presenters';
import { FundingAccountNameDataJoin } from './FundingAccountNameDataJoin';
import { PaymentRailDataJoin } from './PaymentRailDataJoin';

const handlerNames = {
  onBalanceThreshold: 'onBalanceThreshold',
  onAddFunding: 'onAddFunding',
  onAutoTopup: 'onAutoTopup',
  onTransferFunds: 'onTransferFunds',
  onEdit: 'onEdit',
  onDelete: 'onDelete',
  onEditOboDetails: 'onEditOboDetails',
};

const privilegesByHandler = {
  [handlerNames.onBalanceThreshold]: [
    Privileges.SOURCE_ACCOUNT_SET_LOW_BALANCE,
  ],
  [handlerNames.onAddFunding]: [Privileges.SOURCE_ACCOUNT_ADD_FUNDS],
  [handlerNames.onAutoTopup]: [Privileges.SOURCE_ACCOUNT_SET_TOP_UP],
  [handlerNames.onTransferFunds]: [Privileges.SOURCE_ACCOUNT_TRANSFER_FUNDS],
  [handlerNames.onEdit]: [Privileges.SOURCE_ACCOUNT_EDIT],
  [handlerNames.onDelete]: [Privileges.SOURCE_ACCOUNT_DELETE],
  [handlerNames.onBalanceThreshold]: [
    Privileges.SOURCE_ACCOUNT_SET_LOW_BALANCE,
  ],
  [handlerNames.onEditOboDetails]: [Privileges.SOURCE_ACCOUNT_EDIT],
};

function createReducer(privileges) {
  return (handlers, entry) => {
    if (!entry) return handlers;
    const [key, value] = entry;

    if (
      privilegeSelectors.hasPrivileges(privilegesByHandler[key], privileges)
    ) {
      return { ...handlers, [key]: value };
    }
    return handlers;
  };
}

const forks = {
  value: (
    data,
    {
      hasFundingAccounts,
      transferFundsEnabled,
      wireframe,
      privileges,
      notifications,
      onDelete,
    },
    createChild
  ) => {
    const { payorId, id: sourceAccountId, type, railsId } = data;
    const fboType = type === PayorAccountType.FBO;
    const privateType = type === PayorAccountType.PRIVATE;
    return [
      hasFundingAccounts && [
        handlerNames.onAddFunding,
        () =>
          wireframe.navigateToSourceAccountAddFunding({
            payorId,
            sourceAccountId,
          }),
      ],
      (fboType || privateType) &&
        hasFundingAccounts && [
          handlerNames.onAutoTopup,
          () =>
            wireframe.navigateToSourceAccountAutoTopup({
              payorId,
              sourceAccountId,
            }),
        ],

      fboType && [
        handlerNames.onBalanceThreshold,
        () =>
          wireframe.navigateToSourceAccountBalanceThreshold({
            payorId,
            sourceAccountId,
          }),
      ],
      fboType && [
        handlerNames.onEditOboDetails,
        () =>
          wireframe.navigateToEditOboDetails(
            {
              payorId,
              sourceAccountId,
            },
            {
              railsId,
            }
          ),
      ],
      fboType &&
        transferFundsEnabled && [
          handlerNames.onTransferFunds,
          () =>
            wireframe.navigateToSourceAccountTransferFunds({
              payorId,
              sourceAccountId,
            }),
        ],
    ].reduce(createReducer(privileges), {
      onDelete: privilegeSelectors.hasPrivileges(
        [Privileges.SOURCE_ACCOUNT_DELETE],
        privileges
      )
        ? () =>
            onDelete(
              createEarlyExitCallback(
                () => {
                  wireframe.sendNote(notifications.success);
                  wireframe.navigateToSourceAccountsList();
                },
                () => {
                  return fboType
                    ? wireframe.sendNote(notifications.fboFailure)
                    : wireframe.sendNote(notifications.failure);
                }
              )
            )
        : null,
      onClose: wireframe.navigateToSourceAccountsList,
      children: createChild(data),
      data: omit(data, [
        ...(privilegeSelectors.hasPrivileges(
          [Privileges.SOURCE_ACCOUNT_VIEW_PHYSICAL_ACCOUNT],
          privileges
        )
          ? []
          : ['physicalAccountName']),
        ...(hasFundingAccounts ? [] : ['autoTopUpConfig']),
      ]),
    });
  },
  none: ({ onClose }, createChild) => ({ onClose, children: createChild({}) }),
  error: (error, { onClose }) => ({ onClose, error }),
};

function getQueryObjects({ type, currency, payorId }) {
  const baseQuery = { pageSize: 1, payorId };
  // if it's FBO, the currencies for funding accounts must match
  const fundingQueries =
    type === PayorAccountType.FBO
      ? { ...baseQuery, type, currency }
      : { ...baseQuery, type };
  const sourceAccountsQueries =
    type !== PayorAccountType.FBO ? { includeUserDeleted: true } : {};

  return [fundingQueries, { ...baseQuery, ...sourceAccountsQueries, type }];
}

export function SourceAccountsViewPresenter(
  wireframe,
  entity,
  { privileges, sourceAccountId, query },
  notifications
) {
  wireframe.checkForRedirect(sourceAccountId, query);

  function getSourceAccount(cb) {
    return entity.getSourceAccount(sourceAccountId, cb);
  }

  function onDelete(cb) {
    return entity.deleteSourceAccount(sourceAccountId, cb);
  }

  const fundingJoin = FundingAccountNameDataJoin(entity);
  const payorJoin = PayorNameDataJoin(
    entity,
    privilegeSelectors.hasPrivileges(
      [Privileges.SOURCE_ACCOUNT_VIEW_PAYOR],
      privileges
    )
  );
  const paymentRailJoin = PaymentRailDataJoin(entity);

  function createLoaderCallback(cb) {
    return createEarlyExitCallback(
      (
        sourceAccountCb,
        [[{ payorName }]],
        railsCb,
        fundingAccountsCb,
        fundingAccountCb
      ) => {
        const hasFundingAccounts =
          fundingAccountsCb &&
          paginatedSelectors.getTotalElements(fundingAccountsCb) > 0;

        const fundingAccountSourceAccount =
          (fundingAccountCb &&
            fundingAccountSelectors.getSourceAccount(fundingAccountCb)) ||
          sourceAccountSelector.selectSourceAccount(sourceAccountCb);

        const railEnhancedSourceAccount =
          railsCb && fundingAccountSelectors.getSourceAccount(railsCb);

        const transferFundsEnabled =
          paginatedSelectors.getTotalElements(sourceAccountCb) > 1 &&
          railEnhancedSourceAccount.sourceAccountTransfersSupported;

        const editSourceAccount = privilegeSelectors.hasPrivileges(
          [Privileges.SOURCE_ACCOUNT_EDIT],
          privileges
        );

        if (
          editSourceAccount &&
          railsCb &&
          railsCb[0][0]?.onBehalfOfOverrideSupported
        ) {
          return entity.getOboOverrideDetails(sourceAccountId, (err, data) => {
            if (err) {
              if (SendError.isNotFoundError(err)) {
                cb(
                  undefined,
                  {
                    payorName,
                    oboOverrideDetails: {
                      notFoundDetails: true,
                    },
                    ...fundingAccountSourceAccount,
                    ...railEnhancedSourceAccount,
                  },
                  {
                    hasFundingAccounts,
                    transferFundsEnabled,
                  }
                );
              } else {
                cb(err);
              }
            } else {
              cb(
                undefined,
                {
                  payorName,
                  oboOverrideDetails: data,
                  ...fundingAccountSourceAccount,
                  ...railEnhancedSourceAccount,
                },
                {
                  hasFundingAccounts,
                  transferFundsEnabled,
                }
              );
            }
          });
        } else {
          return cb(
            undefined,
            {
              payorName,
              ...fundingAccountSourceAccount,
              ...railEnhancedSourceAccount,
            },
            {
              hasFundingAccounts,
              transferFundsEnabled,
            }
          );
        }
      },
      cb
    );
  }

  function loadData(data, cb) {
    const sourceAccount = sourceAccountSelectors.normaliseSourceAccount(data);
    const [
      getSourceAccountsCb,
      payorJoinCb,
      getRailsCb,
      getFundingAccountsCb,
      fundingAccountJoinCb,
    ] = combineCallbacks(
      sourceAccount.type === PayorAccountType.PRIVATE_COUPLED ? 3 : 5,
      createLoaderCallback(cb)
    );

    if (fundingAccountJoinCb) {
      fundingJoin(fundingAccountJoinCb, [sourceAccount]);
    }

    payorJoin(payorJoinCb, [sourceAccount]);
    paymentRailJoin(getRailsCb, [sourceAccount]);

    const [fundingAccountQuery, sourceAccountQuery] =
      getQueryObjects(sourceAccount);

    if (getFundingAccountsCb) {
      entity.getFundingAccounts(fundingAccountQuery, getFundingAccountsCb);
    }

    entity.getSourceAccounts(sourceAccountQuery, getSourceAccountsCb);
  }

  const loader = pipeCallbackFunctions(getSourceAccount, loadData);

  const onClose = wireframe.navigateToSourceAccountsList;

  return [
    !!sourceAccountId ? loader : () => undefined,
    (result, createChild) =>
      forkResult(
        forks,
        result,
        {
          ...result,
          wireframe,
          privileges,
          onClose,
          onDelete,
          notifications,
        },
        createChild
      ),
  ];
}
