import React from 'react';
import { arrayOf, number, object, shape, string, bool, func } from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import styled from 'astroturf/react';
import { VeloPropTypes } from '../../../VeloPropTypes';
import { ResultContainer } from '../../../ResultContainer';
import { PaymentViewStatus } from '../../PaymentViewStatus';
import { PaymentViewItem } from '../../PaymentViewItem';
import { PaymentViewDateTime } from '../../PaymentViewDateTime';
import { PaymentViewDivider } from '../../PaymentViewDivider';
import { PaymentViewHeading } from '../../PaymentViewHeading';
import { PaymentViewChannelDetails } from '../../PaymentViewChannel';
import { PaymentViewSectionSkeleton } from '../../PaymentViewSectionSkeleton';
import { PaymentViewAmount } from '../../PaymentViewAmount';
import { PaymentView } from '../../PaymentView';
import { SourceAccount } from '../SourceAccount';
import { FXRate } from '../FXRate';
import { FundingStatus } from '../FundingStatus';
import { SourceAccountStatus } from '../../../SourceAccountStatus';
import { PayeeName } from '../PayeeName';
import { VeloButton } from '../../../VeloButton';
import { getTransmissionTypeLabel } from '../../../utils';

const testId = PaymentView.root;

const Amount = ({
  sourceAmount,
  sourceCurrency,
  paymentAmount,
  paymentCurrency,
  rate,
  returnCost,
  returnReason,
  isPaymentCcyBaseCcy,
}) => (
  <>
    <PaymentViewHeading>
      <FormattedMessage defaultMessage="Amount" />
    </PaymentViewHeading>

    {/* Source amount */}
    <PaymentViewAmount
      label={<FormattedMessage defaultMessage="Source amount" />}
      value={sourceAmount}
      currency={sourceCurrency}
      data-testid={`${testId}-source-amount`}
    />

    {/* FX rate */}
    {sourceCurrency && paymentCurrency && (
      <PaymentViewItem
        label={<FormattedMessage defaultMessage="FX rate" />}
        value={
          <FXRate
            sourceCurrency={sourceCurrency}
            paymentCurrency={paymentCurrency}
            rate={rate}
            swap={isPaymentCcyBaseCcy}
          />
        }
        data-testid={`${testId}-fx-rate`}
      />
    )}

    {/* Payment amount */}
    <PaymentViewAmount
      label={<FormattedMessage defaultMessage="Payment amount" />}
      value={paymentAmount}
      currency={paymentCurrency}
      data-testid={`${testId}-payment-amount`}
    />

    {/* Return cost */}
    <PaymentViewAmount
      label={<FormattedMessage defaultMessage="Return cost" />}
      value={returnCost}
      currency={sourceCurrency}
      data-testid={`${testId}-return-cost`}
    />

    {/* Return Reason */}
    <PaymentViewItem
      label={<FormattedMessage defaultMessage="Return reason" />}
      value={returnReason}
      data-testid={`${testId}-return-reason`}
    />

    <PaymentViewDivider />
  </>
);

function hasSourceAccount({ sourceAccount }) {
  return sourceAccount && sourceAccount.result !== null;
}

function isSourceAccountDeleted({ sourceAccountDeleted }) {
  return sourceAccountDeleted?.result;
}

function isSourceUnavailable(result) {
  return (
    result.sourceAccount &&
    result.sourceAccount.result === null &&
    !result.fundingStatus
  );
}

function isNachaAvailable(result) {
  return (
    result.filenameReference ||
    result.individualIdentificationNumber ||
    result.traceNumber
  );
}

/** Render the payment details in the loading state. */
const LoadingComponent = () => (
  <>
    <PaymentViewSectionSkeleton length={3} />
    <PaymentViewSectionSkeleton length={2} heading />
    <PaymentViewSectionSkeleton length={3} heading />
    <PaymentViewSectionSkeleton length={5} heading />
  </>
);

const PaymentStatusContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
// Ensure we always get a margin - as its wrapped the &:last-child
// of PaymentViewItem, causes padding issues when !showWithdrawAction
const PaymentStatusViewItem = styled(PaymentViewItem)`
  margin-bottom: 1rem !important;
`;

const PaymentStatus = ({ status, paymentId, withdrawable, onWithdraw }) => {
  const showWithdrawAction = withdrawable && onWithdraw;
  return (
    <PaymentStatusContainer>
      <PaymentStatusViewItem
        label={<FormattedMessage defaultMessage="Payment status" />}
        value={
          <PaymentViewStatus status={status} data-testid={`${testId}-status`} />
        }
      />

      {showWithdrawAction && (
        <VeloButton
          value={<FormattedMessage defaultMessage="Withdraw" />}
          onClick={() => {
            onWithdraw(paymentId);
          }}
          data-testid={`${testId}-withdraw-button`}
        >
          <FormattedMessage defaultMessage="Withdraw" />
        </VeloButton>
      )}
    </PaymentStatusContainer>
  );
};

/** Render the payment details. */
const BaseComponent = ({
  result,
  paymentChannelRules,
  onWithdraw,
  renderAdditionalBasicPaymentDetails = () => {},
}) => {
  const intl = useIntl();
  const sourceAccountName = result?.sourceAccount?.result;

  return (
    <>
      {/* Status */}
      {result.status && <PaymentStatus {...result} onWithdraw={onWithdraw} />}
      {/* WithdrawnReason */}
      {result.withdrawnReason && (
        <PaymentViewItem
          label={<FormattedMessage defaultMessage="Withdraw reason" />}
          value={result.withdrawnReason}
          data-testid={`${testId}-payment-withdrawn-Reason`}
        />
      )}
      {/* Submitted date/time */}
      {result.submittedDateTime && (
        <PaymentViewItem
          label={<FormattedMessage defaultMessage="Submitted date/time" />}
          value={
            <PaymentViewDateTime
              value={result.submittedDateTime}
              data-testid={`${testId}-datetime`}
            />
          }
        />
      )}

      {/* Schedule Info */}
      {result.schedule && (
        <>
          <PaymentViewItem
            label={
              <FormattedMessage defaultMessage="Scheduled for date/time" />
            }
            value={
              <PaymentViewDateTime
                value={result.schedule.scheduledFor}
                data-testid={`${testId}-schedule-datetime`}
              />
            }
          />
          <PaymentViewItem
            label={<FormattedMessage defaultMessage="Scheduled by" />}
            value={result.schedule.scheduledBy}
            data-testid={`${testId}-scheduled-by`}
          />
        </>
      )}

      {/* Memo */}
      <PaymentViewItem
        label={<FormattedMessage defaultMessage="Payment memo" />}
        value={result.paymentMemo}
        data-testid={`${testId}-payment-memo`}
      />

      {/* transmissionType */}
      <PaymentViewItem
        label={<FormattedMessage defaultMessage="Transmission type" />}
        value={
          result.transmissionType ? (
            getTransmissionTypeLabel(result.transmissionType, intl)
          ) : (
            <FormattedMessage defaultMessage="Unknown" />
          )
        }
        data-testid={`${testId}-transmission-type-id`}
      />

      {renderAdditionalBasicPaymentDetails(result)}

      {/* payorPaymentId */}
      <PaymentViewItem
        label={<FormattedMessage defaultMessage="Payor payment ID" />}
        value={result.payorPaymentId}
        data-testid={`${testId}-payor-payment-id`}
      />

      {/* railsPaymentId */}
      <PaymentViewItem
        label={<FormattedMessage defaultMessage="Rails payment ID" />}
        value={result.railsPaymentId}
        data-testid={`${testId}-rails-payment-id`}
      />

      {/* rejectionReason */}
      <PaymentViewItem
        label={<FormattedMessage defaultMessage="Rejection reason" />}
        value={result.rejectionReason}
        data-testid={`${testId}-rejection-reason`}
      />
      <PaymentViewDivider />

      {/* Payment amount */}
      <Amount {...result} />

      {/** Checks for unavailable source data and skips source section UI */}
      {!isSourceUnavailable(result) && (
        <>
          <PaymentViewHeading>
            <FormattedMessage defaultMessage="Source" />
          </PaymentViewHeading>
          {/* Funding status */}
          {result.fundingStatus && (
            <PaymentViewItem
              label={<FormattedMessage defaultMessage="Funding status" />}
              value={
                <FundingStatus
                  status={result.fundingStatus}
                  use="itemContent"
                />
              }
              data-testid={`${testId}-funding-status`}
            />
          )}

          {/* Paid From */}
          <PaymentViewItem
            label={<FormattedMessage defaultMessage="Payor" />}
            value={result.paidFrom}
          />

          {/* Source account */}
          {hasSourceAccount(result) && isSourceAccountDeleted(result) ? (
            <PaymentViewItem
              label={<FormattedMessage defaultMessage="Source account" />}
              value={
                <SourceAccountStatus
                  label={sourceAccountName}
                  use="itemContent"
                  testId={`${testId}-source-account-deleted`}
                />
              }
              data-testid={`${testId}-source-account`}
            />
          ) : (
            <PaymentViewItem
              label={<FormattedMessage defaultMessage="Source account" />}
              value={<SourceAccount result={result.sourceAccount} />}
              data-testid={`${testId}-source-account`}
            />
          )}

          <PaymentViewDivider />
        </>
      )}

      <PaymentViewHeading>
        <FormattedMessage defaultMessage="Destination" />
      </PaymentViewHeading>
      {/* Payee Name */}
      {result.payeeName && result.payeeName.result !== '' && (
        <PaymentViewItem
          label={<FormattedMessage defaultMessage="Payee name" />}
          value={<PayeeName result={result.payeeName} />}
          data-testid={`${testId}-payee-name`}
        />
      )}

      {/* Remote ID */}
      <PaymentViewItem
        label={<FormattedMessage defaultMessage="Remote ID" />}
        value={result.remoteId}
        data-testid={`${testId}-remote-id`}
      />

      {/* Payment Channel Details */}
      <PaymentViewChannelDetails
        result={result}
        paymentChannelRules={paymentChannelRules}
      />

      {/** Checks for unavailable NACHA and skips section UI */}
      {isNachaAvailable(result) && (
        <>
          <PaymentViewDivider />
          <PaymentViewHeading>
            <FormattedMessage defaultMessage="NACHA" />
          </PaymentViewHeading>
          {/* Filename Reference */}
          <PaymentViewItem
            label={<FormattedMessage defaultMessage="Filename reference" />}
            value={result.filenameReference}
            data-testid={`${testId}-nacha-filename-ref`}
          />

          {/* Individual identification number */}
          <PaymentViewItem
            label={
              <FormattedMessage defaultMessage="Individual identification number" />
            }
            value={result.individualIdentificationNumber}
            data-testid={`${testId}-nacha-iin`}
          />

          {/* Trace Number */}
          <PaymentViewItem
            label={<FormattedMessage defaultMessage="Trace number" />}
            value={result.traceNumber}
            data-testid={`${testId}-nacha-trace-id`}
          />
        </>
      )}
    </>
  );
};

const Component = ResultContainer({ LoadingComponent })(BaseComponent);

BusinessPaymentViewDetails.propTypes = {
  /** The data to render. */
  result: shape({
    submittedDateTime: string,
    remoteId: string,
    status: string,
    fundingStatus: string,
    sourceAccount: VeloPropTypes.result(string),
    sourceAccountDeleted: VeloPropTypes.result(bool),
    sourceAmount: number,
    sourceCurrency: string,
    paymentAmount: number,
    paymentCurrency: string,
    rate: number,
    paymentMemo: string,
    paymentMetadata: string,
    payeeName: object,
    payorPaymentId: string,
    railsPaymentId: string,
    withdrawable: bool,
  }),
  /** The payment channel rules to render. */
  paymentChannelRules: arrayOf(shape(VeloPropTypes.PaymentChannelRuleType)),
  /** onWithdraw - action to withdraw payment, available id result.withdraw-able === true**/
  onWithdraw: func,
};

/**
 * Used to display core Payment details.
 */
function BusinessPaymentViewDetails(props) {
  return <Component {...props} />;
}

export { BusinessPaymentViewDetails };
