import {
  createListRedirectionLoader,
  PayorSearchPresenter,
} from '../../presenters';
import { PayoutTypes } from 'velo-react-components';
import { mapTablePropsWithResult, payoutSelectors } from '../../selectors';

export function PayoutsListRoutePresenter(
  wireFrame,
  entity,
  { query, payorId, scheduleStatus },
  getFilters,
  intl
) {
  const redirect = (qs) => wireFrame.navigateToPayoutsList.redirect({}, qs);

  // these are the keys we are looking to deep-link on in BOP
  const keys = payorId ? [] : ['payorId'];

  const openBankingEnabledLoader = (payorId, cb) => {
    return entity.getPayorById(payorId, (error, data) => {
      if (error) return cb(error);
      const { openBankingEnabled } = data;
      return cb(undefined, openBankingEnabled);
    });
  };

  const loader = createListRedirectionLoader(redirect, keys, query, (q, cb) => {
    return entity.getPayouts(
      { payorId, scheduleStatus, ...q },
      (error, data) => {
        if (error) return cb(error);
        const { content, ...extra } = data;
        return cb(undefined, content, extra);
      }
    );
  });

  const onCreatePayout = ({ id }) => {
    if (id === PayoutTypes.TYPE.OPEN_BANKING) {
      wireFrame.navigateToPayoutCreateOpenBanking();
    } else {
      wireFrame.navigateToPayoutCreate({
        type: id || PayoutTypes.TYPE.STANDARD,
      });
    }
  };

  const onClick = ({ payoutId, payoutType }) => {
    /**
     * The standard payout review and instruct flow does not apply to
     * OPEN_BANKING payouts.
     *
     * Also OPEN_BANKING payouts are not available on payout service `getPayout` details endpoint.
     *
     * So navigate to the payout details page immediately.
     */
    if (payoutType === PayoutTypes.PAYOUT_TYPE.OPEN_BANKING) {
      return wireFrame.navigateToPayoutDetail({ payoutId });
    }

    // retrieve the latest state if the payout to be sure of the next step
    entity.getPayout(payoutId, (error, response) => {
      if (error) {
        // This could be any error - API is sub-optimal as the Payout is removed from the Service
        wireFrame.navigateToPayoutWithdrawn({ payoutId });
      } else {
        if (payoutSelectors.selectPayoutNeedsProcessing(response.status)) {
          // only required if a backend issue means a payout is stuck
          wireFrame.navigateToPayoutProcessing({
            payoutId,
          });
        } else if (payoutSelectors.selectPayoutNeedsReview(response.status)) {
          wireFrame.navigateToPayoutReview({
            payoutId,
          });
        } else if (payoutSelectors.selectPayoutIsWithdrawn(response.status)) {
          wireFrame.navigateToPayoutWithdrawn({ payoutId });
        } else {
          wireFrame.navigateToPayoutDetail({ payoutId });
        }
      }
    });
  };

  const fetchResults = PayorSearchPresenter(entity, { pageSize: 5 });

  return [
    openBankingEnabledLoader,
    loader,
    getFilters(fetchResults, query, intl),
    (props, result, isPayorAdmin) => {
      return [
        mapTablePropsWithResult(props, result),
        {
          onClick,
        },
        {
          onCreatePayout: isPayorAdmin ? onCreatePayout : undefined,
        },
      ];
    },
  ];
}
