import {
  VeloNotification,
  SourceAccountAutoTopupForm,
} from 'velo-react-components';
import { updateAutoTopUpV3 as sourceAccountAutoTopUp } from 'velo-api/src/entities/sourceAccounts';
import { SourceAccountWithFundingAccountSearchPresenter } from './SourceAccountsViewDataPresenter';
import { createRoute } from './helpers';

function Presenter(wireframe, entity, props, notification) {
  const [loader, componentProps, createCallback] =
    SourceAccountWithFundingAccountSearchPresenter(wireframe, entity, props);
  return [
    loader,
    {
      ...componentProps,
      onSubmit: (body, cb) => {
        return entity.sourceAccountAutoTopUp(
          props.sourceAccountId,
          body,
          createCallback(cb, notification)
        );
      },
    },
  ];
}

export const SourceAccountsAutoTopupRoute = createRoute(
  Presenter,
  SourceAccountAutoTopupForm,
  {
    sourceAccountAutoTopUp,
  },
  VeloNotification.types.SOURCE_ACCOUNT_AUTO_TOPUP_CONFIG_SUCCESS
);

SourceAccountsAutoTopupRoute.displayName = 'SourceAccountsAutoTopupRoute';
