import React, { useEffect, useMemo } from 'react';
import { Switch, Route, Redirect, useHistory } from 'react-router';
import { ResetPasswordPage } from 'velo-react-components';
import {
  ForgotPasswordRoute,
  LoginRoute,
  PasswordUnlockRoute,
  ResetPasswordRoute,
  UserTokenRoutes,
  useWireframe,
} from 'velo-portal-common';

const userTokenRoutes = UserTokenRoutes.routes;

const DeepLinkTypeEnum = {
  PasswordUpdate: 'passwordUpdateVerificationLink',
  UnlockAccount: 'unlockAccountLink',
  Token: 'token',
  UserInvite: 'userInvitedVerificationLink',
};

function PublicRoutes({ initialSearch }) {
  const history = useHistory();
  const wireframe = useWireframe(history);
  const searchParams = new URLSearchParams(initialSearch);
  const userToken =
    searchParams.get(DeepLinkTypeEnum.Token) ||
    searchParams.get(DeepLinkTypeEnum.UserInvite);

  const passwordUpdateToken = searchParams.get(DeepLinkTypeEnum.PasswordUpdate);

  const matchers = useMemo(
    () => [
      {
        match: (type) => type === DeepLinkTypeEnum.PasswordUpdate,
        route: wireframe.navigateToResetPassword.path,
      },
      {
        match: (type) =>
          type === DeepLinkTypeEnum.Token ||
          type === DeepLinkTypeEnum.UserInvite,
        // /payor?token=<token> or /payor?userInvitedVerificationLink=<token>
        route: userTokenRoutes.base,
      },
      {
        match: (type) => type === DeepLinkTypeEnum.UnlockAccount,
        route: wireframe.navigateToUnlockAccount.path,
      },
      {
        match: () => true,
      },
    ],
    [
      wireframe.navigateToResetPassword.path,
      wireframe.navigateToUnlockAccount.path,
    ]
  );

  /**
   * TODO: Replace the following regexed values with a URLSearchParams instance
   */
  const [, type, token] = /\?(\w+)=([\w-]+)/.exec(window.location.search) || [];
  const { route } = matchers.find(({ match }) => match(type));

  /**
   * If there is a match for a deep link route, then redirect to that deep link
   * route.
   */
  useEffect(() => {
    if (route) {
      history.replace(route);
    }
  }, [route, history]);

  return (
    <>
      <Switch>
        <Route
          path={wireframe.navigateToResetPassword.path}
          render={(props) => (
            <ResetPasswordRoute
              {...props}
              type={type}
              token={passwordUpdateToken}
              mode={ResetPasswordPage.PasswordUpdateMode.Reset}
            />
          )}
        />

        <Route
          path={userTokenRoutes.base}
          render={(props) => <UserTokenRoutes {...props} token={userToken} />}
        />

        <Route
          path={wireframe.navigateToUnlockAccount.path}
          render={(props) => (
            <PasswordUnlockRoute {...props} type={type} token={token} />
          )}
        />

        <Route
          path={wireframe.navigateToLogin.path}
          render={(props) => <LoginRoute {...props} />}
        />

        <Route
          path={wireframe.navigateToForgotPassword.path}
          render={(props) => <ForgotPasswordRoute {...props} />}
        />

        <Redirect to={wireframe.navigateToLogin.path} />
      </Switch>
    </>
  );
}

PublicRoutes.DeepLinkTypeEnum = DeepLinkTypeEnum;

export { PublicRoutes };
