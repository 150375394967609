import { useMemo } from 'react';
import { contextCurrenciesSelector, useAppContext } from '../../context';

function useCurrencies() {
  const context = useAppContext();
  const currencies = contextCurrenciesSelector(context);

  return useMemo(() => {
    return {
      currencies,
      currencyLabelList: currencies.map(({ currency }) => currency),
      defaultCurrency: 'USD',
    };
  }, [currencies]);
}

export { useCurrencies };
