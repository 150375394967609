import { SendError } from 'velo-api/src/send';

export function PayoutProcessingPresenter(
  wireframe,
  entity,
  { payoutId },
  { failureProcessing }
) {
  function exitProcessing(error) {
    if (error) {
      if (SendError.isNotFoundError(error)) {
        // Status has updated since retrieved (WITHDRAWN)
        // Redirect to navigateToPayoutDetail which handles and renders this state
        wireframe.navigateToPayoutWithdrawn.redirect({ payoutId });
      } else {
        // General error handling
        wireframe.navigateToPayoutsList.redirect();
        wireframe.sendNote(failureProcessing);
      }
    } else {
      wireframe.navigateToPayoutsList.redirect();
    }
  }

  function getPayoutSummary(payoutId, cb) {
    entity.getPayoutSummary(payoutId, (error, result) => {
      if (error) {
        exitProcessing(error);
      } else {
        cb(undefined, result);
      }
    });
  }

  const goToPayoutReview = () => {
    wireframe.navigateToPayoutReview.redirect({ payoutId });
  };

  const goToPayoutDetail = () => {
    wireframe.navigateToPayoutDetail.redirect({ payoutId });
  };

  return {
    getPayoutSummary,
    exitProcessing,
    goToPayoutReview,
    goToPayoutDetail,
  };
}
