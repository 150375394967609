import { createSelector } from 'reselect';

function getUniqueKeys(resultMap, errorMap) {
  const keys = [...Object.keys(resultMap), ...Object.keys(errorMap)];
  return keys.reduce((xs, key) => {
    if (!xs.includes(key)) xs.push(key);
    return xs;
  }, []);
}

/**
 * Creates a selector that will take the two input selectors and produce another
 * which is a map of "result" objects, where each value has result and/or error
 * properties depending on whether they are found in the input selectors.
 */
function createResultMapSelector(resultMapSelector, errorMapSelector) {
  return createSelector(
    resultMapSelector,
    errorMapSelector,
    (resultMap, errorMap) => {
      const keys = getUniqueKeys(resultMap, errorMap);
      return keys.reduce((map, key) => {
        const result = resultMap[key];
        const error = errorMap[key];
        return { ...map, [key]: { result, error } };
      }, {});
    }
  );
}

const emptyResult = { error: undefined, result: undefined };

/**
 * Utility HOF for mapping over a "result" object, which calls either
 * mapResult or mapError depending on which is present. The returned function
 * may also be passed additional arguments to pass to the mapping functions.
 */
function withResult(mapResult, mapError = (error) => error) {
  return function (resultType, ...rest) {
    if (!resultType) return emptyResult;
    const { result, error } = resultType;
    if (error) {
      return {
        result,
        error: mapError(error, ...rest),
      };
    } else if (result !== undefined) {
      return {
        result: mapResult(result, ...rest),
        error,
      };
    }
    // ensure that the same object is returned for "pure" components
    return emptyResult;
  };
}

const isEmpty = (value) =>
  value === null || (typeof value.length !== 'undefined' && value.length === 0);

/**
 * Utility function for taking a result and running a given function based on
 * the state of the passed result object. The result of this invoked function is
 * returned.
 */
function forkResult(
  {
    error = identitySelector,
    value = identitySelector,
    empty = identitySelector,
    none = identitySelector,
  },
  resultType,
  ...rest
) {
  let result;

  if (resultType.result || resultType.result === null) {
    result = resultType.result;
  } else {
    result = resultType.data;
  }

  if (resultType.error) {
    return error(resultType.error, ...rest);
  }

  if (result === undefined) {
    return none(...rest);
  }

  if (isEmpty(result)) {
    return empty(...rest);
  }

  return value(result, ...rest);
}

/**
 * Utility function for combining two result like objects using a combiner
 * function if they both contain a result value, otherwise cascades errors or
 * loading state. Note that this does not handle 'empty' states. Inspired by the
 * scala 'cats' implementation, but with different param ordering.
 * @see https://github.com/typelevel/cats/blob/v1.6.0/core/src/main/scala/cats/syntax/either.scala#L214
 */
function combine(combiner, leftResult, rightResult) {
  if (leftResult.error) {
    return leftResult;
  }

  if (rightResult.error) {
    return rightResult;
  }

  if (leftResult.result === undefined || isEmpty(leftResult.result)) {
    return leftResult;
  }

  if (rightResult.result === undefined || isEmpty(leftResult.result)) {
    return rightResult;
  }

  return {
    result: combiner(leftResult.result, rightResult.result),
  };
}

/**
 * Utility for turning a map of result objects into one result, that will only
 * be resolved when all results are resolved.
 */
function flattenResults(resultsMap) {
  return Object.keys(resultsMap).reduce(
    (acc, key) => {
      // if already an error or set as loading bail
      if (acc.error || acc.result === undefined) {
        return acc;
      }

      const resultType = resultsMap[key];
      // if there is no value, assume loading
      if (!resultType) {
        return { result: undefined };
      }
      // if an error exit here (with no result property)
      if (resultType.error) {
        return { error: resultType.error };
      }
      // if no result yet, exit here (loading)
      if (resultType.result === undefined) {
        return { result: undefined };
      }

      return { result: { ...acc.result, [key]: resultType.result } };
    },
    { result: {} }
  );
}

const formatPayorName = withResult(
  ({ dbaName, payorName }) => dbaName || payorName
);

const getErrorText = (error) =>
  error.errors && error.errors.length && error.errors[0].errorMessage
    ? error.errors[0].errorMessage
    : error.message;

/**
 * Used for formatting a Velo API error or an instance of an Error object into
 * a string error message. The formatting is safe and will default to undefined
 * when there is no error message.
 *
 * @param {Object} error
 */
const formatError = (error) => (error ? getErrorText(error) : undefined);

const formatCallbackErrorArg =
  (cb) =>
  (error, ...rest) =>
    cb(formatError(error), ...rest);

const getErrorData = (error) =>
  error && error.errors?.length ? error.errors[0].errorData : undefined;

const withErrorDataHandler =
  (cb, { onErrorData }) =>
  (error, ...rest) => {
    const errorData = getErrorData(error);
    return errorData ? onErrorData(errorData, cb) : cb(error, ...rest);
  };

/**
 * Constructs and error-first callback that short circuits on error by calling
 * the `onError` handler with error, otherwise calling the `onData` handler with
 * the remaining arguments.
 * This is a time saving "macro" to cut down on repetition.
 *
 * @param {function(D)} onData Handler for just the data and remaining arguments
 * @param {Function} onError Handler for just the error
 * @returns {ErrorFirstCallback<D>}
 * @template D
 */
function createEarlyExitCallback(onData, onError) {
  return (error, ...rest) => (error ? onError(error) : onData(...rest));
}

/**
 * Check a Velo API error for a specified reason code.
 * If the code is present, return it, else return the
 * actual error message.
 */
const formatErrorCode = (error, code) =>
  error
    ? error.errors && error.errors.length
      ? error.errors[0].reasonCode === code
        ? code
        : error.errors[0].errorMessage
      : error.message
    : undefined;

/**
 * Used formatting the Velo API errors array.
 *
 * @param {Array} errors The errors array containing error messages
 * @param {Number} index The index of the item to return (defaults to first item)
 */
const formatAPIError = (errors, index = 0) =>
  errors && errors.length ? errors[index].errorMessage : undefined;

const identitySelector = (x) => x;

function replaceEmptyStringsWithNull(kvMap) {
  return Object.entries(kvMap).reduce(
    (acc, [k, v]) => ({ ...acc, [k]: v === '' ? null : v }),
    {}
  );
}

function replaceEmptyValuesWithEmptyStrings(kvMap) {
  return Object.entries(kvMap).reduce(
    (acc, [k, v]) => ({ ...acc, [k]: v || '' }),
    {}
  );
}

/**
 * Parse the `content` and `page` from a paginated API response.
 * Returns an object containing a `content` result type and a `page`
 * containing the current page and total pages.
 */
const getPagedAPIResponse = (result) => {
  const content = withResult(
    ({ content }) => content,
    (error) => formatError(error)
  )(result);
  const page = withResult(({ page }) => page)(result);
  const pageResult = ({ page, totalPages }) => ({ page, totalPages });
  const emptyPageResult = () => undefined;
  return {
    content,
    page: forkResult(
      {
        error: emptyPageResult,
        none: emptyPageResult,
        empty: pageResult,
        value: pageResult,
      },
      page,
      {}
    ),
  };
};

const isTruthy = (x) => !!x;
const createCancelAllClosure = (cancellationFns) => () =>
  cancellationFns.filter(isTruthy).forEach((cancel) => cancel());

const reduceCallbackFunctions = (outerFn, innerFn) => (param, cb) => {
  // may be called with just a callback param
  const singleArg = cb === undefined;
  const next = singleArg ? param : cb;
  const cancellationTokens = [];

  // create a new callback handler
  const handler = (error, result) => {
    // if there is no error
    if (!error) {
      cancellationTokens.push(
        // call the outer function with the result and the next callback
        outerFn(result, next)
      );
    } else {
      // otherwise just call the callback to indicate exit
      next(error, result);
    }
  };

  cancellationTokens.push(
    // that calls the innerFn with the modified params
    innerFn(...(singleArg ? [handler] : [param, handler]))
  );

  return createCancelAllClosure(cancellationTokens);
};

function pipeCallbackFunctions(...fns) {
  return fns.reduceRight(reduceCallbackFunctions);
}

/**
 * @callback CancellationFunction
 * @returns {void}
 */

/**
 * @callback ErrorFirstCallback A node-style callback
 * @param {Object=} error The error if present
 * @param {D=} data The data if present
 * @return {CancellationFunction}
 * @template D
 */

/**
 * Utility to create a number of callbacks that will call the main callback
 * when they have resolved or rejected.
 *
 * @param {number} count
 * @param {ErrorFirstCallback<D>} callback
 * @returns {ErrorFirstCallback<*>[]}
 *
 * @template D
 */
function combineCallbacks(count, callback) {
  const states = new Array(count).fill().map(() => ({
    result: undefined,
  }));
  const hasError = () => states.some(({ error }) => !!error);
  const getResults = () =>
    states.map(({ result }) => result).filter((x) => !!x);

  return states.map((state) => {
    return (error, ...rest) => {
      if (!hasError()) {
        if (error) {
          state.error = error;
          callback(error);
        } else {
          state.result = rest;
          const results = getResults();
          if (results.length === count) {
            callback(undefined, ...results);
          }
        }
      }
    };
  });
}

/**
 * @callback DataJoin
 * @param {D[]} content
 * @param {ErrorFirstCallback<Array.<D>>} cb
 * @return {CancellationFunction}
 * @template D
 */

/**
 * Create a data-joining function
 *
 * @param {function(D[]):object[]} extractRequests
 * @param {function():CancellationFunction} makeRequest
 * @returns {DataJoin<D>}
 * @template D
 */
function createDataJoinCallbackFunction(extractRequests, makeRequest) {
  const inFlightPlaceholder = 'inflight';
  // state is held locally in this closure
  const resultsByKey = {};

  function getNewRequests(data) {
    return extractRequests(data).reduce((acc, request) => {
      if (resultsByKey[request.key] === undefined) {
        // mark as in flight
        resultsByKey[request.key] = inFlightPlaceholder;
        return [...acc, request];
      }
      return acc;
    }, []);
  }

  function getCompletedRequests() {
    // reduce the object to only include items that have resolved
    return Object.entries(resultsByKey).reduce(
      ([acc, count], [k, v]) => {
        if (v && v !== inFlightPlaceholder) {
          return [{ ...acc, [k]: v }, count];
        }
        return [acc, count + 1];
      },
      [{}, 0]
    );
  }

  function requestToCancellable({ key, params }, cb) {
    const cancelRequest = makeRequest(...params, (error, result) => {
      // update our result set
      resultsByKey[key] = { error, result };
      // trigger a state update by setting the whole state
      cb(...getCompletedRequests());
    });

    return () => {
      cancelRequest();
      // if the response is still inFlight, remove it so it can be re-requested
      if (resultsByKey[key] === inFlightPlaceholder) {
        resultsByKey[key] = undefined;
      }
    };
  }

  return (data, cb) => {
    const newRequests = getNewRequests(data);
    const [previousData, inFlightCount] = getCompletedRequests();
    /**
     * It is valid to callback immediately if:
     * 1. There is previous data that is cached by another call
     * 2. If there are no new inflight calls, ensure that the callback is called
     * at least once.
     */
    if (Object.keys(previousData).length || inFlightCount === 0) {
      cb(previousData, inFlightCount);
    }
    // make new requests
    const cancellationFns = newRequests.map((request) =>
      requestToCancellable(request, cb)
    );

    // if the effect is cancelled, it cancels all the other tokens
    return createCancelAllClosure(cancellationFns);
  };
}

/**
 * @callback AwaitDataJoin
 * @param {ErrorFirstCallback<Array<D>>} cb An error-first callback function to
 * call
 * @param {D[]} content An array of data entries to data-join on
 * @returns {CancellationFunction}
 * @template D
 */

/**
 * @callback MapContentEntry A function for populating an individual entry for a
 * data-join
 * @param {Object} resultsById A hashmap of results, indexed by the original
 * request key
 * @param {D[]} content An array of data entries that have already been
 * populated
 * @param {D} value The current value to process
 * @return {[object, D[]]} A tuple of error and data
 * @template D
 */

/**
 * Takes a data-join function and a mapper for each content entry and will await
 * for all of the inFlight requests to finish (either in success or failure)
 * before calling the mapper for each content entry.
 *
 *
 * @param {DataJoin<D>} dataJoin A data-join function
 * @see createDataJoinCallbackFunction
 * @param {MapContentEntry<D>} mapContentEntry A mapper for each data item
 * @param {boolean} enableDataJoin Whether to enable/disable the join
 * @return {AwaitDataJoin<D>} A function will perform the data-join.
 * @template D
 */
function createAwaitAllDataJoinCallback(
  dataJoin,
  mapContentEntry,
  enableDataJoin
) {
  return (cb, content, ...rest) => {
    /**
     * If the content is empty, or the data-join is not enabled, then
     * short-circuit and just call the callback
     */
    if (!enableDataJoin || content.length === 0) {
      return cb(undefined, content, ...rest);
    }

    return dataJoin(
      content,
      /** the merging function, called for each load */
      (resultsById, remainingCount) => {
        /** the end callback is only called if there are no more inFlight requests */
        if (remainingCount === 0) {
          /**
           * Loop over the content joining the data found in resultsById,
           * short-circuiting on error if one is found.
           * The value that is accumulated is an array `[error, content]`
           * which represents the final arguments to a callback
           */
          const [error, data] = content.reduce(
            ([err, populatedContent], value) => {
              /** early exit if error */
              if (err) return [err];
              return mapContentEntry(resultsById, populatedContent, value);
            },
            // the initial state for the reducer
            [
              /** initially no error */
              undefined,
              /** initially empty content, this will be filled with the content */
              [],
            ]
          );
          return cb(error, data, ...rest);
        }
      }
    );
  };
}

export {
  createDataJoinCallbackFunction,
  createAwaitAllDataJoinCallback,
  createEarlyExitCallback,
  pipeCallbackFunctions,
  createCancelAllClosure,
  combineCallbacks,
  identitySelector,
  forkResult,
  replaceEmptyStringsWithNull,
  replaceEmptyValuesWithEmptyStrings,
  createResultMapSelector,
  withResult,
  combine,
  emptyResult,
  flattenResults,
  formatPayorName,
  formatError,
  formatCallbackErrorArg,
  formatAPIError,
  getPagedAPIResponse,
  formatErrorCode,
  withErrorDataHandler,
};
