import React from 'react';
import { node, object, oneOf, oneOfType, string } from 'prop-types';
import classnames from 'classnames';
import styled from 'astroturf/react';
import { VeloIcon } from '../VeloIcon';
import { VeloTypography } from '../VeloTypography';
import { VeloSkeleton } from '../VeloSkeleton';
import types from '@design-system/tokens/components/status-indicators/json/types.json';
import '@design-system/tokens/components/status-indicators/scss/component-styles.scss';
import statusClassNames from '@design-system/tokens/components/status-indicators/js/component-dictionary.enum';

// Create an object of status values
VeloStatusIndicator.types = Object.keys(types).reduce(
  (acc, cur) => ({ ...acc, [cur.toUpperCase()]: cur }),
  {}
);

VeloStatusIndicator.propTypes = {
  /** The indicator type. */
  type: oneOf(Object.keys(types)).isRequired,
  /** The icon label. */
  label: node.isRequired,
  /** Custom icon. */
  icon: oneOfType([string, object]),
  /** Test ID. */
  'data-testid': string,
};

/**
 * Render an icon with a label.
 *
 * Specify a suitable `type` to ensure the correct icon
 * and label typography is used.
 *
 * For custom indicators, you can override the icon and
 * a limited number of typography styles via the `icon`
 * and `use` props respectively.
 *
 * To render a status indicator in the loading state use
 * `VeloStatusIndicator.Loading`.
 */
function VeloStatusIndicator({
  type,
  label,
  icon,
  use,
  'data-testid': testId,
}) {
  return (
    <div className="ds-status-indicator" data-testid={testId}>
      <VeloIcon
        className={classnames(
          statusClassNames.ICON,
          `${statusClassNames.ICON}--${type}`
        )}
        icon={icon || types[type].icon}
      />
      <VeloTypography
        use={types[type].textToken}
        className={statusClassNames.LABEL}
      >
        {label}
      </VeloTypography>
    </div>
  );
}

const LoadingContainer = styled('div')`
  display: flex;
  align-items: center;
`;

const LoadingIcon = styled(VeloSkeleton)`
  height: 20px;
  width: 20px;
  margin-right: 6px;
`;

Loading.propTypes = {
  /**
   * Custom CSS class(es).
   * Use this to override the width.
   */
  className: string,
  /** Test ID. */
  'data-testid': string,
};

/**
 * Render a status indicator in the loading state.
 */
export function Loading(props) {
  return (
    <LoadingContainer {...props}>
      <LoadingIcon />
      <VeloSkeleton />
    </LoadingContainer>
  );
}

VeloStatusIndicator.Loading = Loading;

export { VeloStatusIndicator };
