import React, { useContext, useState, useMemo } from 'react';
import deepMerge from 'deepmerge';
import { brandingTokens } from './brandingTokens';

const Context = React.createContext({ brandingTokens, theme: null });
const VeloThemeConsumer = Context.Consumer;

const defaultBrandingTokens = brandingTokens;

function VeloThemeProvider({ theme: initialState, brandingTokens, children }) {
  const [theme, onThemeChange] = useState(initialState);
  const state = useMemo(
    () => ({
      theme: theme || null,
      // tokens are merged over the top of any default branding
      brandingTokens: deepMerge(defaultBrandingTokens, brandingTokens || {}),
      onThemeChange,
    }),
    [theme, brandingTokens]
  );

  return <Context.Provider value={state}>{children}</Context.Provider>;
}
function useVeloThemeContext() {
  const { theme, onThemeChange } = useContext(Context);
  return [theme, onThemeChange];
}

function useBrandingTokens() {
  const { brandingTokens } = useContext(Context);
  return brandingTokens;
}

const VeloThemeContext = {
  Consumer: VeloThemeConsumer,
  Provider: VeloThemeProvider,
  useVeloThemeContext,
  useBrandingTokens,
};

export { VeloThemeContext };
