import React from 'react';
import { bool, func, node, number, oneOf } from 'prop-types';
import classnames from 'classnames';
import styled from 'astroturf/react';
import { VeloIcon } from '../VeloIcon';
import { VeloNotification } from '../VeloNotification';
import '@material/snackbar/dist/mdc.snackbar.css';
import '@material/button/dist/mdc.button.css';

const Snackbar = styled('div')`
  @import 'velo-variables';
  @import '@material/snackbar/mixins';

  @include mdc-snackbar-max-width(400px);
  z-index: $velo-snackbar-notifications;
`;

const Label = styled('div')`
  font-family: var(--ds-typography-font-family);
  display: flex;
  align-items: center;
`;

const Action = styled('button')`
  @import 'velo-variables';
  @import '@material/button/mixins';

  @include mdc-button-ink-color(velo-color('token-color-text-inverse'));
  font-family: var(--ds-typography-font-family);
`;

const Icon = styled(VeloIcon)`
  @import 'velo-variables';

  @mixin icon($color) {
    color: $color;
    background-image: radial-gradient(
      at center,
      velo-color('token-color-system-page-background') 50%,
      transparent 40%
    );
    font-size: 30px;
    margin-right: 0.5rem;
  }

  &.type-success {
    @include icon(velo-color('token-color-system-success-default'));
  }

  &.type-failure {
    @include icon(velo-color('token-color-system-error-default'));
  }
`;

Icon.propTypes = {
  type: oneOf([
    VeloNotification.notificationType.SUCCESS,
    VeloNotification.notificationType.FAILURE,
  ]).isRequired,
};

const Interval = {
  OPENING: 150,
  CLOSING: 75,
};

const icons = {
  [VeloNotification.notificationType.SUCCESS]: 'check_circle',
  [VeloNotification.notificationType.FAILURE]: 'cancel',
};

const root = 'mdc-snackbar';

const cssClasses = {
  STACKED: `${root}--stacked`,
  OPEN: `${root}--open`,
  OPENING: `${root}--opening`,
  CLOSING: `${root}--closing`,
  SURFACE: `${root}__surface`,
  LABEL: `${root}__label`,
  ACTIONS: `${root}__actions`,
  ACTION: `${root}__action`,
};

/**
 * Velo Notification Snackbar.
 *
 * This is a Velo version of the MDC Snackbar which
 * adds support for a status icon.
 */
class VeloSnackbar extends React.Component {
  static propTypes = {
    /** Open the snackbar when the component mounts. */
    open: bool,
    /** The snackbar type. */
    type: oneOf(Object.values(VeloNotification.notificationType)),
    /** The message to display. */
    message: node,
    /** The time a message should be displayed. */
    timeout: number,
    /** Used to show the action button below the message. */
    stacked: bool,
    /** The text of the action button. */
    actionText: node,
    /** Called when the action button is clicked. */
    actionHandler: func,
  };

  static defaultProps = {
    timeout: 2750,
  };

  static cssClasses = cssClasses;

  state = {
    open: false,
    opening: false,
    closing: false,
  };

  timers = {
    initial: null,
    opening: null,
    hide: null,
    closing: null,
  };

  open() {
    // This code mirrors the Google snackbar JS
    this.setState({ opening: true }, () => {
      this.timers.initial = setTimeout(() => {
        this.setState({ open: true });
        // Remove opening animation
        this.timers.opening = setTimeout(() => {
          this.setState({ opening: false });
        }, Interval.OPENING);
      }, 0);

      // Hide snackbar
      this.timers.hide = setTimeout(() => {
        this.setState({ open: false, closing: true }, () => {
          // Remove the closing animation
          this.timers.closing = setTimeout(() => {
            this.setState({ closing: false });
          }, Interval.CLOSING);
        });
      }, this.props.timeout);
    });
  }

  componentDidMount() {
    if (this.props.open) {
      this.open();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timers.initial);
    clearTimeout(this.timers.opening);
    clearTimeout(this.timers.hide);
    clearTimeout(this.timers.closing);
  }

  render() {
    const {
      className,
      stacked,
      type,
      message,
      actionText,
      actionHandler,
      ...other
    } = this.props;

    return (
      <Snackbar
        className={classnames(className, root, {
          [cssClasses.STACKED]: stacked,
          [cssClasses.OPEN]: this.state.open,
          [cssClasses.OPENING]: this.state.opening,
          [cssClasses.CLOSING]: this.state.closing,
        })}
        {...other}
      >
        <div className={cssClasses.SURFACE}>
          <div className={cssClasses.LABEL} role="status">
            <Label>
              {type && <Icon type={type} icon={icons[type]} />}
              <div>{message}</div>
            </Label>
          </div>

          {actionText && (
            <div className={cssClasses.ACTIONS}>
              <Action
                type="button"
                className={classnames('mdc-button', cssClasses.ACTION)}
                onClick={actionHandler}
              >
                {actionText}
              </Action>
            </div>
          )}
        </div>
      </Snackbar>
    );
  }
}

export { VeloSnackbar };
