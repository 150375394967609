import React from 'react';
import { injectIntl } from 'react-intl';
import { arrayOf, func, string, bool } from 'prop-types';
import styled from 'astroturf/react';
import { VeloCard } from '../VeloCard';
import { VeloTextField } from '../VeloTextField';
import { VeloList } from '../VeloList';
import { keyCodes, toNumber, stripNonNumeric } from 'velo-data';
import { FilterScrim } from './FilterScrim';
import FilterPropTypes from './filterPropTypes';

const TEXT_FIELD_HEIGHT = '56px';

const Container = styled('div')`
  $text-field-width: 20rem;
  $text-field-width-compact: 7rem;

  position: relative;
  min-width: $text-field-width;

  &.compactWidth {
    min-width: $text-field-width-compact;
  }
`;

Container.propTypes = {
  compactWidth: bool,
};

const Fields = styled(VeloCard)`
  @import 'velo-variables';

  position: absolute;
  top: ${TEXT_FIELD_HEIGHT};
  left: 0px;
  right: 0px;
  z-index: $velo-full-screen-index-default;
`;

const List = styled(VeloList)`
  padding: 0;
`;

const Field = styled(VeloList.Item)`
  cursor: pointer;
`;

const Text = styled(VeloList.ItemText)`
  display: flex;
  width: 100%;
`;

const Label = styled('div')`
  flex-basis: 50%;
`;

const Value = styled('div')`
  flex-basis: 50%;
  text-align: right;
`;

const Divider = styled(VeloList.Divider)`
  margin: 0 1rem;
`;

const prefix = 'velo-filter';

const TestIds = {
  BUTTON: `${prefix}-button`,
  ROOT: `${prefix}-text-field`,
};

// Key state update handlers
const keyHandlers = {
  [keyCodes.ESCAPE]: () => () => ({ showList: false }),
  [keyCodes.UP_ARROW]: (itemCount) => (state) => ({
    selectedField:
      state.selectedField === 0 ? itemCount - 1 : state.selectedField - 1,
  }),
  [keyCodes.DOWN_ARROW]: (itemCount) => (state) => ({
    selectedField:
      state.selectedField === itemCount - 1 ? 0 : state.selectedField + 1,
  }),
};

/**
 * Text filter component that can be used with Filter. Matches user
 * input to available field filters.
 */
class FilterTextField extends React.Component {
  static propTypes = {
    className: string,
    fields: arrayOf(FilterPropTypes.field).isRequired,
    onTogglePopup: func.isRequired,
    onAddFilter: func.isRequired,
  };

  state = {
    value: '',
    showList: false,
    selectedField: 0,
  };

  static testIds = TestIds;

  // Check the validity of a field match
  checkValidity = (type) => {
    const { value } = this.state;
    switch (type) {
      case FilterPropTypes.types.dateRange:
      case FilterPropTypes.types.currencyRange:
      case FilterPropTypes.types.entityIdLookup:
        return false;
      case FilterPropTypes.types.number:
        // Anything vaguely numeric
        return !isNaN(toNumber(value));
      case FilterPropTypes.types.date:
        // yyyy-mm-dd
        return /^\d{4}-\d{2}-\d{2}$/.test(value);
      default:
        return true;
    }
  };

  // Return a list of field matches for the current value.
  // For "list" types we look for a partial match on each list
  // option and can return multiple values.
  matches = () => {
    const { value } = this.state;
    const { fields, intl } = this.props;

    return value
      ? fields.reduce((acc, { name, type, options }) => {
          if (
            type === FilterPropTypes.types.list ||
            type === FilterPropTypes.types.listCountry ||
            type === FilterPropTypes.types.listCurrency
          ) {
            const listOptions =
              typeof options === 'function' ? options(intl) : options;
            // Do we have any partial matches in the list?
            const partials = listOptions.filter(
              ({ label }) =>
                label.toLowerCase().indexOf(value.toLowerCase()) === 0
            );
            // If we found any matches then add them to the accumulator
            return partials.length > 0
              ? [
                  ...acc,
                  ...partials.map(({ label, value }) => ({
                    name,
                    label,
                    value,
                  })),
                ]
              : acc;
          }

          if (type === FilterPropTypes.types.currencyRange) {
            // digits and dots
            if (this.checkValidity(FilterPropTypes.types.number)) {
              const n = [stripNonNumeric(value), stripNonNumeric(value)];
              return [...acc, { name, value: n }];
            }
          }

          if (type === FilterPropTypes.types.dateRange) {
            // is a date
            if (this.checkValidity(FilterPropTypes.types.date)) {
              const n = [value, value];
              return [...acc, { name, value: n }];
            }
          }

          if (type === FilterPropTypes.types.number) {
            if (this.checkValidity(type)) {
              return [...acc, { name, value: stripNonNumeric(value) }];
            }
          }

          // We only display valid matches. Each field type
          // may have a custom regex assigned.
          return this.checkValidity(type) ? [...acc, { name, value }] : acc;
        }, [])
      : [];
  };

  // Return the label to use for a field
  label = (name) =>
    this.props.fields.find((field) => field.name === name).label;

  // Add the current selection as a new filter
  addFilter = (index) => {
    const match = this.matches()[index];
    match && this.props.onAddFilter(match);
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.addFilter(this.state.selectedField);
    this.setState(() => ({
      value: '',
      selectedField: 0,
      showList: false,
    }));
  };

  handleChange = (event) => {
    const { value } = event.target;
    this.setState((state) => ({
      value,
      selectedField: value ? state.selectedField : 0,
      showList: value ? true : false,
    }));
  };

  handleKeyDown = (event) => {
    if (this.state.showList) {
      const handler = keyHandlers[event.keyCode];
      if (handler) {
        event.preventDefault();
        this.setState(handler(this.matches().length));
      }
    }
  };

  handleListClick = (event) => {
    const index = Number(event.currentTarget.getAttribute('data-index'));
    this.addFilter(index);
    this.setState(() => ({
      value: '',
      selectedField: 0,
      showList: false,
    }));
  };

  handleBackgroundClick = () => {
    this.setState({ showList: false });
  };

  renderMatchValue = (v) => {
    return v instanceof Array ? v.join(' - ') : v;
  };

  renderList() {
    if (this.state.showList) {
      const items = this.matches();
      if (items.length > 0) {
        return (
          <Fields flat={false} fullScreen={false} outlined>
            <List data-testid="velo-filter-text-field-list">
              {items.map((match, index, arr) => (
                <React.Fragment key={index}>
                  <Field
                    selected={index === this.state.selectedField}
                    data-index={index}
                    onClick={this.handleListClick}
                    // Disable ripples to fix an MDC timing bug
                    ripple={false}
                  >
                    <Text>
                      <Label>
                        <span>{this.label(match.name)}</span>:
                      </Label>
                      <Value>
                        {this.renderMatchValue(match.label || match.value)}
                      </Value>
                    </Text>
                  </Field>
                  {index < arr.length - 1 && <Divider />}
                </React.Fragment>
              ))}
            </List>
          </Fields>
        );
      }
    }
    return null;
  }

  render() {
    const { className, intl, onTogglePopup, compactWidth } = this.props;
    const { showList, value } = this.state;

    const placeholder = intl.formatMessage({
      defaultMessage: 'Filter',
    });

    return (
      <Container compactWidth={compactWidth} className={className}>
        {/* Used to capture clicks so the filter list can be hidden */}
        {showList && (
          <FilterScrim
            onClick={this.handleBackgroundClick}
            data-testid="velo-filter-text-field-scrim"
          />
        )}

        <form onSubmit={this.handleSubmit}>
          <VeloTextField
            type="search"
            outlined
            label=""
            placeholder={placeholder}
            aria-label={placeholder}
            value={value}
            onChange={this.handleChange}
            trailingIcon={{
              tabIndex: 0,
              icon: 'arrow_drop_down',
              onClick: onTogglePopup,
              'data-testid': TestIds.BUTTON,
            }}
            onKeyDown={this.handleKeyDown}
            data-testid={TestIds.ROOT}
          />
        </form>

        {/* Display the available filters */}
        {this.renderList()}
      </Container>
    );
  }
}

// Inject intl prop into the FilterTextField
const FilterTextFieldIntl = injectIntl(FilterTextField);

export { FilterTextFieldIntl as FilterTextField };
