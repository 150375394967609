import React from 'react';
import { bool, func, number } from 'prop-types';
import { Content } from '../Content';
import { VeloTabbedView } from '../VeloTabbedView';

const Tabs = {
  APPEARANCE: 0,
  ADVANCED: 1,
};

SettingsPage.propTypes = {
  /** onClose handler */
  onClose: func.isRequired,
  /** Active tab index of the form */
  activeTabIndex: number.isRequired,
  /** onActive handler of the form */
  onActivate: func.isRequired,
  /** Is it a payee app */
  payeeApp: bool,
};

function SettingsPage({ onClose, payeeApp, ...props }) {
  const tabOptions = payeeApp ? ['Appearance', 'Advanced'] : ['Appearance'];

  return (
    <Content>
      <VeloTabbedView
        title="Settings"
        tabs={tabOptions}
        onClose={onClose}
        {...props}
      />
    </Content>
  );
}

SettingsPage.tabs = Tabs;

export { SettingsPage };
