import React, { useState } from 'react';
import { Route, useHistory, useParams } from 'react-router';
import { useIntl } from 'react-intl';
import {
  contextCountriesSelector,
  contextPaymentChannelRulesSelector,
  contextPayeeCountryRulesSelector,
  useAppContext,
  useWireframe,
} from 'velo-portal-common';
import {
  v4CreatePayee as createPayee,
  queryBatchStatusV4 as batchStatus,
} from 'velo-api/src/entities/payees';
import { useAPIMethods } from 'velo-api-react-hooks';
import { AddPayee, VeloNotification } from 'velo-react-components';
import { formatCallbackErrorArg } from 'velo-react-components/src/utils/errorLocalisation';
import { PayorCreatePayeePresenter } from './PayorCreatePayeePresenter';
import { usePayeeUploadStatusPoller } from '../../hooks';

const entitySpec = {
  createPayee,
  batchStatus,
};

function ProgressIndicator({ onComplete, getBatchStatus }) {
  const { batchId, batchCount } = useParams();
  const { batch } = usePayeeUploadStatusPoller(
    batchId,
    batchCount,
    getBatchStatus,
    onComplete
  );
  const progress =
    batch && batch.pendingCount > 0
      ? 1 - (batch.pendingCount / batchCount).toPrecision(1)
      : 0;

  return <AddPayee.Processing progress={progress} />;
}

function mergeFailuresAndRejections(failures, rejections) {
  const error = [...failures, ...rejections];
  return error.length ? error : undefined;
}

function getErrorProps(isSubmitting, apiError, failures, rejections) {
  if (isSubmitting) {
    return {};
  } else if (apiError) {
    return { error: apiError };
  } else {
    return {
      error: mergeFailuresAndRejections(failures, rejections),
      isBatchError: true,
    };
  }
}

export function PayorCreatePayeeRoute({ payorId, rootPath }) {
  const intl = useIntl();
  const history = useHistory();
  const wireframe = useWireframe(history, rootPath);
  const entity = useAPIMethods(entitySpec);

  // Types of errors:
  // 1. API error
  // 2. Read CSV error - this is a different pre processing error from submission
  //    errors as part of a successful upload, unless all are rejected at read time....urgh
  // 3. Batch rejections (duplicate id, email etc...)
  // 2 and 3 are the same outcome and contextually identical to the consumer
  // but come from different APIs..
  // Intentionally verbose states to make it more readable and highlight the above
  const [readFailures, setReadFailures] = useState([]);
  const [failures, setFailures] = useState([]);
  const [apiError, setApiError] = useState(undefined);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const context = useAppContext();
  const countries = contextCountriesSelector(context);
  const rules = contextPaymentChannelRulesSelector(context).bank;
  const payeeCountryRules = contextPayeeCountryRulesSelector(context);
  // Filter out countries with no Payment Method rules
  const supportedCountries = countries.filter((country) =>
    rules.find((rule) => rule.isoCountryCode === country.isoCountryCode)
  );

  const { onComplete, onSubmit, getBatchStatus, ...props } =
    PayorCreatePayeePresenter(
      wireframe,
      entity,
      payorId,
      VeloNotification.types.CREATE_PAYEE_SUCCESS,
      context.downloadContentAsFile,
      setReadFailures,
      intl
    );

  /** Provide a callback onSubmit **/
  const onSubmitFnWithCallBack = (data) => {
    // API
    setApiError(undefined);
    // read CSV errors - invalid entries
    setReadFailures([]);
    //processed Failures - duplicate or rejected details
    setFailures([]);
    setIsSubmitting(true);
    onSubmit(
      data,
      formatCallbackErrorArg([], (error) => {
        setIsSubmitting(false);
        setApiError(error);
      })
    );
  };

  // Collate Process Errors and complete
  const onProcessComplete = (response, batchCount) => {
    setFailures(response.failures);
    const failureCount = response.failures.length + readFailures.length;
    const successCount = batchCount - failureCount;
    setIsSubmitting(false);
    onComplete(failureCount, successCount);
  };

  return (
    <>
      <Route path={wireframe.navigateToPayeeUpload.path} exact>
        <ProgressIndicator
          onComplete={onProcessComplete}
          getBatchStatus={getBatchStatus}
        />
      </Route>
      <AddPayee.CreatePayee
        {...props}
        onSubmit={onSubmitFnWithCallBack}
        countries={supportedCountries}
        paymentChannel={{ rules, currency: 'USD' }}
        payeeCountryRules={payeeCountryRules}
        country="US"
        submitting={isSubmitting}
        {...getErrorProps(isSubmitting, apiError, failures, readFailures)}
      />
    </>
  );
}
