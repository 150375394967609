export function submitPassword(send) {
  return (tokenId, body, headers, ...args) =>
    send(
      {
        path: '/v1/tokens/{tokenId}/password',
        pathParameters: { tokenId },
        type: 'post',
        query: undefined,
        body: body,
        headers: headers,
        auth: undefined,
        contentType: 'application/json',
      },
      ...args
    );
}
