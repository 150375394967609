import React from 'react';
import { FormattedMessage } from 'react-intl';
import { PaymentViewResult } from '../../PaymentViewResult';

/**
 * Render a Payee Name.
 */
function PayeeName({ result }) {
  return (
    <PaymentViewResult
      {...result}
      errorMessage={<FormattedMessage defaultMessage="Error loading Payee" />}
    />
  );
}

export { PayeeName };
