export const Privileges = {
  PAYMENT_STATUS_OPTIONS: 'paymentStatusOptions',
  FUNDING_ACCOUNT_VIEW_PAYOR: 'fundingAccountViewPayor',
  FUNDING_ACCOUNT_VIEW_SENSITIVE: 'fundingAccountViewSensitive',
  FUNDING_ACCOUNT_EDIT: 'fundingAccountEdit',
  SOURCE_ACCOUNT_VIEW_PAYOR: 'sourceAccountViewPayor',
  SOURCE_ACCOUNT_VIEW_PHYSICAL_ACCOUNT: 'sourceAccountViewPhysicalAccount',
  SOURCE_ACCOUNT_SET_LOW_BALANCE: 'sourceAccountSetLowBalance',
  SOURCE_ACCOUNT_SET_TOP_UP: 'sourceAccountSetTopUp',
  SOURCE_ACCOUNT_TRANSFER_FUNDS: 'sourceAccountTransferFunds',
  SOURCE_ACCOUNT_ADD_FUNDS: 'sourceAccountAddFunds',
  SOURCE_ACCOUNT_EDIT: 'sourceAccountEdit',
  SOURCE_ACCOUNT_EDIT_OBO_DETAILS: 'sourceAccountEditOboDetails',
  SOURCE_ACCOUNT_DELETE: 'sourceAccountDelete',
  PAYMENT_METHOD_DELETE: 'paymentMethodDelete',
  PAYMENT_METHOD_EDIT: 'paymentMethodEdit',
};
