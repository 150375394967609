import { useState, useCallback } from 'react';

// Hook used to manage column sorting.
// Returns:
// - Current sort column name
// - Current sort direction
// - A function to update the column/direction
// - A function used to build a query object.
const useSort = (initialColumn, initialDirection) => {
  const [sortColumn, setSortColumn] = useState(initialColumn);
  const [sortDirection, setSortDirection] = useState(initialDirection);
  const setSort = (column, direction) => {
    setSortColumn(column);
    setSortDirection(direction);
  };
  const query = useCallback(
    () => ({
      sort: `${sortColumn}:${sortDirection === 1 ? 'asc' : 'desc'}`,
    }),
    [sortColumn, sortDirection]
  );
  return [sortColumn, sortDirection, setSort, query];
};

export { useSort };
