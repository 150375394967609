import React, { useState } from 'react';
import { array, string, func } from 'prop-types';
import styled from 'astroturf/react';
import { dataTestIdBuilder } from 'velo-data';
import { VeloFab } from '../VeloFab';
import { VeloScrim } from '../VeloScrim';

const root = 'split-mobile-fab';

const TestIds = {
  OPEN: `${root}-open`,
  CLOSE: `${root}-close`,
  SCRIM: `${root}-scrim`,
};

const Scrim = styled(VeloScrim)`
  @import 'velo-variables';
  z-index: $velo-base-index-default;
`;

const MenuOptionFab = styled(VeloFab.Mobile)`
  @import 'velo-variables';
  background-color: velo-color('token-color-brand-primary-background');
  color: velo-color('token-color-brand-primary');
`;

const CloseMenuFab = styled(VeloFab.Mobile)`
  @import 'velo-variables';
  background-color: velo-color('token-color-text-muted');
`;

const FAB_HEIGHT = '56px';

const FirstOption = styled(MenuOptionFab)`
  bottom: calc(1rem + ${FAB_HEIGHT} + 1rem);
`;

const SecondOption = styled(MenuOptionFab)`
  bottom: calc(1rem + ${FAB_HEIGHT} + 1rem + ${FAB_HEIGHT} + 1rem);
`;

const ThirdOption = styled(MenuOptionFab)`
  bottom: calc(
    1rem + ${FAB_HEIGHT} + 1rem + ${FAB_HEIGHT} + 1rem + ${FAB_HEIGHT} + 1rem
  );
`;

// Currently a maximum of three menu options on mobile are supported
const MenuOptionFabsList = [FirstOption, SecondOption, ThirdOption];

SplitMobileFab.testIds = TestIds;
SplitMobileFab.dataTestIdBuilder = (label) => dataTestIdBuilder(root, label);

const propTypes = {
  /** The title attribute text for the close button  */
  closeMenuTitle: string.isRequired,
  /** The title attribute text for the open button  */
  openMenuTitle: string.isRequired,
  /** options */
  options: array.isRequired,
  /** called when menu item is selected on */
  onClick: func.isRequired,
};

SplitMobileFab.propTypes = propTypes;

function SplitMobileFab({ options, onClick, closeMenuTitle, openMenuTitle }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <>
      <Scrim
        transparent
        data-testid={TestIds.SCRIM}
        open={isMenuOpen}
        onClick={() => setIsMenuOpen(false)}
      />
      {isMenuOpen ? (
        <>
          {options.map(({ icon, ...option }, i) => {
            const Component = MenuOptionFabsList[i];
            return (
              <Component
                key={option.id}
                title={icon.title}
                data-testid={dataTestIdBuilder(root, option.label)}
                icon={icon}
                onClick={() => onClick(option)}
              />
            );
          })}

          <CloseMenuFab
            data-testid={TestIds.CLOSE}
            onClick={() => setIsMenuOpen(false)}
            title={closeMenuTitle}
            icon={{
              icon: 'close',
              title: closeMenuTitle,
            }}
          />
        </>
      ) : (
        <VeloFab.Mobile
          data-testid={TestIds.OPEN}
          onClick={() => setIsMenuOpen(true)}
          title={openMenuTitle}
          icon={{
            icon: 'fab_menu_multi',
            title: openMenuTitle,
          }}
        />
      )}
    </>
  );
}

export { SplitMobileFab };
