import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { VeloStatusIndicator } from '../VeloStatusIndicator';

const paymentStatus = {
  ACCEPTED: {
    type: VeloStatusIndicator.types.SUCCESS,
    label: <FormattedMessage defaultMessage="Accepted" />,
  },
  AWAITING_FUNDS: {
    type: VeloStatusIndicator.types.INFO,
    label: <FormattedMessage defaultMessage="Awaiting Funds" />,
  },
  FUNDED: {
    type: VeloStatusIndicator.types.SUCCESS,
    label: <FormattedMessage defaultMessage="Funded" />,
  },
  UNFUNDED: {
    type: VeloStatusIndicator.types.WARNING,
    label: <FormattedMessage defaultMessage="Unfunded" />,
  },
  BANK_PAYMENT_REQUESTED: {
    type: VeloStatusIndicator.types.INFO,
    label: <FormattedMessage defaultMessage="Awaiting Confirmation" />,
  },
  REJECTED: {
    type: VeloStatusIndicator.types.FAILED,
    label: <FormattedMessage defaultMessage="Rejected" />,
  },
  WITHDRAWING: {
    type: VeloStatusIndicator.types.WARNING,
    label: <FormattedMessage defaultMessage="Withdrawing" />,
  },
  WITHDRAWN: {
    type: VeloStatusIndicator.types.WARNING,
    label: <FormattedMessage defaultMessage="Withdrawn" />,
  },
  ACCEPTED_BY_RAILS: {
    type: VeloStatusIndicator.types.INFO,
    label: <FormattedMessage defaultMessage="Awaiting Confirmation" />,
  },
  AWAITING_AUTHORIZATION: {
    type: VeloStatusIndicator.types.INFO,
    label: <FormattedMessage defaultMessage="Awaiting Authorisation" />,
  },
  CONFIRMED: {
    type: VeloStatusIndicator.types.SUCCESS,
    label: <FormattedMessage defaultMessage="Confirmed" />,
  },
  RETURNED: {
    type: VeloStatusIndicator.types.FAILED,
    label: <FormattedMessage defaultMessage="Returned" />,
  },
  CANCELLED: {
    type: VeloStatusIndicator.types.WARNING,
    label: <FormattedMessage defaultMessage="Cancelled" />,
  },
};

PaymentStatus.propTypes = {
  /** The status. */
  status: string,
};

/**
 * Payment status indicator.
 */
function PaymentStatus({ status, skeleton, ...other }) {
  const statusProps = paymentStatus[status] || {
    type: VeloStatusIndicator.types.UNKNOWN,
    label: <FormattedMessage defaultMessage="UNKNOWN" />,
  };
  // TODO: refactor to use a `PaymentStatus.Loading` variant.
  return skeleton ? (
    <VeloStatusIndicator.Loading {...other} />
  ) : (
    <VeloStatusIndicator {...statusProps} {...other} />
  );
}

export { PaymentStatus };
