import React, { useState, useEffect, useMemo } from 'react';
import { useHistory, Switch, Route } from 'react-router-dom';
import { SettingsPage } from 'velo-react-components';
import { useWireframe, useActiveTheme } from '../../hooks';
import { SettingsAppearanceRoute } from './SettingsAppearanceRoute';
import { SettingsAdvancedRoute } from './SettingsAdvancedRoute';

export function SettingsRoute({ payee, tabIndex, rootPath }) {
  const [theme, onThemeChange] = useActiveTheme();
  const [previousTheme] = useState(theme);
  const [activeTabIndex, setActiveTabIndex] = useState(tabIndex || 0);

  const history = useHistory();
  const wireframe = useWireframe(history, rootPath);
  const routes = useMemo(
    // Array of routes for each tab
    () => [
      wireframe.navigateToSettingsAppearance.redirect,
      wireframe.navigateToSettingsAdvanced.redirect,
    ],
    [wireframe]
  );

  useEffect(() => {
    // Redirect to the correct route for the current tab.
    routes[activeTabIndex]();
  }, [activeTabIndex, routes]);

  function onCloseHandler() {
    onThemeChange(previousTheme);
    wireframe.backToRootPath();
  }

  return (
    <SettingsPage
      activeTabIndex={activeTabIndex}
      onActivate={setActiveTabIndex}
      onClose={onCloseHandler}
      payeeApp={payee}
    >
      <Switch>
        <Route path={wireframe.navigateToSettingsAppearance.path}>
          <SettingsAppearanceRoute />
        </Route>
        <Route path={wireframe.navigateToSettingsAdvanced.path}>
          <SettingsAdvancedRoute />
        </Route>
      </Switch>
    </SettingsPage>
  );
}
