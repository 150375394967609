import React from 'react';
import { bool, number } from 'prop-types';
import { PaymentViewHeading } from '../PaymentViewHeading';
import { PaymentViewItem } from '../PaymentViewItem';
import { PaymentViewDivider } from '../PaymentViewDivider';

PaymentViewSectionSkeleton.propTypes = {
  /** Include a skeleton heading. */
  heading: bool,
  /** The number of items to render. */
  length: number.isRequired,
};

/**
 * Render a skeleton view of a Payment details section.
 */
function PaymentViewSectionSkeleton({ heading, length, title }) {
  return (
    <>
      {heading && <PaymentViewHeading skeleton />}

      {[...Array(length).keys()].map((key) => (
        <PaymentViewItem key={key} skeleton title={title} />
      ))}

      <PaymentViewDivider />
    </>
  );
}

export { PaymentViewSectionSkeleton };
