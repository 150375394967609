import React, { useMemo, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { bool, func, number } from 'prop-types';
import styled from 'astroturf/react';
import { VeloButton } from '../VeloButton';
import { VeloMenu } from '../VeloMenu';
import { useMenuManager } from '../hooks';

const Button = styled(VeloButton)`
  @import 'velo-variables';

  line-height: normal;
  min-width: 8rem;
  text-transform: none;
  letter-spacing: normal;
  font-weight: normal;

  :global(.mdc-button__label) {
    color: velo-color('token-color-text-default');
  }

  &:disabled {
    :global(.mdc-button__label) {
      color: velo-color('token-color-text-disabled');
    }
  }
`;

const root = 'pagination';

const TestIds = {
  BUTTON: `${root}-page`,
  MENU: `${root}-page-menu`,
};

/**
 * Render an MDC button with a drop-down icon.
 * The user clicks this button to display a list of pages.
 */
const PageButton = ({ disabled, ...other }) => (
  <Button
    trailingIcon="arrow_drop_down"
    disabled={disabled}
    data-testid={TestIds.BUTTON}
    {...other}
  />
);

/**
 * The menu that appears when the button is clicked.
 * Uses `useMenuManager` to esnure it only appears in the DOM
 * when open.
 */
const PageMenu = ({ totalPages, ...menuProps }) => {
  const [mounted, onClose] = useMenuManager(menuProps.open, menuProps.onClose);

  const menuItems = useMemo(() => [...Array(totalPages).keys()], [totalPages]);

  return mounted ? (
    <VeloMenu {...menuProps} onClose={onClose} data-testid={TestIds.MENU}>
      {menuItems.map((index) => (
        <VeloMenu.Item key={index} ripple={false}>
          <FormattedMessage
            defaultMessage="Page {page}"
            values={{ page: index + 1 }}
          />
        </VeloMenu.Item>
      ))}
    </VeloMenu>
  ) : null;
};

PaginationMenu.propTypes = {
  /** The current page. */
  page: number,
  /** The total pages. */
  totalPages: number,
  /** Disable the component. */
  disabled: bool,
  /** Called when a new page is selected. */
  onPage: func,
};

PaginationMenu.testIds = TestIds;

/**
 * Pagination menu.
 * When clicked a menu is displayed allowing the user to
 * jump directly to page.
 */
function PaginationMenu({ page, totalPages, disabled, onPage }) {
  const [open, setOpen] = useState(false);
  const selected = useRef(-1);

  const menuHandlers = useMemo(
    () => ({
      onOpen: () => {
        selected.current = -1;
      },
      onSelect: (event) => {
        selected.current = event.detail.index + 1;
      },
      onClose: () => {
        setOpen(false);
        if (selected.current !== -1) {
          onPage(selected.current);
        }
      },
    }),
    [onPage]
  );

  return (
    <VeloMenu.SurfaceAnchor>
      <PageMenu open={open} totalPages={totalPages} {...menuHandlers} />
      <PageButton
        disabled={disabled || totalPages === 1}
        onClick={() => setOpen(true)}
      >
        <FormattedMessage
          defaultMessage="Page {page} of {totalPages}"
          values={{ page, totalPages }}
        />
      </PageButton>
    </VeloMenu.SurfaceAnchor>
  );
}

export { PaginationMenu };
