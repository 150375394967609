import compose from 'just-compose';
import { VeloTableBuilder } from '../VeloTableBuilder';
import { PaymentsListColumns } from '../PaymentsListColumns';

const {
  columnsByName,
  filtersByName,
  fieldNames,
  getRowProps,
  getSkeletonRowProps,
  getEmptyRowProps,
  ...config
} = PaymentsListColumns;

const addBorderBottomProp = (props) => ({ ...props, borderBottom: true });

const PaymentsByPayoutList = VeloTableBuilder({
  ...config,
  columns: fieldNames,
  getRowProps: compose(getRowProps, addBorderBottomProp),
  columnOrder: [
    columnsByName[fieldNames.PAYEE_NAME],
    columnsByName[fieldNames.REMOTE_ID],
    columnsByName[fieldNames.SOURCE_AMOUNT],
    columnsByName[fieldNames.PAYMENT_AMOUNT],
    columnsByName[fieldNames.PAYMENT_STATUS],
  ],
  filters: [
    filtersByName[fieldNames.REMOTE_ID],
    filtersByName[fieldNames.PAYMENT_STATUS],
    filtersByName[fieldNames.TRANSMISSION_TYPE],
    filtersByName[fieldNames.DATE_TIME],
    filtersByName[fieldNames.PAYMENT_AMOUNT],
    filtersByName[fieldNames.SOURCE_AMOUNT],
  ],
  sortableColumns: [
    fieldNames.REMOTE_ID,
    fieldNames.SOURCE_AMOUNT,
    fieldNames.PAYMENT_AMOUNT,
  ],
});

PaymentsByPayoutList.Headings = compose(
  addBorderBottomProp,
  PaymentsByPayoutList.Headings
);

PaymentsByPayoutList.Loading = compose(
  (props) => ({ ...props, getRowProps: getSkeletonRowProps }),
  PaymentsByPayoutList.Loading
);

PaymentsByPayoutList.Empty = compose((props) => {
  return { ...getEmptyRowProps(props) };
}, PaymentsByPayoutList.Empty);

export { PaymentsByPayoutList };
