import React from 'react';
import { arrayOf, shape, string, func } from 'prop-types';
// eslint-disable-next-line no-unused-vars
import { FormattedMessage } from 'react-intl';
import { dataTestIdBuilder, Privileges } from 'velo-data';
import { PaymentResult } from '../PaymentsList/PaymentResult';
import { PaymentStatus } from '../PaymentStatus';
import { VeloIcon } from '../VeloIcon';
import { VeloListCurrencyAmount } from '../VeloListCurrencyAmount';
import { Alignment } from '../VeloTable/types';
import styled from 'astroturf/react';
import { VeloTypography } from '../VeloTypography';
import { VeloTable } from '../VeloTable';
import { transmissionTypeFilterOptions } from '../utils';

const root = 'velo-payments-list';

const TestIds = {
  LIST: root,
  HEADING: `${root}-heading`,
  SORT_HEADING: `${root}-sort-heading`,
  ROW_PREFIX: `${root}-row`,
  COLUMN_PREFIX: `${root}-column`,
  SHORT: `${root}-short-date`,
  LONG: `${root}-long-date`,
  SKELETON_ROW: `${root}-icon-skeleton`,
};

const fieldNames = {
  DATE_TIME: 'submittedDateTime',
  PAYEE_NAME: 'payeeName',
  PAYOR: 'payorId',
  REMOTE_ID: 'remoteId',
  SOURCE_AMOUNT: 'sourceAmount',
  TRANSMISSION_TYPE: 'transmissionType',
  PAYMENT_AMOUNT: 'paymentAmount',
  PAYMENT_CHANNEL_NAME: 'paymentChannelName',
  PAYMENT_MEMO: 'paymentMemo',
  PAYMENT_STATUS: 'status',
  SCHEDULED_ICON: 'scheduled-icon',
  SCHEDULED_STATUS_ICON: 'scheduled-status-icon',
};

const paymentStatusOptions = (intl) => [
  {
    label: intl.formatMessage({ defaultMessage: 'Accepted' }),
    value: 'ACCEPTED',
    requiredPrivileges: [Privileges.PAYMENT_STATUS_OPTIONS],
  },
  {
    label: intl.formatMessage({ defaultMessage: 'Awaiting Funds' }),
    value: 'AWAITING_FUNDS',
    requiredPrivileges: [Privileges.PAYMENT_STATUS_OPTIONS],
  },
  {
    label: intl.formatMessage({ defaultMessage: 'Funded' }),
    value: 'FUNDED',
    requiredPrivileges: [Privileges.PAYMENT_STATUS_OPTIONS],
  },
  {
    label: intl.formatMessage({ defaultMessage: 'Unfunded' }),
    value: 'UNFUNDED',
    requiredPrivileges: [Privileges.PAYMENT_STATUS_OPTIONS],
  },
  {
    label: intl.formatMessage({ defaultMessage: 'Payment Requested' }),
    value: 'BANK_PAYMENT_REQUESTED',
    requiredPrivileges: [Privileges.PAYMENT_STATUS_OPTIONS],
  },
  {
    label: intl.formatMessage({ defaultMessage: 'Awaiting Confirmation' }),
    value: 'ACCEPTED_BY_RAILS',
    requiredPrivileges: [],
  },
  {
    label: intl.formatMessage({ defaultMessage: 'Confirmed' }),
    value: 'CONFIRMED',
    requiredPrivileges: [],
  },
  {
    label: intl.formatMessage({ defaultMessage: 'Returned' }),
    value: 'RETURNED',
    requiredPrivileges: [],
  },
  {
    label: intl.formatMessage({ defaultMessage: 'Withdrawn' }),
    value: 'WITHDRAWN',
    requiredPrivileges: [Privileges.PAYMENT_STATUS_OPTIONS],
  },
];

const columnsByName = {
  [fieldNames.DATE_TIME]: {
    name: fieldNames.DATE_TIME,
    label: <FormattedMessage defaultMessage="Date/Time" />,
    'data-testid': dataTestIdBuilder(TestIds.COLUMN_PREFIX, 'heading-datetime'),
  },
  [fieldNames.PAYEE_NAME]: {
    name: fieldNames.PAYEE_NAME,
    label: <FormattedMessage defaultMessage="Payee name" />,
    'data-testid': dataTestIdBuilder(
      TestIds.COLUMN_PREFIX,
      'heading-payee-name'
    ),
    dateTimePadding: true,
  },
  [fieldNames.REMOTE_ID]: {
    name: fieldNames.REMOTE_ID,
    label: <FormattedMessage defaultMessage="Remote ID" />,
    'data-testid': dataTestIdBuilder(
      TestIds.COLUMN_PREFIX,
      'heading-remote-id'
    ),
    dateTimePadding: true,
  },
  [fieldNames.PAYMENT_AMOUNT]: {
    name: fieldNames.PAYMENT_AMOUNT,
    label: <FormattedMessage defaultMessage="Payment amount" />,
    align: Alignment.END,
    'data-testid': dataTestIdBuilder(
      TestIds.COLUMN_PREFIX,
      'heading-payment-amount'
    ),
    dateTimePadding: true,
  },
  [fieldNames.SOURCE_AMOUNT]: {
    name: fieldNames.SOURCE_AMOUNT,
    label: <FormattedMessage defaultMessage="Source amount" />,
    align: Alignment.END,
    'data-testid': dataTestIdBuilder(
      TestIds.COLUMN_PREFIX,
      'heading-source-amount'
    ),
    dateTimePadding: true,
  },
  [fieldNames.PAYMENT_STATUS]: {
    name: fieldNames.PAYMENT_STATUS,
    label: <FormattedMessage defaultMessage="Payment status" />,
    'data-testid': dataTestIdBuilder(TestIds.COLUMN_PREFIX, 'heading-status'),
    dateTimePadding: true,
  },
};

const filtersByName = {
  [fieldNames.REMOTE_ID]: {
    name: fieldNames.REMOTE_ID,
    label: <FormattedMessage defaultMessage="Remote ID" />,
    type: 'string',
  },
  [fieldNames.PAYMENT_STATUS]: {
    name: fieldNames.PAYMENT_STATUS,
    label: <FormattedMessage defaultMessage="Status" />,
    type: 'list',
    options: paymentStatusOptions,
  },
  [fieldNames.TRANSMISSION_TYPE]: {
    name: fieldNames.TRANSMISSION_TYPE,
    label: <FormattedMessage defaultMessage="Transmission Type" />,
    type: 'list',
    options: transmissionTypeFilterOptions,
  },
  [fieldNames.DATE_TIME]: {
    name: fieldNames.DATE_TIME,
    label: <FormattedMessage defaultMessage="Submitted Date" />,
    type: 'dateRange',
    nameFrom: 'submittedDateFrom',
    nameTo: 'submittedDateTo',
  },
  [fieldNames.PAYMENT_MEMO]: {
    name: fieldNames.PAYMENT_MEMO,
    label: <FormattedMessage defaultMessage="Payment Memo" />,
    type: 'string',
  },
  [fieldNames.PAYMENT_AMOUNT]: {
    name: fieldNames.PAYMENT_AMOUNT,
    label: <FormattedMessage defaultMessage="Payment Amount" />,
    type: 'currencyRange',
    nameFrom: 'paymentAmountFrom',
    nameTo: 'paymentAmountTo',
  },
  [fieldNames.SOURCE_AMOUNT]: {
    name: fieldNames.SOURCE_AMOUNT,
    label: <FormattedMessage defaultMessage="Source Amount" />,
    type: 'currencyRange',
    nameFrom: 'sourceAmountFrom',
    nameTo: 'sourceAmountTo',
  },
};

const cellComponentByFieldName = {
  [fieldNames.DATE_TIME]: VeloTable.DateTimeCell,
};

const cellFactoryByFieldName = {
  [fieldNames.PAYEE_NAME]: ({ payeeName }, index) => (
    <PaymentResult
      errorMessage="Error loading Payee"
      {...payeeName}
      data-testid={dataTestIdBuilder(TestIds.ROW_PREFIX, index, 'payee-name')}
    />
  ),
  [fieldNames.REMOTE_ID]: ({ remoteId }, index) => (
    <span
      data-testid={dataTestIdBuilder(TestIds.ROW_PREFIX, index, 'remote-id')}
    >
      {remoteId}
    </span>
  ),
  [fieldNames.PAYMENT_AMOUNT]: ({ paymentAmount, paymentCurrency }, index) => (
    <VeloListCurrencyAmount
      amount={paymentAmount}
      currency={paymentCurrency}
      data-testid={dataTestIdBuilder(
        TestIds.ROW_PREFIX,
        index,
        'payment-amount'
      )}
    />
  ),
  [fieldNames.SOURCE_AMOUNT]: ({ sourceAmount, sourceCurrency }, index) => (
    <VeloListCurrencyAmount
      amount={sourceAmount}
      currency={sourceCurrency}
      data-testid={dataTestIdBuilder(TestIds.ROW_PREFIX, index, 'amount')}
    />
  ),
  [fieldNames.PAYMENT_STATUS]: ({ status }, index) => (
    <PaymentStatus
      use="tableContent"
      status={status}
      data-testid={dataTestIdBuilder(TestIds.ROW_PREFIX, index, 'status')}
    />
  ),
};

const EmptyResultContainer = styled('div')`
  @import 'velo-variables';
  background-color: velo-color('token-color-system-info-lighter');
  height: 50px;
  width: inherit;
`;

const EmptyIconLabel = styled('div')`
  margin: 0.8rem;
  display: flex;
  align-items: center;
`;

const EmptyResultTypography = styled(VeloTypography).attrs({
  use: 'bodyText',
  tag: 'div',
})`
  margin-left: 0.5rem;
`;

const VeloEmptyInfoIcon = styled(VeloIcon)`
  @import 'velo-variables';
  color: velo-color('token-color-brand-primary');
`;

export const PaymentsListColumns = {
  fieldNames,
  filtersByName,
  columnsByName,
  getEmptyRowProps: ({ isProcessing }) => ({
    children: isProcessing ? (
      <EmptyResultContainer>
        <EmptyIconLabel>
          <VeloEmptyInfoIcon icon="feedback" />
          <EmptyResultTypography>
            <VeloTypography use="bodyTextEmphasised">
              <FormattedMessage defaultMessage="Processing payments: " />
            </VeloTypography>
            <FormattedMessage defaultMessage="Payments processing is in progress. Please try again later." />
          </EmptyResultTypography>
        </EmptyIconLabel>
      </EmptyResultContainer>
    ) : null,
  }),
  getSkeletonRowProps: () => ({ 'data-testid': TestIds.SKELETON_ROW }),
  getRowProps(payment, index, _, { onClick }) {
    return {
      key: payment.paymentId,
      icon: 'chevron_right',
      onClick: () => onClick(payment),
      'data-testid': dataTestIdBuilder(TestIds.ROW_PREFIX, index),
    };
  },
  getColumnProps({ name: key, align, dateTimePadding, size }, ...args) {
    return {
      key,
      align,
      dateTimePadding,
      size,
      Component: cellComponentByFieldName[key],
      children: cellFactoryByFieldName[key](...args),
    };
  },
  dataProps: {
    data: arrayOf(
      shape({
        /** The payment ID. */
        paymentId: string.isRequired,
        /** The date/time the payment was submitted. */
        submittedDateTime: string.isRequired,
      })
    ),
    onClick: func.isRequired,
  },
};
