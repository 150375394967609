import { useMemo, useCallback } from 'react';
import { matchPath } from 'react-router';
import pathToRegexp from 'path-to-regexp';
import querystring from 'query-string';
import { useAppContext, useCookieConsentContext } from '../context';
import { VeloCacheHitContext } from 'velo-react-components';
import { useLegalLinksList } from './useLegalLinksList';

/**
 * Send a notification to the system
 * @callback sendNote
 * @param {*} notification the notification
 * @param {boolean=} incrementCache will use the notifications context property
 * to trigger a cache increment. Use this for triggering system wide reloads as
 * a result of update/edit/create/delete notifications.
 * @returns {Void}
 */

/**
 * @typedef {Object} Wireframe
 * @property {sendNote} sendNote send a notification
 */

function buildMethod(template, cont) {
  return (vars, query = {}, state) => {
    const path = template(vars);
    const encoded = querystring.stringify(query);
    const qs = encoded.length ? `?${encoded}` : '';
    cont(state ? { pathname: path, search: qs, state } : `${path}${qs}`);
  };
}

function pathsToWireframe(pathMap, history) {
  return Object.entries(pathMap).reduce((wireframe, [methodName, path]) => {
    const template = pathToRegexp.compile(path);
    const method = buildMethod(template, history.push);
    method.path = path;
    method.redirect = buildMethod(template, history.replace);
    method.matchPath = () =>
      matchPath(history.location.pathname, {
        path,
      });

    return {
      ...wireframe,
      [methodName]: method,
    };
  }, {});
}

// just isolated state, doesn't need to be React state (global)
const redirectsByKey = {};

function buildRedirectionHelpers() {
  return {
    checkForRedirect(key, ...args) {
      const redirectFn = redirectsByKey[key];
      if (redirectFn) {
        return redirectFn(...args);
      }
    },
    addRedirect(key, redirectFn) {
      redirectsByKey[key] = redirectFn;
    },
  };
}

/**
 * A hook that takes a history object from react-router and makes an object with
 * named methods that perform the navigation.
 * routePath provides a mechanism to clear history and return to the base routePath
 * if not provided defaults to no-op
 *
 * @returns {Wireframe}
 */
export function useWireframe(history, rootPath) {
  const {
    requestNotification,
    clearNotification,
    downloadContentAsFile,
    appVariant,
    routePaths,
  } = useAppContext();

  const { closeCookieBanner } = useCookieConsentContext();

  const [, increment] = VeloCacheHitContext.useVeloCacheHitContextGlobal();

  const sendNote = useCallback(
    (note, doIncrement, timeoutValue) => {
      if (note.key) {
        requestNotification(note);
      } else {
        requestNotification({
          ...note,
          key: Date.now().toString(10),
        });
      }
      if (doIncrement) {
        increment(note.context, timeoutValue);
      }
    },
    [requestNotification, increment]
  );

  const navigationMethods = useMemo(
    () =>
      pathsToWireframe({ navigateToLogin: '/login', ...routePaths }, history),
    [routePaths, history]
  );

  const legalLinks = useLegalLinksList(appVariant);

  return useMemo(
    () => ({
      ...navigationMethods,
      goBack: () => history.goBack(),
      backToRootPath: () => history.replace(rootPath),
      sendNote,
      clearNote: clearNotification,
      downloadContentAsFile,
      closeCookieBanner,
      legalLinks,
      ...buildRedirectionHelpers(),
    }),
    [
      navigationMethods,
      sendNote,
      clearNotification,
      downloadContentAsFile,
      closeCookieBanner,
      legalLinks,
      history,
      rootPath,
    ]
  );
}
