import React, { useMemo } from 'react';
import { func, shape } from 'prop-types';
import { useIntl } from 'react-intl';
import {
  VeloModalSheetCardContent,
  VeloModalSheet,
  UserView,
  VeloNotification,
  UserRoles,
  VeloBookended,
  InviteOTP,
} from 'velo-react-components';
import {
  resendToken,
  unlockUserV2 as unlockUser,
  deleteUserByIdV2 as deleteUserById,
  getUserByIdV2 as getUserById,
} from 'velo-api/src/entities/users';
import { PayorNameFragment } from '../PayorNameFragment';
import { forkResult } from '../../selectors';
import {
  usePresenter,
  useCallbackFnAsResultState,
  useAuthState,
  useLoggedInUserRole,
} from '../../hooks';
import { OTPRequiredInterceptor } from '../../containers/OTPRequiredInterceptor';
import { PayorUsersTagsRoute } from '../PayorUsersRoute/PayorUsersTagsRoute';

import { UserViewPresenter } from './UserViewPresenter';

const entitySpec = {
  resendToken,
  unlockUser,
  deleteUserById,
  getUserById,
};

const payorNotifications = {
  deleteUserNotification: {
    error: VeloNotification.types.DELETE_PAYOR_USER_FAILURE,
    success: VeloNotification.types.DELETE_PAYOR_USER_SUCCESS,
  },
  unlockUserNotification: {
    error: VeloNotification.types.UNLOCK_PAYOR_USER_FAILURE,
    success: VeloNotification.types.UNLOCK_PAYOR_USER_SUCCESS,
  },
  resendInviteNotification: {
    error: VeloNotification.types.RESEND_PAYOR_USER_INVITE_FAILURE,
    success: VeloNotification.types.RESEND_PAYOR_USER_INVITE_SUCCESS,
  },
  resendRegistrationNotification: {
    success: VeloNotification.types.RESEND_MFA_REGISTRATION_SUCCESS,
    error: VeloNotification.types.RESEND_MFA_REGISTRATION_FAILURE,
  },
};

const backofficeNotifications = {
  deleteUserNotification: {
    error: VeloNotification.types.DELETE_BACKOFFICE_USER_FAILURE,
    success: VeloNotification.types.DELETE_BACKOFFICE_USER_SUCCESS,
  },
  unlockUserNotification: {
    error: VeloNotification.types.UNLOCK_BACKOFFICE_USER_FAILURE,
    success: VeloNotification.types.UNLOCK_BACKOFFICE_USER_SUCCESS,
  },
  resendInviteNotification: {
    error: VeloNotification.types.RESEND_BACKOFFICE_USER_INVITE_FAILURE,
    success: VeloNotification.types.RESEND_BACKOFFICE_USER_INVITE_SUCCESS,
  },
  resendRegistrationNotification: {
    success: VeloNotification.types.RESEND_MFA_REGISTRATION_SUCCESS,
    error: VeloNotification.types.RESEND_MFA_REGISTRATION_FAILURE,
  },
};

const payeeNotifications = {
  deleteUserNotification: {
    error: VeloNotification.types.DELETE_PAYEE_USER_FAILURE,
    success: VeloNotification.types.DELETE_PAYEE_USER_SUCCESS,
  },
  unlockUserNotification: {
    error: VeloNotification.types.UNLOCK_PAYEE_USER_FAILURE,
    success: VeloNotification.types.UNLOCK_PAYEE_USER_SUCCESS,
  },
  resendInviteNotification: {
    error: VeloNotification.types.RESEND_PAYEE_USER_INVITE_FAILURE,
    success: VeloNotification.types.RESEND_PAYEE_USER_INVITE_SUCCESS,
  },
  resendRegistrationNotification: {
    success: VeloNotification.types.RESEND_MFA_REGISTRATION_SUCCESS,
    error: VeloNotification.types.RESEND_MFA_REGISTRATION_FAILURE,
  },
};

const forks = {
  none: (props) => {
    return <UserView.Loading {...props} />;
  },
  error: (error, { onClose }) => (
    <VeloModalSheetCardContent.Error onClose={onClose}>
      {error}
    </VeloModalSheetCardContent.Error>
  ),
};

const adminSupportUserRoles = [UserRoles.PayorAdmin, UserRoles.PayorSupport];

const payorForks = {
  ...forks,
  value: (data, props, getChildren) => (
    <PayorUserView data={data} {...props}>
      {getChildren(data)}
    </PayorUserView>
  ),
};

const backOfficeForks = {
  ...forks,
  value: (data, props) => <BackOfficeUserView data={data} {...props} />,
};

const payeeForks = {
  ...forks,
  value: (data, props) => <PayeeUserView data={data} {...props} />,
};

function canEdit(loggedInRole, userId, data) {
  // You cannot edit your own profile
  if (userId === data.id) {
    return false;
  }

  return UserRoles.canEditOtherRole(loggedInRole, data.role);
}

const dataViewPropTypes = {
  handlers: shape({
    onDelete: func.isRequired,
    onUnlock: func.isRequired,
    onResendInvite: func.isRequired,
    onResendMfaRegistration: func.isRequired,
  }).isRequired,
  onClose: func.isRequired,
  onEdit: func.isRequired,
};

PayorUserView.propTypes = dataViewPropTypes;

function PayorUserView({ role, data, handlers, onEdit, ...props }) {
  const { userId: loggedInUserId } = useAuthState('entityId');

  handlers.onResendInvite.title = InviteOTP.titles.RESEND_INVITE;
  handlers.onResendMfaRegistration.title =
    InviteOTP.titles.RESEND_MFA_REGISTRATION;

  return canEdit(role, loggedInUserId, data) ? (
    <OTPRequiredInterceptor
      user={data}
      handlers={handlers}
      render={(componentProps) => (
        <UserView.Admin
          {...props}
          {...componentProps}
          data={data}
          onEdit={onEdit}
          isBackOfficeViewAdminOrSupport={
            role === UserRoles.BOPAdmin &&
            adminSupportUserRoles.includes(data.role)
          }
          onUnlock={role === UserRoles.BOPAdmin ? handlers.onUnlock : undefined}
        />
      )}
    />
  ) : (
    <UserView.Support data={data} {...props} />
  );
}

BackOfficeUserView.propTypes = dataViewPropTypes;

function BackOfficeUserView({ payorId, data, handlers, ...props }) {
  const { userId: actingUserId } = useAuthState('entityId');

  return data.id === actingUserId ? (
    <UserView.BackOfficeAdminViewSelf data={data} {...props} />
  ) : (
    <UserView.BackOfficeAdmin data={data} {...props} {...handlers} />
  );
}

function PayeeUserView({ data, handlers, ...props }) {
  const role = useLoggedInUserRole();
  const { userId: actingUserId } = useAuthState('entityId');

  return canEdit(role, actingUserId, data) ? (
    <UserView.PayeeUserAdmin data={data} {...props} {...handlers} />
  ) : (
    <UserView.PayeeUserViewSelf data={data} {...props} />
  );
}

function getViewChildren({ id, entityId }) {
  return [
    <VeloBookended.Bookend key="payor" gridGutter>
      <PayorNameFragment payorId={entityId} />
    </VeloBookended.Bookend>,
    <PayorUsersTagsRoute key="tags" entityId={id} payorId={entityId} />,
  ];
}

function useUserViewPresenter({ userId, availableRoles }, fixedProps) {
  const presenterProps = useMemo(
    () => ({
      userId,
      availableRoles,
    }),
    [userId, availableRoles]
  );

  const [loader, props] = usePresenter(
    UserViewPresenter,
    entitySpec,
    fixedProps,
    presenterProps
  );

  const [result] = useCallbackFnAsResultState(loader);
  return [result, props];
}

function PayorUserViewRoute({ role, match, ...props }) {
  const matchParams = match ? match.params : {};
  const intl = useIntl();

  const payorRoles = useMemo(
    () => ({
      [UserRoles.PayorMaster]: UserRoles.Labels(intl)[UserRoles.PayorMaster],
      [UserRoles.PayorAdmin]: UserRoles.Labels(intl)[UserRoles.PayorAdmin],
      [UserRoles.PayorSupport]: UserRoles.Labels(intl)[UserRoles.PayorSupport],
    }),
    [intl]
  );

  const [result, { onClose, onEdit, handlers }] = useUserViewPresenter(
    {
      ...matchParams,
      availableRoles: payorRoles,
    },
    payorNotifications
  );

  return (
    <VeloModalSheet
      open={!!match}
      onClose={onClose}
      disableEscapeKey={!!matchParams.onTop}
    >
      {forkResult(
        payorForks,
        result,
        {
          handlers,
          ...props,
          onClose,
          onEdit,
          roles: payorRoles,
          role,
        },
        role === UserRoles.BOPAdmin ? getViewChildren : () => null
      )}
    </VeloModalSheet>
  );
}

function BackOfficeUserViewRoute({ match }) {
  const matchParams = match ? match.params : {};
  const intl = useIntl();
  const backOfficeRoles = useMemo(
    () => ({
      [UserRoles.BOPAdmin]: UserRoles.Labels(intl)[UserRoles.BOPAdmin],
    }),
    [intl]
  );
  const [result, { onClose, onEdit, handlers }] = useUserViewPresenter(
    {
      ...matchParams,
      availableRoles: backOfficeRoles,
    },
    backofficeNotifications
  );

  return (
    <VeloModalSheet open={!!match} onClose={onClose}>
      {forkResult(backOfficeForks, result, {
        handlers,
        onClose,
        roles: backOfficeRoles,
        onEdit,
      })}
    </VeloModalSheet>
  );
}

function PayeeUserViewRoute({ match }) {
  const matchParams = match ? match.params : {};
  const intl = useIntl();
  const payeeRoles = useMemo(
    () => ({
      [UserRoles.PayeeAdmin]: UserRoles.Labels(intl)[UserRoles.PayeeAdmin],
      [UserRoles.PayeeSupport]: UserRoles.Labels(intl)[UserRoles.PayeeSupport],
    }),
    [intl]
  );
  const [result, { onClose, onEdit, handlers }] = useUserViewPresenter(
    {
      ...matchParams,
      availableRoles: payeeRoles,
    },
    payeeNotifications
  );

  return (
    <VeloModalSheet open={!!match} onClose={onClose}>
      {forkResult(payeeForks, result, {
        handlers,
        onClose,
        roles: payeeRoles,
        onEdit,
      })}
    </VeloModalSheet>
  );
}

export { PayorUserViewRoute, BackOfficeUserViewRoute, PayeeUserViewRoute };
