import React, { useCallback, useState, useMemo } from 'react';
import { func, oneOf, string, shape, node, arrayOf } from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import omit from 'just-omit';
import styled from 'astroturf/react';
import { UserStatus, MFAStatus } from 'velo-data';
import { getCountries } from '../utils';
import { VeloCardForm } from '../VeloCardForm';
import { VeloFieldGrid } from '../VeloFieldGrid';
import { VeloPhoneNumberField } from '../VeloPhoneNumberField';
import { VeloSelect } from '../VeloSelect';
import { VeloSwitch } from '../VeloSwitch';
import { VeloButton } from '../VeloButton';
import { VeloTextField } from '../VeloTextField';
import { VeloGridLoading } from '../VeloGridLoading';
import { VeloLabelledItem } from '../VeloLabelledItem';
import { VeloCallout } from '../VeloCallout';
import { ProfileField, Email } from '../FormFields/FormFieldTypes';
import { ConfirmationDialog } from '../ConfirmationDialog';
import { OTPAdminDialog } from '../OTPAdminDialog';
import { MFATypes } from '../MFATypes';
import { UserRoles } from '../UserRoles';
import { VeloStepper } from '../VeloStepper';
import { LookupTextField } from '../LookupTextField';
import { onChange as entityAwareChangeHandler } from '../useFieldGridForm';
import { formatCallbackErrorArg } from '../utils';

const root = 'user-form';

const TestIds = {
  ...VeloCardForm.testIds,
  CREATE: `${root}-create`,
  EDIT: `${root}-edit`,
  LOADING: `${root}-loading`,
  UNREGISTER_MFA: `${root}-unregister-mfa`,
  UNREGISTER_CONFIRMATION: `${root}-unregister-confirmation`,
  UNREGISTER_ACCEPT: `${root}-confirmation-accept`,
  UNREGISTER_CANCEL: `${root}-confirmation-cancel`,
  DISABLE_STATUS_CONFIRMATION: `${root}-disable-status-confirmation`,
  DISABLE_STATUS_ACCEPT: `${root}-disable-status-accept`,
  DISABLE_STATUS_CANCEL: `${root}-disable-status-cancel`,
  ACTION_BUTTON: `${root}-action-button`,
  STATUS_TOGGLE: `${root}-status-toggle`,
};

const PhoneNumber = (defaultCountry) => ({
  Component: VeloPhoneNumberField,
  defaultCountry,
});

const StatusLabels = {
  PENDING: <FormattedMessage defaultMessage="Pending" />,
  ENABLED: <FormattedMessage defaultMessage="Enabled" />,
  DISABLED: <FormattedMessage defaultMessage="Disabled" />,
};

const FormMode = {
  CREATE: 'create',
  EDIT: 'edit',
};

const ToggleGridRow = styled('section')`
  display: grid;
  grid-template-columns: 8rem 1fr;
`;

const GridRow = styled('section')`
  display: grid;
  grid-template-columns: 1fr 9rem;
`;

const InlineButton = styled(VeloButton)`
  min-width: 8rem;
  margin-left: 1rem;
  margin-top: 10px;
`;

const SupportUserWithSMSCallout = () => {
  return (
    <VeloCallout type={VeloCallout.types.INFO}>
      <FormattedMessage defaultMessage="Two-factor authentication must be upgraded before role can be changed." />
    </VeloCallout>
  );
};

const MFAUnregister = ({
  showUnregisterMFA,
  onUnregisterMfa,
  mfaType,
  ...other
}) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [error, setError] = useState(false);

  return (
    <>
      <GridRow>
        <div>
          <VeloTextField {...other} disabled={true} />
        </div>
        {onUnregisterMfa && showUnregisterMFA && (
          <InlineButton
            /**
             * The unregister MFA button sits inline in a <form /> element along with the submit button.
             * Adding type="button" stops the default behaviour which assumes this is a submit button.
             */
            type="button"
            data-testid={TestIds.UNREGISTER_MFA}
            onClick={(event) => {
              event.preventDefault();
              setShowConfirmation(true);
            }}
          >
            <FormattedMessage defaultMessage="Unregister" />
          </InlineButton>
        )}
      </GridRow>
      {error && <VeloCardForm.SubmitError error={error} />}

      <ConfirmationDialog
        open={showConfirmation}
        dialogType={{
          title: <FormattedMessage defaultMessage="Unregister device?" />,
          body: (
            <FormattedMessage defaultMessage="Are you sure you want to unregister this two-factor authentication device? This operation cannot be undone." />
          ),
          acceptLabel: <FormattedMessage defaultMessage="Unregister" />,
          acceptProps: { 'data-testid': TestIds.UNREGISTER_ACCEPT },
          cancelLabel: <FormattedMessage defaultMessage="Cancel" />,
          cancelProps: { 'data-testid': TestIds.UNREGISTER_CANCEL },
        }}
        data-testid={TestIds.UNREGISTER_CONFIRMATION}
        onClose={(event) => {
          setShowConfirmation(false);
          if (event.target.action === 'accept') {
            setError(undefined);
            onUnregisterMfa({ mfaType }, (error) => {
              setError(error);
            });
          }
        }}
      />
    </>
  );
};

function ToggleField({ labelProps, error, ...props }) {
  return (
    <>
      <ToggleGridRow>
        <VeloLabelledItem {...labelProps} />
        <VeloSwitch {...props} data-testid={TestIds.STATUS_TOGGLE} />
      </ToggleGridRow>
      {error && <VeloCardForm.SubmitError error={error} />}
    </>
  );
}

/**
 * Input fields to render in the payor user form
 */
const names = [
  'firstName',
  'lastName',
  'email',
  'smsNumber',
  'primaryContactNumber',
  'secondaryContactNumber',
  'role',
  'mfaType',
];

/**
 * Input fields to render in the payee user form
 */
const payeeFieldNames = [
  'firstName',
  'lastName',
  'email',
  'smsNumber',
  'primaryContactNumber',
  'role',
  'mfaType',
];

/**
 * Default omitted keys (used by internal state only)
 */
const defaultOmitted = ['payor', 'payorError'];

/**
 * Optional read only fields to display
 */
const displayFieldOrder = [
  {
    name: 'payorName',
    label: <FormattedMessage defaultMessage="Payor Name" />,
  },
  {
    name: 'companyName',
    label: <FormattedMessage defaultMessage="Company Name" />,
  },
];

const OTP_ERROR_REASON_CODE = 'otpRequired';

const otpRequired = (error) => error === OTP_ERROR_REASON_CODE;

const formatErrorCode = (error) =>
  error &&
  error.errors &&
  error.errors.length &&
  error.errors[0].reasonCode === OTP_ERROR_REASON_CODE
    ? error.errors[0].reasonCode
    : error;

// When creating a new user we want to default the initially
// selected role and MFA type to something sensible.
const initialRole = (intl, mode, roles, initialValues, inputNames) => {
  if (mode === FormMode.CREATE && roles.length === 1) {
    const role = roles[0].value;

    return {
      role,
      ...(inputNames.includes('mfaType')
        ? {
            mfaType: initialValues.mfaType,
          }
        : {}),
    };
  }
  return { role: initialValues.role, mfaType: initialValues.mfaType };
};

function getDisplayField(displayField, value) {
  return {
    ...displayField,
    value,
    children: value,
    Component: VeloLabelledItem,
  };
}

function getDisplayFields(displayFieldOrder, data) {
  return displayFieldOrder.reduce((displayFields, { name, ...props }) => {
    const value = data && data[name];
    // Omit display fields which have no value
    if (value === undefined) {
      return displayFields;
    }

    return [...displayFields, getDisplayField({ name, ...props }, value)];
  }, []);
}

function displayFieldGuard(
  inlineDisplayFields,
  originalField,
  data,
  formatDisplayValue = (v) => v
) {
  if (inlineDisplayFields.includes(originalField.name)) {
    const value = data[originalField.name] || originalField.value;
    return value
      ? getDisplayField(
          {
            name: originalField.name,
            label: originalField.label,
          },
          formatDisplayValue(value)
        )
      : undefined;
  } else {
    return originalField;
  }
}

function useStatus({ mode, onSubmitStatus, data = {} }) {
  const [status, setStatus] = useState(data.status);
  const [submittingStatus, setSubmittingStatus] = useState(false);
  const [statusError, setStatusError] = useState(undefined);

  const statusField = {
    Component: ToggleField,
    checked: status === UserStatus.ENABLED,
    name: 'status',
    value: '',
    error: statusError,
    'data-testid': TestIds.TOGGLE_STATUS,
    disabled: submittingStatus || data.status === UserStatus.PENDING,
    onChange: (event) => {
      const nextStatus = event.target.checked
        ? UserStatus.ENABLED
        : UserStatus.DISABLED;
      setSubmittingStatus(true);
      setStatusError();

      onSubmitStatus(nextStatus, (error) => {
        setSubmittingStatus(false);

        if (error) {
          setStatusError(error);
        } else {
          setStatus(nextStatus);
        }
      });
    },
    labelProps: {
      label: <FormattedMessage defaultMessage="User Status" />,
      children: StatusLabels[status],
    },
  };

  if (mode === FormMode.EDIT) {
    return statusField;
  }
}

// Field names for extra fields added into the grid which are not part of the
// api data
const AdditionalFieldNames = {
  mfaRegistration: 'mfaRegistration',
  supportUserWithSMSInfo: 'supportUserWithSMSInfo',
};

const MfaStatusLabelMap = (intl) => ({
  [MFAStatus.VERIFIED]: intl.formatMessage({ defaultMessage: 'Verified' }),
  [MFAStatus.REGISTERED]: intl.formatMessage({ defaultMessage: 'Registered' }),
  [MFAStatus.UNREGISTERED]: intl.formatMessage({
    defaultMessage: 'Unregistered',
  }),
});

const useUserForm = ({
  onClose,
  onSubmit,
  onUnregisterMfa,
  onSubmitStatus,
  onSendOtp,
  defaultCountry,
  data = undefined,
  mode,
  isSupportUserWithSMS = false,
  isBackOfficeEditingAdminOrSupport = false,
  roles,
  requiredFields = [
    'firstName',
    'lastName',
    'email',
    'smsNumber',
    'primaryContactNumber',
    'role',
    'mfaType',
  ],
  inlineDisplayFields = [],
  title,
  inputNames = names,
}) => {
  const intl = useIntl();

  const initialValues = inputNames.reduce(
    (acc, key) => ({
      ...acc,
      [key]: data && data[key] !== undefined ? data[key] : '',
    }),
    {}
  );

  const [{ submitting, error, body, showOtp }, setState] = useState({
    submitting: false,
    error: undefined,
    body: {
      ...initialValues,
      ...initialRole(intl, mode, roles, initialValues, inputNames),
    },
    showOtp: false,
  });

  const dirty =
    Object.entries(body).reduce(
      (acc, [key, value]) => (value === initialValues[key] ? acc : acc + 1),
      0
    ) > 0;

  const setShowOtp = (showOtp) => setState((state) => ({ ...state, showOtp }));

  const displayFields = getDisplayFields(displayFieldOrder, data);
  const statusField = useStatus({
    mode,
    data,
    onSubmitStatus,
  });

  const onChange = useMemo(() => {
    function setBody(delta) {
      setState(({ body, ...state }) => ({
        ...state,
        body: {
          ...body,
          ...delta,
          // Reset MFA type if a new role is selected and the mfaType is SMS
          ...(delta.hasOwnProperty('role') && body.mfaType === MFATypes.SMS
            ? { mfaType: '' }
            : {}),
        },
      }));
    }

    // returns the onChange handler, target is used to avoid persisting the event
    return (event) => entityAwareChangeHandler(event, setBody);
  }, [setState]);

  const sections = [
    {
      fields: [
        ...displayFields,
        /**
         * Back Office cannot edit status on payor admin and support users
         */
        ...(isBackOfficeEditingAdminOrSupport ? [] : [statusField]),
      ].filter((item) => item),
    },
    ...[
      {
        heading: <FormattedMessage defaultMessage="User account details" />,
        fields: [
          {
            ...ProfileField(
              <FormattedMessage defaultMessage="First name" />,
              <FormattedMessage defaultMessage="Please enter a first name" />,
              1,
              50
            ),
            name: 'firstName',
            required: requiredFields.includes('firstName'),
          },
          {
            ...ProfileField(
              <FormattedMessage defaultMessage="Last name" />,
              <FormattedMessage defaultMessage="Please enter a last name" />,
              1,
              50
            ),
            name: 'lastName',
            required: requiredFields.includes('lastName'),
          },
          {
            ...PhoneNumber(defaultCountry),
            label: <FormattedMessage defaultMessage="Primary contact number" />,
            name: 'primaryContactNumber',
            required: requiredFields.includes('primaryContactNumber'),
          },
          {
            ...PhoneNumber(defaultCountry),
            label: (
              <FormattedMessage defaultMessage="Secondary contact number" />
            ),
            name: 'secondaryContactNumber',
            required: requiredFields.includes('secondaryContactNumber'),
          },
        ],
      },
      {
        heading: <FormattedMessage defaultMessage="Secure contact details" />,
        fields: [
          {
            ...Email(
              <FormattedMessage defaultMessage="Login email address" />,
              <FormattedMessage defaultMessage="Please enter a valid email address" />
            ),
            name: 'email',
            required: requiredFields.includes('email'),
          },
          {
            ...PhoneNumber(defaultCountry),
            label: <FormattedMessage defaultMessage="SMS phone number" />,
            name: 'smsNumber',
            required: requiredFields.includes('smsNumber'),
          },
        ],
      },
      {
        heading: <FormattedMessage defaultMessage="Other details" />,
        fields: [
          {
            Component: VeloSelect,
            label: <FormattedMessage defaultMessage="Role" />,
            name: 'role',
            required: requiredFields.includes('role'),
            /**
             * The back office user can only change an admin or support to
             * their current role or master admin.
             */
            options: isBackOfficeEditingAdminOrSupport
              ? roles.filter(
                  (role) =>
                    role.value === data.role ||
                    role.value === UserRoles.PayorMaster
                )
              : roles,
            disabled: isSupportUserWithSMS,
            helpText: {
              validationMsg: true,
              children: (
                <FormattedMessage defaultMessage="Please select a user role" />
              ),
            },
          },
          {
            Component: VeloSelect,
            label: (
              <FormattedMessage defaultMessage="Two-factor authentication type" />
            ),
            name: 'mfaType',
            required: requiredFields.includes('mfaType'),
            // Filter options based on the selected role
            options: MFATypes.options(
              intl,
              mode === FormMode.EDIT ? data.role : body.role
            ),
            disabled: body.role === '',
            helpText: {
              validationMsg: true,
              children: (
                <FormattedMessage defaultMessage="Please select a two-factor authentication method" />
              ),
            },
          },
          ...(isSupportUserWithSMS
            ? [
                {
                  Component: SupportUserWithSMSCallout,
                  value: '',
                  name: AdditionalFieldNames.supportUserWithSMSInfo,
                },
              ]
            : []),
          ...(mode === FormMode.EDIT && !isSupportUserWithSMS
            ? [
                {
                  Component: MFAUnregister,
                  value: MfaStatusLabelMap(intl)[data.mfaStatus],
                  onUnregisterMfa,
                  label: (
                    <FormattedMessage defaultMessage="Two-factor authentication status" />
                  ),
                  name: AdditionalFieldNames.mfaRegistration,
                  mfaType: data.mfaType,
                  showUnregisterMFA:
                    !UserRoles.isPayee(data.role) &&
                    (data.mfaStatus === MFAStatus.VERIFIED ||
                      data.mfaStatus === MFAStatus.REGISTERED),
                },
              ]
            : []),
        ],
      },
    ].map((section) => ({
      ...section,
      fields: section.fields
        .map((field) =>
          displayFieldGuard(
            inlineDisplayFields,
            {
              value: body[field.name],
              ...field,
            },
            data,
            field.name === 'mfaType'
              ? (value) => MFATypes.Labels(intl)[value]
              : undefined
          )
        )
        .filter(
          (item) =>
            item &&
            [...inputNames, ...Object.values(AdditionalFieldNames)].includes(
              item.name
            )
        ),
    })),
  ];

  const allFields = sections.reduce(
    (acc, { fields }) => [...acc, ...fields],
    []
  );

  const onSubmitHandler = useCallback(
    (body) => {
      function formatSubmitBody({ role, ...profile }) {
        const statusFields =
          mode === FormMode.CREATE
            ? ['status', ...defaultOmitted]
            : defaultOmitted;
        const formattedBody = isBackOfficeEditingAdminOrSupport
          ? { role }
          : { role, ...profile };

        return omit(formattedBody, [...statusFields]);
      }

      setState((state) => ({ ...state, submitting: true, error: undefined }));
      onSubmit(formatSubmitBody(body), (error) => {
        formatCallbackErrorArg(allFields, (error) => {
          setState((state) => ({
            ...state,
            submitting: false,
            error: otpRequired(error) ? undefined : error,
            showOtp: otpRequired(error),
          }));
        })(formatErrorCode(error));
      });
    },
    [isBackOfficeEditingAdminOrSupport, mode, onSubmit, allFields]
  );

  return {
    body,
    onChange,
    formProps: {
      title,
      onClose,
      onSubmit: (event) => {
        event.preventDefault();
        onSubmitHandler(body);
      },
      buttonProps: {
        ...{
          [FormMode.CREATE]: {
            disabled: false,
            children: submitting ? (
              <FormattedMessage defaultMessage="Creating..." />
            ) : (
              <FormattedMessage defaultMessage="Create" />
            ),
          },
          [FormMode.EDIT]: {
            disabled: !dirty,
            children: submitting ? (
              <FormattedMessage defaultMessage="Saving..." />
            ) : (
              <FormattedMessage defaultMessage="Save" />
            ),
          },
        }[mode],
        'data-testid': TestIds.ACTION_BUTTON,
      },
      overlay: submitting,
      error,
      dirty,
    },
    otpProps: {
      open: showOtp,
      title,
      user: `${body.firstName} ${body.lastName}`,
      mobile: body.smsNumber,
      onSendCode: onSendOtp,
      onClose: () => setShowOtp(false),
      onSubmit: (otpBody) => {
        setShowOtp(false);
        // Submit the form with the verification code
        onSubmitHandler({ ...body, ...otpBody });
      },
    },
    gridProps: {
      sections,
      onChange,
    },
  };
};

const sharedProps = {
  /**
   * Called when the form is closed.
   */
  onClose: func.isRequired,
  /**
   * Called when the form is submitted.
   * Passed the form data and a callback function to use
   * for submit errors.
   */
  onSubmit: func.isRequired,

  /**
   * The default country code.
   * Used for initially seleected country in the phone number components.
   */
  defaultCountry: oneOf(getCountries()),
  /**
   * The list of available roles.
   */
  roles: arrayOf(
    shape({
      label: node.isRequired,
      value: string.isRequired,
    })
  ).isRequired,
};

PayorUserCreateForm.propTypes = {
  ...sharedProps,
  /**
   * Called when an OTP code needs to be sent to a user's phone.
   */
  onSendOtp: func.isRequired,
  data: shape({ payorName: string.isRequired }),
};

PayorUserCreateForm.testIds = TestIds;

/**
 * Form used when creating a new Payor User.
 */
function PayorUserCreateForm(props) {
  const { formProps, gridProps, otpProps } = useUserForm({
    ...props,
    mode: FormMode.CREATE,
    title: <FormattedMessage defaultMessage="Create Payor User" />,
  });
  return (
    <>
      <VeloCardForm slimline {...formProps}>
        <VeloFieldGrid {...gridProps} />
      </VeloCardForm>
      <OTPAdminDialog {...otpProps} />
    </>
  );
}

const renderStep = ({ children, disabled }) => (disabled ? null : children);

const toSteps = ({ gridProps, body, onChange }, fetchResults) => {
  const sections = [
    {
      fields: [
        {
          name: 'payor',
          label: <FormattedMessage defaultMessage="Payor" />,
          value: body.payor,
          Component: LookupTextField,
          mode: LookupTextField.modes.PAYOR,
          helpText: {
            children: ' ',
          },
          entityId: body.payorId,
          fetchResults,
          required: true,
        },
      ],
    },
  ];

  return [
    {
      title: <FormattedMessage defaultMessage="Select a payor" />,
      children: (
        <VeloFieldGrid compact sections={sections} onChange={onChange} />
      ),
    },
    {
      title: <FormattedMessage defaultMessage="Create a user" />,
      disabled: !body.payorId,
      children: <VeloFieldGrid compact {...gridProps} />,
    },
  ];
};

PayorUserBackOfficeCreateForm.propTypes = {
  ...sharedProps,
  /**
   * Called to lookup the payor
   */
  fetchResults: func.isRequired,
  /**
   * Called when an OTP code needs to be sent to a user's phone.
   */
  onSendOtp: func.isRequired,
  data: shape({
    payor: string.isRequired,
    payorId: string.isRequired,
  }),
};

PayorUserBackOfficeCreateForm.testIds = TestIds;

function PayorUserBackOfficeCreateForm(props) {
  const { formProps, otpProps, ...options } = useUserForm({
    ...props,
    mode: FormMode.CREATE,
    title: <FormattedMessage defaultMessage="Create Payor User" />,
    inputNames: ['payorId', 'payor'].concat(names),
  });

  return (
    <>
      <VeloCardForm slimline {...formProps}>
        <VeloStepper
          steps={toSteps(options, props.fetchResults)}
          renderStep={renderStep}
        />
      </VeloCardForm>
      <OTPAdminDialog {...otpProps} />
    </>
  );
}

BackOfficeUserCreateForm.propTypes = {
  ...sharedProps,
};

/**
 * Form used when creating a new BackOffice User.
 */
function BackOfficeUserCreateForm(props) {
  const { formProps, gridProps } = useUserForm({
    ...props,
    mode: FormMode.CREATE,
    title: <FormattedMessage defaultMessage="Create Admin User" />,
  });
  return (
    <VeloCardForm slimline {...formProps}>
      <VeloFieldGrid {...gridProps} />
    </VeloCardForm>
  );
}

BackOfficeUserCreateForm.propTypes = {
  ...sharedProps,
};

/**
 * Form used when creating a new Payee User.
 */
function PayeeUserCreateForm(props) {
  const { formProps, gridProps } = useUserForm({
    ...props,
    mode: FormMode.CREATE,
    title: <FormattedMessage defaultMessage="Create User" />,
    inputNames: payeeFieldNames,
  });

  return (
    <VeloCardForm slimline {...formProps}>
      <VeloFieldGrid {...gridProps} />
    </VeloCardForm>
  );
}

PayeeUserCreateForm.propTypes = {
  ...sharedProps,
};

/**
 * Variant of the create back office user form which is used with KeyCloak / OIDC
 * create user endpoint.
 */
function BackOfficeUserCreateFormOIDC(props) {
  const { formProps, gridProps } = useUserForm({
    ...props,
    mode: FormMode.CREATE,
    title: <FormattedMessage defaultMessage="Create Admin User" />,
    inputNames: ['firstName', 'lastName', 'email', 'smsNumber', 'role'],
  });
  return (
    <VeloCardForm slimline {...formProps}>
      <VeloFieldGrid {...gridProps} />
    </VeloCardForm>
  );
}

const editFormSharedProps = {
  /**
   * The form data, keyed on the component `name`.
   */
  data: shape({
    status: oneOf(Object.keys(StatusLabels)).isRequired,
    firstName: string.isRequired,
    email: string.isRequired,
    smsNumber: string,
    primaryContactNumber: string.isRequired,
    secondaryContactNumber: string,
    role: string.isRequired,
    mfaType: MFATypes.propType.isRequired,
    mfaStatus: oneOf(Object.values(MFAStatus)),
  }).isRequired,
  /**
   * Called when unregistering the MFA device.
   */
  onUnregisterMfa: func.isRequired,
};

PayorUserEditForm.propTypes = {
  ...sharedProps,
  ...editFormSharedProps,
};

PayorUserEditForm.testIds = {
  ...TestIds,
  ...ConfirmationDialog.testIds,
};

function PayorUserEditForm({ isBackOfficeEditingAdminOrSupport, ...props }) {
  /**
   * The back office can only change the role field on an admin or support user
   *
   * If the admin or support user is on TOTP or YubiKey
   */
  const inlineDisplayFields = isBackOfficeEditingAdminOrSupport
    ? [
        'firstName',
        'lastName',
        'email',
        'smsNumber',
        'primaryContactNumber',
        'secondaryContactNumber',
        'mfaType',
        'mfaRegistration',
      ]
    : [];

  const { formProps, gridProps } = useUserForm({
    ...props,
    mode: FormMode.EDIT,
    title: <FormattedMessage defaultMessage="Edit Payor User" />,
    inlineDisplayFields,
    isBackOfficeEditingAdminOrSupport,
  });

  return (
    <>
      <VeloCardForm slimline {...formProps}>
        <VeloFieldGrid {...gridProps} />
      </VeloCardForm>
    </>
  );
}

BackOfficeUserEditForm.propTypes = {
  ...sharedProps,
  ...editFormSharedProps,
  // Not required
  onSendOtp: func,
};

PayorUserEditForm.testIds = {
  ...TestIds,
  ...ConfirmationDialog.testIds,
};

function BackOfficeUserEditForm(props) {
  const { formProps, gridProps } = useUserForm({
    ...props,
    mode: FormMode.EDIT,
    title: <FormattedMessage defaultMessage="Edit Admin User" />,
  });

  return (
    <VeloCardForm slimline {...formProps}>
      <VeloFieldGrid {...gridProps} />
    </VeloCardForm>
  );
}

function PayeeUserEditForm(props) {
  const { formProps, gridProps } = useUserForm({
    ...props,
    mode: FormMode.EDIT,
    title: <FormattedMessage defaultMessage="Edit Payee User" />,
    inputNames: payeeFieldNames,
  });

  return (
    <VeloCardForm slimline {...formProps}>
      <VeloFieldGrid {...gridProps} />
    </VeloCardForm>
  );
}

LoadingForm.propTypes = {
  /**
   * Called when the close button is clicked.
   */
  onClose: func.isRequired,
};

LoadingForm.testIds = { ...TestIds, ...VeloCardForm.testIds };

function LoadingForm({ onClose }) {
  const fields = (count) =>
    [...Array(count).keys()].map(() => ({
      type: VeloGridLoading.fieldTypes.TextField,
    }));

  return (
    <VeloCardForm.Loading
      slimline
      onClose={onClose}
      data-testid={TestIds.LOADING}
    >
      <VeloGridLoading
        sections={[
          {
            heading: true,
            fields: fields(2),
          },
          {
            heading: true,
            fields: fields(4),
          },
          {
            heading: true,
            fields: fields(3),
          },
        ]}
      />
    </VeloCardForm.Loading>
  );
}

PayorUserEditForm.secureDetailsOTPDialogTitles = {
  onSubmit: <FormattedMessage defaultMessage="Update Secure Details" />,
  onSubmitStatus: <FormattedMessage defaultMessage="Update User Status" />,
  onUnregisterMfa: <FormattedMessage defaultMessage="Unregister Device" />,
};

const UserForm = {
  CreatePayor: PayorUserCreateForm,
  CreatePayorBackOffice: PayorUserBackOfficeCreateForm,
  EditPayor: PayorUserEditForm,
  CreateBackOffice: BackOfficeUserCreateForm,
  CreateBackOfficeOIDC: BackOfficeUserCreateFormOIDC,
  EditBackOffice: BackOfficeUserEditForm,
  EditPayee: PayeeUserEditForm,
  CreatePayee: PayeeUserCreateForm,
  Loading: LoadingForm,
  testIds: TestIds,
};

export { UserForm };
