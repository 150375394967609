import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { bool, string, func, node } from 'prop-types';

import { dataTestIdBuilder } from 'velo-data';
import { useScrollLock } from '../../hooks';
import { VeloScrim } from '../../VeloScrim';
import {
  NavigationDrawerTopAppBar,
  NavigationDrawerTopAppBarImage,
} from '../NavigationDrawerTopAppBar';
import { TopBarMode } from '../NavigationDrawerTopBarMode';
import { Transitions } from '../../Transitions';
import { navigationDrawerConfig } from '../navigationDrawerConfig';
import { NavigationDrawerMobileNestedMenu } from '../NavigationDrawerNestedMenu';

import styles from './VeloDrawer.module.scss';
import navigationDrawerPropTypes from '../navigationDrawerPropTypes';

const root = navigationDrawerConfig.NavigationDrawerRoot;

const TestIds = {
  DRAWER: `${root}`,
  SCRIM: `${root}-drawer-scrim`,
  LOGO: `${root}-logo`,
};

VeloDrawer.testIds = TestIds;

VeloDrawer.propTypes = {
  /** Whether the navigation drawer is in mobile mode. */
  isMobile: bool,
  /** Whether the navigation drawer is open. */
  isNavigationDrawerOpen: bool,
  /** Function to handle when the drawer is closed */
  onDrawerClose: func.isRequired,
  /** Function to handle when a sub menu is closed */
  onSubMenuClose: func.isRequired,
  /** Function to handle when the navigation drawer is toggled */
  onToggleNavigation: func.isRequired,
  /** Function to handle a navigation list action */
  onListAction: func.isRequired,
  /** The title of the current page */
  title: node,
  /** The selected sub menu parent item */
  selectedSubMenuParent: navigationDrawerPropTypes.subMenuItemParent,
  /** The selected sub menu item id*/
  selectedSubMenuItemId: string,
  /** Whether there is a sub menu open  */
  isSubMenuOpen: bool,
  /** The child content */
  children: node,
};

function VeloDrawer({
  isMobile,
  isNavigationDrawerOpen,
  onDrawerClose,
  onSubMenuClose,
  onToggleNavigation,
  onListAction,
  children,
  title,
  selectedSubMenuParent,
  selectedSubMenuItemId,
  selectedSubMenuLabel,
  subNavOpenId,
}) {
  const navigationDrawerRef = useScrollLock(isNavigationDrawerOpen && isMobile);
  const [scrollTarget, setScrollTarget] = useState(null);
  useEffect(
    () => void setScrollTarget(navigationDrawerRef.current),
    [isNavigationDrawerOpen, isMobile, navigationDrawerRef]
  );

  /** Determine which animation is required for the sub menu depending on the mobile state */
  const NavigationAnimation = isMobile
    ? Transitions.SlideLeft
    : Transitions.NavigationDrawerPushMenu;

  return (
    <>
      {/* Scrim for mobile mode to prevent background being interactive when the drawer is open*/}

      {isMobile && (
        <VeloScrim
          data-testid={TestIds.SCRIM}
          open={isNavigationDrawerOpen}
          onClick={onDrawerClose}
        />
      )}

      <NavigationAnimation in={isNavigationDrawerOpen} unmountOnExit={isMobile}>
        <aside
          className={classnames(styles.drawer, {
            [styles.collapsed]: !isNavigationDrawerOpen && !isMobile,
            [styles.open]: isNavigationDrawerOpen && !isMobile,
            [styles.mobile]: isMobile,
          })}
          data-testid={TestIds.DRAWER}
          onClose={onDrawerClose}
        >
          {isMobile && (
            <NavigationDrawerMobileNestedMenu
              onSubMenuClose={onSubMenuClose}
              selectedSubMenuParent={selectedSubMenuParent}
              isSubMenuOpen={!!subNavOpenId}
              onListAction={onListAction}
              selectedSubMenuItemId={selectedSubMenuItemId}
              title={selectedSubMenuLabel}
            />
          )}

          <NavigationDrawerTopAppBar
            isNavigationDrawerOpen={isNavigationDrawerOpen}
            isMobile={isMobile}
            title={title}
            handleToggle={onToggleNavigation}
            showAppBarContent={true}
            mode={TopBarMode.MENU}
            scrollTarget={scrollTarget}
            className="menu"
          >
            <NavigationDrawerTopAppBarImage
              isNavigationDrawerOpen={isNavigationDrawerOpen}
              data-testid={dataTestIdBuilder(TestIds.LOGO, TopBarMode.MENU)}
            />
          </NavigationDrawerTopAppBar>

          <div ref={navigationDrawerRef} className={styles.scrollContent}>
            {children}
          </div>
        </aside>
      </NavigationAnimation>
    </>
  );
}

export { VeloDrawer };
