const compareDateStrings = (from, to) => {
  const a = new Date(from).getTime();
  const b = new Date(to).getTime();
  return a < b ? -1 : a > b ? 1 : 0;
};

const toISODateString = (date) =>
  date ? date.toISOString().split('T')[0] : '';

const ISOStringToDate = (isoString) =>
  isoString ? new Date(isoString) : undefined;

const removeTimezoneOffset = (date) =>
  date
    ? new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())
    : undefined;

const ISODateStringToUTCDateTimeString = (
  isoString,
  { endDate = false } = {}
) => {
  const date = ISOStringToDate(isoString);

  return new Date(
    Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      endDate ? date.getUTCDate() + 1 : date.getUTCDate()
    )
  ).toISOString();
};

export {
  compareDateStrings,
  toISODateString,
  ISOStringToDate,
  removeTimezoneOffset,
  ISODateStringToUTCDateTimeString,
};
