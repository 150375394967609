import { SecondaryValueType } from 'velo-data';
import { SearchPresenterCore } from './SearchPresenterCore';

// Constructor query param limits the versatililty of this Presenter
// we may want to look at tweaking this to allow search function (displayName)
// to take a payorId - or a query - and map to a flattened structure for the Payee
// (hoisting remoteID etc.. where applicable )
export function PayeeSearchPresenter(entity, query) {
  const match = query.payorId ? (id) => id === query.payorId : () => false;
  const reducer = (xs, { payorId, remoteId: value }) =>
    xs.concat(
      match(payorId) ? { type: SecondaryValueType.REMOTE_ID, value } : []
    );
  return SearchPresenterCore(
    entity.getPayees,
    (displayName) => ({ ...query, displayName, pageSize: 5 }),
    ({ displayName, payeeId, email, payorRefs }) => ({
      value: displayName,
      entityId: payeeId,
      secondaryValues: payorRefs.reduce(reducer, [email]),
    })
  );
}
