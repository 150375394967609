import { useContext } from 'react';
import { Context } from '../../context';
import { mapPromiseResponseWithCallback } from '../helpers';

function useLoginAPI() {
  const { api } = useContext(Context);
  const onLogin = mapPromiseResponseWithCallback(api.login);
  const onSubmitOtp = mapPromiseResponseWithCallback(api.verifyMFA);
  const unregisterMFA = mapPromiseResponseWithCallback(api.unregisterMFA);

  return {
    onLogin,
    onSubmitOtp,
    unregisterMFA,
  };
}

export { useLoginAPI };
