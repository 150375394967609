import omit from 'just-omit';
import { querySelectors } from '../selectors';

export function createListRedirectionLoader(redirect, keys, query, cont) {
  const omitKeys = querySelectors.selectAllDisplayValueKeys(keys);
  return function loader(listQuery, displayValues, cb) {
    // if the current state filter does not match, then sync to url instead of loading
    if (querySelectors.queriesNotEqual(keys, query, listQuery)) {
      return redirect(
        querySelectors.selectQueryString(keys, listQuery, displayValues)
      );
    }
    return cont({ ...omit(query, omitKeys), ...listQuery }, cb);
  };
}
