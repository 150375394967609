import React from 'react';
import { func, string, oneOf } from 'prop-types';
import {
  PaymentChannelForm,
  VeloNotification,
  VeloPropTypes,
} from 'velo-react-components';
import { sanitizeDataByPaymentChannelRules } from 'velo-data';
import { Context } from '../../context';
import {
  formatError,
  paymentChannelRulesSelector,
  supportedPaymentCountries,
  formatBankLookupError,
} from '../../selectors';

/**
 * Container used to manage a the create new Payment Channels.
 * */
class PaymentChannelsUpsertContainer extends React.Component {
  static propTypes = {
    /**
     * Called when rendering child components. The current
     * state will be injected as props.
     */
    render: func.isRequired,
    /** A payeeId that is used to filter the list */
    payeeId: string,
    /** A paymentChannelId for edit mode */
    channelId: string,
    /** Used to request additional data depending on mode **/
    type: oneOf(Object.values(PaymentChannelForm.mode)).isRequired,
    /** An array of payment channel rules fetched from the API */
    allPaymentChannelRules: VeloPropTypes.paymentChannelRulesType(),
    /** An array of countries fetched from the API */
    allCountries: VeloPropTypes.countriesType(),
    /** Function to increment the cache status */
    refreshData: func,
    /** Function called on componentDidMount to setup the form */
    onLoad: func.isRequired,
  };

  // Access to the main app context
  static contextType = Context;

  state = {};
  handlers = {
    onSubmit: (data) => {
      if (this.props.type === PaymentChannelForm.mode.CREATE) {
        this.props.handlers.onCreatePaymentChannel(data, (err) =>
          this.setState({
            error: formatError(err),
          })
        );
      } else {
        this.props.handlers.onUpdatePaymentChannel(
          this.sanitizeFormData(data),
          (err) =>
            this.setState({
              error: formatError(err),
            })
        );
      }
    },
    onEnablePaymentChannel: () => {
      const { payeeId, channelId } = this.props;
      this.props.handlers.onEnablePaymentChannel(
        { payeeId, paymentChannelId: channelId },
        (err) => {
          if (err) {
            this.setState(() => ({
              error: formatError(err),
            }));
          } else {
            this.props.refreshData();
            this.props.onComplete();
            this.context.requestNotification({
              ...VeloNotification.types.PAYMENT_CHANNEL_ENABLE_SUCCESS,
              key: Date.now().toString(10),
            });
          }
        }
      );
    },
    onCountryChange: (event) => {
      const currencies = this.selectableCurrencies(event);
      this.setState(() => ({
        currencies,
        selectedCountry: event,
        paymentChannelRules: this.getSelectedChannelRules(
          event,
          this.state.allPaymentChannelRules
        ),
      }));
    },
    onBankLookup: (channel) => {
      this.setState(
        () => ({
          error: undefined,
        }),
        //only send valid payment channel fields to the bank lookup
        this.loadBankLookup(this.sanitizeFormData(channel))
      );
    },
  };
  // Formats the data for submit.
  // This includes only submitting fields entered based on the
  // given paymentChannelsRules
  sanitizeFormData = (data) => {
    //sanitize the data for submit so that only the correct fields for the payment channel rules
    //are submitted.
    return sanitizeDataByPaymentChannelRules(
      data,
      this.state.paymentChannelRules
    );
  };

  setSelectableCurrencies = (countryCode) => {
    const curr = this.selectableCurrencies(countryCode);
    this.setState(() => ({
      currencies: curr,
      currency: curr[0],
    }));
  };

  selectableCurrencies = (countryCode) => {
    let currenciesByCountry = this.props.allCountries.find(
      (currency) => currency.isoCountryCode === countryCode
    );
    return currenciesByCountry.currencies;
  };

  //get the selected channel rules for the given country code
  getSelectedChannelRules = (countryCode) => {
    return paymentChannelRulesSelector(
      countryCode,
      this.props.allPaymentChannelRules
    );
  };

  setPaymentChannelOptions = (channel) => {
    const { currency, countryCode } = channel;

    this.setState(() => ({
      channel,
      currency,
      selectedCountry: countryCode,
      countries: supportedPaymentCountries(
        [
          ...this.props.allCountries.map(
            ({ isoCountryCode }) => isoCountryCode
          ),
        ],
        this.props.allPaymentChannelRules
      ),
      paymentChannelRules: this.getSelectedChannelRules(countryCode),
    }));

    this.setSelectableCurrencies(countryCode);
  };

  loadBankLookup = (channel) => {
    const { countryCode, currency, routingNumber, iban } = channel;
    this.context.api
      .banklookup({
        country: countryCode,
        currency: currency,
        ...(routingNumber && { routingNumber }),
        ...(iban && { iban }),
      })
      .then(
        (payload) => [
          {
            banklookup: { result: payload },
          },
        ],
        // Error
        (ex) => [
          {
            banklookup: {},
            data: {
              result: undefined,
            },
            error: formatBankLookupError(ex, this.state.paymentChannelRules),
            validating: false,
          },
        ]
      )
      // Update the state
      .then(([state, updater]) => this.setState(state, updater));
  };

  componentDidMount() {
    this.props.onLoad((err, payload) =>
      err
        ? this.setState(() => ({
            error: formatError(err),
          }))
        : this.setPaymentChannelOptions(payload)
    );
  }

  render() {
    return this.props.render({
      ...this.props,
      ...this.state,
      ...this.handlers,
    });
  }
}

export { PaymentChannelsUpsertContainer };
