import { PayoutStates } from 'velo-data';
import { LookupTextField } from 'velo-react-components';
import {
  formatError,
  payoutSelectors,
  pipeCallbackFunctions,
  formatCallbackErrorArg,
} from '../../selectors';

export function PayoutReviewPresenter(
  wireFrame,
  entity,
  { payoutId, payorId, isAdmin, isBop, intentionalExecutionDelay = 500 },
  {
    instructFailure,
    instructSuccess,
    withdrawFailure,
    withdrawSuccess,
    scheduleSuccess,
    descheduleSuccess,
    descheduleFailure,
    submissionFailed,
  }
) {
  function mapPayoutPaymentData(content) {
    return content
      ? content.map((payment) => {
          const { payee } = payment;
          return {
            ...payment,
            displayName: getPayeeName(payee),
          };
        })
      : [];
  }

  function getReadableRejectionReasons(data) {
    // Use message instead of reason when possible
    return data.message
      ? data.message.split('\n').map((s) => s[0].toUpperCase() + s.slice(1))
      : data.reason;
  }

  function getRejectedErrorSet(rejectedPayments) {
    rejectedPayments = rejectedPayments ? rejectedPayments : [];
    const errorSet = [
      ...new Set(
        rejectedPayments.flatMap((rjp) => getReadableRejectionReasons(rjp))
      ),
    ];
    return {
      errors: errorSet.length > 0 ? errorSet : null, // explicitly no errors (not just unknown empty list)
      count: rejectedPayments.length,
    };
  }

  function mapRejectionErrors(rejectedPayments) {
    return rejectedPayments.reduce((accumulator, currentValue) => {
      let newRow = [];
      // verbose but ensures matched indexes
      newRow[0] = currentValue.remoteSystemId;
      newRow[1] = currentValue.remoteId;
      newRow[2] = currentValue.currencyType;
      newRow[3] = currentValue.amount;
      newRow[4] = currentValue.sourceAccountName;
      newRow[5] = currentValue.transmissionType;
      newRow[6] = currentValue.payorPaymentId;
      newRow[7] = currentValue.paymentMemo;
      newRow[8] = currentValue.reason;
      newRow[9] = currentValue.reasonCode;
      newRow[10] = currentValue.message;

      return accumulator + newRow + '\n';
    }, `remoteSystemId,remoteId,currencyType,amount,sourceAccountName,transmissionType,payorPaymentId,paymentMemo,reason,reasonCode,message\n`);
  }

  function getResultError(status) {
    // calculate the error as - some non API errors require an error message
    return status === PayoutStates.REJECTED ? submissionFailed : null;
  }

  function sourceAccountName(payout) {
    const accountName = payoutSelectors.selectSourceAccount(payout)
      ? payoutSelectors.selectSourceAccount(payout)
      : payoutSelectors.selectSourceAccountFromAcceptedPayment(
          payout.acceptedPayments ? payout.acceptedPayments[0] : null
        );
    return accountName ? accountName : '';
  }

  function getCanInstructProps(data) {
    // In Hierarchical payments
    // Where:
    // submitting = Logged in Actioning PayorUser {payorId}
    // payoutFrom = The PayorID of the Payor SourceAccount (where the cash is coming from)
    // payoutTo = The PayorID of the Payor who has the Payee relationship (where its going)

    // Combinations:  Submitted > From > To

    //STANDARD - When all equal its payout from a sole Payor to their Payees (Parent used as example)
    // Parent > Parent > Parent

    // ON-BEHALF - Payor uses their own SourceAccount To Pay a child's Payees. payorFrom or submitting can Instruct
    // Parent > Parent > Child
    // Parent > Parent > Baby
    // Child > Child > Baby

    // AS/ON-BEHALF - Act as a child Payor and use the child's Account to pay Payees,
    // or a child of etc... submitting Payor or payorFrom can Instruct
    // (NB payout is not visible in the list for submitting Payor once navigated from the review screen)
    // Parent > Child > Child
    // Parent > Child > Baby
    // Parent-AS-Child > Child > Child
    // Parent-AS-Child > Child > Baby
    // Parent-AS-Baby > Baby > Baby
    // Child-AS-Baby > Baby > Baby

    // In the case of the BOP the payorId value is undefined so we extract it
    // from the flattened data - using the payoutTo Payor as they own the Payees

    const payoutToPayorId = payorId
      ? payorId
      : payoutSelectors.selectPayorToId(data);

    const fromMe = payoutSelectors.selectPayorFromId(data) === payoutToPayorId;

    const onBehalfOfChild =
      payoutSelectors.selectPayorSubmittingId(data) === payorId;

    //Only Admin(NB excludes BOP) + we have data + we are one of the Hierarchy who can instruct
    const canInstruct =
      isAdmin && payoutToPayorId && (fromMe || onBehalfOfChild);

    const instructEnabled = data.status === PayoutStates.QUOTED;
    return { canInstruct, payoutToPayorId, instructEnabled };
  }

  // As the data is not from the Payee service ( that would be up to 20 further API calls) we dont have a displayName
  function getPayeeName(payee) {
    if (!payee) return '';

    if (payee.individual) {
      return (
        payee.individual.name.firstName +
        (payee.individual.name.middleName
          ? ' ' + payee.individual.name.middleName + ' '
          : ' ') +
        payee.individual.name.lastName
      );
    } else {
      return payee.company.companyName;
    }
  }

  function getQuery(query) {
    if (query && query.entityId) {
      // 2 searches direct RemoteId and PayeeName by RemoteId
      // Flatten query to use the most accurate (direct remoteId) if both are provided)
      const { remoteId, entityId, ...sendQuery } = query;
      return { remoteId: remoteId ? remoteId : entityId, ...sendQuery };
    }

    return query;
  }

  //*** Entity API ***

  // Payouts from PayoutService - use for pagination/filter fo the table
  function getPayoutTableData(query, setStateCb) {
    entity.getPayoutPayments(payoutId, getQuery(query), (error, result) => {
      setStateCb({ error: formatError(error), result });
    });
  }

  // getPaymentsByPayout - From Payment Audit
  // This is required as the API summary from Payout Service is not complete
  // So we need to pull this API *JUST* to get the summary details that are missing
  function getPaymentsByPayout(cb) {
    entity.getPaymentsByPayout(payoutId, cb);
  }

  function instructPayout() {
    entity.instructPayout(payoutId, (error) => {
      if (error) {
        wireFrame.sendNote(instructFailure);
      } else {
        setTimeout(() => {
          wireFrame.sendNote(instructSuccess);
          returnToRoot();
        }, intentionalExecutionDelay);
      }
    });
  }

  function schedulePayout(payload, cb) {
    entity.schedulePayout(
      payoutId,
      payload,
      formatCallbackErrorArg((error) => {
        if (error) {
          cb(error);
        } else {
          cb();
          wireFrame.sendNote(scheduleSuccess, true);
        }
      })
    );
  }

  function deschedulePayout() {
    entity.deschedulePayout(payoutId, (error) => {
      if (error) {
        wireFrame.sendNote(descheduleFailure);
      } else {
        wireFrame.sendNote(descheduleSuccess, true);
      }
    });
  }

  function withdrawPayout() {
    entity.withdrawPayout(payoutId, (error) => {
      if (error) {
        wireFrame.sendNote(withdrawFailure);
      } else {
        setTimeout(() => {
          wireFrame.sendNote(withdrawSuccess);
          returnToRoot();
        }, intentionalExecutionDelay);
      }
    });
  }

  function getQuote(cb) {
    entity.quotePayout(payoutId, (error, result) => {
      // Intentionally ignore a Quote Error as this does not affect the loading of the screen data
      // Auto-Re-quote silently polls to resolve any error:
      // * conflict 409 withdraw - Payout is data displayed and quoted silently
      // * any error during create => wwe have the payments from PayoutService, show what we have and Poll silently
      // * any re-quote cycle - failure triggers another silent re-quote, rather than rendering an error
      cb(undefined, result);
    });
  }

  function getPayoutSummary(cb) {
    entity.getPayoutSummary(payoutId, cb);
  }

  // ** WireFrame  Functions**
  function returnToRoot() {
    wireFrame.navigateToPayoutsList();
  }

  function redirectToWithdrawn() {
    wireFrame.navigateToPayoutWithdrawn.redirect({ payoutId });
  }

  function downloadErrors(failuresList) {
    wireFrame.downloadContentAsFile(
      mapRejectionErrors(failuresList),
      'Payout_Errors_List.csv',
      'text/csv;encoding:utf-8'
    );
  }

  // ** load and quotePayout Helpers **/
  /** set of helper functions for composition to public load/reQuote functions**/

  function pipeQuote(data, cb) {
    // Dont quote is !Admin or Rejected - just pass through
    isAdmin && data && data.status !== PayoutStates.REJECTED
      ? getQuote((error, result) => cb(error, { ...data, ...result }))
      : cb(undefined, data);
  }
  function pipePayments(data, cb) {
    entity.getPayoutPayments(payoutId, {}, (error, result) => {
      cb(error, {
        ...data,
        ...result,
      });
    });
  }
  function pipeSummary(data, cb) {
    getPayoutSummary((error, result) => {
      cb(error, { ...data, ...result });
    });
  }

  function pipePayoutPaymentAudit(data, cb) {
    getPaymentsByPayout((error, result) => {
      cb(error, {
        ...data,
        ...(result ? result.summary : {}),
      });
    });
  }

  // Note: this is intentionally verbose so as not to obfuscate
  // the behavior/madness nor hide the API sequence which needs Backend Work...
  const load = (cb) =>
    pipeCallbackFunctions(
      /** First get the Payout to ensure its Quote-able/Instruct-able - See last API call */
      getPayoutSummary,
      // Quote when !isAdmin => 403
      // Quote when Rejected is pointless and returns a conflict error 409
      pipeQuote,
      /** Get the payments from the Payout service */
      pipePayments,
      /* We have to call getPayoutSummary again to get the refreshed details post QUOTE.
    This allows us to display Totals for the Payout that are accurate to the QUOTE
    NB without this its not available in the first call - or stale/wrong
    */
      pipeSummary,
      // This is Last as it hits PaymentAudit which is slow
      // We dont care about the payments in this just the Summary
      // Should not be required as result.summary
      // should be in above API response
      // To be removed when the API is updated - provides Hierarchy and other details not included in getPayoutSummary
      pipePayoutPaymentAudit
    )(cb);

  /** Re-quote and Update Sequence **/
  const quotePayout = pipeCallbackFunctions(
    /** Basic Re-quote - Call Quote API */
    getQuote,
    /* Update with new SummaryData - used primarily for SubmittedDate and Hierarchy*/
    pipePayoutPaymentAudit,
    /* Update the the SourceAccount Totals refreshed by the Quote*/
    pipeSummary
  );

  // combine data for route component props
  function constructDataProps(data) {
    const { error, rejectedPayments, status, fxSummaries, content } = data;

    return {
      ...data,
      content: mapPayoutPaymentData(content),
      error: error ? error : getResultError(status, fxSummaries),
      sourceAccountName: sourceAccountName(data),
      rejectedPaymentErrors: {
        onClick: () => downloadErrors(rejectedPayments),
        ...getRejectedErrorSet(rejectedPayments),
      },
      onClick: (payment) => {
        wireFrame.navigateToPayoutPaymentReview({
          payoutId,
          paymentId: payment.paymentId,
        });
      },
    };
  }

  function constructTableProps(tableData, queryProps, payoutPayorId) {
    const { pageProps, filterProps, ...props } = queryProps;
    // add the fetchResult for call back - defined in column but amended here....
    const updatedFields = filterProps.fields.map((field) =>
      field.mode === LookupTextField.modes.PAYEE
        ? {
            ...field,
            fetchResults: (displayName, cb) =>
              fetchPayees(displayName, payoutPayorId, cb),
          }
        : field
    );

    return {
      ...props,
      filterProps: {
        ...filterProps,
        fields: updatedFields,
      },
      pageProps: {
        ...pageProps,
        ...(tableData.page ? tableData.page : {}),
      },
    };
  }

  // Provide props object by merging data (from load/refresh) and updated quoteData
  function getProps(loadData, quoteData, tableData, queryProps) {
    // Error - load error trumps subsequent errors - as they wont be triggered
    const error = loadData.error
      ? loadData.error
      : tableData.error
      ? tableData.error
      : undefined;
    //construct a flattened version of the data providing
    // a simplified object for consumption and
    const data = {
      error,
      ...(loadData.result ? loadData.result : {}),
      ...(quoteData.result ? quoteData.result : {}),
      ...(tableData.result ? tableData.result : {}),
    };

    // splice in the schedule data to each payment if available
    // payment data from v3/payouts is only a subset of the data on the payment
    // which can be retrieved from payment audit - to save this we splice in the
    // data that is already available on the payout itself (loadData.result)
    if (data && data.content) {
      data.content = data.content.map((payment) => ({
        ...payment,
        schedule: data.schedule,
      }));
    }

    const { canInstruct, payoutToPayorId, instructEnabled } =
      getCanInstructProps(data);

    return {
      actions: {
        onWithdraw: isAdmin || isBop ? withdrawPayout : null,
        onInstruct: canInstruct ? instructPayout : null,
        onSchedule: schedulePayout,
        onDeschedule: deschedulePayout,
        instructEnabled,
      },
      onClose: returnToRoot,
      ...constructDataProps(data),
      ...constructTableProps(data, queryProps, payoutToPayorId),
      isQuotable: !isBop,
    };
  }

  function fetchPayees(displayName, payorId, cb) {
    const matchPayorRefPayorId =
      (id) =>
      ({ payorId }) =>
        payorId === id;
    entity.getPayees({ displayName, payorId }, (error, { content, page }) => {
      cb(error, {
        result: content.map(({ displayName, email, payorRefs }) => {
          const { remoteId } = payorRefs.find(matchPayorRefPayorId(payorId));
          const secondaryValues = [email, `Remote ID: ${remoteId}`];
          return {
            value: displayName,
            entityId: remoteId,
            remoteId,
            secondaryValues,
          };
        }),
        totalResults: page.totalElements,
      });
    });
  }

  return {
    load,
    quotePayout,
    getPayoutTableData,
    getProps,
    redirectToWithdrawn,
    fetchPayees,
  };
}
