/* istanbul ignore file */
import React from 'react';
import * as uuid from 'uuid';
import { useWireframe } from '../../hooks';
import { useHistory } from 'react-router'; // eslint-disable-line import/no-unresolved
import { loadModule } from './loadRemoteModule';
import { useAppContext } from '../../context';
import { AppErrorBoundary } from '../../containers';
import { VeloAppLoading } from 'velo-react-components';

function RemoteRouteMountPoint({ remoteName, url, ...props }) {
  const [, setError] = React.useState(null);
  const parentElementId = uuid.v4();
  const { api } = useAppContext();
  const wireframe = useWireframe(useHistory());
  const cleanup = React.useRef(null);

  React.useEffect(() => {
    async function getModule() {
      try {
        const { inject, unmount } = await loadModule(
          remoteName,
          url,
          'AppInjector'
        );

        inject(parentElementId, {
          federatedFetch: api.sendWithPromise,
          wireframe,
          ...props,
        });

        cleanup.current = unmount;
      } catch (error) {
        setError(() => {
          throw error;
        });
      }
    }

    getModule();
  }, [api, cleanup, parentElementId, props, remoteName, url, wireframe]);

  React.useEffect(() => {
    return () => {
      if (cleanup.current) {
        cleanup.current();
      }
    };
  }, []);

  return <div id={parentElementId}></div>;
}

function RemoteRoute(props) {
  return (
    <AppErrorBoundary fallback={<VeloAppLoading.Error />}>
      <RemoteRouteMountPoint {...props} />
    </AppErrorBoundary>
  );
}

export { RemoteRoute };
