import React from 'react';
import { string, bool } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { VeloTypography } from '../VeloTypography';

PaymentChannelViewType.propTypes = {
  /** The Payment Channel Type as returned by the API (e.g. "CHANNEL_BANK") */
  channelType: string.isRequired,
  /** Render in the disabled state. */
  disabled: bool,
};

/**
 * Display the payment Payment Channel Type.
 */
export function PaymentChannelViewType({ channelType, disabled }) {
  return (
    <VeloTypography use="sectionHeader" tag="h2">
      <FormattedMessage
        defaultMessage="{channelType, select, CHANNEL_BANK {Bank Account} other {}} Details"
        values={{ channelType }}
      />
    </VeloTypography>
  );
}
