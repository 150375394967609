import styled from 'astroturf/react';
import { VeloTypography } from '../VeloTypography';

export const VeloCardErrorMessage = styled(VeloTypography).attrs({
  use: 'bodyTextEmphasised',
  tag: 'div',
})`
  flex-grow: 2;
  margin: 0.5rem 0.5rem 0.5rem 0rem;
`;
