export function deleteUserByIdV2(send) {
  return (userId, ...args) =>
    send(
      {
        path: '/v2/users/{userId}',
        pathParameters: { userId },
        type: 'delete',
        query: undefined,
        body: undefined,
        headers: undefined,
        auth: 'oauth2',
        contentType: undefined,
      },
      ...args
    );
}
