import React, { useEffect, useReducer, useCallback } from 'react';
import { elementType, func, shape, string } from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import memoize from 'fast-memoize';
import curry from 'just-curry-it';
import { VeloIcon } from '../VeloIcon';
import { VeloButton } from '../VeloButton';
import { VeloFieldGrid } from '../VeloFieldGrid';

const ACTION_TYPES = {
  ADD: 'add',
  REMOVE: 'remove',
  UPDATE_BY_ID: 'update-by-id',
};

const reducer = (state, { fieldId: id, actionType, name, value, ...field }) => {
  // eslint-disable-next-line default-case
  switch (actionType) {
    case ACTION_TYPES.ADD:
      return [...state, { id, ...field }];
    case ACTION_TYPES.UPDATE_BY_ID:
      return state.map((field) =>
        field.id === id ? { ...field, [name]: value } : field
      );
    case ACTION_TYPES.REMOVE:
      return state.filter(({ id: fieldId }) => fieldId !== id);
  }
};

VeloRepeatingField.propTypes = {
  onChange: func.isRequired,
  name: string.isRequired,
  Field: elementType.isRequired,
  buttonProps: shape({
    'data-testid': string.isRequired,
  }),
};

const createNewFieldId = () => performance.now() + Math.random();

function VeloRepeatingField({
  onChange,
  name,
  Field,
  buttonProps,
  formatValueOnCreate = (value) => value,
  fieldMapper = (v) => v,
  initialValues,
  isExtensible = true,
}) {
  const [fields, dispatch] = useReducer(reducer, [
    ...(Array.isArray(initialValues)
      ? initialValues.map((value) => ({
          ...formatValueOnCreate(value),
          id: createNewFieldId(),
        }))
      : [
          formatValueOnCreate({
            id: createNewFieldId(),
          }),
        ]),
  ]);
  const intl = useIntl();

  useEffect(() => {
    onChange({
      target: { name, value: fields.map(({ id, ...field }) => field) },
    });

    return () => {
      onChange({
        target: { name, value: [] },
      });
    };
  }, [fields, onChange, name]);

  //eslint-disable-next-line react-hooks/exhaustive-deps
  const updateFieldProperty = useCallback(
    memoize((fieldId) =>
      curry((fieldName, updateEvent) => {
        return dispatch({
          actionType: ACTION_TYPES.UPDATE_BY_ID,
          fieldId,
          name: fieldName,
          value: updateEvent.target.value,
        });
      })
    ),
    [dispatch]
  );

  const addField = (e) => {
    e.preventDefault();

    return dispatch({
      ...formatValueOnCreate({
        fieldId: createNewFieldId(),
      }),
      actionType: ACTION_TYPES.ADD,
    });
  };

  const removeField = memoize((fieldId) => (e) => {
    e.preventDefault();

    return dispatch({
      actionType: ACTION_TYPES.REMOVE,
      fieldId,
    });
  });

  return (
    <>
      {fields && (
        <VeloFieldGrid
          compact
          breakpoint={100}
          sections={[
            {
              fields: fields.map((field, index) => ({
                Component: Field,
                key: field.id,
                updateFieldProperty: updateFieldProperty(field.id),
                removeField:
                  isExtensible && fields.length > 1
                    ? removeField(field.id)
                    : undefined,
                intl,
                value: '',
                name: '',
                index,
                ...fieldMapper(field, fields),
              })),
            },
          ]}
        />
      )}
      {isExtensible && (
        <VeloButton onClick={addField} {...buttonProps}>
          <VeloIcon icon={{ icon: 'add', size: 'xsmall' }} />
          <FormattedMessage defaultMessage="Add another field" />
        </VeloButton>
      )}
    </>
  );
}

export { VeloRepeatingField };
