import { useCallback } from 'react';
import { useInviteUserAPI } from 'velo-api-react-hooks';
import { replaceEmptyStringsWithNull } from '../../selectors';

const formatBody = ({ role, ...body }) =>
  replaceEmptyStringsWithNull({ ...body, roles: [role] });

const useInviteUser = ({ entityId, userType, onSuccess }) => {
  const inviteUser = useInviteUserAPI();

  const onSubmit = useCallback(
    (body, cb) => {
      inviteUser({ entityId, userType, ...formatBody(body) }, (error) => {
        if (error) {
          cb(error);
        } else {
          onSuccess();
        }
      });
    },
    [entityId, inviteUser, userType, onSuccess]
  );

  return onSubmit;
};

export { useInviteUser };
