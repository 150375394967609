import React from 'react';
import { number, node } from 'prop-types';
import styled from 'astroturf/react';
import { VeloLinearProgress } from '../VeloLinearProgress';
import { VeloTypography } from '../VeloTypography';

const ProgressContainer = styled('div')`
  @import 'velo-variables';

  background: velo-color('token-color-brand-primary-background');
  border-radius: 0.1rem;
`;

const Title = styled('div')`
  @import 'velo-variables';

  padding-top: 0.5rem;
  padding-bottom: 0.25rem;
  color: velo-color('token-color-brand-primary');
  text-align: center;
`;

const Bar = styled('div')`
  @import 'velo-variables';

  padding: 10px 20px 5px 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media (max-width: velo-breakpoint(XS)) {
    flex-direction: row;
    align-items: center;
  }
`;

const Label = styled(VeloTypography).attrs({
  tag: 'span',
  use: 'bodyTextSmaller',
})`
  @import 'velo-variables';

  margin-top: 0.4rem;

  @media (max-width: velo-breakpoint(XS)) {
    margin-top: 0.25rem;
    margin-left: 0.5rem;
    flex: 1 0 auto;
    text-align: right;
    font-size: 0.75rem;
  }
`;

VeloProgressIndicator.propTypes = {
  /** Progress value range 0-1, omission displays polling anumation */
  progress: number,
  /** Sub label describing the progress state i.e. 1 of 10 */
  label: node,
  /** Title header */
  title: node,
};

function VeloProgressIndicator({ progress, label, title, ...other }) {
  const rootTestId = other['data-testid']
    ? other['data-testid']
    : 'velo-progress-indicator';
  return (
    <ProgressContainer data-testid={rootTestId}>
      <Title>
        <VeloTypography
          tag="span"
          use="bodyTextSmaller"
          data-testid={`${rootTestId}-progress-title`}
        >
          {title}
        </VeloTypography>
      </Title>
      <Bar>
        <VeloLinearProgress
          data-testid={`${rootTestId}-progress`}
          progress={progress}
        />
        <Label data-testid={`${rootTestId}-progress-label`}>{label}</Label>
      </Bar>
    </ProgressContainer>
  );
}
export { VeloProgressIndicator };
