import * as React from 'react';
import { Route } from 'react-router';
import { z } from 'zod';
import { useAppContext } from '../context';
import { RemoteRoute } from '../routes/RemoteRoute/RemoteRoute';
import { useOperatingUser } from './app';

const remoteRoutesConfigSchema = z.object({
  entities: z.array(z.string().min(1)),
  appConfig: z
    .object({
      navigationLabel: z.string().min(1),
      navigationGroup: z.number(),
      navigationOrder: z.number(),
      navigationIcon: z.string().min(1),
      path: z.string().min(1),
    })
    .required(),
  remoteName: z.string().min(1),
  url: z.string().min(1),
});

export function useRemoteRoutes() {
  const appContext = useAppContext();
  const payorId = useOperatingUser();

  const remoteRoutesConfig =
    appContext.remoteModules && appContext.remoteModules[appContext.appVariant];

  const remoteNavigationEntities = React.useMemo(
    () =>
      remoteRoutesConfig
        ? Object.values(remoteRoutesConfig).reduce(
            (accumulator, remoteModule) => {
              if (
                remoteModule.entities.includes(payorId) ||
                (remoteModule.entities.includes('*') &&
                  remoteRoutesConfigSchema.safeParse(remoteModule).success)
              ) {
                return {
                  navigationItems: [
                    ...accumulator.navigationItems,
                    {
                      id: remoteModule.remoteName,
                      label: remoteModule.appConfig.navigationLabel,
                      group: remoteModule.appConfig.navigationGroup,
                      order: remoteModule.appConfig.navigationOrder,
                      icon: remoteModule.appConfig.navigationIcon,
                      dividers: { top: false, bottom: false },
                    },
                  ],
                  routes: {
                    ...accumulator.routes,
                    [remoteModule.remoteName]: remoteModule.appConfig.path,
                  },
                  components: [
                    ...accumulator.components,
                    <Route
                      path={remoteModule.appConfig.path}
                      key={remoteModule.remoteName}
                    >
                      <RemoteRoute
                        remoteName={remoteModule.remoteName}
                        url={remoteModule.url}
                        config={remoteModule.appConfig}
                      />
                    </Route>,
                  ],
                };
              }

              return accumulator;
            },
            { navigationItems: [], routes: {}, components: [] }
          )
        : undefined,
    [payorId, remoteRoutesConfig]
  );

  return {
    remoteRoutes: remoteNavigationEntities?.components ?? [],
    remoteNavigationEntities: {
      navigationItems: remoteNavigationEntities?.navigationItems ?? [],
      routes: remoteNavigationEntities?.routes ?? {},
    },
  };
}
