import React from 'react';
import { bool } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styled from 'astroturf/react';
import { VeloTypography } from '../../VeloTypography';
import { VeloIcon } from '../../VeloIcon';
import { VeloResponsiveText } from '../../VeloResponsiveText';

const Layout = styled('div')`
  display: flex;
  align-items: flex-start;
`;

const Icon = styled(VeloIcon)`
  @import 'velo-variables';

  color: velo-color('token-color-system-warning-default');
  margin-right: 0.5rem;
  margin-left: 0;
`;

const DisabledMessage = styled('div')`
  @import 'velo-variables';

  background-color: velo-color('token-color-system-warning-lighter');
  padding: 0.1rem 1rem 1rem;

  &.edit {
    margin-top: 1rem;
  }

  &.backoffice {
    padding-bottom: 0.1rem;
  }
`;

DisabledMessage.propTypes = {
  edit: bool,
  backoffice: bool,
};

const Title = styled(VeloTypography)`
  display: flex;
  align-items: center;
`;

const MessageContent = styled(VeloTypography)`
  width: 100%;
`;

const Link = styled(VeloTypography)`
  font-weight: normal;
  text-align: right;
  flex: 1;
`;

const DisabledReason = styled('div')`
  padding-bottom: 1rem;
`;

const root = 'velo-payment-channel-disabled';
const TestIds = {
  DISABLED: `${root}`,
  MESSAGE: `${root}-message`,
  ENABLE_BUTTON: `${root}-enable`,
  ENABLE_INSTRUCTION: `$velo-payment-channel-enable-instruction`,
};

PaymentChannelDisabled.testIds = TestIds;

/**
 * Render a Payment Channel Disabled Content.
 */
function PaymentChannelDisabled({ editMode, onEnable, disabledReason }) {
  const backoffice = onEnable !== undefined;
  return (
    <>
      <DisabledMessage
        data-testid={TestIds.DISABLED}
        edit={editMode}
        backoffice={backoffice}
      >
        <Title use="sectionHeader" tag="h2">
          <Icon icon="warning" />
          <FormattedMessage defaultMessage="Payment Method Disabled" />
          {backoffice && (
            <Link
              tag="a"
              use="linkText"
              href="#enable"
              data-testid={TestIds.ENABLE_BUTTON}
              onClick={onEnable}
            >
              <FormattedMessage defaultMessage="Enable" />
            </Link>
          )}
        </Title>
        <Layout>
          <MessageContent
            use="bodyText"
            tag="span"
            data-testid={TestIds.MESSAGE}
          >
            <DisabledReason>{disabledReason}</DisabledReason>

            {!backoffice && !editMode && (
              <VeloTypography tag="div" use="bodyText">
                <FormattedMessage defaultMessage="To re-enable the payment method, use edit and follow the instructions." />
              </VeloTypography>
            )}

            {editMode && (
              <FormattedMessage
                defaultMessage="If the information below is incorrect, please fill in the correct details and {click} save."
                values={{
                  click: (
                    <VeloResponsiveText
                      mobile={<FormattedMessage defaultMessage="press" />}
                      desktop={<FormattedMessage defaultMessage="click" />}
                      data-testid={TestIds.ENABLE_INSTRUCTION}
                    />
                  ),
                }}
              />
            )}
          </MessageContent>
        </Layout>
      </DisabledMessage>
    </>
  );
}

export { PaymentChannelDisabled };
