import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { VeloStatusIndicator } from '../../../VeloStatusIndicator';

// Supported funding status types
const FundingStatusTypes = {
  INSTRUCTED: {
    type: VeloStatusIndicator.types.INFO,
    label: <FormattedMessage defaultMessage="Instructed" />,
  },
  FUNDED: {
    type: VeloStatusIndicator.types.SUCCESS,
    label: <FormattedMessage defaultMessage="Funded" />,
  },
  UNFUNDED: {
    type: VeloStatusIndicator.types.FAILED,
    label: <FormattedMessage defaultMessage="Not Funded" />,
  },
};

FundingStatus.propTypes = {
  /** The funding status. */
  status: string.isRequired,
};

/**
 * Render a Payor Payment Funding Status indicator.
 */
function FundingStatus({ status, ...props }) {
  const { type, label } = FundingStatusTypes[status] || {
    type: 'unknown',
    label: status,
  };
  return <VeloStatusIndicator type={type} label={label} {...props} />;
}

export { FundingStatus };
