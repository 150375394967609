/**
 * Extracts the api environment from hostname
 * e.g. `preview` from 1234.preview.velointra.net.
 *
 * @param {string} hostname
 */
const getEnv = (hostname) =>
  (hostname &&
    hostname.split('.') &&
    hostname.split('.').length > 1 &&
    hostname.split('.').reverse()[2]) ||
  'localhost';

export const getAPIEnvironment = (
  hostname,
  defaultHost,
  promptForHost = process.env.REACT_APP_PR
) => ({
  env: getEnv(hostname),
  // using number cast for Boolean values and string interpolation
  host: Number(promptForHost)
    ? prompt('Enter the API base URL', defaultHost)
    : defaultHost,
});
