export function createFileDownloadHandler({
  location,
  document,
  navigator,
  URL,
}) {
  /**
   * Takes a string and downloads it to the specified filename
   * @param {string} content A string containing the content of the file
   * @param {string} fileName The name of the file to download the content to
   * @param {string} [mimeType='application/octet-stream'] The mimeType of the content
   */
  function downloadContentAsFile(content, fileName, mimeType) {
    var a = document.createElement('a');
    mimeType = mimeType || 'application/octet-stream';

    if (navigator.msSaveBlob) {
      // IE10
      navigator.msSaveBlob(
        new Blob([content], {
          type: mimeType,
        }),
        fileName
      );
    } else if (URL && 'download' in a) {
      //html5 A[download]
      a.href = URL.createObjectURL(
        new Blob([content], {
          type: mimeType,
        })
      );
      a.setAttribute('download', fileName);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } else {
      location.href =
        'data:application/octet-stream,' + encodeURIComponent(content); // only this mime type is supported
    }
  }
  return downloadContentAsFile;
}
