import { formatCallbackErrorArg } from '../selectors';

export function SearchPresenterCore(searchFn, mapQuery, mapContentItem) {
  return (query, upstreamCallback) => {
    return searchFn(
      mapQuery(query),
      formatCallbackErrorArg((error, data) => {
        if (error) return upstreamCallback(error);
        upstreamCallback(undefined, {
          totalResults: data.page.totalElements,
          result: data.content.map(mapContentItem),
        });
      })
    );
  };
}
