const getMfaDetails = (state) =>
  state && state.user_info && state.user_info.mfa_details
    ? state.user_info.mfa_details
    : {};

const getMfaType = (state) => {
  return getMfaDetails(state).mfa_type;
};

const getMfaVerified = (state) => {
  return getMfaDetails(state).verified;
};

const postLoginSelector = (state) => ({
  verified: getMfaVerified(state),
  mfaType: getMfaType(state),
  showMfa: !!getMfaType(state),
});

export { postLoginSelector };
