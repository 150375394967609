import React from 'react';
import { FormattedMessage } from 'react-intl';
import { VeloTypography } from '../VeloTypography';

export function PayoutDisclaimer() {
  return (
    <VeloTypography use="footnote" tag="p">
      <FormattedMessage defaultMessage="The displayed exchange rate quote is indicative and should be used as guide only. The final exchange rate including all fees will be provided to you upon instruction of the payout." />
    </VeloTypography>
  );
}
