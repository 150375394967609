export function getFundingAccountV2(send) {
  return (fundingAccountId, query, ...args) =>
    send(
      {
        path: '/v2/fundingAccounts/{fundingAccountId}',
        pathParameters: { fundingAccountId },
        type: 'get',
        query: query,
        body: undefined,
        headers: undefined,
        auth: 'oauth2',
        contentType: undefined,
      },
      ...args
    );
}
