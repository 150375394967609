import React, { useCallback } from 'react';
import { ResetPasswordPage } from 'velo-react-components';
import { PasswordUpdateContainer } from '../../containers';
import { useAppContext } from '../../context';
import { useLegalLinksList } from '../../hooks';

const notificationBase = {
  actionText: '',
  timeout: 7000,
  type: 'success',
  context: 'login',
};

const resetNotification = {
  ...notificationBase,
  message: 'Your password has been reset',
};

const createdNotification = {
  ...notificationBase,
  message: 'Your password has been created',
};

const Notifications = {
  create: createdNotification,
  reset: resetNotification,
};

function ResetPasswordRoute({ token, mode, history }) {
  const { appVariant, requestNotification } = useAppContext();
  const legalLinks = useLegalLinksList(appVariant);

  const renderComponent = useCallback(
    (props) => (
      <ResetPasswordPage {...props} mode={mode} legalLinks={legalLinks} />
    ),
    [mode, legalLinks]
  );

  const exit = useCallback(
    (message) => {
      history.replace('/login');
      const note = message
        ? { ...notificationBase, type: 'warning', message }
        : Notifications[mode];
      requestNotification(note);
    },
    [history, requestNotification, mode]
  );

  return (
    <PasswordUpdateContainer
      token={token}
      exit={exit}
      mode={mode}
      render={renderComponent}
    />
  );
}

ResetPasswordRoute.Notifications = Notifications;

export { ResetPasswordRoute };
