import { createEarlyExitCallback } from '../../selectors';
import { objectHasProperty } from 'velo-data';
import { selectIdFromLocation } from '../../selectors';
import { paymentMethodsQueryKey } from './PaymentMethodsListPresenter';

export function Base(
  wireframe,
  entity,
  staticProps,
  { paymentMethodId, payeeId, onClose, onComplete }
) {
  const isEdit = !!paymentMethodId;
  const handlers = {
    onCreatePaymentChannel: (body, cb) =>
      entity.createPaymentChannel(
        payeeId,
        body,
        createEarlyExitCallback(
          (data) => {
            wireframe.sendNote(staticProps.notifications.create.success);
            return onComplete(data);
          },
          (error) => {
            wireframe.sendNote(staticProps.notifications.create.failure);
            return cb(error);
          }
        )
      ),
    onUpdatePaymentChannel: (body, cb) =>
      entity.updatePaymentChannel(
        payeeId,
        paymentMethodId,
        body,
        createEarlyExitCallback(
          (data) => {
            wireframe.sendNote(staticProps.notifications.update.success);
            return onComplete(data);
          },
          (error) => {
            wireframe.sendNote(staticProps.notifications.update.failure);
            return cb(error);
          }
        )
      ),
    onEnablePaymentChannel: (body, cb) =>
      entity.enablePaymentChannel(
        payeeId,
        paymentMethodId,
        createEarlyExitCallback(
          () => cb(),
          (error) => cb(error)
        )
      ),
    onClose,
    onComplete,
  };

  const formatChannelResponse = (channel) => {
    //default empty values for a create form
    const allChannels = {
      countryCode: 'US',
      currency: 'USD',
      paymentChannelName: '',
      accountName: '',
      routingNumber: '',
      accountNumber: '',
      iban: '',
      disabledReasonCode: '',
      disabledReason: '',
    };
    //set the field values for an edit form
    const editChannel = isEdit
      ? {
          countryCode: channel.countryCode,
          currency: channel.currency,
          paymentChannelName: channel.paymentChannelName,
          accountName: channel.accountName,
          routingNumber: channel.routingNumber,
          accountNumber: channel.accountNumber,
          iban: channel.iban,
          enabled: objectHasProperty(channel, 'enabled', true),
          disabledReasonCode: objectHasProperty(
            channel,
            'disabledReasonCode',
            ''
          ),
          disabledReason: objectHasProperty(channel, 'disabledReason', ''),
        }
      : {};

    return {
      ...allChannels,
      ...editChannel,
    };
  };

  const loader = (cb) =>
    isEdit
      ? entity.getPaymentChannelById(
          paymentMethodId,
          { payeeId, sensitive: true },
          createEarlyExitCallback(
            (data) => cb(undefined, formatChannelResponse(data)),
            (error) => cb(error)
          )
        )
      : cb(undefined, formatChannelResponse(undefined));

  const props = {
    paymentChannel: paymentMethodId,
    query: { sensitive: true },
    isEdit,
  };

  return [loader, handlers, props];
}

export const PaymentMethodUpsertPresenter = (
  wireframe,
  entity,
  staticProps,
  props
) => {
  const presenterResult = Base(wireframe, entity, staticProps, {
    ...props,
    onClose: wireframe.navigateToPaymentMethodsList,
    onComplete: wireframe.navigateToPaymentMethodsList,
  });

  return presenterResult;
};

export const PayorPaymentMethodUpsertPresenter = (
  wireframe,
  entity,
  staticProps,
  props
) => {
  const presenterResult = Base(wireframe, entity, staticProps, {
    ...props,
    onClose: () =>
      props.paymentMethodId
        ? wireframe.navigateToPaymentMethodDetail({
            payeeId: props.payeeId,
            paymentMethodId: props.paymentMethodId,
          })
        : wireframe.navigateToPayeePaymentMethods({
            payeeId: props.payeeId,
          }),
    onComplete: ({ location }) => {
      staticProps.queryClient.invalidateQueries({
        queryKey: [paymentMethodsQueryKey],
      });

      // get ID for new logical payment method (editing or creating both create new ids)
      const newPaymentMethodId = selectIdFromLocation(location);

      // infer if editing an existing payment method based on whether a paymentMethodId was
      // originally specified
      props.paymentMethodId
        ? wireframe.navigateToPaymentMethodDetail({
            payeeId: props.payeeId,
            paymentMethodId: newPaymentMethodId,
          })
        : wireframe.navigateToPayeePaymentMethods({
            payeeId: props.payeeId,
          });
    },
  });

  return presenterResult;
};
