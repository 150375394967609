import React from 'react';
import { VeloSkeleton } from '../VeloSkeleton';

// Default error component
const DefaultError = ({ error }) => error;

// Default empty component
const DefaultEmpty = () => null;

/**
 * HOC used to handle a result/error pair.
 *
 * When used to enhance a component that has an "error" and "result"
 * prop this component will either render the error, a loading indicator
 * (if result is undefined, defaulting to a skeleton), nothing (if result
 * is null or an empty array) or just render the wrapped component.
 *
 * Accepts an object containing overrides for the error, loading and
 * empty components.
 */
export function ResultContainer({
  ErrorComponent = DefaultError,
  LoadingComponent = VeloSkeleton,
  EmptyComponent = DefaultEmpty,
} = {}) {
  return function (BaseComponent) {
    return function (props) {
      if (props.error) {
        return <ErrorComponent {...props} />;
      }
      if (props.result === undefined) {
        return <LoadingComponent {...props} />;
      }
      if (
        props.result === null ||
        (typeof props.result.length !== 'undefined' &&
          props.result.length === 0)
      ) {
        return <EmptyComponent {...props} />;
      }
      return <BaseComponent {...props} />;
    };
  };
}
