import React from 'react';
import { FormattedMessage } from 'react-intl';
import { shape, string } from 'prop-types';
import { VeloPropTypes } from '../../../VeloPropTypes';
import { VeloButton } from '../../../VeloButton';
import { ResultContainer } from '../../../ResultContainer';
import { PaymentViewStatus } from '../../PaymentViewStatus';
import { PaymentViewItem } from '../../PaymentViewItem';
import { PaymentViewDateTime } from '../../PaymentViewDateTime';
import { PaymentViewDivider } from '../../PaymentViewDivider';
import { PaymentViewResult } from '../../PaymentViewResult';
import { PaymentViewSectionSkeleton } from '../../PaymentViewSectionSkeleton';
import { PaymentView } from '../../PaymentView';

const testId = PaymentView.root;

/** Render the payment details in the loading state. */
const LoadingComponent = () => (
  <>
    <PaymentViewSectionSkeleton length={3} />
    <PaymentViewSectionSkeleton length={4} heading />
  </>
);

/** Render the payment details. */
const BaseComponent = ({ result, onConfirm }) => {
  const showConfirmationButton = result?.status === 'ACCEPTED_BY_RAILS';

  return (
    <>
      {/* Status */}
      {result.status && (
        <>
          <PaymentViewItem
            label={<FormattedMessage defaultMessage="Payment Status" />}
            value={
              <PaymentViewStatus
                status={result.status}
                data-testid={`${testId}-status`}
              />
            }
            action={
              showConfirmationButton && (
                <VeloButton
                  label={<FormattedMessage defaultMessage="Confirm" />}
                  onClick={() => onConfirm(result)}
                  data-testid={`${testId}-confirm-payment`}
                />
              )
            }
          />
        </>
      )}

      {/* Submitted date/time */}
      <PaymentViewItem
        label={<FormattedMessage defaultMessage="Submitted Date/Time" />}
        value={
          <PaymentViewDateTime
            value={result.submittedDateTime}
            data-testid={`${testId}-datetime`}
          />
        }
      />

      {/* Payor name */}
      <PaymentViewItem
        label={<FormattedMessage defaultMessage="Payor" />}
        value={
          <PaymentViewResult
            {...result.payorName}
            errorMessage={
              <FormattedMessage defaultMessage="Error loading Payor" />
            }
            loadingTestId="velo-payment-view-payor-skeleton"
            data-testid={`${testId}-payor`}
          />
        }
      />

      {/* Memo */}
      <PaymentViewItem
        label={<FormattedMessage defaultMessage="Memo" />}
        value={result.paymentMemo}
        data-testid={`${testId}-payment-memo`}
      />

      <PaymentViewDivider />
    </>
  );
};

const Component = ResultContainer({ LoadingComponent })(BaseComponent);

PayeePaymentViewDetails.propTypes = {
  /** The data to render. */
  result: shape({
    submittedDateTime: string.isRequired,
    payorName: VeloPropTypes.result(string),
    status: string.isRequired,
    paymentMemo: string,
  }),
};

/**
 * Used to display core Payment details.
 */
function PayeePaymentViewDetails({ result, onConfirm }) {
  return <Component result={result} onConfirm={onConfirm} />;
}

export { PayeePaymentViewDetails };
