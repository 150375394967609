import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { postcodeProps, payeeSsn, payeeTaxId } from 'velo-data';
import styles from './FormFieldTypes.module.scss';

const FormFieldHelpText = {
  POSITIVE_CURRENCY: 'The value must be greater than zero',
  CURRENCY: 'The value must be zero or greater',
};

const getHelpText = (helpText) =>
  helpText
    ? {
        className: styles.helpText,
        validationMsg: true,
        children: helpText,
      }
    : undefined;

const baseTextField = (label, helpText) => ({
  label: label,
  helpText: getHelpText(helpText),
});

/**
 * Takes the helpText value and uses it to set the inputs custom HTML 5 form
 * validation message.
 *
 * See this answer for a further information on this pattern
 * https://stackoverflow.com/a/16878766/4405603
 *
 * @param {String} helpText
 */

const getCustomValidityHandlers = (helpText) => ({
  onInvalid: (event) => {
    if (event.target.setCustomValidity) {
      event.target.setCustomValidity(helpText);
    }
  },
  onInput: (event) => {
    if (event.target.setCustomValidity) {
      event.target.setCustomValidity('');
    }
  },
});

// These should be consolidated with the AddPayee Style of Form Struct
const DateOfBirth = () =>
  Custom(
    <FormattedMessage defaultMessage="Date of Birth" />,
    <FormattedMessage defaultMessage="Please enter a valid date of birth" />,
    '[0-9]{4}-(0[1-9]|1[012])-(0[1-9]|1[0-9]|2[0-9]|3[01])',
    10,
    10
  );

/**
 * Convert a payee country rule into text field props.
 */
const payeeFieldProps = ({
  displayName,
  validation,
  minLength,
  maxLength,
  description,
}) => {
  return {
    ...Custom(
      displayName,
      `Please enter your ${displayName}`,
      validation,
      minLength,
      maxLength
    ),
    placeholder: description,
  };
};

/**
 * Social Security Number/National Identifier.
 *
 * Passed in an ISO country code and an array of payee rules
 * from the API.
 */
const NationalIdentification = (isoCountryCode, payeeCountryRules) =>
  payeeFieldProps(payeeSsn(isoCountryCode, payeeCountryRules));

/**
 * Company Tax ID.
 *
 * Passed in an ISO country code and an array of payee rules
 * from the API.
 */
const TaxID = (isoCountryCode, payeeCountryRules) =>
  payeeFieldProps(payeeTaxId(isoCountryCode, payeeCountryRules));

const Email = (
  label = <FormattedMessage defaultMessage="Email Address" />,
  helpText = (
    <FormattedMessage defaultMessage="Please enter a valid Email Address." />
  )
) => ({
  ...baseTextField(label, helpText),
  ...{ type: 'email' },
});

const Password = (
  label = <FormattedMessage defaultMessage="Password" />,
  helpText = (
    <FormattedMessage defaultMessage="Please enter a valid Password." />
  )
) => ({
  ...baseTextField(label, helpText),
  ...{
    type: 'password',
  },
});

const PostalCode = (country) => {
  const { helpText, ...other } = postcodeProps(country);
  return {
    helpText: getHelpText(helpText),
    ...other,
  };
};

const PreventWhiteSpacePadding = {
  pattern: '^\\S.*\\S$',
};

/*
  Initial Field for restricted length open format fields..
  Explicitly null regex as inclusion or exclusion deemed risky
  for variable locale/intl and specific fields with non defined/known requirements
  Regex can be iterated and improved as/if issues arise
  //'^([a-zA-Z0-9_-]{' + min + ',' + max + '})+$',
 */
const ProfileField = (label, helpText, min, max) => ({
  ...Custom(
    label,
    helpText ? (
      helpText
    ) : (
      <FormattedMessage
        defaultMessage="Please enter between {min} and {max} characters"
        values={{ min, max }}
      />
    ),
    // The regex stops you from entering only whitespace
    '.*\\S+.*',
    min,
    max
  ),
});

const FreeTextEntryWithinRange = (label, min, max) => ({
  ...Custom(
    label,
    'Please enter between ' + min + ' and ' + max + ' characters',
    null,
    min,
    max
  ),
});

const AlphaNumeric = (
  label,
  helpText = 'Please enter only letters and numbers',
  range
) => ({
  ...baseTextField(label, helpText),
  ...{
    pattern: range ? '^([a-zA-Z0-9]' + range + ')$' : '^([a-zA-Z0-9]+$)',
  },
});

const AlphaNumericSpaced = (
  label,
  helpText = 'Please enter only letters, numbers, and spaces',
  range
) => ({
  ...baseTextField(label, helpText),
  ...{
    pattern: range ? `^([ a-zA-Z0-9]` + range + `)$` : `^([ a-zA-Z0-9]+$)`,
  },
});

/*
  Field for entity names provided via user input.
  Allows alpha numeric characters, dashes, underscores, and spaces internal to the string.
  Leading and trailing spaces are disallowed.
 */
const UserDefinedEntityName = (
  label,
  helpText = 'Please enter only letters, numbers, dashes, underscores or spaces',
  range
) => ({
  ...baseTextField(label, helpText),
  ...{
    pattern: range
      ? `^(?!\\s)[A-Za-z0-9_-]{0, ${range}}+(?=\\S)[A-Za-z0-9_-\\s-]*$`
      : '^(?!\\s)[A-Za-z0-9_-]+(?=\\S)[A-Za-z0-9_-\\s-]*$',
  },
});

const Numeric = (label, helpText = 'Please enter only Numbers', range) => ({
  ...baseTextField(label, helpText),
  ...{ pattern: range ? '^([0-9]' + range + ')$' : '^([0-9])+$' },
});

function useSMSCode(
  label = <FormattedMessage defaultMessage="6-digit code" />,
  helpText
) {
  const intl = useIntl();
  const helperText =
    helpText ||
    intl.formatMessage({ defaultMessage: 'Please enter a 6-digit code.' });

  return {
    ...Custom(label, helperText, null, 6, 6, Numeric),
    type: 'tel',
    autoComplete: 'off',
    ...getCustomValidityHandlers(helperText),
  };
}

function useYubikeyKeyCode(
  label = <FormattedMessage defaultMessage="YubiKey Code" />,
  helpText,
  range
) {
  const intl = useIntl();
  const helperText =
    helpText ||
    intl.formatMessage({ defaultMessage: 'Please enter the correct YubiKey.' });

  return {
    ...Custom(label, helperText, null, 44, 44, AlphaNumeric),
    ...getCustomValidityHandlers(helperText),
  };
}

const DecimalNumeric = (label, helpText) => ({
  ...baseTextField(label, helpText),
  ...{ type: 'number', step: '0.01' },
});

// Temporary numerical entry decimal input
const BasicCurrency = (label, helpText = 'Please enter a valid amount') => {
  return {
    ...Custom(label, helpText, '^\\d+(\\.\\d{1,2})?'),
  };
};

const BasicCurrencyNonZero = (
  label,
  helpText = 'Please enter a valid amount'
) => {
  return {
    ...Custom(label, helpText, '^(?!0*(\\.0+)?$)(\\d+|\\d*\\.\\d{1,2})$'),
  };
};

const Currency = (currency, label, positive = false) => {
  const helpText = positive
    ? FormFieldHelpText.POSITIVE_CURRENCY
    : FormFieldHelpText.CURRENCY;
  return {
    ...DecimalNumeric(
      <FormattedMessage
        defaultMessage="{label} ({currency})"
        values={{ label, currency }}
      />,
      helpText
    ),
    className: styles.currency,
    // TODO: use different positive amounts based on the currency
    min: positive ? '0.01' : '0',
    ...getCustomValidityHandlers(helpText),
  };
};

const Custom = (
  label,
  helpText = null,
  regexStr = null,
  min = null,
  max = null,
  fieldType = baseTextField
) => ({
  ...fieldType(label, helpText),
  ...(regexStr ? { pattern: regexStr } : undefined),
  ...(min ? { minLength: min } : undefined),
  ...(max ? { maxLength: max } : undefined),
});

export {
  PostalCode,
  NationalIdentification,
  TaxID,
  DateOfBirth,
  ProfileField,
  Numeric,
  AlphaNumeric,
  AlphaNumericSpaced,
  DecimalNumeric,
  FormFieldHelpText,
  Currency,
  BasicCurrency,
  BasicCurrencyNonZero,
  Custom,
  Email,
  Password,
  useYubikeyKeyCode,
  useSMSCode,
  FreeTextEntryWithinRange,
  PreventWhiteSpacePadding,
  UserDefinedEntityName,
};
