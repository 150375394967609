import callbagOf from 'callbag-of';
import flatten from 'callbag-flatten';
import rescue from 'callbag-rescue';
import map from 'callbag-map';
import pipe from 'callbag-pipe';
import throwError from 'callbag-throw-error';

export function logoutOnError(logoutStream, guard = (error) => true) {
  return (source) =>
    pipe(
      source,
      rescue((error) => {
        const errorStream = throwError(error);

        if (guard(error)) {
          return pipe(
            // return the result of the logoutStream
            logoutStream,
            // logout can fail, so catch this failure
            rescue((e) => callbagOf(e)),
            // map all inputs (error/data) to return the original error
            map((_) => errorStream),
            // flatten error stream into a single response
            flatten
          );
        }
        // otherwise, throw the original error
        return errorStream;
      })
    );
}
