import { useMemo } from 'react';
import { usePagination } from './usePagination';
import { useSort } from './useSort';
import { useFilter } from './useFilter';

export function useAllQueries(
  filters,
  defaultColumn,
  defaultSort = 1,
  itemsPerPage = 20,
  defaultFilter = {}
) {
  const [setPage, pageQuery] = usePagination(itemsPerPage);
  const [sortColumn, sortOrder, setSort, sortQuery] = useSort(
    defaultColumn,
    defaultSort
  );
  const [onFilterChange, filtersQuery, displayValues, clearFilters] = useFilter(
    filters,
    defaultFilter
  );

  // Build the query object used when making the API call.
  // This object is used to specify pagination and sort params.
  const query = useMemo(
    () => ({ ...pageQuery(), ...sortQuery(), ...filtersQuery() }),
    [pageQuery, sortQuery, filtersQuery]
  );

  const onSort = (column, direction) => {
    setPage(1);
    setSort(column, direction);
  };

  const headerProps = {
    onSort,
    sortColumn,
    sortOrder,
  };

  const filterState = filtersQuery();

  const filterProps = {
    hasFiltersApplied: !!Object.keys(filterState).length,
    fields: filters,
    onChange: (...args) => {
      setPage(1);
      onFilterChange(...args);
    },
  };

  const pageProps = {
    onPage: (page) => setPage(page),
    ...pageQuery,
  };

  return [
    query,
    { headerProps, filterProps, pageProps },
    displayValues,
    clearFilters,
  ];
}
