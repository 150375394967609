import React from 'react';
import { Switch, Route, useLocation } from 'react-router';
import { PayorFundingListRoute } from './PayorFundingListRoute';

function PayorFundingRoute({ payorId }) {
  const location = useLocation();

  const renderList = () => {
    return <PayorFundingListRoute payorId={payorId} />;
  };

  return (
    <Switch location={location}>
      {/* Add additional funding sub-routes here */}
      <Route render={renderList} />
    </Switch>
  );
}

export { PayorFundingRoute };
