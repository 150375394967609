import React from 'react';
import { func, shape, string } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { VeloCardForm } from '../VeloCardForm';
import { VeloGrid } from '../VeloGrid';
import { VeloTextField } from '../VeloTextField';
import { VeloGridLoading } from '../VeloGridLoading';
import { useFieldGridForm } from '../useFieldGridForm';
import { Description } from '../Description';
import { VeloFieldGrid } from '../VeloFieldGrid';

RemoteIdEditForm.propTypes = {
  /** Called when the form is closed. */
  onClose: func.isRequired,
  /** Called when the form is submitted. */
  onSubmit: func.isRequired,
  /** The API data. */
  data: shape({
    remoteId: string.isRequired,
    payorName: string.isRequired,
    payeeName: string.isRequired,
  }).isRequired,
};

const config = {
  createSections({ remoteId }) {
    return [
      {
        heading: false,
        fields: [
          {
            Component: VeloTextField,
            name: 'remoteId',
            'data-testid': 'remoteId',
            label: <FormattedMessage defaultMessage="Remote ID" />,
            required: true,
            value: remoteId,
          },
        ],
      },
    ];
  },
  getButtonProps(submitting, _, dirty) {
    return {
      disabled: !dirty || submitting,
      children: submitting ? (
        <FormattedMessage defaultMessage="Saving..." />
      ) : (
        <FormattedMessage defaultMessage="Save" />
      ),
    };
  },
  getInitialValues({ data: { remoteId } }) {
    return { remoteId };
  },
  formatBody(body) {
    return body;
  },
};

function RemoteIdEditForm(props) {
  const [gridProps, formProps] = useFieldGridForm(config, props);

  return (
    <VeloCardForm
      title={<FormattedMessage defaultMessage="Edit Remote ID" />}
      onClose={props.onClose}
      {...formProps}
      slimline
    >
      <VeloGrid>
        <VeloGrid.Inner tag="dl">
          {/* Payor name */}
          <VeloGrid.Cell span={12}>
            <Description
              id="payorName"
              label={<FormattedMessage defaultMessage="Payor" />}
              value={props.data.payorName}
            />
          </VeloGrid.Cell>

          {/* Payee name */}
          <VeloGrid.Cell span={12}>
            <Description
              id="payeeName"
              label={<FormattedMessage defaultMessage="Payee" />}
              value={props.data.payeeName}
            />
          </VeloGrid.Cell>

          {/* Remote ID */}
          <VeloGrid.Cell span={12}>
            <VeloFieldGrid compact {...gridProps} />
          </VeloGrid.Cell>
        </VeloGrid.Inner>
      </VeloGrid>
    </VeloCardForm>
  );
}

Loading.propTypes = {
  /** Called when the form is closed. */
  onClose: func.isRequired,
};

function Loading({ onClose }) {
  return (
    <VeloCardForm.Loading
      title={<FormattedMessage defaultMessage="Edit Remote ID" />}
      onClose={onClose}
      slimline
    >
      <VeloGridLoading
        sections={[
          {
            heading: false,
            fields: [
              { type: VeloGridLoading.fieldTypes.LabelledItem },
              { type: VeloGridLoading.fieldTypes.LabelledItem },
              { type: VeloGridLoading.fieldTypes.TextField },
            ],
          },
        ]}
      />
    </VeloCardForm.Loading>
  );
}

RemoteIdEditForm.Loading = Loading;
RemoteIdEditForm.Error = VeloCardForm.Error;

export { RemoteIdEditForm };
