import React from 'react';
import { oneOf, shape, string } from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import {
  OnboardedStatus,
  PayeeType,
  payeeSsnLabel,
  payeeTaxIdLabel,
} from 'velo-data';
import styled from 'astroturf/react';
import { OnboardedStatusIndicator } from '../OnboardedStatusIndicator';
import { Address } from '../Address';
import { Country } from '../Country';
import { MaskedDescription } from '../MaskedDescription';
import { InvitationStatusField } from '../InvitationStatusField';
import { Description } from '../Description';
import { VeloLabelledItem } from '../VeloLabelledItem';
import { VeloButton } from '../VeloButton';

const name = 'details';

const TestIds = {
  SSN_VISIBILITY: `${name}-ssn-visibility`,
  TAX_ID_VISIBILITY: `${name}-tax-id-visibility`,
  DOB_VISIBILITY: `${name}-dob-visibility`,
  RESEND_INVITE: `${name}-resend-visibility`,
  EDIT_REMOTE_ID: `${name}-edit-remote-id`,
};

const propTypes = {
  payeeType: oneOf(Object.values(PayeeType)).isRequired,
  onboardedStatus: oneOf(Object.values(OnboardedStatus)).isRequired,
  address: shape({
    line1: string.isRequired,
    line2: string,
    line3: string,
    line4: string,
    countyOrProvince: string.isRequired,
    zipOrPostcode: string.isRequired,
    country: string.isRequired,
  }),
  remoteId: string,
  individual: shape({
    dateOfBirth: string,
    nationalIdentification: string,
  }),
  company: shape({
    taxId: string,
  }),
};

const payeeTypes = {
  [PayeeType.INDIVIDUAL]: <FormattedMessage defaultMessage="Individual" />,
  [PayeeType.COMPANY]: <FormattedMessage defaultMessage="Company" />,
};

function DateOfBirth({ dateOfBirth }) {
  const intl = useIntl();
  return (
    <MaskedDescription
      id="dateOfBirth"
      label={<FormattedMessage defaultMessage="Date of birth" />}
      value={intl.formatDate(dateOfBirth, {
        day: 'numeric',
        year: 'numeric',
        month: 'long',
        timeZone: 'UTC',
      })}
      data-testid={TestIds.DOB_VISIBILITY}
    />
  );
}

const DetailSectionMode = {
  BACK_OFFICE: 'BACK_OFFICE',
  PAYOR: 'PAYOR',
};

/**
 * In Back Office portal, unmasking the taxId is supported.
 * In Payor portal, The value is masked.
 */
const DetailSectionModeComponentMap = {
  [DetailSectionMode.BACK_OFFICE]: {
    TaxIdField: MaskedDescription,
  },
  [DetailSectionMode.PAYOR]: {
    TaxIdField: VeloLabelledItem.UseValue,
  },
};

const RemoteIDRow = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

function RemoteId({ onEdit, children }) {
  return (
    <RemoteIDRow>
      {children}
      {onEdit && (
        <VeloButton onClick={onEdit} data-testid={TestIds.EDIT_REMOTE_ID}>
          <FormattedMessage defaultMessage="Edit" />
        </VeloButton>
      )}
    </RemoteIDRow>
  );
}

function PayeeViewDetailSection({
  payee: {
    payeeType,
    onboardedStatus,
    invitationStatus,
    address,
    individual,
    company,
    remoteId,
    displayName,
    managedByPayorId,
  },
  payeeCountryRules,
  onResendInvite,
  onEditRemoteId,
  onEdit,
  mode,
}) {
  const { TaxIdField } = DetailSectionModeComponentMap[mode];

  return {
    name,
    heading: <FormattedMessage defaultMessage="Details" />,
    onEdit,
    fields: [
      ...(company && company.operatingName
        ? [
            {
              id: 'operatingName',
              label: (
                <FormattedMessage defaultMessage="Company Operating Name" />
              ),
              value: company.operatingName,
            },
          ]
        : []),
      ...(individual
        ? [
            {
              id: 'displayName',
              label: <FormattedMessage defaultMessage="Payee Name" />,
              value: displayName,
            },
          ]
        : []),
      {
        id: 'payeeType',
        label: <FormattedMessage defaultMessage="Payee type" />,
        value: payeeTypes[payeeType],
      },
      ...(Boolean(managedByPayorId)
        ? [
            {
              id: 'payeeMode',
              label: <FormattedMessage defaultMessage="Payee mode" />,
              value: <FormattedMessage defaultMessage="Managed" />,
            },
          ]
        : []),
      ...(onResendInvite
        ? [
            {
              render: () => (
                <InvitationStatusField
                  showResendInvite={true}
                  invitationStatus={
                    invitationStatus ? invitationStatus : 'UNKNOWN'
                  }
                  onResendInvite={onResendInvite}
                  data-testid={TestIds.RESEND_INVITE}
                  render={(props) => (
                    <Description id="invitationStatus" {...props} />
                  )}
                />
              ),
            },
          ]
        : [
            {
              id: 'onboardedStatus',
              label: <FormattedMessage defaultMessage="Onboarded status" />,
              value: (
                <OnboardedStatusIndicator onboardedStatus={onboardedStatus} />
              ),
            },
          ]),
      {
        id: 'address',
        label: <FormattedMessage defaultMessage="Address" />,
        value: <Address {...address} />,
      },
      {
        id: 'country',
        label: <FormattedMessage defaultMessage="Country" />,
        value: <Country country={address.country} />,
      },
      ...(remoteId
        ? [
            {
              id: 'remoteId',
              label: <FormattedMessage defaultMessage="Remote ID" />,
              value: <RemoteId onEdit={onEditRemoteId}>{remoteId}</RemoteId>,
            },
          ]
        : []),
      ...(individual?.dateOfBirth
        ? [
            {
              render: () => (
                <MaskedDescription
                  id="nationalIdentification"
                  label={payeeSsnLabel(address.country, payeeCountryRules)}
                  value={individual?.nationalIdentification}
                  data-testid={TestIds.SSN_VISIBILITY}
                />
              ),
            },
            {
              render: () => (
                <DateOfBirth dateOfBirth={individual.dateOfBirth} />
              ),
            },
          ]
        : []),
      ...(company
        ? [
            {
              render: () => (
                <TaxIdField
                  id="taxId"
                  label={payeeTaxIdLabel(address.country, payeeCountryRules)}
                  value={company?.taxId}
                  data-testid={TestIds.TAX_ID_VISIBILITY}
                />
              ),
            },
          ]
        : []),
    ],
  };
}

PayeeViewDetailSection.sectionName = name;
PayeeViewDetailSection.propTypes = propTypes;
PayeeViewDetailSection.mode = DetailSectionMode;

export { PayeeViewDetailSection };
