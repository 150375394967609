function getScriptJsonText(additionalSelector = '') {
  return (
    document.querySelector(
      `script[type=application\\/json]${additionalSelector}`
    ) || {
      textContent: '{}',
    }
  ).textContent;
}

export { getScriptJsonText };
