import React from 'react';
import { bool, oneOf, oneOfType, func, object } from 'prop-types';
import styled from 'astroturf/react';
import { VeloSkeleton } from '../VeloSkeleton';
import { VeloLabelledItem } from '../VeloLabelledItem';
import { VeloTextField } from '../VeloTextField';
import { VeloSectionGrid } from '../VeloSectionGrid';
import { VeloOnOffSwitch } from '../VeloOnOffSwitch';
import { VeloCollapsibleSection } from '../VeloCollapsibleSection';

const FieldSkeletonType = {
  TextField: 'TextField',
  LabelledItem: 'LabelledItem',
  OnOffSwitch: 'OnOffSwitch',
  CollapsibleSection: 'CollapsibleSection',
  Custom: 'Custom',
};

const HeadingSkeleton = styled(VeloSkeleton)`
  width: 40%;
`;

const FieldSkeletonTypeComponentMap = {
  [FieldSkeletonType.TextField]: () => <VeloTextField.Skeleton />,
  [FieldSkeletonType.LabelledItem]: () => <VeloLabelledItem.Skeleton />,
  [FieldSkeletonType.OnOffSwitch]: () => <VeloOnOffSwitch.Loading />,
  [FieldSkeletonType.CollapsibleSection]: () => (
    <VeloCollapsibleSection.Loading />
  ),
};

VeloGridLoading.propTypes = {
  ...VeloSectionGrid.type(
    {
      type: oneOf(Object.values(FieldSkeletonType)).isRequired,
      /**
       * The component to render if type === "Custom"
       */
      Component: oneOfType([object, func]),
    },
    { heading: bool }
  ),
};

VeloGridLoading.fieldTypes = FieldSkeletonType;

function VeloGridLoading({ sections, ...props }) {
  return (
    <VeloSectionGrid
      {...props}
      sections={sections.map(({ heading, fields }) => ({
        heading: heading ? <HeadingSkeleton /> : undefined,
        fields,
      }))}
      render={({ type, Component }) =>
        type === FieldSkeletonType.Custom ? (
          <Component />
        ) : (
          FieldSkeletonTypeComponentMap[type]()
        )
      }
    />
  );
}

const fields = (count, type) =>
  [...Array(count).keys()].map(() => ({
    type,
  }));

VeloGridLoading.fields = fields;

export { VeloGridLoading };
