import React from 'react';
import { string } from 'prop-types';
import styled from 'astroturf/react';
import { VeloStepperIcon } from './VeloStepperIcon';
import { VeloStepperText } from './VeloStepperText';

const Label = styled('div')`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
`;

/**
 * Used to wrap the icon and text that appears above each step
 * in a vertical stepper.
 */
const VeloStepperLabel = React.forwardRef(
  ({ icon, text, disabled, skeleton, children, ...other }, ref) => {
    return (
      <Label ref={ref} {...other}>
        {/* Icon shortcut*/}
        {icon && (
          <VeloStepperIcon disabled={disabled} skeleton={skeleton}>
            {icon}
          </VeloStepperIcon>
        )}

        {/* Text shortcut */}
        {text && (
          <VeloStepperText disabled={disabled} skeleton={skeleton}>
            {text}
          </VeloStepperText>
        )}

        {children}
      </Label>
    );
  }
);

VeloStepperLabel.propTypes = {
  /** Custom CSS class(es). */
  className: string,
};

export { VeloStepperLabel };
