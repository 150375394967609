import React from 'react';
import pick from 'just-pick';
import { func, string, arrayOf, oneOf } from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { PayorAccountType } from 'velo-data';
import { useSteppedGridForm } from '../useSteppedGridForm';
import { VeloCardForm } from '../VeloCardForm';
import { VeloFieldGrid } from '../VeloFieldGrid';
import { VeloStepper } from '../VeloStepper';
import { PayorAccountTypeText } from '../PayorAccountTypeText';
import {
  FundingAccountFormFields,
  fieldNames,
} from '../FundingAccountFormFields';
import { VeloGridLoading } from '../VeloGridLoading';

const { attributeMap } = FundingAccountFormFields;

const Labels = {
  TITLE: <FormattedMessage defaultMessage="Create Funding Account" />,
  PAYOR_SELECT_HEADING: <FormattedMessage defaultMessage="Select a payor" />,
  ACCOUNT_INFO_HEADING: (
    <FormattedMessage defaultMessage="Name your funding account" />
  ),
  ACCOUNT_DETAILS_HEADING: (
    <FormattedMessage defaultMessage="Add account details" />
  ),
  SUBMIT_BUTTON: <FormattedMessage defaultMessage="Create" />,
  SUBMITTING_BUTTON: <FormattedMessage defaultMessage="Creating..." />,
};

const fieldsByType = {
  [PayorAccountType.FBO]: [
    fieldNames.COUNTRY,
    fieldNames.ACCOUNT_NAME,
    fieldNames.ROUTING_NUMBER,
    fieldNames.ACCOUNT_NUMBER,
  ],
  [PayorAccountType.PRIVATE]: [
    fieldNames.COUNTRY,
    fieldNames.CURRENCY,
    fieldNames.ACCOUNT_NAME,
    fieldNames.ROUTING_NUMBER,
    fieldNames.ACCOUNT_NUMBER,
  ],
};

const nonApplicableAccountTypes = [PayorAccountType.PRIVATE_COUPLED];

const fieldRenderByName = {
  [fieldNames.NAME]: () => attributeMap.NAME,
  [fieldNames.ACCOUNT_NAME]: () => attributeMap.ACCOUNT_HOLDER_NAME,
  [fieldNames.ROUTING_NUMBER]: () => attributeMap.ROUTING_NUMBER,
  [fieldNames.ACCOUNT_NUMBER]: () => attributeMap.ACCOUNT_NUMBER,
  [fieldNames.COUNTRY]: () => attributeMap.COUNTRY,
  [fieldNames.CURRENCY]: ({ currencies }) => ({
    ...attributeMap.CURRENCY,
    currencies,
  }),
  [fieldNames.PAYOR]: ({ fetchResults }) => ({
    ...attributeMap.PAYOR,
    fetchResults,
  }),
  [fieldNames.TYPE]: ({ payorAccountTypes, intl }) => ({
    ...attributeMap.TYPE,
    options: payorAccountTypes
      .filter((type) => !nonApplicableAccountTypes.includes(type))
      .map((value) => ({
        value,
        label: PayorAccountTypeText.fromIntl(intl, value),
      })),
  }),
};

const disableButton = ({ type, name, payorId }) => {
  return payorId === '' || name === '' || type === '';
};

const mapFieldByName = (props) => (name) => fieldRenderByName[name](props);

const config = {
  steps: [
    {
      getInitialValues({ payor = '', payorId = '' }) {
        return { payorId, payor };
      },
      createSections(_, props) {
        return {
          fields: [mapFieldByName(props)(fieldNames.PAYOR)],
        };
      },
      mapToSteps([{ sections, ...gridProps }]) {
        const [step] = sections;
        return [
          {
            title: Labels.PAYOR_SELECT_HEADING,
            children: (
              <VeloFieldGrid compact sections={[step]} {...gridProps} />
            ),
          },
        ];
      },
    },
    {
      getInitialValues({ payorAccountTypes }) {
        return {
          name: '',
          // if there is only one funding account type, set that automatically
          type: payorAccountTypes.length === 1 ? payorAccountTypes[0] : '',
        };
      },
      createSections(_, props) {
        const { payorAccountTypes } = props;
        return {
          fields: [fieldNames.NAME]
            .concat(payorAccountTypes.length > 1 ? fieldNames.TYPE : [])
            .map(mapFieldByName(props)),
        };
      },
      mapToSteps([{ sections, ...gridProps }, , body]) {
        const [, step] = sections;
        return [
          {
            title: Labels.ACCOUNT_INFO_HEADING,
            disabled: !body.payorId,
            children: (
              <VeloFieldGrid compact sections={[step]} {...gridProps} />
            ),
          },
        ];
      },
    },
    {
      getInitialValues() {
        return {
          accountName: '',
          accountNumber: '',
          routingNumber: '',
          countryCode: '',
          currency: '',
        };
      },
      createSections({ type }, props) {
        const fieldNames = fieldsByType[type] || [];
        return {
          fields: fieldNames.map(mapFieldByName(props)),
        };
      },
      mapToSteps([{ sections, ...gridProps }, , body]) {
        const [, , step] = sections;

        return [
          {
            title: Labels.ACCOUNT_DETAILS_HEADING,
            disabled: !body.name || !body.type,
            children: (
              <VeloFieldGrid compact sections={[step]} {...gridProps} />
            ),
          },
        ];
      },
    },
  ],
  getButtonProps(submitting, body) {
    return {
      disabled: disableButton(body),
      children: submitting ? Labels.SUBMITTING_BUTTON : Labels.SUBMIT_BUTTON,
    };
  },
  formatBody(body) {
    const typeSpecificFields = fieldsByType[body.type];
    return pick(body, [
      'payorId',
      fieldNames.NAME,
      fieldNames.TYPE,
      ...typeSpecificFields,
    ]);
  },
};

const renderStep = ({ children, disabled }) => (disabled ? null : children);

FundingAccountCreateForm.propTypes = {
  /**
   * Handler for when the form is submitted
   */
  onSubmit: func.isRequired,
  /**
   * Handler for when the form is closed
   */
  onClose: func.isRequired,
  /**
   * An array of account types that are supported
   */
  payorAccountTypes: arrayOf(oneOf(Object.values(PayorAccountType))).isRequired,
  /**
   * For WUBS, and array of currencies that are supported
   */
  currencies: arrayOf(string).isRequired,
  /**
   * Handler for when the payor lookup field is actioned
   */
  fetchResults: func.isRequired,
  /**
   * Optional id to pre-fill the lookup field
   */
  payorId: string,
  /**
   * Optional name to pre-fill the lookup field
   */
  payor: string,
};

FundingAccountCreateForm.labels = Labels;

function LoadingForm({ onClose }) {
  const steps = [
    {
      skeleton: true,
      children: (
        <VeloGridLoading
          compact
          sections={[
            { fields: [{ type: VeloGridLoading.fieldTypes.TextField }] },
          ]}
        />
      ),
    },
    {
      skeleton: true,
    },
    {
      skeleton: true,
    },
  ];
  return (
    <VeloCardForm.Loading slimline onClose={onClose} title={Labels.TITLE}>
      <VeloStepper form steps={steps} renderStep={renderStep} />
    </VeloCardForm.Loading>
  );
}

function ErrorForm({ error, onClose }) {
  return (
    <VeloCardForm.Error error={error} onClose={onClose} title={Labels.TITLE} />
  );
}

FundingAccountCreateForm.Loading = LoadingForm;
FundingAccountCreateForm.Error = ErrorForm;

export function FundingAccountCreateForm(props) {
  const intl = useIntl();
  const [steps, , formProps] = useSteppedGridForm(config, { intl, ...props });

  return (
    <VeloCardForm slimline {...formProps} title={Labels.TITLE}>
      <VeloStepper form steps={steps} renderStep={renderStep} />
    </VeloCardForm>
  );
}
