import {
  VeloNotification,
  SourceAccountBalanceThresholdForm,
} from 'velo-react-components';
import { setNotificationsRequest } from 'velo-api/src/entities/sourceAccounts';
import { SourceAccountsViewDataPresenter } from './SourceAccountsViewDataPresenter';
import { createRoute } from './helpers';

function Presenter(wireframe, entity, props, notification) {
  const [loader, componentProps, createCallback] =
    SourceAccountsViewDataPresenter(wireframe, entity, props);
  return [
    loader,
    {
      ...componentProps,
      onSubmit: (body, cb) => {
        return entity.setNotificationsRequest(
          props.sourceAccountId,
          body,
          createCallback(cb, notification)
        );
      },
    },
  ];
}

export const SourceAccountsBalanceThresholdRoute = createRoute(
  Presenter,
  SourceAccountBalanceThresholdForm,
  {
    setNotificationsRequest,
  },
  VeloNotification.types.SOURCE_ACCOUNT_MIN_BALANCE_SUCCESS
);

SourceAccountsBalanceThresholdRoute.displayName =
  'SourceAccountsBalanceThresholdRoute';
