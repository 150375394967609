import React from 'react';
import { func } from 'prop-types';
import { PayoutInstructCard } from '../PayoutInstructCard';
import { PayoutReviewPayments } from '../PayoutReviewPayments';
import { PayoutHeader } from '../PayoutHeader';

/**
 * PayoutPageLoading:
 * Skeleton Loading Page
 * */
PayoutPageLoading.propTypes = {
  /** Function to close page */
  onClose: func.isRequired,
};
function PayoutPageLoading({ onClose }) {
  return (
    <PayoutInstructCard onClose={onClose}>
      <PayoutHeader.Loading actions />
      <PayoutReviewPayments.Loading />
    </PayoutInstructCard>
  );
}

export { PayoutPageLoading };
