import React from 'react';
import styled from 'astroturf/react';
import { bool } from 'prop-types';
import { VeloOverlay } from '../VeloOverlay';
import { VeloSpinner } from '../VeloSpinner';
import { VeloTypography } from '../VeloTypography';
import { useScrollLock } from '../hooks/useScrollLock';
import { VeloSectionGrid } from '../VeloSectionGrid';

const Overlay = styled(VeloOverlay)`
  @import 'velo-variables';

  &.fixed {
    position: fixed;
    z-index: $velo-over-all-default + 1;
  }
`;

Overlay.propTypes = {
  fixed: bool.isRequired,
};

Overlay.defaultProps = {
  fixed: false,
};

const OverlayContent = styled('div')`
  @import 'velo-variables';

  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: $velo-snackbar-notifications;
`;

const OverlayContentText = styled('div')`
  @import 'velo-variables';

  max-width: 70%;
  text-align: center;
  position: relative;
  padding: 1rem;
  margin: 1rem 0;
  background-color: velo-color('token-color-brand-primary-background');

  span {
    color: velo-color('token-color-brand-primary');
  }

  @media (min-width: velo-breakpoint(XS)) {
    max-width: 464px;
  }
`;

function VeloFullScreenLoadingOverlay({ children, ...props }) {
  useScrollLock();
  return (
    <>
      <Overlay {...props} show />
      <OverlayContent>
        <VeloSpinner />
        <OverlayContentText>
          <VeloTypography use="sectionHeader">{children}</VeloTypography>
        </OverlayContentText>
      </OverlayContent>
    </>
  );
}

function ConfirmOverlay({ title, content }) {
  useScrollLock();
  return (
    <>
      <Overlay show fixed />
      <OverlayContent>
        <OverlayContentText>
          <VeloSectionGrid
            compact
            sections={[
              {
                fields: [
                  <VeloTypography use="secondaryHeader">
                    {title}
                  </VeloTypography>,
                  <VeloTypography use="sectionHeader">
                    {content}
                  </VeloTypography>,
                ],
              },
            ]}
            render={(component) => component}
          />
        </OverlayContentText>
        <VeloSpinner />
      </OverlayContent>
    </>
  );
}

VeloFullScreenLoadingOverlay.Confirm = ConfirmOverlay;

export { VeloFullScreenLoadingOverlay };
