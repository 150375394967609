import { arrayOf, func, oneOf, shape, string } from 'prop-types';
import { propTypesCountries } from '../utils';
import { VeloPropTypes } from '../VeloPropTypes';

/**
 * Available modes for the form.
 */
const AddPayeeMode = {
  PAYOR_CREATE: 0,
  PAYOR_EDIT: 1,
  PAYEE_ONBOARDING: 2,
};

const FormPropTypes = {
  /** The current field values. */
  values: shape({
    countryCode: string.isRequired,
  }).isRequired,
  /** Called when a field is changed. */
  onChange: func.isRequired,
  /** The form mode. Determines some field availability.  */
  mode: oneOf(Object.values(AddPayeeMode)).isRequired,
  /**
   * An array of masked field names.
   * Fields in this list are not validated.
   */
  masked: arrayOf(string),
  /**
   * Country data from the API.
   */
  countries: propTypesCountries,
  /**
   * Payee rules from the API.
   */
  payeeCountryRules: VeloPropTypes.payeeCountryRulesType().isRequired,
};

export { FormPropTypes, AddPayeeMode };
