import React from 'react';
import { arrayOf, boolean, number, oneOf, string, shape } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import compose from 'just-compose';
import styled from 'astroturf/react';
import { dataTestIdBuilder } from 'velo-data';
import { VeloTableBuilder } from '../VeloTableBuilder';
import { VeloCurrency } from '../VeloCurrency';
import { VeloDateFormat } from '../VeloDateFormat';
import { AllocationType } from '../Allocation';
import { VeloIconLabel } from '../VeloIconLabel';
import { FundingStatusIndicator } from './FundingStatusIndicator';

const WarningStatusLabel = styled(VeloIconLabel)`
  @import 'velo-variables';
  @import 'velo-mixins';
  color: velo-color('token-color-system-error-default');
`;

const AlignEnd = styled('div')`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  flex-grow: 1;
  text-align: right;
`;

const Label = styled('span')`
  @import 'velo-variables';

  @media (max-width: velo-breakpoint(XS)) {
    padding-left: 4rem;
  }
`;

const root = 'velo-funding-list';

const testIds = {
  LIST: root,
  HEADING: `${root}-heading`,
  SORT_HEADING: `${root}-sort-heading`,
  ROW_PREFIX: `${root}-row`,
  COLUMN_PREFIX: `${root}-column`,
};

const fieldNames = {
  DATE_TIME: 'dateTime',
  FUNDING_TYPE: 'fundingType',
  FUNDING_ACCOUNT_NAME: 'fundingAccountName',
  SOURCE_ACCOUNT_NAME: 'sourceAccountName',
  AMOUNT: 'amount',
  STATUS: 'status',
};

const columnsByName = {
  [fieldNames.DATE_TIME]: {
    name: fieldNames.DATE_TIME,
    label: <FormattedMessage defaultMessage="Date/Time" />,
    'data-testid': dataTestIdBuilder(testIds.COLUMN_PREFIX, 'date-time'),
  },
  [fieldNames.FUNDING_TYPE]: {
    name: fieldNames.FUNDING_TYPE,
    label: <FormattedMessage defaultMessage="Funding Type" />,
    'data-testid': dataTestIdBuilder(testIds.COLUMN_PREFIX, 'funding-type'),
  },
  [fieldNames.FUNDING_ACCOUNT_NAME]: {
    name: fieldNames.FUNDING_ACCOUNT_NAME,
    label: <FormattedMessage defaultMessage="Funding Account" />,
    'data-testid': dataTestIdBuilder(testIds.COLUMN_PREFIX, 'funding-account'),
  },
  [fieldNames.SOURCE_ACCOUNT_NAME]: {
    name: fieldNames.SOURCE_ACCOUNT_NAME,
    label: <FormattedMessage defaultMessage="Source Account" />,
    'data-testid': dataTestIdBuilder(testIds.COLUMN_PREFIX, 'source-account'),
  },
  [fieldNames.AMOUNT]: {
    name: fieldNames.AMOUNT,
    label: <FormattedMessage defaultMessage="Amount" />,
    align: 'end',
    'data-testid': dataTestIdBuilder(testIds.COLUMN_PREFIX, 'amount'),
  },
  [fieldNames.STATUS]: {
    name: fieldNames.STATUS,
    label: <FormattedMessage defaultMessage="Status" />,
    'data-testid': dataTestIdBuilder(testIds.COLUMN_PREFIX, 'status'),
  },
};

const cellFactoryByFieldName = {
  [fieldNames.DATE_TIME]: ({ dateTime }) => <VeloDateFormat value={dateTime} />,
  [fieldNames.AMOUNT]: ({ amount, currency }) => (
    <AlignEnd>
      <VeloCurrency value={amount} currency={currency} />
    </AlignEnd>
  ),
  [fieldNames.FUNDING_TYPE]: ({ fundingType, topupType }) => (
    <AllocationType type={topupType} labelPrefix={fundingType} />
  ),
  [fieldNames.FUNDING_ACCOUNT_NAME]: ({ fundingAccountName }) => (
    <Label>{fundingAccountName}</Label>
  ),
  [fieldNames.SOURCE_ACCOUNT_NAME]: ({ sourceAccountName, userDeleted }) => {
    return userDeleted ? (
      <WarningStatusLabel
        icon={'error_outline'}
        label={sourceAccountName}
        use={'tableContent'}
      />
    ) : (
      <Label>{sourceAccountName}</Label>
    );
  },
  [fieldNames.STATUS]: ({ status }) => (
    <FundingStatusIndicator status={status} />
  ),
};

const filtersByName = {
  [fieldNames.SOURCE_ACCOUNT_NAME]: {
    name: fieldNames.SOURCE_ACCOUNT_NAME,
    label: <FormattedMessage defaultMessage="Source Account" />,
    type: 'string',
  },
};

const FundingListConfig = {
  fieldNames,
  columnsByName,
  getRowProps(_, index) {
    return {
      key: index,
      'data-testid': testIds.ROW_PREFIX,
    };
  },
  getColumnProps(col, data) {
    const key = col.name;
    return {
      key,
      children: cellFactoryByFieldName[key](data),
    };
  },
  dataProps: {
    data: arrayOf(
      shape({
        dateTime: string.isRequired,
        fundingType: string,
        topupType: string.isRequired,
        fundingAccountName: string.isRequired,
        sourceAccountName: string.isRequired,
        userDeleted: boolean,
        amount: number.isRequired,
        currency: string.isRequired,
        status: oneOf(['CREDIT', 'DEBIT', 'PENDING', 'FAILED']),
      })
    ).isRequired,
  },
};

function nestProps(firstFunc, secondFunc) {
  return compose(firstFunc, secondFunc);
}

const addBorderBottomProp = (props) => ({ ...props, borderBottom: true });

const FundingList = VeloTableBuilder({
  columns: fieldNames,
  getRowProps: nestProps(FundingListConfig.getRowProps, addBorderBottomProp),
  getColumnProps: FundingListConfig.getColumnProps,
  columnOrder: [
    columnsByName[fieldNames.DATE_TIME],
    columnsByName[fieldNames.FUNDING_TYPE],
    columnsByName[fieldNames.FUNDING_ACCOUNT_NAME],
    columnsByName[fieldNames.SOURCE_ACCOUNT_NAME],
    columnsByName[fieldNames.AMOUNT],
    columnsByName[fieldNames.STATUS],
  ],
  filters: [filtersByName[fieldNames.SOURCE_ACCOUNT_NAME]],
  sortableColumns: [fieldNames.DATE_TIME, fieldNames.AMOUNT],
  iconSpacer: false,
});

FundingList.Headings = nestProps(addBorderBottomProp, FundingList.Headings);

export { FundingList };
