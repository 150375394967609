import React, { useState, useRef, useEffect } from 'react';
import { func } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styled from 'astroturf/react';
import { VeloOverlay } from '../VeloOverlay';
import { VeloTextField } from '../VeloTextField';
import { OnboardingPage } from '../OnboardingPage';
import { useYubikeyKeyCode } from '../FormFields/FormFieldTypes';

const ROOT = 'invite-register-yubikey';

const TestIds = {
  ROOT,
  FORM: `${ROOT}-form`,
  SUBMIT: `${ROOT}-submit`,
  ERROR: `${ROOT}-error`,
  INPUT: `${ROOT}-input`,
  OVERLAY: `${ROOT}-overlay`,
};

const Form = styled('form')`
  @import '../OnboardingPage/variables';
  max-width: $button-width;
`;

const FormBody = styled('div')`
  margin: 2rem 0;
`;

const Section = styled('section')`
  margin: auto;
  max-width: 30rem;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

InviteRegisterYubikey.propTypes = {
  /** Called when the form is submitted. */
  onSubmit: func.isRequired,
};

function InviteRegisterYubikey(props) {
  const [{ value, submitting, error }, setFormState] = useState({
    value: '',
    submitting: false,
    error: undefined,
  });

  const yubikeyCodeFieldType = useYubikeyKeyCode();

  const buttonText = submitting ? (
    <FormattedMessage defaultMessage="Registering..." />
  ) : (
    <FormattedMessage defaultMessage="Register" />
  );

  const submitCallback = (error) => {
    /**
     * As YubiKey insertion is automated via YubiKey USB keyboard, the
     * user will not be editing the input value manually
     */
    if (error) {
      textFieldRef.current.foundation.setValue('');
      textFieldRef.current.foundation.adapter_.getNativeInput().focus();
      textFieldRef.current.foundation.activateFocus();
      setFormState({ submitting: false, error, value: '' });
    } else {
      setFormState({ submitting: false, error: undefined });
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setFormState({ error: undefined, submitting: true, value });
    props.onSubmit(value, submitCallback);
  };

  const textFieldRef = useRef();

  /**
   * As YubiKey insertion is automated via YubiKey USB keyboard, auto focus the
   * input on mount.
   */
  useEffect(() => {
    textFieldRef.current.foundation.activateFocus();
    textFieldRef.current.foundation.adapter_.getNativeInput().focus();
  }, [textFieldRef]);

  return (
    <Section>
      <header>
        <OnboardingPage.Title>
          <FormattedMessage defaultMessage="Register your secure two-factor authentication method" />
        </OnboardingPage.Title>
      </header>
      <Form onSubmit={onSubmit} id={ROOT} data-testid={TestIds.FORM}>
        <FormBody>
          <OnboardingPage.Subtitle>
            <FormattedMessage defaultMessage="Please insert and activate your YubiKey." />
          </OnboardingPage.Subtitle>
          <VeloTextField
            ref={textFieldRef}
            {...yubikeyCodeFieldType}
            disabled={submitting}
            required
            label={<FormattedMessage defaultMessage="YubiKey Code" />}
            data-testid={TestIds.INPUT}
            onChange={(event) => {
              setFormState({ value: event.target.value });
            }}
          />
          {error && (
            <OnboardingPage.SubmitError data-testid={TestIds.ERROR}>
              {error}
            </OnboardingPage.SubmitError>
          )}
        </FormBody>

        <OnboardingPage.Footer>
          <OnboardingPage.Button
            type="submit"
            form={ROOT}
            disabled={submitting}
            data-testid={TestIds.SUBMIT}
          >
            {buttonText}
          </OnboardingPage.Button>
        </OnboardingPage.Footer>
      </Form>

      <VeloOverlay show={submitting} data-testid={TestIds.OVERLAY} />
    </Section>
  );
}

InviteRegisterYubikey.testIds = TestIds;

export { InviteRegisterYubikey };
