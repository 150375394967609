import compose from 'just-compose';
import { VeloTableBuilder } from '../VeloTableBuilder';
import { PayoutsListColumns } from '../PayoutsListColumns';

const { columnsByName, filtersByName, fieldNames, mapHeaderProps, ...config } =
  PayoutsListColumns;

export const PayoutsListForBackOffice = VeloTableBuilder({
  ...config,
  columns: fieldNames,
  columnOrder: [
    columnsByName[fieldNames.SUBMITTED_DATE],
    columnsByName[fieldNames.SCHEDULED_ICON],
    columnsByName[fieldNames.PAYOR_NAME],
    columnsByName[fieldNames.PAYMENT_MEMO],
    columnsByName[fieldNames.TOTAL_PAYMENTS],
    columnsByName[fieldNames.RETURNED_PAYMENTS],
    columnsByName[fieldNames.STATUS],
    columnsByName[fieldNames.SCHEDULED_STATUS_ICON],
  ],
  filters: [],
  sortableColumns: [fieldNames.SUBMITTED_DATE],
});

const { Headings } = PayoutsListForBackOffice;

PayoutsListForBackOffice.Headings = compose(mapHeaderProps, Headings);

PayoutsListForBackOffice.getFilters = (
  fetchResults,
  { payorId, payorIdName },
  intl
) => {
  return [
    filtersByName[fieldNames.SUBMITTED_DATE],
    {
      ...filtersByName[fieldNames.PAYOR_ID],
      value: payorIdName,
      entityId: payorId,
      fetchResults,
    },
    filtersByName[fieldNames.PAYMENT_MEMO],
    filtersByName[fieldNames.STATUS],
    filtersByName[fieldNames.SCHEDULE_STATUS],
  ];
};

PayoutsListForBackOffice.TabConfig = {
  getFilters: PayoutsListForBackOffice.getFilters,
  primaryFilterField: PayoutsListForBackOffice.columns.SUBMITTED_DATE,
  defaultSort: PayoutsListForBackOffice.sortOrder.DESCENDING,
  table: PayoutsListForBackOffice,
};
