import React from 'react';
import { FormattedMessage } from 'react-intl';
import { VeloStepper } from '../VeloStepper';
import { GroupCsvUpload } from './GroupCsvUpload';
import { StepContent } from './StepContent';

function GroupStandardCreateStep(props) {
  return (
    <VeloStepper.Step>
      <VeloStepper.Label
        icon="2"
        text={
          <FormattedMessage defaultMessage="Add an optional descriptive memo and upload your CSV" />
        }
      />
      <StepContent>
        <GroupCsvUpload {...props} />
      </StepContent>
    </VeloStepper.Step>
  );
}

export { GroupStandardCreateStep };
