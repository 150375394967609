import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'astroturf/react';
import { PayeeCreationTypes } from 'velo-data';
import { VeloSectionGrid } from '../../VeloSectionGrid';
import { PaymentChannelSwitch } from '../PaymentChannelSwitch';
import { PaymentChannelForm } from '../PaymentChannelForm';
import { TestIds } from '../testIds';
import { AddPayeeMode } from '../propTypes';

const SectionGrid = styled(VeloSectionGrid)`
  padding-top: 0rem;
  padding-left: 0rem;
  padding-right: 0rem;
`;

function PaymentChannelStep({
  mode,
  type,
  paymentChannel,
  showPaymentChannel,
  onChange,
  formProps: { submitting, dirty, ...formProps },
}) {
  const show =
    (mode === AddPayeeMode.PAYOR_CREATE || mode === AddPayeeMode.PAYOR_EDIT) &&
    type &&
    type !== PayeeCreationTypes.CSV_UPLOAD &&
    paymentChannel;

  return show ? (
    <SectionGrid
      sections={[
        {
          heading: <FormattedMessage defaultMessage="Payment method details" />,
          fields: [
            {
              Component: PaymentChannelSwitch,
              id: 'togglePaymentChannel',
              value: showPaymentChannel,
              onChange,
              'data-testid': TestIds.TOGGLE_PAYMENT_CHANNEL,
            },
            ...(showPaymentChannel
              ? [
                  {
                    Component: PaymentChannelForm,
                    paymentChannelRules: paymentChannel.rules,
                    ...formProps,
                  },
                ]
              : []),
          ],
        },
      ]}
      render={({ Component, ...props }) => <Component {...props} />}
    />
  ) : null;
}

export { PaymentChannelStep };
