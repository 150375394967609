import { oneOf } from 'prop-types';
import { UserRoles } from '../UserRoles';

const Types = {
  YUBIKEY: 'YUBIKEY',
  SMS: 'SMS',
  TOTP: 'TOTP',
};

const Labels = (intl) => ({
  [Types.TOTP]: intl.formatMessage({ defaultMessage: 'Authenticator App' }),
  [Types.YUBIKEY]: intl.formatMessage({ defaultMessage: 'YubiKey' }),
  [Types.SMS]: intl.formatMessage({ defaultMessage: 'SMS' }),
});

const option = (intl, mfa) => ({ value: mfa, label: Labels(intl)[mfa] });

export const MFATypes = {
  Types,
  ...Types,
  Labels,
  // Return the MFA options for a specific user role
  options: (intl, role) => {
    const YubiKey = option(intl, Types.YUBIKEY);
    const SMS = option(intl, Types.SMS);
    const TOTP = option(intl, Types.TOTP);

    return {
      [UserRoles.BOPAdmin]: [YubiKey, TOTP],
      [UserRoles.PayorMaster]: [YubiKey, TOTP],
      [UserRoles.PayorAdmin]: [YubiKey, TOTP],
      [UserRoles.PayorSupport]: [YubiKey, TOTP, SMS],
      [UserRoles.PayeeAdmin]: [SMS],
      [UserRoles.PayeeSupport]: [SMS],
    }[role];
  },
  // Prop type
  propType: oneOf([Types.SMS, Types.YUBIKEY, Types.TOTP]),
};
