import React from 'react';
import { any } from 'prop-types';
import { VeloDate } from '../../VeloDate';
import { VeloTime } from '../../VeloTime';

PaymentViewDateTime.propTypes = {
  /** The date/time to render. */
  value: any.isRequired,
};

/**
 * Render a date/time in a format suitable for Payments data.
 */
function PaymentViewDateTime({ value, ...other }) {
  return (
    <div {...other}>
      <VeloDate.Long value={value} tag="div" />
      <VeloTime.Long value={value} tag="div" />
    </div>
  );
}

export { PaymentViewDateTime };
