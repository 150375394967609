import React from 'react';
import { bool, string } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { VeloDate } from '../VeloDate';
import { VeloTime } from '../VeloTime';
import { VeloStatusIndicator } from '../VeloStatusIndicator';

const name = 'other';

const propTypes = {
  created: string.isRequired,
  acceptTermsAndConditionsTimestamp: string,
  marketingOptInDecision: bool,
  marketingOptInTimestamp: string,
};

function TermsOfUseAndPrivacyPolicy({ acceptTermsAndConditionsTimestamp }) {
  if (acceptTermsAndConditionsTimestamp) {
    return (
      <>
        <VeloStatusIndicator
          type={VeloStatusIndicator.types.SUCCESS}
          label={<FormattedMessage defaultMessage="Accepted" />}
        />
        <VeloDate.Long value={acceptTermsAndConditionsTimestamp} tag="div" />
        <VeloTime.Long value={acceptTermsAndConditionsTimestamp} tag="div" />
      </>
    );
  }
  return (
    <VeloStatusIndicator
      type={VeloStatusIndicator.types.UNKNOWN}
      label={<FormattedMessage defaultMessage="Unavailable" />}
    />
  );
}

function MarketingOptIn({ marketingOptInDecision, marketingOptInTimestamp }) {
  if (typeof marketingOptInDecision === 'undefined') {
    return (
      <VeloStatusIndicator
        type={VeloStatusIndicator.types.UNKNOWN}
        label={<FormattedMessage defaultMessage="Unavailable" />}
      />
    );
  }
  return (
    <>
      <VeloStatusIndicator
        type={
          marketingOptInDecision
            ? VeloStatusIndicator.types.SUCCESS
            : VeloStatusIndicator.types.FAILED
        }
        label={
          marketingOptInDecision ? (
            <FormattedMessage defaultMessage="Opted in" />
          ) : (
            <FormattedMessage defaultMessage="Opted out" />
          )
        }
      />
      <VeloDate.Long value={marketingOptInTimestamp} tag="div" />
      <VeloTime.Long value={marketingOptInTimestamp} tag="div" />
    </>
  );
}

function PayeeViewOtherDetailsSection({
  payee: {
    created,
    acceptTermsAndConditionsTimestamp,
    marketingOptInDecision,
    marketingOptInTimestamp,
  },
  showUnavailableData,
}) {
  return {
    name,
    hideEdit: true,
    heading: <FormattedMessage defaultMessage="Other details" />,
    fields: [
      {
        id: 'created',
        label: <FormattedMessage defaultMessage="Date added" />,
        value: (
          <>
            <VeloDate.Long value={created} tag="div" />
            <VeloTime.Long value={created} tag="div" />
          </>
        ),
      },
      ...(acceptTermsAndConditionsTimestamp || showUnavailableData
        ? [
            {
              id: 'acceptTermsAndConditionsTimestamp',
              label: (
                <FormattedMessage defaultMessage="Terms of Use & Privacy Policy" />
              ),
              value: (
                <TermsOfUseAndPrivacyPolicy
                  acceptTermsAndConditionsTimestamp={
                    acceptTermsAndConditionsTimestamp
                  }
                />
              ),
            },
          ]
        : []),
      ...(marketingOptInDecision || showUnavailableData
        ? [
            {
              id: 'marketingOptInDecision',
              label: (
                <FormattedMessage defaultMessage="Product updates & marketing comms" />
              ),
              value: (
                <MarketingOptIn
                  marketingOptInDecision={marketingOptInDecision}
                  marketingOptInTimestamp={marketingOptInTimestamp}
                />
              ),
            },
          ]
        : []),
    ],
  };
}
PayeeViewOtherDetailsSection.sectionName = name;
PayeeViewOtherDetailsSection.propTypes = propTypes;

export { PayeeViewOtherDetailsSection };
