import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { PhoneNumber } from '../PhoneNumber';

const name = 'communications';

const propTypes = {
  email: string.isRequired,
  cellphoneNumber: string,
};

const CommunicationsSectionMode = {
  PAYOR: 'PAYOR',
  BOP: 'BOP',
};

const ModeHeadingMap = {
  [CommunicationsSectionMode.PAYOR]: (
    <FormattedMessage defaultMessage="Contact Details" />
  ),
  [CommunicationsSectionMode.BOP]: (
    <FormattedMessage defaultMessage="Communications" />
  ),
};

function PayeeViewCommunicationsSection({
  payee: { email, cellphoneNumber },
  mode = CommunicationsSectionMode.PAYOR,
}) {
  return {
    name,
    heading: ModeHeadingMap[mode],
    fields: [
      {
        id: 'email',
        label: <FormattedMessage defaultMessage="Contact email" />,
        value: email,
      },
      ...(cellphoneNumber
        ? [
            {
              id: 'cellphoneNumber',
              label: <FormattedMessage defaultMessage="Contact phone number" />,
              value: <PhoneNumber value={cellphoneNumber} />,
            },
          ]
        : []),
    ],
  };
}

PayeeViewCommunicationsSection.propTypes = propTypes;
PayeeViewCommunicationsSection.sectionName = name;
PayeeViewCommunicationsSection.modes = CommunicationsSectionMode;

export { PayeeViewCommunicationsSection };
