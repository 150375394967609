import React from 'react';
import { func } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { VeloLogoPage } from '../VeloLogoPage';
import { VeloButton } from '../VeloButton';

const root = 'reset-email-instructions-sent';

const TestIds = {
  CONTENT: `${root}-content`,
  BUTTON: `${root}-button`,
};

ResetInstructionsSentPage.testIds = TestIds;

ResetInstructionsSentPage.propTypes = {
  onClose: func.isRequired,
};

function ResetInstructionsSentPage({ onClose, ...props }) {
  return (
    <VeloLogoPage
      title={
        <FormattedMessage defaultMessage="Instructions successfully sent to your email" />
      }
      {...props}
    >
      <VeloLogoPage.Text data-testid={TestIds.CONTENT}>
        <FormattedMessage defaultMessage="Reset instructions have been sent to your registered email address." />
      </VeloLogoPage.Text>

      <VeloButton.Primary data-testid={TestIds.BUTTON} onClick={onClose}>
        <FormattedMessage defaultMessage="Back to Sign In" />
      </VeloButton.Primary>
    </VeloLogoPage>
  );
}

export { ResetInstructionsSentPage };
