import React from 'react';
import { node, func, bool } from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import styled from 'astroturf/react';
import { Content } from '../Content';
import { PageHeader } from '../PageHeader';
import { VeloTypography } from '../VeloTypography';
import { VeloFab } from '../VeloFab';
import { VeloCardPage } from '../VeloCardPage';
import { VeloLabelledItem } from '../VeloLabelledItem';
import { VeloButton } from '../VeloButton';
import { VeloDialog } from '../VeloDialog';

function getFabProps(intl) {
  return {
    title: intl.formatMessage({ defaultMessage: 'Payment Methods' }),
    label: <FormattedMessage defaultMessage="Create" />,
    icon: 'add',
    'data-testid': VeloFab.testIdBuilder('create'),
  };
}

const modes = {
  STANDARD: 'Standard',
  MANAGED: 'Managed',
};

function PaymentChannelsPageHeader({ onCreate }) {
  const intl = useIntl();
  const fabProps = getFabProps(intl);
  return (
    <PageHeader
      title={fabProps.title}
      fab={onCreate && <VeloFab {...fabProps} onClick={onCreate} />}
    />
  );
}

const HelpText = styled('div')`
  margin-top: 2.5rem;
  padding: 0 1rem;
`;

const CardPageHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0 1.5rem;
`;

const ListWrapper = styled(`div`)`
  padding: 1rem 0;
`;

const ModalWrapper = styled(VeloDialog)`
  :global(.mdc-dialog__surface) {
    max-width: 890px;
    width: 100%;
  }
`;

PaymentChannelsListPage.propTypes = {
  /** The list of payment channels to render. */
  list: node,
  /** Called when the "Create" button is clicked. */
  onCreate: func,
  /** Enable help text for ordering. */
  showHelpText: bool,
};

const FooterHelpText = () => (
  <HelpText>
    <VeloTypography use="bodyTextSmaller">
      <FormattedMessage defaultMessage="The first account in the list is treated as the primary account into which payments will be made by default if the currency matches. If the currency does not match, the system will check subsequent accounts and make the payment into the first matching currency account. If no matching currency account is listed, the payment will be made into the primary account and the receiving financial institution may charge you a fee or an exchange rate for conversion of currency. Fees and currency conversion rates are at the sole discretion of the receiving financial institution." />
    </VeloTypography>
  </HelpText>
);

/**
 * Payment channels page consisting of a header containing a title
 * and "Create" button and a list of payment channels.
 */
function PaymentChannelsListPage({ list, onCreate, showHelpText }) {
  const intl = useIntl();
  return (
    <Content
      header={<PaymentChannelsPageHeader onCreate={onCreate} />}
      footer={
        <VeloFab.Mobile
          {...getFabProps(intl)}
          // No label required on mobile
          label={undefined}
          onClick={onCreate}
        />
      }
    >
      {list}
      {showHelpText && <FooterHelpText />}
    </Content>
  );
}

function PaymentChannelsListCardPage({
  list,
  onCreate,
  onClose,
  payeeName,
  showHelpText,
}) {
  const intl = useIntl();
  return (
    <Content
      footer={
        <VeloFab.Mobile
          {...getFabProps(intl)}
          // No label required on mobile
          label={undefined}
          onClick={onCreate}
        />
      }
    >
      <VeloCardPage
        title={<FormattedMessage defaultMessage="Payee Payment Methods" />}
        divider
        onClose={onClose}
      >
        <CardPageHeader>
          <VeloLabelledItem label="Payee name">{payeeName}</VeloLabelledItem>
          <VeloButton.Primary
            onClick={onCreate}
            data-testid="payment-channel-create"
          >
            <VeloButton.Icon icon="add" />
            <FormattedMessage defaultMessage="Create payment method" />
          </VeloButton.Primary>
        </CardPageHeader>
        <VeloLabelledItem label="Payment method priority order">
          <ListWrapper>{list}</ListWrapper>
        </VeloLabelledItem>
        {showHelpText && <FooterHelpText />}
      </VeloCardPage>
    </Content>
  );
}

PaymentChannelsFormPage.props = {
  /**
   * The form component to render into the content area
   */
  form: func.isRequired,
};

function PaymentChannelsFormPage({ form }) {
  return <Content>{form}</Content>;
}

function PaymentChannelsFormModalPage({ form }) {
  return (
    <ModalWrapper open>
      <VeloDialog.Content>{form}</VeloDialog.Content>
    </ModalWrapper>
  );
}

const PaymentChannelsPage = {
  [modes.STANDARD]: {
    List: PaymentChannelsListPage,
    Form: PaymentChannelsFormPage,
  },
  [modes.MANAGED]: {
    List: PaymentChannelsListCardPage,
    Form: PaymentChannelsFormModalPage,
  },
  modes,
};

export { PaymentChannelsPage };
