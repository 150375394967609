import React from 'react';
import {
  Content,
  VeloNotification,
  FundingAccountEditForm,
} from 'velo-react-components';
import {
  updateAccountDetailsV2 as updateAccountDetails,
  getFundingAccountV2 as getFundingAccount,
  getFundingAccountStatusV2 as getFundingAccountStatus,
} from 'velo-api/src/entities/fundingAccounts';
import { getPayorByIdV2 as getPayor } from 'velo-api/src/entities/payors';
import { getSupportedRails } from 'velo-api/src/entities/rails';
import {
  useCallbackFnAsResultState,
  usePresenter,
  useSerializableMemo,
} from '../../hooks';
import { forkResult } from '../../selectors';
import {
  createFromHook,
  createRenderForksFromComponent,
} from '../../containers';
import { FundingAccountsEditPresenter } from './FundingAccountsEditPresenter';
import { getFundingAccountPrivileges } from './FundingAccountHelpers';

const entitySpec = {
  getPayor,
  getFundingAccount,
  updateAccountDetails,
  getFundingAccountStatus,
  getSupportedRails,
};

const forks = createRenderForksFromComponent(FundingAccountEditForm);

const notification = VeloNotification.types.SOURCE_ACCOUNT_UPDATE_SUCCESS;

export const FundingAccountsEditRoute = createFromHook(
  ({ fundingAccountId, role }) => {
    const [loader, getProps] = usePresenter(
      FundingAccountsEditPresenter,
      entitySpec,
      notification,
      useSerializableMemo({
        fundingAccountId,
        privileges: getFundingAccountPrivileges(role),
      })
    );
    const [result] = useCallbackFnAsResultState(loader);
    return forkResult(forks, result, getProps(result));
  },
  {
    render: (children) => <Content>{children}</Content>,
  }
);
