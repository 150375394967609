import React from 'react';
import { string } from 'prop-types';

PreviewThemeThumbnail.propTypes = {
  /** primary theme colour. */
  colour: string.isRequired,
};

function PreviewThemeThumbnail({ colour }) {
  const background = `var(--token-color-system-page-background)`;
  const foreground = `var(--token-color-system-page-background-inverse)`;
  return (
    <svg fill="none" viewBox="0 0 178 99" xmlns="http://www.w3.org/2000/svg">
      <rect
        x=".5"
        y=".5"
        width="176.878"
        height="98"
        rx="3.5"
        fill={background}
        stroke="#57606E"
      />
      <rect
        x="7.244"
        y="13.683"
        width="22.537"
        height="22.537"
        rx="11.268"
        fill={colour}
      />
      <rect
        x="7.244"
        y="53.927"
        width="22.537"
        height="22.537"
        rx="11.268"
        fill={colour}
      />
      <rect
        x="37.024"
        y="21.732"
        width="130.39"
        height="7.244"
        rx="3.622"
        fill={foreground}
      />
      <path
        d="M46.488 55.122a3 3 0 013-3h124.39a3 3 0 013 3V95a3 3 0 01-3 3H49.488a3 3 0 01-3-3V55.122z"
        fill={colour}
      />
      <path
        d="M80.293 69.622c0-2 1.621-3.622 3.622-3.622h92.963v7.244H83.915c-2 0-3.622-1.622-3.622-3.622zM93.17 87.33a3.623 3.623 0 013.623-3.623h80.085v7.244H96.793c-2 0-3.622-1.621-3.622-3.622z"
        fill={foreground}
      />
      <path stroke={foreground} d="M18.207 36.219v17.708m0 22.536V99" />
    </svg>
  );
}

export { PreviewThemeThumbnail };
