import React, { createContext, useContext } from 'react';

const Context = createContext({
  open: false,
});

function VeloModalSideSheetProvider({ open, children }) {
  return <Context.Provider value={{ open }}>{children}</Context.Provider>;
}

function useVeloModalSideSheetContext() {
  return useContext(Context);
}

export { Context, useVeloModalSideSheetContext, VeloModalSideSheetProvider };
