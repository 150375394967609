export const errorsByCode = {
  401: 'UNAUTHORIZED',
  403: 'FORBIDDEN',
  404: 'NOT_FOUND',
};

export const codesByError = Object.entries(errorsByCode).reduce(
  (acc, [k, v]) => ({ ...acc, [v]: k }),
  {}
);
