import React from 'react';
import { func } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { VeloLogoPage } from '../VeloLogoPage';
import { VeloButton } from '../VeloButton';

const root = 'email-instructions-sent';

const TestIds = {
  CONTENT: `${root}-content`,
  BUTTON: `${root}-button`,
};

EmailInstructionsSent.testIds = TestIds;

EmailInstructionsSent.propTypes = {
  onClickBackLink: func.isRequired,
};

function EmailInstructionsSent({ onClickBackLink }) {
  return (
    <>
      <VeloLogoPage.Text data-testid={TestIds.CONTENT}>
        <FormattedMessage defaultMessage="A password reset link has been emailed to the provided address if registered." />
      </VeloLogoPage.Text>

      <VeloButton.Primary
        data-testid={TestIds.BUTTON}
        onClick={onClickBackLink}
      >
        <FormattedMessage defaultMessage="Back to Sign In" />
      </VeloButton.Primary>
    </>
  );
}

export { EmailInstructionsSent };
