export function instructPayoutV3(send) {
  return (payoutId, body, ...args) =>
    send(
      {
        path: '/v3/payouts/{payoutId}',
        pathParameters: { payoutId },
        type: 'post',
        query: undefined,
        body: body,
        headers: undefined,
        auth: 'oauth2',
        contentType: 'application/json',
      },
      ...args
    );
}
