import pipe from 'callbag-pipe';
import map from 'callbag-map';
import flatten from 'callbag-flatten';
import rescue from 'callbag-rescue';
import throwError from 'callbag-throw-error';
import { SendError } from '../SendError';

export function rescueWithRefresh(refreshStream) {
  return (source) =>
    pipe(
      source,
      // rescue traps errors only, and expects another stream to be returned
      rescue((error) => {
        if (SendError.isType(401, error)) {
          return pipe(
            // first refresh
            refreshStream,
            // retry source on success
            map((_) => source),
            // flatten returned source so it's a regular call
            flatten
          );
        }
        // otherwise just throw the original error
        return throwError(error);
      })
    );
}
