import React, { useState } from 'react';
import { bool, func, string } from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { VeloGridLoading } from '../VeloGridLoading';
import { VeloTabbedView } from '../VeloTabbedView';
import { VeloFieldGrid } from '../VeloFieldGrid';
import { VeloOnOffSwitch } from '../VeloOnOffSwitch';
import { VeloSelect } from '../VeloSelect';

const root = 'advanced-form';

AdvancedForm.TestIds = {
  PAYMENT_METHODS: `${root}-payment-methods`,
  TRANSLATION_LOCALE: `${root}-translation-locale`,
};

const Labels = {
  SAVE: <FormattedMessage defaultMessage="Save" />,
  SAVING: <FormattedMessage defaultMessage="Saving..." />,
};

AdvancedForm.propTypes = {
  /** Callback on form save. */
  onSubmit: func.isRequired,
  /** Default state of advanced payment method setting */
  advancedPaymentMethodsEnabled: bool.isRequired,
  /** Default state of locale setting */
  locale: string,
  /** Locale setting function */
  setLocale: func,
};

function AdvancedForm({
  onSubmit,
  advancedPaymentMethodsEnabled,
  locale = 'en-US',
}) {
  const intl = useIntl();
  const [showAdvancedPaymentMethods, setAdvancedPaymentMethods] = useState(
    advancedPaymentMethodsEnabled
  );
  const [localeTranslation, setLocaleTranslation] = useState(locale);

  const config = {
    getInitialValues() {
      return {
        showAdvancedPaymentMethods,
        localeTranslation,
      };
    },
    formatBody() {
      return {
        showAdvancedPaymentMethods,
        locale: localeTranslation,
      };
    },
    getButtonProps: (submitting) =>
      submitting ? { children: Labels.SAVING } : { children: Labels.SAVE },
    createSections() {
      const formFields = [
        {
          heading: '',
          fields: [
            {
              Component: VeloOnOffSwitch,
              onChange: (event) =>
                setAdvancedPaymentMethods(event.target.checked),
              name: 'showPaymentMethods',
              value: showAdvancedPaymentMethods,
              label: (
                <FormattedMessage defaultMessage="Additional payment methods" />
              ),
              'data-testid': AdvancedForm.TestIds.PAYMENT_METHODS,
            },
            {
              Component: VeloSelect,
              onChange: (event) => setLocaleTranslation(event.target.value),
              name: 'localeTranslations',
              value: localeTranslation,
              label: <FormattedMessage defaultMessage="Locale" />,
              'data-testid': AdvancedForm.TestIds.TRANSLATION_LOCALE,
              options: [
                {
                  label: intl.formatMessage({
                    defaultMessage: 'English United States (en-US)',
                  }),
                  value: 'en-US',
                },
                {
                  label: intl.formatMessage({
                    defaultMessage: 'French France (fr-FR)',
                  }),
                  value: 'fr-FR',
                },
              ],
            },
          ],
        },
      ];
      return formFields;
    },
  };

  const [gridProps, formProps] = VeloTabbedView.useForm(config, {
    onSubmit,
  });

  return (
    <VeloTabbedView.Form
      buttonProps={gridProps.buttonProps}
      onSubmit={gridProps.onSubmit}
    >
      <VeloFieldGrid
        onChange={formProps.onChange}
        sections={formProps.sections}
      />
    </VeloTabbedView.Form>
  );
}

AdvancedForm.Loading = () => (
  <VeloTabbedView.Form.Loading>
    <VeloGridLoading
      sections={[
        {
          heading: false,
          fields: [{ type: VeloGridLoading.fieldTypes.OnOffSwitch }],
        },
      ]}
    />
  </VeloTabbedView.Form.Loading>
);

AdvancedForm.Error = VeloTabbedView.Error;

export { AdvancedForm };
