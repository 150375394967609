import React from 'react';
import { FormattedMessage } from 'react-intl';
import { PaymentViewResult } from '../../PaymentViewResult';

/**
 * Render a Source Account name.
 */
function SourceAccount({ result }) {
  return (
    <PaymentViewResult
      {...result}
      errorMessage={
        <FormattedMessage defaultMessage="Error loading Source Account" />
      }
    />
  );
}

export { SourceAccount };
