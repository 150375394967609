import React from 'react';
import styled from 'astroturf/react';
import {
  TopAppBar,
  SimpleTopAppBar,
  TopAppBarTitle,
  TopAppBarNavigationIcon,
  TopAppBarSection,
  TopAppBarRow,
  TopAppBarFixedAdjust,
} from '@rmwc/top-app-bar';
import '@material/top-app-bar/dist/mdc.top-app-bar.css';

export function VeloTopAppBar(props) {
  return <TopAppBar {...props} />;
}

VeloTopAppBar.Simple = SimpleTopAppBar;
VeloTopAppBar.Title = styled(TopAppBarTitle)`
  font-family: var(--ds-typography-font-family);
`;
VeloTopAppBar.NavigationIcon = TopAppBarNavigationIcon;
VeloTopAppBar.Section = TopAppBarSection;
VeloTopAppBar.Row = TopAppBarRow;
VeloTopAppBar.FixedAdjust = TopAppBarFixedAdjust;
