import React from 'react';
import { string, oneOf, node, bool } from 'prop-types';
import styled from 'astroturf/react';
import { VeloStatusIndicator } from '../VeloStatusIndicator';
import { VeloIcon } from '../VeloIcon';

const Types = VeloStatusIndicator.types;

Types.HIBANAINFO = 'hibanainfo';

const Callout = styled('div')`
  @import 'velo-variables';
  @import 'velo-mixins';

  display: flex;
  padding: 0.75rem;

  &.type-warning {
    background-color: velo-color('token-color-system-warning-lighter');
  }

  &.type-success {
    background-color: velo-color('token-color-system-success-lighter');
  }

  &.type-info {
    background-color: velo-color('token-color-system-info-lighter');
  }

  &.type-error {
    background-color: velo-color('token-color-system-error-lighter');
  }

  &.type-unknown {
    background-color: velo-color('token-color-system-unknown');
  }

  &.type-hibanainfo {
    background-color: #ebf8eb;
  }

  &.type-skeleton {
    @include skeleton-background();
  }
`;

const type = oneOf(['skeleton', ...Object.values(Types)]).isRequired;

Callout.propTypes = {
  type,
};

const Icon = styled(VeloIcon)`
  @import 'velo-variables';

  &.type-warning {
    color: velo-color('token-color-system-warning-default');
  }

  &.type-success {
    color: velo-color('token-color-system-success-default');
  }

  &.type-info {
    color: velo-color('token-color-system-info-default');
  }

  &.type-error {
    color: velo-color('token-color-system-error-default');
  }

  &.type-hibanainfo {
    color: velo-color('token-color-text-default');
  }

  &.type-unknown {
    color: velo-color('token-color-text-default');
  }
`;

Icon.propTypes = {
  type: oneOf(Object.values(Types)).isRequired,
};

const Content = styled('div')`
  display: flex;
  align-items: center;
  flex-basis: 100%;

  &.icon {
    padding-left: 1rem;
  }
`;

Content.propTypes = {
  icon: bool,
};

const icons = {
  [Types.SUCCESS]: 'check_circle',
  [Types.ERROR]: 'cancel',
  [Types.INFO]: 'info',
  [Types.HIBANAINFO]: 'info',
  [Types.WARNING]: 'warning',
  [Types.UNKNOWN]: 'help',
};

VeloCallout.propTypes = {
  /** Custom CSS class(es). */
  className: string,
  /** The type to display */
  type,
  /** The contents to be added to the callout */
  children: node,
  /** Hide/show the icon. This is enabled by default */
  icon: bool,
};

VeloCallout.types = Types;

function Loading() {
  return (
    <Callout type="skeleton">
      <Content>&nbsp;</Content>
    </Callout>
  );
}

VeloCallout.Loading = Loading;

/**
 * The VeloCallout.
 */
function VeloCallout({ type, children, icon = true, ...other }) {
  //get the icon for the types

  return (
    <Callout type={type} {...other}>
      {icon && <Icon type={type} icon={icons[type]} />}
      <Content icon={icon}>{children}</Content>
    </Callout>
  );
}

export { VeloCallout };
