import React from 'react';
import omit from 'just-omit';
import { Content, VeloCardForm, UserRoles } from 'velo-react-components';
import { fundingAccountSelectors, Privileges } from 'velo-data';
import { getSourceAccountV3 as getSourceAccount } from 'velo-api/src/entities/sourceAccounts';
import {
  getFundingAccountV2 as getFundingAccount,
  getFundingAccountsV2 as getFundingAccounts,
} from 'velo-api/src/entities/fundingAccounts';
import { getPayorByIdV2 } from 'velo-api/src/entities/payors';
import { forkResult } from '../../selectors';
import {
  createFromHook,
  createRenderForksFromComponent,
} from '../../containers';
import {
  usePresenter,
  useCallbackFnAsResultState,
  useSerializableMemo,
} from '../../hooks';

const convertComponentToForkable = (Component) => {
  Component.Error = VeloCardForm.Error;
  Component.Loading = Component.Loading || Component;
  Component.Empty = Component;
  return Component;
};

const baseSpec = {
  getSourceAccount,
  getPayor: getPayorByIdV2,
  getFundingAccount,
  getFundingAccounts,
};

export function createRoute(Presenter, Component, entitySpec, notification) {
  // Turn a regular source account based form into something forkable
  const ForkableComponent = convertComponentToForkable(Component);
  // Create render forks given this altered component
  const forks = createRenderForksFromComponent(ForkableComponent);
  // merge in any extra spec with the defaults (getPayor etc)
  const spec = { ...baseSpec, ...entitySpec };
  // A Route component is created from a given hook
  const Route = createFromHook(
    // here we create a hook on the fly
    ({ sourceAccountId, role }) => {
      const [loader, componentProps] = usePresenter(
        Presenter,
        spec,
        notification,
        useSerializableMemo({
          sourceAccountId,
          privileges: UserRoles.isBackOffice(role)
            ? [Privileges.SOURCE_ACCOUNT_VIEW_PAYOR]
            : [],
        })
      );

      const [result] = useCallbackFnAsResultState(loader);
      return forkResult(forks, result, {
        ...componentProps,
        // merge extra attrs from result onto the props
        ...omit(result, ['result', 'error']),
      });
    },
    {
      // the `createFromHook` is passed this default render function, wrapping
      // the result of the forks in a content block
      render: (children) => <Content>{children}</Content>,
    }
  );
  // assign the Presenter onto the route for testing purposes
  Route.Presenter = Presenter;
  return Route;
}

export function combineSourceAndFundingData(
  { name, ...sourceAccount },
  fundingAccounts
) {
  const fundingAccount =
    fundingAccountSelectors.findBySourceAccountId(
      sourceAccount.id,
      fundingAccounts
    ) || {};
  return {
    ...sourceAccount,
    sourceAccountName: name,
    fundingAccountName: fundingAccount.accountName,
    accountNumber: fundingAccount.accountNumber,
    routingNumber: fundingAccount.routingNumber,
  };
}
