import React from 'react';
import { arrayOf, oneOfType, node, string } from 'prop-types';
import styled from 'astroturf/react';
import { VeloTypography } from '../VeloTypography';

const List = styled('ol')`
  @import 'velo-variables';

  list-style: none;
  padding: 0;
  margin: 0;

  @media (min-width: velo-breakpoint(XS)) {
    text-align: center;
  }
`;

const ListItem = styled(VeloTypography).attrs({ use: 'bodyText', tag: 'li' })`
  margin-top: 0;
  margin-bottom: 0.875rem;
  &:last-child {
    margin-bottom: 0;
  }
`;

const Bullet = (props) => (
  <VeloTypography use="bodyTextEmphasised" {...props} />
);

VeloSteps.propTypes = {
  steps: arrayOf(oneOfType([node, string])).isRequired,
  className: string,
};

function VeloSteps({ steps, ...props }) {
  return (
    <List {...props}>
      {steps.map((step, i) => (
        <ListItem key={`velo-step-${i}`}>
          <Bullet>Step {i + 1}: </Bullet>
          {step}
        </ListItem>
      ))}
    </List>
  );
}

VeloSteps.Bullet = Bullet;
VeloSteps.ListItem = ListItem;
VeloSteps.List = List;

export { VeloSteps };
