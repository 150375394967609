import React from 'react';
import { bool } from 'prop-types';
import styled from 'astroturf/react';
import { VeloSkeleton } from '../VeloSkeleton';
import { VeloBackground } from '../VeloBackground';

const minHeight = '5rem';

//token-typography__summary-data
//token-typography__summary-data-error
const CellValue = styled('div')`
  @import 'velo-variables';
  @import 'velo-mixins';

  font-size: 1.2rem;
  @include velo-font-weight(bold);

  @include from-breakpoint(XS) {
    margin-top: 1rem;
  }

  &.error {
    color: velo-color('token-color-system-error-default');
  }
`;

CellValue.propTypes = {
  /**
   * Gives the content an error color
   */
  error: bool,
};

const Cell = styled('li')`
  @import 'velo-variables';
  @import 'velo-mixins';
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0;
  color: velo-color('token-color-text-default');
  list-style: none;
  background-color: velo-color('token-color-system-page-surface');

  & + & {
    border-top: 1px solid velo-color('token-color-system-divider');
  }

  @include from-breakpoint(XS) {
    padding: 0 1rem;
    flex-direction: column;
    justify-content: center;
    flex: 1;

    & + & {
      border-top-width: 0;
      border-left: 1px solid velo-color('token-color-system-divider');
    }
  }
`;

const Row = styled(VeloBackground)`
  @import 'velo-variables';
  @import 'velo-mixins';
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0.5rem 1rem;
  min-height: ${minHeight};
  background-color: velo-color('token-color-system-page-surface');

  @include from-breakpoint(XS) {
    padding: 0;
    flex-direction: row;
    justify-content: space-evenly;
  }
`;

const Loading = styled(VeloSkeleton)`
  @import 'velo-mixins';

  min-height: ${minHeight};

  @include from-breakpoint(XS) {
    width: 30rem;
  }
`;

PayoutPaymentsSummary.Loading = Loading;

export function PayoutPaymentsSummary({ columns }) {
  return (
    <Row as="ul" rounded>
      {columns.map((item, index) => (
        <Cell key={`title-${index}`}>
          {item.label}
          <CellValue
            error={item.showError && item.value > 0}
            data-testid={item['data-testid']}
          >
            {item.value}
          </CellValue>
        </Cell>
      ))}
    </Row>
  );
}
