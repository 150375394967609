import React, { useState, useCallback } from 'react';
import { Route } from 'react-router';
import { useHistory, useLocation } from 'react-router-dom';
import {
  useWireframe,
  usePresenter,
  useCallbackFnAsResultState,
  forkResult,
} from 'velo-portal-common';
import { listPayeesV4 as getPayees } from 'velo-api/src/entities/payees';
import {
  confirmOpenBankingPayoutV1,
  submitOpenBankingPayoutV1,
  getOpenBankingInstitutionsV1,
  getOpenBankingPayeeV1 as getOpenBankingPayee,
} from 'velo-api/src/entities/openbanking';
import {
  OpenBankingCreatePayoutForm,
  Content,
  VeloNotification,
} from 'velo-react-components';
import { OpenBankingCreatePayoutPresenter } from './OpenBankingCreatePayoutPresenter';

const entity = {
  getPayees,
  getOpenBankingPayee,
  submitOpenBankingPayout: submitOpenBankingPayoutV1,
  confirmOpenBankingPayout: confirmOpenBankingPayoutV1,
  getOpenBankingInstitutions: getOpenBankingInstitutionsV1,
};

const tokenResultForks = {
  error: (error) => (
    <OpenBankingCreatePayoutForm.ConfirmPayoutError error={error} />
  ),
};

const notifications = {
  PAYOUT_SUBMITTED: VeloNotification.types.PAYOUT_SUBMITTED,
};

function OpenBankingCreatePayoutRoute(props) {
  const wireframe = useWireframe(useHistory());
  const [payeeId, setPayeeId] = useState();
  const location = useLocation();
  const token = new URLSearchParams(location.search).get('token');

  const [
    loader,
    paymentChannelsLoader,
    tokenLoader,
    { onClose, onSubmit, ...componentProps },
  ] = usePresenter(
    OpenBankingCreatePayoutPresenter,
    entity,
    notifications,
    props
  );

  const [result] = useCallbackFnAsResultState(loader, false);

  const [paymentChannelsResult] = useCallbackFnAsResultState(
    useCallback(
      (cb) => paymentChannelsLoader(payeeId, cb),
      [paymentChannelsLoader, payeeId]
    )
  );

  const [tokenResult, , setTokenResult] = useCallbackFnAsResultState(
    useCallback((cb) => tokenLoader(token, cb), [tokenLoader, token]),
    false
  );

  return (
    <>
      <Content>
        {forkResult(
          {
            none: () => (
              <OpenBankingCreatePayoutForm.Loading onClose={onClose} />
            ),
            error: (error) => (
              <OpenBankingCreatePayoutForm.Error
                onClose={onClose}
                error={error}
              />
            ),
            value: (institutions) => (
              <OpenBankingCreatePayoutForm
                {...componentProps}
                onSubmit={(data, cb) => {
                  setTokenResult(useCallbackFnAsResultState.defaultState);
                  onSubmit(data, cb);
                }}
                paymentChannelData={paymentChannelsResult}
                onSetPayeeId={setPayeeId}
                institutions={institutions}
                onClose={onClose}
                confirmPayoutError={forkResult(tokenResultForks, tokenResult)}
              />
            ),
          },
          result
        )}
      </Content>

      <Route path={wireframe.navigateToPayoutOpenBankingConsent.path} exact>
        <OpenBankingCreatePayoutForm.ConfirmPayoutLoading />
      </Route>
    </>
  );
}

export { OpenBankingCreatePayoutRoute };
