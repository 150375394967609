import React from 'react';
import { node, bool } from 'prop-types';
import styled from 'astroturf/react';
import { VeloTypography } from '../VeloTypography';

const Container = styled('div')`
  @import 'velo-variables';

  display: flex;
  justify-content: space-between;
  align-content: center;
  margin: 0 1rem;

  &.disableMargin {
    margin: 0;
  }

  @media (max-width: velo-breakpoint(XS)) {
    /* header not needed on mobile as content page title now displayed in TopAppBar */
    display: none;
  }
`;

Container.propTypes = {
  disableMargin: bool,
};

const Title = styled(VeloTypography)`
  margin: 0;
`;

PageHeader.propTypes = {
  /**
   * Title to render.
   */
  title: node.isRequired,
  /**
   * Optional fab node to render as a button
   */
  fab: node,
};

/**
 * Creates a header to be displayed on a page with an optional fab button.
 */
function PageHeader({ title, fab, ...props }) {
  return (
    <Container {...props}>
      <Title use="pageHeader" tag="h1">
        {title}
      </Title>
      {fab}
    </Container>
  );
}

export { PageHeader };
