import { arrayOf, node, shape, string } from 'prop-types';

const Alignment = {
  START: 'start',
  MIDDLE: 'middle',
  END: 'end',
};

const Size = {
  XSMALL: 'xsmall',
  SMALL: 'small',
  LARGE: 'large',
  XLARGE: 'xlarge',
};

const SortOrder = {
  DESCENDING: -1,
  NONE: 0,
  ASCENDING: 1,
};

const HeadingsType = arrayOf(
  shape({
    /** The heading label. */
    label: node.isRequired,
    /** The heading name. */
    name: string.isRequired,
  })
);

export { Alignment, SortOrder, HeadingsType, Size };
