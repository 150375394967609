export class SendError extends Error {
  static isType(type, error) {
    return error.type === type;
  }

  static isClientError(error) {
    return error.type >= 400 && error.type < 500;
  }

  static isNotFoundError(error) {
    return error.type === 404;
  }

  static isUnauthorisedError(error) {
    return error.type === 401;
  }

  /**
   * The default is "ui" to match MicroservicesMaster._createCatchHandler
   * The error must have a correlationId for the Redux error handling to work
   *
   * TODO: Remove default when Redux has been removed
   **/
  constructor(type, errors, correlationId = 'ui', options = {}) {
    super('An error occurred with the send request');
    this.name = 'SendError';
    this.type = type;
    this.errors = errors;
    this.legacyAuthError = options.legacyAuthError;
    // used for auth-service errors which don't follow the normal structure
    // TODO: bring auth-service in line with the normal array structure, and remove this
    this.correlationId = correlationId;
  }
}
