/**
 * Build the legal links based on those configured for each application variant
 */
const convertLegalLinksToNavItems = (legalLinks, orders, labels, group) => {
  const items = Object.entries(legalLinks).map(([id], index) => {
    return {
      id,
      label: labels[id],
      group,
      legalLinksPrimaryItem: index === 0,
      order: orders[id],
      openInNewTab: true,
    };
  });

  // The first item has a submenu when the nav drawer
  // is collapsed. The items are a copy of the legal
  // links with the `parent` property applied.
  items[0].subMenuItems = items.map((item, index) => {
    return { ...item, order: index, parent: items[0].id };
  });

  return items;
};

/**
 *  Build primary links - known Default group
 * */
const buildNavigationItem = (props) => {
  const navigationItem = buildNavigationParentItem(props);
  const renderedSubMenuItems = buildNavigationSubMenu(props);
  return { ...navigationItem, subMenuItems: renderedSubMenuItems };
};

/**
 * Function to build the Sub Menu configuration if required, or return nothing if there is no sub menu
 **/
const buildNavigationSubMenu = (props) => {
  const { id, subMenuItems, routes, labels, orders } = props;
  const currentSubMenuItems = subMenuItems[id];

  if (!currentSubMenuItems) return;

  return currentSubMenuItems.map((subMenuItemId) => {
    return (
      subMenuItemId && {
        match: (route) =>
          route.toLowerCase().indexOf(routes[subMenuItemId].toLowerCase()) > -1,
        id: subMenuItemId,
        label: labels[subMenuItemId],
        order: orders[subMenuItemId],
        route: routes[subMenuItemId],
        parent: id,
      }
    );
  });
};

/**
 * Build the top level navigation Item
 */
const buildNavigationParentItem = (props) => {
  const {
    id,
    routes,
    orders,
    labels,
    group,
    dividers = { top: false, bottom: false },
    subMenuItems,
  } = props;
  //check to see if the nav item has a valid route
  const hasRoute = routes[id];
  const matchRoute = hasRoute && {
    match: (route) => {
      const isMatchingRoute = (id, route) =>
        route.toLowerCase().indexOf(routes[id].toLowerCase()) > -1;

      const hasSubMenuMatch =
        subMenuItems[id] &&
        subMenuItems[id].reduce(
          (hasSubMenuMatch, subNavId) =>
            !!(hasSubMenuMatch || isMatchingRoute(subNavId, route)),
          false
        );

      return hasSubMenuMatch || isMatchingRoute(id, route);
    },
  };
  return {
    id: id,
    label: labels[id],
    group: group,
    hasDividers: { top: dividers.top, bottom: dividers.bottom },
    order: orders[id],
    ...matchRoute,
  };
};

function buildMapFromOrderedKeys(orderedKeys) {
  return orderedKeys.reduce(
    ({ orderByKey, count }, key) => ({
      orderByKey: { ...orderByKey, [key]: count },
      count: count + 1,
    }),
    {
      orderByKey: {},
      count: 1,
    }
  ).orderByKey;
}

export {
  buildMapFromOrderedKeys,
  buildNavigationItem,
  convertLegalLinksToNavItems,
  buildNavigationParentItem,
  buildNavigationSubMenu,
};
