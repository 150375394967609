import { NavigationItems } from '../NavigationDrawer/NavigationItems';

const baseUrl = 'https://www.velopayments.com/';
const payeeUrl = 'https://policies.velopayments.com/payee';

export const brandingTokens = {
  copyrightName: 'Velo Payments',
  platformName: 'Velo Payments',
  logoUrl: `${process.env.PUBLIC_URL}/assets/logo/velo-logo-horz.svg`,
  legalLinks: {
    VeloBackOfficePortal: {
      [NavigationItems.TERMS]: baseUrl,
      [NavigationItems.PRIVACY]: baseUrl + 'privacy',
      [NavigationItems.CONTACT]:
        'https://support.velopayments.com/hc/en-us/requests/new',
    },
    VeloPayorPortal: {
      [NavigationItems.TERMS]: baseUrl + 'payorterms',
      [NavigationItems.PRIVACY]: baseUrl + 'privacy',
      [NavigationItems.CONTACT]:
        'https://support.velopayments.com/hc/en-us/requests/new',
    },
    VeloPayeePortal: {
      [NavigationItems.TERMS]: `${payeeUrl}/terms/?closeOption=true`,
      [NavigationItems.PRIVACY]: `${payeeUrl}/privacy/?closeOption=true`,
      [NavigationItems.CONTACT]:
        'https://support.velopayments.com/hc/en-us/requests/new',
    },
    VeloOpenBankingPayins: {
      [NavigationItems.TERMS]: `${baseUrl}/openbankingterms`, // currently unimplemented
      [NavigationItems.PRIVACY]: `${baseUrl}/privacy/?closeOption=true`,
      [NavigationItems.CONTACT]:
        'mailto:support@velopayments.com?subject=Open%20Banking%20Support%20Issue',
    },
    VeloOpenBankingPayinsAdmin: {},
  },
};
