import React, { useEffect, useState, useRef } from 'react';
import { bool, func, node, string } from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import styled from 'astroturf/react';
import { VeloDialog } from '../VeloDialog';
import { VeloCardForm } from '../VeloCardForm';
import { VeloTypography } from '../VeloTypography';
import { VeloButton } from '../VeloButton';
import { VeloTextField } from '../VeloTextField';
import { VeloSectionGrid } from '../VeloSectionGrid';
import { VeloStatefulButton } from '../VeloStatefulButton';
import { useSMSCode } from '../FormFields/FormFieldTypes';
import { VeloDivider } from '../VeloDivider';
import { ErrorMessage } from '../ErrorMessage';

const root = 'otp-admin-dialog';

const TestIds = {
  SEND_CODE: `${root}-send-code`,
  CONTINUE: `${root}-continue`,
  CODE: `${root}-code`,
};

const Form = styled(VeloCardForm)`
  margin: 0;
`;

const Text = ({ disabled, ...props }) => (
  <VeloTypography use={disabled ? 'bodyTextDisabled' : 'bodyText'} {...props} />
);

const Error = styled(ErrorMessage)`
  margin-top: 1rem;
`;

const Item = ({ label, value }) => (
  <>
    <VeloTypography use="itemLabel" tag="div">
      {label}
    </VeloTypography>

    <VeloTypography use="itemContent" tag="div">
      {value}
    </VeloTypography>
  </>
);

OTPAdminDialog.propTypes = {
  /** Open/close the dialog. */
  open: bool.isRequired,
  /** The dialog title. */
  title: node.isRequired,
  /** The name of the user the operation affects. */
  user: node,
  /** The SMS number of the user the operation affects. */
  mobile: node,
  /** Called when the "Send Code" button is clicked. */
  onSendCode: func.isRequired,
  /** Called when the form is submitted. Passed the code. */
  onSubmit: func.isRequired,
  /** Called when the dialog is closed/ESC is pressed. */
  onClose: func.isRequired,
  /**
   * Used for testing to avoid async issues.
   * Revisit when React 16.9.0 lands.
   */
  closedState: string,
};

export function OTPAdminDialog({
  open,
  title,
  user,
  mobile,
  onSendCode,
  onSubmit,
  onClose,
  // Used for testing only as we mock out the
  // `useDialogManager` hook so `VeloDialog onStateChange`
  // never gets called.
  closed = false,
}) {
  const textField = useRef(null);
  const [code, setCode] = useState('');
  const [codeSent, setCodeSent] = useState(false);
  const [overlay, setOverlay] = useState(false);
  const [error, setError] = useState(undefined);
  const [submitError, setSubmitError] = useState(undefined);
  const [dialogState, setDialogState] = useState();
  const intl = useIntl();

  const smsCodeField = useSMSCode();

  useEffect(() => {
    // Reset the form when the dialog is closed
    // Note that using `open` is not enough as the
    // dialog will still be briefly visible.
    // Instead we rely on the dialog state that the
    // MDC dialog supplies via `onStateChange`.
    if (dialogState === 'closed' || closed) {
      setCode('');
      setCodeSent(false);
      setError(undefined);
      setSubmitError(undefined);
    }
  }, [dialogState, closed]);

  // This will handle using ESC to close the dialog
  const onCloseDialog = (evt) => {
    if (evt.detail.action === 'close') {
      onClose();
    }
  };

  return (
    <VeloDialog
      open={open}
      disableTrapFocus
      onClose={onCloseDialog}
      onStateChange={setDialogState}
    >
      <Form
        title={title}
        onSubmit={(event) => {
          event.preventDefault();
          setSubmitError(undefined);
          onSubmit({ verificationCode: code }, (error) => {
            if (error) {
              setSubmitError(error);
            }
          });
        }}
        onClose={onClose}
        buttonProps={{
          children: intl.formatMessage({
            defaultMessage: 'Continue',
          }),
          disabled: !codeSent || !code,
          'data-testid': TestIds.CONTINUE,
        }}
        fullScreen={false}
        overlay={overlay}
      >
        <VeloSectionGrid
          compact
          sections={[
            {
              fields: [
                <Text>
                  <FormattedMessage
                    defaultMessage="This action requires collaboration with the affected user. You
                will need to be talking to them on their mobile phone in order
                to complete this action."
                  />
                </Text>,

                <Item
                  label={<FormattedMessage defaultMessage="User" />}
                  value={user}
                />,

                <Item
                  label={<FormattedMessage defaultMessage="SMS phone number" />}
                  value={mobile}
                />,

                <VeloDivider />,

                <Text>
                  <FormattedMessage
                    defaultMessage="Click the button below to send a One Time Passcode to the
                  user's mobile phone and ask them to read it back to you. This
                  code will expire after 15 minutes. If the code expires or is
                  not received then try resending."
                  />
                </Text>,

                <>
                  <VeloStatefulButton
                    component={
                      codeSent ? VeloButton.Secondary : VeloButton.Primary
                    }
                    stages={{
                      [VeloStatefulButton.stages.DEFAULT]: {
                        children: intl.formatMessage({
                          defaultMessage: 'Send Code',
                        }),
                      },
                      [VeloStatefulButton.stages.PENDING]: {
                        children: intl.formatMessage({
                          defaultMessage: 'Sending Code...',
                        }),
                      },
                      [VeloStatefulButton.stages.ERROR]: {
                        children: intl.formatMessage({
                          defaultMessage: 'Resend Code',
                        }),
                      },
                      [VeloStatefulButton.stages.RESULT]: {
                        children: intl.formatMessage({
                          defaultMessage: 'Resend Code',
                        }),
                      },
                    }}
                    type="button"
                    fullWidth
                    onClick={(cb) => {
                      // Reset the code and error
                      setCode('');
                      setError(undefined);
                      setOverlay(true);
                      // Send the code
                      onSendCode({ smsNumber: mobile }, (error) => {
                        cb(error);
                        setError(error);
                        setCodeSent(true);
                        setOverlay(false);
                        // Set focus to the code if we didn't get an error
                        !error && textField.current.focus();
                      });
                    }}
                    data-testid={TestIds.SEND_CODE}
                  />

                  {error && <Error>{error}</Error>}
                </>,

                <VeloDivider />,

                <Text disabled={!codeSent}>
                  <FormattedMessage defaultMessage="Please enter the code that the user read back to you." />
                </Text>,

                <>
                  <VeloTextField
                    inputRef={(ref) => {
                      textField.current = ref;
                    }}
                    id="code"
                    label={
                      <FormattedMessage defaultMessage="Confirmation code" />
                    }
                    value={code}
                    onChange={(event) => setCode(event.target.value)}
                    disabled={!codeSent}
                    data-testid={TestIds.CODE}
                    {...smsCodeField}
                  />
                  {submitError && <Error>{submitError}</Error>}
                </>,
              ],
            },
          ]}
          render={(component) => component}
        />
      </Form>
    </VeloDialog>
  );
}
