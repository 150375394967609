import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'astroturf/react';
import classnames from 'classnames';
import { bool, func } from 'prop-types';
import { VeloList } from '../../VeloList';
import { navigationDrawerConfig } from '../navigationDrawerConfig';
import { dataTestIdBuilder } from 'velo-data';
import navigationDrawerPropTypes from '../navigationDrawerPropTypes';
import { getDataTip } from '../helpers';

import styles from '../NavigationDrawerItem/NavigationDrawerItem.module.scss';

const Item = styled(VeloList.Item)`
  @import 'velo-variables';
  @import '../navigationDrawerItem';

  @extend %item;

  padding-left: 2rem;
  &.mobile {
    padding-left: 0.5rem;
  }

  &:hover {
    cursor: pointer;
  }

  &:hover:before {
    background-color: velo-color('token-color-text-link-bg-intent') !important;
    opacity: 0.12 !important;
  }

  &.activated {
    i {
      color: velo-color('token-color-brand-primary');
    }
  }
`;

Item.propTypes = {
  mobile: bool,
};

const root = `velo-navigation-drawer-nested-menu-item`;

NavigationDrawerNestedMenuItem.propTypes = {
  /** Is the Navigation Drawer open. */
  subItem: navigationDrawerPropTypes.subMenuItem.isRequired,
  /** Is the Navigation Drawer in mobile state. */
  isSelectedItem: bool,
  /** the function to handle a list action. */
  onSubItemSelect: func,
  /** Whether the Navigation drawer is in mobile mode */
  isMobile: bool,
};

NavigationDrawerNestedMenuItem.root = root;

function NavigationDrawerNestedMenuItem({
  subItem,
  isSelectedItem,
  onSubItemSelect,
  isMobile,
}) {
  const intl = useIntl();
  const label = getDataTip(intl, subItem.label);

  return (
    <Item
      onClick={(evt) => {
        isMobile && onSubItemSelect(subItem.id);
      }}
      className={classnames(styles.subItem, {
        [styles.activated]: isSelectedItem,
      })}
      mobile={isMobile}
      activated={isSelectedItem}
      data-nav-id={subItem.id}
      data-testid={dataTestIdBuilder(root, label, 'link')}
    >
      <VeloList.ItemGraphic
        data-testid={dataTestIdBuilder(root, label, 'icon')}
        type={subItem.id}
        icon={{
          ...navigationDrawerConfig.NavigationItemIcons[subItem.id],
          tabIndex: -1,
          focusable: false,
        }}
      />

      <VeloList.ItemText>{subItem.label}</VeloList.ItemText>
    </Item>
  );
}

export { NavigationDrawerNestedMenuItem };
