import { useRef } from 'react';

function calculateDelta({ viewportDistance, anchorSize, surfaceSize }) {
  /**
   * This code essentially reverts the offset that MDCMenuSurfaceFoundation
   * applies when it detects a right overflow:
   * @see https://github.com/material-components/material-components-web/blob/v1.0.1/packages/mdc-menu-surface/foundation.ts#L354
   */
  const availableRight = viewportDistance.right + anchorSize.width;
  const rightOverflow = surfaceSize.width - availableRight;

  if (rightOverflow < 0) {
    return 0 - (surfaceSize.width - anchorSize.width);
  }

  return 0;
}

export function useRightEdgeMenuAlignment(initialValue) {
  const ref = useRef(initialValue);

  function onOpen() {
    const { menuSurface } = ref.current;
    const delta = calculateDelta(menuSurface.foundation.measurements_);
    const menuEl = menuSurface.root.ref;
    menuEl.style.transform = `translateX(${delta}px)`;
  }

  return { ref, onOpen };
}
