// See link for more info on states for payout, batches and payments
// https://velopayments.atlassian.net/wiki/spaces/VP/pages/440795137/Payouts+-+Orientation
const PayoutStates = {
  // Pre Instruct states
  SUBMITTED: 'SUBMITTED',
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED',
  QUOTED: 'QUOTED',

  // Post an 'action'
  WITHDRAWN: 'WITHDRAWN',
  INSTRUCTED: 'INSTRUCTED',

  // Succesfully on/or Returned from Rails
  COMPLETED: 'COMPLETED',
  INCOMPLETE: 'INCOMPLETE',
};

//its easier to say which don't than do require the icon
const PayoutStatesDoNotRequireScheduledIcon = [
  PayoutStates.COMPLETED,
  PayoutStates.WITHDRAWN,
  PayoutStates.INCOMPLETE,
  PayoutStates.INSTRUCTED,
];

export { PayoutStates, PayoutStatesDoNotRequireScheduledIcon };
