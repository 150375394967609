import { useMemo } from 'react';
import omit from 'just-omit';
import { useTransactionsAPI } from 'velo-api-react-hooks';
import { useAppContext } from '../../context';
import { useLoggedInUser } from '../../hooks/app';
import { mapCallbackArg } from '../../containers/helpers';
import { formatError, withResult } from '../../selectors';
import { usePayorHierarchyData } from '../payors';

const parseResult = withResult(({ links, payorName }, self) => {
  const descendants = links.length > 0;
  return {
    descendants,
    // Exclude the payor name if it's the authenticated user an no descendants
    payorName: self && !descendants ? undefined : payorName,
  };
}, formatError);

const formatBackOfficeParams = ({ query, body }) => ({
  ...omit(body, 'include'),
});
const formatParamsDefault = ({ body }) => body;

function useTransactionReport({
  query,
  formatParams = formatBackOfficeParams,
}) {
  const { downloadContentAsFile } = useAppContext();
  const getTransactions = useTransactionsAPI();

  return useMemo(
    () =>
      mapCallbackArg((data, cb) => (error, response) => {
        if (error) {
          cb(formatError(error));
        } else if (typeof response === 'string') {
          downloadContentAsFile(
            response,
            `VeloTransactionReport-${data.startDate}-to-${data.endDate}.csv`,
            'text/csv;encoding:utf-8'
          );
          // ensure that the form is closed
          cb();
        } else {
          cb(
            'Extract failed. No data available for selected dates. Please try again.'
          );
        }
      })((body, cb) => {
        //remove include from body if no payorId.
        const params = formatParams({ query, body });
        return getTransactions({ ...query, ...params }, cb);
      }),
    [downloadContentAsFile, formatParams, getTransactions, query]
  );
}

/**
 * This hook covers three separate use cases:
 *
 * When the passed `query` prop does not contain a `payorId` property, then no
 * associated hierarchy information is loaded and the returned `data` prop is a
 * static object specifying no `payorName` and no `descendants`.
 * This covers the case where the hook may be used in a BackOffice context where
 * the `payorId` is omitted from the query in order to return all transactions.
 *
 * When the `query` prop does contain a `payorId` then the `payorName` and the
 * `descendants` are loaded and computed from the API, and bundled into the
 * returned `data` prop.
 * This covers the case where the hook is used to present an "as"
 * experience to the view.
 *
 * When the `query` contains a `payorId` **and** this id is the same as the
 * currently authenticated `entityId`, then the returned `payorName` is omitted
 * in the case where there are **no** `descendants`.
 * This covers the case where the logged in user is a leaf (no children) and
 * therefore needs to exclude any knowledge of a hierarchy.
 */
function usePayorTransactionReport({ query }) {
  const loggedIn = useLoggedInUser();
  const payorId = query.payorId;
  const linksQuery = useMemo(
    () => ({ descendantsOfPayor: payorId }),
    [payorId]
  );
  // Load children, if payorId is undefined then no load is executed
  const [result] = usePayorHierarchyData(payorId, linksQuery);

  const onSubmit = useTransactionReport({
    query,
    formatParams: formatParamsDefault,
  });

  return {
    onSubmit,
    data: parseResult(result, payorId === loggedIn),
  };
}

export { useTransactionReport, usePayorTransactionReport };
