import { useCallback } from 'react';
import { keyCodes } from 'velo-data';

/**
 * Hook used for navigating a list of selectors via
 * the cursor up/down keys.
 *
 * Returns keyboard props that can be spread into a
 * suitable parent element.
 */
function useKeyboardNavigation(selector) {
  const onKeyDown = useCallback(
    (event) => {
      if (
        event.which === keyCodes.DOWN_ARROW ||
        event.which === keyCodes.UP_ARROW
      ) {
        // Find all elements
        const all = [...event.currentTarget.querySelectorAll(selector)];
        // We only need to navigate if there are multiple sections
        if (all.length > 1) {
          // Find the active element
          const index = all.findIndex((e) => e === document.activeElement);
          if (index !== -1) {
            event.preventDefault();

            if (event.which === keyCodes.DOWN_ARROW) {
              // Focus next element or wrap to the first
              (all[index + 1] || all[0]).focus();
            }

            if (event.which === keyCodes.UP_ARROW) {
              // Focus previous element or wrap to the last
              (all[index - 1] || all[all.length - 1]).focus();
            }
          }
        }
      }
    },
    [selector]
  );

  return { onKeyDown };
}

export { useKeyboardNavigation };
