import React from 'react';
import {
  bool,
  element,
  func,
  node,
  object,
  oneOfType,
  string,
} from 'prop-types';
import styled from 'astroturf/react';

import { VeloButton } from '../VeloButton';
import { VeloOverlay } from '../VeloOverlay';
import { VeloTypography } from '../VeloTypography';
import { FieldContainer } from './FieldContainer';

const Form = styled('form')`
  @import 'velo-variables';

  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 0;
  margin-block-end: 0;

  @media (max-width: velo-breakpoint(XS)) {
    justify-content: flex-start;
  }

  &:not(.disableHelperTextPadding) {
    :global(.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid
        + .mdc-text-field-helper-line
        .mdc-text-field-helper-text--validation-msg) {
      margin-bottom: 12px;
    }
  }
`;

Form.propTypes = {
  disableHelperTextPadding: bool,
};

const Button = styled(VeloButton.Primary)`
  @import 'velo-variables';

  @media (max-width: velo-breakpoint(XS)) {
    margin: 0;
    width: 100%;
  }

  &.alignEnd {
    align-self: flex-end;
    min-width: 15rem;

    @media (max-width: velo-breakpoint(XS)) {
      width: 100%;
    }
  }

  &.alignInCardStepper {
    align-self: flex-end;
    width: 15rem;
    margin-right: 1.5rem;

    @media (max-width: velo-breakpoint(XS)) {
      margin-right: initial;
      margin: auto;
    }
  }
`;

Button.propTypes = {
  alignEnd: bool,
  alignInCardStepper: bool,
};

const SubmitError = styled('div')`
  display: flex;
  justify-content: center;
`;

const ErrorMessage = styled(VeloTypography)`
  @import 'velo-variables';

  background-color: velo-color('token-color-system-error-lighter');
  padding: 1rem;
  margin-bottom: 1.5rem;
  border-radius: 4px;
  width: 100%;
  text-align: center;
  box-sizing: border-box;
`;

const TypographyError = (props) => (
  <SubmitError>
    <ErrorMessage use="bodyTextEmphasised" tag="div" {...props} />
  </SubmitError>
);

const root = 'velo-form';

const TestIds = {
  ACTION_BUTTON: `${root}-action-button`,
  OVERLAY: `${root}-overlay`,
  ERROR: `${root}-error`,
  LOADING: `${root}-loading`,
  FORM: `${root}`,
};

VeloForm.testIds = TestIds;

VeloForm.propTypes = {
  /**
   * A function that is called when the form is submitted.
   */
  onSubmit: func.isRequired,
  /**
   * A the primary button label.
   */
  actionText: node,
  /**
   * An optional error if there is a issue when submitting.
   */
  error: oneOfType([node, object]),
  /**
   * A boolean indicating whether to show the overlay.
   */
  overlay: bool,
  /**
   * A CSS class name supplied by the parent component,
   * This can be used to apply use case specific styling to the form root node.
   */
  className: string,
  /**
   * A boolean indicating whether to disable helper text padding.
   */
  disableHelperTextPadding: bool,
  /**
   * A button component to override the default.
   */
  button: element,
};

function VeloForm({
  actionText,
  onSubmit,
  children,
  error,
  defaultErrorMessage,
  overlay,
  disableHelperTextPadding,
  buttonProps,
  button,
  dirty,
  readOnly,
  ...other
}) {
  const showOverlay = !error && overlay;
  const errorMessage = defaultErrorMessage || error;
  const submitButton = button || (
    <Button
      disabled={overlay}
      type="submit"
      data-testid={TestIds.ACTION_BUTTON}
      {...buttonProps}
    >
      {(buttonProps && buttonProps.children) || actionText}
    </Button>
  );

  return (
    <>
      <Form
        onSubmit={onSubmit}
        data-testid={TestIds.FORM}
        disableHelperTextPadding={disableHelperTextPadding}
        {...other}
      >
        <div>{children}</div>
        {error && (
          <TypographyError data-testid={TestIds.ERROR}>
            {errorMessage}
          </TypographyError>
        )}
        {submitButton}
      </Form>

      <VeloOverlay
        show={showOverlay || readOnly}
        transparent={readOnly}
        data-testid={TestIds.OVERLAY}
      />
    </>
  );
}

function VeloFormLoading({
  actionText,
  children,
  disableHelperTextPadding,
  buttonProps,
  ...other
}) {
  return (
    <Form
      as="div"
      data-testid={TestIds.LOADING}
      disableHelperTextPadding={disableHelperTextPadding}
      {...other}
    >
      <div>{children}</div>
      <Button skeleton data-testid={TestIds.ACTION_BUTTON} {...buttonProps}>
        {(buttonProps && buttonProps.children) || actionText}
      </Button>
    </Form>
  );
}

function VeloFormError({
  actionText,
  children,
  error,
  defaultErrorMessage,
  disableHelperTextPadding,
  buttonProps,
  ...other
}) {
  return (
    <Form
      as="div"
      disableHelperTextPadding={disableHelperTextPadding}
      {...other}
    >
      <div>{children}</div>
      <TypographyError data-testid={TestIds.ERROR}>{error}</TypographyError>
      <Button data-testid={TestIds.ACTION_BUTTON} {...buttonProps}>
        {(buttonProps && buttonProps.children) || actionText}
      </Button>
    </Form>
  );
}

VeloForm.FieldContainer = FieldContainer;
VeloForm.Loading = VeloFormLoading;
VeloForm.Error = VeloFormError;

export { VeloForm };
