import fetch from './trapRedirectErrorFetch';

function attachAbortController(init) {
  const controller = new AbortController();
  const signal = controller.signal;
  // If a signal is provided, just have it teardown. It's a cancellation token, basically.
  if (init.signal) {
    const outerSignalHandler = () => {
      if (!signal.aborted) {
        controller.abort();
      }
    };
    init.signal.addEventListener('abort', outerSignalHandler);
  } else {
    init.signal = signal;
  }

  return controller;
}

/**
 * Stolen (-ish)
 * @see https://github.com/drejohnson/tallbag-from-fetch/blob/master/index.js
 */
export default function callbagFromFetch(input, init = {}) {
  return (start, sink) => {
    if (start !== 0) return;
    const controller = attachAbortController(init);
    let ended = false;
    const onfulfilled = (val) => {
      if (ended) return;
      sink(1, val);
      if (ended) return;
      sink(2);
    };
    const onrejected = (err) => {
      if (ended) return;
      sink(2, err);
    };
    fetch(input, init).then(onfulfilled, onrejected);
    sink(0, (t) => {
      if (t === 2) {
        ended = true;
        controller.abort();
      }
    });
  };
}
