import Money from 'js-money';

export const majorToMinorUnitMoney = (value, currency) =>
  Money.fromDecimal(value, currency, 'round').amount;

export const minorToMajorUnitMoney = (value, currency) =>
  Money.fromInteger(value, currency).toDecimal();

// Map of currencies -> countries
export const CurrencyCountryMap = {
  AUD: 'AU',
  CAD: 'CA',
  CHF: 'CH',
  DKK: 'DK',
  EUR: 'EU',
  GBP: 'GB',
  HKD: 'HK',
  JPY: 'JP',
  KES: 'KE',
  MXN: 'MX',
  NOK: 'NO',
  NZD: 'NZ',
  PLN: 'PL',
  SEK: 'SE',
  USD: 'US',
  INR: 'IN',
  BRL: 'BR',
  MYR: 'MY',
  SGD: 'SG',
  OMR: 'OM',
  ISK: 'IS',
};

export const getCurrencyName = (currency) =>
  (Money[currency] || { name: '' }).name;

export const getCurrencySymbol = (intl, currency) =>
  intl
    .formatNumber(0.0, { style: 'currency', currency })
    .replace(/\d+([,.]\d+)?/g, '');
