import React from 'react';
import { arrayOf, bool, shape, string, func, number, oneOf } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styled from 'astroturf/react';
import { dataTestIdBuilder, PayorAccountType } from 'velo-data';
import { VeloTable } from '../VeloTable';
import { LookupTextField } from '../LookupTextField';
import { VeloCurrency } from '../VeloCurrency';
import { VeloIconLabel } from '../VeloIconLabel';
import { PayorAccountTypeText } from '../PayorAccountTypeText';

const WarningStatusLabel = styled(VeloIconLabel)`
  @import 'velo-variables';
  @import 'velo-mixins';

  color: velo-color('token-color-system-error-default');
`;

const root = 'velo-source-accounts-list';

const TestIds = {
  LIST: root,
  HEADING: `${root}-heading`,
  ROW_PREFIX: `${root}-row`,
  COLUMN_PREFIX: `${root}-column`,
};

const fieldNames = {
  // for filter only
  PAYOR_ID: 'payorId',
  PHYSICAL_ACCOUNT_ID: 'physicalAccountId',
  NAME: 'name',
  PAYOR: 'payor',
  TYPE: 'type',
  CURRENCY: 'currency',
  BALANCE: 'balance',
  DELETED: 'includeUserDeleted',
};

const PayorName = ({ name, deleted, index }) => {
  return deleted ? (
    <WarningStatusLabel
      icon={'error_outline'}
      label={name}
      use={'tableContent'}
    />
  ) : (
    <span data-testid={dataTestIdBuilder(TestIds.ROW_PREFIX, index, 'name')}>
      {name}
    </span>
  );
};

const columnsByName = {
  [fieldNames.NAME]: {
    name: fieldNames.NAME,
    label: <FormattedMessage defaultMessage="Name" />,
    'data-testid': dataTestIdBuilder(TestIds.HEADING, 'name'),
  },
  [fieldNames.PAYOR]: {
    name: fieldNames.PAYOR,
    label: <FormattedMessage defaultMessage="Payor" />,
    align: VeloTable.alignment.START,
    'data-testid': dataTestIdBuilder(TestIds.HEADING, 'payor'),
  },
  [fieldNames.TYPE]: {
    name: fieldNames.TYPE,
    label: <FormattedMessage defaultMessage="Type" />,
    'data-testid': dataTestIdBuilder(TestIds.HEADING, 'type'),
  },
  [fieldNames.BALANCE]: {
    name: fieldNames.BALANCE,
    label: <FormattedMessage defaultMessage="Effective Balance" />,
    align: VeloTable.alignment.END,
    'data-testid': dataTestIdBuilder(TestIds.HEADING, 'effective-balance'),
  },
};

const filtersByName = {
  [fieldNames.PAYOR_ID]: {
    name: fieldNames.PAYOR_ID,
    label: <FormattedMessage defaultMessage="Payor" />,
    type: 'entityIdLookup',
    mode: LookupTextField.modes.PAYOR,
  },
  [fieldNames.PHYSICAL_ACCOUNT_ID]: {
    name: fieldNames.PHYSICAL_ACCOUNT_ID,
    label: <FormattedMessage defaultMessage="Physical account" />,
    type: 'list',
  },
  [fieldNames.TYPE]: {
    name: fieldNames.TYPE,
    label: <FormattedMessage defaultMessage="Type" />,
    type: 'list',
    options: ({ intl }) =>
      Object.values(PayorAccountType).map((value) => ({
        label: PayorAccountTypeText.fromIntl(intl, value),
        value,
      })),
  },
  [fieldNames.DELETED]: {
    name: fieldNames.DELETED,
    label: <FormattedMessage defaultMessage="Include deleted" />,
    type: 'list',
    options: ({ intl }) => [
      {
        label: intl.formatMessage({ defaultMessage: 'Yes' }),
        value: 'true',
      },
    ],
  },
};

const cellFactoryByFieldName = {
  [fieldNames.NAME]: ({ name, deleted }, index) => (
    <PayorName name={name} index={index} deleted={deleted} />
  ),
  [fieldNames.PAYOR]: ({ payorName }) => payorName,
  [fieldNames.TYPE]: ({ type }, index) => (
    <span data-testid={dataTestIdBuilder(TestIds.ROW_PREFIX, index, 'type')}>
      <PayorAccountTypeText type={type} />
    </span>
  ),
  [fieldNames.BALANCE]: ({ balance, currency, type, userDeleted }, index) => {
    return (
      <span
        data-testid={dataTestIdBuilder(
          TestIds.ROW_PREFIX,
          index,
          'effective-balance'
        )}
      >
        <VeloCurrency value={balance} currency={currency}>
          {type === PayorAccountType.PRIVATE_COUPLED ||
          (type === PayorAccountType.WUBS_DECOUPLED && userDeleted) ? (
            <FormattedMessage defaultMessage="Unknown" />
          ) : (
            <FormattedMessage defaultMessage="View for details" />
          )}
        </VeloCurrency>
      </span>
    );
  },
};

export const SourceAccountsListColumns = {
  filtersByName,
  fieldNames,
  columnsByName,
  getRowProps(sourceAccount, index, _, { onClick }) {
    return {
      key: sourceAccount.id,
      icon: 'chevron_right',
      onClick: () => onClick(sourceAccount),
      'data-testid': dataTestIdBuilder(TestIds.ROW_PREFIX, index),
    };
  },
  getColumnProps({ name: key, align }, ...args) {
    return {
      key,
      align,
      children: cellFactoryByFieldName[key](...args),
    };
  },
  dataProps: {
    data: arrayOf(
      shape({
        id: string.isRequired,
        name: string.isRequired,
        type: oneOf(Object.values(PayorAccountType)),
        payorName: string,
        currency: string.isRequired,
        balance: number,
        userDeleted: bool,
      })
    ),
    onClick: func.isRequired,
  },
};
