import { privilegeSelectors, Privileges } from 'velo-data';
import { UserRoles } from 'velo-react-components';

export function canShowFundingAccountPayorField(privileges) {
  return privilegeSelectors.hasPrivileges(
    [Privileges.FUNDING_ACCOUNT_VIEW_PAYOR],
    privileges
  );
}

export function canShowFundingAccountSensitive(privileges) {
  return privilegeSelectors.hasPrivileges(
    [Privileges.FUNDING_ACCOUNT_VIEW_SENSITIVE],
    privileges
  );
}

export function canEditFundingAccount(privileges) {
  return privilegeSelectors.hasPrivileges(
    [Privileges.FUNDING_ACCOUNT_EDIT],
    privileges
  );
}

export function getFundingAccountPrivileges(role) {
  return UserRoles.isBackOffice(role)
    ? [
        Privileges.FUNDING_ACCOUNT_VIEW_PAYOR,
        Privileges.FUNDING_ACCOUNT_VIEW_SENSITIVE,
        Privileges.FUNDING_ACCOUNT_EDIT,
      ]
    : [];
}
