import React from 'react';
import { bool, node, string } from 'prop-types';
import styled from 'astroturf/react';
import { VeloDivider } from '../VeloDivider';

const Header = styled('div')`
  margin: 0.5rem 1rem 0.5rem 1rem;
  min-height: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

VeloModalSheetHeader.propTypes = {
  /** Custom CSS class(es). */
  className: string,
  /** An optional horizontal divider. */
  divider: bool,
  /** An optional action bar component to render below the title. */
  actionBar: node,
};

/**
 * Used to render a header on a VeloModalSheet.
 */
function VeloModalSheetHeader({ divider, children, actionBar, ...other }) {
  return (
    <>
      <Header {...other}>{children}</Header>
      {actionBar}
      {divider && <VeloDivider />}
    </>
  );
}

export { VeloModalSheetHeader };
