import React from 'react';
import { bool, number, string } from 'prop-types';

FXRate.propTypes = {
  /** The source currency code. */
  sourceCurrency: string.isRequired,
  /** The payment currency code. */
  paymentCurrency: string.isRequired,
  /** The conversion rate. */
  rate: number.isRequired,
  /** Swap the source and payment currency. */
  swap: bool,
};

/**
 * Render an FX rate for a Payment.
 */
function FXRate({ sourceCurrency, paymentCurrency, rate, swap }) {
  const lhs = swap ? paymentCurrency : sourceCurrency;
  const rhs = swap ? sourceCurrency : paymentCurrency;
  return (
    <span>
      1 {lhs} = {rate} {rhs}
    </span>
  );
}

export { FXRate };
