import React, { Children, cloneElement } from 'react';
import styled from 'astroturf/react';
import { Tab, TabBar } from '@rmwc/tabs';
import { VeloTypography } from '../VeloTypography';
import '@material/tab-bar/dist/mdc.tab-bar.css';
import '@material/tab/dist/mdc.tab.css';
import '@material/tab-scroller/dist/mdc.tab-scroller.css';
import '@material/tab-indicator/dist/mdc.tab-indicator.css';

const StyledTab = styled(Tab)`
  @import 'velo-variables';

  font-family: var(--ds-typography-font-family);

  /* https://github.com/material-components/material-components-web/issues/5044 */
  :global(.mdc-tab__ripple::before),
  :global(.mdc-tab__ripple::after) {
    background-color: velo-color('token-color-brand-primary');
  }
`;

export function VeloTab({ active, children, ...props }) {
  return (
    <StyledTab {...props}>
      <VeloTypography use={active ? 'tabLabelActive' : 'tabLabel'}>
        {children}
      </VeloTypography>
    </StyledTab>
  );
}

VeloTab.Bar = ({ children, ...props }) => (
  <TabBar {...props}>
    {Children.map(children, (child, index) =>
      cloneElement(child, { active: index === props.activeTabIndex })
    )}
  </TabBar>
);
