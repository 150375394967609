import compose from 'just-compose';
import { VeloTableBuilder } from '../VeloTableBuilder';
import { PayoutsListColumns } from '../PayoutsListColumns';

const { columnsByName, filtersByName, fieldNames, mapHeaderProps, ...config } =
  PayoutsListColumns;

export const PayoutsScheduledListForBackOffice = VeloTableBuilder({
  ...config,
  columns: fieldNames,
  columnOrder: [
    columnsByName[fieldNames.SCHEDULED_DATE],
    columnsByName[fieldNames.SCHEDULED_ICON],
    columnsByName[fieldNames.PAYOR_NAME],
    columnsByName[fieldNames.PAYMENT_MEMO],
    columnsByName[fieldNames.TOTAL_PAYMENTS],
    columnsByName[fieldNames.RETURNED_PAYMENTS],
    columnsByName[fieldNames.STATUS],
    columnsByName[fieldNames.SCHEDULED_STATUS_ICON],
  ],
  filters: [],
  sortableColumns: [fieldNames.SCHEDULED_DATE],
});

const { Headings } = PayoutsScheduledListForBackOffice;

PayoutsScheduledListForBackOffice.Headings = compose(mapHeaderProps, Headings);

PayoutsScheduledListForBackOffice.getFilters = (
  fetchResults,
  { payorId, payorIdName },
  intl
) => {
  return [
    filtersByName[fieldNames.SCHEDULED_DATE],
    {
      ...filtersByName[fieldNames.PAYOR_ID],
      value: payorIdName,
      entityId: payorId,
      fetchResults,
    },
    filtersByName[fieldNames.PAYMENT_MEMO],
    filtersByName[fieldNames.STATUS],
    filtersByName[fieldNames.SCHEDULE_STATUS],
  ];
};

PayoutsScheduledListForBackOffice.TabConfig = {
  getFilters: PayoutsScheduledListForBackOffice.getFilters,
  primaryFilterField: PayoutsScheduledListForBackOffice.columns.SCHEDULED_DATE,
  defaultSort: PayoutsScheduledListForBackOffice.sortOrder.DESCENDING,
  table: PayoutsScheduledListForBackOffice,
};
