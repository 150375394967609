import React, { useCallback, useState, useEffect } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import styled from 'astroturf/react';
import update from 'immutability-helper';
import { ReactComponent as MenuIcon } from 'velo-assets/icons/menu.svg';
import { VeloTable } from '../VeloTable';
import { VeloTableBuilder } from './VeloTableBuilder';

const DragIconHeaderCell = styled(VeloTable.HeaderCell)`
  @import 'velo-mixins';
  display: none;
  width: 100px;

  @include from-breakpoint(XS) {
    display: block;
  }
`;

const DragIconCell = styled(VeloTable.Cell)`
  @import 'velo-mixins';
  display: none;
  width: 100px;

  @include from-breakpoint(XS) {
    display: block;
  }
`;

const DataContainer = styled('div')`
  // 70px droppable area below the list items
  margin-top: -70px;
  margin-bottom: -70px;
  padding-top: 70px;
  padding-bottom: 70px;
`;

const TABLE_ROW_TYPE = 'table-row';

export function useDndDropZone({ data, onUpdateOrder }) {
  const [dndData, setDndData] = useState(data);

  useEffect(() => {
    setDndData(data);
  }, [data]);

  const findItem = useCallback(
    (id) => {
      const item = dndData.filter((c) => `${c.id}` === id)[0];
      return {
        item,
        index: dndData.indexOf(item),
      };
    },
    [dndData]
  );

  const moveItem = useCallback(
    (id, atIndex, updateParent) => {
      const { item, index } = findItem(id);
      const updated = update(dndData, {
        $splice: [
          [index, 1],
          [atIndex, 0, item],
        ],
      });

      setDndData(updated);
      updateParent && onUpdateOrder(updated);
    },
    [findItem, dndData, onUpdateOrder]
  );
  const [, dropRef] = useDrop(() => ({ accept: TABLE_ROW_TYPE }));

  return { dndData, findItem, moveItem, dropRef };
}

export function useDraggableRow({ findItem, moveItem, disableDrag, id }) {
  const originalIndex = findItem(id).index;
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: TABLE_ROW_TYPE,
      item: { id, originalIndex },
      canDrag: () => !disableDrag,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        const { id: droppedId, originalIndex } = item;
        const didDrop = monitor.didDrop();
        if (!didDrop) {
          moveItem(droppedId, originalIndex, true);
        }
      },
    }),
    [id, originalIndex, moveItem, disableDrag]
  );

  const [, drop] = useDrop(
    /* istanbul ignore next */
    () => ({
      accept: TABLE_ROW_TYPE,
      hover({ id: draggedId }) {
        if (draggedId !== id) {
          const { index: overIndex } = findItem(id);
          moveItem(draggedId, overIndex, false);
        }
      },
      drop({ id: draggedId }) {
        const { index: overIndex } = findItem(id);
        moveItem(draggedId, overIndex, true);
      },
    }),
    [findItem, moveItem]
  );
  const opacity = isDragging ? 0.5 : 1;

  return { drag, drop, opacity };
}

export function VeloDragNDropTableBuilder({
  columnOrder,
  columns,
  filters,
  getRowProps,
  getColumnProps,
  dataProps,
  testIds = {},
  iconSpacer = true,
}) {
  /*
    sortableColumns is not passed to the builder as sorting
    collides with the drag and drop functionality
  */
  const table = VeloTableBuilder({
    columnOrder,
    columns,
    filters,
    getRowProps,
    getColumnProps,
    dataProps,
    testIds,
    iconSpacer,
  });

  const Headings = ({ children, onSort, sortColumn, sortOrder, ...props }) => {
    return (
      <VeloTable.HeaderRow iconSpacer={iconSpacer} {...props}>
        <DragIconHeaderCell></DragIconHeaderCell>
        {columnOrder.map(({ name, label, ...other }) => {
          return (
            <VeloTable.HeaderCell key={name} name={name} {...other}>
              {label}
            </VeloTable.HeaderCell>
          );
        })}
        {children}
      </VeloTable.HeaderRow>
    );
  };

  const Row = ({ findItem, moveItem, disableDrag, id, ...props }) => {
    const { drag, drop, opacity } = useDraggableRow({
      findItem,
      moveItem,
      disableDrag,
      id,
    });
    return (
      <VeloTable.Row
        ref={(node) => drag(drop(node))}
        {...props}
        tabIndex={0}
        style={{ opacity }}
      />
    );
  };

  const Data = ({ data, onUpdateOrder = () => {}, isFetching, ...props }) => {
    const { dndData, findItem, moveItem, dropRef } = useDndDropZone({
      data,
      onUpdateOrder,
    });

    return (
      <DataContainer ref={dropRef}>
        {dndData.map((...args) => {
          return React.createElement(
            Row,
            {
              ...getRowProps(...args, props),
              findItem,
              moveItem,
              id: args[0].id,
              disableDrag: dndData.length <= 1,
            },
            [
              <DragIconCell key="first-item-cell">
                <MenuIcon />
              </DragIconCell>,
              ...columnOrder.map((col) => {
                const { Component, ...columnProps } = getColumnProps(
                  col,
                  ...args,
                  props
                );

                const [cellData] = args;

                return React.createElement(Component || VeloTable.Cell, {
                  ...columnProps,
                  ...(columnProps.children
                    ? {}
                    : {
                        children: cellData[col.name],
                      }),
                });
              }),
            ]
          );
        })}
      </DataContainer>
    );
  };

  Data.propTypes = dataProps;

  return {
    ...table,
    Headings,
    Data,
  };
}
