/* eslint-disable default-case */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { dataTestIdBuilder } from 'velo-data';
import { VeloSkeleton } from '../../VeloSkeleton';
import { PaymentsList } from '../PaymentsList';
import { PaymentResult } from '../PaymentResult';
import { PaymentStatus } from '../../PaymentStatus';
import { VeloListCurrencyAmount } from '../../VeloListCurrencyAmount';
import { PaymentsListColumns } from '../../PaymentsListColumns';
import styles from './PayeePaymentsList.module.scss';
import PaymentsListPropTypes from '../propTypes';

const columns = {
  PAYOR_NAME: 0,
  PAYMENT_CHANNEL_NAME: 1,
  PAYMENT_AMOUNT: 2,
  PAYMENT_STATUS: 3,
};

const TestIds = {
  LIST: PaymentsList.testIds.LIST,
  PAYOR_NAME_SKELETON: `${PaymentsList.testIds.LIST}-payor-skeleton`,
  PAYMENT_CHANNEL_NAME_SKELETON: `${PaymentsList.testIds.LIST}-payment-channel-skeleton`,
  PAYOR_HEADING: `${PaymentsList.testIds.LIST}-heading-payor`,
  PAYMENT_CHANNEL_HEADING: `${PaymentsList.testIds.LIST}-heading-payment-channel`,
  AMOUNT_HEADING: `${PaymentsList.testIds.LIST}-heading-amount`,
  STATUS_HEADING: `${PaymentsList.testIds.LIST}-heading-status`,
};

// Render a cell at a particular row/col
const renderCell = (skeleton, data, row, col) => {
  const testId = dataTestIdBuilder(TestIds.LIST, 'row', row);
  switch (col) {
    case columns.PAYOR_NAME:
      return (
        <span data-testid={dataTestIdBuilder(testId, 'payor')}>
          <PaymentResult
            skeleton={skeleton}
            errorMessage={
              <FormattedMessage defaultMessage="Error loading Payor" />
            }
            {...data.payorName}
            data-testid={skeleton && TestIds.PAYOR_NAME_SKELETON}
          />
        </span>
      );
    case columns.PAYMENT_CHANNEL_NAME:
      return skeleton ? (
        <VeloSkeleton data-testid={TestIds.PAYMENT_CHANNEL_NAME_SKELETON} />
      ) : (
        <span data-testid={`${testId}-payment-channel`}>
          {data.paymentChannelName}
        </span>
      );
    case columns.PAYMENT_AMOUNT:
      return (
        <VeloListCurrencyAmount
          skeleton={skeleton}
          amount={data.paymentAmount}
          currency={data.paymentCurrency}
          data-testid={dataTestIdBuilder(testId, 'amount')}
        />
      );
    case columns.PAYMENT_STATUS:
      return (
        <PaymentStatus
          use="tableContent"
          skeleton={skeleton}
          status={data.status}
          data-testid={dataTestIdBuilder(testId, 'status')}
        />
      );
  }
  // There is no default case on the switch above as it cannot be tested
  // and falling out of this function is harmless.
};

PayeePaymentsList.propTypes = {
  ...PaymentsListPropTypes,
};

/**
 * Displays a list of Payee Payments.
 *
 * Five columns of information are displayed:
 *
 * - Date/Time
 * - Payor name
 * - Payment Method Name
 * - Payment amount
 * - Payment status
 */
function PayeePaymentsList(props) {
  return (
    <PaymentsList
      headings={[
        {
          label: <FormattedMessage defaultMessage="Payor" />,
          className: styles.payor,
          'data-testid': TestIds.PAYOR_HEADING,
        },
        {
          label: <FormattedMessage defaultMessage="Payment Method Name" />,
          className: styles.paymentChannel,
          'data-testid': TestIds.PAYMENT_CHANNEL_HEADING,
        },
        {
          label: <FormattedMessage defaultMessage="Amount" />,
          alignEnd: true,
          'data-testid': TestIds.AMOUNT_HEADING,
        },
        {
          label: <FormattedMessage defaultMessage="Status" />,
          'data-testid': TestIds.STATUS_HEADING,
        },
      ]}
      renderCell={renderCell}
      {...props}
    />
  );
}

const { filtersByName, fieldNames } = PaymentsListColumns;

PayeePaymentsList.filters = [
  filtersByName[fieldNames.DATE_TIME],
  filtersByName[fieldNames.PAYMENT_STATUS],
  filtersByName[fieldNames.PAYMENT_MEMO],
  filtersByName[fieldNames.PAYMENT_AMOUNT],
];

PayeePaymentsList.sortableColumns = [
  fieldNames.DATE_TIME,
  fieldNames.PAYMENT_AMOUNT,
];

PayeePaymentsList.columnOrder = [
  fieldNames.DATE_TIME,
  fieldNames.PAYOR,
  fieldNames.PAYMENT_CHANNEL_NAME,
  fieldNames.PAYMENT_AMOUNT,
  fieldNames.PAYMENT_STATUS,
];

PayeePaymentsList.columns = fieldNames;

export { PayeePaymentsList };
