import React from 'react';
import { FormattedMessage } from 'react-intl';
import { VeloStatusIndicator } from '../VeloStatusIndicator';

const props = {
  CREDIT: {
    type: VeloStatusIndicator.types.SUCCESS,
    label: <FormattedMessage defaultMessage="Credit" />,
  },
  DEBIT: {
    type: VeloStatusIndicator.types.RETURN,
    label: <FormattedMessage defaultMessage="Debit" />,
  },
  PENDING: {
    type: VeloStatusIndicator.types.PENDING,
    label: <FormattedMessage defaultMessage="Pending" />,
  },
  FAILED: {
    type: VeloStatusIndicator.types.FAILED,
    label: <FormattedMessage defaultMessage="Failed" />,
  },
};

function FundingStatusIndicator({ status }) {
  const labelProps = props[status];
  return <VeloStatusIndicator {...labelProps} />;
}

export { FundingStatusIndicator };
