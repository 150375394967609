import { PayorAccountType } from 'velo-data';
import {
  formatCallbackErrorArg,
  createEarlyExitCallback,
} from '../../selectors';
import { PayorSearchPresenter } from '../../presenters/PayorSearchPresenter';
import { AsyncRequestReplyHelper } from '../../presenters';

export function FundingAccountsCreatePresenter(
  wireframe,
  entity,
  { payorId, payorIdName },
  notification
) {
  const createFn = (body, finalCallback) =>
    AsyncRequestReplyHelper(
      (cb) => entity.createFundingAccount(body, cb),
      entity.getFundingAccountStatus,
      finalCallback
    );

  return [
    {
      payorId,
      payor: payorIdName,
      onClose: wireframe.goBack,
      payorAccountTypes: Object.values(PayorAccountType).filter(
        (v) => v !== 'WUBS_DECOUPLED'
      ),
      onSubmit(body, cb) {
        return createFn(
          body,
          formatCallbackErrorArg(
            createEarlyExitCallback(() => {
              wireframe.sendNote(notification);
              wireframe.goBack();
            }, cb)
          )
        );
      },
      fetchResults: PayorSearchPresenter(entity),
    },
  ];
}
