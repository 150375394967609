import React from 'react';
import { bool, string } from 'prop-types';
import classnames from 'classnames';
import styled from 'astroturf/react';
import { VeloCardHeader } from './VeloCardHeader';
import { VeloCardHeaderTitle } from './VeloCardHeaderTitle';
import { VeloCardBody } from './VeloCardBody';
import { VeloCardAction } from './VeloCardAction';
import { VeloCardButton } from './VeloCardButton';
import { VeloCardError } from './VeloCardError';
import { VeloCardErrorMessage } from './VeloCardErrorMessage';
import { VeloCardContent } from './VeloCardContent';
import '@material/card/dist/mdc.card.css';

const Card = styled('div')`
  @import 'velo-variables';

  position: relative;

  &.flat {
    border: none;
    box-shadow: none;
    border-radius: 0.25rem;
    margin: 0 auto;
  }

  &.slimline {
    max-width: 50rem;
  }

  @mixin fullScreen {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    z-index: $velo-over-all-default;
    border-radius: 0;
    max-height: inherit;
  }

  &.fullWidth {
    width: 100%;
    max-width: unset;
  }

  &.fullScreen {
    /* Full screen on mobile. */
    @media (max-width: velo-breakpoint(XS)) {
      @include fullScreen;
    }
  }

  &.fullScreenSmall {
    /* Full screen on mobile using small (S) breakpoint. */
    @media (max-width: velo-breakpoint(S)) {
      @include fullScreen;
    }
  }
`;

Card.propTypes = {
  flat: bool,
  fullScreen: bool,
  fullScreenSmall: bool,
  fullWidth: bool,
  slimline: bool,
};

export const VeloCard = React.forwardRef(
  ({ className, outlined, ...props }, ref) => (
    <Card
      className={classnames(
        'mdc-card',
        { 'mdc-card--outlined': outlined },
        className
      )}
      {...props}
      ref={ref}
    />
  )
);

VeloCard.propTypes = {
  /** Custom CSS class(es). */
  className: string,
  /** Flat card. */
  flat: bool,
  /** Full screen on mobile using the extra small (XS) breakpoint. */
  fullScreen: bool,
  /** Full screen on mobile using small (S) breakpoint. */
  fullScreenSmall: bool,
  /** Removes the shadow and displays a hairline outline instead */
  outlined: bool,
};

VeloCard.defaultProps = {
  flat: true,
  fullScreen: true,
  fullScreenSmall: false,
  slimline: true,
};

VeloCard.Container = Card;
VeloCard.Header = VeloCardHeader;
VeloCard.HeaderTitle = VeloCardHeaderTitle;
VeloCard.Body = VeloCardBody;
VeloCard.Action = VeloCardAction;
VeloCard.Button = VeloCardButton;
VeloCard.Error = VeloCardError;
VeloCard.ErrorMessage = VeloCardErrorMessage;
VeloCard.Content = VeloCardContent;
