import React from 'react';
import { func, node } from 'prop-types';
import { Content } from '../Content';
import { PageHeader } from '../PageHeader';
import { VeloFab } from '../VeloFab';
import { useIntl } from 'react-intl';

PayeesListPage.propTypes = {
  /** The list of payees to render. */
  list: node,
  /** Called when the "Add Payee" button is clicked. */
  onAdd: func,
};

/**
 * Payment channels page consisting of a header containing a title
 * and an optional "Add Payee" button and a list of payment channels.
 */
function PayeesListPage({ list, onAdd }) {
  const intl = useIntl();
  const FabConstants = {
    label: intl.formatMessage({ defaultMessage: 'Create Payee' }),
    title: intl.formatMessage({ defaultMessage: 'Payees' }),
    icon: 'add',
  };
  const header = (
    <PageHeader
      title={FabConstants.title}
      fab={
        onAdd && (
          <VeloFab
            {...FabConstants}
            data-testid={VeloFab.testIdBuilder(FabConstants.label)}
            onClick={onAdd}
          />
        )
      }
    />
  );
  return <Content header={header}>{list}</Content>;
}

export { PayeesListPage };
