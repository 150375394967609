import React from 'react';
import styled from 'astroturf/react';
import { VeloList } from '../VeloList';
import { VeloCard } from '../VeloCard';
import { VeloTypography } from '../VeloTypography';
import { ResultContainer } from '../ResultContainer';
import * as renderResult from './renderResults';
import { dataTestIdBuilder } from 'velo-data';
import { FormattedMessage } from 'react-intl';

const Item = styled(VeloList.Item)`
  @import 'velo-variables';

  cursor: pointer;
  border-bottom: 2px solid velo-color('token-color-system-divider');

  &:last-child {
    border: none;
  }
`;

const ItemContent = styled('div')`
  @import 'velo-variables';

  width: 100%;
  color: velo-color('token-color-text-default');
`;

const Error = styled(VeloTypography)`
  @import 'velo-variables';

  color: velo-color('token-color-system-error-default');
`;

const DisplayListItemInner = styled('div')`
  width: 100%;
  text-align: center;
`;

const SecondaryResultTextContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const root = 'lookup-results-list';

const TestIds = {
  SKELETON: `${root}-skeleton`,
  SKELETON_ITEM: `${root}-skeleton-item`,
  ERROR: `${root}-error`,
  EMPTY_RESULTS: `${root}-empty`,
  DATA: `${root}-data`,
};

// Container used for all Velo Result states
function ResultsListContainer({ children, ...props }) {
  return (
    <VeloCard flat={false} fullScreen={false} {...props}>
      <VeloList>{children}</VeloList>
    </VeloCard>
  );
}

// List item for rendering API data or skeleton
function BaseListItem({
  renderResult,
  renderSecondaryResults,
  value,
  secondaryValues,
  ...props
}) {
  return (
    <Item ripple={false} {...props}>
      <ItemContent>
        {renderResult(value)}
        <SecondaryResultTextContainer>
          {renderSecondaryResults(secondaryValues)}
        </SecondaryResultTextContainer>
      </ItemContent>
    </Item>
  );
}

// Skeleton content list item
function LoadingItem(props) {
  return (
    <BaseListItem
      {...props}
      disabled={true}
      renderResult={renderResult.primaryLoading}
      renderSecondaryResults={renderResult.secondaryLoading}
    />
  );
}

// Data rendered in list item
function ResultItem(props) {
  return (
    <BaseListItem
      {...props}
      renderResult={renderResult.primary}
      renderSecondaryResults={renderResult.secondary}
    />
  );
}

// A list item that is used for display purpose only
function DisplayListItem({ children }) {
  return (
    <VeloList.Item ripple={false} disabled={true}>
      <DisplayListItemInner>{children}</DisplayListItemInner>
    </VeloList.Item>
  );
}

// A list item to display metadata about the results
function TotalResultsItem({ totalResults, maxResultsListSize }) {
  return (
    <DisplayListItem>
      <VeloTypography use="bodyTextSmaller" tag="div">
        <FormattedMessage
          defaultMessage={`Displaying {count} out of {total} results`}
          values={{
            count: <strong>{maxResultsListSize}</strong>,
            total: <strong>{totalResults}</strong>,
          }}
        />
      </VeloTypography>
    </DisplayListItem>
  );
}

// The list to render when there is no results
function EmptyResultsItem({ style, noResultsErrorMessage }) {
  return (
    <ResultsListContainer style={style} data-testid={TestIds.EMPTY_RESULTS}>
      <DisplayListItem>
        <VeloTypography use="bodyTextSmaller" tag="div">
          {noResultsErrorMessage}
        </VeloTypography>
      </DisplayListItem>
    </ResultsListContainer>
  );
}

// The list to render when there is an error
function ErrorResultItem({ style }) {
  return (
    <ResultsListContainer style={style} data-testid={TestIds.ERROR}>
      <DisplayListItem>
        <Error use="bodyTextSmaller" tag="div">
          <FormattedMessage defaultMessage="Oops, something went wrong, we failed to retrieve data from the server." />
        </Error>
      </DisplayListItem>
    </ResultsListContainer>
  );
}

// The full list with data
function BaseComponent({
  result,
  style,
  totalResults,
  onClick,
  maxResultsListSize,
  disableMaxResults,
}) {
  return (
    <ResultsListContainer style={style} data-testid={TestIds.DATA}>
      {result.map(({ value, secondaryValues, ...other }, index, arr) => (
        <ResultItem
          key={index}
          data-index={index}
          onClick={() => onClick({ value, secondaryValues, ...other })}
          onKeyDown={(event) => {
            if (event.key === 'Enter' || event.keyCode === 13) {
              onClick({ value, secondaryValues, ...other });
            }
          }}
          value={value}
          secondaryValues={secondaryValues}
        />
      ))}

      {!disableMaxResults && result.length > maxResultsListSize && (
        <TotalResultsItem
          totalResults={totalResults}
          maxResultsListSize={maxResultsListSize}
        />
      )}
    </ResultsListContainer>
  );
}

const MAX_LOADING_ITEMS = 5;

/**
 * The list to render when loading.
 * @param {Object} props Component props.
 * @param {number} [props.maxResultsListSize=5] The maximum number of results to initially show.
 */
function LoadingComponent({ maxResultsListSize = 5, style }) {
  const numOfLoadingItems =
    maxResultsListSize > MAX_LOADING_ITEMS
      ? MAX_LOADING_ITEMS
      : maxResultsListSize;
  return (
    <ResultsListContainer data-testid={TestIds.SKELETON} style={style}>
      {[...Array(numOfLoadingItems).keys()].map((_, i) => (
        <LoadingItem
          data-testid={TestIds.SKELETON_ITEM}
          key={dataTestIdBuilder(root, 'loading-item', i)}
        />
      ))}
    </ResultsListContainer>
  );
}

const ResultsList = ResultContainer({
  ErrorComponent: ErrorResultItem,
  LoadingComponent: LoadingComponent,
  EmptyComponent: EmptyResultsItem,
})(BaseComponent);

ResultsList.testIds = TestIds;

export { ResultsList };
