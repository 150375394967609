import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { VeloCallout } from '../VeloCallout';

const computeCalloutType = (seconds) =>
  seconds === 0
    ? VeloCallout.types.ERROR
    : seconds > 20
    ? VeloCallout.types.INFO
    : VeloCallout.types.WARNING;

const onInterval = (setState) =>
  setState(([seconds]) => {
    const nextSeconds = Math.max(0, seconds - 1);
    return [nextSeconds, computeCalloutType(nextSeconds)];
  });

const initState = ({ expiryTime }, now) => {
  const seconds = Math.round((new Date(expiryTime).getTime() - now) / 1000);
  return [seconds, computeCalloutType(seconds)];
};

function ValidQuote({ seconds }) {
  return (
    <>
      <FormattedMessage defaultMessage="Quoted FX rate" />
      &nbsp;
      <b>
        <FormattedMessage
          defaultMessage="expires in {seconds} seconds"
          values={{ seconds }}
        />
      </b>
    </>
  );
}

function ExpiredQuote({ onQuoteExpired }) {
  useEffect(() => {
    onQuoteExpired();
  }, [onQuoteExpired]);
  return (
    <>
      <b>
        <FormattedMessage defaultMessage="FX quote expired." />
        &nbsp;
      </b>
      <FormattedMessage defaultMessage="Re-quote required to add funds." />
    </>
  );
}

ConfirmQuoteCallout.Loading = VeloCallout.Loading;

export function ConfirmQuoteCallout({ quote, onQuoteExpired, now }) {
  const [[seconds, type], setState] = useState(initState(quote, now));
  useEffect(() => {
    const intervalId = setInterval(onInterval, 1000, setState);
    return () => clearInterval(intervalId);
  }, [setState]);

  const Component =
    type === VeloCallout.types.ERROR ? ExpiredQuote : ValidQuote;
  return (
    <VeloCallout type={type}>
      <Component seconds={seconds} onQuoteExpired={onQuoteExpired} />
    </VeloCallout>
  );
}
