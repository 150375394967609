import React from 'react';
import { FormattedMessage } from 'react-intl';
import { VeloLabelledItem } from '../VeloLabelledItem';

const label = <FormattedMessage defaultMessage="Payor" />;

function PayorNameFragment({ data, ...props }) {
  return (
    <VeloLabelledItem {...props} label={label}>
      {data.payorName}
    </VeloLabelledItem>
  );
}

PayorNameFragment.Error = ({ error, ...props }) => {
  return (
    <VeloLabelledItem {...props} label={label}>
      {error}
    </VeloLabelledItem>
  );
};

PayorNameFragment.Loading = VeloLabelledItem.Skeleton;
PayorNameFragment.Empty = VeloLabelledItem.Skeleton;

export { PayorNameFragment };
