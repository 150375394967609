/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import { bool, func, node, object, string } from 'prop-types';
import classnames from 'classnames';
import styled from 'astroturf/react';
import { FormField } from '@rmwc/formfield';
import { VeloTypography } from '../VeloTypography';
import '@material/switch/dist/mdc.switch.css';
import '@material/form-field/dist/mdc.form-field.css';

const StyledFormField = styled(FormField)`
  &.spaceEvenly {
    display: flex;
    justify-content: space-between;

    &:global(.mdc-form-field > label) {
      margin-right: inherit;
    }

    &:global(.mdc-form-field--align-end > label) {
      margin-left: inherit;
    }
  }
`;

StyledFormField.propTypes = {
  spaceEvenly: bool,
};

const StyledLabel = styled('label')`
  @import 'velo-mixins';

  .fullWidth {
    display: flex;
    align-items: flex-start;
    flex: 1 0 auto;
    flex-direction: column;

    @include from-breakpoint(XS) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  font-family: var(--ds-typography-font-family);
`;

StyledLabel.propTypes = {
  fullWidth: bool,
};

const mdcRoot = 'mdc-switch';

VeloSwitch.propTypes = {
  /** Custom CSS class(es.) */
  className: string,
  /** The switch ID. Required to ensure labels wotk correcttly. */
  id: string,
  /** The switch label. */
  label: node,
  /** Controls the switch on/off state. */
  checked: bool,
  /** Called when the switch on/off state changes. */
  onChange: func,
  /** Used to disable the switch. */
  disabled: bool,
  /** Props to apply to the root FormField component. */
  rootProps: object,
  /** Props to apply to the label component. */
  labelProp: object,
  /** Position the switch after the label. */
  alignEnd: bool,
  /** Space out the label and switch. */
  spaceEvenly: bool,
};

/**
 * Velo Switch component.
 * Based on the MDC switch with a custom Velo colour.
 *
 * Note this replaces the RMWC Switch which has issues with Safari.
 */
function VeloSwitch({
  className,
  id,
  label,
  checked,
  disabled,
  onChange,
  rootProps,
  labelProps,
  alignEnd,
  spaceEvenly,
  ...other
}) {
  return (
    <StyledFormField
      className={className}
      spaceEvenly={spaceEvenly}
      alignEnd={alignEnd}
      {...rootProps}
    >
      <div
        className={classnames(mdcRoot, {
          [`${mdcRoot}--checked`]: checked,
          [`${mdcRoot}--disabled`]: disabled,
        })}
      >
        <div className={`${mdcRoot}__track`} />
        <div className={`${mdcRoot}__thumb-underlay`}>
          <div className={`${mdcRoot}__thumb`}>
            <input
              type="checkbox"
              id={id}
              className={`${mdcRoot}__native-control`}
              role="switch"
              aria-checked={checked}
              checked={checked}
              onChange={onChange}
              disabled={disabled}
              {...other}
            />
          </div>
        </div>
      </div>
      <StyledLabel htmlFor={id} {...labelProps}>
        {label}
      </StyledLabel>
    </StyledFormField>
  );
}

const StyledSwitch = styled(VeloSwitch)`
  align-items: flex-start;

  :global(.mdc-switch) {
    margin-top: 0.25rem;
  }
`;

const Container = styled('div')`
  margin-left: 0.5rem;
`;

const Title = styled(VeloTypography).attrs({
  use: 'bodyText',
})`
  font-weight: 700;
`;

const HelperText = styled(VeloTypography).attrs({
  use: 'bodyTextSmaller',
  tag: 'div',
})`
  margin-top: 0.5rem;
`;

Multiline.propTypes = {
  /** The switch title. */
  title: node.isRequired,
  /** First line of body text. */
  line1: node.isRequired,
  /** Second line of body text. */
  line2: node,
};

/**
 * Supports a title and two text sections.
 */
function Multiline({ title, line1, line2, ...props }) {
  const label = (
    <Container>
      <Title>{title}</Title>
      <HelperText>{line1}</HelperText>
      <HelperText>{line2}</HelperText>
    </Container>
  );
  return <StyledSwitch label={label} {...props} />;
}

VeloSwitch.Multiline = Multiline;

export { VeloSwitch };
