import React from 'react';
import { oneOf } from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import { VeloStatusIndicator } from '../VeloStatusIndicator';
import { UserStatus } from 'velo-data';

function statusMap(intl) {
  return {
    [UserStatus.PENDING]: {
      type: VeloStatusIndicator.types.PENDING,
      label: intl.formatMessage({ defaultMessage: 'Pending' }),
    },
    [UserStatus.DISABLED]: {
      type: VeloStatusIndicator.types.WARNING,
      label: intl.formatMessage({ defaultMessage: 'Disabled' }),
    },
    [UserStatus.ENABLED]: {
      type: VeloStatusIndicator.types.SUCCESS,
      label: intl.formatMessage({ defaultMessage: 'Enabled' }),
    },
  };
}

function options(intl) {
  return Object.entries(statusMap(intl)).map(([k, v]) => ({
    value: k,
    label: v.label,
  }));
}

UserStatusIndicator.propTypes = {
  /**
   * The user status as a string, or empty for unavailable.
   */
  status: oneOf(Object.values(UserStatus)),
};

UserStatusIndicator.options = options;

function UserStatusIndicator({ status, ...props }) {
  const intl = useIntl();
  return (
    <VeloStatusIndicator
      {...props}
      {...(statusMap(intl)[status] || {
        type: VeloStatusIndicator.types.UNKNOWN,
        label: <FormattedMessage defaultMessage="Unavailable" />,
      })}
    />
  );
}

export { UserStatusIndicator };
