import React from 'react';
import { bool, func, node, string } from 'prop-types';
import { VeloOnOffSwitch } from '../VeloOnOffSwitch';
import { VeloGrid } from '../VeloGrid';
import { VeloTypography } from '../VeloTypography';

MultiLineOnOffSwitch.propTypes = {
  /** The switch label. */
  label: node.isRequired,
  /** Helper text line 1. */
  line1: node.isRequired,
  /** Helper text line 2. */
  line2: node.isRequired,
  /** The switch value. */
  value: bool.isRequired,
  /** Called when the switch is toggled. */
  onChange: func.isRequired,
  /** The switch test ID. */
  'data-testid': string.isRequired,
};

/**
 * On/Off switch with two lines of helper text.
 */
export function MultiLineOnOffSwitch({ line1, line2, ...other }) {
  return (
    <VeloGrid.Inner>
      <VeloGrid.Cell span={12}>
        <VeloOnOffSwitch {...other} />
      </VeloGrid.Cell>
      <VeloGrid.Cell span={12}>
        <VeloTypography use="bodyTextSmaller" tag="div">
          {line1}
        </VeloTypography>
        <VeloTypography use="bodyTextSmaller" tag="div">
          {line2}
        </VeloTypography>
      </VeloGrid.Cell>
    </VeloGrid.Inner>
  );
}
