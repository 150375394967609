import { paymentMethodSelectors } from 'velo-data';
import {
  selectFormValuesFromPayee,
  selectPayeeDataFromFormValues,
} from './payee-details-form-selector';

export function selectFormValuesFromEditPayee({ challenge, ...payee }) {
  return {
    // Profile fields
    ...selectFormValuesFromPayee(payee),
    // Country code
    countryCode: payee.country,
    // Challenge code fields
    challengeDescription: challenge?.description,
    challengeValue: challenge?.value,
    // Include empty payment channel fields
    ...paymentMethodSelectors.paymentChannelFields.reduce(
      (acc, cur) => ({ ...acc, [cur]: '' }),
      {}
    ),
  };
}

export function selectEditPayeeDataFromFormValues({
  challengeDescription,
  challengeValue,
  ...body
}) {
  return {
    // Profile fields
    ...selectPayeeDataFromFormValues(body),
    // Challenge code
    ...(challengeDescription && challengeValue
      ? {
          challenge: {
            description: challengeDescription,
            value: challengeValue,
          },
        }
      : { challenge: null }),
  };
}
