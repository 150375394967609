import React, { forwardRef } from 'react';
import { bool, oneOf, string } from 'prop-types';
import classnames from 'classnames';
import veloTokens from '@design-system/tokens/typography/js/tokens-map';
import '@design-system/tokens/typography/css/tokens-grouped.css';

/**
 * Typography component that supports [Velo Design System](http://designsystem.preview.velointra.net/?path=/story/brand-typography--usage) tokens.
 */
export const VeloTypography = forwardRef(
  (
    { tag: Tag = 'span', use, theme, className, wrap, children, ...other },
    ref
  ) => {
    const classes = classnames(
      // Apply root Velo class
      veloTokens.base,
      // Apply main tyography class
      veloTokens.tokens[use],
      // Custom overrides
      className
    );

    // Inject the styles into the child?
    if (wrap) {
      const child = React.Children.only(children);
      return React.cloneElement(child, {
        className: classnames(classes, child.props.className),
      });
    }

    return (
      <Tag className={classes} ref={ref} {...other}>
        {children}
      </Tag>
    );
  }
);

VeloTypography.propTypes = {
  /** The design system typography token to use */
  use: oneOf([...Object.keys(veloTokens.tokens)]),
  /** The HTML element to render (defaults to "span") */
  tag: string,
  /** Instead of injecting a new tag, wrap a child component by merging the styles directly onto it. */
  wrap: bool,
};

VeloTypography.veloTokens = [...Object.keys(veloTokens.tokens)];
