import React from 'react';
import { useIntl } from 'react-intl';
import { bool, string } from 'prop-types';
import { VeloStatusIndicator } from '../VeloStatusIndicator';
import { PayoutStates } from 'velo-data';

const getStates = (intl) => {
  return {
    ACCEPTED: {
      type: VeloStatusIndicator.types.SUCCESS,
      label: intl.formatMessage({ defaultMessage: 'Accepted' }),
      value: PayoutStates.ACCEPTED,
    },
    REJECTED: {
      type: VeloStatusIndicator.types.FAILED,
      label: intl.formatMessage({ defaultMessage: 'Rejected' }),
      value: PayoutStates.REJECTED,
    },
    WITHDRAWN: {
      type: VeloStatusIndicator.types.WARNING,
      label: intl.formatMessage({ defaultMessage: 'Withdrawn' }),
      value: PayoutStates.WITHDRAWN,
    },
    SUBMITTED: {
      type: VeloStatusIndicator.types.PENDING,
      label: intl.formatMessage({ defaultMessage: 'Submitted' }),
      value: PayoutStates.SUBMITTED,
    },
    QUOTED: {
      type: VeloStatusIndicator.types.INFO,
      label: intl.formatMessage({ defaultMessage: 'Quoted' }),
      value: PayoutStates.QUOTED,
    },
    INSTRUCTED: {
      type: VeloStatusIndicator.types.INFO,
      label: intl.formatMessage({ defaultMessage: 'Instructed' }),
      value: PayoutStates.INSTRUCTED,
    },
    COMPLETED: {
      type: VeloStatusIndicator.types.SUCCESS,
      label: intl.formatMessage({ defaultMessage: 'Completed' }),
      value: PayoutStates.COMPLETED,
    },
    INCOMPLETE: {
      type: VeloStatusIndicator.types.WARNING,
      label: intl.formatMessage({ defaultMessage: 'Incomplete' }),
      value: PayoutStates.INCOMPLETE,
    },
  };
};

PayoutStatus.propTypes = {
  /** Custom CSS class(es). */
  className: string,
  /** The status. */
  status: string,
  /** Render as a skeleton. */
  skeleton: bool,
};

/**
 * Payout status indicator.
 */
PayoutStatus.getStates = getStates;

function PayoutStatus({ status, skeleton, ...other }) {
  const intl = useIntl();
  const states = getStates(intl);
  const unknownState = {
    type: VeloStatusIndicator.types.UNKNOWN,
    label: status || intl.formatMessage({ defaultMessage: 'Unavailable' }),
  };

  const { type, label } = states[status] || unknownState;

  // TODO: remove the ``skeleton` prop in favour of a
  // new `PayoutStatus.Loading` variant.
  return skeleton ? (
    <VeloStatusIndicator.Loading {...other} />
  ) : (
    <VeloStatusIndicator type={type} label={label} {...other} />
  );
}

export { PayoutStatus };
