import { useIntl } from 'react-intl';
import { arrayOf, shape, string } from 'prop-types';

const countries = {
  US: 'US',
  GB: 'GB',
  FR: 'FR',
  CA: 'CA',
  JP: 'JP',
  DE: 'DE',
  NL: 'NL',
  IE: 'IE',
  AU: 'AU',
  CH: 'CH',
  MX: 'MX',
  HK: 'HK',
  KE: 'KE',
  FI: 'FI',
  EE: 'EE',
  NO: 'NO',
  DK: 'DK',
  PL: 'PL',
  SE: 'SE',
  AT: 'AT',
  NZ: 'NZ',
  BE: 'BE',
  IT: 'IT',
  LI: 'LI',
  ES: 'ES',
  BG: 'BG',
  PT: 'PT',
  GR: 'GR',
  SI: 'SI',
  IS: 'IS',
  MY: 'MY',
  BR: 'BR',
  IN: 'IN',
  SG: 'SG',
  OM: 'OM',
  CY: 'CY',
};

const countryNames = (intl) => ({
  US: intl.formatMessage({ defaultMessage: 'United States of America' }),
  GB: intl.formatMessage({ defaultMessage: 'United Kingdom' }),
  FR: intl.formatMessage({ defaultMessage: 'France' }),
  CA: intl.formatMessage({ defaultMessage: 'Canada' }),
  JP: intl.formatMessage({ defaultMessage: 'Japan' }),
  DE: intl.formatMessage({ defaultMessage: 'Germany' }),
  NL: intl.formatMessage({ defaultMessage: 'Netherlands' }),
  IE: intl.formatMessage({ defaultMessage: 'Ireland' }),
  AU: intl.formatMessage({ defaultMessage: 'Australia' }),
  CH: intl.formatMessage({ defaultMessage: 'Switzerland' }),
  MX: intl.formatMessage({ defaultMessage: 'Mexico' }),
  HK: intl.formatMessage({ defaultMessage: 'Hong Kong' }),
  KE: intl.formatMessage({ defaultMessage: 'Kenya' }),
  FI: intl.formatMessage({ defaultMessage: 'Finland' }),
  EE: intl.formatMessage({ defaultMessage: 'Estonia' }),
  NO: intl.formatMessage({ defaultMessage: 'Norway' }),
  DK: intl.formatMessage({ defaultMessage: 'Denmark' }),
  PL: intl.formatMessage({ defaultMessage: 'Poland' }),
  SE: intl.formatMessage({ defaultMessage: 'Sweden' }),
  AT: intl.formatMessage({ defaultMessage: 'Austria' }),
  NZ: intl.formatMessage({ defaultMessage: 'New Zealand' }),
  BE: intl.formatMessage({ defaultMessage: 'Belgium' }),
  IT: intl.formatMessage({ defaultMessage: 'Italy' }),
  LI: intl.formatMessage({ defaultMessage: 'Liechtenstein' }),
  ES: intl.formatMessage({ defaultMessage: 'Spain' }),
  BG: intl.formatMessage({ defaultMessage: 'Bulgaria' }),
  PT: intl.formatMessage({ defaultMessage: 'Portugal' }),
  GR: intl.formatMessage({ defaultMessage: 'Greece' }),
  SI: intl.formatMessage({ defaultMessage: 'Slovenia' }),
  IS: intl.formatMessage({ defaultMessage: 'Iceland' }),
  MY: intl.formatMessage({ defaultMessage: 'Malaysia' }),
  BR: intl.formatMessage({ defaultMessage: 'Brazil' }),
  IN: intl.formatMessage({ defaultMessage: 'India' }),
  SG: intl.formatMessage({ defaultMessage: 'Singapore' }),
  OM: intl.formatMessage({ defaultMessage: 'Oman' }),
  CY: intl.formatMessage({ defaultMessage: 'Cyprus' }),
});

const regionLabels = (intl) => ({
  US: intl.formatMessage({ defaultMessage: 'State' }),
  GB: intl.formatMessage({ defaultMessage: 'County' }),
  CA: intl.formatMessage({ defaultMessage: 'Province' }),
  FR: intl.formatMessage({ defaultMessage: 'Region' }),
  JP: intl.formatMessage({ defaultMessage: 'Prefecture' }),
  DE: intl.formatMessage({ defaultMessage: 'State' }),
  NL: intl.formatMessage({ defaultMessage: 'Province' }),
  IE: intl.formatMessage({ defaultMessage: 'County' }),
  AU: intl.formatMessage({ defaultMessage: 'State' }),
  CH: intl.formatMessage({ defaultMessage: 'Canton' }),
  MX: intl.formatMessage({ defaultMessage: 'State' }),
  HK: intl.formatMessage({ defaultMessage: 'Region' }),
  KE: intl.formatMessage({ defaultMessage: 'County' }),
  FI: intl.formatMessage({ defaultMessage: 'Region' }),
  EE: intl.formatMessage({ defaultMessage: 'County' }),
  NO: intl.formatMessage({ defaultMessage: 'Region' }),
  DK: intl.formatMessage({ defaultMessage: 'Region' }),
  PL: intl.formatMessage({ defaultMessage: 'Voivodeship' }),
  SE: intl.formatMessage({ defaultMessage: 'Province' }),
  AT: intl.formatMessage({ defaultMessage: 'Province' }),
  NZ: intl.formatMessage({ defaultMessage: 'Region' }),
  BE: intl.formatMessage({ defaultMessage: 'Region' }),
  IT: intl.formatMessage({ defaultMessage: 'Region' }),
  ES: intl.formatMessage({ defaultMessage: 'Region' }),
  LI: intl.formatMessage({ defaultMessage: 'Region' }),
  BG: intl.formatMessage({ defaultMessage: 'Region' }),
  PT: intl.formatMessage({ defaultMessage: 'Region' }),
  GR: intl.formatMessage({ defaultMessage: 'Region' }),
  SI: intl.formatMessage({ defaultMessage: 'Region' }),
  IS: intl.formatMessage({ defaultMessage: 'Region' }),
  MY: intl.formatMessage({ defaultMessage: 'Region' }),
  BR: intl.formatMessage({ defaultMessage: 'Region' }),
  IN: intl.formatMessage({ defaultMessage: 'Region' }),
  SG: intl.formatMessage({ defaultMessage: 'Region' }),
  OM: intl.formatMessage({ defaultMessage: 'Region' }),
  CY: intl.formatMessage({ defaultMessage: 'Region' }),
});

const getCountries = () => Object.keys(countries);

/**
 * Return the region label for a specific country.
 */
const useRegionLabel = (countryCode) => {
  const intl = useIntl();

  return (
    regionLabels(intl)[countryCode] ||
    intl.formatMessage({ defaultMessage: 'State or Province' })
  );
};

/**
 * Prop types for the `getSupportedCountries` API response.
 */
const propTypesCountries = arrayOf(
  shape({
    /** Two-character ISO country code. */
    isoCountryCode: string.isRequired,
    /** Supported currencies. */
    currencies: arrayOf(string).isRequired,
    /** Available regions. */
    addressRules: shape({
      countyState: shape({
        options: arrayOf(
          shape({
            /** The region name. */
            name: string.isRequired,
            /** Optional region abbreviation. */
            abbreviation: string,
          })
        ),
      }).isRequired,
    }).isRequired,
  })
);

/**
 * Extract an array of country codes from a
 * `getSupportedCountries` API response.
 */
const getCountryCodes = (countries) =>
  countries.map(({ isoCountryCode }) => isoCountryCode);

const findCountryEntity = (countries, countryCode) =>
  countries?.find((country) => country.isoCountryCode === countryCode);

/**
 * Return an array of region names from a `getSupportedCountries`
 * API response for a specific country code.
 */
const getRegionNames = (countries, countryCode) =>
  findCountryEntity(
    countries,
    countryCode
  )?.addressRules?.countyState.options?.map(({ name }) => name) ?? [];

const countryName = (countryCode, intl) => {
  return countryNames(intl)[countryCode] || '';
};

export {
  getCountries,
  countries,
  useRegionLabel,
  propTypesCountries,
  findCountryEntity,
  getCountryCodes,
  getRegionNames,
  countryName,
};
