import React from 'react';
import { func, node } from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import { VeloIconButton } from '../VeloIconButton';
import { VeloCard } from '../VeloCard';
import { Content } from '../Content';

const root = 'payment-receipt-container';

const TestIds = {
  CLOSE_ICON: `${root}-close-icon`,
  CLOSE_BUTTON: `${root}-close-button`,
};

PaymentReceiptContainer.testIds = TestIds;

PaymentReceiptContainer.propTypes = {
  children: node.isRequired,
  onClose: func.isRequired,
};

function PaymentReceiptContainer({ children, onClose, ...props }) {
  const intl = useIntl();
  return (
    <Content>
      <VeloCard {...props}>
        <VeloCard.Header className={`${root}__heading`} divider>
          <VeloCard.HeaderTitle>
            <FormattedMessage defaultMessage="Payment Receipt" />
          </VeloCard.HeaderTitle>
          <VeloIconButton
            icon="close"
            onClick={onClose}
            title={intl.formatMessage({
              defaultMessage: 'Close payment receipt',
            })}
            data-testid={TestIds.CLOSE_ICON}
          />
        </VeloCard.Header>
        <VeloCard.Body>
          <VeloCard.Content>{children}</VeloCard.Content>
          <VeloCard.Action>
            <VeloCard.Button
              data-testid={TestIds.CLOSE_BUTTON}
              onClick={onClose}
            >
              <FormattedMessage defaultMessage="Close Receipt" />
            </VeloCard.Button>
          </VeloCard.Action>
        </VeloCard.Body>
      </VeloCard>
    </Content>
  );
}

export { PaymentReceiptContainer };
