import { useAppContext } from '../../context';

export function useOperatingUser() {
  const { operatingUser } = useAppContext();
  const loggedInUser = useLoggedInUser();
  return operatingUser || loggedInUser;
}

export function useLoggedInUser() {
  const { authentication } = useAppContext();
  const { entityIds } = authentication;
  if (!entityIds || entityIds.length < 1) return undefined;
  return entityIds[0];
}

export function useLoggedInUserRole() {
  return useAppContext().user.role;
}
