import { useCallback, useEffect } from 'react';
import { useAPIContext } from 'velo-api-react-hooks';
import { useAppContext, createContextDataState } from '../../context';
import { formatError } from '../../selectors';
import { useAuthState } from './useAuthState';

const defaultData = { result: undefined };

export function useAppBootstrap({ entityPropName }) {
  const { setContextState, data = defaultData } = useAppContext();
  const props = useAuthState(entityPropName);

  const api = useAPIContext();
  const bootstrap = useCallback(() => {
    Promise.all([
      api.getSupportedCountries(),
      api.getPaymentChannelRules(),
      api.getCurrencies(),
      api.getSelf(props.userId),
      api.getPayeeRules(),
      api.getSupportedRails?.() || Promise.resolve({ supportedRails: [] }),
    ])
      .then(
        ([
          { countries },
          paymentChannelRules,
          { currencies },
          userData,
          { payeeCountryRules },
          { supportedRails },
        ]) => {
          const { id, companyName } = userData;

          // Intentionally a subset of this, as most info is not useful to store,
          // and may change so should be requested for other appropriate apis so as not to be out of sync
          const user = {
            id,
            companyName,
            role: userData.roles[0].name,
          };

          return [
            undefined,
            countries,
            paymentChannelRules,
            currencies,
            user,
            payeeCountryRules,
            supportedRails,
          ];
        }
      )
      .catch((error) => [formatError(error)])
      .then(([...args]) => setContextState(createContextDataState(...args)));
  }, [api, props.userId, setContextState]);

  useEffect(() => {
    if (props.loggedIn && !data.result) {
      bootstrap();
    }
  }, [bootstrap, props.loggedIn, data]);

  return {
    ...props,
    ...data,
  };
}
