import React, { useState, useCallback } from 'react';
import { func, object, oneOf, shape, string } from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import styled from 'astroturf/react';
import {
  MFAStatus,
  UserStatus as Status,
  defaultWithEmptyStr,
} from 'velo-data';
import { VeloModalSheetCardContent } from '../VeloModalSheetCardContent';
import { VeloSectionGrid } from '../VeloSectionGrid';
import { VeloGridLoading } from '../VeloGridLoading';
import { VeloLabelledItem } from '../VeloLabelledItem';
import { VeloSkeleton } from '../VeloSkeleton';
import { ConfirmationDialog } from '../ConfirmationDialog';
import { VeloStatefulButton } from '../VeloStatefulButton';
import { LockedOut } from './LockedOut';
import { MFATypes } from '../MFATypes';
import { VeloBookended } from '../VeloBookended';
import { UserStatusIndicator } from '../UserStatusIndicator';
import buttonStyles from './UserView.module.scss';

const root = 'user-view';

const TestIds = {
  EMAIL: `${root}-email`,
  STATUS: `${root}-status`,
  ROLE: `${root}-role`,
  MFA_TYPE: `${root}-mfa-type`,
  MFA_STATUS: `${root}-mfa-status`,
  SMS: `${root}-sms`,
  PRIMARY_PHONE: `${root}-primary-phone`,
  SECONDARY_PHONE: `${root}-secondary-phone`,
  COMPANY_NAME: `${root}-company-name`,
  DELETE: `${root}-delete`,
  DELETE_CONFIRMATION: `${root}-delete-confirmation`,
  RESEND_INVITE: `${root}-resend-invite`,
  RESEND_REGISTRATION: `${root}-resend-mfa-registration`,
  UNLOCK: `${root}-unlock`,
  LOADING: `${root}-loading`,
};

const MFALabels = MFATypes.Labels;

const MfaStatusLabelMap = {
  [MFAStatus.VERIFIED]: 'Verified',
  [MFAStatus.REGISTERED]: 'Registered',
  [MFAStatus.UNREGISTERED]: 'Unregistered',
};
const SecureMFATypes = [MFATypes.TOTP, MFATypes.YUBIKEY];

const Skeleton = styled(VeloSkeleton)`
  width: 40%;
`;

UserLoadingView.testIds = TestIds;

function UserLoadingView({ onClose, children }) {
  const fields = (count) =>
    [...Array(count).keys()].map(() => ({
      type: VeloGridLoading.fieldTypes.LabelledItem,
    }));

  return (
    <VeloModalSheetCardContent
      data-testid={TestIds.LOADING}
      title={<Skeleton />}
      onClose={onClose}
    >
      <VeloBookended column>
        <VeloGridLoading
          compact
          sections={[
            {
              heading: true,
              fields: fields(4),
            },
            {
              heading: true,
              fields: fields(3),
            },
          ]}
        />
      </VeloBookended>
    </VeloModalSheetCardContent>
  );
}

const StatusLabel = ({ value }) => (
  <VeloLabelledItem
    label={<FormattedMessage defaultMessage="User status" />}
    testId={TestIds.STATUS}
  >
    <UserStatusIndicator status={value} />
  </VeloLabelledItem>
);

const TwoFactorAuthLabel = ({ value }) => (
  <VeloLabelledItem
    label={<FormattedMessage defaultMessage="Two-factor authentication type" />}
    testId={TestIds.MFA_TYPE}
  >
    {value}
  </VeloLabelledItem>
);

const ResendInviteButton = VeloLabelledItem.withAlignedLabel(
  StatusLabel,
  { disktop: 6, tablet: 4 },
  { desktop: 6, tablet: 4, className: buttonStyles.align }
)(VeloStatefulButton);

const ResendRegistrationButton = VeloLabelledItem.withAlignedLabel(
  TwoFactorAuthLabel,
  { desktop: 6, tablet: 4 },
  { desktop: 6, tablet: 4, className: buttonStyles.align }
)(VeloStatefulButton);

const UserStatus = ({ status, isBackOfficeViewAdminOrSupport, onClick }) => {
  if (!isBackOfficeViewAdminOrSupport && status === 'PENDING' && onClick) {
    return (
      <ResendInviteButton
        value={status}
        onClick={onClick}
        stages={{
          [VeloStatefulButton.stages.PENDING]: {
            children: <FormattedMessage defaultMessage="Resending..." />,
            disabled: true,
          },
        }}
        data-testid={TestIds.RESEND_INVITE}
        // This fixes an issue where the button still
        // renders in the focused state.
        ripple={false}
      >
        <FormattedMessage defaultMessage="Resend Invite" />
      </ResendInviteButton>
    );
  }
  return <StatusLabel value={status} />;
};

const TwoFactorStatus = ({
  status,
  isBackOfficeViewAdminOrSupport,
  mfaVerified,
  mfaType,
  onClick,
}) => {
  const intl = useIntl();
  if (
    !isBackOfficeViewAdminOrSupport &&
    status === 'ENABLED' &&
    !mfaVerified &&
    SecureMFATypes.includes(mfaType) &&
    onClick
  ) {
    return (
      <ResendRegistrationButton
        value={MFALabels(intl)[mfaType]}
        onClick={onClick}
        stages={{
          [VeloStatefulButton.stages.PENDING]: {
            children: <FormattedMessage defaultMessage="Resending..." />,
            disabled: true,
          },
        }}
        data-testid={TestIds.RESEND_REGISTRATION}
        // This fixes an issue where the button still
        // renders in the focused state.
        ripple={false}
      >
        <FormattedMessage defaultMessage="Resend Registration" />
      </ResendRegistrationButton>
    );
  }
  return <TwoFactorAuthLabel value={MFALabels(intl)[mfaType]} />;
};

const cardProps = ({ firstName, lastName }) => ({
  title: `${defaultWithEmptyStr(firstName)} ${defaultWithEmptyStr(lastName)}`,
});

const gridProps = (
  {
    email,
    status,
    role,
    mfaType,
    mfaStatus,
    smsNumber,
    primaryContactNumber,
    secondaryContactNumber,
    lockedOut,
    lockedOutTimestamp,
    companyName,
  },
  roles,
  onResendInvite,
  onResendMfaRegistration,
  onUnlock,
  isBackOfficeViewAdminOrSupport
) => ({
  compact: true,
  sections: [
    {
      fields: [
        ...(lockedOut
          ? [
              {
                Component: LockedOut,
                lockedOutTimestamp,
                'data-testid': TestIds.UNLOCK,
                onClick: onUnlock,
              },
            ]
          : []),
      ],
    },
    {
      heading: <FormattedMessage defaultMessage="Contact details" />,
      fields: [
        {
          label: <FormattedMessage defaultMessage="Login email" />,
          children: email,
          testId: TestIds.EMAIL,
        },
        {
          label: <FormattedMessage defaultMessage="SMS phone number" />,
          children: smsNumber,
          testId: TestIds.SMS,
        },
        {
          label: <FormattedMessage defaultMessage="Primary contact number" />,
          children: primaryContactNumber,
          testId: TestIds.PRIMARY_PHONE,
        },
        ...(secondaryContactNumber
          ? [
              {
                label: (
                  <FormattedMessage defaultMessage="Secondary contact number" />
                ),
                children: secondaryContactNumber,
                testId: TestIds.SECONDARY_PHONE,
              },
            ]
          : []),
      ],
    },
    {
      heading: <FormattedMessage defaultMessage="Other details" />,
      fields: [
        {
          label: <FormattedMessage defaultMessage="Role" />,
          children: roles[role],
          testId: TestIds.ROLE,
        },
        {
          Component: TwoFactorStatus,
          status,
          mfaVerified:
            mfaStatus === MFAStatus.VERIFIED ||
            mfaStatus === MFAStatus.REGISTERED,
          mfaType,
          onClick: onResendMfaRegistration,
          isBackOfficeViewAdminOrSupport,
        },
        {
          label: (
            <FormattedMessage defaultMessage="Two-factor authentication status" />
          ),
          children: MfaStatusLabelMap[mfaStatus],
          testId: TestIds.MFA_STATUS,
        },
        {
          Component: UserStatus,
          status,
          onClick: onResendInvite,
          isBackOfficeViewAdminOrSupport,
        },

        ...(companyName
          ? [
              {
                label: <FormattedMessage defaultMessage="Company Name" />,
                children: companyName,
                testId: TestIds.COMPANY_NAME,
              },
            ]
          : []),
      ],
    },
  ],
  render: ({ Component, ...fieldProps }) =>
    Component ? (
      <Component {...fieldProps} />
    ) : (
      <VeloLabelledItem {...fieldProps} />
    ),
  headingProps: { overline: true, tail: true },
});

const sharedPropTypes = {
  /** The user data to render. */
  data: shape({
    id: string.isRequired,
    firstName: string.isRequired,
    lastName: string.isRequired,
    email: string.isRequired,
    status: oneOf(Object.keys(Status)).isRequired,
    role: string.isRequired,
    mfaType: oneOf(Object.keys(MFATypes.Types)).isRequired,
    smsNumber: string.isRequired,
    primaryContactNumber: string.isRequired,
    secondaryContactNumber: string,
  }).isRequired,
  /** Called when the close icon is clicked. */
  onClose: func.isRequired,
  /** A map of roles > labels. */
  roles: object.isRequired,
};

NonActionableUserView.propTypes = {
  ...sharedPropTypes,
};

function NonActionableUserView({ data, roles, onClose }) {
  return (
    <VeloModalSheetCardContent onClose={onClose} {...cardProps(data)}>
      <VeloSectionGrid {...gridProps(data, roles)} />
    </VeloModalSheetCardContent>
  );
}

NonActionableUserView.testIds = {
  ...VeloModalSheetCardContent.testIds,
  ...TestIds,
};

function DeleteUserConfirmationDialog({
  open,
  onClose,
  title,
  userType,
  data: { firstName, lastName },
}) {
  return (
    <ConfirmationDialog
      open={open}
      onClose={onClose}
      data-testid={TestIds.DELETE_CONFIRMATION}
      dialogType={ConfirmationDialog.typeOf(
        'ConfirmDeleteUserType',
        title,
        <FormattedMessage defaultMessage="Delete" />,
        <FormattedMessage defaultMessage="Cancel" />,
        <FormattedMessage
          defaultMessage="You are about to permanently delete {userType} <strong>{firstName} {lastName}</strong>. Are you sure you want to proceed?"
          values={{
            userType,
            strong: (chunks) => <strong>{chunks}</strong>,
            firstName,
            lastName,
          }}
        />
      )}
    />
  );
}

function useDeleteUser(onDelete) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const onOpenDialog = () => setDialogOpen(true);
  const onCloseDialog = useCallback(
    (event) => {
      const deleteUser = event.target.action === 'accept';
      setDialogOpen(false);
      setDisabled(deleteUser);
      if (deleteUser) {
        onDelete(() => setDisabled(false));
      }
    },
    [onDelete]
  );
  return [dialogOpen, disabled, { onOpenDialog, onCloseDialog }];
}

function UserAdminView({
  buttonProps,
  data,
  roles,
  onClose,
  onDelete,
  onResendInvite,
  onSendOtp,
  onResendMfaRegistration,
  onEdit,
  onUnlock,
  disabled,
  isBackOfficeViewAdminOrSupport,
  children,
  ...props
}) {
  const intl = useIntl();
  const onResendInviteHandler = useCallback(
    (callback) => {
      onResendInvite({}, callback);
    },
    [onResendInvite]
  );

  const onResendMfaRegistrationHandler = useCallback(
    (callback) => {
      onResendMfaRegistration({}, callback);
    },
    [onResendMfaRegistration]
  );

  return (
    <VeloModalSheetCardContent
      onClose={onClose}
      {...cardProps(data)}
      buttons={[
        {
          icon: 'delete',
          title: intl.formatMessage({ defaultMessage: 'Delete' }),
          'data-testid': TestIds.DELETE,
          ...buttonProps,
        },
      ]}
      disabled={disabled}
      onEdit={() => onEdit(data)}
    >
      <VeloBookended column>
        <VeloSectionGrid
          {...gridProps(
            data,
            roles,
            onResendInviteHandler,
            onResendMfaRegistrationHandler,
            onUnlock,
            isBackOfficeViewAdminOrSupport
          )}
        />
        {children}
      </VeloBookended>
    </VeloModalSheetCardContent>
  );
}

const adminViewPropTypes = {
  /**
   * Called when deleting the user.
   */
  onDelete: func.isRequired,
  /**
   * Called when resending an invite for the user.
   * Only available if `status` is `PENDING`.
   */
  onResendInvite: func.isRequired,
  /**
   * Called when the edit FAB is clicked.
   */
  onEdit: func.isRequired,
  /**
   * Called when unlocking the user's password.
   * Note that only BOP Admins can do this.
   */
  onUnlock: func,
};

PayeeUserAdminView.propTypes = {
  ...sharedPropTypes,
  ...adminViewPropTypes,
};

PayeeUserAdminView.testIds = {
  ...VeloModalSheetCardContent.testIds,
  ...TestIds,
};

function PayeeUserAdminView({ data, onDelete, children, ...props }) {
  const [dialogOpen, disabled, { onOpenDialog, onCloseDialog }] =
    useDeleteUser(onDelete);

  return (
    <UserAdminView
      {...props}
      data={data}
      buttonProps={{
        onClick: onOpenDialog,
      }}
      disabled={disabled}
    >
      <DeleteUserConfirmationDialog
        data={data}
        open={dialogOpen}
        onClose={onCloseDialog}
        title={<FormattedMessage defaultMessage="Delete Payee user" />}
        userType={<FormattedMessage defaultMessage="Payee user" />}
      />
      {children}
    </UserAdminView>
  );
}

PayorUserAdminView.propTypes = {
  ...sharedPropTypes,
  ...adminViewPropTypes,
};

PayorUserAdminView.testIds = {
  ...VeloModalSheetCardContent.testIds,
  ...TestIds,
};

function PayorUserAdminView({ data, onDelete, children, ...props }) {
  const [dialogOpen, disabled, { onOpenDialog, onCloseDialog }] =
    useDeleteUser(onDelete);

  return (
    <UserAdminView
      {...props}
      data={data}
      buttonProps={{
        onClick: onOpenDialog,
      }}
      disabled={disabled}
    >
      <DeleteUserConfirmationDialog
        data={data}
        open={dialogOpen}
        onClose={onCloseDialog}
        title={<FormattedMessage defaultMessage="Delete Payor user" />}
        userType={<FormattedMessage defaultMessage="Payor user" />}
      />
      {children}
    </UserAdminView>
  );
}

BackOfficeUserAdminView.propTypes = {
  ...sharedPropTypes,
  ...adminViewPropTypes,
};

BackOfficeUserAdminView.testIds = {
  ...VeloModalSheetCardContent.testIds,
  ...TestIds,
};

function BackOfficeUserAdminView({ data, onDelete, children, ...props }) {
  const [dialogOpen, disabled, { onOpenDialog, onCloseDialog }] =
    useDeleteUser(onDelete);

  return (
    <UserAdminView
      {...props}
      data={data}
      buttonProps={{
        onClick: onOpenDialog,
      }}
      disabled={disabled}
    >
      <DeleteUserConfirmationDialog
        data={data}
        open={dialogOpen}
        onClose={onCloseDialog}
        title={<FormattedMessage defaultMessage="Delete Admin user" />}
        userType={<FormattedMessage defaultMessage="Admin user" />}
      />
      {children}
    </UserAdminView>
  );
}

const UserView = {
  Loading: UserLoadingView,
  Support: NonActionableUserView,
  Admin: PayorUserAdminView,
  BackOfficeAdmin: BackOfficeUserAdminView,
  BackOfficeAdminViewSelf: NonActionableUserView,
  PayeeUserAdmin: PayeeUserAdminView,
  PayeeUserViewSelf: NonActionableUserView,
};

export { UserView };
