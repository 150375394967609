import React from 'react';
import { ScheduledStatus, PostInstructFxStatus } from 'velo-data';
import { FormattedMessage } from 'react-intl';
import { transmissionTypeFilterOptions } from '../utils';

const paymentListFieldEnum = {
  remoteId: 'remoteId',
  status: 'status',
  scheduleStatus: 'scheduleStatus',
  sourceCurrency: 'sourceCurrency',
  sourceAmount: 'sourceAmount',
  paymentCurrency: 'paymentCurrency',
  paymentAmount: 'paymentAmount',
  submittedDateTime: 'submittedDateTime',
  scheduledForDateTime: 'scheduledForDateTime',
  paymentMemo: 'paymentMemo',
  sourceAccountName: 'sourceAccountName',
  transmissionType: 'transmissionType',
  payorPaymentId: 'payorPaymentId',
};

const backOfficePaymentFieldEnum = {
  status: 'status',
  scheduleStatus: 'scheduleStatus',
  sourceAmount: 'sourceAmount',
  paymentAmount: 'paymentAmount',
  submittedDateTime: 'submittedDateTime',
  payorName: 'payorName',
  payeeName: 'payeeName',
  railsId: 'railsId',
  postInstructFxStatus: 'postInstructFxStatus',
};

const statusFieldOptions = (intl) => [
  {
    label: intl.formatMessage({ defaultMessage: 'Accepted' }),
    value: 'ACCEPTED',
  },
  {
    label: intl.formatMessage({ defaultMessage: 'Awaiting Funds' }),
    value: 'AWAITING_FUNDS',
  },
  {
    label: intl.formatMessage({ defaultMessage: 'Funded' }),
    value: 'FUNDED',
  },
  {
    label: intl.formatMessage({ defaultMessage: 'Unfunded' }),
    value: 'UNFUNDED',
  },
  {
    label: intl.formatMessage({ defaultMessage: 'Payment Requested' }),
    value: 'BANK_PAYMENT_REQUESTED',
  },
  {
    label: intl.formatMessage({ defaultMessage: 'Awaiting Confirmation' }),
    value: 'ACCEPTED_BY_RAILS',
  },
  {
    label: intl.formatMessage({ defaultMessage: 'Confirmed' }),
    value: 'CONFIRMED',
  },
  {
    label: intl.formatMessage({ defaultMessage: 'Returned' }),
    value: 'RETURNED',
  },
  {
    label: intl.formatMessage({ defaultMessage: 'Rejected' }),
    value: 'REJECTED',
  },
  {
    label: intl.formatMessage({ defaultMessage: 'Withdrawn' }),
    value: 'WITHDRAWN',
  },
  {
    label: intl.formatMessage({ defaultMessage: 'Cancelled' }),
    value: 'CANCELLED',
  },
];

const scheduleStatusFieldOptions = (intl) => [
  {
    label: intl.formatMessage({ defaultMessage: 'Any' }),
    value: ScheduledStatus.ANY,
  },
  {
    label: intl.formatMessage({ defaultMessage: 'Scheduled' }),
    value: ScheduledStatus.SCHEDULED,
  },
  {
    label: intl.formatMessage({ defaultMessage: 'Executed' }),
    value: ScheduledStatus.EXECUTED,
  },
  {
    label: intl.formatMessage({ defaultMessage: 'Failed' }),
    value: ScheduledStatus.FAILED,
  },
];

const postInstructFxStatusFieldOptions = (intl) => [
  {
    label: intl.formatMessage({ defaultMessage: 'Initiated' }),
    value: PostInstructFxStatus.INITIATED,
  },
  {
    label: intl.formatMessage({ defaultMessage: 'Executed' }),
    value: PostInstructFxStatus.EXECUTED,
  },
  {
    label: intl.formatMessage({ defaultMessage: 'Completed' }),
    value: PostInstructFxStatus.COMPLETED,
  },
  {
    label: intl.formatMessage({ defaultMessage: 'Cancelled' }),
    value: PostInstructFxStatus.CANCELLED,
  },
  {
    label: intl.formatMessage({ defaultMessage: 'Returned' }),
    value: PostInstructFxStatus.RETURNED,
  },
  {
    label: intl.formatMessage({ defaultMessage: 'Resubmitted' }),
    value: PostInstructFxStatus.RESUBMITTED,
  },
  {
    label: intl.formatMessage({ defaultMessage: 'Refunded' }),
    value: PostInstructFxStatus.REFUNDED,
  },
];

const baseFields = [
  {
    name: paymentListFieldEnum.remoteId,
    label: <FormattedMessage defaultMessage="Remote ID" />,
    type: 'string',
  },
  {
    name: paymentListFieldEnum.status,
    label: <FormattedMessage defaultMessage="Status" />,
    type: 'list',
    options: statusFieldOptions,
  },
  {
    name: paymentListFieldEnum.scheduleStatus,
    label: <FormattedMessage defaultMessage="Schedule Status" />,
    type: 'list',
    options: scheduleStatusFieldOptions,
  },
  {
    name: paymentListFieldEnum.transmissionType,
    label: <FormattedMessage defaultMessage="Transmission Type" />,
    type: 'list',
    options: transmissionTypeFilterOptions,
  },
  {
    name: paymentListFieldEnum.payorPaymentId,
    label: <FormattedMessage defaultMessage="Payor Payment ID" />,
    type: 'string',
  },
];

const businessPaymentsListFields = [
  {
    name: paymentListFieldEnum.sourceCurrency,
    label: <FormattedMessage defaultMessage="Source Currency" />,
    type: 'listCurrency',
  },
  {
    name: paymentListFieldEnum.paymentCurrency,
    label: <FormattedMessage defaultMessage="Payment Currency" />,
    type: 'listCurrency',
  },
  {
    name: paymentListFieldEnum.paymentMemo,
    label: <FormattedMessage defaultMessage="Payment Memo" />,
    type: 'string',
  },
  {
    name: paymentListFieldEnum.sourceAccountName,
    label: <FormattedMessage defaultMessage="Source Account Name" />,
    type: 'string',
  },
];

const rangeFields = [
  {
    name: paymentListFieldEnum.submittedDateTime,
    label: <FormattedMessage defaultMessage="Submitted Date" />,
    type: 'dateRange',
    nameFrom: 'submittedDateFrom',
    nameTo: 'submittedDateTo',
  },
  {
    name: paymentListFieldEnum.scheduledForDateTime,
    label: <FormattedMessage defaultMessage="Scheduled For Date" />,
    type: 'dateRange',
    nameFrom: 'scheduledForDateFrom',
    nameTo: 'scheduledForDateTo',
  },
  {
    name: paymentListFieldEnum.paymentAmount,
    label: <FormattedMessage defaultMessage="Payment Amount" />,
    type: 'currencyRange',
    nameFrom: 'paymentAmountFrom',
    nameTo: 'paymentAmountTo',
  },
  {
    name: paymentListFieldEnum.sourceAmount,
    label: <FormattedMessage defaultMessage="Source Amount" />,
    type: 'currencyRange',
    nameFrom: 'sourceAmountFrom',
    nameTo: 'sourceAmountTo',
  },
];

const payorFields = () => [
  {
    name: 'payeeId',
    label: <FormattedMessage defaultMessage="Payee" />,
    type: 'entityIdLookup',
  },
  ...baseFields,
  ...businessPaymentsListFields,
  ...rangeFields,
];

const backOfficeFields = ({ railsProviders = [] }) => {
  return [
    {
      name: 'payeeId',
      label: <FormattedMessage defaultMessage="Payee" />,
      type: 'entityIdLookup',
    },
    ...baseFields,
    {
      name: backOfficePaymentFieldEnum.railsId,
      label: <FormattedMessage defaultMessage="Rails Provider" />,
      type: 'list',
      options: railsProviders.map(({ railsId: value, description: label }) => ({
        label,
        value,
      })),
    },
    {
      name: backOfficePaymentFieldEnum.postInstructFxStatus,
      label: <FormattedMessage defaultMessage="Post Instruct FX Status" />,
      type: 'list',
      options: postInstructFxStatusFieldOptions,
    },
    {
      name: backOfficePaymentFieldEnum.payorName,
      label: <FormattedMessage defaultMessage="Payor Name" />,
      type: 'string',
    },
    ...businessPaymentsListFields,
    ...rangeFields,
  ];
};

const payorPaymentsByPayoutFields = [...baseFields, ...rangeFields];

const paymentsListFields = {
  payorFields,
  backOfficeFields,
  payorPaymentsByPayoutFields,
};

export {
  paymentsListFields,
  statusFieldOptions,
  scheduleStatusFieldOptions,
  postInstructFxStatusFieldOptions,
};
