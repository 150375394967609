import React from 'react';
import {
  ContentWithCard,
  PageHeader,
  WebhookUpsertPageForm,
} from 'velo-react-components';
import {
  forkResult,
  createWebhookUpsertRoute,
  createRenderForksFromComponent,
} from 'velo-portal-common';

const forks = createRenderForksFromComponent(WebhookUpsertPageForm);

const overrideProps = {
  render: ([result, getProps]) => (
    <ContentWithCard
      header={<PageHeader title={WebhookUpsertPageForm.title} disableMargin />}
    >
      {forkResult(forks, result, getProps(result))}
    </ContentWithCard>
  ),
};

export const PayorWebhookUpsertRoute = createWebhookUpsertRoute(overrideProps);
