import React, { useState, useCallback } from 'react';
import { bool, func, node, string } from 'prop-types';
import styled from 'astroturf/react';
import { VeloButton } from '../VeloButton';
import { VeloMenu } from '../VeloMenu';
import { VeloList } from '../VeloList';
import { VeloTypography } from '../VeloTypography';
import { VeloIcon } from '../VeloIcon';
import { useMenuManager } from '../hooks';

const Icon = styled(VeloIcon)`
  @import 'velo-variables';

  color: velo-color('token-color-text-muted');
  font-size: 1.25rem !important;
  width: 1.25rem !important;
  height: 1.25rem !important;
`;

const Container = styled('div')`
  display: flex;
  /* Required for Safari */
  min-height: 36px;
`;

const StyledButton = styled(VeloButton)`
  @import 'velo-variables';

  text-transform: none;
  letter-spacing: normal;
  color: velo-color('token-color-text-default') !important;
`;

Button.propTypes = {
  /** The button icon. */
  icon: string.isRequired,
  /** The button label. */
  label: node.isRequired,
  /** Called when the button is clicked. */
  onClick: func.isRequired,
  /** The button test ID. */
  'data-testid': string.isRequired,
};

/**
 * Render an action button.
 */
export function Button({ icon, ...props }) {
  return (
    <StyledButton
      {...props}
      icon={{ icon: <Icon icon={icon} />, strategy: 'component' }}
    />
  );
}

const StyledSeparator = styled('span')`
  @import 'velo-variables';

  margin: 0.25rem;
  border-right: 1px solid velo-color('token-color-system-divider');
`;

function Separator() {
  return <StyledSeparator role="separator" />;
}

const MenuIcon = styled(VeloList.ItemGraphic)`
  @import 'velo-variables';

  color: velo-color('token-color-text-default') !important;
  margin-right: 1rem;
`;

const StyledMenu = styled(VeloMenu)`
  @import 'velo-variables';

  z-index: $velo-over-all-default;
`;

Menu.propTypes = {
  /** The button icon. */
  icon: string.isRequired,
  /** The button label. */
  label: node.isRequired,
  /** The menu items to display when the button is clicked. */
  children: node.isRequired,
  /**
   * Called when an item is selected.
   * `event.detail.index` contains the selected item index.
   */
  onSelect: func.isRequired,
  /** The button test ID. */
  'data-testid': string.isRequired,
};

/**
 * Render a menu button.
 */
export function Menu({
  icon,
  label,
  onSelect,
  'data-testid': testId,
  children,
}) {
  const [open, setOpen] = useState(false);
  const onCloseMenu = useCallback(() => setOpen(false), []);
  const [mounted, onClose] = useMenuManager(open, onCloseMenu);

  return (
    <VeloMenu.SurfaceAnchor>
      {mounted && (
        <StyledMenu
          open={open}
          onClose={onClose}
          anchorCorner="bottomLeft"
          onSelect={onSelect}
        >
          {children}
        </StyledMenu>
      )}
      <Button
        label={label}
        icon={icon}
        trailingIcon="arrow_drop_down"
        onClick={() => setOpen((open) => !open)}
        data-testid={testId}
      />
    </VeloMenu.SurfaceAnchor>
  );
}

MenuItem.propTypes = {
  /** The item icon. */
  icon: string.isRequired,
  /** The item label. */
  label: node.isRequired,
};

/**
 * Render a menu item.
 */
export function MenuItem({ icon, label, ...other }) {
  return (
    <VeloList.Item role="menuitem" {...other}>
      <MenuIcon icon={icon} />
      <VeloList.ItemText>{label}</VeloList.ItemText>
    </VeloList.Item>
  );
}

const StyledMenuSubheader = styled(VeloTypography).attrs({
  use: 'bodyTextEmphasised',
  tag: 'div',
})`
  @import 'velo-variables';

  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 1rem;
  overflow: hidden;
`;

MenuSubheader.propTypes = {
  /** The heading to display. */
  label: node.isRequired,
};

/**
 * Render  menu sub-header.
 * Used to group related menu items together.
 */
export function MenuSubheader({ label, ...props }) {
  return <StyledMenuSubheader {...props}>{label}</StyledMenuSubheader>;
}

/**
 * Used to separate menu items.
 */
const MenuDivider = styled(VeloMenu.Divider)`
  margin: 0;
`;

const Spacer = styled('span')`
  width: 0.5rem;
`;

VeloActionBar.propTypes = {
  /** A suitable a11y label. */
  label: string.isRequired,
  /** The action bar contents. */
  children: node.isRequired,
  /**
   * Adds a spacer to ensure correct icon/label alignment when used in
   * conjunction with `VeloModalSheet.Header` and `VeloAccordianGrid`.
   */
  spacer: bool,
};

/**
 * Action bar component used to render buttons and menus.
 * Primarily designed for use in modal sheets.
 *
 * The following components are exported:
 *
 * - `VeloActionBar`
 * - `VeloActionBar.Button`
 * - `VeloActionBar.Separator`
 * - `VeloActionBar.Menu`
 * - `VeloActionBar.MenuItem`
 * - `VeloActionBar.MenuSubheader`
 * - `VeloActionBar.MenuDivider`
 */
function VeloActionBar({ spacer, label, children }) {
  return (
    <Container role="group" aria-label={label}>
      {spacer && <Spacer role="presentation" />}
      {children}
    </Container>
  );
}

VeloActionBar.Icon = Icon;
VeloActionBar.Button = Button;
VeloActionBar.Separator = Separator;
VeloActionBar.Menu = Menu;
VeloActionBar.MenuItem = MenuItem;
VeloActionBar.MenuSubheader = MenuSubheader;
VeloActionBar.MenuDivider = MenuDivider;

export { VeloActionBar };
