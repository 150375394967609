import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { arrayOf, bool, func, object } from 'prop-types';
import styled from 'astroturf/react';
import { VeloCard } from '../VeloCard';
import { VeloGrid } from '../VeloGrid';
import { VeloTypography } from '../VeloTypography';
import { VeloIconButton } from '../VeloIconButton';
import { VeloTextField } from '../VeloTextField';
import { VeloSelect } from '../VeloSelect';
import { VeloButton } from '../VeloButton';
import { VeloDateRange } from '../VeloDateRange';
import { VeloCurrencyRange } from '../VeloCurrencyRange';
import { LookupTextField } from '../LookupTextField';
import { SelectCountry } from '../FormFields/SelectCountry';
import { SelectCurrency } from '../FormFields/SelectCurrency';
import { VeloDivider } from '../VeloDivider';
import { EscapeKey } from '../EscapeKey';
import { ScrollLock } from '../ScrollLock';
import { stripNonNumeric } from 'velo-data';
import FilterPropTypes from './filterPropTypes';

const TEXT_FIELD_HEIGHT = '56px';

const Card = styled(VeloCard)`
  @import 'velo-variables';

  max-height: 72vh;
  z-index: $velo-over-all-default;

  @media (min-width: velo-breakpoint(S)) {
    position: absolute;
    top: ${TEXT_FIELD_HEIGHT};
    right: 0px;
    min-width: 42rem;
    z-index: $velo-full-screen-index-default;

    &.compactWidth {
      min-width: 35rem;
    }

    &.compact {
      max-height: 40vh;
    }
  }
`;

Card.propTypes = {
  compact: bool,
  compactWidth: bool,
};

const Header = styled(VeloGrid)`
  width: 100%;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
`;

const Footer = styled(VeloGrid)`
  width: 100%;
  padding-top: 0.875rem;
  padding-bottom: 0.875rem;
`;

const Body = styled('div')`
  @import 'velo-mixins';

  @include smooth-scrolling;

  width: 100%;
  height: 100%;
`;

const Title = styled(VeloGrid.Cell)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const prefix = 'velo-filter';
const root = `${prefix}-form`;

const TestIds = {
  APPLY_BUTTON: `${prefix}-apply-button`,
  RESET_BUTTON: `${prefix}-reset-button`,
};

/**
 * Render a form containing editable filters.
 * Each filter is represented by a component appropriate
 * for the field type.
 */
class FilterForm extends React.Component {
  static propTypes = {
    /** The available columns to filter. */
    fields: arrayOf(FilterPropTypes.field).isRequired,
    /** Initial values as an object keyed on the field name. */
    values: object,
    /**
     * Called when applying changes. Passed the field values as
     * an object keyed on the field name.
     */
    onSubmit: func.isRequired,
    /** Called when cancelling the form. */
    onCancel: func.isRequired,
    /** Used to determine if we are on a mobile viewport. */
    isMobile: bool,
    /** Used to create unique ID's when fields repeat */
    isUnique: bool,
    /** Compact filter list view */
    compact: bool,
  };
  static testIds = TestIds;

  static defaultProps = {
    values: {},
    entityIds: {},
  };

  state = {
    // We need a value per-field
    values: this.props.fields.reduce(
      (acc, field) => ({
        ...acc,
        [field.name]: this.props.values[field.name] || '',
      }),
      {}
    ),
    entityIds: this.props.fields.reduce(
      (acc, field) => ({
        ...acc,
        [field.name]: this.props.entityIds[field.name] || '',
      }),
      {}
    ),
    validationErrors: {},
    disableEscKey: false,
  };

  bodyRef = React.createRef();

  createTextField = ({ inputType, name, label, value, disabled }) => (
    <VeloTextField
      id={`velo-filter-form-${name}`}
      data-testid={`${root}-${name}`}
      type={inputType}
      name={name}
      label={label}
      value={value || this.state.values[name]}
      disabled={disabled}
      onChange={this.handleChange}
    />
  );

  createField = ({
    type,
    name,
    label,
    options,
    value,
    disabled,
    fetchResults,
    entityId,
    dependantOn,
    mode,
  }) => {
    const { intl } = this.props;
    const listOptions = typeof options === 'function' ? options(intl) : options;
    const disabledByDependency = dependantOn
      ? dependantOn.some((name) => !!this.state.values[name])
      : false;

    switch (type) {
      case FilterPropTypes.types.string:
        return this.createTextField({
          inputType: 'text',
          name,
          label,
          value,
          disabled: disabled || disabledByDependency,
        });
      case FilterPropTypes.types.number:
        return this.createTextField({
          inputType: this.props.isMobile ? 'number' : 'text',
          name,
          label,
          disabled: disabledByDependency,
        });
      case FilterPropTypes.types.date:
        return this.createTextField({ inputType: 'date', name, label });
      case FilterPropTypes.types.dateRange:
        return (
          <VeloDateRange
            label={<VeloTypography use="sectionHeader">{label}</VeloTypography>}
            name={name}
            value={this.state.values[name]}
            onChange={this.handleChange}
            disabled={disabledByDependency}
            onToggleDayPicker={this.handleToggleDayPicker}
            cellProps={{ desktop: 6, tablet: 4, phone: 4 }}
          />
        );
      case FilterPropTypes.types.currencyRange:
        return (
          <VeloCurrencyRange
            label={<VeloTypography use="sectionHeader">{label}</VeloTypography>}
            name={name}
            value={this.state.values[name]}
            onChange={this.handleChange}
            isMobile={this.props.isMobile}
            disabled={disabledByDependency}
            isUnique={this.props.isUnique || false}
            cellProps={{ desktop: 6, tablet: 4, phone: 4 }}
          />
        );
      case FilterPropTypes.types.list:
        return (
          <VeloSelect
            id={`${root}-${name}`}
            data-testid={`${root}-${name}`}
            name={name}
            placeholder=""
            label={label}
            disabled={disabledByDependency}
            value={this.state.values[name]}
            onChange={this.handleChange}
          >
            {listOptions.map(({ value, label }, index) => (
              <option key={index} value={value}>
                {label}
              </option>
            ))}
          </VeloSelect>
        );
      case FilterPropTypes.types.listCountry:
        const countries = Object.keys(options).map((key) => options[key].value);

        return (
          <SelectCountry
            name={name}
            label={label}
            value={this.state.values[name]}
            countries={countries}
            disabled={disabledByDependency}
            onChange={this.handleChange}
            data-testid={`${root}-${name}`}
          />
        );
      case FilterPropTypes.types.listCurrency:
        const currencies = Object.keys(options).map(
          (key) => options[key].value
        );

        return (
          <SelectCurrency
            name={name}
            label={label}
            currencies={currencies}
            value={this.state.values[name]}
            disabled={disabledByDependency}
            onChange={this.handleChange}
            data-testid={`${root}-${name}`}
          />
        );
      case FilterPropTypes.types.entityIdLookup:
        return (
          <LookupTextField
            id={`${root}-${name}`}
            data-testid={`${root}-${name}`}
            name={name}
            placeholder=""
            label={label}
            entityId={this.state.entityIds[name]}
            value={this.state.values[name]}
            onChange={this.handleChange}
            disabled={disabledByDependency}
            fetchResults={fetchResults}
            mode={mode}
          />
        );
      default:
        return null;
    }
  };

  isEmptyRangeValue = (value) =>
    value instanceof Array && !value[0] && !value[1];

  convertValue = (type, value) => {
    switch (type) {
      case FilterPropTypes.types.currencyRange:
        return value.map((v) => stripNonNumeric(v));
      case FilterPropTypes.types.number:
        return stripNonNumeric(value);
      default:
        return value;
    }
  };

  handleChange = (event) => {
    const { name, value, entityId, validationError } = event.target;

    this.setState((state) => ({
      entityIds: {
        ...state.entityIds,
        [name]: entityId,
      },
      values: {
        ...state.values,
        [name]: !this.isEmptyRangeValue(value) ? value : undefined,
      },
      validationErrors: {
        ...state.validationErrors,
        [name]: validationError,
      },
    }));
  };

  handleSubmit = (event) => {
    event.preventDefault();
    // Convert values (e.g. strip invalid characters from numbers.)
    const values = Object.entries(this.state.values).reduce(
      (acc, [key, value]) => {
        const field = this.props.fields.find((field) => field.name === key);
        return {
          ...acc,
          [key]: value ? this.convertValue(field.type, value) : value,
        };
      },
      {}
    );
    this.props.onSubmit(values, this.state.entityIds);
  };

  handleReset = () => {
    this.setState(
      {
        values: this.props.fields.reduce(
          (acc, field) => ({ ...acc, [field.name]: '' }),
          {}
        ),
        entityIds: {},
      },
      () => this.props.onSubmit(this.state.values, [])
    );
  };

  hasValidationErrors = () => {
    return Object.values(this.state.validationErrors).find(
      (validationError) => validationError
    );
  };

  handleToggleDayPicker = (open) => {
    this.setState({ disableEscKey: open });
  };

  render() {
    const {
      fields,
      values,
      onSubmit,
      onCancel,
      entityIds,
      isUnique,
      isMobile,
      compact,
      ...other
    } = this.props;
    return (
      <>
        <form onSubmit={this.handleSubmit}>
          <Card flat={false} fullScreenSmall compact={compact} {...other}>
            <div>
              <Header>
                <Title span={12}>
                  <VeloTypography use="secondaryHeader" tag="div">
                    <FormattedMessage defaultMessage="Filters" />
                  </VeloTypography>
                  <VeloIconButton
                    icon="close"
                    title={this.props.intl.formatMessage({
                      defaultMessage: 'Close filter',
                    })}
                    type="button"
                    onClick={onCancel}
                    data-testid={`${root}-cancel`}
                  />
                </Title>
              </Header>
              <VeloDivider />
            </div>

            {/* FIXME: Use the <Grid /> component when it supports a ref prop. */}
            <Body className="mdc-layout-grid" ref={this.bodyRef}>
              <VeloGrid.Inner>
                {fields.map((field, index) => (
                  <VeloGrid.Cell key={`field-${index}`} span={12}>
                    {this.createField(field)}
                  </VeloGrid.Cell>
                ))}
              </VeloGrid.Inner>
            </Body>

            <div>
              <VeloDivider />
              <Footer>
                <VeloGrid.Cell desktop={6} phone={2}>
                  <VeloButton
                    fullWidth
                    onClick={this.handleReset}
                    type="button"
                    data-testid={TestIds.RESET_BUTTON}
                  >
                    <FormattedMessage defaultMessage="Clear All" />
                  </VeloButton>
                </VeloGrid.Cell>

                <VeloGrid.Cell desktop={6} phone={2}>
                  <VeloButton.Primary
                    fullWidth
                    type="submit"
                    disabled={this.hasValidationErrors()}
                    data-testid={TestIds.APPLY_BUTTON}
                  >
                    <FormattedMessage defaultMessage="Apply" />
                  </VeloButton.Primary>
                </VeloGrid.Cell>
              </Footer>
            </div>
          </Card>
        </form>
        <EscapeKey onPressed={onCancel} disabled={this.state.disableEscKey} />
        <ScrollLock
          getScrollableElement={() => this.bodyRef.current}
          isEnabled={isMobile}
        />
      </>
    );
  }
}

const FilterFormIntl = injectIntl(FilterForm);

export { FilterFormIntl as FilterForm };
