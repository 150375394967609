import React from 'react';
import {
  useAuthStateAsResult,
  AppShell,
  UserTokenRoutes,
  Notifications,
  wrapPrivateRoutes,
  forkResult,
} from 'velo-portal-common';
import { VeloAppLoading } from 'velo-react-components';
import { PublicRoutes, PrivateRoutes } from './routes';

const payorIdPropName = 'payorId';

const Private = wrapPrivateRoutes(PrivateRoutes, payorIdPropName);

const forks = {
  none: () => <VeloAppLoading />,
  value: (authProps, props) => {
    return authProps.loggedIn ? (
      <Private {...props} />
    ) : (
      <PublicRoutes {...props} />
    );
  },
};

function Routes(props) {
  const result = useAuthStateAsResult(payorIdPropName);
  return forkResult(forks, result, props);
}

const paymentsRoute = ((list) => ({
  navigateToPaymentsList: list,
  navigateToPaymentDetails: `${list}/:paymentId`,
  navigateToPaymentReceipt: `${list}/:paymentId/receipt`,
}))('/payments');

const fundingRoutes = ((list) => ({
  navigateToFundingList: list,
}))('/funding');

const usersRoutes = ((list) => ({
  navigateToUsersList: list,
  navigateToUserDetails: `${list}/:userId`,
  navigateToUserCreate: `${list}/create`,
  navigateToUserEdit: `${list}/:userId/edit`,
}))('/users');

const payeesRoutes = ((list) => ({
  navigateToPayeesList: list,
  navigateToPayeesByPayorList: `${list}/payor-relationships`,
  navigateToPayeeDetails: `${list}/:payeeId`,
  navigateToPayeeCreate: `${list}/create`,
  navigateToPayeeUpload: `${list}/create/:batchId/:batchCount`,
  navigateToPayeeRefresh: `${list}/refresh`,
  navigateToPayeeEdit: `${list}/:payeeId/edit`,
  navigateToPayeeEditRemoteId: `${list}/:payeeId/remoteId`,
}))('/payees');

const paymentMethodsRoutes = ((list) => {
  return {
    navigateToPayeePaymentMethods: list,
    navigateToPaymentMethodDetail: `${list}/:paymentMethodId`,
    navigateToPaymentMethodEdit: `${list}/:paymentMethodId/edit`,
    navigateToPaymentMethodCreate: `${list}/create/new`,
  };
})('/payees/:payeeId/payment-methods');

const sourceAccountsRoutes = ((list) => {
  const detail = `${list}/:sourceAccountId`;
  const addFunding = `${detail}/add-funds`;
  const navigateToSourceAccountEdit = `${detail}/edit`;
  const autoTopup = `${detail}/ach-auto-topup`;
  const balanceThreshold = `${detail}/low-balance-notification`;
  const transferFunds = `${detail}/transfer-funds`;
  return {
    navigateToSourceAccountsList: list,
    navigateToSourceAccountEdit,
    navigateToSourceAccountDetail: `${detail}/:onTop(tags.*)?`,
    navigateToSourceAccountAddFunding: addFunding,

    navigateToSourceAccountAutoTopup: autoTopup,
    navigateToSourceAccountBalanceThreshold: balanceThreshold,
    navigateToSourceAccountTransferFunds: transferFunds,
  };
})('/source-accounts');

const fundingAccountsRoutes = ((list) => {
  const detail = `${list}/:fundingAccountId`;
  const create = `${list}/create`;
  const edit = `${detail}/edit`;
  return {
    navigateToFundingAccountsList: list,
    navigateToFundingAccountsCreate: create,
    navigateToFundingAccountEdit: edit,
    navigateToFundingAccountDetail: detail,
  };
})('/funding-accounts');

const reportsRoutes = ((reports) => ({
  navigateToReports: reports,
  navigateToTransactionReport: `${reports}/transaction-report`,
}))('/reports');

const settingsRoutes = ((list) => {
  const developer = `${list}/developer`;
  return {
    navigateToSettingsWebhook: `${list}/webhook`,
    navigateToSettings: `${developer}`,
    navigateToSettingsAppearance: `${developer}/appearance`,
    navigateToSettingsAdvanced: `${developer}/advanced`,
  };
})('/settings');

const userTokenWireframeConfig = UserTokenRoutes.wireframeConfig;

const payoutsRoutes = ((list) => {
  const detail = `${list}/:payoutId`;
  const payment = `${detail}/:paymentId`;
  const review = `${detail}/review`;
  const reviewPayment = `${detail}/review/:paymentId`;
  const processing = `${detail}/processing`;

  return {
    navigateToPayoutsList: list,
    navigateToPayoutDetail: detail,
    navigateToPayoutPayment: payment,
    navigateToPayoutWithdrawn: `${detail}/withdrawn`,
    navigateToPayoutPaymentReceipt: `${payment}/receipt`,
    navigateToPayoutReview: review,
    navigateToPayoutPaymentReview: reviewPayment,
    navigateToPayoutProcessing: processing,
    navigateToPayoutCreate: `${list}/create/:type`,
    navigateToPayoutCreateOpenBanking: `${list}/create-open-banking`,
    navigateToPayoutOpenBankingConsent: `${list}/create-open-banking/payout-consent`,
  };
})('/payouts');

const publicRoutes = {
  navigateToLogin: '/login',
  navigateToForgotPassword: '/forgot-password',
  navigateToUnlockAccount: '/unlock-account',
  navigateToResetPassword: '/reset-password',
  navigateToSignout: '/signout',
};

export const wireframeRoutePaths = {
  ...paymentsRoute,
  ...fundingRoutes,
  ...usersRoutes,
  ...payeesRoutes,
  ...paymentMethodsRoutes,
  ...sourceAccountsRoutes,
  ...fundingAccountsRoutes,
  ...reportsRoutes,
  ...payoutsRoutes,
  ...settingsRoutes,
  ...userTokenWireframeConfig,
  ...publicRoutes,
};

const App = (props) => (
  <AppShell {...props} routePaths={wireframeRoutePaths}>
    <Notifications />
    <Routes {...props} />
  </AppShell>
);

export default App;
