import React, { useCallback } from 'react';
import { Route, Switch, useHistory } from 'react-router';
import { useWireframe } from '../../hooks';
import { FundingAccountsCreateRoute } from './FundingAccountsCreateRoute';
import { FundingAccountsListRoute } from './FundingAccountsListRoute';
import { FundingAccountsEditRoute } from './FundingAccountsEditRoute';
import { FundingAccountViewRoute } from './FundingAccountViewRoute';

function FundingAccountsRoute({ query, role }) {
  const history = useHistory();
  const wireframe = useWireframe(history);

  const renderEdit = useCallback(
    ({ match }) => <FundingAccountsEditRoute {...match.params} role={role} />,
    [role]
  );

  const renderView = useCallback(
    ({ match }) => (
      <FundingAccountViewRoute
        {...(match && match.params)}
        role={role}
        query={query}
      />
    ),
    [role, query]
  );

  return (
    <Switch>
      <Route path={wireframe.navigateToFundingAccountsCreate.path} exact>
        <FundingAccountsCreateRoute query={query} />
      </Route>
      <Route
        path={wireframe.navigateToFundingAccountEdit.path}
        exact
        render={renderEdit}
      />
      <Route>
        <FundingAccountsListRoute query={query} role={role} />
        <Route path={wireframe.navigateToFundingAccountDetail.path} exact>
          {renderView}
        </Route>
      </Route>
    </Switch>
  );
}

export { FundingAccountsRoute };
