import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { paymentChannelsConfig } from 'velo-data';
import { MultiLineOnOffSwitch } from './MultiLineOnOffSwitch';

/**
 * Component used to toggle Payment Channel fields
 * when adding a single payee. Contains some helper text indicating
 * the effect of creating a Payment Channel to the user.
 */
export function PaymentChannelSwitch(props) {
  const intl = useIntl();
  return (
    <MultiLineOnOffSwitch
      label={
        <FormattedMessage
          defaultMessage="Add a {title}?"
          values={{ title: paymentChannelsConfig.title }}
        />
      }
      line1={
        <FormattedMessage defaultMessage="Select this if you have information about where to send payments for this Payee." />
      }
      line2={
        <FormattedMessage defaultMessage="The Payee may modify this information and choose to send their payments elsewhere." />
      }
      aria-label={intl.formatMessage(
        {
          defaultMessage: 'Toggle {title}',
        },
        { title: paymentChannelsConfig.title }
      )}
      {...props}
    />
  );
}
