import React, { useMemo } from 'react';
import { Switch, Route, Redirect } from 'react-router';
import {
  VeloNotification,
  InviteOTP,
  InviteRegisterYubikey,
  InviteRegisterTOTP,
  MFATypes,
  OnboardingPage,
} from 'velo-react-components';
import {
  generateOTP as sendOTP,
  validateOTP,
  validateMFA,
  registerMFA,
} from 'velo-api/src/entities/tokens';
import { useAPIMethods } from 'velo-api-react-hooks';
import { useAPIContext } from 'velo-api-react-hooks';
import { useWireframe, useCallbackFnAsResultState } from '../../hooks';
import { forkResult } from '../../selectors';

import {
  MFAValidationPresenter,
  MFARegistrationPresenter,
  OTPValidationPresenter,
} from './UserTokenPresenters';

const entitySpec = {
  sendOTP,
  validateOTP,
  validateMFA,
  registerMFA,
};

const steps = 2;
const userTokenInvalidNotification = VeloNotification.types.USER_TOKEN_INVALID;
const completeNotification = VeloNotification.types.USER_REGISTER_MFA_SUCCESS;
const resendSmsNotification = VeloNotification.types.RESEND_SMS_SUCCESS;

const QR_CODE_SIZE = InviteRegisterTOTP.QR_CODE_SIZE;

function RegisterYubikeyRoute({ history, otpToken }) {
  const wireframe = useWireframe(history);
  const entity = useAPIMethods(entitySpec);

  const [componentProps] = useMemo(
    () =>
      MFAValidationPresenter({ otpToken }, wireframe, entity, {
        userTokenInvalidNotification,
        completeNotification,
      }),
    [otpToken, wireframe, entity]
  );

  return (
    <OnboardingPage activeStep={1} steps={steps}>
      <InviteRegisterYubikey {...componentProps} />
    </OnboardingPage>
  );
}

const forks = {
  none: () => <InviteRegisterTOTP.Loading />,
  value: (data, props) => <InviteRegisterTOTP {...data} {...props} />,
};

function RegisterTOTPRoute({ history, otpToken }) {
  const wireframe = useWireframe(history);
  const entity = useAPIMethods(entitySpec);
  const api = useAPIContext();

  const [componentProps] = useMemo(
    () =>
      MFAValidationPresenter({ otpToken }, wireframe, entity, {
        userTokenInvalidNotification,
        completeNotification,
      }),
    [otpToken, wireframe, entity]
  );

  const [loader, registrationProps] = useMemo(
    () =>
      MFARegistrationPresenter({ otpToken }, wireframe, entity, {
        userTokenInvalidNotification,
        host: api.host,
        QRCodeSize: QR_CODE_SIZE,
      }),
    [otpToken, wireframe, entity, api.host]
  );
  const [result] = useCallbackFnAsResultState(loader);

  return (
    <OnboardingPage activeStep={1} steps={steps}>
      {forkResult(forks, result, {
        ...componentProps,
        ...registrationProps,
      })}
    </OnboardingPage>
  );
}

function OTPRoute(props) {
  return (
    <OnboardingPage activeStep={0} steps={steps}>
      <InviteOTP.MFARegistration {...props} />
    </OnboardingPage>
  );
}

const RoutesMFATypeMap = {
  [MFATypes.YUBIKEY]: [RegisterYubikeyRoute],
  [MFATypes.TOTP]: [RegisterTOTPRoute],
};

function MFARegistrationRoutes({ token, history, ...props }) {
  const [RegisterMFARoute] = RoutesMFATypeMap[props.mfaType];
  const wireframe = useWireframe(history);
  const entity = useAPIMethods(entitySpec);

  const [otpProps] = useMemo(
    () =>
      OTPValidationPresenter({ token }, wireframe, entity, {
        userTokenInvalidNotification,
        resendSmsNotification,
      }),
    [entity, token, wireframe]
  );

  return (
    <Switch>
      <Route
        path={wireframe.navigateToMFARegistrationRegisterDevice.path}
        render={({ history, match }) => (
          <RegisterMFARoute
            history={history}
            otpToken={match.params.otpToken}
            {...props}
          />
        )}
      />
      <Route
        path={wireframe.navigateToMFARegistrationOTPCheck.path}
        render={(routeProps) => (
          <OTPRoute {...routeProps} {...props} {...otpProps} />
        )}
      />
      <Redirect to={wireframe.navigateToMFARegistrationOTPCheck.path} />
    </Switch>
  );
}

export { MFARegistrationRoutes };
