import { useState, useCallback } from 'react';

// Hook used to manage pagination.
// Returns a function to change the page and a helper used to format
// a query object.
const usePagination = (pageSize) => {
  const [page, setPage] = useState(1);
  const query = useCallback(() => ({ page, pageSize }), [page, pageSize]);
  return [setPage, query];
};

export { usePagination };
