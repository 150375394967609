import { mapTablePropsWithResult } from 'velo-portal-common/src/selectors';

export function PayorFundingListPresenter(_, entity, query, { filters }) {
  function loader(componentQuery, cb) {
    return entity.getPayorFundings(
      { ...query, ...componentQuery },
      (error, response) => {
        if (error) return cb(error);
        const { content, page } = response;
        cb(undefined, content, { page });
      }
    );
  }

  function getProps(...args) {
    return mapTablePropsWithResult(...args);
  }

  //@note This would be required for the LookupTextField filter when implemented
  //const fetchSourceAccountResults = SourceAccountSearchPresenter(entity, query);

  return [
    loader,
    /** Populate the filters from the query and fixed data */
    filters,
    /* @note Needed for the LookupTextField filter when added
    filterSelectors.populateFilters(filters, query, {
      fetchSourceAccountResults,
      intl,
    }),*/
    getProps,
  ];
}
