import { useMemo } from 'react';
import {
  useAppContext,
  contextPaymentChannelRulesSelector,
} from '../../context';

export function usePaymentChannelRules() {
  const context = useAppContext();
  const rules = contextPaymentChannelRulesSelector(context);
  return useMemo(() => ({ rules }), [rules]);
}
