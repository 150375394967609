import React, { useMemo } from 'react';
import {
  UsersPage,
  UsersList,
  Pagination,
  UserRoles,
} from 'velo-react-components';
import { useUsers, useWireframe } from '../../hooks';
import { forkResult } from '../../selectors';
import { useHistory } from 'react-router';

const cacheKey = 'payor-users';

function PayorUsersListRoute({ role, payorId }) {
  const query = useMemo(
    () => ({ type: 'Payor', entityId: payorId }),
    [payorId]
  );

  const { content, page, sortProps } = useUsers(
    {
      query,
      initialSortColumn: UsersList.PayorUsers.columns.EMAIL,
      initialSortOrder: UsersList.PayorUsers.sortOrder.ASCENDING,
    },
    cacheKey
  );
  const { navigateToUserCreate, navigateToUserDetails } = useWireframe(
    useHistory()
  );

  return (
    <UsersPage.Payor
      onCreate={
        UserRoles.isPayorAdmin(role)
          ? () => navigateToUserCreate({ payorId })
          : undefined
      }
    >
      <UsersList.PayorUsers.Table>
        <UsersList.PayorUsers.Headings {...sortProps} />
        {forkResult(
          {
            none: () => (
              <>
                <UsersList.PayorUsers.Loading />
                <Pagination skeleton />
              </>
            ),
            error: (error) => <UsersList.PayorUsers.Error error={error} />,
            empty: () => <UsersList.PayorUsers.Empty />,
            value: (data) => (
              <>
                <UsersList.PayorUsers.Data
                  data={data}
                  onClick={({ id: userId }) =>
                    navigateToUserDetails({ userId })
                  }
                />
                <Pagination {...page} />
              </>
            ),
          },
          content
        )}
      </UsersList.PayorUsers.Table>
    </UsersPage.Payor>
  );
}

export { PayorUsersListRoute };
