import React from 'react';
import { useHistory } from 'react-router';

class AppErrorBoundaryComponent extends React.Component {
  state = {
    error: false,
  };

  static getDerivedStateFromError(error) {
    return {
      error,
    };
  }

  componentDidMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      if (this.state.error) {
        this.setState({ error: false });
      }
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  render() {
    if (this.state.error) {
      return this.props.fallback;
    }
    return this.props.children;
  }
}

function AppErrorBoundary(props) {
  const history = useHistory();

  return <AppErrorBoundaryComponent {...props} history={history} />;
}

export { AppErrorBoundary };
