import { func, string, number, object, shape } from 'prop-types';

const paymentReceiptPropTypes = {
  onClose: func.isRequired,
  result: shape({
    payorName: object,
    payeeName: object,
    submittedDateTime: string.isRequired,
    status: string.isRequired,
    sourceAmount: number.isRequired,
    sourceCurrency: string.isRequired,
    paymentAmount: number.isRequired,
    paymentCurrency: string.isRequired,
    rate: number.isRequired,
    paymentMemo: string,
  }),
};

export { paymentReceiptPropTypes };
