import { useRef, useEffect, useState } from 'react';

/**
 * Hook used to scroll an element into view.
 *
 * e.g.:
 *
 * When the component mounts:
 *
 *   const ref = useScrollIntoView();
 *   return <div ref={ref}>...</div>
 *
 * When a prop changes:
 *   const ref = useScrollIntoView([someProp]);
 *   return <div ref={ref}>...</div>
 */
const useScrollIntoView = (options) => {
  const ref = useRef(null);
  useEffect(() => {
    ref.current && ref.current.scrollIntoView(options);
  }, [options]);
  return ref;
};

const useScrollIntoViewOnce = (
  trigger,
  options = {
    behavior: 'smooth',
    block: 'start',
    inline: 'nearest',
  }
) => {
  const ref = useRef(null);
  const [triggered, setTriggered] = useState(false);
  useEffect(() => {
    if (!triggered & trigger) {
      setTriggered(true);
      ref.current && ref.current.scrollIntoView(options);
    }
  }, [trigger, triggered, options]);
  return ref;
};

/**
 * Hook used to scroll an element to (0, 0).
 */
const useScrollToTop = (trigger = undefined) => {
  const ref = useRef(null);
  useEffect(() => {
    ref.current && (ref.current.scrollTop = 0);
  }, [trigger]);
  return ref;
};

export { useScrollIntoView, useScrollIntoViewOnce, useScrollToTop };
