import React from 'react';
import { ForgotPasswordPage } from 'velo-react-components';
import { formatError } from '../../selectors';
import { Context } from '../../context';

class PasswordForgottenContainer extends React.PureComponent {
  static contextType = Context;
  state = {
    error: null,
    showEmailInstructionsSent: false,
  };

  requestEmailLink = (emailAddress) => {
    this.setState({ error: null });

    this.context.api.sendResetPasswordLink(emailAddress).then(
      (result) => {
        this.setState({ showEmailInstructionsSent: true });
      },
      (ex) => {
        this.setState({
          error: formatError(ex),
        });
      }
    );
  };

  backToLoginScreen = () => {
    this.props.history.push('/login');
  };

  render() {
    return (
      <ForgotPasswordPage
        {...this.props}
        {...this.state}
        exit={this.requestEmailLink}
        onClickBackLink={this.backToLoginScreen}
      />
    );
  }
}

export { PasswordForgottenContainer };
