import omit from 'just-omit';
import {
  forkResult,
  formatCallbackErrorArg,
  createEarlyExitCallback,
  paymentChannelRulesSelector,
} from '../../selectors';
import { PaymentMethodsDataJoin } from './PaymentMethodsDataJoin';
import { privilegeSelectors, Privileges } from 'velo-data';
import { UserRoles } from 'velo-react-components';

const EDIT_PRIVILEGES = [Privileges.PAYMENT_METHOD_EDIT];
const DELETE_PRIVILEGES = [Privileges.PAYMENT_METHOD_DELETE];

function getOmittableProps({ enabled }, privileges) {
  return [
    enabled && 'onEnable',
    !privilegeSelectors.hasPrivileges(EDIT_PRIVILEGES, privileges) && 'onEdit',
    !privilegeSelectors.hasPrivileges(DELETE_PRIVILEGES, privileges) &&
      'onDelete',
  ].filter(Boolean);
}

const forks = {
  value: (data, props, privileges, allPaymentChannelRules) => ({
    data,
    paymentChannelRules: paymentChannelRulesSelector(
      data.countryCode,
      allPaymentChannelRules
    ),
    ...omit(props, getOmittableProps(data, privileges)),
  }),
  error: (_, { onClose }) => ({ onClose }),
  none: ({ onClose }) => ({ onClose }),
};

const nullLoader = () => undefined;

export function Base(
  wireframe,
  entity,
  { paymentMethodId, privileges, query, role, payeeId },
  { deleteNotes, enableNotes, onClose }
) {
  const props = {
    onClose,
    onEdit: !UserRoles.isPayeeSupport(role)
      ? () =>
          wireframe.navigateToPaymentMethodEdit({
            paymentMethodId,
            payeeId: payeeId,
          })
      : undefined,
    onDelete: !UserRoles.isPayeeSupport(role)
      ? ({ payeeId }, cb) => {
          return entity.deletePaymentMethod(
            payeeId,
            paymentMethodId,
            formatCallbackErrorArg((error) => {
              cb(error);
              const success = !error;
              wireframe.sendNote(
                success ? deleteNotes.success : deleteNotes.failure,
                success
              );
              wireframe.goBack();
            })
          );
        }
      : undefined,
    onEnable: ({ payeeId }, cb) => {
      return entity.enablePaymentMethod(
        payeeId,
        paymentMethodId,
        formatCallbackErrorArg((error) => {
          cb(error);
          const success = !error;
          wireframe.sendNote(
            success ? enableNotes.success : enableNotes.failure,
            success
          );
        })
      );
    },
    role,
    paymentMethodId,
  };

  const dataJoin = PaymentMethodsDataJoin(entity);

  const loader = (cb) =>
    entity.getPaymentMethod(
      paymentMethodId,
      { ...query, sensitive: !UserRoles.isPayeeSupport(role) },
      createEarlyExitCallback(
        // when the raw payment method is returned
        (data) =>
          // call the data join with [data] to wrap in an array
          dataJoin(
            createEarlyExitCallback(
              // when the data join finishes, unwrap the array
              ([paymentMethod]) => cb(undefined, paymentMethod),
              cb
            ),
            [data]
          ),
        cb
      )
    );

  return [
    paymentMethodId ? loader : nullLoader,
    (result, paymentChannelRules) =>
      forkResult(forks, result, props, privileges, paymentChannelRules),
  ];
}

export const PayeePaymentMethodViewPresenter = (
  wireframe,
  entity,
  fixedProps,
  handlers
) => {
  const presenterProps = Base(wireframe, entity, fixedProps, {
    ...handlers,
    onClose: wireframe.navigateToPaymentMethodsList,
  });

  return presenterProps;
};

export const BackofficePaymentMethodViewPresenter = (
  wireframe,
  entity,
  fixedProps,
  handlers
) => {
  const presenterProps = Base(wireframe, entity, fixedProps, {
    ...handlers,
    onClose: wireframe.navigateToPaymentMethods,
  });

  return presenterProps;
};

export const PayorPaymentMethodViewPresenter = (
  wireframe,
  entity,
  fixedProps,
  handlers
) => {
  const presenterProps = Base(wireframe, entity, fixedProps, {
    ...handlers,
    onClose: () =>
      wireframe.navigateToPayeePaymentMethods({
        payeeId: fixedProps.payeeId,
      }),
  });

  return presenterProps;
};
