import {
  createDataJoinCallbackFunction,
  createAwaitAllDataJoinCallback,
} from '../../selectors';

export function FundingAccountNameDataJoin(entity) {
  return createAwaitAllDataJoinCallback(
    createDataJoinCallbackFunction(
      (data) =>
        data
          .map(({ autoTopUpConfig }) => ({
            key: autoTopUpConfig.fundingAccountId,
            params: [autoTopUpConfig.fundingAccountId, {}],
          }))
          .filter((data) => data.key !== undefined),
      entity.getFundingAccount
    ),
    (resultsByKey, content, value) => {
      // Guard for when there is no fundingAccountId present on a source account
      if (!resultsByKey[value.autoTopUpConfig.fundingAccountId]) {
        return [undefined, content.concat(value)];
      }

      const { error, result: fundingAccount } =
        resultsByKey[value.autoTopUpConfig.fundingAccountId];
      if (error) {
        return [error];
      }

      return [
        undefined,
        content.concat({
          ...value,
          autoTopUpConfig: {
            ...value.autoTopUpConfig,
            fundingAccountName: fundingAccount.name,
          },
        }),
      ];
    },
    true
  );
}
