import { usePayorDetailsAPI } from 'velo-api-react-hooks';
import { UserRoles } from 'velo-react-components';
import { formatError, withResult } from '../../selectors';
import { useInviteUser } from './useInviteUser';

const formatResult = withResult((x) => x, formatError);

function useInvitePayorUser({ payorId, onSuccess, role }) {
  const [data] = usePayorDetailsAPI(payorId, () => role === UserRoles.BOPAdmin);
  const onSubmit = useInviteUser({ entityId: payorId, onSuccess });

  return {
    data: formatResult(data),
    onSubmit,
  };
}

export { useInvitePayorUser };
