import React from 'react';
import { FormattedMessage } from 'react-intl';
import { PayoutStates, dataTestIdBuilder } from 'velo-data';
import TestIds from './testIds';

const FieldNames = {
  Submitted: 'paymentsSubmitted',
  Accepted: 'paymentsAccepted',
  Rejected: 'paymentsRejected',
  Withdrawn: 'paymentsWithdrawn',
  Total: 'totalPayments',
  Confirmed: 'confirmedPayments',
  Released: 'releasedPayments',
  Returned: 'returnedPayments',
  Incomplete: 'incompletePayments',
  PayoutFrom: 'payoutFrom',
  PayoutTo: 'payoutTo',
  SubmittingFrom: 'submitting',
};

const AttributesByFieldName = {
  [FieldNames.Submitted]: {
    label: <FormattedMessage defaultMessage="Submitted" />,
  },
  [FieldNames.Accepted]: {
    label: <FormattedMessage defaultMessage="Accepted" />,
  },
  [FieldNames.Rejected]: {
    label: <FormattedMessage defaultMessage="Rejected" />,
    showError: true,
  },
  [FieldNames.Withdrawn]: {
    label: <FormattedMessage defaultMessage="Withdrawn" />,
  },
  [FieldNames.Total]: {
    label: <FormattedMessage defaultMessage="Total payments" />,
    'data-testid': dataTestIdBuilder(
      TestIds.PAYMENT_SUMMARY_PREFIX,
      'total-payments'
    ),
  },
  [FieldNames.Confirmed]: {
    label: <FormattedMessage defaultMessage="Confirmed" />,
    'data-testid': dataTestIdBuilder(
      TestIds.PAYMENT_SUMMARY_PREFIX,
      'confirmed-payments'
    ),
  },
  [FieldNames.Released]: {
    label: <FormattedMessage defaultMessage="Released" />,
    'data-testid': dataTestIdBuilder(
      TestIds.PAYMENT_SUMMARY_PREFIX,
      'released-payments'
    ),
  },
  [FieldNames.Returned]: {
    label: <FormattedMessage defaultMessage="Returned" />,
    showError: true,
    // purposely named 'failed-payments'
    'data-testid': dataTestIdBuilder(
      TestIds.PAYMENT_SUMMARY_PREFIX,
      'failed-payments'
    ),
  },
  [FieldNames.Incomplete]: {
    label: <FormattedMessage defaultMessage="Incomplete" />,
    'data-testid': dataTestIdBuilder(
      TestIds.PAYMENT_SUMMARY_PREFIX,
      'incomplete-payments'
    ),
  },
  [FieldNames.SubmittingFrom]: {
    label: <FormattedMessage defaultMessage="Submitted by" />,
  },
  [FieldNames.PayoutFrom]: {
    label: <FormattedMessage defaultMessage="Payout from" />,
  },
  [FieldNames.PayoutTo]: {
    label: <FormattedMessage defaultMessage="Payout to" />,
  },
};

const DefaultColumns = [
  FieldNames.Submitted,
  FieldNames.Accepted,
  FieldNames.Rejected,
  FieldNames.Withdrawn,
  FieldNames.Incomplete,
];

const CompletedColumns = [
  FieldNames.Total,
  FieldNames.Confirmed,
  FieldNames.Released,
  FieldNames.Returned,
  FieldNames.Incomplete,
];

const ColumnsByType = {
  [PayoutStates.ACCEPTED]: DefaultColumns,
  [PayoutStates.COMPLETED]: CompletedColumns,
};

function summaryToColumns(summary) {
  const columns =
    ColumnsByType[summary.payoutStatus] || ColumnsByType[PayoutStates.ACCEPTED];
  return columns.map((key) => ({
    value: summary[key] || 0,
    ...AttributesByFieldName[key],
    'data-testid': dataTestIdBuilder(TestIds.PAYMENT_SUMMARY_PREFIX, `-${key}`),
  }));
}

const TimelineOrder = [
  FieldNames.SubmittingFrom,
  FieldNames.PayoutFrom,
  FieldNames.PayoutTo,
];

function summaryToTimeline(summary) {
  return TimelineOrder.reduce((xs, key) => {
    const field = summary[key];
    if (field) {
      const data = {
        ...AttributesByFieldName[key],
        value: field.payorName,
      };
      return [...xs, data];
    }

    return xs;
  }, []);
}

export const PayoutHeaderFields = {
  AttributesByFieldName,
  FieldNames,
  summaryToColumns,
  summaryToTimeline,
};
