const root = 'velo-payouts-instruct-review';
const payoutRoot = 'payments-by-payout';
const TestIds = {
  HEADER: `${root}-header`,
  SUMMARY: `${root}-summary`,
  ACTIONS: `${root}-actions`,
  ERRORS: `${root}-errors`,
  PAYMENT_SUMMARY_PREFIX: payoutRoot,
  PAYOUT_MEMO: `${payoutRoot}-payout-memo`,
  SOURCE_ACCOUNT: `${root}-source-account`,
  SOURCE_TOTAL: `${root}-source-total`,
  DATE: `${root}-date`,
};

export default TestIds;
