import { PayeePaymentsList } from './PayeePaymentsList';
import { PayorPaymentsList } from './PayorPaymentsList';
import { BackOfficePaymentsList } from './BackOfficePaymentsList';

const PaymentsList = {
  Payee: PayeePaymentsList,
  Payor: PayorPaymentsList,
  BackOffice: BackOfficePaymentsList,
};

export { PaymentsList };
