import React from 'react';
import { useParams } from 'react-router';
import {
  usePresenter,
  useSerializableMemo,
  contextCurrenciesSelector,
  useAppContext,
} from 'velo-portal-common';
import { PayoutCreateForm } from 'velo-react-components';
import { useCreatePayoutHook } from '../../hooks/payouts';
import { CreatePayoutPresenter } from './CreatePayoutPresenter';
import { listPayeesV4 as getPayees } from 'velo-api/src/entities/payees';
import { getPayorByIdV2 } from 'velo-api/src/entities/payors';
import { payorLinksV1 } from 'velo-api/src/entities/payorLinks';
import { getSourceAccountsV3 } from 'velo-api/src/entities/sourceAccounts';
import { submitPayoutV3 as submitPayout } from 'velo-api/src/entities/payouts';

const entity = {
  getPayees,
  getPayor: getPayorByIdV2,
  getSourceAccounts: getSourceAccountsV3,
  getPayorLinks: payorLinksV1,
  submitPayout,
};

function CreatePayoutRoute({ payorId }) {
  const context = useAppContext();

  const { type: payoutType } = useParams();
  const { load, searchPayeesByName, submitPayout, ...onActionHandlers } =
    usePresenter(CreatePayoutPresenter, entity);

  const hookProps = useCreatePayoutHook(
    useSerializableMemo({
      currencies: contextCurrenciesSelector(context),
      payorId,
      payoutType,
    }),
    load,
    submitPayout,
    searchPayeesByName
  );

  return <PayoutCreateForm {...hookProps} {...onActionHandlers} />;
}

export { CreatePayoutRoute };
