import { UserRoles } from 'velo-react-components';

import {
  filterSelectors,
  mapTablePropsWithResult,
  createCancelAllClosure,
  createEarlyExitCallback,
} from '../../selectors';
import {
  PayeeSearchPresenter,
  PayorSearchPresenter,
  createListRedirectionLoader,
} from '../../presenters';
import { PaymentMethodsDataJoin } from './PaymentMethodsDataJoin';

const keys = ['payorId', 'payeeId'];
export const paymentMethodsQueryKey = 'get-payment-methods';

function Base(
  wireframe,
  entity,
  notifications,
  { filters, countries, currencies, payeeId, role, query, queryClient },
  intl
) {
  const onClick = ({ id: paymentMethodId }) => {
    wireframe.navigateToPaymentMethodDetail(
      { paymentMethodId, isPayee: !!payeeId, payeeId },
      query
    );
  };

  const onCreate =
    payeeId && !UserRoles.isPayeeSupport(role)
      ? () => wireframe.navigateToPaymentMethodCreate({ payeeId })
      : null;

  const filterOptions = !payeeId
    ? filterSelectors.populateFilters(filters, query, {
        fetchPayorResults: PayorSearchPresenter(entity, { pageSize: 5 }),
        fetchPayeeResults: PayeeSearchPresenter(entity, { pageSize: 5 }),
        countries,
        currencies,
        intl,
      })
    : [];

  return [
    filterOptions,
    (tableProps, result) => {
      return [
        mapTablePropsWithResult(tableProps, result),
        {
          onClick,
          isFetching: result.isFetching,
          /**
           * Used in Payee list only (React Query)
           */
          onUpdateOrder: (content) => {
            const nextIds = content.map(({ id }) => id);
            const oldIds = result.data.map(({ id }) => id);

            if (JSON.stringify(nextIds) !== JSON.stringify(oldIds)) {
              entity.updatePaymentChannelOrder(
                payeeId,
                {
                  paymentChannelIds: content.map(({ id }) => id),
                },
                (err, data) => {
                  if (err) {
                    wireframe.sendNote(notifications.reorder.failure);
                  } else {
                    wireframe.sendNote(notifications.reorder.success);
                    queryClient.cancelQueries(paymentMethodsQueryKey);

                    result.refetch();
                  }
                }
              );
            }
          },
        },
      ];
    },
    onCreate,
  ];
}

export function BackOfficePaymentMethodsListPresenter(
  wireframe,
  entity,
  notifications,
  { filters, countries, currencies, payeeId, role, query },
  intl
) {
  const presenterProps = Base(
    wireframe,
    entity,
    notifications,
    { filters, countries, currencies, payeeId, role, query },
    intl
  );
  const dataJoin = PaymentMethodsDataJoin(entity);

  const redirect = (qs) => wireframe.navigateToPaymentMethods.redirect({}, qs);

  const loader = createListRedirectionLoader(
    redirect,
    keys,
    query,
    (computedQuery, cb) => {
      const queryParams = payeeId
        ? { ...computedQuery, payeeId: payeeId }
        : computedQuery;
      const cancellations = [];
      cancellations.push(
        entity.getPaymentMethods(
          queryParams,
          createEarlyExitCallback(({ content, ...extra }) => {
            cancellations.push(dataJoin(cb, content, extra));
          }, cb)
        )
      );

      return createCancelAllClosure(cancellations);
    }
  );

  return [loader, ...presenterProps];
}

export function PayeePaymentMethodsListPresenter(
  wireframe,
  entity,
  notifications,
  { filters, countries, currencies, payeeId, role, queryClient, query },
  intl
) {
  const presenterProps = Base(
    wireframe,
    entity,
    notifications,
    { filters, countries, currencies, payeeId, role, query, queryClient },
    intl
  );

  const loader = async () => {
    const response = await entity.getPaymentChannelsPayeeAsync(payeeId);
    return response.paymentChannels;
  };

  loader.queryKey = paymentMethodsQueryKey;

  return [loader, ...presenterProps];
}

export function PayorPaymentMethodsListPresenter(
  wireframe,
  entity,
  notifications,
  { filters, countries, currencies, payeeId, role, queryClient, query },
  intl
) {
  const presenterProps = PayeePaymentMethodsListPresenter(
    wireframe,
    entity,
    notifications,
    { filters, countries, currencies, payeeId, role, query, queryClient },
    intl
  );

  const onClose = () => wireframe.navigateToPayeeDetails({ payeeId });

  return [...presenterProps, onClose];
}
