import React from 'react';
import { arrayOf, string, shape } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { dataTestIdBuilder, PayeeType } from 'velo-data';
import { LookupTextField } from '../LookupTextField';
import { VeloIconLabel } from '../VeloIconLabel';
import { OnboardedStatusIndicator } from '../OnboardedStatusIndicator';
import { WatchlistStatusIndicator } from '../WatchlistStatusIndicator';
import { InvitationStatusIndicator } from '../InvitationStatusIndicator';
import { VeloStatusIndicator } from '../VeloStatusIndicator';
import { VeloDateTime } from '../VeloDateTime';
import { VeloTableBuilder } from '../VeloTableBuilder';
import styles from './PayeesList.module.scss';

const root = 'velo-payees-list';

const TestIds = {
  ICON_SKELETON: `${root}-icon-skeleton`,
  ROW_PREFIX: `${root}-row`,
  COLUMN_PREFIX: `${root}-column`,
};

const PayeesListMode = {
  BACKOFFICE: 'BackOffice',
  PAYOR: 'Payor',
  BY_PAYOR: 'ByPayor',
};

const fieldNames = {
  PAYOR_ID: 'payorId',
  ADDED: 'added',
  DISPLAY_NAME: 'displayName',
  REMOTE_ID: 'remoteId',
  INVITE_STATUS: 'inviteStatus',
  WATCHLIST_STATUS: 'watchlistStatus',
  ONBOARDED_STATUS: 'onboardedStatus',
  DISABLED: 'disabled',
  EMAIL: 'email',
  PAYEE_TYPE: 'payeeType',
  PAYEE_COUNTRY: 'payeeCountry',
};

const columnsByName = {
  [fieldNames.ADDED]: {
    name: fieldNames.ADDED,
    label: <FormattedMessage defaultMessage="Date Added" />,
    'data-testid': dataTestIdBuilder(TestIds.COLUMN_PREFIX, 'date-time'),
  },
  [fieldNames.DISPLAY_NAME]: {
    name: fieldNames.DISPLAY_NAME,
    label: <FormattedMessage defaultMessage="Name" />,
    'data-testid': dataTestIdBuilder(TestIds.COLUMN_PREFIX, 'display-name'),
  },
  [fieldNames.REMOTE_ID]: {
    name: fieldNames.REMOTE_ID,
    label: <FormattedMessage defaultMessage="Remote ID" />,
    'data-testid': dataTestIdBuilder(TestIds.COLUMN_PREFIX, 'remote-id'),
  },
  [fieldNames.INVITE_STATUS]: {
    name: fieldNames.INVITE_STATUS,
    label: <FormattedMessage defaultMessage="Invitation Status" />,
    'data-testid': dataTestIdBuilder(TestIds.COLUMN_PREFIX, 'invite-status'),
  },
  [fieldNames.WATCHLIST_STATUS]: {
    name: fieldNames.WATCHLIST_STATUS,
    label: <FormattedMessage defaultMessage="Watchlist Status" />,
    'data-testid': dataTestIdBuilder(TestIds.COLUMN_PREFIX, 'watchlist-status'),
  },
  [fieldNames.ONBOARDED_STATUS]: {
    name: fieldNames.ONBOARDED_STATUS,
    label: <FormattedMessage defaultMessage="Onboarded Status" />,
    className: styles.onboardedStatus,
    'data-testid': dataTestIdBuilder(TestIds.COLUMN_PREFIX, 'onboarded-status'),
  },
  [fieldNames.DISABLED]: {
    name: fieldNames.DISABLED,
    className: styles.payeeDisabledStatus,
    'data-testid': dataTestIdBuilder(TestIds.COLUMN_PREFIX, 'disabled-status'),
  },
};

const cellFactoryByFieldName = {
  [fieldNames.ADDED]: ({ created }) =>
    created ? (
      <VeloDateTime value={created} />
    ) : (
      <FormattedMessage defaultMessage="Unavailable" />
    ),
  [fieldNames.DISPLAY_NAME]: ({ displayName, icon }) => (
    <VeloIconLabel icon={icon} label={displayName} use="tableContent" />
  ),
  [fieldNames.INVITE_STATUS]: ({ invitationStatus }) => (
    <InvitationStatusIndicator invitationStatus={invitationStatus} />
  ),
  [fieldNames.REMOTE_ID]: ({ remoteId }) => remoteId,
  [fieldNames.WATCHLIST_STATUS]: ({ watchlistStatus }) => (
    <WatchlistStatusIndicator
      watchlistStatus={watchlistStatus}
      use="tableContent"
    />
  ),
  [fieldNames.ONBOARDED_STATUS]: ({ onboardedStatus }) => (
    <OnboardedStatusIndicator
      onboardedStatus={onboardedStatus}
      use="tableContent"
    />
  ),
  [fieldNames.DISABLED]: ({ disabled }) =>
    disabled ? (
      <VeloStatusIndicator
        type={VeloStatusIndicator.types.WARNING}
        label={<FormattedMessage defaultMessage="Disabled" />}
      />
    ) : null,
};

const filtersByName = {
  [fieldNames.DISPLAY_NAME]: {
    name: fieldNames.DISPLAY_NAME,
    label: <FormattedMessage defaultMessage="Payee Name" />,
    type: 'string',
  },
  [fieldNames.EMAIL]: {
    name: fieldNames.EMAIL,
    label: <FormattedMessage defaultMessage="Payee Email" />,
    type: 'string',
  },
  [fieldNames.REMOTE_ID]: {
    name: fieldNames.REMOTE_ID,
    label: <FormattedMessage defaultMessage="Remote ID" />,
    type: 'string',
  },
  [fieldNames.PAYEE_TYPE]: {
    name: fieldNames.PAYEE_TYPE,
    label: <FormattedMessage defaultMessage="Payee Type" />,
    type: 'list',
    options: ({ intl }) => [
      {
        label: intl.formatMessage({ defaultMessage: 'Individual' }),
        value: PayeeType.INDIVIDUAL,
      },
      {
        label: intl.formatMessage({ defaultMessage: 'Company' }),
        value: PayeeType.COMPANY,
      },
    ],
  },
  [fieldNames.PAYEE_COUNTRY]: {
    name: fieldNames.PAYEE_COUNTRY,
    label: <FormattedMessage defaultMessage="Payee Country" />,
    type: 'listCountry',
    // These values are selected from the context
    options: [],
  },
  [fieldNames.DISABLED]: {
    name: fieldNames.DISABLED,
    label: <FormattedMessage defaultMessage="Payee Status" />,
    type: 'list',
    options: ({ intl }) => [
      {
        label: intl.formatMessage({ defaultMessage: 'Enabled' }),
        value: 'false',
      },
      {
        label: intl.formatMessage({ defaultMessage: 'Disabled' }),
        value: 'true',
      },
    ],
  },
  [fieldNames.ONBOARDED_STATUS]: {
    name: fieldNames.ONBOARDED_STATUS,
    label: <FormattedMessage defaultMessage="Onboarded Status" />,
    type: 'list',
    options: ({ intl }) => [
      {
        label: intl.formatMessage({ defaultMessage: 'Invited' }),
        value: 'INVITED',
      },
      {
        label: intl.formatMessage({ defaultMessage: 'Registered' }),
        value: 'REGISTERED',
      },
      {
        label: intl.formatMessage({ defaultMessage: 'Onboarded' }),
        value: 'ONBOARDED',
      },
    ],
  },
  [fieldNames.WATCHLIST_STATUS]: {
    name: fieldNames.WATCHLIST_STATUS,
    label: <FormattedMessage defaultMessage="Watchlist Status" />,
    type: 'list',
    options: ({ intl }) => [
      {
        label: intl.formatMessage({ defaultMessage: 'None' }),
        value: 'NONE',
      },
      {
        label: intl.formatMessage({ defaultMessage: 'Review' }),
        value: 'REVIEW',
      },
      {
        label: intl.formatMessage({ defaultMessage: 'Pending' }),
        value: 'PENDING',
      },
      {
        label: intl.formatMessage({ defaultMessage: 'Passed' }),
        value: 'PASSED',
      },
      {
        label: intl.formatMessage({ defaultMessage: 'Failed' }),
        value: 'FAILED',
      },
    ],
  },
};

const backOfficeFiltersByName = {
  [fieldNames.PAYOR_ID]: {
    name: fieldNames.PAYOR_ID,
    type: 'entityIdLookup',
    label: <FormattedMessage defaultMessage="Payor" />,
    mode: LookupTextField.modes.PAYOR,
  },
};

const PayeesListConfig = {
  columns: fieldNames,
  getRowProps(datum, index, _, { onClick }) {
    return {
      key: datum.payeeId,
      icon: 'chevron_right',
      onClick: () => onClick(datum),
      'data-testid': dataTestIdBuilder(TestIds.ROW_PREFIX, index),
    };
  },
  getColumnProps(col, data) {
    const { 'data-testid': testId, ...rest } = col;
    const key = col.name;
    return {
      ...rest,
      key,
      children: cellFactoryByFieldName[key](data),
    };
  },
  dataProps: {
    data: arrayOf(
      shape({
        payeeId: string.isRequired,
        displayName: string,
        created: string,
        icon: string,
        remoteId: string,
        onboardedStatus: string,
        watchlistStatus: string,
      })
    ).isRequired,
  },
};

const buildTable = (columnOrder, sortableColumns, filters) => {
  return VeloTableBuilder({
    ...PayeesListConfig,
    sortableColumns,
    filters,
    columnOrder,
  });
};

const BackOfficeList = buildTable(
  [
    columnsByName[fieldNames.ADDED],
    columnsByName[fieldNames.DISPLAY_NAME],
    columnsByName[fieldNames.WATCHLIST_STATUS],
    columnsByName[fieldNames.ONBOARDED_STATUS],
    columnsByName[fieldNames.DISABLED],
  ],
  [fieldNames.DISPLAY_NAME, fieldNames.REMOTE_ID],
  [
    backOfficeFiltersByName[fieldNames.PAYOR_ID],
    filtersByName[fieldNames.DISPLAY_NAME],
    filtersByName[fieldNames.EMAIL],
    filtersByName[fieldNames.REMOTE_ID],
    filtersByName[fieldNames.PAYEE_TYPE],
    filtersByName[fieldNames.PAYEE_COUNTRY],
    filtersByName[fieldNames.DISABLED],
    filtersByName[fieldNames.ONBOARDED_STATUS],
    filtersByName[fieldNames.WATCHLIST_STATUS],
  ]
);

const PayorList = buildTable(
  [
    columnsByName[fieldNames.ADDED],
    columnsByName[fieldNames.DISPLAY_NAME],
    columnsByName[fieldNames.REMOTE_ID],
    columnsByName[fieldNames.ONBOARDED_STATUS],
  ],
  [fieldNames.DISPLAY_NAME, fieldNames.REMOTE_ID],
  [
    filtersByName[fieldNames.DISPLAY_NAME],
    filtersByName[fieldNames.EMAIL],
    filtersByName[fieldNames.REMOTE_ID],
    filtersByName[fieldNames.PAYEE_TYPE],
    filtersByName[fieldNames.PAYEE_COUNTRY],
    filtersByName[fieldNames.DISABLED],
    filtersByName[fieldNames.ONBOARDED_STATUS],
    filtersByName[fieldNames.WATCHLIST_STATUS],
  ]
);

const PayeesByPayorList = buildTable(
  [
    columnsByName[fieldNames.DISPLAY_NAME],
    columnsByName[fieldNames.REMOTE_ID],
    columnsByName[fieldNames.INVITE_STATUS],
    columnsByName[fieldNames.WATCHLIST_STATUS],
    columnsByName[fieldNames.ONBOARDED_STATUS],
  ],
  [fieldNames.DISPLAY_NAME, fieldNames.REMOTE_ID],
  [
    backOfficeFiltersByName[fieldNames.PAYOR_ID],
    filtersByName[fieldNames.DISPLAY_NAME],
    filtersByName[fieldNames.EMAIL],
    filtersByName[fieldNames.REMOTE_ID],
    filtersByName[fieldNames.PAYEE_TYPE],
    filtersByName[fieldNames.PAYEE_COUNTRY],
    filtersByName[fieldNames.DISABLED],
    filtersByName[fieldNames.ONBOARDED_STATUS],
    filtersByName[fieldNames.WATCHLIST_STATUS],
  ]
);

const PayeesList = {
  [PayeesListMode.BACKOFFICE]: BackOfficeList,
  [PayeesListMode.PAYOR]: PayorList,
  [PayeesListMode.BY_PAYOR]: PayeesByPayorList,
};

PayeesList.mode = PayeesListMode;
PayeesList.testIds = TestIds;
PayeesList.filtersByName = filtersByName;
PayeesList.fieldNames = fieldNames;

export { PayeesList };
