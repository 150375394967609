import {
  pooledAccountSelectors,
  privilegeSelectors,
  Privileges,
  PageSize,
} from 'velo-data';

import {
  PayorSearchPresenter,
  createListRedirectionLoader,
  PayorNameDataJoin,
} from '../../presenters';
import {
  filterSelectors,
  createCancelAllClosure,
  mapTablePropsWithResult,
  createEarlyExitCallback,
} from '../../selectors';

/**
 * This private presenter encapsulates the logic to load and present the filters
 */
function FiltersPresenter(entity, query, filters, dataSources) {
  const fetchPayorResults = PayorSearchPresenter(entity, {
    pageSize: PageSize.SMALL,
  });
  const mergeFilters = (physicalAccountOptions) =>
    filterSelectors.populateFilters(filters, query, {
      ...dataSources,
      physicalAccountOptions,
      fetchPayorResults,
    });

  const mergeOriginal = (cb) => cb(undefined, mergeFilters());

  const hasPooled = filters.some(({ name }) => name === 'physicalAccountId');

  return [
    hasPooled
      ? (cb) => {
          return entity.getAllPooledAccounts((error, data) => {
            // if error, just return original filters
            if (error) return mergeOriginal(cb);
            cb(
              undefined,
              mergeFilters(
                pooledAccountSelectors.asFormOptions(data.pooledAccounts)
              )
            );
          });
        }
      : mergeOriginal,
    false,
    {
      result: mergeFilters(),
    },
  ];
}

// these are the keys we are looking to deep-link on
const validKeys = ['payorId', 'physicalAccountId'];

export function SourceAccountsListPresenter(
  wireFrame,
  entity,
  { query, privileges },
  { filters, ...dataSources }
) {
  const dataJoin = PayorNameDataJoin(
    entity,
    privilegeSelectors.hasPrivileges(
      [Privileges.SOURCE_ACCOUNT_VIEW_PAYOR],
      privileges
    )
  );

  const keys = filters.reduce(
    (xs, { name }) => xs.concat(validKeys.includes(name) ? name : []),
    []
  );

  const redirect = (qs) =>
    wireFrame.navigateToSourceAccountsList.redirect({}, qs);

  const loader = createListRedirectionLoader(redirect, keys, query, (q, cb) => {
    const cancellations = [];
    cancellations.push(
      entity.getSourceAccounts(
        q,
        createEarlyExitCallback(
          ({ content, ...extra }) =>
            cancellations.push(dataJoin(cb, content, extra)),
          cb
        )
      )
    );

    return createCancelAllClosure(cancellations);
  });

  const onCreate = () => wireFrame.navigateToSourceAccountCreate({}, query);
  const onClick = ({ id: sourceAccountId }) =>
    wireFrame.navigateToSourceAccountDetail({ sourceAccountId }, query);

  function getProps(...args) {
    return [
      mapTablePropsWithResult(...args),
      {
        onClick,
      },
    ];
  }

  return [
    loader,
    FiltersPresenter(entity, query, filters, dataSources),
    getProps,
    onCreate,
  ];
}
