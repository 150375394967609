import React from 'react';
import { node, string } from 'prop-types';
import styled from 'astroturf/react';
import { VeloTypography } from '../VeloTypography';

/**
 * Required because the default browser styles
 * for a `dd` element includes a left margin.
 */
const Value = styled(VeloTypography)`
  margin: 0;
`;

Description.propTypes = {
  /** The item ID. */
  id: string.isRequired,
  /** The label text (description term). */
  label: node.isRequired,
  /** The value. */
  value: node.isRequired,
};

/**
 * Render a `<dt>`/`<dd>` pair with a suitable ID that
 * can be used to find the value when testing.
 *
 * Wrap multiple `Description` components in a
 * `<dl>` (Description List) element for semantic
 * labelled values in forms, flyouts, etc.
 *
 * For example:
 *
 * ```js
 * <Description id="hello" label="Label" value="Hello, world!" />
 *
 * const value = screen.getByLabelText("Label");
 * expect(value).toHaveTextContent("Hello, world!");
 * ```
 */
function Description({ label, value, id }) {
  return (
    <>
      <VeloTypography use="itemLabel" tag="dt" id={id}>
        {label}
      </VeloTypography>
      <Value use="itemContent" tag="dd" aria-labelledby={id}>
        {value}
      </Value>
    </>
  );
}

export { Description };
