import { PaymentChannelStates } from './PaymentChannelStates';

function getPaymentChannelState(
  payableStatus,
  payableIssues,
  paymentChannelId
) {
  if (payableStatus) return PaymentChannelStates.ENABLED;

  if (payableIssues && payableIssues.find((issue) => issue.code === 'PR1004')) {
    return PaymentChannelStates.DISABLED;
  }
  if (!paymentChannelId) {
    return PaymentChannelStates.NOTSET;
  }

  return PaymentChannelStates.ENABLED;
}

function getPaymentsPausedFromIssues(payableIssues) {
  return payableIssues
    ? payableIssues.some((issue) => issue.code === 'PR1003')
    : false;
}

export { getPaymentChannelState, getPaymentsPausedFromIssues };
