import React, {
  useState,
  useMemo,
  useCallback,
  useContext,
  useEffect,
} from 'react';

/**
 * The increment function takes the key of the cache to increment by
 * @callback Increment
 * @param {string} id key in the cache map to increment by 1
 * @returns {Void}
 */

const CacheIncrementType = {
  TIMER: 'timer',
  VALUE: 'value',
};

const VeloCacheHitContext = React.createContext({
  increment: () => undefined,
  cacheHits: {},
});

function createIncrement(timeout = undefined, cacheValue = { value: 0 }) {
  if (timeout) {
    return {
      type: CacheIncrementType.TIMER,
      value: cacheValue.value,
      timeout,
      timeStamp: Date.now(),
    };
  }
  return {
    type: CacheIncrementType.VALUE,
    value: cacheValue.value + 1,
  };
}

function VeloCacheHitProvider({ children }) {
  const [cacheHits, setCacheHits] = useState({});
  const increment = useCallback(
    (id, timeout) => {
      setCacheHits((cache) => ({
        ...cache,
        [id]: createIncrement(timeout, getCacheValue(cache, id)),
      }));
    },
    [setCacheHits]
  );

  const value = useMemo(
    () => ({
      increment,
      cacheHits,
    }),
    [cacheHits, increment]
  );
  return (
    <VeloCacheHitContext.Provider value={value}>
      {children}
    </VeloCacheHitContext.Provider>
  );
}

function getCacheValue(cache, id) {
  return cache[id] || createIncrement();
}

/**
 * Exposes the global map and increment function
 * @returns {[*, Increment]} A tuple of the current cache map and increment
 * function.
 */
function useVeloCacheHitContextGlobal() {
  const { increment, cacheHits } = useContext(VeloCacheHitContext);
  return [cacheHits, increment];
}

/**
 * Exposes the data and increment function for working on a single cache key.
 * @param {string} id the cache key to operate on.
 * @returns {[number, Increment]} A tuple of the current cache value and increment
 * function.
 */
function useVeloCacheHitContext(id) {
  const [cacheHits, increment] = useVeloCacheHitContextGlobal();
  const incrementer = useCallback(
    (value) => increment(id, value),
    [id, increment]
  );
  const cacheValue = getCacheValue(cacheHits, id);

  useEffect(() => {
    // Initialise a timer cache incrementer
    // As the cacheValue is not cleared using the same id will restart the timer
    // only do this if the timer hasn't expired - or only for the time left since timeStamp
    if (cacheValue.type === CacheIncrementType.TIMER) {
      const remainingTime =
        cacheValue.timeStamp + cacheValue.timeout - Date.now();
      if (remainingTime > 0) {
        const timeoutId = setTimeout(incrementer, remainingTime);
        return () => clearTimeout(timeoutId);
      }
    }
  }, [cacheValue, incrementer]);
  return [cacheValue.value, incrementer];
}

const Context = {
  Provider: VeloCacheHitProvider,
  getCacheValue,
  useVeloCacheHitContext,
  useVeloCacheHitContextGlobal,
};

export { Context as VeloCacheHitContext };
