const selectDisplayValueKey = (key) => `${key}Name`;

const selectAllDisplayValueKeys = (keys) =>
  keys.reduce((xs, key) => [...xs, key, selectDisplayValueKey(key)], []);

function selectQueryString(keys, query, displayValues) {
  return keys.reduce((map, key) => {
    return {
      ...map,
      [key]: query[key],
      [selectDisplayValueKey(key)]: displayValues[key],
    };
  }, {});
}

function queriesNotEqual(keys, query, nextQuery) {
  return keys.some((key) => query[key] !== nextQuery[key]);
}

export const querySelectors = {
  selectDisplayValueKey,
  selectAllDisplayValueKeys,
  selectQueryString,
  queriesNotEqual,
};
