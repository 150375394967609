import React from 'react';
import { bool, string } from 'prop-types';
import styled from 'astroturf/react';
import { VeloCard } from '../VeloCard';
import { Transitions } from '../Transitions';
import { useIsBelowBreakpoint } from '../hooks';

const Breakpoints = useIsBelowBreakpoint.breakpoints;

const Card = styled(VeloCard)`
  @import 'velo-mixins';
  @import '@material/elevation/mixins';
  @import 'velo-variables';

  @include mdc-elevation(24);

  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 500px;
  border-radius: 0;
  display: flex;
  flex-direction: column;
  z-index: $velo-over-all-default;

  @media (max-width: velo-breakpoint(XS)) {
    left: 0;
    width: 100%;
    min-width: 100%;
  }
`;

VeloModalSheetCard.propTypes = {
  /** Custom CSS class(es). */
  className: string,
  /** Used to show/hide the card. */
  open: bool,
};

/**
 * The card where VeloModalSheet content is hosted.
 * You do not need to use this component directly.
 */
function VeloModalSheetCard({ open, ...other }) {
  const isExtraSmall = useIsBelowBreakpoint(Breakpoints.XS);

  return (
    <Transitions.SlideRight in={open} unmountOnExit animate={!isExtraSmall}>
      <Card flat={false} fullScreen={false} {...other} />
    </Transitions.SlideRight>
  );
}

export { VeloModalSheetCard };
