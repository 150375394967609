import React from 'react';
import { node, string } from 'prop-types';
import styled from 'astroturf/react';
import { VeloGrid } from '../VeloGrid';

const Grid = styled(VeloGrid)`
  @import 'velo-variables';

  max-width: 74rem;
  margin: 2.5rem auto;
  padding: 0rem 1rem;

  @media (max-width: velo-breakpoint(XS)) {
    padding: 0;
    margin-top: 0;
    padding-bottom: 4rem;
    width: 100%;
    max-width: 100%;
  }
`;

const Header = styled(VeloGrid.Cell)`
  @import 'velo-variables';

  @media (max-width: velo-breakpoint(XS)) {
    /* header not needed on mobile as content page title now displayed in TopAppBar */
    display: none;
  }
`;

const ActionBar = styled(VeloGrid.Cell)`
  display: flex;
  justify-content: flex-end;
`;

Content.propTypes = {
  /** Custom CSS classes. */
  className: string,
  /** The custom header to render. */
  header: node,
  /** The custom action bar to render below the header. */
  actions: node,
  /** The custom footer to render. */
  footer: node,
  /** The content to render. */
  children: node,
};

/**
 * Generic content component. Renders a custom header (which may
 * contain a title, buttons, icons, etc.) and generic content
 * below. Uses an MDC layout grid for a responsive layout.
 */
export function Content({ children, header, actions, footer, ...other }) {
  return (
    <Grid {...other}>
      {header && <Header span="12">{header}</Header>}
      {actions && <ActionBar span="12">{actions}</ActionBar>}
      <VeloGrid.Cell span="12">{children}</VeloGrid.Cell>
      {footer && <VeloGrid.Cell span="12">{footer}</VeloGrid.Cell>}
    </Grid>
  );
}
