import cookie from 'cookie';

const COOKIE_NAME = 'app_preference_stopconfirmpaymentsdialog';

const getCookiePrefs = () => {
  const cookies = cookie.parse(document.cookie);

  return (
    (cookies && cookies[COOKIE_NAME] && cookies[COOKIE_NAME] === 'true') ||
    false
  );
};

function useGetPaymentConfirmationPreference() {
  return getCookiePrefs();
}

useGetPaymentConfirmationPreference.getCookiePrefs = getCookiePrefs;

export { useGetPaymentConfirmationPreference };
