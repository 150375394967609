import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { string } from 'prop-types';
import { VeloCallout } from '../VeloCallout';
import { VeloTypography } from '../VeloTypography';

const testIdRoot = 'payout-schedule-info';

const TestIds = {
  CONTAINER: `${testIdRoot}-container`,
};

PayoutScheduleInfo.propTypes = {
  /** Schedule for */
  scheduledFor: string.isRequired,
};

PayoutScheduleInfo.testIds = TestIds;

export function PayoutScheduleInfo({ scheduledFor, ...props }) {
  const scheduleDate = useMemo(
    () => new Date(scheduledFor).toString(),
    [scheduledFor]
  );

  return (
    <VeloCallout {...props} type="info" data-testid={TestIds.CONTAINER}>
      <div>
        <VeloTypography use="bodyText" style={{ width: '100%' }}>
          <FormattedMessage defaultMessage="Payout is scheduled. This payment or series of payments will automatically be sent on:" />
        </VeloTypography>
        <div>
          <VeloTypography use="bodyTextEmphasised">
            {scheduleDate}
          </VeloTypography>
        </div>
      </div>
    </VeloCallout>
  );
}
