import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { VeloTypography } from '../VeloTypography';

WUBSPaymentReceiptTitle.propTypes = {
  paymentCurrency: string.isRequired,
};

function WUBSPaymentReceiptTitle({ paymentCurrency, ...props }) {
  return (
    <VeloTypography use="bodyTextEmphasised" tag="div" {...props}>
      {paymentCurrency === 'AUD' ? (
        <FormattedMessage defaultMessage="Money Transfer Services provided in Australia by Western Union Business Solutions (Australia) Pty Limited" />
      ) : (
        <FormattedMessage defaultMessage="Money Transfer Services provided by Convera USA, LLC" />
      )}
    </VeloTypography>
  );
}

export { WUBSPaymentReceiptTitle };
