export function unlockUserV2(send) {
  return (userId, ...args) =>
    send(
      {
        path: '/v2/users/{userId}/unlock',
        pathParameters: { userId },
        type: 'post',
        query: undefined,
        body: undefined,
        headers: undefined,
        auth: 'oauth2',
        contentType: undefined,
      },
      ...args
    );
}
