export function getPayorByIdV2(send) {
  return (payorId, ...args) =>
    send(
      {
        path: '/v2/payors/{payorId}',
        pathParameters: { payorId },
        type: 'get',
        query: undefined,
        body: undefined,
        headers: undefined,
        auth: 'oauth2',
        contentType: undefined,
      },
      ...args
    );
}
