import React from 'react';
import { func, oneOf, string } from 'prop-types';
import styled from 'astroturf/react';
import { VeloCard } from '../VeloCard';
import { VeloGrid } from '../VeloGrid';
import { VeloTypography } from '../VeloTypography';
import { VeloButton } from '../VeloButton';
import { VeloRadio } from '../VeloRadio';
import { EscapeKey } from '../EscapeKey';
import { VeloScrim } from '../VeloScrim';
import { dataTestIdBuilder } from 'velo-data';
import { useScrollLock } from '../hooks';
import { SortOrder, HeadingsType } from './types';
import styles from './VeloTableMobileSort.module.scss';

const Card = styled(VeloCard)`
  @import 'velo-variables';
  @import '@material/elevation/mixins';

  @include mdc-elevation(24);

  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  border: none;
  max-height: 100%;
  z-index: $velo-over-all-default;
`;

const Header = styled('header')`
  @import 'velo-variables';
  @import '@design-system/tokens/typography/scss/mixins';

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0.5rem 0.5rem 1rem;

  @media (min-width: velo-breakpoint(XS)) {
    /* Hide the header */
    display: none;
  }

  :global(.mdc-button__label) {
    @include ds-typography-ellipsis;
  }
`;

const HeaderLabel = styled(VeloTypography).attrs({ use: 'bodyTextEmphasised' })`
  flex-shrink: 0;
  margin-right: 1rem;
`;

const Grid = styled('div')`
  @import 'velo-mixins';

  @include smooth-scrolling;

  width: 100%;
`;

const ApplyButton = styled(VeloButton.Primary)`
  width: 100%;
`;

// Hooks used to call the supplied functon when the window
// is resized. Used to close the sort options form.
const useResize = (fn) => {
  React.useEffect(() => {
    window.addEventListener('resize', fn);
    return () => window.removeEventListener('resize', fn);
  }, [fn]);
};

const TestIds = {
  BUTTON: 'button',
  APPLY_BUTTON: 'apply',
  SCRIM: 'scrim',
  CARD: 'card',
};

const SortOptions = [
  {
    id: 'ascending',
    label: 'Ascending',
    value: SortOrder.ASCENDING,
  },
  {
    id: 'descending',
    label: 'Descending',
    value: SortOrder.DESCENDING,
  },
];

/**
 * Heading used on the sort form.
 */
function Heading({ children }) {
  return <VeloTypography use="sectionHeader">{children}</VeloTypography>;
}

const sharedProps = {
  /** The available heading names and labels. */
  headings: HeadingsType.isRequired,
  /** The initially selected column name. */
  column: string.isRequired,
  /** The initial sort direction. */
  direction: oneOf([SortOrder.ASCENDING, SortOrder.DESCENDING]).isRequired,
};

VeloTableSortForm.propTypes = {
  ...sharedProps,
  /** Called when the form is closed. */
  onClose: func.isRequired,
  /** Called when applying sort options. */
  onApply: func.isRequired,
  /** The root test ID used for the components. */
  'data-testid': string.isRequired,
};

function VeloTableSortForm(props) {
  const containerRef = useScrollLock(true);
  const [column, setColumn] = React.useState(props.column);
  const [direction, setDirection] = React.useState(props.direction);
  useResize(props.onClose);

  const onApply = () => props.onApply(column, direction);
  const onChangeColumn = (event) => setColumn(event.target.value);
  const onChangeDirection = (event) =>
    setDirection(parseInt(event.target.value, 10));

  return (
    <>
      <VeloScrim
        open
        data-testid={dataTestIdBuilder(props['data-testid'], TestIds.SCRIM)}
        onClick={props.onClose}
      />
      <Card
        flat={false}
        fullScreen={false}
        data-testid={dataTestIdBuilder(props['data-testid'], TestIds.CARD)}
      >
        {/* FIXME: Use the <Grid /> component when it supports a ref prop. */}
        <Grid className="mdc-layout-grid" ref={containerRef}>
          <VeloGrid.Inner>
            <VeloGrid.Cell span={12}>
              <Heading>Category</Heading>
            </VeloGrid.Cell>

            {props.headings.map(({ name, label }, index) => (
              <VeloGrid.Cell key={name} span={12}>
                <VeloRadio
                  id={`category-${index}`}
                  value={name}
                  onChange={onChangeColumn}
                  checked={column === name}
                  rootProps={{ className: styles.radio }}
                  data-testid={dataTestIdBuilder(
                    props['data-testid'],
                    'column',
                    index
                  )}
                >
                  {label}
                </VeloRadio>
              </VeloGrid.Cell>
            ))}

            <VeloGrid.Cell span={12}>
              <Heading>Order</Heading>
            </VeloGrid.Cell>

            {SortOptions.map(({ id, value, label }, index) => (
              <VeloGrid.Cell key={id} span={12}>
                <VeloRadio
                  id={id}
                  value={value}
                  onChange={onChangeDirection}
                  checked={direction === value}
                  rootProps={{ className: styles.radio }}
                  data-testid={dataTestIdBuilder(
                    props['data-testid'],
                    'direction',
                    id
                  )}
                >
                  {label}
                </VeloRadio>
              </VeloGrid.Cell>
            ))}

            <VeloGrid.Cell span={12}>
              <ApplyButton
                onClick={onApply}
                data-testid={dataTestIdBuilder(
                  props['data-testid'],
                  TestIds.APPLY_BUTTON
                )}
              >
                Apply
              </ApplyButton>
            </VeloGrid.Cell>
          </VeloGrid.Inner>
        </Grid>
      </Card>

      <EscapeKey onPressed={props.onClose} />
    </>
  );
}

VeloTableMobileSortHeader.propTypes = {
  ...sharedProps,
  /** Custom CSS classes for the root component. */
  className: string,
  /** Called when the sort column and direction have changed. */
  onSort: func.isRequired,
  /** The root test ID for the sort components. */
  'data-testid': string.isRequired,
};

VeloTableMobileSortHeader.testIds = TestIds;

// Render a mobile-friendly sort header
function VeloTableMobileSortHeader({
  className,
  headings,
  column,
  direction,
  'data-testid': testId,
  onSort,
}) {
  const [showForm, setShowForm] = React.useState(false);
  const columnName = headings.find((heading) => heading.name === column).label;

  return (
    <>
      <Header className={className}>
        <HeaderLabel>Sort by</HeaderLabel>
        <VeloButton
          icon={
            direction === SortOrder.ASCENDING
              ? 'arrow_downward'
              : 'arrow_upward'
          }
          onClick={() => setShowForm(true)}
          data-testid={dataTestIdBuilder(testId, TestIds.BUTTON)}
        >
          {columnName}
        </VeloButton>
      </Header>

      {showForm && (
        <VeloTableSortForm
          headings={headings}
          direction={direction}
          column={column}
          onClose={() => setShowForm(false)}
          onApply={(column, direction) => {
            setShowForm(false);
            onSort(column, direction);
          }}
          data-testid={testId}
        />
      )}
    </>
  );
}

export { VeloTableMobileSortHeader };
