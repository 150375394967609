import { useMemo } from 'react';
import { useAppContext, contextPayeeCountryRulesSelector } from '../../context';

function usePayeeCountryRules() {
  const context = useAppContext();
  const payeeCountryRules = contextPayeeCountryRulesSelector(context);

  return useMemo(() => {
    return {
      payeeCountryRules,
    };
  }, [payeeCountryRules]);
}

export { usePayeeCountryRules };
