import React from 'react';
import { arrayOf, func, shape, string, node, bool } from 'prop-types';
import styled from 'astroturf/react';
import { VeloIcon } from '../VeloIcon';
import { VeloStepperStep } from './VeloStepperStep';
import { VeloStepperLabel } from './VeloStepperLabel';
import { VeloStepperIcon } from './VeloStepperIcon';
import { VeloStepperText } from './VeloStepperText';
import { VeloStepperContent } from './VeloStepperContent';

const Stepper = styled('div')`
  @import 'velo-variables';

  display: flex;
  flex-direction: column;
  background-color: velo-color('token-color-system-page-background');
  padding: 1rem;

  &.form {
    padding-left: 1.5rem;
    padding-right: 0.5rem;
  }
`;

Stepper.propTypes = {
  /**
   * Styles the Stepper with different horizontal padding so that it fits in a
   * `VeloCardForm` correctly
   * @see VeloCardForm
   */
  form: bool,
};

VeloStepper.propTypes = {
  /** Custom CSS class(es). */
  className: string,
  /** @see Stepper.propTypes */
  form: bool,
  /**
   * The steps to render. If provided then a
   * renderStep prop must also be included.
   */
  steps: arrayOf(
    shape({
      /** The step title. */
      title: node,
      /** The icon to use if the step number is not required. */
      icon: string,
      /** Disable the step. */
      disabled: bool,
      /** Collapse the step content. */
      collapsed: bool,
      /** Render as a skeleton */
      skeleton: bool,
    })
  ),
  /**
   * Called when rendering each step. Passed the step object
   * and index. Return a suitable component to render.
   */
  renderStep: func,
};

VeloStepper.propTypes = {
  renderStep: () => {},
};

/**
 * Vertical stepper component.
 *
 * This component can be used in two ways:
 *
 * 1. As the root component when composing your own stepper, where you
 * provide <VeloStepperStep> components as children.
 *
 * 2. As a higher-level component where you provide the `steps` and
 * `renderStep` props and have this component do the heavy lifting.
 */
function VeloStepper({ steps, renderStep, children, ...other }) {
  return (
    <Stepper {...other}>
      {steps &&
        steps.map((step, index) => (
          <VeloStepperStep key={index}>
            {/* Label */}
            <VeloStepperLabel>
              {/* Icon or number */}
              <VeloStepperIcon
                disabled={step.disabled}
                skeleton={step.skeleton}
                success={step.success}
              >
                {step.icon ? <VeloIcon icon={step.icon} /> : index + 1}
              </VeloStepperIcon>
              {/* Text */}
              <VeloStepperText
                disabled={step.disabled}
                skeleton={step.skeleton}
              >
                {step.title}
              </VeloStepperText>
            </VeloStepperLabel>

            {/* Content */}
            <VeloStepperContent form={other.form}>
              {step.collapsed ? null : renderStep(step, index)}
            </VeloStepperContent>
          </VeloStepperStep>
        ))}

      {children}
    </Stepper>
  );
}

VeloStepper.Step = VeloStepperStep;
VeloStepper.Label = VeloStepperLabel;
VeloStepper.Icon = VeloStepperIcon;
VeloStepper.Text = VeloStepperText;
VeloStepper.Content = VeloStepperContent;

export { VeloStepper };
