import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useWireframe, useSessionInactivityTimer } from '../hooks';
import { useAppContext } from '../context';
import { withAppContainer } from '../containers';

function PrivateAppWrapper({ render, ...props }) {
  // Session inactivity is applied to private routes only
  // Component lifecycle is used to enable/disable timer
  // The timer is disabled if the user is accessing a public route
  useSessionInactivityTimer();
  const history = useHistory();
  const { removeDeepLink } = useAppContext();
  const wireframe = useWireframe(history);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const token = searchParams.get('one-time-token');

    if (token) {
      removeDeepLink();
      wireframe.navigateToPayoutOpenBankingConsent.redirect({}, { token });
    }
  }, [removeDeepLink, wireframe]);

  return render(props);
}

export function wrapPrivateRoutes(children, entityPropName) {
  return withAppContainer(entityPropName)((props) => (
    <PrivateAppWrapper {...props} render={() => children(props)} />
  ));
}
