import React from 'react';
import styled from 'astroturf/react';
import { FormattedMessage } from 'react-intl';
import { VeloSkeleton } from '../VeloSkeleton';
import { VeloCurrency } from '../VeloCurrency';
import { VeloLabelledItem } from '../VeloLabelledItem';
import { VeloOnOffSwitch } from '../VeloOnOffSwitch';
import { VeloSelect } from '../VeloSelect';
import {
  FreeTextEntryWithinRange,
  ProfileField,
} from '../FormFields/FormFieldTypes';
import { SelectCurrency } from '../FormFields/SelectCurrency';
import {
  sourceAccountFormType,
  sourceAccountFormProps,
  sourceAccountSkeletonData,
} from './types';
import { LookupTextField } from '../LookupTextField';
import { PayorAccountTypeText } from '../PayorAccountTypeText';
import { VeloTextField } from '../VeloTextField';
import { VeloRepeatingField } from '../VeloRepeatingField';
import styles from './SourceAccountFormFields.module.scss';

export const fieldNames = {
  PAYOR: 'payor',
  NAME: 'name',
  TYPE: 'type',
  CURRENCY: 'currency',
  CURRENCY_IN_RAIL: 'currencyInRail',
  CURRENCY_FIXED: 'currencyFixed',
  RAILS_ID: 'railsId',
  POOLED_ACCOUNT_ID: 'pooledAccountId',
  RAIL_SPECIFIC_ACCOUNT_CREDENTIALS: 'accountCredentials',
  RAILS_PROVIDER: 'railsProvider',
  PAYOR_NAME: 'payorName',
  BALANCE: 'balance',
  FUNDING_REF: 'fundingRef',
  PHYSICAL_ACCOUNT_NAME: 'physicalAccountName',
  TOP_UP_ENABLED: 'autoTopUpConfig.enabled',
  TOP_UP_ACCOUNT_NAME: 'autoTopUpConfig.fundingAccountName',
  TOP_UP_MIN_BALANCE: 'autoTopUpConfig.minBalance',
  TOP_UP_TARGET_BALANCE: 'autoTopUpConfig.targetBalance',
  LOW_BALANCE_ENABLED: 'notifications.minimumBalanceEnabled',
  LOW_BALANCE_THRESHOLD: 'notifications.minimumBalance',
  DELETION_STATUS: 'userDeleted',
  DELETION_DATE: 'deletedAt',
  OBO_NOT_FOUND_DETAILS: 'oboOverrideDetails.notFoundDetails',
  OBO_NAME: 'oboOverrideDetails.oboName',
  OBO_REF: 'oboOverrideDetails.oboReference',
  OBO_ADDRESS: 'oboOverrideDetails.oboAddress',
  OBO_ADDRESS_COUNTRY: 'oboOverrideDetails.oboAddress.country',
};

export const labelsByFieldName = {
  [fieldNames.NAME]: <FormattedMessage defaultMessage="Source account name" />,
  [fieldNames.PAYOR]: <FormattedMessage defaultMessage="Payor" />,
  [fieldNames.PAYOR_NAME]: <FormattedMessage defaultMessage="Payor" />,
  [fieldNames.CURRENCY]: <FormattedMessage defaultMessage="Currency" />,
  [fieldNames.TYPE]: <FormattedMessage defaultMessage="Type" />,
  [fieldNames.POOLED_ACCOUNT_ID]: (
    <FormattedMessage defaultMessage="FBO account" />
  ),
  [fieldNames.RAILS_ID]: <FormattedMessage defaultMessage="Rails provider" />,
  [fieldNames.RAILS_PROVIDER]: (
    <FormattedMessage defaultMessage="Rails provider" />
  ),
  [fieldNames.PHYSICAL_ACCOUNT_NAME]: (
    <FormattedMessage defaultMessage="FBO account" />
  ),
  [fieldNames.BALANCE]: <FormattedMessage defaultMessage="Effective balance" />,
  [fieldNames.FUNDING_REF]: (
    <FormattedMessage defaultMessage="Funding reference ID" />
  ),
  [fieldNames.TOP_UP_ENABLED]: <FormattedMessage defaultMessage="Auto topup" />,
  [fieldNames.TOP_UP_ACCOUNT_NAME]: (
    <FormattedMessage defaultMessage="Funding account name" />
  ),
  [fieldNames.TOP_UP_MIN_BALANCE]: (
    <FormattedMessage defaultMessage="Minimum balance" />
  ),
  [fieldNames.TOP_UP_TARGET_BALANCE]: (
    <FormattedMessage defaultMessage="Target balance" />
  ),
  [fieldNames.LOW_BALANCE_ENABLED]: (
    <FormattedMessage defaultMessage="Low balance notification" />
  ),
  [fieldNames.LOW_BALANCE_THRESHOLD]: (
    <FormattedMessage defaultMessage="Minimum balance" />
  ),
  [fieldNames.DELETION_STATUS]: (
    <FormattedMessage defaultMessage="Source account status" />
  ),
  [fieldNames.DELETION_DATE]: (
    <FormattedMessage defaultMessage="Deletion date/time" />
  ),
  [fieldNames.OBO_NOT_FOUND_DETAILS]: (
    <FormattedMessage defaultMessage="Details" />
  ),
  [fieldNames.OBO_REF]: <FormattedMessage defaultMessage="Reference" />,
  [fieldNames.OBO_NAME]: <FormattedMessage defaultMessage="Company name" />,
  [fieldNames.OBO_ADDRESS]: <FormattedMessage defaultMessage="Address" />,
  [fieldNames.OBO_ADDRESS_COUNTRY]: (
    <FormattedMessage defaultMessage="Country" />
  ),
};

const HeadingSkeleton = styled(VeloSkeleton)`
  width: 40%;
`;

const id = (x) => x;

function createFieldOrderReducer(loading, data) {
  const Component = loading ? VeloLabelledItem.Skeleton : VeloLabelledItem;
  return (a, { name, formatChildren = id, formatValue = id, ...props }) => {
    const rawValue = data[name];
    // balance can be undefined
    if (rawValue === undefined && name !== 'balance') {
      return a;
    }

    const children = !loading && formatChildren(rawValue, data);
    const value = formatValue(rawValue);

    return [
      ...a,
      {
        ...props,
        name,
        value,
        children,
        Component,
      },
    ];
  };
}

const sourceAccountFieldRoot = 'source-account-field';

const SourceAccountFieldTestIds = {
  PAYOR_NAME_VALUE: `${sourceAccountFieldRoot}-payor-name-value`,
  ACCOUNT_NAME_VALUE: `${sourceAccountFieldRoot}-account-name-value`,
  EFFECTIVE_BALANCE_VALUE: `${sourceAccountFieldRoot}-effective-balance-value`,
};

const RailSpecificAccountCredential = (props) => {
  const { propertyName, label, updateFieldProperty } = props;
  return (
    <VeloTextField
      name={propertyName}
      required
      value={props[propertyName]}
      onChange={updateFieldProperty(propertyName)}
      data-testid={propertyName}
      {...ProfileField(
        label,
        <FormattedMessage defaultMessage="This field is required" />
      )}
    />
  );
};

const attributeMap = {
  NAME: {
    ...FreeTextEntryWithinRange(labelsByFieldName[fieldNames.NAME], 3, 30),
    name: fieldNames.NAME,
    required: true,
  },
  PAYOR: {
    label: labelsByFieldName[fieldNames.PAYOR],
    name: fieldNames.PAYOR,
    Component: LookupTextField,
    mode: LookupTextField.modes.PAYOR,
    helpText: {
      children: ' ',
    },
    entityId: '',
    required: true,
  },
  POOLED_ACCOUNT_ID: {
    name: fieldNames.POOLED_ACCOUNT_ID,
    label: labelsByFieldName[fieldNames.POOLED_ACCOUNT_ID],
    Component: VeloSelect,
    required: true,
    helpText: {
      validationMsg: true,
      children: <FormattedMessage defaultMessage="Choose an FBO account" />,
    },
  },
  CURRENCY: {
    Component: SelectCurrency,
    name: fieldNames.CURRENCY,
    helpText: <FormattedMessage defaultMessage="Please select a currency" />,
    required: true,
  },
  TYPE: {
    name: fieldNames.TYPE,
    Component: VeloSelect,
    label: labelsByFieldName[fieldNames.TYPE],
    helpText: (
      <FormattedMessage defaultMessage="Please select an account type" />
    ),
    required: true,
  },
  RAILS_ID: {
    name: fieldNames.RAILS_ID,
    label: labelsByFieldName[fieldNames.RAILS_ID],
    Component: VeloSelect,
    required: true,
    helpText: {
      validationMsg: true,
      children: <FormattedMessage defaultMessage="Choose a rails provider" />,
    },
  },
  RAIL_SPECIFIC_ACCOUNT_CREDENTIALS: {
    Component: VeloRepeatingField,
    isExtensible: false,
    name: fieldNames.RAIL_SPECIFIC_ACCOUNT_CREDENTIALS,
    Field: RailSpecificAccountCredential,
    required: true,
  },
};

const sourceAccountFieldOrder = [
  {
    label: <FormattedMessage defaultMessage="Payor" />,
    name: 'payorName',
    testId: SourceAccountFieldTestIds.PAYOR_NAME_VALUE,
  },
  {
    label: <FormattedMessage defaultMessage="Source account" />,
    name: 'sourceAccountName',
    testId: SourceAccountFieldTestIds.ACCOUNT_NAME_VALUE,
  },
  {
    label: <FormattedMessage defaultMessage="Source account type" />,
    name: 'type',
    formatChildren: (type) => <PayorAccountTypeText type={type} />,
  },
  {
    label: <FormattedMessage defaultMessage="Effective balance" />,
    name: 'balance',
    testId: SourceAccountFieldTestIds.EFFECTIVE_BALANCE_VALUE,
    formatValue: () => '',
    formatChildren: (value, { currency }) => (
      <VeloCurrency value={value} currency={currency} />
    ),
  },
];

function createSourceAccountGridSection(
  loading,
  data,
  heading = <FormattedMessage defaultMessage="Pay Into" />
) {
  return {
    heading: loading ? <HeadingSkeleton /> : heading,
    fields: sourceAccountFieldOrder.reduce(
      createFieldOrderReducer(loading, data),
      []
    ),
  };
}

const headingProps = {
  className: styles.compactHeader,
};

function createLabelledSwitchGridField(loading, fieldProps) {
  return {
    ...fieldProps,
    Component: loading ? VeloOnOffSwitch.Loading : VeloOnOffSwitch,
  };
}

const SourceAccountFormFields = {
  attributeMap,
  createSourceAccountGridSection,
  createLabelledSwitchGridField,
  HeadingSkeleton,
  headingProps,
  sourceAccountFormType,
  sourceAccountFormProps,
  sourceAccountSkeletonData,
};

export { SourceAccountFormFields };
