import React, { useState } from 'react';
import { func, arrayOf, string, shape } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useScrollIntoViewOnce } from '../hooks';
import { VeloStepper } from '../VeloStepper';
import { VeloIconButton } from '../VeloIconButton';
import { VeloFieldGrid } from '../VeloFieldGrid';
import {
  BasicCurrency,
  FreeTextEntryWithinRange,
} from '../FormFields/FormFieldTypes';
import { VeloForm } from '../VeloForm';
import { SelectCurrency } from '../FormFields/SelectCurrency';
import { LookupTextField } from '../LookupTextField';
import { VeloCard } from '../VeloCard';
import { VeloCardForm } from '../VeloCardForm';
import { VeloGridLoading } from '../VeloGridLoading';
import { BankRadioGroup } from './BankRadioGroup';
import { ConfirmPayoutDialog } from './ConfirmPayoutDialog';
import { ConfirmPayoutError } from './ConfirmPayoutError';
import { VeloPropTypes } from '../VeloPropTypes';
import { VeloFullScreenLoadingOverlay } from '../VeloFullScreenLoadingOverlay';

const root = 'velo-ob-payouts-create';

const TestIds = {
  CLOSE: `${root}-close`,
  PAYEE_NAME: `${root}-payee-name`,
  CURRENCIES: `${root}-currencies`,
  AMOUNT: `${root}-amount`,
  PAYMENT_REF: `${root}-payment-ref`,
  LOADING: `${root}-loading`,
};

OpenBankingCreatePayoutForm.propTypes = {
  /** callback triggered on closing form. */
  onClose: func.isRequired,
  /** Called when the form is submitted. Passed the code. */
  onSubmit: func.isRequired,
  /** Handler which is needed to trigger side effects when payeeId is updated. */
  onSetPayeeId: func.isRequired,
  /** fetch payee results */
  fetchPayeeResults: func.isRequired,
  /** List of institutions returned from the API */
  institutions: arrayOf(
    shape({
      id: string.isRequired,
      name: string.isRequired,
      iconUrl: string.isRequired,
    })
  ).isRequired,
  /** Payment channel result */
  paymentChannelData: VeloPropTypes.result(
    shape({
      routingNumber: string.isRequired,
      accountNumber: string.isRequired,
    })
  ),
};

OpenBankingCreatePayoutForm.testIds = TestIds;
OpenBankingCreatePayoutForm.dialogTestIds = ConfirmPayoutDialog.testIds;

const SUPPORTED_CURRENCY = 'GBP';

const title = (
  <FormattedMessage defaultMessage="Create a payout via Open Banking" />
);

function OpenBankingCreatePayoutForm({
  onClose,
  fetchPayeeResults,
  paymentChannelData,
  onSubmit,
  onSetPayeeId,
  confirmPayoutError,
  institutions,
}) {
  const [selectedBank, setSelectedBank] = useState('');
  const [payeeLookup, setPayeeLookup] = useState({
    payeeId: '',
    payeeName: '',
  });
  const [paymentDetails, setPaymentDetails] = useState({
    paymentMemo: '',
    amount: '',
  });

  const [modalOpen, setModalOpen] = useState(false);
  const selectedBankData = institutions.find(({ id }) => id === selectedBank);
  const sectionTwoRef = useScrollIntoViewOnce(!!selectedBank);

  return (
    <VeloCard>
      <VeloCard.Header divider>
        <VeloCard.HeaderTitle>{title}</VeloCard.HeaderTitle>
        <VeloIconButton
          title="close"
          icon="close"
          data-testid={TestIds.CLOSE}
          onClick={onClose}
        />
      </VeloCard.Header>
      <VeloCard.Body>
        <VeloStepper>
          {confirmPayoutError}
          {/* Step 1 */}
          <VeloStepper.Step>
            <VeloStepper.Label
              icon="1"
              text={
                <FormattedMessage defaultMessage="From which bank is this payout being made?" />
              }
            />
            <VeloStepper.Content>
              <BankRadioGroup
                institutions={institutions}
                selectedBank={selectedBank}
                onChange={setSelectedBank}
              />
            </VeloStepper.Content>
          </VeloStepper.Step>

          <VeloForm
            onSubmit={(e) => {
              e.preventDefault();
              setModalOpen(true);
            }}
            actionText={<FormattedMessage defaultMessage="Continue" />}
            disableHelperTextPadding={true}
            buttonProps={{
              disabled:
                !selectedBank || !payeeLookup.payeeId || !paymentDetails.amount,
            }}
            autoComplete="off"
          >
            <VeloStepper.Step>
              <VeloStepper.Label
                disabled={!selectedBank}
                icon="2"
                ref={sectionTwoRef}
                text={
                  <FormattedMessage defaultMessage="To which payee is this payout being made?" />
                }
              />
              <VeloStepper.Content>
                <VeloFieldGrid
                  compact
                  onChange={(e) => {
                    onSetPayeeId(e.target.entityId);

                    setPayeeLookup({
                      payeeName: e.target.value,
                      payeeId: e.target.entityId,
                    });
                  }}
                  sections={[
                    {
                      fields: [
                        {
                          Component: LookupTextField,
                          helpText: {
                            children: ' ',
                          },
                          label: <FormattedMessage defaultMessage="Payee" />,
                          value: payeeLookup.payeeName,
                          entityId: payeeLookup.payeeId,
                          name: 'payeeName',
                          'data-testid': TestIds.PAYEE_NAME,
                          fetchResults: fetchPayeeResults,
                          required: true,
                          disabled: !selectedBank,
                        },
                      ],
                    },
                  ]}
                />
              </VeloStepper.Content>
            </VeloStepper.Step>

            <VeloStepper.Step>
              <VeloStepper.Label
                disabled={!payeeLookup.payeeId}
                icon="3"
                text={
                  <FormattedMessage defaultMessage="Add the payment details" />
                }
              />
              <VeloStepper.Content>
                <VeloFieldGrid
                  compact
                  breakpoint={600}
                  onChange={(e) => {
                    const updatedState = {
                      [e.target.name]: e.target.value,
                    };
                    setPaymentDetails((prevPaymentDetails) => ({
                      ...prevPaymentDetails,
                      ...updatedState,
                    }));
                  }}
                  sections={[
                    {
                      fields: [
                        {
                          Component: SelectCurrency,
                          name: 'currency',
                          'data-testid': TestIds.CURRENCIES,
                          helpText: (
                            <FormattedMessage defaultMessage="Select available currency" />
                          ),
                          currencies: [SUPPORTED_CURRENCY],
                          value: SUPPORTED_CURRENCY,
                          desktop: 4,
                          tablet: 4,
                          phone: 4,
                          required: true,
                          disabled: true,
                        },
                        {
                          name: 'amount',
                          'data-testid': TestIds.AMOUNT,
                          value: paymentDetails.amount,
                          ...BasicCurrency(
                            <FormattedMessage defaultMessage="Payment amount" />,
                            <FormattedMessage defaultMessage="Please enter a valid payment amount" />
                          ),
                          desktop: 8,
                          tablet: 8,
                          phone: 8,
                          disabled: !payeeLookup.payeeId,
                          required: true,
                        },
                        {
                          name: 'paymentMemo',
                          'data-testid': TestIds.PAYMENT_REF,
                          ...FreeTextEntryWithinRange(
                            <FormattedMessage defaultMessage="Payment reference" />,
                            1,
                            40
                          ),
                          value: paymentDetails.paymentMemo,
                          disabled: !payeeLookup.payeeId,
                        },
                      ],
                    },
                  ]}
                />
              </VeloStepper.Content>
            </VeloStepper.Step>
          </VeloForm>
        </VeloStepper>

        <ConfirmPayoutDialog
          open={modalOpen}
          onSubmit={onSubmit}
          currency={SUPPORTED_CURRENCY}
          paymentChannelData={paymentChannelData}
          selectedBank={selectedBankData}
          formData={{
            ...paymentDetails,
            ...payeeLookup,
          }}
          onClose={() => setModalOpen(false)}
        />
      </VeloCard.Body>
    </VeloCard>
  );
}

function OpenBankingCreatePayoutFormError(props) {
  return <VeloCardForm.Error title={title} {...props} />;
}

const FieldTypes = VeloGridLoading.fieldTypes;

function Loading({ onClose }) {
  return (
    <VeloCardForm.Loading
      data-testid={TestIds.LOADING}
      title={title}
      onClose={onClose}
      slimline
    >
      <VeloGridLoading
        sections={[
          {
            fields: [
              { type: FieldTypes.Custom, Component: BankRadioGroup.Loading },
            ],
          },
          {
            heading: true,
            fields: [{ type: FieldTypes.TextField }],
          },
          {
            heading: true,
            fields: [
              { type: FieldTypes.TextField },
              { type: FieldTypes.TextField },
              { type: FieldTypes.TextField },
            ],
          },
        ]}
      />
    </VeloCardForm.Loading>
  );
}

function ConfirmPayoutOverlay(props) {
  return (
    <VeloFullScreenLoadingOverlay.Confirm
      title={<FormattedMessage defaultMessage="Payout submitting..." />}
      content={
        <FormattedMessage defaultMessage="You have been securely logged out from your bank and are being transferred back to Velo Payments." />
      }
      {...props}
    />
  );
}

OpenBankingCreatePayoutForm.Error = OpenBankingCreatePayoutFormError;
OpenBankingCreatePayoutForm.Loading = Loading;
OpenBankingCreatePayoutForm.ConfirmPayoutError = ConfirmPayoutError;
OpenBankingCreatePayoutForm.ConfirmPayoutLoading = ConfirmPayoutOverlay;

export { OpenBankingCreatePayoutForm };
