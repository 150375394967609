export function refresh(send) {
  return (query, body, ...args) =>
    send(
      {
        path: '/v1/authenticate',
        pathParameters: {},
        type: 'post',
        query: query,
        body: body,
        headers: undefined,
        auth: 'basic',
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
      },
      ...args
    );
}
