import React, { createContext, useContext } from 'react';
import { RMWCProvider } from '@rmwc/provider';
import { base as basename } from '@design-system/icons/lib/webfonts/DsIconFont-map';
import { Provider as APIProvider } from 'velo-api-react-hooks';
import {
  VeloCacheHitContext,
  VeloThemeContext,
  VeloConfirmDiscardChangesContext,
  VeloNotification,
} from 'velo-react-components';
import observe from 'callbag-observe';
import { CookieConsentContext } from './CookieConsentContext';
import { SettingsContext } from './SettingsContext';

const Context = createContext({
  api: null,
  parent: null,
  requestNotification: null,
  notification: null,
  downloadContentAsFile: null,
  storeTokenPair: null,
  appVariant: null,
  user: null,
  routePaths: {},
});

function createStateFromNotification(notification) {
  if (notification.component === 'snackbar') {
    return { snackbar: notification };
  }
  return { notification };
}

const OPERATING_USER_PERSISTENCE_KEY = 'operatingUser';

function setPersistedOperatingUserId(userId) {
  window.sessionStorage.setItem(OPERATING_USER_PERSISTENCE_KEY, userId);
}

function getPersistedOperatingUserId() {
  return (
    window.sessionStorage.getItem(OPERATING_USER_PERSISTENCE_KEY) || undefined
  );
}

function clearPersistedOperatingUserId() {
  window.sessionStorage.removeItem(OPERATING_USER_PERSISTENCE_KEY);
}

const sessionExpiredAutoLogoutNotification =
  VeloNotification.types.SESSION_EXPIRED_AUTO_LOGOUT;

class Provider extends React.Component {
  static defaultProps = {
    routePaths: {},
    authentication: {},
  };

  state = {
    /**
     * Boolean flag to keep track of when the logout API function call has been
     * initiated by a user i.e the Logout nav item.
     */
    userInitiatedLogoutInProgress: false,
    authentication: this.props.authentication,
    notification: {}, // single notification, Queue management should be handled outside this context
    operatingUser: getPersistedOperatingUserId(), // the payorId of the operating user.
  };

  handlers = {
    requestNotification: (notification) => {
      this.setState(createStateFromNotification(notification));
    },
    clearNotification: () => {
      this.setState({
        notification: {},
      });
    },
    setOperatingUser: (operatingUser) =>
      this.setState(
        {
          operatingUser,
        },
        this.onOperatingUserChange
      ),
    setContextState: (...args) => this.setState(...args),
    logout: ({ userInitiatedLogout } = {}) => {
      this.setState({ userInitiatedLogoutInProgress: !!userInitiatedLogout });
      this.props.value.api.logout();
    },
    removeInviteLink: () => {
      const searchParams = new URLSearchParams(window.location.search);

      if (searchParams.has('inviteLink')) {
        this.props.value.parent.removeDeepLink();
      }
    },
    removeDeepLink: () => {
      this.props.value.parent.removeDeepLink();
    },
    removeBusinessUserInviteLink: () => {
      const searchParams = new URLSearchParams(window.location.search);

      if (
        // The query param `userInvitedVerificationLink` is deprecated
        // This param is supported incase any old invite links are still
        // active in users email accounts.
        // TODO: Decide when to remove `userInvitedVerificationLink`
        searchParams.has('userInvitedVerificationLink') ||
        searchParams.has('token')
      ) {
        this.props.value.parent.removeDeepLink();
      }
    },
  };

  onOperatingUserChange() {
    const { operatingUser } = this.state;
    // TODO: remove when legacy PayorPortal is removed
    const { parent } = this.props.value;

    if (operatingUser) {
      setPersistedOperatingUserId(operatingUser);
    } else {
      clearPersistedOperatingUserId();
    }

    parent.payorID = operatingUser;
  }

  onAuthentication = (authentication) => {
    const nextState = { authentication };

    const isLoggingOut =
      !nextState.authentication.refresh_token &&
      this.state.authentication.refresh_token;

    this.setState(nextState, () => {
      if (isLoggingOut) {
        this.handlers.setOperatingUser(undefined);
        this.props.value.parent._logout();

        if (!this.state.userInitiatedLogoutInProgress) {
          /* istanbul ignore next */
          const onClose = () => {};

          this.handlers.requestNotification({
            ...sessionExpiredAutoLogoutNotification,
            onClose,
            key: Date.now().toString(10),
          });
        }

        this.setState({ userInitiatedLogoutInProgress: false });
      }
    });
  };

  componentWillUnmount() {
    /* istanbul ignore next */
    this.setState = () => undefined;
  }

  componentDidMount() {
    // Defensive coding for tests not adhering to the correct spec
    const api = this.props.value.api || {};
    if (api.sessionStateObservable) {
      observe(this.onAuthentication)(api.sessionStateObservable);
    }
  }

  render() {
    const { appVariant, value, config, routePaths } = this.props;

    const valueProp = {
      appVariant,
      routePaths,
      remoteModules: config?.remoteModules,
      ...this.handlers,
      ...value,
      ...this.state,
    };

    return (
      <RMWCProvider
        icon={{
          strategy: 'className',
          basename,
          prefix: `${basename}-`,
        }}
      >
        <Context.Provider value={valueProp}>
          <APIProvider value={value}>
            <VeloCacheHitContext.Provider>
              <VeloThemeContext.Provider {...config}>
                <SettingsContext.Provider>
                  <VeloConfirmDiscardChangesContext.Provider>
                    <CookieConsentContext.Provider appVariant={appVariant}>
                      {this.props.children}
                    </CookieConsentContext.Provider>
                  </VeloConfirmDiscardChangesContext.Provider>
                </SettingsContext.Provider>
              </VeloThemeContext.Provider>
            </VeloCacheHitContext.Provider>
          </APIProvider>
        </Context.Provider>
      </RMWCProvider>
    );
  }
}

const { Consumer } = Context;
const defaultMapper = (context) => context;

const withAppContext = (mapContextToProps = defaultMapper) => {
  return (WrappedComponent) => (props) => {
    return (
      <Consumer>
        {(context) => (
          <WrappedComponent {...props} {...mapContextToProps(context, props)} />
        )}
      </Consumer>
    );
  };
};

/**
 * This function helps when using jest.mock.
 */
const useAppContext = () => useContext(Context);

export { Context, Provider, Consumer, useAppContext, withAppContext };
