import React from 'react';
import { func } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { VeloLogoPage } from '../VeloLogoPage';
import { VeloButton } from '../VeloButton';

const root = 'email-instructions-sent';

const TestIds = {
  CONTENT: `${root}-content`,
  BUTTON: `${root}-button`,
};

MFADisabledInstructionsPage.testIds = TestIds;

MFADisabledInstructionsPage.propTypes = {
  onClose: func.isRequired,
};

function MFADisabledInstructionsPage({ onClose, ...props }) {
  return (
    <VeloLogoPage
      title={
        <FormattedMessage defaultMessage="Two-factor authentication device is deactivated" />
      }
      {...props}
    >
      <VeloLogoPage.Text data-testid={TestIds.CONTENT}>
        <FormattedMessage defaultMessage="Please contact your administrator." />
      </VeloLogoPage.Text>

      <VeloButton.Primary data-testid={TestIds.BUTTON} onClick={onClose}>
        <FormattedMessage defaultMessage="Back to Sign In" />
      </VeloButton.Primary>
    </VeloLogoPage>
  );
}

export { MFADisabledInstructionsPage };
