import React from 'react';
import { func } from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import { VeloActionBar } from '../VeloActionBar';

const root = 'payee-view-action-bar';

const TestIds = {
  VIEW: `${root}-view`,
  DELETE: `${root}-delete`,
};

ActionBar.propTypes = {
  /** Called when an item is selected from the View menu. */
  onView: func.isRequired,
  onDelete: func,
};

function ActionBar({ onView, onDelete }) {
  const intl = useIntl();
  return (
    <VeloActionBar
      label={intl.formatMessage({ defaultMessage: 'Payee menu' })}
      spacer
    >
      <VeloActionBar.Menu
        icon="visibility"
        label={<FormattedMessage defaultMessage="View" />}
        data-testid={TestIds.VIEW}
        onSelect={onView}
      >
        <VeloActionBar.MenuItem
          icon="payments"
          label={<FormattedMessage defaultMessage="Payments" />}
        />
      </VeloActionBar.Menu>

      {onDelete && (
        <>
          <VeloActionBar.Separator />
          <VeloActionBar.Menu
            icon="delete"
            label={<FormattedMessage defaultMessage="Delete" />}
            data-testid={TestIds.DELETE}
            onSelect={onDelete}
          >
            <VeloActionBar.MenuItem
              name="payee"
              icon="payees"
              label={<FormattedMessage defaultMessage="Payee" />}
            />
          </VeloActionBar.Menu>
        </>
      )}
    </VeloActionBar>
  );
}

export { ActionBar };
