import { PayeeType } from 'velo-data';
import { withResult, replaceEmptyStringsWithNull } from '../helpers';

const MapperByType = {
  [PayeeType.INDIVIDUAL]: ({ individual }) => {
    const { dateOfBirth, nationalIdentification, name } = individual;
    return { ...name, dateOfBirth, nationalIdentification };
  },
  [PayeeType.COMPANY]: ({ company }) => {
    const { taxId, name, operatingName } = company;
    return { taxId, name, operatingName };
  },
};

// Returns an array of profile field names
const getProfileFields = () => [
  // Individual
  'title',
  'firstName',
  'otherNames',
  'lastName',
  'nationalIdentification',
  'dateOfBirth',
  // Company
  'name',
  'operatingName',
  'taxId',
  // Shared
  'email',
  // Address
  'line1',
  'line2',
  'line3',
  'line4',
  'city',
  'countyOrProvince',
  'zipOrPostcode',
  'country',
];

// Merge profile fields into an existing object and
// ensure they default to an empty string.
const mergeWithProfile = (values) =>
  getProfileFields().reduce(
    (acc, key) => ({ ...acc, [key]: values[key] || '' }),
    values
  );

const selectFormValuesFromPayee = (payee) => {
  const { address, email, ...props } = payee;
  const type =
    props.type || (props.individual ? PayeeType.INDIVIDUAL : PayeeType.COMPANY);
  return mergeWithProfile({
    type,
    email,
    ...MapperByType[type](props),
    ...address,
  });
};

const selectReadOnlyValuesFromPayee = (payee) => ({
  loginEmail: payee.loginEmail,
});

const selectFormValuesFromPayeeResult = withResult(selectFormValuesFromPayee);
const selectReadOnlyValuesFromPayeeResult = withResult(
  selectReadOnlyValuesFromPayee
);

const FormatterByType = {
  [PayeeType.INDIVIDUAL]: (values) => {
    const {
      dateOfBirth,
      nationalIdentification,
      firstName,
      lastName,
      title,
      otherNames,
    } = values;
    return {
      // if we are changing types, be sure to null this
      company: null,
      individual: {
        name: {
          firstName,
          lastName,
          title,
          otherNames,
        },
        dateOfBirth,
        nationalIdentification,
      },
    };
  },
  [PayeeType.COMPANY]: (values) => {
    const {
      taxId,
      name,
      operatingName,
      adminUserFirstName,
      adminUserLastName,
    } = values;
    return {
      // if we are changing types, be sure to null this
      individual: null,
      company: {
        name,
        operatingName,
        taxId,
        adminUserFirstName,
        adminUserLastName,
      },
    };
  },
};

function selectPayeeDataFromFormValues(values) {
  const {
    type: payeeType,
    email,
    contactEmail,
    contactSmsNumber,
    countryCode: country,
    ...fields
  } = replaceEmptyStringsWithNull(values);
  const { line1, line2, line3, line4, countyOrProvince, city, zipOrPostcode } =
    fields;
  return {
    address: {
      line1,
      line2,
      line3,
      line4,
      city,
      countyOrProvince,
      country,
      zipOrPostcode,
    },
    payeeType,
    contactEmail,
    contactSmsNumber,
    email,
    ...FormatterByType[payeeType](fields),
  };
}

export {
  getProfileFields,
  mergeWithProfile,
  selectFormValuesFromPayee,
  selectFormValuesFromPayeeResult,
  selectReadOnlyValuesFromPayeeResult,
  selectPayeeDataFromFormValues,
};
