import React from 'react';
import { arrayOf, bool, func } from 'prop-types';
import styled from 'astroturf/react';
import FilterPropTypes from './filterPropTypes';
import { FilterContainer } from './FilterContainer';
import { FilterTextField } from './FilterTextField';
import { FilterHeader } from './FilterHeader';
import { FilterForm } from './FilterForm';

const TextField = styled(FilterTextField)`
  @import 'velo-variables';

  @media (max-width: velo-breakpoint(S)) {
    display: none;
  }
`;

/**
 * Render either a text field or a button based
 * on the device screen width.
 */
const ResponsiveFilter = (props) => (
  <>
    <TextField {...props} />
    <FilterHeader {...props} />
  </>
);

Filter.propTypes = {
  /** The available columns to filter. */
  fields: arrayOf(FilterPropTypes.field).isRequired,
  /**
   * Called when the filters change.
   * Passed an object map keyed on name with the filter value.
   */
  onChange: func.isRequired,

  /** Width of text field + form */
  compactWidth: bool,
};

/**
 * Column filter component.
 * Used to create filters for fields used by a Portal table.
 */
function Filter({ fields, onChange, compactWidth, ...other }) {
  return (
    <FilterContainer
      fields={fields}
      onChange={onChange}
      compactWidth={compactWidth}
      render={(props) => (
        <ResponsiveFilter compactWidth={compactWidth} {...props} />
      )}
      {...other}
    />
  );
}

Filter.TextField = FilterTextField;
Filter.Form = FilterForm;
Filter.types = FilterPropTypes.types;

export { Filter };
