import React from 'react';
import { AppearanceForm, VeloThemeContext } from 'velo-react-components';
import { usePresenter } from '../../hooks';
import { SettingsAppearancePresenter } from './SettingsAppearancePresenter';

function AppearanceFormComponent(context) {
  const props = usePresenter(SettingsAppearancePresenter, {}, null, context);

  return <AppearanceForm {...props} />;
}

export function SettingsAppearanceRoute() {
  return (
    <VeloThemeContext.Consumer>
      {(context) => <AppearanceFormComponent {...context} />}
    </VeloThemeContext.Consumer>
  );
}
