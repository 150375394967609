import React from 'react';
import { bool, string, func } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styled from 'astroturf/react';
import { VeloFieldGrid } from '../VeloFieldGrid';
import { VeloForm } from '../VeloForm';
import { VeloDateRange } from '../VeloDateRange';
import { VeloGridLoading } from '../VeloGridLoading';
import { VeloCheckbox } from '../VeloCheckbox';
import { VeloTypography } from '../VeloTypography';
import { useFieldGridForm } from '../useFieldGridForm';

const root = 'transaction-report-form';

const TestIds = {
  EXPORT_BUTTON: 'report-form-export-button',
  DESCENDANTS_FIELD: `${root}-descendants`,
  ...VeloForm.testIds,
  ...VeloDateRange.testIds,
};

const FieldGrid = styled(VeloFieldGrid)`
  padding: 0;
  margin-bottom: 1.5rem;
`;

const Checkbox = styled(VeloCheckbox)`
  margin: -11px 0px -11px -11px;
`;

const IncludeModes = {
  PAYOR_AND_DESCENDANTS: 'payorAndDescendants',
  PAYOR_ONLY: 'payorOnly',
};

const getInitialValues = () => ({
  descendants: false,
  payorName: '',
  dateRange: ['', ''],
});

const disableButton = ({ dateRange: [startDate, endDate], dateRangeError }) =>
  startDate === '' || endDate === '' || dateRangeError;

const getButtonProps = (submitting, body) => {
  return {
    disabled: disableButton(body),
    'data-testid': TestIds.EXPORT_BUTTON,
    children: submitting ? (
      <FormattedMessage defaultMessage="Exporting..." />
    ) : (
      <FormattedMessage defaultMessage="Export to CSV" />
    ),
  };
};

const createSections = (
  body,
  { fetchPayorResults, payorName, descendants }
) => {
  const asField = payorName
    ? [
        {
          name: 'payorName',
          Component: () => (
            <VeloTypography use="bodyText">
              <FormattedMessage
                defaultMessage="Currently acting as <strong>{payorName}</strong>."
                values={{
                  strong: (chunks) => <strong>{chunks}</strong>,
                  payorName,
                }}
              />
            </VeloTypography>
          ),
          value: payorName,
        },
      ]
    : [];

  const descendantField =
    payorName || descendants
      ? [
          {
            name: 'descendants',
            'data-testid': TestIds.DESCENDANTS_FIELD,
            label: (
              <FormattedMessage defaultMessage="Include descendant Payors" />
            ),
            Component: Checkbox,
            value: body.descendants,
            checked: body.descendants,
            disabled: !descendants,
          },
        ]
      : [];

  return [
    {
      fields: [
        ...asField,
        ...descendantField,
        {
          name: 'dateRange',
          Component: VeloDateRange,
          disableFutureDates: true,
          label: (
            <VeloTypography use="bodyTextSmaller" tag="div">
              <FormattedMessage defaultMessage="Select a date range." />
            </VeloTypography>
          ),
        },
      ],
    },
  ];
};

const formatBody = ({ dateRange, descendants }) => ({
  startDate: dateRange[0],
  endDate: dateRange[1],
  include: descendants
    ? IncludeModes.PAYOR_AND_DESCENDANTS
    : IncludeModes.PAYOR_ONLY,
});

TransactionReportForm.propTypes = {
  /** The name of the payor for the "As:" label */
  payorName: string,
  /** A boolean, whether or not the payor has children */
  descendants: bool,
  /** callback triggered on submitting form. */
  onSubmit: func.isRequired,
};

TransactionReportForm.testIds = TestIds;

function TransactionReportForm(props) {
  const [gridProps, { dirty, ...formProps }] = useFieldGridForm(
    {
      createSections,
      getInitialValues,
      getButtonProps,
      formatBody,
    },
    props
  );

  return (
    <VeloForm
      {...formProps}
      disableHelperTextPadding={true}
      autoComplete="off"
      buttonProps={{ ...formProps.buttonProps, alignEnd: true }}
    >
      <FieldGrid {...gridProps} />
    </VeloForm>
  );
}

function Loading() {
  return (
    <VeloForm.Loading buttonProps={{ alignEnd: true }}>
      <VeloGridLoading
        compact
        sections={[
          {
            heading: false,
            fields: [
              {
                type: VeloGridLoading.fieldTypes.LabelledItem,
              },
              {
                type: VeloGridLoading.fieldTypes.LabelledItem,
              },
              {
                type: VeloGridLoading.fieldTypes.TextField,
              },
            ],
          },
        ]}
      />
    </VeloForm.Loading>
  );
}

function LoadingError({ error }) {
  return (
    <VeloForm.Error
      disableHelperTextPadding={true}
      buttonProps={{
        disabled: true,
        alignEnd: true,
        children: <FormattedMessage defaultMessage="Export to CSV" />,
      }}
      error={error}
    />
  );
}

TransactionReportForm.Loading = Loading;
TransactionReportForm.Error = LoadingError;

export { TransactionReportForm };
