export function deletePaymentChannelV3(send) {
  return (payeeId, paymentChannelId, ...args) =>
    send(
      {
        path: '/v3/payees/{payeeId}/paymentChannels/{paymentChannelId}',
        pathParameters: { payeeId, paymentChannelId },
        type: 'delete',
        query: undefined,
        body: undefined,
        headers: undefined,
        auth: 'oauth2',
        contentType: undefined,
      },
      ...args
    );
}
