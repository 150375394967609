import React from 'react';
import { FormattedMessage } from 'react-intl';
import { arrayOf, shape } from 'prop-types';
import { PaymentChannelTypes } from '../propTypes';
import { ResultContainer } from '../../ResultContainer';
import { PaymentViewHeading } from '../PaymentViewHeading';
import { PaymentViewSectionSkeleton } from '../PaymentViewSectionSkeleton';
import { PaymentViewChannelDetails } from './PaymentViewChannelDetails';
import { VeloPropTypes } from '../../VeloPropTypes';

const LoadingComponent = () => (
  <PaymentViewSectionSkeleton heading length={3} />
);

const BaseComponent = ({ result, paymentChannelRules }) => (
  <>
    <PaymentViewHeading>
      <FormattedMessage defaultMessage="Payment Method Details" />
    </PaymentViewHeading>
    {/* Payment Channel Details */}
    <PaymentViewChannelDetails
      result={result}
      paymentChannelRules={paymentChannelRules}
    />
  </>
);

/**
 * Used to render Payment Channel details in a PaymentView.
 */
const PaymentViewChannel = ResultContainer({ LoadingComponent })(BaseComponent);

PaymentViewChannel.propTypes = {
  /** The data to render. */
  result: shape(PaymentChannelTypes),
  /** The payment channel rules to render. */
  paymentChannelRules: arrayOf(shape(VeloPropTypes.PaymentChannelRuleType)),
};

export { PaymentViewChannel };
