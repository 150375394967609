import React, { useState, useMemo } from 'react';
import { OTPAdminDialog } from 'velo-react-components';
import { useAPIMethods } from 'velo-api-react-hooks';
import { registerSms as registerSMS } from 'velo-api/src/entities/users';
import { OTPRequiredPresenter } from './OTPRequiredPresenter';

const entitySpec = {
  registerSMS,
};

function OTPRequiredInterceptor({ render, user, handlers }) {
  // All state management and logic for this component is handled by the
  // presenter
  const [state, setState] = useState(OTPRequiredPresenter.initialState);
  const entity = useAPIMethods(entitySpec);
  const [wrappedHandlers, dialogProps] = useMemo(
    () => OTPRequiredPresenter(setState, entity, { handlers, user }),
    [entity, handlers, user]
  );

  return (
    <>
      {render(wrappedHandlers)}
      <OTPAdminDialog {...state} {...dialogProps} />
    </>
  );
}

export { OTPRequiredInterceptor };
