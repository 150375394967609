import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'astroturf/react';
import TestIds from './testIds';
import { PayoutTypes } from '../PayoutTypes';
import { VeloRadioTileGroup } from '../VeloRadioTileGroup';

const RadioTileGroup = styled(VeloRadioTileGroup)`
  :global(.ds-icon) {
    font-size: 3.8rem;
  }
`;

function PayoutTypeRadioGroup({ selectedPayoutType, onChange }) {
  return (
    <RadioTileGroup
      value={selectedPayoutType}
      onChange={onChange}
      desktopSpan={6}
    >
      <VeloRadioTileGroup.Tile
        icon="payout_group"
        title={<FormattedMessage defaultMessage="Group Payout" />}
        label={
          <FormattedMessage defaultMessage="For making multiple payments" />
        }
        value={PayoutTypes.CREATE.GROUP}
        data-testid={TestIds.GROUP_PAYOUT}
      />
      <VeloRadioTileGroup.Tile
        icon="payout_single"
        title={<FormattedMessage defaultMessage="Single Payout" />}
        label={
          <FormattedMessage defaultMessage="For making an individual payment" />
        }
        value={PayoutTypes.CREATE.SINGLE}
        data-testid={TestIds.SINGLE_PAYOUT}
      />
    </RadioTileGroup>
  );
}
export { PayoutTypeRadioGroup };
