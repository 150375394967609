import React from 'react';
import { FormattedMessage } from 'react-intl';
import { VeloLogoPage } from '../VeloLogoPage';
import { ResetPasswordForm } from '../ResetPasswordForm';
import { PasswordUpdateMode } from './PasswordUpdateMode';
import { MultiFactorChallengeGuard } from '../MultiFactorChallengeGuard';

const Titles = {
  [PasswordUpdateMode.Create]: (
    <FormattedMessage defaultMessage="Create a Password" />
  ),
  [PasswordUpdateMode.Reset]: (
    <FormattedMessage defaultMessage="Reset Password" />
  ),
};

function ResetPasswordPage({
  mode,
  showMfa,
  onSubmitOtp,
  secondaryAction,
  mfaType,
  error,
  ...props
}) {
  const title = showMfa ? (
    <FormattedMessage defaultMessage="Verification Code" />
  ) : (
    Titles[mode]
  );

  return (
    <VeloLogoPage title={title} {...props}>
      <MultiFactorChallengeGuard
        showMfa={showMfa}
        isLogin={false}
        secondaryAction={secondaryAction}
        mfaType={mfaType}
        onSubmit={onSubmitOtp}
      >
        <ResetPasswordForm {...props} error={error} />
      </MultiFactorChallengeGuard>
    </VeloLogoPage>
  );
}

ResetPasswordPage.Titles = Titles;
ResetPasswordPage.PasswordUpdateMode = PasswordUpdateMode;

export { ResetPasswordPage };
