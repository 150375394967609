import React from 'react';
import { createHashHistory } from 'history';
import { Router } from 'react-router';
import { IntlProvider } from 'react-intl';
import {
  VeloPopupContentContext,
  MUIThemeProvider,
} from 'velo-react-components';
import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {
  MultiBackend,
  TouchTransition,
  MouseTransition,
  createTransition,
} from 'react-dnd-multi-backend';
import KeyboardBackend, {
  isKeyboardDragTrigger,
} from 'react-dnd-accessible-backend';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Provider as AppProvider } from '../context/VeloAppContext';
import { LocaleContext } from '../context/LocaleContext';
import { useTheme } from '../hooks';
import { getJsonConfig } from '../selectors/getJsonConfig';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      networkMode: 'always',
    },
  },
});

// istanbul ignore next
const KeyboardTransition = createTransition('keydown', (event) => {
  if (!isKeyboardDragTrigger(event)) return false;
  event.preventDefault();
  return true;
});

const Theme = ({ children }) => {
  //apply theme to body using the useTheme hook.
  useTheme();
  return <>{children}</>;
};

const CustomHTML5toTouch = {
  backends: [
    {
      id: 'html5',
      backend: HTML5Backend,
      transition: MouseTransition,
      // by default, will dispatch a duplicate `mousedown` event when this backend is activated
    },
    {
      id: 'touch',
      backend: TouchBackend,

      options: { enableMouseEvents: true },
      transition: TouchTransition,
      // will not dispatch a duplicate `touchstart` event when this backend is activated
      skipDispatchOnTransition: true,
    },

    {
      id: 'keyboard',
      backend: KeyboardBackend,
      context: { window, document },
      preview: true,
      transition: KeyboardTransition,
    },
  ],
};

const Locale = ({ children }) => {
  return (
    <LocaleContext.Consumer>
      {({ locale, localeTranslations }) => (
        <IntlProvider
          locale={locale}
          textComponent={React.Fragment}
          /**
           * If the locale data is not included then fallback to the default browser language.
           * This avoids dev mode warnings about missing strings.
           */
          defaultLocale={locale}
          messages={localeTranslations}
        >
          {children}
        </IntlProvider>
      )}
    </LocaleContext.Consumer>
  );
};

export const AppShell = ({
  veloLib,
  appVariant,
  children,
  routePaths,
  messages,
}) => {
  const jsonConfig = getJsonConfig();

  // Prevent usage of the portal from with an iframe
  // https://cheatsheetseries.owasp.org/cheatsheets/Clickjacking_Defense_Cheat_Sheet.html#best-for-now-legacy-browser-frame-breaking-script
  if (window.self !== window.top) {
    document.body.style = 'display: none;';
  }

  return (
    <QueryClientProvider client={queryClient}>
      {/* Enable for debugging React Query Calls in dev mode */}
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      <DndProvider backend={MultiBackend} options={CustomHTML5toTouch}>
        <LocaleContext.Provider messages={messages}>
          <Locale>
            <MUIThemeProvider>
              <AppProvider
                value={veloLib}
                appVariant={appVariant}
                config={jsonConfig}
                routePaths={routePaths}
              >
                <Theme>
                  <VeloPopupContentContext.Provider>
                    <Router history={createHashHistory()}>{children}</Router>
                  </VeloPopupContentContext.Provider>
                </Theme>
              </AppProvider>
            </MUIThemeProvider>
          </Locale>
        </LocaleContext.Provider>
      </DndProvider>
    </QueryClientProvider>
  );
};
