export function listPayeesV4(send) {
  return (query, ...args) =>
    send(
      {
        path: '/v4/payees',
        pathParameters: {},
        type: 'get',
        query: query,
        body: undefined,
        headers: undefined,
        auth: 'oauth2',
        contentType: undefined,
      },
      ...args
    );
}
