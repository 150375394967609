const contextCountriesSelector = ({ data }) =>
  data ? data.result.countries : [];

const contextSupportedRailsSelector = ({ data }) =>
  data ? data.result.supportedRails : [];

const contextPaymentChannelRulesSelector = ({ data }) =>
  data ? data.result.paymentChannelRules : {};

const contextCurrenciesSelector = ({ data }) =>
  data ? data.result.currencies : [];

const contextPayeeCountryRulesSelector = ({ data }) =>
  data ? data.result.payeeCountryRules : [];

const createContextDataState = (
  error,
  countries,
  paymentChannelRules,
  currencies,
  user,
  payeeCountryRules,
  supportedRails
) => ({
  data: {
    result: error
      ? undefined
      : {
          paymentChannelRules,
          countries,
          currencies,
          payeeCountryRules,
          supportedRails,
        },
    error,
  },
  user: user,
});

export {
  contextCountriesSelector,
  createContextDataState,
  contextPaymentChannelRulesSelector,
  contextCurrenciesSelector,
  contextPayeeCountryRulesSelector,
  contextSupportedRailsSelector,
};
