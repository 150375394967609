import React from 'react';
import { arrayOf, func, string } from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import styled from '@mui/system/styled';
import { VeloEnhancedSelect } from '../../VeloEnhancedSelect';
import { VeloFlagIcon } from '../../VeloFlagIcon';
import { Country } from '../../Country';
import { getCountries } from '../../utils';

const FlagIcon = styled(VeloFlagIcon)`
  margin-right: 1rem;
`;

SelectCountry.propTypes = {
  /** The list of country codes to display. */
  countries: arrayOf(string),
  /** The currently selected country. */
  value: string.isRequired,
  /** Called when the selection changes. */
  onChange: func.isRequired,
  /** Test ID. */
  'data-testid': string.isRequired,
};

export function SelectCountry({ countries = getCountries(), ...other }) {
  const intl = useIntl();
  const options = countries
    .map((value) => ({ value, label: Country.getCountryName(intl, value) }))
    .sort((a, b) => a.label.localeCompare(b.label));

  return (
    <VeloEnhancedSelect
      label={<FormattedMessage defaultMessage="Country" />}
      options={options}
      {...other}
    >
      {options.map((option) => {
        return (
          <MenuItem key={option.value} value={option.value}>
            <ListItemIcon>
              <FlagIcon flag={option.value} disabled={other.disabled} />
            </ListItemIcon>
            <ListItemText disableTypography sx={{ my: 0 }}>
              {option.label}
            </ListItemText>
          </MenuItem>
        );
      })}
    </VeloEnhancedSelect>
  );
}
