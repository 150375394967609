export function registerSms(send) {
  return (body, ...args) =>
    send(
      {
        path: '/v2/users/registration/sms',
        pathParameters: {},
        type: 'post',
        query: undefined,
        body: body,
        headers: undefined,
        auth: 'oauth2',
        contentType: 'application/json',
      },
      ...args
    );
}
