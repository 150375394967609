const accountToOption = ({ id: value, name: label }) => ({ value, label });

const selectAsOptions = (sourceAccounts) => sourceAccounts.map(accountToOption);

const selectSourceAccount = ([sourceAccount]) =>
  sourceAccount?.content?.[0] ?? {};

export const sourceAccountSelectors = {
  selectAsOptions,
  selectSourceAccount,
};
