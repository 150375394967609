import React from 'react';
import { FormattedMessage } from 'react-intl';
import { PaymentViewHistory } from '../../PaymentViewHistory';
import { PaymentViewDateTime } from '../../PaymentViewDateTime';
import { PaymentViewItem } from '../../PaymentViewItem';
import { VeloStatusIndicator } from '../../../VeloStatusIndicator';

const Events = {
  PAYMENT_ACCEPTED_BY_RAILS: 'PAYMENT_ACCEPTED_BY_RAILS',
  AWAITING_CONFIRMATION: 'AWAITING_CONFIRMATION',
};

// Map of events and associated icons, class modifiers and labels
const eventTypes = {
  PAYMENT_ACCEPTED_BY_RAILS: {
    type: VeloStatusIndicator.types.SUCCESS,
    label: <FormattedMessage defaultMessage="Submitted" />,
  },
  PAYMENT_CONFIRMED: {
    type: VeloStatusIndicator.types.SUCCESS,
    label: <FormattedMessage defaultMessage="Confirmed" />,
  },
  PAYMENT_FAILED: {
    type: VeloStatusIndicator.types.ERROR,
    label: <FormattedMessage defaultMessage="Failed" />,
  },
  ACH_RETURN_RECEIVED: {
    type: VeloStatusIndicator.types.ERROR,
    label: <FormattedMessage defaultMessage="Failed" />,
  },
  ACH_PAYMENT_RETURN_CANCELLED: {
    type: VeloStatusIndicator.types.ERROR,
    label: <FormattedMessage defaultMessage="Return Cancelled" />,
  },
  // This is a special synthetic event
  AWAITING_CONFIRMATION: {
    type: VeloStatusIndicator.types.INFO,
    label: <FormattedMessage defaultMessage="Awaiting Confirmation" />,
  },
  PAYMENT_RETURNED_BY_RAILS: {
    type: VeloStatusIndicator.types.INFO,
    label: <FormattedMessage defaultMessage="Returned" />,
  },
  RETURN_CANCELLED: {
    type: VeloStatusIndicator.types.INFO,
    label: <FormattedMessage defaultMessage="Return Cancelled" />,
  },
};

// Modify the event list to spoof an AWAITING_CONFIRMATION event
const getEvents = (events) => {
  if (
    events.length === 1 &&
    events[0].eventType === Events.PAYMENT_ACCEPTED_BY_RAILS
  ) {
    return [{ eventType: Events.AWAITING_CONFIRMATION }, ...events];
  }
  return events;
};

function PayeePaymentViewHistory({ result }) {
  return (
    <PaymentViewHistory
      result={result}
      eventTypes={eventTypes}
      getEvents={(events) => getEvents(events)}
      renderEventDetails={(event) => (
        <>
          {/* Submitted Date/Time */}
          {event.eventDateTime && (
            <PaymentViewItem
              label={<FormattedMessage defaultMessage="Date/Time" />}
              value={<PaymentViewDateTime value={event.eventDateTime} />}
            />
          )}
        </>
      )}
    />
  );
}

export { PayeePaymentViewHistory };
