import React from 'react';
import { arrayOf, string, shape } from 'prop-types';
import styled from 'astroturf/react';
import { useIntl } from 'react-intl';
import { VeloGrid } from '../VeloGrid';
import { PaymentChannelView } from '../PaymentChannelView';
import { toProperCase } from 'velo-data';
import { countryName } from '../utils';
import { VeloPropTypes } from '../VeloPropTypes';

const Grid = styled(VeloGrid)`
  padding: 1rem 0;
`;

const BankDetails = styled(VeloGrid.Cell)`
  * {
    word-break: initial;
  }
`;

const BankName = styled('div')`
  padding-bottom: 0.5rem;
`;

const root = 'velo-banklookup';
const TestIds = {
  BANK_DETAILS: `${root}-bank-details`,
  SECOND_ADDRESS_LINE: `${root}-second-line`,
  COUNTRY: `${root}-country`,
};

BankLookupBody.testIds = TestIds;

BankLookupBody.propTypes = {
  /** The payment channel details  */
  channel: shape({
    /** Payment channel name. */
    paymentChannelName: string,
    /** The channel country code. */
    countryCode: string,
    /** The channel currency code. */
    currency: string,
    /** The account name. */
    accountName: string,
    /** The routing number (e.g. 123-456-789) */
    routingNumber: string,
    /** The account number. */
    accountNumber: string,
    /** The IBAN. */
    iban: string,
  }).isRequired,
  /* The bank lookup response information */
  banklookup: VeloPropTypes.bankLookupType(),
  /** An array of payment channel rules fetched from the API */
  paymentChannelRules: arrayOf(VeloPropTypes.paymentChannelRuleType),
};

function BankLookupBody(props) {
  const intl = useIntl();
  const { paymentChannelRules, channel, banklookup } = props;

  return (
    <Grid>
      <VeloGrid.Cell span="6">
        {paymentChannelRules &&
          channel &&
          paymentChannelRules.map((rule, index) => {
            return (
              <PaymentChannelView.Item
                key={`${index}`}
                label={rule.displayName}
                value={channel[rule.element]}
                valueTestId={`${root}-${rule.element}`}
              />
            );
          })}
      </VeloGrid.Cell>
      <BankDetails span="6">
        <PaymentChannelView.Item
          label="Bank"
          value={
            banklookup && (
              <>
                <BankName>{banklookup.bankName}</BankName>
                <div data-testid={TestIds.SECOND_ADDRESS_LINE}>
                  {banklookup.bankAddress.countyOrProvince &&
                    toProperCase(banklookup.bankAddress.countyOrProvince)}
                </div>
                <div data-testid={TestIds.COUNTRY}>
                  {countryName(banklookup.bankAddress.country, intl)}
                </div>
              </>
            )
          }
          valueTestId={TestIds.BANK_DETAILS}
        />
      </BankDetails>
    </Grid>
  );
}

export { BankLookupBody };
