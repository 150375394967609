import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { PhoneNumber } from '../PhoneNumber';

const name = 'security';

const propTypes = {
  email: string.isRequired,
  cellphoneNumber: string,
};

function PayeeViewSecuritySection({ payee: { email, cellphoneNumber } }) {
  return {
    name,
    heading: <FormattedMessage defaultMessage="Security" />,
    fields: [
      {
        id: 'email',
        label: <FormattedMessage defaultMessage="Email address" />,
        value: email,
      },
      {
        id: 'cellphoneNumber',
        label: <FormattedMessage defaultMessage="Mobile number" />,
        value: cellphoneNumber ? (
          <PhoneNumber value={cellphoneNumber} />
        ) : (
          <FormattedMessage defaultMessage="Not set" />
        ),
      },
    ],
  };
}

PayeeViewSecuritySection.sectionName = name;
PayeeViewSecuritySection.propTypes = propTypes;
export { PayeeViewSecuritySection };
