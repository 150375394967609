import React, { useMemo, useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import omit from 'just-omit';
import { VeloCardForm } from '../VeloCardForm';
import { VeloFieldGrid } from '../VeloFieldGrid';
import { VeloTextField } from '../VeloTextField';
import { Currency } from '../FormFields/FormFieldTypes';
import { minorToMajorUnitMoney, majorToMinorUnitMoney } from 'velo-data';
import { SourceAccountFormFields } from '../SourceAccountFormFields';

const root = 'source-account-balance-threshold';

const TestIds = {
  ...VeloCardForm.testIds,
  TOGGLE: `${root}-toggle`,
  TOGGLE_VALUE: `${root}-toggle-value`,
  BALANCE_FIELD: `${root}-balance-field`,
  ACTION_BUTTON: `${root}-action-button`,
};

const defaultState = {
  submitting: false,
  submitError: undefined,
  body: {
    minimumBalanceToggle: false,
    minimumBalance: 0,
  },
};

const stateFromData = ({ notifications, currency }) => {
  const minimumBalance = minorToMajorUnitMoney(
    notifications.minimumBalance || 0,
    currency
  );
  return {
    minimumBalanceToggle: minimumBalance > 0,
    minimumBalance,
  };
};

function useBalanceThreshold({
  data = undefined,
  onClose,
  onSubmit,
  omitDisplayFields = [],
}) {
  const [{ submitting, error, body }, setState] = useState(defaultState);

  useEffect(() => {
    setState((s) => ({
      ...s,
      body: data ? stateFromData(data) : defaultState.body,
    }));
  }, [setState, data]);

  const methods = useMemo(
    () => ({
      onSubmit: (e) => {
        e.preventDefault();
        setState((s) => ({ ...s, submitting: true, error: undefined }));
        onSubmit(
          {
            minimumBalance: body.minimumBalanceToggle
              ? majorToMinorUnitMoney(body.minimumBalance, data.currency)
              : 0,
          },
          (error) => {
            if (error) {
              setState((s) => ({ ...s, error, submitting: false }));
            }
          }
        );
      },
      onChange: (e) => {
        const { name, value, type, checked } = e.target;
        setState(({ body, ...s }) => ({
          ...s,
          body: {
            ...body,
            [name]: type === 'checkbox' ? checked : value,
          },
        }));
      },
    }),
    [data, body, setState, onSubmit]
  );

  return {
    onClose,
    error,
    ...methods,
    ...useMemo(() => {
      const loading = data === undefined;
      const normalizedData = omit(
        data || SourceAccountFormFields.sourceAccountSkeletonData,
        omitDisplayFields
      );
      const { currency, notifications } = normalizedData;
      const minBalance = body.minimumBalanceToggle
        ? majorToMinorUnitMoney(body.minimumBalance, currency)
        : 0;
      const dirty = !loading && minBalance !== notifications.minimumBalance;
      return {
        dirty,
        overlay: submitting,
        title: <FormattedMessage defaultMessage="Low Balance Notification" />,
        buttonProps: {
          disabled: !dirty,
          skeleton: loading,
          children: submitting ? (
            <FormattedMessage defaultMessage="Saving..." />
          ) : (
            <FormattedMessage defaultMessage="Save" />
          ),
          'data-testid': TestIds.ACTION_BUTTON,
        },
        gridProps: {
          headingProps: SourceAccountFormFields.headingProps,
          sections: [
            SourceAccountFormFields.createSourceAccountGridSection(
              loading,
              normalizedData,
              null
            ),
            {
              heading: loading ? (
                <SourceAccountFormFields.HeadingSkeleton />
              ) : (
                <FormattedMessage defaultMessage="Balance Settings" />
              ),
              fields: [
                SourceAccountFormFields.createLabelledSwitchGridField(loading, {
                  name: 'minimumBalanceToggle',
                  label: (
                    <FormattedMessage defaultMessage="Low balance notification" />
                  ),
                  value: body.minimumBalanceToggle,
                  'data-testid': TestIds.TOGGLE,
                }),
                {
                  name: 'minimumBalance',
                  ...Currency(
                    currency,
                    <FormattedMessage defaultMessage="Minimum balance" />,
                    true
                  ),
                  /**
                   * ensure that initial Number(0) is not rendered, but
                   * string("0") will render.
                   */
                  value: body.minimumBalance
                    ? body.minimumBalance.toString(10)
                    : '',
                  disabled: !body.minimumBalanceToggle,
                  required: body.minimumBalanceToggle,
                  autoComplete: 'off',
                  'data-testid': TestIds.BALANCE_FIELD,
                  Component: loading ? VeloTextField.Skeleton : undefined,
                },
              ],
            },
          ],
        },
      };
    }, [body, data, submitting, omitDisplayFields]),
  };
}

SourceAccountBalanceThresholdForm.propTypes =
  SourceAccountFormFields.sourceAccountFormProps;

function SourceAccountBalanceThresholdForm(props) {
  const { onChange, gridProps, ...formProps } = useBalanceThreshold(props);
  return (
    <VeloCardForm slimline {...formProps}>
      <VeloFieldGrid onChange={onChange} {...gridProps} />
    </VeloCardForm>
  );
}

SourceAccountBalanceThresholdForm.testIds = TestIds;

export { SourceAccountBalanceThresholdForm };
