import styled from 'astroturf/react';
import { bool } from 'prop-types';

export const VeloCardContent = styled('div')`
  margin: 1rem;

  &.slimline {
    margin-left: 0;
    margin-right: 0;
  }
`;

VeloCardContent.propTypes = {
  /**
   * Renders the content block without side margins
   */
  slimline: bool,
};
