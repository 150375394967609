import React from 'react';
import { ReactComponent as ErrorSvg } from 'velo-assets/icons/error.svg';
import { VeloTypography } from '../VeloTypography';

const prefix = 'velo-app-loading-container';

const TestIds = {
  LOADING: `${prefix}-loading`,
  ERROR: `${prefix}-error`,
};

/**
 * Styles are inlined as components are statically appended to the build
 * output HTML.
 */
const styles = {
  parent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100vh',
  },
  error: {
    width: '4rem',
    height: '4rem',
    marginBottom: '1rem',
  },
};

const clsNames = {
  downwards_diagonal: 'downwards-diagonal',
  downwards_corner: 'downwards-corner',
  upwards_corner: 'upwards-corner',
  upwards_diagonal: 'upwards-diagonal',
  downwards_corner_edge: 'downwards-corner-edge',
};

const cssText = (
  playState,
  size,
  halfSize,
  doubleSize,
  sizePlusHalf,
  trippleSize
) => {
  const animNames = {
    downward_diagonal: 'downward-diagonal',
    upward_diagonal: 'upward-diagonal',
    upward_corner: 'upward-corner',
    downward_corner: 'downward-corner',
    downward_corner_edge: 'downward-corner-edge',
  };

  return `
.right {
	fill: #4F4F4F;
}
.left {
	fill: #828282;
}
.top {
	fill: #BDBDBD;
}

@keyframes ${animNames.downward_diagonal} {
  0% {
    transform: translate(${size}px,0px);
  }
  50% {
    transform: translate(${doubleSize}px,${halfSize}px);
  }
  100% {
    transform: translate(${doubleSize}px,${halfSize}px);
  }
}

@keyframes ${animNames.upward_diagonal} {
  0% {
    transform: translate(${doubleSize}px,${sizePlusHalf}px);
  }
  50% {
    transform:translate(${size}px,${size}px);
  }
  100% {
    transform:translate(${size}px,${size}px);
  }
}

@keyframes ${animNames.upward_corner} {
  0% {
    transform:translate(${size}px,${size}px);
  }
  50% {
    transform: translate(0px,${halfSize}px);
  }
  100% {
    transform: translate(${size}px,0px);
  }
}

@keyframes ${animNames.downward_corner} {
  0% {
    transform: translate(${doubleSize}px,${halfSize}px);
  }
  50% {
    transform: translate(${trippleSize}px,${size}px);
  }
  100% {
    transform: translate(${doubleSize}px,${sizePlusHalf}px);
  }
}

@keyframes ${animNames.downward_corner_edge} {
  0% {
    transform: translate(${doubleSize}px,${halfSize}px);
    opacity: 0;
  }
  49% {
    opacity: 0;
  }
  50% {
    transform: translate(${trippleSize}px,${size}px);
    opacity: 1;
  }
  100% {
    transform: translate(${doubleSize}px,${sizePlusHalf}px);
    opacity: 1;
  }
}

.cube {
  animation-duration: 680ms;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-play-state: ${playState}
}

.${clsNames.downwards_diagonal} {
  animation-name: ${animNames.downward_diagonal};
}

.${clsNames.downwards_corner} {
  animation-name: ${animNames.downward_corner};
}

.${clsNames.downwards_corner_edge} {
  animation-name: ${animNames.downward_corner_edge};
}

.${clsNames.upwards_diagonal} {
  animation-name: ${animNames.upward_diagonal};
}

.${clsNames.upwards_corner} {
  animation-name: ${animNames.upward_corner};
}`;
};

const Sides = {
  TOP: 'top',
  LEFT: 'left',
  RIGHT: 'right',
};

const pointFns = {
  [Sides.TOP]: (s, hs, ds) => [
    [s, 0],
    [ds, hs],
    [s, s],
    [0, hs],
  ],
  [Sides.LEFT]: (s, hs, ds, sph) => [
    [0, hs],
    [s, s],
    [s, ds],
    [0, sph],
  ],
  [Sides.RIGHT]: (s, hs, ds, sph) => [
    [s, s],
    [ds, hs],
    [ds, sph],
    [s, ds],
  ],
};

const polyPoints = (points) => points.map((x) => x.join(',')).join(' ');

const CubeFace = ({ sizes, side }) => (
  <polygon
    className={side}
    points={polyPoints(pointFns[side](...sizes))}
    shapeRendering="crispEdges"
  />
);

const Cube = ({ sides, sizes, className }) => (
  <g className={`cube ${className}`}>
    {sides.map((side) => (
      <CubeFace key={side} side={side} sizes={sizes} />
    ))}
  </g>
);

const cubes = [
  [clsNames.downwards_diagonal, [Sides.TOP, Sides.LEFT, Sides.RIGHT]],
  [clsNames.downwards_corner, [Sides.LEFT]],
  [clsNames.upwards_corner, [Sides.TOP, Sides.LEFT]],
  [clsNames.downwards_corner, [Sides.TOP, Sides.RIGHT]],
  [clsNames.upwards_diagonal, [Sides.TOP, Sides.LEFT, Sides.RIGHT]],
  [clsNames.downwards_corner_edge, [Sides.TOP, Sides.LEFT]],
];

// pre-compute sizes to avoid extensive maths
const computeSizes = (size) => [size, size / 2, size * 2, size * 1.5, size * 3];

function Cubes({ size, playState }) {
  const sizes = computeSizes(size);
  return (
    <>
      <style>{cssText(playState, ...sizes)}</style>
      {cubes.map(([className, sides], key) => (
        <Cube key={key} className={className} sides={sides} sizes={sizes} />
      ))}
    </>
  );
}

const Center = ({ children }) => <div style={styles.parent}>{children}</div>;

const Spinner = ({ size = 25, playState, testId }) => (
  <svg data-testid={testId} width={size * 5} height={size * 3.5}>
    <Cubes size={size} playState={playState} />
  </svg>
);

const VeloAppLoading = (props) => (
  <Center {...props}>
    <Spinner testId={TestIds.LOADING} playState="running" />
  </Center>
);

const ErrorComponent = ({
  error = 'Sorry, something went wrong. Please try again.',
  ...props
}) => (
  <Center {...props}>
    <ErrorSvg style={styles.error} aria-label="error" role="img" />
    <VeloTypography tag="div" use="bodyText" data-testid={TestIds.ERROR}>
      {error}
    </VeloTypography>
  </Center>
);

VeloAppLoading.Error = ErrorComponent;
VeloAppLoading.testIds = TestIds;

export { VeloAppLoading };
