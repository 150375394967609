import { privilegeSelectors } from 'velo-data';
import { Filter } from 'velo-react-components';
import { countryName } from 'velo-react-components/src/utils/countries';

const selectFiltersByPrivileges = (filters, privileges, intl) =>
  filters.map((filter) => {
    if (filter.type === Filter.types.list) {
      const filterOptions =
        typeof filter.options === 'function'
          ? filter.options(intl)
          : filter.options;
      return {
        ...filter,
        options: filterOptions.filter((option) =>
          privilegeSelectors.hasPrivileges(
            option.requiredPrivileges || [],
            privileges
          )
        ),
      };
    }
    return filter;
  });

function populateOptions(dataSources, options) {
  if (typeof options === 'function') {
    return options(dataSources);
  }
  return options;
}

const countryFilterMapper = (filter, _, { countries, intl }) => ({
  ...filter,
  options: countries.map(({ isoCountryCode: value }) => ({
    value,
    label: countryName(value, intl),
  })),
});

const filterMappersByName = {
  physicalAccountId: (filter, query, { physicalAccountOptions: options }) => ({
    ...filter,
    value: query.physicalAccountId,
    options,
  }),
  entityId: (filter, query, { fetchEntityResults: fetchResults }) => ({
    ...filter,
    value: query.entityIdName,
    entityId: query.entityId,
    fetchResults,
  }),
  /*
  @TODO This can be added once the /source-accounts endpoint is available to query based on a name string
  sourceAccountName: (filter, query, { fetchSourceAccountResults: fetchResults }) => ({
    ...filter,
    value: query.SourceAccountName,
    entityId: query.SourceAccountName,
    fetchResults,
  }),*/
  payorId: (filter, query, { fetchPayorResults: fetchResults }) => ({
    ...filter,
    value: query.payorIdName,
    entityId: query.payorId,
    fetchResults,
  }),
  payeeId: (filter, query, { fetchPayeeResults: fetchResults }) => ({
    ...filter,
    value: query.payeeIdName,
    entityId: query.payeeId,
    fetchResults,
  }),
  currency: (filter, _, { currencies }) => ({
    ...filter,
    options: currencies.map(({ currency: value }) => ({
      value,
      label: value,
    })),
  }),
  country: countryFilterMapper,
  payeeCountry: countryFilterMapper,
  [Filter.types.list]: (filter, _, dataSources) => ({
    ...filter,
    options: populateOptions(dataSources, filter.options),
  }),
  default: (filter) => filter,
};

/**
 * @typedef {Object} FilterSpec
 * @property {string} name The unique name of the filter.
 * @property {*} value The current value of the filter.
 * @property {string=} entityId The identifier for the value.
 * @property {Function=} fetchResults The lookup function.
 * @property {Object[]|Function=} options An array of options for the filter, or
 * a function accepting dataSources that returns an array of options.
 *
 * @typedef {Object} FilterQuery
 * @property {string=} payorId The queried payor identifier
 * @property {string=} payorIdName The payor name as it appears in the query
 * @property {string=} entityId The queried entity identifier
 * @property {string=} entityIdName The entity name as it appears in the query
 * @property {string=} physicalAccountId The queried physical account identifier
 *
 * @typedef {Object} FilterDataSources
 * @property {Function=} fetchPayorResults A fetcher for payors
 * @property {Function=} fetchPayeeResults A fetcher for payees
 * @property {Function=} fetchEntityResults A fetcher for user entities (payors)
 * @property {Object[]=} countries An array of countries
 * @property {Object[]=} currencies An array of currencies
 */

/**
 * Populates a given array of filters with values and additional options from
 * the passed query and dataSources parameters.
 *
 * @param {FilterSpec[]} filters The incoming filters to be processed.
 * @param {FilterQuery} query The query object to populate the current filter
 * values with.
 * @param {FilterDataSources} dataSources An object describing any additional
 * data sources needed by the filter population methods e.g. `fetchPayorResults`
 *
 * @returns {FilterSpec[]}
 */
function populateFilters(filters, query, dataSources) {
  return filters.map((filter) =>
    (
      filterMappersByName[filter.name] ||
      filterMappersByName[filter.type] ||
      filterMappersByName['default']
    )(filter, query, dataSources)
  );
}

export const filterSelectors = {
  populateFilters,
  selectFiltersByPrivileges,
};
